import TextArea from "antd/lib/input/TextArea";
import React, { Component } from "react";

export default class RemarksContainer extends Component {
  render() {
    return (
      <div>
        <div
          style={{ marginTop: "10px", marginBottom: "6px", marginLeft: "16px" }}
        >
          Remarks
          {/* <span style={{ color: "red" }}>*</span> */}
        </div>
        <div style={{ marginBottom: "8px", marginLeft: "16px" }}>
          <TextArea
            value={this.props.remarks}
            placeholder="Please enter remarks"
            onChange={(e) => this.props.handleRemarksChange(e)}
            style={{
              paddingLeft: "11px",
              paddingTop: "5px",
              fontSize: "12px",
              background: "#f5f5f5",
              border: "1px solid #898989",
              borderRadius: "0px",
              color: "#212121",
              letterSpacing: "0.032em",
              lineHeight: "20px",
              fontSize: "12px",
              height: "48px",
              padding: "4px 11px",
            }}
          />
        </div>
      </div>
    );
  }
}
