import React, { Component } from "react";
import { Row, Col, Table, Input, InputNumber } from "antd";
import {
  formatNumber,
  toStoreCurrency,
  // toUSD,
  getCurrencySymbol,
} from "../../../../../../Helpers/Pricing";
import "./ImportListProductVariants.scss";

class ForcedRenderInput extends Component {
  state = {
    value: this.props.defaultValue ? this.props.defaultValue : 0,
  };
  onChange(value) {
    this.setState({
      value,
    });
  }
  componentWillReceiveProps(newProps) {
    this.setState({
      value: newProps.defaultValue,
    });
  }
  render() {
    if (this.props.inputType === "number") {
      return (
        <div className="flex-variants">
          <InputNumber
            {...this.props}
            value={this.state.value}
            onChange={(value) => this.onChange(value)}
          />
        </div>
      );
    } else {
      return (
        <Input
          {...this.props}
          value={this.state.value}
          onChange={(value) => this.onChange(value)}
        />
      );
    }
  }
}

class ImportListProductVariants extends Component {
  state = {
    selectedRowKeys: [],
    loading: false,
  };

  componentDidMount() {
    let selectedRowKeys = this.props.product.variants
      .filter((v) => v.selected)
      .map((v) => v.skuPropIds);
    this.setState(
      {
        selectedRowKeys,
      },
      () => {
        // this.numberFieldBlur(0, {}, false);
      }
    );
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
    let variantData = this.props.product.variants;
    variantData = variantData.map((v) => {
      if (selectedRowKeys.indexOf(v.skuPropIds) !== -1) {
        return { ...v, selected: true };
      }
      return { ...v, selected: false };
    });
    this.props.onFieldChange("variants", variantData);
  };

  numberFieldBlur(row, values, showAlert) {
    let variantData = this.props.product.variants;
    variantData[row] = { ...variantData[row], ...values };
    this.props.onFieldChange("variants", variantData, showAlert);
  }

  render() {
    const { currencies } = this.props;
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    let skuData = this.props.product.variants;
    const price_settings = this.props.pricing;

    skuData = skuData.map((sku) => {
      sku.key = sku.skuPropIds;

      const manuallyEdited =
        sku.manually_edited_comparedatprice || sku.manually_edited_shopprice
          ? true
          : false;

      if (!sku.currency_converted) {
        sku.price = toStoreCurrency(sku.price, currencies);
        sku.currency_converted = true;
      }
      sku.shop_price = sku.shop_price;

      // = manuallyEdited
      //   ? sku.shop_price
      //   : price_settings.product_cost.type === "multiplier"
      //   ? sku.price * parseFloat(price_settings.product_cost.data)
      //   : sku.price + parseFloat(price_settings.product_cost.data);

      sku.compared_at_price = sku.compared_at_price;

      // = manuallyEdited
      //   ? sku.compared_at_price
      //   : price_settings.compared_at_price.type === "multiplier"
      //   ? sku.price * parseFloat(price_settings.compared_at_price.data)
      //   : sku.price + parseFloat(price_settings.compared_at_price.data);

      sku.profit = sku.shop_price - sku.price;

      return sku;
    });

    let columns = [
      {
        title: `${
          this.state.selectedRowKeys.length
            ? this.state.selectedRowKeys.length
            : "No"
        } Variant(s) Selected`,
        dataIndex: "img",
        render: (_, sku, __) => {
          return sku.img ? (
            <img
              src={sku.img}
              alt={`SKU ${sku.vskuid}`}
              style={{ maxWidth: "64px" }}
            />
          ) : (
            ""
          );
        },
      },
      {
        title: "SKU",
        dataIndex: "vskuid",
        render: (text, _, index) => (
          <Input
            defaultValue={text}
            onBlur={(e) =>
              this.numberFieldBlur(index, { vskuid: e.target.value })
            }
            style={{ maxWidth: "100px" }}
          />
        ),
      },
      {
        title: "Product Cost",
        dataIndex: "price",
        render: (text) => (
          <span>
            {getCurrencySymbol(
              this.props.storegeo === "global" ? "USD" : "INR"
            )}{" "}
            {!!text ? formatNumber(text) : 0}
            {/* {getCurrencySymbol(currencies.store_currency)} {formatNumber(text)}{" "} */}
            {/* {currencies.store_currency !== "USD" && (
              <i style={{ color: "rgb(125, 125, 125)" }}>
                <br /> $ {formatNumber(toUSD(text, currencies))}{" "}
              </i>
            )} */}
          </span>
        ),
      },
      {
        title:
          "Shopify Price " +
          `(${getCurrencySymbol(this.props.currencies.store_currency)})`,
        dataIndex: "shop_price",
        render: (text, _, index) => (
          <span>
            <ForcedRenderInput
              inputType="number"
              sym={getCurrencySymbol(this.props.currencies.store_currency)}
              defaultValue={parseFloat(text).toFixed(2)}
              onBlur={(e) =>
                this.numberFieldBlur(index, {
                  shop_price: parseFloat(e.target.value),
                  manually_edited_shopprice: true,
                })
              }
            />
            {/* {currencies.store_currency !== "USD" && (
              <i style={{ color: "rgb(125, 125, 125)" }}>
                <br /> $ {formatNumber(toUSD(text, currencies))}
              </i>
            )} */}
          </span>
        ),
      },
      {
        title:
          "Compared At Price " +
          `(${getCurrencySymbol(this.props.currencies.store_currency)})`,
        dataIndex: "compared_at_price",

        render: (text, _, index) => (
          <span>
            <ForcedRenderInput
              inputType="number"
              defaultValue={parseFloat(text).toFixed(2)}
              sym={getCurrencySymbol(this.props.currencies.store_currency)}
              onBlur={(e) =>
                this.numberFieldBlur(index, {
                  compared_at_price: parseFloat(e.target.value),
                  manually_edited_comparedatprice: true,
                })
              }
            />
            {/* {currencies.store_currency !== "USD" && (
              <i style={{ color: "rgb(125, 125, 125)" }}>
                <br /> $ {formatNumber(toUSD(text, currencies))}
              </i>
            )} */}
          </span>
        ),
      },

      // hide profit for now
      // {
      //   title: "Profit",
      //   dataIndex: "profit",
      //   render: (text) => (
      //     <span>
      //       {getCurrencySymbol(this.props.currencies.store_currency)}{" "}
      //       {formatNumber(text)}{" "}
      //       {/* {getCurrencySymbol(currencies.store_currency)} {formatNumber(text)}{" "} */}
      //       {/* {currencies.store_currency !== "USD" && (
      //         <i style={{ color: "rgb(125, 125, 125)" }}>
      //           <br /> $ {formatNumber(toUSD(text, currencies))}
      //         </i>
      //       )} */}
      //     </span>
      //   ),
      // },
      // {
      //   title: "Inventory",
      //   dataIndex: "inventory",
      //   render: (text) => formatNumber(text, 0),
      // },
    ];

    if (
      this.props.product &&
      this.props.product.vfprod &&
      this.props.product.vfprod.variantPropKeys
    ) {
      columns = [
        ...columns.slice(0, 2),
        ...this.props.product.vfprod.variantPropKeys.map((k) => ({
          title: k,
          dataIndex: k,
          render: (text, _, index) => (
            <Input
              defaultValue={text}
              onBlur={(e) =>
                this.numberFieldBlur(index, { [k]: e.target.value })
              }
              style={{ maxWidth: "100px" }}
            />
          ),
        })),
        ...columns.slice(2),
      ];
    } else {
      columns = [...columns.slice(0, 2), ...columns.slice(2)];
    }

    // const menu = (
    //   <Menu className="editPrice">
    //     <SubMenu title="Price">
    //       <Menu.Item
    //         onClick={() =>
    //           this.props.toggleOverridePriceModal(
    //             "price",
    //             "newValueModalVisible"
    //           )
    //         }
    //       >
    //         Set New Value
    //       </Menu.Item>
    //       <Menu.Item
    //         onClick={() =>
    //           this.props.toggleOverridePriceModal(
    //             "price",
    //             "multiplierModalVisible"
    //           )
    //         }
    //       >
    //         Set A Multiplier
    //       </Menu.Item>
    //     </SubMenu>
    //     <SubMenu title="Compared At Price">
    //       <Menu.Item
    //         onClick={() =>
    //           this.props.toggleOverridePriceModal(
    //             "compared_at_price",
    //             "newValueModalVisible"
    //           )
    //         }
    //       >
    //         Set New Value
    //       </Menu.Item>
    //       <Menu.Item
    //         onClick={() =>
    //           this.props.toggleOverridePriceModal(
    //             "compared_at_price",
    //             "multiplierModalVisible"
    //           )
    //         }
    //       >
    //         Set A Multiplier
    //       </Menu.Item>
    //     </SubMenu>
    //   </Menu>
    // );
    return (
      <div className="product-list-variants">
        <Row style={{ marginBottom: ".75rem" }}>
          <Col xs={6}>
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <Switch
                defaultChecked={product.product_autoupdate === 1 ? true : false}
                size="small"
                onChange={(checked) =>
                  this.props.onFieldChange(
                    "product_autoupdate",
                    checked === true ? 1 : 0
                  )
                }
              />{" "}
              <p className="product-card-switch"> Auto Update Price</p>
            </div> */}
          </Col>

          {/* <Col xs={6}>
            <Dropdown overlay={menu}>
              <a
                className="ant-dropdown-link"
                href="#/"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Icon type="edit" />{" "}
                <p className="product-card-switch"> Edit Pricing</p>{" "}
                <Icon type="down" style={{ color: "rgba(0, 0, 0, 0.65)" }} />
              </a>
            </Dropdown>
          </Col> */}
        </Row>
        <Row>
          <Table
            pagination={false}
            rowSelection={rowSelection}
            columns={columns}
            scroll={{ x: 1000 }}
            dataSource={skuData}
          />
        </Row>
      </div>
    );
  }
}

export default ImportListProductVariants;
