import React, { Component } from "react";
import { Tooltip, Icon, Select, message, Alert } from "antd";
import { formatNumber, getCurrencySymbol } from "../../../../Helpers/Pricing";

class QuantityPicker extends Component {
  state = {
    value: 0,
  };

  componentDidUpdate(pp) {
    if (this.props.index === 0) {
      if (
        pp.selectedType !== this.props.selectedType ||
        pp.moqIndex !== this.props.moqIndex
      ) {
        // this.setState({ value: 0 }, () => {
        //   this.onInputChange(this.state.value);
        // });

        if (this.props.selectedType === "Sample" && this.props.bulkSample) {
          return;
        } else {
          if (this.props.selectedType === "vf") {
            this.setState({ value: 0 }, () => {
              this.onInputChange(this.props.vf_inventory);
            });
          } else if (this.props.selectedType === "Sample") {
            this.setState({ value: 0 }, () => {
              this.onInputChange(1);
            });
          } else {
            this.setState({ value: 0 }, () => {
              this.onInputChange(
                this.props.product.moq_sourcing_table2[
                  this.freightStatusName[this.props.freightData]
                ][this.props.moqIndex]["moq"]
              );
            });
          }
        }
      }
    }
  }

  freightStatusName = {
    "Via Air": "air_price",
    "Via Sea": "sea_price",
    Domestic: "domestic_price",
    Sample: "sample_price",
  };

  componentDidMount() {
    if (!!this.props.product && this.props.index === 0) {
      if (this.props.selectedType === "vf") {
        this.setState({ value: 0 }, () => {
          this.onInputChange(this.props.vf_inventory);
        });
      } else if (this.props.selectedType === "Sample") {
        this.setState({ value: 0 }, () => {
          this.onInputChange(1);
        });
      } else {
        this.setState({ value: 0 }, () => {
          this.onInputChange(
            this.props.product.moq_sourcing_table2[
              this.freightStatusName[this.props.freightData]
            ][this.props.moqIndex]["moq"]
          );
        });
      }
    }
  }
  increment() {
    if (this.props.selectedType === "vf") {
      if (this.state.value >= this.props.vf_inventory) {
        message.destroy();
        message.error(
          "Can not enter more quantity than available VF Inventory quantity."
        );
        return;
      } else {
        this.setState(
          (state) => ({
            value: Number(state.value) + 1,
          }),
          () => this.onInputChange(this.state.value)
        );
      }
    } else {
      this.setState(
        (state) => ({
          value: Number(state.value) + 1,
        }),
        () => this.onInputChange(this.state.value)
      );
    }
  }

  decrement() {
    this.setState(
      (state) => ({
        value: Math.max(0, state.value - 1),
      }),
      () => this.onInputChange(this.state.value)
    );
  }

  onInputChange(v) {
    if (
      this.props.selectedType === "vf" &&
      Number(v) > Number(this.props.vf_inventory)
    ) {
      let value = v;
      if (!value) {
        value = 0;
      }
      if (/^\d+$/.test(this.props.vf_inventory)) {
        this.setState(
          { value: Number(this.props.vf_inventory) },
          () => this.props.setValue(this.state.value),
          this.props.onUpdatemoqvqlue(v)
        );
      }
    } else {
      let value = v;
      if (!value) {
        value = 0;
      }
      if (/^\d+$/.test(value)) {
        this.setState(
          { value: Number(value) },
          () => this.props.setValue(this.state.value),
          this.props.onUpdatemoqvqlue(v)
        );
      }
    }
  }

  render() {
    return (
      <div className="booking-qty-parent-wraper">
        <div className={`booking-qty ${this.props.disabled ? "disabled" : ""}`}>
          <button onClick={() => this.decrement()}>-</button>
          <input
            onMouseLeave={(e) => this.onInputChange(e.target.value)}
            onBlur={(e) => this.onInputChange(e.target.value)}
            max={
              this.props.selectedType === "vf"
                ? this.props.vf_inventory
                : 99999999
            }
            value={this.state.value}
            onChange={(e) => this.setState({ value: e.target.value })}
          />
          <button onClick={() => this.increment()}>+</button>
        </div>
      </div>
    );
  }
}

class RangePriceShow extends Component {
  state = {
    value: this.props.variantPrice.base,
  };

  moqName = {
    "Via Air": "air_price",
    "Via Sea": "sea_price",
    Domestic: "domestic_price",
    Sample: "sample_price",
  };

  moqRangeArray = this.props.product.moq_sourcing_table2;

  findIndexByMoq(entries, targetMoq) {
    let closestIndex = -1;
    let minDifference = Infinity;

    entries.forEach((entry, index) => {
      const difference = Math.abs(entry.moq - targetMoq);
      if (difference < minDifference) {
        minDifference = difference;
        closestIndex = index;
      }
    });

    return closestIndex;
  }

  componentDidUpdate(pp) {
    if (
      pp.selectedType !== this.props.selectedType ||
      pp.moqIndex !== this.props.moqIndex
    ) {
      // this.setState({ value: 0 }, () => {
      //   this.onInputChange(this.state.value);
      // });

      // if (this.props.selectedType === "vf") {
      //   this.setState({ value: 0 }, () => {
      //     // this.onInputChange(this.props.vf_inventory);
      //     this.onInputChange(this.props.variantPrice.base);
      //   });
      // } else if (this.props.selectedType === "retail") {
      //   this.setState({ value: 0 }, () => {
      //     this.onInputChange(1);
      //   });
      // } else {
      //   this.setState({ value: 0 }, () => {
      //     this.onInputChange(
      //       this.props.product.moq_sourcing_table[0].range.split("-")[0]
      //     );
      //   });
      // }

      try {
        console.log(
          "rrrrrrrrr",
          this.findIndexByMoq(
            this.moqRangeArray[this.moqName[this.props.selectedType]],
            this.props.quantity
          )
        );

        let quantityIndex = this.findIndexByMoq(
          this.moqRangeArray[this.moqName[this.props.selectedType]],
          this.props.quantity
        );

        if (
          this.props.product.moq_sourcing_table2 &&
          this.props.product.moq_sourcing_table2[
            this.moqName[this.props.selectedType]
          ]
        ) {
          this.onInputChange(
            this.props.product.moq_sourcing_table2[
              this.moqName[this.props.selectedType]
            ][quantityIndex]["total"]
          );
        }
      } catch (error) {}
    }
  }

  componentDidMount() {
    if (!!this.props.product && this.props.index === 0) {
      try {
        console.log(
          "rrrrrrrrr",
          this.findIndexByMoq(
            this.moqRangeArray[this.moqName[this.props.selectedType]],
            this.props.quantity
          )
        );

        let quantityIndex = this.findIndexByMoq(
          this.moqRangeArray[this.moqName[this.props.selectedType]],
          this.props.quantity
        );

        if (this.props.product.moq_sourcing_table2) {
          this.onInputChange(
            this.props.product.moq_sourcing_table2[
              this.moqName[this.props.selectedType]
            ][quantityIndex]["total"]
          );
        }
      } catch (error) {}
    }
  }

  onInputChange(v) {
    if (
      this.props.selectedType === "vf" &&
      Number(v) > Number(this.props.vf_inventory)
    ) {
      let value = v;
      if (!value) {
        value = 0;
      }
      if (/^\d+$/.test(this.props.vf_inventory)) {
        this.setState({ value: Number(this.props.vf_inventory) });
      }
    } else {
      let value = v;
      if (!value) {
        value = 0;
      }
      if (/^\d+$/.test(value)) {
        this.setState({ value: Number(value) });
      }
    }
  }

  render() {
    return (
      <div className="air-days">
        {getCurrencySymbol(this.props.currency)}{" "}
        {formatNumber(this.state.value)}
      </div>
    );
  }
}

class VariantSelector extends Component {
  state = {
    variants: {},
    selectedVariant: -1,
    freightData: "",
  };

  svgRef = React.createRef();

  handleCheckboxChange = (event) => {
    this.props.onUpdate(event);
    this.setState({ freightData: event });
    this.props.updateFreightData(event);
  };

  componentDidMount() {
    const variants = {};
    this.props.variants.forEach((v) => (variants[v.vfsku] = 0));
    this.setState({ variants });
  }

  setValue(sku, value) {
    console.log("sssssss", sku, value);

    this.setState(
      (state) => ({
        variants: {
          ...state.variants,
          [sku]: value,
        },
      }),
      () => this.props.updateSelectedVariants(sku, Number(value))
    );
  }

  setSelectedVariant(index) {
    this.setState({ selectedVariant: index }, () =>
      this.props.setSelectedImage(
        this.props.cr.length -
          (this.props.variants.filter((f) => f.default === 1).length - index)
      )
    );
  }

  transitTimeCalc(status) {
    if (status === "") {
      return "N/A";
    }

    if (status === "vf") {
      return "0 days";
    }

    if (status === "air") {
      return "8-10 days";
    }
    if (status === "sea") {
      return "30-40 days";
    }
  }

  render() {
    const variants = this.props.variants || [];
    const currency = this.props.currency;
    return (
      <>
        {!!this.props.errorText ? (
          <div id="fade-in">
            <Alert message={`${this.props.errorText}`} type="error" showIcon />
          </div>
        ) : null}
        <div className="raise-bulk-order-pricing-table-wraper">
          <div ref={this.svgRef} className="pricing-wraper">
            <div className="table-sub-headings">
              <div className="variants">Variant Details</div>
              {/* <div className="air-days">Price/Qty</div> */}
              <div className="booking-qty">Booking Qty</div>
            </div>

            {/* table row */}

            {variants.map((variant, index) => {
              const variantPrice = this.props.pricing[variant.vfsku];
              if (variantPrice <= 0) {
                return null;
              }
              return (
                <div
                  onClick={() => this.setSelectedVariant(index)}
                  className="table-row-data"
                >
                  <div className="variants-details">
                    <div className="img">
                      <img
                        src={variant.images[0]}
                        alt={`variant ${variant.vfsku}`}
                      />
                    </div>{" "}
                    <div className="sku-details">
                      <div className="sku">
                        <p>SKU:</p>{" "}
                        <Tooltip title="Variant ID"> {variant.vfsku}</Tooltip>{" "}
                      </div>
                      <div className="specs">
                        <p>Specs:</p>{" "}
                        {variant.allspecs.length > 14 ? (
                          <Tooltip title={variant.allspecs}>
                            {" "}
                            <span>{variant.allspecs.slice(0, 14) + "…"}</span>
                          </Tooltip>
                        ) : (
                          <span>{variant.allspecs}</span>
                        )}{" "}
                      </div>
                    </div>
                  </div>

                  {/* <RangePriceShow
                    moqIndex={this.props.moqIndex}
                    quantity={this.props.quantity}
                    currency={currency}
                    variantPrice={variantPrice}
                    product={this.props.product}
                    selectedType={this.props.freightData}
                    vf_inventory={variant.vf_inventory}
                  /> */}

                  {this.props.freightData === "" ? (
                    <Tooltip
                      title={
                        <p className="small-tooltip-text">
                          Please “Select Inventory Freight Type” first to be
                          able to Book Bulk Quantity.
                        </p>
                      }
                    >
                      <QuantityPicker
                        freightData={this.props.freightData}
                        moqIndex={this.props.moqIndex}
                        bulkSample={this.props.bulkSample}
                        product={false}
                        index={index}
                        selectedType={this.props.freightData}
                        vf_inventory={variant.vf_inventory}
                        disabled={true}
                        setValue={(value) =>
                          this.setValue(variant.vfsku, value)
                        }
                        onUpdatemoqvqlue={this.props.onUpdatemoqvqlue}
                      />
                    </Tooltip>
                  ) : (
                    <QuantityPicker
                      freightData={this.props.freightData}
                      moqIndex={this.props.moqIndex}
                      bulkSample={this.props.bulkSample}
                      index={index}
                      product={this.props.product}
                      selectedType={this.props.freightData}
                      vf_inventory={variant.vf_inventory}
                      disabled={false}
                      setValue={(value) => this.setValue(variant.vfsku, value)}
                      onUpdatemoqvqlue={(e) => this.props.onUpdatemoqvqlue(e)}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default VariantSelector;
