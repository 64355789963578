import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Skeleton, message, Modal } from "antd";
import BasicSettings from "./BasicSettings";
import RTOSettings from "./RTOSettings";
import SettingNav from "./SettingNav";
import ChannelBranding from "./ChanelBranding";
// import GlobalPricingRules from "./GlobalPricingRules";

// import OtherSettings from "./OtherSettings";
// import BankSettings from "./BankSettings";
import axios from "axios";
import { hasPermission } from "../../../Helpers/Permissions";
import ZoomModal from "./ZoomModal";
// import Support from "./Support";
import "./index.scss";
import PincodeDownload from "./PincodeDownload";
import OrderConfirmation from "./OrderConfrmation";
import OTPVerify from "./OTPVerify";
const { confirm } = Modal;
// const TabPane = Tabs.TabPane;

class Settings extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: "",
    currentlyActiveTab: "basic",
    settings: {
      pricing: {
        type: "basic",
        product_cost: {
          type: "multiplier",
          data: 2,
        },
        compared_at_price: {
          type: "multiplier",
          data: 2,
        },
        advanced_pricing: [
          {
            start: "",
            end: "",
            product_cost_type: "multiplier",
            product_cost_data: 2,
            compared_at_price_type: "multiplier",
            compared_at_price_data: 2,
          },
          {
            start: "",
            end: "",
            product_cost_type: "multiplier",
            product_cost_data: 2,
            compared_at_price_type: "multiplier",
            compared_at_price_data: 2,
          },
          {
            start: "",
            end: "",
            product_cost_type: "multiplier",
            product_cost_data: 2,
            compared_at_price_type: "multiplier",
            compared_at_price_data: 2,
          },
        ],
        assign_cents: {
          on: false,
          selling_price_cents: 99,
          comparedat_price_cents: 99,
        },
      },
      basic: {
        productPriceInput: null,
        productPriceChoice: "Multiplier",
        comparedAtPriceInput: null,
        comparedAtPriceChoice: "Multiplier",
        advanced: false,
        assignCents: false,
        sellingPriceCents: null,
        comparedAtPriceCents: null,
      },
      rto: {
        buyback: 1,
      },
      other: {
        email: null,
        timeZone: "(GMT+00:00) Bamaka",
      },
    },
    originalSettings: {},
    formDirty: {
      pricing: false,
      basic: false,
      other: false,
      bank: false,
    },
    activeTab: "settings",
    chnagenumber: false,
    changeemail: false,
    ChangeUrl: false,
    files: [],
    imagePreviewUrl: [],
    brandSettings: [],
    zoomModal: {
      visible: false,
    },

    files2: [],
    order_confirmation_management_setting: {},
  };

  toggleZoomModal(src, type) {
    this.setState((state) => ({
      zoomModal: {
        ...state.zoomModal,
        src,
        type,
        visible: !state.zoomModal.visible,
      },
    }));
  }

  handleImageChange(e) {
    e.preventDefault();
    let files = Array.from(e.target.files);
    files.forEach((file) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          files: file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
    });
  }

  deletelimage() {
    this.setState({
      imagePreviewUrl: [],
      files: [],
    });
  }
  fileimagedelte(files) {
    this.setState({
      files,
    });
  }

  buildImgTag() {
    return (
      <div
        className="photo-container"
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        <div style={{ position: "relative" }}>
          <img
            key="1"
            style={{
              width: 135,
              height: 110,
              objectFit: "cover",
              borderRadius: 2,
              border: "1px solid #D0D0D0",
              cursor: "pointer",
              marginRight: "8px",
              marginTop: "0px",
            }}
            className="photo-uploaded"
            src={this.state.imagePreviewUrl}
            alt="upload"
            onClick={() =>
              this.toggleZoomModal(this.state.imagePreviewUrl, "image")
            }
          />

          <p
            key="1"
            style={{
              color: "#F12F1A",
              cursor: "pointer",
              position: "absolute",
              width: "16px",
              height: "16px",
              top: "4px",
              left: "111px",
            }}
            onClick={() => this.deletelimage()}
          >
            <p style={{ margin: "4px 0 0 0" }}>
              <svg
                className="crossican"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  id="Path_99"
                  data-name="Path 99"
                  d="M8,0A8,8,0,1,1,0,8,8,8,0,0,1,8,0Z"
                  fill="#898989"
                />
                <path
                  id="close"
                  d="M4.733,4.136,7.848,1.021A.518.518,0,1,0,7.115.288L4,3.4.885.288a.518.518,0,0,0-.733.733L3.267,4.136.152,7.251a.518.518,0,1,0,.733.733L4,4.869,7.115,7.984a.518.518,0,0,0,.733-.733Zm0,0"
                  transform="translate(4 3.864)"
                  fill="#fff"
                />
              </svg>
            </p>
          </p>
        </div>
      </div>
    );
  }

  handleContactChange(value) {
    this.setState({
      contactno: value,
    });
  }

  changeEmail() {
    this.setState({
      changeemail: !this.state.changeemail,
    });
  }
  chnageEmial2() {
    this.setState({
      changeemail: false,
    });
  }

  changeUrl() {
    this.setState({
      ChangeUrl: !this.state.ChangeUrl,
    });
  }

  ChnageNumber() {
    this.setState({
      chnagenumber: !this.state.chnagenumber,
    });
  }

  ChnageNumber2() {
    this.setState({
      chnagenumber: false,
    });
  }

  tabHandler(tab) {
    this.setState({ activeTab: tab });
  }

  loadSettting() {
    this.setState({ loading: true }, () =>
      axios({
        url: process.env.REACT_APP_API_URL + "/store/get_settings",
        method: "post",
        data: {
          storeid: this.props.match.params.storeid,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.setState({
              settings: res.data.settings,
              loading: false,
              brandSettings: res.data.brand_settings,
              order_confirmation_management_setting:
                res.data.order_confirmation_management_setting,
            });
          } else {
            this.setState({
              error: true,
              errorMsg: res.data.msg,
              loading: false,
            });
          }
        })
        .catch((e) => {
          this.setState({
            error: true,
            errorMsg: e.message,
            loading: false,
          });
        })
    );
  }

  componentDidMount() {
    this.loadSettting();
    if (window.location.href.includes("from_order")) {
      this.setState({
        activeTab: "channelbranding",
      });
    }
  }

  changeSettingTab(tab) {
    if (this.state.formDirty[this.state.currentlyActiveTab]) {
      const that = this;
      confirm({
        title: "You have unsaved changes?",
        content:
          "If you continue, all unsaved changes will be lost. Do you want to continue?",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          that.setState({
            formDirty: {
              ...that.state.formDirty,
              [that.state.currentlyActiveTab]: false,
            },
            currentlyActiveTab: tab,
          });
        },
        onCancel() {},
      });
    } else {
      this.setState({
        currentlyActiveTab: tab,
      });
    }
  }

  markFormDirty(type) {
    this.setState({
      formDirty: { ...this.state.formDirty, [type]: true },
    });
  }

  onUpdate(type, settings) {
    if (!hasPermission(this.props.auth.store, "modify_settings")) {
      message.error("You are not authotized to update settings!");
      return;
    }
    axios({
      url: process.env.REACT_APP_API_URL + "/store/update_settings",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        settings_type: type,
        settings_data: settings,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            settings: {
              ...this.state.settings,
              [type]: settings,
            },
            formDirty: { ...this.state.formDirty, [type]: false },
          });
          message.success("Updated Successfully");
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  }

  render() {
    return (
      <div className="settings-page">
        <SettingNav
          loading={this.state.loading}
          tabHandler={(e) => this.tabHandler(e)}
          activeTab={this.state.activeTab}
          history={this.props.history}
          match={this.props.match}
        />

        {this.state.loading ? (
          <Skeleton active />
        ) : (
          <>
            <ChannelBranding
              chnagenumber={this.state.chnagenumber}
              changeemail={this.state.changeemail}
              ChnageNumbertoggle={() => this.ChnageNumber()}
              chnageEmailtoggle={() => this.changeEmail()}
              ChangeUrl={this.state.ChangeUrl}
              changeUrltoggle={() => this.changeUrl()}
              handleImageChange={(e) => this.handleImageChange(e)}
              imagePreviewUrl={this.state.imagePreviewUrl}
              files={this.state.files}
              buildImgTag={() => this.buildImgTag()}
              brandSettings={this.state.brandSettings}
              toggleZoomModal={(src, type) => this.toggleZoomModal(src, type)}
              storeid={this.props.match.params.storeid}
              // uplaodImage={() => this.uplaodImage()}
              loadSettting={() => this.loadSettting()}
              chnageEmial2={() => this.chnageEmial2()}
              ChnageNumber2={() => this.ChnageNumber2()}
            />
            <div className="abc">
              <Card>
                <BasicSettings
                  pincodes_updated_on={this.state.settings.pincodes_updated_on}
                  settings={this.state.settings.basic}
                  onUpdate={(settings) => this.onUpdate("basic", settings)}
                  onSettingChange={() => this.markFormDirty("basic")}
                  storegeo={this.props.match.params.storegeo}
                />

                {/* {this.props.match.params.storegeo === "global" ? null : (
                    <RTOSettings
                      settings={this.state.settings.rto}
                      onUpdate={(settings) => this.onUpdate("rto", settings)}
                      onSettingChange={() => this.markFormDirty("rto")}
                      storegeo={this.props.match.params.storegeo}
                    />
                  )} */}
              </Card>
            </div>
            <div className="abc">
              <Card>
                <OrderConfirmation
                  data={
                    this.state.order_confirmation_management_setting
                  }
               
                />
              </Card>
              <Card>
                <OTPVerify
                  data={
                    this.state.order_confirmation_management_setting
                  }
               
                />
              </Card>
            </div>
            <div className="abc">
              <Card>
                <PincodeDownload
                  pincodes_updated_on={this.state.settings.pincodes_updated_on}
                  pincodes_moderate_updated_on={
                    this.state.settings &&
                    this.state.settings.pincodes_moderate_updated_on
                  }
                  pincodes_highrisk_updated_on={
                    this.state.settings &&
                    this.state.settings.pincodes_highrisk_updated_on
                  }
                />

                {/* {this.props.match.params.storegeo === "global" ? null : (
                    <RTOSettings
                      settings={this.state.settings.rto}
                      onUpdate={(settings) => this.onUpdate("rto", settings)}
                      onSettingChange={() => this.markFormDirty("rto")}
                      storegeo={this.props.match.params.storegeo}
                    />
                  )} */}
              </Card>
            </div>

            <ZoomModal
              {...this.state.zoomModal}
              toggleModal={() => this.toggleZoomModal()}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(Settings);
