import React, { Component } from "react";
import {
  Row,
  Skeleton,
  Alert,
  Table,
  Switch,
  message,
  Icon,
  Tooltip,
  Pagination,
} from "antd";
import axios from "axios";
import moment from "moment";
import "./index.scss";
import { connect } from "react-redux";
import { updateStoreStatus } from "../../../store/actions/authActions";
import AddStoreModal from "../Common/AddStoreModal";
import Managestornav from "./Storenav";
import StoreGeoDropdown from "./StoreGeoDropdown";
import { ReactComponent as GeoIn } from "../../../assets/images/icons/india.svg";
import { ReactComponent as GeoGlobal } from "../../../assets/images/icons/global.svg";

class StoreListing extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: "",
    stores: [],
    pagination: {
      showSizeChanger: true,
      page: 1,
      pageSize: 10,
      total: 0,
    },
    addStoreModal: {
      visible: false,
    },
    filters: {
      search: "",
    },
  };

  toggleAddStoreModal() {
    this.setState({
      addStoreModal: {
        visible: !this.state.addStoreModal.visible,
      },
    });
  }

  loadData() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/store/store_listing",
          method: "post",
          data: {
            storeid: this.props.store.id,
            page: this.state.pagination.page,
            pageSize: this.state.pagination.pageSize,
            filters: this.state.filters,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                stores: res.data.data,
                pagination: { ...this.state.pagination, total: res.data.total },
                loading: false,
                error: false,
                errorMsg: "",
              });
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
                loading: false,
              });
            }
          })
          .catch((e) => {
            this.setState({
              error: true,
              errorMsg: e.message,
              loading: false,
            });
          });
      }
    );
  }
  changeHandler(kvpairs) {
    this.setState({
      filters: { ...this.state.filters, ...kvpairs },
    });
  }

  changeFilter(kvpairs) {
    this.setState(
      {
        filters: { ...this.state.filters, ...kvpairs },
        pagination: { ...this.state.pagination, page: 1 },
      },
      () => this.loadData()
    );
  }

  goToPage(page) {
    this.setState(
      {
        pagination: { ...this.state.pagination, page },
      },
      () => {
        this.loadData();
      }
    );
  }

  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        pagination: { ...this.state.pagination, pageSize: pageSize },
      },
      () => this.loadData()
    );
  }

  changeStatus(val, id, status) {
    axios({
      url: process.env.REACT_APP_API_URL + "/store/toggle_storeactive_status",
      method: "post",
      data: {
        // storeid: this.props.store.id,
        id: id,
        current_status: status,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState(
            {
              stores: this.state.stores.map((s) => {
                if (s._id["$oid"] === id) {
                  return { ...s, store_active: status === 1 ? 0 : 1 };
                } else {
                  return s;
                }
              }),
            },
            () => {
              message.success(
                `Store ${val === true ? "enabled" : "disabled"} successfully`
              );
              this.props.storeStatusUpdated(
                this.state.stores.find((s) => s._id["$oid"] === id)
              );
            }
          );
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  }
  componentDidMount() {
    this.loadData();
  }

  render() {
    const { user } = this.props;

    const PageWrapper = (children) => {
      return (
        <div className="store-listing">
          <Managestornav
            toggleAddStoreModal={() => this.toggleAddStoreModal()}
            changeFilter={(e) => this.changeFilter(e)}
            changeHandler={(e) => this.changeHandler(e)}
            filters={this.state.filters}
          />

          {children}
          <AddStoreModal
            storeid={this.props.match.params.storeid}
            visible={this.state.addStoreModal.visible}
            handleCancel={() => this.toggleAddStoreModal()}
            alignButtonsRight={true}
            user={user}
          />
        </div>
      );
    };

    if (this.state.loading) {
      return PageWrapper(
        <Row style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </Row>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <Row>
          <Alert
            message="There was an error"
            description={this.state.errorMsg}
            type="error"
          />
        </Row>
      );
    }
    const columns = [
      {
        title: "Store Name",
        dataIndex: "store_name",
        render: (_, row) => (
          <div>
            <div className="store-info">
              <p>
                {" "}
                {row && row.store_name && row.store_name.length > 25 ? (
                  <Tooltip title={row.store_name}>
                    {" "}
                    {`${row.store_name.substring(0, 20)}...`}
                  </Tooltip>
                ) : !!row.store_name ? (
                  row.store_name
                ) : (
                  "N/A"
                )}
              </p>
            </div>
          </div>
        ),
      },

      {
        title: "Channel Link",
        dataIndex: "shopify_link",

        render: (status, row) => (
          <div className="shopify-link">
            {row.shopify_link ? (
              <span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`http://${row.shopify_link}`}
                >
                  {row && row.shopify_link.length > 18 ? (
                    <Tooltip title={row.shopify_link}>
                      {" "}
                      {`${row.shopify_link.substring(0, 15)}...`}
                    </Tooltip>
                  ) : (
                    row.shopify_link
                  )}
                </a>
                <span style={{ marginRight: "8px" }}>
                  {" "}
                  <a
                    href={`http://${row.shopify_link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.259"
                      height="11.448"
                      viewBox="0 0 11.259 11.448"
                    >
                      <g
                        id="Group_3617"
                        data-name="Group 3617"
                        transform="translate(-961.362 -333.152)"
                      >
                        <path
                          id="Path_84"
                          data-name="Path 84"
                          d="M-4594.261-691.587h-1.944v10h10V-683.4"
                          transform="translate(5558.167 1025.587)"
                          fill="none"
                          stroke="#1370fc"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.2"
                        />
                        <g
                          id="Group_3178"
                          data-name="Group 3178"
                          transform="translate(965.64 334)"
                        >
                          <path
                            id="Path_85"
                            data-name="Path 85"
                            d="M-4590.015-685.789l6.133-6.2"
                            transform="translate(4590.015 691.988)"
                            fill="none"
                            stroke="#1370fc"
                            stroke-linecap="round"
                            stroke-width="1.2"
                          />
                          <path
                            id="Path_86"
                            data-name="Path 86"
                            d="M-4587.394-691.587h4.074v3.886"
                            transform="translate(4589.643 691.587)"
                            fill="none"
                            stroke="#1370fc"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.2"
                          />
                        </g>
                      </g>
                    </svg>
                  </a>
                </span>
              </span>
            ) : (
              `Not linked to`
            )}
          </div>
        ),
      },
      // {
      //   title: "Store Domain",
      //   dataIndex: "store_domain",
      //   render: (status, row) => (
      //     <div className="shopify-link">
      //       <p>
      //         {row.store_domain ? (
      //           <a
      //             target="_blank"
      //             rel="noopener noreferrer"
      //             href={`http://${row.store_domain}`}
      //           >
      //             {row && row.store_domain.length > 18 ? (
      //               <Tooltip title={row.store_domain}>
      //                 {" "}
      //                 {`${row.store_domain.substring(0, 15)}...`}
      //               </Tooltip>
      //             ) : (
      //               row.store_domain
      //             )}

      //             <span style={{ marginRight: "8px" }}>
      //               {" "}
      //               <a
      //                 href={`http://${row.store_domain}`}
      //                 target="_blank"
      //                 rel="noopener noreferrer"
      //               >
      //                 <svg
      //                   xmlns="http://www.w3.org/2000/svg"
      //                   width="11.259"
      //                   height="11.448"
      //                   viewBox="0 0 11.259 11.448"
      //                 >
      //                   <g
      //                     id="Group_3617"
      //                     data-name="Group 3617"
      //                     transform="translate(-961.362 -333.152)"
      //                   >
      //                     <path
      //                       id="Path_84"
      //                       data-name="Path 84"
      //                       d="M-4594.261-691.587h-1.944v10h10V-683.4"
      //                       transform="translate(5558.167 1025.587)"
      //                       fill="none"
      //                       stroke="#1370fc"
      //                       stroke-linecap="round"
      //                       stroke-linejoin="round"
      //                       stroke-width="1.2"
      //                     />
      //                     <g
      //                       id="Group_3178"
      //                       data-name="Group 3178"
      //                       transform="translate(965.64 334)"
      //                     >
      //                       <path
      //                         id="Path_85"
      //                         data-name="Path 85"
      //                         d="M-4590.015-685.789l6.133-6.2"
      //                         transform="translate(4590.015 691.988)"
      //                         fill="none"
      //                         stroke="#1370fc"
      //                         stroke-linecap="round"
      //                         stroke-width="1.2"
      //                       />
      //                       <path
      //                         id="Path_86"
      //                         data-name="Path 86"
      //                         d="M-4587.394-691.587h4.074v3.886"
      //                         transform="translate(4589.643 691.587)"
      //                         fill="none"
      //                         stroke="#1370fc"
      //                         stroke-linecap="round"
      //                         stroke-linejoin="round"
      //                         stroke-width="1.2"
      //                       />
      //                     </g>
      //                   </g>
      //                 </svg>
      //               </a>
      //             </span>
      //           </a>
      //         ) : (
      //           `Not linked to Shopify`
      //         )}
      //       </p>
      //     </div>
      //   ),
      // },

      // store geo column

      {
        title: "Store Geo",
        dataIndex: "store_geo_set",
        render: (status, row) => (
          <div style={{ color: "#000", width: "105px" }}>
            {row.store_geo_set === 0 ? (
              <StoreGeoDropdown
                loadData={() => this.loadData()}
                storeName={row.store_name}
                storeId={row._id.$oid}
              />
            ) : row.store_geo === "in" ? (
              <div
                className="flex"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Icon
                  component={GeoIn}
                  style={{ marginRight: "8px", fontSize: "24px" }}
                />
                <h3 style={{ fontSize: "12px" }}>India</h3>
              </div>
            ) : row.store_geo === "global" ? (
              <div
                className="flex"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Icon
                  component={GeoGlobal}
                  style={{ marginRight: "8px", fontSize: "24px" }}
                />
                <h3 style={{ fontSize: "12px" }}>Global</h3>
              </div>
            ) : null}
          </div>
        ),
      },

      {
        title: "Added On",
        dataIndex: "added_on",
        render: (status, row) => (
          <div className="shopify-link">
            <p className="add-on">
              {moment(row.added_on).format("MMM DD, YYYY")}
            </p>
          </div>
        ),
      },
      {
        title: "Store Status",
        dataIndex: "store_active",
        render: (status, row) => (
          <div className="store-status">
            <Switch
              size="small"
              onChange={(val) =>
                this.changeStatus(val, row._id["$oid"], row.store_active)
              }
              checked={status === 1 ? true : false}
            />{" "}
            <p>{status === 1 ? "Store Enabled" : "Store Disabled"}</p>
          </div>
        ),
      },
    ];

    return PageWrapper(
      <div>
        <p className="Re-verify-details">
          {" "}
          Note : Store GEO once set can not be changed. Re-verify the details.
        </p>
        <Table
          loading={this.state.loading}
          rowKey={(row, i) => row._id["$oid"]}
          columns={columns}
          dataSource={this.state.stores}
          onChange={(page) => this.goToPage(page)}
          pagination={false}
          width="500px"
        />

        <Pagination
          locale={{ jump_to: "Go to page" }}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          showSizeChanger
          showQuickJumper
          pageSizeOptions={["10", "25", "50"]}
          onShowSizeChange={(p, d) => this.onShowSizeChange(p, d)}
          current={this.state.pagination.page}
          onChange={(page) => this.goToPage(page)}
          total={this.state.pagination.total}
          defaultPageSize={this.state.pagination.pageSize}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
    user: state.auth.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    storeStatusUpdated: (store) => dispatch(updateStoreStatus(store)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreListing);
