import React, { Component } from "react";
import {
  Row,
  Card,
  Skeleton,
  Alert,
  message,
  Icon,
  Button,
  Tooltip,
  Switch,
} from "antd";
import axios from "axios";
import Variantnav from "./Viewvaraintmapnav";
import { connect } from "react-redux";
import LinkedVaraintMatchSelecter from "./LinkedVaraintMatchSelecter";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../../../../../assets/images/icons/arrow.svg";
import ViewlinkImportListModal from "./ImportListModal";
import ViewBundleModal from "./BundleModal";
import { SHOPIFY_NAME } from "../../../../../../constants";
import "./index.scss";
import FlexFullmentCard from "./FlexFullmentCard";

class ViewLinkedVariantMatching extends Component {
  state = {
    loading: true,
    shopifyProduct: [],
    aliProduct: null,
    mapping: {},
    aliData: [],
    aliArray: [],
    shopifyProductId: "",
    // visible: false,
    bundleProduct: false,
    bundleVisible: false,
    products: [],
    ExistingProductloading: true,
    paging: {
      page: 1,
      per_page_count: 10,
    },
    filters: {
      updates: "",
      linkStatus: this.props.match.params.linkStatus
        ? this.props.match.params.linkStatus
        : "",
      search: "",
    },
    linkProductModal: {
      visible: false,
      id: null,
    },

    modalLoading: false,
    productbundel: "",
  };

  componentDidMount() {
    this.getvaraintmatchingdata();
  }
  handlebuyback(ids, storeid, checked) {
    this.setState(
      {
        escalateLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/products/enable_buyback",
          method: "POST",
          data: {
            productid: ids,
            storeid: storeid,
            buyback: checked ? 1 : 0,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                escalateLoading: false,
                escalateNowButton:
                  this.state.escalateNowButton === true ? false : true,
              });

              message.success(res.data.msg);
              // this.props.loadData();
            } else {
              this.setState({
                escalateLoading: false,
              });
              message.warning(res.data.msg);
            }
          })
          .catch((err) => {
            this.setState({
              escalateLoading: false,
            });
            message.warning(err.message);
          });
      }
    );
  }
  splitUsingImportList() {
    if (this.props.match.params.aliId === null) {
      message.error("Select a product");
    } else {
      this.setState({ modalLoading: true });

      axios({
        url:
          process.env.REACT_APP_API_URL + "/products/link_product_import_list",
        method: "post",
        data: {
          storeid: this.props.match.params.storeid,
          product_mongoid: this.props.match.params.productId,
          product_aliid: this.props.match.params.aliId,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.setState({ modalLoading: false });

            this.props.history.push(
              `/${this.props.match.params.storeid}/variants/${
                this.props.match.params.aliId
              }/${this.props.match.params.productId}`
            );
          } else {
            this.setState({ modalLoading: false });
            message.error(res.data.msg);
          }
        })
        .catch((e) => {
          this.setState({ modalLoading: false });
          message.error(e.message);
        });
    }
  }

  getvaraintmatchingdata() {
    axios({
      url:
        process.env.REACT_APP_API_URL + "/products/get_variant_matching_data",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        productId: this.props.match.params.productId,
        aliId: !!this.props.location.hash.includes("#undefined")
          ? [this.props.match.params.aliId]
          : this.props.location.hash.replace("#", "").split(","),
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          // let mapping = {};
          // res.data.shopifyProduct.variants.forEach((v) => {
          //   mapping[v.variant_shopifyid] = v.skuPropIds ? v.skuPropIds : [];
          // });
          let shopifyProductId = res.data.shopifyProduct._id.$oid;
          let aliData = [];
          let x = 0;
          res.data.aliProduct.forEach((prod) => {
            prod.variants.forEach((variant) => {
              aliData[x] = {};
              aliData[x].name = prod.name;
              aliData[x].vfsku = variant.vfsku;
              aliData[x].img = variant.img;
              aliData[x].allspecs = variant.allspecs;
              x++;
            });
          });
          this.setState({
            shopifyProduct: res.data.shopifyProduct,
            aliProduct: res.data.aliProduct[0],
            aliData: aliData,
            aliArray: res.data.aliProduct,
            bundleProduct: res.data.bundle_product,
            shopifyProductId: shopifyProductId,
            // aliproduct22:res.data.aliProduct,
            // mapping: mapping,
            loading: false,
            productbundel: res.data.bundle_product,
          });
        } else {
          this.setState({
            error: true,
            errorMsg: res.data.msg,
            loading: false,
          });
        }
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMsg: e.message,
          loading: false,
        });
      });
  }

  toggleLinkProductModal(id = null) {
    this.setState({
      linkProductModal: {
        ...this.state.linkProductModal,
        id,
        visible: !this.state.linkProductModal.visible,
      },
    });
  }

  bundleToggleLinkProductModal() {
    this.setState({
      bundleVisible: !this.state.bundleVisible,
    });
  }

  submitMatchData() {
    const matchData = this.state.mapping;
    let arr = Object.values(matchData);
    for (let i = 0; i < arr.length; i++) {
      let a = arr[i].length === 0;
      if (a) {
        message.error(`Please match all ${SHOPIFY_NAME} variants`);
        return false;
      }
    }
    axios({
      url: process.env.REACT_APP_API_URL + "/products/match_product_variants",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        matchdata: this.state.mapping,
        aliid: this.props.match.params.aliId,
        product_mongoid: this.props.match.params.productId,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          message.success("Product linked successfully");
          if (this.props.location.hash === "#order") {
            this.props.history.push(
              `/${this.props.store.store_geo}/${
                this.props.match.params.storeid
              }/orders/open`
            );
          } else {
            this.props.history.push(
              `/${this.props.store.store_geo}/${
                this.props.match.params.storeid
              }/products/store-products`
            );
          }
        } else {
          message.error("There was an error");
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  }

  render() {
    const PageWrapper = (children) => {
      return (
        <div>
          <Variantnav storeway={true} bundle={this.state.productbundel} />
          {children}
        </div>
      );
    };

    if (this.state.loading) {
      return PageWrapper(
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={this.state.errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    return PageWrapper(
      <div className="product-link-variant-matching-view">
        <p className="ali-product-name">
          {" "}
          {`Linking ${SHOPIFY_NAME} Product`} {""}
          <span className="link-product-name">
            {this.state.shopifyProduct.name.length > 30 ? (
              <Tooltip title={this.state.shopifyProduct.name}>
                {`${this.state.shopifyProduct.name.substring(0, 30)}...`}
              </Tooltip>
            ) : this.state.shopifyProduct.name ? (
              this.state.shopifyProduct.name
            ) : (
              "Not Available"
            )}
          </span>{" "}
          with VF Catalog Product{" "}
          <span className="link-product-name">
            {this.state.aliProduct.name.length > 30 ? (
              <Tooltip title={this.state.aliProduct.name}>
                {`${this.state.aliProduct.name.substring(0, 30)}...`}
              </Tooltip>
            ) : this.state.aliProduct.name ? (
              this.state.aliProduct.name
            ) : (
              "Not Available"
            )}
          </span>
        </p>
        {/* <div className="flexible-fullfillment-bar">
          <div>Flexible Fulfilment status</div>
          <Switch
            // checked={this.state.escalateNowButton === true ? 1 : 0}
            // onChange={(checked) =>
            //   this.handlebuyback(
            //     product._id["$oid"],
            //     product.product_storeid.$oid,
            //     checked
            //   )
            // }
            style={{
              color: "#000",
              width: "30px",
              marginLeft: "8px",
            }}
            // size="small"
          />
        </div> */}
        <Card>
          <>
            <div className="header-txt">
              <p> {`Product Variants In ${SHOPIFY_NAME}`}</p>
              <p> VF Product Variant in Import List</p>
            </div>
            {this.state.shopifyProduct.variants.map((sv) =>
              sv.variant_shopifyid !== undefined ? (
                <div className="view-varaint-matching-data" key={sv.vskuid}>
                  <div className="left-varaint-image-data">
                    <img src={sv.img ? sv.img : null} alt="#" />
                    <span className="image-allspecs">{sv.allspecs}</span>
                  </div>

                  <LinkedVaraintMatchSelecter
                    shopifyProduct={this.state.shopifyProduct}
                    aliProduct={this.state.aliProduct}
                    aliData={this.state.aliData}
                    bundleProduct={this.state.bundleProduct}
                    variant={sv}
                    variants={
                      sv.bundle === undefined
                        ? this.state.aliProduct.variants.filter((x) => {
                            return x.skuPropIds == sv.skuPropIds;
                          })
                        : this.state.aliProduct.variants.filter((x) => {
                            return (
                              sv.bundle.map((x) => x.vfsku).indexOf(x.vfsku) !==
                              -1
                            );
                          })
                    }
                  />
                </div>
              ) : null
            )}
          </>
        </Card>
        <div className="cantainer-flexible-data">
          <div>Flexible Fulfilment </div>
          <hr />
          <div className="heading-flexble-fullfillment">
            <div className="item">Product Name</div>
            <div className="item">Flexible Fulfilment Status</div>
          </div>
          {this.state.aliArray.map((product) => {
            return (
              <FlexFullmentCard
                key={product._id.$oid}
                id={product.productId}
                image={product.variants[0].img}
                name={product.name}
                shopifyProductId={this.state.shopifyProductId}
                flexible_fullfillment_on={product.flexible_fullfillment_on}
              />
            );
          })}
        </div>

        <div className="back-store-product-link">
          <Link
            to={`/${this.props.store.store_geo}/${
              this.props.match.params.storeid
            }/products/store-products`}
            className="backBtn"
          >
            <Icon
              className={`arrow ${this.state.arrow ? "arrow-fill" : null}`}
              component={Arrow}
              style={{
                fontSize: "16px",
              }}
            />
            Back To Store Products
          </Link>
          {!!this.state.aliProduct.provisional &&
            this.state.aliProduct.provisional === true && (
              <Button
                className="clickable"
                onClick={() => this.toggleLinkProductModal()}
              >
                Edit Variant Mapping
              </Button>
            )}

          {!!this.state.aliProduct.provisional &&
            this.state.aliProduct.provisional === true &&
            this.state.productbundel && (
              <Button
                className="clickable"
                onClick={() => this.bundleToggleLinkProductModal()}
              >
                Edit Variant Mapping
              </Button>
            )}
        </div>

        <div className="view-varaint-matching-data">
          <ViewlinkImportListModal
            visible={this.state.linkProductModal.visible}
            onCancel={() => this.toggleLinkProductModal()}
            storeid={this.props.match.params.storeid}
            sv={this.state.shopifyProduct}
            onConfirm={() => this.splitUsingImportList()}
            aliId={this.props.match.params.aliId}
          />
        </div>

        <ViewBundleModal
          bundleVisible={this.state.bundleVisible}
          onCancel={() => this.bundleToggleLinkProductModal()}
          storeid={this.props.match.params.storeid}
          aliId={this.props.match.params.aliId}
          productId={this.props.match.params.productId}
          history={this.props.history}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  store: state.auth.auth.store,
});
export default connect(mapStateToProps)(ViewLinkedVariantMatching);
