import React, { Component } from "react";
import "./index.scss";
import axios from "axios";
import {
  Select,
  DatePicker,
  Icon,
  message,
  Dropdown,
  Menu,
  Button,
} from "antd";
import { ReactComponent as PaymentStatusIcon } from "../../../../assets/images/icons/payment_status_icon.svg";
import { ReactComponent as ReadyToShipIcon } from "../../../../assets/images/icons/readytoship.svg";
import { ReactComponent as InTransitShipmentIcon } from "../../../../assets/images/icons/inTransitShipments.svg";
import { ReactComponent as DeliveredShipmentIcon } from "../../../../assets/images/icons/deliveredShipments.svg";
import { ReactComponent as ReturnedShipmentIcon } from "../../../../assets/images/icons/returnedShipments.svg";
import { ReactComponent as CircleCross } from "../../../../assets/images/icons/circle-cross.svg";
import moment from "moment";
import Sorter from "../../Common/Sorter";
import Globalsearch from "../../Common/Globalsearch";
import { SHOPIFY_NAME } from "../../../../constants";
import VideoTooltip from "../../Common/VideoTooltip";
const { Option } = Select;
const { RangePicker } = DatePicker;
class ShipmentOrderNav extends Component {
  state = {
    intransitbtn: false,
    tobeshipedbtn: true,
    deliveredbtn: false,
    returnedbtn: false,
    allShippedBtn: false,
    rtointransitbtn: false,
    outForDelivereryBtn: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.showAll !== this.props.showAll) {
      this.setState({
        allShippedBtn: this.props.showAll ? true : false,
        tobeshipedbtn: false,
      });

      return;
    }

    if (prevProps.showRTO !== this.props.showRTO) {
      this.setState({
        rtointransitbtn: this.props.showRTO ? true : false,
        tobeshipedbtn: false,
      });

      return;
    }
  }
  handleMenuClick(e) {
    this.props.togglebulksearchaction();
  }

  render() {
    const menu = (
      <Menu onClick={() => this.handleMenuClick()} className="bulk-search-menu">
        <Menu.Item key="1">Bulk Search</Menu.Item>
      </Menu>
    );
    return (
      <div className="Shipment_order_data fullorder_data ">
        <div className=" shipmentnav ">
          <div className="ordfill">
            <div className="ordfillwrap">
              <div
                className="left breadcurmb-txt"
                style={{ marginLeft: "7px" }}
              >
                <div
                  style={{
                    color: "#000",
                    fontSize: "12px",
                    letterSpacing: "0.032em",
                    lineHeight: "24px",
                  }}
                >
                  Shipments
                </div>
                <VideoTooltip
                  title="Shipments"
                  subtitle="Track all your Shipments Statuses along with their journey details."
                  contentHeadings={[
                    "Ready to ship",
                    "In-Transit",
                    "RTO In-Transit",
                    "Delivered",
                    "Returned",
                  ]}
                  content={[
                    "Shipments that are packed and ready to be picked up by the courier person from vFulfill warehouse.",
                    "A shipment is said to be In-transit, from the time an order is picked up from vFulfill Warehouse until it is either Delivered or returned back to vFulfill warehouse.",
                    "Shipments that are marked as returned but are yet to reach vFulfill warehouse.",
                    "Shipments that are successfully delivered to end customer. ",
                    "Shipments that are delivered back to vFulfill Warehouse.",
                  ]}
                  thumbnail="https://d24fzeiqvvundc.cloudfront.net/catalog/images/d074df1e4090be2409fd68b28a7c6234.png"
                  videolink="https://res.cloudinary.com/dxfu98xzs/video/upload/v1671512639/Shipments_Journey_-_Final_1_xrtmao.mp4"
                />
              </div>
              <div className="right">
                <div className="store-filters">
                  <>
                    {/* <div
                      style={{
                        display: "flex",
                        float: "left",
                        alignItems: "center",
                      }}
                    >
                      <p className="dispute-status">Filter By: </p>

                      <Select
                        // className=""
                        style={{ fontWeight: 500, fontSize: 12 }}
                        value={this.props.filters.dateType}
                        onChange={(val) =>
                          this.props.changeFilter({ dateType: val })
                        }
                        onDropdownVisibleChange={(val) =>
                          this.setState({ arrow1: val })
                        }
                        suffixIcon={
                          <svg
                            style={{
                              transition: "all 0.5s",
                              transform: `${
                                this.state.arrow1
                                  ? "rotateX(180deg)"
                                  : "rotateX(0deg)"
                              }`,
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="8"
                            viewBox="0 0 16 8"
                          >
                            <g
                              id="Polygon_49"
                              data-name="Polygon 49"
                              transform="translate(16 8) rotate(180)"
                              fill={`${this.state.arrow1 ? "#898989" : "none"}`}
                            >
                              <path d="M8,0l8,8H0Z" stroke="none" />
                              <path
                                d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                                stroke="none"
                                fill="#898989"
                              />
                            </g>
                          </svg>
                        }
                      >
                        <Option value="shopify">Shopify Order Date</Option>
                        <Option value="vf">vF Order Date</Option>
                      </Select>
                    </div> */}
                    <Sorter
                      sorterFunction={(e) => this.props.sorterFunction(e)}
                      sortingOrder={this.props.sort}
                    />
                  </>
                </div>
                {/* <div style={{ color: "#898989" }}>|</div> */}
                <p className="show_data_from" style={{ marginLeft: "8px" }}>
                  Show Data From:{" "}
                </p>
                <div ref={this.props.calenderRef}>
                  {" "}
                  <RangePicker
                    disabledDate={(current) => {
                      return current > moment();
                    }}
                    style={{ marginLeft: "10px", maxWidth: "300px" }}
                    value={[
                      this.props.filters.startDate === "" ||
                      this.props.filters.startDate === null
                        ? ""
                        : moment(this.props.filters.startDate, "YYYY-MM-DD"),
                      this.props.filters.endDate === "" ||
                      this.props.filters.startDate === null
                        ? ""
                        : moment(this.props.filters.endDate, "YYYY-MM-DD"),
                    ]}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Week": [
                        moment().startOf("week"),
                        moment().endOf("week"),
                      ],
                      "Last 15 Days": [moment().subtract("days", 15), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      "Last Month": [
                        moment()
                          .subtract(1, "month")
                          .startOf("month"),
                        moment()
                          .subtract(1, "month")
                          .endOf("month"),
                      ],
                      "Last Year": [
                        moment()
                          .subtract(1, "year")
                          .startOf("year"),
                        moment()
                          .subtract(1, "year")
                          .endOf("year"),
                      ],
                    }}
                    onChange={(_, dateString) =>
                      this.props.rangePickerChange({
                        startDate: dateString[0],
                        endDate: dateString[1],
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="secondwrap">
              <div className="filtertab">
                {/* To be shipped tab */}

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-shipment-underline-animation "
                      : this.state.tobeshipedbtn
                      ? "tab  hover-shipment-underline-animation-click"
                      : "tab hover-shipment-underline-animation"
                  }
                >
                  <button
                    className={this.state.tobeshipedbtn ? "active" : ""}
                    value=""
                    onClick={() => {
                      this.setState({
                        intransitbtn: false,
                        tobeshipedbtn: true,
                        deliveredbtn: false,
                        returnedbtn: false,
                        allShippedBtn: false,
                        rtointransitbtn: false,
                        outForDelivereryBtn: false,
                      });
                      this.props.changeFilter({
                        shipmentStatus: [],
                        startDate: this.props.filters.startDate,
                        endDate: this.props.filters.endDate,
                        dateType: "shopify",
                        search: "",
                        fulfillmentStatus: [],
                        paymentStatus: [],
                        vfOrderStatus: [],
                        pagename: [],
                      });
                    }}
                  >
                    Ready To Ship
                  </button>

                  <div
                    className={` ${
                      this.state.tobeshipedbtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.shipments &&
                    this.props.shipmentcount &&
                    this.props.shipmentcount.to_be_shipped_order
                      ? this.props.shipmentcount.to_be_shipped_order > 1000
                        ? (
                            Number(
                              this.props.shipmentcount.to_be_shipped_order
                            ) / 1000
                          ).toFixed(1) + "k"
                        : this.props.shipmentcount.to_be_shipped_order < 1000
                        ? this.props.shipmentcount.to_be_shipped_order
                        : null
                      : this.props.shipmentcount.to_be_shipped_order === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-shipment-underline-animation "
                      : this.state.intransitbtn
                      ? "tab  hover-shipment-underline-animation-click"
                      : "tab hover-shipment-underline-animation"
                  }
                >
                  <button
                    className={this.state.intransitbtn ? "active" : ""}
                    value=""
                    onClick={() => {
                      this.setState({
                        intransitbtn: true,
                        deliveredbtn: false,
                        returnedbtn: false,
                        tobeshipedbtn: false,
                        allShippedBtn: false,
                        rtointransitbtn: false,
                        outForDelivereryBtn: false,
                      });
                      this.props.changeFilter({
                        shipmentStatus: [],
                        startDate: this.props.filters.startDate,
                        endDate: this.props.filters.endDate,
                        dateType: "shopify",
                        search: "",
                        fulfillmentStatus: [],
                        paymentStatus: [],
                        vfOrderStatus: [],
                        pagename: ["in_transit"],
                      });
                    }}
                  >
                    In-Transit
                  </button>

                  <div
                    className={` ${
                      this.state.intransitbtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.shipments &&
                    this.props.shipmentcount &&
                    this.props.shipmentcount.in_transit_order
                      ? this.props.shipmentcount.in_transit_order > 1000
                        ? (
                            Number(this.props.shipmentcount.in_transit_order) /
                            1000
                          ).toFixed(1) + "k"
                        : this.props.shipmentcount.in_transit_order < 1000
                        ? this.props.shipmentcount.in_transit_order
                        : null
                      : this.props.shipmentcount.in_transit_order === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>
                {/* {out for delivery} */}
                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-shipment-underline-animation "
                      : this.state.outForDelivereryBtn
                      ? "tab  hover-shipment-underline-animation-click"
                      : "tab hover-shipment-underline-animation"
                  }
                >
                  <button
                    className={this.state.outForDelivereryBtn ? "active" : ""}
                    value="rto"
                    onClick={() => {
                      this.setState({
                        intransitbtn: false,
                        deliveredbtn: false,
                        returnedbtn: false,
                        tobeshipedbtn: false,
                        allShippedBtn: false,
                        rtointransitbtn: false,
                        outForDelivereryBtn: true,
                      });
                      this.props.changeFilter({
                        shipmentStatus: [],
                        startDate: this.props.filters.startDate,
                        endDate: this.props.filters.endDate,
                        dateType: "shopify",
                        search: "",
                        fulfillmentStatus: [],
                        paymentStatus: [],
                        vfOrderStatus: [],
                        pagename: ["out_for_delivery"],
                      });
                    }}
                  >
                    Out For Delivery
                  </button>

                  <div
                    className={` ${
                      this.state.outForDelivereryBtn
                        ? "backgroundcircle"
                        : "circle"
                    }`}
                  >
                    {this.props.shipments &&
                    this.props.shipmentcount &&
                    this.props.shipmentcount.out_for_delivery
                      ? this.props.shipmentcount.out_for_delivery > 1000
                        ? (
                            Number(this.props.shipmentcount.out_for_delivery) /
                            1000
                          ).toFixed(1) + "k"
                        : this.props.shipmentcount.out_for_delivery < 1000
                        ? this.props.shipmentcount.out_for_delivery
                        : null
                      : this.props.shipmentcount.out_for_delivery === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>
                {/* Rto_intransit */}
                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-shipment-underline-animation "
                      : this.state.rtointransitbtn
                      ? "tab  hover-shipment-underline-animation-click"
                      : "tab hover-shipment-underline-animation"
                  }
                >
                  <button
                    className={this.state.rtointransitbtn ? "active" : ""}
                    value="rto"
                    onClick={() => {
                      this.setState({
                        intransitbtn: false,
                        deliveredbtn: false,
                        returnedbtn: false,
                        tobeshipedbtn: false,
                        allShippedBtn: false,
                        rtointransitbtn: true,
                        outForDelivereryBtn: false,
                      });
                      this.props.changeFilter({
                        shipmentStatus: ["rto_in_transit"],
                        startDate: this.props.filters.startDate,
                        endDate: this.props.filters.endDate,
                        dateType: "shopify",
                        search: "",
                        fulfillmentStatus: [],
                        paymentStatus: [],
                        vfOrderStatus: [],
                        pagename: ["rto_in_transit"],
                      });
                    }}
                  >
                    RTO In-Transit
                  </button>

                  <div
                    className={` ${
                      this.state.rtointransitbtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.shipments &&
                    this.props.shipmentcount &&
                    this.props.shipmentcount.rto_in_transit
                      ? this.props.shipmentcount.rto_in_transit > 1000
                        ? (
                            Number(this.props.shipmentcount.rto_in_transit) /
                            1000
                          ).toFixed(1) + "k"
                        : this.props.shipmentcount.rto_in_transit < 1000
                        ? this.props.shipmentcount.rto_in_transit
                        : null
                      : this.props.shipmentcount.rto_in_transit === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                {/* Delivered */}

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-shipment-underline-animation "
                      : this.state.deliveredbtn
                      ? "tab  hover-shipment-underline-animation-click"
                      : "tab hover-shipment-underline-animation"
                  }
                >
                  <button
                    className={this.state.deliveredbtn ? "active" : ""}
                    value="delivered"
                    onClick={() => {
                      this.setState({
                        intransitbtn: false,
                        deliveredbtn: true,
                        returnedbtn: false,
                        tobeshipedbtn: false,
                        allShippedBtn: false,
                        rtointransitbtn: false,
                        outForDelivereryBtn: false,
                      });
                      this.props.changeFilter({
                        shipmentStatus: ["delivered"],
                        startDate: this.props.filters.startDate,
                        endDate: this.props.filters.endDate,
                        dateType: "shopify",
                        search: "",
                        fulfillmentStatus: [],
                        paymentStatus: [],
                        vfOrderStatus: [],
                        pagename: ["delivered"],
                      });
                    }}
                  >
                    Delivered
                  </button>

                  <div
                    className={` ${
                      this.state.deliveredbtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.shipments &&
                    this.props.shipmentcount &&
                    this.props.shipmentcount.delivered_order
                      ? this.props.shipmentcount.delivered_order > 1000
                        ? (
                            Number(this.props.shipmentcount.delivered_order) /
                            1000
                          ).toFixed(1) + "k"
                        : this.props.shipmentcount.delivered_order < 1000
                        ? this.props.shipmentcount.delivered_order
                        : null
                      : this.props.shipmentcount.delivered_order === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                {/* Returned */}

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-shipment-underline-animation "
                      : this.state.returnedbtn
                      ? "tab  hover-shipment-underline-animation-click"
                      : "tab hover-shipment-underline-animation"
                  }
                >
                  <button
                    className={this.state.returnedbtn ? "active" : ""}
                    value="rto"
                    onClick={() => {
                      this.setState({
                        intransitbtn: false,
                        deliveredbtn: false,
                        returnedbtn: true,
                        tobeshipedbtn: false,
                        allShippedBtn: false,
                        rtointransitbtn: false,
                        outForDelivereryBtn: false,
                      });
                      this.props.changeFilter({
                        shipmentStatus: ["rto"],
                        startDate: this.props.filters.startDate,
                        endDate: this.props.filters.endDate,
                        dateType: "shopify",
                        search: "",
                        fulfillmentStatus: [],
                        paymentStatus: [],
                        vfOrderStatus: [],
                        pagename: ["rto"],
                      });
                    }}
                  >
                    Returned
                  </button>

                  <div
                    className={` ${
                      this.state.returnedbtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.shipments &&
                    this.props.shipmentcount &&
                    this.props.shipmentcount.returned_order
                      ? this.props.shipmentcount.returned_order > 1000
                        ? (
                            Number(this.props.shipmentcount.returned_order) /
                            1000
                          ).toFixed(1) + "k"
                        : this.props.shipmentcount.returned_order < 1000
                        ? this.props.shipmentcount.returned_order
                        : null
                      : this.props.shipmentcount.returned_order === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                {/* All Shipments */}

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-shipment-underline-animation "
                      : this.state.allShippedBtn
                      ? "tab  hover-shipment-underline-animation-click"
                      : "tab hover-shipment-underline-animation"
                  }
                >
                  <button
                    className={this.state.allShippedBtn ? "active" : ""}
                    value="rto"
                    onClick={() => {
                      this.setState({
                        intransitbtn: false,
                        deliveredbtn: false,
                        returnedbtn: false,
                        tobeshipedbtn: false,
                        allShippedBtn: true,
                        rtointransitbtn: false,
                        outForDelivereryBtn: false,
                      });
                      this.props.changeFilter({
                        shipmentStatus: [],
                        startDate: this.props.filters.startDate,
                        endDate: this.props.filters.endDate,
                        dateType: "shopify",
                        search: "",
                        fulfillmentStatus: [],
                        paymentStatus: [],
                        vfOrderStatus: [],
                        pagename: ["all"],
                      });
                    }}
                  >
                    All
                  </button>

                  <div
                    className={` ${
                      this.state.allShippedBtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.shipments &&
                    this.props.shipmentcount &&
                    this.props.shipmentcount.all_order
                      ? this.props.shipmentcount.all_order > 1000
                        ? (
                            Number(this.props.shipmentcount.all_order) / 1000
                          ).toFixed(1) + "k"
                        : this.props.shipmentcount.all_order < 1000
                        ? this.props.shipmentcount.all_order
                        : null
                      : this.props.shipmentcount.all_order === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>
              </div>

              <div className="download_csv">
                <button
                  className="clickable"
                  style={{
                    width: "120px",
                  }}
                  onClick={() => this.props.downloadCSV()}
                >
                  Download CSV
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_data">
          <div className="Globalsearch">
            <Globalsearch
              class={"nextwidth"}
              data={this.props.filters}
              tooltip={true}
              placeholder={`Search by Order ID, AWB, ${SHOPIFY_NAME} ID, Product Name`}
              change={(value) =>
                this.props.changeHandler({ search: value.target.value })
              }
              searchFunc={(value) => this.props.changeFilter({ search: value })}
            />{" "}
            <Dropdown overlay={menu} placement="bottomRight">
              <Button>
                <svg
                  style={
                    {
                      // transition: "all 0.5s",
                      // transform: `${
                      //   this.state.arrow2 ? "rotateX(180deg)" : "rotateX(0deg)"
                      // }`,
                    }
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="8"
                  viewBox="0 0 16 8"
                >
                  <g
                    id="Polygon_49"
                    data-name="Polygon 49"
                    transform="translate(16 8) rotate(180)"
                    // fill={`${this.state.arrow2 ? "#898989" : "none"}`}
                  >
                    <path d="M8,0l8,8H0Z" stroke="none" />
                    <path
                      d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                      stroke="none"
                      fill="#898989"
                    />
                  </g>
                </svg>
              </Button>
            </Dropdown>
            {/* <div className="drop-down-bulk">
              <svg
                style={{
                  // transition: "all 0.5s",
                  // transform: `${
                  //   this.state.arrow2 ? "rotateX(180deg)" : "rotateX(0deg)"
                  // }`,
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="8"
                viewBox="0 0 16 8"
              >
                <g
                  id="Polygon_49"
                  data-name="Polygon 49"
                  transform="translate(16 8) rotate(180)"
                  // fill={`${this.state.arrow2 ? "#898989" : "none"}`}
                >
                  <path d="M8,0l8,8H0Z" stroke="none" />
                  <path
                    d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                    stroke="none"
                    fill="#898989"
                  />
                </g>
              </svg>
              <div className="bulksearch">
              <button
                className="bulk-search-button"
                onClick={() => this.props.togglebulksearchaction()}
              >
                Bulk Search
              </button>
            </div>
            </div> */}
          </div>

          {this.state.allShippedBtn === true ? (
            <div
              className="drop"
              style={{
                display: "flex",
                float: "left",
                alignItems: "center",
                marginLeft: "-54px",
              }}
            >
              <p className="dispute-status"> </p>
              <Select
                style={{ fontWeight: 500, fontSize: 12, width: 288 }}
                value={this.props.shipmentStatus}
                className="shipment-order-filter"
                placeholder="Shipment Status: All"
                onChange={(val) =>
                  val === ""
                    ? this.props.changeFilter({
                        shipmentStatus: [],
                        startDate: this.props.filters.startDate,
                        endDate: this.props.filters.endDate,
                        dateType: "shopify",
                        search: "",
                      })
                    : this.props.changeFilter({
                        shipmentStatus: [val],
                        startDate: this.props.filters.startDate,
                        endDate: this.props.filters.endDate,
                        dateType: "shopify",
                        search: "",
                      })
                }
                onDropdownVisibleChange={(val) =>
                  this.setState({ arrow2: val })
                }
                suffixIcon={
                  <svg
                    style={{
                      transition: "all 0.5s",
                      transform: `${
                        this.state.arrow2 ? "rotateX(180deg)" : "rotateX(0deg)"
                      }`,
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_49"
                      data-name="Polygon 49"
                      transform="translate(16 8) rotate(180)"
                      fill={`${this.state.arrow2 ? "#898989" : "none"}`}
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#898989"
                      />
                    </g>
                  </svg>
                }
              >
                <Option value="" className="commondropdown">
                  All
                </Option>
                <Option value="manifested" className="commondropdown">
                  <Icon component={ReadyToShipIcon} /> Ready To Ship
                </Option>
                <Option value="rto_in_transit" className="commondropdown">
                  <Icon component={InTransitShipmentIcon} /> RTO In-Transit
                </Option>
                <Option value="transit" className="commondropdown">
                  <Icon component={InTransitShipmentIcon} /> In-Transit
                </Option>
                <Option value="delivered" className="commondropdown">
                  <Icon component={DeliveredShipmentIcon} /> Delivered
                </Option>
                <Option value="rto" className="commondropdown">
                  <Icon component={ReturnedShipmentIcon} /> Returned
                </Option>
                <Option value="cancelled" className="commondropdown">
                  <Icon component={CircleCross} /> Cancelled
                </Option>
              </Select>
            </div>
          ) : null}

          <div
            className="drop"
            style={{
              display: "flex",
              float: "left",
              alignItems: "center",
              marginLeft: "-32px",
            }}
          >
            <p className="dispute-status"> </p>

            <Select
              style={{ fontWeight: 500, fontSize: 12, width: 288 }}
              value={this.props.paymentStatus}
              className="shipment-order-filter"
              placeholder="Payment Status: All"
              onChange={(val) =>
                this.props.changeFilter({
                  paymentStatus: [val],
                  startDate: this.props.filters.startDate,
                  endDate: this.props.filters.endDate,
                  dateType: "shopify",
                  search: this.props.filters.search,
                })
              }
              onDropdownVisibleChange={(val) => this.setState({ arrow3: val })}
              suffixIcon={
                <svg
                  style={{
                    transition: "all 0.5s",
                    transform: `${
                      this.state.arrow3 ? "rotateX(180deg)" : "rotateX(0deg)"
                    }`,
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="8"
                  viewBox="0 0 16 8"
                >
                  <g
                    id="Polygon_49"
                    data-name="Polygon 49"
                    transform="translate(16 8) rotate(180)"
                    fill={`${this.state.arrow3 ? "#898989" : "none"}`}
                  >
                    <path d="M8,0l8,8H0Z" stroke="none" />
                    <path
                      d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                      stroke="none"
                      fill="#898989"
                    />
                  </g>
                </svg>
              }
            >
              <Option value="all" className="commondropdown">
                All
              </Option>

              <Option value="paid" className="commondropdown">
                <Icon
                  component={PaymentStatusIcon}
                  style={{ color: this.props.paymentStatusColors["paid"] }}
                />{" "}
                Prepaid
              </Option>

              <Option value="pending" className="commondropdown">
                <Icon
                  component={PaymentStatusIcon}
                  style={{ color: this.props.paymentStatusColors["pending"] }}
                />{" "}
                Cash On Delivery
              </Option>
              {/* <Option value="refunded">
                <Icon
                  component={PaymentStatusIcon}
                  style={{ color: this.props.paymentStatusColors["refunded"] }}
                />{" "}
                Refunded
              </Option> */}
            </Select>
          </div>
        </div>
      </div>
    );
  }
}

export default ShipmentOrderNav;
