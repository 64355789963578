import React, { Component } from "react";
import "./index.scss";
import { hasPermission } from "../../../../../Helpers/Permissions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown } from "antd";
import Globalsearch from "../../../Common/Globalsearch";
import VideoTooltip from "../../../Common/VideoTooltip";

class Importlistnav extends Component {
  render() {
    return (
      <div className="importlist-nav fullorder_data">
        <div className="importlistnav">
          <div className="ordfill">
            <div className="importlist-wrap">
              <div className="left">
                <div className="breadcurmb-txt">
                  Products <span>&#62;</span>{" "}
                  <span className="breadcurmb-underline">My Products</span>
                  <VideoTooltip
                    title="My Products"
                    subtitle="vFulfill product research tool to helps you to find products in the niche you would like to do business."
                    contentHeadings={[
                      "Import List",
                      "Private Products",
                      "Store Products",
                      "My Wishlist",
                    ]}
                    content={[
                      "Products that you import from AliExpress or from vFulfill catalog.",
                      "All products that have been shortlisted by you and are also linked with vFulfill catalog. ",
                      "List of all the products that have been published on your store.",
                      "Products that have been added by you to Wishlist from vFulfill Catalog.",
                    ]}
                    thumbnail=""
                    videolink=""
                  />
                </div>
              </div>
            </div>
            <div className="secondwrap">
              <div className="filtertab">
                <div
                  className={
                    !this.props.loading
                      ? "tab  hover-ndr-underline-animation-click"
                      : " tab hover-ndr-underline-animation "
                  }
                >
                  <button className="import-list-txt">
                    {!!this.props.urlSearched ? (
                      <Link to="../import-list">Import List</Link>
                    ) : (
                      <Link to="./import-list">Import List</Link>
                    )}
                  </button>

                  <div className="backgroundcircle">
                    {this.props.count && this.props.count.import_list === 0
                      ? "0"
                      : this.props.count &&
                        this.props.count.import_list &&
                        !this.props.loading
                      ? this.props.count.import_list
                      : "..."}
                  </div>
                </div>

                <div className="tab hover-ndr-underline-animation">
                  <button className="private-products-txt">
                    {" "}
                    {!!this.props.urlSearched ? (
                      <Link to="../private-products">Private Products</Link>
                    ) : (
                      <Link to="./private-products">Private Products</Link>
                    )}
                  </button>

                  <div className="circle">
                    {this.props.count && this.props.count.linked_products === 0
                      ? "0"
                      : this.props.count &&
                        this.props.count.linked_products &&
                        !this.props.loading
                      ? this.props.count.linked_products
                      : "..."}
                  </div>
                </div>

                <div className="tab hover-ndr-underline-animation">
                  <button className="store-product-txt">
                    {" "}
                    {!!this.props.urlSearched ? (
                      <Link to="../store-products">Store Products</Link>
                    ) : (
                      <Link to="./store-products">Store Products</Link>
                    )}
                  </button>

                  <div className="circle">
                    {this.props.count && this.props.count.my_products === 0
                      ? "0"
                      : this.props.count &&
                        this.props.count.my_products &&
                        !this.props.loading
                      ? this.props.count.my_products
                      : "..."}
                  </div>
                </div>

                <div className="tab hover-ndr-underline-animation">
                  <button className="wishlist-product-txt">
                    {" "}
                    {!!this.props.urlSearched ? (
                      <Link to="../wishlist-products">My Wishlist</Link>
                    ) : (
                      <Link to="./wishlist-products">My Wishlist</Link>
                    )}
                  </button>
                  <div className="circle">
                    {this.props.count && this.props.count.my_wishlist === 0
                      ? "0"
                      : this.props.count &&
                        this.props.count.my_wishlist &&
                        !this.props.loading
                      ? this.props.count.my_wishlist
                      : "..."}
                  </div>
                </div>
              </div>

              <div className="download_csv">
                {hasPermission(this.props.store1, "import_export_products") && (
                  <Dropdown
                    className="clickable-dropdown"
                    overlay={this.props.productActions}
                    placement="bottomRight"
                  >
                    <a
                      className="ant-dropdown-link"
                      href="#/"
                      style={{
                        fontWeight: "400",
                        fontSize: ".75rem",
                        color: "#fff",
                        verticalAlign: "middle",
                        padding: "8px 16px",
                      }}
                    >
                      <span style={{ color: "white" }}>Add Products</span>
                      <svg
                        className="down-arrow"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="8"
                        viewBox="0 0 16 8"
                        style={{ marginLeft: "16px" }}
                      >
                        <g
                          id="Polygon_54"
                          data-name="Polygon 54"
                          transform="translate(16 8) rotate(180)"
                          fill="none"
                        >
                          <path d="M8,0l8,8H0Z" stroke="none" />
                          <path
                            d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                            stroke="none"
                            fill="#fff"
                          />
                        </g>
                      </svg>
                      <svg
                        className="up-arrow"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="8"
                        viewBox="0 0 16 8"
                        style={{ marginLeft: "16px" }}
                      >
                        <path
                          id="Polygon_51"
                          data-name="Polygon 51"
                          d="M8,0l8,8H0Z"
                          fill="#fff"
                        />
                      </svg>
                    </a>
                  </Dropdown>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_data">
          <div className="Globalsearch">
            <Globalsearch
              data={this.props.data}
              change={(value) =>
                this.props.changeHandler({ search: value.target.value })
              }
              searchFunc={(value) => this.props.changeFilter({ search: value })}
              placeholder={"Search by Product Name or ID"}
            />
          </div>
          <div />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    count: state.auth.count,
  };
};

export default connect(mapStateToProps)(Importlistnav);
