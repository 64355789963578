import React, { Component } from "react";
import { Card, Icon, Checkbox, Button } from "antd";
import "./index.scss";
import { ReactComponent as rightArrow } from "../../../../../assets/images/icons/long-arrow.svg";

class NicheCategories extends Component {
  render() {
    const niche = this.props.niche[0];
    const selectAll =
      this.props.selectedNiches[this.props.greekSearchcategory] === 1
        ? true
        : false;

    let categoriesList = [];
    this.props.mapping &&
      this.props.mapping[this.props.greekSearchcategory] &&
      this.props.mapping[this.props.greekSearchcategory].length > 0 &&
      this.props.mapping[this.props.greekSearchcategory].map((m) =>
        this.props.allCategories.forEach((c) => {
          if (c.id === m) {
            return (categoriesList = [...categoriesList, c]);
          }
        })
      );

    return (
      <Card className="product-categories-card">
        <div className="product-categories">
          <div className="selected-niche">
            <div className="niche-info">
              <Icon
                component={this.props.nicheIcons[niche.name]}
                style={{ fontSize: "2.5rem" }}
              />
              <p>{niche.name}</p>
              {/* <Icon component={rightArrow} /> */}
            </div>
            <p>Select categories to</p>
            <p> look for in "{niche.name}"</p>
            {categoriesList && categoriesList.length > 0 && (
              <Checkbox
                checked={selectAll}
                onChange={(e) =>
                  this.props.selectAll(
                    this.props.greekSearchcategory,
                    e.target.checked
                  )
                }
              >
                {selectAll ? "Deselect All" : "Select All"}
              </Checkbox>
            )}

            <Button
              type="link"
              style={{
                fontSize: ".75rem",
                color: "#7a7a7a",
                display: "flex",
                alignItems: "center",
              }}
              onClick={this.props.toggleCategory}
            >
              <Icon
                component={rightArrow}
                style={{ transform: "rotate(180deg)" }}
              />
              Confirm Selection(s) & Go Back
            </Button>
          </div>
          <div className="niche-category">
            <Checkbox.Group
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
              value={
                this.props.selectedNiches[this.props.greekSearchcategory] ===
                  1 &&
                this.props.selectedCategories &&
                this.props.selectedCategories[this.props.greekSearchcategory] &&
                this.props.selectedCategories[this.props.greekSearchcategory]
                  .length === 0
                  ? this.props.mapping[this.props.greekSearchcategory]
                  : this.props.selectedCategories[
                      this.props.greekSearchcategory
                    ]
              }
              onChange={(e) =>
                this.props.selectCategory(this.props.greekSearchcategory, e)
              }
            >
              {categoriesList &&
                categoriesList.length > 0 &&
                categoriesList.map((c, i) => (
                  <Checkbox key={i} className="category-name" value={c.id}>
                    {c.name}
                  </Checkbox>
                ))}
            </Checkbox.Group>
          </div>
        </div>
      </Card>
    );
  }
}

export default NicheCategories;
