import React, { Component } from "react";
import { Row, Col, Button, Icon, Tag } from "antd";
import "./index.scss";
import moment from "moment";
import { SHOPIFY_NAME } from "../../../../../../constants";
import { ReactComponent as pin } from "../../../../../../assets/images/icons/pin.svg";
import { formatNumber } from "../../../../../../Helpers/Pricing";

class AdvertisingData extends Component {
  state = {};

  render() {
    let advertising_data =
      this.props.product &&
      this.props.product.advertising_data &&
      this.props.product.advertising_data;
    return (
      <div className="social-data">
        {advertising_data &&
          ((advertising_data.facebook_targeting &&
            advertising_data.facebook_targeting.length > 0) ||
            (advertising_data.facebook_engagement &&
              advertising_data.facebook_engagement.length > 0)) && (
            <Row gutter={15}>
              {advertising_data.facebook_targeting &&
                advertising_data.facebook_targeting.length > 0 && (
                  <Col md={12}>
                    <div className="social_stats">
                      <h4>Facebook Targeting Metrics:</h4>
                      <div className="facebook-stats">
                        <div className="stats-info">
                          <Row gutter={10}>
                            {advertising_data.facebook_targeting.map(
                              (metric) => {
                                return (
                                  <Col xs={12} key={metric.key}>
                                    <p>
                                      <span>
                                        {metric.key.replace(/[^\w]/g, "")}:{" "}
                                      </span>
                                      {metric.value}
                                    </p>
                                  </Col>
                                );
                              }
                            )}
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                )}

              {advertising_data.facebook_engagement &&
                advertising_data.facebook_engagement.length > 0 && (
                  <Col span={12}>
                    <div className="social_stats">
                      <h4>Facebook Engagement Stats:</h4>
                      <div className="engagement-stats">
                        <Row>
                          {advertising_data.facebook_engagement.map((e) => (
                            <Col xs={6} key={e.key}>
                              <span>{e.value}</span>
                              <br />
                              {e.key}
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </div>
                  </Col>
                )}
            </Row>
          )}

        {this.props.product &&
          this.props.product.ad_copies &&
          this.props.product.ad_copies.length > 0 && (
            <Row style={{ marginTop: "30px" }}>
              <h4>Facebook Ad Copies: </h4>

              {this.props.product.ad_copies.map((e, i) => (
                <div className="internal-notes" key={i}>
                  <div
                    style={{
                      background: "#fef7e6",
                      padding: "12px",
                      width: "260px",
                    }}
                  >
                    <p dangerouslySetInnerHTML={{ __html: e.copy }} />
                    <div style={{ textAlign: "center" }}>
                      <Button
                        onClick={() => this.props.toggleContentModal(e.copy)}
                      >
                        {" "}
                        View Ad Copy
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </Row>
          )}

        {advertising_data &&
          advertising_data.facebook_ad_copy_images &&
          advertising_data.facebook_ad_copy_images.length > 0 && (
            <Row style={{ marginTop: "30px" }}>
              <h4>Facebook Image Ads: </h4>

              <div className="facebook-images">
                {advertising_data.facebook_ad_copy_images.map((img, i) => (
                  <div style={{ position: "relative" }} key={i}>
                    <img
                      src={
                        process.env.REACT_APP_API_URL +
                        `/products/getImage?id=${img}`
                      }
                      alt="fb-ad-copy-img"
                    />
                    <Icon
                      type="eye"
                      style={{
                        bottom: "50%",
                        right: "50%",
                        background: "white",
                        color: "#0366fb",
                        cursor: "pointer",
                      }}
                      theme="filled"
                      onClick={() =>
                        this.props.toggleShowImageModal(
                          i,
                          advertising_data.facebook_ad_copy_images.map(
                            (img) =>
                              process.env.REACT_APP_API_URL +
                              `/products/getImage?id=${img}`
                          ),
                          "direct"
                        )
                      }
                    />
                    <a
                      href={
                        process.env.REACT_APP_API_URL +
                        `/products/getImage?id=${img}`
                      }
                      download="file"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon type="download" />
                    </a>
                  </div>
                ))}
              </div>
            </Row>
          )}
        {advertising_data &&
          advertising_data.facebook_ad_videos &&
          advertising_data.facebook_ad_videos.length > 0 && (
            <Row style={{ marginTop: "30px" }}>
              <h4>Facebook Ad Videos: </h4>

              <div className="facebook-images">
                {advertising_data.facebook_ad_videos.map((img, i) => (
                  // <video key={i} src={img} preload="none" />
                  <div style={{ position: "relative" }} key={i}>
                    <video width="120" height="120" controls>
                      <source src={img} type="video/mp4" />
                    </video>
                    <a
                      href={img}
                      download="file"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon type="download" style={{ bottom: "28px" }} />
                    </a>
                  </div>
                ))}
              </div>
            </Row>
          )}

        {advertising_data &&
          (advertising_data.shopify_stores_count ||
            advertising_data.total_units_sold ||
            advertising_data.avg_selling_price) && (
            <>
              <hr />
              <Row style={{ marginTop: "30px" }}>
                <h4>
                  {`Product Metrics In ${SHOPIFY_NAME}:`}
                  <span
                    style={{
                      fontSize: "12px",
                      color: "#838383",
                      fontWeight: "500",
                      float: "right",
                    }}
                  >
                    Last Updated On:{" "}
                    {moment(advertising_data.updated_on).format("DD MMM, YYYY")}
                  </span>
                </h4>
                {advertising_data &&
                  (advertising_data.shopify_stores_count ||
                    advertising_data.total_units_sold ||
                    advertising_data.avg_selling_price) && (
                    <div className="shopify-metrics">
                      {advertising_data.shopify_stores_count && (
                        <p>
                          <span>Number of stores selling: </span>
                          {formatNumber(
                            advertising_data.shopify_stores_count,
                            0
                          )}
                        </p>
                      )}
                      {advertising_data.total_units_sold && (
                        <p>
                          <span>Product units sold: </span>
                          {advertising_data.total_units_sold}
                        </p>
                      )}
                      {advertising_data.avg_selling_price && (
                        <p>
                          <span>Average selling price: </span>
                          {advertising_data.avg_selling_price}
                        </p>
                      )}
                    </div>
                  )}

                {/* popul */}
              </Row>
            </>
          )}

        {advertising_data &&
          (advertising_data.popular_sites_links ||
            advertising_data.countries) && (
            <>
              <hr />
              <Row>
                {advertising_data.popular_sites_links && (
                  <Col md={12}>
                    <h4>Other Relevant Links</h4>

                    <ol className="store-list">
                      {advertising_data.popular_sites_links.map((e) => (
                        <li key={e.name}>
                          <a
                            href={e.url}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {e.name}
                          </a>
                        </li>
                      ))}
                    </ol>
                  </Col>
                )}
                {advertising_data.countries && (
                  <Col md={12}>
                    <h4>Top Countries Where The Product Is Being Sold:</h4>

                    <ol className="country-list">
                      {advertising_data.countries.map((e, i) => (
                        <li key={e.name}>{e.name}</li>
                      ))}
                    </ol>
                  </Col>
                )}
              </Row>
            </>
          )}

        {advertising_data && advertising_data.instagram_influencers && (
          <Row style={{ marginTop: "30px" }}>
            <h4>Instagram Influencers: </h4>
            <div className="instagram-influencers">
              {advertising_data.instagram_influencers.map((insta, i) => (
                <div className="insta-influence" key={i}>
                  <div className="insta-img">
                    <img
                      src={
                        process.env.REACT_APP_API_URL +
                        `/products/getImage?id=${insta.img_link}`
                      }
                      alt="not found"
                    />
                    <div style={{ marginTop: 6 }}>
                      <p>{insta.name}</p>
                      <a style={{ fontSize: "10px" }} href={insta.link}>
                        {insta.instagram_handle}
                      </a>
                      <div style={{ margin: "6px 0" }}>
                        {insta.categories.map((c, i) => (
                          <Tag color="gold" key={i}>
                            {c}
                          </Tag>
                        ))}
                      </div>
                      <p
                        style={{
                          fontSize: "10px",
                          color: "black",
                          lineHeight: "14px",
                        }}
                      >
                        <Icon component={pin} style={{ fontSize: "10px" }} />
                        {insta.country}
                      </p>
                      <p
                        style={{
                          fontSize: "10px",
                          color: "black",
                          lineHeight: "14px",
                        }}
                      >
                        <Icon type="instagram" style={{ fontSize: "10px" }} />{" "}
                        {insta.followers} followers
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Row>
        )}
      </div>
    );
  }
}

export default AdvertisingData;
