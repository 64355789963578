import React, { Component } from "react";
import moment from "moment";
import { Button, Card, Icon, Table, Tooltip, Divider } from "antd";
import AudioTag from "../../../Common/AudioTag";
import { ReactComponent as VideoIcon } from "../../../../../assets/images/icons/video-play.svg";
import { ReactComponent as ImgEye } from "../../../../../assets/images/icons/image-eye.svg";
import { ReactComponent as DownloadBG } from "../../../../../assets/images/icons/download-bg.svg";
import { ReactComponent as RightIcon } from "../../../../../assets/images/icons/right-icon.svg";
import { ReactComponent as LeftIcon } from "../../../../../assets/images/icons/left-icon.svg";
import { ReactComponent as Arrow } from "../../../../../assets/images/icons/arrow.svg";
export default class LogsAndNotes extends Component {
  state = {
    viewMedia: false,
  };
  handleMedia() {
    this.setState({ viewMedia: true });
  }
  beforeDATE(DATE) {
    return new Date(DATE) < new Date();
  }
  render() {
    const { obj, index, lastIndex } = this.props;
    console.log("in", obj);
    let mediaAttachments = obj.partner_remark.files;
    const images = obj.partner_remark.files.filter(
      (file) => file.filetype === "image"
    );
    const audio = obj.partner_remark.files.filter(
      (file) => file.filetype === "audio"
    );
    const video = obj.partner_remark.files.filter(
      (file) => file.filetype === "video"
    );

    return (
      <div className="logsnotes-container">
        <div className="log-contiainer ">
          {" "}
          {!this.state.viewMedia ? (
            <div className="Escalation--log-container right-log">
              <span className="right-icon">
                <RightIcon />
              </span>
              <div className="line-1">
                <div>
                  {lastIndex ? (
                    <>
                      <span className="date">Escalation Re-opened On</span>{" "}
                      <span className="divi">:</span>{" "}
                      <span className="esc">
                        {" "}
                        {obj.partner_remark.created_date
                          ? moment(obj.partner_remark.created_date).format(
                              "DD MMM, YYYY"
                            )
                          : null}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="date">Escalated On</span>{" "}
                      <span className="divi">:</span>{" "}
                      <span className="esc">
                        {" "}
                        {obj.partner_remark.created_date
                          ? moment(obj.partner_remark.created_date).format(
                              "DD MMM, YYYY"
                            )
                          : null}
                      </span>
                    </>
                  )}
                </div>
                <div onClick={() => this.handleMedia()} className="view-btn">
                  View Media
                </div>
              </div>
              <div className="remark-partner">
                Remark :
                <span className="remark-partner-text">
                  {" "}
                  {obj.partner_remark.remark}
                </span>
              </div>
            </div>
          ) : (
            <div className="media-attachments">
              {!!mediaAttachments && !!mediaAttachments.length ? (
                // audio media
                <>
                  {!!audio && !!audio.length ? (
                    <div className="audio-container">
                      <p>Uploaded Audio:</p>
                      <div className="audio-wraper-all">
                        {audio.map((image, i) => {
                          return <AudioTag src={image.file} />;
                        })}
                      </div>
                    </div>
                  ) : null}{" "}
                  {/* images media */}
                  {!!images && !!images.length ? (
                    <div className="images-container">
                      <p>Uploaded Images:</p>
                      <div className="image-div-wraper">
                        {images.map((image, i) => {
                          return (
                            <div className="img-div">
                              <img src={image.file} alt="Not found" />
                              <Icon
                                className="eye-icon"
                                key={i}
                                onClick={() => this.props.showImage(i, images)}
                                component={ImgEye}
                              />
                              <a
                                className="download-icon"
                                href={
                                  `${process.env.REACT_APP_API_URL}` +
                                  `/products/download_product_media?url=` +
                                  `${image.file}`
                                }
                              >
                                <Icon component={DownloadBG} />
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                  {/* video media */}
                  {!!video && !!video.length ? (
                    <div className="videos-container">
                      <p>Uploaded Videos:</p>
                      <div className="video-div-wraper">
                        {video.map((vid, i) => {
                          return (
                            <div className="video-div">
                              <video src={vid.file} />

                              <a
                                className="download-icon"
                                href={
                                  `${process.env.REACT_APP_API_URL}` +
                                  `/products/download_product_media?url=` +
                                  `${vid.file}`
                                }
                              >
                                <Icon component={DownloadBG} />
                              </a>
                              <Icon
                                className="video-icon"
                                key={i}
                                onClick={() => this.props.showImage(i, video)}
                                component={VideoIcon}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                  <Button
                    onClick={() => {
                      this.setState({
                        viewMedia: false,
                      });
                    }}
                    style={{ marginLeft: "16px" }}
                    className="clickable ar"
                  >
                    <Arrow /> Go Back
                  </Button>
                </>
              ) : (
                <>
                  <div className="no-found">No Media Found!</div>
                  <Button
                    onClick={() => {
                      this.setState({
                        viewMedia: false,
                      });
                    }}
                    style={{ marginLeft: "16px" }}
                    className="clickable ar"
                  >
                    <Arrow /> Go Back
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
        {!!obj.vf_remark && !!obj.vf_remark.length ? (
          <div className="log-contiainer">
            <div className="reattampt-container scroller">
              {!!obj.vf_remark && !!obj.vf_remark.length
                ? obj.vf_remark.map((note, i) => {
                    return (
                      <>
                        <div className="note-wraper-table">
                          <h2 style={{ wordWrap: "break-word" }}>
                            {note.note}
                          </h2>

                          <div className="date-added">
                            {/* <p>Added by: {note.addedby}</p> */}
                            <p>
                              Added On:{" "}
                              {moment(note.date).format(
                                "DD MMM, YYYY | hh:mm A"
                              )}
                            </p>
                          </div>
                        </div>

                        {i === obj.vf_remark.length - 1 ? null : <Divider />}
                      </>
                    );
                  })
                : null}
            </div>
          </div>
        ) : null}

        {/* <div className="log-contiainer">
          {!obj.vf_remark.closed_date &&
          !obj.vf_remark.created_date &&
          !obj.vf_remark.remark &&
          !obj.partner_remark.reattampt ? (
            <div className="escalation-processing">
              {" "}
              <span className="left-icon">
                <LeftIcon />{" "}
              </span>
              Processing escalation request, please wait ...
            </div>
          ) : (
            <div>
              <div className="reattampt-container">
                <div>
                  {" "}
                  <span className="left-icon">
                    <LeftIcon />{" "}
                  </span>
                  {obj.partner_remark.reattampt !== "NA" ? (
                    <div>
                      {" "}
                      <span className="">
                        {" "}
                        {moment(obj.partner_remark.reattampt).isBefore(moment())
                          ? "Re-attempted Date"
                          : "Re-attempt Date"}
                      </span>{" "}
                      <span className="divi">|</span>{" "}
                      <span
                        style={{ color: "#212121", fontWeight: "500" }}
                        className="date"
                      >
                        {moment(obj.partner_remark.reattampt).format(
                          "DD MMM, YYYY"
                        )}
                      </span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <span className="date">Re-attempt Date</span>{" "}
                      <span className="divi">|</span> N/A
                    </div>
                  )}
                </div>

                <div>
                  {" "}
                  {obj.vf_remark.remark ? (
                    <div className="remark-parnter">
                      Note :{" "}
                      <span className="remark-partner-text">
                        {obj.vf_remark.remark}
                      </span>{" "}
                      <span class="divi">|</span> Added by vFulfill System
                    </div>
                  ) : null}
                </div>
                {!obj.vf_remark.closed_date && !obj.vf_remark.remark ? (
                  <div className="ece-status">
                    Escalation Status:{" "}
                    <span className="ece-span">In Processing</span>{" "}
                  </div>
                ) : null}
              </div>
              {/* {obj.partner_remark.reattampt !== "NA" ? (
                <div className="reattampt-container">
                  <div>
                    {" "}
                    <span className="left-icon">
                      <LeftIcon />{" "}
                    </span>
                    {obj.partner_remark.reattampt ? (
                      this.beforeDATE(obj.partner_remark.created_date) ? (
                        <div>
                          <span className="esc">Reattempted </span>{" "}
                          <span className="divi">|</span>{" "}
                          {moment(obj.vf_remark.created_date).format(
                            "DD MMM, YYYY"
                          )}
                        </div>
                      ) : (
                        <div>
                          {" "}
                          <span className="esc">Reattempte Date</span>{" "}
                          <span className="divi">|</span>{" "}
                          {moment(obj.vf_remark.created_date).format(
                            "DD MMM, YYYY"
                          )}
                        </div>
                      )
                    ) : null}{" "}
                  </div>
                  <div>
                    {" "}
                    {obj.vf_remark.remark ? (
                      <div className="remark-parnter">
                        Note :{" "}
                        <span className="remark-partner-text">
                          {obj.vf_remark.remark}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null} */}
        {/* {obj.vf_remark.closed_date ? (
                <div className="Escalation-close">
                  {" "}
                  <span className="left-icon">
                    <LeftIcon />{" "}
                  </span>
                  <span className="esc">Escalation Closed</span>{" "}
                  <span className="divi">|</span>{" "}
                  <span className="date">
                    {moment(obj.vf_remark.closed_date).format("DD MMM, YYYY")}{" "}
                  </span>
                </div>
              ) : null}
            </div>
          )}
        </div> */}
      </div>
    );
  }
}
