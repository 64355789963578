import React, { Component } from "react";
import { Button, Row, Col, Input, message, Tag } from "antd";
import {
  getCurrencySymbol,
  formatNumber,
} from "../../../../../../Helpers/Pricing";
import axios from "axios";
import "./index.scss";
// import moment from "moment";
// import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
// import DataSet from "@antv/data-set";
// import { ReactComponent as circle } from "../../../../../../assets/images/icons/circle-filled.svg";
// import { ReactComponent as NoGraphIcon } from "../../../../../../assets/images/icons/500.svg";

class PricingRelatedData extends Component {
  state = {
    cost: {
      pincode: "122001",
      codamount:
        this.props.productData &&
        this.props.productData.cost_data &&
        this.props.productData.cost_data.vfmrp
          ? this.props.productData.cost_data.vfmrp * 2
          : 0,
      apiResponse: {
        cod: "",
        price: "",
        shipping: "",
        ship: "",
        fulfillment: 0,
      },
    },
    vFcostCalculate: false,
    // sales: {
    //   "2019-08-01": {
    //     sales_number: 29296,
    //     sales_cost: 0,
    //   },

    //   "2019-08-02": {
    //     sales_number: 52972,
    //     sales_cost: 0,
    //   },
    //   "2019-08-03": {
    //     sales_number: 20371,
    //     sales_cost: 21433.67,
    //   },

    //   "2019-08-04": {
    //     sales_number: 0,
    //     sales_cost: 0,
    //   },
    //   "2019-08-05": {
    //     sales_number: 11664,
    //     sales_cost: 0,
    //   },

    //   "2019-08-06": {
    //     sales_number: 24103,
    //     sales_cost: "0",
    //   },
    // },
  };

  onClickvFcostCalculate() {
    const item = { quantity: 1 };
    item.price = this.props.productData.cost_data.priceusd;
    item.weight = this.props.productData.cost_data.weight;
    item.dimensions = this.props.productData.cost_data.dimensions;
    axios({
      url: process.env.REACT_APP_API_URL + "/products/get_est_vf_price_catalog",
      method: "post",
      data: {
        aliprice: this.props.productData.cost_data.aliprice,
        storeid: this.props.storeid,
        pincode: this.state.cost.pincode,
        codamount: this.state.cost.codamount,
        productid: this.props.productData.vfprodid,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setCostData("apiResponse", res.data);
          this.setState({
            vFcostCalculate: true,
          });
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  }

  setCostData(k, v) {
    // const product = this.props.productData;
    // if (product.cost_data.provisional) {
    //   v = {
    //     ...v,
    //     price: product.cost_data.price,
    //     shipping: product.cost_data.shipping,
    //     ship: product.cost_data.shipping * 0.5,
    //   };
    // }
    this.setState((state) => ({
      cost: {
        ...state.cost,
        [k]: v,
      },
    }));
  }
  render() {
    // let sales = Object.values(this.state.sales);
    // let dates = Object.keys(this.state.sales);

    // sales.map((e, i) =>
    //   dates.forEach((p, n) => {
    //     if (i === n) {
    //       e.date = p;
    //     }
    //   })
    // );

    // const salesData = sales;
    // const cols = {
    //   date: {
    //     range: [0, 1],
    //   },
    // };
    // const ds = new DataSet();
    // const dv = ds.createView().source(salesData);
    // dv.transform({
    //   type: "fold",
    //   fields: ["sales_number", "sales_cost"],
    //   key: "Date",
    //   value: "Amount",
    // });

    let product = this.props.productData;
    const currency = this.props.storegeo === "global" ? "USD" : "INR";
    // let analytics_data =
    //   this.props.productData &&
    //   this.props.productData.advertising_data &&
    //   this.props.productData.advertising_data;
    return (
      <div>
        {/* <p>
          India Landing Cost:{" "}
          <Tooltip title="Product Price" placement="right">
          <span style={{ textDecoration: "underline", color: "black" }}>
            {" "}
            ₹ {product.cost_data.vfmrp}
          </span>
          </Tooltip>
        </p> */}

        {this.props.showBulk && (
          <div className="bulk-qty-pricing">
            <h4 style={{ fontSize: 16 }}>Bulk Quantity India Landing Cost:</h4>
            {this.props.getPriceRange(product) !==
            `₹ ${formatNumber(product.cost_data.vfmrp)}` ? (
              <div
                className="bulk-price"
                style={{ background: "#F0F0F0", padding: 24 }}
              >
                {Object.keys(product.sourcing_pricing)
                  .sort(
                    (a, b) => Number(a.split("-")[0]) - Number(b.split("-")[0])
                  )
                  .map((range) => {
                    const ppqs = Object.values(
                      product.sourcing_pricing[range]
                    ).map((v) => Number(v.avg));
                    const minPrice = Math.min(...ppqs);
                    const maxPrice = Math.max(...ppqs);
                    if (ppqs.length) {
                      return (
                        <Tag
                          color="blue"
                          key={range}
                          style={{ margin: "5px 10px" }}
                        >
                          <p
                            style={{
                              textAlign: "center",
                              margin: 0,
                              padding: "5px 10px",
                              color: "#585858",
                            }}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                margin: 0,
                                padding: "5px 10px",
                                color: "#585858",
                              }}
                            >
                              {range} Pcs. <br />{" "}
                              <span
                                style={{ fontWeight: 600, color: "#2B2A2A" }}
                              >
                                {minPrice === maxPrice
                                  ? `${getCurrencySymbol(
                                      currency
                                    )} ${formatNumber(minPrice)}`
                                  : `${getCurrencySymbol(
                                      currency
                                    )} ${formatNumber(
                                      minPrice
                                    )} - ${getCurrencySymbol(
                                      currency
                                    )} ${formatNumber(maxPrice)}`}{" "}
                                Per Piece
                              </span>
                            </p>
                          </p>
                        </Tag>
                      );
                    } else {
                      return null;
                    }
                  })}
                <div style={{ textAlign: "center", marginTop: 15 }}>
                  <Button
                    type="primary"
                    onClick={() => this.props.explorePricing()}
                  >
                    Explore Pricing
                  </Button>
                </div>
              </div>
            ) : (
              <div className="bulk-pricing-not-available">
                <h2>Bulk Pricing Not Available</h2>
                <p>
                  Seems like we currently don’t have bulk pricing data to
                  display here. Ordering product in bulk can help you save in
                  India Landing Costs. If you want to order this product in bulk
                  you can initiate the process by requesting bulk pricing for
                  it.
                </p>
                <div style={{ textAlign: "center", marginTop: 15 }}>
                  <Button
                    type="primary"
                    onClick={() => this.props.escalateBulkPricing()}
                  >
                    Request Bulk Pricing
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
        <div style={{ marginTop: 15 }}>
          <h4 style={{ fontSize: 16 }}>Retail VF Cost Calculator:</h4>
          <div className="calculate-est-vf">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {this.props.storegeo === "in" && (
                <>
                  <div className="calculation">
                    <p>Enter Delivery Pincode:</p>
                    <Input
                      placeholder="122001"
                      value={this.state.cost.pincode}
                      onChange={(e) =>
                        this.setCostData("pincode", e.target.value)
                      }
                    />
                  </div>
                  <div className="calculation">
                    <p>Enter Sale Amount (₹):</p>
                    <Input
                      placeholder="2000"
                      type="number"
                      value={this.state.cost.codamount}
                      onChange={(e) =>
                        this.setCostData("codamount", e.target.value)
                      }
                    />
                  </div>
                </>
              )}
              <p>
                Est. vF Product Cost:{" "}
                {/* <Tooltip title="Product Price" placement="right"> */}
                <span
                  style={{
                    textDecoration: "underline",
                    color: "black",
                  }}
                >
                  {" "}
                  {getCurrencySymbol(currency)} {product.cost_data.vfmrp}
                </span>
                {/* </Tooltip> */}
              </p>
            </div>

            <div className="cal-button">
              <Button
                type="primary"
                onClick={() => this.onClickvFcostCalculate()}
              >
                Calculate Total vF Cost
              </Button>
            </div>

            {this.state.vFcostCalculate && (
              <Row>
                <br />
                <br />
                <div className="total-result">
                  <Col xs={8}>
                    Product Price:{" "}
                    <span style={{ color: "black" }}>{`${getCurrencySymbol(
                      currency
                    )} ${formatNumber(
                      product.cost_data.provisional
                        ? Number(product.cost_data.vfmrp)
                        : this.state.cost.apiResponse.price
                    )}`}</span>
                  </Col>
                  <Col xs={8}>
                    Forward Shipping:{" "}
                    <span style={{ color: "black" }}>{`${getCurrencySymbol(
                      currency
                    )} ${formatNumber(
                      product.cost_data.provisional
                        ? Number(product.alishipping) * 0.98
                        : this.state.cost.apiResponse.shipping
                    )}`}</span>
                  </Col>
                  {this.props.storegeo === "in" ? (
                    <Col xs={8}>
                      COD Fee:{" "}
                      <span style={{ color: "black" }}>{`${getCurrencySymbol(
                        currency
                      )} ${formatNumber(
                        this.state.cost.apiResponse.cod
                      )}`}</span>
                    </Col>
                  ) : (
                    <Col xs={8}>
                      Fulfillment Fee:{" "}
                      <span style={{ color: "black" }}>{`${getCurrencySymbol(
                        currency
                      )} ${formatNumber(
                        this.state.cost.apiResponse.fulfillment
                      )}`}</span>
                    </Col>
                  )}
                  <Col xs={8} style={{ fontWeight: "500" }}>
                    Total vF Cost:{" "}
                    <span style={{ color: "black" }}>{`${getCurrencySymbol(
                      currency
                    )} ${formatNumber(
                      (product.cost_data.provisional
                        ? Number(product.cost_data.vfmrp)
                        : this.state.cost.apiResponse.price) +
                        (product.cost_data.provisional
                          ? Number(product.alishipping) * 0.98
                          : this.state.cost.apiResponse.shipping) +
                        this.state.cost.apiResponse.cod +
                        (this.state.cost.apiResponse.fulfillment || 0)
                    )}`}</span>
                  </Col>
                  {this.props.storegeo === "in" ? (
                    product.buyback === 1 && product.buyback_percentage > 0 ? (
                      <Col xs={8} style={{ fontWeight: "500" }}>
                        Buy Back Price:{" "}
                        <span style={{ color: "black" }}>
                          {" "}
                          ₹{" "}
                          {(
                            (product.cost_data.provisional
                              ? (Number(product.cost_data.vfmrp) *
                                  product.buyback_percentage) /
                                100
                              : (this.state.cost.apiResponse.price *
                                  product.buyback_percentage) /
                                100) +
                            this.state.cost.apiResponse.cod -
                            (product.cost_data.provisional
                              ? Number(product.alishipping) * 0.95 * 0.5
                              : this.state.cost.apiResponse.ship)
                          ).toFixed(2)}{" "}
                        </span>
                      </Col>
                    ) : (
                      <Col xs={8} style={{ fontWeight: "500" }}>
                        Buy Back Price:{" "}
                        <span style={{ color: "black" }}>N.A.</span>
                      </Col>
                    )
                  ) : null}
                </div>
              </Row>
            )}
          </div>
        </div>
        <br />
        <br />
        {/* <hr />
        <h4>
          Order data:{" "}
          {analytics_data && analytics_data.updated_on && (
            <span
              style={{
                fontSize: "12px",
                color: "#838383",
                fontWeight: "500",
                float: "right",
              }}
            >
              Last Updated On:{" "}
              {moment(analytics_data.updated_on).format("DD MMM, YYYY")}
            </span>
          )}
        </h4>

        <Row>
          <div className="shopify-metrics">
            {analytics_data && analytics_data.total_units_sold && (
              <p>
                <span>Total Orders:</span>{" "}
                {formatNumber(analytics_data.total_units_sold, 0)}
                <br />
                <span style={{ color: "#0068FF", fontSize: "10px" }}>
                  (Across all platforms)
                </span>
              </p>
            )}
            {analytics_data && analytics_data.total_units_sold && (
              <p>
                <span>Total Orders:</span>
                {formatNumber(analytics_data.total_units_sold, 0)}
                <br />
                <span style={{ color: "#0068FF", fontSize: "10px" }}>
                  (Through vFulfill)
                </span>
              </p>
            )}
            <p>
              <span>
                Successful Deliveries:{" "}
                {product.product_analytics &&
                product.product_analytics.total &&
                product.product_analytics.total.shipment_delivered > 0
                  ? formatNumber(
                      product.product_analytics.total.shipment_delivered
                    )
                  : 0}
              </span>
            </p>
            <p>
              <span>
                Product Returns:{" "}
                {product.product_analytics &&
                product.product_analytics.total &&
                product.product_analytics.total.shipment_rto > 0
                  ? formatNumber(product.product_analytics.total.shipment_rto)
                  : 0}
              </span>
            </p>
          </div>
        </Row> */}
        {/* <h4>Order Trend Graph:</h4>
        <Row>
          <div className="report-graph">
            <div className="graph-stat">
              <div className="stat-name">
                <p>
                  <Icon
                    component={circle}
                    style={{ color: "rgb(38, 143, 251)" }}
                  />{" "}
                  vFulfill Order Sales Number
                </p>
              </div>
              <div className="stat-name">
                <p>
                  {" "}
                  <Icon
                    component={circle}
                    style={{ color: "rgb(99, 195, 93)" }}
                  />{" "}
                  vFulfill Order Sales Amount
                </p>
              </div>
            </div>
            <div>
              {sales.reduce((acc, c) => {
                return acc + c.sales_number + c.sales_cost;
              }, 0) > 0 ? (
                <Chart height={400} data={dv} scale={cols} forceFit>
                  <Legend />
                  <Axis name="date" visible={false} />
                  <Axis
                    name="Amount"
                    label={{
                      formatter: (val) => `${getCurrencySymbol("INR")} ${val}`,
                    }}
                  />
                  <Tooltip
                    crosshairs={{
                      type: "y",
                    }}
                    containerTpl='<div class="g2-tooltip"><p class="g2-tooltip-title"></p><table class="g2-tooltip-list"></table></div>'
                    itemTpl={`<tr class=&quot;g2-tooltip-list-item&quot;><td style=&quot;color:{color}&quot;><span class=&quot;g2-tooltip-list-item-dot&quot;></span><span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span> {name}: </td><td>${getCurrencySymbol(
                      "INR"
                    )} {value}</td></tr>`}
                    offset={50}
                    g2-tooltip={{
                      position: "absolute",
                      visibility: "hidden",
                      border: "1px solid #000",
                      borderRadius: "4px",
                      backgroundColor: "rgba(0, 0, 0, 0.9)",
                      color: "#fff",
                      opacity: "1",
                      padding: "1rem 1.25rem",
                      transition: "top 200ms,left 200ms",
                    }}
                  />
                  <Geom
                    type="line"
                    position="date*Amount"
                    size={2}
                    color={"Date"}
                  />
                  <Geom
                    type="point"
                    position="date*Amount"
                    size={4}
                    shape={"circle"}
                    color={"Date"}
                    style={{
                      stroke: "#fff",
                      lineWidth: 1,
                    }}
                  />
                </Chart>
              ) : (
                <Row
                  style={{
                    marginBottom: 50,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <NoGraphIcon />
                  <div style={{ textAlign: "left", marginLeft: 30 }}>
                    <h1 style={{ marginBottom: 0 }}>Not Enough Data!</h1>
                    <p style={{ margin: 0, fontSize: 18 }}>
                      We could not find enough data to build a graph!
                    </p>
                  </div>
                </Row>
              )}
            </div>
          </div>
        </Row> */}
      </div>
    );
  }
}

export default PricingRelatedData;
