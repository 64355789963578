import React, { Component } from "react";
import "./index.scss";
import {
  Row,
  Card,
  Table,
  Col,
  Button,
  Skeleton,
  message,
  Spin,
  Alert,
  Empty,
  Icon,
} from "antd";
import { Chart, Geom, Axis, Legend, Tooltip } from "bizcharts";
import DataSet from "@antv/data-set";
import { ChartCard } from "ant-design-pro/lib/Charts";
import "ant-design-pro/dist/ant-design-pro.css";
import moment from "moment";
import axios from "axios";
import {
  formatNumber,
  getCurrencySymbol,
  formatBalance,
} from "../../../../Helpers/Pricing";
import MyWalletReviewModal from "../MyWalletReviewModal";
import WithdrawalModal from "../WithdrawalModal";
import WireTransferSuccessModal from "../WireTransferSuccessModal";
import AddFundsModal from "../AddFundsModal";
import AddCouponModal from "../AddCouponModal";
import TransactionSummary from "../TransactionsReport/index";

import { connect } from "react-redux";
import { hasPermission } from "../../../../Helpers/Permissions";
import NotFoundCard from "../../Common/NotFoundCard";
import RedirectModal from "./RedirectModal";

class WithdrawalSummary extends Component {
  state = {
    reviewModal: {
      visible: false,
      id: undefined,
    },
    transactions: [],
    withdrawalHistory: [],
    balance: 0,
    lastAdded: "",
    inrValue: 1,
    transaction_graph: {
      startDate: moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      data: [{ name: "Debit" }, { name: "Credit" }],
    },

    paging: {
      page: 1,
      per_page_count: 10,
    },
    loading: {
      transactions: true,
      graph: true,
      withdrawal_history: true,
      balance: true,
    },
    addFundsModal: {
      visible: false,
    },
    addCouponModal: {
      visible: false,
    },
    withdrawalModal: {
      visible: false,
    },
    redirectModal: {
      visible: false,
    },
    clients: {
      razorpay: "",
    },
    wireModal: {
      visible: false,
      id: "",
    },
  };

  toggleWireModal(id = "") {
    this.setState({
      wireModal: {
        visible: id ? true : false,
        id,
      },
    });
  }

  // componentWillUnmount() {
  //   if (this.props.cancelToken) {
  //     this.props.cancelToken20.cancel();
  //   }
  // }

  fetchPaymentClients() {
    axios({
      url: process.env.REACT_APP_API_URL + "/wallet/get_payment_clients",
      method: "post",
      withCredentials: true,
    })
      .then((res) => {
        this.setState({
          clients: res.data.clients,
        });
      })
      .catch((e) => {});
  }

  fetchTransactions() {
    axios({
      url: process.env.REACT_APP_API_URL + "/wallet/get_transactions",
      method: "post",
      data: {
        currency: this.props.match.params.storegeo === "global" ? "USD" : "INR",
        storeid: this.props.store.id,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        page: 1,
        pageSize: 10,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            transactions: res.data.transactions,
            loading: { ...this.state.loading, transactions: false },
          });
        }
      })
      .catch((e) => {
        message.error("There was an error fetching transactions!");
      });
  }

  fetchBalance() {
    axios({
      url: process.env.REACT_APP_API_URL + "/wallet/get_current_balance",
      method: "post",
      data: {
        currency: this.props.match.params.storegeo === "global" ? "USD" : "INR",
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            balance: res.data.balance,
            lastAdded: res.data.lastAdded,
            inrValue: res.data.inrValue,
            loading: { ...this.state.loading, balance: false },
          });
        }
      })
      .catch((e) => {
        message.error("There was an error fetching todays transactions!");
      });
  }

  fetchTransactionGraph() {
    this.setState(
      {
        loading: {
          ...this.state.loading,
          graph: true,
        },
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/wallet/get_transaction_count",
          method: "post",
          data: {
            currency:
              this.props.match.params.storegeo === "global" ? "USD" : "INR",
            start: this.state.transaction_graph.startDate,
            end: this.state.transaction_graph.endDate,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                transaction_graph: {
                  ...this.state.transaction_graph,
                  data: res.data.transactions,
                },
                loading: { ...this.state.loading, graph: false },
              });
            }
          })
          .catch((e) => {
            message.error("There was an error fetching todays transactions!");
          });
      }
    );
  }

  fetchWithdrawal() {
    axios({
      url: process.env.REACT_APP_API_URL + "/wallet/get_withdrawal_list",
      method: "post",
      data: {
        currency: this.props.match.params.storegeo === "global" ? "USD" : "INR",
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            withdrawalHistory: res.data.requests,
            loading: { ...this.state.loading, withdrawal_history: false },
          });
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  }

  componentDidMount() {
    this.fetchBalance();
    // this.fetchTransactions();
    this.fetchTransactionGraph();
    // this.fetchWithdrawal();
  }

  changeState = (field, value) => {
    if (typeof value === "string" && value.length) {
      this.setState({
        [field]: value,
      });
    } else {
      this.setState({
        [field]: !this.state[field],
      });
    }
  };

  toggleReviewModal(id = undefined) {
    this.setState({
      reviewModal: {
        ...this.state.reviewModal,
        id,
        visible: !this.state.reviewModal.visible,
      },
    });
  }
  cancelRedirectModal() {
    this.setState({
      redirectModal: { visible: false },
    });
  }
  toggleWithdrawalModal = () => {
    if (!this.props.kyc_submit) {
      this.setState({
        redirectModal: { visible: true },
      });
      return;
    }

    this.setState({
      withdrawalModal: {
        ...this.state.withdrawalModal,
        visible: !this.state.withdrawalModal.visible,
      },
    });
  };
  reloadWithdrawalHistory = () => {
    this.fetchWithdrawal();
  };

  onDateChange(date) {
    this.setState(
      {
        transaction_graph: {
          ...this.state.transaction_graph,
          ...date,
          data: [{ name: "Debit" }, { name: "Credit" }],
        },
        loading: {
          ...this.state.loading,
          graph: true,
        },
      },
      () => this.fetchTransactionGraph()
    );
  }

  toggleAddFundsModal() {
    this.setState((state) => ({
      addFundsModal: {
        ...state.addFundsModal,
        visible: !state.addFundsModal.visible,
      },
    }));
  }
  toggleAddCouponModal() {
    this.setState((state) => ({
      addCouponModal: {
        ...state.addCouponModal,
        visible: !state.addCouponModal.visible,
      },
    }));
  }

  render() {
    const { store, user } = this.props;

    const transactionHistoryColumns = [
      {
        title: "Transaction ID",
        dataIndex: "transaction_date",
        width: "10%",
        render: (text, record) => {
          return (
            <div>
              <span style={{ color: "#212121", fontWeight: "400" }}>
                {record.transaction_vfid}
              </span>

              <span
                style={{ fontSize: "10px", color: "#212121", display: "block" }}
              >
                {moment(text).format("DD MMM, YYYY")}
              </span>
            </div>
          );
        },
      },

      // {
      //   title: "Transaction ID",
      //   dataIndex: "transaction_vfid",
      //   width: "15%",
      // },

      {
        title: " Store Name",
        dataIndex: "channel_name",
        width: "10%",
      },

      {
        title: "Transaction Head",
        dataIndex: "transaction_data",
        width: "25%",
        className: "transaction-details",
        render: (text, record) => {
          if (record.transaction_subtype === "order_placed") {
            return <span>{`${text.items.length} product(s) ordered`} </span>;
          } else if (record.transaction_subtype === "funds_added_admin") {
            return <span>Wallet Balance Added</span>;
          } else if (record.transaction_subtype === "razorpay_addfunds") {
            return <span>Wallet Balance Added via Razorpay</span>;
          } else if (record.transaction_subtype === "cashback_addfunds") {
            return <span>Cashback</span>;
          } else if (record.transaction_subtype === "coupon_addfunds") {
            return <span>Wallet Balance Added via Coupon</span>;
          } else if (record.transaction_subtype === "wire_addfunds") {
            return <span>Funds Added via Wire Transfer</span>;
          } else if (record.transaction_subtype === "withdraw_funds") {
            return <span>Wallet Balance Withdrawn</span>;
          } else if (record.transaction_subtype === "client_sourcing_request") {
            return <span>Sourcing Request</span>;
          } else if (record.transaction_subtype === "rto_remit") {
            return <span>RTO Remittance</span>;
          } else if (record.transaction_subtype === "cod_remit") {
            return <span>COD Remittance</span>;
          } else if (record.transaction_subtype === "cod_remit_new") {
            return <span>COD Remittance</span>;
          } else if (record.transaction_subtype === "credit_note") {
            return (
              <span>
                Credit Note
                {/*<Button
                  type="primary"
                  size="small"
                  className="review-button"
                  onClick={() => this.toggleReviewModal(record._id["$oid"])}
                >
                  Review
                </Button>*/}
              </span>
            );
          } else if (record.transaction_subtype === "admin_note") {
            return <span>Manual Note</span>;
          } else if (record.transaction_subtype === "order_reverse_pickup") {
            return <span>Order Reverse Pickup</span>;
          } else if (
            record.transaction_subtype === "verified_order_cancellation"
          ) {
            return <span>Verified Order Cancellation Fee</span>;
          } else if (record.transaction_subtype === "order_refund") {
            return <span>Order Refund</span>;
          } else if (record.transaction_subtype === "order_placed_pp") {
            return <span>Product Price</span>;
          } else if (record.transaction_subtype === "order_placed_ff") {
            return <span>Fulfilment Fees</span>;
          } else if (record.transaction_subtype === "order_placed_fs") {
            return <span>Forward Shipping</span>;
          } else if (record.transaction_subtype === "order_placed_codf") {
            return <span>COD Fees</span>;
          } else if (record.transaction_subtype === "storage_charges") {
            return <span>Storage Charges</span>;
          } else if (record.transaction_subtype === "rto_shipping") {
            return <span>RTO Shipping</span>;
          } else if (record.transaction_subtype === "rto_handling_fee") {
            return <span>RTO Handling Fee</span>;
          } else if (record.transaction_subtype === "buyback_handling_fee") {
            return <span>BuyBack Handling Fee</span>;
          } else if (record.transaction_subtype === "order_cancellation_fee") {
            return <span>Order Cancellation Fee</span>;
          } else if (record.transaction_subtype === "order_reverse_pickup") {
            return <span>Reverse Pick Up </span>;
          } else if (record.transaction_subtype === "codf_reverse") {
            return <span>Reverse COD Fees</span>;
          } else if (record.transaction_subtype === "inward_fees") {
            return <span>Inward Fees</span>;
          } else if (record.transaction_subtype === "credit_note_pp") {
            return <span>Product Price Credit</span>;
          } else if (record.transaction_subtype === "credit_note_ff") {
            return <span>Fullfillment Fee Credit</span>;
          } else if (record.transaction_subtype === "credit_note_fs") {
            return <span>Shipping Charges credit</span>;
          } else if (record.transaction_subtype === "payment_gatway_charges") {
            return <span>Payment Gatway Charges</span>;
          } else if (record.transaction_subtype === "prepaid_remittance") {
            return <span>Prepaid Remittance</span>;
          } else if (record.transaction_subtype === "management_fee") {
            return <span>Management Fees</span>;
          } else if (
            record.transaction_subtype === "orderamount_gst_deductions"
          ) {
            // return (
            //   <span className="gst-deductions-on-deliver">
            //     {" "}
            //     <Tooltip title="GST deductions are done on delivered order only">
            //       GST Deductions
            //     </Tooltip>{" "}
            //   </span>
            // );
            return <span>GST Deductions</span>;
          } else if (record.transaction_subtype === "cod_remit_reverse") {
            return <span>Cod Remittance Reverse</span>;
          } else if (record.transaction_subtype === "cod_remit_new_reverse") {
            return <span>Cod Remittance New Reverse</span>;
          } else if (
            record.transaction_subtype === "prepaid_remittance_reverse"
          ) {
            return <span>Prepaid Remittance Reverse</span>;
          } else if (
            record.transaction_subtype === "orderamount_gst_deductions_reverse"
          ) {
            return <span>Orderamount Gst Deductions Reverse</span>;
          } else if (record.transaction_subtype === "management_fee_reverse") {
            return <span>Management Fee Reverse</span>;
          } else if (record.transaction_subtype === "payment_gatway_fee") {
            return <span>Payment Gateway Fee</span>;
          } else if (record.transaction_subtype === "inventory_rtv") {
            return <span>Inventory RTV</span>;
          }
        },
      },

      // {
      //   title: "Transaction Head",
      //   dataIndex: "transaction_subtype",
      //   width: "15%",
      //   render: (type) => {
      //     const types = {
      //       rto_remit: "Buyback Credit",
      //       cod_remit: "COD Remittance",
      //       funds_added_admin: "Add Funds - Manual",
      //       wire_addfunds: "Add Funds - Wire",
      //       order_placed: "vF Cost",
      //       razorpay_addfunds: "Add Funds - Razorpay",
      //       cashback_addfunds: "Add Funds - Cashback",
      //       withdraw_funds: "Withdraw Funds",
      //       coupon_addfunds: "Fund Addition",
      //       credit_note: "Credit Note",
      //       admin_note: "System Transaction",
      //       client_sourcing_request: "Sourcing Request",
      //       order_reverse_pickup: "Order Reverse Pickup",
      //       verified_order_cancellation: "Verified Order Cancellation Fee",
      //       order_refund: "Order Refund",
      //       order_placed_pp: "Product Price",
      //       order_placed_ff: "Fulfilment Fees",
      //       order_placed_fs: "Forward Shipping",
      //       order_placed_codf: "COD Fees",
      //       storage_charges: "Storage Charges",
      //       rto_shipping: "RTO Shipping",
      //       rto_handling_fee: "RTO Handling Fee",
      //       buyback_handling_fee: "BuyBack Handling Fee",
      //       order_cancellation_fee: "Order Cancellation Fee",
      //       reverse_pickup: "Reverse Pick Up",
      //       codf_reverse: "Reverse COD Fees",
      //       inward_fees: "Inward Fees",
      //       credit_note_pp: "Product Price Credit",
      //       credit_note_ff: "Fullfillment Fee Credit",
      //       credit_note_fs: "Shipping Charges credit",
      //     };
      //     return types[type];
      //   },
      // },
      {
        title: "Reference ID",
        dataIndex: "transaction_data",
        width: "10%",
        render: (text, record) => {
          return (
            <div>
              {" "}
              <span class="tooltip">
                {record.transaction_subtype === "inventory_rtv"
                  ? record.transaction_data.rtv_allocation_id
                  : [
                      "order_placed_ff",
                      "order_placed_pp",
                      "order_placed_fs",
                      "order_placed_codf",
                      "credit_note_ff",
                      "credit_note_fs",
                      "credit_note_pp",
                      "codf_reverse",
                      "order_placed",
                      "order_cancellation_fee",
                      "order_refund",
                      "verified_order_cancellation",
                      "credit_note",
                      "management_fee",
                      "prepaid_remittance",
                      "orderamount_gst_deductions",
                      "payment_gatway_charges",
                      "order_reverse_pickup",
                      "prepaid_remittance_reverse",
                      "orderamount_gst_deductions_reverse",
                      "management_fee_reverse",
                    ].indexOf(record.transaction_subtype) !== -1
                  ? record.transaction_data.vforderid
                  : [
                      "cod_remit",
                      "cod_remit_new",
                      "rto_remit",
                      "buyback_handling_fee",
                      "rto_handling_fee",
                      "rto_shipping",
                      "orderamount_gst_deductions",
                      "cod_remit_reverse",
                      "cod_remit_new_reverse",
                    ].indexOf(record.transaction_subtype) !== -1
                  ? record.transaction_data.awb
                  : ["client_sourcing_request"].indexOf(
                      record.transaction_subtype
                    ) !== -1
                  ? record.transaction_data.client_sourcing_request_vfid
                  : null}

                <span class="tooltiptext">
                  {record.transaction_subtype === "inventory_rtv"
                    ? record.transaction_data.rtv_allocation_id
                    : [
                        "order_placed_ff",
                        "order_placed_pp",
                        "order_placed_fs",
                        "order_placed_codf",
                        "credit_note_ff",
                        "credit_note_fs",
                        "credit_note_pp",
                        "codf_reverse",
                        "order_placed",
                        "order_cancellation_fee",
                        "order_refund",
                        "verified_order_cancellation",
                        "credit_note",
                        "management_fee",
                        "prepaid_remittance",
                        "orderamount_gst_deductions",
                        "payment_gatway_charges",
                        "order_reverse_pickup",
                        "prepaid_remittance_reverse",
                        "orderamount_gst_deductions_reverse",
                        "management_fee_reverse",
                      ].indexOf(record.transaction_subtype) !== -1
                    ? "Vf Order id"
                    : [
                        "cod_remit",
                        "cod_remit_new",
                        "rto_remit",
                        "buyback_handling_fee",
                        "rto_handling_fee",
                        "rto_shipping",
                        "orderamount_gst_deductions",
                        "cod_remit_reverse",
                        "cod_remit_new_reverse",
                      ].indexOf(record.transaction_subtype) !== -1
                    ? "AWB"
                    : ["client_sourcing_request"].indexOf(
                        record.transaction_subtype
                      ) !== -1
                    ? "Sourcing Request ID"
                    : null}
                </span>
              </span>
              {[
                "order_placed_ff",
                "order_placed_pp",
                "order_placed_fs",
                "order_placed_codf",
                "credit_note_ff",
                "credit_note_fs",
                "credit_note_pp",
                "codf_reverse",
                "order_placed",
                "credit_note",
                "cod_remit",
                "cod_remit_new",
                "rto_remit",
                "buyback_handling_fee",
                "rto_handling_fee",
                "rto_shipping",
              ].indexOf(record.transaction_subtype) !== -1 ? (
                <Button
                  type="primary"
                  size="small"
                  className="clickable"
                  onClick={() => this.toggleReviewModal(record._id["$oid"])}
                >
                  Review
                </Button>
              ) : null}
            </div>
          );
        },
      },

      {
        title: "Taxable Amt",
        dataIndex: "transaction_data",
        width: "10%",
        className: "transaction-details",
        render: (text, record) => {
          if (
            record.transaction_subtype === "order_placed_pp" ||
            record.transaction_subtype === "credit_note_pp"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.base)}
              </span>
            );
          } else if (
            record.transaction_subtype === "order_placed_ff" ||
            record.transaction_subtype === "credit_note_ff"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.fulfilment.base)}
              </span>
            );
          } else if (
            record.transaction_subtype === "order_placed_fs" ||
            record.transaction_subtype === "credit_note_fs"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.shipping.base)}
              </span>
            );
          } else if (
            record.transaction_subtype === "order_placed_codf" ||
            record.transaction_subtype === "codf_reverse"
          ) {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.cod.base)}
              </span>
            );
          } else if (record.transaction_subtype === "buyback_handling_fee") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.product)}
              </span>
            );
          } else if (record.transaction_subtype === "rto_handling_fee") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.rto_handling.base)}
              </span>
            );
          } else if (record.transaction_subtype === "rto_shipping") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.shipping.base)}
              </span>
            );
          } else if (record.transaction_subtype === "cod_remit_new") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.amount
                  ? formatNumber(record.transaction_data.amount)
                  : formatNumber(record.transaction_data.total)}
              </span>
            );
          } else if (record.transaction_subtype === "client_sourcing_request") {
            if (record.transaction_data.breakup !== undefined) {
              return (
                <span>
                  {" "}
                  {getCurrencySymbol(
                    record.transaction_currency === "USD" ? "USD" : "INR"
                  )}{" "}
                  {formatNumber(record.transaction_data.breakup.amount)}
                </span>
              );
            }
          } else if (record.transaction_subtype === "management_fee") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}

                {record.transaction_amount
                  ? formatNumber(record.transaction_amount)
                  : null}
              </span>
            );
          } else if (record.transaction_subtype === "prepaid_remittance") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}

                {record.transaction_amount
                  ? formatNumber(record.transaction_amount)
                  : null}
              </span>
            );
          } else if (
            record.transaction_subtype === "orderamount_gst_deductions"
          ) {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_amount
                  ? formatNumber(record.transaction_amount)
                  : null}
              </span>
            );
          } else if (record.transaction_subtype === "payment_gatway_charges") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}

                {record.transaction_amount
                  ? formatNumber(record.transaction_amount)
                  : null}
              </span>
            );
          } else if (record.transaction_subtype === "order_reverse_pickup") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.costs.shipping.base
                  ? formatNumber(record.transaction_data.costs.shipping.base)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "cod_remit_reverse") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.amount
                  ? formatNumber(record.transaction_data.amount)
                  : formatNumber(record.transaction_data.total)}
              </span>
            );
          } else if (record.transaction_subtype === "cod_remit_new_reverse") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.amount
                  ? formatNumber(record.transaction_data.amount)
                  : formatNumber(record.transaction_data.total)}
              </span>
            );
          } else if (
            record.transaction_subtype === "prepaid_remittance_reverse"
          ) {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}

                {record.transaction_amount
                  ? formatNumber(record.transaction_amount)
                  : null}
              </span>
            );
          } else if (
            record.transaction_subtype === "orderamount_gst_deductions_reverse"
          ) {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_amount
                  ? formatNumber(record.transaction_amount)
                  : null}
              </span>
            );
          } else if (record.transaction_subtype === "management_fee_reverse") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}

                {record.transaction_amount
                  ? formatNumber(record.transaction_amount)
                  : null}
              </span>
            );
          } else if (record.transaction_subtype === "inventory_rtv") {
            return (
              <span>
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}

                {record.transaction_amount
                  ? formatNumber(record.transaction_amount)
                  : null}
              </span>
            );
          }
        },
      },

      {
        title: "GST",
        dataIndex: "transaction_data",
        width: "10%",
        className: "transaction-details",
        render: (text, record) => {
          if (
            record.transaction_subtype === "order_placed_pp" ||
            record.transaction_subtype === "credit_note_pp"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.gst)}
              </span>
            );
          } else if (
            record.transaction_subtype === "order_placed_ff" ||
            record.transaction_subtype === "credit_note_ff"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.fulfilment.gst)}
              </span>
            );
          } else if (
            record.transaction_subtype === "order_placed_fs" ||
            record.transaction_subtype === "credit_note_fs"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.shipping.gst)}
              </span>
            );
          } else if (
            record.transaction_subtype === "order_placed_codf" ||
            record.transaction_subtype === "codf_reverse"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.cod.gst)}
              </span>
            );
          } else if (record.transaction_subtype === "buyback_handling_fee") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.gst)}
              </span>
            );
          } else if (record.transaction_subtype === "rto_handling_fee") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.rto_handling.gst)}
              </span>
            );
          } else if (record.transaction_subtype === "rto_shipping") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {formatNumber(record.transaction_data.costs.shipping.gst)}
              </span>
            );
          } else if (record.transaction_subtype === "cod_remit_new") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "client_sourcing_request") {
            if (record.transaction_data.breakup !== undefined) {
              return (
                <span>
                  {getCurrencySymbol(
                    record.transaction_currency === "USD" ? "USD" : "INR"
                  )}{" "}
                  {formatNumber(record.transaction_data.breakup.gst)}
                </span>
              );
            }
          } else if (
            record.transaction_subtype === "orderamount_gst_deductions"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (
            record.transaction_subtype === "orderamount_gst_deductions"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "management_fee") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "prepaid_remittance") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "payment_gatway_charges") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "order_reverse_pickup") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.costs.shipping.gst
                  ? formatNumber(record.transaction_data.costs.shipping.gst)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "cod_remit_reverse") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "cod_remit_new_reverse") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (
            record.transaction_subtype === "prepaid_remittance_reverse"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (
            record.transaction_subtype === "orderamount_gst_deductions_reverse"
          ) {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "management_fee_reverse") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.gst
                  ? formatNumber(record.transaction_data.gst)
                  : "0"}
              </span>
            );
          } else if (record.transaction_subtype === "inventory_rtv") {
            return (
              <span>
                {" "}
                {getCurrencySymbol(
                  record.transaction_currency === "USD" ? "USD" : "INR"
                )}{" "}
                {record.transaction_data.breakup.gst
                  ? formatNumber(record.transaction_data.breakup.gst)
                  : "0"}
              </span>
            );
          }
        },
      },

      {
        title: "Total Amt",
        dataIndex: "transaction_amount",
        width: "10%",
        render: (text, r) => (
          <span
            className={`${
              r.transaction_status === "pending" ||
              r.transaction_status === "in_processing"
                ? "orange-text"
                : r.transaction_type === "debit" ||
                  r.transaction_status === "declined"
                ? "red-text"
                : "green-text"
            }`}
          >
            {getCurrencySymbol(
              r.transaction_currency === "USD" ? "USD" : "INR"
            )}{" "}
            <span class="tooltip">
              {formatNumber(text)}
              <span class="tooltiptext">
                {r.transaction_status === "pending" ||
                r.transaction_status === "in_processing"
                  ? "Pending"
                  : r.transaction_type === "debit" ||
                    r.transaction_status === "declined"
                  ? " Debit"
                  : "Credit"}{" "}
              </span>
            </span>
          </span>
        ),
      },
      {
        title: "Type",
        dataIndex: "transaction_type",
        width: "10%",
        render: (type) => {
          const types = {
            credit: "Credit",
            debit: "Debit",
          };
          return types[type];
        },
      },

      {
        title: "Status",
        dataIndex: "transaction_status",
        width: "10%",
        render: (status) => {
          const statuses = {
            processed: "Processed",
            pending: "Pending",
            in_processing: "In Processing",
            failed: "Failed",
            declined: "Declined",
          };
          return statuses[status];
        },
      },

      {
        title: "Remarks",
        dataIndex: "transaction_external_remark",
        width: "20%",
      },

      // {
      //   title: "Effective Balance",
      //   dataIndex: "balance",
      //   width: "15%",
      //   render: text => (
      //     <span className="blue-text">
      //       {getCurrencySymbol("INR")} {formatNumber(text)}
      //     </span>
      //   )
      // },
      // {
      //   title: "Comments",
      //   dataIndex: "transaction_comments",
      //   width: "15%",
      //   render: text => <span className="blue-text">{text}</span>
      // }
    ];

    const globalTransactionHistoryColumns = [
      {
        title: "Transaction ID",
        dataIndex: "transaction_date",
        width: "10%",
        render: (text, record) => {
          return (
            <div>
              <span style={{ color: "#212121", fontWeight: "400" }}>
                {record.transaction_vfid}
              </span>
              <span
                style={{ fontSize: "10px", color: "#212121", display: "block" }}
              >
                {moment(text).format("DD MMM, YYYY")}
              </span>
            </div>
          );
        },
      },

      {
        title: " Store Name",
        dataIndex: "channel_name",
        width: "10%",
      },

      {
        title: "Transaction Head",
        dataIndex: "transaction_data",
        width: "25%",
        className: "transaction-details",
        render: (text, record) => {
          if (record.transaction_subtype === "order_placed") {
            return <span>{`${text.items.length} product(s) ordered`} </span>;
          } else if (record.transaction_subtype === "funds_added_admin") {
            return <span>Wallet Balance Added</span>;
          } else if (record.transaction_subtype === "razorpay_addfunds") {
            return <span>Wallet Balance Added via Razorpay</span>;
          } else if (record.transaction_subtype === "cashback_addfunds") {
            return <span>Cashback</span>;
          } else if (record.transaction_subtype === "coupon_addfunds") {
            return <span>Wallet Balance Added via Coupon</span>;
          } else if (record.transaction_subtype === "wire_addfunds") {
            return <span>Funds Added via Wire Transfer</span>;
          } else if (record.transaction_subtype === "withdraw_funds") {
            return <span>Wallet Balance Withdrawn</span>;
          } else if (record.transaction_subtype === "client_sourcing_request") {
            return <span>Sourcing Request</span>;
          } else if (record.transaction_subtype === "rto_remit") {
            return <span>RTO Remittance</span>;
          } else if (record.transaction_subtype === "cod_remit") {
            return <span>COD Remittance</span>;
          } else if (record.transaction_subtype === "cod_remit_new") {
            return <span>COD Remittance</span>;
          } else if (record.transaction_subtype === "credit_note") {
            return (
              <span>
                Credit Note
                {/*<Button
                  type="primary"
                  size="small"
                  className="review-button"
                  onClick={() => this.toggleReviewModal(record._id["$oid"])}
                >
                  Review
                </Button>*/}
              </span>
            );
          } else if (record.transaction_subtype === "admin_note") {
            return <span>Manual Note</span>;
          } else if (record.transaction_subtype === "order_reverse_pickup") {
            return <span>Order Reverse Pickup</span>;
          } else if (
            record.transaction_subtype === "verified_order_cancellation"
          ) {
            return <span>Verified Order Cancellation Fee</span>;
          } else if (record.transaction_subtype === "order_refund") {
            return <span>Order Refund</span>;
          } else if (record.transaction_subtype === "order_placed_pp") {
            return <span>Product Price</span>;
          } else if (record.transaction_subtype === "order_placed_ff") {
            return <span>Fulfilment Fees</span>;
          } else if (record.transaction_subtype === "order_placed_fs") {
            return <span>Forward Shipping</span>;
          } else if (record.transaction_subtype === "order_placed_codf") {
            return <span>COD Fees</span>;
          } else if (record.transaction_subtype === "storage_charges") {
            return <span>Storage Charges</span>;
          } else if (record.transaction_subtype === "rto_shipping") {
            return <span>RTO Shipping</span>;
          } else if (record.transaction_subtype === "rto_handling_fee") {
            return <span>RTO Handling Fee</span>;
          } else if (record.transaction_subtype === "buyback_handling_fee") {
            return <span>BuyBack Handling Fee</span>;
          } else if (record.transaction_subtype === "order_cancellation_fee") {
            return <span>Order Cancellation Fee</span>;
          } else if (record.transaction_subtype === "order_reverse_pickup") {
            return <span>Reverse Pick Up </span>;
          } else if (record.transaction_subtype === "codf_reverse") {
            return <span>Reverse COD Fees</span>;
          } else if (record.transaction_subtype === "inward_fees") {
            return <span>Inward Fees</span>;
          } else if (record.transaction_subtype === "credit_note_pp") {
            return <span>Product Price Credit</span>;
          } else if (record.transaction_subtype === "credit_note_ff") {
            return <span>Fullfillment Fee Credit</span>;
          } else if (record.transaction_subtype === "credit_note_fs") {
            return <span>Shipping Charges credit</span>;
          } else if (record.transaction_subtype === "payment_gatway_charges") {
            return <span>Payment Gatway Charges</span>;
          } else if (record.transaction_subtype === "prepaid_remittance") {
            return <span>Prepaid Remittance</span>;
          } else if (record.transaction_subtype === "management_fee") {
            return <span>Management Fees</span>;
          }
        },
      },

      {
        title: "Reference ID",
        dataIndex: "transaction_data",
        width: "10%",
        render: (text, record) => {
          return (
            <div>
              <span class="tooltip">
                {[
                  "order_placed_ff",
                  "order_placed_pp",
                  "order_placed_fs",
                  "order_placed_codf",
                  "credit_note_ff",
                  "credit_note_fs",
                  "credit_note_pp",
                  "codf_reverse",
                  "order_placed",
                  "order_cancellation_fee",
                  "order_refund",
                  "verified_order_cancellation",
                  "credit_note",
                  "management_fee",
                  "prepaid_remittance",
                  "orderamount_gst_deductions",
                  "payment_gatway_charges",
                ].indexOf(record.transaction_subtype) !== -1
                  ? record.transaction_data.vforderid
                  : [
                      "cod_remit",
                      "cod_remit_new",
                      "rto_remit",
                      "buyback_handling_fee",
                      "rto_handling_fee",
                      "rto_shipping",
                      "orderamount_gst_deductions",
                    ].indexOf(record.transaction_subtype) !== -1
                  ? record.transaction_data.awb
                  : ["client_sourcing_request"].indexOf(
                      record.transaction_subtype
                    ) !== -1
                  ? record.transaction_data.client_sourcing_request_vfid
                  : null}{" "}
                <span class="tooltiptext">
                  {[
                    "order_placed_ff",
                    "order_placed_pp",
                    "order_placed_fs",
                    "order_placed_codf",
                    "credit_note_ff",
                    "credit_note_fs",
                    "credit_note_pp",
                    "codf_reverse",
                    "order_placed",
                    "order_cancellation_fee",
                    "order_refund",
                    "verified_order_cancellation",
                    "credit_note",
                    "management_fee",
                    "prepaid_remittance",
                    "orderamount_gst_deductions",
                    "payment_gatway_charges",
                  ].indexOf(record.transaction_subtype) !== -1
                    ? "Vf Order id"
                    : [
                        "cod_remit",
                        "cod_remit_new",
                        "rto_remit",
                        "buyback_handling_fee",
                        "rto_handling_fee",
                        "rto_shipping",
                        "orderamount_gst_deductions",
                      ].indexOf(record.transaction_subtype) !== -1
                    ? "AWB"
                    : ["client_sourcing_request"].indexOf(
                        record.transaction_subtype
                      ) !== -1
                    ? "Sourcing request id"
                    : null}{" "}
                </span>
              </span>
              {[
                "order_placed_ff",
                "order_placed_pp",
                "order_placed_fs",
                "order_placed_codf",
                "credit_note_ff",
                "credit_note_fs",
                "credit_note_pp",
                "codf_reverse",
                "order_placed",
                "credit_note",
                "cod_remit",
                "cod_remit_new",
                "rto_remit",
                "buyback_handling_fee",
                "rto_handling_fee",
                "rto_shipping",
              ].indexOf(record.transaction_subtype) !== -1 ? (
                <Button
                  type="primary"
                  size="small"
                  className="clickable"
                  onClick={() => this.toggleReviewModal(record._id["$oid"])}
                >
                  Review
                </Button>
              ) : null}
            </div>
          );
        },
      },

      {
        title: "Total Amt",
        dataIndex: "transaction_amount",
        width: "10%",
        render: (text, r) => (
          <span
            className={`${
              r.transaction_status === "pending" ||
              r.transaction_status === "in_processing"
                ? "orange-text"
                : r.transaction_type === "debit" ||
                  r.transaction_status === "declined"
                ? "red-text"
                : "green-text"
            }`}
          >
            {getCurrencySymbol(
              r.transaction_currency === "USD" ? "USD" : "INR"
            )}{" "}
            <span class="tooltip">
              {formatNumber(text)}
              <span class="tooltiptext">
                {r.transaction_status === "pending" ||
                r.transaction_status === "in_processing"
                  ? "Pending"
                  : r.transaction_type === "debit" ||
                    r.transaction_status === "declined"
                  ? " Debit"
                  : "Credit"}{" "}
              </span>
            </span>
          </span>
        ),
      },
      {
        title: "Type",
        dataIndex: "transaction_type",
        width: "10%",
        render: (type) => {
          const types = {
            credit: "Credit",
            debit: "Debit",
          };
          return types[type];
        },
      },

      {
        title: "Status",
        dataIndex: "transaction_status",
        width: "10%",
        render: (status) => {
          const statuses = {
            processed: "Processed",
            pending: "Pending",
            in_processing: "In Processing",
            failed: "Failed",
            declined: "Declined",
          };
          return statuses[status];
        },
      },

      {
        title: "Remarks",
        dataIndex: "transaction_external_remark",
        width: "20%",
      },

      // {
      //   title: "Effective Balance",
      //   dataIndex: "balance",
      //   width: "15%",
      //   render: text => (
      //     <span className="blue-text">
      //       {getCurrencySymbol("INR")} {formatNumber(text)}
      //     </span>
      //   )
      // },
      // {
      //   title: "Comments",
      //   dataIndex: "transaction_comments",
      //   width: "15%",
      //   render: text => <span className="blue-text">{text}</span>
      // }
    ];

    const ds = new DataSet();
    const dv = ds.createView().source(this.state.transaction_graph.data);
    const fields = Object.keys(this.state.transaction_graph.data[0]).filter(
      (k) => k !== "name"
    );

    dv.transform({
      type: "fold",
      fields: fields,
      key: "Transactions",
      value: "Amount",
    });

    // const operations = (
    //   <div>
    //     <RangePicker
    //       style={{ marginLeft: "10px" }}
    //       // defaultValue={[
    //       //   this.state.transaction_graph.start,
    //       //   this.state.transaction_graph.end,
    //       // ]}
    //       value={[
    //         this.state.transaction_graph.startDate === ""
    //           ? null
    //           : moment(this.state.transaction_graph.startDate, "YYYY-MM-DD"),
    //         this.state.transaction_graph.endDate === ""
    //           ? null
    //           : moment(this.state.transaction_graph.endDate, "YYYY-MM-DD"),
    //       ]}
    //       onChange={(_, dateString) =>
    //         this.onDateChange({
    //           startDate: dateString[0],
    //           endDate: dateString[1],
    //         })
    //       }
    //       ranges={{
    //         Today: [moment(), moment()],
    //         "This Week": [moment().startOf("week"), moment().endOf("week")],
    //         "This Month": [moment().startOf("month"), moment().endOf("month")],
    //         "Last Month": [
    //           moment()
    //             .subtract(1, "month")
    //             .startOf("month"),
    //           moment()
    //             .subtract(1, "month")
    //             .endOf("month"),
    //         ],
    //       }}
    //     />
    //   </div>
    // );

    const currency =
      this.props.match.params.storegeo === "global" ? "USD" : "INR";

    if (this.props.loading2) {
      return <Skeleton />;
    }
    return (
      <>
        <div className="my-wallet">
          {/* <div className="alert-wrap-reporting">
            <Alert
              style={{ marginBottom: "40px" }}
              message="Important Information"
              description="Please note that the data represented on this page is approximately 2 hours delayed. It is recommended to keep the lag in mind for correct readings."
              type="info"
              showIcon
            />
            <span
              className="ex-ic"
              style={{
                width: "24px",
                height: "24px",
                fontSize: "24px",
                lineHeight: "24px",
                display: "inline-block",
                color: "#0068FF",
              }}
            >
              <Icon type="exclamation-circle" theme="filled" />
            </span>
          </div> */}
          <p className="Wallet_Stats_txt">Wallet Stats</p>
          <Row>
            <Col xs={24} md={24}>
              <div className="Wallet_stats">
                <Card loading={this.state.loading.todays_transactions}>
                  <div className="wallet-balance">
                    <div className="wallet-balance-title">
                      <p style={{ display: "flex", margin: 0 }}>
                        Your Current Balance
                      </p>
                    </div>
                    <div className="wallet-balance-number">
                      <div className="balance-amount">
                        <p>
                          {this.state.loading.balance ? (
                            <Spin />
                          ) : this.state.balance >= 0 ? (
                            <>
                              {getCurrencySymbol(currency)}{" "}
                              {formatBalance(this.state.balance)}
                              {/* {currency !== "USD" && (
                              <>
                                <br />
                                <span
                                  style={{
                                    color: "rgba(0,0,0,0.85)",
                                    fontSize: 18,
                                  }}
                                >
                                  (~ {getCurrencySymbol("USD")}{" "}
                                  {formatNumber(
                                    (
                                      this.state.balance / this.state.inrValue
                                    ).toFixed(2)
                                  )}
                                  )
                                </span>
                              </>
                            )} */}
                            </>
                          ) : (
                            <>
                              {getCurrencySymbol(currency)}{" "}
                              {formatBalance(this.state.balance)}
                              {/* {currency !== "USD" && (
                              <>
                                <br />
                                <span
                                  style={{
                                    color: "rgba(0,0,0,0.85)",
                                    fontSize: 18,
                                  }}
                                >
                                  (~ {getCurrencySymbol("USD")}{" "}
                                  {formatNumber(
                                    (
                                      this.state.balance / this.state.inrValue
                                    ).toFixed(2)
                                  )}
                                  )
                                </span>
                              </>
                            )} */}
                            </>
                          )}
                        </p>
                      </div>
                      <div className="balance-button">
                        {hasPermission(store, "add_funds_to_wallet") && (
                          <Button
                            className="add-balance-btn"
                            onClick={() => this.toggleAddFundsModal()}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{ marginRight: "8px", marginTop: "4px" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="20.498"
                                viewBox="0 0 21 20.498"
                              >
                                <path
                                  id="path39"
                                  d="M1.2,1.634,12.752,4.52a3.525,3.525,0,0,1,2.53,3.242V18.2a1.976,1.976,0,0,1-2.539,2.01l-9.7-2.282A3.463,3.463,0,0,1,.5,14.718V3.414A2.617,2.617,0,0,1,3.109.805H17.891A2.617,2.617,0,0,1,20.5,3.414v9.565a2.616,2.616,0,0,1-2.609,2.609H15.283"
                                  transform="translate(0 -0.305)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1"
                                />
                                <line
                                  id="line41"
                                  x2="7.423"
                                  transform="translate(10 3.5)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1"
                                />
                                <circle
                                  id="circle43"
                                  cx="2"
                                  cy="2"
                                  r="2"
                                  transform="translate(9.342 9.753)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1"
                                />
                                <line
                                  id="line45"
                                  x2="1.071"
                                  transform="translate(16 7.457)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1"
                                />
                              </svg>
                            </span>
                            Add Funds To Wallet
                          </Button>
                        )}
                        {this.props.match.params.storegeo === "in" ? (
                          <>
                            <Button
                              className="add-balance-btn"
                              onClick={() => this.toggleAddCouponModal()}
                              style={{
                                display: "flex",

                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{ marginRight: "8px", marginTop: "4px" }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18.498"
                                  height="21.63"
                                  viewBox="0 0 18.498 21.63"
                                >
                                  <g
                                    id="Group_3317"
                                    data-name="Group 3317"
                                    transform="translate(-4573.275 1072.519)"
                                  >
                                    <path
                                      id="Path_114"
                                      data-name="Path 114"
                                      d="M55.993,0h-14a1.993,1.993,0,0,0-2,1.981c0,19.486-.012,18.869.022,18.936a.327.327,0,0,0,.087.126.336.336,0,0,0,.282.082c.05-.009-.033.019,1.693-.632l1.631.616a.337.337,0,0,0,.237,0l1.631-.616,1.631.616a.336.336,0,0,0,.237,0l1.631-.616,1.631.616a.336.336,0,0,0,.237,0l1.631-.616c1.755.662,1.676.65,1.795.634a.333.333,0,0,0,.278-.245c.016-.062.011.533.011-8.743.652.388.655.407.778.407s.083.006,1.111-.605l.939.558a.334.334,0,0,0,.5-.283V1.981a1.993,1.993,0,0,0-2-1.981ZM52.458,19.83l-1.631.616L49.2,19.83a.337.337,0,0,0-.237,0l-1.631.616L45.7,19.83a.337.337,0,0,0-.237,0l-1.631.616L42.2,19.83a.336.336,0,0,0-.237,0l-1.3.49V1.981A1.328,1.328,0,0,1,41.994.66H54.5a1.974,1.974,0,0,0-.509,1.18c0,.021,0-.7,0,18.479l-1.3-.49A.336.336,0,0,0,52.458,19.83Zm4.868-8.2-.606-.36a.336.336,0,0,0-.343,0l-.94.558-.778-.462V1.981a1.305,1.305,0,0,1,.39-.933,1.336,1.336,0,0,1,2.277.933Z"
                                      transform="translate(4533.53 -1072.269)"
                                      fill="#fff"
                                      stroke="#fff"
                                      stroke-width="0.5"
                                    />
                                    <path
                                      id="Path_116"
                                      data-name="Path 116"
                                      d="M144.389,352h-8.028c-.177,0-.321.191-.321.427s.144.427.321.427h8.028c.177,0,.321-.191.321-.427S144.566,352,144.389,352Z"
                                      transform="translate(4441.783 -1409.88)"
                                      fill="#fff"
                                    />
                                    <path
                                      id="Path_117"
                                      data-name="Path 117"
                                      d="M144.389,312h-8.028c-.177,0-.321.191-.321.427s.144.427.321.427h8.028c.177,0,.321-.191.321-.427S144.566,312,144.389,312Z"
                                      transform="translate(4441.783 -1371.516)"
                                      fill="#fff"
                                    />
                                    <path
                                      id="Path_118"
                                      data-name="Path 118"
                                      d="M136.361,272.855h8.028c.177,0,.321-.191.321-.427s-.144-.427-.321-.427h-8.028c-.177,0-.321.191-.321.427S136.184,272.855,136.361,272.855Z"
                                      transform="translate(4441.782 -1333.15)"
                                      fill="#fff"
                                    />
                                    <path
                                      id="Path_119"
                                      data-name="Path 119"
                                      d="M143.478,392h-7.046a.429.429,0,0,0,0,.855h7.046a.429.429,0,0,0,0-.855Z"
                                      transform="translate(4441.461 -1448.246)"
                                      fill="#fff"
                                    />
                                    <path
                                      id="Path_120"
                                      data-name="Path 120"
                                      d="M81.322,272h-.855a.427.427,0,0,0,0,.855h.855a.427.427,0,0,0,0-.855Z"
                                      transform="translate(4495.622 -1333.15)"
                                      fill="#fff"
                                    />
                                    <path
                                      id="Path_121"
                                      data-name="Path 121"
                                      d="M81.322,312h-.855a.427.427,0,0,0,0,.855h.855a.427.427,0,0,0,0-.855Z"
                                      transform="translate(4495.622 -1371.516)"
                                      fill="#fff"
                                    />
                                    <path
                                      id="Path_122"
                                      data-name="Path 122"
                                      d="M81.322,352h-.855a.427.427,0,1,0,0,.854h.855a.427.427,0,1,0,0-.854Z"
                                      transform="translate(4495.622 -1409.88)"
                                      fill="#fff"
                                    />
                                    <path
                                      id="Path_123"
                                      data-name="Path 123"
                                      d="M81.322,392h-.855a.427.427,0,0,0,0,.855h.855a.427.427,0,0,0,0-.855Z"
                                      transform="translate(4495.622 -1448.246)"
                                      fill="#fff"
                                    />
                                    <text
                                      id="_"
                                      data-name="%"
                                      transform="translate(4577.865 -1062.269)"
                                      fill="#fff"
                                      font-size="8"
                                      font-family="Poppins-Medium, Poppins"
                                      font-weight="500"
                                    >
                                      <tspan x="0" y="0">
                                        %
                                      </tspan>
                                    </text>
                                  </g>
                                </svg>
                              </span>
                              Add Coupon
                            </Button>
                            {hasPermission(
                              store,
                              "withdraw_funds_from_wallet"
                            ) && (
                              <Button
                                className="clickable"
                                onClick={() => this.toggleWithdrawalModal()}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    marginRight: "8px",
                                    marginTop: "4px",
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                  >
                                    <g
                                      id="Polygon_42"
                                      data-name="Polygon 42"
                                      transform="translate(5.5 5)"
                                      fill="none"
                                    >
                                      <path d="M5.5,0,11,7H0Z" stroke="none" />
                                      <path
                                        d="M 5.5 1.618590831756592 L 2.057464599609375 6 L 8.942535400390625 6 L 5.5 1.618590831756592 M 5.5 0 L 11 7 L 0 7 L 5.5 0 Z"
                                        stroke="none"
                                        fill="#fff"
                                      />
                                    </g>
                                    <line
                                      id="Line_6"
                                      data-name="Line 6"
                                      y1="6"
                                      transform="translate(11 11)"
                                      fill="none"
                                      stroke="#fff"
                                      stroke-width="1"
                                    />
                                    <g
                                      id="Ellipse_49"
                                      data-name="Ellipse 49"
                                      fill="none"
                                      stroke="#fff"
                                      stroke-width="1.2"
                                    >
                                      <circle
                                        cx="11"
                                        cy="11"
                                        r="11"
                                        stroke="none"
                                      />
                                      <circle
                                        cx="11"
                                        cy="11"
                                        r="10.4"
                                        fill="none"
                                      />
                                    </g>
                                  </svg>
                                </span>
                                Withdraw Funds
                              </Button>
                            )}
                          </>
                        ) : (
                          <>
                            {hasPermission(
                              store,
                              "withdraw_funds_from_wallet"
                            ) && (
                              <Button
                                className="clickable"
                                onClick={() => this.toggleWithdrawalModal()}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    marginRight: "8px",
                                    marginTop: "4px",
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                  >
                                    <g
                                      id="Polygon_42"
                                      data-name="Polygon 42"
                                      transform="translate(5.5 5)"
                                      fill="none"
                                    >
                                      <path d="M5.5,0,11,7H0Z" stroke="none" />
                                      <path
                                        d="M 5.5 1.618590831756592 L 2.057464599609375 6 L 8.942535400390625 6 L 5.5 1.618590831756592 M 5.5 0 L 11 7 L 0 7 L 5.5 0 Z"
                                        stroke="none"
                                        fill="#fff"
                                      />
                                    </g>
                                    <line
                                      id="Line_6"
                                      data-name="Line 6"
                                      y1="6"
                                      transform="translate(11 11)"
                                      fill="none"
                                      stroke="#fff"
                                      stroke-width="1"
                                    />
                                    <g
                                      id="Ellipse_49"
                                      data-name="Ellipse 49"
                                      fill="none"
                                      stroke="#fff"
                                      stroke-width="1.2"
                                    >
                                      <circle
                                        cx="11"
                                        cy="11"
                                        r="11"
                                        stroke="none"
                                      />
                                      <circle
                                        cx="11"
                                        cy="11"
                                        r="10.4"
                                        fill="none"
                                      />
                                    </g>
                                  </svg>
                                </span>
                                Withdraw Funds
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <p className="Wallet_Stats_txt">Overall Stats</p>
              {this.props.graph ? (
                <Skeleton active />
              ) : (
                <ChartCard className="stats-card">
                  <div className="debit_credit">
                    <div>
                      <span style={{ marginRight: "8px" }}>
                        <span style={{ marginRight: "8px" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                          >
                            <circle
                              id="Oval_1526_Copy_257"
                              data-name="Oval 1526 Copy 257"
                              cx="5"
                              cy="5"
                              r="5"
                              fill="#c70c3c"
                            />
                          </svg>
                        </span>
                        Debit{" "}
                      </span>
                      <span>
                        <span style={{ marginRight: "8px" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                          >
                            <circle
                              id="Oval_1526_Copy_257"
                              data-name="Oval 1526 Copy 257"
                              cx="5"
                              cy="5"
                              r="5"
                              fill="#28af21"
                            />
                          </svg>
                        </span>
                        Credit
                      </span>
                    </div>
                    <div className="yaxis">
                      <p>X-Axis Plot: Period</p>
                      <p>|</p>
                      <p>Y-Axis Plot: Amount</p>
                    </div>
                  </div>
                  {this.state.loading.balance ? (
                    <Spin />
                  ) : this.state.transactions.length > 0 ? (
                    <Chart
                      height={288}
                      data={dv}
                      scale={{
                        Transactions: {
                          type: "cat",
                          tickCount: 10,
                        },
                      }}
                      forceFit
                      padding={{ top: 10, right: 30, bottom: 20, left: 48 }}
                    >
                      <Axis
                        name="Transactions"
                        label={{
                          formatter: (text) => {
                            return moment(text).format("DD MMM");
                          },
                        }}
                      />
                      <Axis
                        name="Amount"
                        label={{
                          formatter: (text) => {
                            return ` ${formatNumber(text, 0)}`;
                          },
                        }}
                      />
                      <Legend />
                      <Tooltip
                        crosshairs={{ type: "y" }}
                        containerTpl='<div class="g2-tooltip"><p class="g2-tooltip-title"></p><table class="g2-tooltip-list"></table></div>'
                        itemTpl={`<tr class=&quot;g2-tooltip-list-item&quot;><td style=&quot;color:{color}&quot;><span class=&quot;g2-tooltip-list-item-dot&quot;></span><span style="background-color:{color};width:10px;height:10px;border-radius:50%;display:inline-block;margin-right:8px;"></span> {name}: </td><td>${getCurrencySymbol(
                          currency
                        )} {value}</td></tr>`}
                        offset={50}
                        g2-tooltip={{
                          position: "absolute",
                          visibility: "hidden",
                          border: "1px solid #000",
                          borderRadius: "4px",
                          backgroundColor: "rgba(0, 0, 0, 0.9)",
                          color: "#fff",
                          opacity: "1",
                          padding: "1rem 1.25rem",
                          transition: "top 200ms,left 200ms",
                        }}
                      />
                      <Geom
                        type="interval"
                        position="Transactions*Amount"
                        color={[
                          "name",
                          (name) => {
                            if (name === "Debit") return "#C70C3C";
                            else return "#28AF21";
                          },
                        ]}
                        size={17}
                        adjust={[
                          {
                            type: "dodge",
                            marginRatio: 1 / 32,
                          },
                        ]}
                        style={{
                          stroke: "#fff",
                          lineWidth: 1,
                        }}
                      />
                    </Chart>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "16px",
                        background: "#fff",
                      }}
                    >
                      <div>
                        <Empty description={false} />
                      </div>

                      <div style={{ textAlign: "left" }}>
                        <h1 style={{ marginBottom: 0, fontSize: "20px" }}>
                          Not Enough Data!
                        </h1>
                        <p style={{ margin: 0, fontSize: "1rem" }}>
                          We could not find enough data...
                        </p>
                      </div>
                    </div>
                  )}
                </ChartCard>
              )}
            </Col>
          </Row>

          <TransactionSummary currency={currency} />

          <MyWalletReviewModal
            transactions={this.state.transactions}
            id={this.state.reviewModal.id}
            visible={this.state.reviewModal.visible}
            storegeo={this.props.store.store_geo}
            handleCancel={() => this.toggleReviewModal()}
          />

          {this.state.addFundsModal.visible && (
            <AddFundsModal
              clients={this.state.clients}
              inrValue={this.state.inrValue}
              visible={this.state.addFundsModal.visible}
              toggleAddFundsModal={() => this.toggleAddFundsModal()}
              stripeOption={user.user_kyc_origin === "non-india" ? true : false}
              fetchData={() => {
                this.fetchBalance();

                this.fetchTransactionGraph();
              }}
            />
          )}
          {this.state.withdrawalModal.visible && (
            <WithdrawalModal
              visible={this.state.withdrawalModal.visible}
              balance={this.state.balance}
              toggleWithdrawalModal={this.toggleWithdrawalModal}
              currency={currency}
            />
          )}
          <AddCouponModal
            visible={this.state.addCouponModal.visible}
            toggleAddCouponModal={() => this.toggleAddCouponModal()}
          />
          <WireTransferSuccessModal
            hideFooter={true}
            visible={this.state.wireModal.visible}
            id={this.state.wireModal.id}
            toggleSuccessModal={() => this.toggleWireModal()}
          />
          {this.state.redirectModal.visible && (
            <RedirectModal
              visible={this.state.redirectModal.visible}
              id={this.props.store.id}
              cancel={() => {
                this.cancelRedirectModal();
              }}
            />
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
    user: state.auth.auth.user,
  };
};
export default connect(mapStateToProps)(WithdrawalSummary);
