import React, { Component } from "react";
import { Modal, Radio, message, Button, Icon } from "antd";
import {
  SHOPIFY_CLIENT_ID,
  BASE_URL,
  SHOPIFY_NAME,
} from "../../../../../constants";
import { ReactComponent as indainflagican } from "../../../../../assets/images/icons/indianFlag.svg";
import { ReactComponent as globalflagican } from "../../../../../assets/images/icons/Globalflag.svg";
import ConnectToShopify from "../../../Settings/Shopify/ConnectToShopify";
import { ReactComponent as Shopify } from "../../../../../assets/images/icons/shopify2.svg";
// import { ReactComponent as Custom } from "../../../../assets/images/icons/custom.svg";
import { ReactComponent as Woocommerce } from "../../../../../assets/images/icons/wocommerce2.svg";
import { ReactComponent as greenContinueIcan } from "../../../../../assets/images/icons/greenContinueIcan.svg";
import "./index.scss";
export default class StoreTypeContant extends Component {
  state = {
    shopify: "Shopify",
    Woocommerce: "WooCommerce",
  };
  toggleShopifyState() {
    this.setState({
      shopify: "shopify",
    });
  }

  toggleContinueState() {
    this.setState({
      shopify: "Continue",
    });
  }

  toggleWocommerceState() {
    this.setState({
      Woocommerce: "WooCommerce",
    });
  }

  toggleWocommerceContinue() {
    this.setState({
      Woocommerce: "Continue",
    });
  }
  render() {
    return (
      <>
        <div className="pStore-type">
          <div>
            <p className="title_txt">{` Add New ${SHOPIFY_NAME}`} </p>
            <hr/>
            <p className="store_objective">
              {" "}
              {`Select the platform of your ${SHOPIFY_NAME} below to get started with integration`}
            </p>
          </div>
          <div>
            <div className="shopify_and_wooCommerece">
              <Radio.Group
                // defaultValue="shopify_store"
                value={this.props.selectedStoreType}
                onChange={(e) => this.props.handleStoreTypeChange(e)}
              >
                <div className="flag_radio_btn">
                  <div className="shopify_flag_radio_btn">
                    <div
                      onMouseEnter={() => this.toggleContinueState()}
                      onMouseLeave={() => this.toggleShopifyState()}
                      className={
                        this.state.selectedStoreType === "shopify_store"
                          ? "Shopify-type-card"
                          : "Wocoomerce-type-card"
                      }
                    >
                      <Radio value="shopify_store" className="Shopify_flag">
                        <Icon component={Shopify} />
                      </Radio>
                    </div>

                    <span className="logo_txt">
                      {this.state.shopify}
                      {this.state.shopify === "Continue" ? (
                        <Icon component={greenContinueIcan} />
                      ) : null}
                    </span>
                  </div>

                  <div className="shopify_flag_radio_btn">
                    <div
                      onMouseEnter={() => this.toggleWocommerceContinue()}
                      onMouseLeave={() => this.toggleWocommerceState()}
                      className={
                        this.state.selectedStoreType === "WoCommerce"
                          ? "Wocoomerce-type-card"
                          : "Shopify-type-card"
                      }
                    >
                      <Radio value="WoCommerce" className="wocommerce_flag">
                        <Icon component={Woocommerce} />
                      </Radio>
                    </div>
                    <span className="logo_txt">
                      {this.state.Woocommerce}
                      {this.state.Woocommerce === "Continue" ? (
                        <Icon component={greenContinueIcan} />
                      ) : null}
                    </span>
                  </div>
                </div>
              </Radio.Group>
            </div>

            <div className="footer_button_continue">
              <Button
                onClick={() => this.props.handleCancel()}
                style={{ width: "120px" }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
