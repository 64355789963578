import React, { Component } from "react";
import { Modal, Button, Icon } from "antd";
import "./index.scss";

class ShowImageModal extends Component {
  state = {
    index: 0,
    leftIconDisabled: true,
    rightIconDisabled: true,
  };
  componentDidUpdate(pp) {
    if (this.props.visible !== pp.visible) {
      this.setState(
        {
          index: this.props.defaultIndex,
        },
        () => {
          this.setState((state) => ({
            leftIconDisabled: state.index === 0,
            rightIconDisabled: state.index === this.props.files.length - 1,
          }));
        }
      );
    }
  }
  onLeftClick() {
    let currentIndex = this.state.index;
    currentIndex--;
    if (currentIndex < 0) {
      currentIndex = 0;
    }
    this.setState({
      index: currentIndex,
      leftIconDisabled: currentIndex === 0,
      rightIconDisabled: currentIndex === this.props.files.length - 1,
    });
  }

  onRightClick() {
    let currentIndex = this.state.index;
    currentIndex++;
    if (currentIndex >= this.props.files.length) {
      currentIndex = this.props.files.length - 1;
    }
    this.setState({
      index: currentIndex,
      leftIconDisabled: currentIndex === 0,
      rightIconDisabled: currentIndex === this.props.files.length - 1,
    });
  }

  render() {
    let files = this.props.files;
    let index = this.state.index;
    if (!files) {
      return null;
    }

    return (
      <Modal
        title={null}
        visible={this.props.visible}
        onCancel={(e) => this.props.toggleShowImageModal(e)}
        width={800}
        className="image-modal"
        footer={null}
      >
        <Button
          className="image-arrow"
          style={{ left: 0 }}
          onClick={() => this.onLeftClick()}
          disabled={this.state.leftIconDisabled}
        >
          <Icon type="left" />
        </Button>

        {files.length > 0 && (
          <img
            src={`${files[index]}`}
            style={{ width: "100%", height: "100%", position: "relative" }}
            alt="No found"
          />
        )}

        <Button
          className="image-arrow"
          style={{ right: 0 }}
          onClick={() => this.onRightClick()}
          disabled={this.state.rightIconDisabled}
        >
          <Icon type="right" />
        </Button>
      </Modal>
    );
  }
}
export default ShowImageModal;
