import React from "react";
import { Modal, message } from "antd";
import { SHOPIFY_CLIENT_ID, BASE_URL, SHOPIFY_NAME } from "../../../constants";
import axios from "axios";

import "./index.scss";

class AppUpdateModal extends React.Component {
  state = {
    geo: "in",
    selectedGeo: "global",
  };

  handleSubmit = (shop, geo) => {
    // geo = "in";

    axios({
      url: process.env.REACT_APP_API_URL + "/store/add_store",
      method: "post",
      data: {
        shop,

        geo,
        timezone: new Date().getTimezoneOffset(),
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          window.location =
            `https://${shop}.myshopify.com/admin/oauth/authorize?client_id=${SHOPIFY_CLIENT_ID}&scope=read_product_listings,read_products,write_products,read_orders,write_orders,read_inventory,write_customers,write_inventory,write_price_rules,read_draft_orders,write_draft_orders,read_customers,write_customers,read_checkouts,write_checkouts,read_fulfillments,read_merchant_managed_fulfillment_orders,write_merchant_managed_fulfillment_orders,read_assigned_fulfillment_orders,write_assigned_fulfillment_orders,read_third_party_fulfillment_orders,write_third_party_fulfillment_orders,write_fulfillments&redirect_uri=` +
            BASE_URL +
            `login/shopify_callback&state=${res.data.id}`;

          // let newUrl =
          //   `${shop}.myshopify.com/admin/oauth/authorize?client_id=${SHOPIFY_CLIENT_ID}&scope=read_product_listings,read_products,write_products,read_orders,write_orders,read_inventory,write_customers,write_inventory,write_price_rules,read_draft_orders,write_draft_orders,read_customers,write_customers,read_checkouts,write_checkouts,read_fulfillments,read_merchant_managed_fulfillment_orders,write_merchant_managed_fulfillment_orders,read_assigned_fulfillment_orders,write_assigned_fulfillment_orders,read_third_party_fulfillment_orders,write_third_party_fulfillment_orders,write_fulfillments&redirect_uri=` +
          //   BASE_URL +
          //   `/api/shopify_callback&state=${res.data.id}`;
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  render() {
    return (
      <Modal
        className="update-store-modal"
        visible={this.props.visible}
        onCancel={this.props.closeModal}
        footer={null}
      >
        <div class="imp-modal">
          <div class="icon">
            <svg
              id="settings"
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="80"
              viewBox="0 0 80 80"
            >
              <circle
                id="Ellipse_1"
                data-name="Ellipse 1"
                cx="39.709"
                cy="39.709"
                r="39.709"
                fill="#ffd400"
              />
              <path
                id="Path_1"
                data-name="Path 1"
                d="M133.392,93.739c0-.774-.025-1.543-.068-2.307L111.2,69.309l-1.042,3.055-3.3-.155L98.653,64,64,98.653l8.209,8.207,4.028,11.267,15.2,15.2c.763.044,1.532.068,2.307.068A39.652,39.652,0,0,0,133.392,93.739Z"
                transform="translate(-53.392 -53.392)"
                fill="#ff9f00"
              />
              <path
                id="Path_2"
                data-name="Path 2"
                d="M122.783,98.248V88.535l-7.968-1.584a23.091,23.091,0,0,0-1.722-4.168l4.443-6.648-6.889-6.889L104,73.69a23.094,23.094,0,0,0-4.168-1.722L98.248,64H88.535l-1.584,7.968a23.089,23.089,0,0,0-4.168,1.722l-6.648-4.443-6.889,6.889,4.443,6.648a23.1,23.1,0,0,0-1.722,4.168L64,88.535v9.713l7.968,1.584A23.089,23.089,0,0,0,73.69,104l-4.443,6.648,6.889,6.889,6.648-4.443a23.1,23.1,0,0,0,4.168,1.722l1.585,7.968h9.713l1.584-7.968A23.092,23.092,0,0,0,104,113.093l6.648,4.443,6.889-6.889L113.093,104a23.1,23.1,0,0,0,1.722-4.168Zm-29.392,7.2a12.055,12.055,0,1,1,12.055-12.055A12.068,12.068,0,0,1,93.392,105.447Z"
                transform="translate(-53.392 -53.392)"
                fill="#5a5a5a"
              />
              <path
                id="Path_3"
                data-name="Path 3"
                d="M279.2,99.833A22.148,22.148,0,0,1,277.333,104l4.811,6.648-7.459,6.889-7.2-4.443a26.2,26.2,0,0,1-4.513,1.722l-1.716,7.968H256V105.447c7.2,0,13.053-5.408,13.053-12.055S263.2,81.336,256,81.336V64h5.258l1.716,7.968a26.2,26.2,0,0,1,4.513,1.722l7.2-4.443,7.459,6.889-4.811,6.648a22.152,22.152,0,0,1,1.865,4.168l8.628,1.585v9.713Z"
                transform="translate(-218.433 -53.392)"
                fill="#444"
              />
            </svg>
          </div>
          <h1>Reinstall vFulfill App</h1>

          <h2>We recommend you to reinstall our new app to get</h2>

          <h3>benefits of all our new features and service launches:</h3>

          <p>
            1. Click{" "}
            <span>
              {" "}
              <a href={this.props.url}>here</a>{" "}
            </span>{" "}
            {`to connect with our new ${SHOPIFY_NAME} App.`}
          </p>
          <p>
            {`2. It will redirect you to your ${SHOPIFY_NAME} and would
            ask you to confirm the installation of the new vFulfill $
            {SHOPIFY_NAME} App, click on “Install App” here.`}
          </p>
          <p>
            3. You will be redirected back to your vFulfill Dashboard and the
            linking is complete.
          </p>
          <p>
            {` 4. Now open your ${SHOPIFY_NAME}  Admin in new tab and go to
            Apps -&gt; Delete the old vFulfill app having the app name as -
            vF-App.`}
          </p>

          <div class="btn">
            <a href={this.props.url}>
              <button className="clickable">
                {`Connect ${SHOPIFY_NAME} App`}
              </button>
            </a>
            <button onClick={this.props.closeModal}>Close Window</button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default AppUpdateModal;
