import React, { Component } from "react";
import { Col, Row, Icon, Button, Checkbox } from "antd";
import { ReactComponent as check } from "../../../../../../assets/images/icons/check.svg";
import { ReactComponent as eye } from "../../../../../../assets/images/icons/eye.svg";
import ShowImageModal from "../ShowImageModal";

import "./index.scss";

class ImportListProductImages extends Component {
  state = {
    selected: this.props.product.product_selected_gallery_images
      ? this.props.product.product_selected_gallery_images
      : [],
    showImageModal: {
      visible: false,
      index: 0,
      files: [],
    },
  };

  handleClick = (i) => {
    let selected = this.state.selected;
    if (selected.indexOf(i) === -1) {
      selected.push(i);
    } else {
      selected = selected.filter((index) => index !== i, []);
    }
    this.setState(
      {
        selected,
      },
      () =>
        this.props.onFieldChange(
          "product_selected_gallery_images",
          this.state.selected
        )
    );
  };

  selectAll() {
    if (
      this.state.selected.length === this.props.product.vfprod.gallery.length
    ) {
      this.setState({
        selected: [],
      });
    } else {
      this.setState({
        selected: this.props.product.vfprod.gallery.map((_, i) => i),
      });
    }
  }

  toggleShowImageModal(e, i = 0, files = []) {
    e.stopPropagation();
    this.setState({
      showImageModal: {
        visible: !this.state.showImageModal.visible,
        index: i,
        files: files,
      },
    });
  }

  render() {
    const { product } = this.props;


    const imageList = product.vfprod.gallery.map((url, i) => {
      let image;

      // if (!(i === 0)) {
        image = (
          <Col xs={24} md={4} key={i}>
            <div
              className={`img-container ${
                this.state.selected.indexOf(i) !== -1 ? "selected" : ""
              }`}
              style={{
                width: "100%",
                height: 0,
                paddingTop: "100%",
                background: `url(${url})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
              onClick={() => this.handleClick(i)}
            >
              {this.state.selected.indexOf(i) !== -1 && (
                <div className="select-container">
                  <Icon component={check} />
                </div>
              )}
              <Button
                className="eyeIcon"
                onClick={(e) =>
                  this.toggleShowImageModal(e, i, product.vfprod.gallery)
                }
              >
                <Icon component={eye} />
              </Button>
            </div>
          </Col>
        );
      // }

      return image;
    });

    return (
      <div>
        <Row style={{ marginBottom: ".625rem" }} className="select-deselect">
          {this.state.selected.length > 0 ? (
            <p>{this.state.selected.length} image(s) selected </p>
          ) : (
            <p>No image(s) selected</p>
          )}
          <Checkbox
            onClick={() => this.selectAll()}
            style={{ float: "right" }}
            checked={
              this.state.selected.length ===
              this.props.product.vfprod.gallery.length
                ? true
                : false
            }
          >
            {this.state.selected.length ===
            this.props.product.vfprod.gallery.length
              ? "Deselect All"
              : "Select All"}
          </Checkbox>
        </Row>

        <Row className="img-gallery" gutter={15} style={{ margin: 0 }}>
          <Col xs={24} md={24}>
            <Row className="all-imgs" gutter={15}>
              {imageList}
            </Row>
          </Col>
          {/* <Col xs={24} md={12} className="main-img">
            <div
              className={`img-container ${
                this.state.selected.indexOf(0) !== -1 ? "selected" : ""
              }`}
              style={{
                width: "100%",
                height: 0,
                paddingTop: "100%",
                background: `url(${product.vfprod.gallery[0]})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
              onClick={() => this.handleClick(0)}
            >
              {this.state.selected.indexOf(0) !== -1 && (
                <div className="select-container">
                  <Icon component={check} />
                </div>
              )}
            </div>
          </Col> */}
        </Row>
        <ShowImageModal
          visible={this.state.showImageModal.visible}
          toggleShowImageModal={(e) => this.toggleShowImageModal(e)}
          files={this.state.showImageModal.files}
          defaultIndex={this.state.showImageModal.index}
        />
      </div>
    );
  }
}

export default ImportListProductImages;
