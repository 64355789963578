import React, { Component } from "react";
import { Tooltip } from "antd";
import "./index.scss";
import moment from "moment";

class ViewRemark extends Component {
  render() {
    const ndr_remarks = this.props.ndr_remarks;

    return (
      <div className="view-remark-tab">
        <div
          style={{
            background: "white",
            padding: "12px 16px",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
          }}
        >
          {ndr_remarks && ndr_remarks.length > 0 ? (
            <div className="thead">
              <p className="flex-remarks">Remarks</p>

              {this.props.ndr.hasOwnProperty("ndr_bucket_code") ? (
                <p className="flex-bucket">NDR Bucket</p>
              ) : null}

              <p className="flex-logged">Logged On</p>
              <p className="flex-remarkby"> Remark By</p>
            </div>
          ) : null}
          {ndr_remarks && ndr_remarks.length > 0 ? (
            ndr_remarks &&
            ndr_remarks.map((note, idx) => (
              <div
                className="flex"
                key={idx}
                style={{
                  padding: "8px 16px",
                  background: "#F5F5F5",
                  marginBottom: 8,
                }}
              >
                <div className="flex-remarks">
                  {/* {note.remark && note.remark.length <= 45 ? (
                    note.remark
                  ) : (
                    <Tooltip title={note.remark}>
                      {" "}
                      {note.remark
                        ? `${note.remark.substring(0, 45)}...`
                        : null}
                    </Tooltip>
                  )} */}
                  {note && note.remark ? note.remark : null}
                </div>

                {this.props.ndr.hasOwnProperty("ndr_bucket_code") ? (
                  <p className="flex-bucket">
                    {this.props.bucket[this.props.ndr.ndr_bucket_code]}
                  </p>
                ) : null}

                <div className="flex-logged">
                  {moment(note.date).format("DD MMM, YYYY | HH:mm")}
                </div>
                <div className="flex-remarkby">{note.addedby}</div>
              </div>
            ))
          ) : (
            <div
              style={{
                padding: 16,
                textAlign: "center",
                background: "white",
                color: "#000",
                fontWeight: 500,
              }}
            >
              Remarks Not Found!
            </div>
          )}
        </div>

        {/* <Table
          rowKey={(ndr_remarks, i) => i}
          dataSource={ndr_remarks}
          pagination={false}
          columns={[
            {
              title: "Date",
              dataIndex: "date",
              render: (date) => moment(date).format("DD MMM YYYY | HH:mm"),
            },
            {
              title: "Remark",
              dataIndex: "remark",
            },
            {
              title: "Added By",
              dataIndex: "addedby",
              render: (name) => (
                <span style={{ textTransform: "capitalize" }}>{name}</span>
              ),
            },
          ]}
        /> */}
      </div>
    );
  }
}

export default ViewRemark;
