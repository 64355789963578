import React, { Component } from "react";
import { Input, Tooltip } from "antd";
import "./index.scss";

class Globalsearch extends Component {
  searchRef = React.createRef();
  searchRefHandler() {
    if (!!this.searchRef.current) {
      let icon = this.searchRef.current.querySelector(".ant-input-suffix");
      icon.addEventListener("click", () => this.props.searchFunc(""));
    }
  }
  componentDidMount() {
    this.searchRefHandler();
  }

  render() {
    return (
      <div
        ref={this.searchRef}
        className={`import-product-search ${
          this.props.totalwidth === 608 ? "import-product-search-large" : null
        }  ${this.props.class}`}
      >
        {this.props.tooltip ? (
          <Tooltip placement="bottomLeft" title={this.props.placeholder}>
            <Input.Search
              style={{ height: "40px", width: "auto" }}
              onFocus={{ background: "red" }}
              placeholder={this.props.placeholder.slice(0, 30) + "..."}
              onSearch={this.props.searchFunc}
              value={this.props.data.search}
              onChange={this.props.change}
              allowClear
              enterButton={
                <button
                className="search-click"
                  style={{
                    padding: "14px 40px",
                    border: "none",
                    background: "#FD104D",
                    color: "#fff",
                    fontSize: "12px",
                    // lineHeight: "16px",
                    width: "122px",
                    cursor: "pointer",
                    borderRadius: "0 2px 2px 0",
                    height: "40px",
                  }}
                >
                  Search
                </button>
              }
            />
          </Tooltip>
        ) : (
          <Input.Search
            style={{ height: "40px", width: "auto" }}
            onFocus={{ background: "red" }}
            placeholder={this.props.placeholder}
            onSearch={this.props.searchFunc}
            value={this.props.data.search}
            onChange={this.props.change}
            allowClear
            enterButton={
              <button
                style={{
                  padding: "14px 40px",
                  border: "none",
                  background: "#FD104D",
                  color: "#fff",
                  fontSize: "12px",
                  // lineHeight: "16px",
                  width: "122px",
                  cursor: "pointer",
                  borderRadius: "0 2px 2px 0",
                  height: "40px",
                }}
              >
                Search
              </button>
            }
          />
        )}
      </div>
    );
  }
}

export default Globalsearch;
