import React from "react";
import Lottie from "react-lottie";
import animationData from "./icon.json";
import "./index.scss";
import { Icon, Tooltip, Carousel, Button } from "antd";

import { ReactComponent as PlayIcon } from "../../../../assets/images/icons/PlayWhite.svg";
import VideoModal from "./Modal";

class VideoTooltip extends React.Component {
  state = {
    visible: false,
    videoPlaying: false,
    iconStop: true,
  };

  CarouselRef = React.createRef();

  render() {
    const defaultOptions = {
      // loop: true,
      // autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    const {
      title = "",
      subtitle = "",
      content = [],
      thumbnail,
      videolink,
      contentHeadings,
      showOverviewTour = false,
      showPageTour = false,
    } = this.props;

    return (
      <span className="global-tooltip-video">
        <Tooltip
          autoAdjustOverflow={false}
          placement="bottom"
          // visible
          overlayClassName="tooltip-video-div-overlay"
          title={
            <div className="tooltip-video-div">
              {!!videolink ? (
                <div className="left">
                  <div
                    onClick={() => this.setState({ visible: true })}
                    className="image-div"
                  >
                    {!!thumbnail ? (
                      <img src={thumbnail} />
                    ) : (
                      <video src={videolink} alt="" />
                    )}

                    <div className="svg">
                      <PlayIcon /> Watch Video
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="right">
                <h1>{title}</h1>
                {!!subtitle ? <h2>{subtitle}</h2> : null}

                <div className="html-div">
                  <Carousel dots={true} autoplay ref={this.CarouselRef}>
                    {content.map((d, i) => (
                      <div className="corousel-div">
                        <div className="corousel-heading">
                          <div className="serial">{i + 1}</div>
                          <div> {contentHeadings[i]} </div>
                        </div>
                        <p>
                          <b>{d}</b>
                        </p>
                      </div>
                    ))}
                  </Carousel>
                </div>
                <div className="bottom-area">
                  {showOverviewTour ? (
                    <button
                      onClick={() => this.props.showQuickOverTourHandler()}
                      className="quick-overview"
                    >
                      Quick Overview Tour
                    </button>
                  ) : null}

                  {showPageTour ? (
                    <button
                      onClick={() => this.props.showPageTourHandler()}
                      className="quick-page"
                    >
                      Quick Page Tour
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          }
        >
          <div
            className={`icon-wraper ${
              this.state.iconStop ? `` : `radiusIncrease`
            }`}
            onMouseEnter={() => this.setState({ iconStop: false })}
            onMouseLeave={() => this.setState({ iconStop: true })}
          >
            <Lottie
              options={defaultOptions}
              // isStopped={this.state.iconStop}
              height={18}
              width={18}
            />
          </div>
        </Tooltip>
        {this.state.visible ? (
          <VideoModal
            title={title}
            link={videolink}
            onClose={() => this.setState({ visible: false })}
            visible={this.state.visible}
          />
        ) : null}
      </span>
    );
  }
}

export default VideoTooltip;
