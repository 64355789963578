import React, { Component } from "react";
import { Alert, Skeleton, Row, Icon, Card, Select, Pagination } from "antd";
import SourcingCard from "./SourcingCard";
import axios from "axios";
import "./index.scss";
import NotFoundCard from "../../Common/NotFoundCard";
import ErrorCard from "../../Common/ErrorCard";
// import Globalsearch from "../../Common/Globalsearch";
import SourcingRequestNav from "./SourcingRequestNav";

import qs from "qs";
// import { tuple } from "antd/lib/_util/type";

// const { Search } = Input;
// const { Option } = Select;
class SourcingRequests extends Component {
  state = {
    filters: {
      search: "",
      status: [],
    },
    total: 0,
    paging: {
      page: 1,
      pageSize: 25,
    },
    orders: [],

    loading: false,
    error: false,
    errorMsg: "",
    errorCode: "",
    sourcingplaceholderkey: true,
  };

  fetchOrders() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL + "/sourcing/get_sourcing_requests",
          method: "POST",
          data: {
            storeid: this.props.match.params.storeid,
            filters: this.state.filters,
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                orders: res.data.data,
                total: res.data.total,
              });
            } else {
              this.setState({
                loading: false,
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((e) => {
            if (!!e.request) {
              this.setState({
                error: true,
                errorCode: e.request.status,
                errorMsg: e.request.statusText,
                loading: false,
              });
            } else {
              this.setState({
                error: false,
                errorMsg: e.message,
                loading: true,
              });
            }
          });
      }
    );
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search.replace(/\?/g, ""));
    if (params.search) {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: params.search,
          },
        },
        () => this.fetchOrders()
      );
    } else {
      this.fetchOrders();
    }
  }
  componentDidUpdate(pp) {
    if (pp.location.search !== this.props.location.search) {
      const params = qs.parse(this.props.location.search.replace(/\?/g, ""));
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: params.search,
          },
          paging: { page: 1, pageSize: 10 },
        },
        () => this.fetchOrders()
      );
    }
  }
  // componentDidMount() {
  //   this.fetchOrders();
  // }

  searchhandler() {
    if (window.location.href.includes("sourcing-requests")) {
      this.props.history.push(
        `/${this.props.match.params.storegeo}/${this.props.match.params.storeid}/requests/sourcing-requests?search=${this.state.filters.search}`
      );
    } else {
      this.props.history.push(
        `/${this.props.match.params.storegeo}/${this.props.match.params.storeid}/requests/sourcing-requests?search=${this.state.filters.search}`
      );
    }
  }

  changeFilter(filters) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...filters,
        },
        paging: { ...this.state.paging, page: 1 },
      },
      () => this.searchhandler(),
      () => this.fetchOrders()
    );
  }

  changeFilter2(filters) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...filters,
        },
        paging: { ...this.state.paging, page: 1 },
      },

      () => this.fetchOrders()
    );
  }

  goToPage(page) {
    this.setState(
      {
        loading: true,
        paging: {
          ...this.state.paging,
          page,
        },
      },
      () => this.fetchOrders()
    );
  }

  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        paging: { ...this.state.paging, pageSize: pageSize },
      },
      () => this.fetchOrders()
    );
  }

  changeHandler(kvpairs) {
    this.setState({
      filters: { ...this.state.filters, ...kvpairs },
    });
  }

  render() {
    const PageWrapper = (children) => {
      return (
        <div className="sourcing-requests-list">
          <SourcingRequestNav
            changeHandler={(e) => this.changeHandler(e)}
            changeFilter={(e) => this.changeFilter(e)}
            filters={this.state.filters}
            changeFilter2={(e) => this.changeFilter2(e)}
            storeid={this.props.match.params.storeid}
          />

          {children}
        </div>
      );
    };

    if (this.state.loading) {
      return PageWrapper(
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <ErrorCard
          errorMsg={this.state.errorMsg}
          errorCode={this.state.errorCode}
        />
      );
    }

    if (
      !this.state.loading &&
      !this.state.error &&
      this.state.orders.length === 0
    ) {
      return PageWrapper(
        <NotFoundCard
          mainHeading={"Oops! No Data Found."}
          secondHeading={<p>Looks like there are no products in this page.</p>}
        />
      );
    }

    return PageWrapper(
      <>
        {this.state.orders.map((o) => (
          <SourcingCard
            request={o}
            key={o._id["$oid"]}
            storeid={this.props.match.params.storeid}
            fetchOrders={() => this.fetchOrders()}
          />
        ))}

        <Pagination
          locale={{ jump_to: "Go to page" }}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          showQuickJumper
          showSizeChanger
          pageSizeOptions={["10", "25", "50"]}
          onShowSizeChange={(p, d) => this.onShowSizeChange(p, d)}
          current={this.state.paging.page}
          pageSize={this.state.paging.pageSize}
          total={this.state.total}
          onChange={(page) => this.goToPage(page)}
        />
      </>
    );
  }
}

export default SourcingRequests;
