import React, { Component } from "react";
import { Input, Icon, message } from "antd";
import { ReactComponent as editican } from "../../../../assets/images/icons/editican.svg";
import { ReactComponent as rightican } from "../../../../assets/images/icons/right.svg";
import "../index.scss";
import axios from "axios";
class SupportEmail extends Component {
  state = {
    value: "",
    disable: true,
    chnagenumber: false,
    loading: false,
    timerOn: true,
    minutes: 2,
    seconds: 0,
    myinterval: 0,
  };

  startTimer() {
    if (this.state.myinterval != 0) {
      clearInterval(this.state.myinterval);
    }
    this.setState(
      {
        minutes: 2,
        seconds: 0,
      },
      () => {
        this.state.myinterval = setInterval(() => {
          const { seconds, minutes } = this.state;
          if (seconds > 0) {
            this.setState(({ seconds }) => ({
              seconds: seconds - 1,
            }));
          }
          if (seconds === 0) {
            if (minutes === 0) {
              clearInterval(this.state.myinterval);
            } else {
              this.setState(({ minutes }) => ({
                minutes: minutes - 1,
                seconds: 59,
              }));
            }
          }
        }, 1000);
      }
    );
  }

  updateEmail() {
    // if (this.props.resetTimer) {
    this.startTimer();
    this.props.updateEmail();
    // } else {
    // this.props.updatePhonenumber();
    // }
  }

  resendOtp(event) {
    event.preventDefault();
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/generateotp",
          method: "post",
          withCredentials: true,
          data: {
            type: "email",
            location: "main",
            status: "resend",
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
              });
              message.success(res.data.msg);
              this.startTime();
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
            message.error(e.msg);
          });
      }
    );
  }

  inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  componentDidMount() {
    this.startTimer();
  }

  render() {
    return (
      <div className="support-email-phone-modal">
        <div>
          <div className="header-support-txt">
            <p>Your Email Address</p>
            {this.props.saveChangeEmail ? (
              <p>
                <button
                  className="save-number-txt"
                  onClick={() => this.updateEmail()}
                  loading={this.props.loading}
                >
                  <span className="save-ican">
                    <Icon component={rightican} />
                  </span>
                  Save Email Address
                </button>
              </p>
            ) : (
              <p>
                <button
                  className="change-number-txt"
                  onClick={() => this.props.togglesaveChangeEmail()}
                >
                  <span className="edit-ican">
                    <Icon component={editican} />
                  </span>
                  Change Email Address
                </button>
              </p>
            )}
          </div>
          <div className="input-number-data">
            {/* <p className="ind-91">IND +91</p> */}
            <p className="input-email-txt">
              {this.props.saveChangeEmail ? (
                <Input
                  type="text"
                  placeholder="Enter text here"
                  value={this.props.email}
                  onChange={(e) => this.props.handleEmailChange(e.target.value)}
                />
              ) : (
                <p className="disable-phonenumber">{this.props.email2}</p>
              )}
            </p>
          </div>
          <p className="otp-number-txt">
            Your will receive an OTP on this email address.
          </p>
        </div>
        <p className="otp-txt">Please wait 1-2 min for the OTP to arrive</p>
        <div>
          <form onSubmit={this.handleSubmit}>
            <div className="otp-verify">
              <input
                onPaste={(e) => this.props.pasteFuncEmail(e)}
                name="otp1"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.emailotp.emailotp1}
                onKeyPress={this.keyPressed}
                onChange={(e) => this.props.handleChange("emailotp1", e)}
                tabIndex="1"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp2"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.emailotp.emailotp2}
                onChange={(e) => this.props.handleChange("emailotp2", e)}
                tabIndex="2"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp3"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.emailotp.emailotp3}
                onChange={(e) => this.props.handleChange("emailotp3", e)}
                tabIndex="3"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp4"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.emailotp.emailotp4}
                onChange={(e) => this.props.handleChange("emailotp4", e)}
                tabIndex="4"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp5"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.emailotp.emailotp5}
                onChange={(e) => this.props.handleChange("emailotp5", e)}
                tabIndex="5"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp6"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.emailotp.emailotp6}
                onChange={(e) => this.props.handleChange("emailotp6", e)}
                tabIndex="6"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
            </div>
            <div className="resend-code">
              <span>
                <button
                  className={
                    this.state.minutes == 0 && this.state.seconds === 0
                      ? "resend-code-txt "
                      : "resend-code-txt2"
                  }
                  onClick={(e) => this.resendOtp(e)}
                  loading={this.state.loading}
                >
                  You can resend code in
                </button>
              </span>
              <span>
                {this.state.minutes}:
                {this.state.seconds < 9
                  ? `0${this.state.seconds}`
                  : this.state.seconds}
              </span>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default SupportEmail;
