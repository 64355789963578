import React, { Component } from "react";
import { Menu, Dropdown, Icon, Modal, message, Button } from "antd";
import "./index.scss";
import axios from "axios";
import { ReactComponent as GeoIn } from "../../../assets/images/icons/india.svg";
import { ReactComponent as GeoGlobal } from "../../../assets/images/icons/global.svg";
import { ReactComponent as Arrow } from "../../../assets/images/icons/arrow.svg";

class StoreGeoDropdown extends Component {
  state = {
    arrow: false,
    storeId: this.props && this.props.storeId,
    storeName: this.props && this.props.storeName,
    selectedGeo: undefined,
    modal: false,
    loading: false,
  };

  //   set geo function for geo

  setGeo() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/store/set_store_geo",
          method: "post",
          data: {
            storeid: this.state.storeId,
            geo: this.state.selectedGeo,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              message.success(res.data.msg);
              this.setState(
                {
                  loading: false,
                  modal: false,
                },
                () => this.props.loadData()
              );
            } else {
              message.error(res.data.msg);
              this.setState({
                loading: false,
                modal: false,
              });
            }
          })
          .catch((e) => {
            this.setState({
              error: true,
              errorMsg: e.message,
              loading: false,
              modal: false,
            });

            message.error(e.message);
          });
      }
    );
  }

  render() {
    const menu = (
      <Menu>
        <Menu.Item
          onClick={() => this.setState({ selectedGeo: "in", modal: true })}
        >
          <div className="flex">
            <Icon
              component={GeoIn}
              style={{ marginRight: "8px", fontSize: "24px" }}
            />
            <h3>India</h3>
          </div>
        </Menu.Item>
        <Menu.Item
          onClick={() => this.setState({ selectedGeo: "global", modal: true })}
        >
          <div className="flex">
            <Icon
              component={GeoGlobal}
              style={{ marginRight: "8px", fontSize: "24px" }}
            />
            <h3>Global</h3>
          </div>
        </Menu.Item>
      </Menu>
    );

    return (
      <>
        <Dropdown
          overlayClassName="select-geo"
          className="storgeo-dropdown"
          overlay={menu}
          onVisibleChange={(a) => this.setState({ arrow: !this.state.arrow })}
        >
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <span>Set Store Geo</span>
            <Icon
              className={`arrow ${this.state.arrow ? "arrow-fill" : null}`}
              component={Arrow}
              style={{
                marginLeft: "4px",
                fontSize: "16px",
              }}
            />
          </a>
        </Dropdown>

        {/* Modal for store geo */}

        <Modal
          visible={this.state.modal}
          footer={null}
          className="store-geo-modal"
          //   onOk={handleOk}
          onCancel={() =>
            this.setState({
              selectedGeo: undefined,
              modal: false,
              arrow: !this.state.arrow,
            })
          }
        >
          <h1>Confirm Your Selection</h1>
          <h2>Are you sure you want to make the following selection:</h2>
          <h5>
            <span>Note: </span>{" "}
            <span class="reverify_geo">
              Store GEO once set can not be changed. Re-verify the details
            </span>
          </h5>
          <div className="content">
            <div className="div1">
              <p>Store Name</p>
              <p>Store Geo</p>
            </div>
            <div className="div1">
              <p>{!!this.state.storeName ? this.state.storeName : "N/A"}</p>
              <p>
                {!!this.state.selectedGeo &&
                this.state.selectedGeo === "global" ? (
                  <div className="flex">
                    <Icon
                      component={GeoGlobal}
                      style={{ marginRight: "8px", fontSize: "24px" }}
                    />
                    <h3>Global</h3>
                  </div>
                ) : this.state.selectedGeo === "in" ? (
                  <div className="flex">
                    <Icon
                      component={GeoIn}
                      style={{ marginRight: "8px", fontSize: "24px" }}
                    />
                    <h3>India</h3>
                  </div>
                ) : null}
              </p>
            </div>
          </div>
          <div className="btns">
            <Button
              loading={this.state.loading}
              className="confirm clickable-dropdown"
              onClick={() => this.setGeo()}
            >
              {this.state.loading ? "" : "Confirm Store Geo"}
            </Button>
            <div
              className="cancel"
              onClick={() =>
                this.setState({
                  selectedGeo: undefined,
                  modal: false,
                  arrow: !this.state.arrow,
                })
              }
            >
              Cancel
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default StoreGeoDropdown;
