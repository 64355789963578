import React, { Component } from "react";
import { Modal, Upload, Icon, Button } from "antd";

const { Dragger } = Upload;

export default class NDREscalationModal extends Component {
  state = {
    fileList: [],
  };
  render() {
    const { fileList } = this.state;
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [file],
        }));
        return false;
      },
      fileList,
    };
    return (
      <Modal
        title="NDR Escalation Modal"
        visible={this.props.visible}
        onCancel={() => this.props.toggleNdrEscalationModal()}
        destroyOnClose={true}
        footer={[
          <Button
            key="back"
            onClick={() => this.props.toggleNdrEscalationModal()}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={this.props.uploading}
            onClick={() => this.props.confirmNdrEscalationModal(fileList)}
          >
            Submit
          </Button>,
        ]}
      >
        <p>Upload Escalation Files (jpg ,png, jpeg, wav, mp4, mp3,)</p>
        <Dragger {...props} accept=".jpg, .png, .jpeg, .webp, .wav, .mp4, .mp3">
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Dragger>
      </Modal>
    );
  }
}
