import React, { Component } from "react";
import "./index.scss";
import {
  Row,
  Form,
  Icon,
  Input,
  Button,
  Card,
  Checkbox,
  message,
  Switch,
  Radio,
  Select,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import qs from "qs";
import { Redirect } from "react-router-dom";
import ReactPhoneInput from "react-phone-input-2";
import { ReactComponent as RightArrow } from "../../../../assets/images/icons/rightarrow.svg";
import { ReactComponent as FacbookLogo } from "../../../../assets/images/icons/facebook.svg";
import { ReactComponent as GoogleLogo } from "../../../../assets/images/icons/googlelogo.svg";
import analytics from "../../../../analytics";
import { ReactComponent as VfulfillLogo } from "../../../../assets/images/icons/vFULLfillLogo.svg";
class SignupSection extends Component {
  state = {
    email: "",
    signupapiurl: "/login/platform_signup",
    errorPhone: false,
    contactno: "",
    prefix_1: "+91",
    loading: false,
    interval: "",
    plan_name: "",
    trial: "",
    identifyself: "",
    ecom_cod_experience: "",
  };
  numberValueHandler(dialcode, number) {
    return `${dialcode}` + `${number}`;
  }
  handleIdentifySelfChange(e) {
    this.setState({
      identifyself: e.target.value,
    });
  }
  handleEcomCodExperienceChange(e) {
    this.setState({
      ecom_cod_experience: e.target.value,
    });
  }
  handleContactChange(ob) {
    let onlyNumber = ob.value
      .replace(/[^0-9]+/g, "")
      .slice(
        !!Object.entries(ob.extraVal).length ? ob.extraVal.dialCode.length : 0
      );

    if (ob.value === 0 || ob.value === "") {
      this.setState({
        contactno: "",
        prefix_1: "",
      });
    } else if (parseInt(ob.value)) {
      if (onlyNumber.length === 10) {
        this.setState({
          errorPhone: false,
        });
      }

      this.setState({
        contactno: onlyNumber,
        prefix_1: ob.extraVal.dialCode,
      });
    }
  }
  componentDidMount() {
    const params = qs.parse(window.location.search.replace(/\?/g, ""));
    if (params.email) {
      this.setState({ email: params.email });
    }
    if (params.plan) {
      this.setState({ plan_name: params.plan });
    }
    if (params.trial) {
      this.setState({ trial: params.trial });
    }
    if (params.intv) {
      this.setState({ interval: params.intv });
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();

    analytics.track("Email Verification Clicked");
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.state.contactno.length !== 10) {
          this.setState({
            errorPhone: true,
          });
          return;
        }
        values.user_phone = {
          code: this.state.prefix_1,
          number: this.state.contactno,
        };
        values.interval = this.state.interval;
        values.plan_name = this.state.plan_name;
        values.trial = this.state.trial;
        this.props.getCredentials(values.email, values.password);
        this.setState({ loading: true }, () => {
          axios({
            url: process.env.REACT_APP_API_URL + this.state.signupapiurl,
            method: "post",
            data: {
              ...values,
            },
            withCredentials: true,
          })
            .then((res) => {
              this.setState({ loading: false });
              if (res.data.success === 1) {
                analytics.track("Email Verification Success");

                this.props.changeTab();
              } else {
                message.error(res.data.msg);
              }
            })
            .catch((e) => {
              message.error(e.message);
              this.setState({ loading: false });
            });
        });
      } else {
        if (this.state.contactno.length !== 10) {
          this.setState({
            errorPhone: true,
          });
        }
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { auth } = this.props;
    return (
      <div className="right-card-signupcontainer">
        <div class="logoRightContainer">
          <VfulfillLogo />
        </div>
        <div className="heading-bar">
          <div className="main-heading">Let’s create your account!</div>
          <div className="sub-heading">Easy to Start, 100% Hassle Free!</div>
        </div>
        <Form onSubmit={this.handleSubmit} className="signup-form">
          <div class="flex-from">
            <div className="flex-form-inner">
              <Form.Item>
                {getFieldDecorator("name", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your name!",
                    },
                  ],
                })(
                  <Input
                    className="input-signup23"
                    placeholder={"Enter your name "}
                  />
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator("email", {
                  initialValue: this.state.email,
                  rules: [
                    { required: true, message: "Please input your email!" },
                    {
                      type: "email",
                      message: "Please enter a valid email address.",
                    },
                  ],
                })(
                  <Input
                    className="input-signup23"
                    placeholder="Your Email Id"
                  />
                )}
                <div className="otp-info">
                  Your will receive an OTP on this email address.
                </div>
              </Form.Item>

              <Form.Item>
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter a  password!",
                    },
                    // {
                    //   pattern: /^(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*\d).{6,}$/,
                    //   message:
                    //     "Password: 6+ chars, 1 special, 1 uppercase, 1 number",
                    // },
                    {
                      min: 5,
                      message: "Password must be at least 5 characters long",
                    },
                  ],
                })(
                  <Input.Password
                    className="input-signup22"
                    type="password"
                    placeholder="Your Password"
                  />
                )}
              </Form.Item>

              <Form.Item className="phone-relative">
                {!this.state.contactno && <p>Your Whatsapp Number</p>}

                <ReactPhoneInput
                  placeholder=""
                  className={
                    this.state.errorPhone
                      ? "phoneinput error-phone-input"
                      : "phoneinput"
                  }
                  value={this.numberValueHandler("+91")}
                  onChange={(val, extra) =>
                    this.handleContactChange({
                      value: val,
                      extraVal: extra,
                      field: "contactno",
                    })
                  }
                  enableSearchField
                />
                {this.state.errorPhone ? (
                  <div className="error-phone">
                    Please enter a valid Whatsapp Number!
                  </div>
                ) : null}
              </Form.Item>
              <Form.Item className="question-container question-container-first">
                <div className="headingquestion">
                  How do you identify yourself?
                </div>
                {getFieldDecorator("identification", {
                  rules: [
                    {
                      required: true,
                      message: "Please select one option !",
                    },
                  ],
                })(
                  <Radio.Group
                    className="option-group"
                    onChange={(e) => this.handleIdentifySelfChange(e)}
                  >
                    <Radio className="option" value={"dropshipper"}>
                      Dropshipper
                    </Radio>
                    <Radio className="option" value={"d2c"}>
                      D2C
                    </Radio>
                    <Radio className="option" value={"affiliate"}>
                      Affiliate / Media Buyer
                    </Radio>
                    <Radio className="option" value={"others"}>
                      Network / Agency
                    </Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              {this.state.identifyself === "dropshipper" ||
              this.state.identifyself === "d2c" ? (
                <Form.Item className="question-container input-signup22">
                  <div className="headingquestion">Average Daily Orders?</div>
                  {getFieldDecorator("dailyorders", {
                    rules: [
                      {
                        required: true,
                        message: "Please select one option!",
                      },
                    ],
                  })(
                    // <select options={[
                    //   {value: "Yes", label: "Yes"},
                    //   {value: "No", label: "No"},
                    // ]} placeholder="Average Daily Orders?" className="input-signup22" />
                    <select>
                      <option value="0">Select Option</option>
                      <option value="yet_to_start">Yet to Start</option>
                      <option value="10-50">10-50 Orders/Day</option>
                      <option value="50-100">50-100 Orders/Day</option>
                      <option value="100-250">100-250 Orders/Day</option>
                      <option value="500plus">500+ Orders/Day</option>
                    </select>
                  )}
                </Form.Item>
              ) : null}

              <Form.Item className="question-container">
                <div className="headingquestion">
                  Any experience with India COD eCommerce?
                </div>
                {getFieldDecorator("ecom_experience", {
                  rules: [
                    {
                      required: true,
                      message: "Please select one option !",
                    },
                  ],
                })(
                  <Radio.Group
                    className="option-group"
                    onChange={(e) => this.handleEcomCodExperienceChange(e)}
                  >
                    <Radio className="option" value="yes">
                      Yes
                    </Radio>
                    <Radio className="option" value="no">
                      No
                    </Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              {this.state.ecom_cod_experience === "yes" ? (
                <Form.Item className="question-container">
                  <div className="headingquestion">Mode of operations?</div>
                  {getFieldDecorator("modeoperations", {
                    rules: [
                      {
                        required: true,
                        message: "Please select one option !",
                      },
                    ],
                  })(
                    <Radio.Group className="option-group">
                      <Radio className="option" value="dropshipping">
                        Dropshipping
                      </Radio>
                      <Radio className="option" value="selfshipping">
                        Self Shipping
                      </Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
              ) : null}

              <Form.Item className="question-container question-container-last">
                <div className="headingquestion">
                  What are you most interested in?
                </div>
                {getFieldDecorator("interests", {
                  rules: [
                    {
                      required: true,
                      message: "Please select one option !",
                    },
                  ],
                })(
                  <Radio.Group className="option-group">
                    <Radio className="option" value="end_to_end_cod_e_commerce">
                      End to End E-Commerce
                    </Radio>
                    <Radio className="option" value="product_sourcing">
                      Product Sourcing
                    </Radio>
                    <Radio className="option" value="winning_products">
                      Winning Products
                    </Radio>
                    <Radio className="option" value="order_fulfillment">
                      Order Fulfillment
                    </Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </div>
          </div>
          <div class="flex-form2">
            <Button
              htmlType="submit"
              className="submitbutton"
              loading={this.state.loading}
            >
              Continue to email verification
              <span className="rightlogocontainer">
                {" "}
                <RightArrow />
              </span>
            </Button>
            <div className="signup-line">
              Already have an account?{" "}
              <Link to="/login" className="login-form-signup-here">
                Log In Here
              </Link>
            </div>
            {/* <a
              className="facebookbutton"
              href={process.env.REACT_APP_API_URL + "/login/fb"}
              onClick={() => {
                analytics.track("Facebook Signup Clicked");
              }}
            >
              Sign Up with{" "}
              <span className="facebookcontainer">
                <FacbookLogo />
              </span>{" "}
              Facebook
            </a>
            <a
              className="signupbutton"
              href={process.env.REACT_APP_API_URL + "/auth/googleLogin"}
              onClick={() => {
                analytics.track("Google Signup Clicked");
              }}
            >
              Sign Up with{" "}
              <span className="googlecontainer">
                <GoogleLogo />
              </span>{" "}
              Google
            </a> */}
          </div>
        </Form>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};
export default connect(mapStateToProps)(Form.create()(SignupSection));
