import React, { Component } from "react";
import { Tabs, Card, Skeleton } from "antd";
import BasicDetails from "./BasicDetails";
import AdvanceDetails from "./AdvancedDetails";
import { connect } from "react-redux";
import { hasPermission } from "../../../Helpers/Permissions";
import Userprofilenav from "./Profilenav";
import "./index.scss";
import axios from "axios";
import { removeRedirect } from "../../../store/actions/authActions";
import RateCards from "../RateCards";
import PlanAndBilling from "./PlanAndBilling";
const { TabPane } = Tabs;

class Profile extends Component {
  state = {
    saving: false,
    loading: true,
    pending: false,
    error: false,
    kycdata: { address: {} },
    user: "",
    files: [],

    userprofile: {
      basicdetailbtn: true,
      advancedetailbtn: false,
      rateCardDetailbtn: false,
      plansandBilling: false,
    },
    userloading: false,
    basicDetails: {},
    usererror: false,
  };

  toggleuserprofile(statedata4) {
    this.setState({
      userprofile: { ...statedata4 },
    });
  }

  getuserdetail() {
    this.setState(
      {
        userloading: true,
      },
      () =>
        axios({
          url: process.env.REACT_APP_API_URL + "/user/get_user_basic_details",
          method: "post",
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                userloading: false,
                basicDetails: res.data.basicDetails,
              });
            } else {
              this.setState({
                userloading: false,
                usererror: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((e) => {
            this.setState({
              userloading: false,
              usererror: true,
              errorMsg: e.message,
            });
          })
    );
  }

  fetchKycData() {
    axios({
      url: process.env.REACT_APP_API_URL + "/user/get_user_kyc",
      method: "post",
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            pending: Boolean(Number(res.data.pending)),
            loading: false,
            kycdata: res.data.kycdata,
            user: res.data.user,
          });
        } else {
          this.setState({
            loading: false,
            error: true,
            errorMsg: res.data.msg,
          });
        }
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: true,
          errorMsg: e.message,
        });
      });
  }

  componentDidMount() {
    this.fetchKycData();
    this.getuserdetail();
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active />;
    }
    const { store } = this.props;
    // let defaultActive = "1";
    // if (!hasPermission(store, "view_organisation_kyc_bank_data")) {
    //   defaultActive = "2";
    // }
    return (
      <div className="profile-settings">
        <Userprofilenav
          toggleuserprofile={(e) => this.toggleuserprofile(e)}
          laoding={this.state.userloading}
        />
        <br />
        <br />
        <br />
        {/* <div
          className="profile-settings-tab-header"
          style={{
            padding: "20px 32px",
            backgroundColor: "#16273B",
            borderRadius: "4px 4px 0px 0px",
            marginTop: "48px",
          }}
        >
          <h2
            style={{
              color: "#FFFFFF",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "16px",
            }}
          >
            Profile Details
          </h2>
        </div> */}
        <Card>
          {!!this.state.userprofile.basicdetailbtn && (
            <div>
              {hasPermission(store, "view_organisation_kyc_bank_data") && (
                <BasicDetails
                  basicDetails={this.state.basicDetails}
                  laoding={this.state.userloading}
                  error={this.state.usererror}
                  getuserdetail={() => this.getuserdetail()}
                  errorMsg={this.state.errorMsg}
                />
              )}
            </div>
          )}
          {!!this.state.userprofile.advancedetailbtn && (
            <div>
              {hasPermission(store, "view_organisation_kyc_bank_data") && (
                <AdvanceDetails
                  user={this.state.user}
                  kycdata={this.state.kycdata}
                  fetchKycData={() => this.fetchKycData()}
                />
              )}
            </div>
          )}

          {/* {this.state.userprofile.basicdetailbtn ? (
            <div>
              {hasPermission(store, "view_organisation_kyc_bank_data") && (
                <BasicDetails
                  basicDetails={this.state.basicDetails}
                  laoding={this.state.userloading}
                  error={this.state.usererror}
                  getuserdetail={() => this.getuserdetail()}
                  errorMsg={this.state.errorMsg}
                />
              )}
            </div>
          ) : (
            <div>
              {hasPermission(store, "view_organisation_kyc_bank_data") && (
                <AdvanceDetails
                  user={this.state.user}
                  kycdata={this.state.kycdata}
                  fetchKycData={() => this.fetchKycData()}
                />
              )}
            </div>
          )} */}

          {/* <Tabs tabPosition="left" defaultActiveKey={defaultActive}>
            {hasPermission(store, "view_organisation_kyc_bank_data") && (
              <TabPane tab="Basic Details" key="1">
                <BasicDetails />
              </TabPane>
            )}

            {this.props.store.store_role === "owner" ? (
              <TabPane tab="Advanced Details" key="2">
                <AdvanceDetails
                  user={this.state.user}
                  kycdata={this.state.kycdata}
                  fetchKycData={() => this.fetchKycData()}
                />
              </TabPane>
            ) : null}
          </Tabs> */}
        </Card>
        {hasPermission(store, "view_organisation_kyc_bank_data") &&
          !!this.state.userprofile.rateCardDetailbtn && (
            <div>
              <RateCards />
            </div>
          )}
        {hasPermission(store, "view_organisation_kyc_bank_data") &&
          !!this.state.userprofile.plansandBilling && (
            <div>
              <PlanAndBilling auth={this.props} />
            </div>
          )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeRedirect: () => dispatch(removeRedirect()),
  };
};

const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
