import React from "react";
import {
  // Button,
  Icon,
  Checkbox,
  Tooltip,
  InputNumber,
  // message,
} from "antd";
import "../../index.scss";
import { Link } from "react-router-dom";
import {
  getCurrencySymbol,
  formatNumber,
} from "../../../../../../../Helpers/Pricing";
import { ReactComponent as ShipmentStatusIcon } from "../../../../../../../assets/images/icons/shopping-cart.svg";
import moment from "moment";
import { hasPermission } from "../../../../../../../Helpers/Permissions";
import OrderProductTitle from "../../../../../Common/GlobalCommon/ProductTitle/OrderTitle";

class LineItems extends React.Component {
  state = {
    order: this.props.order,
  };

  orderPlacedOn(li) {
    if (
      li.item_shipmentstatus === "delivered" ||
      li.item_shipmentstatus === "rto" ||
      li.item_vforderstatus === "cancelled" ||
      li.item_shipmentstatus === "transit" ||
      li.item_shipmentstatus === "rto_in_transit"
    ) {
      return false;
    }

    if (
      li.item_shipmentstatus === "processing" ||
      li.item_shipmentstatus === "manifested" ||
      li.item_vforderstatus === "verified_ordered"
    ) {
      return true;
    }

    return false;
  }

  render() {
    const {
      shipmentStatusColors,
      shipmentStatusText,
      vfOrderStatusColors,
      vfOrderStatusIcons,
      vfOrderStatusText,
    } = this.props;
    let serviceable = true;
    if (this.state.order.is_servicable === 0) {
      serviceable = false;
    }

    return (
      <div
        className={`tab-container ${
          this.props.activeTab !== "lineItems" ? "hidden" : ""
        }`}
      >
        <div className="line-items-tab">
          <div className="line-items-tab_header_upper_data">
            <p className="flex33">Variant Details</p>
            <p className="flex44">Order Status</p>
            <p className="flex55">
              <div className="Quantity">Quantity</div>
              <div className="star">x</div>
              <div className="Shopify_Selling_Price">Shopify Selling Price</div>
            </p>
            <p
              className={
                !!this.props.pageTab &&
                this.props.pageTab[0] === "Verified" &&
                this.props.provisional
                  ? "verify-linked-bundle"
                  : "flex66"
              }
            >
              Action
            </p>
          </div>

          {this.props.lineItems.map((li) =>
            (this.props.pageType === "openorders" &&
              this.props.pageTab.includes("notlinked") &&
              (li.item_shipmentstatus === "delivered" ||
                li.item_vforderstatus === "cancelled" ||
                li.item_vforderstatus === "not_fulfill" ||
                li.item_vforderstatus === "open")) ||
            (this.props.pageType === "openorders" &&
              this.props.pageTab.length === 0 &&
              (li.item_shipmentstatus === "delivered" ||
                li.item_vforderstatus === "cancelled" ||
                li.item_vforderstatus === "not_fulfill" ||
                li.item_vforderstatus === "notlinked")) ? null : (
              <div
                className={`line-item ${
                  this.props.selectedLineItems.find(
                    (i) => i === li.lineitem_id && !!serviceable
                  ) !== undefined
                    ? "selected"
                    : ""
                }`}
                key={li.lineitem_id}
              >
                <div className="line-item-product-detail">
                  <span style={{ marginRight: "8px" }}>
                    {serviceable ? (
                      <Checkbox
                        disabled={
                          this.props.disabledCheckbox ||
                          this.props.provisional ||
                          [
                            "verified_ordered",
                            "cancelled",
                            "notlinked",
                            "not_fulfill",
                          ].indexOf(li.item_vforderstatus) !== -1
                        }
                        checked={
                          this.props.selectedLineItems.find(
                            (i) => i === li.lineitem_id
                          ) !== undefined
                        }
                        onChange={() =>
                          this.props.lineItemSelectedChange(li.lineitem_id)
                        }
                      />
                    ) : null}
                  </span>
                  <span>
                    <span
                      className="line-item-img"
                      style={{ background: `url(${li.img})` }}
                    />
                  </span>
                  <span>
                    <p className="blue-text line-item-name">
                      {/* <Tooltip title={li.name} placement="topLeft">
          {li.name}
        </Tooltip> */}
                      <OrderProductTitle
                        orderProductname={li.name}
                        productShopifyid={li.product_shopifyid}
                        storeShopifyadmin={li.product_link}
                      />
                    </p>

                    <p className="line-item-name-sku">
                      <span
                        style={{
                          fontSize: "12px",
                          color: "#898989",
                          fontWeight: 400,
                        }}
                      >
                        SKU:{" "}
                      </span>{" "}
                      {li.sku ? (
                        <span style={{ color: "#000" }}>
                          {" "}
                          <Tooltip title={li.sku} placement="topLeft">
                            #{li.sku}
                          </Tooltip>
                        </span>
                      ) : (
                        <span style={{ fontSize: "12px", color: "#000" }}>
                          Not Available
                        </span>
                      )}
                    </p>

                    {li.bundle && li.bundle.length > 0 ? null : li.vfsku ? (
                      <p className="line-item-sku">
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#898989",
                            fontWeight: 400,
                          }}
                        >
                          VFSKU:{" "}
                        </span>{" "}
                        <Link
                          to={`/${this.props.match.params.storegeo}/${
                            this.props.match.params.storeid
                          }/products/${
                            li.vfsku.split("-")[li.vfsku.split("-").length - 2]
                          }`}
                          // onClick={(e) => {
                          //   this.vfLink(e);
                          // }}
                          target="_blank"
                        >
                          {li.vfsku}{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 17.528 17.811"
                          >
                            <g
                              id="Open_In_New_Window_Icon"
                              transform="translate(-961.212 -332.939)"
                            >
                              <path
                                id="Path_84"
                                data-name="Path 84"
                                d="M-4591.894-691.587h-4.312v16h16v-4.1"
                                transform="translate(5558.167 1025.587)"
                                fill="none"
                                stroke="#000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                              />
                              <g
                                id="Group_3178"
                                data-name="Group 3178"
                                transform="translate(968.509 334)"
                              >
                                <path
                                  id="Path_85"
                                  data-name="Path 85"
                                  d="M-4590.015-682.718l9.17-9.27"
                                  transform="translate(4590.015 691.988)"
                                  fill="none"
                                  stroke="#000"
                                  stroke-linecap="round"
                                  stroke-width="1.5"
                                />
                                <path
                                  id="Path_86"
                                  data-name="Path 86"
                                  d="M-4587.394-691.587h6.091v5.81"
                                  transform="translate(4590.756 691.587)"
                                  fill="none"
                                  stroke="#000"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.5"
                                />
                              </g>
                            </g>
                          </svg>
                        </Link>
                      </p>
                    ) : null}

                    {li.allspecs ? (
                      <p className="line-item-name-Specs">
                        <Tooltip
                          title={li.allspecs === "" ? "Default" : li.allspecs}
                        >
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#898989",
                              fontWeight: 400,
                            }}
                          >
                            Specs:{" "}
                          </span>{" "}
                          {li.allspecs === "" ? "Default" : li.allspecs}
                        </Tooltip>
                      </p>
                    ) : null}

                    {li.confirmation_date && (
                      <p className="line-item-sku">
                        <Tooltip title="VF Order Date">
                          {" "}
                          {moment(li.confirmation_date).format(
                            "DD MMM YYYY hh:mm A"
                          )}
                        </Tooltip>
                      </p>
                    )}
                    {li.bundle && li.bundle.length > 0 && (
                      <p>
                        <button
                          type="primary"
                          onClick={() =>
                            this.props.toggleViewBundleModal(li.bundle)
                          }
                          className="clickable"
                          style={{ marginTop: 4 }}
                        >
                          View Product Bundle
                        </button>
                      </p>
                    )}
                  </span>
                </div>
                <div className="openordersvg">
                  {!serviceable ? (
                    <span style={{ display: "flex" }}>
                      <Tooltip title="Not Serviceable">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          style={{ marginRight: "6px" }}
                          viewBox="0 0 17 17"
                        >
                          <g
                            id="Group_3314"
                            data-name="Group 3314"
                            transform="translate(-1856.5 -1108.5)"
                          >
                            <circle
                              id="Ellipse_45"
                              data-name="Ellipse 45"
                              cx="8"
                              cy="8"
                              r="8"
                              transform="translate(1857 1109)"
                              fill="#dd5725"
                              stroke="#fff"
                              stroke-width="1"
                            />
                            <g
                              id="Group_3305"
                              data-name="Group 3305"
                              transform="translate(1193 -183)"
                            >
                              <g
                                id="Group_3304"
                                data-name="Group 3304"
                                transform="translate(665.566 1296.006)"
                              >
                                <path
                                  id="Path_64"
                                  data-name="Path 64"
                                  d="M323.612,304.4a1.461,1.461,0,1,0,1.462,1.463A1.461,1.461,0,0,0,323.612,304.4Zm0,2.191a.731.731,0,1,1,.731-.731.731.731,0,0,1-.731.731Z"
                                  transform="translate(-314.45 -299.322)"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_65"
                                  data-name="Path 65"
                                  d="M103.108,304.4a1.461,1.461,0,1,0,1.461,1.461A1.461,1.461,0,0,0,103.108,304.4Zm0,2.191a.731.731,0,1,1,.731-.731.731.731,0,0,1-.731.731Z"
                                  transform="translate(-99.237 -299.322)"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_66"
                                  data-name="Path 66"
                                  d="M333.006,110.991a.365.365,0,0,0-.326-.2h-1.924v.729h1.7l.995,1.978.653-.328Z"
                                  transform="translate(-322.859 -110.059)"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_67"
                                  data-name="Path 67"
                                  d="M11.084,16.949h2.763v.731H11.084Z"
                                  transform="translate(-5.963 -10.762)"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_68"
                                  data-name="Path 68"
                                  d="M47.346,353.345H46.08a.365.365,0,1,0,0,.731h1.266a.365.365,0,0,0,0-.731Z"
                                  transform="translate(-44.68 -347.158)"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_69"
                                  data-name="Path 69"
                                  d="M56.6,82.49l-.748-.922a.385.385,0,0,0-.3-.14H52.8V78.885a.372.372,0,0,0-.379-.364H46.1a.364.364,0,1,0,0,.727h5.944v2.544a.372.372,0,0,0,.379.364H55.37l.552.682v1.875h-1.05a.364.364,0,1,0,0,.727H56.3a.372.372,0,0,0,.379-.364V82.713a.354.354,0,0,0-.078-.223Z"
                                  transform="translate(-44.68 -78.521)"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_70"
                                  data-name="Path 70"
                                  d="M28.4,271.6h-1.68a.365.365,0,1,0,0,.731H28.4a.365.365,0,1,0,0-.731Z"
                                  transform="translate(-25.756 -267.248)"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_71"
                                  data-name="Path 71"
                                  d="M3.482,208.134H.365a.365.365,0,1,0,0,.731H3.482a.365.365,0,1,0,0-.731Z"
                                  transform="translate(0 -205.214)"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_72"
                                  data-name="Path 72"
                                  d="M29.835,144.672H26.718a.365.365,0,1,0,0,.731h3.117a.365.365,0,1,0,0-.731Z"
                                  transform="translate(-25.756 -143.182)"
                                  fill="#fff"
                                />
                              </g>
                              <path
                                id="Path_73"
                                data-name="Path 73"
                                d="M13.5,4.5l-6,10"
                                transform="translate(661.5 1290.5)"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-width="1"
                              />
                            </g>
                          </g>
                        </svg>
                      </Tooltip>
                    </span>
                  ) : // this.props.pageType !== "openorders" && (

                  //   <span className="mr-32">
                  //     <Tooltip
                  //       title={shipmentStatusText[li.item_shipmentstatus]}
                  //     >
                  //       <Icon
                  //         // component={ShipmentStatusIcon}
                  //         style={{
                  //           color:
                  //             shipmentStatusColors[li.item_shipmentstatus],
                  //           fontSize: 20,
                  //           width: 100,
                  //         }}
                  //       />
                  //     </Tooltip>
                  //   </span>
                  // )
                  null}

                  {/* {this.props.provisional ? (
          <span className="mr-33">
            <Tooltip title={"Order data in processing"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <circle
                  id="Ellipse_42"
                  data-name="Ellipse 42"
                  cx="8"
                  cy="8"
                  r="8"
                  fill="#fd7a3b"
                />
                <g id="pause" transform="translate(-43.532 -558.36)">
                  <g
                    id="Group_3347"
                    data-name="Group 3347"
                    transform="translate(49.032 562.36)"
                  >
                    <g
                      id="Group_3346"
                      data-name="Group 3346"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Path_74"
                        data-name="Path 74"
                        d="M147.522,562.36a.489.489,0,0,0-.511.507v6.987a.523.523,0,0,0,1.045,0v-6.987A.517.517,0,0,0,147.522,562.36Z"
                        transform="translate(-143.056 -562.36)"
                        fill="#fff"
                      />
                      <path
                        id="Path_75"
                        data-name="Path 75"
                        d="M49.543,562.36a.512.512,0,0,0-.511.507v6.987a.523.523,0,0,0,1.045,0v-6.987A.508.508,0,0,0,49.543,562.36Z"
                        transform="translate(-49.032 -562.36)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </Tooltip>
          </span>
        ) : null} */}

                  <span className="mr-33">
                    <Tooltip
                      title={
                        li.item_shipmentstatus === "manifested"
                          ? "To Be Shipped"
                          : vfOrderStatusText[li.item_vforderstatus] === "Open"
                          ? "Open Order"
                          : vfOrderStatusText[li.item_vforderstatus] ===
                              "Verified & Ordered" &&
                            shipmentStatusText[li.item_shipmentstatus] ===
                              "In Processing"
                          ? "Placed Order"
                          : shipmentStatusText[li.item_shipmentstatus] ===
                            "Delivered"
                          ? "Delivered"
                          : shipmentStatusText[li.item_shipmentstatus] ===
                            "Returned"
                          ? "Returned"
                          : shipmentStatusText[li.item_shipmentstatus] ===
                            "In Transit"
                          ? "In Transit"
                          : vfOrderStatusText[li.item_vforderstatus]
                      }
                    >
                      <Icon
                        component={
                          shipmentStatusText[li.item_shipmentstatus] ===
                          "Delivered"
                            ? ShipmentStatusIcon
                            : shipmentStatusText[li.item_shipmentstatus] ===
                                "Returned" ||
                              shipmentStatusText[li.item_shipmentstatus] ===
                                "In Transit"
                            ? ShipmentStatusIcon
                            : vfOrderStatusIcons[li.item_vforderstatus]
                        }
                        style={{
                          color:
                            li.item_shipmentstatus === "manifested"
                              ? "#D241FD"
                              : shipmentStatusText[li.item_shipmentstatus] ===
                                "Delivered"
                              ? "#73D13D"
                              : shipmentStatusText[li.item_shipmentstatus] ===
                                "Returned"
                              ? "#F12F1A"
                              : shipmentStatusText[li.item_shipmentstatus] ===
                                "In Transit"
                              ? "#FEC62D"
                              : vfOrderStatusColors[li.item_vforderstatus],
                          fontSize: 17,
                        }}
                      />
                    </Tooltip>
                  </span>
                </div>
                <div className="quntyandpices">
                  {li.editable ? (
                    <div>
                      <InputNumber
                        className="quantity-field"
                        min={1}
                        type="number"
                        max={li.original_qty}
                        value={li.quantity}
                        onChange={(e) =>
                          this.props.changeLineItemQty(li.lineitem_id, e)
                        }
                        onFocus={(e) => e.target.select()}
                      />
                    </div>
                  ) : (
                    <div className="Quantity">
                      {li.quantity === 1
                        ? `${li.quantity} piece `
                        : `${li.quantity} pieces `}
                    </div>
                  )}
                  <div className="star">x</div>
                  <div className="Shopify_Selling_Price">
                    <Tooltip title="Shopify Selling Price">
                      {getCurrencySymbol(
                        this.props.store.store_geo === "in"
                          ? "INR"
                          : this.props.currencies.store_currency
                      )}{" "}
                      {formatNumber(
                        li.item_total_sold_price.amount +
                          this.props.order.shipping_perquantity,
                        2
                      )}
                    </Tooltip>
                  </div>
                </div>
                {li.item_shipmentstatus === "out_for_delivery" ? (
                  <div className="in-proces-date">
                    <span>Out for Delivery</span>
                  </div>
                ) : this.orderPlacedOn(li) ? (
                  <div className="in-proces-date">
                    <span>Order Placed On</span>
                    <p className="line-item-sku">
                      <Tooltip title="VF Order Date">
                        {" "}
                        {moment(li.confirmation_date).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                      </Tooltip>
                    </p>
                  </div>
                ) : (
                  this.datechecker(
                    li,
                    serviceable,
                    shipmentStatusColors,
                    shipmentStatusText,
                    vfOrderStatusText,
                    vfOrderStatusColors
                  )
                )}
              </div>
            )
          )}
          {/* <p className="shopify_selling_price">
            Total Shopify Selling Price:{" "}
            {this.state.order && this.state.order.total_order_value ? (
              <span style={{ color: "#000" }}>
                ₹ {this.state.order.total_order_value}
              </span>
            ) : (
              "Not Avaiable"
            )}
          </p> */}
        </div>
      </div>
    );
  }

  datechecker(
    li,
    serviceable,
    shipmentStatusColors,
    shipmentStatusText,
    vfOrderStatusText,
    vfOrderStatusColors
  ) {
    return (
      <div className="editbtn">
        {/* check if status is rto in transit */}
        {li.item_vforderstatus === "verified" ? (
          <button
            className="edit-quantity-btn"
            onClick={() => this.props.toggleLineItemEditable(li.lineitem_id)}
          >
            {li.editable ? (
              <button className="clickable-dropdown">Save Quantity</button>
            ) : (
              <div>
                {" "}
                <span style={{ marginRight: "8px" }}>
                  <svg
                    style={{
                      marginRight: "-4px",
                      marginBottom: "-1.5px",
                    }}
                    id="edit"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16.002"
                    viewBox="0 0 16 16.002"
                  >
                    <path
                      id="Shape"
                      d="M15.356,16H.642a.643.643,0,0,1,0-1.286H15.356a.643.643,0,0,1,0,1.286ZM3.916,12.656a.574.574,0,0,1-.524-.8l1.782-4.2A.576.576,0,0,1,5.3,7.472L12.388.378a1.29,1.29,0,0,1,1.821,0l1.416,1.416a1.29,1.29,0,0,1,0,1.822L8.529,10.706a.575.575,0,0,1-.182.121l-4.2,1.781A.581.581,0,0,1,3.916,12.656ZM11.281,3.3h0L6.3,8.283,5.264,10.737,7.717,9.7,12.7,4.718,11.281,3.3ZM13.3,1.284h0l0,0L12.19,2.395,13.6,3.809,14.711,2.7l0,0L13.3,1.284Z"
                      transform="translate(0 0)"
                      fill="#ff005e"
                    />
                  </svg>
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    textDecoration: "underline",
                  }}
                >
                  Edit Quantity
                </span>
              </div>
            )}
          </button>
        ) : li.item_shipmentstatus === "rto_in_transit" ? (
          <p>RTO In Transit</p>
        ) : !serviceable &&
          this.props.paymentStatusText[
            this.state.order.order_payment_status
          ] === "Fully Paid" ? (
          li.item_shipmentstatus === "processing" ? (
            <span style={{ color: "#898989" }}>Order Placed</span>
          ) : li.item_vforderstatus === "hold" && !serviceable ? (
            <span>
              <span
                style={{
                  color:
                    li.item_vforderstatus === "hold" && !serviceable
                      ? "#9B9B9B"
                      : null,
                }}
              >
                On Hold
              </span>
              <br />
              <span
                style={{
                  textDecoration: "underline",
                  fontWeight: 500,
                  color:
                    li.item_vforderstatus === "hold" && !serviceable
                      ? "#FD7A3B"
                      : null,
                }}
              >
                {moment(li.snooze_date).format("DD MMM, YYYY")}
              </span>
            </span>
          ) : (
            this.button(li)
          )
        ) : !serviceable ? (
          <div style={{ marginLeft: "14px" }}>
            <span style={{ fontSize: "12px" }}>
              {" "}
              <Tooltip
                title={
                  this.props.logs.length &&
                  this.props.logs[0] &&
                  this.props.logs[0].note
                    ? this.props.logs[0].note
                    : null
                }
              >
                {this.props.logs.length &&
                this.props.logs[0] &&
                this.props.logs[0].note
                  ? this.props.logs[0].note.slice(0, 18)
                  : null}
              </Tooltip>
            </span>
            {this.props.logs.length > 0 ? null : "Not Serviceable"}
          </div>
        ) : li.item_vforderstatus === "notlinked" || li.vfsku === undefined ? (
          <div>
            <a
              href="#/"
              onClick={() =>
                this.props.toggleLinkProductModal(li.product_mongoid, {
                  gallery: [li.img],
                  name: li.name,
                  vfod: this.props.ordervf,
                })
              }
            >
              Link Product
            </a>
            <p className="or-txt">or</p>
            <a
              href="#/"
              onClick={() =>
                this.props.toggleBundleLinkModal(li.product_mongoid, {
                  gallery: [li.img],
                  name: li.name,
                  vfod: this.props.ordervf,
                })
              }
            >
              Create Bundle
            </a>
          </div>
        ) : li.item_vforderstatus === "open" ||
          (li.item_vforderstatus === "verified" && this.props.provisional) ? (
          this.props.provisional ? (
            <div>
              <a
                href="#/"
                onClick={() =>
                  this.props.toggleLinkProductModal(li.product_mongoid, {
                    gallery: [li.img],
                    name: li.name,
                  })
                }
              >
                Link Product
              </a>
              <p className="or-txt">or</p>
              <a
                href="#/"
                onClick={() =>
                  this.props.toggleBundleLinkModal(li.product_mongoid, {
                    gallery: [li.img],
                    name: li.name,
                  })
                }
              >
                Create Bundle
              </a>
            </div>
          ) : this.props.provisional2 ? (
            <div>
              <a
                href="#/"
                onClick={() =>
                  this.props.toggleLinkProductModal(li.product_mongoid, {
                    gallery: [li.img],
                    name: li.name,
                  })
                }
              >
                Link Product
              </a>
              <a
                href="#/"
                onClick={() =>
                  this.props.toggleBundleLinkModal(li.product_mongoid, {
                    gallery: [li.img],
                    name: li.name,
                  })
                }
              >
                Create Bundle
              </a>
            </div>
          ) : hasPermission(this.props.store, "verify_modify_orders") ? (
            <button
              className="edit-quantity-btn"
              onClick={() => this.props.toggleLineItemEditable(li.lineitem_id)}
            >
              {li.editable ? (
                <button className="clickable-dropdown">Save Quantity</button>
              ) : (
                <div>
                  {" "}
                  <span style={{ marginRight: "8px" }}>
                    <svg
                      style={{
                        marginRight: "-4px",
                        marginBottom: "-1.5px",
                      }}
                      id="edit"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16.002"
                      viewBox="0 0 16 16.002"
                    >
                      <path
                        id="Shape"
                        d="M15.356,16H.642a.643.643,0,0,1,0-1.286H15.356a.643.643,0,0,1,0,1.286ZM3.916,12.656a.574.574,0,0,1-.524-.8l1.782-4.2A.576.576,0,0,1,5.3,7.472L12.388.378a1.29,1.29,0,0,1,1.821,0l1.416,1.416a1.29,1.29,0,0,1,0,1.822L8.529,10.706a.575.575,0,0,1-.182.121l-4.2,1.781A.581.581,0,0,1,3.916,12.656ZM11.281,3.3h0L6.3,8.283,5.264,10.737,7.717,9.7,12.7,4.718,11.281,3.3ZM13.3,1.284h0l0,0L12.19,2.395,13.6,3.809,14.711,2.7l0,0L13.3,1.284Z"
                        transform="translate(0 0)"
                        fill="#ff005e"
                      />
                    </svg>
                  </span>
                  <span
                    style={{
                      fontSize: "12px",
                      textDecoration: "underline",
                    }}
                  >
                    Edit Quantity
                  </span>
                </div>
              )}
            </button>
          ) : null
        ) : li.item_vforderstatus === "verified_ordered" ? (
          this.newsomething(
            shipmentStatusColors,
            li,
            shipmentStatusText,
            vfOrderStatusText
          )
        ) : (
          <span
            style={{
              color: vfOrderStatusColors[li.item_vforderstatus],
            }}
          >
            {vfOrderStatusText[li.item_vforderstatus] === "Cancelled Order" ? (
              <p style={{ color: "#f12f1a", textAlign: "left" }}>
                {" "}
                <p style={{ color: "#898989" }}>
                  {this.state.order.cancelled_at ? (
                    "Cancelled On"
                  ) : (
                    <span style={{ marginLeft: "-24px" }}>Cancelled</span>
                  )}
                </p>
                {!!this.state.order.cancelled_at ? (
                  <span
                    style={{
                      textDecoration: "underline",
                      fontWeight: 500,
                    }}
                  >
                    {moment(this.state.order.cancelled_at).format(
                      "DD MMM, YYYY"
                    )}
                  </span>
                ) : null}
              </p>
            ) : (
              <p
                style={{
                  marginLeft:
                    vfOrderStatusText[li.item_vforderstatus] === "Verified"
                      ? "-35px"
                      : null,
                }}
              >
                {vfOrderStatusText[li.item_vforderstatus]}
              </p>
            )}

            {li.item_vforderstatus === "hold" && (
              <span
                style={{
                  color:
                    vfOrderStatusText[li.item_vforderstatus] === "On Hold"
                      ? "#FD7A3B"
                      : null,
                }}
              >
                <span
                  style={{
                    textDecoration: "underline",
                    fontWeight: 500,
                  }}
                >
                  {moment(li.snooze_date).format("DD MMM, YYYY")}
                </span>
              </span>
            )}
          </span>
        )}
      </div>
    );
  }

  button(li) {
    return (
      <button
        className="edit-quantity-btn"
        onClick={() => this.props.toggleLineItemEditable(li.lineitem_id)}
      >
        {li.editable ? (
          <button className="clickable-dropdown">Save Quantity</button>
        ) : (
          <div>
            {" "}
            <span style={{ marginRight: "8px" }}>
              <svg
                style={{
                  marginRight: "-4px",
                  marginBottom: "-1.5px",
                }}
                id="edit"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16.002"
                viewBox="0 0 16 16.002"
              >
                <path
                  id="Shape"
                  d="M15.356,16H.642a.643.643,0,0,1,0-1.286H15.356a.643.643,0,0,1,0,1.286ZM3.916,12.656a.574.574,0,0,1-.524-.8l1.782-4.2A.576.576,0,0,1,5.3,7.472L12.388.378a1.29,1.29,0,0,1,1.821,0l1.416,1.416a1.29,1.29,0,0,1,0,1.822L8.529,10.706a.575.575,0,0,1-.182.121l-4.2,1.781A.581.581,0,0,1,3.916,12.656ZM11.281,3.3h0L6.3,8.283,5.264,10.737,7.717,9.7,12.7,4.718,11.281,3.3ZM13.3,1.284h0l0,0L12.19,2.395,13.6,3.809,14.711,2.7l0,0L13.3,1.284Z"
                  transform="translate(0 0)"
                  fill="#ff005e"
                />
              </svg>
            </span>
            <span
              style={{
                fontSize: "12px",
                textDecoration: "underline",
              }}
            >
              Edit Quantity
            </span>
          </div>
        )}
      </button>
    );
  }

  newsomething(
    shipmentStatusColors,
    li,
    shipmentStatusText,
    vfOrderStatusText
  ) {
    return (
      <span
        style={{
          color: shipmentStatusColors[li.item_shipmentstatus],
        }}
      >
        {li.item_shipmentstatus === "lost" ? (
          <span style={{ color: "red" }}>Lost</span>
        ) : li.item_shipmentstatus === "manifested" ? (
          "To Be Shipped On"
        ) : shipmentStatusText[li.item_shipmentstatus] === "In Processing" &&
          vfOrderStatusText[li.item_vforderstatus] === "Verified & Ordered" ? (
          "Order Placed On"
        ) : shipmentStatusText[li.item_shipmentstatus] === "In Transit" ? (
          shipmentStatusText[li.item_shipmentstatus]
        ) : (
          shipmentStatusText[li.item_shipmentstatus] + " On"
        )}

        {li.item_shipmentstatus === "manifested" ||
        shipmentStatusText[li.item_shipmentstatus] === "In Processing" ||
        shipmentStatusText[li.item_shipmentstatus] === "In Transit" ? null : (
          <p
            style={{
              color: `${
                li.item_shipmentstatus === "delivered" ? "#73D13D" : null
              }`,
            }}
          >
            {(this.props.closeordernav.deliveredbtn ||
              this.props.closeordernav.rtobtn ||
              this.props.allordernav.ClosedOrdersbtn) &&
            !!this.state.order.order_shipments &&
            !!this.state.order.order_shipments[0].closed_on ? (
              <span
                style={{
                  textDecoration: "underline",
                  fontWeight: 500,
                  color:
                    li.item_shipmentstatus === "delivered" ? "#73D13D" : "red",
                }}
              >
                {li.hasOwnProperty("closed_on") &&
                !!li.closed_on &&
                !!li.closed_on.length &&
                li.closed_on.length > 8
                  ? moment(li.closed_on).format("DD MMM, YYYY")
                  : null}
              </span>
            ) : !!this.state.order.order_shipments &&
              !!this.state.order.order_shipments[0] &&
              !!this.state.order.order_shipments[0].shipment_date ? (
              <span
                style={{
                  textDecoration: "underline",
                  fontWeight: 500,
                  color:
                    li.item_shipmentstatus === "delivered" ? "#73D13D" : "red",
                }}
              >
                {li.hasOwnProperty("closed_on") &&
                !!li.closed_on.length &&
                li.closed_on.length > 8
                  ? moment(li.closed_on).format("DD MMM, YYYY")
                  : null}
              </span>
            ) : null}
          </p>
        )}
      </span>
    );
  }
}

export default LineItems;
