import React from "react";
import CounterCard from "./CounterCard";
import { Row, Skeleton, Alert } from "antd";
import NotFoundCard from "../../Common/NotFoundCard";
import ErrorCard from "../../Common/ErrorCard";
import { SHOPIFY_NAME } from "../../../../constants";
import "./index.scss";

const CounterCardWraper = (props) => {
  const { loading, data, counterType, errorMsg, errorCode, error } = props;

  if (loading) {
    return (
      <Row style={{ marginBottom: "30px" }}>
        <Skeleton active />
      </Row>
    );
  }

  if (!loading && error) {
    return <ErrorCard errorMsg={errorMsg} errorCode={errorCode} />;
  }

  if (data.length === 0) {
    return (
      <NotFoundCard
        mainHeading={"Oops! No Data Found."}
        secondHeading={
          <p>
            Looks like there are no counters data in{" "}
            <span>current Date Range</span>.
          </p>
        }
        thirdHeading={
          <p>
            Please <span>select another Date Range</span> from Date Picker to
            filter data.
          </p>
        }
      />
    );
  }

  if (counterType === "Orders") {
    return (
      <div className="counter-card-wraper" id="fade-in">
        <CounterCard
          title={`Total Orders on ${SHOPIFY_NAME}`}
          tooltip={true}
          data={data}
          tooltipText={`Orders that you received on your ${SHOPIFY_NAME}.`}
          count={data.total_shopify_orders}
          amount={data.total_shopify_orders_amount}
        />
        <CounterCard
          tooltip={true}
          data={data}
          tooltipText={"Orders that you placed on vFulfill."}
          title="Total Orders to VF"
          count={data.total_order_to_vf}
          amount={data.total_order_to_vf_amount}
          type="vforder"
        />
        {/* <CounterCard
          tooltip={true}
          tooltipText={"COD Orders that you placed on vFulfill."}
          title="Total COD Orders to VF"
          count={data.total_cod_orders}
          amount={data.total_cod_orders_amount}
        /> */}
        {/* <CounterCard
          tooltip={true}
          tooltipText={"Prepaid Orders that you placed on vFulfill."}
          title="Total Prepaid Orders to VF"
          count={data.total_prepaid_orders}
          amount={data.total_prepaid_orders_amount}
        /> */}
        <CounterCard
          percantage={true}
          data={data}
          title="Total Orders Attempts"
          count={data.total_order_attempts_orders}
          attempts={true}
          per={data.vf_to_attempts_percentage}
          type={"total_orders_attempts"}
        />

        <CounterCard
          tooltip={true}
          custom={"Count"}
          tooltipText={
            "This is the amount of Successful Deliveries out of all the Attempted Deliveries."
          }
          type={"attempted_orders_delivered"}
          percantage={true}
          data={data}
          title="Attempted Orders Delivered"
          count={data.total_delivered_orders}
          amount={data.attempted_delivery_percentage}
        />

        <CounterCard
          tooltip={true}
          tooltipText={
            "This is the amount of Successful Deliveries out of all the Orders to vFulfill."
          }
          type="overall_orders_delivered"
          custom={"Count"}
          percantage={true}
          data={data}
          overall={true}
          title="Overall Orders Delivered"
          count={data.total_delivered_orders}
          amount={data.overall_delivery_percentage}
        />

        <CounterCard
          title="Average Orders Per Day"
          round={true}
          count={data.average_order_perday}
          amount={data.average_order_amount_perday}
        />

        <CounterCard
          tooltip={true}
          data={data}
          tooltipText={"Orders that you placed on vFulfill."}
          title="Remittance Processed"
          count={data.total_order_to_vf}
          amount={data.total_order_to_vf_amount}
          type="remittance_processed"
        />
        <CounterCard
          tooltip={true}
          data={data}
          tooltipText={"Orders that you placed on vFulfill."}
          title="Remittance Pending"
          count={data.total_order_to_vf}
          amount={data.total_order_to_vf_amount}
          type="remittance_pending"
        />
      </div>
    );
  }

  return (
    <div className="counter-card-wraper trans" id="fade-in">
      <CounterCard
        tooltip={true}
        tooltipText={"Total Order value of all delivered orders."}
        title="Total Delivered Order Value"
        count={data.delivered_order_count}
        amount={data.total_delivered_orders_amount}
      />
      <CounterCard
        tooltip={true}
        tooltipText={
          "Delivered Order Amount that has been pending for remittance"
        }
        title="Pending Remittance"
        count={data.total_pending_remitttance_count}
        amount={data.pending_cod_remit_amount}
      />
      <CounterCard
        tooltip={true}
        tooltipText={"Delivered Order Amount that has been remitted to you."}
        title="Total Remittance Processed"
        count={data.total_processed_remitttance_count}
        amount={data.processed_cod_remit_amount}
      />
      {/* <CounterCard
        title="Overall Pending Remittance"
        count={data.total_pending_remitttance_count}
        amount={data.pending_cod_remit_amount}
      /> */}
      <CounterCard
        tooltip={true}
        tooltipText={
          "Prepaid Amount collected from vFulfill Payment Gateway integrated on your store."
        }
        title="Prepaid Remittance Received"
        count={data.processed_prepaid_remit_count}
        amount={data.processed_prepaid_remit_amount}
      />
    </div>
  );
};

export default CounterCardWraper;
