import React from "react";
import "./index.scss";
import moment from "moment";
import { Tooltip } from "antd";

class Logs extends React.Component {
  render() {
    return (
      <div
        className={`tab-container ${
          this.props.activeTab !== "logs" ? "hidden" : ""
        }`}
      >
        <div className="notes-tab">
          {" "}
          {this.props.logs && this.props.logs.length > 0 ? (
            <div className="header_upper_data">
              <p className="flex-action">Actions</p>
              <p className="flex-date">Date</p>
              <p className="flex-addedby">Added by</p>
            </div>
          ) : null}
          {this.props.logs && this.props.logs.length > 0 ? (
            this.props.logs.map((n) => (
              <div className="note" key={n.date}>
                <div className="flex-action">
                  {" "}
                  {n.note && n.note.length <= 45 ? (
                    n.note
                  ) : (
                    <Tooltip
                      title={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: n.note.replace(/\n/g, "<br/>"),
                          }}
                        />
                      }
                    >
                      {" "}
                      {n.note ? `${n.note.substring(0, 45)}...` : null}
                    </Tooltip>
                  )}
                </div>

                <div className="flex-date">
                  {moment(n.date).format("DD MMM, YYYY | HH:mm:ss")}
                </div>
                <div className="flex-addedby">by {n.addedbyname}</div>
              </div>
            ))
          ) : (
            <h3 style={{ textAlign: "center" }}>No Logs Found!</h3>
          )}
        </div>
      </div>
    );
  }
}

export default Logs;
