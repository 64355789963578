import React from "react";
import { Button, Card, Icon, Table, Tooltip } from "antd";
import { ReactComponent as circle } from "../../../../assets/images/icons/circle-filled.svg";
import { ReactComponent as ShipmentStatusIcon } from "../../../../assets/images/icons/shopping-cart.svg";
import { ReactComponent as VideoIcon } from "../../../../assets/images/icons/video-play.svg";
import { ReactComponent as ImgEye } from "../../../../assets/images/icons/image-eye.svg";
import { ReactComponent as DownloadBG } from "../../../../assets/images/icons/download-bg.svg";
import moment from "moment";
import { reasons } from "../../Shipments/DisputeModals/DisputeModal";
import OrderProductTitle from "../.../../../Common/GlobalCommon/ProductTitle/OrderTitle";
import AudioTag from "../../Common/AudioTag";
import CustomerInfo from "../CustomerInfo";
import VfIdCopyBadge from "../../Common/VfIdCopyBadge";
import "./index.scss";
import { SHOPIFY_NAME } from "../../../../constants";
import LogsAndNotes from "./LogsAndNotes";
const ndr_bucket_code_OBJ = {
  "0": "Exception",
  "1": "Customer Unavailable",
  "2": "Rejected by Customer",
  "3": "Delivery Rescheduled",
  "4": "No Attempt",
  "5": "Customer Unreachable",
  "6": "Address Issue",
  "7": "Payment Issue",
  "8": "Out of Delivery Area",
  "9": "Order Already Cancelled",
  "10": "Self Collect",
  "11": "Shipment Seized by Customer",
  "12": "Customer Wants Open Delivery",
  "13": "Shipment Misrouted by Logistics Partner",
  "14": "OTP-Based Cancellation",
};

class NdrEscalationCard extends React.Component {
  state = {
    shipment: this.props.shipment,
    activeTab: "disputeInformation",
    customerTab: {
      editing: false,
      saving: false,
    },
    errors: {},
    shipmentsVisible: {},
    mediaAttachments: [],
    showMedia: false,

    // ndrEscalationVisibleModal: false
  };

  // this function used  to show current tabs
  setActiveTab = (activeTab) => {
    this.setState({
      activeTab,
    });
  };

  toggleNdrEscalationModal = () => {
    this.setState({ showHideheaderdata: false });
    this.props.toggleNdrEscalationModal();
  };
  // this function used show image modal
  showImage(index, files) {
    this.props.toggleShowImageModal(index, files);
  }

  handlemedia(arr) {
    this.setState({
      showMedia: true,
      mediaAttachments: arr,
    });
  }

  render() {
    const {
      shipmentStatusColors,
      shipmentStatusText,
      disputeStatusColors,
      disputeStatusText,
    } = this.props;
    const dispute = this.props.dispute;
    console.log(dispute);
    const columnsd = [
      {
        title: "Escalation Date",
        dataIndex: "created_date",
        width: 150,
        render: (date) => (
          <div>{date ? moment(date).format("DD MMM, YYYY") : "N/A"}</div>
        ),
      },
      {
        title: "Reattempt Date",
        dataIndex: "reattempt_date",
        width: 150,
        render: (date) => (
          <div>{date ? moment(date).format("DD MMM, YYYY") : "N/A"}</div>
        ),
      },
      {
        title: "Escalation Remark(s)",
        dataIndex: "remarks",

        render: (remark) => <div>{remark}</div>,
      },
      {
        title: "Close Date",
        dataIndex: "closed_on",
        width: 150,
        render: (date) => (
          <div>{date ? moment(date).format("DD MMM, YYYY") : "N/A"}</div>
        ),
      },
      {
        title: "Media",
        data: "ndr_files",
        width: 150,
        render: (arr) => (
          <button
            class="clickable"
            onClick={() => this.handlemedia(arr.ndr_files)}
          >
            View Media
          </button>
        ),
      },
    ];
    const images = dispute.files.filter((file) => file.filetype === "image");
    const audio = dispute.files.filter((file) => file.filetype === "audio");
    const video = dispute.files.filter((file) => file.filetype === "video");
    const images1 = this.state.mediaAttachments.filter(
      (file) => file.filetype === "image"
    );
    const audio1 = this.state.mediaAttachments.filter(
      (file) => file.filetype === "audio"
    );
    const video1 = this.state.mediaAttachments.filter(
      (file) => file.filetype === "video"
    );
    const notes = dispute.notes.filter((note) => note.note_type === "note");
    const logs = dispute.notes.filter((note) => note.note_type === "log");
    let lineItems = this.props.dispute.items;
    let mediaAttachments = this.props.dispute.files;

    return (
      <Card id="test" className="escalation-card">
        <div className="top-header">
          {/* <div
            style={{
              background:
                dispute.status === "in_processing"
                  ? "#f5b709"
                  : disputeStatusColors[dispute.status],
            }}
            className="vf"
          >
            <p>VF Escalation Id : #{dispute.order_vfid}</p>
          </div> */}

          <div style={{ display: "flex" }}>
            {" "}
            <VfIdCopyBadge
              text={"VF Escalation ID"}
              hashSign={false}
              textToCopy={
                dispute && dispute.escalation_vfid
                  ? dispute.escalation_vfid
                  : "N/A"
              }
            />
            <div className="header-top-row">
              <p
                style={{
                  background: "#F5FAFF",
                  color: "#212121",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Tooltip title="Re-Attempt Count">
                  <span
                    style={{
                      margin: "auto",
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="tooltip_icon_svg"
                  >
                    NDR Count: {dispute && dispute.ndr_count}{" "}
                    <span
                      style={{
                        margin: "auto",
                        marginLeft: "5px",
                        marginTop: "2px",
                      }}
                    >
                      {" "}
                      <svg
                        className="product-tooltip-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                      >
                        <g data-name="Group 2529">
                          <g
                            transform="translate(0 1)"
                            fill="none"
                            stroke="#898989"
                          >
                            <circle cx="8" cy="8" r="8" stroke="none" />
                            <circle cx="8" cy="8" r="7.5" />
                          </g>
                          <text
                            transform="translate(6.5)"
                            fill="#898989"
                            fontSize="12"
                            fontFamily="Poppins-Medium, Poppins"
                            fontWeight="500"
                            letterSpacing=".032em"
                          >
                            <tspan x="0" y="13">
                              i
                            </tspan>
                          </text>
                        </g>
                      </svg>
                    </span>
                  </span>
                </Tooltip>
              </p>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div className="dispute-title">
              <span
                style={{
                  display: "flex",
                  color: "white",
                  background:
                    dispute.status === "in_processing"
                      ? "#f5b709"
                      : disputeStatusColors[dispute.status],
                  position: dispute.status === "close" ? "relative" : "static",
                  top: "3px",
                  borderRadius: "2px",
                  fontWeight: "500",
                }}
              >
                <Icon component={circle} className="circle-ican" />
                <span className="circle-ican-status">
                  {dispute.status === "in_processing"
                    ? "In Processing"
                    : disputeStatusText[dispute.status]}
                </span>
              </span>
            </div>
            {dispute.status === "close" ? (
              <span style={{ marginLeft: "10px" }}>
                <Button
                  className="clickable"
                  onClick={() => this.toggleNdrEscalationModal(true)}
                >
                  Reopen Escalation
                </Button>
              </span>
            ) : null}
          </div>
        </div>

        <div class="fields">
          <div class="field">
            <p class="field-label"> WayBill Number(AWB): </p>
            <p class="field-value">
              {" "}
              <span className="blue-text">
                <a
                  href={`../tracking?search=${dispute.awb}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {dispute.awb}
                </a>
              </span>
            </p>
          </div>
          <div class="field">
            <p class="field-label">Escalation Raised On:</p>
            <p class="field-value">
              <span>
                {moment(dispute.created_at).format("DD MMM, YYYY | LT")}
              </span>{" "}
            </p>
          </div>
          <div class="field">
            <p class="field-label">Re-Attempt Date:</p>
            <p class="field-value">
              <span>
                {dispute.reattempt_date !== "NA"
                  ? moment(dispute.reattempt_date).format("DD MMM, YYYY")
                  : "N/A"}
              </span>{" "}
            </p>
          </div>
          <div class="field">
            <p class="field-label">Order Number:</p>
            <p class="field-value">
              {" "}
              <span className="blue-text">
                <a
                  href={`../orders?search=${dispute.order_vfid}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {dispute.order_vfid}
                </a>
              </span>
            </p>
          </div>
          <div class="field">
            <p class="field-label">{`${SHOPIFY_NAME} Order ID:`}</p>
            <p class="field-value">
              {" "}
              <span className="blue-text">
                <a
                  href={`../orders?search=${dispute.shopify_orderid}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {dispute.shopify_orderid}
                </a>
              </span>
            </p>
          </div>
        </div>
        <div className="tabs-row">
          <ul className="lists">
            <li
              className={
                this.state.activeTab === "disputeInformation" ? "active" : ""
              }
              onClick={() => this.setActiveTab("disputeInformation")}
            >
              Information
            </li>
            <li
              className={this.state.activeTab === "logs&notes" ? "active" : ""}
              onClick={() => this.setActiveTab("logs&notes")}
            >
              Notes & Logs{" "}
              <span className="tab-count">
                {dispute.escalation_journey.length}
              </span>
            </li>
            <li
              className={
                this.state.activeTab === "mediaAttachments" ? "active" : ""
              }
              onClick={() => this.setActiveTab("mediaAttachments")}
            >
              Media
            </li>
            <li
              className={this.state.activeTab === "lineItems" ? "active" : ""}
              onClick={() => this.setActiveTab("lineItems")}
            >
              Shipment Items
            </li>

            <li
              className={
                this.state.activeTab === "customerInfo" ? "active" : ""
              }
              onClick={() => this.setActiveTab("customerInfo")}
            >
              Customer Info
            </li>

            {/* <li
              className={this.state.activeTab === "notes" ? "active" : ""}
              onClick={() => this.setActiveTab("notes")}
            >
              Notes <span className="tab-count">{notes.length}</span>
            </li> */}

            {/* <li
              className={this.state.activeTab === "logs" ? "active" : ""}
              onClick={() => this.setActiveTab("logs")}
            >
              Logs <span className="tab-count">{logs.length}</span>
            </li> */}

            {/* {this.props.dispute.escalation_logs.length ? (
              <li
                className={
                  this.state.activeTab === "Escalationlog" ? "active" : ""
                }
                onClick={() => this.setActiveTab("Escalationlog")}
              >
                Old Escalation Logs{" "}
                <span className="tab-count">
                  {this.props.dispute.escalation_logs.length}
                </span>
              </li>
            ) : null} */}
          </ul>
        </div>

        <div
          className={`tab-container ${
            this.state.activeTab !== "disputeInformation" ? "hidden" : ""
          }`}
        >
          <div className="dispute-info-tab">
            <div className="upper_data">
              <div className="dispute-reason">
                <h6>Owner:</h6>
                <p className="capitalize-text">{dispute.escalation_owner}</p>
              </div>

              {/* <div className="dispute-reason">
                <h6>Escalation Reason:</h6>
                <p> {reasons[dispute.reason]}</p>
              </div> */}
            </div>

            <div
              className="dispute-reason"
              style={{ width: "99%", marginTop: "10px" }}
            >
              <h6>Description:</h6>
              <p style={{ height: "auto" }}>
                {/* {dispute && dispute.remarks && dispute.remarks.length <= 60 ? (
                  dispute.remarks
                ) : (
                  <Tooltip title={dispute.remarks}>
                    {" "}
                    {dispute.remarks
                      ? `${dispute.remarks.substring(0, 60)}...`
                      : null}
                  </Tooltip>
                )} */}
                {dispute.remarks}
              </p>
            </div>
            <div
              className="dispute-reason"
              style={{ width: "99%", marginTop: "10px" }}
            >
              <h6>NDR Reason:</h6>
              <p style={{ height: "auto" }}>
                {/* {dispute && dispute.remarks && dispute.remarks.length <= 60 ? (
                  dispute.remarks
                ) : (
                  <Tooltip title={dispute.remarks}>
                    {" "}
                    {dispute.remarks
                      ? `${dispute.remarks.substring(0, 60)}...`
                      : null}
                  </Tooltip>
                )} */}
                {dispute.ndr_bucket_reason}
              </p>
            </div>
           
          </div>
        </div>

        <div
          className={`tab-container ${
            this.state.activeTab !== "lineItems" ? "hidden" : ""
          }`}
        >
          <div className="line-items-tab">
            <div className="flex header-varaint-status">
              <div className="flex-5">Variant Details</div>

              <div className="flex-7">Quantity</div>
            </div>
            {lineItems.map((li) => {
              return (
                <div className={`line-item `} key={li.lineitem_id}>
                  <div className="flex-5">
                    <div className="line-item-product-detail">
                      <span
                        className="line-item-img"
                        style={{ background: `url(${li.img})` }}
                      />
                      <span>
                        <p className="blue-text line-item-name">
                          {/* <Tooltip title={li.name} placement="topLeft">
                            {li && li.name ? li.name : "Not Available"}
                          </Tooltip> */}

                          <OrderProductTitle
                            orderProductname={li.name}
                            productShopifyid={li.product_shopifyid}
                            storeShopifyadmin={li.product_link}
                          />
                        </p>
                        <p className="line-item-sku">
                          <b>SKU: </b> #
                          <b className="detailcommon">
                            {" "}
                            {li && li.sku.length ? (
                              li.sku.length > 16 ? (
                                <Tooltip title={li.sku} placement="topLeft">
                                  {li.sku.slice(0, 16) + "..."}
                                </Tooltip>
                              ) : (
                                li.sku
                              )
                            ) : (
                              "Not Available"
                            )}
                          </b>
                        </p>
                        <p className="line-item-sku">
                          <b>Specs: </b>{" "}
                          <b className="detailcommon">
                            {li.allspecs === "" ? (
                              "Default"
                            ) : li && li.allspecs && li.allspecs.length > 16 ? (
                              <Tooltip title={li.allspecs} placement="topLeft">
                                {li.allspecs.slice(0, 16) + "..."}
                              </Tooltip>
                            ) : (
                              li.allspecs
                            )}
                          </b>
                        </p>
                      </span>
                    </div>
                  </div>

                  <div className="flex-7">
                    <span>
                      {li.quantity === 1
                        ? `${li.quantity} piece `
                        : `${li.quantity} pieces `}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={`tab-container ${
            this.state.activeTab !== "mediaAttachments" ? "hidden" : ""
          }`}
        >
          <div className="media-attachments">
            {!!mediaAttachments && !!mediaAttachments.length ? (
              // audio media
              <>
                {!!audio && !!audio.length ? (
                  <div className="audio-container">
                    <p>Uploaded Audio:</p>
                    <div className="audio-wraper-all">
                      {audio.map((image, i) => {
                        return <AudioTag src={image.file} />;
                      })}
                    </div>
                  </div>
                ) : null}{" "}
                {/* images media */}
                {!!images && !!images.length ? (
                  <div className="images-container">
                    <p>Uploaded Images:</p>
                    <div className="image-div-wraper">
                      {images.map((image, i) => {
                        return (
                          <div className="img-div">
                            <img src={image.file} alt="Not found" />
                            <Icon
                              className="eye-icon"
                              key={i}
                              onClick={() => this.showImage(i, images)}
                              component={ImgEye}
                            />
                            <a
                              className="download-icon"
                              href={
                                `${process.env.REACT_APP_API_URL}` +
                                `/products/download_product_media?url=` +
                                `${image.file}`
                              }
                            >
                              <Icon component={DownloadBG} />
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
                {/* video media */}
                {!!video && !!video.length ? (
                  <div className="videos-container">
                    <p>Uploaded Videos:</p>
                    <div className="video-div-wraper">
                      {video.map((vid, i) => {
                        return (
                          <div className="video-div">
                            <video src={vid.file} />

                            <a
                              className="download-icon"
                              href={
                                `${process.env.REACT_APP_API_URL}` +
                                `/products/download_product_media?url=` +
                                `${vid.file}`
                              }
                            >
                              <Icon component={DownloadBG} />
                            </a>
                            <Icon
                              className="video-icon"
                              key={i}
                              onClick={() => this.showImage(i, video)}
                              component={VideoIcon}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </>
            ) : (
              <div className="no-found">No Media Found!</div>
            )}
          </div>
        </div>

        <div
          className={`tab-container ${
            this.state.activeTab !== "customerInfo" ? "hidden" : ""
          }`}
        >
          <CustomerInfo shipment={dispute} />
          {/* <CustomerInfo shipment={shipment} shipmentkey={true} /> */}
        </div>

        <div
          style={{
            display:
              this.state.activeTab === "notes" ||
              this.state.activeTab === "logs"
                ? "block"
                : "none",
          }}
          className="notes-logs-data"
        >
          {(this.state.activeTab === "notes" ? notes : logs).length > 0 ? (
            <div className="flex notes-logs-txt">
              <div className="flex-note-log">
                {this.state.activeTab === "notes" ? `Notes` : `Logs`}
              </div>
              <div className="flex-date">Date</div>
              <div className="flex-addedby">Added by</div>
            </div>
          ) : null}

          {(this.state.activeTab === "notes" ? notes : logs).length > 0 ? (
            (this.state.activeTab === "notes" ? notes : logs).map(
              //  flex  flex-2 flex-3 and flex-1 used to fex grid system
              (note, idx) => (
                <div className="flex note-logs" key={idx}>
                  <div className="flex-note-log">
                    {note && note.note.length <= 60 ? (
                      note.note
                    ) : (
                      <Tooltip title={note.note}>
                        {" "}
                        {note.note ? `${note.note.substring(0, 60)}...` : null}
                      </Tooltip>
                    )}
                  </div>
                  <div className="flex-date">
                    {moment(note.date).format("DD MMM, YYYY | HH:mm A")}
                  </div>
                  <div className="flex-addedby">by {note.added_by}</div>
                </div>
              )
            )
          ) : (
            <div className="no-found">
              No {this.state.activeTab === "notes" ? `Notes` : `Logs`} Found!
            </div>
          )}
        </div>
        {this.state.activeTab === "logs&notes" ? (
          <div>
            {this.props.dispute.escalation_journey.map((obj, index) => {
              return (
                <>
                  <div className="log-heading-cointainer">
                    <div>Escalated by Partner</div>
                    {!!obj.vf_remark && !!obj.vf_remark.length ? (
                      <div>Action Taken by vFulfill Team</div>
                    ) : null}
                  </div>
                  <LogsAndNotes
                    obj={obj}
                    lastIndex={
                      this.props.dispute.escalation_journey.length - 1 ===
                        index && index !== 0
                        ? true
                        : false
                    }
                    index={index}
                    key={index}
                    showImage={(i, media) => {
                      this.showImage(i, media);
                    }}
                  />
                </>
              );
            })}
          </div>
        ) : null}
        <div
          className={`tab-container ${
            this.state.activeTab !== "Escalationlog" ? "hidden" : ""
          }`}
        >
          <div className="escalation-log">
            <div className=" header-varaint-status-table">
              {/* <div className="flex-5">Variant Details</div> */}

              {!this.state.showMedia ? (
                this.props.dispute.escalation_logs.length ? (
                  <Table
                    className="transaction-stats-tabledc"
                    columns={columnsd}
                    height={500}
                    //  rowKey={(record) => record.created_by["$oid"]}
                    dataSource={this.props.dispute.escalation_logs || []}
                    pagination={false}
                  />
                ) : null
              ) : (
                <div className="media-attachments">
                  {this.state.mediaAttachments &&
                  this.state.mediaAttachments.length ? (
                    // audio media
                    <>
                      {!!audio1 && !!audio1.length ? (
                        <div className="audio-container">
                          <p>Uploaded Audio:</p>
                          <div className="audio-wraper-all">
                            {audio1.map((image, i) => {
                              return <AudioTag src={image.file} />;
                            })}
                          </div>
                        </div>
                      ) : null}{" "}
                      {/* images media */}
                      {!!images1 && !!images1.length ? (
                        <div className="images-container">
                          <p>Uploaded Images:</p>
                          <div className="image-div-wraper">
                            {images1.map((image, i) => {
                              return (
                                <div className="img-div">
                                  <img src={image.file} alt="Not found" />
                                  <Icon
                                    className="eye-icon"
                                    key={i}
                                    onClick={() =>
                                      this.props.toggleShowImageModal(
                                        i,
                                        images1
                                      )
                                    }
                                    component={ImgEye}
                                  />
                                  <a
                                    className="download-icon"
                                    href={
                                      `${process.env.REACT_APP_API_URL}` +
                                      `/products/download_product_media?url=` +
                                      `${image.file}`
                                    }
                                  >
                                    <Icon component={DownloadBG} />
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : null}
                      {/* video media */}
                      {!!video1 && !!video1.length ? (
                        <div className="videos-container">
                          <p>Uploaded Videos:</p>
                          <div className="video-div-wraper">
                            {video1.map((vid, i) => {
                              return (
                                <div className="video-div">
                                  <video src={vid.file} />

                                  <a
                                    className="download-icon"
                                    href={
                                      `${process.env.REACT_APP_API_URL}` +
                                      `/products/download_product_media?url=` +
                                      `${vid.file}`
                                    }
                                  >
                                    <Icon component={DownloadBG} />
                                  </a>
                                  <Icon
                                    className="video-icon"
                                    key={i}
                                    onClick={() =>
                                      this.props.toggleShowImageModal(i, video1)
                                    }
                                    component={VideoIcon}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : null}
                      <button
                        onClick={() => {
                          this.setState({
                            mediaAttachments: [],
                            showMedia: false,
                          });
                        }}
                        style={{
                          width: "200px",
                          marginBottom: "10px",
                          marginLeft: "16px",
                          border: "0px",
                          color: "#212121",
                        }}
                      >
                        Go Back
                      </button>
                    </>
                  ) : (
                    <div className="no-found">No Media Found!</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

export default NdrEscalationCard;
