import React from "react";
import { Modal } from "antd";
import vcimage from "../../../../assets/images/icons/vc.png";
import axios from "axios";
class ContactCenterModal extends React.Component {
  handleUnLock() {
    axios({
      url: process.env.REACT_APP_API_URL + "/products/wingman_login",
      method: "post",
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success) {
          if (res.data.url) {
            window.open(res.data.url, "_blank");
          }
        } else {
        }
      })
      .catch(() => {});
  }
  render() {
    return (
      <Modal
        className="contact-cen"
        visible={this.props.contactCenterModal}
        footer={null}
        onCancel={() => this.props.enableModal()}
      >
        <div class="contact-center-modal">
          <h1>Wingman Settings</h1>
          <h2>Steps to enable Wingman Services:</h2>
          <div class="logo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="62.617"
              height="52"
              viewBox="0 0 62.617 52"
            >
              <defs>
                <linearGradient
                  id="linear-gradient"
                  x2="1"
                  y2="1"
                  gradientUnits="objectBoundingBox"
                >
                  <stop offset="0" stop-color="#26b975" />
                  <stop offset="1" stop-color="#26b99a" />
                </linearGradient>
              </defs>
              <g
                id="Group_4344"
                data-name="Group 4344"
                transform="translate(-88 -116)"
              >
                <path
                  id="Union_6"
                  data-name="Union 6"
                  d="M4,52a4,4,0,0,1-4-4V2A2,2,0,0,1,2,0H48a4,4,0,0,1,4,4V52Zm48,0V38.543L62.617,52Z"
                  transform="translate(88 116)"
                  fill="url(#linear-gradient)"
                />
                <g
                  id="Path_393"
                  data-name="Path 393"
                  transform="translate(95.8 123.8)"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    d="M2.275,0H31.85A4.55,4.55,0,0,1,36.4,4.55V36.4H4.55A4.55,4.55,0,0,1,0,31.85V2.275A2.275,2.275,0,0,1,2.275,0Z"
                    stroke="none"
                  />
                  <path
                    d="M 2.274997711181641 2 C 2.123359680175781 2 2 2.123359680175781 2 2.274997711181641 L 2 31.85001754760742 C 2 33.25608825683594 3.143928527832031 34.40001678466797 4.549999237060547 34.40001678466797 L 34.40001678466797 34.40001678466797 L 34.40001678466797 4.549999237060547 C 34.40001678466797 3.143928527832031 33.25608825683594 2 31.85001754760742 2 L 2.274997711181641 2 M 2.274997711181641 0 L 31.85001754760742 0 C 34.36291885375977 0 36.40001678466797 2.037109375 36.40001678466797 4.549999237060547 L 36.40001678466797 36.40001678466797 L 4.549999237060547 36.40001678466797 C 2.037109375 36.40001678466797 0 34.36291885375977 0 31.85001754760742 L 0 2.274997711181641 C 0 1.018547058105469 1.018547058105469 0 2.274997711181641 0 Z"
                    stroke="none"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="43.033"
              height="38.73"
              viewBox="0 0 43.033 38.73"
            >
              <path
                id="icons8-connect"
                d="M38.578,3a6.488,6.488,0,0,0-6.455,6.455,6.337,6.337,0,0,0,.651,2.761l-8,8H14.507a6.455,6.455,0,1,0,0,4.3H24.777l8,8a6.337,6.337,0,0,0-.651,2.761,6.455,6.455,0,1,0,6.455-6.455,6.337,6.337,0,0,0-2.761.651l-7.106-7.106,7.106-7.106a6.337,6.337,0,0,0,2.761.651,6.455,6.455,0,1,0,0-12.91Zm0,4.3a2.152,2.152,0,1,1-2.152,2.152A2.12,2.12,0,0,1,38.578,7.3ZM8.455,20.213A2.152,2.152,0,1,1,6.3,22.365,2.12,2.12,0,0,1,8.455,20.213Zm30.123,12.91a2.152,2.152,0,1,1-2.152,2.152A2.12,2.12,0,0,1,38.578,33.123Z"
                transform="translate(-2 -3)"
              />
            </svg>

            <svg
              style={{ marginLeft: "30px" }}
              id="g1440"
              xmlns="http://www.w3.org/2000/svg"
              width="53.757"
              height="53.715"
              viewBox="0 0 53.757 53.715"
            >
              <path
                id="path53330"
                d="M-.448,0H53.288l-.021,53.715H-.469Z"
                transform="translate(0.469)"
                fill="#191919"
              />
              <path
                id="path53332"
                d="M442.343,379.578c.005.008.017.013.022.023a.247.247,0,0,1,.025.032c.005.01.017.023.022.035s.012.025.018.037.012.027.017.04a.186.186,0,0,1,.012.042c0,.013.005.029.01.044a.192.192,0,0,1,.005.045.392.392,0,0,1,0,.046c0,.01,0,.022,0,.033s0,.031,0,.046,0,.031,0,.045a.229.229,0,0,1-.01.045.213.213,0,0,1-.012.043.208.208,0,0,1-.017.042c-.005.013-.012.027-.017.039s-.012.025-.018.037-.017.023-.022.034a.192.192,0,0,1-.025.031.376.376,0,0,1-.028.027.257.257,0,0,1-.028.023.136.136,0,0,1-.031.018c-.01,0-.022.01-.031.013l-.032.01a.167.167,0,0,1-.033,0h-.022a.185.185,0,0,1-.033,0,.135.135,0,0,1-.033-.008l-.032-.012a.125.125,0,0,1-.031-.017l-.031-.02c-.01-.008-.018-.017-.028-.024a.144.144,0,0,1-.025-.028.249.249,0,0,1-.025-.031c-.005-.01-.017-.023-.022-.035s-.012-.025-.018-.038-.012-.026-.017-.04a.189.189,0,0,1-.012-.042c0-.013-.005-.029-.01-.044a.194.194,0,0,1-.005-.045.223.223,0,0,1,0-.027L434.7,399.421h6.3l.04,0a.422.422,0,0,0,.056-.01.446.446,0,0,0,.056-.018.464.464,0,0,0,.054-.025.527.527,0,0,0,.052-.032.573.573,0,0,0,.05-.039.653.653,0,0,0,.046-.046q.022-.025.043-.052t.039-.058q.018-.03.035-.063c.01-.021.02-.043.028-.066l0-.008,7.067-19.432Zm-1.006,18.427.025.027-.025-.027Zm-.778.123-.018.036Zm-.035.063q-.017.033-.031.067Q440.507,398.224,440.523,398.191Zm1.041.451q0,.038,0,.075Q441.564,398.68,441.565,398.642Z"
                transform="translate(-415.217 -362.577)"
                fill="#67e9d9"
              />
              <path
                id="path53334"
                d="M234.041,376.7l3.765,11.053.01.028q.061.161.131.314l.3.876.19.558,2.284,6.705.01.028q.012.033.025.064t.033.064q.018.031.038.06t.042.054a.656.656,0,0,0,.046.048.593.593,0,0,0,.049.041.543.543,0,0,0,.052.035.489.489,0,0,0,.054.027.457.457,0,0,0,.056.02.408.408,0,0,0,.113.015h6.33L243.8,385.641l-.01-.028q-.061-.161-.131-.314l-.3-.876-.19-.558-2.284-6.705-.01-.028q-.013-.033-.028-.064t-.034-.064q-.018-.031-.038-.059a.678.678,0,0,0-.042-.054.649.649,0,0,0-.046-.048.592.592,0,0,0-.049-.041.522.522,0,0,0-.052-.034.5.5,0,0,0-.054-.027.468.468,0,0,0-.056-.02.442.442,0,0,0-.057-.012.416.416,0,0,0-.056,0h-6.327Zm5.927.223q-.021.027-.039.058h0q.019-.03.039-.058Zm-.074.121q-.017.033-.031.067h0q.014-.035.031-.067Zm-.038.091q-.009.024-.017.048h0c.005-.016.011-.032.017-.048Zm1.019.735q-.015.035-.031.067h0Q240.861,377.909,240.875,377.874Zm-.045.095-.018.036h0Zm-.031,17.424h0l-.018.036.018-.036Zm-.035.063h0q-.017.033-.031.067.014-.035.031-.067Zm1.048.536q0,.034-.005.068Q241.81,396.026,241.811,395.992Zm-.042.219h0c-.005.016-.011.032-.017.048Q241.762,396.235,241.77,396.211Zm-.025.071h0q-.015.035-.031.067Q241.73,396.316,241.744,396.282Zm-.065.131h0q-.019.03-.039.058Q241.661,396.443,241.679,396.412Z"
                transform="translate(-223.544 -359.842)"
                fill="#5db3ff"
              />
              <path
                id="path53336"
                d="M562.342,379.358l-4,.008h-.4l-11.625-.041h-.093a.678.678,0,0,0-.078.008c-.025,0-.052.008-.077.013a.385.385,0,0,0-.075.02l-.072.025-.069.031a.519.519,0,0,0-.064.036.454.454,0,0,0-.059.041.6.6,0,0,0-.054.045c-.017.017-.033.032-.048.049s-.025.031-.037.047l-.018.031-2.14,6.214,10.176-.02v0l6.385-.008Z"
                transform="translate(-519.078 -362.346)"
                fill="#fefefe"
              />
              <path
                id="path53338"
                d="M657.534,613.436H648.8l-1.748,4.812,8.735-.005Z"
                transform="translate(-618.069 -585.977)"
                fill="#fefefe"
              />
            </svg>
          </div>

          <p>
            1. Click
            <a href="#" onClick={() => this.handleUnLock()}>
              here{" "}
            </a>{" "}
            to create your account on Wingman.
          </p>
          <p>
            2. Once your account is created, please fill your KYC Details.
            {/* <span>
              <a
                href="https://app.vconversio.com/vfulfill/login"
                target="_blank"
              >
                here
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.259"
                  height="11.448"
                  viewBox="0 0 11.259 11.448"
                >
                  <g
                    id="Group_3617"
                    data-name="Group 3617"
                    transform="translate(-961.362 -333.152)"
                  >
                    <path
                      id="Path_84"
                      data-name="Path 84"
                      d="M-4594.261-691.587h-1.944v10h10V-683.4"
                      transform="translate(5558.167 1025.587)"
                      fill="none"
                      stroke="#1370fc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.2"
                    />
                    <g
                      id="Group_3178"
                      data-name="Group 3178"
                      transform="translate(965.64 334)"
                    >
                      <path
                        id="Path_85"
                        data-name="Path 85"
                        d="M-4590.015-685.789l6.133-6.2"
                        transform="translate(4590.015 691.988)"
                        fill="none"
                        stroke="#1370fc"
                        stroke-linecap="round"
                        stroke-width="1.2"
                      />
                      <path
                        id="Path_86"
                        data-name="Path 86"
                        d="M-4587.394-691.587h4.074v3.886"
                        transform="translate(4589.643 691.587)"
                        fill="none"
                        stroke="#1370fc"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.2"
                      />
                    </g>
                  </g>
                </svg>{" "}
              </a>
            </span> */}
          </p>
          <p>
            3. After submitting your KYC Details, please connect your Shopify
            Store.
          </p>
          <p>4. Enable the WhatsApp Automation from Settings Page.</p>
          <p>
            5. Once done, enable the Wingman Settings in your vFulfill Dashboard
            as well.
          </p>

          <button onClick={() => this.props.enableModal()}>
            {" "}
            Close Window
          </button>
        </div>
      </Modal>
    );
  }
}

export default ContactCenterModal;
