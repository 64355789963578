import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";

class StoreProductTitle extends React.Component {
  render() {
    return (
      <div>
        {this.props.productname && this.props.productname.length <= 60 ? (
          <p>
            {this.props.catalogComplete === 1 ? (
              <p
                className="f16 clamp2 mb4"
                style={{
                  color: "#1268FB",
                  fontWeight: 500,
                  WebkitBoxOrient: "vertical",
                  marginRight: 20,
                }}
              >
                <Link
                  className="f16 hover-underline-animation"
                  to={`/${this.props.storegeo}/${this.props.storeid}/products/${
                    this.props.vfprodid
                  }`}
                  target="_blank"
                >
                  {this.props.productname}
                </Link>
              </p>
            ) : (
              <p
                className="f16 clamp2 mb4"
                style={{
                  color: "#212121",
                  fontWeight: 500,
                  WebkitBoxOrient: "vertical",
                  marginRight: 30,
                }}
              >
                {this.props.productname}
              </p>
            )}
          </p>
        ) : (
          <Tooltip
            title={
              <p style={{ textAlign: "center", fontSize: "12px" }}>
                {this.props.productname}
              </p>
            }
          >
            {this.props.catalogComplete === 1 ? (
              <p
                className="f16 clamp2 mb4"
                style={{
                  color: "#1268FB",
                  fontWeight: 500,
                  WebkitBoxOrient: "vertical",
                  marginRight: 30,
                }}
              >
                <Link
                  className="f16 hover-underline-animation"
                  to={`/${this.props.storegeo}/${this.props.storeid}/products/${
                    this.props.vfprodid
                  }`}
                  target="_blank"
                >
                  {this.props.productname
                    ? this.props.productname.slice(0, 50) + "..."
                    : this.props.productname}
                </Link>
              </p>
            ) : (
              <p
                className="f16 clamp2 mb4"
                style={{
                  color: "#212121",
                  fontWeight: 500,
                  WebkitBoxOrient: "vertical",
                  marginRight: 30,
                }}
              >
                {this.props.productname
                  ? this.props.productname.slice(0, 50) + "..."
                  : this.props.productname}
              </p>
            )}
          </Tooltip>
        )}
      </div>
    );
  }
}

export default StoreProductTitle;
