import React from "react";
import { Button } from "antd";
import { ReactComponent as MoverIllustrationUnicorn } from "../../../../../../assets/images/icons/MoverIllustrationUnicorn.svg";
import { ReactComponent as RightCircle } from "../../../../../../assets/images/icons/RightCircle.svg";
import "./index.scss";

class UniCornCard extends React.Component {
  state = { visible: false };

  render() {
    return (
      <div
        // onClick={(key) => this.props.tabHandler("2")}

        className="unicorn-card-wraper"
      >
        <div className="price-card">
          <h1>
            Unicorn <span>(Add-On)</span>
          </h1>

          <div className="price">
            <h2>$799</h2>
            <span>/{this.props.planType}*</span>
          </div>

          <Button>Book A Consultation Call</Button>
        </div>

        <div className="right">
          <div className="illustration-wraper">
            <MoverIllustrationUnicorn />
            <p>
              “I am looking for{" "}
              <span>
                someone who will dedicatedly manage & strategise my eCom stores
              </span>{" "}
              with me”
            </p>
          </div>

          <div className="features-card">
            <div className="features">
              <div className="feature-row">
                <RightCircle /> <p>Dedicated Account Manager</p>
              </div>
              <div className="feature-row">
                <RightCircle />{" "}
                <p>Custom Real-Time Profit Analysis Dashboard</p>
              </div>
              <div className="feature-row">
                <RightCircle /> <p>Bi-monthly Strategy Sessions</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UniCornCard;
