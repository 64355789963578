import React from "react";
import "./index.scss";
import { Icon, Tooltip } from "antd";
import { ReactComponent as TooltipIcon } from "../../../../assets/images/icons/tooltipican.svg";

export default function GlobalTooltipIcon(props) {
  const tooltipText = props.tooltipText;
  return (
    <span className="global-tooltip-black">
      <Tooltip title={<p>{tooltipText}</p>}>
        <Icon
          component={TooltipIcon}
          className="global-tooltip-icon-black"
          name="tooltip"
        />
      </Tooltip>
    </span>
  );
}
