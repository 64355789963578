import React, { Component } from "react";
import { Select, Checkbox, Divider, Icon, Button, Tooltip } from "antd";
import { ReactComponent as Arrow } from "../../../../../assets/images/icons/arrow.svg";
import { ReactComponent as Lock } from "../../../../../assets/images/icons/filterLock.svg";
import "./index.scss";
const { Option, OptGroup } = Select;

class MavFilter extends Component {
  state = {
    selected: this.props.value,
    open: false,
    searchText: "",
    arrow: false,
  };

  componentDidUpdate(pp) {
    if (pp.value !== this.props.value) {
      this.setState({ selected: this.props.value });
    }
  }

  reasons = [
    { value: "address_incomplete", label: "Address Incomplete" },
    { value: "city_rto_history", label: "City RTO History" },
    { value: "pincode_rto_history", label: "Pincode RTO History" },
    { value: "invalid_address", label: "Invalid Address" },
    { value: "short_address_length", label: "Short Address Length" },
    { value: "house_flat_no_absent", label: "House/Flat No. Not Present" },
  ];

  rto_risk = [
    // { value: 0, label: "0" },
    { value: "high", label: "High" },
    { value: "moderate", label: "Moderate" },
    { value: "low", label: "Low" },
  ];

  negativeRisk = [
    {
      value: "address_complete",
      label: "Address Complete",
    },
    {
      value: "no_city_rto_history",
      label: "No City RTO History",
    },
    {
      value: "no_pincode_rto_history",
      label: "No Pincode RTO History",
    },

    {
      value: "valid_address",
      label: "Valid Address",
    },
    {
      value: "full_address_length",
      label: "Valid Address Length",
    },

    {
      value: "house_flat_no_present",
      label: "House/Flat No. Exist",
    },
  ];

  selectAll() {
    if (this.props.type === "reason") {
      let selected = this.reasons.map((d) => d.value);
      let negative = this.negativeRisk.map((d) => d.value);
      let all = [...selected, ...negative];

      this.setState({ selected: all }, () =>
        this.props.changeHandler(this.state.selected)
      );
    } else {
      let selected = this.rto_risk.map((d) => d.value);
      this.setState({ selected: selected }, () =>
        this.props.changeHandler(this.state.selected)
      );
    }
  }

  render() {
    const selectProps = {
      mode: "multiple",
      // open: true,
      style: { width: this.props.width },
      value: this.props.value,
      searchValue: this.state.searchText,
      dropdownMatchSelectWidth: true,
      dropdownClassName: this.props.overlayClass,
      maxTagCount: 1,
      dropdownRender: (menu) => (
        <div>
          {menu}
          <Divider style={{ margin: "4px 0" }} />
          <div
            className="overlay-footer"
            style={{ cursor: "pointer" }}
            onMouseDown={(e) => e.preventDefault()}
          >
            {this.state.selected.length > 0 ? (
              <Button
                type=""
                onClick={() =>
                  this.setState({ selected: [] }, () =>
                    this.props.changeHandler(this.state.selected)
                  )
                }
              >
                Clear All
              </Button>
            ) : (
              <Button onClick={() => this.selectAll()} type="primary">
                Select All
              </Button>
            )}
          </div>
        </div>
      ),
      onSearch: (t) => this.setState({ searchText: t }),
      filterOption: (input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
      onChange: (d) =>
        this.setState({ selected: d }, () =>
          this.props.changeHandler(this.state.selected)
        ),
      onDropdownVisibleChange: (d) => this.setState({ arrow: d }),
    };

    const PageWrapper = (children) => {
      return (
        <Tooltip
          title={
            !this.props.showMavFilters ? (
              <div>
                Upgrade your plan to unlock this feature.
                <div style={{ marginTop: "12px" }}>
                  <a
                    href="../../../profile/?plan=upgradesubscriptionplan"
                    style={{
                      border: "1px solid #0068FF",
                      padding: "5px",
                      borderRadius: "4px",
                      backgroundColor: "#0068FF",
                      color: "#fff",
                      fontWeight: "500",
                    }}
                    target="_blank"
                  >
                    {" "}
                    Upgrade Plan Now
                  </a>
                </div>
              </div>
            ) : null
          }
        >
          <div style={{ height: "40px" }}>
            <div
              className={`mav-filter-wraper ${
                this.props.type === "reason" ? `custom-padding` : ``
              } ${!this.props.showMavFilters ? "lock-mav" : ""}`}
            >
              <div className="custom-text">
                {this.props.type === "reason"
                  ? `Address Quality Metrics: `
                  : `RTO Risk Indicator: `}
                {!!this.state.selected.length ||
                !!this.state.searchText.length ? null : (
                  <span>All</span>
                )}
              </div>

              <Select disabled={!this.props.showMavFilters} {...selectProps}>
                {(this.props.type === "rto_risk"
                  ? this.rto_risk
                  : this.reasons
                ).map((d, i) => {
                  return (
                    <Option key={i} value={d.value}>
                      {d.label}
                    </Option>
                  );
                })}
              </Select>
              <div
                className={`custom-arrow-icon ${
                  this.state.arrow ? `open` : ``
                }`}
              >
                {!this.props.showMavFilters ? (
                  <span className="lock-container">
                    <Lock />{" "}
                  </span>
                ) : (
                  <Arrow />
                )}
              </div>
            </div>
          </div>
        </Tooltip>
      );
    };

    return PageWrapper();
  }
}

export default MavFilter;
