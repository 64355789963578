import { Button, Icon, Modal } from "antd";
import React from "react";
import "./index.scss";

import { ReactComponent as DownloadBG } from "../../../../../../assets/images/icons/download-bg.svg";
import { ReactComponent as VideoTick } from "../../../../../../assets/images/icons/video-play.svg";

export const UploadedMedia = ({ data, onCancel }) => {
  let { visible, media } = data;
  return (
    <Modal
      title="Upload Media" //told by shvanker
      visible={visible}
      onCancel={onCancel}
      footer={<Button onClick={() => onCancel()}>Close</Button>}
    >
      <div className="image-row">
        {media.map((url) => {
          let arr = url.split(".");
          let type = "image";
          let imageTYPE = ["jpeg", "jpg", "png", "gif", "bmp"];
          if (imageTYPE.includes(arr[arr.length - 1])) {
            type = "image";
          } else if (arr[arr.length - 1] === "pdf") {
            type = "pdf";
          } else {
            type = "other";
          }
          if (type === "pdf") {
            return (
              <span className="img-cointainer">
                <a
                  className="download-icon"
                  // href={
                  //   `${process.env.REACT_APP_API_URL}` +
                  //   `/products/download_product_media?url=` +
                  //   `${url}`
                  // }
                  href={url}
                  target="_blank"
                >
                  <Icon component={DownloadBG} />
                </a>
                <iframe
                  src={url}
                  style={{
                    height: "110px",
                    width: "110px",
                    marginRight: "10px",
                  }}
                />
              </span>
            );
          }
          if (type === "other") {
            return (
              <span className="img-cointainer">
                <a
                  className="download-icon"
                  // href={
                  //   `${process.env.REACT_APP_API_URL}` +
                  //   `/products/download_product_media?url=` +
                  //   `${url}`
                  // }
                  href={url}
                  target="_blank"
                >
                  <Icon component={DownloadBG} />
                </a>
                <span className="io-container">
                  <VideoTick />
                </span>
              </span>
            );
          }
          return (
            <div className="img-cointainer">
              <a
                className="download-icon"
                // href={
                //   `${process.env.REACT_APP_API_URL}` +
                //   `/products/download_product_media?url=` +
                //   `${url}`
                // }
                href={url}
                target="_blank"
              >
                <Icon component={DownloadBG} />
              </a>
              <img src={url} />
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
