import React, { Component } from "react";
import "./index.scss";
import ContactCenterTab from "./ContactCenterTab";
import ContactCenterModal from "./ContactCenetermodal";
import WingManAppModal from "./WingManAppModal";
import { ReactComponent as Set } from "../../../assets/images/icons/set.svg";
import axios from "axios";
import { connect } from "react-redux";
import { message, Icon, Button, Skeleton, Tooltip } from "antd";
import WingmanTab from "./WingmanTab";
class ContactCenter extends Component {
  state = {
    Settingsbtn: true,
    contactCenterModal: false,
    activeTab: "contact-center",
    cod: {
      enabled: 0,
      callingServiceUse: "NEW_ORDER",
      ordersConfirmed: "ORDERS_VERRIFIED",
      declineOrder: "CANCEL_ORDER",
      customerNotReachable: "CANCEL_ORDER",
    },
    prepaid: {
      enabled: 0,
      callingServiceUse: "NEW_ORDER",
      ordersConfirmed: "ORDER_VERIFIED",
      CustomerDecline: "CANCEL_ORDER",
      customerNotReachable: "CANCEL_ORDER",
    },
    ndr: {
      enabled: 0,
      customerNotReachable: "RE_ATTEMPT_ANYWAYS",
    },
    entice: {
      cod: {
        enabled: 0,
        ordersConfirmed: "ORDERS_VERRIFIED",
        orderCancel: "DO_NOTHING",
      },
      prepaid: {
        enabled: 0,
        ordersConfirmed: "ORDERS_VERRIFIED",
        orderCancel: "DO_NOTHING",
      },
      ndr: {
        enabled: 0,
        reschedule: "RE_ATTEMPT_ANYWAYS",
      },
    },
    wingManAppModal: false,
    loading: false,
    loading2: true,
    loading3: false,
    showWingman: false,
  };

  contactCenterStatusEnable() {
    this.setState({ contactCenterModal: true });
  }
  wingmanappSettings() {
    this.setState({
      wingManAppModal: true,
    });
  }
  handleUnLock() {
    axios({
      url: process.env.REACT_APP_API_URL + "/products/wingman_login",
      method: "post",
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success) {
          if (res.data.url) {
            window.open(res.data.url, "_blank");
          }
        } else {
        }
      })
      .catch(() => {});
  }

  fetchContactCenter() {
    this.setState(
      {
        loading2: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL + "/Contactcenter/get_store_services",
          method: "post",
          data: {
            // store_id: this.props.storeId,
            storeid: this.props.match.params.storeid,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading2: false,
                ...res.data.data,
              });
            }
          })
          .catch((e) => {
            this.setState({
              loading2: false,
            });
          })
          .finally(() => {
            this.setState({ loading2: false });
          });
      }
    );
  }
  fetchWingMan() {
    this.setState({ loading3: true }, () => {
      axios({
        url:
          process.env.REACT_APP_API_URL + "/Contactcenter/get_wingman_settings",
        method: "post",
        data: {
          storeid: this.props.match.params.storeid,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1 && res.data.data) {
            this.setState({
              entice: {
                ...res.data.data,
              },
            });
          } else {
            if (res.data.msg === "Please set data") {
              // message.info(res.data.msg);
            } else {
              message.error(res.data.msg || "Error in getting wingman setting");
            }
          }
        })
        .catch((e) => {
          message.error(e.message);
        })
        .finally(() => {
          this.setState({
            loading3: false,
          });
        });
    });
  }
  loadSettting() {
    axios({
      url: process.env.REACT_APP_API_URL + "/store/get_settings",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            showWingman: !res.data.order_confirmation_management_setting
              .order_management_enable,
          });
        } else {
        }
      })
      .catch((e) => {
        // this.setState({
        //   error: true,
        //   errorMsg: e.message,
        // });
      });
  }
  componentDidMount() {
    axios({
      url: process.env.REACT_APP_API_URL + "/snapshot/",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          // const steps = res.data.steps;

          res.data.steps.forEach((step) => {
            if (step.id === "CONTACT_CENTER" && step.completed !== true) {
              this.setState({
                contactCenterModal: true,
                contactCenterModalShow: true,
              });
            }
          });

          this.setState({
            steps: res.data.steps,
          });
        } else {
          this.setState({
            error: true,
            errorMsg: res.data.msg,
            loading: false,
          });
        }
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMsg: e.message,
          loading: false,
        });
      });

    this.fetchContactCenter();
    // this.loadSettting();
  }

  contactCenterStatus() {
    this.setState({ contactCenterModal: false });
    if (this.state.contactCenterModalShow === true) {
      axios({
        url: process.env.REACT_APP_API_URL + "/snapshot/contact_center_setting",
        method: "post",
        withCredentials: true,
        data: { storeid: `${this.props.match.params.storeid}` },
      })
        .then((res) => {})
        .catch((e) => {
          message.error(e.message);
        });
    }
  }

  getSettings() {
    return {
      cod: {
        ...this.state.cod,
      },
      prepaid: {
        ...this.state.prepaid,
      },
      ndr: {
        ...this.state.ndr,
      },
    };
  }
  getWingmanSetting() {
    return {
      entice: {
        ...this.state.entice,
      },
      storeid: this.props.match.params.storeid,
    };
  }
  updateSetting = (section, field, value) => {
    this.setState({
      [section]: {
        ...this.state[section],
        [field]: value,
      },
    });
  };
  updatewingmanSetting = (section, field, value) => {
    this.setState({
      entice: {
        ...this.state.entice,
        [section]: {
          ...this.state.entice[section],
          [field]: value,
        },
      },
    });
  };
  saveWingmanSetting() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/Contactcenter/set_wingman_settings",
          method: "post",
          data: this.getWingmanSetting(),
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState((state) => ({
                loading: false,
              }));
              message.success("Setting Updated successfully!");
            } else {
              message.error(res.data.msg);
              this.setState((state) => ({
                loading: false,
              }));
            }
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
            message.error("There was an error saving the setting!");
          });
      }
    );
  }
  saveSetting() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL + "/Contactcenter/set_store_services",
          method: "post",
          data: {
            contact_center_settings: this.getSettings(),
            storeid: this.props.match.params.storeid,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (
              res.data.success === 1 &&
              res.data.msg !== "Contact center service updated!"
            ) {
              this.setState((state) => ({
                loading: false,
              }));
              message.success("Setting saved successfully!");
              setTimeout(() => {
                this.contactCenterStatusEnable();
              }, 1000);
            } else if (
              res.data.success === 1 &&
              res.data.msg === "Contact center service updated!"
            ) {
              this.setState((state) => ({
                loading: false,
              }));
              message.success("Setting updated successfully!");
            }
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
            message.error("There was an error saving the setting!");
          });
      }
    );
  }

  wingmenAccessHandler() {
    let logo = (
      <span className="logo-containr">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="48"
          height="48"
          viewBox="0 0 62.617 52"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x2="1"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#26b975"></stop>
              <stop offset="1" stop-color="#26b99a"></stop>
            </linearGradient>
          </defs>
          <g
            id="Group_4344"
            data-name="Group 4344"
            transform="translate(-88 -116)"
          >
            <path
              id="Union_6"
              data-name="Union 6"
              d="M4,52a4,4,0,0,1-4-4V2A2,2,0,0,1,2,0H48a4,4,0,0,1,4,4V52Zm48,0V38.543L62.617,52Z"
              transform="translate(88 116)"
              fill="url(#linear-gradient)"
            ></path>
            <g
              id="Path_393"
              data-name="Path 393"
              transform="translate(95.8 123.8)"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                d="M2.275,0H31.85A4.55,4.55,0,0,1,36.4,4.55V36.4H4.55A4.55,4.55,0,0,1,0,31.85V2.275A2.275,2.275,0,0,1,2.275,0Z"
                stroke="none"
              ></path>
              <path
                d="M 2.274997711181641 2 C 2.123359680175781 2 2 2.123359680175781 2 2.274997711181641 L 2 31.85001754760742 C 2 33.25608825683594 3.143928527832031 34.40001678466797 4.549999237060547 34.40001678466797 L 34.40001678466797 34.40001678466797 L 34.40001678466797 4.549999237060547 C 34.40001678466797 3.143928527832031 33.25608825683594 2 31.85001754760742 2 L 2.274997711181641 2 M 2.274997711181641 0 L 31.85001754760742 0 C 34.36291885375977 0 36.40001678466797 2.037109375 36.40001678466797 4.549999237060547 L 36.40001678466797 36.40001678466797 L 4.549999237060547 36.40001678466797 C 2.037109375 36.40001678466797 0 34.36291885375977 0 31.85001754760742 L 0 2.274997711181641 C 0 1.018547058105469 1.018547058105469 0 2.274997711181641 0 Z"
                stroke="#0566f6"
                fill="#fff"
                strokeWidth="2px"
              ></path>
            </g>
          </g>
        </svg>
      </span>
    );

    if (this.state.activeTab === "wingman") {
      if (
        this.props.auth.plan.current_plan.plan_name &&
        String(this.props.auth.plan.current_plan.plan_name)
          .toLowerCase()
          .includes("free")
      ) {
        return (
          <Tooltip
            title={
              <div>
                Upgrade your plan to unlock this feature.
                <div style={{ marginTop: "12px" }}>
                  <a
                    href="../../../profile/?plan=upgradesubscriptionplan"
                    style={{
                      border: "1px solid #0068FF",
                      padding: "5px",
                      borderRadius: "4px",
                      backgroundColor: "#0068FF",
                      color: "#fff",
                      fontWeight: "500",
                    }}
                    target="_blank"
                  >
                    {" "}
                    Upgrade Plan Now
                  </a>
                </div>
              </div>
            }
          >
            <div className="contact-center-settings-btn">
              <Button disabled className={"contact-p clickable"}>
                {logo}
                <span style={{ fontWeight: 600 }}> Access Wingman</span>
              </Button>
            </div>
          </Tooltip>
        );
      }

      return (
        <div className="contact-center-settings-btn">
          <Button
            onClick={() => this.handleUnLock()}
            className={"contact-p clickable"}
          >
            {logo}
            <span style={{ fontWeight: 600 }}> Access Wingman</span>
          </Button>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="contact-center-container">
        <div className="contact-center-nav fullorder_data">
          <div className="contact-ceneternav">
            <div className="contactcenterfield">
              <div className="contactcenterwrap">Services</div>
              <div className="contact-center-secondwrap">
                <div className="tab-container-contact">
                  <div
                    className={
                      this.props.loading
                        ? "non-cursor-pointer tab hover-inventory-underline-animation "
                        : this.state.activeTab === "contact-center"
                        ? "tab  hover-inventory-underline-animation-click"
                        : "tab hover-inventory-underline-animation"
                    }
                  >
                    <button
                      className={
                        this.state.activeTab === "contact-center"
                          ? "active"
                          : ""
                      }
                      style={{ fontWeight: 500 }}
                      onClick={() => {
                        this.setState({
                          activeTab: "contact-center",
                        });
                      }}
                    >
                      Contact Center Settings
                    </button>
                  </div>
                  {/* {this.state.showWingman ? ( */}
                    <div
                      className={
                        this.props.loading
                          ? "non-cursor-pointer tab hover-inventory-underline-animation "
                          : this.state.activeTab === "wingman"
                          ? "tab  hover-inventory-underline-animation-click"
                          : "tab hover-inventory-underline-animation"
                      }
                    >
                      <button
                        className={
                          this.state.activeTab === "wingman" ? "active" : ""
                        }
                        style={{ fontWeight: 500 }}
                        onClick={() => {
                          this.setState({
                            activeTab: "wingman",
                          });
                          this.fetchWingMan();
                        }}
                      >
                        Wingman Settings
                      </button>
                    </div>
                  {/* ) : null} */}
                </div>

                <div className="contact-center-btn">
                  {this.state.activeTab === "contact-center" && (
                    <div className="save-settings-btn">
                      <Button
                        className={
                          this.state.cod.enabled === 1 ||
                          this.state.prepaid.enabled === 1 ||
                          this.state.ndr.enabled === 1
                            ? "clickable-dropdown"
                            : "clickable-dropdown disable-clickable-dropdown"
                        }
                        onClick={() => this.saveSetting()}
                        loading={this.state.loading}
                      >
                        Save Settings
                      </Button>
                    </div>
                  )}
                  {this.state.activeTab === "wingman" && (
                    <div className="save-settings-btn">
                      <Button
                        className={
                          this.state.entice.cod.enabled === 1 ||
                          this.state.entice.prepaid.enabled === 1 ||
                          this.state.entice.ndr.enabled === 1
                            ? "clickable-dropdown"
                            : "clickable-dropdown disable-clickable-dropdown"
                        }
                        style={{
                          marginRight: "100px",
                        }}
                        onClick={() => this.saveWingmanSetting()}
                        loading={this.state.loading}
                      >
                        Save Settings
                      </Button>
                    </div>
                  )}
                  {this.state.activeTab === "contact-center" && (
                    <div className="contact-center-settings-btn">
                      <Button
                        onClick={() => this.contactCenterStatusEnable()}
                        className={
                          this.state.cod.enabled === 1 ||
                          this.state.prepaid.enabled === 1 ||
                          this.state.ndr.enabled === 1
                            ? "contact-p clickable"
                            : "contact-p clickable disable-contact-center-settings-btn"
                        }
                      >
                        <Icon component={Set} />
                        Contact Center Settings
                      </Button>
                    </div>
                  )}

                  {this.wingmenAccessHandler()}

                  {/* {this.state.activeTab === "wingman" && (
                    <div className="contact-center-settings-btn">
                      <Button
                        //  onClick={() => this.contactCenterStatusEnable()}
                        className={
                          // this.state.cod.enabled === 1 ||
                          // this.state.prepaid.enabled === 1 ||
                          // this.state.ndr.enabled === 1
                          false
                            ? "contact-p clickable"
                            : "contact-p clickable disable-contact-center-settings-btn"
                        }
                      >
                        <Icon component={Set} />
                        Settings Info
                      </Button>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="test" className="body-container-contact">
          {this.state.loading2 && <Skeleton />}
          {this.state.activeTab === "contact-center" &&
            !this.state.loading2 && (
              <ContactCenterTab
                {...this.props}
                updateSetting={(a, b, c) => this.updateSetting(a, b, c)}
                cod={this.state.cod}
                prepaid={this.state.prepaid}
                ndr={this.state.ndr}
                fetchContactCenter={() => this.fetchContactCenter()}
              />
            )}
          {this.state.loading3 && <Skeleton />}
          {this.state.activeTab === "wingman" && !this.state.loading3 && (
            <WingmanTab
              {...this.props}
              updateSetting={(a, b, c) => this.updatewingmanSetting(a, b, c)}
              cod={this.state.entice.cod}
              prepaid={this.state.entice.prepaid}
              ndr={this.state.entice.ndr}
            />
          )}
        </div>

        <ContactCenterModal
          {...this.props}
          contactCenterModal={this.state.contactCenterModal}
          enableModal={() => this.contactCenterStatus()}
        />
        <WingManAppModal
          {...this.props}
          contactCenterModal={this.state.wingManAppModal}
          enableModal={() => this.setState({ wingManAppModal: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(ContactCenter);
