import React, { Component } from "react";
import { Input, Select, Icon, message } from "antd";
import { ISDCodes } from "../../../../Helpers/ISD";
import { ReactComponent as editican } from "../../../../assets/images/icons/editican.svg";
import { ReactComponent as rightican } from "../../../../assets/images/icons/right.svg";
import "../index.scss";
import axios from "axios";
import ReactPhoneInput from "react-phone-input-2";

const { Option } = Select;
class SupportPhoneNumber extends Component {
  state = {
    value: "",
    disable: true,
    minutes: 2,
    seconds: 0,
    loading: false,
    myinterval: 0,
  };

  numberValueHandler(dialcode, number) {
    return `${dialcode}` + `${number}`;
  }

  startTimer() {
    if (this.state.myinterval != 0) {
      clearInterval(this.state.myinterval);
    }
    this.setState(
      {
        minutes: 2,
        seconds: 0,
      },
      () => {
        this.state.myinterval = setInterval(() => {
          const { seconds, minutes } = this.state;
          if (seconds > 0) {
            this.setState(({ seconds }) => ({
              seconds: seconds - 1,
            }));
          }
          if (seconds === 0) {
            if (minutes === 0) {
              clearInterval(this.state.myinterval);
            } else {
              this.setState(({ minutes }) => ({
                minutes: minutes - 1,
                seconds: 59,
              }));
            }
          }
        }, 1000);
      }
    );
  }

  resendOtp(event) {
    event.preventDefault();
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/generateotp",
          method: "post",
          withCredentials: true,
          data: {
            type: "mobile",
            location: "main",
            status: "resend",
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
              });
              message.success(res.data.msg);
              this.startTimer();
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
            message.error(e.msg);
          });
      }
    );
  }

  updatePhonenumber() {
    // if (this.props.resetTimer) {
    this.startTimer();
    this.props.updatePhonenumber();
    // } else {
    // this.props.updatePhonenumber();
    // }
  }

  inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  componentDidMount() {
    this.startTimer();
  }

  render() {
    return (
      <div className="support-email-phone-modal">
        <div>
          <div className="header-support-txt">
            <p>Your Phone Number</p>
            {this.props.chnagenumber ? (
              <p>
                <button
                  className="save-number-txt"
                  onClick={() => this.updatePhonenumber()}
                  loading={this.props.loading}
                >
                  <span className="save-ican">
                    <Icon component={rightican} />
                  </span>
                  Save Phone Number
                </button>
              </p>
            ) : (
              <p>
                <button
                  className="change-number-txt"
                  onClick={() => this.props.ChnageNumbertoggle()}
                >
                  <span className="edit-ican">
                    <Icon component={editican} />
                  </span>
                  Change Number
                </button>
              </p>
            )}
          </div>
          <div className="input-number-data">
            {/* <p className="ind-91">
              {this.props.chnagenumber ? (
                <Select
                  style={{ width: 100, marginTop: "7px" }}
                  value={this.props.prefix_1}
                  onChange={(val) =>
                    this.props.changePrefixState("prefix_1", val)
                  }
                  onDropdownVisibleChange={(val) =>
                    this.setState({ arrow: val })
                  }
                  suffixIcon={
                    <svg
                      style={{
                        transition: "all 0.5s",
                        transform: `${
                          this.state.arrow ? "rotateX(180deg)" : "rotateX(0deg)"
                        }`,
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="8"
                      viewBox="0 0 16 8"
                    >
                      <g
                        id="Polygon_49"
                        data-name="Polygon 49"
                        transform="translate(16 8) rotate(180)"
                        fill={`${this.state.arrow ? "#898989" : "none"}`}
                      >
                        <path d="M8,0l8,8H0Z" stroke="none" />
                        <path
                          d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                          stroke="none"
                          fill="#898989"
                        />
                      </g>
                    </svg>
                  }
                >
                  {ISDCodes.map((isd) => (
                    <Option key={`${isd.iso2.toUpperCase()} +${isd.dialCode}`}>
                      {isd.iso2.toUpperCase()} +{isd.dialCode}
                    </Option>
                  ))}
                </Select>
              ) : (
                <p>{this.props.prefix_1}</p>
              )}
            </p> */}
            <p className="input-number-txt">
              {this.props.chnagenumber ? (
                <>
                  {/* <Input
                    type="number"
                    placeholder="Enter Number"
                    value={this.props.contactno}
                    onChange={(e) =>
                      this.props.handleContactChange(e.target.value)
                    }
                  /> */}

                  <ReactPhoneInput
                    placeholder="Enter phone number"
                    enableSearchField
                    // disabled={this.props.phoneverified === 1 ? true : false}
                    value={this.numberValueHandler(
                      this.props.prefix_1,
                      this.props.contactno
                    )}
                    onChange={(val, extra) =>
                      this.props.handleContactChange({
                        value: val,
                        extraVal: extra,
                        field: "contactno",
                      })
                    }
                  />
                </>
              ) : (
                <ReactPhoneInput
                  placeholder="Enter phone number"
                  enableSearchField
                  disabled={true}
                  value={this.numberValueHandler(
                    this.props.prefix_1,
                    this.props.contactno
                  )}
                  onChange={(val, extra) =>
                    this.props.handleContactChange({
                      value: val,
                      extraVal: extra,
                      field: "contactno",
                    })
                  }
                />
              )}
            </p>
          </div>
          <p className="otp-number-txt">
            Your will receive an OTP on this number.
          </p>
        </div>
        <p className="otp-txt">Please wait 1-2 min for the OTP to arrive*</p>
        <div>
          <form onSubmit={this.handleSubmit}>
            <div className="otp-verify">
              <input
                name="otp1"
                type="text"
                onPaste={(e) => this.props.pasteFunc(e)}
                autoComplete="off"
                className="otp-number"
                value={this.props.phoneotp.phoneotp1}
                onKeyPress={this.keyPressed}
                onChange={(e) => this.props.toggleChnageNumber("phoneotp1", e)}
                tabIndex="1"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp2"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.phoneotp.phoneotp2}
                onChange={(e) => this.props.toggleChnageNumber("phoneotp2", e)}
                tabIndex="2"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp3"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.phoneotp.phoneotp3}
                onChange={(e) => this.props.toggleChnageNumber("phoneotp3", e)}
                tabIndex="3"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp4"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.phoneotp.phoneotp4}
                onChange={(e) => this.props.toggleChnageNumber("phoneotp4", e)}
                tabIndex="4"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp5"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.phoneotp.phoneotp5}
                onChange={(e) => this.props.toggleChnageNumber("phoneotp5", e)}
                tabIndex="5"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp6"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.phoneotp.phoneotp6}
                onChange={(e) => this.props.toggleChnageNumber("phoneotp6", e)}
                tabIndex="6"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
            </div>
            <div className="resend-code">
              <span>
                <button
                  className={
                    this.state.minutes == 0 && this.state.seconds === 0
                      ? "resend-code-txt "
                      : "resend-code-txt2"
                  }
                  loading={this.state.loading}
                  onClick={(e) => this.resendOtp(e)}
                >
                  You can resend code in
                </button>
              </span>
              <span>
                {this.state.minutes}:
                {this.state.seconds < 10
                  ? `0${this.state.seconds}`
                  : this.state.seconds}
              </span>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default SupportPhoneNumber;
