import React, { Component } from "react";
import { Tooltip, Icon } from "antd";
import { ReactComponent as Redalertican } from "../../../../../../../assets/images/icons/redalert.svg";
import "./index.scss";

class LinkedVaraintMatchSelecter extends Component {
  render() {
   let{aliData,bundleProduct} = this.props;

   if(!bundleProduct){
    return (
      <div className="variant-link-view-right-varaint-image-data">
        {this.props.variants.map((av) => (
          <div className="variant-link-view-allspec-dropdown">
            <div className="image-allspec-data">
              <img src={av.img ? av.img : "#"} alt="#" />
              <div className="varaint-name-spac">
                <p className="allspecs-product-name">
                  {this.props.aliProduct.name.length > 30 ? (
                    <Tooltip title={this.props.aliProduct.name}>
                      {`${this.props.aliProduct.name.substring(0, 30)}...`}
                    </Tooltip>
                  ) : this.props.aliProduct.name ? (
                    this.props.aliProduct.name
                  ) : (
                    "Not Available"
                  )}
                </p>

                <p className="allspecs-product-name-id">
                  SKU:{" "}
                  <span className="vfsku-id">
                    {av.vfsku ? av.vfsku : "Not available"}
                  </span>
                </p>

                <p className="allspecs-product-name-id">
                  Specs:{" "}
                  <span className="vfsku-id">
                    {av.allspecs ? av.allspecs : "Not available"}
                  </span>
                </p>
              </div>
            </div>
          </div>
        ))}

        {/* {!!this.props.aliProduct.provisional &&
          this.props.aliProduct.variants.map((av) => (
            <div
              className={
                this.props.shopifyProduct.bundle
                  ? "image-allspec-bundle-notlinlk"
                  : "image-allspec-data-notlinlk"
              }
            >
              <Icon component={Redalertican} className="redalert-ican" />

              <div className="notlink-varaint-name-spac">
                <p className="notlink-allspecs-product-name">
                  Variant (<span className="sku-txt">SKU:</span>
                  <span className="vfsku-id">
                    {" "}
                    {av.vfsku ? av.vfsku : "Not available"})
                  </span>
                </p>

                <p className="notlink-txt">is no longer available.</p>

                <p className="notlink-txt">Please select a new variant.</p>
              </div>
            </div>
          ))} */}
      </div>
    );
   }
 
    return (
      <div className="variant-link-view-right-varaint-image-data">
        {this.props.variant.bundle.map((av) => { 
         let index= aliData.findIndex((obj)=>obj.vfsku===av.vfsku);
      
         const currentData =  aliData[index];
         
          return(
          <div className="variant-link-view-allspec-dropdown">
            <div className="image-allspec-data">
              <img src={currentData.img ? currentData.img : "#"} alt="#" />
              <div className="varaint-name-spac">
                <p className="allspecs-product-name">
                  {currentData.name.length > 30 ? (
                    <Tooltip title={currentData.name}>
                      {`${currentData.name.substring(0, 30)}...`}
                    </Tooltip>
                  ) : currentData.name ? (
                    currentData.name
                  ) : (
                    "Not Available"
                  )}
                </p>

                <p className="allspecs-product-name-id">
                  SKU:{" "}
                  <span className="vfsku-id">
                    {currentData.vfsku ? currentData.vfsku : "Not available"}
                  </span>
                </p>

                <p className="allspecs-product-name-id">
                  Specs:{" "}
                  <span className="vfsku-id">
                    {currentData.allspecs ? currentData.allspecs : "Not available"}
                  </span>
                </p>
              </div>
            </div>
          </div>
        )})}

        {/* {!!this.props.aliProduct.provisional &&
          this.props.aliProduct.variants.map((av) => (
            <div
              className={
                this.props.shopifyProduct.bundle
                  ? "image-allspec-bundle-notlinlk"
                  : "image-allspec-data-notlinlk"
              }
            >
              <Icon component={Redalertican} className="redalert-ican" />

              <div className="notlink-varaint-name-spac">
                <p className="notlink-allspecs-product-name">
                  Variant (<span className="sku-txt">SKU:</span>
                  <span className="vfsku-id">
                    {" "}
                    {av.vfsku ? av.vfsku : "Not available"})
                  </span>
                </p>

                <p className="notlink-txt">is no longer available.</p>

                <p className="notlink-txt">Please select a new variant.</p>
              </div>
            </div>
          ))} */}
      </div>
    );
  }
}

export default LinkedVaraintMatchSelecter;
