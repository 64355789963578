import React, { Component } from "react";
import { Row, Skeleton, Alert, Card, Pagination, message } from "antd";
import axios from "axios";
import "./index.scss";
import NdrEscalationCard from "./NdrEscalationCard";
import ShowImageModal from "./ShowImageModal";
import { connect } from "react-redux";
import qs from "qs";
import NdrEscalationNav from "./NdrEscalationNav";
import NotFoundCard from "../Common/NotFoundCard";
import ErrorCard from "../Common/ErrorCard";
import NDREscalationModal from "./NDREscalationModal";
import ActionNotification from "../Common/ActionNotification";
import BucketFilter from "./BucketFilter/BucketFilter";
import AttemptFilter from "./BucketFilter/BucketFilter";
import moment from "moment";
class NdrEscalation extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: "",
    errorCode: "",
    NdrEscalation: [],
    shipments: [],
    tabcount: [],
    total: 0,
    paging: {
      page: 1,
      pageSize: 25,
    },

    filters: {
      startDate: "",
      endDate: "",
      status: "",
      search: "",
      action_status: "",
    },
    showImageModal: {
      visible: false,
      index: 0,
      files: [],
    },
    newData: {},
    remarks: "",
    errorsdata: {},
    ndrEscalationModal: {
      visible: false,
      fileList: [],
      shipmentId: "",
      orderid: "",
      uploading: false,
      awb: "",
      vforderid: "",
      shopifyLink: "",
    },
    date: "",
    clear: false,
    reattemptagain: false,
    fileList: [],
    ndr_bucket: [],
    ndr_count: [],
  };

  // this function used for show  big images
  toggleShowImageModal(i = 0, files = []) {
    this.setState({
      showImageModal: {
        visible: !this.state.showImageModal.visible,
        index: i,
        // imageTag: imageTag,
        files: files,
      },
    });
  }

  componentDidMount() {
    const params = qs.parse(window.location.search.replace(/\?/, ""));
    if (params.search) {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: params.search,
            status: "all",
          },
        },
        () => this.loadData()
      );
    } else {
      this.loadData();
    }
  }
  bucketHandler(e) {
    this.setState({ ndr_bucket: e }, () => this.loadData());
  }
  attemptHandler(e) {
    this.setState({ ndr_count: e }, () => this.loadData());
  }
  componentDidUpdate(pp) {
    if (pp.location.search !== this.props.location.search) {
      const params = qs.parse(this.props.location.search.replace(/\?/g, ""));
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: params.search,
          },
          paging: { page: 1, pageSize: 10 },
        },
        () => this.loadData()
      );
    }
  }

  // this  function used for load the all dispute store data, all data show in disputes state
  loadData() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/ndr/get_ndr_escalation",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            filters: this.state.filters,
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
            ndr_bucket: this.state.ndr_bucket,
            ndr_count: this.state.ndr_count,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                NdrEscalation: res.data.data,
                total: res.data.count,
                tabcount: res.data.navcount,
                loading: false,
                error: false,
                errorMsg: "",
              });
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
                loading: false,
              });
            }
          })
          .catch((e) => {
            if (!!e.request) {
              this.setState({
                error: true,
                errorCode: e.request.status,
                errorMsg: e.request.statusText,
                loading: false,
              });
            } else {
              this.setState({
                error: false,
                errorMsg: e.message,
                loading: true,
              });
            }
          });
      }
    );
  }

  // this function used for search escalation link
  searchhandler() {
    if (window.location.href.includes("escalations")) {
      this.props.history.push(
        `/${this.props.match.params.storegeo}/${this.props.match.params.storeid}/escalation/ndr-escalations?search=${this.state.filters.search}`
      );
    } else {
      this.props.history.push(
        `/${this.props.match.params.storegeo}/${this.props.match.params.storeid}/escalation/ndr-escalations?search=${this.state.filters.search}`
      );
    }
  }
  validatendrcustomerdetail() {
    const order = this.state.newData;
    const date = this.state.date;
    let errors = {};

    if (order.shipping_address.name === "") {
      errors.customer_details_name = "This is a required field!";
    }
    if (order.shipping_address.address1 === "") {
      errors.shipping_address_address1 = "This is a required field!";
    }
    if (order.shipping_address.zip === "") {
      errors.shipping_address_zip = "This is a required field!";
    }
    if (order.customer_details.email === "") {
      errors.customer_details_email = "This is a required field!";
    }
    if (order.shipping_address.country === "") {
      errors.shipping_address_country = "This is a required field!";
    }
    if (order.shipping_address.city === "") {
      errors.shipping_address_city = "This is a required field!";
    }
    if (order.shipping_address.province === "") {
      errors.shipping_address_province = "This is a required field!";
    }
    if (!moment(date).isValid()) {
      errors.date = "This is a required field!";
    }
    this.setState({ errorsdata: errors });
    return errors;
  }
  ////all functions for ndr eslation modal
  //this function on the close status ndr escalation
  //this function on the close status ndr escalation
  //this function on the close status ndr escalation
  //this function on the close status ndr escalation
  //this function on the close status ndr escalation
  onSaveEscalation(file, validateOnly = false) {
    if (!this.state.reattemptagain) {
      const formData = new FormData();
      file.forEach((file) => {
        formData.append("file[]", file);
      });

      formData.append("storeid", this.props.match.params.storeid);
      formData.append("orderid", this.state.ndrEscalationModal.orderid);
      formData.append("shipmentid", this.state.ndrEscalationModal.shipmentId);
      formData.append("remarks", this.state.remarks);
      this.setState(
        {
          ndrEscalationModal: {
            ...this.state.ndrEscalationModal,
            uploading: true,
          },
        },
        () => {
          axios({
            url:
              process.env.REACT_APP_API_URL +
              "/shipments/reopen_ndr_escalation",
            method: "post",
            data: formData,
            withCredentials: true,
          })
            .then((res) => {
              if (res.data.success === 1) {
                ActionNotification({
                  notificationType: "re-attempt-and-escalate-1",
                  awb: this.state.ndrEscalationModal.awb,
                });
                this.loadData();
                const reattempt = this.state.ndrEscalationModal.reattempt;
                if (reattempt) {
                  const ndr = this.state.NdrEscalation.find(
                    (n) =>
                      n.shipment_vfid ===
                      this.state.ndrEscalationModal.shipmentId
                  );
                  this.toggleNdrEscalationModal(
                    (reattempt = false),
                    ndr.shipment_vfid,
                    ndr.awb,
                    {
                      customer_details: ndr.customer_details,
                      shipping_address: ndr.shipping_address,
                    }
                  );
                }
                this.setState((state) => ({
                  ndrEscalationModal: {
                    ...this.state.ndrEscalationModal,
                    visible: !state.ndrEscalationModal.visible,
                    uploading: false,
                  },
                }));
                // message.success("Updated Successfully!");
              } else {
                this.setState({
                  ndrEscalationModal: {
                    ...this.state.ndrEscalationModal,
                    uploading: false,
                  },
                });
                message.error(res.data.msg);
              }
            })
            .catch((e) => {
              this.setState({
                ndrEscalationModal: {
                  ...this.state.ndrEscalationModal,
                  uploading: false,
                },
              });
              message.error(e.message);
            });
        }
      );
    } else {
      const errors = this.validatendrcustomerdetail();

      if (Object.keys(errors).length === 0) {
        const formData = new FormData();
        file.forEach((file) => {
          formData.append("file[]", file);
        });

        formData.append("storeid", this.props.match.params.storeid);
        formData.append("orderid", this.state.ndrEscalationModal.orderid);
        formData.append("shipmentid", this.state.ndrEscalationModal.shipmentId);
        formData.append("remarks", this.state.remarks);
        formData.append(
          "reattempt",
          this.state.reattemptagain
            ? JSON.stringify(this.state.newData)
            : JSON.stringify({})
        );
        formData.append(
          "date",
          this.state.reattemptagain
            ? this.state.date.format("YYYY-MM-DD")
            : JSON.stringify({})
        );
        if (!validateOnly && Object.keys(errors).length === 0) {
          this.setState(
            {
              ndrEscalationModal: {
                ...this.state.ndrEscalationModal,
                uploading: true,
              },
            },
            () => {
              axios({
                url:
                  process.env.REACT_APP_API_URL +
                  "/shipments/reopen_ndr_escalation",
                method: "post",
                data: formData,
                withCredentials: true,
              })
                .then((res) => {
                  if (res.data.success === 1) {
                    ActionNotification({
                      notificationType: "re-attempt-and-escalate",
                      awb: this.state.ndrEscalationModal.awb,
                    });
                    const reattempt = this.state.ndrEscalationModal.reattempt;
                    if (reattempt) {
                      const ndr = this.state.NdrEscalation.find(
                        (n) =>
                          n.shipment_vfid ===
                          this.state.ndrEscalationModal.shipmentId
                      );
                      this.toggleNdrEscalationModal(
                        (reattempt = false),
                        ndr.shipment_vfid,
                        ndr.awb,
                        {
                          customer_details: ndr.customer_details,
                          shipping_address: ndr.shipping_address,
                        }
                      );
                    }
                    this.setState((state) => ({
                      ndrEscalationModal: {
                        ...this.state.ndrEscalationModal,
                        visible: !state.ndrEscalationModal.visible,
                        uploading: false,
                      },
                    }));
                    // message.success("Updated Successfully!");
                  } else {
                    this.setState({
                      ndrEscalationModal: {
                        ...this.state.ndrEscalationModal,
                        uploading: false,
                      },
                    });
                    message.error(res.data.msg);
                  }
                })
                .catch((e) => {
                  this.setState({
                    ndrEscalationModal: {
                      ...this.state.ndrEscalationModal,
                      uploading: false,
                    },
                  });
                  message.error(e.message);
                });
            }
          );
        }
      }
    }
  }
  toggleNdrEscalationModal(
    shipmentid = "",
    orderid = "",
    reattempt = false,
    awb = "",
    address = { customer_details: {}, shipping_address: {} },
    shopifyId = "",
    vforderid = "",
    shopifyLink = ""
  ) {
    this.setState({
      ndrEscalationModal: {
        ...this.state.ndrEscalationModal,
        visible: !this.state.ndrEscalationModal.visible,
        shipmentId: shipmentid,
        orderid: orderid,
        reattempt,
        awb: awb,
        address,
        shopifyId: shopifyId,
        vforderid: vforderid,
        shopifyLink: shopifyLink,
      },

      fileList: [],
      reattemptagain: false,
      remarks: "",
    });
  }
  onNDRReattemptSuccess() {
    this.loadData();
  }
  newDataHandler(object) {
    this.setState({ newData: object });
  }
  reattempttoggle() {
    this.setState({ reattemptagain: !this.state.reattemptagain });
  }
  datehandler(obj2) {
    this.setState({ date: obj2 });
  }
  removeRemarkhnadler(value) {
    this.setState({
      // ...this.state.remarks,
      remarks: value,
    });
  }
  // this function used for filter data in global search
  changeFilter(kvpairs) {
    this.setState(
      {
        filters: { ...this.state.filters, ...kvpairs },
        paging: { ...this.state.paging, page: 1 },
      },
      () => this.searchhandler(),
      () => this.loadData()
    );
  }

  // this function used for range picker and tabs
  changeFilter2(kvpairs) {
    this.setState(
      {
        filters: { ...this.state.filters, ...kvpairs },
        paging: { ...this.state.paging, page: 1 },
      },

      () => this.loadData()
    );
  }

  //this function used for go  particular page in pagination
  goToPage(page) {
    this.setState(
      {
        paging: { ...this.state.paging, page },
      },
      () => {
        this.loadData();
      }
    );
  }

  // this function used for event in global search
  changeHandler(kvpairs) {
    this.setState({
      filters: { ...this.state.filters, ...kvpairs },
    });
  }

  // this function used for  show size of pagination
  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        paging: { ...this.state.paging, pageSize: pageSize },
      },
      () => this.loadData()
    );
  }
  bucketUi() {
    return (
      <div className="bucket-filters-wraper">
        <BucketFilter
          value={this.state.ndr_bucket}
          changeHandler={(e) => this.bucketHandler(e)}
          type="bucket"
        />

        <AttemptFilter
          value={this.state.ndr_count}
          changeHandler={(e) => this.attemptHandler(e)}
          type="attempt"
        />
      </div>
    );
  }

  render() {
    const { fileList } = this.state;
    const imageprops = {
      multiple: true,
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          // fileList: [.file]
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };
    const disputeStatusColors = {
      open: "#FC824E",
      pending: "#FC554E",
      close: "#2BC823",
      reopen: "#FC824E",
    };
    const disputeStatusText = {
      open: "Open",
      pending: "in_Processing",
      close: "Closed",
      reopen: "Re-Opened",
    };
    const shipmentStatusColors = {
      processing: "#FF7A45",
      manifested: "#FF7A45",
      transit: "#FFC53D",
      delivered: "#73D13D",
      rto: "#FF4D4F",
      ndr_actionable: "#FF4D4F",
    };
    const shipmentStatusText = {
      processing: "In Processing",
      manifested: "In Processing",
      transit: "In Transit",
      delivered: "Delivered",
      rto: "Returned",
      ndr_actionable: "NDR",
    };

    const PageWrapper = (children) => {
      return (
        <div className="ndr-escalations">
          <NdrEscalationNav
            filters={this.state.filters}
            changeHandler={(e) => this.changeHandler(e)}
            changeFilter={(e) => this.changeFilter(e)}
            changeFilter2={(e) => this.changeFilter2(e)}
            store={this.props.store}
            tabcounts={this.state.tabcount}
            loading={this.state.loading}
            storeid={this.props.match.params.storeid}
            page={this.state.paging.page}
            pageSize={this.state.paging.pageSize}
            ndr_bucket={this.state.ndr_bucket}
            ndr_count={this.state.ndr_count}
          />
          {this.bucketUi()}
          {children}
          <Row>
            <Pagination
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              showQuickJumper
              showSizeChanger
              pageSizeOptions={["10", "25", "50"]}
              onShowSizeChange={(p, d) => this.onShowSizeChange(p, d)}
              current={this.state.paging.page}
              onChange={(page) => this.goToPage(page)}
              total={this.state.total}
              defaultPageSize={this.state.paging.pageSize}
            />
          </Row>
        </div>
      );
    };

    if (this.state.loading) {
      return PageWrapper(
        <Row className="skeleton">
          <Skeleton active />
        </Row>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <ErrorCard
          errorMsg={this.state.errorMsg}
          errorCode={this.state.errorCode}
        />
      );
    }

    return PageWrapper(
      <div>
        {this.state.NdrEscalation.length > 0 ? (
          this.state.NdrEscalation.map((NdrEscalation) => (
            <NdrEscalationCard
              {...this.props}
              key={NdrEscalation._id["$oid"]}
              dispute={NdrEscalation}
              disputeStatusColors={disputeStatusColors}
              disputeStatusText={disputeStatusText}
              shipmentStatusColors={shipmentStatusColors}
              shipmentStatusText={shipmentStatusText}
              toggleNdrEscalationModal={(reattempt) =>
                this.toggleNdrEscalationModal(
                  NdrEscalation.shipment_vfid,
                  NdrEscalation.order_id["$oid"],
                  reattempt,
                  NdrEscalation.awb,
                  {
                    customer_details: NdrEscalation.customer_details,
                    shipping_address: NdrEscalation.shipping_address,
                  },
                  NdrEscalation.shopify_orderid,
                  NdrEscalation.order_vfid,
                  NdrEscalation.shopify_order_link
                )
              }
              toggleShowImageModal={(i, files) =>
                this.toggleShowImageModal(i, files)
              }
            />
          ))
        ) : (
          <NotFoundCard
            mainHeading={"Oops! No Data Found."}
            secondHeading={
              <p>Looks like there are no escalations in this page.</p>
            }
          />
        )}

        <ShowImageModal
          toggleShowImageModal={() => this.toggleShowImageModal()}
          visible={this.state.showImageModal.visible}
          files={this.state.showImageModal.files}
          defaultIndex={this.state.showImageModal.index}
        />
        <NDREscalationModal
          imageprops={imageprops}
          fileList={fileList}
          {...this.props}
          visible={this.state.ndrEscalationModal.visible}
          toggleNdrEscalationModal={() => this.toggleNdrEscalationModal()}
          confirmNdrEscalationModal={(file) => this.onSaveEscalation(file)}
          address={this.state.ndrEscalationModal.address}
          shipment_vfid={this.state.ndrEscalationModal.shipmentId}
          awb={this.state.ndrEscalationModal.awb}
          shopifyId={this.state.ndrEscalationModal.shopifyId}
          onNDRReattemptSuccess={() => this.onNDRReattemptSuccess()}
          newDataHandler={(o, obj2) => this.newDataHandler(o, obj2)}
          reattempttoggle={() => this.reattempttoggle()}
          reattemptagain={this.state.reattemptagain}
          datehandler={(o) => this.datehandler(o)}
          newData={this.state.newData}
          error={this.state.errorsdata}
          vforderid={this.state.ndrEscalationModal.vforderid}
          removeRemarkhnadler={(val) => this.removeRemarkhnadler(val)}
          remarks={this.state.remarks}
          clear={this.state.clear}
          shopifyLink={this.state.ndrEscalationModal.shopifyLink}
          //  shopifyLink={this.state.ndrEscalationModal.shopifyLink}
          //   shopifyLink={this.state.ndrEscalationModal.shopifyLink}
          uploading={this.state.ndrEscalationModal.uploading}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  store: state.auth.auth.store,
});
export default connect(mapStateToProps)(NdrEscalation);
