import React, { Component } from "react";
import "./index.scss";

class Progressbar extends Component {
  state = {
    progressbar: this.props.questionScreen_step,
    activeStep: this.props.activeStep,
  };

  render() {
    return (
      <div className="progressbar_data">
        <div className="progressbar_data2">
          <div className="line-container">
            <div className="progress-line">
              <div
                className="progress"
                style={{
                  animation:
                    this.state.progressbar.questionScreen2_step1 === 1
                      ? "load-10 1.2s linear forwards"
                      : this.state.progressbar.questionScreen2_step2 === 1
                      ? "load-58 1.2s linear forwards"
                      : this.state.progressbar.questionScreen2_step3 === 1
                      ? "load-100 1.2s linear forwards"
                      : "",
                }}
              />

              <div className="status">
                <div
                  className={`dot completed${
                    this.state.progressbar.questionScreen2_step1 === 1
                      ? ` dot-active`
                      : ``
                  }`}
                />
              </div>

              <div className="status">
                <div
                  className={`dot ${
                    this.state.activeStep.activStep2 === 1 ? "completed" : ""
                  }  ${
                    this.state.progressbar.questionScreen2_step2 === 1
                      ? "dot-active"
                      : ""
                  }`}
                />
              </div>

              <div className="status">
                <div
                  className={`dot ${
                    this.state.activeStep.activStep3 === 1 ? "completed" : ""
                  }  ${
                    this.state.progressbar.questionScreen2_step3 === 1
                      ? "dot-active"
                      : ""
                  }`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Progressbar;
