import React, { Component } from "react";
import { Modal, Button } from "antd";
import "./index.scss";
import ClickToCopy from "../../../../Common/ClickToCopy";

class ViewContent extends Component {
  state = {
    text: "",
  };

  componentDidUpdate(pp) {
    if (pp.data !== this.props.data) {
      this.setState({
        text: this.props.data,
      });
    }
  }

  render() {
    return (
      <Modal
        title={
          <div>
            <h1>Description:</h1>
          </div>
        }
        width={700}
        visible={this.props.visible}
        onCancel={this.props.toggleContentModal}
        className="view-content"
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
        closable={false}
      >
        <p
          dangerouslySetInnerHTML={{
            __html: this.state.text,
          }}
        />
        <div style={{ marginTop: "10px", textAlign: "center" }}>
          {/* <Button type="primary" style={{ marginRight: "10px" }}>
            Copy Description
          </Button> */}
          <ClickToCopy textToCopy={this.state.text}>
            {(copy) => (
              <Button
                onClick={copy}
                type="primary"
                style={{ marginRight: "10px" }}
              >
                Copy Description
              </Button>
            )}
          </ClickToCopy>
          <Button
            onClick={this.props.toggleContentModal}
            style={{ color: "#707070" }}
          >
            Close Description
          </Button>
        </div>
      </Modal>
    );
  }
}

export default ViewContent;
