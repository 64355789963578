import React, { Component } from "react";
import { Modal, Button } from "antd";
import "./index.scss";
// import { ReactComponent as CircleCheck } from "../../../../../assets/images/icons/circle-cross.svg";

class DisputeFailedModal extends Component {
  render() {
    return (
      <Modal
        title={null}
        visible={this.props.visible}
        className="dispute-failed-modal"
        closable={false}
        maskClosable={false}
        footer={null}
        width={400}
      >
        <>
          <div className="dispute-raised-header">
            <div className="di">
              <svg
                width="24"
                height="24"
                viewBox="0 0 16.59 16.59"
                fill="red"
                aria-hidden="true"
                focusable="false"
                class=""
              >
                <g transform="translate(-867 -654)">
                  <path
                    d="M12.169,12.169A7.131,7.131,0,0,1,.561,9.9,7.128,7.128,0,0,1,12.169,2.087a7.119,7.119,0,0,1,0,10.082Z"
                    transform="translate(868.167 655.166)"
                    fill="#fff"
                  />
                  <path
                    d="M8.295,16.59A8.3,8.3,0,0,1,2.429,2.429,8.3,8.3,0,0,1,14.161,14.161,8.241,8.241,0,0,1,8.295,16.59Zm0-7.469h0l2.5,2.508a.582.582,0,0,0,.824,0,.584.584,0,0,0,0-.824l-2.5-2.511,2.5-2.51a.582.582,0,1,0-.824-.822l-2.5,2.506L5.8,4.963a.583.583,0,1,0-.826.822l2.5,2.51-2.5,2.51a.583.583,0,0,0,.413.995.575.575,0,0,0,.413-.172l2.5-2.506Z"
                    transform="translate(867 654)"
                    fill="currentColor"
                  />
                </g>
              </svg>

              <h6>Escalation cannot be raised!</h6>
            </div>

            <p>{this.props.failedReason}</p>

            <div className="but">
              <Button onClick={this.props.onCountinue} type="primary">
                Go Back
              </Button>
              {this.props.failedReasonStatus && (
                <a
                  href="https://help.letx.co/support/letxco/ShowHomePage.do?articlestatus=published#Solutions/dv/244206000024799266/en"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Know More
                </a>
              )}
            </div>
          </div>
        </>
      </Modal>
    );
  }
}

export default DisputeFailedModal;
