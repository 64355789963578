import React, { Component } from "react";
import { DatePicker, Select } from "antd";
import moment from "moment";
import Globalsearch from "../../Common/Globalsearch";
import "./index.scss";

const { Option } = Select;
const { RangePicker } = DatePicker;

class DownloadUtilityNav extends Component {
 

  componentDidUpdate() {
    // let status = this.props.filters.status;

    // if (status === "all" && this.state.mount !== true) {
    //   this.setState({
    //     allBtn: true,
    //     openbtn: false,
    //     closebtn: false,
    //     pendingbtn: false,
    //     mount: true,
    //   });
    // }
  }

  render() {
    return (
      <div className="download-utility-wraper fullorder_data">
        <div className="download-utility-nav">
          <div className="ordfill">
            <div className="ordfillwrap">
              <div className="left">
                <div className="content-page-title">
                  Downloads <span style={{ margin: "0 8px" }}>&gt;</span>
                  <span className="escalations-txt">Download Activity</span>
                </div>
              </div>

              {/* <div className="right">
                <p className="show-data-from">Show Data From: </p>

                <RangePicker
                  className="range-picker"
                  value={[
                    this.props.filters.startDate === ""
                      ? null
                      : moment(this.props.filters.startDate, "YYYY-MM-DD"),
                    this.props.filters.endDate === ""
                      ? null
                      : moment(this.props.filters.endDate, "YYYY-MM-DD"),
                  ]}
                  onChange={(_, dateString) =>
                    this.props.changeFilter2({
                      startDate: dateString[0],
                      endDate: dateString[1],
                    })
                  }
                />
              </div> */}
            </div>
            <div className="secondwrap">
              <div className="filtertab">
                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-ndrEscalations-underline-animation "
                      : this.props.viewPage === "reporting"
                      ? "tab  hover-ndrEscalations-underline-animation-click"
                      : "tab hover-ndrEscalations-underline-animation"
                  }
                >
                  <button
                    className={
                      this.props.viewPage === "reporting" ? "active" : ""
                    }
                    onClick={() => {
                      this.props.onViewPageChange("reporting");
                    }}
                  >
                    Reporting Logs
                  </button>

                  <div
                    className={` ${
                      this.props.viewPage === "reporting"
                        ? "backgroundcircle"
                        : "circle"
                    }`}
                  >
                    {this.props.tabcounts
                      ? this.props.tabcounts > 1000
                        ? (Number(this.props.tabcounts) / 1000).toFixed(1) + "k"
                        : this.props.tabcounts < 1000
                        ? this.props.tabcounts
                        : null
                      : this.props.tabcounts === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>
                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-ndrEscalations-underline-animation "
                      : this.props.viewPage === "jobs"
                      ? "tab  hover-ndrEscalations-underline-animation-click"
                      : "tab hover-ndrEscalations-underline-animation"
                  }
                >
                  <button
                    className={this.props.viewPage === "jobs"? "active" : ""}
                    onClick={() => {
                      this.props.onViewPageChange("jobs");
                      this.props.jobsLoad();
                    }}
                  >
                    Jobs
                  </button>

                  <div
                    className={` ${
                      this.props.viewPage === "jobs"
                        ? "backgroundcircle"
                        : "circle"
                    }`}
                  >
                    {this.props.tabcountJobs
                      ? this.props.tabcountJobs > 1000
                        ? (Number(this.props.tabcountJobs) / 1000).toFixed(1) + "k"
                        : this.props.tabcountJobs < 1000
                        ? this.props.tabcountJobs
                        : null
                      : this.props.tabcountJobs === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-data">
          {/* <div className="Globalsearch">
            <Globalsearch
              class={"nextwidth"}
              data={this.props.filters}
              tooltip={true}
              placeholder={
                "Search by Escalation ID, AWB, Product ID, Product Name"
              }
              change={(value) =>
                this.props.changeHandler({ search: value.target.value })
              }
              searchFunc={(value) => this.props.changeFilter({ search: value })}
            />
          </div> */}
        </div>
      </div>
    );
  }
}

export default DownloadUtilityNav;
