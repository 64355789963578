import React, { Component } from "react";

export default class Container4 extends Component {
  render() {
    let {
      disabled,
      facebookurl,
      instagramurl,
      twitterurl,
      handleInstagramurl,
      handleTwitterurl,
      handleFacebookurl,
    } = this.props;
    return (
      <div>
        <div className="sub-heading">Social Media Links</div>
        <div className="cointainer-input">
          <div className="container-main">
            <div className="title-input">Facebook URL </div>
            <input
              value={facebookurl}
              onChange={handleFacebookurl}
              disabled={disabled}
              className="input"
            />
          </div>

          <div className="container-main">
            <div className="title-input">Twitter URL</div>
            <input
              value={twitterurl}
              onChange={handleTwitterurl}
              disabled={disabled}
              className="input"
            />
          </div>
        </div>
        <div>
          <div className="cointainer-input">
            <div className="container-main">
              <div className="title-input">Instagram  URL</div>
              <input
                value={instagramurl}
                onChange={handleInstagramurl}
                disabled={disabled}
                className="input"
              />
            </div>
          </div>
     
        </div>
      </div>
    );
  }
}
