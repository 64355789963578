import React from "react";
import { Row, Icon } from "antd";
import moment from "moment";
import "./ImportListProductBasic.scss";
import { hasPermission } from "../../../../../../Helpers/Permissions";
import { ReactComponent as requestalert } from "../../../../../../assets/images/icons/requestalert.svg";
import { ReactComponent as requestsucess } from "../../../../../../assets/images/icons/requestsucess.svg";

class RequestLattestPrice extends React.Component {
  render() {
    return (
      <div style={{ width: "100%" }}>
        {hasPermission(this.props.store, "pricing_request_add") &&
        this.props.product.vfprod.prod_status === "1" ? (
          <Row style={{ width: "100%", textAlign: "right", marginTop: "8px" }}>
            <div
              style={{
                fontSize: "12px",
                lineHeight: "16px",
              }}
            >
              {this.props.responsemessage ? (
                this.props.status === 0 ? (
                  <span
                    className="f12"
                    style={{
                      marginBottom: 0,
                      background: "#FFFCE3",
                      padding: "4px 8px",
                      color: "#898989",
                      display: "inline-flex",
                    }}
                  >
                    <Icon component={requestalert} />
                    <span
                      className="underline"
                      style={{
                        letterSpacing: "0.032em",
                        fontSize: "12px",
                        fontWeight: "400",
                        marginLeft: "8px",
                      }}
                    >
                      {this.props.responsemessage}
                    </span>
                  </span>
                ) : (
                  <span
                    className="f12"
                    style={{
                      marginBottom: 0,
                      background: "#F5FFF4",
                      padding: "4px 8px",
                      color: "#898989",
                      display: "inline-flex",
                    }}
                  >
                    <Icon component={requestsucess} />
                    <span
                      className="underline"
                      style={{
                        letterSpacing: "0.032em",
                        fontSize: "12px",
                        fontWeight: "400",
                        marginLeft: "8px",
                      }}
                    >
                      {this.props.responsemessage}
                    </span>
                  </span>
                )
              ) : (
                <div
                  style={{ display: "flex", marginTop: "8px" }}
                  className="f12"
                >
                  <p
                    style={{
                      flex: 1,
                      marginRight: "8px",
                      marginBottom: "0px",
                    }}
                  >
                    {this.props.product.vfprod.lastupdated ? (
                      <span style={{ fontSize: "12px", color: "#898989" }}>
                        Pricing last updated on:{" "}
                        {moment(this.props.product.vfprod.lastupdated).format(
                          "MMM DD, YYYY"
                        )}{" "}
                        (
                        {moment().diff(
                          moment(this.props.product.vfprod.lastupdated),
                          "days"
                        )}{" "}
                        Days ago)
                      </span>
                    ) : null}
                  </p>
                </div>
              )}
            </div>
            <p />
          </Row>
        ) : (
          <span
            style={{
              fontSize: "12px",
              color: "#898989",
              float: "right",
              marginRight: "16px",
              marginTop: "8px",
            }}
          >
            Pricing last updated on: Not Available
          </span>
        )}
      </div>
    );
  }
}

export default RequestLattestPrice;
