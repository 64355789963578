import React, { Component } from "react";
import { Modal, Input, message, Button } from "antd";
import "./index.scss";
export default class WooStoreContent extends Component {
  state = {
    woCommerceDetail: {
      shop_name: "",
      shop_url: "",
      shop_consumer_key: "",
      shop_consumer_secret: "",
    },
    fill: false,
  };
  handleChange(key, value) {
    this.setState(
      {
        woCommerceDetail: {
          ...this.state.woCommerceDetail,
          [key]: value,
        },
      },
      () => {
        if (
          this.state.woCommerceDetail.shop_name &&
          this.state.woCommerceDetail.shop_url &&
          this.state.woCommerceDetail.shop_consumer_key &&
          this.state.woCommerceDetail.shop_consumer_secret
        ) {
          this.setState({
            fill: true,
          });
        }
        if (
            !this.state.woCommerceDetail.shop_name ||
            !this.state.woCommerceDetail.shop_url ||
            !this.state.woCommerceDetail.shop_consumer_key ||
            !this.state.woCommerceDetail.shop_consumer_secret
          ) {
            this.setState({
              fill: false,
            });
          }
      }
    );
  }
  emptywoCommerceDetail() {
    this.setState({
      woCommerceDetail: {
        shop_name: "",
        shop_url: "",
        shop_consumer_key: "",
        shop_consumer_secret: "",
      },
      fill:false
    });
  }
  toggleWoCommerceModal4() {
    this.emptywoCommerceDetail();
    this.props.toggleWoCommerceModal2();
  }
  render() {
    const props = this.props;
    return (
      <div className="woCommerceContant">
        <div>Add New WooCommerce Store</div>
        <hr />
        <div className="woCommerce_detail_input">
          <div className="upeer_label_margin">
            <p className="common_label">
              Shop Name <span className="star">*</span>
            </p>
            <Input
              className="shop_name"
              placeholder="Enter Your Shop Name "
              value={this.state.woCommerceDetail.shop_name}
              onChange={(e) => this.handleChange("shop_name", e.target.value)}
            />
          </div>

          <div className="upeer_label_margin">
            <p className="common_label">
              Shop URL <span className="star">*</span>
            </p>
            <Input
              className="shop_url"
              placeholder="Enter Shop URL"
              value={this.state.woCommerceDetail.shop_url}
              onChange={(e) => this.handleChange("shop_url", e.target.value)}
            />
          </div>

          <div className="upeer_label_margin">
            <p className="common_label">
              Shop Consumer Key <span className="star">*</span>
            </p>
            <Input
              className="shop_consumer_key"
              placeholder="Enter Shop Consumer key"
              value={this.state.woCommerceDetail.shop_consumer_key}
              onChange={(e) =>
                this.handleChange("shop_consumer_key", e.target.value)
              }
            />
          </div>

          <div className="upeer_label_margin">
            <p className="common_label">
              Shop Consumer Secret <span className="star">*</span>
            </p>
            <Input
              className="shop_consumer_secret"
              placeholder="Enter Shop Consumer Secret"
              value={this.state.woCommerceDetail.shop_consumer_secret}
              onChange={(e) =>
                this.handleChange("shop_consumer_secret", e.target.value)
              }
            />
          </div>
        </div>
        <div className="bottom_button">
           <Button
            className="go_back_btn"
            onClick={() => {
              this.emptywoCommerceDetail();
              this.props.handleGoBackToLoctionType();
            }}
          >
            <span className="svg_go_back">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="16"
                viewBox="0 0 8 16"
              >
                <g
                  id="Polygon_63"
                  data-name="Polygon 63"
                  transform="translate(0 16) rotate(-90)"
                  fill="none"
                >
                  <path
                    d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                    stroke="none"
                    fill="#898989"
                  />
                </g>
              </svg>
            </span>
            <span className="go_back_txt">Go Back</span>
          </Button>

          <Button
            onClick={() => this.props.handleCancel()}
            className="cancel_close_btn"
          >
            Cancel & Close
          </Button>

          <Button
            className={!this.state.fill?"not-fill clickable_btn":"clickable_btn"}
            onClick={() =>
              this.props.handleWooCommerceSubmit(this.state.woCommerceDetail)
            }
            loading={this.props.loading}
          >
            Continue
          </Button>
        </div>
      </div>
    );
  }
}
