import React, { Component } from "react";
import { Card, Button, Radio } from "antd";
import Progressbar from "./Progressbar";
import "./index.scss";

class QuestionScreeen2Step1 extends Component {
  state = {};

  render() {
    return (
      <Card className="questionScreen2">
        <div className="questionScreen2-header-txt">
          <p className="questionScreen2-account-txt">
            Let Us Set Up Your Account
          </p>
          <p className="step1">Step 1 of 3</p>
        </div>

        <Progressbar
          questionScreen_step={this.props.questionScreen2_step1}
          activeStep={this.props.activeStep}
        />

        <p className="question-following-txt">
          Which of the following best describes your business?
        </p>

        <div className="step-list1">
          <ul>
            {this.props.step1_options.map((step1, idx) => (
              <li>
                <Radio
                  className="step1-list-checkbox"
                  checked={this.props.Step1Selected === step1.key}
                  onChange={() => this.props.onChangeStep1(step1.key)}
                >
                  {step1.value}
                </Radio>
              </li>
            ))}
          </ul>
        </div>
        <Button
          onClick={() => this.props.toggleContinue("questionScreen2_step2")}
          className={
            !!this.props.Step1Selected.length ? "continue-btn2" : "continue-btn"
          }
        >
          Continue
        </Button>
      </Card>
    );
  }
}

export default QuestionScreeen2Step1;
