import React, { Component } from "react";
// import { Row, Col } from "antd";
import "./index.scss";

class CustomerInfo extends Component {
  render() {
    const shipment = this.props.shipment;
    return (
      <div className="customer-info-tab">
        <div className="header_customer_info">
          <div className="full_name">
            <p className="label">Full Name:</p>
            <p className="shipment_cstm_info capitalize-text">
              {shipment.shipping_address && shipment.shipping_address.name
                ? shipment.shipping_address.name.toLowerCase()
                : null}
            </p>
          </div>

          <div className="address_line1">
            <p className="label">Address Line #1:</p>
            <p className="shipment_cstm_info">
              {shipment.shipping_address.address1}
            </p>
          </div>

          <div className="Pin_code">
            <div>
              <p className="label">Pin Code: </p>
              <p className="shipment_cstm_info">
                {shipment.shipping_address.zip}
              </p>
            </div>
          </div>
        </div>

        <div className="middle_customer_info">
          <div className="email_address">
            <p className="label">Email Address: </p>
            <p className="shipment_cstm_info">
              {shipment.customer_details.email}
            </p>
          </div>

          <div className="address_line2">
            <p className="label">Address Line #2: </p>
            <p className="shipment_cstm_info">
              {shipment.shipping_address.address2
                ? shipment.shipping_address.address2
                : "Not Available"}
            </p>
          </div>

          <div className="ship_country">
            <p className="label">Country : </p>
            <p className="shipment_cstm_info">
              {shipment.shipping_address.country}
            </p>
          </div>
        </div>

        <div className="footer_customer_info">
          <div className="mobile_number">
            <p className="label">Mobile Number: </p>
            <p className="shipment_cstm_info">
              {shipment.shipping_address.phone}
            </p>
          </div>

          <div className="citytown">
            <p className="label">City/Town: </p>
            <p className="shipment_cstm_info">
              {shipment.shipping_address.city}
            </p>
          </div>

          <div className="State">
            <p className="label">State: </p>
            <p className="shipment_cstm_info">
              {shipment.shipping_address.province}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerInfo;
