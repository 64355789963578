import React from "react";
import { Row, Col, Icon, Button } from "antd";
import { SHOPIFY_NAME } from "../../../../../../constants";
import "./ImportListProductBasic.scss";

class StoreConnected2 extends React.Component {
  render() {
    return (
      <div style={{ width: "100%" }}>
        {" "}
        {this.props.messageStatus === "uninstall" ? (
          <Row
            style={{
              width: "100%",
              height: "104px",
              background: "#FDF7F0",
              border: "1px solid #FD9526",
              padding: "15px",
              borderRadius: "3px",
              marginTop: "16px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span
                style={{
                  width: "24px",
                  height: "24px",
                  fontSize: "24px",
                  lineHeight: "24px",
                  display: "inline-block",
                  color: "#FD9526",
                  marginRight: "10px",
                }}
              >
                <Icon type="exclamation-circle" theme="filled" />
              </span>
              <div>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#000000D9",
                  }}
                >
                  {`You need to connect your ${SHOPIFY_NAME} to vFulfill to be able
                  to Add Products to the store.`}
                </p>
              </div>
            </div>

            <div
              style={{ display: "flex", justifyContent: "center" }}
              className="div"
            >
              <button
                onClick={() => this.props.addStoreModal()}
                style={{
                  background: "#FD9526",
                  border: "none",
                  width: "207px",
                  height: "32px",
                  color: "white",
                  marginTop: "10px",
                  cursor: "pointer",
                  fontWeight: "500",
                  display: "block",
                }}
              >
                {" "}
                {`Connect ${SHOPIFY_NAME}`}
              </button>
            </div>
          </Row>
        ) : null}
      </div>
    );
  }
}

export default StoreConnected2;
