import React, { Component } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import Globalsearch from "../../../Common/Globalsearch";
import VideoTooltip from "../../../Common/VideoTooltip";

class Wishlistproductnav extends Component {
  render() {
    return (
      <div className="wishlist-product-nav fullorder_data">
        <div className="wishlist-nav">
          <div className="wishlist-product-wrap">
            <div className="left">
              <div className="breadcurmb-txt">
                Products <span>&#62;</span>{" "}
                <span className="breadcurmb-underline">My Products</span>{" "}
                <VideoTooltip
                  title="My Products"
                  subtitle="vFulfill product research tool to helps you to find products in the niche you would like to do business."
                  contentHeadings={[
                    "Import List",
                    "Private Products",
                    "Store Products",
                    "My Wishlist",
                  ]}
                  content={[
                    "Products that you import from AliExpress or from vFulfill catalog.",
                    "All products that have been shortlisted by you and are also linked with vFulfill catalog. ",
                    "List of all the products that have been published on your store.",
                    "Products that have been added by you to Wishlist from vFulfill Catalog.",
                  ]}
                  thumbnail=""
                  videolink=""
                />
              </div>
            </div>
          </div>

          <div className="secondwrap">
            <div className="filtertab">
              <div className="tab hover-ndr-underline-animation">
                <button className="import-list-txt">
                  {!!this.props.urlSearched ? (
                    <Link to="../import-list">Import List</Link>
                  ) : (
                    <Link to="./import-list">Import List</Link>
                  )}
                </button>

                <div className="circle">
                  {this.props.count && this.props.count.import_list === 0
                    ? "0"
                    : this.props.count &&
                      this.props.count.import_list &&
                      !this.props.loading
                    ? this.props.count.import_list
                    : "..."}
                </div>
              </div>

              <div className="tab hover-ndr-underline-animation">
                <button className="private-products-txt">
                  {" "}
                  {!!this.props.urlSearched ? (
                    <Link to="../private-products">Private Products</Link>
                  ) : (
                    <Link to="./private-products">Private Products</Link>
                  )}
                </button>

                <div className="circle">
                  {this.props.count && this.props.count.linked_products === 0
                    ? "0"
                    : this.props.count &&
                      this.props.count.linked_products &&
                      !this.props.loading
                    ? this.props.count.linked_products
                    : "..."}
                </div>
              </div>

              <div className="tab hover-ndr-underline-animation">
                <button className="store-products-txt">
                  {" "}
                  {!!this.props.urlSearched ? (
                    <Link to="../store-products">Store Products</Link>
                  ) : (
                    <Link to="./store-products">Store Products</Link>
                  )}
                </button>

                <div className="circle">
                  {this.props.count && this.props.count.my_products === 0
                    ? "0"
                    : this.props.count &&
                      this.props.count.my_products &&
                      !this.props.loading
                    ? this.props.count.my_products
                    : "..."}
                </div>
              </div>

              <div
                className={
                  !this.props.loading
                    ? "tab  hover-ndr-underline-animation-click"
                    : "non-cursor-pointer tab hover-ndr-underline-animation "
                }
              >
                <button className="wishlist-product-txt">
                  {" "}
                  {!!this.props.urlSearched ? (
                    <Link to="../wishlist-products">My Wishlist</Link>
                  ) : (
                    <Link to="./wishlist-products">My Wishlist</Link>
                  )}
                </button>

                <div className="backgroundcircle">
                  {this.props.count && this.props.count.my_wishlist === 0
                    ? "0"
                    : this.props.count &&
                      this.props.count.my_wishlist &&
                      !this.props.loading
                    ? this.props.count.my_wishlist
                    : "..."}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_data">
          <Globalsearch
            data={this.props.data}
            change={(value) =>
              this.props.changeHandler({ search: value.target.value })
            }
            searchFunc={(value) => this.props.changeFilter({ search: value })}
            placeholder={"Search by Product Name or ID"}
          />
        </div>
      </div>
    );
  }
}

export default Wishlistproductnav;
