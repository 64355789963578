import React from "react";
import { Tooltip } from "antd";

class OrderProductTitle extends React.Component {
  render() {
    return (
      <div>
        <Tooltip
          title={
            <p style={{ textAlign: "center", fontSize: "12px" }}>
              {this.props.orderProductname}
            </p>
          }
        >
          <p
            className="clamp2 mb4"
            style={{
              color: "#1268FB",
              fontWeight: 500,
              WebkitBoxOrient: "vertical",
              marginRight: 30,
            }}
          >
            <a
              className="hover-underline-animation"
              href={this.props.storeShopifyadmin}
              target="_blank"
              rel="noreferrer noopener"
            >
              {this.props.orderProductname &&
              this.props.orderProductname.length <= 20
                ? this.props.orderProductname
                : this.props.orderProductname.slice(0, 20) + "..."}
            </a>
          </p>
        </Tooltip>
      </div>
    );
  }
}

export default OrderProductTitle;
