import React, { Component } from "react";
import {
  Row,
  Skeleton,
  Alert,
  Table,
  Switch,
  message,
  Icon,
  Tooltip,
  Pagination,
} from "antd";
class WarehouseTable extends Component {
  render() {
    let warehouseHeadingPreKey={
      rto_handling_fees:"Reverse Pick Up Inwards (mandatory buy in on top : advanced QC)",
      fc_india_storage_fees:"Storage Fees",
      rtv_fees:"RTV Handling",
      fc_india_inward_fees:"Inward Fee"
    }
    let warehouseUnitToMeasurePreKey={
      rto_handling_fees:"per unit",
      fc_india_storage_fees:"per unit / day",
      rtv_fees:"per unit",
      fc_india_inward_fees:"per unit"
    }
    const columns = [
      {
        title: "Warehousing",
        dataIndex: "key",
        width:"30%",
        render: (key, obj) => {
          return <div>{warehouseHeadingPreKey[key]} </div>;
        },
      },

      {
        title: "Unit of Measure",
        dataIndex: "key",
        width:"40%",
        render: (key, obj) => {
          return <div>{warehouseUnitToMeasurePreKey[key]}</div>;
        },
      },
      {
        title: "  Prices in ₹ (excl. 18% GST) ",
        dataIndex: "key",
        width:"30%",
        render: (key, obj) => {
        
          return <div>{obj[key].replace("INR","₹")}</div>;
        },
      },
    ];
    console.log("in",this.props.data);
    return (
      <Table
        rowKey={(row, i) => row.key}
        columns={columns}
        dataSource={this.props.data}
        // onChange={(page) => this.goToPage(page)}
        pagination={false}
        width="500px"
      />
    );
  }
}
export default WarehouseTable;
