import React from "react";

const AdvertisingTab = ({
  data = {},
  ad_copies = [],
  ad_copy_images = [],
  ad_copy_videos = [],
}) => {
  // const ad_copy_images = [...(data.ad_copy_images ? data.ad_copy_images : [])];
  // const ad_copy_videos = [...(data.ad_copy_videos ? data.ad_copy_videos : [])];
  const facebook_targeting = [
    ...(data.facebook_targeting ? data.facebook_targeting : []),
  ];
  const facebook_engagement = [
    ...(data.facebook_engagement ? data.facebook_engagement : []),
  ];
  const instagram_influencers = [
    ...(data.instagram_influencers ? data.instagram_influencers : []),
  ];

  let imageAvailable = false;

  ad_copy_images.forEach((e) => {
    if (e && e.default === 1) {
      imageAvailable = true;
    }
  });

  return (
    <div>
      {facebook_targeting.length > 0 ? (
        <div style={{ marginBottom: 24 }}>
          <p className="f12" style={{ color: "#898989", marginBottom: 4 }}>
            Facebook Targeting Metrics:
          </p>
          <ul
            style={{
              display: "flex",
              listStyleType: "none",
              margin: 0,
              padding: "12px 16px",
              flexWrap: "wrap",
              background: "#F5F5F5",
            }}
          >
            {facebook_targeting.map((metric) => {
              return (
                <li
                  key={metric.key}
                  className="f12"
                  style={{ width: "33.3333%", padding: "0 8px 8px 0" }}
                >
                  {metric.key.charAt(0).toUpperCase() +
                    metric.key.toLowerCase().slice(1)}
                  : {metric.value}
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}

      {facebook_engagement.length > 0 ? (
        <div style={{ marginBottom: 24 }}>
          <p className="f12" style={{ color: "#898989", marginBottom: 4 }}>
            Facebook Engagement Metrics:
          </p>
          <ul
            style={{
              display: "flex",
              listStyleType: "none",
              margin: 0,
              padding: "12px 16px",
              flexWrap: "wrap",
              background: "#F5F5F5",
            }}
          >
            {facebook_engagement.map((metric) => {
              return (
                <li
                  key={metric.key}
                  className="f12"
                  style={{
                    flex: 1,
                    lineHeight: "24px",
                    textAlign: "center",
                  }}
                >
                  {metric.value}
                  <br />
                  {metric.key.charAt(0).toUpperCase() +
                    metric.key.toLowerCase().slice(1)}
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}

      {ad_copies.length > 0 ? (
        <div style={{ marginBottom: 24 }}>
          <p className="f12" style={{ color: "#898989", marginBottom: 4 }}>
            Facebook Ad Copies:
          </p>
          <ul
            style={{
              listStyleType: "none",
              display: "flex",
              flexDirection: "column",
              margin: 0,
              padding: 0,
            }}
          >
            {ad_copies.map((ad, index) => {
              return (
                <li
                  key={index}
                  style={{
                    padding: "12px 18px",
                    background: "#FFF0C4",
                    marginBottom: 16,
                  }}
                >
                  <p
                    className="f12"
                    style={{ color: "#212121", marginBottom: 8 }}
                    dangerouslySetInnerHTML={{ __html: ad.copy }}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}

      {ad_copy_images.length > 0 && ad_copy_images ? (
        <div style={{ marginBottom: 24 }}>
          {imageAvailable ? (
            <p
              className="f12 mb0"
              style={{ color: "#898989", marginBottom: 12 }}
            >
              Facebook Image Ads:
            </p>
          ) : null}

          <ul
            style={{
              width: "100%",
              overflowX: "auto",
              listStyleType: "none",
              padding: 0,
              display: "flex",
            }}
          >
            {ad_copy_images.map((img, index) =>
              img.default === 1 ? (
                <li
                  key={index}
                  style={{
                    width: 144,
                    height: 144,
                    marginRight: 24,
                    position: "relative",
                  }}
                >
                  <img
                    // src={`/products/getImage?id=${img}`}
                    src={img.url}
                    alt="product"
                    style={{
                      width: 144,
                      height: 144,
                      objectFit: "cover",
                      borderRadius: 2,
                    }}
                  />
                  <a
                    href={
                      process.env.REACT_APP_API_URL +
                      `/products/getImage?id=${img.url}`
                    }
                    download
                    style={{
                      position: "absolute",
                      bottom: 16,
                      right: 16,
                      padding: "6px 8px",
                      background: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.8"
                      height="19.6"
                      viewBox="0 0 16.8 19.6"
                    >
                      <g data-name="Group 2197">
                        <g data-name="Group 2193">
                          <g data-name="Group 2192">
                            <path
                              data-name="Path 52"
                              d="M16.154 13.411a.633.633 0 00-.646.619v3.714a.633.633 0 01-.646.619H1.934a.633.633 0 01-.646-.619V14.03a.647.647 0 00-1.292 0v3.714A1.9 1.9 0 001.934 19.6h12.928a1.9 1.9 0 001.938-1.857v-3.714a.633.633 0 00-.646-.618z"
                              fill="#1370fc"
                            />
                          </g>
                        </g>
                        <g data-name="Group 2195">
                          <g data-name="Group 2194">
                            <path
                              data-name="Path 53"
                              d="M12.316 11.892a.69.69 0 00-.957 0l-2.27 2.265V.688a.689.689 0 00-1.378 0v13.469l-2.269-2.265a.688.688 0 00-.974.972L7.912 16.3a.69.69 0 00.974 0l3.445-3.438a.687.687 0 00-.015-.97z"
                              fill="#1370fc"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                </li>
              ) : null
            )}
          </ul>
        </div>
      ) : null}

      {ad_copy_videos.length > 0 ? (
        <div style={{ marginBottom: 24 }}>
          <p className="f12" style={{ color: "#898989", marginBottom: 4 }}>
            Facebook Ad Videos:
          </p>
          <ul
            style={{
              width: "100%",
              overflowX: "auto",
              listStyleType: "none",
              padding: 0,
              display: "flex",
            }}
          >
            {ad_copy_videos.map((video, index) => (
              <li
                key={index}
                style={{
                  height: 144,
                  marginRight: 24,
                  position: "relative",
                }}
              >
                <video src={video.url} controls style={{ height: "100%" }}>
                  <source src={video.url} type="video/mp4" />
                </video>
                <a
                  href={
                    process.env.REACT_APP_API_URL +
                    `/products/download_product_media?url=${video.url}`
                  }
                  download
                  style={{
                    position: "absolute",
                    bottom: 16,
                    right: 16,
                    padding: "6px 8px",
                    background: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.8"
                    height="19.6"
                    viewBox="0 0 16.8 19.6"
                  >
                    <g data-name="Group 2197">
                      <g data-name="Group 2193">
                        <g data-name="Group 2192">
                          <path
                            data-name="Path 52"
                            d="M16.154 13.411a.633.633 0 00-.646.619v3.714a.633.633 0 01-.646.619H1.934a.633.633 0 01-.646-.619V14.03a.647.647 0 00-1.292 0v3.714A1.9 1.9 0 001.934 19.6h12.928a1.9 1.9 0 001.938-1.857v-3.714a.633.633 0 00-.646-.618z"
                            fill="#1370fc"
                          />
                        </g>
                      </g>
                      <g data-name="Group 2195">
                        <g data-name="Group 2194">
                          <path
                            data-name="Path 53"
                            d="M12.316 11.892a.69.69 0 00-.957 0l-2.27 2.265V.688a.689.689 0 00-1.378 0v13.469l-2.269-2.265a.688.688 0 00-.974.972L7.912 16.3a.69.69 0 00.974 0l3.445-3.438a.687.687 0 00-.015-.97z"
                            fill="#1370fc"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
            ))}
          </ul>
        </div>
      ) : null}

      {instagram_influencers.length > 0 ? (
        <div style={{ marginBottom: 24 }}>
          <p className="f12" style={{ color: "#898989", marginBottom: 4 }}>
            Instagram Influencers:
          </p>
          <ul
            style={{
              width: "100%",
              overflowX: "auto",
              listStyleType: "none",
              padding: 0,
              display: "flex",
            }}
          >
            {instagram_influencers.map((influencer, index) => (
              <li
                key={index}
                style={{
                  width: 128,
                  marginRight: 16,
                  background: "#F5F5F5",
                  padding: 8,
                }}
              >
                <img
                  src={
                    process.env.REACT_APP_API_URL +
                    `/products/getImage?id=${influencer.img_link}`
                  }
                  style={{
                    width: 112,
                    height: 112,
                    objectFit: "cover",
                    marginBottom: 12,
                  }}
                  alt={influencer.name}
                />
                <p className="f12 blue-text mb0" style={{ fontWeight: 500 }}>
                  {influencer.name}
                </p>
                <a
                  className="f12 blue-text underline"
                  href={influencer.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @{influencer.name}
                </a>
                <p>
                  <span
                    className="f8"
                    style={{
                      padding: "2px 6px",
                      color: "white",
                      background: "#FD104D",
                    }}
                  >
                    {influencer.categories[0]}
                  </span>
                </p>
                <p
                  className="f8"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <svg
                    style={{ marginRight: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="16"
                    viewBox="0 0 12 16"
                  >
                    <g>
                      <path
                        data-name="Path 34"
                        d="M6 0a5.907 5.907 0 00-6 5.795c0 3.965 5.369 9.786 5.6 10.032a.553.553 0 00.8 0C6.629 15.581 12 9.76 12 5.795A5.907 5.907 0 006 0zm0 8.71a2.972 2.972 0 01-3.019-2.915 3.021 3.021 0 016.037 0A2.972 2.972 0 016 8.71z"
                      />
                    </g>
                  </svg>
                  {influencer.country}
                </p>
                <p
                  className="f8"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <svg
                    style={{ marginRight: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.997"
                    height="16"
                    viewBox="0 0 15.997 16"
                  >
                    <g>
                      <g>
                        <path d="M15.952 4.7a5.846 5.846 0 00-.372-1.941A4.1 4.1 0 0013.244.422 5.861 5.861 0 0011.303.05C10.446.009 10.174 0 8.002 0s-2.444.009-3.3.047a5.848 5.848 0 00-1.938.372 3.9 3.9 0 00-1.419.925A3.939 3.939 0 00.423 2.76 5.862 5.862 0 00.052 4.7C.01 5.557.001 5.829.001 8s.009 2.446.051 3.3a5.846 5.846 0 00.367 1.94 4.1 4.1 0 002.341 2.341 5.862 5.862 0 001.941.372c.851.038 1.126.047 3.298.047s2.444-.009 3.3-.047a5.845 5.845 0 001.941-.372 4.092 4.092 0 002.341-2.341 5.866 5.866 0 00.371-1.94c.037-.853.047-1.125.047-3.3s0-2.444-.041-3.3zm-1.441 6.533a4.386 4.386 0 01-.275 1.485 2.654 2.654 0 01-1.514 1.522 4.4 4.4 0 01-1.485.275c-.844.038-1.1.047-3.232.047s-2.391-.009-3.232-.047a4.383 4.383 0 01-1.485-.275 2.462 2.462 0 01-.919-.6 2.488 2.488 0 01-.6-.919 4.4 4.4 0 01-.275-1.485c-.038-.844-.047-1.1-.047-3.232s.009-2.391.047-3.232a4.383 4.383 0 01.275-1.485 2.432 2.432 0 01.6-.919 2.484 2.484 0 01.919-.6A4.4 4.4 0 014.777 1.5c.844-.037 1.1-.047 3.232-.047s2.391.009 3.232.047a4.385 4.385 0 011.485.275 2.46 2.46 0 01.919.6 2.487 2.487 0 01.6.919 4.4 4.4 0 01.275 1.485c.037.844.047 1.1.047 3.232s-.009 2.385-.047 3.229zm0 0" />
                        <path d="M8.001 3.892a4.11 4.11 0 104.11 4.11 4.111 4.111 0 00-4.11-4.11zm0 6.776a2.666 2.666 0 112.666-2.666 2.667 2.667 0 01-2.666 2.666zm0 0" />
                        <path d="M13.235 3.728a.96.96 0 11-.96-.96.96.96 0 01.96.96zm0 0" />
                      </g>
                    </g>
                  </svg>
                  {influencer.followers} followers
                </p>
              </li>
            ))}
          </ul>
        </div>
      ) : null}

      <div style={{ marginBottom: 24 }}>
        <p className="f12" style={{ color: "#898989", marginBottom: 4 }}>
          Product Metrics in Adv. Data:
        </p>
        <div style={{ display: "flex" }}>
          <p
            className="f12"
            style={{
              flex: 1,
              padding: "12px 0",
              background: "#EEEEEE",
              textAlign: "center",
              marginRight: 24,
            }}
          >
            Number of stores selling:{" "}
            {data.shopify_stores_count ? data.shopify_stores_count : "N.A."}
          </p>
          <p
            className="f12"
            style={{
              flex: 1,
              padding: "12px 0",
              background: "#EEEEEE",
              textAlign: "center",
              marginRight: 24,
            }}
          >
            Product units sold:{" "}
            {data.total_units_sold ? data.total_units_sold : "N.A."}
          </p>
          <p
            className="f12"
            style={{
              flex: 1,
              padding: "12px 0",
              background: "#EEEEEE",
              textAlign: "center",
            }}
          >
            Avg. selling price:{" "}
            {data.avg_selling_price ? data.avg_selling_price : "N.A."}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AdvertisingTab;
