import React from "react";
import { Modal, Radio, Button, Icon } from "antd";
import { ReactComponent as Shopify } from "../../../../assets/images/icons/shopify2.svg";
// import { ReactComponent as Custom } from "../../../../assets/images/icons/custom.svg";
import { ReactComponent as Woocommerce } from "../../../../assets/images/icons/wocommerce2.svg";
import { ReactComponent as greenContinueIcan } from "../../../../assets/images/icons/greenContinueIcan.svg";
import { SHOPIFY_NAME } from "../../../../constants";
import "./index.scss";

class StoreChanelModal extends React.Component {
  state = {
    shopify: "Shopify",
    Woocommerce: "WooCommerce",
  };

  toggleShopifyState() {
    this.setState({
      shopify: "shopify",
    });
  }

  toggleContinueState() {
    this.setState({
      shopify: "Continue",
    });
  }

  toggleWocommerceState() {
    this.setState({
      Woocommerce: "WooCommerce",
    });
  }

  toggleWocommerceContinue() {
    this.setState({
      Woocommerce: "Continue",
    });
  }

  render() {
    const props = this.props;
    const { visible, handleCancel } = props;
    return (
      <div>
        <Modal
          className="store-chanel-modal"
          width={504}
          title={
            <div>
              <p className="title_txt">{` Add New ${SHOPIFY_NAME}`} </p>
              <p className="store_objective">
                {" "}
                {`Select your Store Platform :`}
              </p>
            </div>
          }
          visible={visible}
          onCancel={handleCancel}
          footer={null}
        >
          <div>
            <div className="shopify_and_wooCommerece">
              <Radio.Group
                // defaultValue="shopify_store"
                value={this.props.storeSelected}
                onChange={(e) =>
                  this.props.handleStoreChanel("storeSelected", e.target.value)
                }
              >
                <div className="flag_radio_btn">
                  <div className="shopify_flag_radio_btn">
                    <div
                      onMouseEnter={() => this.toggleContinueState()}
                      onMouseLeave={() => this.toggleShopifyState()}
                      className={
                        this.props.storeSelected === "shopify_store"
                          ? "Shopify-type-card"
                          : "Wocoomerce-type-card"
                      }
                    >
                      <Radio value="shopify_store" className="Shopify_flag">
                        <Icon component={Shopify} />
                      </Radio>
                    </div>

                    <span className="logo_txt">
                      {this.state.shopify}
                      {this.state.shopify === "Continue" ? (
                        <Icon component={greenContinueIcan} />
                      ) : null}
                    </span>
                  </div>

                  <div className="shopify_flag_radio_btn">
                    <div
                      onMouseEnter={() => this.toggleWocommerceContinue()}
                      onMouseLeave={() => this.toggleWocommerceState()}
                      className={
                        this.props.storeSelected === "WoCommerce"
                          ? "Wocoomerce-type-card"
                          : "Shopify-type-card"
                      }
                    >
                      <Radio value="WoCommerce" className="wocommerce_flag">
                        <Icon component={Woocommerce} />
                      </Radio>
                    </div>
                    <span className="logo_txt">
                      {this.state.Woocommerce}
                      {this.state.Woocommerce === "Continue" ? (
                        <Icon component={greenContinueIcan} />
                      ) : null}
                    </span>
                  </div>

                  {/* <div>
                    <div
                      className={
                        this.props.storeSelected === "custome_store"
                          ? "Shopify-type-card"
                          : "custome_store-card"
                      }
                      disabled={true}
                    >
                      <Radio
                        value="custome"
                        className="wocommerce_flag"
                        disabled={true}
                      >
                        <Icon component={Custom} />
                      </Radio>
                    </div>
                    <span className="logo_txt">Custome Store</span>
                  </div> */}
                </div>
              </Radio.Group>
            </div>

            <div className="footer_button_continue">
              <Button
                onClick={() => this.props.toggleAddStoreModal()}
                style={{ width: "120px" }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
////
export default StoreChanelModal;
