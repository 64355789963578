import React from "react";
import { Row, Col, Button, Icon, Tooltip } from "antd";
import { ReactComponent as ShipmentStatusIcon } from "../../../../../../../assets/images/icons/shopping-cart.svg";
import Shipmentprogressbar from "../../GlobalContant/ShipmentProgressbar";
import "../../index.scss";

class Shipment extends React.Component {
  state = {
    shipmentsVisible: {},
    lineItems: this.props.order.line_items,
  };
  render() {
    const order = this.props.order;

    const shipmentstatus = this.state.lineItems.map((li) => {
      return li.item_shipmentstatus;
    });

    const {
      shipmentStatusColors,
      shipmentStatusText,
      // vfOrderStatusColors,
      vfOrderStatusIcons,
    } = this.props;

    return (
      <div
        className={`tab-container ${
          this.props.activeTab !== "shipments" ? "hidden" : ""
        }`}
      >
        <div className="shipments-tab">
          {order.order_shipments && order.order_shipments.length > 0 ? (
            (this.props.pageType === "ndr"
              ? order.order_shipments.filter(
                  (s) => s.status === "ndr_actionable"
                )
              : order.order_shipments
            ).map((s) => {
              return (
                <div className="shipment" key={`${s.carrier}_${s.awb}`}>
                  <div className="shipment-header">
                    <Row
                      gutter={0}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Col xs={this.props.pageType === "ndr" ? 5 : 7}>
                        <span className="font_text_size">
                          Shipment Number :{" "}
                        </span>
                        {""}
                        {this.props.pageType === "ndr" ? <br /> : " "}
                        <span
                          className="blue-text"
                          style={{ fontSize: "12px" }}
                        >
                          {s.shipment_vfid}
                        </span>
                      </Col>
                      <Col
                        xs={this.props.pageType === "ndr" ? 5 : 7}
                        style={{ textAlign: "center" }}
                      >
                        <span className="font_text_size">
                          Tracking Number :{" "}
                        </span>{" "}
                        {this.props.pageType === "ndr" ? <br /> : " "}
                        <span className="blue-text">
                          <a
                            href={s.tracking_url}
                            target="_blank"
                            rel="noreferrer noopener"
                            style={{ fontSize: "12px" }}
                          >
                            {s.awb}
                          </a>
                        </span>
                      </Col>

                      <Col
                        xs={5}
                        style={{ textAlign: "center", fontSize: "12px" }}
                      >
                        {s.status ? (
                          s.status === "ndr_actionable" ? (
                            <Tooltip
                              title={
                                s.ndr_remarks
                                  .filter((r) => r.addedby === "system")
                                  .reverse()[0].remark
                              }
                            >
                              <span
                                style={{
                                  color: shipmentStatusColors[s.status],
                                }}
                              >
                                <Icon
                                  component={vfOrderStatusIcons[s.status]}
                                  style={{
                                    color: shipmentStatusColors[s.status],
                                  }}
                                />{" "}
                                {shipmentStatusText[s.status]}
                              </span>
                            </Tooltip>
                          ) : (
                            <span
                              style={{
                                color: shipmentStatusColors[s.status],
                              }}
                            >
                              {/* <Icon
                                component={ShipmentStatusIcon}
                                style={{
                                  color: shipmentStatusColors[s.status],
                                }}
                              />{" "} */}

                              {shipmentstatus &&
                              shipmentstatus[0] === "manifested"
                                ? "To Be shipped"
                                : shipmentStatusText[s.status]}

                              {/* {s.status === "manifested"
                                ? "To Be shipped"
                                : "Placed Orders"} */}
                            </span>
                          )
                        ) : (
                          <span
                            style={{
                              color: shipmentStatusColors["processing"],
                            }}
                          >
                            <Icon
                              component={ShipmentStatusIcon}
                              style={{
                                color: shipmentStatusColors["processing"],
                              }}
                            />{" "}
                            {shipmentStatusText["processing"]}
                          </span>
                        )}
                      </Col>

                      {this.props.pageType === "ndr" && (
                        <Col xs={6} style={{ textAlign: "center" }}>
                          <span>
                            <Button
                              size="small"
                              type="primary"
                              onClick={() =>
                                this.props.toggleNDRModal(
                                  s.awb,
                                  s.shipment_vfid,
                                  {
                                    customer_details: order.customer_details,
                                    shipping_address: order.shipping_address,
                                  }
                                )
                              }
                            >
                              Re-Attempt
                            </Button>
                            <br />
                            <Button
                              size="small"
                              type="primary"
                              onClick={() =>
                                this.props.toggleNDRRemarksModal(s.ndr_remarks)
                              }
                              style={{ marginTop: 10 }}
                            >
                              View Remarks
                            </Button>
                          </span>
                        </Col>
                      )}
                      <Col
                        xs={this.props.pageType === "ndr" ? 3 : 5}
                        style={{ textAlign: "right" }}
                      >
                        <span>
                          <a
                            href="#"
                            onClick={() =>
                              this.setState({
                                shipmentsVisible: {
                                  ...this.state.shipmentsVisible,
                                  [s.awb]: !this.state.shipmentsVisible[s.awb],
                                },
                              })
                            }
                            style={{ fontSize: "12px" }}
                          >
                            {this.state.shipmentsVisible[s.awb]
                              ? "Hide"
                              : "Show"}{" "}
                            Products{" "}
                            <Icon
                              type={
                                this.state.shipmentsVisible[s.awb]
                                  ? "up"
                                  : "down"
                              }
                            />
                          </a>
                        </span>
                      </Col>
                    </Row>
                  </div>
                  <div
                    className="shipment-items"
                    style={{
                      display: this.state.shipmentsVisible[s.awb]
                        ? "block"
                        : "none",
                    }}
                  >
                    {s.items.map((li) => (
                      <Row className="shipment-item" key={li.lineitem_id}>
                        <Col xs={12}>
                          <Row className="shipment-item-product-detail">
                            <Col xs={3}>
                              <span
                                className="shipment-item-img"
                                style={{ background: `url(${li.img})` }}
                              />
                            </Col>
                            <Col xs={21}>
                              <p className="blue-text shipment-item-name">
                                <Tooltip title={li.name}>{li.name}</Tooltip>
                              </p>
                              <p className="shipment-item-sku">
                                <b>SKU:</b> {li.sku}
                              </p>
                              <p className="shipment-item-sku">
                                <b>Specs:</b>{" "}
                                {li.allspecs === "" ? "Default" : li.allspecs}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={5} style={{ textAlign: "center" }}>
                          <span className="mr-3">
                            <Tooltip title={shipmentStatusText[s.status]}>
                              <Icon
                                // component={vfOrderStatusIcons[s.status]}

                                component={
                                  s.status === "manifested"
                                    ? ShipmentStatusIcon
                                    : shipmentStatusText[s.status] ===
                                      "Delivered"
                                    ? ShipmentStatusIcon
                                    : shipmentStatusText[s.status] ===
                                        "Returned" ||
                                      shipmentStatusText[s.status] ===
                                        "In Transit"
                                    ? ShipmentStatusIcon
                                    : vfOrderStatusIcons[s.status]
                                }
                                style={{
                                  color:
                                    s.status === "manifested"
                                      ? "#FD7A3B"
                                      : shipmentStatusText[s.status] ===
                                        "Delivered"
                                      ? "#73D13D"
                                      : shipmentStatusText[s.status] ===
                                        "Returned"
                                      ? "#F12F1A"
                                      : shipmentStatusText[s.status] ===
                                        "In Transit"
                                      ? "#FEC62D"
                                      : shipmentStatusColors[s.status],
                                  fontSize: 17,
                                }}
                              />
                            </Tooltip>
                          </span>
                          {/* <span className="mr-3">
                            <Tooltip
                              title={
                                s.status === "manifested"
                                  ? "To Be Shipped"
                                  : "Placed Order"
                              }
                            >
                              <Icon
                                theme="filled"
                                type="check-circle"
                                style={{
                                  color:
                                    s.status === "manifested"
                                      ? "#D241FD"
                                      : vfOrderStatusColors["verified_ordered"],
                                }}
                              />
                            </Tooltip>
                          </span> */}
                        </Col>
                        <Col xs={3}>
                          {/* <span className="mr-3">{li.quantity}</span>
                          <span className="mr-3">X</span>
                          <span className="mr-3">
                            {getCurrencySymbol("INR")} {li.item_mrp.toFixed(2)}
                          </span> */}
                        </Col>
                        <Col xs={4}>
                          {/* {getCurrencySymbol("INR")}{" "}
                          {(li.quantity * li.item_mrp).toFixed(2)} */}
                        </Col>
                      </Row>
                    ))}
                    <div>
                      {" "}
                      <Shipmentprogressbar order={s} />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="no-shipment">
              <h3>No Shipments Found!</h3>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Shipment;
