import React, { Component } from "react";
import { Dropdown, Menu } from "antd";

class FeedbackWidget extends Component {
  render() {
    return (
      <>
        <Dropdown
          overlayClassName={"feedback-widget-overlay"}
          placement="topLeft"
          overlayStyle={{ zIndex: 999999 }}
          overlay={
            <Menu>
              <Menu.Item>
                <a
                  href={
                    process.env.REACT_APP_API_URL +
                    `/settings/serviceable_pincodes`
                  }
                  target="_blank"
                >
                  Serviceable Pincodes
                </a>
              </Menu.Item>

              <Menu.Item>
                <a
                  href={"https://help.vfulfill.io/portal/en/kb/vfulfill"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Knowledge Base
                </a>
              </Menu.Item>
              <Menu.Item>
                <a
                  href="https://help.vfulfill.io/portal/en/newticket?departmentId=244206000011742048&layoutId=244206000011744275"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Support
                </a>
              </Menu.Item>
              <Menu.Item>
                <a href="https://d24fzeiqvvundc.cloudfront.net/freevalue/AggressiveRTOPincodes.xlsx">
                  High RTO Risk Pincodes
                </a>
              </Menu.Item>
              <Menu.Item>
                <a href="https://d24fzeiqvvundc.cloudfront.net/freevalue/ModerateRTOPincodes.xlsx">
                  Moderate RTO Risk Pincodes
                </a>
              </Menu.Item>
              <Menu.Item>
                <a href="https://vfulfill.io/schedule-demo/">Schedule a Demo</a>
              </Menu.Item>
            </Menu>
          }
        >
          <p
            style={{
              position: "fixed",
              bottom: 18,
              right: 18,
              color: "#007bff",
              zIndex: 9999,
            }}
          >
            <svg
              viewBox="0 0 20 20"
              fill="currentColor"
              style={{ width: 64, height: 64 }}
            >
              <circle cx="10" cy="10" r="8" fill="white" />
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                clipRule="evenodd"
              />
            </svg>
          </p>
        </Dropdown>
      </>
    );
  }
}

export default FeedbackWidget;
