import React, { Component } from "react";
import { Button, Icon, Input, Modal, Alert } from "antd";
import { ReactComponent as RedArrow } from "../../../../../assets/images/icons/red-arrow.svg";
import "./index.scss";

class ImportProductModal extends Component {
  state = {
    singleImport: true,
    bulkImportId: true,
    bulkImportUrl: true,
    productId: "",
    productURL: "",
    productIds: [],
    productURLs: [],
    error: false,
    errorMsg: "",
  };

  handleOk = () => {
    let ids = [];
    if (this.state.singleImport) {
      if (this.state.productId.trim()) {
        ids.push(this.state.productId);
      }
      if (this.state.productURL.trim()) {
        ids.push(this.state.productURL);
      }
    } else {
      if (this.state.bulkImportId) {
        ids = ids.concat(this.state.productIds);
      }
      if (this.state.bulkImportUrl) {
        ids = ids.concat(this.state.productURLs);
      }
    }

    if (!ids.length) {
      return this.setState({
        error: true,
        errorMsg: "Please enter atleast one Product Id or URL",
      });
    }

    this.props.onConfirm(ids);
    this.props.onCancel();

    // if (ids.length) {
    //   this.setState({
    //     error: false,
    //     errorMsg: "",
    //   });
    //   if (this.validateURLs(ids) === true) {
    //     this.setState({
    //       error: true,
    //       errorMsg: "Please enter a valid AliExpress Link",
    //     });
    //   } else {
    //     this.props.onConfirm(ids);
    //     this.props.onCancel();
    //   }
    // } else {
    //   this.setState({
    //     error: true,
    //     errorMsg: "Please enter atleast one Product Id or URL",
    //   });
    // }
  };

  validateURLs(input) {
    let errorFlag = false;
    input.forEach((item) => {
      if (item.includes("aliexpress") === false) {
        errorFlag = true;
      }
    });
    return errorFlag;
  }

  singleFieldChange = (k, v) => {
    this.setState({ [k]: v });
  };

  bulkFieldChange = (k, v) => {
    this.setState({ [k]: v.split("\n") });
  };

  resetModal = () => {
    this.setState({
      singleImport: true,
      error: false,
      errorMsg: "",
      // bulkImportId: false,
      // bulkImportUrl: false,
      productId: "",
      productURL: "",
      productIds: [],
      productURLs: [],
    });
  };

  toggleBulkImportOptions = () => {
    if (this.state.singleImport) {
      this.setState({
        singleImport: false,
        error: false,
        errorMsg: "",
        // bulkImportId: false,
        // bulkImportUrl: false,
      });
    } else {
      this.setState({
        singleImport: true,
      });
    }
  };

  // showBulkImportIdOptions = () => {
  //   this.setState({
  //     bulkImportId: true,
  //   });
  // };

  // showBulkImportUrlOptions = () => {
  //   this.setState({
  //     bulkImportUrl: true,
  //   });
  // };

  render() {
    const { singleImport } = this.state;

    return (
      <Modal
        className="product-import-modal"
        title="Add Products from AliExpress"
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        // afterClose={this.resetModal}
        afterClose={() => {
          this.resetModal();
        }}
        width={480}
        footer={[
          <Button
            className="add-import-btn clickable-dropdown"
            key="submit"
            type="primary"
            loading={this.props.loading}
            onClick={this.handleOk}
          >
            Import Product
          </Button>,
          <Button
            className="import-cancel-modal"
            key="back"
            onClick={() => {
              this.resetModal();
              this.props.onCancel();
            }}
          >
            Cancel
          </Button>,
        ]}
      >
        <div>
          {singleImport ? (
            <div key="singleImport">
              <p className="input-label">Product ID</p>
              <Input
                placeholder="Enter the ID of the product you want to import"
                value={this.state.productId}
                onChange={(e) =>
                  this.singleFieldChange("productId", e.target.value)
                }
                disabled={this.state.productURL !== "" ? true : false}
              />
              <p className="or-txt">or</p>
              <p className="input-label">Product URL</p>
              <Input
                placeholder="Enter the URL of the product you want to import"
                value={this.state.productURL}
                onChange={(e) =>
                  this.singleFieldChange("productURL", e.target.value)
                }
                disabled={this.state.productId !== "" ? true : false}
              />
            </div>
          ) : (
            <div>
              <div key="bulkImportId">
                <p className="input-label">Product IDs</p>
                <Input.TextArea
                  placeholder="Enter the IDs of the products you want to import, separated by a new line."
                  value={this.state.productIds.join("\n")}
                  onChange={(e) =>
                    this.bulkFieldChange("productIds", e.target.value)
                  }
                  disabled={
                    this.state.productURLs.length > 0 ||
                    (this.state.productURLs.length !== 0 &&
                      this.state.productURLs[0].length === 0)
                      ? true
                      : false
                  }
                />
              </div>

              <p className="or-txt2">or</p>
              <div key="bulkImportUrl">
                <p className="input-label">Product URLs</p>
                <Input.TextArea
                  placeholder="Enter the URLs of the products you want to import, separated by a new line."
                  value={this.state.productURLs.join("\n")}
                  onChange={(e) =>
                    this.bulkFieldChange("productURLs", e.target.value)
                  }
                  disabled={
                    this.state.productIds.length > 0 ||
                    (this.state.productIds.length !== 0 &&
                      this.state.productIds[0].length === 0)
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          )}

          <p className="add-products-bulk-link">
            <a
              href="#/"
              key="link-single-bulk"
              onClick={this.toggleBulkImportOptions}
            >
              {" "}
              {singleImport ? (
                <span>
                  Add products in bulk
                  <Icon className="arrow-right" component={RedArrow} />
                </span>
              ) : (
                <span>
                  Add a single product
                  <Icon className="arrow-right" component={RedArrow} />
                </span>
              )}
            </a>
          </p>
          {this.state.error && (
            <Alert
              message={this.state.errorMsg}
              type="error"
              showIcon
              style={{ marginTop: "15px" }}
            />
          )}
        </div>
      </Modal>
    );
  }
}

export default ImportProductModal;
