import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  Radio,
  Checkbox,
  Table,
  Tag,
  Divider,
  message,
} from "antd";
import axios from "axios";
import "./index.scss";

const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;

class SplitProductModal extends Component {
  state = {
    property: "color",
    splitManually: false,
    selectedRowKeys: [],
    selectedOptions: {},
    loading: false,
  };

  resetModal = () => {
    this.setState({
      property: "color",
      splitManually: false,
      selectedRowKeys: [],
      selectedOptions: {},
    });
  };

  handleAutomaticOptionChange = (value) => {
    this.setState({
      property: value,
    });
  };

  handleManualOptionChange = (field, value) => {
    this.setState(
      {
        selectedOptions: { ...this.state.selectedOptions, [field]: value },
      },
      () => {
        let selectedRowKeys = [];
        this.props.product.variants.map((v) => {
          Object.keys(this.state.selectedOptions).forEach((key) => {
            this.state.selectedOptions[key].forEach((val) => {
              if (v[key] === val) {
                selectedRowKeys.push(v.skuPropIds);
              }
            });
          });
          return true;
        });
        // console.log(selectedRowKeys);
        this.setState({
          selectedRowKeys,
        });
      }
    );
  };

  toggleState = (field) => {
    this.setState({
      ...this.state,
      [field]: !this.state[field],
    });
  };

  onConfirm() {
    let options = {
      productid: this.props.product._id["$oid"],
      storeid: this.props.product.product_storeid["$oid"],
    };
    if (this.state.splitManually) {
      options["type"] = "manual";
      options["skuids"] = this.state.selectedRowKeys;
    } else {
      options["type"] = "auto";
      options["property"] = this.state.property;
    }
    const that = this;
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/products/split_product",
          method: "post",
          data: options,
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              that.setState({
                loading: false,
              });
              this.props.onSplitSuccessfull();
              message.success("Product split successfully");
            } else {
              that.setState({
                loading: false,
              });
              message.error("There was an error splitting this product");
            }
          })
          .catch((e) => {
            that.setState({
              loading: false,
            });
            message.error("There was an error splitting this product");
          });
      }
    );
  }

  render() {
    if (!this.props.product) {
      return <span />;
    }

    const { property, splitManually } = this.state;
    const { visible, onCancel } = this.props;
    let options = {};
    Object.keys(this.props.product.vfprod.variantPropKeyvals).map(
      (property) => {
        options[property] = this.props.product.vfprod.variantPropKeyvals[
          property
        ].map((v) => ({ label: v, value: v, key: v }));
        return true;
      }
    );

    let columns = [
      {
        title: "",
        dataIndex: "img",
        render: (_, sku, __) => {
          return sku.img ? (
            <img
              src={sku.img}
              alt={`SKU ${sku.vskuid}`}
              style={{ maxWidth: "50px" }}
            />
          ) : (
            ""
          );
        },
      },
      {
        title: "SKU",
        dataIndex: "vskuid",
        render: (text, _, index) => <Tag>{text}</Tag>,
      },
      {
        title: "Cost",
        dataIndex: "price",
        render: (text) => (
          <Tag>{`${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Tag>
        ),
      },
      {
        title: "Price",
        dataIndex: "shop_price",
        render: (text, _, index) => <Tag>{text}</Tag>,
      },
      {
        title: "Compared At Price",
        dataIndex: "compared_at_price",
        render: (text, _, index) => <Tag>{text}</Tag>,
      },
      {
        title: "Profit",
        dataIndex: "profit",
        render: (text) => <Tag>{text}</Tag>,
      },
      {
        title: "Inventory",
        dataIndex: "inventory",
        render: (text) => <Tag>{text}</Tag>,
      },
    ];
    columns = [
      ...columns.slice(0, 2),
      ...this.props.product.vfprod.variantPropKeys.map((k) => ({
        title: k,
        dataIndex: k,
        render: (text, _, index) => <Tag>{text}</Tag>,
      })),
      ...columns.slice(2),
    ];

    let data = [...this.props.product.variants];
    const price_settings = this.props.pricing;

    data = data.map((sku) => {
      sku.key = sku.skuPropIds;
      sku.shop_price = sku.manually_edited_shopprice
        ? sku.shop_price
        : price_settings.product_cost.type === "multiplier"
        ? sku.price * parseFloat(price_settings.product_cost.data)
        : sku.price + parseFloat(price_settings.product_cost.data);

      sku.compared_at_price = sku.manually_edited_comparedatprice
        ? sku.compared_at_price
        : price_settings.compared_at_price.type === "multiplier"
        ? sku.shop_price * parseFloat(price_settings.compared_at_price.data)
        : sku.shop_price + parseFloat(price_settings.compared_at_price.data);

      sku.profit = sku.shop_price - sku.price;

      return sku;
    });

    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
    };

    return (
      <Modal
        className="product-split-modal"
        title="Split Product"
        visible={visible}
        onCancel={onCancel}
        afterClose={this.resetModal}
        maskClosable={false}
        closable={!this.state.loading}
        footer={[
          <a
            href="#/"
            key="link-single-bulk"
            onClick={() => this.toggleState("splitManually")}
            style={{ float: "left" }}
          >
            or, split the product {splitManually ? "automatically" : "manually"}
          </a>,
          <Button key="back" onClick={onCancel} disabled={this.state.loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={this.state.loading}
            onClick={() => this.onConfirm()}
          >
            Split Product
          </Button>,
        ]}
      >
        {splitManually ? (
          <div>
            <p>
              Select the variants which you want to split into another product:
            </p>

            <Row>
              {Object.keys(options).map((option) => (
                <Col className="block-column" span={24} key={option}>
                  <span className="bold-text">{option}</span>{" "}
                  <CheckboxGroup
                    options={options[option]}
                    onChange={(value) =>
                      this.handleManualOptionChange(option, value)
                    }
                  />
                </Col>
              ))}
            </Row>

            <Divider />
            <div style={{ maxHeight: "350px", overflowY: "scroll" }}>
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data}
                scroll={{ x: 900 }}
                pagination={false}
              />
            </div>
          </div>
        ) : (
          <div>
            <p>
              Select the property based on which you want to split the product:
            </p>

            <RadioGroup
              name="radiogroup"
              value={property}
              onChange={(e) => this.handleAutomaticOptionChange(e.target.value)}
            >
              <Row>
                {Object.keys(options).map((option) => (
                  <Col
                    className="block-column radio-column"
                    key={option}
                    span={24}
                    onClick={() => this.handleAutomaticOptionChange(option)}
                  >
                    <Radio value={option}>{option}</Radio>
                    <span className="sub-text">
                      We will split this product on the basis of the {option}{" "}
                      property into x different products
                    </span>
                  </Col>
                ))}
              </Row>
            </RadioGroup>
          </div>
        )}
      </Modal>
    );
  }
}

export default SplitProductModal;
