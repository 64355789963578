import React, { Component } from "react";
import {
  Row,
  Skeleton,
  Alert,
  Table,
  Switch,
  message,
  Icon,
  Tooltip,
  Pagination,
} from "antd";
class Fullfillmenttable extends Component {
  render() {
    let FullfillmentheadingAspreKey = {
      outbond_per_item: "Outbound",
      stationary_per_order_charges: "Printing & Stationery Charges",
      packaging_per_order_charges: "Packaging",
      vas_per_order_charges:"VAS (chargeable as per quote or basis the work, whichever is higher)",
    };
    let FullfillmentUnitToMeasureAspreKey = {
      outbond_per_item: "per unit",
      stationary_per_order_charges: "per order",
      packaging_per_order_charges: "per order",
      vas_per_order_charges: "per unit, starting price for basic knitting",
    };

    const columns = [
      {
        title: "Fulfillment",
        dataIndex: "key",
        width:"30%",
        render: (key, obj) => {
          return <div>{FullfillmentheadingAspreKey[key]}</div>;
        },
      },

      {
        title: "Unit of Measure",
        dataIndex: "key",
        width:"40%",
        render: (key, obj) => {
          return <div>{FullfillmentUnitToMeasureAspreKey[key]}</div>;
        },
      },
      {
        title: "  Prices in ₹ (excl. 18% GST) ",
        dataIndex: "key",
        width:"30%",
        render: (key, obj) => {
          if (key === "outbond_per_item") {
            return <div>{obj["outbond_per_item"].replace("INR", "₹")}</div>;
          }
          if (key === "stationary_per_order_charges") {
            return (
              <div>
                {obj["stationary_per_order_charges"]==="%"? "N/A":obj["stationary_per_order_charges"].replace("INR", "₹")}
              </div>
            );
          }
          if (key === "packaging_per_order_charges") {
            return (
              <div>
                {obj["packaging_per_order_charges"]==="%"? "N/A":obj["packaging_per_order_charges"].replace("INR", "₹")}
              </div>
            );
          }
          if (key === "vas_per_order_charges") {
            return (
              <div>{obj["vas_per_order_charges"]==="%"? "N/A":obj["vas_per_order_charges"].replace("INR", "₹")}</div>
            );
          }

          return <div></div>;
        },
      },
    ];
    return (
      <Table
        rowKey={(row, i) => row.key}
        columns={columns}
        dataSource={this.props.data}
        // onChange={(page) => this.goToPage(page)}
        pagination={false}
        width="500px"
      />
    );
  }
}
export default Fullfillmenttable;
