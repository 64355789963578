import React from "react";
import { Button, message } from "antd";
import { ReactComponent as MoverIllustrationFree } from "../../../../../../assets/images/icons/MoverIllustrationFree.svg";
import { Redirect } from "react-router-dom";
import axios from "axios";
import analytics from "../../../../../../analytics";
import "./index.scss";

class FreePlanCard extends React.Component {
  state = { visible: false, loading: false, isFree: false };

  submitPlan(planName) {
    this.props.setState({ loading: true });

    this.setState({ loading: true, planName: planName }, () => {
      axios({
        url: process.env.REACT_APP_API_URL + "/stripe/set_free_plan",
        method: "post",
        withCredentials: true,
        data: {
          cancel_now:
            this.props.current_plan &&
            this.props.current_plan.plan_status === "past_due"
              ? 1
              : 0,
        },
      })
        .then((res) => {
          analytics.track("Subscribe Free Plan");
          this.props.setState({ loading: false });
          this.setState({ loading: false });

          if (res.data.success === 1) {
            this.setState({
              isFree: true,
            });
          } else {
            // message.error(res.data.msg);
          }
        })
        .catch((e) => {
          // message.error(e.message);
          this.props.setState({ loading: false });
          this.setState({ loading: false });
        });
    });
  }

  render() {
    if (this.state.isFree && window.innerWidth < 1280) {
      return window.location.assign(
        process.env.REACT_APP_NEW_APP + `/switch-store`
      );
    }

    if (this.state.isFree) {
      return window.location.assign(
        process.env.REACT_APP_NEW_APP + `/switch-store`
      );
    }

    return (
      <div
        // onClick={(key) => this.props.tabHandler("2")}

        className="free-plan-card-wraper"
      >
        <div className="price-card">
          <h1>Free Plan</h1>

          <div className="price">
            <h2>$ 0</h2>
            <span> / month*</span>
          </div>

          {!!this.props.isFreePlan ? (
            <Button
              id="payment-disable"
              loading={this.state.loading}
              disabled={true}
            >
              Currently Active
            </Button>
          ) : (
            <Button
              loading={this.state.loading}
              onClick={() => this.submitPlan("free")}
            >
              Try Free Plan
            </Button>
          )}
        </div>

        <div className="right">
          <div className="illustration-wraper">
            <MoverIllustrationFree />
            <p>
              “I just want to get an idea of what I can expect from the
              platform.”
            </p>
          </div>

          <div className="features-card">
            <div className="features">
              Limited Access of the Platform to help you understand how it works
              and what can you expect out of it.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FreePlanCard;
