import React, { Component } from "react";
import { Button, Modal, message, Select, Spin, Tooltip } from "antd";
import axios from "axios";
import debounce from "lodash/debounce";
import "./index.scss";
// import RadioGroup from "antd/lib/radio/group";
const { Option } = Select;
class BundleModal extends Component {
  state = {
    productIds: [],
    error: false,
    errorMsg: "",
    checkInterval: null,
    loading: false,
    type: "importList",
    products: [],
  };

  getAliIds() {
    return this.state.productIds.map((idOrURL) => {
      if (idOrURL.indexOf("http") !== -1 && idOrURL.indexOf(".html") !== -1) {
        if (idOrURL.indexOf("?") !== -1) {
          idOrURL = idOrURL.split("?")[0];
        }
        idOrURL = idOrURL.split(".html")[0];
        idOrURL = idOrURL.split("/");
        idOrURL = idOrURL[idOrURL.length - 1];
      }
      return idOrURL;
    });
  }

  verifyScrape(id, scrapeid, scrapeCount) {
    if (scrapeCount <= 0) {
      message.error("There was an error fetching the product");
      this.setState({ loading: false });
      return;
    }
    setTimeout(() => {
      axios({
        url: process.env.REACT_APP_API_URL + "/products/get_aliproduct_scraped",
        method: "post",
        data: {
          scrapeid,
          storeid: this.props.match.params.storeid,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.productId !== undefined) {
            const productId = this.getAliIds().join(",");
            this.props.history.push(
              `/${this.props.match.params.storeid}/bundle/${productId}/${
                this.props.linkProductModalId
              }`
            );
          } else {
            this.verifyScrape(id, scrapeid, --scrapeCount);
          }
        })
        .catch((e) => {
          this.verifyScrape(id, scrapeid, --scrapeCount);
        });
    }, 3000);
  }

  handleOk = () => {
    const productId = this.getAliIds().join(",");
    this.props.history.push(
      `/${this.props.match.params.storeid}/bundle/${productId}/${
        this.props.linkProductModalId
      }#${this.props.orderway}${
        this.props.data && this.props.data.vfod
          ? `=` + this.props.data.vfod
          : ""
      }`
    );

    // let ids = this.state.productIds.map((id) => id.trim());
    // if (ids.length) {
    //   const productId = ids[0];
    //   this.setState({
    //     error: false,
    //     errorMsg: "",
    //   });
    //   axios({
    //     url: "/products/link_product_aliexpress",
    //     method: "post",
    //     data: {
    //       storeid: this.props.match.params.storeid,
    //       productId: productId,
    //     },
    //   })
    //     .then((res) => {
    //       if (res.data.success === 1) {
    //         this.setState({ loading: true }, () =>
    //           this.verifyScrape(productId, res.data.scrapeid, 8)
    //         );
    //       } else {
    //         message.error(res.data.msg);
    //       }
    //     })
    //     .catch((e) => {
    //       message.error(e.message);
    //     });
    // } else {
    //   this.setState({
    //     error: true,
    //     errorMsg: "Please enter atleast either Product Id or URL",
    //   });
    // }
  };

  fieldChange = (idx, v) => {
    const productIds = [...this.state.productIds];
    productIds[idx] = v;
    this.setState({ productIds });
  };

  addField = () => {
    this.setState((state) => ({
      productIds: [...state.productIds, ""],
    }));
  };

  removeField = (idx) => {
    const productIds = [
      ...this.state.productIds.slice(0, idx),
      ...this.state.productIds.slice(idx + 1),
    ];
    this.setState({ productIds });
  };

  resetModal = () => {
    this.setState({
      productIds: [],
      error: false,
      errorMsg: "",
      checkInterval: null,
      loading: false,
      type: "importList",
    });
  };

  fetchProducts(search) {
    const that = this;
    this.setState(
      {
        products: [],
        fetching: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/products/get_import_product_listing",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            search,
          },
          withCredentials: true,
        })
          .then((res) => {
            that.setState({
              products: res.data ? res.data : [],
              fetching: false,
            });
          })
          .catch((e) => {
            that.setState({
              products: [],
              fetching: false,
            });
          });
      }
    );
  }

  selectProducts(productIds) {
    this.setState({
      productIds,
    });
  }

  render() {
    const { error, errorMsg } = this.state;

    return (
      <Modal
        className="link-product-bundle-modal"
        title={
          <div>
            <p className="bundle-product-list">Creating product bundle</p>
            <p className="bundle-product-txt">
              Select product(s) to add to the bundle
            </p>
          </div>
        }
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        afterClose={this.resetModal}
        maskClosable={false}
        closable={!this.state.loading}
        destroyOnClose={true}
        width={496}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={this.handleOk}
            loading={this.state.loading}
            className="next-button"
            disabled={this.state.productIds.length === 0 ? true : false}
          >
            {this.state.loading ? "Fetching Product Data" : "Next"}
          </Button>,
          <Button
            key="back"
            onClick={this.props.onCancel}
            className="cancle-button"
          >
            Cancel
          </Button>,

          <p className="link-product-note-txt">
            {" "}
            *Note - Please make sure you have the required product in Import
            List to link with. If there isn’t any, then you can import from
            either VF Catalog or Ali Express before continuing.
          </p>,
        ]}
      >
        {/* {this.state.type === "" && (
          <div>
            <RadioGroup
              onChange={(e) => this.setState({ type: e.target.value })}
              value={this.state.type}
            >
              <Radio value="aliexpress">
                <span className="label-text">
                  Create a bundle using AliExpress URL/IDs
                </span>
              </Radio>
              <Radio value="importList">
                <span className="label-text">
                  Create a bundle from Import List
                </span>
              </Radio>
            </RadioGroup>
          </div>
        )} */}
        {/* {this.state.type === "aliexpress" && (
          <div>
            <div>
              <p className="input-label">
                Enter the AliExpress links or IDs of all the products you want
                to map:
              </p>
              {this.state.productIds.map((id, i) => (
                <div>
                  <Input
                    placeholder="Enter AliExpress Link or Product ID"
                    value={id}
                    style={{ width: "calc(100% - 30px)", marginRight: 12 }}
                    onChange={(e) => this.fieldChange(i, e.target.value)}
                  />
                  <a href="#!" onClick={() => this.removeField(i)}>
                    <Icon type="close" />
                  </a>
                </div>
              ))}
              <div style={{ textAlign: "right" }}>
                <a href="#!" onClick={() => this.addField()}>
                  + Add More Links
                </a>
              </div>
            </div>
            {error && (
              <Alert
                message={errorMsg}
                type="error"
                showIcon
                style={{ marginTop: "15px" }}
              />
            )}
          </div>
        )} */}

        {this.state.type === "importList" && (
          <div>
            <p className="input-label">Link With</p>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select one or more products"
              optionFilterProp="children"
              onChange={(value) => this.selectProducts(value)}
              notFoundContent={
                this.state.fetching ? <Spin size="small" /> : null
              }
              onSearch={debounce((val) => this.fetchProducts(val), 500)}
              size="large"
              mode="multiple"
              filterOption={false}
            >
              {this.state.products.map((p) => (
                <Option key={p.product_aliid} value={p.product_aliid}>
                  <img
                    src={p && p.gallery ? p.gallery[0] : null}
                    alt={p.name}
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid #898989",
                      padding: "4px",
                      width: "30px",
                      height: "30px",
                    }}
                  />
                  <span style={{ marginLeft: "16px", fontSize: "12px" }}>
                    {" "}
                    {p.name.length > 30 ? (
                      <Tooltip title={p.name}>
                        {`${p.name.substring(0, 30)}...`}
                      </Tooltip>
                    ) : p.name ? (
                      p.name
                    ) : (
                      "Name not Available"
                    )}
                  </span>
                </Option>
              ))}
            </Select>
          </div>
        )}
      </Modal>
    );
  }
}

export default BundleModal;
