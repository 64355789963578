import React from "react";
import { Row, Col, Icon, Button } from "antd";
import "./ImportListProductBasic.scss";

class RemindedMessage extends React.Component {
  render() {
    return (
      <div>
        {" "}
        {this.props.messagedata === "reminded" ? (
          <Row
            style={{
              width: "100%",
              background: "#F5FFF4",
              border: "1px solid #2BC822",
              padding: "10px",
              borderRadius: "3px",
              marginTop: "16px",
            }}
          >
            <Col md={24}>
              <p
                style={{
                  fontSize: "12px",
                  color: "#000000D9",
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    width: "24px",
                    height: "24px",
                    fontSize: "24px",
                    lineHeight: "24px",
                    display: "inline-block",
                    color: "#2BC822",
                    verticalAlign: "middle",
                    marginRight: "8px",
                  }}
                >
                  <Icon type="exclamation-circle" theme="filled" />
                </span>
                Your request to get this product cataloged has been submitted.
                It takes us between 2-3 working days on average to completely
                catalog a product. You will be sent a notification on your
                vFulfill dashboard once it has been done!
              </p>

              <span
                style={{
                  color: "#2BC822",
                  marginTop: "10px",
                  fontWeight: "500",
                  display: "block",
                  textAlign: "center",
                }}
              >
                {" "}
                Reminder Sent!
              </span>
            </Col>
          </Row>
        ) : (
          this.props.messageStatus === "doreminder" && (
            <Row
              style={{
                width: "100%",
                background:
                  this.props.messagedata === "reminded" ? "#F5FFF4" : "#FDF7F0",
                border:
                  this.props.messagedata === "reminded"
                    ? "1px solid #2BC822"
                    : "1px solid #FD9526",
                padding: "10px",
                borderRadius: "3px",
                marginTop: "16px",
              }}
            >
              <Col md={1}>
                <span
                  style={{
                    width: "24px",
                    height: "24px",
                    fontSize: "24px",
                    lineHeight: "24px",
                    display: "inline-block",
                    color:
                      this.props.messagedata === "reminded"
                        ? "#2BC822"
                        : "#FD9526",
                  }}
                >
                  <Icon type="exclamation-circle" theme="filled" />
                </span>
              </Col>
              <Col md={23} style={{ textAlign: "center" }}>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#000000D9",
                  }}
                >
                  Your request to get this product cataloged has been submitted.
                  It takes us between 2-3 working days on average to completely
                  catalog a product. You will be sent a notification on your
                  vFulfill dashboard once it has been done!
                </p>
                <span>Been over 3 days? Looks like we need a reminder.</span>{" "}
                <span>
                  <Button
                    className="rejected"
                    style={{
                      backgroundColor: "#FD9526",
                      color: "#fff",
                      // padding: "12px 10px",
                      margin: "16px",
                      display: "inline-block",
                      cursor: "pointer",
                      fontSize: "12px",
                    }}
                    onClick={() => this.props.reminder()}
                    loading={this.props.loading}
                    disabled={this.props.loading}
                  >
                    {" "}
                    Send a Reminder
                  </Button>
                </span>
              </Col>
            </Row>
          )
        )}
      </div>
    );
  }
}

export default RemindedMessage;
