import { Button, Icon } from "antd";
import React, { Component } from "react";
import StepsModal from "./Modal";

import "./index.scss";

class ViewStepsCard extends Component {
  state = {
    modalVisible: false,
  };

  toggleModal = () => {
    this.setState({
      ...this.state,
      modalVisible: !this.state.modalVisible,
    });
  };
  render() {
    return (
      <div className={`view-steps-card ${this.props.clicked ? `clicked` : ``}`}>
        <div className="steps-count">
          <h1>2/5</h1>
          <p>steps completed</p>
          <Button
            onClick={() => this.toggleModal()}
            className="clickable-dropdown"
          >
            View Steps <Icon type="right" />
          </Button>
        </div>
        <div className="content">
          <h1>Almost there !</h1>
          <h2>
            Almost there! We recommend completing the remaining steps to get
            most out of LetConvert. Let's get done with it!{" "}
          </h2>

          <Button className="clickable-dropdown">
            Step 3: Add LetConvert Pixel
          </Button>
          <h4>
            Add a universal JS code to your website to create trigger and manage
            campaigns from our dashboard directly.
          </h4>
          <h5>Just add once and manage forever! </h5>
        </div>
        <div className="img">
          <img
            src="https://assets.website-files.com/5bff8886c3964a992e90d465/5bff98f85ea7faf86504605e_scenes.gif"
            alt=""
          />
        </div>

        <StepsModal
          domid={3}
          visible={this.state.modalVisible}
          firstIncomplete={"pixel"}
          closeModal={this.toggleModal}
          {...this.props}
        />
      </div>
    );
  }
}

export default ViewStepsCard;
