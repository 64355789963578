import React, { Component } from "react";
import {
  Row,
  Skeleton,
  Alert,
  Table,
  Switch,
  message,
  Icon,
  Tooltip,
  Pagination,
} from "antd";
class PlatformFeeTable extends Component {
  render() {
    let PlatformFeeHeadingAsPerKey = {
      fixed: "Convenience Fees Fixed",
      percentage: "Convenience Fees",
      management_fees: "Tax Management Fees",
    };
    let PlatformFeeChargeableAsPerKey = {
      fixed:
        " Unlimited Platform Usage. Access to Brand Specialist, All Features Access, 100% Managed System, Native Integration to Contact Center",
      percentage:
        "Unlimited Platform Usage. Access to Brand Specialist, All  Features Access, 100% Managed System, Native Integration to Contact Center",
      management_fees: "",
    };

    const columns = [
      {
        title: "Convenience Fees",
        dataIndex: "key",
        width:"70%",
        render: (key, obj) => {
          return <div>{PlatformFeeHeadingAsPerKey[key]}</div>;
        },
      },

   
      {
        title: "  Prices in ₹ (excl. 18% GST) ",
        dataIndex: "key",
        width:"30%",
        render: (key, obj) => {
          if (obj.key === "management_fees") {
            if (obj["management_fees"]["margin"] === "0%") {
              return (
                <>
                  <div>
                    {" "}
                    {obj["management_fees"]["min"].replace("INR", "₹")}
                  </div>
                  <div>
                    <span style={{ fontSize: "8px" }}>
                      (per delivered order value)
                    </span>
                  </div>
                </>
              );
            }

            return (
              <>
                <div>
                  {obj["management_fees"]["margin"]} or{" "}
                  {obj["management_fees"]["min"].replace("INR", "₹")}
                </div>
                <div>
                  <span style={{ fontSize: "8px" }}>(whichever is higher)</span>
                </div>
              </>
            );
          }

          return (
            <>
              <div>{obj[key].replace("INR", "₹")}</div>
              <div>
                <span style={{ fontSize: "8px" }}>
                  (per delivered order value)
                </span>
              </div>
            </>
          );
        },
      },
    ];
    return (
      <Table
        rowKey={(row, i) => row.key}
        columns={columns}
        dataSource={this.props.data}
        // onChange={(page) => this.goToPage(page)}
        pagination={false}
        width="500px"
      />
    );
  }
}
export default PlatformFeeTable;
