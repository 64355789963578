import React, { Component } from "react";
import {
  Button,
  Input,
  Modal,
  Icon,
  message,
  Form,
  Alert,
  Tabs,
  Row,
} from "antd";
import axios from "axios";
import "./index.scss";
import { connect } from "react-redux";
import { signIn } from "../../../../store/actions/authActions";
const TabPane = Tabs.TabPane;
class ForgotPasswordForm extends Component {
  state = {
    email: this.props.email,
    sentSuccessfully: false,
    loading: false,
    showMsg: false,
    msgType: "error",
    msg: "",
  };

  componentDidMount() {
    document.title = this.props.title;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true }, () => {
          axios({
            url: process.env.REACT_APP_API_URL + "/login/password_reset",
            method: "post",
            data: {
              email: values.email,
            },
            withCredentials: true,
          })
            .then((res) => {
              if (res.data.success === 1) {
                this.setState({
                  showMsg: true,
                  loading: false,
                  msgType: "success",
                  msg:
                    "Please check your email inbox for password reset instructions",
                  sentSuccessfully: true,
                });
              } else {
                this.setState({
                  showMsg: true,
                  loading: false,
                  msgType: "error",
                  msg: res.data.msg,
                });
              }
            })
            .catch((e) => {
              this.setState({
                showMsg: true,
                loading: false,
                msgType: "error",
                msg: e.message,
              });
            });
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="forgotSection">
        <div className="heading-bar">
          <div className="main-heading">Welcome Back</div>
          <div className="sub-heading">Log In to access your dashboard</div>
        </div>{" "}
        <div className="heading-reset">Reset Your Password</div>
        <div className="line-blue"></div>
        <Form onSubmit={this.handleSubmit} className="forgot-form" noValidate>
          <div className="flex-from">
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    type: "email",
                    message: "Please enter a valid Email Id!",
                  },
                ],
                initialValue: this.state.email,
              })(
                <Input
                  type="email"
                  placeholder="Your Email Id"
                  className="input-signup23"
                />
              )}
            </Form.Item>
            <Form.Item>
              {this.state.showMsg && (
                <Alert
                  message={this.state.msg}
                  type={this.state.msgType}
                  className="alert"
                />
              )}
              <Button
                htmlType="submit"
                className="change-emailbtn"
                style={{
                  pointerEvents: this.state.sentSuccessfully ? "none" : "auto",
                }}
                loading={this.state.loading}
              >
                {this.state.msgType === "success"
                  ? "Sent Successfully"
                  : "Continue"}
              </Button>
              <Button
                className="login-form-signup-here"
                onClick={() => {
                  this.props.changeTab();
                }}
              >
                Back to Login
              </Button>
            </Form.Item>
          </div>
        </Form>
        <div className="login-page-footer">
          <Row type="flex">
            <p className="contactus-line">
              Need help? We are just a click away. <a href="https://help.vfulfill.io/portal/en/newticket?departmentId=244206000011742048&layoutId=244206000011744275" target="_blank">Contact Us</a>
            </p>
            {/* <p className="copy-line">
              Copyright &copy; {new Date().getFullYear()} vFulfill
            </p> */}
          </Row>
          {/* <Row type="flex">
            <ul className="footer-links">
              <li>
                <a href="https://www.vfulfill.io/legals/terms">Terms</a>
              </li>
              <li>
                <a href="https://www.vfulfill.io/legals/privacy">Privacy</a>
              </li>
              <li>
                <a href="https://help.vfulfill.io">Help</a>
              </li>
            </ul>
          </Row> */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ForgotPasswordForm));
