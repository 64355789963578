import React, { Component } from "react";
import {
  Dropdown,
  Icon,
  Card,
  Tabs,
  Skeleton,
  Menu,
  Checkbox,
  Alert,
  Tooltip,
  Row,
  Col,
} from "antd";
import ImportListProductBasic from "../ImportListProductBasic";
import ImportListProductDescription from "../ImportListProductDescription";
import ImportListProductImages from "../ImportListProductImages";
import ImportListProductVariants from "../ImportListProductVariants";
import OverrideProductModal from "../../OverrirdeProductModal";
import SplitProductModal from "../../SplitProductModal";
import axios from "axios";
import "./index.scss";
import { REFRESH_TIMEOUT } from "../../../../../../constants";
import PLACEHOLDER_PRODUCT_IMAGE from "../../../../../../assets/images/icons/image_placeholder.png";
import { hasPermission } from "../../../../../../Helpers/Permissions";
import { SHOPIFY_NAME } from "../../../../../../constants";
class ImportListProductContainer extends Component {
  state = {
    updateTimeoutId: null,
    showHideheaderdata: false,
  };

  startAJAXPolling(scrape, productExport, productOverride) {
    if (this.state.updateTimeoutId) {
      return;
    }
    if (scrape || productExport || productOverride) {
      const timeoutId = setInterval(() => {
        axios({
          url: process.env.REACT_APP_API_URL + "/products/get_product",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            productid: this.props.product._id["$oid"],
          },
          withCredentials: true,
        }).then((res) => {
          if (res.data.success === 1) {
            if (scrape) {
              if (res.data.product.scrape_in_progress !== 1) {
                clearInterval(this.state.updateTimeoutId);
                this.setState({ updateTimeoutId: null });
                this.props.onProductUpdate(
                  this.props.product._id["$oid"],
                  "scrape_in_progress",
                  res.data.product.scrape_in_progress
                );
              }
            }
            if (productExport) {
              if (res.data.product.export_in_progress !== 1) {
                clearInterval(this.state.updateTimeoutId);
                this.setState({
                  updateTimeoutId: null,
                  shopifyURL: res.data.product.shopify_url,
                });
                this.props.onProductUpdate(
                  this.props.product._id["$oid"],
                  "export_in_progress",
                  res.data.product.export_in_progress
                );
              }
            }
            if (productOverride) {
              if (res.data.product.override_in_progress !== 1) {
                clearInterval(this.state.updateTimeoutId);
                this.setState({ updateTimeoutId: null });
                this.props.onProductUpdate(
                  this.props.product._id["$oid"],
                  "override_in_progress",
                  res.data.product.override_in_progress
                );
              }
            }
          }
        });
      }, REFRESH_TIMEOUT);
      this.setState({
        updateTimeoutId: timeoutId,
      });
    }
  }

  onComponentMount() {
    const { product } = this.props;
    const scrape =
      product.scrape_in_progress && product.scrape_in_progress === 1
        ? true
        : false;
    const productExport =
      product.export_in_progress && product.export_in_progress === 1
        ? true
        : false;
    const productOverride =
      product.override_in_progress && product.override_in_progress === 1
        ? true
        : false;
    this.startAJAXPolling(scrape, productExport, productOverride);
  }

  productMessage = (product) => {
    let messageStatus = "default";

    if (product.catalog_complete === 1) {
      messageStatus = "default";
    } else {
      if (product.rejected === 1) {
        messageStatus = "rejected";
      } else {
        if (product.escalated === 0) {
          messageStatus = "doescalate";
        } else if (product.escalated === 1) {
          messageStatus = "escalated";
        } else if (product.escalated === -1) {
          messageStatus = "doreminder";
        } else if (product.escalated === -2) {
          messageStatus = "reminded";
        }
      }
    }

    if (this.props.storeConnected === false) {
      messageStatus = "notConnected";
    }

    if (this.props.app_uninstall === true) {
      return (messageStatus = "uninstall");
    }

    return messageStatus;
  };

  componentDidMount() {
    this.onComponentMount();

    this.setState({
      messagedata: this.productMessage(this.props.product),
    });
  }

  componentWillUnmount() {
    if (this.state.updateTimeoutId) {
      clearInterval(this.state.updateTimeoutId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const scrape = nextProps.product.scrape_in_progress === 1 ? true : false;
    const productExport =
      nextProps.product.export_in_progress === 1 ? true : false;
    const productOverride =
      nextProps.product.override_in_progress === 1 ? true : false;
    if (scrape || productExport || productOverride) {
      this.startAJAXPolling(scrape, productExport, productOverride);
    }
  }

  onFieldChange(pid, field, value, showAlert = true) {
    this.props.onProductUpdate(pid, field, value, showAlert);
  }

  importToStore() {
    this.props.importToStore([this.props.product._id["$oid"]]);
  }

  toggleSelectedStatus() {
    this.props.onSelect(this.props.product._id["$oid"]);
  }

  render() {
    const { product, isSelected, store } = this.props;

    if (product.scrape_in_progress && product.scrape_in_progress === 1) {
      return (
        <Card
          style={{ marginBottom: "20px" }}
          id="test"
          className="import-list-product-container"
        >
          <p>Fetching Product Data</p>
          <Skeleton active />
        </Card>
      );
    }
    if (product.scrape_in_progress && product.scrape_in_progress === -1) {
      return (
        <Card
          style={{ marginBottom: "20px" }}
          id="test"
          className="import-list-product-container"
        >
          <Col xs={24} style={{ paddingRight: "15px" }}>
            <Alert
              type="error"
              message={
                <Row>
                  <Col xs={24} md={20}>
                    There was an error fetching this product:{" "}
                    {product.product_scrapeurl}
                  </Col>
                  <Col xs={24} md={4} style={{ textAlign: "right" }}>
                    {hasPermission(store, "delete_products") && (
                      <a
                        href="#!"
                        style={{ color: "#FF2700" }}
                        onClick={() =>
                          this.props.onProductDelete([product._id["$oid"]])
                        }
                      >
                        Delete Product
                      </a>
                    )}
                  </Col>
                </Row>
              }
            />
          </Col>
        </Card>
      );
    }
    if (product.export_in_progress && product.export_in_progress === 1) {
      return (
        <Card
          style={{ marginBottom: "20px" }}
          id="test"
          className="import-list-product-container"
        >
          <p>Exporting Product to Store</p>
          <Skeleton active />
        </Card>
      );
    }
    if (product.override_in_progress && product.override_in_progress === 1) {
      return (
        <Card
          style={{ marginBottom: "20px" }}
          id="test"
          className="import-list-product-container"
        >
          <p>Product Override in progress</p>
          <Skeleton active />
        </Card>
      );
    }
    if (product.product_status && product.product_status === "connected") {
      return (
        <Card
          style={{ marginBottom: "20px" }}
          id="test"
          className="import-list-product-container"
        >
          <Col md={3}>
            <p>
              <Icon
                theme="filled"
                type="check-circle"
                style={{
                  color: "#36C626",
                  fontSize: "30px",
                  marginRight: "20px",
                  verticalAlign: "middle",
                }}
              />
              <img
                src={
                  product.variants && product.variants[0]
                    ? product.variants[0].img
                    : PLACEHOLDER_PRODUCT_IMAGE
                }
                style={{ maxWidth: "50px", verticalAlign: "middle" }}
                alt={product.name}
              />
            </p>
          </Col>
          <Col md={17}>
            <p>
              <span style={{ verticalAlign: "middle", fontWeight: 600 }}>
                {product.name}
              </span>
            </p>
          </Col>
          <Col md={4}>
            {this.state.shopifyURL ? (
              <a
                className="ant-btn ant-btn-primary ant-btn-lg"
                href={this.state.shopifyURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`Edit in ${SHOPIFY_NAME}`}
              </a>
            ) : (
              <a
                className="ant-btn ant-btn-primary ant-btn-lg"
                href={product.shopify_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`Edit in ${SHOPIFY_NAME}`}
              </a>
            )}
          </Col>
        </Card>
      );
    }
    if (product.override_status && product.override_status === 1) {
      return (
        <Card
          style={{ marginBottom: "20px" }}
          id="test"
          className="import-list-product-container"
        >
          <p>
            <Icon
              theme="filled"
              type="check-circle"
              style={{
                color: "#36C626",
                fontSize: "30px",
                marginRight: "20px",
                verticalAlign: "middle",
              }}
            />
            <img
              src={
                product.gallery && product.gallery[0]
                  ? product.gallery[0]
                  : PLACEHOLDER_PRODUCT_IMAGE
              }
              style={{ maxWidth: "50px", verticalAlign: "middle" }}
              alt={product.name}
            />
            <span
              style={{
                marginLeft: "20px",
                verticalAlign: "middle",
                fontWeight: 600,
              }}
            >
              {product.name}
            </span>
          </p>
        </Card>
      );
    }

    const productActions = (
      <div className="menudropdownacton">
        <Menu style={{ width: "147px" }}>
          {this.props.storeConnected && (
            <Menu.Item
              className="menu-items-modal"
              onClick={() =>
                this.setState({ showHideheaderdata: false }, () =>
                  this.props.toggleOverrideModal(this.props.product)
                )
              }
            >
              <span>Override Product</span>
            </Menu.Item>
          )}
          {this.props.product.variants.length > 1 && <Menu.Divider />}
          {/* {this.props.product.variants.length > 1 && (
            <Menu.Item
              className="menu-items-modal"
              onClick={() => this.props.toggleSplitProductModal()}
            >
              <span>Split Product</span>
            </Menu.Item>
          )}
          <Menu.Divider /> */}
          <Menu.Item
            className="menu-items-modal"
            onClick={() =>
              this.setState({ showHideheaderdata: false }, () =>
                this.props.onProductDelete([product._id["$oid"]])
              )
            }
          >
            <span>Delete Product</span>
          </Menu.Item>
        </Menu>
      </div>
    );

    return (
      <Card
        id="test"
        className={`import-list-product-container ${
          isSelected ? "selected" : ""
        }`}
      >
        <Tabs
          defaultActiveKey="1"
          tabBarExtraContent={
            <React.Fragment>
              <div
                className="product-selection-container"
                onClick={() => this.toggleSelectedStatus()}
              >
                <Checkbox checked={isSelected} />
              </div>
              <div>
                {this.props.product.catalog_complete === 0 ? (
                  <button
                    style={{
                      marginRight: "20px",
                      padding: "15px 24px",
                      pointerEvents: "none",
                      lineHeight: "12px",
                      background: "#E3E3E3",
                      border: "none",
                      color: "#fff",
                      borderRadius: "2px",
                      fontSize: "12px",
                      textAlign: "center",
                      letterSpacing: "0.032em",
                      position: "relative",
                    }}
                  >
                    Add Product To Store
                  </button>
                ) : this.props.storeConnected &&
                  this.state.messagedata === "default" &&
                  hasPermission(store, "import_export_products") ? (
                  <button
                    className="clickable"
                    type="primary"
                    style={{
                      marginRight: "20px",
                      padding: "12px 24px",
                      lineHeight: "12px",
                      background: "#0068FF",
                      border: "none",
                      color: "#fff",
                      borderRadius: "2px",
                      fontSize: "12px",
                      cursor: "pointer",
                      letterSpacing: "0.032em",
                    }}
                    disabled={this.props.addToStoreLoading}
                    onClick={() => this.importToStore()}
                  >
                    Add Product To Store
                  </button>
                ) : (
                  <button
                    style={{
                      marginRight: "20px",
                      padding: "15px 24px",

                      lineHeight: "12px",
                      background: "#E3E3E3",
                      border: "none",
                      color: "#fff",
                      borderRadius: "2px",
                      fontSize: "12px",
                      textAlign: "center",
                      letterSpacing: "0.032em",
                      position: "relative",
                    }}
                  >
                    Add Product To Store
                  </button>
                )}

                <Dropdown
                  overlay={productActions}
                  placement="bottomRight"
                  onVisibleChange={(e) => {
                    this.setState({ showHideheaderdata: e });
                  }}
                >
                  <a
                    className="ant-dropdown-link importlist_takeaction"
                    href="#/"
                  >
                    Take Action{" "}
                    <svg
                      style={{
                        marginLeft: "5px",
                        transition: "all 0.5s",
                        transform: `${
                          this.state.showHideheaderdata
                            ? "rotateX(180deg)"
                            : "rotateX(0deg)"
                        }`,
                        cursor: "pointer",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="8"
                      viewBox="0 0 16 8"
                    >
                      <g
                        id="Polygon_49"
                        data-name="Polygon 49"
                        transform="translate(16 8) rotate(180)"
                        fill={`${
                          this.state.showHideheaderdata ? "#fff" : "none"
                        }`}
                      >
                        <path d="M8,0l8,8H0Z" stroke="none" />
                        <path
                          d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                          stroke="none"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </a>
                </Dropdown>
              </div>
            </React.Fragment>
          }
        >
          <Tabs.TabPane tab="Product" key="1">
            <ImportListProductBasic
              lessRequestCount={() => this.props.lessRequestCount()}
              requestCount={this.props.requestCount}
              toggleProductDrawer={(p) => this.props.toggleProductDrawer(p)}
              refreshPrice={(cb) => this.props.refreshPrice(cb)}
              product={this.props.product}
              pricing={this.props.settings}
              app_uninstall={this.props.app_uninstall}
              importToStore={() => this.importToStore()}
              storeConnected={this.props.storeConnected}
              currencies={this.props.currencies}
              tags={this.props.tags}
              types={this.props.types}
              collections={this.props.collections}
              onFieldChange={(field, value) =>
                this.onFieldChange(this.props.product._id["$oid"], field, value)
              }
              loadData={this.props.loadData}
              auth={this.props.auth}
              history={this.props.history}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Details" key="2">
            <ImportListProductDescription
              product={this.props.product}
              onFieldChange={(field, value) =>
                this.onFieldChange(this.props.product._id["$oid"], field, value)
              }
            />
          </Tabs.TabPane>
          {/* test  */}
          <Tabs.TabPane tab="Variants" key="3" forceRender={true}>
            <ImportListProductVariants
              product={this.props.product}
              pricing={this.props.settings}
              currencies={this.props.currencies}
              storegeo={this.props.store.store_geo}
              editPricingModalVisible={this.props.editPricingModalVisible}
              toggleOverridePriceModal={(data, type) =>
                this.props.toggleOverridePriceModal(data, type)
              }
              toggleModal={this.props.toggleModal}
              onFieldChange={(field, value, showAlert) =>
                this.onFieldChange(
                  this.props.product._id["$oid"],
                  field,
                  value,
                  showAlert
                )
              }
            />
          </Tabs.TabPane>
          {/* test  */}
          <Tabs.TabPane tab="Images" key="4">
            <ImportListProductImages
              product={this.props.product}
              onFieldChange={(field, value) =>
                this.onFieldChange(this.props.product._id["$oid"], field, value)
              }
            />
          </Tabs.TabPane>
        </Tabs>

        <OverrideProductModal
          visible={this.props.overrideProductModalVisible}
          onCancel={() => this.props.toggleModal("overrideProductModalVisible")}
          onConfirm={() =>
            this.props.toggleModal("overrideProductModalVisible")
          }
        />
        <SplitProductModal
          visible={this.props.splitProductModalVisible}
          onCancel={() => this.props.toggleModal("splitProductModalVisible")}
          onConfirm={() => this.props.toggleModal("splitProductModalVisible")}
        />
      </Card>
    );
  }
}
export default ImportListProductContainer;
