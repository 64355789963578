import React, { Component } from "react";
import {
  Button,
  Divider,
  Icon,
  Input,
  Form,
  message,
  Select,
  Row,
  Radio,
  Upload,
  Col,
  DatePicker,
  Spin,
} from "antd";

import axios from "axios";
import moment from "moment";
import "./index.scss";
const { Option } = Select;

const { TextArea } = Input;

class ExternalForm extends Component {
  state = {
    saving: false,
    privateLabel: undefined,

    productLoading: false,
    productData: [],
    hideProductData: false,
    // image

    preview_image: "",
    fileList: undefined,
    imageLoading: false,
  };

  loadProductData(search) {
    this.setState({ productLoading: true }, () => {
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.cancelToken = axios.CancelToken.source();
      axios({
        url:
          process.env.REACT_APP_API_URL +
          "/rfqrequests/get_products_rqf_requests_create",
        method: "post",
        cancelToken: this.cancelToken.token,
        data: {
          // page: this.state.paging.page,
          // per_page_count: this.state.paging.per_page_count,
          filters: { search },
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.setState({
              productData: !!search ? res.data.data : [],
              productLoading: false,
              hideProductData: false,
            });
          } else {
            this.setState({
              error: true,
              errorMsg: res.data.msg,
              productLoading: false,
              productData: [],
            });
          }
        })
        .catch((e) => {
          this.setState({
            error: true,
            errorMsg: e.message,
            productData: [],
          });
        });
    });
  }

  // image functions
  apiPreview(file) {
    this.setState({ imageLoading: true }, () => {
      const formData = new FormData();

      formData.append("file", file);

      axios({
        url:
          process.env.REACT_APP_API_URL +
          "/rfqrequests/upload_rfq_request_files",
        method: "post",
        withCredentials: true,
        data: formData,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.setState({
              preview_image: res.data.file,
              imageLoading: false,
            });

            message.success("Uploaded successfully!");
          } else {
            message.error(res.data.msg);
            this.setState({
              imageLoading: false,
              fileList: undefined,
              preview_image: undefined,
            });
          }
        })
        .catch((e) => {
          this.setState({
            imageLoading: false,
            fileList: undefined,
            preview_image: undefined,
          });
          message.error(e.message);
        });
    });
  }

  imgHandler(fileUrl) {
    // Get the file extension.
    const fileExtension = fileUrl.split(".")[fileUrl.split(".").length - 1];

    // Check the file extension against a list of supported file types.
    const supportedFileTypes = [
      "xlsx",
      "png",
      "jpg",
      "jpeg",
      "pdf",

      "pdf",
      "mp4",
    ];

    if (supportedFileTypes.includes(fileExtension)) {
      // The file type is supported, so return the HTML for displaying the file.
      switch (fileExtension) {
        case "xlsx":
          return <iframe title="file" src={fileUrl} />;
        case "png":
        case "jpg":

        case "jpeg":
          return <img alt="img" src={fileUrl} />;

        case "mp4":
          return <video controls alt="img" src={fileUrl} />;
        case "pdf":
          return (
            <embed
              src={fileUrl}
              type="application/pdf"
              width="100%"
              height="500px"
            />
          );
        default:
          return null;
      }
    } else {
      // The file type is not supported, so return an empty string.
      return "";
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
    

      if (!err) {
        this.setState(
          {
            saving: true,
          },
          () => {
            axios({
              url:
                process.env.REACT_APP_API_URL +
                "/rfqrequests/create_rfq_requests",
              method: "post",
              data: {
                ...values,
                files: this.state.preview_image
                  ? [this.state.preview_image]
                  : [],
                type: "external",
                expected_landing_product_cost: Number(
                  values.expected_landing_product_cost
                ),
              },
              withCredentials: true,
            })
              .then((res) => {
                if (res.data.success === 1) {
                  this.props.activeTabHandler("open");
                  this.setState(
                    {
                      saving: false,
                    },
                    () => this.props.onCancel()
                  );
                } else {
                  message.info(res.data.msg);

                  this.setState({
                    saving: false,
                  });
                }
              })
              .catch((e) => {
                this.setState({
                  saving: false,
                });
              });
          }
        );
      }
    });
  };

  clickChecker(e) {
    try {
      if (e.target.className.includes("img-details external")) {
        return null;
      } else {
        this.setState({ productData: [], hideProductData: true });
      }
    } catch (error) {}
  }
  render() {
    const { getFieldDecorator, setFieldsValue } = this.props.form;

    // uploader prop
    const props = {
      name: "files",
      multiple: false,
      showUploadList: true,
      accept: ".jpg, .jpeg, .png, .mp4, .pdf",

      onRemove: (file) => {
        setFieldsValue({ files: null });
        this.setState((state) => {
          // const index = state.fileList.indexOf(file);
          // const newFileList = state.fileList.slice();
          // newFileList.splice(index, 1);

          return {
            fileList: undefined,
            preview_image: "",
          };
        });
      },
      beforeUpload: (file) => {
        this.setState(
          (state) => ({
            fileList: [file],
          }),
          () => {
            this.apiPreview(file);
          }
        );

        return false;
      },
      maxCount: 1,
    };

    return (
      <Form onClick={(e) => this.clickChecker(e)} onSubmit={this.handleSubmit}>
        <div className="product-name-wraper">
          <Form.Item label="Product Name">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Please enter product name",
                },
              ],
            })(
              <Input
                // onBlur={() => this.setState({ hideProductData: true })}

                onChange={(e) => this.loadProductData(e.target.value)}
                placeholder="Enter product name"
              />
            )}
          </Form.Item>

          {this.state.productLoading && <Spin />}

          {this.state.productData.length &&
          this.state.hideProductData === false ? (
            <div className="external-product-wraper">
              {" "}
              {this.state.productData.map((d) => (
                <div
                  onClick={(a) => this.props.externalFormProductHandler(d)}
                  className="img-details external"
                >
                  <div className="img-details">
                    <img src={d.image} alt="" />
                    <div className="text-wraper">
                      <a
                        target="_blank"
                        className="underline-animation"
                        href={d.product_url}
                      >
                        {d.name}
                      </a>

                      <div className="vf">
                        <span>VF ID:</span> {d.vfprodid}
                      </div>
                    </div>
                  </div>

                  <svg
                    className="right_svg"
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z" />
                  </svg>
                </div>
              ))}
            </div>
          ) : null}

          <Divider />
          <Form.Item label="Product URL">
            {getFieldDecorator("product_url", {
              rules: [
                {
                  type: "url",
                  message: "Please enter a valid product URL",
                },
                {
                  required: true,
                  message: "Enter Product URL",
                },
              ],
            })(<Input placeholder="Product URL" />)}
          </Form.Item>
           
          <Form.Item label="Required Quantity">
            {getFieldDecorator("qty", {
              rules: [
                {
                  required: true,
                  message: "Please enter required quantity",
                },
              ],
            })(
              <Select
                className="landing-select"
                placeholder="Enter required quantity"
              >
                <Option value={"1"}> Sample </Option>
                <Option value={"100-250"}>100 - 250</Option>
                <Option value={"251-500"}>251 - 500</Option>
                <Option value={"501-1000"}>501 - 1000</Option>
                <Option value={"1001-2000"}>1001 - 2000</Option>
                <Option value={"2001"}> + 2001</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Upload Files (.jpg, .jpeg, .png, .mp4, .pdf)">
            {getFieldDecorator(
              "files",
              {}
            )(
              <Upload.Dragger {...props} fileList={this.state.fileList}>
                {this.state.imageLoading ? (
                  <Spin />
                ) : (
                  <>
                    <Icon type="upload" /> Click to upload media{" "}
                  </>
                )}
              </Upload.Dragger>
            )}
          </Form.Item>

          <Row gutter={12}>
            {/* <Col span={24}>
              <Form.Item label="Expected Landing Timeline">
                {getFieldDecorator("expected_landing_date", {
                  rules: [
                    {
                      required: true,
                      message: "Please select expected landing Timeline",
                    },
                  ],
                })(
                  <Select
                  className="landing-select"
                  placeholder="Enter expected landing timeline"
                >
                   <Option value={"4-7"}>4-7 days</Option>
                  <Option value={"10-15"}>10-15 days</Option>
                  <Option value={"30-45"}>30-45 days</Option>
                </Select>
                )}
              </Form.Item>
            </Col> */}
          </Row>
        

          <Form.Item label="Expected Product Cost">
            {getFieldDecorator(
              "expected_landing_product_cost",
              {}
            )(
              <Input
                placeholder="Enter expected product cost for the required quantity"
                addonBefore="₹"
                type="number"
              />
            )}
          </Form.Item>
          <Form.Item
            className="private-label-radio-group"
            label="Private Label"
          >
            {getFieldDecorator("private_label", {
              initialValue: "no",
              rules: [],
            })(
              <Radio.Group
                onChange={(e) =>
                  this.setState({ privateLabel: e.target.value })
                }
              >
                <Radio value={"yes"}>Yes</Radio>
                <Radio value={"no"}>No</Radio>
              </Radio.Group>
            )}
          </Form.Item>

          <Form.Item label="Additional Information">
            {getFieldDecorator("private_label_remarks", {
              rules: [],
            })(<TextArea placeholder="Enter some additional information for the request." />)}
          </Form.Item>
        </div>
        <Form.Item className="footer-button">
          <Button
            type="info"
            htmlType="button"
            className="cancelBtn"
            disabled={this.state.saving}
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            disabled={this.state.saving || this.state.imageLoading}
            style={{ marginLeft: 12 }}
            loading={this.state.saving}
          >
            Submit Request
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(ExternalForm);
