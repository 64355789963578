import React, { useState } from "react";
import { formatBalance } from "../../../../../Helpers/Pricing";
import "./index.scss";
import { Button, Modal, Tooltip, Icon, Switch } from "antd";
import { ReactComponent as TooltipIcon } from "../../../../../assets/images/icons/TooltipIcon.svg";
import ImageUploader from "./ImageUploader";
import axios from "axios";
import RemarksContainer from "./RemarksContainer";

const BulkOrderConfirmModal = (props) => {
  const { data, freightType, visible, loading, currency, pricing } = props;

  function transitTimeCalc(status) {
    if (status === "") {
      return "N/A";
    }

    if (status === "VF Inventory") {
      return "0 days";
    }

    if (status === "Transit Via Air") {
      return "8-10 days";
    }
    if (status === "Transit Via Sea") {
      return "30-40 days";
    }
  }

  return (
    <>
      {/*  Open Raise Bulk Order Request */}
      <Modal
        width={1000}
        className="rasing-bulk-order-modal"
        visible={visible}
        footer={
          <>
            <div className="note">
              {props.pricing && props.pricing.gstamount > 0 ? (
                <p>*Note - </p>
              ) : null}
              {/* <p>*Note - </p> */}
            </div>
            <div className="note-desc">
              {props.pricing && props.pricing.gstamount > 0
                ? // <p className="gst-desc">
                  //   *You will receive an input credit of the GST charged in the
                  //   amount above
                  // </p>
                  null
                : null}

              {freightType === "VF Inventory" ? (
                // <p>
                //   Once you confirm the Booking, final amount will be deducted
                //   from your wallet and Inventory will be immediately transferred
                //   to you.
                // </p>
                <p>
                  {
                    "You will receive a confirmation call from vFulfill team and the final amount will be charged from your wallet only after your confirmation."
                  }
                </p>
              ) : (
                <p>
                  {
                    "You will receive a confirmation call from vFulfill team and the final amount will be charged from your wallet only after your confirmation."
                  }
                </p>
              )}
            </div>
            <div className="btns">
              <Button
                disabled={loading}
                onClick={() => props.cancelOrder()}
                type="cancel"
              >
                Cancel
              </Button>

              <Button
                loading={loading}
                className="clickable-dropdown"
                onClick={() => props.confirmOrder()}
                type="primary"
              >
                {freightType === "VF Inventory"
                  ? "Confirm Booking Request"
                  : "  Confirm Booking Request"}
              </Button>
            </div>
          </>
        }
        onOk={() => props.confirmOrder()}
        title={
          freightType === "VF Inventory" ? (
            <>
              <h1>Confirm Booking Request</h1>{" "}
              <p>
                Please verify the following details of your booking request:
              </p>{" "}
            </>
          ) : (
            <>
              <h1>Raising Bulk Store Order Request</h1>{" "}
              <p>
                Please verify the following details of your booking request:
              </p>{" "}
            </>
          )
        }
        // onCancel={handleCancel}
      >
        <div className="content-headings">
          <div className="content-head-1">
            <p>Bulk Inv. Freight Type:</p>
            <span>{freightType}</span>
          </div>
          <div className="content-head-2">
            <p>Transit Time: </p>
            <span> {transitTimeCalc(freightType)}</span>
            <div className="product-tooltip">
              <Tooltip title="">
                <Icon
                  className="product-tooltip-icon"
                  component={TooltipIcon}
                />
              </Tooltip>
            </div>
          </div>
          {/* to do */}
          {/* <div className="content-head-3">
            <p>Inventory Restocking Time: {props.stockProcessingTime}</p>
            <div className="product-tooltip">
              <Tooltip title="">
                <Icon
                  className="product-tooltip-icon"
                  component={TooltipIcon}
                />
              </Tooltip>
            </div>
          </div> */}
        </div>
        <div className="variants-headings">
          <div className="head-1">Variant(s)</div>
          <div className="head-2">Price/ Pc.</div>
          <div className="head-3">Booking Qty.</div>
          <div className="head-4">Total Price</div>
        </div>

        {data.map((d) => {
          return (
            <div className="variants-data-row">
              <div className="head-1">
                <div className="img">
                  {" "}
                  <img src={d.img} />
                </div>
                <div className="details">
                  <div className="sku">
                    <p>SKU: </p>
                    <span>{d.vfsku}</span>
                  </div>
                  <div className="specs">
                    <p>Specs:</p>
                    <span>{d.specs}</span>
                  </div>
                </div>
              </div>
              <div className="head-2">
                {currency}{" "}
                {(Number(d.totalPrice) / Number(d.qty)).toFixed(2) === "NaN"
                  ? "0"
                  : formatBalance(Number(d.totalPrice) / Number(d.qty))}
              </div>
              <div className="head-3">{Number(d.qty)}</div>
              <div className="head-4">
                {currency}{" "}
                {Number(d.totalPrice).toFixed(2) === "NaN"
                  ? "0"
                  : formatBalance(Number(d.totalPrice))}
              </div>
            </div>
          );
        })}
        <div className="variants-total-pricing">
          <div className="included">
            Included: Shipping to India | Customs Duty | Inward Fees |
            Compliance Fees
          </div>

          <div className="total-price">
            {Number(pricing.gstamount) > 0 ? (
              <div className="gst">+ {props.gstPercentage}% GST:</div>
            ) : null}
            <div className="price">
              {currency}
              {formatBalance(Number(pricing.total))}
            </div>{" "}
          </div>
        </div>
        <div
          style={{ marginBottom: "5px", marginLeft: "16px", marginTop: "24px" }}
        >
          Do you want to private label your product ?
        </div>
        <Switch
          style={{ marginBottom: "12px", marginLeft: "16px" }}
          value={props.privateLabel}
          onChange={(e) => props.onChangePrivateLabel(e)}
        />
        {props.privateLabel ? (
          <>
            {" "}
            <ImageUploader
              selectedImages={props.selectedImages}
              loading={props.loadingImage}
              handleImageChange={(e) => props.handleImageChange(e)}
              handleCancel={(index) => props.handleCancelimage(index)}
              imageprops={props.imageprops}
            />
            <RemarksContainer
              remarks={props.remarks}
              handleRemarksChange={(e) => props.handleRemarksChange(e)}
            />
          </>
        ) : null}
      </Modal>
    </>
  );
};

export default BulkOrderConfirmModal;
