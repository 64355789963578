import React from "react";
import { Modal, Input, message, Button } from "antd";
import axios from "axios";
import "./index.scss";

class WoCommerceModal extends React.Component {
  state = {
    woCommerceDetail: {
      shop_name: "",
      shop_url: "",
      shop_consumer_key: "",
      shop_consumer_secret: "",
    },
    loading: false,
    key_id: null,
    key_id2: null,
  };

  handleChange(key, value) {
    this.setState({
      woCommerceDetail: {
        ...this.state.woCommerceDetail,
        [key]: value,
      },
    });
  }

  emptywoCommerceDetail() {
    this.setState({
      woCommerceDetail: {
        shop_name: "",
        shop_url: "",
        shop_consumer_key: "",
        shop_consumer_secret: "",
      },
    });
  }

  toggleWoCommerceModal4() {
    this.emptywoCommerceDetail();
    this.props.toggleWoCommerceModal2();
  }

  onSaveChange() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/store/add_woocommerce_store",
          method: "post",
          data: {
            ...this.state.woCommerceDetail,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                key_id: res.data.id,
              });
              this.props.toggleWoCommerceModal2();
              this.props.history.push(`/switch-store/${res.data.id}`);
              this.emptywoCommerceDetail();
              message.success("Store successfully connected");
            } else {
              this.setState({
                loading: false,
              });
              message.error(res.data.msg);
            }
          })

          .catch((e) => {
            this.setState({
              loading: false,
            });
            message.error(e.message);
          });
      }
    );
  }

  render() {
    const props = this.props;
    const { visible, toggleWoCommerceModal2 } = props;
    return (
      <Modal
        className="woCommerceModal"
        width={568}
        title="Add New WooCommerce Store"
        visible={visible}
        onCancel={toggleWoCommerceModal2}
        destroyOnClose={true}
        footer={
          <div className="bottom_button">
            <Button
              className="go_back_btn"
              onClick={() => this.props.toggleFlageBackfunctionlity()}
            >
              <span className="svg_go_back">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="16"
                  viewBox="0 0 8 16"
                >
                  <g
                    id="Polygon_63"
                    data-name="Polygon 63"
                    transform="translate(0 16) rotate(-90)"
                    fill="none"
                  >
                    <path
                      d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                      stroke="none"
                      fill="#898989"
                    />
                  </g>
                </svg>
              </span>
              <span className="go_back_txt">Go Back</span>
            </Button>

            <Button
              onClick={() => this.toggleWoCommerceModal4()}
              className="cancel_close_btn"
            >
              Cancel & Close
            </Button>

            <Button
              className="clickable_btn"
              onClick={() => this.onSaveChange()}
            >
              Continue
            </Button>
          </div>
        }
      >
        <div className="woCommerce_detail_input">
          <div className="upeer_label_margin">
            <p className="common_label">
              Shop Name <span className="star">*</span>
            </p>
            <Input
              className="shop_name"
              placeholder="Enter Your Shop Name "
              value={this.state.woCommerceDetail.shop_name}
              onChange={(e) => this.handleChange("shop_name", e.target.value)}
            />
          </div>

          <div className="upeer_label_margin">
            <p className="common_label">
              Shop URL <span className="star">*</span>
            </p>
            <Input
              className="shop_url"
              placeholder="Enter Shop URL"
              value={this.state.woCommerceDetail.shop_url}
              onChange={(e) => this.handleChange("shop_url", e.target.value)}
            />
          </div>

          <div className="upeer_label_margin">
            <p className="common_label" style={{ width: "100%" }}>
              Shop Consumer Key <span className="star">*</span>
              <a
                target="_blank"
                href="https://woocommerce.github.io/woocommerce-rest-api-docs/?php#authentication"
                style={{ float: "right" }}
              >
                Reference Link
              </a>
            </p>
            <Input
              className="shop_consumer_key"
              placeholder="Enter Shop Consumer key"
              value={this.state.woCommerceDetail.shop_consumer_key}
              onChange={(e) =>
                this.handleChange("shop_consumer_key", e.target.value)
              }
            />
          </div>

          <div className="upeer_label_margin">
            <p className="common_label" style={{ width: "100%" }}>
              Shop Consumer Secret <span className="star">*</span>
              <a
                target="_blank"
                href="https://woocommerce.github.io/woocommerce-rest-api-docs/?php#authentication"
                style={{ float: "right" }}
              >
                Reference Link
              </a>
            </p>
            <Input
              className="shop_consumer_secret"
              placeholder="Enter Shop Consumer Secret"
              value={this.state.woCommerceDetail.shop_consumer_secret}
              onChange={(e) =>
                this.handleChange("shop_consumer_secret", e.target.value)
              }
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default WoCommerceModal;
