import { Modal, message, Button } from "antd";
import React, { Component } from "react";
import ImageUploader from "./ImageUploader";
import RemarksContainer from "./RemarksContainer";
import axios from "axios";
export default class UpdatePackingModal extends Component {
  state = {
    selectedImages: this.props.images,
    loadingImage: false,
    remarks: this.props.remarks,
  };
  handleRemarksChange = (e) => {
    this.setState({ remarks: e.target.value });
  };
  handleImageChange = (file) => {
    // const selectedImages = Array.from(event.target.files);

    const formData = new FormData();
    formData.append("file", file);

    this.setState({ loadingImage: true }, () => {
      axios({
        url:
          process.env.REACT_APP_API_URL +
          "/sourcing/upload_clientsourcingrequest_packaging_docs",
        method: "POST",
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          if (response.data.success) {
            const uploadedUrl = response.data.file;
            // message.success();
            this.setState({
              selectedImages: [...this.state.selectedImages, uploadedUrl],
            });
          } else {
            message.error(response.data.msg);
          }
          this.setState({ loadingImage: false });
        })
        .catch((error) => {
          message.error("Error uploading images:", error);
          this.setState({ loadingImage: false });
        });
    });
  };
  handleCancelimage = (index) => {
    let selected = [...this.state.selectedImages];
    selected.splice(index, 1);
    this.setState({
      selectedImages: selected,
    });
  };
  handleCustomPacking = () => {
    // if (!this.state.remarks) {
    //   message.error("Please enter remarks");
    //   return;
    // }
    axios({
      url:
        process.env.REACT_APP_API_URL +
        "/sourcing/add_clientsourcingrequest_packaging_suggestions",
      method: "POST",
      data: {
        csrid: this.props.request_vfid,
        remarks: this.state.remarks,
        suggestions: this.state.selectedImages,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.msg);
          this.props.cancel();
          this.props.fetchOrders();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  render() {
    const imageprops = {
      multiple: true,
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        // this.setState((state) => ({
        //   // fileList: [.file]
        //   fileList: [...state.fileList, file],
        // }));
        this.handleImageChange(file);
        return false;
      },
      fileList: [],
    };
    return (
      <Modal
        visible={this.props.visible}
        onCancel={() => {
          this.props.cancel();
        }}
        title={
          this.props.Addheading
            ? "Add Private Label Settings"
            : "Update Private Label Settings"
        }
        width={800}
        footer={[
          <Button
            onClick={() => {
              this.props.cancel();
            }}
          >
            Cancel
          </Button>,
          <Button
            onClick={() => {
              this.handleCustomPacking();
            }}
            className="clickable"
          >
            Update
          </Button>,
        ]}
      >
        <ImageUploader
          selectedImages={this.state.selectedImages}
          loading={this.state.loadingImage}
          handleImageChange={this.handleImageChange}
          handleCancel={(index) => this.handleCancelimage(index)}
          imageprops={imageprops}
        />
        <RemarksContainer
          remarks={this.state.remarks}
          handleRemarksChange={(e) => this.handleRemarksChange(e)}
        />
      </Modal>
    );
  }
}
