import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, message } from "antd";
import "./index.scss";
import axios from "axios";
class flexibleFullfillmentCard extends Component {
  state = {
    flexibleFullfillment: this.props.flexible_fullfillment_on ? true : false,
  };
  handleFullfillmentToggle(checked) {
    axios({
      url: process.env.REACT_APP_API_URL + "/products/enable_flexible_fulfillment",
      method: "POST",
      data: {
        vfprodid: this.props.id,
        storeid: this.props.store.id,
        flexible_fullfillment_on: checked?1:0,
        // shopifyProductId:this.props.shopifyProductId
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            escalateLoading: false,
            flexibleFullfillment:
              this.state.flexibleFullfillment === true ? false : true,
          });

          message.success(res.data.msg);
          // this.props.loadData();
        } else {
          message.warning(res.data.msg);
        }
      })
      .catch((err) => {
        message.warning(err.message);
      });
  }
  render() {
    const { id, image, name } = this.props;
    return (
      <div className="fullfillment-card">
        <div className="left-varaint-image-data">
          <img src={image ? image : null} alt="#" />
          <span className="image-name">{name}</span>
        </div>
        <div className="switch-cointainer">
          <Switch
            checked={this.state.flexibleFullfillment}
            onChange={(checked) =>
              this.handleFullfillmentToggle(checked)
            }
            className="switchFullfillment"
            // size="small"
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  store: state.auth.auth.store,
});
export default connect(mapStateToProps)(flexibleFullfillmentCard);
