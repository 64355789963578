import React from "react";
import moment from "moment";
import "./index.scss";
import { Tooltip } from "antd";
class Notes extends React.Component {
  render() {
    const text = <span>Calling Remark </span>;
    return (
      <div
        className={`tab-container ${
          this.props.activeTab !== "notes" ? "hidden" : ""
        }`}
      >
        <div className="notes-tab">
          {" "}
          {this.props.notes && this.props.notes.length > 0 ? (
            <div className="header_upper_data">
              <p className="flex-note">Notes</p>
              <p className="flex-date">Date</p>
              <p className="flex-addedby">Added by</p>
            </div>
          ) : null}
             
          {this.props.notes && this.props.notes.length > 0 ? (
            this.props.notes.map((n) => (
              <div className="note" key={n.date}>
                <div className="flex-note">
                  {console.log(n.note_purpose)}
                  {n.note_purpose ==="calling"? <Tooltip placement="left" title={text} >
                  <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="49.399"
                        height="49.399"
                        viewBox="0 0 49.399 49.399"
                      >
                        <path
                          id="Icon_awesome-phone-alt"
                          data-name="Icon awesome-phone-alt"
                          d="M34.973,25.439,27.1,22.064a1.687,1.687,0,0,0-1.969.485L21.641,26.81A26.062,26.062,0,0,1,9.183,14.351l4.261-3.488A1.683,1.683,0,0,0,13.929,8.9L10.554,1.02A1.7,1.7,0,0,0,8.62.043L1.308,1.73A1.688,1.688,0,0,0,0,3.375,32.621,32.621,0,0,0,32.625,36a1.687,1.687,0,0,0,1.645-1.308l1.687-7.313a1.708,1.708,0,0,0-.985-1.941Z"
                          transform="translate(0 18.541) rotate(-31)"
                        />
                      </svg>
                      </Tooltip>:
                      <span style={{width:"18px",height:"18px",display:"inline-block" ,marginRight:"3px"}}></span>}
                  {/* {
                    (n.note_purpose = "calling" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="49.399"
                        height="49.399"
                        viewBox="0 0 49.399 49.399"
                      >
                        <path
                          id="Icon_awesome-phone-alt"
                          data-name="Icon awesome-phone-alt"
                          d="M34.973,25.439,27.1,22.064a1.687,1.687,0,0,0-1.969.485L21.641,26.81A26.062,26.062,0,0,1,9.183,14.351l4.261-3.488A1.683,1.683,0,0,0,13.929,8.9L10.554,1.02A1.7,1.7,0,0,0,8.62.043L1.308,1.73A1.688,1.688,0,0,0,0,3.375,32.621,32.621,0,0,0,32.625,36a1.687,1.687,0,0,0,1.645-1.308l1.687-7.313a1.708,1.708,0,0,0-.985-1.941Z"
                          transform="translate(0 18.541) rotate(-31)"
                        />
                      </svg>
                    ) : null)
                  } */}

                  {n.note && n.note.length <= 45 ? (
                    n.note
                  ) : (
                    <Tooltip
                      title={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: n.note.replace(/\n/g, "<br/>"),
                          }}
                        />
                      }
                    >
                      {" "}
                      {n.note ? `${n.note.substring(0, 45)}...` : null}
                    </Tooltip>
                  )}

                  {/* <span
                    dangerouslySetInnerHTML={{
                      __html: n.note.replace(/\n/g, "<br/>"),
                    }}
                  /> */}
                </div>
                <div className="flex-date">
                  {moment(n.date).format("DD MMM, YYYY | HH:mm:ss")}
                </div>
                <div className="flex-addedby">by {n.addedbyname}</div>
              </div>
            ))
          ) : (
            <h3 style={{ textAlign: "center" }}>No Notes Added!</h3>
          )}
        </div>
      </div>
    );
  }
}

export default Notes;
