import React from "react";
import "./index.scss";

const OnboardingField = props => (
  <div className="onboarding-field">
    {props.render()}
    <label>{props.label}</label>
    {props.addon && <span className="input-addon">{props.addon}</span>}
  </div>
);

export default OnboardingField;
