import React from "react";
import { Tag, Icon } from "antd";
import { ReactComponent as featured } from "../../../../../../assets/images/icons/feature.svg";
import { ReactComponent as featureDisable } from "../../../../../../assets/images/icons/feature-disable.svg";
import { ReactComponent as bestseller } from "../../../../../../assets/images/icons/best-seller.svg";
import { ReactComponent as bestsellerDisable } from "../../../../../../assets/images/icons/bestseller-disable.svg";
import { ReactComponent as shipping } from "../../../../../../assets/images/icons/shipping.svg";
import { ReactComponent as shippingDisable } from "../../../../../../assets/images/icons/shipping-disable.svg";
// import { ReactComponent as standard_shipping } from "../../../../../../assets/images/icons/standard-shipping.svg";
import { ReactComponent as doller_circle } from "../../../../../../assets/images/icons/doller-circle.svg";
import { ReactComponent as buybackDisable } from "../../../../../../assets/images/icons/buyback-disable.svg";
import "./index.scss";

export default ({ product }) => {
  return (
    <div className="product-attributes">
      <div className="attri">
        {product.attributes && product.attributes.featured === 1 ? (
          <Tag color="gold">
            <div className="tag-class">
              <Icon component={featured} /> <p>Featured</p>
            </div>
          </Tag>
        ) : (
          <Tag>
            <div className="tag-class">
              <Icon component={featureDisable} /> <p>Featured</p>
            </div>
          </Tag>
        )}
        {product.attributes && product.attributes.bestesller === 1 ? (
          <Tag>
            <div className="tag-class">
              <Icon component={bestseller} /> <p>Bestseller</p>
            </div>
          </Tag>
        ) : (
          <Tag>
            <div className="tag-class">
              <Icon component={bestsellerDisable} /> <p>Bestseller</p>
            </div>
          </Tag>
        )}
        {product.buyback === 1 && product.buyback_percentage > 0 ? (
          <Tag color="green" style={{ lineHeight: "13px" }}>
            <div className="tag-class">
              <Icon component={doller_circle} />
              <p>
                Buyback Available
                <br />({product.buyback_percentage}% Of Original Price)
              </p>
            </div>
          </Tag>
        ) : (
          <Tag style={{ lineHeight: "13px" }}>
            <div className="tag-class">
              <Icon component={buybackDisable} />{" "}
              <p>
                Buyback Available
                <br />({product.buyback_percentage}% Of Original Price)
              </p>
            </div>
          </Tag>
        )}
        {product.inventory_available === 1 ? (
          <Tag color="cyan" style={{ lineHeight: "13px" }}>
            <div className="tag-class">
              <Icon component={shipping} />{" "}
              <p>
                Expedited Shipping
                <br />
                (Delivers in 4-8 days)
              </p>
            </div>
          </Tag>
        ) : (
          <Tag style={{ lineHeight: "13px" }}>
            <div className="tag-class">
              <Icon component={shippingDisable} />{" "}
              <p>
                Expedited Shipping
                <br />
                (Delivers in 4-8 days)
              </p>
            </div>
          </Tag>
        )}
        {/* <Tag color="blue" style={{ lineHeight: "13px" }}>
          <Icon component={standard_shipping} /> Standard Shipping
          <br />
          <span>(Delivers in 8-10 days)</span>
        </Tag> */}
      </div>
    </div>
  );
};
