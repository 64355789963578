import React from "react";
import { Link } from "react-router-dom";
import { Icon, Tooltip } from "antd";
import { ReactComponent as heartblank } from "../../../../../../assets/images/icons/heartblank.svg";
import { ReactComponent as heartfill } from "../../../../../../assets/images/icons/heartfill.svg";
import "./index.scss";

class ViewInVfCatalogButton extends React.Component {
  render() {
    return (
      <div className="footer pr-viewvf">
        <Link
          className="f12 hover-underline-animation"
          to={`/${this.props.storegeo}/${this.props.storeid}/products/${this.props.product.vfprodid}`}
          target="_blank"
        >
          View Product Details{" "}
          <svg
            className="ml8"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="8"
            viewBox="0 0 16 8"
          >
            <path
              data-name="Path 36"
              d="M15.907 3.73L12.352.084a.272.272 0 00-.324-.052.305.305 0 00-.176.3v2.334H.3a.272.272 0 00-.213.094.348.348 0 00-.083.24v2a.347.347 0 00.083.239.272.272 0 00.213.094h11.552v2.334a.317.317 0 00.176.3.265.265 0 00.324-.062l3.555-3.688a.363.363 0 00.092-.25.35.35 0 00-.092-.237z"
              fill="#0068ff"
            />
          </svg>
        </Link>
        <button
          className="f12 add-to-import-list-button"
          onClick={() =>
            this.props.importProduct(
              this.props.product.vfprodid,
              this.props.product.name
            )
          }
        >
          <span className="icon" style={{ height: 48 }}>
            <svg
              id="Add_Icon"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
            >
              <path
                id="vertical_Line"
                d="M184.458,948.284v12"
                transform="translate(-178.458 -948.284)"
                fill="none"
                stroke="#fff"
                stroke-width="3"
              />
              <path
                id="horizontal_Line"
                d="M178.436,954h12"
                transform="translate(-178.436 -948)"
                fill="none"
                stroke="#fff"
                stroke-width="3"
              />
            </svg>
          </span>
          <span className="text">Add To Import List</span>
        </button>

        <span className="heartblank-ican ">
          <Tooltip
            title={
              this.props.in_watchlist ? (
                <p>Remove from My Wishlist</p>
              ) : (
                <p>Add to My Wishlist</p>
              )
            }
          >
            <button
              className={
                this.props.Wishlistcard
                  ? "heartblank-ican-btn wishlisttabheart"
                  : "heartblank-ican-btn"
              }
              onClick={() => this.props.productWatch()}
            >
              {this.props.in_watchlist === 1 ? (
                <Icon component={heartfill} className="heartfill" />
              ) : (
                <Icon component={heartblank} className="heartblank" />
              )}
            </button>
          </Tooltip>
        </span>
      </div>
    );
  }
}

export default ViewInVfCatalogButton;
