import {
  Alert,
  Badge,
  Button,
  message,
  Row,
  Skeleton,
  Table,
  Popconfirm,
  Tooltip,
} from "antd";
import React, { Component } from "react";
import "./index.scss";
import axios from "axios";
import { ReactComponent as VisaIcon } from "../../../../assets/images/icons/visa.svg";
import { ReactComponent as MasterIcon } from "../../../../assets/images/icons/credit-debit.svg";
import { ReactComponent as EditiCON } from "../../../../assets/images/icons/editican.svg";
import AppSubscriptionPlan from "../../AppSubscriptionPlan";
import EditPaymentModal from "./EditPaymentModal";
import { ReactReduxContext } from "react-redux";
import analytics from "../../../../analytics";
import { Redirect } from "react-router-dom";
import moment from "moment";

const planStatus = {
  active: "Active",
  incomplete: "Incomplete",
  pause: "Pause",
  incomplete_expired: "Expired",
  trialing: "Trial Active",
  past_due: "Overdue",
};

const planStatusColor = {
  active: "#2C9545",
  incomplete: "#CC6F22",
  pause: "#CC6F12",
  incomplete_expired: "#CC3D12",
  trialing: "#8412CC",
  past_due: "#ac0036",
};

const columns = [
  {
    title: "Date of Payment",
    dataIndex: "payment_date",
    render: (e, obj) => {
      return <div>{e ? moment(e).format("DD MMM, YYYY") : ""} </div>;
    },
  },
  {
    title: "Plan Name",
    dataIndex: "plan_name",
  },
  {
    title: "Paid Via",
    dataIndex: "payment_details",
    render: (e, obj) => {
      if (obj.amount) {
        return (
          <div>
            {
              <span style={{ fontWeight: 600, textTransform: "uppercase" }}>
                {obj.payment_details.card_type}
              </span>
            }{" "}
            ending in{" "}
            {
              <span style={{ fontWeight: 600, textTransform: "uppercase" }}>
                {obj.payment_details.card_ending}
              </span>
            }
          </div>
        );
      }

      return <div>-</div>;
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    render: (e, obj) => {
      return (
        <div>
          {obj.currency === "USD" || obj.currency === "usd" ? "$" : "₹"} {e}{" "}
        </div>
      );
    },
  },

  {
    title: "Payment Status",
    dataIndex: "status",
    render: (e, obj) => {
      return (
        <div>
          {e == true ? (
            <div className="green-badge"> Success </div>
          ) : (
            <div className="red-badge"> Failed </div>
          )}
        </div>
      );
    },
  },

  {
    title: "Invoice Actions",
    dataIndex: "invoice",
    render: (e, arr) => {
      return e ? (
        <div className="failed-wraper">
          <a className="do-btn" href={e} download>
            Download
          </a>

          {/* {arr.status === false && <div> Failed </div>} */}
        </div>
      ) : (
        "N/A"
      );
    },
  },
];
export default class PlanAndBilling extends Component {
  state = {
    loading: false,
    loadingSubscription: false,
    pauseSubscription: false,
    freeSubscription: false,
    current_plan: {
      plan_name: "",
      renewal_date: "",
      collection_pause: 0,
      currency: "",
      amount: "",
      interval: "",
      plan_type: "",
      plan_status: "",
    },
    upgradable_plans: [],
    payment_method: {
      card_type: "",
      card_ending: "",
      card_valid_upto: "",
      customer_details: {
        name: "",
        email: "",
      },
    },
    invoice_list: [],
    error: false,
    errorMsg: "",
    showUpgradePlanModal: false,
    cancelPlan: false,
    editPaymentModal: false,
  };
  creditCardHandler(card) {
    if (!card) {
      return;
    }

    if (card === "visa" || card === "Visa" || card === "VISA") {
      return <VisaIcon />;
    }

    if (
      card === "MASTERCARD" ||
      card === "mastercard" ||
      card === "MasterCard" ||
      card === "master" ||
      card === "master_card"
    ) {
      return <MasterIcon />;
    }

    return <div className="credit-card-wraper">{card}</div>;
  }
  loadData() {
    this.setState(
      {
        loading: true,
        editPaymentModal: false,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/store/get_store_plan_billing",
          method: "post",

          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success) {
              if (
                !!this.props.auth.location.search &&
                !!this.props.auth.location.search.includes(
                  "upgradesubscriptionplan"
                )
              ) {
                this.setState({
                  ...res.data.data,
                  showUpgradePlanModal: true,
                  loading: false,
                });
              } else {
                this.setState({
                  ...res.data.data,
                  loading: false,
                });
              }
            } else {
              this.setState({
                loading: false,
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((error) => {
            this.setState({
              loading: false,
              error: true,
              errorMsg: error.message,
            });
            message.error(error.message);
          });
      }
    );
  }
  cancelSubcription(type = 0) {
    analytics.track("Cancel Plan Clicked");
    this.setState(
      {
        loadingSubscription: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/stripe/cancel_subscriptions",
          method: "post",

          withCredentials: true,

          data: {
            cancel_now: type,
          },
        })
          .then((res) => {
            this.setState({
              loadingSubscription: false,
            });
            if (res.data.success) {
              message.info(res.data.msg);
              setTimeout(() => this.setState({ cancelPlan: true }), 1000);
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((err) => {
            this.setState({
              loadingSubscription: false,
            });
            message.error(err.message);
          });
      }
    );
  }
  freeSubcription(status) {
    analytics.track(`${status} plan clicked`);
    this.setState(
      {
        freeSubscription: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/stripe/set_free_plan",
          method: "post",
          withCredentials: true,
          data: {
            cancel_now: 1,
          },
        })
          .then((res) => {
            this.setState({
              freeSubscription: false,
            });
            if (res.data.success) {
              message.success(res.data.msg);

              this.props.auth.history.push(`/switch-store`);
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((err) => {
            this.setState({
              freeSubscription: false,
            });
            message.error(err.message);
          });
      }
    );
  }
  pauseSubcription(status) {
    analytics.track("Pause Plan Clicked");
    this.setState(
      {
        pauseSubscription: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/stripe/pause_subscriptions",
          method: "post",
          withCredentials: true,
          data: {
            subscription_status: status,
          },
        })
          .then((res) => {
            this.setState({
              pauseSubscription: false,
            });
            if (res.data.success) {
              message.success(res.data.msg);
              this.props.auth.history.push(`/switch-store`);
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((err) => {
            this.setState({
              pauseSubscription: false,
            });
            message.error(err.message);
          });
      }
    );
  }
  componentDidMount() {
    this.loadData();
  }

  cancelPlan(currentPlan) {
    try {
      if (currentPlan.plan_type === "paid") {
        if (
          (currentPlan.plan_status === "active" ||
            currentPlan.plan_status === "trialing") &&
          currentPlan.cancellation_scheduled == 1
        ) {
          return (
            <div className="fifth-line">
              <Button
                disabled
                // onClick={() => {
                //   this.cancelSubcription();
                // }}
                loading={this.state.loadingSubscription}
              >
                Cancellation Scheduled
              </Button>
            </div>
          );
        }

        if (
          currentPlan.plan_status === "active" ||
          currentPlan.plan_status === "trialing"
        ) {
          return (
            <div className="fifth-line">
              {this.state.current_plan &&
              this.state.current_plan.public === 0 ? (
                <Tooltip title="Contact to Admin Team">
                  <Button disabled>Cancel Subscription</Button>
                </Tooltip>
              ) : (
                <Popconfirm
                  className="custom-popconfirm"
                  placement="right"
                  title="Are you sure you want to cancel the Subscription?"
                  onConfirm={() => {
                    this.cancelSubcription();
                  }}
                  onCancel={null}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button loading={this.state.loadingSubscription}>
                    Cancel Subscription
                  </Button>
                </Popconfirm>
              )}
            </div>
          );
        }
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  }
  render() {
    if (this.state.loading) {
      return (
        <Row style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </Row>
      );
    }
    if (!this.state.loading && this.state.error) {
      return (
        <Row>
          <Alert
            message="There was an error"
            description={this.state.errorMsg}
            type="error"
          />
        </Row>
      );
    }

    if (this.state.cancelPlan) {
      return <Redirect to="/switch-store?plan" />;
    }

    return (
      <>
        <div className="planandbilldetailcontainer">
          {this.state.current_plan.plan_status === "past_due" && (
            <div className="overdue-bar-wraper">
              <div className="overdue-bar">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="62"
                  viewBox="0 -960 960 960"
                  width="62"
                >
                  <path d="M160-200v-80h80v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v280h80v80H160ZM480-80q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80Zm-40-360h80v-200h-80v200Zm40 120q17 0 28.5-11.5T520-360q0-17-11.5-28.5T480-400q-17 0-28.5 11.5T440-360q0 17 11.5 28.5T480-320Z" />
                </svg>
                Urgent Notice: Your current plan payment is overdue. Please
                ensure immediate payment for uninterrupted services.{" "}
                <Button
                  onClick={() => this.setState({ editPaymentModal: true })}
                >
                  Update Payment Details
                </Button>
              </div>
            </div>
          )}

          <div className="uppercontainer">
            <div
              className={`currentplan-container ${
                this.state.current_plan.plan_status === "past_due"
                  ? "overdue"
                  : ""
              }`}
            >
              <div className="firstline">
                <div>
                  Plan:{" "}
                  <span className="plan">
                    {this.state.current_plan.plan_name}
                  </span>
                </div>
                <div>
                  <div className="plan-status-wrap">
                    Status:{" "}
                    <p
                      style={{
                        color: `${
                          planStatusColor[this.state.current_plan.plan_status]
                        }`,
                      }}
                    >
                      {planStatus[this.state.current_plan.plan_status]}
                    </p>{" "}
                  </div>
                  {this.state.current_plan.plan_name !== "free" &&
                  this.state.current_plan.plan_status !== "past_due" &&
                  this.state.current_plan.plan_status !== "trialing"
                    ? "Renewal:"
                    : this.state.current_plan.plan_status === "trialing"
                    ? "Trial Ending:"
                    : ""}{" "}
                  <span>
                    {this.state.current_plan.renewal_date &&
                    this.state.current_plan.plan_status !== "past_due"
                      ? moment(this.state.current_plan.renewal_date).format(
                          "DD MMM, YYYY"
                        )
                      : null}
                  </span>
                </div>
              </div>
              {this.state.current_plan.plan_name &&
              this.state.current_plan.plan_name.toLowerCase() !== "free" ? (
                <>
                  <div className="secline">
                    <span className="cost">
                      {this.state.current_plan.currency === "USD" ? "$" : "₹"}{" "}
                      {this.state.current_plan.amount}
                    </span>
                    /{" "}
                    {this.state.current_plan.interval === "month"
                      ? "month"
                      : "year"}
                  </div>
                  {/* <div className="third-line">
                    {this.state.current_plan.interval === "month"
                      ? "paid monthly"
                      : "paid yearly"}
                  </div> */}
                </>
              ) : (
                <>
                  <div className="free-line">Free Forever</div>
                  {/* <div className="free-line-bottom">Forever</div> */}
                </>
              )}
              <div className="forth-line">
                {this.state.current_plan &&
                this.state.current_plan.public === 0 ? (
                  <Tooltip title="Contact to Admin Team">
                    <Button disabled>Manage Plan</Button>
                  </Tooltip>
                ) : (
                  <Button
                    // onClick={() =>
                    //   this.setState({ showUpgradePlanModal: true }, () =>
                    //     analytics.track("Upgrade Plan Clicked")
                    //   )
                    // }
                    onClick={() =>
                      window.location.assign(
                        `${process.env.REACT_APP_NEW_APP}/plans`
                      )
                    }
                  >
                    Manage Plan
                  </Button>
                )}
              </div>

              {this.state.current_plan.plan_status === "past_due" && (
                <div className="six-line">
                  <Button
                    className="red-btn"
                    loading={this.state.freeSubscription}
                    onClick={() => this.cancelSubcription(1)}
                  >
                    Cancel Now
                  </Button>

                  {this.state.current_plan.collection_pause === 1 && (
                    <Button
                      loading={this.state.pauseSubscription}
                      onClick={() => this.pauseSubcription("resume")}
                    >
                      Resume Subscription
                    </Button>
                  )}

                  {this.state.current_plan.collection_pause === 0 && (
                    <Button
                      className="orange-btn"
                      loading={this.state.pauseSubscription}
                      onClick={() => this.pauseSubcription("pause")}
                    >
                      Pause Subscription
                    </Button>
                  )}
                </div>
              )}

              {this.cancelPlan(this.state.current_plan)}
            </div>
            {this.state.payment_method.card_type ? (
              <div className="payment-method-container">
                <div className="first-line">Payment method</div>
                <div className="sec-line">How you pay for your plan.</div>
                <div className="details-container">
                  <div className="item-first">
                    {this.creditCardHandler(
                      this.state.payment_method.card_type
                    )}
                  </div>
                  <div className="item-sec">
                    <div className="sec-1">
                      <span
                        style={{ fontWeight: 600, textTransform: "uppercase" }}
                      >
                        {" "}
                        {this.state.payment_method.card_type}{" "}
                      </span>
                      ending in{" "}
                      <span
                        style={{ fontWeight: 600, textTransform: "uppercase" }}
                      >
                        {" "}
                        {this.state.payment_method.card_ending}
                      </span>
                    </div>
                    <div className="sec-2">
                      Expiry {this.state.payment_method.card_valid_upto}
                    </div>
                    <div className="sec-3">
                      {this.state.payment_method.customer_details.email}
                    </div>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setState({ editPaymentModal: true })}
                    className="item-third"
                  >
                    {/* <span className="iconContainer"> */}
                    <EditiCON />
                    {/* </span> */}
                    Update
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div>
            <div className="table-cointainer">
              <Table
                rowKey={(row, i) => i}
                columns={columns}
                rowClassName={(record, index) =>
                  record.status === true ? "" : "row-red"
                }
                dataSource={this.state.invoice_list}
                // onChange={(page) => this.goToPage(page)}
                pagination={false}
                width="500px"
              />
            </div>
          </div>
        </div>
        <div className="bottom-copy">
          @ Copyright {new Date().getFullYear()}
        </div>

        {this.state.showUpgradePlanModal && (
          <AppSubscriptionPlan
            isFreePlan={
              this.state.current_plan &&
              this.state.current_plan.plan_type === "free"
                ? true
                : false
            }
            onClose={() => this.setState({ showUpgradePlanModal: false })}
            current_plan={this.state.current_plan}
            upgradable_plans={this.state.upgradable_plans}
            upgradePlan={true}
          />
        )}

        {this.state.editPaymentModal && (
          <EditPaymentModal
            loadData={() => this.loadData()}
            setState={(state) => this.setState(state)}
          />
        )}
      </>
    );
  }
}
