import React, { Component } from "react";
import { Input, Form, Icon, Select, Upload, Button } from "antd";
import Header from "../../../../Header";
import { ReactComponent as individualican } from "../../../../../../assets/images/icons/individualican.svg";
import { ReactComponent as arrowican } from "../../../../../../assets/images/icons/arrow.svg";
import { ReactComponent as uploadican } from "../../../../../../assets/images/icons/upload.svg";
import { ReactComponent as rightican } from "../../../../../../assets/images/icons/right.svg";
import { ReactComponent as GreenTick } from "../../../../../../assets/images/icons/green-tick.svg";
import "intl-tel-input/build/css/intlTelInput.css";
import IntlTelInput from "react-intl-tel-input-v2";
import ReactPhoneInput from "react-phone-input-2";

import { allStates, allCities } from "./../../../../../../Helpers/countryList";

const { Option } = Select;
const Dragger = Upload.Dragger;

class Profiledetail extends Component {
  state = {
    selectedState: "",
    selectedCity: "",
    citydropdown: [],
    arrow2: false,
    arrow: false,
    country: "India",
    numbers:
      this.props.questions && this.props.questions.phonecall
        ? this.props.questions.phonecall
        : "",
    dialCode: "91",
    individual_name:
      this.props.kycData && this.props.kycData.individual_name
        ? this.props.kycData.individual_name
        : "",
  };

  // Function handler to update the selected state
  stateChange(e) {
    let name = allStates.indexOf(e);
    let citiesarr = allCities[name].split(" | ");
    this.setState({
      ...this.state,
      selectedState: e,
      citydropdown: citiesarr,
      // selectedCity: "",
      arrow2: !this.state.arrow2,
    });
  }

  // Function to handle the state change for the city input
  cityChange(e) {
    this.setState({
      ...this.state,
      selectedCity: e,
      arrow: !this.state.arrow,
    });
  }

  btnHandler() {
    this.setState({ arrow2: !this.state.arrow2 });
  }
  cityHandler() {
    this.setState({ arrow: !this.state.arrow });
  }

  numberValueHandler(dialcode, number) {
    let val = `${dialcode}` + `${!!number && number !== 0 ? number : ""}`;

    return val;
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    // const inputProps = {
    //   placeholder: "Enter Your Phone Number",
    // };
    // const value = {
    //   iso2: this.props.questions.iso2,
    //   dialCode: this.props.questions.dialCode,
    //   phone: this.props.questions.phonecall,
    // };

    // const inputProps2 = {
    //   placeholder: "Enter support phone number",
    // };
    // const value2 = {
    //   iso2: this.props.questions.iso2,
    //   dialCode: this.props.questions.dialCode,
    //   phone: this.props.questions.phonecall,
    // };

    return (
      <>
        {this.props.currentStep === 1 && (
          <Header
            title="Let Us Know More About You"
            subtitle="Let’s get done with completing & verifying your profile info"
          />
        )}

        {this.props.kycType === "Organisation"
          ? this.props.currentStep === 1 && (
              <p className="gst-individual">
                <span>
                  <Icon component={individualican} />
                </span>
                <span className="flag">
                  I am an <span className="registered-india">Organisation</span>{" "}
                  operating <span className="registered-india">with GST</span>{" "}
                  in India
                </span>
              </p>
            )
          : this.props.currentStep === 1 && (
              <p className="gst-individual">
                <span>
                  <Icon component={individualican} />
                </span>
                <span className="flag">
                  I am an <span className="registered-india">Individual</span>{" "}
                  operating{" "}
                  <span className="registered-india">without GST</span> in India
                </span>
              </p>
            )}

        {this.props.currentStep === 1 && (
          <div className="top-header-steps">
            <p>
              Step {this.props.currentStep} out of {this.props.totalSteps}
            </p>
            <button
              className="back-btn"
              onClick={() => this.props.toggleBackButton()}
            >
              <span>
                <Icon component={arrowican} />
              </span>
              Previous Step
            </button>
          </div>
        )}

        <Form className="profile-form">
          {this.props.currentStep === 1 ? (
            <div
              style={{
                display: this.props.currentStep === 1 ? "block" : "none",
              }}
            >
              {this.props.kycType === "Individual" && (
                <>
                  <Form.Item label="Full Name">
                    {getFieldDecorator("individual_name", {
                      initialValue: this.state.individual_name,
                      rules: [
                        {
                          required: true,
                          message: "Please enter your full name!",
                        },
                      ],
                    })(<Input placeholder="Please enter your full name" />)}
                  </Form.Item>
                </>
              )}

              {this.props.kycType === "Organisation" && (
                <>
                  <Form.Item label="Business Name">
                    {getFieldDecorator("org_name", {
                      initialValue: this.props.kycData1.org_name,
                      rules: [
                        {
                          required: true,
                          message: "Please enter your Name!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter your Business/Organisation Name"
                        onChange={(e) =>
                          this.props.previousdatastoe(
                            "org_name",
                            e.target.value
                          )
                        }
                        value={this.props.kycData1.org_name}
                      />
                    )}
                  </Form.Item>
                </>
              )}

              <Form.Item
                label="Building/ Apartment/ Street Number"
                className="address-input"
              >
                {getFieldDecorator("address[line1]", {
                  initialValue: this.props.kycData1.line1,
                  rules: [
                    {
                      required: true,
                      message: "Please enter your address!",
                    },
                  ],
                })(
                  <Input
                    placeholder="Enter address line 1"
                    onChange={(e) =>
                      this.props.previousdatastoe("line1", e.target.value)
                    }
                    value={this.props.kycData1.line1}
                  />
                )}
              </Form.Item>

              <Form.Item
                label="Area/ Locality/ Society"
                className="address-input"
              >
                {getFieldDecorator("address[line2]", {
                  initialValue: this.props.kycData1.line2,
                  rules: [
                    {
                      required: true,
                      message: "Please enter address line 2!",
                    },
                  ],
                })(
                  <Input
                    placeholder="Enter address line 2"
                    onChange={(e) =>
                      this.props.previousdatastoe("line2", e.target.value)
                    }
                    value={this.props.kycData1.line2}
                  />
                )}
              </Form.Item>

              <div className="country-wrapper">
                <div className="state-wrapper">
                  <Form.Item label="State">
                    {getFieldDecorator("address[state]", {
                      initialValue: this.state.selectedState,
                      rules: [
                        {
                          required: true,
                          message: "Please enter your state!",
                        },
                      ],
                    })(
                      <Select
                        placeholder="Select State"
                        showSearch
                        value={this.state.selectedState}
                        onChange={(e) => this.stateChange(e)}
                        className="state-select"
                        filterOption={(input, option) => {
                          return (
                            option.key
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            option.props.value
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                        onDropdownVisibleChange={() => this.btnHandler()}
                        suffixIcon={
                          <svg
                            style={{
                              transition: "all 0.5s",
                              transform: `${
                                this.state.arrow2
                                  ? "rotateX(180deg)"
                                  : "rotateX(0deg)"
                              }`,
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="8"
                            viewBox="0 0 16 8"
                          >
                            <g
                              id="Polygon_33"
                              data-name="Polygon 33"
                              transform="translate(16 8) rotate(180)"
                              fill={`${this.state.arrow2 ? "898989" : "none"}`}
                            >
                              <path d="M8,0l8,8H0Z" stroke="none" />
                              <path
                                d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                                stroke="none"
                                fill="#898989"
                              />
                            </g>
                          </svg>
                        }
                      >
                        {/* Add map for option */}
                        {allStates.map((item, index) => {
                          return (
                            <Option
                              value={item}
                              key={index}
                              className="profile-dropdown"
                            >
                              {item}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </div>

                <div className="city-wrapper">
                  <Form.Item label="City">
                    {getFieldDecorator("address[city]", {
                      initialValue: this.state.selectedCity,
                      rules: [
                        {
                          required: true,
                          message: "Please enter your city!",
                        },
                      ],
                    })(
                      <Select
                        placeholder="Select City"
                        value={this.state.selectedCity}
                        showSearch
                        onChange={(e) => this.cityChange(e)}
                        className="city-select"
                        onDropdownVisibleChange={() => this.cityHandler()}
                        filterOption={(input, option) => {
                          return (
                            option.key
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            option.props.value
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                        suffixIcon={
                          <svg
                            style={{
                              transition: "all 0.5s",
                              transform: `${
                                this.state.arrow
                                  ? "rotateX(180deg)"
                                  : "rotateX(0deg)"
                              }`,
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="8"
                            viewBox="0 0 16 8"
                          >
                            <g
                              id="Polygon_33"
                              data-name="Polygon 33"
                              transform="translate(16 8) rotate(180)"
                              fill={`${this.state.arrow ? "898989" : "none"}`}
                            >
                              <path d="M8,0l8,8H0Z" stroke="none" />
                              <path
                                d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                                stroke="none"
                                fill="#898989"
                              />
                            </g>
                          </svg>
                        }
                      >
                        {this.state.citydropdown.length > 0
                          ? this.state.citydropdown.map((item, index) => {
                              return (
                                <Option
                                  value={item}
                                  key={index}
                                  className="profile-dropdown"
                                >
                                  {item}
                                </Option>
                              );
                            })
                          : null}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </div>

              <div className="city-pincode-wrapper">
                <div className="india-country">
                  <Form.Item label="Country">
                    <Input
                      placeholder="Country"
                      value={this.state.country}
                      className="placeholder-country-input"
                    />
                  </Form.Item>
                </div>

                <div className="pincode-wrapper">
                  <Form.Item label="Pincode">
                    {getFieldDecorator("address[pincode]", {
                      initialValue: this.props.kycData1.pincode,
                      rules: [
                        {
                          required: true,
                          message: "Please enter your pincode!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Pincode"
                        onChange={(e) =>
                          this.props.previousdatastoe("pincode", e.target.value)
                        }
                        value={this.props.kycData1.pincode}
                      />
                    )}
                  </Form.Item>
                </div>
              </div>

              {this.props.kycType === "Individual" && (
                // <Form.Item
                //   label={<span className="label-phone-no">Phone Number</span>}
                //   className="phone-number"
                // >
                //   {
                //     <IntlTelInput
                //       className="phone-number-value"
                //       inputClassName="form-control"
                //       autoHideDialCode
                //       preferredCountries={["in"]}
                //       inputProps={inputProps}
                //       value={value}
                //       onChange={(val) => this.props.phonecallChangeHandler(val)}
                //     />
                //   }
                // </Form.Item>

                // <Form.Item
                //   label={<span className="label-phone-no">Phone-number</span>}
                //   className="input-phone-txt phone-input-validation"
                // >
                //   <p className="dialCode-txt">{this.state.dialCode}</p>
                //   {getFieldDecorator("phone", {
                //     initialValue: this.state.numbers,
                //     rules: [
                //       {
                //         max: 10,
                //         required: true,
                //         message: "Please enter valid phone number!",
                //       },
                //     ],
                //   })(
                //     <Input
                //       placeholder="Enter phone number"
                //       type="number"
                //       disabled={true}
                //     />
                //   )}
                // </Form.Item>
                <>
                  <div className="phone-field-wraper">
                    {this.props.verifiedcontact ? (
                      <p className="already-verify-email">
                        <span className="save-ican">
                          <Icon component={rightican} />
                        </span>
                        Already verified
                      </p>
                    ) : this.props.phoneverified === 1 ? (
                      <div className="verified-div">
                        {" "}
                        <Icon component={GreenTick} />
                      </div>
                    ) : (
                      <Button
                        loading={this.props.numberOtpLoading}
                        className={
                          this.props.supportNumber2 &&
                          !!this.props.supportNumber2.length &&
                          this.props.supportNumber2.length > 9
                            ? "verify-phone2 anchor clickable"
                            : "verify-phone anchor clickable"
                        }
                        onClick={() => this.props.VerifyPhone()}
                      >
                        Verify
                      </Button>
                    )}

                    <Form.Item
                      label={
                        <span className="label-phone-no">
                          Support Phone Number
                        </span>
                      }
                      className="input-phone-txt phone-input-validation"
                    >
                      {/* <p className="dialCode-txt">{this.state.dialCode}</p> */}
                      {getFieldDecorator("support_phone", {
                        initialValue: this.numberValueHandler(
                          this.state.dialCode,
                          this.props.supportNumber2
                        ),
                        rules: [
                          {
                            max: 12,
                            required: true,
                            message: "Please enter valid phone number!",
                          },
                        ],
                      })(
                        // <Input
                        //   placeholder="Enter phone number"
                        //   onChange={(e) =>
                        //     this.props.handleSupportPhoneChange(
                        //       "contactno",
                        //       e.target.value
                        //     )
                        //   }
                        //   type="number"
                        //   disabled={this.props.phoneverified === 1 ? true : false}
                        //   value={this.props.supportNumber2}
                        // />
                        <ReactPhoneInput
                          placeholder="Enter phone number"
                          disableDropdown
                          countryCodeEditable={false}
                          enableSearchField
                          disabled={
                            this.props.phoneverified === 1 ? true : false
                          }
                          value={this.numberValueHandler(
                            this.state.dialCode,
                            this.props.supportNumber2
                          )}
                          onChange={(val, extra) =>
                            this.props.handleSupportPhoneChange({
                              value: val,
                              extraVal: extra,
                              field: "contactno",
                            })
                          }
                        />
                      )}
                    </Form.Item>
                  </div>

                  <div className="email-field-wraper">
                    {this.props.verifiedmsg ? (
                      <p className="already-verify-email">
                        <span className="save-ican">
                          <Icon component={rightican} />
                        </span>
                        Already verified
                      </p>
                    ) : this.props.emailverified === 1 ? (
                      <div className="verified-div ind-indv">
                        {" "}
                        <Icon component={GreenTick} />
                      </div>
                    ) : (
                      <Button
                        loading={this.props.emailOtpLoading}
                        className={
                          !!this.props.supportEmail2.length
                            ? "verify-phone2 anchor clickable"
                            : "verify-phone anchor clickable"
                        }
                        onClick={() => this.props.VerifyEmail()}
                      >
                        Verify
                      </Button>
                    )}

                    <Form.Item label="Support Email Address">
                      {getFieldDecorator("email", {
                        initialValue: this.props.supportEmail2,
                        rules: [
                          {
                            required: true,
                            message: "Please Enter support email address!",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Enter support email address"
                          onChange={(e) =>
                            this.props.handleSupportEmailChange(
                              "EmailInpute",
                              e.target.value
                            )
                          }
                          disabled={
                            this.props.emailverified === 1 ? true : false
                          }
                          value={this.props.supportEmail2}
                        />
                      )}
                    </Form.Item>
                  </div>
                </>
              )}

              {this.props.kycType === "Organisation" && (
                <>
                  {/* <a
                    href
                    className="verify-email"
                    onClick={() => this.props.toggleSupportNumberModal()}
                  >
                    Verify Number
                  </a>
                  <Form.Item
                    label={
                      <span className="label-phone-no">
                        Support Phone Number
                      </span>
                    }
                    className="phone-number"
                  >
                    {
                      <IntlTelInput
                        inputClassName="form-control"
                        autoHideDialCode
                        preferredCountries={["in"]}
                        inputProps={inputProps2}
                        value={value2}
                        onChange={(val) =>
                          this.props.phonecallChangeHandler(val)
                        }
                      />
                    }
                  </Form.Item> */}

                  <div className="phone-field-wraper">
                    {this.props.verifiedcontact ? (
                      <p className="already-verify-email">
                        <span className="save-ican">
                          <Icon component={rightican} />
                        </span>
                        Already verified
                      </p>
                    ) : this.props.phoneverified === 1 ? (
                      <div className="verified-div">
                        {" "}
                        <Icon component={GreenTick} />
                      </div>
                    ) : (
                      <>
                        <Button
                          loading={this.props.numberOtpLoading}
                          className={
                            this.props.supportNumber2 &&
                            !!this.props.supportNumber2.length &&
                            this.props.supportNumber2.length > 9
                              ? "verify-phone2 anchor clickable"
                              : "verify-phone anchor clickable"
                          }
                          onClick={() => this.props.VerifyPhone()}
                        >
                          Verify
                        </Button>
                      </>
                    )}

                    <Form.Item
                      label={
                        <span className="label-phone-no">
                          Support Phone Number
                        </span>
                      }
                      className="input-phone-txt phone-input-validation"
                    >
                      {/* <p className="dialCode-txt">{this.state.dialCode}</p> */}
                      {getFieldDecorator("support_phone", {
                        initialValue: this.numberValueHandler(
                          this.state.dialCode,
                          this.props.supportNumber2
                        ),
                        rules: [
                          {
                            max: 12,
                            required: true,
                            message: "Please enter valid phone number!",
                          },
                        ],
                      })(
                        <ReactPhoneInput
                          placeholder="Enter phone number"
                          disableDropdown
                          countryCodeEditable={false}
                          enableSearchField
                          disabled={
                            this.props.phoneverified === 1 ? true : false
                          }
                          value={this.numberValueHandler(
                            this.state.dialCode,
                            this.props.supportNumber2
                          )}
                          onChange={(val, extra) =>
                            this.props.handleSupportPhoneChange({
                              value: val,
                              extraVal: extra,
                              field: "contactno",
                            })
                          }
                        />
                      )}
                    </Form.Item>
                  </div>

                  <div className="email-field-wraper">
                    {this.props.verifiedmsg ? (
                      <p className="already-verify-email">
                        <span className="save-ican">
                          <Icon component={rightican} />
                        </span>
                        Already verified
                      </p>
                    ) : this.props.emailverified === 1 ? (
                      <div className="verified-div ind-indv">
                        {" "}
                        <Icon component={GreenTick} />
                      </div>
                    ) : (
                      <Button
                        loading={this.props.emailOtpLoading}
                        className={
                          !!this.props.supportEmail2.length
                            ? "verify-phone2 anchor clickable"
                            : "verify-phone anchor clickable"
                        }
                        onClick={() => this.props.VerifyEmail()}
                      >
                        Verify
                      </Button>
                    )}

                    <Form.Item label="Support Email Address">
                      {getFieldDecorator("email", {
                        initialValue: this.props.supportEmail2,
                        rules: [
                          {
                            required: true,
                            message: "Please Enter support email address!",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Enter support email address"
                          onChange={(e) =>
                            this.props.handleSupportEmailChange(
                              "EmailInpute",
                              e.target.value
                            )
                          }
                          disabled={
                            this.props.emailverified === 1 ? true : false
                          }
                          value={this.props.supportEmail2}
                        />
                      )}
                    </Form.Item>
                  </div>

                  <Form.Item label="GST Number">
                    {getFieldDecorator("org_gst", {
                      initialValue: this.props.kycData1.org_gst,
                      rules: [
                        {
                          required: true,
                          message: "Enter your GST Number",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter your GST Number"
                        onChange={(e) =>
                          this.props.previousdatastoe("org_gst", e.target.value)
                        }
                        value={this.props.kycData1.org_gst}
                      />
                    )}
                  </Form.Item>

                  <Form.Item
                    className="upload-file"
                    style={{ marginBottom: "-16px" }}
                  >
                    {getFieldDecorator("Gst_certificate", {
                      rules: [
                        {
                          required: true,
                          message: "Please upload GST Certificate document!",
                        },
                      ],
                    })(
                      <div className="ant-row ant-form-item">
                        <div className="ant-col ant-form-item-label upload-label">
                          <label className="ant-form-item-required">
                            GST Certificate(webp, jpeg, jpg, png, pdf)
                          </label>
                        </div>
                        <Dragger
                          accept=".webp,.jpeg,.jpg,.png,.pdf"
                          fileList={
                            this.props.files.gst_certificate
                              ? this.props.files.gst_certificate
                              : []
                          }
                          action={
                            process.env.REACT_APP_API_URL +
                            "/user/upload_kyc_doc"
                          }
                          withCredentials="true"
                          onChange={({ fileList }) =>
                            this.props.onFileUpload("gst_certificate", fileList)
                          }
                        >
                          <p className="ant-upload-drag-icon">
                            <Icon type="upload" /> Upload Document (Drop files
                            here or Click to Upload)
                          </p>
                        </Dragger>

                        <p className="aadhar-card-name">
                          {this.props.files.gst_certificate &&
                          this.props.files.gst_certificate &&
                          this.props.files.gst_certificate.length &&
                          this.props.files.gst_certificate[0].originFileObj
                            ? this.props.files.gst_certificate[0].originFileObj
                                .name
                            : null}{" "}
                        </p>

                        {this.props.files.gst_certificate &&
                        Object.keys(this.props.files.gst_certificate).length >
                          0 ? (
                          <button
                            onClick={() =>
                              this.props.onRemoveGstCertificate(
                                "gst_certificate"
                              )
                            }
                          >
                            X
                          </button>
                        ) : null}
                      </div>
                    )}
                  </Form.Item>

                  <Form.Item
                    label="Authorised Signatory’s PAN Card Number"
                    className="authorized_signatory"
                    style={{ marginTop: "4px" }}
                  >
                    {getFieldDecorator("org_authpan", {
                      initialValue: this.props.kycData1.org_authpan,
                      rules: [
                        {
                          required: true,
                          message:
                            "Enter Authorized Signatory’s PAN Card number",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter Authorized Signatory’s PAN Card number"
                        onChange={(e) =>
                          this.props.previousdatastoe(
                            "org_authpan",
                            e.target.value
                          )
                        }
                        value={this.props.kycData1.org_authpan}
                      />
                    )}
                  </Form.Item>

                  <Form.Item className="upload-file">
                    {getFieldDecorator("org_pan", {
                      rules: [
                        {
                          required: true,
                          message: "Please upload pan card document!",
                        },
                      ],
                    })(
                      <div className="ant-row ant-form-item">
                        <div className="ant-col ant-form-item-label upload-label">
                          <label className="ant-form-item-required">
                            Authorized Signatory’s PAN Card(webp, jpeg, jpg,
                            png, pdf)
                          </label>
                        </div>
                        <Dragger
                          accept=".webp,.jpeg,.jpg,.png,.pdf"
                          fileList={
                            this.props.files.signatory_pan_card
                              ? this.props.files.signatory_pan_card
                              : []
                          }
                          action={
                            process.env.REACT_APP_API_URL +
                            "/user/upload_kyc_doc"
                          }
                          withCredentials="true"
                          onChange={({ fileList }) =>
                            this.props.onFileUpload(
                              "signatory_pan_card",
                              fileList
                            )
                          }
                        >
                          <p className="ant-upload-drag-icon">
                            <Icon type="upload" /> Upload Document (Drop files
                            here or Click to Upload)
                          </p>
                        </Dragger>

                        <p className="aadhar-card-name">
                          {this.props.files.signatory_pan_card &&
                          this.props.files.signatory_pan_card &&
                          this.props.files.signatory_pan_card.length &&
                          this.props.files.signatory_pan_card[0].originFileObj
                            ? this.props.files.signatory_pan_card[0]
                                .originFileObj.name
                            : null}{" "}
                        </p>

                        {this.props.files.signatory_pan_card &&
                        Object.keys(this.props.files.signatory_pan_card)
                          .length > 0 ? (
                          <button
                            onClick={() =>
                              this.props.onremovesignaturepencard(
                                "signatory_pan_card"
                              )
                            }
                          >
                            X
                          </button>
                        ) : null}
                      </div>
                    )}
                  </Form.Item>
                </>
              )}

              {this.props.kycType === "Individual" && (
                <>
                  <Form.Item label="PAN Card Number">
                    {getFieldDecorator("pan_number", {
                      initialValue: this.props.kycData1.pan_number,
                      rules: [
                        {
                          required: true,
                          message: "Enter your PAN number!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter your PAN number"
                        onChange={(e) =>
                          this.props.previousdatastoe(
                            "pan_number",
                            e.target.value
                          )
                        }
                        value={this.props.kycData1.pan_number}
                      />
                    )}
                  </Form.Item>

                  <Form.Item className="upload-file">
                    {getFieldDecorator("pan_card", {
                      rules: [
                        {
                          required: true,
                          message: "Please upload pan card document!",
                        },
                      ],
                    })(
                      <div className="ant-row ant-form-item">
                        <div className="ant-col ant-form-item-label upload-label">
                          <label className="ant-form-item-required">
                            {this.props.kycType === "Organisation"
                              ? "Business "
                              : ""}
                            PAN Card(webp, jpeg, jpg, png, pdf)
                          </label>
                        </div>
                        <Dragger
                          accept=".webp,.jpeg,.jpg,.png,.pdf"
                          fileList={
                            this.props.files.pan_card
                              ? this.props.files.pan_card
                              : []
                          }
                          action={
                            process.env.REACT_APP_API_URL +
                            "/user/upload_kyc_doc"
                          }
                          withCredentials="true"
                          onChange={({ fileList }) =>
                            this.props.onFileUpload("pan_card", fileList)
                          }
                          onRemove={() =>
                            this.props.onremovepencard("pan_card")
                          }
                        >
                          <p className="ant-upload-drag-icon">
                            <Icon component={uploadican} /> Upload Document
                            (Drop files here or Click to Upload)
                          </p>
                        </Dragger>

                        <p className="aadhar-card-name">
                          {this.props.files.pan_card &&
                          this.props.files.pan_card &&
                          this.props.files.pan_card.length &&
                          this.props.files.pan_card[0].originFileObj
                            ? this.props.files.pan_card[0].originFileObj.name
                            : null}{" "}
                        </p>
                        {this.props.files.pan_card &&
                        Object.keys(this.props.files.pan_card).length > 0 ? (
                          <button
                            onClick={() =>
                              this.props.onremovepencard("pan_card")
                            }
                          >
                            X
                          </button>
                        ) : null}
                      </div>
                    )}
                  </Form.Item>
                </>
              )}
            </div>
          ) : null}
        </Form>
      </>
    );
  }
}

export default Form.create()(Profiledetail);
