import React from "react";
import { Icon, Tooltip } from "antd";
import { ReactComponent as copyIcon } from "../../../../assets/images/icons/copy-icon1.svg";

import "./index.scss";

class VfIdCopyBadge extends React.Component {
  state = {
    copySuccess: this.props.defaultText ? this.props.defaultText : "Copy",
  };

  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand("copy");
    e.target.focus();
    this.setState({
      copySuccess: "Copied!",
    });
  };

  render() {
    return (
      <span>
        {document.queryCommandSupported("copy") && (
          <span>
            <Tooltip
              placement="right"
              title={this.state.copySuccess}
              onVisibleChange={(visible) =>
                !visible &&
                this.setState({
                  copySuccess: this.props.defaultText
                    ? this.props.defaultText
                    : "Copy",
                })
              }
            >
              {this.props.children ? (
                this.props.children(this.copyToClipboard)
              ) : (
                <div className="click-id-badge" onClick={this.copyToClipboard}>
                  {this.props.text}: {!!this.props.hashSign ? "#" : null}
                  {this.props.textToCopy}
                  <Icon component={copyIcon} />
                </div>
              )}
            </Tooltip>
            <textarea
              style={{
                width: 0,
                height: "0 !important",
                padding: 0,
                margin: 0,
                borderColor: "transparent",
                lineHeight: 0,
                outline: "none",
                background: "transparent",
              }}
              ref={(textarea) => (this.textArea = textarea)}
              value={this.props.textToCopy}
              readOnly
            />
          </span>
        )}
      </span>
    );
  }
}

export default VfIdCopyBadge;
