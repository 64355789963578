import React, { Component } from "react";
import { Modal, Button, Select, Input, message } from "antd";
import "./index.scss";
import DisputeModals from "../../Shipments/DisputeModals";
import { SHOPIFY_NAME } from "../../../../constants";
import axios from "axios";
const { Option } = Select;

class AddDisputeModal extends Component {
  state = {
    arrow2: "",
    selectedShipment: "",
    type: undefined,
    id: "",
    loading: false,
    shipments: [],
    selectShipment: "",
    toggleDisputeData: undefined,
  };

  //this function used for  select option  in escalation modal
  handleChange(key, value) {
    this.setState(
      {
        ...this.state,
        [key]: value,
      },
      () => this.setState({ arrow2: !this.state.arrow2 })
    );
  }

  // this function used for input box
  handleChange2(key, value) {
    this.setState({
      ...this.state,
      [key]: value,
    });
  }

  // this function used for open Complete Escalation Form modal
  toggleDisputeModal() {
    this.setState({
      toggleDisputeData: undefined,
    });
  }

  // this function used for open Add New Escalation modal if shipmentid is not available
  toggleIfNotShipment() {
    if (this.state.selectShipment === false) {
      this.setState({
        toggleDisputeData: this.state.shipments[0],
      });
      this.props.toggleAddDisputeModal();
    }
  }

  // this function used for open Add New Escalation modal if shipmentid  available
  toggleIfShipment() {
    this.setState({
      toggleDisputeData: this.state.shipments.find(
        (shipment) => shipment.awb === this.state.selectedShipment
      ),
    });
    this.props.toggleAddDisputeModal();
  }

  //this function used to fetch shipment detail and show detail on Complete Escalation Form
  onCountinue() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/disputes/get_shipments",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            type: this.state.type,
            search_id: this.state.id,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                shipments: res.data.shipments,
                loading: false,
                selectShipment: res.data.shipments.length > 1 ? true : false,
              });
              this.toggleIfNotShipment();
            } else {
              this.setState({
                loading: false,
              });
              message.warning(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
            message.warning(e.message);
          });
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible === true && this.props.visible === false) {
      this.setState({
        selectedShipment: "",
        type: undefined,
        id: "",
        loading: false,
        shipments: [],
        selectShipment: "",
        toggleDisputeData: undefined,
      });
    }
  }
  render() {
    const shipments = this.state.shipments;
    return (
      <Modal
        title="Add New Escalation"
        visible={this.props.visible}
        className="add-dispute-modal"
        closable={false}
        maskClosable={false}
        onCancel={() => this.props.toggleAddDisputeModal()}
        footer={
          <div>
            <Button
              style={{
                color: "white",
                background: `${
                  !!this.state.id && !!this.state.type ? `#1370FC` : `lightblue`
                }`,
                pointerEvents: `${
                  !!this.state.id && !!this.state.type ? `visible` : `none`
                }`,
              }}
              onClick={() =>
                this.state.selectShipment
                  ? this.toggleIfShipment()
                  : this.onCountinue()
              }
              loading={this.props.loading}
            >
              Continue
            </Button>
            <Button onClick={this.props.toggleAddDisputeModal}>
              Cancel & Close
            </Button>
          </div>
        }
      >
        <>
          <div className="dispute-form">
            <div className="dispute-reason">
              <p>Type of Order ID:</p>
              <Select
                onDropdownVisibleChange={(val) =>
                  this.setState({ arrow2: val })
                }
                suffixIcon={
                  <svg
                    style={{
                      transition: "all 0.5s",
                      transform: `${
                        this.state.arrow2 ? "rotateX(180deg)" : "rotateX(0deg)"
                      }`,
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_33"
                      data-name="Polygon 33"
                      transform="translate(16 8) rotate(180)"
                      fill={`${this.state.arrow2 ? "898989" : "none"}`}
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#898989"
                      />
                    </g>
                  </svg>
                }
                placeholder={`Select out of WayBill Number, vF Order Id or ${SHOPIFY_NAME} ID`}
                onChange={(val) => this.handleChange("type", val)}
                value={this.state.type}
                style={{ width: 200 }}
                bordered={false}
              >
                <Option className="op" value="awb">
                  WayBill Number
                </Option>
                <Option className="op" value="order_vfid">
                  vF Order Id
                </Option>
                <Option className="op" value="shopify_orderid">
                  {` ${SHOPIFY_NAME} Order ID`}
                </Option>
              </Select>
            </div>
            <div className="dispute-reason">
              <p>Enter ID:</p>
              <Input
                rows={4}
                value={this.state.id}
                placeholder="Please enter correct ID"
                onChange={(e) => this.handleChange2("id", e.target.value)}
              />
            </div>
            {this.state.selectShipment && (
              <div className="dispute-reason">
                <p>Select Shipment:</p>
                <Select
                  placeholder="Select Shipment"
                  onChange={(val) => this.handleChange("selectedShipment", val)}
                  style={{ width: 200 }}
                >
                  {shipments.map((shipment) => (
                    <Option value={shipment.awb}>{shipment.awb}</Option>
                  ))}
                </Select>
              </div>
            )}
          </div>
          <DisputeModals
            {...this.props}
            data={this.state.toggleDisputeData}
            resetModal={() => this.toggleDisputeModal()}
          />
        </>
      </Modal>
    );
  }
}

export default AddDisputeModal;
