import React from "react";
import { Modal } from "antd";

import { Player } from "video-react";

class VideoModal extends React.Component {
  state = {
    visible: false,
    videoPlaying: false,
  };
  render() {
    return (
      <Modal
        title={this.props.title}
        className="video-modal"
        visible={this.props.visible}
        // onOk={handleOk}
        footer={null}
        onCancel={() => this.props.onClose()}
      >
        <video controls muted autoPlay src={this.props.link} />
      </Modal>
    );
  }
}

export default VideoModal;
