import React, { Component } from "react";
import "./index.scss";

class Variantnav extends Component {
  render() {
    return (
      <div className="variant-nav-data fullorder_data">
        <div className="variant-nav">
          <div className="ordfill">
            <div className="ordfillwrap">
              <div className="left" style={{ marginLeft: "7px" }}>
                <div
                  style={{
                    color: "#000",
                    fontSize: "12px",
                    letterSpacing: "0.032em",
                    lineHeight: "24px",
                  }}
                >
                  <span>Products</span>
                  <span className="arrow-txt">&gt;</span>
                  <span className="variant-mapping-txt"> Store Products</span>

                  <span className="arrow-txt">&gt;</span>

                  <span className="variant-mapping-txt">
                    {" "}
                    {this.props.bundle
                      ? "Product Bundle Variant Mapping"
                      : "View Variant Map"}{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Variantnav;
