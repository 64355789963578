import React, { Component } from "react";
import { Menu, Dropdown, Icon, Badge, message, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./index.scss";
import axios from "axios";
import moment from "moment";
import { ReactComponent as bell } from "../../../assets/images/icons/bell.svg";
import rightIcon from "../../../assets/images/icons/Polygon 42.png";
import { ReactComponent as bell_colored } from "../../../assets/images/icons/bell_colored.svg";
import { ReactComponent as notificationArrow } from "../../../assets/images/icons/notification-arrow.svg";

class Notifications extends Component {
  state = {
    loading: false,
    page: 1,
    pageSize: 10,
    error: false,
    visible: false,
    notification_data: [],
    total: 0,
    total_unread: 0,
  };

  fetchNoticfications() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL + "/Notifications/get_notifications",
          method: "post",
          data: {
            storeid: this.props.auth.store.id,
            page: this.state.page,
            pageSize: this.state.pageSize,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState((state) => ({
                loading: false,
                notification_data: res.data.notification_data,
                page: state.page,
                total_unread: res.data.total_unread,
                total: res.data.total,
              }));
            }
          })
          .catch((e) => {
            this.setState({
              loading: false,
              error: true,
            });
          });
      }
    );
  }

  closeNotifications(ids) {
    axios({
      url:
        process.env.REACT_APP_API_URL +
        "/Notifications/mark_as_read_notifications",
      method: "post",
      data: {
        storeid: this.props.auth.store.id,
        notification_id: ids,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState((state) => ({
            total_unread: 0,
            notification_data: state.notification_data.map((n) => {
              const id = n.notification_id["$oid"];

              if (ids.indexOf(id) === -1) {
                return n;
              } else {
                return {
                  ...n,
                  status: 1,
                };
              }
            }),
          }));
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  }

  markAllRead() {
    axios({
      url: process.env.REACT_APP_API_URL + "/Notifications/mark_all_read",
      method: "post",
      data: {
        storeid: this.props.auth.store.id,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState((state) => ({
            total_unread: 0,
            notification_data: state.notification_data.map((n) => {
              return {
                ...n,
                status: 1,
              };
            }),
          }));
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  }

  handleMenuClick(e) {
    if (e.key === "footer") {
      this.setState({
        visible: true,
      });
    }
  }

  onVisibleChange(e) {
    this.setState({
      visible: e,
    });
  }

  componentDidMount() {
    this.fetchNoticfications();
  }

  render() {
    let menu;

    if (this.state.error) {
      menu = (
        <Menu>
          <Menu.Item>There was an error!</Menu.Item>
        </Menu>
      );
    }
    if (!this.state.error) {
      if (this.state.notification_data.length > 0) {
        menu = (
          <Menu
            className="notifications-dropdown"
            onClick={(e) => this.handleMenuClick(e)}
          >
            <Menu.Item key="header" className="notification-header">
              <p>Notifications</p>
              <button onClick={() => this.markAllRead()}>
                Mark All As Read
              </button>
            </Menu.Item>
            {this.state.notification_data.map((n, i) => {
              const ahrefProps = {
                href: n.action.actiondata,
                className: n.status === 1 ? "unread" : "",
              };

              if (n.action.actiontype === "REDIRECT_NEWTAB") {
                ahrefProps.target = "_blank";
                ahrefProps.rel = "noreferrer noopener";
              }
              return (
                <Menu.Item key={i} className="notification">
                  <a
                    onClick={() =>
                      this.closeNotifications([n.notification_id["$oid"]])
                    }
                    {...ahrefProps}
                    style={{ background: n.status === 0 ? "#f5faff" : null }}
                  >
                    <div className="img-wraper">
                      <img
                        src={n.icon}
                        alt="Notification Icon"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div>
                      {/* show tooltip if text count is greater then 24 characters */}
                      {n.title.length > 24 ? (
                        <p
                          className={`notification-heading ${
                            n.status === 0 ? `notification-header-color` : null
                          }`}
                        >
                          {n.title.slice(0, 24)}...
                        </p>
                      ) : (
                        <p
                          className={`notification-heading ${
                            n.status === 0 ? `notification-header-color` : null
                          }`}
                        >
                          {n.title}
                        </p>
                      )}

                      <p
                        style={{
                          fontSize: "12px",
                          letterSpacing: "0.032em",
                          color: n.status === 0 ? "#212121" : null,
                        }}
                      >
                        {n.subtitle.length > 30
                          ? n.subtitle.slice(0, 30) + `...`
                          : n.subtitle}
                      </p>

                      <p
                        style={{
                          fontSize: "10px",
                          lineHeight: "16px",
                          paddingTop: "3px",
                          color: n.status === 0 ? "#212121" : null,
                          letterSpacing: "0.032em",
                        }}
                      >
                        {moment(n.date_created).format("MMM DD, YYYY")} |{" "}
                        {moment(n.date_created).format("h:mma")}
                      </p>
                    </div>
                  </a>
                </Menu.Item>
              );
            })}
            <Menu.ItemGroup
              className="notification-footer"
              style={{ minHeight: "40px", padding: "0px 16px 8px" }}
            >
              {this.state.loading && (
                <Menu.Item
                  key="loader"
                  style={{
                    textAlign: "center",
                    listStyle: "none",
                    color: "#2198FF",
                    letterSpacing: "0.032em",
                  }}
                >
                  Loading...
                </Menu.Item>
              )}

              <p className="notification-detail-view">
                <parseFloat
                  style={{ marginRight: "8px", letterSpacing: "0.032em" }}
                >
                  {
                    <Link
                      to={`/${this.props.auth.store.store_geo}/${
                        this.props.auth.store.id
                      }/all-notification`}
                    >
                      Detailed View
                    </Link>
                  }
                </parseFloat>{" "}
                <img
                  src={rightIcon}
                  alt="right icon"
                  style={{
                    verticalAlign: "middle",
                  }}
                />
              </p>
            </Menu.ItemGroup>
          </Menu>
        );
      } else {
        menu = (
          <Menu style={{ marginTop: "1.5rem" }}>
            <Menu.Item>No Notifications Found!</Menu.Item>
          </Menu>
        );
      }
    }

    return (
      <div>
        <Dropdown
          overlay={menu}
          // trigger={["click"]}
          onVisibleChange={(visible) => this.onVisibleChange(visible)}
          visible={this.state.visible}
          className="nav-notification"
        >
          <a className="ant-dropdown-link" href="#!">
            <Badge
              count={this.state.total_unread}
              onClick={() => this.fetchNoticfications()}
            >
              <Icon component={bell} className="bell-without-hover" />
              <Icon component={bell_colored} className="bell-on-hover" />
              <Icon
                component={notificationArrow}
                className={`notification-arrow ${
                  this.state.visible ? `transform-up` : null
                }`}
              />
            </Badge>
          </a>
        </Dropdown>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});
export default connect(mapStateToProps)(Notifications);
