import React from "react";
import { Table, Tooltip } from "antd";
import {
  getCurrencySymbol,
  formatNumber,
} from "../../../../../Helpers/Pricing";

const COD = (props) => {
  const transaction = props.transaction;
  let items = [];

  if (transaction) {
    items = [transaction];
  }

  const reviewColumns = [
    {
      title: "vFulfill Order ID",
      dataIndex: "order_id",
      width: "9.5%",
      render: () => transaction.transaction_data.vforderid,
    },
    {
      title: "Order Details",
      dataIndex: "items",
      width: "50.5%",
      className: "products-list",
      render: () => (
        <div style={{ width: "100%" }}>
          {transaction.transaction_data.items.map((item) => (
            <div
              key={item.lineitem_id}
              style={{ display: "flex", alignItems: "start" }}
            >
              <img src={item.img} alt="" style={{ width: 50 }} />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: 10,
                }}
              >
                <p
                  style={{
                    flex: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: 215,
                    marginBottom: 5,
                  }}
                >
                  <Tooltip title={item.name} placement="topLeft">
                    {item.name}
                  </Tooltip>
                </p>

                <p style={{ flex: 1, margin: 0, marginBottom: 5 }}>
                  <Tooltip title="vFulfill Selling Price">
                    <span style={{ background: "#f0f2f5", padding: "0 5px" }}>
                      {getCurrencySymbol("INR")} {formatNumber(item.item_mrp)} X{" "}
                      {item.quantity}
                    </span>
                  </Tooltip>
                </p>
                <p>
                  <span style={{ background: "#f0f2f5", padding: "0 5px" }}>
                    SKU: {item.sku}
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "AWB",
      dataIndex: "awb",
      width: "20%",
      render: () => (
        <p style={{ textAlign: "center" }}>
          {transaction.transaction_data.awb}
        </p>
      ),
    },
    {
      title: "COD Amount",
      dataIndex: "cod",
      width: "20%",
      render: () => (
        <p className="blue-text" style={{ textAlign: "left" }}>
          {getCurrencySymbol("INR")}{" "}
          {formatNumber(Number(transaction.transaction_data.cod).toFixed(2))}
        </p>
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey={(record) => `${record["orderid"]}_${record["lineitem_id"]}`}
        pagination={false}
        columns={reviewColumns}
        dataSource={items}
      />
      {transaction && (
        <div
          style={{
            display: "table",
            width: "100%",
            padding: "10px 0",
            boxShadow: "0 -50px 50px rgba(0,0,0,0.25)",
            marginTop: "2px",
          }}
        >
          <div
            style={{
              width: "9.5%",
              display: "inline-block",
              padding: "4px 16px",
            }}
          />
          <div
            style={{
              width: "50.5%",
              display: "inline-block",
              padding: "4px 16px 4px 78px",
            }}
          >
            <h3 style={{ margin: 0 }}>Total</h3>
          </div>
          <div
            style={{
              width: "20%",
              display: "inline-block",
              padding: "4px 16px",
            }}
          />
          <div
            style={{
              width: "20%",
              display: "inline-block",
              padding: "4px 16px",
              textAlign: "left",
            }}
          >
            {getCurrencySymbol("INR")}{" "}
            {formatNumber(transaction.transaction_data.cod, 2)}
          </div>
        </div>
      )}
    </>
  );
};

export default COD;
