import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";

class SourcingProductTitle extends React.Component {
  
  render() {
    return (
      <div>
        {this.props.productName ? (
          <span>
            {this.props.productName.length <= 60 ? (
              <a
                href={`${process.env.REACT_APP_NEW_APP}/productDetails/${this.props.vfprodid}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.props.productName}
              </a>
            ) : (
              <Tooltip title={this.props.productName}>
                {" "}
                <a
                  href={`${process.env.REACT_APP_NEW_APP}/productDetails/${this.props.vfprodid}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {this.props.productName
                    ? `${this.props.productName.substring(0, 60)}...`
                    : null}
                </a>
              </Tooltip>
            )}
          </span>
        ) : (
          "Product name not available"
        )}
      </div>
    );
  }
}

export default SourcingProductTitle;
