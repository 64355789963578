import React, { Component } from "react";
import { Modal, Radio } from "antd";
import moment from "moment";
import VfullfillForm from "./vfullfillForm";

import "./index.scss";
import { Tabs } from "antd";

const { TabPane } = Tabs;

class RaiseRFQModal extends Component {
  render() {
    return (
      <Modal
        onCancel={() => this.props.onCancel()}
        title={
          <>
            <h1>Request for Quotation</h1>
            <h2>
              Please provide relevant information for the Product you want to
              Request Quote for
            </h2>
          </>
        }
        footer={null}
        className="rfq-modal"
        visible={this.props.visible}
        destroyOnClose={true}
      >
        <VfullfillForm
          vfprodid={this.props.vfprodid}
          onCancel={() => this.props.onCancel()}
          activeTabHandler={(s) => this.props.activeTabHandler(s)}
        />
      </Modal>
    );
  }
}

export default RaiseRFQModal;
