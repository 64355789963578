import React from "react";
import { Modal, Button } from "antd";
export const SelfDomain = ({ cancel, visible }) => {
  return (
    <Modal
      visible={visible}
      onCancel={() => {
        cancel();
      }}
      width={800}
      footer={
        <Button
          className="clickable"
          onClick={() => {
            cancel();
          }}
        >
          OK
        </Button>
      }
      title="Important Information"
    >
      <div style={{ height: "250px", overflow: "auto" }}>
        <div style={{ marginBottom: "12px" }}>
          If you have chosen the option to install the Branded tracking Page on
          your own Branded subdomain, please follow the steps below to complete
          the DNS settings. These settings are necessary to ensure that your
          subdomain points to the correct location.
        </div>
        <div style={{ marginBottom: "12px" }}>
          <div>
            <span style={{ fontWeight: 500 }}>Step 1 : </span> Obtain Your
            Unique Subdomain{" "}
          </div>
          <div>
            Remember your unique subdomain name (e.g.,
            "tracking.yourdomain.com").
          </div>
        </div>
        <div style={{ marginBottom: "12px" }}>
          <div >
            <span style={{ fontWeight: 500 }}>Step 2 : </span> Access Your
            Domain Registrar or DNS Provider
          </div>
          <div>
            Login to your domain registrar or DNS provider (e.g., GoDaddy,
            Namecheap).
          </div>
        </div>
        <div style={{ marginBottom: "12px" }}>
          <div>
            <span style={{ fontWeight: 500 }}>Step 3 : </span> Find DNS Settings{" "}
          </div>
          <div>Locate the DNS settings or DNS management section.</div>
        </div>{" "}
        <div style={{ marginBottom: "12px" }}>
          <div>
            {" "}
            <span style={{ fontWeight: 500 }}>Step 4 : </span> Create a CNAME
            Record{" "}
          </div>
          <div>
            Add a new CNAME record:Name/Alias: "tracking" (for
            "tracking.yourdomain.com"). Value/Points to:
            "trackingpage.vfulfill.io" (provided during installation).
          </div>
        </div>
        <div style={{ marginBottom: "12px" }}>
          <div>
            {" "}
            <span style={{ fontWeight: 500 }}>Step 5:</span> Save the Changes{" "}
          </div>
          <div>
            Save the CNAME record and wait for DNS propagation (up to 24 hours).
          </div>
        </div>
        <div style={{ marginBottom: "12px" }}>
          <div>
            {" "}
            <span style={{ fontWeight: 500 }}>Step 6:</span> Verify Setup
          </div>
          <div>
            Access your Branded Tracking page at "tracking.yourdomain.com".
          </div>
        </div>
        <div style={{ marginBottom: "12px" }}>
          <div>For more info or any help, contact our support team.</div>
        </div>
      </div>
    </Modal>
  );
};
