import axios from "axios";
import analytics from "../../analytics";

export const signIn = (credentials) => {
  const { email, password } = credentials;
  return (dispatch, getState) => {
    axios({
      url: process.env.REACT_APP_API_URL + "/login/email",
      method: "post",
      data: {
        email,
        password,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          dispatch({ type: "LOGIN_SUCCESS", payload: { data: res.data.data } });
        } else {
          dispatch({ type: "LOGIN_ERROR", err: res.data.msg });
        }
      })
      .catch((err) => {
        dispatch({ type: "LOGIN_ERROR", err });
      });
  };
};

export const signUp = (credentials) => {
  const { first_name, last_name, email, password } = credentials;
  return (dispatch, getState) => {
    axios({
      url: process.env.REACT_APP_API_URL + "/login/mail_signup",
      method: "post",
      data: {
        first_name,
        last_name,
        email,
        password,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          dispatch({
            type: "SIGNUP_SUCCESS",
            payload: { data: res.data.data },
          });
        } else {
          dispatch({ type: "SIGNUP_ERROR", err: res.data.msg });
        }
      })
      .catch((err) => {
        dispatch({ type: "SIGNUP_ERROR", err });
      });
  };
};

export const signOut = (msg) => {
  return (dispatch) => {
    axios({
      url: process.env.REACT_APP_API_URL + "/login/logout",
      method: "post",
      withCredentials: true,
    }).then((res) => {
      if (res.data.success === 1) {
        dispatch({ type: "SIGNOUT_SUCCESS", payload: { msg } });
      }
    });
  };
};

export const removeRedirect = () => {
  return (dispatch) => {
    dispatch({ type: "REMOVE_REDIRECT" });
  };
};

export const updateStoreStatus = (store) => {
  return (dispatch) =>
    dispatch({
      type: "STORE_STATUS_UPDATED",
      payload: {
        storeid: store._id["$oid"],
        status: store.store_active,
      },
    });
};

export const switchStore = (storeid, redirect = "") => {
  console.log("plann", redirect);

  return (dispatch, getState) => {
    try{
      console.log("aaaaa", process.env.REACT_APP_API_URL + "/store/switch_store");
    axios({
      url: process.env.REACT_APP_API_URL + "/store/switch_store",
      method: "post",
      data: {
        storeid: storeid,
        redirect_to: redirect,
      },
      withCredentials: true,
    }).then((res) => {
      if (res.data.success === 1) {
        analytics.identify(res.data.data.store.id, {
          storeName: res.data.data.store.store_name,
          email: res.data.data.user.user_email,
        });
        console.log("successs",res.data.data);
        dispatch({
          type: "STORE_SWITCH_SUCCESSFUL",
          payload: { data: res.data.data },
        });
      } else {
        dispatch({ type: "SIGNOUT_SUCCESS" });
      }
    });
    }catch(e){
      console.log("catch",e);
    }
  };
};

export const storecount = (storeid) => {
  return (dispatch, getState) => {
    axios({
      url: process.env.REACT_APP_API_URL + "/store/get_store_counts",
      method: "post",
      data: {
        storeid: storeid,
      },
      withCredentials: true,
    }).then((res) => {
      if (res) {
        dispatch({
          type: "STORE_COUNT_SUCCESSFUL",
          payload: { data: res.data },
        });
      }
    });
  };
};
