import React from "react";
import { Modal, message } from "antd";
import {
  SHOPIFY_CLIENT_ID,
  BASE_URL,
  SHOPIFY_NAME,
} from "../../../../constants";
import ConnectToShopify from "../../Settings/Shopify/ConnectToShopify";
import axios from "axios";
import "./index.scss";

const handleSubmit = (shop, props) => {
  axios({
    url: process.env.REACT_APP_API_URL + "/store/check_store_used",
    method: "post",
    data: {
      shop: shop,
      storeid: props.match.params.storeid,
    },
    withCredentials: true,
  })
    .then((res) => {
      if (res.data.success === 1) {
        window.location = `https://${shop}.myshopify.com/admin/oauth/authorize?client_id=${SHOPIFY_CLIENT_ID}&scope=read_product_listings,read_products,write_products,read_orders,write_orders,read_inventory,write_customers,write_inventory&redirect_uri=${BASE_URL}login/shopify_callback&state=${
          props.match.params.storeid
        }`;
      } else {
        message.error(res.data.msg);
      }
    })
    .catch((e) => {
      message.error(e.message);
    });
};

const ConnectShopifyModal = (props) => {
  // console.log(props, "props");
  return (
    <Modal
      className="connect-shopify-modal"
      visible={props.visible}
      onCancel={props.onCancel}
      width={430}
      title={
        <h3
          style={{
            fontWeight: "500",
            textAlign: "center",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.032em",
          }}
        >
          {`Add New ${SHOPIFY_NAME}`}
        </h3>
      }
      destroyOnClose={true}
      footer={[]}
    >
      <ConnectToShopify
        showCancel={true}
        alignButtonsRight={true}
        handleOk={(val) => handleSubmit(val, props)}
        userauth={props.auth}
        {...props}
        username={true}
      />
    </Modal>
  );
};

export default ConnectShopifyModal;
