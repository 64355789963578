import React from "react";

import ClickToCopy from "../../../ClickToCopy";
import "./index.scss";

class ProductID extends React.Component {
  render() {
    return (
      <div className="product-id-g">
        <span style={{ marginRight: "8px" }}>
          {!!this.props.packaging ? "PACKAGING ID:" : "PRODUCT ID:"}
        </span>
        <span className="id">
          <span className="copied-id" style={{ marginLeft: "0px" }}>
            <ClickToCopy
              className=""
              textToCopy={this.props.product.vfprodid}
            />
          </span>
        </span>
      </div>
    );
  }
}

export default ProductID;
