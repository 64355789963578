import React, { Component } from "react";
import { Modal, Icon, Button } from "antd";
import { ReactComponent as failIcon } from "../../../../assets/images/icons/paymentfailed.svg";
import "./index.scss";

class TransactionFailed extends Component {
  render() {
    return (
      <Modal
        className="transactionFailedModal"
        visible={this.props.visible}
        width={480}
        title={null}
        onCancel={() => this.props.toggleFailedModal()}
        footer={null}
      >
        <div style={{ textAlign: "center" }}>
          <div className="transaction-failed-content">
            <Icon component={failIcon} />
            <h1>TRANSACTION FAILED!</h1>

            <p>
              {" "}
              {this.props.error
                ? this.props.error
                : "Looks like there was some error in the transaction."}{" "}
            </p>
          </div>
          <div className="footer-button">
            <span onClick={() => this.props.toggleFailedModal()}>
              Cancel Transaction <br /> (& go back to wallet)
            </span>
            <Button onClick={() => this.props.toggleFailedModal()}>
              RETRY
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default TransactionFailed;
