import {
  Button,
  Checkbox,
  Divider,
  Icon,
  message,
  Radio,
  Switch,
  Tooltip,
} from "antd";

import React, { Component } from "react";
import "./index.scss";
import axios from "axios";
import { connect } from "react-redux";
import { ReactComponent as editican } from "../../../../assets/images/icons/editican.svg";
import { ReactComponent as Tooltipican } from "../../../../assets/images/icons/tooltipican.svg";
class OTPVerify extends Component {
  state = {
    enableOTP: this.props.data.verify_otp_enable,
    type: this.props.data.tag_type ? 2 : 1,
    edit: this.props.data.verify_otp_enable ? false : true,

    inputtag: this.props.data.tag_type,
    originaldata: {
      enableOTP: this.props.data.verify_otp_enable,
      type: this.props.data.tag_type ? 2 : 1,
      inputtag: this.props.data.tag_type,
    },
    loading: false,
  };
  onSubmitData() {
    if (this.state.type === 2 && !this.state.inputtag.trim()) {
      return message.error("Please enter a tag");
    }

    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/store/update_order_management_confirmation_setting",
          method: "POST",
          data: {
            storeid: this.props.auth.store.id,

            verify_otp_enable: this.state.enableOTP ? 1 : 0,
            otp_verified_tags:
              this.state.type === 1 ? "" : this.state.inputtag.trim(),
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success) {
              this.setState({
                enableOTP:
                  res.data.order_confirmation_management_setting
                    .verify_otp_enable,
                type: res.data.order_confirmation_management_setting.tag_type
                  ? 2
                  : 1,
                edit: false,
                loading: false,
                inputtag:
                  res.data.order_confirmation_management_setting.tag_type,
                originaldata: {
                  enableOTP:
                    res.data.order_confirmation_management_setting
                      .verify_otp_enable,
                  type: res.data.order_confirmation_management_setting.tag_type
                    ? 2
                    : 1,
                  inputtag:
                    res.data.order_confirmation_management_setting.tag_type,
                },
              });
              message.success("Updated Successfully");
            } else {
              message.error(res.data.msg);
              this.setState({
                loading: false,
              });
            }
          })
          .catch((e) => {
            message.error(e.message);
            this.setState({
              loading: false,
            });
          });
      }
    );
  }
  onSubmitDataCancel() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/store/update_order_management_confirmation_setting",
          method: "POST",
          data: {
            storeid: this.props.auth.store.id,

            verify_otp_enable: 0,
            otp_verified_tags: "",
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success) {
              this.setState({
                enableOTP: false,

                type: 1,
                edit: true,
                inputtag: "",
                loading: false,
                originaldata: {
                  enableOTP: false,
                  type: 1,
                  inputtag: "",
                },
              });
              message.success("Disabled Successfully");
            } else {
              message.error(res.data.msg);
              this.setState({
                loading: false,
              });
            }
          })
          .catch((e) => {
            message.error(e.message);
            this.setState({
              loading: false,
            });
          });
      }
    );
  }
  render() {
    return (
      <div className="settings-container">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3 className="title">
          OTP Verification{" "}
            <span
              className="time-zone-tooltip"
              style={{
                marginLeft: "10px",
              }}
            >
              <Tooltip title="By enabling this feature means that all orders on your store will be considered as OTP verified. This will help us detect fraud better on your orders.">
                <Icon component={Tooltipican} />
              </Tooltip>
            </span>
          </h3>
        </div>

        <Divider />

        <div className="options-container35">
          <div className="otp-switch-container">
            <span className="label">My Orders are OTP Verified :</span>{" "}
            <Switch
              checked={this.state.enableOTP ? true : false}
              disabled={!this.state.edit}
              onChange={(checked) => {
                if (checked) {
                  this.setState({
                    enableOTP: checked,
                  });
                } else {
                  this.onSubmitDataCancel(true);
                }
              }}
            />
          </div>

          {this.state.enableOTP ? (
            <div className="type-container">
              {/* <div className="subheading"> Select Tag Type</div> */}
              <Radio.Group
                value={this.state.type}
                disabled={!this.state.edit}
                onChange={(e) => {
                  if (e.target.value === 2) {
                    this.setState({
                      type: e.target.value,
                    });
                  } else {
                    this.setState({
                      type: e.target.value,
                      inputtag: "",
                    });
                  }
                }}
              >
                <Radio value={1}> All Orders</Radio>
                <Radio value={2}> Orders having specific Tag</Radio>
              </Radio.Group>
            </div>
          ) : null}
          {this.state.type == 2 ? (
            <div className="input-container">
              <div className="subheading"> Enter Tag</div>
              <input
                className="input-tags"
                disabled={!this.state.edit}
                value={this.state.inputtag}
                onChange={(e) => {
                  this.setState({
                    inputtag: e.target.value,
                  });
                }}
              />
            </div>
          ) : null}
          {!this.state.edit ? (
            this.state.enableOTP ? (
              <div className="footer-contai">
                {/* <Button
                className="clickable"
                onClick={() => {
                  this.setState({
                    edit: true,
                  });
                }}
              >
                Edit
              </Button> */}
                <button
                  className="change-number-txt"
                  onClick={() => {
                    this.setState({
                      edit: true,
                    });
                  }}
                >
                  <span className="edit-ican">
                    <Icon component={editican} />
                  </span>
                  Edit
                </button>
              </div>
            ) : null
          ) : this.state.enableOTP ? (
            <div>
              {" "}
              <Button
                className="clickable"
                onClick={() => {
                  this.onSubmitData();
                }}
                style={{ marginRight: "10px" }}
                laading={this.state.loading}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    ...this.state.originaldata,
                    edit: this.state.originaldata.enableOTP ? false : true,
                  });
                }}
              >
                Cancel
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

{
  /* <button
  className="change-number-txt"
  onClick={() => this.props.chnageEmailtoggle()}
>
  <span className="edit-ican">
    <Icon component={editican} />
  </span>
  {this.state.updateEmailModal &&
  !!this.state.updateEmailModal.supportemail &&
  !!this.state.updateEmailModal.supportemail.length
    ? "Change Email Address"
    : "Add Email Address"}
</button>; */
}
export default connect(mapStateToProps)(OTPVerify);
