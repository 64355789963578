import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Tooltip, Empty } from "antd";
import { formatNumber } from "../../../../Helpers/Pricing";
import { SHOPIFY_NAME } from "../../../../constants";
import "./index.scss";

class RecentSoldProduct extends Component {
  state = {
    ShopifyName: false,
  };

  showshopifyname = () => {
    this.setState({
      ShopifyName: true,
    });
  };

  showcatalogname = () => {
    this.setState({
      ShopifyName: false,
    });
  };
  render() {
    const PageWrapper = (children) => {
      return <div>{children}</div>;
    };
    return PageWrapper(
      <div
        className="Sold_Products"
        style={{ boxShadow: "0 3px 10px rgba(0, 0, 0, 0.05)" }}
      >
        <div className="Sold_Products_container">
          <p className="Selected_Period_text">
            5 Most Recently Sold Products In Selected Period
          </p>
          <div className="right_text">
            <span>
              <button
                onClick={this.showcatalogname}
                className={
                  this.state.ShopifyName === true
                    ? "color_change"
                    : "Via_Catalog_Name"
                }
              >
                Via Catalog Name
              </button>
            </span>
            <span>|</span>
            <span>
              <button
                onClick={this.showshopifyname}
                className={
                  this.state.ShopifyName === true
                    ? "Via_Shopify_Name"
                    : "color_change"
                }
              >
                {`Via ${SHOPIFY_NAME} Name`}
              </button>
            </span>
          </div>
        </div>

        <table className="Sold_Products_table">
          <div className="table_parrent">
            <div style={{ padding: "20px 32px" }}>
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th style={{ textAlign: "center" }}>Total Sales</th>
                  <th style={{ textAlign: "center" }}>Sales Amount (₹)</th>
                  <th style={{ textAlign: "center" }}>Delivered Count</th>
                  <th style={{ textAlign: "center" }}>Delivered Amount (₹)</th>
                </tr>
              </thead>
            </div>
            <div style={{ background: "#ffffff" }}>
              {this.props.mostrecentproduct.length > 0 ? (
                <tbody>
                  {this.props.mostrecentproduct &&
                    this.props.mostrecentproduct.map((mostsellingproducts) =>
                      mostsellingproducts.catalogdata.length === 0 ? null : (
                        <tr key={mostsellingproducts._id}>
                          <td>
                            <span className="Selling_image">
                              <img
                                src={
                                  mostsellingproducts.image &&
                                  mostsellingproducts.image.length
                                    ? mostsellingproducts.image
                                    : "https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-4.png"
                                }
                                alt="#"
                              />
                            </span>
                            <span
                              className="spn"
                              style={{ textDecoration: "underline" }}
                            >
                              <Tooltip
                                title={
                                  mostsellingproducts &&
                                  mostsellingproducts.catalogdata &&
                                  mostsellingproducts.catalogdata[0].catalogname
                                    ? mostsellingproducts.catalogdata[0]
                                        .catalogname
                                    : null
                                }
                              >
                                <a
                                  href={
                                    this.state.ShopifyName === true
                                      ? mostsellingproducts.shopify_product_link
                                      : `/${this.props.match.params.storegeo}/${
                                          this.props.auth.store.id
                                        }/products/${
                                          mostsellingproducts.catalogdata[0]
                                            .vfprodid
                                        }`
                                  }
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  style={{
                                    color: "#1162FB",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {this.state.ShopifyName === true
                                    ? mostsellingproducts &&
                                      mostsellingproducts.shopify_name
                                      ? `${mostsellingproducts.shopify_name.substring(
                                          0,
                                          27
                                        )}${"....."}`
                                      : null
                                    : mostsellingproducts &&
                                      mostsellingproducts.catalogdata
                                    ? `${mostsellingproducts.catalogdata[0].catalogname.substring(
                                        0,
                                        27
                                      )}${"....."}`
                                    : null}
                                </a>
                                <svg
                                  className="svI"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13.019"
                                  height="13.207"
                                  viewBox="0 0 13.019 13.207"
                                >
                                  <g
                                    id="Group_3577"
                                    data-name="Group 3577"
                                    transform="translate(-961.462 -333.293)"
                                  >
                                    <path
                                      id="Path_84"
                                      data-name="Path 84"
                                      d="M-4591.471-691.587h-4.733v12h12v-4.576"
                                      transform="translate(5558.167 1025.587)"
                                      fill="none"
                                      stroke="#1162fb"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1"
                                    />
                                    <g
                                      id="Group_3178"
                                      data-name="Group 3178"
                                      transform="translate(967.669 334)"
                                    >
                                      <path
                                        id="Path_85"
                                        data-name="Path 85"
                                        d="M-4590.015-685.817l6.1-6.171"
                                        transform="translate(4590.015 691.988)"
                                        fill="none"
                                        stroke="#1162fb"
                                        stroke-linecap="round"
                                        stroke-width="1"
                                      />
                                      <path
                                        id="Path_86"
                                        data-name="Path 86"
                                        d="M-4587.394-691.587h4.055v3.868"
                                        transform="translate(4589.632 691.587)"
                                        fill="none"
                                        stroke="#1162fb"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="1"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </Tooltip>
                            </span>
                          </td>
                          <td>
                            <div style={{ textAlign: "center" }}>
                              <p>{mostsellingproducts.total_sales}</p>
                            </div>
                          </td>

                          <td style={{ textAlign: "center" }}>
                            <div>
                              <p>
                                {" "}
                                {formatNumber(
                                  mostsellingproducts.total_sales_amount,
                                  2
                                )}
                              </p>
                            </div>
                          </td>

                          <td style={{ textAlign: "center" }}>
                            <div>
                              <p>{mostsellingproducts.delivered}</p>
                            </div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div>
                              <p>
                                {formatNumber(
                                  mostsellingproducts.delivered_amount,
                                  2
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "16px",
                  }}
                >
                  <div>
                    <Empty description={false} />
                  </div>

                  <div style={{ textAlign: "left" }}>
                    <h1 style={{ marginBottom: 0, fontSize: "20px" }}>
                      Not Enough Data!
                    </h1>
                    <p style={{ margin: 0, fontSize: "1rem" }}>
                      We could not find enough data...
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </table>
      </div>
    );
  }
}

export default RecentSoldProduct;
