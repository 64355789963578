import React, { Component } from "react";
import {
  Input,
  Select,
  Card,
  Button,
  message,
  Icon,
  Alert,
  Form,
  Skeleton,
} from "antd";
import "./index.scss";
import { connect } from "react-redux";
import axios from "axios";
import Cointainer2 from "./Container2";
import Container3 from "./Container3";
import Container4 from "./Container4";
import Container1 from "./Container1/Index";
import ActionBar from "./ActionBar";
import ZoomModal from "./ZoomModal";
import ErrorCard from "../../Common/ErrorCard";
import ContainerDomain from "./ContainerDomain";
// import LockedImage from "../../../../assets/images/backgrounds/locked.png";
import LockedImage from "../../../../assets/images/backgrounds/demo.gif";
import AppSubscriptionPlan from "../../AppSubscriptionPlan";

// demo page
let currentYear = new Date().getFullYear();
let demoUrl = btoa(
  JSON.stringify({
    theme: {
      colors: {
        button: "#212121",
        button_text: "#FFFFFF",
        footer_bar: "#212121",
        header_bar: "#212121",
        footer_text: "#FFFFFF",
        header_text: "#FFFFFF",
      },
      font_sizes: {
        footer_text: "",
        primary_text: "",
        secondary_text: "",
        section_title_text: "",
      },
    },
    headers: {
      logo:
        "https://d24fzeiqvvundc.cloudfront.net/tracking_page_images/86ba08724aa0d1b545c4190b1759a907.png",
      store_name: "Your Store Name",
      store_url: "",
      favicon:
        "https://d24fzeiqvvundc.cloudfront.net/tracking_page_images/bdcb8654be5cb4952e0f6d12aa6f7ea0.png",
      page_title: "Shop Smarter, Live Better",
      page_description:
        "We source and test the most innovative products that can make your life easier and more enjoyable in every aspect.",
    },
    footers: {
      social_links: {
        instagram: "#",
        facebook: "#",
        twitter: "#",
        youtube: "",
        igtv: "",
      },
      disclaimer: `${currentYear}, Your Brand Powered by Shopify`,
    },
  })
);
const lockedSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="48"
    viewBox="0 -960 960 960"
    width="48"
  >
    <path d="M220-634h390v-96q0-54.167-37.882-92.083-37.883-37.917-92-37.917Q426-860 388-822.083 350-784.167 350-730h-60q0-79 55.606-134.5t134.5-55.5Q559-920 614.5-864.425T670-730v96h70q24.75 0 42.375 17.625T800-574v434q0 24.75-17.625 42.375T740-80H220q-24.75 0-42.375-17.625T160-140v-434q0-24.75 17.625-42.375T220-634Zm260.168 354Q512-280 534.5-302.031T557-355q0-30-22.668-54.5t-54.5-24.5Q448-434 425.5-409.5t-22.5 55q0 30.5 22.668 52.5t54.5 22Z" />
  </svg>
);
const eyeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="48"
    viewBox="0 -960 960 960"
    width="48"
  >
    <path d="M480.118-330Q551-330 600.5-379.618q49.5-49.617 49.5-120.5Q650-571 600.382-620.5q-49.617-49.5-120.5-49.5Q409-670 359.5-620.382q-49.5 49.617-49.5 120.5Q310-429 359.618-379.5q49.617 49.5 120.5 49.5Zm-.353-58Q433-388 400.5-420.735q-32.5-32.736-32.5-79.5Q368-547 400.735-579.5q32.736-32.5 79.5-32.5Q527-612 559.5-579.265q32.5 32.736 32.5 79.5Q592-453 559.265-420.5q-32.736 32.5-79.5 32.5ZM480-200q-146 0-264-83T40-500q58-134 176-217t264-83q146 0 264 83t176 217q-58 134-176 217t-264 83Z" />
  </svg>
);

class ShipmentForm extends Component {
  state = {
    access: 0,
    headers: {
      logo: "",
      favicon: "",
      page_description: "",
      page_title: "",
      store_name: "",
      store_url: "",
    },
    footers: {
      social_links: {
        instagram: "",
        facebook: "",
        twitter: "",
      },
      disclaimer: "",
    },
    theme: {
      colors: {
        button: "#0068FF",
        button_text: "#ffffff",
        footer_bar: "#212121",
        header_bar: "#212121",
        footer_text: "#ffffff",
        header_text: "#ffffff",
      },
      font_sizes: {
        footer_text: "",
        primary_text: "",
        secondary_text: "",
        section_title_text: "",
      },
    },
    media: null,
    mediaFavicon: null,
    loading: false,
    disabled: false,
    themeOption: 0,
    imageLoading: false,
    orignalData: {
      headers: {
        logo: "",
        favicon: "",
        page_description: "",
        page_title: "",
        store_name: "",
        store_url: "",
      },
      footers: {
        social_links: {
          instagram: "",
          facebook: "",
          twitter: "",
        },
        disclaimer: "",
      },
      theme: {
        colors: {
          button: "#0068FF",
          button_text: "#ffffff",
          footer_bar: "#212121",
          header_bar: "#212121",
          footer_text: "#ffffff",
          header_text: "#ffffff",
        },
        font_sizes: {
          footer_text: "",
          primary_text: "",
          secondary_text: "",
          section_title_text: "",
        },
      },
    },
    zoomModal: {
      visible: false,
    },
    domain_settings: {
      select: "",
      vfullfillDomain: "",
      self: "",
      verifyed: false,
    },
    verifyedString: "",
    error: false,
    errorMsg: "",
    domainSettingOn: false,
    vfsubdomain: "",

    //

    current_plan: {
      plan_name: "",
      renewal_date: "",
      currency: "",
      amount: "",
      interval: "",
      plan_type: "",
      plan_status: "",
    },
    showUpgradePlanModal: false,
    upgradable_plans: [],
  };

  handleRadioDomainSetting = (e) => {
    if (e.target.value === "vFullfill") {
      this.setState({
        domain_settings: {
          ...this.state.domain_settings,
          select: e.target.value,
          self: "",
        },
      });
    } else if (e.target.value === "self") {
      this.setState({
        domain_settings: {
          ...this.state.domain_settings,
          select: e.target.value,
          vfullfillDomain: "",
          verifyed: false,
        },
      });
    }
  };

  handleDominSettingInputChange = (e) => {
    if (this.state.domain_settings.select === "vFullfill") {
      this.setState({
        domain_settings: {
          ...this.state.domain_settings,
          vfullfillDomain: e.target.value,
          verifyed: false,
        },
      });
    } else {
      this.setState({
        domain_settings: {
          ...this.state.domain_settings,
          self: e.target.value,
        },
      });
    }
  };
  handleVerifyDomain = () => {
    if (
      this.state.verifyedString &&
      this.state.verifyedString === this.state.domain_settings.vfullfillDomain
    ) {
      this.setState({
        domain_settings: {
          ...this.state.domain_settings,
          verifyed: true,
        },
      });
      return;
    }

    axios({
      url: process.env.REACT_APP_API_URL + "/store/verify_tracking_domain",
      method: "post",
      data: {
        domain_url: this.state.domain_settings.vfullfillDomain,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            domain_settings: {
              ...this.state.domain_settings,
              verifyed: true,
            },
          });
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  };
  toggleZoomModal = (src, type) => {
    this.setState((state) => ({
      zoomModal: {
        ...state.zoomModal,
        src,
        type,
        visible: !state.zoomModal.visible,
      },
    }));
  };
  handleFaviconChange = async (e) => {
    let file = e.target.files[0];
    // let image = await getBase64(file);
    let fd = new FormData();
    fd.append("file", file);
    // this.setState({
    //   faviconbase64: image,
    //   mediaFavicon: file,
    // });
    this.setState({ imageLoading: true }, () => {
      axios({
        url:
          process.env.REACT_APP_API_URL + "/store/update_tracking_page_images",
        method: "post",
        data: fd,
        withCredentials: true,
      })
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            this.setState({
              headers: {
                ...this.state.headers,
                favicon: res.data.file,
              },
              imageLoading: false,
            });
          } else {
            message.error(res.data.msg);
            this.setState({
              imageLoading: false,
            });
          }
        })
        .catch((e) => {
          message.error(e.message);
          this.setState({
            imageLoading: false,
          });
        });
    });
  };
  themes = {
    theme1: {
      button: "#459B43",
      button_text: "#ffffff",
      footer_bar: "#5E19D9",
      header_bar: "#7020FF",
      footer_text: "#ffffff",
      header_text: "#ffffff",
    },
    theme2: {
      button: "#FFC107",
      button_text: "#212121",
      footer_bar: "#1C8046",
      header_bar: "#27AE60",
      footer_text: "#ffffff",
      header_text: "#ffffff",
    },
    theme3: {
      button: "#F39C12",
      button_text: "#ffffff",
      footer_bar: "#153E59",
      header_bar: "#1B4F72",
      footer_text: "#ffffff",
      header_text: "#ffffff",
    },
    theme0: {
      button: "#0068FF",
      button_text: "#ffffff",
      footer_bar: "#212121",
      header_bar: "#212121",
      footer_text: "#ffffff",
      header_text: "#ffffff",
    },
  };
  handleThemeChange = (e) => {
    this.setState({
      themeOption: e,
      theme: {
        ...this.state.theme,
        colors: {
          ...this.themes["theme" + e],
        },
      },
    });
  };
  handleLogoChange = async (e) => {
    let file = e.target.files[0];
    //let image = await getBase64(file);
    const fd = new FormData();
    fd.append("file", file);
    this.setState({ imageLoading: true }, () => {
      axios({
        url:
          process.env.REACT_APP_API_URL + "/store/update_tracking_page_images",
        method: "post",
        data: fd,
        withCredentials: true,
      })
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            this.setState({
              headers: {
                ...this.state.headers,
                logo: res.data.file,
              },
              imageLoading: false,
            });
          } else {
            message.error(res.data.msg);
            this.setState({
              imageLoading: false,
            });
          }
        })
        .catch((e) => {
          message.error(e.message);
          this.setState({
            imageLoading: false,
          });
        });
    });
  };
  handleFooterTextChange = (e) => {
    this.setState({
      footers: {
        ...this.state.footers,
        disclaimer: e.target.value,
      },
    });
  };
  handleSecondTextChange = (obj) => {
    console.log(obj);
    this.setState({
      headers: {
        ...this.state.headers,
        [obj.name]: obj.value,
      },
    });
  };
  handleSecondFontChange = (obj) => {
    console.log(obj);
    this.setState({
      theme: {
        ...this.state.theme,
        font_sizes: {
          ...this.state.theme.font_sizes,
          [obj.name]: obj.value,
        },
      },
    });
  };
  handleColorChange = (obj) => {
    console.log("obj", obj);
    this.setState({
      theme: {
        ...this.state.theme,
        colors: {
          ...this.state.theme.colors,
          [obj.name]: obj.value,
        },
      },
    });
  };
  loadData() {
    this.setState({ loading: true }, () =>
      axios({
        url: process.env.REACT_APP_API_URL + "/store/get_tracking_settings",
        method: "post",
        data: {
          store_id: this.props.auth.store.id,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success) {
            let disable;
            if (!res.data.data.headers.logo) {
              disable = false;
            } else {
              disable = true;
            }
            let data = { ...res.data.data };

            if (!data.theme.colors.button) {
              data.theme.colors.button = "#212121";
            }
            if (!data.theme.colors.button_text) {
              data.theme.colors.button_text = "#ffffff";
            }
            if (!data.theme.colors.footer_bar) {
              data.theme.colors.footer_bar = "#212121";
            }
            if (!data.theme.colors.header_bar) {
              data.theme.colors.header_bar = "#212121";
            }
            if (!data.theme.colors.header_text) {
              data.theme.colors.header_text = "#ffffff";
            }
            if (!data.theme.colors.footer_text) {
              data.theme.colors.footer_text = "#ffffff";
            }
            let colortheme = 4;
            if (
              data.theme.colors.button === "#212121" &&
              data.theme.colors.button_text === "#ffffff" &&
              data.theme.colors.footer_bar === "#212121" &&
              data.theme.colors.header_bar === "#212121" &&
              data.theme.colors.header_text === "#ffffff" &&
              data.theme.colors.footer_text === "#ffffff"
            ) {
              colortheme = 0;
            } else if (
              data.theme.colors.button === "#459B43" &&
              data.theme.colors.button_text === "#ffffff" &&
              data.theme.colors.footer_bar === "#5E19D9" &&
              data.theme.colors.header_bar === "#7020FF" &&
              data.theme.colors.header_text === "#ffffff" &&
              data.theme.colors.footer_text === "#ffffff"
            ) {
              colortheme = 1;
            } else if (
              data.theme.colors.button === "#FFC107" &&
              data.theme.colors.button_text === "#212121" &&
              data.theme.colors.footer_bar === "#1C8046" &&
              data.theme.colors.header_bar === "#27AE60" &&
              data.theme.colors.header_text === "#ffffff" &&
              data.theme.colors.footer_text === "#ffffff"
            ) {
              colortheme = 2;
            } else if (
              data.theme.colors.button === "#F39C12" &&
              data.theme.colors.button_text === "#ffffff" &&
              data.theme.colors.footer_bar === "#153E59" &&
              data.theme.colors.header_bar === "#1B4F72" &&
              data.theme.colors.header_text === "#ffffff" &&
              data.theme.colors.footer_text === "#ffffff"
            ) {
              colortheme = 3;
            }
            if (!data.domain_settings.type) {
              data.domain_settings = {
                select: "",
                vfullfillDomain: "",
                self: "",
                verifyed: false,
              };
            } else {
              data.domainSettingOn = true;
            }
            if (data.domain_settings.type === "self") {
              data.domain_settings = {
                select: "self",
                vfullfillDomain: "",
                self: data.domain_settings.domain_url,
                verifyed: false,
              };
            }
            if (data.domain_settings.type === "vfulfill") {
              data.verifyedString = data.domain_settings.domain_url;
              data.domain_settings = {
                select: "vFullfill",
                vfullfillDomain: data.domain_settings.domain_url,
                self: "",
                verifyed: true,
              };
            }
            this.setState(
              {
                ...data,
                orignalData: { ...data },
                loading: false,
                disabled: disable,
                themeOption: colortheme,
                vfsubdomain: res.data.vfsubdomain,
              },
              () => this.loadPricing()
            );
          } else {
            this.setState({
              error: true,
              errorMsg: res.data.msg,
              loading: false,
            });
          }
        })
        .catch((e) => {
          this.setState({
            error: true,
            errorMsg: e.message,
            loading: false,
          });
        })
    );
  }

  loadPricing() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/store/get_store_plan_billing",
          method: "post",
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success) {
              this.setState({
                ...res.data.data,
                loading: false,
              });
            } else {
              this.setState({
                loading: false,
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((error) => {
            this.setState({
              loading: false,
              error: true,
              errorMsg: error.message,
            });
            message.error(error.message);
          });
      }
    );
  }

  handleDownload = () => {
    // Create a link element
    const link = document.createElement("a");
    let previewdata = {
      theme: {
        ...this.state.theme,
      },
      headers: {
        ...this.state.headers,
      },
      footers: {
        ...this.state.footers,
      },
    };
    let styles = JSON.stringify(previewdata);
    const encodedData = btoa(styles);
    console.log(encodedData);

    let url =
      "https://master.dq32da0syuzgx.amplifyapp.com/?previewData=" + encodedData;

    link.href = url;

    link.target = "_blank";
    link.click();
  };
  componentDidMount() {
    this.loadData();
  }
  componentDidUpdate() {
    if (this.state.themeOption !== 4) {
      if (
        !(
          this.state.theme.colors.button === "#0068FF" &&
          this.state.theme.colors.button_text === "#ffffff" &&
          this.state.theme.colors.footer_bar === "#212121" &&
          this.state.theme.colors.header_bar === "#212121" &&
          this.state.theme.colors.header_text === "#ffffff" &&
          this.state.theme.colors.footer_text === "#ffffff"
        ) &&
        !(
          this.state.theme.colors.button === "#459B43" &&
          this.state.theme.colors.button_text === "#ffffff" &&
          this.state.theme.colors.footer_bar === "#5E19D9" &&
          this.state.theme.colors.header_bar === "#7020FF" &&
          this.state.theme.colors.header_text === "#ffffff" &&
          this.state.theme.colors.footer_text === "#ffffff"
        ) &&
        !(
          this.state.theme.colors.button === "#FFC107" &&
          this.state.theme.colors.button_text === "#212121" &&
          this.state.theme.colors.footer_bar === "#1C8046" &&
          this.state.theme.colors.header_bar === "#27AE60" &&
          this.state.theme.colors.header_text === "#ffffff" &&
          this.state.theme.colors.footer_text === "#ffffff"
        ) &&
        !(
          this.state.theme.colors.button === "#F39C12" &&
          this.state.theme.colors.button_text === "#ffffff" &&
          this.state.theme.colors.footer_bar === "#153E59" &&
          this.state.theme.colors.header_bar === "#1B4F72" &&
          this.state.theme.colors.header_text === "#ffffff" &&
          this.state.theme.colors.footer_text === "#ffffff"
        )
      ) {
        this.setState({
          themeOption: 4,
        });
      }
    }
  }
  handleChangeStoreName = (e) => {
    this.setState({
      headers: {
        ...this.state.headers,
        store_name: e.target.value,
      },
    });
  };
  handleFacebookurl = (e) => {
    console.log(e.target.value);
    this.setState({
      footers: {
        ...this.state.footers,
        social_links: {
          ...this.state.footers.social_links,
          facebook: e.target.value,
        },
      },
    });
  };
  handleTwitterurl = (e) => {
    this.setState({
      footers: {
        ...this.state.footers,
        social_links: {
          ...this.state.footers.social_links,
          twitter: e.target.value,
        },
      },
    });
  };
  handleInstagramurl = (e) => {
    this.setState({
      footers: {
        ...this.state.footers,
        social_links: {
          ...this.state.footers.social_links,
          instagram: e.target.value,
        },
      },
    });
  };
  handleDisclimer = (e) => {
    this.setState({
      footers: {
        ...this.state.footers,
        disclimer: e.target.value,
      },
    });
  };
  uploadData = () => {
    let submitData = {
      headers: {
        ...this.state.headers,
      },
      footers: {
        ...this.state.footers,
      },
      theme: {
        ...this.state.theme,
      },
      store_id: this.props.auth.store.id,
    };
    if (!this.state.domain_settings.select) {
      submitData.domain_settings = {
        type: "",
        domain_url: "",
      };
    } else if (this.state.domain_settings.select === "self") {
      submitData.domain_settings = {
        type: "self",
        domain_url: this.state.domain_settings.self,
      };
    } else if (this.state.domain_settings.select === "vFullfill") {
      submitData.domain_settings = {
        type: "vFullfill",
        domain_url: this.state.domain_settings.vfullfillDomain,
      };
    }

    this.setState({ loading: true }, () =>
      axios({
        url: process.env.REACT_APP_API_URL + "/store/update_tracking_settings",
        method: "post",
        data: submitData,
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success) {
            message.success(res.data.msg);
            let data = { ...res.data.data };
            if (!data.domain_settings.type) {
              data.domain_settings = {
                select: "",
                vfullfillDomain: "",
                self: "",
                verifyed: false,
              };
            } else {
              data.domainSettingOn = true;
            }
            if (data.domain_settings.type === "self") {
              data.domain_settings = {
                select: "self",
                vfullfillDomain: "",
                self: data.domain_settings.domain_url,
                verifyed: false,
              };
            }
            if (data.domain_settings.type === "vFullfill") {
              data.domain_settings = {
                select: "vFullfill",
                vfullfillDomain: data.domain_settings.domain_url,
                self: "",
                verifyed: true,
              };
            }
            this.setState({
              loading: false,
              orignalData: { ...data },
              domainSettingOn: res.data.data.domain_settings.type
                ? true
                : false,
              disabled: true,
            });
          } else {
            message.error(res.data.msg);
            this.setState({
              loading: false,
            });
          }
        })
        .catch((e) => {
          this.setState({
            error: true,
            errorMsg: e.message,
            loading: false,
          });
          message.error(e.message);
        })
    );
  };
  handleSubmit = (e) => {
    e.preventDefault();
    var urlPattern = /^(https?:\/\/)?((([a-zA-Z0-9_]+)(\.[a-zA-Z0-9_]+)+)|localhost)(:\d{1,5})?(\/[^\s]*)?$/;
    var domainPattern = /^(?!www\.)(?!https:\/\/)(?!http:\/\/)(?!:\/\/)[a-zA-Z0-9-]{1,63}(?:\.[a-zA-Z0-9-]{1,63})+$/;
    if (!this.state.headers.logo) {
      message.error("Please upload brand logo");
      return;
    }
    if (
      this.state.domain_settings.select === "self" &&
      !this.state.domain_settings.self
    ) {
      message.error("Please enter domain name");
      return;
    }
    if (
      this.state.domain_settings.select === "self" &&
      this.state.domain_settings.self &&
      !domainPattern.test(this.state.domain_settings.self)
    ) {
      message.error("Please enter valid domain name");
      return;
    }
    if (
      this.state.domain_settings.select === "vFullfill" &&
      !this.state.domain_settings.vfullfillDomain
    ) {
      message.error("Please enter vFullfill Sub Domain");
      return;
    }
    if (
      this.state.domain_settings.select === "vFullfill" &&
      this.state.domain_settings.vfullfillDomain
    ) {
      const terms = [".", "/"];
      const result1 = terms.some((term) =>
        this.state.domain_settings.vfullfillDomain.includes(term)
      );
      if (result1) {
        message.error("Please enter valid vFullfill sub domain");
        return;
      }
    }
    if (
      this.state.domain_settings.select === "vFullfill" &&
      !this.state.domain_settings.verifyed
    ) {
      message.error("Please check the availability of domain name");
      return;
    }
    if (
      this.state.footers.social_links.facebook &&
      !urlPattern.test(this.state.footers.social_links.facebook)
    ) {
      message.error("Please enter valid facebook URL");
      return;
    }
    if (
      this.state.footers.social_links.twitter &&
      !urlPattern.test(this.state.footers.social_links.twitter)
    ) {
      message.error("Please enter valid twitter URL");
      return;
    }
    if (
      this.state.footers.social_links.instagram &&
      !urlPattern.test(this.state.footers.social_links.instagram)
    ) {
      message.error("Please enter valid instagram URL");
      return;
    }
    if (!this.state.footers.disclaimer) {
      message.error("Please enter footer text");
      return;
    }
    if (!this.state.headers.page_description) {
      message.error("Please enter page description");
      return;
    }
    if (!this.state.headers.page_title) {
      message.error("Please enter page title");
      return;
    }

    this.uploadData();
    // axios call to upload
    // on successful
  };
  handleEdit = () => {
    this.setState({
      disabled: false,
    });
  };
  handleClear = () => {
    let colortheme = 4;
    if (
      this.state.orignalData.theme.colors.button === "#212121" &&
      this.state.orignalData.theme.colors.button_text === "#ffffff" &&
      this.state.orignalData.theme.colors.footer_bar === "#212121" &&
      this.state.orignalData.theme.colors.header_bar === "#212121" &&
      this.state.orignalData.theme.colors.header_text === "#ffffff" &&
      this.state.orignalData.theme.colors.footer_text === "#ffffff"
    ) {
      colortheme = 0;
    } else if (
      this.state.orignalData.theme.colors.button === "#459B43" &&
      this.state.orignalData.theme.colors.button_text === "#ffffff" &&
      this.state.orignalData.theme.colors.footer_bar === "#5E19D9" &&
      this.state.orignalData.theme.colors.header_bar === "#7020FF" &&
      this.state.orignalData.theme.colors.header_text === "#ffffff" &&
      this.state.orignalData.theme.colors.footer_text === "#ffffff"
    ) {
      colortheme = 1;
    } else if (
      this.state.orignalData.theme.colors.button === "#FFC107" &&
      this.state.orignalData.theme.colors.button_text === "#212121" &&
      this.state.orignalData.theme.colors.footer_bar === "#1C8046" &&
      this.state.orignalData.theme.colors.header_bar === "#27AE60" &&
      this.state.orignalData.theme.colors.header_text === "#ffffff" &&
      this.state.orignalData.theme.colors.footer_text === "#ffffff"
    ) {
      colortheme = 2;
    } else if (
      this.state.orignalData.theme.colors.button === "#F39C12" &&
      this.state.orignalData.theme.colors.button_text === "#ffffff" &&
      this.state.orignalData.theme.colors.footer_bar === "#153E59" &&
      this.state.orignalData.theme.colors.header_bar === "#1B4F72" &&
      this.state.orignalData.theme.colors.header_text === "#ffffff" &&
      this.state.orignalData.theme.colors.footer_text === "#ffffff"
    ) {
      colortheme = 3;
    }
    this.setState({
      disabled: true,
      ...this.state.orignalData,
      themeOption: colortheme,
    });
  };
  handleHeaderColour = (e) => {
    this.setState({});
  };

  changeColour = (e) => {
    this.setState({
      colour: e.target.value,
    });
  };
  handleUnlock() {
    this.setState({ showUpgradePlanModal: true });

    // axios({
    //   url: process.env.REACT_APP_API_URL + "/store/raise_plan_upgrade_ticket",
    //   method: "post",
    //   data: {
    //     store_id: this.props.store.id,
    //   },
    //   withCredentials: true,
    // })
    //   .then((res) => {
    //     if (res.data.success) {
    //       message.success(res.data.msg);
    //     } else {
    //       message.error(res.data.msg);
    //     }
    //   })
    //   .catch((err) => {
    //     message.error("Something went wrong");
    //   });
  }
  lockedContent = (
    <div className="custom-pop-up">
      <div class="ribbon ribbon-top-left">
        <span>Premium</span>
      </div>

      <div className="wr">
        <div className="left">
          <h1>Unlock the Power of Tracking 🚀</h1>

          <h2>Request access to our advanced Tracking Page now!</h2>

          <h3>
            Provide real-time insights, enhanced analytics, and a seamless
            tracking journey to your customers.
          </h3>

          <h4>
            Feel free to adjust and personalize the text to match your brand's
            tone and style.{" "}
          </h4>

          <div className="btn-wraper">
            <a
              className="ant-btn"
              href={
                `https://master.dq32da0syuzgx.amplifyapp.com/?previewData=` +
                demoUrl
              }
              target="_blank"
            >
              {" "}
              {eyeIcon}
              Preview Tracking Page
            </a>

            <a
              className="ant-btn ant-btn-primary"
              onClick={() => {
                this.handleUnlock();
              }}
            >
              {lockedSvg} Unlock Premium Tracking
            </a>
          </div>
        </div>
        <div className="right">
          <img src={LockedImage} alt="" />
        </div>
      </div>
    </div>
  );
  render() {
    if (this.state.error) {
      return (
        <ErrorCard
          // errorCode={this.state.errorCode}
          errorMsg={this.state.errorMsg}
        />
      );
    }
    return (
      <>
        {this.state.loading ? (
          <Skeleton active />
        ) : (
          <Card>
            <div
              className={`setting-cointainer ${
                this.state.access ? null : `locked`
              } `}
            >
              {this.state.access ? null : this.lockedContent}

              <form onSubmit={this.handleSubmit}>
                <div className="heading">
                  <div>Tracking Page Settings</div>
                  <div className="heading-right">
                    {" "}
                    <ActionBar
                      disabled={this.state.disabled}
                      handleEdit={this.handleEdit}
                      handleClear={this.handleClear}
                      handleDownload={this.handleDownload}
                    />
                  </div>
                </div>
                {this.state.imageLoading ? (
                  <Skeleton active />
                ) : (
                  <Container1
                    disabled={this.state.disabled}
                    logoUrl={this.state.headers.logo}
                    faviconUrl={this.state.headers.favicon}
                    store_name={this.state.headers.store_name}
                    handleChangeStoreName={this.handleChangeStoreName}
                    handleFaviconChange={this.handleFaviconChange}
                    handleLogoChange={this.handleLogoChange}
                    toggleZoomModal={this.toggleZoomModal}
                  />
                )}
                <Cointainer2
                  disabled={this.state.disabled}
                  pageTitle={this.state.headers.page_title}
                  pageDescription={this.state.headers.page_description}
                  footerText={this.state.footers.disclaimer}
                  primaryTextFontSize={this.state.theme.font_sizes.primary_text}
                  secondoryTextFontSize={
                    this.state.theme.font_sizes.secondary_text
                  }
                  sectionFontSize={
                    this.state.theme.font_sizes.section_title_text
                  }
                  footerFontSize={this.state.theme.font_sizes.footer_text}
                  handleSecondTextChange={this.handleSecondTextChange}
                  handleSecondFontChange={this.handleSecondFontChange}
                  handleFooterTextChange={this.handleFooterTextChange}
                />
                <Container3
                  disabled={this.state.disabled}
                  handleColorChange={this.handleColorChange}
                  headerbarColour={this.state.theme.colors.header_bar}
                  footerbarColour={this.state.theme.colors.footer_bar}
                  buttonColor={this.state.theme.colors.button}
                  buttonTextColor={this.state.theme.colors.button_text}
                  headerTextColor={this.state.theme.colors.header_text}
                  footerTextColor={this.state.theme.colors.footer_text}
                  selecttheme={this.state.themeOption}
                  handleThemeChange={(e) => {
                    this.handleThemeChange(e);
                  }}
                />
                <ContainerDomain
                  disabled={this.state.disabled}
                  domainSetting={this.state.domain_settings}
                  domainSettingOn={this.state.domainSettingOn}
                  handleRadioDomainSetting={(e) =>
                    this.handleRadioDomainSetting(e)
                  }
                  handleDominSettingInputChange={(e) =>
                    this.handleDominSettingInputChange(e)
                  }
                  handleVerifyDomain={() => this.handleVerifyDomain()}
                  vfsubdomain={this.state.vfsubdomain}
                />
                <Container4
                  disabled={this.state.disabled}
                  facebookurl={this.state.footers.social_links.facebook}
                  instagramurl={this.state.footers.social_links.instagram}
                  twitterurl={this.state.footers.social_links.twitter}
                  handleInstagramurl={(e) => this.handleInstagramurl(e)}
                  handleTwitterurl={(e) => this.handleTwitterurl(e)}
                  handleFacebookurl={(e) => this.handleFacebookurl(e)}
                />
                <div className="divider" />
                <div className="footer-cointainer">
                  <ActionBar
                    disabled={this.state.disabled}
                    handleEdit={this.handleEdit}
                    handleClear={this.handleClear}
                    handleDownload={this.handleDownload}
                  />
                </div>
              </form>
            </div>
          </Card>
        )}
        <ZoomModal
          {...this.state.zoomModal}
          toggleModal={() => this.toggleZoomModal()}
        />

        {this.state.showUpgradePlanModal && (
          <AppSubscriptionPlan
            isFreePlan={
              this.state.current_plan &&
              this.state.current_plan.plan_type === "free"
                ? true
                : false
            }
            onClose={() => this.setState({ showUpgradePlanModal: false })}
            current_plan={this.state.current_plan}
            upgradable_plans={this.state.upgradable_plans}
            upgradePlan={true}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    store: state.auth.auth.store,
  };
};

export default connect(mapStateToProps)(ShipmentForm);
