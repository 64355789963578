import React, { Component } from "react";
import { Button, Input, Modal, Icon, message, Select } from "antd";
import { ReactComponent as editican } from "../../../../../assets/images/icons/editican.svg";
import { ReactComponent as rightican } from "../../../../../assets/images/icons/right.svg";
import { ISDCodes } from "../../../../../Helpers/ISD";
import axios from "axios";
import "../../../index.scss";
import ReactPhoneInput from "react-phone-input-2";

const { Option } = Select;

class GlobalSupportPhoneNumber extends Component {
  state = {
    minutes: 0,
    seconds: 30,
    loading: false,
    myinterval: 0,
  };

  startTimer() {
    if (this.state.myinterval != 0) {
      clearInterval(this.state.myinterval);
    }
    this.setState(
      {
        seconds: 30,
      },
      () => {
        this.state.myinterval = setInterval(() => {
          const { seconds, minutes } = this.state;
          if (seconds > 0) {
            this.setState(({ seconds }) => ({
              seconds: seconds - 1,
            }));
          }
          if (seconds === 0) {
            if (minutes === 0) {
              clearInterval(this.state.myinterval);
            } else {
              this.setState(({ minutes }) => ({
                seconds: 30,
              }));
            }
          }
        }, 1000);
      }
    );
  }

  updateNumber() {
    // if (this.props.resetTimer) {
    this.startTimer();
    this.props.updatePhonenumber();
    // } else {
    // this.props.updatePhonenumber();
    // }
  }

  numberValueHandler(dialcode, number) {
    return `${dialcode}` + `${number}`;
  }

  resendOtp(event) {
    event.preventDefault();
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/generateotp",
          method: "post",
          withCredentials: true,
          data: {
            type: "mobile",
            location: "support",
            status: "resend",
            mobile: {
              number: this.props.supportNumber2,
              code: "91",
            },
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
              });
              message.success(res.data.msg);
              this.startTimer();
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
            message.error(e.msg);
          });
      }
    );
  }

  inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  componentDidUpdate(pp) {
    if (this.props.visible !== pp.visible) {
      if (!this.props.visible && this.myInterval) {
        clearInterval(this.myInterval);
      }
      if (this.props.visible) {
        this.startTimer();
      }
    }
  }

  render() {
    const { visible, onCancel } = this.props;
    const PhoneVerify =
      !!this.props.phoneotp.phoneotp1.length &&
      !!this.props.phoneotp.phoneotp2.length &&
      !!this.props.phoneotp.phoneotp3.length &&
      !!this.props.phoneotp.phoneotp4.length &&
      !!this.props.phoneotp.phoneotp5.length &&
      !!this.props.phoneotp.phoneotp6.length;

    return (
      <Modal
        className="support-email-phone-modal"
        title={
          <div className="email-header">
            <p className="support-phone-number-txt">Support Phone Number</p>
            <p className="number-verify-txt">
              Please enter the OTP received on provided number to verify
            </p>
          </div>
        }
        visible={visible}
        onCancel={onCancel}
        closable={!this.props.saving}
        afterClose={this.resetModal}
        width={456}
        footer={null}
        destroyOnClose={true}
      >
        <div>
          <div className="header-support-txt">
            <p>Support Phone Number</p>
            {this.props.chnageNumberEmail ? (
              <p>
                <button
                  className="save-number-txt"
                  onClick={() => this.updateNumber()}
                >
                  <span className="save-ican">
                    <Icon component={rightican} />
                  </span>
                  Save Phone Number
                </button>
              </p>
            ) : (
              <p>
                <button
                  className="change-number-txt"
                  onClick={() => this.props.toggleChnageNumberemail()}
                >
                  <span className="edit-ican">
                    <Icon component={editican} />
                  </span>
                  Change Number
                </button>
              </p>
            )}
          </div>

          <div className="input-number-data">
            {/* <p className="ind-91">
              {this.props.chnageNumberEmail ? (
                <Select
                  style={{ width: 100, marginTop: "7px" }}
                  value={this.props.prefix_1}
                  onChange={(val) =>
                    this.props.changePrefixState("prefix_1", val)
                  }
                  onDropdownVisibleChange={(val) =>
                    this.setState({ arrow: val })
                  }
                  suffixIcon={
                    <svg
                      style={{
                        transition: "all 0.5s",
                        transform: `${
                          this.state.arrow ? "rotateX(180deg)" : "rotateX(0deg)"
                        }`,
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="8"
                      viewBox="0 0 16 8"
                    >
                      <g
                        id="Polygon_49"
                        data-name="Polygon 49"
                        transform="translate(16 8) rotate(180)"
                        fill={`${this.state.arrow ? "#898989" : "none"}`}
                      >
                        <path d="M8,0l8,8H0Z" stroke="none" />
                        <path
                          d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                          stroke="none"
                          fill="#898989"
                        />
                      </g>
                    </svg>
                  }
                >
                  {ISDCodes.map((isd) => (
                    <Option key={`${isd.iso2.toUpperCase()} +${isd.dialCode}`}>
                      {isd.iso2.toUpperCase()} +{isd.dialCode}
                    </Option>
                  ))}
                </Select>
              ) : (
                <p>{this.props.prefix_1}</p>
              )}
            </p> */}

            <p className="input-number-txt">
              {this.props.chnageNumberEmail ? (
                // <Input
                //   type="number"
                //   placeholder="Enter text here"
                //   value={this.props.supportNumber2}
                //   onChange={(e) =>
                //     this.props.handleContactChange(e.target.value)
                //   }
                //   max={10}
                // />

                <>
                  <ReactPhoneInput
                    placeholder="Enter phone number"
                    enableSearchField
                    // disabled={this.props.phoneverified === 1 ? true : false}
                    value={this.numberValueHandler(
                      this.props.prefix_1,
                      this.props.supportNumber2
                    )}
                    onChange={(val, extra) =>
                      this.props.handleContactChange({
                        value: val,
                        extraVal: extra,
                        field: "contactno",
                      })
                    }
                  />
                </>
              ) : (
                <ReactPhoneInput
                  placeholder="Enter phone number"
                  enableSearchField
                  disabled={true}
                  value={this.numberValueHandler(
                    this.props.prefix_1,
                    this.props.supportNumber2
                  )}
                  onChange={(val, extra) =>
                    this.props.handleContactChange({
                      value: val,
                      extraVal: extra,
                      field: "contactno",
                    })
                  }
                />
              )}
            </p>
          </div>
          <p className="otp-number-txt">
            Your will receive an OTP on this number.
          </p>
        </div>

        <p className="otp-txt">Please wait 1-2 min for the OTP to arrive*</p>

        <div>
          <form onSubmit={this.handleSubmit}>
            <div className="otp-verify">
              <input
                name="otp1"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.phoneotp.phoneotp1}
                onKeyPress={this.keyPressed}
                onChange={(e) =>
                  this.props.otpChnageNumber("phoneotp1", e.target.value)
                }
                tabIndex="1"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp2"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.phoneotp.phoneotp2}
                onChange={(e) =>
                  this.props.otpChnageNumber("phoneotp2", e.target.value)
                }
                tabIndex="2"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp3"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.phoneotp.phoneotp3}
                onChange={(e) =>
                  this.props.otpChnageNumber("phoneotp3", e.target.value)
                }
                tabIndex="3"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp4"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.phoneotp.phoneotp4}
                onChange={(e) =>
                  this.props.otpChnageNumber("phoneotp4", e.target.value)
                }
                tabIndex="4"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />

              <input
                name="otp5"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.phoneotp.phoneotp5}
                onChange={(e) =>
                  this.props.otpChnageNumber("phoneotp5", e.target.value)
                }
                tabIndex="5"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp6"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.phoneotp.phoneotp6}
                onChange={(e) =>
                  this.props.otpChnageNumber("phoneotp6", e.target.value)
                }
                tabIndex="6"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
            </div>
            <div className="resend-code">
              <span>
                <button
                  className={
                    this.state.minutes == 0 && this.state.seconds === 0
                      ? "resend-code-txt "
                      : "resend-code-txt2"
                  }
                  loading={this.state.loading}
                  onClick={(e) => this.resendOtp(e)}
                >
                  You can resend code in
                </button>
              </span>
              <span>
                {this.state.minutes}:
                {this.state.seconds < 10
                  ? `0${this.state.seconds}`
                  : this.state.seconds}
              </span>
            </div>
            <Button
              type="submit"
              loading={this.props.numberOtpLoading}
              className={
                PhoneVerify ? "verify-continue-btn" : "verify-continue-btn2"
              }
              disabled={!PhoneVerify}
              onClick={() => this.props.verifyPhoneContinue()}
            >
              Verify & Continue
            </Button>
            <Button className="cancel-btn" type="submit" onClick={onCancel}>
              Cancel
            </Button>
          </form>
        </div>
      </Modal>
    );
  }
}

export default GlobalSupportPhoneNumber;
