import React from "react";
import "./index.scss";
import { Modal } from "antd";
import Wave from "../../../../../assets/images/icons/waving-hand.png";
import { ReactComponent as Rocket } from "../../../../../assets/images/icons/rocket-red.svg";
import { ReactComponent as LocationChart } from "../../../../../assets/images/icons/location-chart.svg";
import { ReactComponent as Check } from "../../../../../assets/images/icons/check-circle.svg";
import { ReactComponent as Arrow } from "../../../../../assets/images/icons/arrowright.svg";
import { Link } from "react-router-dom";
import { ReactComponent as Close } from "../../../../../assets/images/icons/close.svg";

const StepsModal = (props) => {
  const {
    PIXEL,
    PROFILE,
    CAMPAIGN,
    visible,
    username,
    firstIncomplete,
    closeModal,
  } = props;
  const domid = props.domid;
  return (
    <Modal
      className="steps-modal-let"
      closable={false}
      footer={null}
      visible={visible}
      width={800}
    >
      <Close className="close-btn" onClick={closeModal} />
      <div className="top-div">
        <div className="left">
          <h3 className="title">
            <img src={Wave} alt="wave" /> Hey Name,
          </h3>
          <p className="sub-title">Just few steps away from getting all set!</p>
          <p className="steps-ratio">
            <Rocket />
            {2 + Number(PIXEL) + Number(PROFILE) + Number(CAMPAIGN)}/5 steps
            completed
          </p>
        </div>

        <div className="right">
          <LocationChart />
        </div>
      </div>

      <div className="list-div">
        <div className="step">
          <Check />

          <div className="text">
            <h5 className="heading">Create a LetConvert account</h5>
            <p className="content">
              Sign Up for a LetConvert account and get started
            </p>
          </div>
        </div>

        <div className="step">
          <Check />

          <div className="text">
            <h5 className="heading">Set up your company domain</h5>
            <p className="content">
              Add the domain you intend to use LetConvert upon
            </p>
          </div>
        </div>

        <div
          className={`step ${
            PIXEL === 1 || PIXEL === "1"
              ? ""
              : firstIncomplete === "pixel"
              ? "first-incomplete"
              : "incomplete"
          }`}
        >
          {PIXEL === 1 || PIXEL === "1" ? (
            <Check />
          ) : (
            <p className="number">3</p>
          )}

          <div className="text">
            {PIXEL === 1 || PIXEL === "1" ? (
              <h5 className="heading">
                Add LetConvert pixel to your website <Arrow />
              </h5>
            ) : (
              <Link className="heading" to={`/${domid}/steps/3`}>
                Add LetConvert pixel to your website <Arrow />
              </Link>
            )}
            <p className="content">
              Adding our pixel won't change a thing, than just increasing
              website conversions.
            </p>
          </div>
        </div>

        <div
          className={`step ${
            PROFILE === 1 || PROFILE === "1"
              ? ""
              : firstIncomplete === "profile"
              ? "first-incomplete"
              : "incomplete"
          }`}
        >
          {PROFILE === 1 || PROFILE === "1" ? (
            <Check />
          ) : (
            <p className="number">4</p>
          )}

          <div className="text">
            {PROFILE === 1 || PROFILE === "1" ? (
              <h5 className="heading">
                Complete your profile <Arrow />
              </h5>
            ) : (
              <Link className="heading" to={`/${domid}/steps/4`}>
                Complete your profile <Arrow />
              </Link>
            )}
            <p className="content">
              Tell us a bit more about yourself for us to help you even better
            </p>
          </div>
        </div>

        <div
          className={`step ${
            CAMPAIGN === 1 || CAMPAIGN === "1"
              ? ""
              : firstIncomplete === "campaign"
              ? "first-incomplete"
              : "incomplete"
          }`}
        >
          {CAMPAIGN === 1 || CAMPAIGN === "1" ? (
            <Check />
          ) : (
            <p className="number">5</p>
          )}

          <div className="text">
            {
              <Link className="heading" to={`/${domid}/new_campaign/`}>
                Launch a campaign <Arrow />
              </Link>
            }
            <p className="content">
              Set up a LetConvert campaign and watch your conversions grow
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StepsModal;
