import React, { Component } from "react";
import { Select, Icon, Tooltip } from "antd";
// import PLACEHOLDER_PRODUCT_IMAGE from "./../../../../../../assets/images/icons/image_placeholder.png";

import { ReactComponent as Arrow } from "./../../../../../../assets/images/icons/arrow.svg";

import "./index.scss";
const { Option } = Select;

class VaraintMatchSelecter extends Component {
  state = {
    arrow: false,
  };

  render() {
    return (
      <div className="right-varaint-image-data">
        <div key={this.props.sv.variant_shopifyid}>
          <Select
            showSearch
            style={{ width: "100%", minHeight: "64px" }}
            placeholder="Select a product"
            optionFilterProp="children"
            dropdownClassName="option-varaint"
            // open={true}
            onChange={(val) =>
              this.props.mapProduct(this.props.sv.variant_shopifyid, val)
            }
            // size="large"
            // defaultValue={this.state.mapping[sv.variant_shopifyid]}
            filterOption={(input, option) =>
              option.props.children.props.children[1].props.children[2].props.children[1]
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onVisibleChange={(a) => this.setState({ arrow: !this.state.arrow })}
            suffixIcon={
              <Icon
                className={`arrow ${this.state.arrow ? "arrow-fill" : null}`}
                component={Arrow}
                style={{
                  marginLeft: "4px",
                  fontSize: "16px",
                }}
              />
            }
          >
            {this.props.aliProduct.variants.map((av) => (
              <Option
                key={av.skuPropIds}
                value={av.skuPropIds}
                className="allspec-dropdown"
              >
                <div className="allspec-data" style={{ lineHeight: "16px" }}>
                  <img
                    src={av.img ? av.img : "#"}
                    style={{
                      maxWidth: "48px",
                      marginRight: "8px",
                      border: "1px solid #979797",
                      padding: "2px",
                    }}
                    alt="#"
                  />
                  <div className="varaint-name-spac">
                    <p
                      className="allspecs-product-name"
                      style={{
                        fontSize: "12px",
                        color: "#1161FB",
                      }}
                    >
                      {this.props.aliProduct.name.length > 30 ? (
                        <Tooltip title={this.props.aliProduct.name}>
                          {`${this.props.aliProduct.name.substring(0, 25)}...`}
                        </Tooltip>
                      ) : this.props.aliProduct.name ? (
                        this.props.aliProduct.name
                      ) : (
                        "Not Available"
                      )}
                    </p>

                    <p
                      style={{ color: "#898989" }}
                      className="allspecs-product-name"
                    >
                      SKU:{" "}
                      <span style={{ color: "#000" }}>
                        {av.vfsku ? av.vfsku : "Not available"}
                      </span>
                    </p>

                    <h5
                      style={{
                        color: "#000",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                      className="allspecs-product-name"
                    >
                      <span style={{ color: "#898989" }}>Specs: </span>
                      {av.allspecs}
                    </h5>
                  </div>
                </div>
              </Option>
            ))}
          </Select>
        </div>
      </div>
    );
  }
}

export default VaraintMatchSelecter;
