import React from "react";
import { Card, Icon, Tooltip } from "antd";
import { ReactComponent as circle } from "../../../../../assets/images/icons/circle-filled.svg";
import { ReactComponent as ShipmentStatusIcon } from "../../../../../assets/images/icons/shopping-cart.svg";
import { ReactComponent as BlueTick } from "../../../../../assets/images/icons/blue-tick.svg";
import { ReactComponent as GreenTick } from "../../../../../assets/images/icons/green-tick.svg";
import { ReactComponent as PurpleTick } from "../../../../../assets/images/icons/purple-tick.svg";
import moment from "moment";
import VfIdCopyBadge from "../../../Common/VfIdCopyBadge";
// import { reasons } from "../../Shipments/DisputeModals/DisputeModal";
import OrderProductTitle from "../../../Common/GlobalCommon/ProductTitle/OrderTitle";
import { Link } from "react-router-dom";
import "./index.scss";
const reasons = {
  customer_wanted_a_different_variant: " Customer Wanted a Different Variant",
  customer_wanted_a_different_product: "Customer Wanted a Different Product",
  customer_changed_his_mind: " Customer Changed his mind",
  customer_didnt_like_the_quality_of_the_product:
    "Customer didn't like the quality of the product",
  customer_found_a_cheaper_alternative: "Customer found a cheaper alternative",
};

class ShipmentRequestCard extends React.Component {
  state = {
    rvp: false,
    shipment: this.props.shipment,
    activeTab: this.props.activeTab === "rvp" ? "info" : "lineItems",
    customerTab: {
      editing: false,
      saving: false,
    },
    errors: {},
    shipmentsVisible: {},
    refundDetail: undefined,
  };

  componentDidMount() {
    if (this.props.refundDetail !== undefined) {
      this.setState({ refundDetail: this.props.refundDetail });
    } else {
    }
  }

  // this function used  to show current tabs
  setActiveTab = (activeTab) => {
    this.setState({
      activeTab,
    });
  };

  // this function used show image modal
  showImage(index, files) {
    this.props.toggleShowImageModal(index, files);
  }

  // action status text wrap in tooltip if exceed the limit

  actionStatusText(status, reverseAwb = false) {
    if (!!reverseAwb && status && status.length >= 30) {
      return (
        <Tooltip title={status}>
          <span>{status.slice(0, 30).trim() + "..."}</span>
        </Tooltip>
      );
    } else {
      return status;
    }
  }

  render() {
    const { disputeStatusColors, disputeStatusText } = this.props;
    const refundDetail = this.state.refundDetail;

    // riverse pick up name status object
    const rvp = {
      rvp_requested: "Reverse Pickup Requested",
      rvp_request: "Reverse Pickup Requested",
      rvp_initiated: "Reverse Pickup Initiated",
      rvp_awb_map: "AWB Mapped",
      rvp_qc_check: "QC Status Updated",
    };

    // Transaction pick up name status object

    const refund = {
      refund_initiated: "Refund Requested",
      refund_closed: "Refund Request Closed",
    };

    const replacement = {
      replacement_initiated: "Customer Refund  Initiated ",
    };

    const dispute = this.props.dispute;
    const notes = dispute.notes.filter((note) => note.notetype === "note");
    const logs = dispute.notes.filter((note) => note.notetype === "log");
    let lineItems = this.props.dispute.items;

    return (
      <Card id="test" className="dispute-card">
        <div className="upper-section">
          <div
            // style={{
            //   background: disputeStatusColors[dispute.status],
            // }}
            className="vf"
          >
            <VfIdCopyBadge
              text={"VF Request ID"}
              hashSign={false}
              textToCopy={dispute.dispute_vfid}
            />
          </div>

          <div className="dispute-title">
            {this.props.activeTab === "rvp" ? (
              <span
                style={{
                  display: "flex",
                  color: "white",
                  background: disputeStatusColors[dispute.rvp_status],
                  borderRadius: "2px",
                  fontWeight: "500",
                }}
              >
                <Icon component={circle} className="circle-ican" />
                <span className="circle-ican-status">
                  {dispute.rvp_status === "close"
                    ? "Close"
                    : disputeStatusText[dispute.rvp_status]}
                </span>
              </span>
            ) : this.props.activeTab === "refund" ? (
              <span
                style={{
                  display: "flex",
                  color: "white",
                  background:
                    disputeStatusColors[dispute.refund_request_status],
                  borderRadius: "2px",
                  fontWeight: "500",
                }}
              >
                <Icon component={circle} className="circle-ican" />
                <span className="circle-ican-status">
                  {dispute.refund_request_status === "close"
                    ? "Close"
                    : disputeStatusText[dispute.refund_request_status]}
                </span>
              </span>
            ) : (
              <span
                style={{
                  display: "flex",
                  color: "white",
                  background:
                    disputeStatusColors[dispute.replacement_request_status],
                  borderRadius: "2px",
                  fontWeight: "500",
                }}
              >
                <Icon component={circle} className="circle-ican" />
                <span className="circle-ican-status">
                  {dispute.replacement_request_status === "close"
                    ? "Close"
                    : disputeStatusText[dispute.replacement_request_status]}
                </span>
              </span>
            )}
          </div>
        </div>

        <div class="fields">
          <div class="field">
            <p class="field-label"> WayBill Number (AWB):</p>
            <p class="field-value">
              {" "}
              <span className="blue-text">
                <a
                  href={dispute.tracking_url}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {dispute.dispute_awb}
                </a>
              </span>
            </p>
          </div>
          <div class="field">
            <p class="field-label">Request Raised On:</p>
            <p class="field-value">
              <span>
                {moment(dispute.created_at).format("DD MMM, YYYY | LT")}
              </span>{" "}
            </p>
          </div>
          <div class="field">
            <p class="field-label">Order Number:</p>
            <p class="field-value">
              <Link
                to={`/in/${this.props.storeid}/orders/closedOrders?search=${dispute.order_vfid}`}
                target="_blank"
                // rel="noreferrer noopener"
              >
                <span>{dispute.order_vfid}</span>
              </Link>
            </p>
          </div>
          <div class="field">
            <p class="field-label">Shopify ID:</p>
            <p class="field-value">
              {" "}
              <a
                href={dispute.shopify_url}
                rel="noreferrer noopener"
                target="_blank"
              >
                <span>{dispute.order_shopify_name}</span>{" "}
              </a>
            </p>
          </div>
        </div>
        <div className="fields action-fields">
          {dispute.hasOwnProperty("dispute_rvp_awb") &&
          !!dispute.dispute_rvp_awb ? (
            <div className="field">
              <p className="field-label">Reverse Pickup AWB:</p>
              <p className="field-value">
                {" "}
                <a
                  href={`https://track.vcfl.express/?waybill=${dispute.dispute_rvp_awb}`}
                  target="_blank"
                >
                  {dispute.dispute_rvp_awb}
                </a>{" "}
              </p>
            </div>
          ) : (
            <div className="field">
              <p className="field-label">Reverse Pickup AWB:</p>
              <p className="field-value">Not Available</p>
            </div>
          )}

          {this.props.activeTab === "rvp" ? (
            dispute.action_taken &&
            dispute.action_taken.hasOwnProperty("rvp") ? (
              <div className="field">
                <p className="field-label">Reverse Pickup Status:</p>
                <p className="field-value">
                  <GreenTick />
                  {this.actionStatusText(
                    rvp[
                      dispute.action_taken.rvp[
                        dispute.action_taken.rvp.length - 1
                      ][0]
                    ],
                    true
                  )}
                </p>
              </div>
            ) : (
              <div className="field">
                <p className="field-label">Reverse Pickup Status:</p>
                <p className="field-value">No Action Taken </p>
              </div>
            )
          ) : this.props.activeTab == "refund" ? (
            dispute.action_taken &&
            dispute.action_taken.hasOwnProperty("refund") ? (
              <div className="field">
                <p className="field-label">Customer Refund Status:</p>
                <p className="field-value">
                  <BlueTick />
                  {this.actionStatusText(
                    refund[
                      dispute.action_taken.refund[
                        dispute.action_taken.refund.length - 1
                      ][0]
                    ],
                    true
                  )}
                </p>
              </div>
            ) : (
              <div className="field">
                <p className="field-label">Customer Refund Status:</p>
                <p className="field-value">No Action Taken </p>
              </div>
            )
          ) : dispute.action_taken &&
            dispute.action_taken.hasOwnProperty("replacement") ? (
            <div className="field">
              <p className="field-label">Replacement Status:</p>
              <p className="field-value">
                <PurpleTick />
                Replacement Created
              </p>
            </div>
          ) : (
            <div className="field">
              <p className="field-label">Replacement Status:</p>
              <p className="field-value">No Action Taken </p>
            </div>
          )}
        </div>

        <div className="tabs-row">
          <ul className="row-ul">
            {this.props.activeTab === "rvp" ? (
              <li
                className={
                  this.state.activeTab === "info" ? "active" : ""
                }
                onClick={() => this.setActiveTab("info")}
              >
                Information
              </li>
            ) : null}
            <li
              className={this.state.activeTab === "lineItems" ? "active" : ""}
              onClick={() => this.setActiveTab("lineItems")}
            >
              Line Items
            </li>

            {this.props.activeTab === "refund" ? (
              <li
                className={
                  this.state.activeTab === "refund_detail" ? "active" : ""
                }
                onClick={() => this.setActiveTab("refund_detail")}
              >
                Refund Detail
              </li>
            ) : null}

            <li
              className={this.state.activeTab === "notes" ? "active" : ""}
              onClick={() => this.setActiveTab("notes")}
            >
              Notes <span className="tab-count">{notes.length}</span>
            </li>

            <li
              className={this.state.activeTab === "logs" ? "active" : ""}
              onClick={() => this.setActiveTab("logs")}
            >
              Logs <span className="tab-count">{logs.length}</span>
            </li>
          </ul>
        </div>

        {/* Line item */}
        <div
          className={`tab-container ${
            this.state.activeTab !== "lineItems" ? "hidden" : ""
          }`}
        >
          <div className="line-items-tab">
            <div className="flex header-varaint-status">
              <div className="flex-5">Variant Details</div>
              <div className="flex-6">SKU</div>
              <div className="flex-7">Quantity</div>
            </div>
            {lineItems.map((li) => {
              return (
                <div className={`line-item `} key={li.lineitem_id}>
                  <div className="flex-5">
                    <div className="line-item-product-detail">
                      <span
                        className="line-item-img"
                        style={{ background: `url(${li.img})` }}
                      />
                      <span>
                        <p className="blue-text line-item-name">
                          <OrderProductTitle
                            orderProductname={li.name}
                            productShopifyid={li.product_shopifyid}
                          />
                        </p>

                        <p className="line-item-sku">
                          <b>Specs: </b>{" "}
                          <b className="detailcommon">
                            {li.allspecs === "" ? (
                              "Default"
                            ) : li && li.allspecs && li.allspecs.length > 16 ? (
                              <Tooltip title={li.allspecs} placement="topLeft">
                                {li.allspecs.slice(0, 16) + "..."}
                              </Tooltip>
                            ) : (
                              li.allspecs
                            )}
                          </b>
                        </p>
                      </span>
                    </div>
                  </div>

                  <div className="flex-6">
                    <p className="line-item-sku">
                      <b className="detailcommon">
                        {" "}
                        {li && li.sku.length ? (
                          li.sku.length > 30 ? (
                            <Tooltip title={li.sku} placement="topLeft">
                              {li.sku.slice(0, 30) + "..."}
                            </Tooltip>
                          ) : (
                            li.sku
                          )
                        ) : (
                          "Not Available"
                        )}
                      </b>
                    </p>
                  </div>

                  <div className="flex-7">
                    <span>
                      {li.quantity === 1
                        ? `${li.quantity} piece `
                        : `${li.quantity} pieces `}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* info */}
        {this.props.activeTab === "rvp" ? (
          <div
            className={`tab-container ${
              this.state.activeTab !== "info" ? "hidden" : ""
            }`}
          >
            <div className="dispute-info-tab">
              <div className="refund-dispute-info">
                <div className="product-description">
                  <div className="fields">
                    <div className="field">
                      <p className="field-label"> Owner:</p>
                      <p className="field-value">
                        {dispute.raised_by
                          ? dispute.raised_by
                          : "Not Available"}
                      </p>
                    </div>
                    <div className="field">
                      <p className="field-label"> Request Reason :</p>
                      <p className="field-value">
                        {dispute.reverse_pickup_reason
                          ? reasons[dispute.reverse_pickup_reason]
                          : "Not Available"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* Refund Detail */}

        {this.props.activeTab === "refund" ? (
          <div
            className={`tab-container ${
              this.state.activeTab !== "refund_detail" ? "hidden" : ""
            }`}
          >
            <div className="dispute-info-tab">
              <div className="refund-dispute-info">
                <div className="product-description">
                  <div className="fields">
                    <div className="field">
                      <p className="field-label"> Customer Account Number:</p>
                      <p className="field-value">
                        {refundDetail ? refundDetail.acc_no : "Not Available"}
                      </p>
                    </div>
                    <div className="field">
                      <p className="field-label"> Customer Refund Amount:</p>
                      <p className="field-value">
                        {refundDetail ? refundDetail.amount : "Not Available"}
                      </p>
                    </div>
                    <div className="field">
                      <p className="field-label">Customer Name:</p>
                      <p className="field-value">
                        {refundDetail
                          ? refundDetail.customer_name
                          : "Not Available"}
                      </p>
                    </div>
                  </div>
                  <div className="fields ">
                    <div className="field" style={{ marginTop: "10px" }}>
                      <p className="field-label"> Customer IFSC Code:</p>
                      <p className="field-value">
                        {refundDetail
                          ? refundDetail.ifsc_code
                          : "Not Available"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* note */}
        <div
          style={{
            display: this.state.activeTab === "notes" ? "block" : "none",
          }}
          className="notes-logs-data"
        >
          {(this.state.activeTab === "notes" ? notes : logs).length > 0 ? (
            <div className="flex notes-logs-txt">
              <div className="flex-note-log">
                {this.state.activeTab === "notes" ? `Notes` : `Logs`}
              </div>
              <div className="flex-date">Date</div>
              <div className="flex-addedby">Added by</div>
            </div>
          ) : null}

          {(this.state.activeTab === "notes" ? notes : logs).length > 0 ? (
            (this.state.activeTab === "notes" ? notes : logs).map(
              //  flex  flex-2 flex-3 and flex-1 used to fex grid system
              (note, idx) => (
                <div className="flex note-logs" key={idx}>
                  <div className="flex-note-log">
                    {" "}
                    {note && note.note.length <= 60 ? (
                      note.note
                    ) : (
                      <Tooltip title={note.note}>
                        {" "}
                        {note.note ? `${note.note.substring(0, 60)}...` : null}
                      </Tooltip>
                    )}
                  </div>
                  <div className="flex-date">
                    {moment(note.date).format("DD MMM, YYYY | HH:mm")}
                  </div>
                  <div className="flex-addedby">by {note.added_by}</div>
                </div>
              )
            )
          ) : (
            <div className="no-found">
              No {this.state.activeTab === "notes" ? `Notes` : `Logs`} Found!
            </div>
          )}
        </div>

        {/* logs */}
        <div
          style={{
            display: this.state.activeTab === "logs" ? "block" : "none",
          }}
          className="notes-logs-data"
        >
          {(this.state.activeTab === "notes" ? notes : logs).length > 0 ? (
            <div className="flex escalation-status-wraper">
              {/* Progress status for escalation */}

              {this.props.activeTab === "rvp" ? (
                <div className="progress-line">
                  <div
                    className="progress"
                    style={{
                      animation:
                        dispute.rvp_status === "open"
                          ? "load-25 1.2s linear forwards"
                          : dispute.rvp_status === "in_processing"
                          ? "load-50 1.2s linear forwards"
                          : dispute.rvp_status === "close"
                          ? "load-100 1.2s linear forwards"
                          : "",
                    }}
                  />

                  <div className="status">
                    <div
                      className={`dot ${
                        dispute.rvp_status === "open" ||
                        dispute.rvp_status === "close" ||
                        dispute.rvp_status === "in_processing"
                          ? "completed"
                          : ""
                      } ${dispute.status === "open" ? "dot-active" : ""} `}
                    />

                    <div className="time-and-status-wraper">
                      <div
                        className={
                          dispute.rvp_status === "open" ||
                          dispute.rvp_status === "close" ||
                          dispute.rvp_status === "in_processing"
                            ? "shipment_step_color"
                            : "original_color"
                        }
                      >
                        Open
                      </div>
                      <div className="date">
                        {" "}
                        {moment(dispute.created_at).format("DD MMM, YYYY")}
                      </div>
                    </div>
                  </div>

                  <div className="status">
                    <div
                      className={`dot ${
                        dispute.rvp_status === "close" ||
                        dispute.rvp_status === "in_processing"
                          ? "completed"
                          : ""
                      } ${
                        dispute.rvp_status === "in_processing"
                          ? "dot-active"
                          : ""
                      }`}
                    />

                    <div className="time-and-status-wraper">
                      <div
                        className={
                          dispute.rvp_status === "close" ||
                          dispute.rvp_status === "in_processing"
                            ? "shipment_step_color"
                            : "original_color"
                        }
                      >
                        Processing
                      </div>

                      {dispute.rvp_status === "in_processing" ||
                      dispute.rvp_status === "close" ? (
                        <div className="date">
                          {" "}
                          {moment(dispute.in_processing_date).format(
                            "DD MMM, YYYY"
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="status" style={{ left: "1px" }}>
                    <div
                      className={`dot ${
                        dispute.rvp_status === "close" ? "completed" : ""
                      } ${dispute.rvp_status === "close" ? "dot-active" : ""}`}
                    />

                    <div
                      className="time-and-status-wraper"
                      style={{ marginLeft: "-64px" }}
                    >
                      <div
                        className={
                          dispute.rvp_status === "close"
                            ? "shipment_step_color"
                            : "original_color"
                        }
                      >
                        Closed
                      </div>

                      {dispute.rvp_status === "close" ? (
                        <div className="date">
                          {" "}
                          {moment(dispute.closed_on).format("DD MMM, YYYY")}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : this.props.activeTab === "refund" ? (
                <div className="progress-line">
                  <div
                    className="progress"
                    style={{
                      animation:
                        dispute.refund_request_status === "open"
                          ? "load-25 1.2s linear forwards"
                          : dispute.refund_request_status === "in_processing"
                          ? "load-50 1.2s linear forwards"
                          : dispute.refund_request_status === "close"
                          ? "load-100 1.2s linear forwards"
                          : "",
                    }}
                  />

                  <div className="status">
                    <div
                      className={`dot ${
                        dispute.refund_request_status === "open" ||
                        dispute.refund_request_status === "close" ||
                        dispute.refund_request_status === "in_processing"
                          ? "completed"
                          : ""
                      } ${
                        dispute.refund_request_status === "open"
                          ? "dot-active"
                          : ""
                      } `}
                    />

                    <div className="time-and-status-wraper">
                      <div
                        className={
                          dispute.refund_request_status === "open" ||
                          dispute.refund_request_status === "close" ||
                          dispute.refund_request_status === "in_processing"
                            ? "shipment_step_color"
                            : "original_color"
                        }
                      >
                        Open
                      </div>
                      <div className="date">
                        {" "}
                        {moment(dispute.created_at).format("DD MMM, YYYY")}
                      </div>
                    </div>
                  </div>

                  <div className="status">
                    <div
                      className={`dot ${
                        dispute.refund_request_status === "close" ||
                        dispute.refund_request_status === "in_processing"
                          ? "completed"
                          : ""
                      } ${
                        dispute.refund_request_status === "in_processing"
                          ? "dot-active"
                          : ""
                      }`}
                    />

                    <div className="time-and-status-wraper">
                      <div
                        className={
                          dispute.refund_request_status === "close" ||
                          dispute.refund_request_status === "in_processing"
                            ? "shipment_step_color"
                            : "original_color"
                        }
                      >
                        Processing
                      </div>

                      {dispute.refund_request_status === "in_processing" ||
                      dispute.refund_request_status === "close" ? (
                        <div className="date">
                          {" "}
                          {moment(dispute.in_processing_date).format(
                            "DD MMM, YYYY"
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="status" style={{ left: "1px" }}>
                    <div
                      className={`dot ${
                        dispute.refund_request_status === "close"
                          ? "completed"
                          : ""
                      } ${
                        dispute.refund_request_status === "close"
                          ? "dot-active"
                          : ""
                      }`}
                    />

                    <div
                      className="time-and-status-wraper"
                      style={{ marginLeft: "-64px" }}
                    >
                      <div
                        className={
                          dispute.refund_request_status === "close"
                            ? "shipment_step_color"
                            : "original_color"
                        }
                      >
                        Closed
                      </div>

                      {dispute.refund_request_status === "close" ? (
                        <div className="date">
                          {" "}
                          {moment(dispute.closed_on).format("DD MMM, YYYY")}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="progress-line">
                  <div
                    className="progress"
                    style={{
                      animation:
                        dispute.replacement_request_status === "open"
                          ? "load-25 1.2s linear forwards"
                          : dispute.replacement_request_status ===
                            "in_processing"
                          ? "load-50 1.2s linear forwards"
                          : dispute.replacement_request_status === "close"
                          ? "load-100 1.2s linear forwards"
                          : "",
                    }}
                  />

                  <div className="status">
                    <div
                      className={`dot ${
                        dispute.replacement_request_status === "open" ||
                        dispute.replacement_request_status === "close" ||
                        dispute.replacement_request_status === "in_processing"
                          ? "completed"
                          : ""
                      } ${
                        dispute.replacement_request_status === "open"
                          ? "dot-active"
                          : ""
                      } `}
                    />

                    <div className="time-and-status-wraper">
                      <div
                        className={
                          dispute.replacement_request_status === "open" ||
                          dispute.replacement_request_status === "close" ||
                          dispute.replacement_request_status === "in_processing"
                            ? "shipment_step_color"
                            : "original_color"
                        }
                      >
                        Open
                      </div>
                      <div className="date">
                        {" "}
                        {moment(dispute.created_at).format("DD MMM, YYYY")}
                      </div>
                    </div>
                  </div>

                  <div className="status">
                    <div
                      className={`dot ${
                        dispute.replacement_request_status === "close" ||
                        dispute.replacement_request_status === "in_processing"
                          ? "completed"
                          : ""
                      } ${
                        dispute.replacement_request_status === "in_processing"
                          ? "dot-active"
                          : ""
                      }`}
                    />

                    <div className="time-and-status-wraper">
                      <div
                        className={
                          dispute.replacement_request_status === "close" ||
                          dispute.replacement_request_status === "in_processing"
                            ? "shipment_step_color"
                            : "original_color"
                        }
                      >
                        Processing
                      </div>

                      {dispute.replacement_request_status === "in_processing" ||
                      dispute.replacement_request_status === "close" ? (
                        <div className="date">
                          {" "}
                          {moment(dispute.in_processing_date).format(
                            "DD MMM, YYYY"
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="status" style={{ left: "1px" }}>
                    <div
                      className={`dot ${
                        dispute.replacement_request_status === "close"
                          ? "completed"
                          : ""
                      } ${
                        dispute.replacement_request_status === "close"
                          ? "dot-active"
                          : ""
                      }`}
                    />

                    <div
                      className="time-and-status-wraper"
                      style={{ marginLeft: "-64px" }}
                    >
                      <div
                        className={
                          dispute.replacement_request_status === "close"
                            ? "shipment_step_color"
                            : "original_color"
                        }
                      >
                        Closed
                      </div>

                      {dispute.replacement_request_status === "close" ? (
                        <div className="date">
                          {" "}
                          {moment(dispute.closed_on).format("DD MMM, YYYY")}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : null}

          {/* <div className="note">
            NOTE: The following escalation status dates are under IST timezone:
          </div> */}

          <div
            className={`logs-wraper ${
              !!logs && logs.length <= 3 ? "single-log" : ""
            }`}
          >
            {(this.state.activeTab === "notes" ? notes : logs).length > 0 ? (
              (this.state.activeTab === "notes" ? notes : logs).map(
                //  flex  flex-2 flex-3 and flex-1 used to fex grid system
                (note, idx) => (
                  <div className="flex note-logs status-logs" key={idx}>
                    <div className="flex-date">
                      <div
                        className={`circle ${
                          logs.length === 1 ? "single-circle" : ""
                        }`}
                      />

                      <div className="date-concat">
                        <span className="font-weight">
                          {moment(note.date).format("DD MMM, YYYY")}
                        </span>

                        <span>
                          <span className="line">|</span>
                          {moment(note.date).format("dddd")}{" "}
                          <span className="line">|</span>
                        </span>

                        <span>{moment(note.date).format("LT")} </span>
                      </div>
                    </div>

                    <div className="flex-note-log">
                      {" "}
                      {note && note.note.length <= 60 ? (
                        note.note
                      ) : (
                        <Tooltip title={note.note}>
                          {" "}
                          {note.note
                            ? `${note.note.substring(0, 60)}...`
                            : null}
                        </Tooltip>
                      )}
                    </div>

                    <div className="flex-addedby">Added by {note.added_by}</div>
                  </div>
                )
              )
            ) : (
              <div className="no-found">
                No {this.state.activeTab === "notes" ? `Notes` : `Logs`} Found!
              </div>
            )}
          </div>
        </div>
      </Card>
    );
  }
}

export default ShipmentRequestCard;
