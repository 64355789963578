import React, { Component } from "react";
import LoginPageContainer from "..//LoginPageContainer";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signUp } from "../../../store/actions/authActions";
import { Form, Icon, Input, Button, Alert, Tabs, message, Card } from "antd";
import axios from "axios";
const TabPane = Tabs.TabPane;

class RegisterForm extends Component {
  state = {
    loading: true,
    invalid_link: false,
    link_validation_error: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        axios({
          url: process.env.REACT_APP_API_URL + "/login/subuser_signup",
          method: "post",
          data: {
            ...values,
            hash: this.props.match.params.hash,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.props.history.push("/switch-store");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
          });
      }
    });
  };

  componentDidMount() {
    document.title = this.props.title;

    axios({
      url: process.env.REACT_APP_API_URL + "/login/get_subuser",
      method: "post",
      data: {
        hash: this.props.match.params.hash,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            loading: false,
          });
          this.props.form.setFieldsValue({
            ...res.data.user,
          });
        } else {
          this.setState({
            loading: false,
            invalid_link: true,
            link_validation_error: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
          link_validation_error: true,
          invalid_link: false,
        });
      });
  }

  componentWillReceiveProps(props) {
    document.title = props.title;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        axios({
          url: process.env.REACT_APP_API_URL + "/login/subuser_signup",
          method: "post",
          data: {
            ...values,
            hash: this.props.match.params.hash,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.props.history.push("/switch-store");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
          });
      }
    });
  };

  render() {
    const { signupError } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <LoginPageContainer>
        {this.state.loading && <Card loading={this.state.loading} />}
        {!this.state.loading && this.state.link_validation_error && (
          <Alert
            style={{ marginTop: 30 }}
            message="Error"
            description="There was an error. Please try refreshing the page."
            type="error"
            showIcon
          />
        )}

        {!this.state.loading && this.state.invalid_link && (
          <Alert
            style={{ marginTop: 30 }}
            message="Error"
            description="You seem to have followed an invalid link"
            type="error"
            showIcon
          />
        )}

        {!this.state.loading &&
          !this.state.link_validation_error &&
          !this.state.invalid_link && (
            <Tabs defaultActiveKey="1">
              <TabPane tab="Join vFulfill" key="1">
                <Form
                  onSubmit={this.handleSubmit}
                  className="login-form"
                  noValidate
                >
                  <p className="bold register-tag-line">Enter Your Details</p>
                  <Form.Item>
                    {getFieldDecorator("first_name", {
                      rules: [
                        {
                          required: true,
                          type: "string",
                          message: "Please enter your first name!",
                        },
                      ],
                    })(
                      <Input
                        size="large"
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type="string"
                        placeholder="Your First Name"
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator("last_name", {
                      rules: [
                        {
                          required: false,
                          type: "string",
                          message: "Please enter your last name!",
                        },
                      ],
                    })(
                      <Input
                        size="large"
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type="string"
                        placeholder="Your Last Name"
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          required: true,
                          type: "email",
                          message: "Please enter a valid Email Id!",
                        },
                      ],
                    })(
                      <Input
                        size="large"
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type="email"
                        placeholder="Your Email Id"
                        disabled
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter your Password!",
                        },
                      ],
                    })(
                      <Input
                        size="large"
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type="password"
                        placeholder="Your Password"
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {signupError && (
                      <Alert message={signupError} type="error" />
                    )}
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      Let’s Get Ahead
                    </Button>
                    Already have an account?{" "}
                    <Link to="/login" className="login-form-signup-here">
                      Log In Here
                    </Link>
                  </Form.Item>
                </Form>
              </TabPane>
            </Tabs>
          )}
      </LoginPageContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    signupError: state.auth.signupError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (creds) => dispatch(signUp(creds)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(RegisterForm));
