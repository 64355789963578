import { Select } from "antd";
import React, { Component } from "react";
import "./index.scss";
const { Option } = Select;
export default class Container3 extends Component {
  render() {
    let {
      disabled,
      handleColorChange,
      headerbarColour,
      footerbarColour,
      buttonColor,
      buttonTextColor,
      headerTextColor,
      footerTextColor,
    } = this.props;
    return (
      <div className="colorContainer">
        <div className="sub-heading">Theme Settings</div>
        <div className="title-select">Select Theme</div>
        <div className="select-theme">
          <Select
            style={{ width: "200px" }}
            value={this.props.selecttheme}
            onChange={(e) => this.props.handleThemeChange(e)}
            disabled={this.props.disabled}
          >
            <Option value={1}>Theme Purple </Option>
            <Option value={2}>Theme Green </Option>
            <Option value={3}>Theme Blue</Option>
            <Option value={0}>Default theme</Option>
            <Option value={4} disabled>
              Custom theme
            </Option>
          </Select>
        </div>

        <div className="cointainer-input">
          <div className="container-main">
            <div className="title-input"> Header Background Colour </div>
            <input
              disabled={disabled}
              type="color"
              value={headerbarColour}
              onChange={(e) =>
                handleColorChange({ name: "header_bar", value: e.target.value })
              }
            />
            <span className="colour-text">{headerbarColour}</span>
          </div>

          <div className="container-main">
            <div className="title-input">Footer Background Colour</div>
            <input
              disabled={disabled}
              type="color"
              value={footerbarColour}
              onChange={(e) =>
                handleColorChange({ name: "footer_bar", value: e.target.value })
              }
            />
            <span className="colour-text">{footerbarColour}</span>
          </div>
          <div className="container-main">
            <div className="title-input"> Button Colour </div>
            <input
              disabled={disabled}
              type="color"
              value={buttonColor}
              onChange={(e) =>
                handleColorChange({ name: "button", value: e.target.value })
              }
            />
            <span className="colour-text">{buttonColor}</span>
          </div>
        </div>
        <div className="cointainer-input">
          {/* <div className="container-main">
            <div className="title-input">Header Text Colour</div>
            <input
              disabled={disabled}
              type="color"
              value={headerTextColor}
              onChange={(e) =>
                handleColorChange({
                  name: "header_text",
                  value: e.target.value,
                })
              }
            />
            <span className="colour-text">{headerTextColor}</span>
          </div> */}
          <div className="container-main">
            <div className="title-input"> Footer Text Colour </div>
            <input
              disabled={disabled}
              type="color"
              value={footerTextColor}
              onChange={(e) =>
                handleColorChange({
                  name: "footer_text",
                  value: e.target.value,
                })
              }
            />
            <span className="colour-text">{footerTextColor}</span>
          </div>
          <div className="container-main">
            <div className="title-input"> Button Text Colour </div>
            <input
              disabled={disabled}
              type="color"
              value={buttonTextColor}
              onChange={(e) =>
                handleColorChange({
                  name: "button_text",
                  value: e.target.value,
                })
              }
            />
            <span className="colour-text">{buttonTextColor}</span>
          </div>
        </div>
      </div>
    );
  }
}
