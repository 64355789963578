import React, { Component } from "react";
import "./index.scss";
// import qs from "qs";
import axios from "axios";
import Globalsearch from "../../../Common/Globalsearch2";

import {
  Select,
  DatePicker,
  Icon,
  message,
  Dropdown,
  Menu,
  Button,
} from "antd";
import { ReactComponent as ShipmentStatusIcon } from "../../../../../assets/images/icons/shopping-cart.svg";
import { ReactComponent as PaymentStatusIcon } from "../../../../../assets/images/icons/payment_status_icon.svg";
import VideoTooltip from "../../../Common/VideoTooltip";

import Sorter from "../../../Common/Sorter";
import moment from "moment";
import { SHOPIFY_NAME } from "../../../../../constants";
import MavFilter from "../MavFilterWithNegativeStatus";
import MavRiskFilter from "../MavFilter";
import TagsFilter from "../TagFilter";
const { Option } = Select;
const { RangePicker } = DatePicker;

class Ordernav extends Component {
  state = {
    filters: {
      startDate: moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      dateType: "shopify",
      search: "",
      fulfillmentStatus: [],
      paymentStatus: [],
      vfOrderStatus: [],
      shipmentStatus: [],
      closeorderStatus: [],
      pagename: [],
    },

    paging: {
      page: 1,
      per_page_count: 20,
    },
    loading: true,
    error: false,
    errorMsg: "",
    orders: [],
    selected: {},
    selectedConfirmed: {},
    tags: [],
    total: 0,
    store_settings: null,
    currencies: null,
    selectAllOrders: false,

    tabIsProcessing: false,

    toBeShippedBtn: false,
    shippedBtn: false,

    toBeShippedBtn2: false,
    shippedBtn2: false,
    allBtn2: true,

    openbtn: true,
    calling: false,
    Verifiedbtn: false,
    onholdbtn: false,
    notlinkbtn: false,
    allbtn5: false,

    openorderbtn: true,
    InProcessingbtn: false,
    ClosedOrdersbtn: false,
    allBtn: false,

    servicablebtn: false,
    notservicablebtn: false,

    deliveredbtn: true,
    cancelbtn: false,
    donotfullfilbtn: false,
    allbtn3: false,
    rtobtn: false,

    to_be_shipedbtn: false,
    placebtn: true,
    allBtn4: false,
    mount: false,
  };
  handleMenuClick(e) {
    this.props.togglebulksearchaction();
  }
  render() {
    console.log("this", this.props);
    const menu = (
      <Menu onClick={() => this.handleMenuClick()} className="bulk-search-menu">
        <Menu.Item key="1">Bulk Search</Menu.Item>
      </Menu>
    );
    if (this.props.pageType === "openorders") {
      return (
        <div id="orders-page-nav" className="fullorder_data orders-page">
          <div className="ordernav opennav">
            <div className="ordfill">
              <div className="ordfillwrap">
                <div className="left">
                  <div
                    className="breadcurmb-txt"
                    style={{
                      color: "#000",
                      fontSize: "12px",
                      letterSpacing: "0.032em",
                      lineHeight: "24px",
                    }}
                  >
                    <span
                      style={{
                        color: "#000",
                        letterSpacing: "0.032em",
                        lineHeight: "24px",
                      }}
                    >
                      Orders
                    </span>
                    <span style={{ margin: "0 4px" }}>&gt;</span>
                    <span
                      className="content-page-title"
                      style={{
                        letterSpacing: "0,032em",
                        lineHeight: "24px",
                        fontSize: "12px",
                        textDecoration: "underline",
                      }}
                    >
                      {this.props.PageTitles[this.props.pageType]}
                    </span>
                    <VideoTooltip
                      title="Orders Tab"
                      subtitle="All orders on your shopify store will be synced in this section."
                      contentHeadings={[
                        "Open Orders",
                        "In-Processing",
                        "Closed Orders",
                        "All Orders",
                      ]}
                      content={[
                        "These are all the 'Open Orders' that you have received on your Shopify store - which you have not yet Verified and Placed to vFulfill for further fulfillment.",
                        "These are your 'In-Processing orders' which you have Verified & Placed to vFulfill where the fulfillment process has been started, but have not been shipped yet.",
                        "All orders that has been closed with no further action is required on them. This includes the Delivered Orders, Returned orders, Cancelled orders & Do not fulfill orders.",
                        "These are all your orders which you have received on your Shopify store irrespective of their current Shopify or vFulfill Status.",
                      ]}
                      thumbnail="https://d24fzeiqvvundc.cloudfront.net/catalog/images/d911ab1f351b7f6faef1117048077c97.png"
                      videolink="https://d24fzeiqvvundc.cloudfront.net/images/global/dashboard/tooltips_videos/25%20Nov%202022%20Vmaker%20Recording.mp4"
                    />
                  </div>
                </div>
                <div className="right">
                  <div className="store-filters">
                    <>
                      <Sorter
                        sorterFunction={(e) => this.props.sorterFunction(e)}
                        sortingOrder={this.props.sort}
                      />
                      <div
                        style={{
                          color: "#898989",
                          fontWeight: 400,
                          margin: "0 10px 0 0",
                        }}
                      >
                        |
                      </div>
                      <div
                        // className="drop"
                        style={{
                          display: "flex",
                          float: "left",
                          alignItems: "center",
                        }}
                      >
                        <p className="dispute-status">Filter By: </p>

                        <Select
                          // className=""
                          style={{ fontWeight: 500, fontSize: 12 }}
                          value={this.props.filters.dateType}
                          onChange={(val) =>
                            this.props.shopifyOrderDateFilter({ dateType: val })
                          }
                          onDropdownVisibleChange={(val) =>
                            this.setState({ arrow1: val })
                          }
                          suffixIcon={
                            <svg
                              style={{
                                transition: "all 0.5s",
                                transform: `${
                                  this.state.arrow1
                                    ? "rotateX(180deg)"
                                    : "rotateX(0deg)"
                                }`,
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="8"
                              viewBox="0 0 16 8"
                            >
                              <g
                                id="Polygon_49"
                                data-name="Polygon 49"
                                transform="translate(16 8) rotate(180)"
                                fill={`${
                                  this.state.arrow1 ? "#898989" : "none"
                                }`}
                              >
                                <path d="M8,0l8,8H0Z" stroke="none" />
                                <path
                                  d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                                  stroke="none"
                                  fill="#898989"
                                />
                              </g>
                            </svg>
                          }
                        >
                          <Option value="shopify">{`${SHOPIFY_NAME} Order Date`}</Option>
                          <Option value="vf">VF Order Date</Option>
                        </Select>
                      </div>
                    </>
                  </div>
                  <div style={{ color: "#898989", fontWeight: 400 }}>|</div>
                  <p className="show_data_from" style={{ marginLeft: "8px" }}>
                    Show Data From:{" "}
                  </p>

                  <div ref={this.props.calenderRef}>
                    {" "}
                    <RangePicker
                      disabledDate={(current) => {
                        return current > moment();
                      }}
                      style={{ marginLeft: "10px", maxWidth: "300px" }}
                      value={[
                        this.props.filters.startDate === "" ||
                        this.props.filters.startDate === null
                          ? ""
                          : moment(this.props.filters.startDate, "YYYY-MM-DD"),
                        this.props.filters.endDate === "" ||
                        this.props.filters.startDate === null
                          ? ""
                          : moment(this.props.filters.endDate, "YYYY-MM-DD"),
                      ]}
                      ranges={{
                        Today: [moment(), moment()],
                        "This Week": [
                          moment().startOf("week"),
                          moment().endOf("week"),
                        ],
                        "Last 15 Days": [
                          moment().subtract("days", 15),
                          moment(),
                        ],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        "Last Month": [
                          moment()
                            .subtract(1, "month")
                            .startOf("month"),
                          moment()
                            .subtract(1, "month")
                            .endOf("month"),
                        ],
                        "Last Year": [
                          moment()
                            .subtract(1, "year")
                            .startOf("year"),
                          moment()
                            .subtract(1, "year")
                            .endOf("year"),
                        ],
                      }}
                      onChange={(_, dateString) =>
                        this.props.rangePickerChange({
                          startDate: dateString[0],
                          endDate: dateString[1],
                        })
                      }
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="secondwrap">
                <div className="filtertab">
                  {/* openbtn */}
                  <div
                    className={
                      this.props.loading
                        ? "non-cursor-pointer tab hover-order-underline-animation "
                        : this.props.tabIsopen &&
                          this.props.openordernav.openbtn
                        ? "tab  hover-order-underline-animation-click"
                        : "tab hover-order-underline-animation"
                    }
                  >
                    <button
                      className={
                        this.props.tabIsopen && this.props.openordernav.openbtn
                          ? "active"
                          : ""
                      }
                      value="open"
                      onClick={() => {
                        this.setState(
                          {
                            Verifiedbtn: false,
                            onholdbtn: false,
                            openbtn: true,
                            notlinkbtn: false,
                            allbtn5: false,
                          },
                          () =>
                            this.props.openordernavtoggle({
                              Verifiedbtn: false,
                              onholdbtn: false,
                              openbtn: true,
                              notlinkbtn: false,
                              allbtn5: false,
                            })
                        );
                        this.props.changeFilter({
                          vfOrderStatus: [],
                          startDate: this.props.filters.startDate,
                          endDate: this.props.filters.endDate,
                          dateType: this.props.filters.dateType,
                          search: "",
                          fulfillmentStatus: [],
                          paymentStatus: [],
                          shipmentStatus: [],
                          duplicate_orders: [],
                          closeorderStatus: [],
                          pagename: [],
                          is_servicable: [],
                          mav_filters: {
                            rto_risk: [],
                            reason: [],
                          },
                        });
                      }}
                    >
                      Open
                    </button>

                    <div
                      className={` ${
                        this.props.tabIsopen && this.props.openordernav.openbtn
                          ? "backgroundcircle"
                          : "circle"
                      }`}
                    >
                      {this.props.order_counts &&
                      this.props.order_counts.open_order
                        ? this.props.order_counts.open_order > 1000
                          ? (
                              Number(this.props.order_counts.open_order) / 1000
                            ).toFixed(1) + "k"
                          : this.props.order_counts.open_order < 1000
                          ? this.props.order_counts.open_order
                          : null
                        : this.props.order_counts.open_order === 0
                        ? "0"
                        : "..."}
                    </div>
                  </div>

                  {/* calling */}

                  <div
                    className={
                      this.props.loading
                        ? "non-cursor-pointer tab hover-order-underline-animation "
                        : this.props.tabIsopen &&
                          this.props.openordernav.calling
                        ? "tab  hover-order-underline-animation-click"
                        : "tab hover-order-underline-animation"
                    }
                  >
                    <button
                      className={
                        this.props.tabIsopen && this.props.openordernav.calling
                          ? "active"
                          : ""
                      }
                      value="calling_inprogress"
                      onClick={() => {
                        this.setState(
                          {
                            Verifiedbtn: false,
                            onholdbtn: false,
                            calling: true,
                            openbtn: false,
                            notlinkbtn: false,
                            allbtn5: false,
                          },
                          () =>
                            this.props.openordernavtoggle({
                              Verifiedbtn: false,
                              onholdbtn: false,
                              openbtn: false,
                              calling: true,
                              notlinkbtn: false,
                              allbtn5: false,
                            })
                        );
                        this.props.changeFilter({
                          vfOrderStatus: ["calling_inprogress"],
                          startDate: this.props.filters.startDate,
                          endDate: this.props.filters.endDate,
                          duplicate_orders: [],
                          dateType: this.props.filters.dateType,
                          search: "",
                          fulfillmentStatus: [],
                          paymentStatus: [],
                          shipmentStatus: [],
                          closeorderStatus: [],
                          pagename: ["calling_inprogress"],
                          is_servicable: [],
                          mav_filters: {
                            rto_risk: [],
                            reason: [],
                          },
                        });
                      }}
                    >
                      Calling
                    </button>

                    <div
                      className={` ${
                        this.props.tabIsopen && this.props.openordernav.calling
                          ? "backgroundcircle"
                          : "circle"
                      }`}
                    >
                      {this.props.order_counts &&
                      this.props.order_counts.calling_inprogress
                        ? this.props.order_counts.calling_inprogress > 1000
                          ? (
                              Number(
                                this.props.order_counts.calling_inprogress
                              ) / 1000
                            ).toFixed(1) + "k"
                          : this.props.order_counts.calling_inprogress < 1000
                          ? this.props.order_counts.calling_inprogress
                          : null
                        : this.props.order_counts.calling_inprogress === 0
                        ? "0"
                        : "..."}
                    </div>
                  </div>

                  {/* verified */}

                  <div
                    className={
                      this.props.loading
                        ? "non-cursor-pointer tab hover-order-underline-animation "
                        : this.props.tabIsopen &&
                          this.props.openordernav.Verifiedbtn
                        ? "tab  hover-order-underline-animation-click"
                        : "tab hover-order-underline-animation"
                    }
                  >
                    <button
                      className={
                        this.props.tabIsopen &&
                        this.props.openordernav.Verifiedbtn
                          ? "active"
                          : ""
                      }
                      value="verified"
                      onClick={() => {
                        this.setState(
                          {
                            Verifiedbtn: true,
                            onholdbtn: false,
                            openbtn: false,
                            notlinkbtn: false,
                            allbtn5: false,
                          },
                          () =>
                            this.props.openordernavtoggle({
                              Verifiedbtn: true,
                              onholdbtn: false,
                              openbtn: false,
                              notlinkbtn: false,
                              allbtn5: false,
                            })
                        );
                        this.props.changeFilter({
                          vfOrderStatus: ["verified"],
                          startDate: this.props.filters.startDate,
                          endDate: this.props.filters.endDate,
                          duplicate_orders: [],
                          dateType: this.props.filters.dateType,
                          search: "",
                          fulfillmentStatus: [],
                          paymentStatus: [],
                          shipmentStatus: [],
                          closeorderStatus: [],
                          pagename: ["Verified"],
                          is_servicable: [],
                          mav_filters: {
                            rto_risk: [],
                            reason: [],
                          },
                        });
                      }}
                    >
                      Verified
                    </button>

                    <div
                      className={` ${
                        this.props.tabIsopen &&
                        this.props.openordernav.Verifiedbtn
                          ? "backgroundcircle"
                          : "circle"
                      }`}
                    >
                      {this.props.order_counts &&
                      this.props.order_counts.verified_order
                        ? this.props.order_counts.verified_order > 1000
                          ? (
                              Number(this.props.order_counts.verified_order) /
                              1000
                            ).toFixed(1) + "k"
                          : this.props.order_counts.verified_order < 1000
                          ? this.props.order_counts.verified_order
                          : null
                        : this.props.order_counts.verified_order === 0
                        ? "0"
                        : "..."}
                    </div>
                  </div>

                  {/* onholdbtn */}

                  <div
                    className={
                      this.props.loading
                        ? "non-cursor-pointer tab hover-order-underline-animation "
                        : this.props.tabIsopen &&
                          this.props.openordernav.onholdbtn
                        ? "tab  hover-order-underline-animation-click"
                        : "tab hover-order-underline-animation"
                    }
                  >
                    <button
                      className={
                        this.props.tabIsopen &&
                        this.props.openordernav.onholdbtn
                          ? "active"
                          : ""
                      }
                      value="hold"
                      onClick={() => {
                        this.setState(
                          {
                            Verifiedbtn: false,
                            onholdbtn: true,
                            openbtn: false,
                            notlinkbtn: false,
                            allbtn5: false,
                          },
                          () =>
                            this.props.openordernavtoggle({
                              Verifiedbtn: false,
                              onholdbtn: true,
                              openbtn: false,
                              notlinkbtn: false,
                              allbtn5: false,
                            })
                        );
                        this.props.changeFilter({
                          vfOrderStatus: ["hold"],
                          startDate: this.props.filters.startDate,
                          endDate: this.props.filters.endDate,
                          duplicate_orders: [],
                          dateType: this.props.filters.dateType,
                          search: "",
                          fulfillmentStatus: [],
                          paymentStatus: [],
                          shipmentStatus: [],
                          closeorderStatus: [],
                          pagename: ["hold"],
                          is_servicable: [],
                          mav_filters: {
                            rto_risk: [],
                            reason: [],
                          },
                        });
                      }}
                    >
                      On Hold
                    </button>

                    <div
                      className={` ${
                        this.props.tabIsopen &&
                        this.props.openordernav.onholdbtn
                          ? "backgroundcircle"
                          : "circle"
                      }`}
                    >
                      {this.props.order_counts &&
                      this.props.order_counts.on_hold_order
                        ? this.props.order_counts.on_hold_order > 1000
                          ? (
                              Number(this.props.order_counts.on_hold_order) /
                              1000
                            ).toFixed(1) + "k"
                          : this.props.order_counts.on_hold_order < 1000
                          ? this.props.order_counts.on_hold_order
                          : null
                        : this.props.order_counts.on_hold_order === 0
                        ? "0"
                        : "..."}
                    </div>
                  </div>

                  {/* notlink*/}

                  <div
                    className={
                      this.props.loading
                        ? "non-cursor-pointer tab hover-order-underline-animation "
                        : this.props.tabIsopen &&
                          this.props.openordernav.notlinkbtn
                        ? "tab  hover-order-underline-animation-click"
                        : "tab hover-order-underline-animation"
                    }
                  >
                    <button
                      className={
                        this.props.tabIsopen &&
                        this.props.openordernav.notlinkbtn
                          ? "active"
                          : ""
                      }
                      value="notlinked"
                      onClick={() => {
                        this.setState(
                          {
                            Verifiedbtn: false,
                            onholdbtn: false,
                            openbtn: false,
                            notlinkbtn: true,
                            allbtn5: false,
                          },
                          () =>
                            this.props.openordernavtoggle({
                              Verifiedbtn: false,
                              onholdbtn: false,
                              openbtn: false,
                              notlinkbtn: true,
                              allbtn5: false,
                            })
                        );
                        this.props.changeFilter({
                          vfOrderStatus: ["notlinked"],
                          startDate: this.props.filters.startDate,
                          endDate: this.props.filters.endDate,
                          duplicate_orders: [],
                          dateType: this.props.filters.dateType,
                          search: "",
                          fulfillmentStatus: [],
                          paymentStatus: [],
                          shipmentStatus: [],
                          closeorderStatus: [],
                          pagename: ["notlinked"],
                          is_servicable: [],
                          mav_filters: {
                            rto_risk: [],
                            reason: [],
                          },
                        });
                      }}
                    >
                      Not Linked
                    </button>

                    <div
                      className={` ${
                        this.props.tabIsopen &&
                        this.props.openordernav.notlinkbtn
                          ? "backgroundcircle"
                          : "circle"
                      }`}
                    >
                      {this.props.order_counts &&
                      this.props.order_counts.notlinked_order
                        ? this.props.order_counts.notlinked_order > 1000
                          ? (
                              Number(this.props.order_counts.notlinked_order) /
                              1000
                            ).toFixed(1) + "k"
                          : this.props.order_counts.notlinked_order < 1000
                          ? this.props.order_counts.notlinked_order
                          : null
                        : this.props.order_counts.notlinked_order === 0
                        ? "0"
                        : "..."}
                    </div>
                  </div>

                  <div
                    className={
                      this.props.loading
                        ? "non-cursor-pointer tab hover-order-underline-animation "
                        : this.props.tabIsopen &&
                          this.props.openordernav.allbtn5
                        ? "tab  hover-order-underline-animation-click"
                        : "tab hover-order-underline-animation"
                    }
                  >
                    <button
                      className={
                        this.props.tabIsopen && this.props.openordernav.allbtn5
                          ? "active"
                          : ""
                      }
                      value="notlinked"
                      onClick={() => {
                        this.setState(
                          {
                            Verifiedbtn: false,
                            onholdbtn: false,
                            openbtn: false,
                            notlinkbtn: false,
                            allbtn5: true,
                          },
                          () =>
                            this.props.openordernavtoggle({
                              Verifiedbtn: false,
                              onholdbtn: false,
                              openbtn: false,
                              notlinkbtn: false,
                              allbtn5: true,
                            })
                        );
                        this.props.changeFilter({
                          vfOrderStatus: ["all"],
                          startDate: this.props.filters.startDate,
                          endDate: this.props.filters.endDate,
                          dateType: this.props.filters.dateType,
                          search: "",
                          fulfillmentStatus: [],
                          paymentStatus: [],
                          shipmentStatus: [],
                          duplicate_orders: [],
                          closeorderStatus: [],
                          pagename: ["all"],
                          is_servicable: [],
                          mav_filters: {
                            rto_risk: [],
                            reason: [],
                          },
                        });
                      }}
                    >
                      All
                    </button>

                    <div
                      className={` ${
                        this.props.tabIsopen && this.props.openordernav.allbtn5
                          ? "backgroundcircle"
                          : "circle"
                      }`}
                    >
                      {this.props.order_counts && this.props.order_counts.all
                        ? this.props.order_counts.all > 1000
                          ? (
                              Number(this.props.order_counts.all) / 1000
                            ).toFixed(1) + "k"
                          : this.props.order_counts.all < 1000
                          ? this.props.order_counts.all
                          : null
                        : this.props.order_counts.all === 0
                        ? "0"
                        : "..."}
                    </div>
                  </div>
                </div>
                <div className="download_csv">
                  <button
                    className="clickable"
                    style={{
                      width: "120px",
                    }}
                    onClick={() => this.props.downloadCSV()}
                  >
                    Download CSV
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom_data">
            <div className="Globalsearch-order2">
              {this.state.openbtn ? (
                <Globalsearch
                  class={"nextwidth2"}
                  data={this.props.filters}
                  change={(value) =>
                    this.props.changeHandler({ search: value.target.value })
                  }
                  searchFunc={(value) =>
                    this.props.changeFilter({ search: value })
                  }
                  tooltip={true}
                  placeholder={`Search by Product ID, ${SHOPIFY_NAME} ID, Customer Name, Product Name`}
                  ordervale={true}
                />
              ) : (
                <Globalsearch
                  tooltip={true}
                  class={"nextwidth2"}
                  data={this.props.filters}
                  change={(value) =>
                    this.props.changeHandler({ search: value.target.value })
                  }
                  placeholder={`Search by Product ID, ${SHOPIFY_NAME} ID, Customer Name, Product Name`}
                  searchFunc={(value) =>
                    this.props.changeFilter({ search: value })
                  }
                  ordervale={true}
                />
              )}
              <Dropdown overlay={menu} placement="bottomRight">
                <Button>
                  <svg
                    style={
                      {
                        // transition: "all 0.5s",
                        // transform: `${
                        //   this.state.arrow2 ? "rotateX(180deg)" : "rotateX(0deg)"
                        // }`,
                      }
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_49"
                      data-name="Polygon 49"
                      transform="translate(16 8) rotate(180)"
                      // fill={`${this.state.arrow2 ? "#898989" : "none"}`}
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#898989"
                      />
                    </g>
                  </svg>
                </Button>
              </Dropdown>
              {/* <div className="drop-down-bulk">
              <svg
                style={{
                  // transition: "all 0.5s",
                  // transform: `${
                  //   this.state.arrow2 ? "rotateX(180deg)" : "rotateX(0deg)"
                  // }`,
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="8"
                viewBox="0 0 16 8"
              >
                <g
                  id="Polygon_49"
                  data-name="Polygon 49"
                  transform="translate(16 8) rotate(180)"
                  // fill={`${this.state.arrow2 ? "#898989" : "none"}`}
                >
                  <path d="M8,0l8,8H0Z" stroke="none" />
                  <path
                    d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                    stroke="none"
                    fill="#898989"
                  />
                </g>
              </svg>
              <div className="bulksearch">
              <button
                className="bulk-search-button"
                onClick={() => this.props.togglebulksearchaction()}
              >
                Bulk Search
              </button>
            </div>
            </div> */}
            </div>
            {/* <div className="bulksearch">
              <button  className="bulk-search-button" onClick={() =>{
                this.props.resetModal();
                 this.props.togglebulksearchaction()
                }
                 }>
                Bulk Search
              </button>
            </div> */}

            {this.props.tabIsopen ? (
              <div
                className="drop"
                style={{
                  display: "flex",
                  float: "left",
                  alignItems: "center",
                }}
              >
                {/* <p className="dispute-status"> </p> */}

                <Select
                  style={{ fontWeight: 500, fontSize: 12, width: 288 }}
                  value={this.props.serviceableStatus}
                  // open={true}
                  className="servicebale-select"
                  placeholder="Serviceability Status: All"
                  onChange={(val) =>
                    this.props.changeFilter({
                      is_servicable: [val],
                      startDate: this.props.filters.startDate,
                      endDate: this.props.filters.endDate,
                      search: this.props.filters.search,
                    })
                  }
                  onDropdownVisibleChange={(val) =>
                    this.setState({ arrow2: val })
                  }
                  suffixIcon={
                    <svg
                      style={{
                        transition: "all 0.5s",
                        transform: `${
                          this.state.arrow2
                            ? "rotateX(180deg)"
                            : "rotateX(0deg)"
                        }`,
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="8"
                      viewBox="0 0 16 8"
                    >
                      <g
                        id="Polygon_49"
                        data-name="Polygon 49"
                        transform="translate(16 8) rotate(180)"
                        fill={`${this.state.arrow2 ? "#898989" : "none"}`}
                      >
                        <path d="M8,0l8,8H0Z" stroke="none" />
                        <path
                          d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                          stroke="none"
                          fill="#898989"
                        />
                      </g>
                    </svg>
                  }
                >
                  <Option value="" className="commondropdown">
                    {" "}
                    All
                  </Option>
                  <Option value="serviceable" className="commondropdown">
                    <Icon
                      component={this.props.vfOrderStatusIcons["serviceable"]}
                      style={{
                        color: this.props.vfOrderStatusColors["serviceable"],
                      }}
                    />{" "}
                    Serviceable
                  </Option>

                  <Option value="not_serviceable" className="commondropdown">
                    <Icon
                      component={
                        this.props.vfOrderStatusIcons["not_serviceable"]
                      }
                      style={{
                        color: this.props.vfOrderStatusColors[
                          "not_serviceable"
                        ],
                      }}
                    />{" "}
                    Not Serviceable
                  </Option>
                </Select>
              </div>
            ) : null}

            {this.props.tabIsopen ? (
              <div
                className="drop"
                style={{
                  display: "flex",
                  float: "left",
                  alignItems: "center",
                }}
              >
                {/* <p className="dispute-status"> </p> */}

                <Select
                  style={{ fontWeight: 500, fontSize: 12, width: 288 }}
                  value={this.props.duplicate_orders}
                  // open={true}
                  className="servicebale-select"
                  placeholder="Duplicate Orders: All"
                  onChange={(val) =>
                    this.props.changeFilter({
                      duplicate_orders: [val],
                      startDate: this.props.filters.startDate,
                      endDate: this.props.filters.endDate,
                      search: this.props.filters.search,
                    })
                  }
                  onDropdownVisibleChange={(val) =>
                    this.setState({ arrow3: val })
                  }
                  suffixIcon={
                    <svg
                      style={{
                        transition: "all 0.5s",
                        transform: `${
                          this.state.arrow3
                            ? "rotateX(180deg)"
                            : "rotateX(0deg)"
                        }`,
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="8"
                      viewBox="0 0 16 8"
                    >
                      <g
                        id="Polygon_49"
                        data-name="Polygon 49"
                        transform="translate(16 8) rotate(180)"
                        fill={`${this.state.arrow3 ? "#898989" : "none"}`}
                      >
                        <path d="M8,0l8,8H0Z" stroke="none" />
                        <path
                          d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                          stroke="none"
                          fill="#898989"
                        />
                      </g>
                    </svg>
                  }
                >
                  <Option value="" className="commondropdown">
                    {" "}
                    All
                  </Option>
                  <Option value="repeated" className="commondropdown">
                    {/* <Icon />  */}
                    Repeated
                  </Option>

                  <Option value="duplicate" className="commondropdown">
                    {/* <Icon /> */}
                    Duplicate
                  </Option>
                </Select>
              </div>
            ) : null}

            <div
              className="drop"
              style={{
                display: "flex",
                float: "left",
                alignItems: "center",
              }}
            >
              {/* <p className="dispute-status"> </p> */}

              <Select
                style={{ fontWeight: 500, fontSize: 12, width: 288 }}
                value={this.props.paymentStatus}
                // open={true}
                placeholder="Payment Status: All"
                onChange={(val) =>
                  this.props.changeFilter({
                    paymentStatus: [val],
                    startDate: this.props.filters.startDate,
                    endDate: this.props.filters.endDate,
                    dateType: this.props.filters.dateType,
                    search: this.props.filters.search,
                  })
                }
                onDropdownVisibleChange={(val) =>
                  this.setState({ arrow3: val })
                }
                suffixIcon={
                  <svg
                    style={{
                      transition: "all 0.5s",
                      transform: `${
                        this.state.arrow3 ? "rotateX(180deg)" : "rotateX(0deg)"
                      }`,
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_49"
                      data-name="Polygon 49"
                      transform="translate(16 8) rotate(180)"
                      fill={`${this.state.arrow3 ? "#898989" : "none"}`}
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#898989"
                      />
                    </g>
                  </svg>
                }
              >
                <Option value="all" className="commondropdown">
                  All
                </Option>
                <Option value="paid" className="commondropdown">
                  <Icon
                    component={PaymentStatusIcon}
                    style={{ color: this.props.paymentStatusColors["paid"] }}
                  />{" "}
                  Prepaid
                </Option>

                <Option value="pending" className="commondropdown">
                  <Icon
                    component={PaymentStatusIcon}
                    style={{ color: this.props.paymentStatusColors["pending"] }}
                  />{" "}
                  Cash On Delivery
                </Option>
              </Select>
            </div>

            {this.props.pageType === "openorders" ? (
              // <div className="mav-filter">
              <>
                <MavRiskFilter
                  value={this.props.filters.mav_filters.rto_risk}
                  changeHandler={(e) => this.props.mavRtoRisk(e)}
                  type="rto_risk"
                  width={288}
                  overlayClass={"mav-filter-overlay"}
                  showMavFilters={this.props.showMavFilters}
                />

                <MavFilter
                  width={288}
                  value={this.props.filters.mav_filters.reason}
                  changeHandler={(e) => this.props.mavReason(e)}
                  type="reason"
                  overlayClass={"mav-filter-overlay"}
                  showMavFilters={this.props.showMavFilters}
                />

                {!!this.props.tags &&
                  !!this.props.tags.length &&
                  this.props.store &&
                  this.props.store.store_platform === "shopify" && (
                    <TagsFilter
                      tags={this.props.tags}
                      width={288}
                      value={this.props.filters.tags}
                      changeHandler={(e) => this.props.tagChangeHandler(e)}
                      type="reason"
                      overlayClass={"mav-filter-overlay"}
                    />
                  )}
              </>
            ) : // </div>
            null}
          </div>
        </div>
      );
    } else if (this.props.pageType === "processingorders") {
      return (
        <div id="orders-page-nav" className="fullorder_data orders-page">
          <div className="ordernav opennav">
            <div className="ordfill">
              <div className="ordfillwrap">
                <div className="left">
                  <div
                    className="breadcurmb-txt"
                    style={{
                      color: "#000",
                      fontSize: "12px",
                      letterSpacing: "0.032em",
                      lineHeight: "24px",
                    }}
                  >
                    <span
                      style={{
                        color: "#000",
                        letterSpacing: "0.032em",
                        lineHeight: "24px",
                      }}
                    >
                      Orders
                    </span>
                    <span style={{ margin: "0 4px" }}>&gt;</span>
                    <span
                      className="content-page-title"
                      style={{
                        letterSpacing: "0,032em",
                        lineHeight: "24px",
                        fontSize: "12px",
                        textDecoration: "underline",
                      }}
                    >
                      {this.props.PageTitles[this.props.pageType]}
                    </span>
                    <VideoTooltip
                      title="Orders Tab"
                      subtitle="All orders on your shopify store will be synced in this section."
                      contentHeadings={[
                        "Open Orders",
                        "In-Processing",
                        "Closed Orders",
                        "All Orders",
                      ]}
                      content={[
                        "These are all the 'Open Orders' that you have received on your Shopify store - which you have not yet Verified and Placed to vFulfill for further fulfillment.",
                        "These are your 'In-Processing orders' which you have Verified & Placed to vFulfill where the fulfillment process has been started, but have not been shipped yet.",
                        "All orders that has been closed with no further action is required on them. This includes the Delivered Orders, Returned orders, Cancelled orders & Do not fulfill orders.",
                        "These are all your orders which you have received on your Shopify store irrespective of their current Shopify or vFulfill Status.",
                      ]}
                      thumbnail="https://d24fzeiqvvundc.cloudfront.net/catalog/images/d911ab1f351b7f6faef1117048077c97.png"
                      videolink="https://d24fzeiqvvundc.cloudfront.net/images/global/dashboard/tooltips_videos/Orders%20-%20In-processing%20orders.mp4"
                    />
                  </div>
                </div>
                <div className="right">
                  <div className="store-filters">
                    {(this.props.pageType === "allorders" ||
                      this.props.pageType === "processingorders") && (
                      <>
                        <Sorter
                          sorterFunction={(e) => this.props.sorterFunction(e)}
                          sortingOrder={this.props.sort}
                        />
                        <div
                          style={{
                            color: "#898989",
                            fontWeight: 400,
                            margin: "0 10px 0 0",
                          }}
                        >
                          |
                        </div>
                        <div
                          // className="drop"
                          style={{
                            display: "flex",
                            float: "left",
                            alignItems: "center",
                          }}
                        >
                          <p className="dispute-status">Filter By: </p>

                          <Select
                            style={{ fontWeight: 500, fontSize: 12 }}
                            value={this.props.filters.dateType}
                            onChange={(val) =>
                              this.props.shopifyOrderDateFilter({
                                dateType: val,
                              })
                            }
                            onDropdownVisibleChange={(val) =>
                              this.setState({ arrow: val })
                            }
                            suffixIcon={
                              <svg
                                style={{
                                  transition: "all 0.5s",
                                  transform: `${
                                    this.state.arrow
                                      ? "rotateX(180deg)"
                                      : "rotateX(0deg)"
                                  }`,
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="8"
                                viewBox="0 0 16 8"
                              >
                                <g
                                  id="Polygon_49"
                                  data-name="Polygon 49"
                                  transform="translate(16 8) rotate(180)"
                                  fill={`${
                                    this.state.arrow ? "#898989" : "none"
                                  }`}
                                >
                                  <path d="M8,0l8,8H0Z" stroke="none" />
                                  <path
                                    d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                                    stroke="none"
                                    fill="#898989"
                                  />
                                </g>
                              </svg>
                            }
                          >
                            <Option value="shopify">{`${SHOPIFY_NAME} Order Date`}</Option>
                            <Option value="vf">VF Order Date</Option>
                          </Select>
                        </div>
                      </>
                    )}
                  </div>
                  <div style={{ color: "#898989", fontWeight: 400 }}>|</div>
                  <p className="show_data_from" style={{ marginLeft: "8px" }}>
                    Show Data From:{" "}
                  </p>

                  <div ref={this.props.calenderRef}>
                    {" "}
                    <RangePicker
                      disabledDate={(current) => {
                        return current > moment();
                      }}
                      style={{ marginLeft: "10px", maxWidth: "300px" }}
                      value={[
                        this.props.filters.startDate === "" ||
                        this.props.filters.startDate === null
                          ? ""
                          : moment(this.props.filters.startDate, "YYYY-MM-DD"),
                        this.props.filters.endDate === "" ||
                        this.props.filters.startDate === null
                          ? ""
                          : moment(this.props.filters.endDate, "YYYY-MM-DD"),
                      ]}
                      ranges={{
                        Today: [moment(), moment()],
                        "This Week": [
                          moment().startOf("week"),
                          moment().endOf("week"),
                        ],
                        "Last 15 Days": [
                          moment().subtract("days", 15),
                          moment(),
                        ],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        "Last Month": [
                          moment()
                            .subtract(1, "month")
                            .startOf("month"),
                          moment()
                            .subtract(1, "month")
                            .endOf("month"),
                        ],
                        "Last Year": [
                          moment()
                            .subtract(1, "year")
                            .startOf("year"),
                          moment()
                            .subtract(1, "year")
                            .endOf("year"),
                        ],
                      }}
                      onChange={(_, dateString) =>
                        this.props.rangePickerChange({
                          startDate: dateString[0],
                          endDate: dateString[1],
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="secondwrap">
                <div className="filtertab">
                  {/* <div
                    className={
                      this.props.loading
                        ? "non-cursor-pointer tab hover-order-underline-animation "
                        : this.props.tabIsProcessing &&
                          this.props.inprocessordernav.placebtn
                        ? "tab  hover-order-underline-animation-click"
                        : "tab hover-order-underline-animation"
                    }
                  >
                    <button
                      className={
                        this.props.tabIsProcessing &&
                        this.props.inprocessordernav.placebtn
                          ? "active"
                          : ""
                      }
                      value="serviceable"
                      onClick={() => {
                        this.setState(
                          {
                            to_be_shipedbtn: false,
                            allBtn4: false,
                            placebtn: true,
                          },
                          () =>
                            this.props.inprocessnavtoggle({
                              to_be_shipedbtn: false,
                              allBtn4: false,
                              placebtn: true,
                            })
                        );
                        this.props.changeFilter({
                          vfOrderStatus: [],
                          startDate: "",
                          endDate: "",
                          dateType: "shopify",
                          search: "",
                          paymentStatus: [],
                          shipmentStatus: [],
                          pagename: [],
                        });
                      }}
                    >
                      Placed Orders
                    </button>

                    <div
                      className={` ${
                        this.props.tabIsProcessing &&
                        this.props.inprocessordernav.placebtn
                          ? "backgroundcircle"
                          : "circle"
                      }`}
                    >
                      {this.props.order_counts &&
                      this.props.order_counts.placed_order
                        ? this.props.order_counts.placed_order > 1000
                          ? (
                              Number(this.props.order_counts.placed_order) /
                              1000
                            ).toFixed(1) + "k"
                          : this.props.order_counts.placed_order < 1000
                          ? this.props.order_counts.placed_order
                          : null
                        : this.props.order_counts.placed_order === 0
                        ? "0"
                        : "..."}
                    </div>
                  </div> */}

                  {/* notservicable */}

                  {/* <div
                    className={
                      this.props.loading
                        ? "non-cursor-pointer tab hover-order-underline-animation "
                        : this.props.tabIsProcessing &&
                          this.props.inprocessordernav.to_be_shipedbtn
                        ? "tab  hover-order-underline-animation-click"
                        : "tab hover-order-underline-animation"
                    }
                  >
                    <button
                      className={
                        this.props.tabIsProcessing &&
                        this.props.inprocessordernav.to_be_shipedbtn
                          ? "active"
                          : ""
                      }
                      value="processing"
                      onClick={() => {
                        this.setState(
                          {
                            to_be_shipedbtn: true,
                            allBtn4: false,
                            placebtn: false,
                          },
                          () =>
                            this.props.inprocessnavtoggle({
                              to_be_shipedbtn: true,
                              allBtn4: false,
                              placebtn: false,
                            })
                        );
                        this.props.changeFilter({
                          shipmentStatus: ["processing"],
                          startDate: "",
                          endDate: "",
                          dateType: "shopify",
                          search: "",
                          paymentStatus: [],
                          vfOrderStatus: [],
                          pagename: ["to_Be_Shipped"],
                        });
                      }}
                    >
                      To Be Shipped
                    </button>

                    <div
                      className={` ${
                        this.props.tabIsProcessing &&
                        this.props.inprocessordernav.to_be_shipedbtn
                          ? "backgroundcircle"
                          : "circle"
                      }`}
                    >
                      {this.props.order_counts &&
                      this.props.order_counts.to_be_shipped
                        ? this.props.order_counts.to_be_shipped > 1000
                          ? (
                              Number(this.props.order_counts.to_be_shipped) /
                              1000
                            ).toFixed(1) + "k"
                          : this.props.order_counts.to_be_shipped < 1000
                          ? this.props.order_counts.to_be_shipped
                          : null
                        : this.props.order_counts.to_be_shipped === 0
                        ? "0"
                        : "..."}
                    </div>
                  </div> */}

                  <div
                    className={
                      this.props.loading
                        ? "non-cursor-pointer tab hover-order-underline-animation "
                        : this.props.tabIsProcessing &&
                          this.props.inprocessordernav.allBtn4
                        ? "tab  hover-order-underline-animation-click"
                        : "tab hover-order-underline-animation"
                    }
                  >
                    <button
                      className={
                        this.props.tabIsProcessing &&
                        this.props.inprocessordernav.allBtn4
                          ? "active"
                          : ""
                      }
                      value=""
                      onClick={() => {
                        this.setState(
                          {
                            to_be_shipedbtn: false,
                            allBtn4: true,
                            placebtn: false,
                          },
                          () =>
                            this.props.inprocessnavtoggle({
                              to_be_shipedbtn: false,
                              allBtn4: true,
                              placebtn: false,
                            })
                        );
                        this.props.changeFilter({
                          vfOrderStatus: ["all"],
                          startDate: this.props.filters.startDate,
                          endDate: this.props.filters.endDate,
                          dateType: this.props.filters.dateType,
                          search: "",
                          paymentStatus: [],
                          pagename: ["all"],
                          shipmentStatus: [],
                        });
                      }}
                    >
                      In-Processing
                    </button>

                    <div
                      className={` ${
                        this.props.tabIsProcessing &&
                        this.props.inprocessordernav.allBtn4
                          ? "backgroundcircle"
                          : "circle"
                      }`}
                    >
                      {this.props.order_counts && this.props.order_counts.all
                        ? this.props.order_counts.all > 1000
                          ? (
                              Number(this.props.order_counts.all) / 1000
                            ).toFixed(1) + "k"
                          : this.props.order_counts.all < 1000
                          ? this.props.order_counts.all
                          : null
                        : this.props.order_counts.all === 0
                        ? "0"
                        : "..."}
                    </div>
                  </div>
                </div>

                <div className="download_csv">
                  <button
                    className="clickable"
                    style={{
                      width: "120px",
                    }}
                    onClick={() => this.props.downloadCSV()}
                  >
                    Download CSV
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom_data">
            <div className="Globalsearch-order2">
              <Globalsearch
                tooltip={true}
                class={"nextwidth"}
                data={this.props.filters}
                placeholder={`Search by Product ID, ${SHOPIFY_NAME} ID, Customer Name, Product Name`}
                change={(value) =>
                  this.props.changeHandler({ search: value.target.value })
                }
                searchFunc={(value) =>
                  this.props.changeFilter({ search: value })
                }
                ordervale={true}
              />
              <Dropdown overlay={menu} placement="bottomRight">
                <Button>
                  <svg
                    style={
                      {
                        // transition: "all 0.5s",
                        // transform: `${
                        //   this.state.arrow2 ? "rotateX(180deg)" : "rotateX(0deg)"
                        // }`,
                      }
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_49"
                      data-name="Polygon 49"
                      transform="translate(16 8) rotate(180)"
                      // fill={`${this.state.arrow2 ? "#898989" : "none"}`}
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#898989"
                      />
                    </g>
                  </svg>
                </Button>
              </Dropdown>
            </div>

            <div
              className="drop"
              style={{
                display: "flex",
                float: "left",
                alignItems: "center",
              }}
            >
              {/* <p className="dispute-status"> </p> */}

              <Select
                style={{ fontWeight: 500, fontSize: 12, width: "288px" }}
                value={this.props.paymentStatus}
                placeholder="Payment Status: All"
                onChange={(val) =>
                  this.props.changeFilter({
                    paymentStatus: [val],
                    startDate: this.props.filters.startDate,
                    endDate: this.props.filters.endDate,
                    dateType: this.props.filters.dateType,
                    search: this.props.filters.search,
                    vfOrderStatus: [],
                    shipmentStatus: [],
                  })
                }
                onDropdownVisibleChange={(val) =>
                  this.setState({ arrow3: val })
                }
                suffixIcon={
                  <svg
                    style={{
                      transition: "all 0.5s",
                      transform: `${
                        this.state.arrow3 ? "rotateX(180deg)" : "rotateX(0deg)"
                      }`,
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_49"
                      data-name="Polygon 49"
                      transform="translate(16 8) rotate(180)"
                      fill={`${this.state.arrow3 ? "#898989" : "none"}`}
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#898989"
                      />
                    </g>
                  </svg>
                }
              >
                <Option value="all" className="commondropdown">
                  All
                </Option>
                <Option value="paid" className="commondropdown">
                  <Icon
                    component={PaymentStatusIcon}
                    style={{ color: this.props.paymentStatusColors["paid"] }}
                  />{" "}
                  Prepaid
                </Option>

                <Option value="pending" className="commondropdown">
                  <Icon
                    component={PaymentStatusIcon}
                    style={{ color: this.props.paymentStatusColors["pending"] }}
                  />{" "}
                  Cash On Delivery
                </Option>
                {/* <Option value="refunded">
                  <Icon
                    component={PaymentStatusIcon}
                    style={{
                      color: this.props.paymentStatusColors["refunded"],
                    }}
                  />{" "}
                  Refunded
                </Option> */}
              </Select>
            </div>
          </div>
        </div>
      );
    } else if (this.props.pageType === "closedOrders") {
      return (
        <div id="orders-page-nav" className="fullorder_data orders-page">
          <div className="ordernav opennav">
            <div className="ordfill">
              <div className="ordfillwrap">
                <div className="left">
                  <div
                    className="breadcurmb-txt"
                    style={{
                      color: "#000",
                      fontSize: "12px",
                      letterSpacing: "0.032em",
                      lineHeight: "24px",
                    }}
                  >
                    <span
                      style={{
                        color: "#000",
                        letterSpacing: "0.032em",
                        lineHeight: "24px",
                      }}
                    >
                      Orders
                    </span>
                    <span style={{ margin: "0 4px" }}>&gt;</span>
                    <span
                      className="content-page-title"
                      style={{
                        letterSpacing: "0,032em",
                        lineHeight: "24px",
                        fontSize: "12px",
                        textDecoration: "underline",
                      }}
                    >
                      {this.props.PageTitles[this.props.pageType]}
                    </span>
                    <VideoTooltip
                      title="Orders Tab"
                      subtitle="All orders on your shopify store will be synced in this section."
                      contentHeadings={[
                        "Open Orders",
                        "In-Processing",
                        "Closed Orders",
                        "All Orders",
                      ]}
                      content={[
                        "These are all the 'Open Orders' that you have received on your Shopify store - which you have not yet Verified and Placed to vFulfill for further fulfillment.",
                        "These are your 'In-Processing orders' which you have Verified & Placed to vFulfill where the fulfillment process has been started, but have not been shipped yet.",
                        "All orders that has been closed with no further action is required on them. This includes the Delivered Orders, Returned orders, Cancelled orders & Do not fulfill orders.",
                        "These are all your orders which you have received on your Shopify store irrespective of their current Shopify or vFulfill Status.",
                      ]}
                      thumbnail="https://d24fzeiqvvundc.cloudfront.net/catalog/images/d911ab1f351b7f6faef1117048077c97.png"
                      videolink="https://d24fzeiqvvundc.cloudfront.net/images/global/dashboard/tooltips_videos/Orders%20-%20Closed%20Orders.mp4"
                    />
                  </div>
                </div>
                <div className="right">
                  <div className="store-filters">
                    <>
                      <Sorter
                        sorterFunction={(e) => this.props.sorterFunction(e)}
                        sortingOrder={this.props.sort}
                      />
                      <div
                        style={{
                          color: "#898989",
                          fontWeight: 400,
                          margin: "0 10px 0 0",
                        }}
                      >
                        |
                      </div>
                      <div
                        // className="drop"
                        style={{
                          display: "flex",
                          float: "left",
                          alignItems: "center",
                        }}
                      >
                        <p className="dispute-status">Filter By: </p>

                        <Select
                          // className=""
                          style={{ fontWeight: 500, fontSize: 12 }}
                          value={this.props.filters.dateType}
                          onChange={(val) =>
                            this.props.shopifyOrderDateFilter({ dateType: val })
                          }
                          onDropdownVisibleChange={(val) =>
                            this.setState({ arrow: val })
                          }
                          suffixIcon={
                            <svg
                              style={{
                                transition: "all 0.5s",
                                transform: `${
                                  this.state.arrow
                                    ? "rotateX(180deg)"
                                    : "rotateX(0deg)"
                                }`,
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="8"
                              viewBox="0 0 16 8"
                            >
                              <g
                                id="Polygon_49"
                                data-name="Polygon 49"
                                transform="translate(16 8) rotate(180)"
                                fill={`${
                                  this.state.arrow ? "#898989" : "none"
                                }`}
                              >
                                <path d="M8,0l8,8H0Z" stroke="none" />
                                <path
                                  d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                                  stroke="none"
                                  fill="#898989"
                                />
                              </g>
                            </svg>
                          }
                        >
                          <Option value="shopify">{`${SHOPIFY_NAME} Order Date`}</Option>
                          <Option value="vf">VF Order Date</Option>
                        </Select>
                      </div>
                    </>
                  </div>

                  <div style={{ color: "#898989", fontWeight: 400 }}>|</div>
                  <p className="show_data_from" style={{ marginLeft: "8px" }}>
                    Show Data From:{" "}
                  </p>
                  <div ref={this.props.calenderRef}>
                    {" "}
                    <RangePicker
                      disabledDate={(current) => {
                        return current > moment();
                      }}
                      style={{ marginLeft: "10px", maxWidth: "300px" }}
                      value={[
                        this.props.filters.startDate === "" ||
                        this.props.filters.startDate === null
                          ? ""
                          : moment(this.props.filters.startDate, "YYYY-MM-DD"),
                        this.props.filters.endDate === "" ||
                        this.props.filters.startDate === null
                          ? ""
                          : moment(this.props.filters.endDate, "YYYY-MM-DD"),
                      ]}
                      ranges={{
                        Today: [moment(), moment()],
                        "This Week": [
                          moment().startOf("week"),
                          moment().endOf("week"),
                        ],
                        "Last 15 Days": [
                          moment().subtract("days", 15),
                          moment(),
                        ],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        "Last Month": [
                          moment()
                            .subtract(1, "month")
                            .startOf("month"),
                          moment()
                            .subtract(1, "month")
                            .endOf("month"),
                        ],
                        "Last Year": [
                          moment()
                            .subtract(1, "year")
                            .startOf("year"),
                          moment()
                            .subtract(1, "year")
                            .endOf("year"),
                        ],
                      }}
                      onChange={(_, dateString) =>
                        this.props.rangePickerChange({
                          startDate: dateString[0],
                          endDate: dateString[1],
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="secondwrap">
                <div className="filtertab">
                  <div
                    className={
                      this.props.loading
                        ? "non-cursor-pointer tab hover-order-underline-animation "
                        : this.props.tabIsClosedOrders &&
                          this.props.closeordernav.deliveredbtn
                        ? "tab  hover-order-underline-animation-click"
                        : "tab hover-order-underline-animation"
                    }
                  >
                    <button
                      className={
                        this.props.tabIsClosedOrders &&
                        this.props.closeordernav.deliveredbtn
                          ? "active"
                          : ""
                      }
                      value="delivered"
                      onClick={() => {
                        this.setState(
                          {
                            deliveredbtn: true,
                            cancelbtn: false,
                            donotfullfilbtn: false,
                            allbtn3: false,
                            rtobtn: false,
                          },
                          () =>
                            this.props.closeordernavtoggle({
                              deliveredbtn: true,
                              cancelbtn: false,
                              donotfullfilbtn: false,
                              allbtn3: false,
                              rtobtn: false,
                            })
                        );
                        this.props.changeFilter({
                          shipmentStatus: [],
                          startDate: this.props.filters.startDate,
                          endDate: this.props.filters.endDate,
                          dateType: this.props.filters.dateType,
                          search: "",
                          fulfillmentStatus: [],
                          paymentStatus: [],
                          vfOrderStatus: [],
                          closeorderStatus: [],
                          pagename: [],
                        });
                      }}
                    >
                      Delivered
                    </button>
                    <div
                      className={`${
                        this.props.tabIsClosedOrders &&
                        this.props.closeordernav.deliveredbtn
                          ? "backgroundcircle"
                          : "circle"
                      }`}
                      order_counts
                    >
                      {this.props.order_counts &&
                      this.props.order_counts.delivered_order
                        ? this.props.order_counts.delivered_order > 1000
                          ? (
                              Number(this.props.order_counts.delivered_order) /
                              1000
                            ).toFixed(1) + "k"
                          : this.props.order_counts.delivered_order < 1000
                          ? this.props.order_counts.delivered_order
                          : null
                        : this.props.order_counts.delivered_order === 0
                        ? "0"
                        : "..."}
                    </div>
                  </div>

                  <div
                    className={
                      this.props.loading
                        ? "non-cursor-pointer tab hover-order-underline-animation "
                        : this.props.tabIsClosedOrders &&
                          this.props.closeordernav.rtobtn
                        ? "tab  hover-order-underline-animation-click"
                        : "tab hover-order-underline-animation"
                    }
                  >
                    <button
                      className={
                        this.props.tabIsClosedOrders &&
                        this.props.closeordernav.rtobtn
                          ? "active"
                          : ""
                      }
                      value="not_fulfill"
                      onClick={() => {
                        this.setState(
                          {
                            deliveredbtn: false,
                            cancelbtn: false,
                            donotfullfilbtn: false,
                            allbtn3: false,
                            rtobtn: true,
                          },
                          () =>
                            this.props.closeordernavtoggle({
                              deliveredbtn: false,
                              cancelbtn: false,
                              donotfullfilbtn: false,
                              allbtn3: false,
                              rtobtn: true,
                            })
                        );
                        this.props.changeFilter({
                          shipmentStatus: ["rto"],
                          startDate: this.props.filters.startDate,
                          endDate: this.props.filters.endDate,
                          dateType: this.props.filters.dateType,
                          search: "",
                          fulfillmentStatus: [],
                          paymentStatus: [],
                          closeorderStatus: [],
                          pagename: ["rto"],
                          vfOrderStatus: [],
                        });
                      }}
                    >
                      Returned
                    </button>
                    <div
                      className={` ${
                        this.props.tabIsClosedOrders &&
                        this.props.closeordernav.rtobtn
                          ? "backgroundcircle"
                          : "circle"
                      }`}
                    >
                      {this.props.order_counts &&
                      this.props.order_counts.rto_order
                        ? this.props.order_counts.rto_order > 1000
                          ? (
                              Number(this.props.order_counts.rto_order) / 1000
                            ).toFixed(1) + "k"
                          : this.props.order_counts.rto_order < 1000
                          ? this.props.order_counts.rto_order
                          : null
                        : this.props.order_counts.rto_order === 0
                        ? "0"
                        : "..."}
                    </div>
                  </div>
                  <div
                    className={
                      this.props.loading
                        ? "non-cursor-pointer tab hover-order-underline-animation "
                        : this.props.tabIsClosedOrders &&
                          this.props.closeordernav.cancelbtn
                        ? "tab  hover-order-underline-animation-click"
                        : "tab hover-order-underline-animation"
                    }
                  >
                    <button
                      className={
                        this.props.tabIsClosedOrders &&
                        this.props.closeordernav.cancelbtn
                          ? "active"
                          : ""
                      }
                      value="cancelled"
                      onClick={() => {
                        this.setState(
                          {
                            deliveredbtn: false,
                            cancelbtn: true,
                            donotfullfilbtn: false,
                            allbtn3: false,
                            rtobtn: false,
                          },
                          () =>
                            this.props.closeordernavtoggle({
                              deliveredbtn: false,
                              cancelbtn: true,
                              donotfullfilbtn: false,
                              allbtn3: false,
                              rtobtn: false,
                            })
                        );
                        this.props.changeFilter({
                          vfOrderStatus: ["cancelled"],
                          startDate: this.props.filters.startDate,
                          endDate: this.props.filters.endDate,
                          dateType: this.props.filters.dateType,
                          search: "",
                          fulfillmentStatus: [],
                          paymentStatus: [],
                          shipmentStatus: [],
                          closeorderStatus: [],
                          pagename: ["cancelled"],
                        });
                      }}
                    >
                      Cancelled
                    </button>

                    <div
                      className={`${
                        this.props.tabIsClosedOrders &&
                        this.props.closeordernav.cancelbtn
                          ? "backgroundcircle"
                          : "circle"
                      }`}
                    >
                      {this.props.order_counts &&
                      this.props.order_counts.cancelled_order
                        ? this.props.order_counts.cancelled_order > 1000
                          ? (
                              Number(this.props.order_counts.cancelled_order) /
                              1000
                            ).toFixed(1) + "k"
                          : this.props.order_counts.cancelled_order < 1000
                          ? this.props.order_counts.cancelled_order
                          : null
                        : this.props.order_counts.cancelled_order === 0
                        ? "0"
                        : "..."}
                    </div>
                  </div>
                  <div
                    className={
                      this.props.loading
                        ? "non-cursor-pointer tab hover-order-underline-animation "
                        : this.props.tabIsClosedOrders &&
                          this.props.closeordernav.donotfullfilbtn
                        ? "tab  hover-order-underline-animation-click"
                        : "tab hover-order-underline-animation"
                    }
                  >
                    <button
                      className={
                        this.props.tabIsClosedOrders &&
                        this.props.closeordernav.donotfullfilbtn
                          ? "active"
                          : ""
                      }
                      value="not_fulfill"
                      onClick={() => {
                        this.setState(
                          {
                            deliveredbtn: false,
                            cancelbtn: false,
                            donotfullfilbtn: true,
                            allbtn3: false,
                            rtobtn: false,
                          },
                          () =>
                            this.props.closeordernavtoggle({
                              deliveredbtn: false,
                              cancelbtn: false,
                              donotfullfilbtn: true,
                              allbtn3: false,
                              rtobtn: false,
                            })
                        );
                        this.props.changeFilter({
                          vfOrderStatus: ["not_fulfill"],
                          startDate: this.props.filters.startDate,
                          endDate: this.props.filters.endDate,
                          dateType: this.props.filters.dateType,
                          search: "",
                          fulfillmentStatus: [],
                          paymentStatus: [],
                          shipmentStatus: [],
                          closeorderStatus: [],
                          pagename: ["not_fulfill"],
                        });
                      }}
                    >
                      Do Not Fulfill
                    </button>
                    <div
                      className={` ${
                        this.props.tabIsClosedOrders &&
                        this.props.closeordernav.donotfullfilbtn
                          ? "backgroundcircle"
                          : "circle"
                      }`}
                    >
                      {this.props.order_counts &&
                      this.props.order_counts.donot_fulfill_order
                        ? this.props.order_counts.donot_fulfill_order > 1000
                          ? (
                              Number(
                                this.props.order_counts.donot_fulfill_order
                              ) / 1000
                            ).toFixed(1) + "k"
                          : this.props.order_counts.donot_fulfill_order < 1000
                          ? this.props.order_counts.donot_fulfill_order
                          : null
                        : this.props.order_counts.donot_fulfill_order === 0
                        ? "0"
                        : "..."}
                    </div>
                  </div>

                  <div
                    className={
                      this.props.loading
                        ? "non-cursor-pointer tab hover-order-underline-animation "
                        : this.props.tabIsClosedOrders &&
                          this.props.closeordernav.allbtn3
                        ? "tab  hover-order-underline-animation-click"
                        : "tab hover-order-underline-animation"
                    }
                  >
                    <button
                      className={
                        this.props.tabIsClosedOrders &&
                        this.props.closeordernav.allbtn3
                          ? "active"
                          : ""
                      }
                      value=""
                      onClick={() => {
                        this.setState(
                          {
                            deliveredbtn: false,
                            cancelbtn: false,
                            donotfullfilbtn: false,
                            allbtn3: true,
                            rtobtn: false,
                          },
                          () =>
                            this.props.closeordernavtoggle({
                              deliveredbtn: false,
                              cancelbtn: false,
                              donotfullfilbtn: false,
                              allbtn3: true,
                              rtobtn: false,
                            })
                        );
                        this.props.changeFilter({
                          vfOrderStatus: ["all"],
                          startDate: this.props.filters.startDate,
                          endDate: this.props.filters.endDate,
                          dateType: this.props.filters.dateType,
                          search: "",
                          fulfillmentStatus: [],
                          paymentStatus: [],
                          shipmentStatus: [],
                          closeorderStatus: [],
                          pagename: ["all"],
                        });
                      }}
                    >
                      All
                    </button>
                    <div
                      className={` ${
                        this.props.tabIsClosedOrders &&
                        this.props.closeordernav.allbtn3
                          ? "backgroundcircle"
                          : "circle"
                      }`}
                    >
                      {this.props.order_counts && this.props.order_counts.all
                        ? this.props.order_counts.all > 1000
                          ? (
                              Number(this.props.order_counts.all) / 1000
                            ).toFixed(1) + "k"
                          : this.props.order_counts.all < 1000
                          ? this.props.order_counts.all
                          : null
                        : this.props.order_counts.all === 0
                        ? "0"
                        : "..."}
                    </div>
                  </div>
                </div>
                <div className="download_csv">
                  <button
                    className="clickable"
                    style={{
                      width: "120px",
                    }}
                    onClick={() => this.props.downloadCSV()}
                  >
                    Download CSV
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom_data">
            <div className="Globalsearch-order2">
              <Globalsearch
                tooltip={true}
                class={"nextwidth"}
                data={this.props.filters}
                placeholder={`Search by Product, ${SHOPIFY_NAME} ID, Customer Name, Product Name`}
                change={(value) =>
                  this.props.changeHandler({ search: value.target.value })
                }
                searchFunc={(value) =>
                  this.props.changeFilter({ search: value })
                }
                ordervale={true}
              />
              <Dropdown overlay={menu} placement="bottomRight">
                <Button>
                  <svg
                    style={
                      {
                        // transition: "all 0.5s",
                        // transform: `${
                        //   this.state.arrow2 ? "rotateX(180deg)" : "rotateX(0deg)"
                        // }`,
                      }
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_49"
                      data-name="Polygon 49"
                      transform="translate(16 8) rotate(180)"
                      // fill={`${this.state.arrow2 ? "#898989" : "none"}`}
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#898989"
                      />
                    </g>
                  </svg>
                </Button>
              </Dropdown>
            </div>

            {/* {this.state.rtobtn ? (
              <div
                className="drop"
                style={{
                  display: "flex",
                  float: "left",
                  alignItems: "center",
                }}
              >
                <p className="dispute-status">Shipment Status: </p>

                <Select
                  style={{ fontWeight: 500, fontSize: 12 }}
                  value={
                    this.props.shipmentStatus[0] === "r_t_o"
                      ? undefined
                      : this.props.shipmentStatus
                  }
                  placeholder=": All"
                  onChange={(val) =>
                    this.props.changeFilter({
                      shipmentStatus: [val],
                      startDate: "",
                      endDate: "",
                      dateType: "shopify",
                      search: "",
                    })
                  }
                  onDropdownVisibleChange={(val) =>
                    this.setState({ arrow5: val })
                  }
                  suffixIcon={
                    <svg
                      style={{
                        transition: "all 0.5s",
                        transform: `${
                          this.state.arrow5
                            ? "rotateX(180deg)"
                            : "rotateX(0deg)"
                        }`,
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="8"
                      viewBox="0 0 16 8"
                    >
                      <g
                        id="Polygon_49"
                        data-name="Polygon 49"
                        transform="translate(16 8) rotate(180)"
                        fill={`${this.state.arrow5 ? "#898989" : "none"}`}
                      >
                        <path d="M8,0l8,8H0Z" stroke="none" />
                        <path
                          d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                          stroke="none"
                          fill="#898989"
                        />
                      </g>
                    </svg>
                  }
                >
                  <Option value="all">All</Option>
                  <Option value="deliverd_return">
                    <Icon
                      component={ShipmentStatusIcon}
                      style={{
                        color: this.props.shipmentStatusColors["rto"],
                      }}
                    />{" "}
                    Delivered return
                  </Option>
                  <Option value="rto">
                    <Icon
                      component={ShipmentStatusIcon}
                      style={{ color: this.props.shipmentStatusColors["rto"] }}
                    />{" "}
                    RTO
                  </Option>
                </Select>
              </div>
            ) : null} */}

            <div
              className="drop"
              style={{
                display: "flex",
                float: "left",
                alignItems: "center",
              }}
            >
              {/* <p className="dispute-status"> </p> */}

              <Select
                style={{ fontWeight: 500, fontSize: 12, width: 288 }}
                value={this.props.paymentStatus}
                placeholder="Payment Status: All"
                onChange={(val) =>
                  this.props.changeFilter({
                    paymentStatus: [val],
                    startDate: this.props.filters.startDate,
                    endDate: this.props.filters.endDate,
                    dateType: this.props.filters.dateType,
                    search: this.props.filters.search,
                  })
                }
                onDropdownVisibleChange={(val) =>
                  this.setState({ arrow3: val })
                }
                suffixIcon={
                  <svg
                    style={{
                      transition: "all 0.5s",
                      transform: `${
                        this.state.arrow3 ? "rotateX(180deg)" : "rotateX(0deg)"
                      }`,
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_49"
                      data-name="Polygon 49"
                      transform="translate(16 8) rotate(180)"
                      fill={`${this.state.arrow3 ? "#898989" : "none"}`}
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#898989"
                      />
                    </g>
                  </svg>
                }
              >
                <Option value="all" className="commondropdown">
                  All
                </Option>
                <Option value="paid" className="commondropdown">
                  <Icon
                    component={PaymentStatusIcon}
                    style={{ color: this.props.paymentStatusColors["paid"] }}
                  />{" "}
                  Prepaid
                </Option>

                <Option value="pending" className="commondropdown">
                  <Icon
                    component={PaymentStatusIcon}
                    style={{ color: this.props.paymentStatusColors["pending"] }}
                  />{" "}
                  Cash On Delivery
                </Option>
                {/* <Option value="refunded">
                  <Icon
                    component={PaymentStatusIcon}
                    style={{
                      color: this.props.paymentStatusColors["refunded"],
                    }}
                  />{" "}
                  Refunded
                </Option> */}
              </Select>
            </div>
          </div>
        </div>
      );
    } else if (this.props.pageType === "allorders") {
      return (
        <div id="orders-page-nav" className="fullorder_data orders-page">
          <div className="ordernav opennav">
            <div className="ordfill">
              <div className="ordfillwrap">
                <div className="left">
                  <div
                    className="breadcurmb-txt"
                    style={{
                      color: "#000",
                      fontSize: "12px",
                      letterSpacing: "0.032em",
                      lineHeight: "24px",
                    }}
                  >
                    <span
                      style={{
                        color: "#000",
                        letterSpacing: "0.032em",
                        lineHeight: "24px",
                      }}
                    >
                      Orders
                    </span>
                    <span style={{ margin: "0 4px" }}>&gt;</span>
                    <span
                      className="content-page-title"
                      style={{
                        letterSpacing: "0,032em",
                        lineHeight: "24px",
                        fontSize: "12px",
                        textDecoration: "underline",
                      }}
                    >
                      {this.props.PageTitles[this.props.pageType]}
                    </span>
                    {/* <VideoTooltip
                      title="Orders Tab"
                      subtitle="All orders on your shopify store will be synced in this section."
                      contentHeadings={[
                        "Open Orders",
                        "In-Processing",
                        "Closed Orders",
                        "All Orders",
                      ]}
                      content={[
                        "These are all the 'Open Orders' that you have received on your Shopify store - which you have not yet Verified and Placed to vFulfill for further fulfillment.",
                        "These are your 'In-Processing orders' which you have Verified & Placed to vFulfill where the fulfillment process has been started, but have not been shipped yet.",
                        "All orders that has been closed with no further action is required on them. This includes the Delivered Orders, Returned orders, Cancelled orders & Do not fulfill orders.",
                        "These are all your orders which you have received on your Shopify store irrespective of their current Shopify or vFulfill Status.",
                      ]}
                      thumbnail=""
                      videolink=""
                    /> */}
                  </div>
                </div>
                <div className="right">
                  <div className="store-filters">
                    {(this.props.pageType === "allorders" ||
                      this.props.pageType === "processingorders") && (
                      <>
                        <Sorter
                          sorterFunction={(e) => this.props.sorterFunction(e)}
                          sortingOrder={this.props.sort}
                        />
                        <div
                          style={{
                            color: "#898989",
                            fontWeight: 400,
                            margin: "0 10px 0 0",
                          }}
                        >
                          |
                        </div>
                        <div
                          className="shopify-date-filter"
                          style={{
                            display: "flex",
                            float: "left",
                            alignItems: "center",
                          }}
                        >
                          <p className="dispute-status">Filter By: </p>

                          <Select
                            // className=""
                            style={{ fontWeight: 500, fontSize: 12 }}
                            value={this.props.filters.dateType}
                            onChange={(val) =>
                              this.props.shopifyOrderDateFilter({
                                dateType: val,
                              })
                            }
                            onDropdownVisibleChange={(val) =>
                              this.setState({ arrow1: val })
                            }
                            suffixIcon={
                              <svg
                                style={{
                                  transition: "all 0.5s",
                                  transform: `${
                                    this.state.arrow1
                                      ? "rotateX(180deg)"
                                      : "rotateX(0deg)"
                                  }`,
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="8"
                                viewBox="0 0 16 8"
                              >
                                <g
                                  id="Polygon_49"
                                  data-name="Polygon 49"
                                  transform="translate(16 8) rotate(180)"
                                  fill={`${
                                    this.state.arrow1 ? "#898989" : "none"
                                  }`}
                                >
                                  <path d="M8,0l8,8H0Z" stroke="none" />
                                  <path
                                    d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                                    stroke="none"
                                    fill="#898989"
                                  />
                                </g>
                              </svg>
                            }
                          >
                            <Option value="shopify">{`${SHOPIFY_NAME} Order Date`}</Option>
                            <Option value="vf">VF Order Date</Option>
                          </Select>
                        </div>
                      </>
                    )}
                  </div>
                  <div style={{ color: "#898989", fontWeight: 400 }}>|</div>
                  <p className="show_data_from" style={{ marginLeft: "8px" }}>
                    Show Data From:{" "}
                  </p>

                  <div ref={this.props.calenderRef}>
                    {" "}
                    <RangePicker
                      disabledDate={(current) => {
                        return current > moment();
                      }}
                      style={{ marginLeft: "10px", maxWidth: "300px" }}
                      value={[
                        this.props.filters.startDate === "" ||
                        this.props.filters.startDate === null
                          ? ""
                          : moment(this.props.filters.startDate, "YYYY-MM-DD"),
                        this.props.filters.endDate === "" ||
                        this.props.filters.startDate === null
                          ? ""
                          : moment(this.props.filters.endDate, "YYYY-MM-DD"),
                      ]}
                      ranges={{
                        Today: [moment(), moment()],
                        "This Week": [
                          moment().startOf("week"),
                          moment().endOf("week"),
                        ],
                        "Last 15 Days": [
                          moment().subtract("days", 15),
                          moment(),
                        ],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        "Last Month": [
                          moment()
                            .subtract(1, "month")
                            .startOf("month"),
                          moment()
                            .subtract(1, "month")
                            .endOf("month"),
                        ],
                        "Last Year": [
                          moment()
                            .subtract(1, "year")
                            .startOf("year"),
                          moment()
                            .subtract(1, "year")
                            .endOf("year"),
                        ],
                      }}
                      onChange={(_, dateString) =>
                        this.props.rangePickerChange({
                          startDate: dateString[0],
                          endDate: dateString[1],
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="secondwrap">
                <div className="filtertab">
                  <div
                    className={
                      this.props.loading
                        ? "non-cursor-pointer tab hover-order-underline-animation "
                        : this.props.tabIsallOrder &&
                          this.props.allordernav.openorderbtn
                        ? "tab  hover-order-underline-animation-click"
                        : "tab hover-order-underline-animation"
                    }
                  >
                    <button
                      className={
                        this.props.tabIsallOrder &&
                        this.props.allordernav.openorderbtn
                          ? "active"
                          : ""
                      }
                      value="open"
                      onClick={() => {
                        this.setState(
                          {
                            InProcessingbtn: false,
                            ClosedOrdersbtn: false,
                            openorderbtn: true,
                            allBtn: false,
                          },
                          () =>
                            this.props.childtop({
                              InProcessingbtn: false,
                              ClosedOrdersbtn: false,
                              openorderbtn: true,
                              allBtn: false,
                            })
                        );
                        this.props.changeFilter({
                          vfOrderStatus: [],
                          startDate: this.props.filters.startDate,
                          endDate: this.props.filters.endDate,
                          dateType: this.props.filters.dateType,
                          search: "",
                          fulfillmentStatus: [],
                          paymentStatus: [],
                          shipmentStatus: [],
                          closeorderStatus: [],
                          pagename: [],
                        });
                      }}
                    >
                      To Be Placed
                    </button>
                    {/* sdsds */}
                    <div />
                    <div
                      className={` ${
                        this.props.tabIsallOrder &&
                        this.props.allordernav.openorderbtn
                          ? "backgroundcircle"
                          : "circle"
                      }`}
                    >
                      {this.props.order_counts &&
                      this.props.order_counts.open_order
                        ? this.props.order_counts.open_order > 1000
                          ? (
                              Number(this.props.order_counts.open_order) / 1000
                            ).toFixed(1) + "k"
                          : this.props.order_counts.open_order < 1000
                          ? this.props.order_counts.open_order
                          : null
                        : this.props.order_counts.open_order === 0
                        ? "0"
                        : "..."}
                    </div>
                  </div>

                  <div
                    className={
                      this.props.loading
                        ? "non-cursor-pointer tab hover-order-underline-animation "
                        : this.props.tabIsallOrder &&
                          this.props.allordernav.InProcessingbtn
                        ? "tab  hover-order-underline-animation-click"
                        : "tab hover-order-underline-animation"
                    }
                  >
                    <button
                      className={
                        this.props.tabIsallOrder &&
                        this.props.allordernav.InProcessingbtn
                          ? "active"
                          : ""
                      }
                      value={["processing", "transit"]}
                      onClick={() => {
                        this.setState(
                          {
                            InProcessingbtn: true,
                            ClosedOrdersbtn: false,
                            openorderbtn: false,
                            allBtn: false,
                          },
                          () =>
                            this.props.childtop({
                              InProcessingbtn: true,
                              ClosedOrdersbtn: false,
                              openorderbtn: false,
                              allBtn: false,
                            })
                        );

                        this.props.changeFilter({
                          shipmentStatus: ["processing", "manifested"],
                          startDate: this.props.filters.startDate,
                          endDate: this.props.filters.endDate,
                          dateType: this.props.filters.dateType,
                          search: "",
                          fulfillmentStatus: [],
                          paymentStatus: [],
                          closeorderStatus: [],
                          vfOrderStatus: [],
                          pagename: ["In-Processing"],
                        });
                      }}
                    >
                      In-Processing
                    </button>
                    <div
                      className={` ${
                        this.props.tabIsallOrder &&
                        this.props.allordernav.InProcessingbtn
                          ? "backgroundcircle"
                          : "circle"
                      }`}
                    >
                      {this.props.order_counts &&
                      this.props.order_counts.inprocessing_order
                        ? this.props.order_counts.inprocessing_order > 1000
                          ? (
                              Number(
                                this.props.order_counts.inprocessing_order
                              ) / 1000
                            ).toFixed(1) + "k"
                          : this.props.order_counts.inprocessing_order < 1000
                          ? this.props.order_counts.inprocessing_order
                          : null
                        : this.props.order_counts.inprocessing_order === 0
                        ? "0"
                        : "..."}
                    </div>
                  </div>

                  <div
                    className={
                      this.props.loading
                        ? "non-cursor-pointer tab hover-order-underline-animation "
                        : this.props.tabIsallOrder &&
                          this.props.allordernav.ClosedOrdersbtn
                        ? "tab  hover-order-underline-animation-click"
                        : "tab hover-order-underline-animation"
                    }
                  >
                    <button
                      className={
                        this.props.tabIsallOrder &&
                        this.props.allordernav.ClosedOrdersbtn
                          ? "active"
                          : ""
                      }
                      value={[
                        "delivered",
                        "not_fulfill",
                        "cancelled",
                        "rto",
                        "delivered_return",
                      ]}
                      onClick={() => {
                        this.setState(
                          {
                            InProcessingbtn: false,
                            ClosedOrdersbtn: true,
                            openorderbtn: false,
                            allBtn: false,
                          },
                          () =>
                            this.props.childtop({
                              InProcessingbtn: false,
                              ClosedOrdersbtn: true,
                              openorderbtn: false,
                              allBtn: false,
                            })
                        );
                        this.props.changeFilter({
                          closeorderStatus: [
                            "delivered",
                            "not_fulfill",
                            "cancelled",
                            "rto",
                            "delivered_return",
                          ],
                          startDate: this.props.filters.startDate,
                          endDate: this.props.filters.endDate,
                          dateType: this.props.filters.dateType,
                          search: "",
                          fulfillmentStatus: [],
                          paymentStatus: [],
                          shipmentStatus: [],
                          pagename: ["Closed Orders"],
                        });
                      }}
                    >
                      Closed
                    </button>
                    <div
                      className={` ${
                        this.props.tabIsallOrder &&
                        this.props.allordernav.ClosedOrdersbtn
                          ? "backgroundcircle"
                          : "circle"
                      }`}
                    >
                      {this.props.order_counts &&
                      this.props.order_counts.closed_order
                        ? this.props.order_counts.closed_order > 1000
                          ? (
                              Number(this.props.order_counts.closed_order) /
                              1000
                            ).toFixed(1) + "k"
                          : this.props.order_counts.closed_order < 1000
                          ? this.props.order_counts.closed_order
                          : null
                        : this.props.order_counts.closed_order === 0
                        ? "0"
                        : "..."}
                    </div>
                  </div>

                  <div
                    className={
                      this.props.loading
                        ? "non-cursor-pointer tab hover-order-underline-animation "
                        : this.props.tabIsallOrder &&
                          this.props.allordernav.allBtn
                        ? "tab  hover-order-underline-animation-click"
                        : "tab hover-order-underline-animation"
                    }
                  >
                    <button
                      className={
                        this.props.tabIsallOrder &&
                        this.props.allordernav.allBtn
                          ? "active"
                          : ""
                      }
                      value=""
                      onClick={() => {
                        this.setState(
                          {
                            InProcessingbtn: false,
                            ClosedOrdersbtn: false,
                            openorderbtn: false,
                            allBtn: true,
                          },
                          () =>
                            this.props.childtop({
                              InProcessingbtn: false,
                              ClosedOrdersbtn: false,
                              openorderbtn: false,
                              allBtn: true,
                            })
                        );
                        this.props.changeFilter({
                          vfOrderStatus: ["all"],
                          startDate: this.props.filters.startDate,
                          endDate: this.props.filters.endDate,
                          dateType: this.props.filters.dateType,
                          search: "",
                          fulfillmentStatus: [],
                          paymentStatus: [],
                          shipmentStatus: [],
                          closeorderStatus: [],
                          pagename: ["all"],
                        });
                      }}
                    >
                      All
                    </button>

                    <div
                      className={` ${
                        this.props.tabIsallOrder &&
                        this.props.allordernav.allBtn
                          ? "backgroundcircle"
                          : "circle"
                      }`}
                    >
                      {this.props.order_counts && this.props.order_counts.all
                        ? this.props.order_counts.all > 1000
                          ? (
                              Number(this.props.order_counts.all) / 1000
                            ).toFixed(1) + "k"
                          : this.props.order_counts.all < 1000
                          ? this.props.order_counts.all
                          : null
                        : this.props.order_counts.all === 0
                        ? "0"
                        : "..."}
                    </div>
                  </div>
                </div>

                <div className="download_csv">
                  <button
                    className="clickable"
                    style={{
                      width: "120px",
                    }}
                    onClick={() => this.props.downloadCSV()}
                  >
                    Download CSV
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom_data">
            <div className="Globalsearch-order2">
              <Globalsearch
                tooltip={true}
                class={"nextwidth"}
                data={this.props.filters}
                placeholder={`Search by Product ID, ${SHOPIFY_NAME} ID, Customer Name, Product Name`}
                change={(value) =>
                  this.props.changeHandler({ search: value.target.value })
                }
                searchFunc={(value) =>
                  this.props.changeFilter({ search: value })
                }
                ordervale={true}
              />
              <Dropdown overlay={menu} placement="bottomRight">
                <Button>
                  <svg
                    style={
                      {
                        // transition: "all 0.5s",
                        // transform: `${
                        //   this.state.arrow2 ? "rotateX(180deg)" : "rotateX(0deg)"
                        // }`,
                      }
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_49"
                      data-name="Polygon 49"
                      transform="translate(16 8) rotate(180)"
                      // fill={`${this.state.arrow2 ? "#898989" : "none"}`}
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#898989"
                      />
                    </g>
                  </svg>
                </Button>
              </Dropdown>
            </div>

            {this.props.tabIsallOrder && this.props.allordernav.openorderbtn ? (
              <div
                className="drop"
                style={{
                  display: "flex",
                  float: "left",
                  alignItems: "center",
                }}
              >
                {/* <p className="dispute-status"> </p> */}

                <Select
                  style={{
                    fontWeight: 500,
                    fontSize: 12,
                    width: "288px",
                  }}
                  value={this.props.vfOrderStatus}
                  placeholder="Order Status: All"
                  className="navb"
                  dropdownMatchSelectWidth={400}
                  onChange={(val) =>
                    this.props.changeFilter({
                      vfOrderStatus: [val],
                      startDate: this.props.filters.startDate,
                      endDate: this.props.filters.endDate,
                      search: this.props.filters.search,
                    })
                  }
                  onDropdownVisibleChange={(val) =>
                    this.setState({ arrow2: val })
                  }
                  suffixIcon={
                    <svg
                      style={{
                        transition: "all 0.5s",
                        transform: `${
                          this.state.arrow2
                            ? "rotateX(180deg)"
                            : "rotateX(0deg)"
                        }`,
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="8"
                      viewBox="0 0 16 8"
                    >
                      <g
                        id="Polygon_49"
                        data-name="Polygon 49"
                        transform="translate(16 8) rotate(180)"
                        fill={`${this.state.arrow2 ? "#898989" : "none"}`}
                      >
                        <path d="M8,0l8,8H0Z" stroke="none" />
                        <path
                          d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                          stroke="none"
                          fill="#898989"
                        />
                      </g>
                    </svg>
                  }
                >
                  <Option value="all" className="commondropdown">
                    All
                  </Option>
                  <Option value="open" className="commondropdown">
                    <Icon
                      component={this.props.vfOrderStatusIcons["open"]}
                      style={{ color: this.props.vfOrderStatusColors["open"] }}
                    />{" "}
                    Open
                  </Option>

                  <Option value="verified" className="commondropdown">
                    <Icon
                      component={
                        this.props.vfOrderStatusIcons["verified_ordered"]
                      }
                      style={{
                        color: this.props.vfOrderStatusColors["verified"],
                      }}
                    />{" "}
                    Verified
                  </Option>

                  <Option value="hold" className="commondropdown">
                    <Icon
                      component={this.props.vfOrderStatusIcons["hold"]}
                      style={{ color: this.props.vfOrderStatusColors["hold"] }}
                    />{" "}
                    On Hold
                  </Option>

                  <Option value="notlinked" className="commondropdown">
                    <Icon
                      component={this.props.vfOrderStatusIcons["notlinked"]}
                      style={{
                        color: this.props.vfOrderStatusColors["notlinked"],
                      }}
                    />{" "}
                    Not Linked
                  </Option>
                </Select>
              </div>
            ) : this.props.tabIsallOrder &&
              this.props.allordernav.ClosedOrdersbtn ? (
              <div
                className="drop"
                style={{
                  display: "flex",
                  float: "left",
                  alignItems: "center",
                }}
              >
                {/* <p className="dispute-status"> </p> */}

                <Select
                  style={{ fontWeight: 500, fontSize: 12, width: "288px" }}
                  value={this.props.vfOrderStatus}
                  placeholder=" Order Status: All"
                  onChange={(val) =>
                    this.props.changeFilter({
                      vfOrderStatus: [val],
                      startDate: this.props.filters.startDate,
                      endDate: this.props.filters.endDate,
                      dateType: this.props.filters.dateType,
                      search: this.props.filters.search,
                    })
                  }
                  onDropdownVisibleChange={(val) =>
                    this.setState({ arrow2: val })
                  }
                  suffixIcon={
                    <svg
                      style={{
                        transition: "all 0.5s",
                        transform: `${
                          this.state.arrow2
                            ? "rotateX(180deg)"
                            : "rotateX(0deg)"
                        }`,
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="8"
                      viewBox="0 0 16 8"
                    >
                      <g
                        id="Polygon_49"
                        data-name="Polygon 49"
                        transform="translate(16 8) rotate(180)"
                        fill={`${this.state.arrow2 ? "#898989" : "none"}`}
                      >
                        <path d="M8,0l8,8H0Z" stroke="none" />
                        <path
                          d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                          stroke="none"
                          fill="#898989"
                        />
                      </g>
                    </svg>
                  }
                >
                  <Option value="all" className="commondropdown">
                    All
                  </Option>
                  <Option value="delivered" className="commondropdown">
                    <Icon
                      component={ShipmentStatusIcon}
                      style={{
                        // color: this.props.shipmentStatusColors["delivered"],
                        color: "#2BC822",
                      }}
                    />{" "}
                    Delivered
                  </Option>
                  {/* <Option value="rto" className="commondropdown">
                    <Icon
                      component={ShipmentStatusIcon}
                      style={{ color: this.props.shipmentStatusColors["rto"] }}
                    />{" "}
                    RTO
                  </Option>
                  <Option value="deliverd_return" className="commondropdown">
                    <Icon
                      component={ShipmentStatusIcon}
                      style={{
                        color: this.props.shipmentStatusColors[
                          "delivered_return"
                        ],
                      }}
                    />{" "}
                    Delivered Return
                  </Option> */}
                  <Option value="rto" className="commondropdown">
                    <Icon
                      component={ShipmentStatusIcon}
                      style={{ color: this.props.shipmentStatusColors["rto"] }}
                    />{" "}
                    Returned
                  </Option>

                  <Option value="cancelled" className="commondropdown">
                    <Icon
                      component={this.props.vfOrderStatusIcons["cancelled"]}
                      style={{
                        color: this.props.vfOrderStatusColors["cancelled"],
                      }}
                    />{" "}
                    Cancelled
                  </Option>

                  <Option value="not_fulfill" className="commondropdown">
                    <Icon
                      component={this.props.vfOrderStatusIcons["not_fulfill"]}
                      style={{
                        color: this.props.vfOrderStatusColors["not_fulfill"],
                      }}
                    />{" "}
                    Do Not Fulfill
                  </Option>
                </Select>
              </div>
            ) : this.props.tabIsallOrder && this.props.allordernav.allBtn ? ( // all fillters
              <div
                className="drop"
                style={{
                  display: "flex",
                  float: "left",
                  alignItems: "center",
                }}
              >
                {/* <p className="dispute-status"> </p> */}

                <Select
                  style={{
                    fontWeight: 500,
                    fontSize: 12,
                    width: "288px",
                  }}
                  value={this.props.vfOrderStatus}
                  placeholder="Order Status: All"
                  className="navb"
                  dropdownMatchSelectWidth={400}
                  onChange={(val) =>
                    this.props.changeFilter({
                      vfOrderStatus: [val],
                      startDate: this.props.filters.startDate,
                      endDate: this.props.filters.endDate,
                      search: this.props.filters.search,
                    })
                  }
                  onDropdownVisibleChange={(val) =>
                    this.setState({ arrow2: val })
                  }
                  suffixIcon={
                    <svg
                      style={{
                        transition: "all 0.5s",
                        transform: `${
                          this.state.arrow2
                            ? "rotateX(180deg)"
                            : "rotateX(0deg)"
                        }`,
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="8"
                      viewBox="0 0 16 8"
                    >
                      <g
                        id="Polygon_49"
                        data-name="Polygon 49"
                        transform="translate(16 8) rotate(180)"
                        fill={`${this.state.arrow2 ? "#898989" : "none"}`}
                      >
                        <path d="M8,0l8,8H0Z" stroke="none" />
                        <path
                          d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                          stroke="none"
                          fill="#898989"
                        />
                      </g>
                    </svg>
                  }
                >
                  <Option value="all" className="commondropdown">
                    All
                  </Option>
                  <Option value="open" className="commondropdown">
                    <Icon
                      component={this.props.vfOrderStatusIcons["open"]}
                      style={{ color: this.props.vfOrderStatusColors["open"] }}
                    />{" "}
                    Open
                  </Option>

                  <Option value="verified" className="commondropdown">
                    <Icon
                      component={
                        this.props.vfOrderStatusIcons["verified_ordered"]
                      }
                      style={{
                        color: this.props.vfOrderStatusColors["verified"],
                      }}
                    />{" "}
                    Verified
                  </Option>

                  <Option value="verified_ordered" className="commondropdown">
                    <Icon
                      component={
                        this.props.vfOrderStatusIcons["verified_ordered"]
                      }
                      style={{
                        color: this.props.vfOrderStatusColors[
                          "verified_ordered"
                        ],
                      }}
                    />{" "}
                    Placed
                  </Option>

                  <Option value="processing" className="commondropdown">
                    <Icon
                      component={this.props.vfOrderStatusIcons["cart"]}
                      style={{
                        color: this.props.vfOrderStatusColors["in_processing"],
                      }}
                    />{" "}
                    In-Processing
                  </Option>

                  <Option value="manifested" className="commondropdown">
                    <Icon
                      component={
                        this.props.vfOrderStatusIcons["verified_ordered"]
                      }
                      style={{
                        color: this.props.vfOrderStatusColors["ready_to_ship"],
                      }}
                    />{" "}
                    To Be Shipped
                  </Option>
                  <Option value="transit" className="commondropdown">
                    <Icon
                      component={this.props.vfOrderStatusIcons["cart"]}
                      style={{
                        color: this.props.vfOrderStatusColors["in_transit"],
                      }}
                    />{" "}
                    In-Transit
                  </Option>

                  <Option value="rto_in_transit" className="commondropdown">
                    <Icon
                      component={this.props.vfOrderStatusIcons["cart"]}
                      style={{
                        color: this.props.vfOrderStatusColors["rto_in_transit"],
                      }}
                    />{" "}
                    RTO In-Transit
                  </Option>

                  <Option value="hold" className="commondropdown">
                    <Icon
                      component={this.props.vfOrderStatusIcons["hold"]}
                      style={{ color: this.props.vfOrderStatusColors["hold"] }}
                    />{" "}
                    On Hold
                  </Option>

                  <Option value="notlinked" className="commondropdown">
                    <Icon
                      component={this.props.vfOrderStatusIcons["notlinked"]}
                      style={{
                        color: this.props.vfOrderStatusColors["notlinked"],
                      }}
                    />{" "}
                    Not Linked
                  </Option>
                  <Option value="delivered" className="commondropdown">
                    <Icon
                      component={ShipmentStatusIcon}
                      style={{
                        // color: this.props.shipmentStatusColors["delivered"],
                        color: "#2BC822",
                      }}
                    />{" "}
                    Delivered
                  </Option>
                  {/* <Option value="rto" className="commondropdown">
                  <Icon
                    component={ShipmentStatusIcon}
                    style={{ color: this.props.shipmentStatusColors["rto"] }}
                  />{" "}
                  RTO
                </Option>
                <Option value="deliverd_return" className="commondropdown">
                  <Icon
                    component={ShipmentStatusIcon}
                    style={{
                      color: this.props.shipmentStatusColors[
                        "delivered_return"
                      ],
                    }}
                  />{" "}
                  Delivered Return
                </Option> */}
                  <Option value="rto" className="commondropdown">
                    <Icon
                      component={ShipmentStatusIcon}
                      style={{ color: this.props.shipmentStatusColors["rto"] }}
                    />{" "}
                    Returned
                  </Option>

                  <Option value="cancelled" className="commondropdown">
                    <Icon
                      component={this.props.vfOrderStatusIcons["cancelled"]}
                      style={{
                        color: this.props.vfOrderStatusColors["cancelled"],
                      }}
                    />{" "}
                    Cancelled
                  </Option>

                  <Option value="not_fulfill" className="commondropdown">
                    <Icon
                      component={this.props.vfOrderStatusIcons["not_fulfill"]}
                      style={{
                        color: this.props.vfOrderStatusColors["not_fulfill"],
                      }}
                    />{" "}
                    Do Not Fulfill
                  </Option>
                </Select>
              </div>
            ) : null}

            <div className="drop custom-lineheight">
              {/* <p className="dispute-status">: </p> */}

              <Select
                className="antsvg"
                style={{ fontWeight: 500, fontSize: 12, width: 288 }}
                value={this.props.paymentStatus}
                placeholder="Payment Status: All"
                onChange={(val) =>
                  this.props.changeFilter({
                    paymentStatus: [val],
                    startDate: this.props.filters.startDate,
                    endDate: this.props.filters.endDate,
                    dateType: this.props.filters.dateType,
                    search: this.props.filters.search,
                  })
                }
                onDropdownVisibleChange={(val) =>
                  this.setState({ arrow3: val })
                }
                suffixIcon={
                  <svg
                    className="antsvg"
                    style={{
                      transition: "all 0.5s",
                      transform: `${
                        this.state.arrow3 ? "rotateX(180deg)" : "rotateX(0deg)"
                      }`,
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_49"
                      data-name="Polygon 49"
                      transform="translate(16 8) rotate(180)"
                      fill={`${this.state.arrow3 ? "#898989" : "none"}`}
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#898989"
                      />
                    </g>
                  </svg>
                }
              >
                <Option value="all" className="commondropdown">
                  All
                </Option>
                <Option value="paid" className="commondropdown">
                  <Icon
                    component={PaymentStatusIcon}
                    style={{ color: this.props.paymentStatusColors["paid"] }}
                  />{" "}
                  Prepaid
                </Option>

                <Option value="pending" className="commondropdown">
                  <Icon
                    component={PaymentStatusIcon}
                    style={{ color: this.props.paymentStatusColors["pending"] }}
                  />{" "}
                  Cash On Delivery
                </Option>
                {/* <Option value="refunded">
                  <Icon
                    component={PaymentStatusIcon}
                    style={{
                      color: this.props.paymentStatusColors["refunded"],
                    }}
                  />{" "}
                  Refunded
                </Option> */}
              </Select>
            </div>
            {this.props.tags &&
              !!this.props.tags.length &&
              this.props.pageType === "allorders" &&
              this.props.allordernav.allBtn &&
              this.props.store.store_platform === "shopify" && (
                <TagsFilter
                  tags={this.props.tags}
                  width={288}
                  value={this.props.filters.tags}
                  changeHandler={(e) => this.props.tagChangeHandler(e)}
                  type="reason"
                  overlayClass={"mav-filter-overlay"}
                />
              )}
          </div>
        </div>
      );
    }
  }
}

export default Ordernav;
