import { Button, Icon, message, Modal, Tooltip } from "antd";
import React, { Component } from "react";
import {
  formatNumber,
  getCurrencySymbol,
  toStoreCurrency,
} from "../../../../../Helpers/Pricing";
import "./index.scss";
import axios from "axios";
import { connect } from "react-redux";
import { hasPermission } from "../../../../../Helpers/Permissions";
import BulkOrderConfirmModal from "../BulkOrderConfirmModal";
import ConfirmPackagingModal from "../ConfirmPackagingModal";
import NoBalanceModal from "../NoBalanceModal";
import { ReactComponent as NewTab } from "../../../../../assets/images/icons/newtab.svg";
import GlobalTooltipIcon from "../../../Common/GlobalTooltipIcon";

class BookingConfirmationModal extends Component {
  state = {
    time: 59,
    cancelLoading: false,
  };

  startTimer() {
    this.setState(
      {
        time: 59,
      },
      () => {
        this.timerInterval = setInterval(() => {
          this.setState(
            (state) => ({
              time: state.time - 1,
            }),
            () => {
              if (this.state.time <= 0) {
                clearInterval(this.timerInterval);
              }
            }
          );
        }, 1000);
      }
    );
  }

  cancelRequest(outsideCancel = false) {
    // only modal close if 60 section  are gone

    if (outsideCancel) {
      window.location.reload();
    } else {
      if (this.state.time === 0) {
        this.props.toggleBookingConfirmationModal();
      } else {
        this.setState(
          {
            cancelLoading: true,
          },
          () => {
            axios({
              url:
                process.env.REACT_APP_API_URL +
                "/sourcing/cancel_sourcing_request",
              method: "POST",
              data: {
                storeid: this.props.storeid,
                request_vfid: this.props.id,
              },
              withCredentials: true,
            })
              .then((res) => {
                this.setState({
                  cancelLoading: false,
                });
                return res;
              })
              .then((res) => {
                if (res.data.success === 1) {
                  this.props.toggleBookingConfirmationModal(res.data.id);
                  message.success("Request Cancelled Successfully!");
                } else {
                  this.setState({
                    bookingLoading: false,
                  });
                  message.error(res.data.msg);
                }
              })
              .catch((err) => {
                this.setState({
                  bookingLoading: false,
                });
                message.error(err.message);
              });
          }
        );
      }
    }
  }

  componentDidUpdate(pp) {
    if (this.props.visible !== pp.visible) {
      if (!this.props.visible && this.timerInterval) {
        clearInterval(this.timerInterval);
      }
      if (this.props.visible) {
        this.startTimer();
      }
    }
  }

  render() {
    const currency = this.props.currency;
    const totalQty = Object.values(this.props.variants).reduce(
      (acc, v) => acc + v,
      0
    );
    return (
      <Modal
        width={392}
        className="bulk-inventory-booking-confirmation-modal"
        title={
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{ verticalAlign: "middle", marginRight: 8 }}
            >
              <g transform="translate(-368 -600)">
                <circle
                  cx="12"
                  cy="12"
                  r="12"
                  transform="translate(368 600)"
                  fill="#2bc822"
                />
                <path
                  d="M383.81 609.038l-.231-.24a.636.636 0 00-.923 0l-3.921 4.081-1.391-1.447a.635.635 0 00-.923 0l-.231.24a.7.7 0 000 .961l2.082 2.169a.638.638 0 00.923 0l4.615-4.8a.7.7 0 000-.961z"
                  fill="#fff"
                />
              </g>
            </svg>
            Booking request sent!
          </div>
        }
        closable={true}
        onCancel={() => this.cancelRequest(true)}
        footer={null}
        visible={this.props.visible}
      >
        <div>
          <p style={{ fontSize: 16, color: "#212121", margin: "8px 0" }}>
            Booking Details:
          </p>
          <div style={{ padding: 16, background: "#F5F5F5" }}>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <p
                style={{ color: "#898989", flex: 1 }}
                className="underline mb16 f12"
              >
                {totalQty} Piece(s):
              </p>
              <p className="mb0 bold f16" style={{ color: "#212121" }}>
                {this.state.loading ? (
                  <div className="loader" />
                ) : (
                  `${getCurrencySymbol(currency)} ${formatNumber(
                    this.props.pricing.base
                  )}`
                )}
              </p>
            </div>

            <div>
              {this.props.pricing.gstamount > 0 ? (
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <p
                    style={{ color: "#898989", flex: 1, fontStyle: "Italic" }}
                    className="mb8 f12"
                  >
                    + GST
                  </p>
                  <p className="mb0 bold f16" style={{ color: "#212121" }}>
                    {getCurrencySymbol(currency)}
                    {formatNumber(
                      this.props.pricing.gstamount > 0
                        ? this.props.pricing.gstamount
                        : 0
                    )}
                  </p>
                </div>
              ) : null}
            </div>
            <p style={{ color: "#898989" }} className="mb16 f12">
              {this.props.storeGeo === "global" ? null : "Express Shipping"}{" "}
              <br />
              {/* lead time change for global stores */}
              {/* Shipping Time: 4-6 Days <br /> */}
              {this.props.storeGeo === "global"
                ? "Lead Time: 3-4 Days"
                : "Lead Time: 4-6 Days "}{" "}
              <br />
            </p>

            {this.props.p.freightData === "" ||
            this.props.p.freightData === "air" ? (
              this.props.p.quantity < 99 ? (
                <p style={{ color: "#898989" }} className="mb16 f12">
                  {" "}
                  {this.props.storeGeo === "global"
                    ? ""
                    : " Shipping Time: 4-6 Days"}{" "}
                </p>
              ) : this.props.storeGeo !== "global" ? (
                <p style={{ color: "#898989" }} className="mb16 f12">
                  {" "}
                  {this.props.storeGeo === "global"
                    ? null
                    : " Air Freight Transit Time: 8-10 Days"}{" "}
                </p>
              ) : null
            ) : this.props.p.freightData === "sea" &&
              this.props.p.quantity > 99 ? (
              this.props.geo !== "global" ? (
                <p style={{ color: "#898989" }} className="mb16 f12">
                  {" "}
                  Sea Freight Transit Time: 30-40 Days{" "}
                </p>
              ) : null
            ) : this.props.storeGeo !== "global" ? (
              <p style={{ color: "#898989" }} className="mb16 f12">
                {" "}
                Air Freight Transit Time: 8-10 Days{" "}
              </p>
            ) : null}
            {/* 
            {dd ? (
              <p style={{ color: "rgb(137, 137, 137)", fontSize: "12px" }}>
                {dd.innerHTML}
              </p>
            ) : null} */}

            <div
              style={{
                display: "flex",
                borderTop: "1px solid #707070",
                paddingTop: 19,
                alignItems: "baseline",
              }}
            >
              <p
                style={{ color: "#898989", flex: 1 }}
                className="underline mb8 f12"
              >
                Total Est. Cost:
              </p>
              <p className="mb0 bold f16" style={{ color: "#212121" }}>
                {this.state.loading ? (
                  <div className="loader" />
                ) : (
                  `${getCurrencySymbol(currency)} ${formatNumber(
                    this.props.pricing.total
                  )}`
                )}
              </p>
            </div>
          </div>
          <div style={{ width: 264, margin: "0 auto" }}>
            <div className="btn-wraper" style={{ margin: "24px auto" }}>
              <div className="track-sourcing">
                <a
                  href={`/${this.props.storeGeo}/${this.props.storeid}/requests/sourcing-requests?search=${this.props.vfprodid}`}
                  className="view-in-vf-link"
                  rel="noopener noreferrer"
                >
                  Track sourcing requests
                </a>

                <Icon component={NewTab} />
              </div>

              <Button
                type="primary"
                className="btn-green w100 f16 py8"
                style={{ marginTop: 20, marginBottom: 10 }}
                onClick={() => this.cancelRequest(true)}
              >
                Continue
              </Button>
              <div
                style={{
                  display: "flex",
                }}
              >
                <Button
                  className="f16"
                  disabled={this.state.time <= 0}
                  style={{
                    flex: 1,
                    border: "2px solid #898989",
                    borderRadius: 2,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    height: "unset",
                  }}
                  loading={this.state.cancelLoading}
                  onClick={() => this.cancelRequest()}
                >
                  Cancel
                </Button>
                <span
                  style={{
                    background: "#898989",
                    color: "white",
                    borderTopRightRadius: 2,
                    borderBottomRightRadius: 2,
                  }}
                  className="f16 px8 py8"
                >
                  00:
                  {this.state.time > 9
                    ? `${this.state.time}`
                    : `0${this.state.time}`}
                </span>
              </div>
            </div>

            <p
              style={{ textAlign: "center", fontSize: 12, fontStyle: "italic" }}
            >
              Note: You will receive a confirmation call from vFulfill team and
              the final amount will be charged from your wallet only after your
              confirmation.
              <br />
              You can check all your “Requests” from <br /> Dashboard &gt;
              Inventory &gt; Sourcing Requests.
            </p>
          </div>
        </div>
      </Modal>
    );
  }
}
class BookInventoryFloatingWidget extends Component {
  state = {
    pricing: {
      total: 0,
      variants: {},
      gstamount: 0,
    },
    modalData: [],
    days: "N/A",
    loading: false,
    showPackagingModal: false,
    packagingModalVisible: false,
    packagingData: [],
    bookingLoading: false,
    bookingConfirmationModal: {
      visible: false,
      id: null,
    },
    confimModal: false,
    bookingId: "",
    selectedImages: [],
    loadingImage: false,
    remarks: "",
    privateLabel: false,
    noBalanceVisible: false,
    apiRes: {},
  };

  handleRemarksChange = (e) => {
    this.setState({ remarks: e.target.value });
  };
  handleImageChange = (file) => {
    // const selectedImages = Array.from(event.target.files);
    console.log(file);
    const formData = new FormData();
    formData.append("file", file);

    this.setState({ loadingImage: true }, () => {
      axios({
        url:
          process.env.REACT_APP_API_URL +
          "/sourcing/upload_clientsourcingrequest_packaging_docs",
        method: "POST",
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          if (response.data.success) {
            const uploadedUrl = response.data.file;
            // message.success();
            this.setState({
              selectedImages: [...this.state.selectedImages, uploadedUrl],
            });
          } else {
            // console.log("errrrrrreeeees", response);
            message.error(response.data.msg);
          }
          this.setState({ loadingImage: false });
        })
        .catch((error) => {
          // console.log("errrrrrr", error);
          message.error("Error uploading images:", error);
          this.setState({ loadingImage: false });
        });
    });
  };
  handleCancelimage = (index) => {
    let selected = [...this.state.selectedImages];
    selected.splice(index, 1);
    this.setState({
      selectedImages: selected,
    });
  };
  handleCustomPacking = () => {
    // if (!this.state.remarks) {
    //   message.error("Please enter remarks ");
    //   return;
    // }
    axios({
      url:
        process.env.REACT_APP_API_URL +
        "/sourcing/add_clientsourcingrequest_packaging_suggestions",
      method: "POST",
      data: {
        csrid: this.props.bookingId,
        remarks: this.state.remarks,
        suggestions: this.state.selectedImages,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.msg);
          this.props.cancel();
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  onChangePrivateLabel = (checked) => {
    if (!checked) {
      this.setState({
        privateLabel: checked,
        selectedImages: [],
        loadingImage: false,
        remarks: "",
      });
    } else {
      this.setState({ privateLabel: checked });
    }
  };
  toggleBookingConfirmationModal(id = null) {
    this.setState((state) => ({
      bookingConfirmationModal: {
        visible: !state.bookingConfirmationModal.visible,
        id,
      },
    }));
  }

  fcHandler(geo, freightData) {
    if (geo === "global") {
      return "china_freight";
    }

    if (freightData === "vf") {
      return "vf_inventory";
    }

    if (freightData === "air") {
      return "india_air_freight";
    }

    if (freightData === "sea") {
      return "india_sea_freight";
    }
  }

  packagingModalHandler() {
    if (!this.state.showPackagingModal) {
      this.props.loadProductData();
    }
  }

  bookInventory(btn = false) {
    // fc china_freight for global stores

    if (this.state.apiRes.data.success == 2) {
      return this.setState({ noBalanceVisible: true });
    }

    if (btn) {
      this.setState({ confimModal: true });
    } else {
      const totalQty = Object.values(this.props.variants).reduce(
        (acc, v) => acc + v,
        0
      );
      this.setState(
        {
          bookingLoading: true,
        },
        () => {
          axios({
            url:
              process.env.REACT_APP_API_URL +
              "/sourcing/create_sourcing_request",
            method: "POST",
            data: {
              storeid: this.props.storeid,
              product_vfid: this.props.vfprodid,
              mix_skus: 0,
              skus: Object.entries(this.props.variants)
                .filter((d) => d[1] !== 0)
                .reduce((acc, [k, v]) => ((acc[k] = v), acc), {}),
              packaging: 0,
              // fc: "india_express_freight",
              quantity: totalQty,
              currency: this.props.currency,
              pricing: this.state.pricing,
              fc: this.fcHandler(this.props.storeGeo, this.props.freightData),
              remarks: this.state.remarks,
              suggestions: this.state.selectedImages,
            },
            withCredentials: true,
          })
            .then((res) => {
              return res;
            })
            .then((res) => {
              if (res.data.success === 1) {
                message.success(res.data.msg);
                this.setState({
                  bookingLoading: false,
                  confimModal: false,
                  packagingModalVisible: true,
                  bookingId: res.data.id,
                });

                // this.toggleBookingConfirmationModal(res.data.id);
              } else if (res.data.success === 2) {
                this.setState({
                  bookingLoading: false,
                });

                this.setState({ noBalanceVisible: true });
              } else {
                this.setState({
                  bookingLoading: false,
                });
                message.error(res.data.msg);
              }
            })
            .catch((err) => {
              this.setState({
                bookingLoading: false,
              });
              message.error(err.message);
            });
        }
      );
    }
  }

  leadTime(time) {
    if (time === "") {
      return "N/A";
    }

    if (time === 0 || time === "0") {
      return "0 day";
    }

    return `${time} days`;
  }
  getPricing() {
    this.setState(
      {
        loading: true,
      },
      () => {
        if (this.cancelToken) {
          this.cancelToken.cancel();
        }
        this.cancelToken = axios.CancelToken.source();
        axios({
          url: process.env.REACT_APP_API_URL + "/products/calculate_csrpricing",
          method: "post",
          cancelToken: this.cancelToken.token,
          data: {
            vfprodid: this.props.vfprodid,
            variants: this.props.variants,
            packaging: 0,
            storeid: this.props.storeid,
            geo: this.props.currency === "USD" ? "global" : "in",
            freight: this.props.freightData,
          },
          withCredentials: true,
        })
          .then((res) => {
            this.setState({ loading: false });
            return res;
          })
          .then((res) => {
            if (!!res.data.error_msg) {
              this.props.variantsErrorInfo(res.data.error_msg);
            } else {
              this.props.variantsErrorInfo("");
            }
            let product = this.props.product.variants;
            let variants = this.props.variants;

            let api = res.data.pricing;

            let fill = Object.entries(variants)
              .filter((d) => d[1] !== 0)
              .reduce((acc, [k, v]) => ((acc[k] = v), acc), {});

            variants = fill;

            let dataForModal = [];

            product.forEach((product) => {
              let obj = {
                vfsku: "",
                img: "",
                qty: "",
                price: "",
                totalPrice: "",
                specs: "",
              };

              if (
                api["variants"].hasOwnProperty(product.vfsku) &&
                !!variants[product.vfsku]
              ) {
                obj.qty = variants[product.vfsku];
                obj.specs = product.allspecs;
                obj.vfsku = product.vfsku;
                obj.img = product.images[0];
                obj.price = api["variants"][product.vfsku]["base"];
                obj.totalPrice = api["variants"][product.vfsku]["base"];

                dataForModal.push(obj);
              }
            });

            this.setState({
              pricing: res.data.pricing,
              modalData: dataForModal,
              apiRes: res,
              days: this.leadTime(res.data.lead_time),
            });
          })
          .catch((e) => {});
      }
    );
  }

  freightStatus(status) {
    if (status === "") {
      return "None";
    }
    if (status === "vf") {
      return "VF Inventory";
    }

    if (status === "sea") {
      return "Transit Via Sea";
    }

    if (status === "air") {
      return "Transit Via Air";
    }
  }

  componentDidMount() {
    if (this.props.product !== undefined) {
      let packagingData =
        this.props.product.hasOwnProperty("recommended_packaging") &&
        this.props.product.recommended_packaging.length
          ? true
          : false;

      if (packagingData) {
        this.setState({
          showPackagingModal: true,
          packagingData: this.props.product.recommended_packaging,
        });
      }
    }
  }

  componentDidUpdate(pp) {
    const prevQty = Object.values(pp.variants).reduce((acc, v) => acc + v, 0);
    const newQty = Object.values(this.props.variants).reduce(
      (acc, v) => acc + v,
      0
    );
    if (pp.freightData !== this.props.freightData) {
      this.getPricing();
    }
    if (prevQty !== newQty) {
      this.getPricing();
    }
  }

  processingTimeHandler() {
    // stocking time calculation

    const totalQty = Object.values(this.props.variants).reduce(
      (acc, v) => acc + v,
      0
    );

    let days = "N/A";
    let restockingArr =
      !!this.props.product.restocking_time &&
      !!this.props.product.restocking_time.length
        ? this.props.product.restocking_time
        : [];
    let freightType = this.props.freightData;

    restockingArr.forEach((d, i) => {
      if (freightType === "vf") {
        days = "0 day";
      }

      // air_restocking_time
      if (freightType === "air") {
        if (totalQty <= Number(d.moq_start)) {
          return (days = `${d.air_restocking_time.start}-${d.air_restocking_time.end} days`);
        }

        if (totalQty >= Number(d.moq_start) && totalQty <= Number(d.moq_end)) {
          return (days = `${d.air_restocking_time.start}-${d.air_restocking_time.end} days`);
        }

        if (i === restockingArr.length - 1 && totalQty >= Number(d.moq_end)) {
          return (days = `${d.air_restocking_time.start}-${d.air_restocking_time.end} days`);
        }
      }

      // sea_restocking_time
      if (freightType === "sea") {
        if (totalQty <= Number(d.moq_start)) {
          return (days = `${d.sea_restocking_time.start}-${d.sea_restocking_time.end} days`);
        }

        if (totalQty >= Number(d.moq_start) && totalQty <= Number(d.moq_end)) {
          return (days = `${d.sea_restocking_time.start}-${d.sea_restocking_time.end} days`);
        }

        if (i === restockingArr.length - 1 && totalQty >= Number(d.moq_end)) {
          return (days = `${d.sea_restocking_time.start}-${d.sea_restocking_time.end} days`);
        }
      }
    });

    return days;
  }

  render() {
    // const rangval = Object.entries(this.props.product.moq_sourcing).map(
    //   ([range, data], index) => {
    //     const rangeVals = range.split("-").map((rv) => Number(rv));
    //     return rangeVals[0];
    //   }
    // );
    const imageprops = {
      multiple: true,
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        // this.setState((state) => ({
        //   // fileList: [.file]
        //   fileList: [...state.fileList, file],
        // }));
        this.handleImageChange(file);
        return false;
      },
      fileList: [],
    };
    const totalQty = Object.values(this.props.variants).reduce(
      (acc, v) => acc + v,
      0
    );
    const currency = this.props.currency;
    const store = this.props.store;
    return (
      <div
        className="floating-widget-wraper"
        style={{
          background: "white",
          padding: "12px 12px",
          width: 273,
        }}
      >
        {/* floating modal image div */}
        {this.props.importStoreData ? (
          <div
            className="import-store-container"
            style={{
              padding: "12px 12px",
              backgroundColor: "#F5F5F5",
              marginBottom: "8px",
            }}
          >
            <div
              className="import-store"
              style={{
                display: "flex",
                paddingBottom: "16px",
                position: "relative",
              }}
            >
              <div className="flex-1" style={{ marginRight: "8px" }}>
                <img
                  src={this.props.catalogImage[0].url}
                  alt="importStore"
                  style={{ width: "48px" }}
                />
              </div>

              <div className="flex-1" style={{ overflow: "hidden" }}>
                <h4 className="catalog-name-tooltip">
                  {this.props.catalogName}
                  <span className="catalog-tooltip">
                    {this.props.catalogName}
                  </span>
                </h4>

                <p
                  style={{
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.032em",
                    color: "#898989",
                  }}
                >
                  PRODUCT ID: {this.props.vfprodid}
                </p>
              </div>
            </div>
            <div className="import-store-button">
              <button
                style={{
                  backgroundColor: "#2BC822",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: "500",
                  width: "100%",
                  border: "none",
                  color: "#fff",
                  padding: "12px 16px",
                  borderRadius: "2px",
                  letterSpacing: "0.032em",
                  boxShadow: "0 2px 3px rgba(0, 0, 0, 0.2)",
                }}
                onClick={(d) =>
                  this.props.importProducts([this.props.vfprodid])
                }
              >
                Import to Store
              </button>
            </div>
          </div>
        ) : null}

        <div className="pricing-container">
          <div className="float-heading">
            <p>Inventory Freight Type:</p>{" "}
            <span> {this.freightStatus(this.props.freightData)} </span>
          </div>

          {/* to do */}
          {/* <div className="float-heading">
            <p style={{ marginRight: "2px" }}>Restocking Time:</p>

            <span style={{ margin: "0 !important" }}>{this.state.days}</span>

            <GlobalTooltipIcon
              tooltipText={
                "Time required for the stock to be ready to be picked up from the supplier."
              }
            />
          </div> */}

          {this.props.importStoreData ? null : (
            <div className="horizental-line" />
          )}

          {/* price head */}

          {this.props.importStoreData ? null : (
            <div className="price-heading">
              <p> {totalQty} Piece(s):</p>
              <span>
                {" "}
                {this.state.loading ? (
                  <div className="loader" />
                ) : (
                  `${getCurrencySymbol(currency)} ${formatNumber(
                    this.state.pricing.base > 0 ? this.state.pricing.base : 0
                  )}`
                )}
              </span>
            </div>
          )}

          {/* show gst conditionaly >*/}

          {this.state.pricing.gstamount > 0 && !this.props.importStoreData ? (
            <div className="gst-heading">
              <p>+ {this.props.gstPercentage}% GST</p>
              <span>
                {getCurrencySymbol(currency)}
                {formatNumber(
                  this.state.pricing.gstamount > 0
                    ? this.state.pricing.gstamount
                    : 0
                )}
              </span>
            </div>
          ) : null}

          {/* all fees info */}

          {this.props.importStoreData ? null : (
            <div className="info-container">
              <div className="shipping">
                <p>Shipping to India: Included</p>
                <GlobalTooltipIcon
                  tooltipText={
                    "The cost for shipping this stock from the supplier to our Indian Warehouse is already included."
                  }
                />
              </div>

              <div className="custom">
                <p>Customs Duty: Included</p>
                <GlobalTooltipIcon
                  tooltipText={
                    "Custom Duties and Import Taxes to be paid to the Government(s) are already included."
                  }
                />
              </div>

              <div className="inward">
                <p>Inward Fees: Included</p>
                <GlobalTooltipIcon
                  tooltipText={
                    "Cost of Labelling, QC and Inwarding this stock to our Indian Warehouse is already included."
                  }
                />
              </div>

              <div className="compliance">
                <p>Compliance Fees: Included</p>
                <GlobalTooltipIcon
                  tooltipText={
                    "Compliance Fees(if applicable) to be paid to the respective party is already included."
                  }
                />
              </div>
            </div>
          )}

          <div className="total-cost-container">
            <div className="total-cost">
              <div className="cost-wraper">
                <p style={{ color: "#898989" }}>
                  Total{" "}
                  {this.props.importStoreData ? (
                    <span> {totalQty} Piece(s)</span>
                  ) : null}
                </p>{" "}
                <span style={{ color: "#898989" }}>:</span>
              </div>

              <p className="mb0 bold f16" style={{ color: "#212121" }}>
                {this.state.loading ? (
                  <div className="loader" />
                ) : (
                  `${getCurrencySymbol(currency)} ${formatNumber(
                    this.state.pricing.total
                  )}`
                )}
              </p>
            </div>

            {this.props.importStoreData ? (
              <div className="gst-heading-bottom">
                <p style={{ fontStyle: "normal" }}>
                  Inclusive of GST & Other Charges
                </p>

                {this.state.pricing.gstamount > 0 ? (
                  <GlobalTooltipIcon
                    tooltipText={
                      <>
                        <div className="float-tooltip">
                          <h1 style={{ fontStyle: "normal" }}>
                            GST & Other Charges
                          </h1>
                          <div>
                            <p>1. {this.props.gstPercentage}% GST Included</p>
                            <p>2. Shipping to India Included</p>
                            <p>3. Customs Duty Included</p>
                            <p>4. Inward Fees Included</p>
                            <p>5. Compliance Fees Included</p>
                          </div>
                        </div>{" "}
                      </>
                    }
                  />
                ) : (
                  <GlobalTooltipIcon
                    tooltipText={
                      <>
                        <div className="float-tooltip">
                          <h1 style={{ fontStyle: "normal" }}>Other Charges</h1>
                          <div>
                            <p>1. Shipping to India Included</p>
                            <p>2. Customs Duty Included</p>
                            <p>3. Inward Fees Included</p>
                            <p>4. Compliance Fees Included</p>
                          </div>
                        </div>{" "}
                      </>
                    }
                  />
                )}
              </div>
            ) : null}

            {this.props.pricing && this.props.pricing.gstamount > 0 ? (
              <p className="gst-desc">
                *You will receive an input credit of the GST charged in the
                amount above
              </p>
            ) : null}
          </div>

          {totalQty > 0 && this.state.pricing.total !== 0 ? (
            hasPermission(store, "raise_booking_request") ? (
              <div className="raise_booking_request">
                <Button
                  type={
                    totalQty > 0 && this.state.pricing.total === 0
                      ? "cancel"
                      : "primary"
                  }
                  disabled={
                    totalQty > 0 && this.state.pricing.total === 0
                      ? true
                      : false
                  }
                  style={{
                    fontSize: "14px",
                    letterSpacing: "0.032em",
                    display: `${
                      this.props.storeGeo === "global" &&
                      this.props.radio === "in"
                        ? "none"
                        : "block"
                    }`,
                  }}
                  onClick={(bool) => this.bookInventory(true)}
                  loading={this.state.bookingLoading}
                >
                  {this.props.freightData === "vf"
                    ? "Review Booking"
                    : " Raise Sourcing Request"}
                </Button>

                <p
                  style={{ color: "#898989", textAlign: "center" }}
                  className="f12 pb0 italic"
                >
                  Note: You will receive a confirmation call from vFulfill team
                  and the final amount will be charged from your wallet only
                  after your confirmation.
                </p>
              </div>
            ) : null
          ) : null}
        </div>

        {/* suggested selling price as per geo */}

        {this.props.suggestedPrice && this.props.geo !== "global" ? (
          <div
            style={{
              display: "flex",
              background: "#FFF0C4",
              padding: 12,
              marginTop: 8,
            }}
          >
            <p
              style={{ color: "#898989", flex: 1 }}
              className="underline mb0 f12"
            >
              Sugg. Selling Price: <br /> (Per Piece)
            </p>
            <p className="mb0 bold f16" style={{ color: "#212121" }}>
              {getCurrencySymbol(currency)} {this.props.suggestedPrice}
            </p>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              background: "#FFF0C4",
              padding: 12,
              marginTop: 8,
            }}
          >
            <p
              style={{ color: "#898989", flex: 1 }}
              className="underline mb0 f12"
            >
              Sugg. Selling Price: <br /> (Per Piece)
            </p>
            <p className="mb0 bold f16" style={{ color: "#212121" }}>
              {getCurrencySymbol(currency)} {this.props.globalSuggested}
            </p>
          </div>
        )}

        {/* booking request comfirmation Modal */}
        <BookingConfirmationModal
          currency={currency}
          visible={this.state.bookingConfirmationModal.visible}
          id={this.state.bookingConfirmationModal.id}
          vfprodid={this.props.vfprodid}
          p={this.props}
          storeGeo={this.props.storeGeo}
          storeid={this.props.storeid}
          variants={this.props.variants}
          pricing={this.state.pricing}
          freightData={this.props.freightData}
          toggleBookingConfirmationModal={() =>
            this.toggleBookingConfirmationModal()
          }
        />

        {!!this.state.confimModal ? (
          <BulkOrderConfirmModal
            confirmOrder={() => this.bookInventory()}
            cancelOrder={() =>
              this.setState({
                confimModal: false,
                selectedImages: [],
                loadingImage: false,
                remarks: "",
                privateLabel: false,
              })
            }
            data={this.state.modalData}
            pricing={this.state.pricing}
            freightType={this.freightStatus(this.props.freightData)}
            visible={this.state.confimModal}
            loading={this.state.bookingLoading}
            currency={getCurrencySymbol(currency)}
            gstPercentage={this.props.gstPercentage}
            stockProcessingTime={this.state.days}
            handleRemarksChange={(e) => this.handleRemarksChange(e)}
            handleImageChange={(e) => this.handleImageChange(e)}
            handleCancelimage={(e) => this.handleCancelimage(e)}
            onChangePrivateLabel={(e) => this.onChangePrivateLabel(e)}
            selectedImages={this.state.selectedImages}
            loadingImage={this.state.loadingImage}
            remarks={this.state.remarks}
            privateLabel={this.state.privateLabel}
            imageprops={imageprops}
          />
        ) : null}

        <NoBalanceModal
          cancel={() => this.setState({ noBalanceVisible: false })}
          visible={this.state.noBalanceVisible}
        />

        <ConfirmPackagingModal
          product={this.props.product}
          bookingId={this.state.bookingId}
          cancel={() =>
            this.setState({ packagingModalVisible: false }, () =>
              this.props.loadProductData()
            )
          }
          freightType={this.freightStatus(this.props.freightData)}
          data={this.state.packagingData.filter(
            (d) => d.complimentary_packaging !== 1
          )}
          currency={getCurrencySymbol(currency)}
          visible={this.state.packagingModalVisible}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};

export default connect(mapStateToProps)(BookInventoryFloatingWidget);
