import React from "react";
import { Modal, Radio, Icon, Button } from "antd";
import { ReactComponent as indainflagican } from "../../../../assets/images/icons/indiaflag2.svg";
import { ReactComponent as globalflagican } from "../../../../assets/images/icons/globalflag2.svg";
import { ReactComponent as greenContinueIcan } from "../../../../assets/images/icons/greenContinueIcan.svg";
import { SHOPIFY_NAME } from "../../../../constants";
import "./index.scss";

class AddStoreModal extends React.Component {
  state = {
    india: "India",
    global: "Global",
  };

  toggleIndiaState() {
    this.setState({ india: "India" });
  }
  toggleIndiaContinue() {
    this.setState({ india: "Continue" });
  }

  toggleGlobalState() {
    this.setState({ global: "Global" });
  }

  toggleGlobalContinue() {
    this.setState({ global: "Continue" });
  }

  render() {
    const props = this.props;
    const { visible, handleCancel } = props;
    return (
      <div>
        <Modal
          className="switch-store-modal-second"
          width={504}
          title={
            <div>
              <p className="title_txt">{` Add New ${SHOPIFY_NAME}`} </p>
              <p className="store_objective">
                {`Select the geography of your ${SHOPIFY_NAME} operations`}
              </p>
            </div>
          }
          visible={visible}
          onCancel={handleCancel}
          footer={null}
          destroyOnClose={true}
        >
          <div>
            <div className="global_and_indian_dropshipping">
              <Radio.Group
                value={this.props.flag.flagchanel}
                onChange={(e) =>
                  this.props.toggleIndiaGlobalModal(
                    "flagchanel",
                    e.target.value
                  )
                }
              >
                <div className="flag_radio_btn">
                  <div>
                    <div
                      onMouseEnter={() => this.toggleIndiaContinue()}
                      onMouseLeave={() => this.toggleIndiaState()}
                      className={
                        this.props.flag.flagchanel === "in"
                          ? "India-type-card"
                          : "Global-type-card"
                      }
                    >
                      <Radio value="in" className="indian_flag">
                        <Icon component={indainflagican} />
                      </Radio>
                    </div>
                    <span className="flag">
                      {this.state.india}
                      {this.state.india === "Continue" ? (
                        <Icon component={greenContinueIcan} />
                      ) : null}
                    </span>
                  </div>

                  <div>
                    <div
                      onMouseEnter={() => this.toggleGlobalContinue()}
                      onMouseLeave={() => this.toggleGlobalState()}
                      className={
                        this.props.flag.flagchanel === "global"
                          ? "Global-type-card"
                          : "India-type-card"
                      }
                    >
                      <Radio value="global" className="global_flag">
                        <Icon component={globalflagican} />
                      </Radio>
                    </div>
                    <span className="flag">
                      {" "}
                      {this.state.global}
                      {this.state.global === "Continue" ? (
                        <Icon component={greenContinueIcan} />
                      ) : null}
                    </span>
                  </div>
                </div>
              </Radio.Group>
            </div>

            <div className="footer_button_continue">
              <Button
                type="default"
                onClick={() => this.props.toggleGobackAddStoreModal()}
                className="go_back_btn"
              >
                <span className="svg_go_back">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16"
                  >
                    <g
                      id="Polygon_63"
                      data-name="Polygon 63"
                      transform="translate(0 16) rotate(-90)"
                      fill="none"
                    >
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#898989"
                      />
                    </g>
                  </svg>
                </span>
                <span className="go_back_txt">Go Back</span>
              </Button>

              <Button
                type="default"
                onClick={() => handleCancel()}
                style={{ width: "120px" }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default AddStoreModal;
