import React from "react";
import { Button, message, Popconfirm } from "antd";
import { ReactComponent as MoverIllustrationShaker } from "../../../../../../assets/images/icons/MoverIllustrationShaker.svg";
import { ReactComponent as RightCircle } from "../../../../../../assets/images/icons/RightCircle.svg";
import { ReactComponent as BlackRightCircle } from "../../../../../../assets/images/icons/BlackRightCircle.svg";
import { ReactComponent as WingMenLogo } from "../../../../../../assets/images/icons/WingMenLogo.svg";
import { ReactComponent as PrToolLogo } from "../../../../../../assets/images/icons/PrToolLogo.svg";
import { ReactComponent as CheckOutXLogo } from "../../../../../../assets/images/icons/CheckOutXLogo.svg";
import { ReactComponent as CrossIcon } from "../../../../../../assets/images/icons/circle-cross2.svg";
import analytics from "../../../../../../analytics";
import axios from "axios";
import "./index.scss";

class ShakerCard extends React.Component {
  state = { loading: false };

  submitPlan(planid, planName, isTrial = false) {
    this.props.setState({ loading: true });

    this.setState({ loading: true, planName: planName }, () => {
      axios({
        url: process.env.REACT_APP_API_URL + "/stripe/make_payment",
        method: "post",
        data: {
          plan_id: planid,
          trial: !!isTrial ? 1 : 0,
        },
        withCredentials: true,
      })
        .then((res) => {
          this.props.setState({ loading: false });
          this.setState({ loading: false });

          if (res.data.success === 1) {
            this.props.setState({
              ...this.props.state,
              clientSecretKey: res.data.client_secret,
              planName: planName,
              plan_id: planid,
              activeKey: "2",
              trial: isTrial,
              couponCode: "",
              couponPrice: "",
            });
          } else {
            message.error(res.data.msg);
          }
        })
        .catch((e) => {
          message.error(e.message);
          this.props.setState({ loading: false });
          this.setState({ loading: false });
        });
    });
  }
  upgradePlan(planid, planName) {
    this.props.setState({ loading: true });

    this.setState({ loading: true }, () => {
      axios({
        url: process.env.REACT_APP_API_URL + "/stripe/update_plan",
        method: "post",
        data: {
          plan_id: planid,
        },
        withCredentials: true,
      })
        .then((res) => {
          this.props.setState({ loading: false });
          this.setState({ loading: false });

          if (res.data.success === 1) {
            if (!!res.data.client_secret) {
              this.props.setState({
                ...this.props.state,
                clientSecretKey: res.data.client_secret,
                planName: planName,
                plan_id: planid,
                activeKey: "2",
              });
            } else {
              message.success(res.data.msg);
              setTimeout(
                () => window.location.assign("/switch-store?plan"),
                1000
              );
            }
          } else {
            message.error(res.data.msg);
          }
        })
        .catch((e) => {
          message.error(e.message);
          this.props.setState({ loading: false });
          this.setState({ loading: false });
        });
    });
  }

  topButtonHandler() {
    if (
      this.props.isActivePlan &&
      this.props.current_plan.plan_status === "trialing"
    ) {
      return (
        <Popconfirm
          title="Proceed with Ending Trial and Starting Subscription?"
          onConfirm={(id, name) => {
            analytics.track("Upgrade Shaker Clicked");
            this.upgradePlan(
              this.props.card.plan_id,
              this.props.card.plan_name
            );
          }}
          onCancel={null}
          okText="Yes"
          cancelText="No"
        >
          <Button
            id="payment-disable"
            className="payment-disable"
            loading={this.state.loading}
          >
            Start Now
          </Button>
        </Popconfirm>
      );
    }
    if (
      this.props.current_plan &&
      this.props.current_plan.plan_status === "trialing"
    ) {
      return (
        <Popconfirm
          title="Proceed with Ending Trial and Starting Subscription?"
          onConfirm={(id, name) => {
            analytics.track("Upgrade Shaker Clicked");
            this.upgradePlan(
              this.props.card.plan_id,
              this.props.card.plan_name
            );
          }}
          onCancel={null}
          okText="Yes"
          cancelText="No"
        >
          <Button loading={this.state.loading}>Start Now</Button>
        </Popconfirm>
      );
    }

    if (this.props.isActivePlan) {
      return (
        <Button
          id="payment-disable"
          className="payment-disable"
          disabled
          loading={this.state.loading}
        >
          Currently Active
        </Button>
      );
    }

    if (this.props.upgradePlan) {
      return (
        <Button
          disabled={this.props.loading}
          loading={this.state.loading}
          onClick={(id, name) => {
            analytics.track("Upgrade Shaker Clicked");

            this.upgradePlan(
              this.props.card.plan_id,
              this.props.card.plan_name
            );
          }}
        >
          Change Plan
        </Button>
      );
    }

    if (
      !!this.props.website_signup &&
      !!this.props.website_signup.trial_period
    ) {
      return (
        <Button
          disabled={this.props.loading}
          loading={this.state.loading}
          onClick={(id, name) => {
            analytics.track("Trial Shaker Clicked");
            this.submitPlan(
              this.props.card.plan_id,
              this.props.card.plan_name,
              true
            );
          }}
        >
          Try {this.props.website_signup.trial_period} Month(s) For $1
        </Button>
      );
    }

    if (this.props.planType === "month") {
      return (
        <Button
          disabled={this.props.loading}
          loading={this.state.loading}
          onClick={(id, name) => {
            analytics.track("Trial Shaker Clicked");
            this.submitPlan(
              this.props.card.plan_id,
              this.props.card.plan_name,
              true
            );
          }}
        >
          Try 7 Days For $1
        </Button>
      );
    }

    return (
      <Button
        disabled={this.props.loading}
        loading={this.state.loading}
        onClick={(id, name) => {
          analytics.track("Shaker Clicked");
          this.submitPlan(this.props.card.plan_id, this.props.card.plan_name);
        }}
      >
        Start Now
      </Button>
    );
  }

  bottomButtonHandler() {
    if (
      this.props.isActivePlan &&
      this.props.current_plan.plan_status === "trialing"
    ) {
      return (
        <Popconfirm
          title="Proceed with Ending Trial and Starting Subscription?"
          onConfirm={(id, name) => {
            analytics.track("Upgrade Shaker Clicked");
            this.upgradePlan(
              this.props.card.plan_id,
              this.props.card.plan_name
            );
          }}
          onCancel={null}
          okText="Yes"
          cancelText="No"
        >
          <Button
            id="payment-disable"
            className="payment-disable"
            loading={this.state.loading}
          >
            Start Now
          </Button>
        </Popconfirm>
      );
    }
    if (
      this.props.current_plan &&
      this.props.current_plan.plan_status === "trialing"
    ) {
      return (
        <Popconfirm
          title="Proceed with Ending Trial and Starting Subscription?"
          onConfirm={(id, name) => {
            analytics.track("Upgrade Shaker Clicked");
            this.upgradePlan(
              this.props.card.plan_id,
              this.props.card.plan_name
            );
          }}
          onCancel={null}
          okText="Yes"
          cancelText="No"
        >
          <Button loading={this.state.loading}>Start Now</Button>
        </Popconfirm>
      );
    }

    if (this.props.isActivePlan) {
      return (
        <Button
          id="payment-disable"
          className="payment-disable"
          disabled
          loading={this.state.loading}
        >
          Currently Active
        </Button>
      );
    }

    if (this.props.upgradePlan) {
      return (
        <Button
          disabled={this.props.loading}
          loading={this.state.loading}
          onClick={(id, name) => {
            analytics.track("Upgrade Shaker Clicked");

            this.upgradePlan(
              this.props.card.plan_id,
              this.props.card.plan_name
            );
          }}
        >
          Change Plan
        </Button>
      );
    }

    return (
      <Button
        disabled={this.props.loading}
        loading={this.state.loading}
        onClick={(id, name) => {
          analytics.track("Shaker Clicked");
          this.submitPlan(this.props.card.plan_id, this.props.card.plan_name);
        }}
      >
        Start Now
      </Button>
    );
  }

  yearlyText(planInterval) {
    if (planInterval !== "month") {
      return (
        <>
          <div className="save">
            <span>
              Savings {this.props.getCurrencySymbol(this.props.card.currency)}
              {Math.round(
                this.props.card.monthly_charges * 12 - this.props.card.amount
              )}{" "}
              per year!
            </span>
          </div>
        </>
      );
    }
  }
  render() {
    return (
      <div
        // onClick={(key) => this.props.tabHandler("2")}

        className="shaker-card plan-card"
      >
        {(!!this.props.preview && !!this.props.state.trial) ||
        (this.props.isActivePlan &&
          this.props.current_plan.plan_status === "trialing") ? (
          <div class="card-ribbon card-ribbon-top-left">
            <span>Trial Plan</span>
          </div>
        ) : null}

        <div className="top-bar">
          <div className="trend">
            <span>🔥 </span> <p>Most Popular Plan!</p>
          </div>
        </div>

        <div className="shaker-cards-wraper">
          {!!this.props.preview ? (
            <div className="price-card">
              <h1>Shaker</h1>
              {!!this.props.website_signup &&
              !!this.props.website_signup.trial_period ? (
                <span>
                  {this.props.website_signup.trial_period} Month(s) access for
                  $1
                </span>
              ) : !!this.props.state.trial ? (
                <span>7 days access for $1</span>
              ) : null}
            </div>
          ) : (
            <div className="price-card">
              <h1>Shaker</h1>

              <div className="price">
                <h2>
                  {this.props.getCurrencySymbol(this.props.card.currency)}

                  {this.props.planType === "month"
                    ? this.props.card.amount
                    : Math.round(this.props.card.amount / 12)}
                </h2>
                <span>/month*</span>
              </div>

              {this.props.planType === "month" ? (
                <span></span>
              ) : (
                <span>*if paid annually</span>
              )}

              {this.yearlyText(this.props.planType)}

              {this.topButtonHandler()}
            </div>
          )}

          {!this.props.preview && (
            <div className="illustration-wraper">
              <MoverIllustrationShaker />
              <p>
                {/* “I want to <span>increase my profits, reduce my returns</span> &{" "}
                <span>scale my business”</span> */}
                “I want to{" "}
                <span>
                  increase profits, reduce returns, & automate operations on my
                  eCommerce business.”
                </span>
              </p>
            </div>
          )}

          <div
            className={`features-card ${
              !!this.props.preview ? "scroller" : ""
            }`}
          >
            {/* product research */}
            <div className="inner-part">
              <h1>🔍 Product Research:</h1>

              <div className="features">
                <div className="feature-row">
                  <RightCircle />
                  <p>
                    🔥 <span>5</span> New Products / Week
                  </p>
                </div>
                <div className="feature-row">
                  <RightCircle />
                  <p>
                    Catalog Search: <span>Unlimited</span> / Day
                  </p>
                </div>
                <div className="feature-row">
                  <CrossIcon />
                  <p>
                    <span>15 Days Delayed </span> Access to Inner Circle
                    Products
                  </p>
                </div>
                <div className="feature-row">
                  <RightCircle /> <p>Product Media Available</p>
                </div>
                <div className="feature-row">
                  <CrossIcon />
                  <p>FB Ads Included</p>
                </div>
              </div>
            </div>

            {/* Sourcing */}
            <div className="inner-part">
              <h1>🏭 Sourcing:</h1>

              <div className="features">
                <div className="feature-row">
                  <RightCircle /> <p>Source Products from China / India</p>
                </div>
                <div className="feature-row">
                  <RightCircle />
                  <p>
                    <span>Fastrack</span> Sourcing
                  </p>
                </div>

                <div className="feature-row">
                  <RightCircle />
                  <p>100% Managed Imports & Customs</p>
                </div>
                <div className="feature-row">
                  <RightCircle />
                  <p>Product Samples</p>
                </div>
                <div className="feature-row">
                  <RightCircle />
                  <p>
                    <span>10</span> Requests for Quotation
                  </p>
                </div>

                <div className="feature-row">
                  <CrossIcon />
                  <p>Customised Product Creation</p>
                </div>

                <div className="feature-row">
                  <CrossIcon />
                  <p>Access to One-on-One Sourcing Specialist</p>
                </div>
              </div>
            </div>

            {/* warehouse */}
            <div className="inner-part">
              <h1>📦 Warehousing & Fulfillment:</h1>

              <div className="features">
                <div className="feature-row">
                  <RightCircle /> <p>Managed Warehouse</p>
                </div>

                <div className="feature-row">
                  <RightCircle /> <p>No Lock-in, No Set Up Fee</p>
                </div>

                <div className="feature-row">
                  <RightCircle /> <p>Real Time View of Inventory Stored</p>
                </div>
                <div className="feature-row">
                  <RightCircle /> <p>AI Based Address Score</p>
                </div>
                <div className="feature-row">
                  <RightCircle /> <p>Guaranteed 1 Day Fulfillment</p>
                </div>
                <div className="feature-row">
                  <RightCircle /> <p>Custom Inserts</p>
                </div>
                <div className="feature-row">
                  <CrossIcon />
                  <p>Custom Packaging</p>
                </div>
              </div>
            </div>
            {/* shipping */}
            <div className="inner-part">
              <h1>🚚 Managed Shipping:</h1>

              <div className="features">
                <div className="feature-row">
                  <RightCircle />
                  <p>
                    <span>Premium</span> Shipping Partners
                  </p>
                </div>

                <div className="feature-row">
                  <RightCircle /> <p>AI Based Smart Courier Allocation</p>
                </div>

                <div className="feature-row">
                  <RightCircle />
                  <p>Branded Tracking Page</p>
                </div>
                <div className="feature-row">
                  <CrossIcon /> <p>Branded Shipping Label & Invoices</p>
                </div>
                <div className="feature-row">
                  <RightCircle /> <p>RTO Risk Prediction</p>
                </div>
                <div className="feature-row">
                  <RightCircle /> <p>Guaranteed Zero Weight Disputes</p>
                </div>
                <div className="feature-row">
                  <RightCircle />
                  <p>Real-Time NDR in 1 Dashboard</p>
                </div>
                <div className="feature-row">
                  <RightCircle />
                  <p>Whatsapp Order Communication, Starting INR 6/order</p>
                </div>
              </div>
            </div>

            {/* platform */}
            <div className="inner-part">
              <h1>🖥️ Platform:</h1>

              <div className="features">
                <div className="feature-row custom-lineheight">
                  <RightCircle />
                  <p>
                    <span>Advanced</span> Reports
                  </p>
                </div>

                <div className="feature-row">
                  <RightCircle />
                  <p>
                    <span>3</span> Connected Stores
                  </p>
                </div>
                <div className="feature-row">
                  <RightCircle />
                  <p>
                    <span>4</span> Staff Accounts with Custom Roles
                  </p>
                </div>

                <div className="feature-row">
                  <RightCircle />
                  <p>Fortnightly Webinar Access</p>
                </div>
                <div className="feature-row">
                  <RightCircle /> <p>Stress Free Transparent Accounting</p>
                </div>
                <div className="feature-row">
                  <RightCircle />
                  <p>Lease Indian Tax & Billing ID</p>
                </div>

                <div className="feature-row">
                  <RightCircle />
                  <p>Weekly COD Settlement</p>
                </div>
                <div className="feature-row">
                  <RightCircle />
                  <p>Integrated Value Apps Ecosystem - Full Access</p>
                </div>
                <div className="feature-row">
                  <RightCircle />
                  <p>
                    <span>Email & Whatsapp Support</span>
                  </p>
                </div>
                <div className="feature-row">
                  <RightCircle />
                  <p>
                    Convenience Fee of INR <span>40 or 4%</span> of{" "}
                    <span>delivered</span> order value
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-btn">
            {!this.props.preview ? this.bottomButtonHandler() : null}
          </div>
          {/* {!this.props.preview && (
            <>
              <div className="save-more-card">
                <h1>
                  <span>💰</span> Save more with vFulfill
                </h1>

                <div className="save-row-card">
                  <div className="save-row">
                    <BlackRightCircle /> <p>12% less Convenience Fee</p>
                  </div>
                  <div className="save-row">
                    <BlackRightCircle /> <p>18% less Confirmation Charges</p>
                  </div>

                  <div className="save-row">
                    <BlackRightCircle /> <p>10% Discount on Integrated Apps</p>
                  </div>
                </div>
              </div>

              <div className="brands-card">
                <h1>Access to Cutting-Edge Tech.</h1>

                <div className="brands-logo">
                  <div className="wingmen-logo">
                    <WingMenLogo />
                  </div>

                  <div className="prtool-logo">
                    <PrToolLogo />
                  </div>

                  <div className="checkout-logo">
                    <CheckOutXLogo />
                  </div>
                </div>
              </div>
            </>
          )} */}
        </div>
        {!!this.props.footer && this.props.footer}
        <div className="bottom-bar"></div>
      </div>
    );
  }
}

export default ShakerCard;
