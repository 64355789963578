import React from "react";
import { Tooltip, Icon } from "antd";
import { ReactComponent as Tooltipican } from "../../../../../assets/images/icons/tooltipican.svg";

import "./index.scss";

class InventoryAvailable extends React.Component {
  render() {
    return (
      <div className="inventry">
        <>
          <p className="f12 inventry-card-count ">
            <span className="inventry-available-txt">Inventory Available:</span>
            <span className="inventry-tooltip">
              <Tooltip
                title={
                  <p className="inventry-tooltip-txt">Inventory Available</p>
                }
              >
                <Icon component={Tooltipican} />
              </Tooltip>
            </span>
          </p>
          <div className="inventry-count">
            <div class="second">
              <div class="inventry-right">
                <h2>
                  {this.props.product && !!this.props.product.stock_inventory
                    ? this.props.product.stock_inventory
                    : "N/A"}
                </h2>
              </div>
            </div>
          </div>
        </>
        {/* to do */}
        {/* <p className="inventry-filling-day">
          <span className="restocking-time-txt">
            Inventory Restocking Time:
          </span>
          <span className="inventry-date">
            <span>
              {this.props.restocking_time && !!this.props.restocking_time.start
                ? this.props.restocking_time.start
                : "0"}
            </span>
            <span> - </span>
            <span>
              {" "}
              {this.props.restocking_time && !!this.props.restocking_time.end
                ? this.props.restocking_time.end
                : "0"}
            </span>{" "}
            days
          </span>
        </p> */}
      </div>
    );
  }
}

export default InventoryAvailable;
