import React, { Component } from "react";
import "./index.scss";

class Userprofilenav extends Component {
  state = {
    basicdetailbtn: true,
    advancedetailbtn: false,
    rateCardDetailbtn: false,
    plansandBilling: false,
  };
  componentDidMount() {
    let plan = window.location.search.includes("plan");
    if (plan) {
      this.setState(
        {
          basicdetailbtn: false,
          advancedetailbtn: false,
          rateCardDetailbtn: false,
          plansandBilling: true,
        },
        () =>
          this.props.toggleuserprofile({
            basicdetailbtn: false,
            advancedetailbtn: false,
            rateCardDetailbtn: false,
            plansandBilling: true,
          })
      );
    }
  }
  render() {
    return (
      <div className="profilenav fullorder_data">
        <div className=" userprofilennav ">
          <div className="ordfill">
            <div className="profilefillwrap">
              <div className="left" style={{ marginLeft: "7px" }}>
                <div
                  style={{
                    color: "#000",
                    fontSize: "12px",
                    letterSpacing: "0.032em",
                    lineHeight: "24px",
                  }}
                >
                  Your Profile
                </div>
              </div>
            </div>
            <div className="secondwrap">
              <div className="filtertab">
                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-order-underline-animation "
                      : this.state.basicdetailbtn
                      ? "tab  hover-order-underline-animation-click"
                      : "tab hover-order-underline-animation"
                  }
                >
                  <button
                    className={this.state.basicdetailbtn ? "active" : ""}
                    value=""
                    onClick={() => {
                      this.setState(
                        {
                          basicdetailbtn: true,
                          advancedetailbtn: false,
                          rateCardDetailbtn: false,
                          plansandBilling: false,
                        },
                        () =>
                          this.props.toggleuserprofile({
                            basicdetailbtn: true,
                            advancedetailbtn: false,
                            rateCardDetailbtn: false,
                            plansandBilling: false,
                          })
                      );
                    }}
                  >
                    Basic Details
                  </button>
                </div>

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-order-underline-animation "
                      : this.state.advancedetailbtn
                      ? "tab  hover-order-underline-animation-click"
                      : "tab hover-order-underline-animation"
                  }
                >
                  <button
                    className={this.state.advancedetailbtn ? "active" : ""}
                    value="delivered"
                    onClick={() => {
                      this.setState(
                        {
                          basicdetailbtn: false,
                          advancedetailbtn: true,
                          rateCardDetailbtn: false,
                          plansandBilling: false,
                        },
                        () =>
                          this.props.toggleuserprofile({
                            basicdetailbtn: false,
                            advancedetailbtn: true,
                            rateCardDetailbtn: false,
                            plansandBilling: false,
                          })
                      );
                    }}
                  >
                    Advanced Details
                  </button>
                </div>
                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-order-underline-animation "
                      : this.state.rateCardDetailbtn
                      ? "tab  hover-order-underline-animation-click"
                      : "tab hover-order-underline-animation"
                  }
                >
                  <button
                    className={this.state.rateCardDetailbtn ? "active" : ""}
                    value="delivered"
                    onClick={() => {
                      this.setState(
                        {
                          basicdetailbtn: false,
                          advancedetailbtn: false,
                          rateCardDetailbtn: true,
                          plansandBilling: false,
                        },
                        () =>
                          this.props.toggleuserprofile({
                            basicdetailbtn: false,
                            advancedetailbtn: false,
                            rateCardDetailbtn: true,
                            plansandBilling: false,
                          })
                      );
                    }}
                  >
                    Rate Cards
                  </button>
                </div>
                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-order-underline-animation "
                      : this.state.plansandBilling
                      ? "tab  hover-order-underline-animation-click"
                      : "tab hover-order-underline-animation"
                  }
                >
                  <button
                    className={this.state.plansandBilling ? "active" : ""}
                    value="delivered"
                    onClick={() => {
                      this.setState(
                        {
                          basicdetailbtn: false,
                          advancedetailbtn: false,
                          rateCardDetailbtn: false,
                          plansandBilling: true,
                        },
                        () =>
                          this.props.toggleuserprofile({
                            basicdetailbtn: false,
                            advancedetailbtn: false,
                            rateCardDetailbtn: false,
                            plansandBilling: true,
                          })
                      );
                    }}
                  >
                    Plans & Billing
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Userprofilenav;
