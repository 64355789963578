import React, { Component } from "react";
import { Tooltip } from "antd";
import {
  getCurrencySymbol,
  formatNumber,
} from "../../../../../Helpers/Pricing";
import "../Common/index.scss";
import { SHOPIFY_NAME } from "../../../../../constants";

export class OrderPlacedCODF extends Component {
  render() {
    const transaction = this.props.transaction;
    return (
      <div className="Transactions_head_fs">
        <div className="modal_headerdata">
          <div className="Vforderid_txt">
            <span> VFOrder ID : {transaction.transaction_data.vforderid}</span>
          </div>
          <div className="Header_name">
            <span style={{ marginRight: "8px" }}>
              Transaction ID : {""}
              <span>{transaction.transaction_vfid}</span>
            </span>
            <span
              style={{ borderRight: "2px solid #e3e3e3", marginRight: "8px" }}
            />
            <sapn style={{ marginRight: "8px" }}>
              {`${SHOPIFY_NAME} Order Name:`}
              <span>
                {transaction.transaction_data.items &&
                transaction.transaction_data.items[0] &&
                transaction.transaction_data.items[0].orderid
                  ? transaction.transaction_data.items[0].orderid
                  : ""}
              </span>
            </sapn>
          </div>
        </div>

        <table className="Transactions-table_fs">
          <div className="table_parrent">
            <thead>
              <tr>
                <th>Product Details</th>
                <th>Order Value</th>

                {this.props.storegeo === "global" ? null : (
                  <th>Taxable Amount</th>
                )}

                {this.props.storegeo === "global" ? null : <th>GST Amount</th>}

                <th>Total Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ border: "2px solid #e3e3e3" }}>
                <div
                  className="Transactions-order_place_fs"
                  style={{ background: "#f5f5f5" }}
                >
                  {transaction.transaction_data.items.map((item) =>
                    item.bundle && item.bundle.length > 0 ? (
                      item.bundle.map((bundleitem) => (
                        <tr key={bundleitem.vfsku}>
                          {
                            <div className="transaction_images">
                              <div className="wraper">
                                {" "}
                                <img src={bundleitem.img} alt="" />{" "}
                                <div className="side">
                                  {" "}
                                  <p>
                                    {" "}
                                    <Tooltip
                                      title={bundleitem.aliname}
                                      placement="topLeft"
                                    >
                                      {" "}
                                      {bundleitem && bundleitem.aliname
                                        ? bundleitem.aliname.substring(0, 16)
                                        : null}{" "}
                                    </Tooltip>{" "}
                                  </p>{" "}
                                  <p
                                    style={{
                                      margin: "0 0 0 0px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {" "}
                                    {bundleitem && bundleitem.vfsku
                                      ? bundleitem.vfsku
                                      : null}{" "}
                                    X{" "}
                                    {bundleitem && bundleitem.quantity
                                      ? bundleitem.quantity
                                      : null}{" "}
                                  </p>{" "}
                                </div>{" "}
                              </div>
                            </div>
                          }
                        </tr>
                      ))
                    ) : (
                      <tr key={item.vfsku}>
                        {
                          <div className="transaction_images">
                            <div className="wraper">
                              {" "}
                              <img src={item.img} alt="" />{" "}
                              <div className="side">
                                {" "}
                                <p>
                                  {" "}
                                  <Tooltip
                                    title={item.aliname}
                                    placement="topLeft"
                                  >
                                    {" "}
                                    {item && item.aliname
                                      ? item.aliname.substring(0, 16)
                                      : null}{" "}
                                  </Tooltip>{" "}
                                </p>{" "}
                                <p
                                  style={{
                                    margin: "0 0 0 0px",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {" "}
                                  {item && item.vfsku
                                    ? item.vfsku
                                    : null} X{" "}
                                  {item && item.quantity ? item.quantity : null}{" "}
                                </p>{" "}
                              </div>{" "}
                            </div>
                          </div>
                        }
                      </tr>
                    )
                  )}
                </div>
                <td>
                  {
                    <div className="transaction_Taxable_Amount">
                      <p style={{ textAlign: "center" }}>
                        {getCurrencySymbol(transaction.transaction_currency)}{" "}
                        {transaction.transaction_amount}
                      </p>
                    </div>
                  }
                </td>

                {this.props.storegeo === "global" ? null : (
                  <td>
                    {
                      <div className="transaction_Taxable_Amount">
                        <p style={{ textAlign: "center" }}>
                          {getCurrencySymbol(transaction.transaction_currency)}{" "}
                          {transaction.transaction_data &&
                          transaction.transaction_data.costs &&
                          transaction.transaction_data.costs.cod &&
                          transaction.transaction_data.costs.cod.base
                            ? transaction.transaction_data.costs.cod.base
                            : null}
                        </p>
                      </div>
                    }
                  </td>
                )}

                {this.props.storegeo === "global" ? null : (
                  <td>
                    {
                      <div className="transaction_fs_GST">
                        <p style={{ textAlign: "center" }}>
                          {getCurrencySymbol(transaction.transaction_currency)}{" "}
                          {formatNumber(transaction.transaction_data.costs.cod.gst,2)}
                        </p>
                      </div>
                    }
                  </td>
                )}

                <td>
                  {
                    <div
                      className={
                        transaction.transaction_type === "debit"
                          ? "red_text"
                          : "green_text"
                      }
                    >
                      <p style={{ textAlign: "center" }}>
                        {getCurrencySymbol(transaction.transaction_currency)}{" "}
                        {formatNumber(
                          transaction.transaction_data.costs.cod.total,
                          2
                        )}
                      </p>
                    </div>
                  }
                </td>
              </tr>

              <tr className="no-border">
                <td>Total</td>
                {this.props.storegeo === "global" ? null : <td />}
                {this.props.storegeo === "global" ? null : <td />}
                <td />
                <td>
                  {" "}
                  <p
                    className={
                      transaction.transaction_type === "debit"
                        ? "red_text"
                        : "green_text"
                    }
                    style={{ textAlign: "center" }}
                  >
                    {getCurrencySymbol(transaction.transaction_currency)}{" "}
                    {formatNumber(transaction.transaction_amount, 2)}
                  </p>
                </td>
              </tr>
            </tbody>
          </div>
        </table>
      </div>
    );
  }
}

export default OrderPlacedCODF;
