import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  DatePicker,
  Alert,
  Select,
  Table,
  Tooltip,
  Icon,
  message,
} from "antd";
import { formatNumber, getCurrencySymbol } from "../../../../Helpers/Pricing";
import moment from "moment";
import axios from "axios";
import { connect } from "react-redux";
import { hasPermission } from "../../../../Helpers/Permissions";
import "./index.scss";
import qs from "qs";

const { RangePicker } = DatePicker;
const { Option } = Select;

const status = {
  rto: "RTO",
  delivered: "Delivered",
  transit: "In Transit",
  manifested: "Manifested",
};

const columnData = {
  vforderid: {
    title: "vF Order Id",
    width: "150px",
    dataIndex: "vforderid",
    sortValue: 1,
  },
  vf_order_date: {
    width: "150px",
    title: "vF Order Date",
    dataIndex: "vf_order_date",
    sortValue: 2,
    render: (_, row) =>
      row.items.map((item, i) => (
        <p key={i}>{moment(item.confirmation_date).format("DD MMM, YYYY")}</p>
      )),
  },
  waybill_number: {
    width: "150px",
    title: "WayBill Number",
    dataIndex: "waybill_number",
    sortValue: 3,
  },
  waybill_content: {
    width: "150px",
    title: "WayBill Content",
    className: "order-content",
    dataIndex: "waybill_content",
    sortValue: 4,
    render: (_, row) =>
      row.items.map((item, i) => (
        <p key={i}>
          <Tooltip
            title={`${item.name} X ${item.quantity}`}
            placement="topLeft"
          >{`${item.name} X ${item.quantity}`}</Tooltip>
        </p>
      )),
  },
  payment_type: {
    width: "150px",
    title: "Payment Type",
    dataIndex: "payment_type",
    sortValue: 5,
  },
  collection_amount: {
    width: "150px",
    title: "Collection Amount",
    dataIndex: "collection_amount",
    sortValue: 6,
    render: (amount) =>
      amount ? (
        <p>
          {getCurrencySymbol("INR")} {formatNumber(amount)}
        </p>
      ) : (
        "NA"
      ),
  },
  customer_name: {
    width: "150px",
    title: "Customer Name",
    dataIndex: "customer_name",
    sortValue: 7,
  },
  delivery_address_city: {
    width: "150px",
    title: "Delivery Address City",
    dataIndex: "delivery_address_city",
    sortValue: 8,
  },

  delivery_address_pin_code: {
    width: "150px",
    title: "Delivery Address Pin Code",
    dataIndex: "delivery_address_pin_code",
    sortValue: 9,
  },

  current_status: {
    width: "150px",
    title: "Current Status",
    dataIndex: "current_status",
    sortValue: 10,
    render: (current_status) => Object.values(status[current_status]),
  },
  manifestation_date: {
    width: "150px",
    title: "Manifestation Date",
    dataIndex: "manifestation_date",
    sortValue: 11,
    render: (date) => <p>{moment(date).format("DD MMM, YYYY")}</p>,
  },
  shipped_date: {
    width: "150px",
    title: "Shipped Date",
    dataIndex: "shipped_date",
    sortValue: 12,
  },
  first_attempt_date: {
    width: "150px",
    title: "First Attempt Date",
    dataIndex: "first_attempt_date",
    sortValue: 13,
  },
  subsequent_attempt_date: {
    width: "150px",
    title: "Subsequent Attempt Date",
    dataIndex: "subsequent_attempt_date",
    sortValue: 14,
  },
  last_attempt_date: {
    width: "150px",
    title: "Last Attempt Date",
    dataIndex: "last_attempt_date",
    sortValue: 15,
  },
};

const defaultCols = [
  "vforderid",
  "waybill_number",
  "current_status",
  "payment_type",
  "collection_amount",
];

class ShipmentsStatusReport extends Component {
  state = {
    currency: "INR",
    stats: {
      transit_shipments: 0,
      ndr_shipments: 0,
      delivered_shipments: 0,
      returned_shipments: 0,
      total_order: 0,
      total_shipment: 0,
    },
    filters: {
      startDate: moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
    paging: {
      page: 1,
      pageSize: 10,
      total: 0,
    },
    loading: false,
    statsLoading: false,
    error: false,
    errorMsg: "",
    orders: [],
    columns: defaultCols.map((c) => columnData[c]),
    columnData: {},
  };

  componentDidMount() {
    const inExclusive = [
      "first_attempt_date",
      "subsequent_attempt_date",
      "last_attempt_date",
    ];
    if (this.props.store.store_geo === "global") {
      inExclusive.forEach((k) => delete columnData[k]);
    }
    this.setState(
      {
        columnData,
        columns:
          this.props.store.store_geo === "global"
            ? defaultCols
                .filter((k) => inExclusive.indexOf(k) === -1)
                .map((c) => columnData[c])
            : defaultCols.map((c) => columnData[c]),
      },
      () => {
        this.getOrders();
        this.getStats();
      }
    );
  }

  getOrders() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/reporting/get_shipments",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
            filters: this.state.filters,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                orders: res.data.orders,
                loading: false,
                error: false,
                errorMsg: "",
                paging: { ...this.state.paging, total: res.data.total },
              });
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
                loading: false,
              });
            }
          })
          .catch((e) => {
            this.setState({
              error: true,
              errorMsg: e.message,
              loading: false,
            });
          });
      }
    );
  }

  getStats() {
    this.setState(
      {
        statsLoading: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL + "/reporting/shipment_status_report",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            filters: this.state.filters,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                statsLoading: false,
                stats: {
                  transit_shipments: res.data.stats.transit_shipments,
                  ndr_shipments: res.data.stats.ndr_shipments,
                  delivered_shipments: res.data.stats.delivered_shipments,
                  returned_shipments: res.data.stats.returned_shipments,
                  total_order: res.data.stats.total_orders,
                  total_shipment: res.data.stats.total_shipments,
                },
              });
            } else {
              this.setState({
                statsLoading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              statsLoading: false,
            });
            message.error(e.message);
          });
      }
    );
  }

  filterChanged() {
    this.getOrders();
    this.getStats();
  }

  handleChange(value) {
    this.setState({
      columns: value
        .map((c) => this.state.columnData[c])
        .sort((a, b) => a.sortValue - b.sortValue),
    });
  }

  unselectColumn(index) {
    this.setState({
      columns: this.state.columns
        .filter((_, i) => i !== index)
        .sort((a, b) => a.sortValue - b.sortValue),
    });
  }
  goToPage = (page) => {
    this.setState(
      {
        paging: { ...this.state.paging, page: page.current },
      },
      () => this.getOrders()
    );
  };

  render() {
    const { store } = this.props;
    const pagination = {
      current: this.state.paging.page,
      pageSize: this.state.paging.pageSize,
      total: this.state.paging.total,
    };
    const PageWrapper = (children) => {
      return (
        <div className="remittance">
          <Row>
            <div className="heading">
              <h1 className="content-page-title" style={{ marginBottom: 0 }}>
                Shipments Status Report
              </h1>
              <h3
                className="content-page-subtitle"
                style={{ marginBottom: 30 }}
              >
                Shipments for Orders In the Selected Period
                <span style={{ float: "right", marginTop: "-4px" }}>
                  Show data from:
                  <RangePicker
                    disabledDate={(current) => {
                      return current > moment();
                    }}
                    value={[
                      this.state.filters.startDate === ""
                        ? null
                        : moment(this.state.filters.startDate),
                      this.state.filters.endDate === ""
                        ? null
                        : moment(this.state.filters.endDate),
                    ]}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Week": [
                        moment().startOf("week"),
                        moment().endOf("week"),
                      ],
                      "Last 15 Days": [moment().subtract("days", 15), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      "Last Month": [
                        moment()
                          .subtract(1, "month")
                          .startOf("month"),
                        moment()
                          .subtract(1, "month")
                          .endOf("month"),
                      ],
                      "Last Year": [
                        moment()
                          .subtract(1, "year")
                          .startOf("year"),
                        moment()
                          .subtract(1, "year")
                          .endOf("year"),
                      ],
                    }}
                    onChange={(_, dateString) =>
                      this.setState(
                        {
                          filters: {
                            startDate: dateString[0],
                            endDate: dateString[1],
                          },
                        },
                        () => this.filterChanged()
                      )
                    }
                  />
                </span>
              </h3>
            </div>
          </Row>
          {children}
        </div>
      );
    };

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <Row>
          <Alert
            message="There was an error"
            description={this.state.errorMsg}
            type="error"
          />
        </Row>
      );
    }

    const order_stats = {
      transit_shipments: "Shipments In Transit",
      ndr_shipments: "Shipments in NDR",
      delivered_shipments: "Shipments Delivered",
      returned_shipments: "Shipments Returned",
    };
    const tooltip = {
      transit_shipments: "Shipments In Transit",
      ndr_shipments: "Shipments in NDR",
      delivered_shipments: "Shipments Delivered",
      returned_shipments: "Shipments Returned",
    };

    return PageWrapper(
      <React.Fragment>
        <Row gutter={{ lg: 30 }}>
          <Col xs={24} md={12} lg={12} xl={12} style={{ marginBottom: 56 }}>
            <Card className="reporting-card" loading={this.state.statsLoading}>
              <div className="header" style={{ textAlign: "center" }}>
                Total Orders
              </div>
              <div className="body" style={{ textAlign: "center" }}>
                {this.state.stats.total_order}
              </div>
            </Card>
          </Col>
          <Col xs={24} md={12} lg={12} xl={12} style={{ marginBottom: 56 }}>
            <Card className="reporting-card" loading={this.state.statsLoading}>
              <div className="header" style={{ textAlign: "center" }}>
                Total Shipments
              </div>
              <div className="body" style={{ textAlign: "center" }}>
                {this.state.stats.total_shipment}
              </div>
            </Card>
          </Col>
        </Row>
        <Row gutter={{ lg: 30 }}>
          {Object.keys(order_stats).map((k) => (
            <Col
              xs={24}
              md={12}
              lg={12}
              xl={6}
              style={{ marginBottom: 56 }}
              key={k}
            >
              <Card
                className="reporting-card"
                loading={this.state.statsLoading}
              >
                <div className="header">
                  {order_stats[k]}
                  <span className="header-tooltip">
                    <Tooltip title={tooltip[k]}>
                      <Icon type="exclamation-circle-o" />
                    </Tooltip>
                  </span>
                </div>
                <div className="body">{this.state.stats[k]}</div>
              </Card>
            </Col>
          ))}
        </Row>

        <Row>
          <div className="heading">
            <h1 className="content-page-title" style={{ marginBottom: "10px" }}>
              Shipments Analysis Data
              <span style={{ float: "right" }}>
                {hasPermission(store, "export_shipments_mis_report") && (
                  <a
                    href={`/reporting/shipment_status_report_csv?${qs.stringify(
                      {
                        storeid: this.props.match.params.storeid,
                        filters: this.state.filters,
                        columns: this.state.columns.map((c) => c.dataIndex),
                      },
                      { encodeValuesOnly: true }
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      background: "#25BE05",
                      border: "none",
                      color: "white",
                    }}
                    className="ant-btn"
                  >
                    <Icon type="download" /> Download CSV
                  </a>
                )}
              </span>
            </h1>
          </div>
        </Row>
        <Row>
          <div className="selected-column">
            <h3 className="content-page-subtitle">Display Columns : </h3>
            <Select
              mode="multiple"
              placeholder="Select from dropdown"
              value={this.state.columns.map((column) => `${column.dataIndex}`)}
              onChange={(value) => this.handleChange(value)}
              className="display-column-select"
            >
              {Object.values(this.state.columnData).map((c) => (
                <Option value={c.dataIndex} key={c.dataIndex}>
                  {c.title}
                </Option>
              ))}
            </Select>
          </div>
        </Row>
        <Row style={{ marginBottom: "30px" }}>
          {this.state.columns.map((column, i) => (
            <div className="selectedValue" key={i}>
              {column.title}{" "}
              <Icon type="close" onClick={() => this.unselectColumn(i)} />
            </div>
          ))}
          <div />
        </Row>
        <Row>
          <Table
            columns={this.state.columns}
            dataSource={this.state.orders}
            loading={this.state.loading}
            rowKey={(row) => row.waybill_number}
            onChange={(page) => this.goToPage(page)}
            scroll={{ x: 175 * this.state.columns.length }}
            pagination={{
              ...pagination,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};
export default connect(mapStateToProps)(ShipmentsStatusReport);
