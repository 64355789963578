import React, { Component } from "react";
import { Card, Button, Collapse } from "antd";
import "./index.scss";
import { formatNumber } from "../../../../../Helpers/Pricing";
import Expandable from "./Expandable";

const { Panel } = Collapse;

export const statusClass = {
  pending: "pending",
  sourced: "sourced",
  transit: "transit",
  inventory_live: "inventory",
};

class InventoryCard extends Component {
  render() {
    let order = this.props.order;
    let product_img =
      order && order.images && order.images.length > 0 && order.images[0].url;

    return (
      <>
        <Expandable order={order} />
      </>
    );
  }
}

export default InventoryCard;
