import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import "./index.scss";
import { Alert, Button, message, Skeleton, Switch, Icon } from "antd";
import ImageViewer from "./ImageViewer";
import BasicDetails from "./BasicDetails";
import PlaceOrderDrawer from "./PlaceOrderDrawer";
import RaiseRFQModal from "./RaiseRFQModal";
import { Link } from "react-router-dom";
import ActionNotification from "../../Common/ActionNotification";

const defaultState = {
  loading: true,
  placeOrderDrawerVisible: false,
  error: false,
  appInstall: false,
  product: null,
  selectedImageIndex: 0,
  activeTab: "overview",
  fixCheckout: false,
  checkoutLeft: 0,
  radio: undefined,
  checkoutTop: 0,
  checkoutHeight: 0,
  selectedVariants: {},
  // selectedVariantsData: {},
  categories: [],
  niches: [],
  // filters: [],

  filters: {
    niche: [],
  },
  bookingConfirmationModal: {
    visible: false,
  },
  zoomModal: {
    visible: false,
  },
  moqIndex: null,
  freightkey: "",
  moqvalue: "",
  bulkpricerange: "",
  variantsErrorText: "",
  showRfqModal: false,
};

const moqName = {
  air_price: "Via Air",
  sample_price: "Sample",
  sea_price: "Via Sea",
  domestic_price: "Domestic",
};
class CatalogProduct extends Component {
  constructor(props) {
    super(props);
    this.bottomSectionRef = React.createRef();
    this.checkoutBoxRef = React.createRef();
    this.mainRef = React.createRef();
  }

  state = { ...defaultState };

  defaultStateSet() {
    this.setState(
      {
        loading: true,
        error: false,
        appInstall: false,
        product: null,
        selectedImageIndex: 0,
        activeTab: "overview",
        fixCheckout: false,
        checkoutLeft: 0,
        radio: undefined,
        checkoutTop: 0,
        checkoutHeight: 0,
        selectedVariants: {},
        // selectedVariantsData: {},
        categories: [],
        niches: [],
        // filters: [],

        filters: {
          niche: [],
        },
        bookingConfirmationModal: {
          visible: false,
        },
        zoomModal: {
          visible: false,
        },
        freightkey: "",
        moqvalue: "",
        bulkpricerange: "",
      },
      () => this.loadProductData()
    );
  }

  updateFreightData = (ftype) => {
    this.setState({
      freightkey: ftype,
    });
  };

  onUpdate = (val, index) => {
    this.setState({
      fieldVal: val,
      moqIndex: index,
    });
  };
  onUpdatemoqvqlue = (val, index) => {
    this.setState({
      moqvalue: val,
      moqIndex: index,
    });
  };

  variantsErrorInfo(error) {
    this.setState({ variantsErrorText: error });
  }

  setActiveTab(activeTab) {
    this.setState({ activeTab });
  }

  loadProductData() {
    this.setState({ loading: true, error: false }, () => {
      axios({
        url: process.env.REACT_APP_API_URL + "/products/search",
        method: "post",
        data: {
          storeid: this.props.match.params.storeid,
          filters: { id: this.props.match.params.vfprodid },
          geo: !!this.state.radio
            ? this.state.radio
            : this.props.match.params.storegeo,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            const upd = {};
            if (res.data.products.length) {
              upd.loading = false;
              upd.product = res.data.products[0];
              upd.categories = res.data.categories;
              upd.niches = res.data.niches;
              upd.selectedVariants = {};
              upd.error = false;
            } else {
              upd.error = true;
              upd.loading = false;
            }
            this.setState(upd);
          } else {
            this.setState({
              error: true,
              errorMsg: res.data.msg,
              loading: false,
            });
          }
        })
        .catch((e) => {
          this.setState({
            error: true,
            errorMsg: e.message,
            loading: false,
          });
        });
    });
  }

  componentDidUpdate(pp) {
    if (!!this.state.radio) {
    } else {
      if (pp.match.params.storegeo !== this.props.match.params.storegeo) {
        this.setState({ ...defaultState }, () => this.loadProductData());
      }
    }
  }

  componentDidMount() {
    setTimeout(() => {
      let appInstall = document.querySelector(".important-info");

      if (appInstall !== null) {
        this.setState({ appInstall: true });
      }
    }, 4000);

    this.loadProductData();

    if (this.props.location.search && this.props.location.search.length) {
      let isRfq = this.props.location.search.includes("rfq");

      if (isRfq) {
        this.setState({ showRfqModal: true });
      }
    }

    this.setState({ radio: this.props.match.params.storegeo });
  }

  importProducts = (productIds, name) => {
    axios({
      url:
        process.env.REACT_APP_API_URL + "/products/import_products_aliexpress",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        productids: productIds,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          // message.success(
          //   "Product import in progress. Please visit import list section to view imported products"
          // );

          ActionNotification({
            notificationType: "importProduct",
            name: this.state.product.catalogname,
          });
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  setSelectedImage(selectedImageIndex) {
    this.setState({ selectedImageIndex });
  }

  updateSelectedVariants(k, v) {
    this.setState((state) => ({
      selectedVariants: {
        ...state.selectedVariants,
        [k]: Number(v),
      },
    }));
  }

  getSelectedVariantQuantity() {
    return Object.values(this.state.selectedVariants).reduce(
      (acc, qty) => acc + qty,
      0
    );
  }

  moqTable2Handler(data, name) {
    if (Array.isArray(data)) {
      return data.map((item, index) => {
        return (
          <div key={index} className="new-moq-table-row">
            <div className="type">{name}</div>
            <div className="moq">{!!item.moq ? item.moq : "-"}</div>
            <div className="transit-time">
              {!!item["transit_time"] ? item["transit_time"] : "-"}
            </div>
            <div className="price">
              {!!item["total"] ? `₹ ` + item["total"] : "-"}
            </div>
          </div>
        );
      });
    }
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active />;
    }

    if (this.state.error) {
      return (
        <div className="error-wraper-catalog">
          <Alert
            message="Error"
            description="There was an error fetching this product."
            type="error"
            showIcon
          />
          <Link to="./search-products">Go back to catalog</Link>
        </div>
      );
    }

    const product = this.state.product;

    let totalQty = Object.entries(this.state.selectedVariants).reduce(
      (acc, v) => acc + v[1],
      0
    );

    const currency = !!this.state.radio
      ? this.state.radio === "in"
        ? "INR"
        : "USD"
      : this.props.match.params.storegeo === "in"
      ? "INR"
      : "USD";

    return (
      <div className="catalog-product catalog-product-new" ref={this.mainRef}>
        {/* left image div */}
        <div className="gallery">
          <ImageViewer
            images={product.carousal_image.map((img) => img)}
            selected={this.state.selectedImageIndex}
            onChange={(idx) => this.setSelectedImage(idx)}
          />

          {product.hsn_attributes && product.hsn_attributes.hsn ? (
            <p
              className="f12"
              style={{
                background: "#E3E3E3",
                color: "#898989",
                width: "100%",
                textAlign: "center",
                padding: "4px 0",
              }}
            >
              HSN: {product.hsn_attributes.hsn}
            </p>
          ) : null}
          <p className="cost-updated">
            {" "}
            Product Details Last Updated On:{" "}
            <span> {moment(product.costUpdated).format("MMM DD, YYYY")}</span>
          </p>
        </div>

        {/* center div */}
        <div className="center-details">
          <BasicDetails
            setActiveTab={() => this.setActiveTab("reviews")}
            mainRef={this.mainRef}
            product={product}
            geo={
              !!this.state.radio
                ? this.state.radio
                : this.props.match.params.storegeo
            }
            goToReviewsTab={() =>
              this.hasReviewData() ? this.setActiveTab("reviews") : null
            }
          />

          <div className="new-moq-table">
            <div className="main-heading">
              <div className="type">Shipping Type</div>
              <div className="moq">MOQ</div>
              <div className="transit-time">Transit Time</div>
              <div className="price">Price/Qty</div>
            </div>

            {this.moqTable2Handler(
              product &&
                product.moq_sourcing_table2 &&
                product.moq_sourcing_table2.sample_price,
              "Sample"
            )}
            {this.moqTable2Handler(
              product &&
                product.moq_sourcing_table2 &&
                product.moq_sourcing_table2.domestic_price,
              "Domestic"
            )}
            {this.moqTable2Handler(
              product &&
                product.moq_sourcing_table2 &&
                product.moq_sourcing_table2.air_price,
              "Via Air"
            )}
            {this.moqTable2Handler(
              product &&
                product.moq_sourcing_table2 &&
                product.moq_sourcing_table2.sea_price,
              "Via Sea"
            )}
          </div>

          <div className="action-btns">
            <Button
              onClick={() => this.setState({ showRfqModal: true })}
              type="cancel"
            >
              Request For Quotation
            </Button>
            <Button
              type="primary"
              onClick={() => this.setState({ placeOrderDrawerVisible: true })}
            >
              Raise Sourcing Request
            </Button>
          </div>
        </div>

        {/* right side floating div */}
        <div className="right">
          <h1>Order Placement Lifecycle:</h1>

          <div className="bar-content">
            <div className="bar">
              <div className="payment"></div>
              <div className="confirmation"></div>
              <div className="dispatch">
                <div className="dispatch-bar"></div>
              </div>
              <div className="received"></div>
            </div>
            <div className="text">
              <div className="payment-text">Payment</div>
              <div className="confirmation-text">Confirmation</div>

              <div className="avg-wraper">
                <div className="avg">Avg. Lead Time</div>
                <div className="days">4 Days</div>
              </div>

              <div className="dispatch-text">Dispatch</div>
              <div className="avg-wraper-green">
                <div className="avg">Avg. Delivery Time</div>
                <div className="days">10 Days</div>
              </div>
              <div className="received-text">Received</div>
            </div>
          </div>
        </div>

        {this.state.showRfqModal ? (
          <RaiseRFQModal
            {...this.props}
            vfprodid={this.props.match.params.vfprodid}
            loadData={() => this.loadData()}
            visible={this.state.showRfqModal}
            onCancel={() => this.setState({ showRfqModal: false })}
          />
        ) : null}

        <PlaceOrderDrawer
          match={this.props.match}
          freightkey={this.state.freightkey}
          state={this.state}
          setState={(e) => this.setState(e)}
          visible={this.state.placeOrderDrawerVisible}
          setSelectedImage={(index) => this.setSelectedImage(index)}
          updateSelectedVariants={(k, v) => this.updateSelectedVariants(k, v)}
          onUpdate={this.onUpdate}
          moq2={product.moq_sourcing_table2}
          updateFreightData={this.updateFreightData}
          onUpdatemoqvqlue={this.onUpdatemoqvqlue}
          errorText={this.state.variantsErrorText}
          moqvalue={totalQty}
          quantity={this.getSelectedVariantQuantity()}
          defaultStateSet={() => this.defaultStateSet()}
          variantsErrorInfo={(d) => this.variantsErrorInfo(d)}
        />
      </div>
    );
  }
}

export default CatalogProduct;
