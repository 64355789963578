import React from "react";

import { Tooltip } from "antd";
import "./index.scss";

class ProductSpecialTag extends React.Component {
  render() {
    return (
      <ul
        className="specialTags"
        style={{ listStyleType: "none", padding: 0, margin: 0 }}
      >
        {this.props.product.hotselling === 1 ? (
          <li className="mr8" style={{ display: "inline-block" }}>
            <Tooltip
              placement="bottom"
              title={
                <p style={{ textAlign: "center", fontSize: "10px" }}>
                  Hot Selling
                </p>
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g data-name="Group 2506" transform="translate(-520 -456)">
                  <rect
                    data-name="Rectangle 294"
                    width="24"
                    height="24"
                    rx="12"
                    transform="translate(520 456)"
                    fill="#f3705a"
                  />
                  <path
                    data-name="Path 30"
                    d="M529.006 472.916a8.532 8.532 0 01-1.607-2.053 4.24 4.24 0 01-.3-2.679 5.546 5.546 0 011.275-2.479 2.248 2.248 0 00.658 1.753 3.887 3.887 0 011.054-3.458 8.038 8.038 0 013.381-2 2.283 2.283 0 00-.471 2.353 9.484 9.484 0 001.247 2.228 4.687 4.687 0 01.942 2.3 7.729 7.729 0 00.693-1.327 2.383 2.383 0 00.111-1.452 3.571 3.571 0 01.914 1.8 6.853 6.853 0 01.029 2.134 4.976 4.976 0 01-.8 2.228 4.615 4.615 0 01-2.411 1.627 5.929 5.929 0 01-4.715-.975z"
                    fill="#fff"
                  />
                </g>
              </svg>
            </Tooltip>
          </li>
        ) : null}
        {this.props.product.videos.length > 0 ? (
          <li className="mr8" style={{ display: "inline-block" }}>
            <Tooltip
              placement="bottom"
              title={
                <p style={{ textAlign: "center", fontSize: "10px" }}>
                  Video Available
                </p>
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g data-name="Group 2507" transform="translate(-552 -456)">
                  <rect
                    data-name="Rectangle 1721"
                    width="24"
                    height="24"
                    rx="12"
                    transform="translate(552 456)"
                    fill="#b726fc"
                  />
                  <path
                    data-name="Path 32"
                    d="M561.812 462.265c-1.001-.621-1.812-.113-1.812 1.135v9.2c0 1.248.811 1.756 1.812 1.136l7.438-4.612a1.216 1.216 0 000-2.247z"
                    fill="#fff"
                  />
                </g>
              </svg>
            </Tooltip>
          </li>
        ) : null}
        {this.props.product.buyback === 1 ? (
          <li className="mr8" style={{ display: "inline-block" }}>
            <Tooltip
              placement="bottom"
              title={
                <p style={{ textAlign: "center", fontSize: "10px" }}>
                  Buyback Available
                </p>
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g data-name="Group 2508" transform="translate(-583.846 -456)">
                  <rect
                    data-name="Rectangle 297"
                    width="24"
                    height="24"
                    rx="12"
                    transform="translate(583.846 456)"
                    fill="#26b99a"
                  />
                  <path
                    data-name="Path 28"
                    d="M594.594 464.678l.233-3.328-5.348 4.748 4.508 5.794.32-3.138s3.55-.807 6.202 5.03c0 0 2.083-4.213-1.1-7.561a7.603 7.603 0 00-4.815-1.545z"
                    fill="#fff"
                  />
                </g>
              </svg>
            </Tooltip>
          </li>
        ) : null}
        {this.props.product.ad_copies &&
        this.props.product.ad_copies.length > 0 ? (
          <li className="mr8" style={{ display: "inline-block" }}>
            <Tooltip
              placement="bottom"
              title={
                <p style={{ textAlign: "center", fontSize: "10px" }}>
                  Ad Copy Available
                </p>
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g data-name="Group 2614" transform="translate(-616 -456)">
                  <circle
                    data-name="Ellipse 40"
                    cx="12"
                    cy="12"
                    r="12"
                    transform="translate(616 456)"
                    fill="#fcdf41"
                  />
                  <path
                    d="M634.385 463.686l-.375-.375a1.063 1.063 0 00-1.5 0l-6.374 6.377-2.262-2.26a1.062 1.062 0 00-1.5 0l-.375.375a1.065 1.065 0 000 1.5l3.385 3.389a1.066 1.066 0 001.5 0l7.5-7.5a1.066 1.066 0 000-1.5z"
                    fill="#fff"
                  />
                </g>
              </svg>
            </Tooltip>
          </li>
        ) : null}
      </ul>
    );
  }
}

export default ProductSpecialTag;
