import React from "react";

function StarRating({ rating }) {
  return (
    <div className="stars">
      {[1, 2, 3, 4, 5].map((r) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          className={`${r === 5 ? "" : "mr8"} `}
          key={r}
        >
          <path
            data-name="Polygon 4"
            d="M8 0l2.4 5.371 5.6.74-4.117 4.06L12.944 16 8 13.138 3.056 16l1.061-5.829L0 6.111l5.6-.74z"
            fill={rating >= r ? "#FD104D" : "#FFB2C6"}
          />
        </svg>
      ))}
    </div>
  );
}

export default StarRating;
