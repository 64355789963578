import React from "react";
import { Card } from "antd";
import "./index.scss";

const StatisticCard = ({
  header,
  tooltip,
  value,
  footer,
  showCount = true,
}) => {
  return (
    <Card className="statistic-card">
      <div className="header">
        {header}
        <span className="header-tooltip">{tooltip}</span>
      </div>

      {showCount ? (
        <>
          <div className="body">{value}</div>
          <div className="divider" />
          <div className="footer">{footer}</div>
        </>
      ) : (
        <div className="body custom-body">{value}</div>
      )}
    </Card>
  );
};

export default StatisticCard;
