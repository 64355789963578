import React from "react";
import GlobalTooltipIconWhite from "../../../Common/GlobalTooltipIconWhite";
import "./index.scss";

const VariantsTab = ({ variants = [], inventoryRestocking = [] }) => {
  return (
    <div>
      <VariantsTable variants={variants} />

      {inventoryRestocking.length > 0 ? (
        <InventoryRestockingTable inventoryRestocking={inventoryRestocking} />
      ) : null}
    </div>
  );
};

// variants table
const VariantsTable = (props) => {
  return (
    <div className="variants-table-pricing-table-wraper">
      <div className="heading-wraper">
        <div className="heading">Variant Details</div>
      </div>
      <div className="pricing-wraper">
        <div className="table-headings">
          <div className="transit-time">Transit time</div>
          <div className="vf-days">Specs</div>
          <div className="air-days">Dimensions</div>
          <div className="sea-days">Weight</div>

          <div className="applicable-weight">
            Applicable Weight{" "}
            <GlobalTooltipIconWhite
              tooltipText={
                "Applicable weight refers to the overall size of a parcel and is measured in Applicable kilograms"
              }
            />
          </div>
          <div className="sea-days">VF Inventory</div>
        </div>

        {props.variants.map((variant) => {
          return (
            <div key={variant.vfsku} className="table-row-data">
              <div className="transit-time">
                <div className="img">
                  <img
                    src={
                      variant.images && variant.images[0]
                        ? variant.images[0]
                        : ""
                    }
                    alt={`variant ${variant.vfsku}`}
                  />
                </div>
                <p> {variant.vfsku}</p>
              </div>
              <div className="vf-days">{variant.allspecs}</div>
              <div className="air-days">
                <p>
                  {variant.dimensions.d} X {variant.dimensions.w} X{" "}
                  {variant.dimensions.h}
                </p>{" "}
                <span>cms</span>
              </div>
              <div className="sea-days">
                <p>{variant.weight}</p> <span>Kilograms</span>
              </div>
              <div className="sea-days">
                <p>{variant.effective_volumetric_weight}</p>{" "}
                <span>Kilograms</span>
              </div>
              <div className="sea-days">{variant.vf_inventory}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

// Invnentory Restocking Table
const InventoryRestockingTable = (props) => {
  return (
    <div className="inventory-restocking-table-wraper">
      <div className="heading-wraper">
        <div className="heading">Inventory Restocking Time</div>
      </div>
      <div className="pricing-wraper">
        <div className="table-headings">
          <div className="transit-time">MOQ</div>
          <div className="sea-days">Cross Border Shipping</div>
        </div>

        <div className="table-sub-headings">
          <div className="transit-time" />
          <div className="air-days">Restocking Time Via Air</div>
          <div className="sea-days">Restocking Time Via Sea</div>
        </div>

        {props.inventoryRestocking.map((inventory) => {
          return (
            <div className="table-row-data">
              <div className="transit-time">
                {inventory.moq_start}
                {"-"}
                {inventory.moq_end}
              </div>
              <div className="air-days">
                {inventory.air_restocking_time.start}
                {"-"}
                {inventory.air_restocking_time.end} days
              </div>
              <div className="sea-days">
                {inventory.sea_restocking_time.start}
                {"-"}
                {inventory.sea_restocking_time.end} days
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VariantsTab;
