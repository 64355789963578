import React, { Component } from "react";
import { Select, Icon, InputNumber, Button, Tooltip } from "antd";
// import PLACEHOLDER_PRODUCT_IMAGE from "./../../../../../../assets/images/icons/image_placeholder.png";

import { ReactComponent as Arrow } from "./../../../../../../assets/images/icons/arrow.svg";

import "./index.scss";
const { Option } = Select;

class VaraintBundleMatchSelecter extends Component {
  state = {
    arrow: false,
  };

  render() {
    return (
      <div className="right-varaint-bundle-image-data">
        {this.props.mapping[this.props.sv.variant_shopifyid].map((v, i) => (
          <div style={{ display: "flex", marginBottom: 10 }}>
            <Select
              showSearch
              style={{ width: "100%", minHeight: "100%" }}
              placeholder="Please select a variant"
              optionFilterProp="children"
              onChange={(val) => {
                this.props.mapProduct(
                  this.props.sv.variant_shopifyid,
                  i,
                  val.split("_")[0],
                  val.split("_")[1]
                );
              }}
              // size="large"
              // value={
              //   `${v.aliId}_${v.skuPropIds}` === "_"
              //     ? null
              //     : `${v.aliId}_${v.skuPropIds}`
              // }
              filterOption={(input, option) =>
                option.props.children.props.children[1].props.children[2].props.children[1]
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onVisibleChange={(a) =>
                this.setState({ arrow: !this.state.arrow })
              }
              suffixIcon={
                <Icon
                  className={`arrow ${this.state.arrow ? "arrow-fill" : null}`}
                  component={Arrow}
                  style={{
                    marginLeft: "4px",
                    fontSize: "16px",
                  }}
                />
              }
            >
              {this.props.aliProduct.map((alip) =>
                alip.variants.map((av) => (
                  <Option
                    key={`${alip.productId}_${av.skuPropIds}`}
                    value={`${alip.productId}_${av.skuPropIds}`}
                    className="allspec-dropdown"
                  >
                    <div
                      className="allspec-data"
                      style={{ lineHeight: "16px" }}
                    >
                      <img
                        src={av.img ? av.img : "#"}
                        style={{
                          maxWidth: "48px",
                          marginRight: "8px",
                          border: "1px solid #979797",
                          padding: "2px",
                        }}
                        alt="#"
                      />

                      <div className="varaint-name-spac">
                        <p
                          className="allspecs-product-name"
                          style={{
                            fontSize: "12px",
                            color: "#1161FB",
                          }}
                        >
                          {alip.name.length > 30 ? (
                            <Tooltip title={alip.name}>
                              {`${alip.name.substring(0, 25)}...`}
                            </Tooltip>
                          ) : alip.name ? (
                            alip.name
                          ) : (
                            "Not Available"
                          )}
                        </p>

                        <p
                          style={{ color: "#898989" }}
                          className="allspecs-product-name"
                        >
                          SKU:{" "}
                          <span style={{ color: "#000" }}>
                            {av.vfsku ? av.vfsku : "Not available"}
                          </span>
                        </p>

                        <h5
                          style={{
                            color: "#000",
                            fontSize: "12px",
                            fontWeight: "400",
                          }}
                          className="allspecs-product-name"
                        >
                          <span style={{ color: "#898989" }}>Specs: </span>
                          {av.allspecs.length > 30 ? (
                            <Tooltip title={av.allspecs}>
                              {`${av.allspecs.substring(0, 25)}...`}
                            </Tooltip>
                          ) : av.allspecs ? (
                            av.allspecs
                          ) : (
                            "Not Available"
                          )}
                        </h5>
                      </div>
                    </div>
                  </Option>
                ))
              )}
            </Select>

            <div style={{ display: "flex", marginLeft: "16px" }}>
              <InputNumber
                min="1"
                value={v.qty}
                onChange={(val) =>
                  this.props.updateMatchQty(
                    this.props.sv.variant_shopifyid,
                    i,
                    val
                  )
                }
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "16px",
              }}
            >
              <a
                href="#"
                style={{ fontSize: 22 }}
                onClick={() =>
                  this.props.removeVariant(this.props.sv.variant_shopifyid, i)
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.263"
                  height="14.263"
                  viewBox="0 0 14.263 14.263"
                >
                  <g
                    id="Group_3917"
                    data-name="Group 3917"
                    transform="translate(-206.869 -702.869)"
                  >
                    <path
                      id="Path_76"
                      data-name="Path 76"
                      d="M1301-148l12,12"
                      transform="translate(-1093 852)"
                      fill="none"
                      stroke="#898989"
                      stroke-linecap="round"
                      stroke-width="1.6"
                    />
                    <path
                      id="Path_77"
                      data-name="Path 77"
                      d="M1301-136l12-12"
                      transform="translate(-1093 852)"
                      fill="none"
                      stroke="#898989"
                      stroke-linecap="round"
                      stroke-width="1.6"
                    />
                  </g>
                </svg>
              </a>
            </div>
          </div>
        ))}

        <div style={{ textAlign: "end", marginTop: "8px" }}>
          <Button
            type="link"
            onClick={() =>
              this.props.onAddVariant(this.props.sv.variant_shopifyid)
            }
            className="add-new-varaint"
          >
            + Add New Variant
          </Button>
        </div>
      </div>
    );
  }
}

export default VaraintBundleMatchSelecter;
