import React, { Component } from "react";
import { Modal, Icon, Button, Tabs } from "antd";
import { ReactComponent as rightArrow } from "../../../../assets/images/icons/long-arrow-pointing-to-the-right.svg";
import { ReactComponent as wireSuccessIcon } from "../../../../assets/images/icons/wire-transfer-success.svg";
import "./index.scss";
import ClickToCopy from "../../Common/ClickToCopy";
const { TabPane } = Tabs;

const AccountDetails = ({ details }) => {
  const copyAll = () => {
    let textToCopy = "";
    // Iterate through details object keys
    Object.keys(details).forEach((key) => {
      // Append each textToCopy value to textToCopy string
      textToCopy += details[key] + "\n";
    });
    // Create a temporary textarea element to copy the text
    const tempElement = document.createElement("textarea");
    tempElement.value = textToCopy.trim();
    document.body.appendChild(tempElement);
    tempElement.select();
    document.execCommand("copy");
    document.body.removeChild(tempElement);
  };

  return (
    <div className="account-details">
      <p>
        Account Name:
        <span>
          {" "}
          <ClickToCopy
            fullText={true}
            textToCopy={details.ac_name}
          ></ClickToCopy>
        </span>
      </p>
      <p>
        Account Number:
        <span>
          {" "}
          <ClickToCopy
            fullText={true}
            textToCopy={details.ac_num}
          ></ClickToCopy>
        </span>
      </p>
      <p>
        Bank Name:
        <span>
          {" "}
          <ClickToCopy
            fullText={true}
            textToCopy={details.bank_name}
          ></ClickToCopy>
        </span>
      </p>
      <p>
        Bank Address:
        <span>
          {" "}
          <ClickToCopy
            fullText={true}
            textToCopy={details.Bank_Address}
          ></ClickToCopy>
        </span>
      </p>

      <p>
        SWIFT Code:
        <span>
          {" "}
          <ClickToCopy
            fullText={true}
            textToCopy={details.SWIFT_Code}
          ></ClickToCopy>
        </span>
      </p>
      <p>
        ABA Routing Number:
        <span>
          {" "}
          <ClickToCopy
            fullText={true}
            textToCopy={details.ABA_Routing_Number}
          ></ClickToCopy>
        </span>
      </p>
      <p>
        Bank Account Currency:
        <span>
          {" "}
          <ClickToCopy
            fullText={true}
            textToCopy={details.Bank_Account_Currency}
          ></ClickToCopy>
        </span>
      </p>

      <p className="copy-all" onClick={() => copyAll()}>
        Copy All
        <span>
          <ClickToCopy textToCopy={""}></ClickToCopy>{" "}
        </span>
      </p>
    </div>
  );
};
class WireTransferSuccessOutSideIndiaModal extends Component {
  state = {
    bank: "icici",
    details: {
      icici: {
        ac_name: "vFulfill Inc.",
        ac_num: "202256687939",
        bank_name: "Choice Financial Group",
        Bank_Address: "4501 23rd Avenue S Fargo, ND 58104, USA",
        SWIFT_Code: "CHFGUS44021",
        ABA_Routing_Number: "091311229",
        Bank_Account_Currency: "USD",
      },
    },
  };
  setBank(bank) {
    this.setState({
      bank,
    });
  }
  render() {
    return (
      <Modal
        className="wireSuccessModal1"
        visible={this.props.visible}
        width={480}
        title={null}
        closable={this.props.hideFooter}
        onCancel={() => this.props.toggleSuccessModal()}
        footer={null}
      >
        <div style={{ textAlign: "center" }}>
          <div className="transaction-sucess-content">
            <Icon component={wireSuccessIcon} />
            <h1>Please follow the process carefully.</h1>
            <p>Transfer funds to the account with following details:</p>
          </div>
          <Tabs
            activeKey={this.state.bank}
            onChange={(bank) => this.setBank(bank)}
          >
            <TabPane tab="Choice Financial Group" key="icici">
              <AccountDetails details={this.state.details["icici"]} />
            </TabPane>
          </Tabs>
          <div className="copy-message">
            <p>
              Please add the message{" "}
              <span>"vFulfill Funds for {this.props.id}" </span>
              in the remarks while transferring the amount
            </p>
            <ClickToCopy textToCopy={`vFulfill Funds for ${this.props.id}`}>
              {(copy) => <Button onClick={copy}>Copy the message</Button>}
            </ClickToCopy>
          </div>
          <div className="footer-button">
            {!this.props.hideFooter && (
              <Button onClick={() => window.location.reload()} type="link">
                Continue To Dashboard
                <Icon component={rightArrow} />
              </Button>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default WireTransferSuccessOutSideIndiaModal;
