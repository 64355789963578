import { Icon } from "antd";
import React, { Component } from "react";
import moment from "moment";

class OverviewTab extends Component {
  constructor(props) {
    super(props);
    this.iframe = React.createRef();
  }

  componentDidMount() {
    this.iframe.current.contentWindow.document.open();
    this.iframe.current.contentWindow.document.write(
      `
        <html>
          <head>
            <style>
              @import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
              * {
                font-family: "Poppins", "Chinese Quote", -apple-system, BlinkMacSystemFont,
                "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",
                "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji",
                "Segoe UI Emoji", "Segoe UI Symbol" !important;
                font-size: 12px;
                color: #595959;
              }
              img {
                max-width: 80%;
                margin: auto;
                display: block;
              }
            </style>
          </head> 
          <body>
            ${this.props.product.description} 
          </body>
        </html>`
    );
    this.iframe.current.contentWindow.document.close();
    this.iframe.current.onload = () => {
      this.iframe.current.height = this.iframe.current.contentWindow.document.body.scrollHeight;
    };
  }

  getNicheName(niche) {
    const nicheData = this.props.niches.find((n) => n.id === niche);
    if (nicheData) {
      return nicheData.name;
    } else {
      return niche.replace(/_/g, " ");
    }
  }

  render() {
    const product = this.props.product;
    return (
      <div>
        {product.summary ? (
          <>
            <p className="f12" style={{ color: "#898989", marginBottom: 4 }}>
              Description:
            </p>
            <p
              className="f12"
              style={{
                background: "#F5F5F5",
                padding: "10px 16px",
                marginBottom: 32,
                lineHeight: "24px",
              }}
            >
              {product.summary}
            </p>
          </>
        ) : null}

        <ul className="inline-list" style={{ marginBottom: 24 }}>
          {product.tags.map((t) => (
            <li className="description-tag" key={t}>
              {t.charAt(0).toUpperCase() + t.slice(1)}
            </li>
          ))}
        </ul>

        {!!product.features.length ? (
          <>
            <p className="f12" style={{ color: "#898989", marginBottom: 4 }}>
              Features:
            </p>
            <ul
              className="inline-list f12"
              style={{ display: "flex", flexWrap: "wrap", marginBottom: 28 }}
            >
              {product.features.map((f, idx) => (
                <li
                  key={idx}
                  style={{ width: "50%", padding: "4px 8px 4px 8px" }}
                >
                  - {f}
                </li>
              ))}
            </ul>
          </>
        ) : null}

        <div style={{ display: "flex", marginBottom: 32, marginTop: "16px" }}>
          <div style={{ flex: 1, paddingRight: 8 }}>
            <p className="f12" style={{ color: "#898989", marginBottom: 4 }}>
              Technical Details:
            </p>
            <table className="description-table">
              <tbody>
                <tr>
                  <td>Product ID</td>
                  <td>{product.vfprodid}</td>
                </tr>
                <tr>
                  <td>
                    Product Dimensions <br /> (LXBXH)
                  </td>
                  <td>
                    {product.variants[0].dimensions.d} X{" "}
                    {product.variants[0].dimensions.w} X{" "}
                    {product.variants[0].dimensions.h} cms
                  </td>
                </tr>
                <tr>
                  <td>Weight</td>
                  <td>{product.variants[0].weight} kg</td>
                </tr>
                <tr>
                  <td>Number of Items</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Bluetooth Enabled</td>
                  {product.attributes.bluetooth === 1 ? (
                    <td>
                      <Icon type="check" style={{ color: "#2BC822" }} /> Yes
                    </td>
                  ) : (
                    <td>
                      <Icon
                        type="plus"
                        style={{ transform: "rotate(45deg)", color: "#c92222" }}
                      />{" "}
                      No
                    </td>
                  )}
                </tr>
                <tr>
                  <td>Contains Battery</td>
                  {product.attributes.battery === 1 ? (
                    <td>
                      <Icon type="check" style={{ color: "#2BC822" }} /> Yes
                    </td>
                  ) : (
                    <td>
                      <Icon
                        type="plus"
                        style={{ transform: "rotate(45deg)", color: "#c92222" }}
                      />{" "}
                      No
                    </td>
                  )}
                </tr>
                <tr>
                  <td>Comes with a Box</td>
                  {product.attributes.comeswithabox === 1 ? (
                    <td>
                      <Icon type="check" style={{ color: "#2BC822" }} /> Yes
                    </td>
                  ) : (
                    <td>
                      <Icon
                        type="plus"
                        style={{ transform: "rotate(45deg)", color: "#c92222" }}
                      />{" "}
                      No
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ flex: 1, paddingLeft: 8 }}>
            <p className="f12" style={{ color: "#898989", marginBottom: 4 }}>
              Additional Info:
            </p>
            <table className="description-table">
              <tbody>
                <tr>
                  <td>Fragile</td>
                  {product.attributes.fragile === 1 ? (
                    <td>
                      <Icon type="check" style={{ color: "#2BC822" }} /> Yes
                    </td>
                  ) : (
                    <td>
                      <Icon
                        type="plus"
                        style={{ transform: "rotate(45deg)", color: "#c92222" }}
                      />{" "}
                      No
                    </td>
                  )}
                </tr>
                <tr>
                  <td>Product First Added On</td>
                  <td>{moment(product.created_on).format("DD MMM, YYYY")}</td>
                </tr>
                <tr>
                  <td>Last Updated On</td>
                  <td>
                    {moment(product.last_updatedat).format("DD MMM, YYYY")}
                  </td>
                </tr>
                <tr>
                  <td>Product Niche(s)</td>
                  <td>
                    {(product.niche || [])
                      .map((n) => this.getNicheName(n))
                      .join(", ")}
                  </td>
                </tr>
                <tr>
                  <td>Product Category(ies)</td>
                  <td>{product.categories.map((c) => c.name).join(", ")}</td>
                </tr>

                {this.props.geo === "global" ? null : (
                  <tr>
                    <td>Buyback</td>
                    <td>
                      {product.buyback === 0
                        ? "No Buyback"
                        : `Available at ${
                            product.buyback_percentage
                          }% of Original Price`}
                    </td>
                  </tr>
                )}

                <tr>
                  <td>Inventory Available</td>
                  <td>
                    {product.stock_inventory ? product.stock_inventory : 0}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <iframe
          ref={this.iframe}
          className="description-html-iframe"
          title="Product Description"
        />
      </div>
    );
  }
}

export default OverviewTab;
