import React, { Component } from "react";
import "./index.scss";
import axios from "axios";
import { DatePicker, message, Select, Icon } from "antd";
// import { ReactComponent as PaymentStatusIcon } from "../../../../assets/images/icons/payment_status_icon.svg";
import moment from "moment";
import Globalsearch from "../../Common/Globalsearch";
import { SHOPIFY_NAME } from "../../../../constants";
import VideoTooltip from "../../Common/VideoTooltip";

import { ReactComponent as PaymentStatusIcon } from "../../../../assets/images/icons/payment_status_icon.svg";
import { ReactComponent as ReadyToShipIcon } from "../../../../assets/images/icons/readytoship.svg";
import { ReactComponent as InTransitShipmentIcon } from "../../../../assets/images/icons/inTransitShipments.svg";
import { ReactComponent as DeliveredShipmentIcon } from "../../../../assets/images/icons/deliveredShipments.svg";
import { ReactComponent as ReturnedShipmentIcon } from "../../../../assets/images/icons/returnedShipments.svg";
import { ReactComponent as CircleCross } from "../../../../assets/images/icons/circle-cross.svg";
import { ReactComponent as ndrCircle } from "../../../../assets/images/icons/ndrCircle.svg";

const { Option } = Select;
const { RangePicker } = DatePicker;
class NdrOrderNav extends Component {
  state = {
    ndr: this.props.ndrs,
    openndrbtn: true,
    respondedndrbtn: false,
    expiredndrbtn: false,
    allbtn: false,
    mount: false,
  };

  componentDidUpdate() {
    let status = this.props.filters.status;

    if (status === "All" && this.state.mount !== true) {
      this.setState({
        ndr: this.props.ndrs,
        openndrbtn: false,
        respondedndrbtn: false,
        expiredndrbtn: false,
        allbtn: true,
        mount: true,
      });
    }
  }

  download() {
    axios({
      url: process.env.REACT_APP_API_URL + "/shipments/get_ndrs",
      method: "post",
      data: {
        storeid: this.props.storeid,
        type: this.props.status,
        filters: this.props.filters,
        downloadcsv: 1,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMsg: e.message,
          loading: false,
        });
      });
  }

  render() {
    return (
      <div className="Ndr_order_data fullorder_data">
        <div className=" Ndrnav ">
          <div className="ordfill">
            <div className="ordfillwrap">
              <div
                className="left breadcurmb-txt"
                style={{ marginLeft: "7px" }}
              >
                <div
                  style={{
                    color: "#000",
                    fontSize: "12px",
                    letterSpacing: "0.032em",
                    lineHeight: "24px",
                  }}
                >
                  NDR
                </div>
                <VideoTooltip
                  title="NDR"
                  subtitle="This sections helps you to take actions on your failed shipment delivery."
                  contentHeadings={["Open NDR", "Responded NDR", "Expired NDR"]}
                  content={[
                    "These are shipments on which delivery attempt was made however it was not delivered to the Customer. The store owners are thus required to take necessary actions on these non delivered shipments on a regular basis.",
                    "These are the orders on which action has been taken by the store owner for either Reattempt in Delivery or Marked as Return-to-Origin basis their conversation and understanding with the customer.",
                    "Any open NDR on which no action is taken in 24 hours after the NDR status has been marked on the shipment, it is automatically marked as an Expired NDR.",
                  ]}
                  thumbnail="https://d24fzeiqvvundc.cloudfront.net/catalog/images/fcd7aa4a9d8c688addc65f266408c9d2.png"
                  videolink="https://d24fzeiqvvundc.cloudfront.net/images/global/dashboard/tooltips_videos/NDR.mp4"
                />
              </div>
              <div className="right">
                <p className="show_data_from" style={{ marginLeft: "8px" }}>
                  Show Data From:{" "}
                </p>
                <RangePicker
                  disabledDate={(current) => {
                    return current > moment();
                  }}
                  style={{ marginLeft: "10px", maxWidth: "300px" }}
                  value={[
                    this.props.filters.startDate === "" ||
                    this.props.filters.startDate === null
                      ? ""
                      : moment(this.props.filters.startDate, "YYYY-MM-DD"),
                    this.props.filters.endDate === "" ||
                    this.props.filters.startDate === null
                      ? ""
                      : moment(this.props.filters.endDate, "YYYY-MM-DD"),
                  ]}
                  ranges={{
                    Today: [moment(), moment()],
                    "This Week": [
                      moment().startOf("week"),
                      moment().endOf("week"),
                    ],
                    "Last 15 Days": [moment().subtract("days", 15), moment()],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                    "Last Month": [
                      moment()
                        .subtract(1, "month")
                        .startOf("month"),
                      moment()
                        .subtract(1, "month")
                        .endOf("month"),
                    ],
                    "Last Year": [
                      moment()
                        .subtract(1, "year")
                        .startOf("year"),
                      moment()
                        .subtract(1, "year")
                        .endOf("year"),
                    ],
                  }}
                  onChange={(_, dateString) =>
                    this.props.changeFilter({
                      startDate: dateString[0],
                      endDate: dateString[1],
                    })
                  }
                />
              </div>
            </div>
            <div className="secondwrap">
              <div className="filtertab">
                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-ndr-underline-animation "
                      : this.state.openndrbtn
                      ? "tab  hover-ndr-underline-animation-click"
                      : "tab hover-ndr-underline-animation"
                  }
                >
                  <button
                    className={this.state.openndrbtn ? "active" : ""}
                    value=""
                    onClick={() => {
                      this.setState({
                        openndrbtn: true,
                        respondedndrbtn: false,
                        ndr_calling: false,
                        expiredndrbtn: false,
                        allbtn: false,
                      });
                      this.props.changeFilter(
                        {
                          startDate: "",
                          endDate: "",
                          search: "",
                          pagename: ["pending"],
                          status: "pending",
                          action_status: "",
                        },
                        true
                      );
                    }}
                  >
                    Open NDR
                  </button>

                  <div
                    className={` ${
                      this.state.openndrbtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.ndrcount && this.props.ndrcount.open
                      ? this.props.ndrcount.open > 1000
                        ? (Number(this.props.ndrcount.open) / 1000).toFixed(1) +
                          "k"
                        : this.props.ndrcount.open < 1000
                        ? this.props.ndrcount.open
                        : null
                      : this.props.ndrcount.open === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                {/* ndr calling */}
                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-ndr-underline-animation "
                      : this.state.ndr_calling
                      ? "tab  hover-ndr-underline-animation-click"
                      : "tab hover-ndr-underline-animation"
                  }
                >
                  <button
                    className={this.state.ndr_calling ? "active" : ""}
                    value="delivered"
                    onClick={() => {
                      this.setState({
                        openndrbtn: false,
                        respondedndrbtn: false,
                        expiredndrbtn: false,
                        ndr_calling: true,
                        allbtn: false,
                      });
                      this.props.changeFilter(
                        {
                          startDate: "",
                          endDate: "",
                          search: "",
                          pagename: ["ndr_calling"],
                          status: "ndr_calling",
                          action_status: "",
                        },
                        true
                      );
                    }}
                  >
                    NDR Calling
                  </button>

                  <div
                    className={` ${
                      this.state.ndr_calling ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.ndrcount && this.props.ndrcount.ndr_calling
                      ? this.props.ndrcount.responded > 1000
                        ? (
                            Number(this.props.ndrcount.ndr_calling) / 1000
                          ).toFixed(1) + "k"
                        : this.props.ndrcount.ndr_calling < 1000
                        ? this.props.ndrcount.ndr_calling
                        : null
                      : this.props.ndrcount.ndr_calling === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-ndr-underline-animation "
                      : this.state.respondedndrbtn
                      ? "tab  hover-ndr-underline-animation-click"
                      : "tab hover-ndr-underline-animation"
                  }
                >
                  <button
                    className={this.state.respondedndrbtn ? "active" : ""}
                    value="delivered"
                    onClick={() => {
                      this.setState({
                        openndrbtn: false,
                        respondedndrbtn: true,
                        expiredndrbtn: false,
                        allbtn: false,
                        ndr_calling: false,
                      });
                      this.props.changeFilter(
                        {
                          startDate: "",
                          endDate: "",
                          search: "",
                          pagename: ["responded"],
                          status: "responded",
                          action_status: "",
                        },
                        true
                      );
                    }}
                  >
                    Responded NDR
                  </button>

                  <div
                    className={` ${
                      this.state.respondedndrbtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.ndrcount && this.props.ndrcount.responded
                      ? this.props.ndrcount.responded > 1000
                        ? (
                            Number(this.props.ndrcount.responded) / 1000
                          ).toFixed(1) + "k"
                        : this.props.ndrcount.responded < 1000
                        ? this.props.ndrcount.responded
                        : null
                      : this.props.ndrcount.responded === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-ndr-underline-animation "
                      : this.state.expiredndrbtn
                      ? "tab  hover-ndr-underline-animation-click"
                      : "tab hover-ndr-underline-animation"
                  }
                >
                  <button
                    className={this.state.expiredndrbtn ? "active" : ""}
                    value="rto"
                    onClick={() => {
                      this.setState({
                        openndrbtn: false,
                        respondedndrbtn: false,
                        ndr_calling: false,
                        expiredndrbtn: true,
                        allbtn: false,
                      });
                      this.props.changeFilter(
                        {
                          startDate: "",
                          endDate: "",
                          search: "",
                          pagename: ["expired"],
                          status: "expired",
                          action_status: "",
                        },
                        true
                      );
                    }}
                  >
                    Expired NDR
                  </button>

                  <div
                    className={` ${
                      this.state.expiredndrbtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.ndrcount && this.props.ndrcount.expired
                      ? this.props.ndrcount.expired > 1000
                        ? (Number(this.props.ndrcount.expired) / 1000).toFixed(
                            1
                          ) + "k"
                        : this.props.ndrcount.expired < 1000
                        ? this.props.ndrcount.expired
                        : null
                      : this.props.ndrcount.expired === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-ndr-underline-animation "
                      : this.state.allbtn
                      ? "tab  hover-ndr-underline-animation-click"
                      : "tab hover-ndr-underline-animation"
                  }
                >
                  <button
                    className={this.state.allbtn ? "active" : ""}
                    value="rto"
                    onClick={() => {
                      this.setState({
                        openndrbtn: false,
                        respondedndrbtn: false,
                        expiredndrbtn: false,
                        ndr_calling: false,
                        allbtn: true,
                      });
                      this.props.changeFilter(
                        {
                          startDate: "",
                          endDate: "",
                          search: "",
                          pagename: ["All"],
                          status: "All",
                          action_status: "",
                        },
                        true
                      );
                    }}
                  >
                    All
                  </button>

                  <div
                    className={` ${
                      this.state.allbtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.ndrcount && this.props.ndrcount.all
                      ? this.props.ndrcount.all > 1000
                        ? (Number(this.props.ndrcount.all) / 1000).toFixed(1) +
                          "k"
                        : this.props.ndrcount.all < 1000
                        ? this.props.ndrcount.all
                        : null
                      : this.props.ndrcount.all === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>
              </div>

              <div className="download_csv">
                <button
                  className="clickable"
                  style={{
                    width: "120px",
                  }}
                  onClick={() => this.download()}
                >
                  Download CSV
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_data">
          <div className="Globalsearch">
            <Globalsearch
              data={this.props.filters}
              change={(value) =>
                this.props.changeHandler({ search: value.target.value })
              }
              tooltip={true}
              placeholder={`Search by VF Order ID, AWB, ${SHOPIFY_NAME} ID, Product Name`}
              searchFunc={(value) => this.props.changeFilter({ search: value })}
            />
          </div>

          {!this.state.openndrbtn ? (
            <div
              className="drop"
              style={{
                display: "flex",
                float: "left",
                alignItems: "center",
                marginLeft: "-54px",
              }}
            >
              <p className="dispute-status"> </p>
              <Select
                style={{ fontWeight: 500, fontSize: 12, width: 288 }}
                value={this.props.shipment_status}
                className="shipment-order-filter"
                placeholder="Shipment Status: All"
                onChange={(val) =>
                  val === ""
                    ? this.props.changeFilter({
                        shipment_status: [],
                      })
                    : this.props.changeFilter({
                        shipment_status: [val],
                      })
                }
                onDropdownVisibleChange={(val) =>
                  this.setState({ arrow2: val })
                }
                suffixIcon={
                  <svg
                    style={{
                      transition: "all 0.5s",
                      transform: `${
                        this.state.arrow2 ? "rotateX(180deg)" : "rotateX(0deg)"
                      }`,
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_49"
                      data-name="Polygon 49"
                      transform="translate(16 8) rotate(180)"
                      fill={`${this.state.arrow2 ? "#898989" : "none"}`}
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#898989"
                      />
                    </g>
                  </svg>
                }
              >
                <Option value="" className="commondropdown">
                  All
                </Option>
                <Option value="manifested" className="commondropdown">
                  <Icon component={ReadyToShipIcon} /> Ready To Ship
                </Option>
                <Option value="rto_in_transit" className="commondropdown">
                  <Icon component={InTransitShipmentIcon} /> RTO In-Transit
                </Option>
                <Option value="transit" className="commondropdown">
                  <Icon component={InTransitShipmentIcon} /> In-Transit
                </Option>
                <Option value="delivered" className="commondropdown">
                  <Icon component={DeliveredShipmentIcon} /> Delivered
                </Option>
                <Option value="rto" className="commondropdown">
                  <Icon component={ReturnedShipmentIcon} /> Returned
                </Option>

                <Option value="ndr_actionable" className="commondropdown">
                  <Icon component={ndrCircle} /> NDR
                </Option>

                <Option value="cancelled" className="commondropdown">
                  <Icon component={CircleCross} /> Cancelled
                </Option>
              </Select>
            </div>
          ) : null}

          {!this.state.openndrbtn ? (
            <div className="drop">
              <Select
                style={{ fontWeight: 500, fontSize: 12, width: 288 }}
                value={
                  this.props.filters.action_status
                    ? this.props.filters.action_status
                    : undefined
                }
                // open={true}
                placeholder="NDR Status: All"
                onChange={(val) =>
                  this.props.changeFilter({
                    action_status: val,
                  })
                }
                onDropdownVisibleChange={(val) =>
                  this.setState({ arrow3: val })
                }
                suffixIcon={
                  <svg
                    style={{
                      transition: "all 0.5s",
                      transform: `${
                        this.state.arrow3 ? "rotateX(180deg)" : "rotateX(0deg)"
                      }`,
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_49"
                      data-name="Polygon 49"
                      transform="translate(16 8) rotate(180)"
                      fill={`${this.state.arrow3 ? "#898989" : "none"}`}
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#898989"
                      />
                    </g>
                  </svg>
                }
              >
                <Option value="" className="commondropdown">
                  All
                </Option>
                <Option value="reattempt" className="commondropdown">
                  Re-Attempt
                </Option>

                <Option value="escalated" className="commondropdown">
                  Escalated
                </Option>
                <Option value="rto_initiated" className="commondropdown">
                  RTO initiated
                </Option>
              </Select>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default NdrOrderNav;
