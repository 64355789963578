import React, { Component } from "react";
import "./index.scss";
import { Checkbox } from "antd";
import CodOrders from "./CodOrders";
import NonDeliveryReport from "./NonDeliveryReports";
import PrepaidOrders from "./PrepaidOrders";
// import axios from "axios";
// import docs from "../../../../../assets/images/icons/docs.png";
// import { ReactComponent as Set } from "../../../../../assets/images/icons/set.svg";
class ContactCenterSettings extends Component {
  state = {};

  componentDidMount() {
    // axios({
    //   url: process.env.REACT_APP_API_URL + "/snapshot/",
    //   method: "post",
    //   data: {
    //     storeid: this.props.auth.store.id,
    //   },
    //   withCredentials: true,
    // })
    //   .then((res) => {
    //     if (res.data.success === 1) {
    //       // const steps = res.data.steps;
    //       this.setState({
    //         steps: res.data.steps,
    //       });
    //     } else {
    //       this.setState({
    //         error: true,
    //         errorMsg: res.data.msg,
    //         loading: false,
    //       });
    //     }
    //   })
    //   .catch((e) => {
    //     this.setState({
    //       error: true,
    //       errorMsg: e.message,
    //       loading: false,
    //     });
    //   });
    // this.props.fetchContactCenter();
  }

  render() {
    // if (this.state.loading === true) {
    //   return <Skeleton active />;
    // } else {
    return (
      <div className="contact-center-setting">
        <div className="enable-order-container">
          <div
            className="enable-order-check"
            style={{
              padding: "16px 0px",
              borderBottom: "1px solid #CECECE",
            }}
          >
            {" "}
            <Checkbox
              checked={this.props.cod.enabled === 1}
              onChange={(e) =>
                this.props.updateSetting(
                  "cod",
                  "enabled",
                  e.target.checked === true ? 1 : 0
                )
              }
            >
              Enable Order Verification for COD Orders:
            </Checkbox>
            {this.props.cod.enabled === 1 ? (
              <CodOrders
                data={this.props.cod}
                updateSetting={(section, key, value) =>
                  this.props.updateSetting(section, key, value)
                }
              />
            ) : null}
          </div>
        </div>

        <div
          style={{
            padding: "16px 0px",
            borderBottom: "1px solid #CECECE",
          }}
        >
          {" "}
          <Checkbox
            checked={this.props.prepaid.enabled === 1}
            onChange={(e) =>
              this.props.updateSetting(
                "prepaid",
                "enabled",
                e.target.checked === true ? 1 : 0
              )
            }
          >
            Enable Order Verification for Prepaid Orders:
          </Checkbox>
          {this.props.prepaid.enabled === 1 ? (
            <PrepaidOrders
              data={this.props.prepaid}
              updateSetting={(section, key, value) =>
                this.props.updateSetting(section, key, value)
              }
            />
          ) : null}
        </div>

        <div
          style={{
            padding: "16px 0px",
          }}
        >
          {" "}
          <Checkbox
            checked={this.props.ndr.enabled === 1}
            onChange={(e) =>
              this.props.updateSetting(
                "ndr",
                "enabled",
                e.target.checked === true ? 1 : 0
              )
            }
          >
            Enable Calling for Non-Delivery Reports (NDR):
          </Checkbox>
          {this.props.ndr.enabled === 1 ? (
            <NonDeliveryReport
              data={this.props.ndr}
              updateSetting={(section, key, value) =>
                this.props.updateSetting(section, key, value)
              }
            />
          ) : null}
        </div>
      </div>
    );
    // }
  }
}

export default ContactCenterSettings;
