import React, { Component } from "react";
import moment from "moment";
import "./index.scss";
import ViewCat from "../../../Common/GlobalCommon/ViewCat";
import ClickToCopy from "../../../Common/ClickToCopy";
// import { Tooltip } from "antd";

import {
  formatNumber,
  getCurrencySymbol,
} from "../../../../../Helpers/Pricing";
import SourcingProductTitle from "../../../Common/GlobalCommon/ProductTitle/SourcingTitle/";

class PricingCard extends Component {
  state = {
    currentImgIndex: 0,
    activeTab: "details",
  };

  //  this function used  for current active tab
  setActiveTab(activeTab) {
    this.setState({
      activeTab,
    });
  }

  render() {
    const request = this.props.request;
    // const notes = request.notes.filter((note) => note.notetype === "note");
    // const logs = request.notes.filter((note) => note.notetype === "log");
    const currency = this.props.geo === "in" ? "INR" : "USD";
    return (
      <div id="test" className="pricing-card">
        <div className="top-section" style={{ position: "relative" }}>
          <div
            className="product-label"
            style={{ background: request.status === 1 ? "#2BC822" : "#FF9400" }}
          >
            <p>{request.status === 1 ? "Pricing Updated" : "In Processing"}</p>
          </div>
          <div className="img-container">
            <img
              src={request.images[this.state.currentImgIndex].url}
              alt="product"
            />

            <div style={{ textAlign: "center" }}>
              <ViewCat
               
                url={`/productDetails/${request.prod_vf}`}
              />
            </div>
          </div>
          <div className="basic-details">
            <h2 className="product-name-detail">
              <span className="product-name-txt">
                <SourcingProductTitle
                  productName={request.catalogname}
                  packaging={request.packaging}
                  storegeo={this.props.geo}
                  vfprodid={request.prod_vf}
                  storeid={this.props.storeid}
                />
              </span>

              <div className="product-id-copie-data">
                <span className="product-Id">PRODUCT ID:</span>
                <span className="copied-Id">
                  <ClickToCopy
                    textToCopy={
                      request && request.prod_vf
                        ? request.prod_vf
                        : "Not available"
                    }
                  />
                </span>
              </div>
            </h2>

            <p className="product-description">{request.product_description}</p>
            <div className="fields">
              <div className="field">
                <p className="field-label">Latest Price Requested On:</p>
                <p className="field-value">
                  {moment(request.requestd_at).format("MMM DD, YYYY")} (
                  {moment().diff(moment(request.requestd_at), "days")} Days ago)
                </p>
              </div>
              <div className="field">
                <p className="field-label">Pricing Updated On:</p>
                <p className="field-value">
                  {moment(request.cost_updated).format("MMM DD, YYYY")} (
                  {moment().diff(moment(request.cost_updated), "days")} Days
                  ago)
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-container">
          <div className="tabs-header">
            <ul>
              <li>
                <button
                  onClick={() => this.setActiveTab("details")}
                  style={{
                    background:
                      this.state.activeTab === "details"
                        ? "#244060"
                        : "transparent",
                  }}
                >
                  Pricing Details
                </button>
              </li>
            </ul>
          </div>
          <div className="tabs-content">
            <div
              className={
                this.state.activeTab === "details"
                  ? "tab-content-all-data block"
                  : "tab-content-all-data"
              }
            >
              <div style={{ width: "100%" }}>
                {/* flex class used  for flex grid */}
                <div className="flex">
                  {/* flex-4 class used  for flex grid */}
                  <div className="flex-4">Retail Price</div>
                </div>
                <div className="flex retail-price-data">
                  {/* flex-1 class used  for flex grid */}
                  <div className="flex-1">
                    <h3 className="retail-piece">
                      {`${getCurrencySymbol(currency)} ${formatNumber(
                        !!request.retail_sourcing &&
                          !!request.retail_sourcing.min
                          ? request.retail_sourcing.min
                          : 0
                      )}`}
                      <span> /piece</span>
                    </h3>
                  </div>
                  {/* flex-3 class used  for flex grid */}
                  <div className="flex-3">
                    <p className="right-text">
                      Includes Product Purchase Price, Local & Cross Border
                      Freight
                      <br />
                      Charges & Custom Duty. Excludes any taxes applicable.
                    </p>
                  </div>
                </div>
              </div>

              {Object.keys(request && request.moq_sourcing).length > 0 ? (
                <div className="bulk-pricing-table">
                  <span className="bulk-pricing-table-txt">
                    Bulk Pricing Table
                  </span>
                  <div className="pricingtabledata">
                    <div className="nowrap-Data">
                      <div className="quantity-data">
                        <div className="quantity-txt">Quantity:</div>
                        <div className="quantity-price-txt">
                          Price:
                          <br />
                        </div>
                      </div>
                      {Object.entries(request.moq_sourcing).map(
                        ([range, data], index) => {
                          return (
                            <div key={range} className="range-data">
                              <div className="range-split-data">
                                {range.split("-")[0]}
                              </div>
                              <div className="freight-ppq">
                                {`${getCurrencySymbol(currency)} ${formatNumber(
                                  request.moq_sourcing[range]
                                    .india_express_freight.ppq
                                )}`}
                                {request.default_moq_sourcing &&
                                request.default_moq_sourcing[range] &&
                                request.default_moq_sourcing[range]
                                  .india_express_freight &&
                                request.default_moq_sourcing[range]
                                  .india_express_freight.ppq ? (
                                  <div className="f12 line-through">
                                    {`${getCurrencySymbol(
                                      currency
                                    )} ${formatNumber(
                                      request.default_moq_sourcing[range]
                                        .india_express_freight.ppq
                                    )}`}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {/* <div
              style={{
                display:
                  this.state.activeTab === "notes" ||
                  this.state.activeTab === "logs"
                    ? "block"
                    : "none",
              }}
              className="note_logs"
            >
              {(this.state.activeTab === "notes" ? notes : logs).length > 0 ? (
                (this.state.activeTab === "notes" ? notes : logs).map(
                  (note, idx) => (
                    <div
                      className="flex"
                      key={idx}
                      style={{
                        padding: "8px 16px",
                        background: "#F5F5F5",
                        marginBottom: 8,
                      }}
                    >
                      <div className="flex-1">{note.note}</div>
                      <div className="flex-1">
                        {moment(note.date).format("DD MMM, YYYY | HH:mm")}
                      </div>
                      <div className="flex-1" style={{ textAlign: "right" }}>
                        by {note.addedby_name}
                      </div>
                    </div>
                  )
                )
              ) : (
                <div
                  style={{
                    padding: 16,
                    textAlign: "center",
                    background: "white",
                  }}
                >
                  No {this.state.activeTab === "notes" ? `Notes` : `Logs`}{" "}
                  Found!
                </div>
              )}
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default PricingCard;
