import React, { Component } from "react";
import {
  Modal,
  Input,
  message,
  Button,
  Tooltip,
  Icon,
  Skeleton,
  Select,
} from "antd";
import {
  getCurrencySymbol,
  formatNumber,
} from "../../../../../Helpers/Pricing";
import { connect } from "react-redux";
import axios from "axios";
import "./index.scss";
const { Option } = Select;

class EstimatePriceModal extends Component {
  state = {
    pincode: "122001",
    codamount: 1000,
    lastcod: 1000,
    variantIndex: 0,
    apiResponse: {
      success: 0,
      price: 0,
      cod: 0,
      shipping: 0,
      ship: 0,
      aliprice: 0,
      alishipping: 0,
    },
    shipping: [{ price: 0 }],
    loading: false,
  };

  getShippingData(shippingdata) {
    let ret = shippingdata
      .filter((d) => d.isTracked)
      .map((d) => {
        const days = d.time.split("-");
        return {
          ...d,
          minDays: Number(days[0]),
          maxDays: Number(days[1]),
        };
      })
      .filter(
        (d) => d.minDays >= 15 && d.maxDays <= 28 && Number(d.price) !== 0
      )
      .sort((a, b) => Number(a.price) - Number(b.price));

    if (ret.length) return ret;

    ret = shippingdata
      .filter((d) => d.isTracked)
      .sort((a, b) => Number(a.price) - Number(b.price));

    if (ret.length) return ret;

    return [{ price: 0 }];
  }

  componentDidUpdate(pp) {
    if (JSON.stringify(this.props.product) !== JSON.stringify(pp.product)) {
      this.setState(
        {
          codamount: 1000,
          lastcod: 1000,
          pincode: "122001",
          variantIndex: 0,
          apiResponse: {
            success: 0,
            price: 0,
            cod: 0,
            shipping: 0,
            fulfillment: 0,
          },
          shipping:
            this.props.product && this.props.product.aliproduct
              ? this.getShippingData(this.props.product.aliproduct.shippingdata)
              : [],
          ship: 0,
          aliprice: 0,
          alishipping: 0,
        },
        () => this.getVFPrice()
      );
    }
  }

  getVFPrice = () => {
    if (!this.props.product) {
      return;
    }
    this.setState(
      {
        loading: true,
        lastcod: this.state.codamount,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/products/get_est_vf_price",
          method: "post",
          data: {
            pincode: this.state.pincode,
            codamount: this.state.codamount,
            variantIndex: this.state.variantIndex,
            storeid: this.props.storeid,
            productid: this.props.product._id["$oid"],
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              const pricingData = { ...res.data };
              this.setState({
                apiResponse: pricingData,
                loading: false,
              });
            } else {
              message.error(res.data.msg);
              this.setState({
                loading: false,
              });
            }
          })
          .catch((e) => {
            message.error(e.message);
            this.setState({
              loading: false,
            });
          });
      }
    );
  };

  setValue(k, v) {
    this.setState({
      [k]: v,
    });
  }

  render() {
    if (!this.props.product) {
      return null;
    }
    const currency = this.props.store.store_geo === "in" ? "INR" : "USD";
    return (
      <Modal
        title="vFulfill Single Order Cost Breakup"
        visible={this.props.visible}
        width={576}
        destroyOnClose={true}
        onCancel={this.props.onCancel}
        className="cost-brakup-modal"
        footer={null}
        maskClosable={false}
      >
        <p
          style={{ fontSize: "12px", fontStyle: "italic", margin: "8px 0 0 0" }}
        >
          This cost breakup is done assuming that the order contains single
          product quantity
        </p>
        <hr />
        {this.props.store.store_geo !== "global" && (
          <div className="cost-breakup-container">
            <div className="cost-breakup-inputs">
              <p className="cost-breakeup-heading">Delivery Pin Code:</p>
              <Input
                value={this.state.pincode}
                onChange={(e) => this.setValue("pincode", e.target.value)}
              />
            </div>
            <div className="cost-breakup-inputs">
              <p
                className="cost-breakeup-heading"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                COD Amount:{" "}
                <Tooltip title="The cash collected for sale of this item">
                  <Icon type="info-circle" style={{ fontSize: "12px" }} />
                </Tooltip>
              </p>
              <Input
                value={this.state.codamount}
                onChange={(e) => this.setValue("codamount", e.target.value)}
              />
            </div>
          </div>
        )}
        <div style={{ marginTop: 15 }}>
          <p className="cost-breakeup-heading">Select Variant</p>
          <Select
            value={this.state.variantIndex}
            style={{ width: "100%" }}
            onChange={(val) => this.setValue("variantIndex", val)}
          >
            {this.props.product.variants.map((v, idx) => (
              <Option key={idx} value={idx}>
                {v.allspecs}
              </Option>
            ))}
          </Select>
        </div>
        <div style={{ margin: "10px 0" }}>
          <Button
            type="primary"
            loading={this.state.loading}
            style={{ width: "100%" }}
            onClick={() => this.getVFPrice()}
          >
            Calculate Cost Breakup
          </Button>
        </div>

        {this.state.loading && <Skeleton />}

        {!this.state.loading &&
          this.props.visible &&
          this.state.apiResponse.success === 1 && (
            <>
              <div className="cost-breakup-container margin-class">
                <div className="ali-pricing">
                  <p
                    className="cost-breakeup-heading"
                    style={{ padding: "6px 0px" }}
                  >
                    AliExpress Price Estimation
                  </p>
                  <p>
                    Product Price
                    <span>
                      <>
                        {getCurrencySymbol(currency)}{" "}
                        {formatNumber(this.state.apiResponse.aliprice)}
                      </>
                    </span>
                  </p>
                  <p>
                    Shipping Price
                    <span>
                      <>
                        {getCurrencySymbol(currency)}{" "}
                        {formatNumber(this.state.apiResponse.alishipping)}
                      </>
                    </span>
                  </p>
                  <p>
                    Payment <br />
                    Gateway Charges{" "}
                    <Tooltip title="For prepaid orders payment gateway will charge 2.5%">
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: "12px",
                          marginLeft: "10px",
                          color: "black",
                        }}
                      />
                    </Tooltip>
                    <span>
                      {getCurrencySymbol(currency)}{" "}
                      {formatNumber(Number(this.state.lastcod) * 0.025)}
                    </span>
                  </p>
                </div>
                <div className="vf-pricing">
                  <p
                    className="cost-breakeup-heading"
                    style={{ padding: "6px 0px" }}
                  >
                    vFulfill Price Estimation
                  </p>
                  <p>
                    Product Price{" "}
                    <Tooltip title="This includes Product fee & Custom Duty for the product.">
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: "12px",
                          marginLeft: "10px",
                          color: "black",
                        }}
                      />
                    </Tooltip>
                    <span>
                      {this.props.product && (
                        <>
                          {getCurrencySymbol(currency)}{" "}
                          {formatNumber(this.state.apiResponse.price)}
                        </>
                      )}
                    </span>
                  </p>
                  <p>
                    Shipping Price{" "}
                    <Tooltip title="This includes Freight & LMD Charges.">
                      <Icon
                        type="info-circle"
                        style={{
                          fontSize: "12px",
                          marginLeft: "10px",
                          color: "black",
                        }}
                      />
                    </Tooltip>
                    <span>
                      {" "}
                      {getCurrencySymbol(currency)}{" "}
                      {formatNumber(this.state.apiResponse.shipping)}
                    </span>
                  </p>
                  {this.props.store.store_geo === "global" ? (
                    <p>
                      Fulfillment
                      <br /> Charges{" "}
                      <Tooltip title="This includes the fees for fulfiling the order.">
                        <Icon
                          type="info-circle"
                          style={{
                            fontSize: "12px",
                            marginLeft: "10px",
                            color: "black",
                          }}
                        />
                      </Tooltip>
                      <span>
                        {" "}
                        {getCurrencySymbol(currency)}{" "}
                        {formatNumber(this.state.apiResponse.fulfillment)}
                      </span>
                    </p>
                  ) : (
                    <p>
                      COD Collection
                      <br /> Charges{" "}
                      <Tooltip title="This includes the fees for collecting COD amount.">
                        <Icon
                          type="info-circle"
                          style={{
                            fontSize: "12px",
                            marginLeft: "10px",
                            color: "black",
                          }}
                        />
                      </Tooltip>
                      <span>
                        {" "}
                        {getCurrencySymbol(currency)}{" "}
                        {formatNumber(this.state.apiResponse.cod)}
                      </span>
                    </p>
                  )}
                </div>
              </div>
              <div className="cost-breakup-container">
                <div className="total-ali-pricing">
                  <p>
                    Total
                    <br /> AliExpress Price
                    <span>
                      {" "}
                      {this.props.product && (
                        <>
                          {getCurrencySymbol(currency)}{" "}
                          {formatNumber(
                            this.state.apiResponse.aliprice +
                              this.state.apiResponse.alishipping +
                              Number(this.state.lastcod) * 0.025
                          )}
                        </>
                      )}
                    </span>
                  </p>
                </div>
                <div className="total-vf-pricing">
                  <p>
                    Total
                    <br /> vFulfill Price
                    <span>
                      {this.props.product && (
                        <>
                          {getCurrencySymbol(currency)}{" "}
                          {formatNumber(
                            Number(this.state.apiResponse.price) +
                              Number(this.state.apiResponse.shipping) +
                              Number(this.state.apiResponse.cod) +
                              Number(this.state.apiResponse.fulfillment)
                          )}
                        </>
                      )}
                    </span>
                  </p>
                </div>
              </div>
              {this.props.store.store_geo !== "global" && (
                <div className="vf-buyback-price-container">
                  <hr />
                  {this.props.product.vfcatprod &&
                  this.props.product.vfcatprod.buyback === 1 &&
                  this.props.product.vfcatprod.buyback_percentage > 0 ? (
                    <>
                      <p
                        className="cost-breakeup-heading"
                        style={{ marginBottom: "5px" }}
                      >
                        vFulfill Buyback Price:
                      </p>
                      <div className="cost-breakup-container">
                        <div className="vf-buyback-price">
                          <div className="buyback-value">
                            {this.props.product &&
                              this.props.product.aliproduct && (
                                <>
                                  {getCurrencySymbol(currency)}{" "}
                                  {formatNumber(
                                    (Number(this.state.apiResponse.price) *
                                      this.props.product.vfcatprod
                                        .buyback_percentage) /
                                      100 -
                                      Number(this.state.apiResponse.ship) +
                                      Number(this.state.apiResponse.cod)
                                  )}
                                </>
                              )}
                          </div>
                        </div>
                        <div className="vf-buyback-price">
                          <Tooltip
                            title={`(${getCurrencySymbol(currency)}
                    ${formatNumber(
                      Number(
                        (this.state.apiResponse.price *
                          this.props.product.vfcatprod.buyback_percentage) /
                          100
                      )
                    )} ) -
                    ${getCurrencySymbol(currency)} ${formatNumber(
                              Number(this.state.apiResponse.ship)
                            )} +
                    ${getCurrencySymbol(currency)}  ${formatNumber(
                              Number(this.state.apiResponse.cod)
                            )}`}
                            placement="topLeft"
                          >
                            <p className="italic-subheading">
                              <i>
                                (Product Price *{" "}
                                {formatNumber(
                                  this.props.product.vfcatprod
                                    .buyback_percentage / 100,
                                  2
                                )}
                                ) - Reverse Shipping
                                <span style={{ color: "#EA2B2B" }}>*</span> +
                                COD collection fee
                                <span style={{ color: "#EA2B2B" }}>**</span>
                              </i>
                            </p>
                          </Tooltip>
                        </div>
                      </div>
                      <hr />
                      <p className="italic-subheading">
                        <i>
                          <span style={{ color: "#EA2B2B" }}>*</span> Reverse
                          shipping is the cost of bringing the package back to
                          our closest warehouse.
                        </i>
                      </p>
                      <p className="italic-subheading">
                        <i>
                          <span style={{ color: "#EA2B2B" }}>**</span> COD
                          collection fee is returned to you since there is no
                          COD collected.
                        </i>
                      </p>
                    </>
                  ) : null}

                  <div className="cost-brakup-footer">
                    <Button onClick={this.props.onCancel}>Close</Button>
                  </div>
                </div>
              )}
            </>
          )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};
export default connect(mapStateToProps)(EstimatePriceModal);
