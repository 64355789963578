import React, { Component } from "react";
import { Button, Input, Modal } from "antd";
import "./index.scss";

class AddNoteModal extends Component {
  state = {
    note: null,
  };

  handleChange = (field, value) => {
    this.setState({
      ...this.state,
      [field]: value,
    });
  };

  resetModal = () => {
    this.setState({
      note: null,
    });
  };

  render() {
    const { visible, error, onCancel, onConfirm } = this.props;

    if (error) {
      // console.log(error);
    }
    return (
      <Modal
        className="orders-add-note-modal"
        title="Add Note"
        visible={visible}
        onCancel={onCancel}
        closable={!this.props.saving}
        afterClose={this.resetModal}
        width={560}
        footer={[
          <Button className="add-note-cancel" key="back" onClick={onCancel}>
            Cancel
          </Button>,
          <Button
            className="add-note-btn clickable-dropdown"
            key="submit"
            type="primary"
            onClick={() => onConfirm(this.state.note)}
            loading={this.props.saving}
          >
            Add Note
          </Button>,
        ]}
      >
        <div>
          <Input.TextArea
            value={this.state.note}
            placeholder="Enter text here"
            onChange={(e) => this.handleChange("note", e.target.value)}
            autosize={{ minRows: 6 }}
          />
        </div>
      </Modal>
    );
  }
}

export default AddNoteModal;
