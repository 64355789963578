import React, { Component } from "react";
import { Input, Form, Icon, Select } from "antd";
import { ReactComponent as individualican } from "../../../../../../assets/images/icons/individualican.svg";
import { ReactComponent as arrowican } from "../../../../../../assets/images/icons/arrow.svg";
import Header from "../../../../Header";
import { countryList, currency } from "./../../../../../../Helpers/countryList";
import GlobalTooltipIcon from "../../../../../Dashboard/Common/GlobalTooltipIcon";
const { Option } = Select;
class Bankdetail extends Component {
  state = {
    selectedCity: "USD",
    selectedACType: "saving",
    arrow2: false,
    arrow: false,
  };

  cityChange(e) {
    this.setState({
      ...this.state,
      selectedCity: e,
      arrow2: !this.state.arrow2,
    });
  }
  actypeChange(e) {
    this.setState({
      ...this.state,
      selectedACType: e,
      arrow: !this.state.arrow,
    });
  }
  cityHandler() {
    this.setState({ arrow2: !this.state.arrow2 });
  }
  cityHandler1() {
    this.setState({ arrow: !this.state.arrow });
  }
  render() {
    const { getFieldDecorator, getFieldsValue } = this.props.form;
    let selectAccountType = [
      { value: "saving", label: "Saving Account" },
      { value: "personal", label: "Personal Account" },
      { value: "business", label: "Business Account" },
      { value: "current", label: "Current  Account" },
      { value: "xme", label: "XME Account" },
    ];

    const bankData = getFieldsValue(["bank[accno]", "bank[re_accno]"]).bank;

    return (
      <>
        {this.props.currentStep === 2 && (
          <Header
            title="Enter Banking Details"
            subtitle="Enter your banking details to begin setting up a wallet in vFulfill."
          />
        )}

        {this.props.kycType === "Organisation"
          ? this.props.currentStep === 2 && (
              <p className="gst-individual">
                <span>
                  <Icon component={individualican} />
                </span>
                <span className="flag">
                  I am an <span className="registered-india">Organization</span>{" "}
                  operating outside India
                </span>
              </p>
            )
          : this.props.currentStep === 2 && (
              <p className="gst-individual">
                <span>
                  <Icon component={individualican} />
                </span>

                <span className="flag">
                  I am an <span className="registered-india">Individual</span>{" "}
                  operating outside India
                </span>
              </p>
            )}

        {this.props.currentStep === 2 && (
          <div className="top-header-steps">
            <p>
              Step {this.props.currentStep} out of {this.props.totalSteps}
            </p>
            <button
              className="back-btn"
              onClick={() => this.props.profileBakButton()}
            >
              <span>
                <Icon component={arrowican} />
              </span>
              Previous Step
            </button>
          </div>
        )}

        <Form>
          {this.props.currentStep === 2 ? (
            <div
              style={{
                display: this.props.currentStep === 2 ? "block" : "none",
              }}
            >
              {/* <div className="label-row"><div className="label required">Beneficiary Name</div>  <div><GlobalTooltipIcon tooltipText="jo"/></div></div> */}
              <Form.Item label="Beneficiary Name">
                {getFieldDecorator("bank[beneficiary_name]", {
                  rules: [
                    {
                      required: true,
                      message: "Enter Beneficiary Name",
                    },
                  ],
                })(<Input placeholder="Enter Beneficiary Name" />)}
              </Form.Item>
              <Form.Item label="Beneficiary Address">
                {getFieldDecorator("bank[beneficiary_address]")(
                  <Input placeholder="Enter Beneficiary Address" />
                )}
              </Form.Item>

              <Form.Item label="Bank Account Number">
                {getFieldDecorator("bank[accno]")(
                  <Input placeholder="Enter your Bank Account Number" />
                )}
              </Form.Item>
              <Form.Item label="International Banking Account Number">
                {getFieldDecorator("bank[iban]")(
                  <Input placeholder="Enter IBAN" />
                )}
              </Form.Item>
              <div className="country-wrapper">
                <div className="global-country">
                  <Form.Item label="Currency">
                    {getFieldDecorator("bank[currency_code]", {
                      initialValue: this.state.selectedCity,
                      rules: [
                        {
                          required: true,
                          message: "Please select your bank currency!",
                        },
                      ],
                    })(
                      <Select
                        placeholder="Select Country"
                        showSearch
                        value={this.state.selectedCity}
                        onChange={(e) => this.cityChange(e)}
                        // filterOption={(input, option) => {
                        //   return (
                        //     option.key
                        //       .toLowerCase()
                        //       .indexOf(input.toLowerCase()) >= 0 ||
                        //     option.props.title
                        //       .toLowerCase()
                        //       .indexOf(input.toLowerCase()) >= 0
                        //   );
                        // }}
                        className="city-select"
                        onDropdownVisibleChange={() => this.cityHandler()}
                        suffixIcon={
                          <svg
                            style={{
                              transition: "all 0.5s",
                              transform: `${
                                this.state.arrow2
                                  ? "rotateX(180deg)"
                                  : "rotateX(0deg)"
                              }`,
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="8"
                            viewBox="0 0 16 8"
                          >
                            <g
                              id="Polygon_33"
                              data-name="Polygon 33"
                              transform="translate(16 8) rotate(180)"
                              fill={`${this.state.arrow2 ? "898989" : "none"}`}
                            >
                              <path d="M8,0l8,8H0Z" stroke="none" />
                              <path
                                d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                                stroke="none"
                                fill="#898989"
                              />
                            </g>
                          </svg>
                        }
                      >
                        {currency.map((item) => {
                          let c = "";
                          if (item.symbol) {
                            c = "(" + item.symbol + ")";
                          }

                          return (
                            <Option
                              title={item.name}
                              key={item.name}
                              id={item.code}
                              value={item.code}
                              className="profile-dropdown"
                            >
                              {item.code}{" "}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: c,
                                }}
                              ></span>
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </div>
                <div className="global-country">
                  <Form.Item label="Account Type">
                    {getFieldDecorator("bank[account_type]", {
                      initialValue: this.state.selectedACType,
                      rules: [
                        {
                          required: true,
                          message: "Please select your A/C type!",
                        },
                      ],
                    })(
                      <Select
                        placeholder="Select Type"
                        showSearch
                        value={this.state.selectedACType}
                        onChange={(e) => this.actypeChange(e)}
                        // filterOption={(input, option) => {
                        //   return (
                        //     option.key
                        //       .toLowerCase()
                        //       .indexOf(input.toLowerCase()) >= 0 ||
                        //     option.props.title
                        //       .toLowerCase()
                        //       .indexOf(input.toLowerCase()) >= 0
                        //   );
                        // }}
                        className="city-select"
                        onDropdownVisibleChange={() => this.cityHandler1()}
                        suffixIcon={
                          <svg
                            style={{
                              transition: "all 0.5s",
                              transform: `${
                                this.state.arrow
                                  ? "rotateX(180deg)"
                                  : "rotateX(0deg)"
                              }`,
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="8"
                            viewBox="0 0 16 8"
                          >
                            <g
                              id="Polygon_33"
                              data-name="Polygon 33"
                              transform="translate(16 8) rotate(180)"
                              fill={`${this.state.arrow ? "898989" : "none"}`}
                            >
                              <path d="M8,0l8,8H0Z" stroke="none" />
                              <path
                                d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                                stroke="none"
                                fill="#898989"
                              />
                            </g>
                          </svg>
                        }
                      >
                        {selectAccountType.map((item) => {
                          return (
                            <Option
                              title={item.value}
                              key={item.value}
                              id={item.value}
                              value={item.value}
                              className="profile-dropdown"
                            >
                              {item.label}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </div>
              {/* <Form.Item
                label="Re-Enter Account Number"
                validateStatus={
                  bankData.accno === bankData.re_accno ? "success" : "error"
                }
                help={
                  bankData.accno === bankData.re_accno
                    ? ""
                    : "Account Numbers do not match"
                }
              >
                {getFieldDecorator("bank[re_accno]", {
                  rules: [
                    {
                      pattern: /\d+/,
                      message: "Please enter a valid Account Number!",
                    },
                    {
                      required: true,
                      message: "Enter your Bank Account Number",
                    },
                  ],
                })(<Input placeholder="Enter your Bank Account Number" />)}
              </Form.Item> */}

              <Form.Item label="SWIFT Code">
                {getFieldDecorator("bank[swift]")(
                  <Input placeholder="Enter SWIFT Code" />
                )}
              </Form.Item>
              <Form.Item label="Routing Number">
                {getFieldDecorator("bank[routing_number]")(
                  <Input placeholder="Enter Routing Number" />
                )}
              </Form.Item>
              <Form.Item label="BIC Code">
                {getFieldDecorator("bank[bic_code]")(
                  <Input placeholder="Enter BIC Code" />
                )}
              </Form.Item>
              <Form.Item label="Intermediary BIC Code">
                {getFieldDecorator("bank[intermediary_bic_code]")(
                  <Input placeholder="Enter Intermediary BIC Code" />
                )}
              </Form.Item>

            
              <Form.Item label="Bank / Payment institution">
                {getFieldDecorator("bank[bank_paymentinstitution]",{
                  rules: [
                    {
                      required: true,
                      message: "Enter Bank / Payment institution",
                    },
                  ],
                })(
                  <Input placeholder="Enter Bank / Payment institution" />
                )}
              </Form.Item>
              <Form.Item label="Bank / Payment institution address">
                {getFieldDecorator("bank[bank_paymentinstitution_address]",{
                  rules: [
                    {
                      required: true,
                      message: "Enter Bank / Payment institution address",
                    },
                  ],
                })(
                  <Input placeholder="Bank / Payment institution address" />
                )}
              </Form.Item>
            </div>
          ) : null}
        </Form>
      </>
    );
  }
}

export default Form.create()(Bankdetail);
