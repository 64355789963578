import React, { Component } from "react";
import {
  Row,
  // Col,
  Skeleton,
  Alert,
  Pagination,
  // DatePicker,
  Card,
  // Icon,
  Select,
  notification,
  message,
  Tag,
} from "antd";
import axios from "axios";
// import { ReactComponent as FFStatusIcon } from "../../../assets/images/icons/ff_status_icon.svg";
// import { ReactComponent as ShipmentStatusIcon } from "../../../assets/images/icons/shopping-cart.svg";
// import { ReactComponent as PaymentStatusIcon } from "../../../assets/images/icons/payment_status_icon.svg";
import { ReactComponent as CircleCheck } from "../../../assets/images/icons/circle-check.svg";
import { ReactComponent as CircleCross } from "../../../assets/images/icons/circle-cross.svg";
import { ReactComponent as CirclePause } from "../../../assets/images/icons/circle-pause.svg";
import { ReactComponent as OpenOrderIcon } from "../../../assets/images/icons/open-order-icon.svg";
import { ReactComponent as NotLinkedIcon } from "../../../assets/images/icons/unlinkedorder.svg";
import { ReactComponent as NonServiceableIcon } from "../../../assets/images/icons/nonservicable.svg";
import { ReactComponent as NotFulfillIcon } from "../../../assets/images/icons/not-fulfill-icon.svg";
import "./index.scss";
import ShipmentCard from "./ShipmentsCard";
import DisputeModals from "./DisputeModals";
import moment from "moment";
import { connect } from "react-redux";
// import { hasPermission } from "../../../Helpers/Permissions";
import NotFoundCard from "../../Dashboard/Common/NotFoundCard/index";
import { SHOPIFY_NAME } from "../../../constants";

import ShipmentOrderNav from "./ShipmentOrderNav";
import qs from "qs";
// import Globalsearch from "../Common/Globalsearch";
import ErrorCard from "../Common/ErrorCard";
import GlobakBulkSearch from "../Common/GlobakBulkSearch";
// import ShipmentRequestModal from "./ShipmentsCard/ShipmentRequestModal";

// const { RangePicker } = DatePicker;
// const { Option } = Select;
// const { Search } = Input;

class Shipments extends Component {
  state = {
    isDateRangeValid: true,
    loading: true,
    error: false,
    errorCode: "",
    errorMsg: "",
    shipments: [],
    shipmentcount: [],
    selected: {},
    tags: [],
    total: 0,
    toggleDisputeData: undefined,
    showAll: false,
    showRTO: false,
    tabIsProcessing: false,
    selectAllOrders: false,
    toBeShippedBtn: false,
    shippedBtn: false,
    allBtn: true,
    visibleBulk: false, //modal bulk search
    sort: "date-desc",
    paging: {
      page: 1,
      per_page_count: 10,
    },
    filters: {
      startDate: "",
      endDate: "",
      dateType: "shopify",
      fulfillmentStatus: [],
      paymentStatus: [],
      vfOrderStatus: [],
      shipmentStatus: [],
      pagename: [],
      gst_enabled: "",
      bulkSearch: [], //bulk search value cointainer
    },

    intransitbtn: false,
    tobeshippedbtn: true,
    deliveredbtn: false,
    returnedbtn: false,
    allShippedBtn: false,

    visible: false,
  };

  calenderRef = React.createRef();

  toggleDisputeModal(data = undefined) {
    this.setState({
      toggleDisputeData: data,
    });
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search.replace(/\?/g, ""));
    if (params.search) {
      if (params.search.split("-").length > 1) {
        this.setState(
          {
            filters: {
              ...this.state.filters,
              search: params.search,
              pagename: ["rto_in_transit"],
            },
            showRTO: true,
          },
          () => this.loadData()
        );
      } else {
        this.setState(
          {
            filters: {
              ...this.state.filters,
              search: params.search,
              pagename: ["all"],
            },
            showAll: true,
          },
          () => this.loadData()
        );
      }
    } else {
      this.loadData();
    }
  }

  componentDidUpdate(pp) {
    if (pp.location.search !== this.props.location.search) {
      const params = qs.parse(this.props.location.search.replace(/\?/g, ""));
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: params.search,
            pagename: ["all"],
          },
          showAll: true,
          paging: { page: 1, pageSize: 10 },
        },
        () => this.loadData()
      );
    }
  }

  loadData() {
    this.setState(
      {
        loading: true,
        orders: [],
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/shipments/get_shipments",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            page: this.state.paging.page,
            pageSize: this.state.paging.per_page_count,
            filters: this.state.filters,
            sort: this.state.sort,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              if (
                !!this.state.filters.pagename.length &&
                this.state.filters.pagename[0] === "delivered" &&
                res.data.show_feedback_form === 1
              ) {
                this.oldUserFeedback(res.data.show_feedback_form_name);
              }
              this.setState({
                shipments: res.data.shipments,
                shipmentcount: res.data.shipment_count,
                currencies: res.data.currencies,
                total: res.data.total,
                loading: false,
                visibleBulk: false,
                filters: { ...this.state.filters },
                error: false,
                errorMsg: "",
                gst_enabled: res.data.gst_enabled,
              });
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
                loading: false,
              });
            }
          })
          .catch((e) => {
            if (!!e.request) {
              this.setState({
                error: true,
                errorCode: e.request.status,
                errorMsg: e.request.statusText,
                loading: false,
              });
            } else {
              this.setState({
                error: false,
                errorMsg: e.message,
                loading: true,
              });
            }
          });
      }
    );

    if (!!this.state.filters.shipmentStatus.length) {
    } else {
      this.setState({ allBtn: true, toBeShippedBtn: false, shippedBtn: false });
    }
  }

  goToPage(page) {
    this.setState(
      {
        paging: { ...this.state.paging, page },
      },
      () => {
        this.loadData();
      }
    );
  }

  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        paging: { ...this.state.paging, per_page_count: pageSize },
      },
      () => this.loadData()
    );
  }

  searchhandler() {
    if (window.location.href.includes("tracking")) {
      this.props.history.push(
        `/${this.props.match.params.storegeo}/${
          this.props.match.params.storeid
        }/tracking?search=${this.state.filters.search}`
      );
    } else {
      this.props.history.push(
        `/${this.props.match.params.storegeo}/${
          this.props.match.params.storeid
        }/tracking?search=${this.state.filters.search}`
      );
    }
  }

  changeFilter2(kvpairs) {
    this.setState(
      {
        filters: { ...this.state.filters, ...kvpairs },
        paging: { ...this.state.paging, page: 1 },
      },
      () => this.searchhandler(),
      () => this.loadData()
    );
  }
  changeFilter(kvpairs) {
    this.setState(
      {
        filters: { ...this.state.filters, ...kvpairs },
        paging: { ...this.state.paging, page: 1 },
      },
      () => this.loadData()
    );
  }

  rangePickerChange(filters) {
    let monthDifference = moment(new Date(filters.endDate)).diff(
      new Date(filters.startDate),
      "months",
      true
    );
    if (monthDifference <= 6 || !monthDifference) {
      this.setState(
        {
          isDateRangeValid: true,
          filters: { ...this.state.filters, ...filters },
          paging: { ...this.state.paging, page: 1 },
        },
        () => this.loadData()
      );

      if (this.calenderRef.current.classList.contains("border-orange")) {
        this.calenderRef.current.classList.remove("border-orange");
      }
    } else {
      this.setState(
        {
          isDateRangeValid: false,
          filters: { ...this.state.filters, ...filters },
          paging: { ...this.state.paging, page: 1 },
        },
        () => this.loadData()
      );
    }
  }

  changeHandler(kvpairs) {
    this.setState({
      filters: { ...this.state.filters, ...kvpairs },
    });
  }
  //bulk search visible or not
  togglebulksearchaction() {
    this.setState({
      visibleBulk: !this.state.visibleBulk,
    });
  }
  //function for reset the bulk search

  resetModal = (searchId = false) => {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          page: 1,
        },

        filters: {
          ...this.state.filters,
          bulkSearch: searchId
            ? this.state.filters.bulkSearch.filter((item) => item !== searchId)
            : [],
        },
      },
      () => this.loadData()
    );
  };

  //handle change in bulk search text fields
  bulkFieldChange = (k, v) => {
    if (!v) {
      this.resetModal();
      return;
    }
    this.setState({
      filters: {
        ...this.state.filters,
        [k]: v.split("\n"),
      },
    });
  };
  //handle search bulk  submit
  bulkSearchHandler() {
    this.setState(
      {
        filters: { ...this.state.filters, search: "" },
      },
      () => {
        if (this.state.filters.bulkSearch.length > 500) {
          message.error("The number of max search item should be 500");
          return;
        }

        this.setState(
          {
            filters: {
              ...this.state.filters,
              bulkSearch: [
                ...new Set(
                  this.state.filters.bulkSearch
                    .filter((item) => item !== "")
                    .map((d) => String(d).trim())
                ),
              ],
            },
          },
          () => this.loadData()
        );
      }
    );
  }
  oldUserFeedback(name) {
    notification.open({
      bottom: "5%",

      className: "old-user-custom-feedback",
      placement: "bottomLeft",
      duration: 6,
      key: "firstTimeUserFeedback",
      style: { border: "2px solid #2A9C27" },
      description: (
        <div class="feedback-old-store">
          <div class="svg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
            >
              <g
                id="Group_3934"
                data-name="Group 3934"
                transform="translate(-136 -400)"
              >
                <circle
                  id="Ellipse_45"
                  data-name="Ellipse 45"
                  cx="24"
                  cy="24"
                  r="24"
                  transform="translate(136 400)"
                  fill="#2a9c27"
                />
                <g
                  id="Polygon_47"
                  data-name="Polygon 47"
                  transform="translate(148 412)"
                  fill="none"
                >
                  <path
                    d="M12,0l4.14,7.275L24,9.167l-5.3,6.388L19.416,24,12,20.673,4.584,24,5.3,15.555,0,9.167,7.86,7.275Z"
                    stroke="none"
                  />
                  <path
                    d="M 12.00000095367432 4.043889999389648 L 9.169970512390137 9.017230033874512 L 3.589206695556641 10.36043453216553 L 7.363531112670898 14.90857028961182 L 6.864070892333984 20.78484344482422 L 12.00000095367432 18.48068046569824 L 17.13593101501465 20.78484344482422 L 16.63647079467773 14.90857028961182 L 20.41079521179199 10.36042785644531 L 14.8300313949585 9.017239570617676 L 12.00000095367432 4.043889999389648 M 12.00000095367432 0 L 16.14000129699707 7.275409698486328 L 24 9.167180061340332 L 18.69866180419922 15.55541038513184 L 19.41641044616699 24 L 12.00000095367432 20.6727294921875 L 4.583591461181641 24 L 5.301340103149414 15.55541038513184 L 1.9073486328125e-06 9.167189598083496 L 7.860000610351562 7.275409698486328 L 12.00000095367432 0 Z"
                    stroke="none"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </div>

          <h1>Hey {name}!</h1>

          <div class="line" />
          <h3>How is your experience with vFulfill?</h3>

          <div class="paragraph">
            <p class="paragraph-first">
              Your feedback would help us to make improvements to our app!
            </p>
            <p>{`Please leave a review on the ${SHOPIFY_NAME} App Store.`} </p>
          </div>

          <div class="btns">
            <button
              onClick={() => this.feedBackHandler("submit", true)}
              className="clickable-dropdown"
            >
              Give Feedback
            </button>
            <button onClick={() => this.feedBackHandler("skip")}>
              Skip for now
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="16"
                viewBox="0 0 8 16"
              >
                <g
                  id="Polygon_46"
                  data-name="Polygon 46"
                  transform="translate(8) rotate(90)"
                  fill="none"
                >
                  <path d="M8,0l8,8H0Z" stroke="none" />
                  <path
                    d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                    stroke="none"
                    fill="#898989"
                  />
                </g>
              </svg>
            </button>
          </div>
        </div>
      ),
      icon: null,
    });
  }

  feedBackHandler(actionTaken, oldUser = false) {
    if (actionTaken === "submit") {
      window.open(
        "https://apps.shopify.com/vfulfill-cod-dropshipping?surface_detail=vfulfill&surface_inter_position=1&surface_intra_position=1&surface_type=search",
        "_blank"
      );
    }

    notification.close("firstTimeUserFeedback");

    axios({
      url: process.env.REACT_APP_API_URL + "/store/feedback_form_action",
      method: "post",
      data: {
        action: actionTaken,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
        } else {
        }
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMsg: e.message,
          loading: false,
        });
      });
  }

  // function for using sorting
  sorterFunction(sort) {
    this.setState(
      {
        sort: sort,
        paging: { page: 1, per_page_count: 20 },
      },
      () => this.loadData()
    );
  }

  downloadCSV() {
    axios({
      url: process.env.REACT_APP_API_URL + "/shipments/get_shipments",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        type: this.props.pageType,
        filters: this.state.filters,
        downloadcsv: 1,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMsg: e.message,
          loading: false,
        });
      });
  }

  render() {
    // let totalamount;
    // this.state.shipment.items.forEach((item) => {
    //   totalamount +=
    //     (item.item_total_sold_price.amount +
    //       this.state.shipment.shipping_perquantity) *
    //     item.quantity;
    // });

    const paymentStatusColors = {
      paid: "#73D13D",
      partially_paid: "#4569FF",
      pending: "#FEC62D",
      refunded: "#FF4D4F",
      partially_refunded: "#FFC53D",
    };
    const paymentStatusText = {
      paid: "Fully Paid",
      partially_paid: "Partially Paid",
      pending: "Cash On Delivery",
      refunded: "Refunded",
      partially_refunded: "Partially Refunded",
    };

    const shipmentStatusColors = {
      processing: "#FF7A45",
      manifested: "#d241fd",
      transit: "#FFC53D",
      delivered: "#73D13D",
      rto: "#F12F1A",
      rto_in_transit: "#FFC53D",
      ndr_actionable: "#FF4D4F",
      lost: "#F12F1A",
    };
    const shipmentStatusText = {
      processing: "In Processing",
      manifested: "Ready To Ship",
      transit: "In Transit",
      delivered: "Delivered on",
      rto: "Returned",
      rto_in_transit: "RTO In-Transit",
      ndr_actionable: "NDR",
      lost: "Lost",
    };
    // const shipmentStatustransit = {
    //   transit: "In Transit",
    // };
    const fulfillmentStatusColors = {
      shipped: "#73D13D",
      unfulfilled: "#FF4D4F",
      partial: "#FF7A45",
    };
    const vfOrderStatusColors = {
      verified: "#73D13D",
      verified_ordered: "#4569FF",
      not_fulfill: "#FF4585",
      hold: "#FF7A45",
      cancelled: "#FF4D4F",
      open: "#FFC53D",
      notlinked: "#DD2525",
      not_serviceable: "#DD5725",
    };
    const vfOrderStatusIcons = {
      verified: CircleCheck,
      verified_ordered: CircleCheck,
      not_fulfill: NotFulfillIcon,
      hold: CirclePause,
      cancelled: CircleCross,
      open: OpenOrderIcon,
      notlinked: NotLinkedIcon,
      not_serviceable: NonServiceableIcon,
    };

    const PageWrapper = (children) => {
      return (
        <div className="my-orders">
          <ShipmentOrderNav
            changeFilter={(e) => this.changeFilter(e)}
            changeHandler={(e) => this.changeHandler(e)}
            togglebulksearchaction={() => this.togglebulksearchaction()}
            filters={this.state.filters}
            {...this.props}
            showAll={this.state.showAll}
            showRTO={this.state.showRTO}
            tobeshippedbtn={this.state.toBeShippedBtn}
            intransitbtn={this.state.intransitbtn}
            deliveredbtn={this.state.deliveredbtn}
            returnedbtn={this.state.returnedbtn}
            paymentStatusColors={paymentStatusColors}
            paymentStatus={this.state.filters.paymentStatus}
            shipments={this.state.shipments}
            shipmentcount={this.state.shipmentcount}
            shipmentStatus={this.state.filters.shipmentStatus}
            storeid={this.props.match.params.storeid}
            loading={this.state.loading}
            downloadCSV={() => this.downloadCSV()}
            calenderRef={this.calenderRef}
            rangePickerChange={(e) => this.rangePickerChange(e)}
            sort={this.state.sort}
            sorterFunction={(e) => this.sorterFunction(e)}
          />
          {this.state.filters.startDate && this.state.filters.endDate ? (
            <>
              <h2 className="content-page-subtitle">
                Orders from{" "}
                {moment(this.state.filters.startDate).format("DD MMM, YYYY")} -{" "}
                {moment(this.state.filters.endDate).format("DD MMM, YYYY")}
              </h2>
              <br />
            </>
          ) : // <h2 className="content-page-subtitle">Your Orders</h2>
          null}

          {!!this.state.filters.bulkSearch &&
          !!this.state.filters.bulkSearch.length ? (
            <div id="fade-in" className="bulk-list-wraper">
              <div className="detailswrp">
                <p>
                  Showing <span>{this.state.total}</span> Results for Bulk
                  Search
                </p>

                <div className="right">
                  {/* <button
                    onClick={() => this.togglebulksearchaction()}
                    className="clickable"
                  >
                    Edit Bulk Search
                  </button> */}
                  <button
                    onClick={() => this.resetModal()}
                    className="clickable err"
                  >
                    Clear All
                  </button>
                </div>
              </div>

              <div className="lst-wraper">
                <div className="left">
                  {this.state.filters.bulkSearch.map((d) => (
                    <Tag
                      closable
                      onClose={(e) => {
                        e.preventDefault();
                        this.resetModal(d);
                      }}
                    >
                      {d}
                    </Tag>
                  ))}
                </div>
              </div>
            </div>
          ) : null}

          {/* <Row>
            <h1 className="content-page-title">
              {this.state.tabIsProcessing ? (
                <Row className="button-row fill3">
                  <span>Showing Data of Shipments</span>

                  <button
                    className={this.state.allBtn ? "active" : ""}
                    value={["processing", "transit"]}
                    onClick={(e) => {
                      this.setState({
                        shippedBtn: false,
                        toBeShippedBtn: false,
                        allBtn: true,
                      });

                      this.changeFilter({ shipmentStatus: [] });
                    }}
                  >
                    All
                  </button>

                  <button
                    className={this.state.shippedBtn ? "active" : ""}
                    value="transit"
                    onClick={(e) => {
                      this.setState({
                        shippedBtn: true,
                        toBeShippedBtn: false,
                        allBtn: false,
                      });

                      this.changeFilter({
                        shipmentStatus: ["delivered", "rto"],
                      });
                    }}
                  >
                    Closed
                  </button>
                  <button
                    className={this.state.toBeShippedBtn ? "active" : ""}
                    value="processing"
                    onClick={(e) => {
                      this.setState({
                        shippedBtn: false,
                        toBeShippedBtn: true,
                        allBtn: false,
                      });
                      this.changeFilter({
                        shipmentStatus: ["processing", "transit"],
                      });
                    }}
                  >
                    Open
                  </button>
                </Row>
              ) : (
                ""
              )}
              <span
                style={{
                  float: "left",
                  fontSize: "14px",
                  color: "#6e6e6e",
                  marginTop: "15px",
                }}
              >
                <p>Showing data of all Shipments</p>
              </span>
            </h1>
          </Row> */}

          {/* <Row style={{ marginBottom: "1rem" }}>
            <Col span={12} style={{ padding: "15px 0" }}>
              <div className="import-product-search" style={{ width: "608px" }}>
                <Input.Search
                  style={{ height: "40px" }}
                  onFocus={{ background: "red" }}
                  placeholder="Search by product name or ID..."
                  onSearch={(value) => this.changeFilter2({ search: value })}
                  enterButton={
                    <button
                      style={{
                        padding: "14px 40px",
                        border: "none",
                        background: "#FD104D",
                        color: "#fff",
                        fontSize: "12px",
                        // lineHeight: "16px",
                        width: "176px",
                        cursor: "pointer",
                        borderRadius: "0 2px 2px 0",
                        height: "40px",
                      }}
                    >
                      Search
                    </button>
                  }
                />
              </div>
              <Globalsearch
                data={this.state.filters}
                change={(value) =>
                  this.changeHandler({ search: value.target.value })
                }
                searchFunc={(value) => this.changeFilter({ search: value })}
              />
            </Col>
            <Col span={12} style={{ padding: "15px 0", textAlign: "right" }}>
              <span style={{ fontSize: "12px", color: "rgb(110, 110, 110)" }}>
                Show Data From
              </span>

              <RangePicker disabledDate={(current) => {
                    return current > moment();
                  }}
                className="pick"
                value={[
                  this.state.filters.startDate === ""
                    ? null
                    : moment(this.state.filters.startDate, "YYYY-MM-DD"),
                  this.state.filters.endDate === ""
                    ? null
                    : moment(this.state.filters.endDate, "YYYY-MM-DD"),
                ]}
                onChange={(_, dateString) =>
                  this.changeFilter({
                    startDate: dateString[0],
                    endDate: dateString[1],
                  })
                }
                style={{ maxWidth: 300, textAlign: "center" }}
              />
            </Col>
          </Row> */}

          {/* <Row style={{ marginBottom: "1.5rem" }}>
            <Col xs={24} md={6}>
              <Select
                className="filter"
                showSearch={false}
                placeholder="Payment Status: All"
                style={{ width: "100%" }}
                onChange={(val) => this.changeFilter({ paymentStatus: val })}
                mode="multiple"
                value={this.state.filters.paymentStatus}
              >
                <Option value="paid">
                  <Icon
                    component={PaymentStatusIcon}
                    style={{ color: paymentStatusColors["paid"] }}
                  />{" "}
                  Fully Paid
                </Option>
                <Option value="partially_paid">
                  <Icon
                    component={PaymentStatusIcon}
                    style={{ color: paymentStatusColors["partially_paid"] }}
                  />{" "}
                  Partially Paid
                </Option>
                <Option value="pending">
                  <Icon
                    component={PaymentStatusIcon}
                    style={{ color: paymentStatusColors["pending"] }}
                  />{" "}
                  Pending (COD)
                </Option>
                <Option value="refunded">
                  <Icon
                    component={PaymentStatusIcon}
                    style={{ color: paymentStatusColors["refunded"] }}
                  />{" "}
                  Refunded
                </Option>
                <Option value="partially_refunded">
                  <Icon
                    component={PaymentStatusIcon}
                    style={{ color: paymentStatusColors["partially_refunded"] }}
                  />{" "}
                  Partially Refunded
                </Option>
              </Select>
            </Col>

            <Col xs={24} md={6}>
              <Select
                className="filter"
                showSearch={false}
                placeholder="vFulfill Order Status: All"
                style={{ width: "100%" }}
                onChange={(val) => this.changeFilter({ vfOrderStatus: val })}
                mode="multiple"
                disabled={true}
                value={this.state.filters.vfOrderStatus}
              >
                <Option value="open">
                  <Icon
                    component={vfOrderStatusIcons["open"]}
                    style={{ color: vfOrderStatusColors["open"] }}
                  />{" "}
                  Open
                </Option>
                <Option value="verified">
                  <Icon
                    component={vfOrderStatusIcons["verified"]}
                    style={{ color: vfOrderStatusColors["verified"] }}
                  />{" "}
                  Verified
                </Option>
                <Option value="verified_ordered">
                  <Icon
                    component={vfOrderStatusIcons["verified_ordered"]}
                    style={{ color: vfOrderStatusColors["verified_ordered"] }}
                  />{" "}
                  Verified & Ordered
                </Option>
                <Option value="not_fulfill">
                  <Icon
                    component={vfOrderStatusIcons["not_fulfill"]}
                    style={{ color: vfOrderStatusColors["not_fulfill"] }}
                  />{" "}
                  Do Not Fulfill
                </Option>
                <Option value="hold">
                  <Icon
                    component={vfOrderStatusIcons["hold"]}
                    style={{ color: vfOrderStatusColors["hold"] }}
                  />{" "}
                  On Hold
                </Option>
                <Option value="cancelled">
                  <Icon
                    component={vfOrderStatusIcons["cancelled"]}
                    style={{ color: vfOrderStatusColors["cancelled"] }}
                  />{" "}
                  Cancelled
                </Option>
                <Option value="notlinked">
                  <Icon
                    component={vfOrderStatusIcons["notlinked"]}
                    style={{ color: vfOrderStatusColors["notlinked"] }}
                  />{" "}
                  Not Linked
                </Option>
                <Option value="not_serviceable">
                  <Icon
                    component={vfOrderStatusIcons["not_serviceable"]}
                    style={{ color: vfOrderStatusColors["not_serviceable"] }}
                  />{" "}
                  Not Serviceable
                </Option>
              </Select>
            </Col>

            <Col xs={24} md={6}>
              <Select
                className="filter"
                showSearch={false}
                placeholder="Fulfillment Status: All"
                style={{ width: "100%" }}
                onChange={(val) =>
                  this.changeFilter({ fulfillmentStatus: val })
                }
                mode="multiple"
                disabled={true}
                value={this.state.filters.fulfillmentStatus}
              >
                <Option value="fulfilled">
                  <Icon
                    component={FFStatusIcon}
                    style={{ color: fulfillmentStatusColors.shipped }}
                  />{" "}
                  Fulfilled
                </Option>
                <Option value="unfulfilled">
                  <Icon
                    component={FFStatusIcon}
                    style={{ color: fulfillmentStatusColors.unshipped }}
                  />{" "}
                  Unfulfilled
                </Option>
                <Option value="partially_fulfilled">
                  <Icon
                    component={FFStatusIcon}
                    style={{ color: fulfillmentStatusColors.partial }}
                  />{" "}
                  Partially Fulfilled
                </Option>
              </Select>
            </Col>

            <Col xs={24} md={6}>
              <Select
                className="filter"
                showSearch={false}
                placeholder="Shipment Status: All"
                style={{ width: "100%" }}
                onChange={(val) => this.changeFilter({ shipmentStatus: val })}
                mode="multiple"
                value={this.state.filters.shipmentStatus}
              >
                <Option
                  disabled={this.state.shippedBtn ? true : false}
                  value="processing"
                >
                  <Icon
                    component={ShipmentStatusIcon}
                    style={{ color: shipmentStatusColors["processing"] }}
                  />{" "}
                  In Processing
                </Option>
                <Option
                  disabled={this.state.shippedBtn ? true : false}
                  value="transit"
                >
                  <Icon
                    component={ShipmentStatusIcon}
                    style={{ color: shipmentStatusColors["transit"] }}
                  />{" "}
                  In Transit
                </Option>
                <Option
                  disabled={this.state.toBeShippedBtn ? true : false}
                  value="delivered"
                >
                  <Icon
                    component={ShipmentStatusIcon}
                    style={{ color: shipmentStatusColors["delivered"] }}
                  />{" "}
                  Delivered
                </Option>
                <Option
                  disabled={this.state.toBeShippedBtn ? true : false}
                  value="rto"
                >
                  <Icon
                    component={ShipmentStatusIcon}
                    style={{ color: shipmentStatusColors["rto"] }}
                  />{" "}
                  Returned
                </Option>
              </Select>
            </Col>
          </Row> */}

          {/* <Row style={{ marginBottom: "30px" }}>
            {hasPermission(this.props.store, "export_shipments_data") && (
              <span style={{ float: "right" }}>
                <a
                  href={`/shipments/export_shipments?${qs.stringify(
                    {
                      storeid: this.props.match.params.storeid,
                      filters: this.state.filters,
                    },
                    { encodeValuesOnly: true }
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ant-btn ant-btn-primary clickable"
                >
                  Download CSV
                </a>
              </span>
            )}
          </Row> */}

          {children}

          <Row>
            <Pagination
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              showSizeChanger
              showQuickJumper
              pageSizeOptions={["10", "25", "50"]}
              onShowSizeChange={(p, d) => this.onShowSizeChange(p, d)}
              current={this.state.paging.page}
              onChange={(page) => this.goToPage(page)}
              total={this.state.total}
              defaultPageSize={this.state.paging.per_page_count}
            />
          </Row>
          <GlobakBulkSearch
            bulksearchtext={this.state.filters}
            visible={this.state.visibleBulk}
            togglebulksearchaction={() => this.togglebulksearchaction()}
            resetModal={() => this.resetModal()}
            loading={this.state.loading}
            bulkFieldChange={(k, v) => this.bulkFieldChange(k, v)}
            submitbulkaction={() => this.bulkSearchHandler()}
          />
        </div>
      );
    };

    if (this.state.loading) {
      return PageWrapper(
        <Row style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </Row>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <ErrorCard
          errorCode={this.state.errorCode}
          errorMsg={this.state.errorMsg}
        />
      );
    }

    if (
      this.state.shipments.length === 0 &&
      !!this.state.filters.startDate &&
      !!this.state.filters.endDate
    ) {
      return PageWrapper(
        <NotFoundCard
          mainHeading={"Oops! No Data Found."}
          secondHeading={
            <p>
              Looks like there are no shipments in{" "}
              <span>current Date Range</span>.
            </p>
          }
          thirdHeading={
            <p>
              Please <span>select another Date Range</span> from Date Picker to
              filter data.
            </p>
          }
        />
      );
    }

    // Shipment Card
    return PageWrapper(
      <div>
        {this.state.shipments.length > 0 ? (
          this.state.shipments.map((shipment) => (
            <ShipmentCard
              key={shipment._id["$oid"]}
              {...this.props}
              geo={this.props.match.params.storegeo}
              shipmentStatusColors={shipmentStatusColors}
              shipmentStatusText={shipmentStatusText}
              paymentStatusColors={paymentStatusColors}
              paymentStatusText={paymentStatusText}
              toggle={(e) => this.toggleDisputeModal(e)}
              shipment={shipment}
              currencies={this.state.currencies}
              vfOrderStatusIcons={vfOrderStatusIcons}
              shipmentStatus={this.state.filters.shipmentStatus}
              gst_enabled={this.state.gst_enabled}
              requestStatus={shipment.request_status}
            />
          ))
        ) : (
          <NotFoundCard
            mainHeading={"Oops! No Data Found."}
            secondHeading={
              <p>Looks like there are no shipments in this page.</p>
            }
          />
        )}
        <DisputeModals
          {...this.props}
          data={this.state.toggleDisputeData}
          resetModal={() => this.toggleDisputeModal()}
          reloadList={() => this.loadData()}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  store: state.auth.auth.store,
});
export default connect(mapStateToProps)(Shipments);
