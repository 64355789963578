import React, { Component } from "react";
import { DatePicker, Button, Select, Tooltip } from "antd";
import moment from "moment";
import "./index.scss";
import { Link } from "react-router-dom";
import Globalsearch from "../../Common/Globalsearch";
import VideoTooltip from "../../Common/VideoTooltip";
const { Option } = Select;
const { RangePicker } = DatePicker;
class OpenRfqNav extends Component {
  componentDidUpdate(pp) {
    if (pp.active !== this.props.activeTab) {
    }
  }

  render() {
    console.log("left", this.props.rfqLeft > 0);
    return (
      <div className="open-rfq-nav fullorder_data">
        <div className="open-rfq-nav-upper">
          <div className="ordfill">
            <div className="private-product-wrap">
              <div className="left" style={{ marginLeft: "7px" }}>
                <div className="breadcurmb-txt">
                  Products <span> &#62; </span>{" "}
                  <span className="breadcurmb-underline"> Quotations</span>
                  {/* <VideoTooltip
                    title="My Products"
                    subtitle="vFulfill product research tool to helps you to find products in the niche you would like to do business."
                    contentHeadings={[
                      "Import List",
                      "Private Products",
                      "Store Products",
                      "My Wishlist",
                    ]}
                    content={[
                      "Products that you import from AliExpress or from vFulfill catalog.",
                      "All products that have been shortlisted by you and are also linked with vFulfill catalog. ",
                      "List of all the products that have been published on your store.",
                      "Products that have been added by you to Wishlist from vFulfill Catalog.",
                    ]}
                    thumbnail=""
                    videolink=""
                  /> */}
                </div>
              </div>

              {this.props.showSearch && (
                <div className="right">
                  <p className="show-data-from">Show Data From: </p>

                  <RangePicker
                    disabledDate={(current) => {
                      return current > moment();
                    }}
                    className="range-picker"
                    value={[
                      this.props.filters.startDate === ""
                        ? null
                        : moment(this.props.filters.startDate, "YYYY-MM-DD"),
                      this.props.filters.endDate === ""
                        ? null
                        : moment(this.props.filters.endDate, "YYYY-MM-DD"),
                    ]}
                    onChange={(_, dateString) =>
                      this.props.changeFilter({
                        startDate: dateString[0],
                        endDate: dateString[1],
                      })
                    }
                  />
                </div>
              )}
            </div>
            <div className="secondwrap">
              <div className="filtertab">
                <div
                  className={
                    !this.props.loading && this.props.activeTab === "open"
                      ? "tab  hover-ndr-underline-animation-click"
                      : " tab hover-ndr-underline-animation "
                  }
                >
                  <button
                    onClick={() =>
                      this.props.changeFilter({ type: "open", status: "" })
                    }
                    className="import-list-txt"
                  >
                    Open Requests
                  </button>

                  <div className="backgroundcircle">
                    {this.props.count && this.props.count.openCount === 0
                      ? "0"
                      : this.props.count &&
                        this.props.count.openCount &&
                        !this.props.loading
                      ? this.props.count.openCount
                      : "..."}
                  </div>
                </div>

                <div
                  className={
                    !this.props.loading && this.props.activeTab === "completed"
                      ? "tab  hover-ndr-underline-animation-click"
                      : " tab hover-ndr-underline-animation "
                  }
                >
                  <button
                    onClick={() =>
                      this.props.changeFilter({ type: "completed", status: "" })
                    }
                    className="import-list-txt"
                  >
                    Closed Requests
                  </button>

                  <div className="backgroundcircle">
                    {this.props.count && this.props.count.completedCount === 0
                      ? "0"
                      : this.props.count &&
                        this.props.count.completedCount &&
                        !this.props.loading
                      ? this.props.count.completedCount
                      : "..."}
                  </div>
                </div>

                <div
                  className={
                    !this.props.loading && this.props.activeTab === "rejected"
                      ? "tab  hover-ndr-underline-animation-click"
                      : " tab hover-ndr-underline-animation "
                  }
                >
                  <button
                    onClick={() =>
                      this.props.changeFilter({ type: "rejected", status: "" })
                    }
                    className="import-list-txt"
                  >
                    Rejected Requests
                  </button>

                  <div className="backgroundcircle">
                    {this.props.count && this.props.count.rejectedCount === 0
                      ? "0"
                      : this.props.count &&
                        this.props.count.rejectedCount &&
                        !this.props.loading
                      ? this.props.count.rejectedCount
                      : "..."}
                  </div>
                </div>
              </div>

              {this.props.showSearch && !this.props.loading ? (
                this.props.rfqLeft > 0 ? (
                  <div className="roq-btb-container">
                    <Button
                      className="nav-rfq-btn"
                      type="primary"
                      onClick={() => this.props.rfqHandler()}
                    >
                      Request Quotation
                    </Button>
                    <div className="rfq-left-dis">
                      <div className="rfq-left">
                        {" "}
                        <span className="labe">Credits left :</span>{" "}
                        <span className="valu">
                          {this.props.rfqLeft} / {this.props.rfqAvailability}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Tooltip
                    title={
                      <div>
                        Upgrade subscription for more quotation requests.
                        <div style={{ marginTop: "12px" }}>
                          <a
                            href="../../../profile/?plan=upgradesubscriptionplan"
                            style={{
                              border: "1px solid #0068FF",
                              padding: "5px",
                              borderRadius: "4px",
                              backgroundColor: "#0068FF",
                              color: "#fff",
                              fontWeight: "500",
                            }}
                            target="_blank"
                          >
                            {" "}
                            Upgrade Plan Now
                          </a>
                        </div>
                      </div>
                    }
                  >
                    <div className="roq-btb-container">
                      <Button
                        className="nav-rfq-btn"
                        type="primary"
                        onClick={() => this.props.rfqHandler()}
                        disabled={true}
                      >
                        Request Quotation
                      </Button>
                      <div className="rfq-left-dis">
                        {" "}
                        <span className="labe">Credits left :</span>{" "}
                        <span className="valu">
                          {this.props.rfqLeft} / {this.props.rfqAvailability}
                        </span>
                      </div>
                    </div>
                  </Tooltip>
                )
              ) : null}
            </div>
          </div>
        </div>

        <div className="bottom_data">
          {this.props.showSearch && (
            <Globalsearch
              data={this.props.data}
              change={(value) =>
                this.props.changeHandler({ search: value.target.value })
              }
              searchFunc={(value) => this.props.changeFilter({ search: value })}
              placeholder={"Search By Product Name, Product VFID"}
            />
          )}

          {this.props.showSearch && this.props.filters.type === "open" && (
            <div className="open-rfq-status-wraper">
              <Select
                value={this.props.filters.status}
                className="rfq-status"
                onChange={(val) =>
                  this.props.changeFilter({
                    status: val,
                  })
                }
                dropdownClassName="custom-select"
                suffixIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_49"
                      data-name="Polygon 49"
                      transform="translate(16 8) rotate(180)"
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                      />
                    </g>
                  </svg>
                }
              >
                <Option value="" className="commondropdown">
                  <span>Filter Status: </span> All
                </Option>

                <Option value="open" className="commondropdown">
                  <span>Filter Status: </span> Open
                </Option>

                <Option value="in_processing" className="commondropdown">
                  <span>Filter Status: </span> In-Processing
                </Option>
                <Option value="reopened" className="commondropdown">
                  <span>Filter Status: </span> Re-Opened
                </Option>
              </Select>
            </div>
          )}

          {this.props.showSearch && this.props.filters.type === "completed" && (
            <div className="open-rfq-status-wraper">
              <Select
                value={this.props.filters.status}
                className="rfq-status"
                placeholder="Payment Status: All"
                onChange={(val) =>
                  this.props.changeFilter({
                    status: val,
                  })
                }
                dropdownClassName="custom-select"
                suffixIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_49"
                      data-name="Polygon 49"
                      transform="translate(16 8) rotate(180)"
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                      />
                    </g>
                  </svg>
                }
              >
                <Option value="" className="commondropdown">
                  <span>Filter Status: </span> All
                </Option>
                <Option value="closed" className="commondropdown">
                  <span>Filter Status: </span> Completed
                </Option>

                <Option value="expired" className="commondropdown">
                  <span>Filter Status: </span> Expired
                </Option>
                <Option value="cancelled" className="commondropdown">
                  <span>Filter Status: </span> Cancelled
                </Option>
              </Select>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default OpenRfqNav;
