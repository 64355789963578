import React from "react";
import { Button, Switch } from "antd";
import MoverCard from "./Cards/MoverCard";
import ShakerCard from "./Cards/ShakerCard";
import EarthQuakerCard from "./Cards/EarthQuakerCard";
import UniCornCard from "./Cards/UniCornCard";
import FreePlanCard from "./Cards/FreePlanCard";
import { getCurrencySymbol } from "../../../../Helpers/Pricing";
import analytics from "../../../../analytics";
import "./index.scss";

class PlansScreen extends React.Component {
  state = {
    visible: false,
    cardArr: [],
  };

  componentDidMount() {
    this.setState({
      cardArr:
        this.props.planType === "month"
          ? !!this.props.plans && !!this.props.plans.monthly.length
            ? this.props.plans.monthly
            : []
          : !!this.props.plans && !!this.props.plans.yearly.length
          ? this.props.plans.yearly
          : [],
    });
  }

  componentDidUpdate(prev, props) {
    if (
      prev.planType !== this.props.planType ||
      prev.plans !== this.props.plans
    ) {
      this.setState({
        cardArr:
          this.props.planType === "month"
            ? !!this.props.plans && !!this.props.plans.monthly.length
              ? this.props.plans.monthly
              : []
            : !!this.props.plans && !!this.props.plans.yearly.length
            ? this.props.plans.yearly
            : [],
      });
    }
  }

  cardTypesHandler() {
    return (
      !!this.state.cardArr &&
      this.state.cardArr.map((card) => {
        if (String(card.plan_displayname).includes("Earthquaker")) {
          return (
            <EarthQuakerCard
              onClose={() => this.props.onClose()}
              isActivePlan={
                this.props.upgradePlan
                  ? card.plan_displayname.includes(
                      this.props.current_plan.plan_name
                    )
                  : false
              }
              website_signup={this.props.website_signup}
              current_plan={this.props.current_plan}
              isFreePlan={this.props.isFreePlan}
              upgradePlan={this.props.upgradePlan}
              getCurrencySymbol={(currency) => getCurrencySymbol(currency)}
              loading={this.props.loading}
              state={this.props.state}
              setState={(state) => this.props.setState(state)}
              card={card}
              planType={this.props.planType}
              tabHandler={(key) => this.props.tabHandler(key)}
            />
          );
        }

        if (String(card.plan_displayname).includes("Shaker")) {
          return (
            <ShakerCard
              website_signup={this.props.website_signup}
              onClose={() => this.props.onClose()}
              isActivePlan={
                this.props.upgradePlan
                  ? card.plan_displayname.includes(
                      this.props.current_plan.plan_name
                    )
                  : false
              }
              current_plan={this.props.current_plan}
              isFreePlan={this.props.isFreePlan}
              upgradePlan={this.props.upgradePlan}
              loading={this.props.loading}
              getCurrencySymbol={(currency) => getCurrencySymbol(currency)}
              state={this.props.state}
              setState={(state) => this.props.setState(state)}
              card={card}
              planType={this.props.planType}
              tabHandler={(key) => this.props.tabHandler(key)}
            />
          );
        }
        if (String(card.plan_displayname).includes("Mover")) {
          return (
            <MoverCard
              website_signup={this.props.website_signup}
              onClose={() => this.props.onClose()}
              isActivePlan={
                this.props.upgradePlan
                  ? card.plan_displayname.includes(
                      this.props.current_plan.plan_name
                    )
                  : false
              }
              isFreePlan={this.props.isFreePlan}
              current_plan={this.props.current_plan}
              upgradePlan={this.props.upgradePlan}
              loading={this.props.loading}
              getCurrencySymbol={(currency) => getCurrencySymbol(currency)}
              state={this.props.state}
              setState={(state) => this.props.setState(state)}
              card={card}
              planType={this.props.planType}
              tabHandler={(key) => this.props.tabHandler(key)}
            />
          );
        }
      })
    );
  }

  render() {
    return (
      <div id="fade-in" className="plan-screen-wraper">
        <div className="scroll-area">
          <div className="plan-header">
            <h1>Choose Your Growth Plan</h1>

            <div className="switch-wraper">
              <h2>Monthly</h2>{" "}
              <Switch
                onChange={(checked) => {
                  analytics.track(
                    this.props.planType === "month"
                      ? `Yearly Subscription`
                      : `Monthly Subscription`
                  );
                  this.props.setState({
                    ...this.props.state,
                    planType: checked ? "year" : "month",
                  });
                }}
                checked={this.props.planType === "month" ? false : true}
              />{" "}
              <h2>Yearly</h2> <span>(🔥 Get Upto 2 months FREE)</span>
            </div>
          </div>

          <div className="plan-card-wraper">{this.cardTypesHandler()}</div>

          {/* <UniCornCard planType={this.props.planType} /> */}

          {/* {this.freePlanHandler()} */}

          {this.props.current_plan &&
          this.props.current_plan.plan_type === "paid" &&
          this.props.current_plan.plan_status !== "past_due" ? (
            <div style={{ padding: "32px" }}></div>
          ) : (
            <FreePlanCard
              onClose={() => this.props.onClose()}
              isActivePlan={
                this.props.upgradePlan
                  ? !!this.props.current_plan.plan_type === "free"
                  : false
              }
              isFreePlan={this.props.isFreePlan}
              loading={this.props.loading}
              current_plan={this.props.current_plan}
              planType={this.props.planType}
              state={this.props.state}
              setState={(state) => this.props.setState(state)}
            />
          )}
        </div>

        {/* <div className="plan-footer">
          <Button
            onClick={() => {
              analytics.track("Plan Comparison Clicked");
            }}
          >
            Compare All Plans
          </Button>
        </div> */}
      </div>
    );
  }
}

export default PlansScreen;
