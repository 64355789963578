import React, { Component } from "react";
import {
  Modal,
  Button,
  Table,
  Row,
  Skeleton,
  Pagination,
  Icon,
  Tooltip,
} from "antd";
import "./index.scss";
import moment from "moment";
import ErrorCard from "../../../Common/ErrorCard";
import { ReactComponent as newTabIcon } from "../../../../../assets/images/icons/openInNew.svg";
import GlobalTooltipIconWhite from "../../../Common/GlobalTooltipIconWhite";
import TooltipTitle from "../../../Common/GlobalTooltipIconWhite";

class CustomTable extends Component {
  render() {
    const columnsLabel = {
      vforder_id: "VF Order ID",
      order_confirmation_date: "Order Confirmation Date",
      is_cod: "Order Type",
      order_shopify_name: "Channel Order ID",
      expenses: "Net Expense",
      cod_income: "COD Income",
      prepaid_income: "Prepaid Income",
      total_income: "Net Income",
      net_profit: "Net Profit",
      shipment_zone: "Order Zone",
      shipment_weight: "Shipment Weight",
      order_type: "Order Type",
      pp_cost: "Product Price",
      ff_cost: "Fulfillment Fee",
      fs_cost: "Forward Shipping",
      remit_cost: "Remittance Amount",
      orderamount_gst_deductions: "Order GST Deduction",
      codf_cost: "COD Fee",
      rto_shipping_cost: "RTO Shipping",
      rto_handling_cost: "RTO Handling",
      verified_order_cancellation: "Order Cancellation Fee",
      prepaid_remittance: "Prepaid Remittance",
      payment_gatway_charges: "Payment Gateway Fee",
      order_reverse_pickup: "RVP Charges",
      credit_note_ff: "Fulfillment Fee Reverse",
      credit_note_pp: "Product Price Reverse",
      credit_note_fs: "Forward Shipping Reverse",
      codf_reverse: "COD Fee Reverse",
      management_fee: "Management Fee",
      orderamount_gst_deductions_reverse: "Order GST Reverse",
      management_fee_reverse: "Management Fee Reverse",
      rev_expenses: "Return Expenses",
      fwd_expenses: "Forward Expenses",
      platform_fee: "Platform Fee",
      platform_fee_reverse: "Platform Fee Reverse",
    };

    const currentStatus = {
      cancelled: "Cancelled",
      damaged: "Damaged",
      delivered: "Delivered",
      lost: "Lost",
      manifested: "Manifested",
      ndr_actionable: "Failed Delivery",
      out_for_delivery: "Out For Delivery",
      rto: "RTO",
      rto_in_transit: "RTO In Transit",
      transit: "Transit",
    };

    const {
      data = [],
      loading,
      error,
      errorMsg,
      errorCode,
      startDate,
      endDate,
      graphName,
      currentPage,
      pageSize,
      downloadCSVLoading,
      total,
      customColumns = false,
    } = this.props;

    const nonPricingLabels = [
      "vforder_id",
      "order_shopify_name",
      "order_type",
      "is_cod",
      "shipment_zone",
      "shipment_weight",
    ];
    const dateLabels = ["order_confirmation_date"];

    const fixedLabelRight = ["net_profit"];

    let col = () => {
      if (!!data.length) {
        let column = [];
        Object.entries(data[0]).forEach((d) => {
          let ob = {
            title: "",
            dataIndex: "",
            render: "",
          };

          if (dateLabels.includes(d[0])) {
            ob.title = columnsLabel[d[0]];
            ob.dataIndex = d[0];
            ob.render = (d) => {
              return <span> {moment(d).format("DD MMM, YYYY")}</span>;
            };
          } else if (nonPricingLabels.includes(d[0])) {
            if (d[0] === "vforder_id" || d[0] === "order_shopify_name") {
              ob.title = columnsLabel[d[0]];
              ob.dataIndex = d[0];
              ob.fixed = d[0] === "vforder_id" ? "left" : "";
              ob.render = (d) => {
                return (
                  <span>
                    {" "}
                    <a
                      href={`./orders?search=${d}`}
                      className="underline-animation"
                      target="_blank"
                    >
                      {d}
                    </a>{" "}
                  </span>
                );
              };
            } else {
              ob.title = columnsLabel[d[0]];
              ob.dataIndex = d[0];
              ob.render = (d) => {
                return <span> {d}</span>;
              };
            }
          } else {
            if (fixedLabelRight.includes(d[0])) {
              ob.title = columnsLabel[d[0]];
              ob.dataIndex = d[0];
              ob.fixed = "right";
              ob.render = (d) => {
                return (
                  <span>
                    {d !== "N/A" && !!Number(d)
                      ? `₹ ${Number(d).toFixed(2)}`
                      : 0}
                  </span>
                );
              };
            } else {
              if (d[0] === "expenses") {
                ob.title = (
                  <span className="tooltip-title">
                    {columnsLabel[d[0]]}

                    <TooltipTitle tooltipText={"Expenses for Order Shipment"} />
                  </span>
                );

                ob.dataIndex = d[0];
                ob.render = (d) => {
                  return (
                    <span>
                      {d !== "N/A" && !!Number(d)
                        ? `₹ ${Number(d).toFixed(2)}`
                        : 0}
                    </span>
                  );
                };
              } else if (d[0] === "cod_income") {
                ob.title = (
                  <span className="tooltip-title">
                    {columnsLabel[d[0]]}

                    <TooltipTitle
                      tooltipText={"Amount Recieved from COD Remittance"}
                    />
                  </span>
                );

                ob.dataIndex = d[0];
                ob.render = (d) => {
                  return (
                    <span>
                      {d !== "N/A" && !!Number(d)
                        ? `₹ ${Number(d).toFixed(2)}`
                        : 0}
                    </span>
                  );
                };
              } else if (d[0] === "prepaid_income") {
                ob.title = (
                  <span className="tooltip-title">
                    {columnsLabel[d[0]]}

                    <TooltipTitle tooltipText={"Prepaid Order Value"} />
                  </span>
                );

                ob.dataIndex = d[0];
                ob.render = (d) => {
                  return (
                    <span>
                      {d !== "N/A" && !!Number(d)
                        ? `₹ ${Number(d).toFixed(2)}`
                        : 0}
                    </span>
                  );
                };
              } else {
                ob.title = columnsLabel[d[0]];
                ob.dataIndex = d[0];
                ob.render = (d) => {
                  return (
                    <span>
                      {d !== "N/A" && !!Number(d)
                        ? `₹ ${Number(d).toFixed(2)}`
                        : 0}
                    </span>
                  );
                };
              }
            }
          }

          column.push(ob);
        });

        return column;
      } else {
        return [];
      }
    };

    function tooltipHandler(name, id) {
      try {
        if (name.length > 28) {
          return (
            <div className="name-wraper ">
              <Tooltip title={name}>
                <a
                  href={
                    `${process.env.REACT_APP_NEW_APP}` + "/productDetails/" + id
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                  className="name underline-animation"
                >
                  {name.slice(0, 28).trim() + "..."}
                </a>

                <Icon component={newTabIcon} />
              </Tooltip>
            </div>
          );
        } else {
          return (
            <div className="name-wraper ">
              <a
                href={
                  `${process.env.REACT_APP_NEW_APP}` + "/productDetails/" + id
                }
                rel="noopener noreferrer"
                target="_blank"
                className="name underline-animation"
              >
                {name}
              </a>
              <Icon component={newTabIcon} />
            </div>
          );
        }
      } catch {}
    }

    const rtoColmuns = [
      {
        title: "Order ID",
        dataIndex: "order_vfid",
        key: "1",
        fixed: "left",
        align: "center",
        render: (a, b) => {
          return (
            <a
              href={`./orders?search=${a}`}
              className="underline-animation"
              target="_blank"
            >
              {a}
            </a>
          );
        },
      },
      {
        title: "AWB",
        dataIndex: "awb",
        key: "2",
        align: "center",
        headerAlign: "center",
        render: (a, b) => {
          return (
            <a
              href={`./tracking?search=${a}`}
              className="underline-animation"
              target="_blank"
            >
              {a}
            </a>
          );
        },
      },

      {
        title: "Current Status",
        dataIndex: "status",
        key: "4",
        align: "center",
        render: (picked_up, arr) => currentStatus[picked_up],
      },

      {
        title: <p style={{ textAlign: "left" }}>Product Details</p>,

        dataIndex: "",
        key: "3",
        align: "left",
        render: (awb, arr) => {
          return (
            <div className="details">
              {tooltipHandler(arr.item_name, arr.product_id)}

              <div className="id">PRODUCT ID: {arr.product_id}</div>
            </div>
          );
        },
      },

      {
        title: "Dispatch date",
        dataIndex: "picked_up",
        key: "4",
        align: "center",
        render: (picked_up, arr) =>
          !!picked_up && moment(picked_up).format("DD MMM, YYYY"),
      },

      {
        title: "Total Attempts",
        dataIndex: "total_ofd_attempts",
        key: "5",
        align: "center",
      },

      {
        title: "First Attempt Date",
        dataIndex: "first_ofd_date",
        key: "6",
        align: "center",
        render: (first_ofd_date, arr) =>
          !!first_ofd_date && moment(first_ofd_date).format("DD MMM, YYYY"),
      },

      {
        title: "Last Attempt Date",
        dataIndex: "last_ofd_date",
        key: "7",
        align: "center",
        render: (last_ofd_date, arr) =>
          !!last_ofd_date && moment(last_ofd_date).format("DD MMM, YYYY"),
      },

      {
        title: (
          <p className="table-title-icon">
            EDD <GlobalTooltipIconWhite tooltipText="Expected Delivery Date" />{" "}
          </p>
        ),
        dataIndex: "edd",
        key: "8",
        align: "center",

        render: (edd, arr) => {
          if (arr.status === "rto" || !edd) {
            return <p>-</p>;
          } else {
            return <p>{!!edd && moment(edd).format("DD MMM, YYYY")}</p>;
          }
        },
      },

      {
        title: "Latest NDR Reason",
        dataIndex: "latest_ndr_reason",
        key: "9",
        align: "center",
        width: 300,
        render: (a, b) => (
          <p style={{ fontWeight: "500", color: "#212121" }}>{a}</p>
        ),
      },

      // {
      //   title: "RTO Initiated Date",
      //   dataIndex: "rto_initiated_date",
      //   key: "10",
      //   align: "center",
      //   render: (rto_initiated_date, arr) => {
      //     if (!rto_initiated_date || arr.status === "delivered") {
      //       return <p>-</p>;
      //     } else {
      //       return (
      //         <p>
      //           {!!rto_initiated_date &&
      //             moment(rto_initiated_date).format("DD MMM, YYYY")}
      //         </p>
      //       );
      //     }
      //   },
      // },

      {
        title: "Closed On",
        dataIndex: "closed_on",
        key: "11",
        align: "center",
        render: (closed_on, arr) =>
          !!closed_on && moment(closed_on).format("DD MMM, YYYY"),
      },
    ];

    const PageWrapper = (children) => {
      return (
        <>
          <div className="table-top-head flex">
            {customColumns ? (
              <div>
                <span className="name">{graphName}</span>
              </div>
            ) : (
              <div>
                <span className="name">{graphName}</span> |{" "}
                <span>
                  {startDate} - {endDate}
                </span>
              </div>
            )}

            {!loading && !error && !!data.length ? (
              <Button
                loading={downloadCSVLoading}
                onClick={() => this.props.downloadCSV()}
                className="clickable"
              >
                Export CSV
              </Button>
            ) : null}
          </div>
          <div className="orders-to-vf-graph-wraper" id="fade-in">
            <div
              className={
                customColumns
                  ? "rtoTable custom-table-wraper"
                  : "custom-table-wraper"
              }
            >
              {children}
            </div>
            <div className="table-pagination">
              <Pagination
                locale={{ jump_to: "Go to page" }}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                showSizeChanger
                // showQuickJumper
                pageSizeOptions={["10", "25", "50"]}
                onShowSizeChange={(p, d) => this.props.onShowSizeChange(p, d)}
                current={currentPage}
                onChange={(a, b) => this.props.goToPage(a, b)}
                total={total}
                defaultPageSize={pageSize}
              />
            </div>
          </div>
        </>
      );
    };

    if (!loading && error) {
      return PageWrapper(
        <Row
          style={{
            height: "430px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ErrorCard errorMsg={errorMsg} errorCode={errorCode} />
        </Row>
      );
    }

    // if (loading) {
    //   return PageWrapper(
    //     <Row
    //       style={{
    //         height: "430px",
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "center",
    //       }}
    //     >
    //       <Skeleton active />
    //     </Row>
    //   );
    // }

    return PageWrapper(
      <Table
        columns={customColumns ? rtoColmuns : col()}
        scroll={{ x: "max-content" }}
        rowKey={(record, i) => i}
        dataSource={data}
        loading={loading}
        pagination={false}
      />
    );
  }
}

export default CustomTable;
