import React from "react";

import { Icon } from "antd";

import { ReactComponent as EscIcon } from "../../../../assets/images/icons/escIcon.svg";

import "./index.scss";
const ErrorCard = (props) => {
  const { errorCode, errorMsg } = props;

  return (
    <div className="error-card-wraper">
      <div className="error-message-with-ic">
        <div className="icon">
          {" "}
          <Icon component={EscIcon} />{" "}
        </div>
        <h2>
          {!!errorCode ? errorCode : null} {!!errorMsg ? errorMsg : null}
        </h2>
      </div>
      <div className="error-description">
        Oops! Looks like something went wrong. We are looking right into it, you
        can come back later or inform us with a screenshot from link below.
      </div>

      <a
        href="https://help.vfulfill.io/portal/en/newticket?departmentId=244206000011742048&layoutId=244206000011744275"
        target="_blank"
      >
        Raise Ticket
      </a>
    </div>
  );
};

export default ErrorCard;
