import React, { Component } from "react";
import { Alert, Button, Input, Modal } from "antd";
import "./index.scss";

// const RadioGroup = Radio.Group;

class BulksearchoptionModal extends Component {
  //   state = {
  //     searchtype: "vforderid",
  //   };

  //this function are used  for  input box onchnage , it take value  and store the value in state
  //   handleChange = (field, value) => {
  //     this.setState({
  //       ...this.state,
  //       [field]: value,
  //     });
  //   };

  render() {
    return (
      <Modal
        className="bulk-place-modal"
        title={
          <>
            <div className="heading-cointainer">
              <p className="bulk-search-txt">Place Bulk Order </p>
              <button
                onClick={() => this.props.clearData()}
                className="clear-data"
              >
                Clear data{" "}
              </button>
            </div>
            {/* <div style={{color:"#898989",fontSize:"12px",fontWeight:400}}>
                Orders can only be placed for items in stock.{" "}
                <div>
                  Out-of-stock items will be excluded until they are available.
                </div>
              </div>{" "} */}
            <div>
              <Alert
                type="info"
                showIcon
                message="Important Information"
                description={
                  <div style={{ fontWeight: "400",fontSize:"11px" }}>
                    <div>Orders can only be placed for items in stock.</div>
                    <div>
                      Out-of-stock items will be excluded until they are
                      available.
                    </div>
                  </div>
                }
              />
            </div>
          </>
        }
        visible={this.props.visible}
        onCancel={() => {
          this.props.resetModal();
          //   this.props.togglebulksearchaction();
        }}
        // afterClose={this.props.resetModal}
        // destroyOnClose={true}
        width={480}
        footer={[
          <Button
            className={
              !!this.props.bulksearchtext.length
                ? "bulk-search-btn clickable-dropdown"
                : "disabled-bulk-search-btn"
            }
            key="submit"
            type="primary"
            loading={this.props.loading}
            onClick={() => {
              if (!!this.props.bulksearchtext.length) {
                this.props.submitbulkaction();
              }
            }}
          >
            Place Order
          </Button>,
          <Button
            className="bulk-search-cancel-modal"
            key="back"
            onClick={() => {
              this.props.resetModal();
              this.props.togglebulksearchaction();
            }}
          >
            Cancel
          </Button>,
        ]}
      >
        <div className="bulk-search">
          <div className="bulk-search-radio-btn">
            <p />
          </div>
          {/*  if searchType  equal vforderid  then search by vforderid a else awbid  */}

          <div key="bulkImportId">
            <Input.TextArea
              placeholder={
                this.props.page === "orders"
                  ? "Enter the VF Order ID,separated by a new line. "
                  : "Enter the VF Order ID, Product SKU ID, Shopify Order ID, Shipment AWB  you want to Search, separated by a new line."
              }
              value={this.props.bulksearchtext.join("\n")}
              onChange={(e) =>
                this.props.bulkFieldChange("bulkOrderPlace", e.target.value)
              }
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default BulksearchoptionModal;
