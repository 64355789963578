import React from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown } from "antd";

import "./index.scss";

class ViewInVfCatalogButton extends React.Component {
  render() {
    return (
      <div className="footer ">
        <div
          style={{ width: "80% !important" }}
          className="product_detail ViewInVFCatalog_Button"
        >
          <Button className="view-product-details">
            <Link
              className="f12 af hover-underline-animation"
              to={`/${this.props.storegeo}/${this.props.storeid}/products/${this.props.product.vfprodid}`}
              target="_blank"
            >
              View Product Details{" "}
              <svg
                className="ml8"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="8"
                viewBox="0 0 16 8"
              >
                <path
                  data-name="Path 36"
                  d="M15.907 3.73L12.352.084a.272.272 0 00-.324-.052.305.305 0 00-.176.3v2.334H.3a.272.272 0 00-.213.094.348.348 0 00-.083.24v2a.347.347 0 00.083.239.272.272 0 00.213.094h11.552v2.334a.317.317 0 00.176.3.265.265 0 00.324-.062l3.555-3.688a.363.363 0 00.092-.25.35.35 0 00-.092-.237z"
                  fill="#0068ff"
                />
              </svg>
            </Link>
            <div className="under" />
          </Button>
        </div>
        <div className="dropdown_link">
          <Dropdown overlay={this.props.menu} trigger={["hover"]}>
            <Button className="ant-dropdown-link">
              <span style={{ marginRight: "-4px" }}>Ali Links </span>
              <svg
                className="down-arrow"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="8"
                viewBox="0 0 16 8"
                style={{ marginLeft: "8px" }}
              >
                <g
                  id="Polygon_50"
                  data-name="Polygon 50"
                  transform="translate(16 8) rotate(180)"
                  fill="none"
                >
                  <path d="M8,0l8,8H0Z" stroke="none" />
                  <path
                    d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                    stroke="none"
                    fill="#fff"
                  />
                </g>
              </svg>
              <svg
                className="up-arrow"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="8"
                viewBox="0 0 16 8"
                style={{ marginLeft: "8px" }}
              >
                <path
                  id="Polygon_51"
                  data-name="Polygon 51"
                  d="M8,0l8,8H0Z"
                  fill="#fff"
                />
              </svg>
            </Button>
          </Dropdown>
        </div>
      </div>
    );
  }
}

export default ViewInVfCatalogButton;
