import React, { Component } from "react";
import { Tabs } from "antd";

import "./index.scss";
import Salesgraph from "./Sales";
import Revenuegraph from "./Revenue";

const { TabPane } = Tabs;

class Graph extends Component {
  state = {
    activeKey: "store_order_stats",
    loading: false,
  };

  changeFilter(filter) {
    this.setState({
      ...filter,
    });
  }

  render() {
    return (
      <div className="overall_progress">
        {/* <h1>Overall Progress Plot</h1> */}
        <Tabs
          value={this.state.activeKey}
          onChange={(val) => this.changeFilter({ activeKey: val })}
        >
          <TabPane tab="Sales" key="store_order_stats">
            <Salesgraph {...this.props} sales={this.props.sales} />
          </TabPane>
          <TabPane tab="Revenue" key="shipment_stats">
            <Revenuegraph {...this.props} revenue={this.props.revenue} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default Graph;
