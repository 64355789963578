import React, { Component } from "react";
import "./index.scss";
import OtpVerifyContainer from "./OtpVerifyContainer";

class RightContainer extends Component {
  state = {
    email: "",
    password: "",
  };
  render() {
    return (
      <div className="right-container-signup1">
        <OtpVerifyContainer
          activeKey={this.state.activeKey}
          email={this.state.email}
          password={this.state.password}
          {...this.props}
        />
      </div>
    );
  }
}

export default RightContainer;
