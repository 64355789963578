import React, { Component } from "react";
import "./index.scss";
import {
  Row,
  Form,
  Icon,
  Input,
  Button,
  Card,
  Checkbox,
  message,
  Switch,
  Alert,
} from "antd";
import { connect } from "react-redux";
import axios from "axios";
import qs from "qs";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";

import { ReactComponent as FacbookLogo } from "../../../../assets/images/icons/facebook.svg";
import { ReactComponent as GoogleLogo } from "../../../../assets/images/icons/googlelogo.svg";
import analytics from "../../../../analytics";
import { signIn } from "../../../../store/actions/authActions";
import { ReactComponent as VfulfillLogo } from "../../../../assets/images/icons/vFULLfillLogo.svg";
class SignInSection extends Component {
  state = {
    email: "",

    loading: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.signIn({ email: values.email, password: values.password });
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { auth, authError } = this.props;
    return (
      <div className="right-card-signincontainer">
        <div class="logoRightContainer">
          <VfulfillLogo />
        </div>
        <div className="heading-bar">
          <div className="main-heading">Welcome Back</div>
          <div className="sub-heading">Log In to access your dashboard</div>
        </div>
        <Form onSubmit={this.handleSubmit} className="login-form" noValidate>
          <div className="flex-from">
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    type: "email",
                    message: "Please enter a valid Email Id!",
                  },
                ],
              })(
                <Input
                  className="input-signup23"
                  type="email"
                  placeholder="Your Email Id"
                  onChange={(e) => {
                    this.setState({
                      email: e.target.value,
                    });
                  }}
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("password", {
                rules: [
                  { required: true, message: "Please enter your Password!" },
                ],
              })(
                <Input.Password
                  className="input-signup22"
                  type="password"
                  placeholder="Your Password"
                />
              )}
            </Form.Item>
            {authError && (
              <Alert message={authError} type="error" className="alert-error" />
            )}
            <Form.Item>
              <div
                className="flex-row forgot-remember-parent"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "390px",
                }}
              >
                {getFieldDecorator("remember", {
                  valuePropName: "checked",
                  initialValue: true,
                })(<Checkbox>Remember me</Checkbox>)}
                <Button
                  onClick={() => {
                    this.props.getEmail(this.state.email);
                    this.props.changeTab();
                  }}
                  className="forgetBtn"
                >
                  Forgot password?
                </Button>
              </div>
              <Button type="primary" htmlType="submit" className="submitbutton">
                Log in
              </Button>
              <div className="signup-line">
                Don’t have an account?{" "}
                <Link to="/signup" className="login-form-signup-here">
                  Sign Up Here
                </Link>
              </div>
              {/* <a
                className="facebookbutton"
                href={process.env.REACT_APP_API_URL + "/login/fb"}
                onClick={() => {
                  analytics.track("Facebook Signup Clicked");
                }}
              >
                Log In with{" "}
                <span className="facebookcontainer">
                  <FacbookLogo />
                </span>{" "}
                Facebook
              </a>
              <a
                className="signupbutton"
                href={process.env.REACT_APP_API_URL + "/auth/googleLogin"}
                onClick={() => {
                  analytics.track("Google Signup Clicked");
                }}
              >
                Log In with{" "}
                <span className="googlecontainer">
                  <GoogleLogo />
                </span>{" "}
                Google
              </a> */}
            </Form.Item>
          </div>
        </Form>
        <div className="login-page-footer">
          <Row type="flex">
            {/* <p>Copyright &copy; {new Date().getFullYear()} vFulfill</p> */}
            <span className="contactus-line">
              Need help? We are just a click away.{" "}
              <a
                href="https://help.vfulfill.io/portal/en/newticket?departmentId=244206000011742048&layoutId=244206000011744275"
                target="_blank"
              >
                Contact Us
              </a>
            </span>
          </Row>
          {/* <Row type="flex">
            <ul className="footer-links">
              <li>
                <a href="https://www.vfulfill.io/legals/terms">Terms</a>
              </li>
              <li>
                <a href="https://www.vfulfill.io/legals/privacy">Privacy</a>
              </li>
              <li>
                <a href="https://help.vfulfill.io">Help</a>
              </li>
            </ul>
          </Row> */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    authError: state.auth.authError,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SignInSection));
