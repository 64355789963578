import React, { Component } from "react";
import "./index.scss";

// import { Select, DatePicker } from "antd";

import Globalsearch from "../../Common/Globalsearch";

class Usersettingnav extends Component {
  state = {
    userlistbtn: true,
    rolebtn: false,
  };

  render() {
    return (
      <div className="usersettingnav fullorder_data">
        <div className="settingnav">
          <div className="ordfill">
            <div className="ordfillwrap">
              <div className="left" style={{ marginLeft: "7px" }}>
                <div
                  style={{
                    color: "#000",
                    fontSize: "12px",
                    letterSpacing: "0.032em",
                    lineHeight: "24px",
                  }}
                >
                  Manage Users
                </div>
              </div>
            </div>
            <div className="secondwrap">
              <div className="filtertab">
                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-user-underline-animation "
                      : this.state.userlistbtn
                      ? "tab  hover-user-underline-animation-click"
                      : "tab hover-user-underline-animation"
                  }
                >
                  <button
                    className={this.state.userlistbtn ? "active" : ""}
                    value=""
                    onClick={() => {
                      this.setState(
                        {
                          userlistbtn: true,
                          rolebtn: false,
                        },
                        () =>
                          this.props.manageuserstoggle({
                            userlistbtn: true,
                            rolebtn: false,
                          })
                      );
                      this.props.fetch();
                    }}
                  >
                    User’s List
                  </button>

                  <div
                    className={` ${
                      this.state.userlistbtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.users && this.props.users.length
                      ? this.props.users.length
                      : 0}
                  </div>
                </div>

                {/* Delivered */}

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-user-underline-animation "
                      : this.state.rolebtn
                      ? "tab  hover-user-underline-animation-click"
                      : "tab hover-user-underline-animation"
                  }
                >
                  <button
                    className={this.state.rolebtn ? "active" : ""}
                    value="delivered"
                    onClick={() => {
                      this.setState(
                        {
                          userlistbtn: false,
                          rolebtn: true,
                        },
                        () =>
                          this.props.manageuserstoggle({
                            userlistbtn: false,
                            rolebtn: true,
                          })
                      );
                      this.props.getRoles();
                    }}
                  >
                    Roles
                  </button>

                  <div
                    className={` ${
                      this.state.rolebtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.roles && this.props.roles.length
                      ? this.props.roles.length
                      : 0}
                  </div>
                </div>

                {/* Returned */}
              </div>

              <div className="download_csv">
                {this.state.userlistbtn ? (
                  <button
                    className="clickable"
                    style={{
                      width: "120px",
                    }}
                    onClick={() => {
                      this.props.changeState({
                        modalVisible: true,
                        modalType: "add_modal",
                      });
                    }}
                  >
                    Add New User
                  </button>
                ) : (
                  <button
                    className="clickable"
                    style={{
                      width: "120px",
                    }}
                    onClick={() => this.props.togglePermissionsDrawer()}
                  >
                    + Add New Role
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_data">
          <div className="Globalsearch">
            {this.state.userlistbtn ? (
              <Globalsearch
                data={this.props.filters}
                change={(value) =>
                  this.props.changeHandler({ search: value.target.value })
                }
                searchFunc={(value) =>
                  this.props.changeFilter({ search: value })
                }
                class={"five-lg"}
                placeholder={"Search by User Name or Email Id"}
              />
            ) : (
              <Globalsearch
                data={this.props.filters2}
                change={(value) =>
                  this.props.changeHandler2({ search: value.target.value })
                }
                searchFunc={(value) =>
                  this.props.changeFilter2({ search: value })
                }
                class={"five-lg"}
                placeholder={"Search by User Role"}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Usersettingnav;
