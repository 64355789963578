import React, { Component } from "react";
import { Input, Select, Card, Button, message, Icon, Alert } from "antd";
import { ISDCodes } from "../../../../Helpers/ISD";
import { ReactComponent as editican } from "../../../../assets/images/icons/editican.svg";
import { ReactComponent as rightican } from "../../../../assets/images/icons/right.svg";
import SupportEmail from "./SupportEmailModal";
import SupportPhoneNumber from "./SupportPhoneNoModal";
import axios from "axios";
import "./index.scss";
import ReactPhoneInput from "react-phone-input-2";

const { Option } = Select;

class ChannelBranding extends Component {
  state = {
    value: "",
    disable: true,
    loading: false,
    emailVerifiedmsg: false,
    emailverified: 0,
    phoneverified: 0,
    verifiedEmailId: "",
    verifiedcontact: false,
    verifiedPhoneNO: "",
    chnagenumber: false,
    BrandName:
      !!this.props.brandSettings && !!this.props.brandSettings.brand_name
        ? this.props.brandSettings.brand_name
        : "Not Available",

    updateEmailModal: {
      supportemail:
        !!this.props.brandSettings &&
        !!this.props.brandSettings.support_details &&
        !!this.props.brandSettings.support_details.support_email
          ? this.props.brandSettings.support_details.support_email
          : "",
    },

    supportNumber2: {
      contactno:
        !!this.props.brandSettings &&
        !!this.props.brandSettings.support_details &&
        !!this.props.brandSettings.support_details.support_phone &&
        this.props.brandSettings.support_details.support_phone.number
          ? this.props.brandSettings.support_details.support_phone.number
          : "",
      prefix_1:
        !!this.props.brandSettings &&
        !!this.props.brandSettings.support_details &&
        !!this.props.brandSettings.support_details.support_phone &&
        this.props.brandSettings.support_details.support_phone.code
          ? this.props.brandSettings.support_details.support_phone.code
          : "91",
    },

    SupportUrl:
      !!this.props.brandSettings &&
      !!this.props.brandSettings.support_details &&
      !!this.props.brandSettings.support_details.support_url
        ? this.props.brandSettings.support_details.support_url
        : "",

    emailotp: {
      emailotp1: "",
      emailotp2: "",
      emailotp3: "",
      emailotp4: "",
      emailotp5: "",
      emailotp6: "",
    },
    phoneotp: {
      phoneotp1: "",
      phoneotp2: "",
      phoneotp3: "",
      phoneotp4: "",
      phoneotp5: "",
      phoneotp6: "",
    },
    supportEmail: {
      visible: false,
    },
    supportNumber: {
      visible: false,
    },
    brandsetting: false,
  };

  // paste function

  pasteFunc(ev) {
    const clip = ev.clipboardData.getData("text"); // Get clipboard data
    const pin = clip.replace(/\s/g, ""); // Sanitize string
    const ch = [...pin]; // Create array of chars

    if (ch.length === 6) {
      this.setState({
        phoneotp: {
          phoneotp1: ch[0],
          phoneotp2: ch[1],
          phoneotp3: ch[2],
          phoneotp4: ch[3],
          phoneotp5: ch[4],
          phoneotp6: ch[5],
        },
      });
    }
  }

  pasteFuncEmail(ev) {
    const clip = ev.clipboardData.getData("text"); // Get clipboard data
    const pin = clip.replace(/\s/g, ""); // Sanitize string
    const ch = [...pin]; // Create array of chars

    if (ch.length === 6) {
      this.setState({
        emailotp: {
          emailotp1: ch[0],
          emailotp2: ch[1],
          emailotp3: ch[2],
          emailotp4: ch[3],
          emailotp5: ch[4],
          emailotp6: ch[5],
        },
      });
    }
  }

  // start Email modal api and function

  handleEmailChnage2(value) {
    this.setState({
      updateEmailModal: {
        ...this.state.updateEmailModal,
        supportemail: value,
      },
    });
  }

  previousStateEmail() {
    this.setState(
      {
        updateEmailModal: {
          ...this.state.updateEmailModal,
          supportemail: this.props.brandSettings.support_details.support_email,
        },
      },
      () => this.props.chnageEmial2()
    );
  }

  cancelToggleSupportModal() {
    this.setState(
      {
        supportEmail: {
          visible: !this.state.supportEmail.visible,
        },
      },
      () => this.previousStateEmail()
    );
  }

  toggleSupportModal() {
    this.setState({
      supportEmail: {
        visible: !this.state.supportEmail.visible,
      },
    });
  }

  otpChnageEmail(field, value) {
    this.setState({
      emailotp: {
        ...this.state.emailotp,
        [field]: value,
      },
    });
  }

  verifyEmailContinue = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/verify_otp",
          method: "post",
          withCredentials: true,
          data: {
            location: "support",
            type: "email",
            pageName: "brandsetting",
            storeid: this.props.storeid,
            email: {
              email: this.state.updateEmailModal.supportemail,
              otp: Number(
                this.state.emailotp.emailotp1 +
                  this.state.emailotp.emailotp2 +
                  this.state.emailotp.emailotp3 +
                  this.state.emailotp.emailotp4 +
                  this.state.emailotp.emailotp5 +
                  this.state.emailotp.emailotp6
              ),
            },
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                emailverified: 1,
                updateEmailModal: {
                  ...this.state.updateEmailModal,
                },
              });
              message.success(res.data.msg);
              this.toggleSupportModal();
              this.props.chnageEmailtoggle();
              this.props.loadSettting();
            } else {
              this.setState({
                loading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
            message.error(e.message);
          });
      }
    );
  };

  handleEmailChange(value) {
    if (value === "") {
      this.setState({
        updateEmailModal: {
          ...this.state.updateEmailModal,
          supportemail: value,
        },
      });
    } else {
      if (value === this.state.verifiedEmailId || this.state.emailVerifiedmsg) {
        if (value === this.state.verifiedEmailId) {
          this.setState({ emailVerifiedmsg: true });
        } else {
          this.setState({ emailVerifiedmsg: false });
        }
      } else {
        this.setState({
          updateEmailModal: {
            ...this.state.updateEmailModal,
            supportemail: value,
          },
        });
      }
    }
  }

  updateEmail() {
    if (
      this.state.updateEmailModal.supportemail.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      this.setState(
        {
          loading: true,
        },
        () => {
          axios({
            url: process.env.REACT_APP_API_URL + "/user/generateotp",
            method: "post",
            data: {
              type: "email",
              status: "new",
              location: "support",
              email: this.state.updateEmailModal.supportemail,
              change: 1,
              pageName: "brandsetting",
              storeid: this.props.storeid,
            },
            withCredentials: true,
          })
            .then((res) => {
              if (res.data.success === 1) {
                this.setState({
                  updateEmailModal: {
                    ...this.state.updateEmailModal,
                  },
                });

                message.success("Email updated successfully.");
                this.toggleChnageNumber();
              } else {
                this.setState({
                  updateEmailModal: {
                    ...this.state.updateEmailModal,
                  },
                  loading: false,
                });
                message.error(res.data.msg);
              }
            })
            .catch((e) => {
              message.error(e.message);
              this.setState({
                updateEmailModal: {
                  ...this.state.updateEmailModal,
                },
                loading: false,
              });
            });
        }
      );
    }
  }

  VerifyEmail() {
    if (
      this.state.updateEmailModal.supportemail.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      this.setState(
        {
          loading: true,
        },
        () => {
          axios({
            url: process.env.REACT_APP_API_URL + "/user/generateotp",
            method: "post",
            withCredentials: true,
            data: {
              type: "email",
              location: "support",
              status: "new",
              email: this.state.updateEmailModal.supportemail,
              pageName: "brandsetting",
              storeid: this.props.storeid,
            },
          })
            .then((res) => {
              if (res.data.success === 2) {
                message.success(res.data.msg);
                this.setState({
                  emailVerifiedmsg: true,
                  verifiedEmailId: this.state.updateEmailModal.supportemail,
                  emailverified: 1,
                });
                this.props.chnageEmailtoggle();

                return;
              } else {
                if (res.data.success === 1) {
                  this.setState({
                    loading: false,
                  });
                  this.toggleSupportModal();

                  message.success(res.data.msg);
                } else {
                  message.error(res.data.msg);
                }
              }
            })
            .catch((e) => {
              this.setState({
                loading: false,
              });
              message.error(e.msg);
            });
        }
      );
    } else {
      message.error("Please enter a valid Email Address.");
    }
  }

  // End Email modal api and function

  // start phone Modal Changes

  changePreviousState() {
    this.setState(
      {
        supportNumber2: {
          prefix_1: this.props.brandSettings.support_details.support_phone.code,
          contactno: this.props.brandSettings.support_details.support_phone
            .number,
        },
      },
      () => this.props.ChnageNumber2()
    );
  }

  changePrefixState = (field, value) => {
    this.setState({
      supportNumber2: {
        ...this.state.supportNumber2,
        [field]: value,
      },
    });
  };

  toggleChnageNumber() {
    this.setState({
      chnagenumber: !this.state.chnagenumber,
    });
  }

  cancelToggleSupportNumberModal() {
    this.setState(
      {
        supportNumber: {
          visible: !this.state.supportNumber.visible,
        },
      },
      () => this.changePreviousState()
    );
  }

  toggleSupportNumberModal() {
    this.setState({
      supportNumber: {
        visible: !this.state.supportNumber.visible,
      },
    });
  }

  handleSupportPhoneChange(value) {
    if (value === "") {
      if (value.length === 0 && value === "") {
        this.setState({
          supportNumber2: {
            ...this.state.supportNumber2,
            contactno: "",
          },
        });
      } else if (value.length <= 10 && parseInt(value)) {
        this.setState({
          supportNumber2: {
            ...this.state.supportNumber2,
            contactno: value,
          },
        });
      }
    } else {
      if (value === this.state.verifiedPhoneNO || this.state.verifiedcontact) {
        if (value === this.state.verifiedPhoneNO) {
          this.setState({ verifiedcontact: true });
        } else {
          this.setState({ verifiedcontact: false });
        }
      } else {
        if (value.length <= 10 && parseInt(value)) {
          this.setState({
            supportNumber2: {
              ...this.state.supportNumber2,
              contactno: value,
            },
          });
        }
      }
    }
  }

  // handleContactChange(value) {
  //   if (value.length === 0 && value === "") {
  //     this.setState({
  //       supportNumber2: {
  //         ...this.state.supportNumber2,
  //         contactno: "",
  //       },
  //     });
  //   } else {
  //     if (value.length <= 10 && parseInt(value)) {
  //       this.setState({
  //         supportNumber2: {
  //           ...this.state.supportNumber2,
  //           contactno: value,
  //         },
  //       });
  //     }
  //   }
  // }

  // new

  handleContactChange(ob) {
    let onlyNumber = ob.value
      .replace(/[^0-9]+/g, "")
      .slice(
        !!Object.entries(ob.extraVal).length ? ob.extraVal.dialCode.length : 0
      );

    if (ob.value === 0 && ob.value === "") {
      this.setState({
        supportNumber2: {
          ...this.state.supportNumber2,
          contactno: "",
          prefix_1: "",
        },
      });
    } else if (parseInt(ob.value)) {
      if (
        onlyNumber === this.state.verifiedPhoneNO ||
        this.state.verifiedcontact
      ) {
        if (onlyNumber === this.state.verifiedPhoneNO) {
          this.setState({ verifiedcontact: true });
        } else {
          this.setState({ verifiedcontact: false });
        }
      }

      this.setState({
        contactno: onlyNumber,
        prefix_1: ob.extraVal.dialCode,
      });

      this.setState({
        supportNumber2: {
          ...this.state.supportNumber2,
          contactno: onlyNumber,
          prefix_1: ob.extraVal.dialCode,
        },
      });
    }
  }

  otpChnageNumber(field, value) {
    this.setState({
      phoneotp: {
        ...this.state.phoneotp,
        [field]: value,
      },
    });
  }

  verifyPhoneContinue = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/verify_otp",
          method: "post",
          withCredentials: true,
          data: {
            location: "support",
            type: "mobile",
            pageName: "brandsetting",
            storeid: this.props.storeid,
            mobile: {
              number: this.state.supportNumber2.contactno,
              code: "91",
              otp: Number(
                this.state.phoneotp.phoneotp1 +
                  this.state.phoneotp.phoneotp2 +
                  this.state.phoneotp.phoneotp3 +
                  this.state.phoneotp.phoneotp4 +
                  this.state.phoneotp.phoneotp5 +
                  this.state.phoneotp.phoneotp6
              ),
            },
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                phoneverified: 1,
                supportNumber2: {
                  ...this.state.supportNumber2,
                },
              });
              message.success(res.data.msg);
              this.props.ChnageNumbertoggle();
              this.toggleSupportNumberModal();
              this.props.loadSettting();
            } else {
              this.setState({
                loading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
            message.error(e.message);
          });
      }
    );
  };

  updatePhonenumber() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/generateotp",
          method: "post",
          data: {
            type: "mobile",
            status: "new",
            location: "support",
            pageName: "brandsetting",
            storeid: this.props.storeid,
            change: 1,
            mobile: {
              number: this.state.supportNumber2.contactno,
              code: "91",
            },
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                supportNumber2: {
                  ...this.state.supportNumber2,
                },
              });

              message.success("Phone number has been updated!!");
              this.toggleChnageNumber();
            } else {
              this.setState({
                ...this.state.supportNumber2.contactno,
                loading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
            this.setState({
              ...this.state.supportNumber2.contactno,
              loading: false,
            });
          });
      }
    );
  }

  VerifyPhone() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/generateotp",
          method: "post",
          withCredentials: true,
          data: {
            type: "mobile",
            location: "support",
            status: "new",
            pageName: "brandsetting",
            storeid: this.props.storeid,
            mobile: {
              number: this.state.supportNumber2.contactno,
              code: this.state.supportNumber2.prefix_1,
            },
          },
        })
          .then((res) => {
            if (res.data.success === 2) {
              message.success(res.data.msg);
              this.setState({
                verifiedcontact: true,
                verifiedPhoneNO: this.state.supportNumber2.contactno,
                phoneverified: 1,
              });
              this.props.ChnageNumbertoggle();
              return;
            } else {
              if (res.data.success === 1) {
                this.setState({
                  loading: false,
                });
                this.toggleSupportNumberModal();
                message.success(res.data.msg);
              } else {
                message.error(res.data.msg);
              }
            }
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
            message.error(e.msg);
          });
      }
    );
  }

  // End phone Modal Changes

  // Start Url changes
  handlechangeurl(value) {
    this.setState({
      SupportUrl: value,
    });
  }

  updateSupporturl() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/store/save_brand_settings",
          method: "post",
          data: {
            type: "support_url",
            support_details: {
              support_url: this.state.SupportUrl,
            },
            storeid: this.props.storeid,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                SupportUrl: this.state.SupportUrl,
              });
              message.success("Support URL has been updated!");
              this.props.changeUrltoggle();
            } else {
              this.setState({
                SupportUrl: this.state.SupportUrl,
                loading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
            this.setState({
              SupportUrl: this.state.SupportUrl,
              loading: false,
            });
          });
      }
    );
  }

  // End Url changes

  // start brand logo function
  brandtoggle(value) {
    this.setState({
      BrandName: value,
    });
  }

  toggleBrandsetting() {
    this.setState({
      brandsetting: !this.state.brandsetting,
    });
  }

  uploadImageUrl = () => {
    this.setState({ loading: true });
    var formData = new FormData();
    formData.append(
      this.props.imagePreviewUrl && !!this.props.imagePreviewUrl.length
        ? "media[]"
        : "brand_logo",
      this.props.imagePreviewUrl && !!this.props.imagePreviewUrl.length
        ? this.props.files
        : this.props.brandSettings.brand_logo
    );
    formData.append("storeid", this.props.storeid);
    formData.append("brand_name", this.state.BrandName);
    formData.append("type", "brand_setting");
    axios({
      url: process.env.REACT_APP_API_URL + "/store/save_brand_settings",
      method: "post",
      withCredentials: true,
      data: formData,
    })
      .then((res) => {
        if (res.data.success === 1) {
          message.success("Image Upload Sucessfully");
          this.toggleBrandsetting();
          this.props.loadSettting();
          this.setState({
            loading: false,
          });
        } else {
          message.error(res.data.msg);
          this.setState({
            loading: false,
          });
        }
      })
      .catch((e) => {
        message.error(e.message);
        this.setState({
          loading: false,
        });
      });
  };

  numberValueHandler(dialcode, number) {
    return `${dialcode}` + `${number}`;
  }

  // End brand logo Toggle

  render() {
    return (
      <>
        <Card className="channel_branding_card">
          <p className="brand-setting-txt">Brand Settings</p>
          <div className="middle_data">
            <div className="brand_name">
              <p className="brand_name_label">Brand Name</p>

              {this.state.brandsetting ? (
                <div>
                  {" "}
                  <Input
                    type="text"
                    className={
                      this.state.brandsetting ? "brand_input2" : "brand_input"
                    }
                    value={this.state.BrandName}
                    onChange={(e) => this.brandtoggle(e.target.value)}
                  />
                  <div className="brand_logo">
                    <p className="brand_name_label">
                      Brand Logo (Recommended size: 250px X 250px)
                    </p>

                    <div className="brand_logo_uploder">
                      <div className="brand_logo_ican">
                        <div
                          className="drage-image"
                          onClick={() => this.fileInput.click()}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                          >
                            <g
                              id="Group_3680"
                              data-name="Group 3680"
                              transform="translate(-936 -1024)"
                            >
                              <g
                                id="Group_2193"
                                data-name="Group 2193"
                                transform="translate(936 1044.009)"
                              >
                                <g id="Group_2192" data-name="Group 2192">
                                  <path
                                    id="Path_52"
                                    data-name="Path 52"
                                    d="M47.835,307.2a1.215,1.215,0,0,0-1.231,1.2v7.195a1.215,1.215,0,0,1-1.231,1.2H20.758a1.215,1.215,0,0,1-1.231-1.2V308.4a1.231,1.231,0,0,0-2.462,0v7.195a3.646,3.646,0,0,0,3.692,3.6H45.374a3.646,3.646,0,0,0,3.692-3.6V308.4A1.215,1.215,0,0,0,47.835,307.2Z"
                                    transform="translate(-17.066 -307.2)"
                                    fill="#898989"
                                  />
                                </g>
                              </g>
                              <g
                                id="Group_2195"
                                data-name="Group 2195"
                                transform="translate(944.006 1024)"
                              >
                                <g
                                  id="Group_2194"
                                  data-name="Group 2194"
                                  transform="translate(0)"
                                >
                                  <path
                                    id="Path_53"
                                    data-name="Path 53"
                                    d="M152.323,6.7a1.673,1.673,0,0,1-1.855,0l-4.4-3.29V22.983c0,.552-.6,1-1.335,1s-1.335-.447-1.335-1V3.412L139,6.7a1.668,1.668,0,0,1-1.888-.025.83.83,0,0,1,0-1.388l6.674-5a1.668,1.668,0,0,1,1.888,0l0,0,6.674,5A.834.834,0,0,1,152.323,6.7Z"
                                    transform="translate(-136.742 0)"
                                    fill="#898989"
                                  />
                                </g>
                              </g>
                            </g>
                          </svg>

                          <p
                            style={{
                              marginTop: "8px",
                              fontSize: "8px",
                              marginBottom: "0px",
                              lineHeight: "16px",
                              textAlign: "center",
                            }}
                          >
                            Click To Upload
                          </p>
                        </div>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          ref={(fileInput) => (this.fileInput = fileInput)}
                          accept="image/png, image/jpeg, image/webp"
                          onChange={(e) => this.props.handleImageChange(e)}
                        />
                      </div>

                      <div className="images_show">
                        {this.props.imagePreviewUrl &&
                        !!this.props.imagePreviewUrl.length ? (
                          <div>{this.props.buildImgTag()}</div>
                        ) : !!this.props.brandSettings &&
                          !!this.props.brandSettings.brand_logo &&
                          !!this.props.brandSettings.brand_logo.length ? (
                          <ul
                            style={{
                              width: "100%",
                              overflowX: "auto",
                              listStyleType: "none",
                              padding: 0,
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            <li
                              key="xyz"
                              style={{
                                width: 135,
                                height: 110,
                                marginRight: 24,
                                position: "relative",
                                marginBottom: 24,
                              }}
                            >
                              <img
                                src={this.props.brandSettings.brand_logo}
                                alt="Brandlogo"
                                onClick={() =>
                                  this.props.toggleZoomModal(
                                    this.props.brandSettings.brand_logo,
                                    "image"
                                  )
                                }
                                style={{
                                  width: 135,
                                  height: 110,
                                  objectFit: "contain",
                                  borderRadius: 2,
                                  border: "1px solid #D0D0D0",
                                  cursor: "pointer",
                                }}
                              />
                            </li>
                          </ul>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <p
                    className="brand_input_name"
                    style={{ marginBottom: "32px" }}
                  >
                    {this.state.BrandName}
                  </p>
                  <ul
                    style={{
                      width: "100%",
                      overflowX: "auto",
                      listStyleType: "none",
                      padding: 0,
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <li
                      key="xyz"
                      style={{
                        width: 135,
                        height: 110,
                        marginRight: 24,
                        position: "relative",
                        marginBottom: 24,
                      }}
                    >
                      <img
                        src={
                          !!this.props.brandSettings.brand_logo &&
                          !!this.props.brandSettings.brand_logo.length
                            ? this.props.brandSettings.brand_logo
                            : "https://d24fzeiqvvundc.cloudfront.net/images/global/dashboard/no-image-found-placeholder.png"
                        }
                        alt="Not found"
                        onClick={() =>
                          this.props.toggleZoomModal(
                            this.props.brandSettings.brand_logo,
                            "image"
                          )
                        }
                        style={{
                          width: 135,
                          height: 110,
                          objectFit: "contain",
                          borderRadius: 2,
                          border: "1px solid #D0D0D0",
                          cursor: "pointer",
                        }}
                      />
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {this.state.brandsetting ? (
              <div>
                {" "}
                <Button
                  className="brand_save clickable-dropdown"
                  loading={this.state.loading}
                  onClick={() => this.uploadImageUrl()}
                >
                  Save
                </Button>{" "}
                <Button
                  className="cancel_btn"
                  onClick={() => this.toggleBrandsetting()}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <Button
                className="brand_Edit clickable"
                onClick={() => this.toggleBrandsetting()}
              >
                Edit Brand Setting
              </Button>
            )}
          </div>

          <div className="bottom_data">
            <p className="brand-setting-txt">Support Settings</p>
            <div className="alert-wrap-reporting">
              <Alert
                style={{ marginBottom: "40px" }}
                message="Important Information"
                description="We recommend you to share Support email or Support phone number since these details are printed in your order invoice. This will help create better support system for your end customer."
                type="info"
                showIcon
              />
              <span
                className="ex-ic"
                style={{
                  width: "24px",
                  height: "24px",
                  fontSize: "24px",
                  lineHeight: "24px",
                  display: "inline-block",
                  color: "#0068FF",
                }}
              >
                <Icon type="exclamation-circle" theme="filled" />
              </span>
            </div>
            <div className="footer_data">
              <div className="change_email">
                <div className="save_chnage">
                  <p className="suppport_txt">Support Email Address</p>
                  {this.props.changeemail ? (
                    this.state.emailVerifiedmsg ? (
                      <p className="already-verify-email">
                        <span className="save-ican">
                          <Icon component={rightican} />
                        </span>
                        Already verified
                      </p>
                    ) : this.props.emailverified === 1 ? null : (
                      <>
                        <p>
                          <a
                            href
                            className={
                              !!this.state.updateEmailModal.supportemail.length
                                ? "verify-email2"
                                : "verify-email"
                            }
                            onClick={() => this.VerifyEmail()}
                          >
                            Verify Email Address
                          </a>

                          <a
                            className="cancel-verify-email"
                            href
                            onClick={() => this.props.chnageEmailtoggle()}
                          >
                            Cancel
                          </a>
                        </p>
                      </>
                    )
                  ) : (
                    <p>
                      <button
                        className="change-number-txt"
                        onClick={() => this.props.chnageEmailtoggle()}
                      >
                        <span className="edit-ican">
                          <Icon component={editican} />
                        </span>
                        {this.state.updateEmailModal &&
                        !!this.state.updateEmailModal.supportemail &&
                        !!this.state.updateEmailModal.supportemail.length
                          ? "Change Email Address"
                          : "Add Email Address"}
                      </button>
                    </p>
                  )}
                </div>

                <div className="input-number-data">
                  <p
                    className={
                      this.props.changeemail
                        ? "input-email-txt2"
                        : "input-email-txt"
                    }
                  >
                    {this.props.changeemail ? (
                      <Input
                        type="text"
                        placeholder="Enter text here"
                        value={this.state.updateEmailModal.supportemail}
                        onChange={(e) => this.handleEmailChange(e.target.value)}
                        // disabled={this.state.emailverified === 1 ? true : false}
                      />
                    ) : (
                      <p className="disable-phonenumber">
                        {this.state.updateEmailModal &&
                        !!this.state.updateEmailModal.supportemail &&
                        !!this.state.updateEmailModal.supportemail.length
                          ? this.state.updateEmailModal.supportemail
                          : "Not Available"}
                      </p>
                    )}
                  </p>
                </div>
              </div>

              <div className="change_phone_number">
                <div className="save_chnage">
                  <p className="suppport_txt">Phone Number</p>

                  {this.props.chnagenumber ? (
                    this.state.verifiedcontact ? (
                      <p className="already-verify-phone">
                        <span className="save-ican">
                          <Icon component={rightican} />
                        </span>
                        Already verified
                      </p>
                    ) : this.props.phoneverified === 1 ? null : (
                      <p>
                        <a
                          href
                          className={
                            this.state.supportNumber2 &&
                            !!this.state.supportNumber2.contactno.length &&
                            this.state.supportNumber2.contactno.length === 10
                              ? "verify-phone2"
                              : "verify-phone"
                          }
                          onClick={() => this.VerifyPhone()}
                        >
                          Verify Phone Number
                        </a>

                        <a
                          className="cancel-verify-Phone"
                          href
                          onClick={() => this.props.ChnageNumbertoggle()}
                        >
                          Cancel
                        </a>
                      </p>
                    )
                  ) : (
                    <p>
                      <button
                        className="change-number-txt"
                        onClick={() => this.props.ChnageNumbertoggle()}
                      >
                        <span className="edit-ican">
                          <Icon component={editican} />
                        </span>
                        {this.state.supportNumber2 &&
                        !!this.state.supportNumber2.contactno &&
                        !!this.state.supportNumber2.contactno.length
                          ? "Change Number"
                          : "Add Your Number"}
                      </button>
                    </p>
                  )}
                </div>

                {/* <div className="input-number-data">
                  <p className="ind-91">
                    {this.props.chnagenumber ? (
                      <Select
                        style={{ width: 100, marginTop: "7px" }}
                        value={this.state.supportNumber2.prefix_1}
                        // disabled={this.state.phoneverified === 1 ? true : false}
                        onChange={(val) =>
                          this.changePrefixState("prefix_1", val)
                        }
                        onDropdownVisibleChange={(val) =>
                          this.setState({ arrow: val })
                        }
                        suffixIcon={
                          <svg
                            style={{
                              transition: "all 0.5s",
                              transform: `${
                                this.state.arrow
                                  ? "rotateX(180deg)"
                                  : "rotateX(0deg)"
                              }`,
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="8"
                            viewBox="0 0 16 8"
                          >
                            <g
                              id="Polygon_49"
                              data-name="Polygon 49"
                              transform="translate(16 8) rotate(180)"
                              fill={`${this.state.arrow ? "#898989" : "none"}`}
                            >
                              <path d="M8,0l8,8H0Z" stroke="none" />
                              <path
                                d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                                stroke="none"
                                fill="#898989"
                              />
                            </g>
                          </svg>
                        }
                      >
                        {ISDCodes.map((isd) => (
                          <Option
                            key={`${isd.iso2.toUpperCase()} +${isd.dialCode}`}
                          >
                            {isd.iso2.toUpperCase()} +{isd.dialCode}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <p>{this.state.supportNumber2.prefix_1}</p>
                    )}
                  </p>

                  <p
                    className={
                      this.props.chnagenumber
                        ? "input-number-txt2"
                        : "input-number-txt"
                    }
                  >
                    {this.props.chnagenumber ? (
                      <Input
                        type="number"
                        placeholder="Enter Number"
                        value={this.state.supportNumber2.contactno}
                        onChange={(e) =>
                          this.handleSupportPhoneChange(e.target.value)
                        }
                        // disabled={this.state.phoneverified === 1 ? true : false}
                      />
                    ) : (
                      <p className="disable-phonenumber">
                        {this.state.supportNumber2 &&
                        !!this.state.supportNumber2.contactno &&
                        !!this.state.supportNumber2.contactno.length
                          ? this.state.supportNumber2.contactno
                          : "Not Available"}
                      </p>
                    )}
                  </p>
                </div> */}

                {/* new */}
                <p className="input-number-txt">
                  {this.props.chnagenumber ? (
                    <>
                      <ReactPhoneInput
                        placeholder="Enter phone number"
                        enableSearchField
                        // disabled={this.props.phoneverified === 1 ? true : false}
                        value={this.numberValueHandler(
                          this.state.supportNumber2.prefix_1,
                          this.state.supportNumber2.contactno
                        )}
                        onChange={(val, extra) =>
                          this.handleContactChange({
                            value: val,
                            extraVal: extra,
                          })
                        }
                      />
                    </>
                  ) : (
                    <ReactPhoneInput
                      placeholder="Enter phone number"
                      enableSearchField
                      disabled={true}
                      value={this.numberValueHandler(
                        this.state.supportNumber2.prefix_1,
                        this.state.supportNumber2.contactno
                      )}
                      onChange={(val, extra) =>
                        this.props.handleContactChange({
                          value: val,
                          extraVal: extra,
                        })
                      }
                    />
                  )}
                </p>
              </div>
            </div>

            {/* <div className="footer_data support_url">
              <div className="change_email">
                <div className="save_chnage">
                  <p className="suppport_txt">Support URL</p>
                  {this.props.ChangeUrl ? (
                    <p>
                      <button
                        className="save-number-txt"
                        onClick={() => this.updateSupporturl()}
                      >
                        <span className="save-ican">
                          <Icon component={rightican} />
                        </span>
                        Save URL
                      </button>

                      <button
                        className="cancel-url"
                        onClick={() => this.props.changeUrltoggle()}
                      >
                        Cancel
                      </button>
                    </p>
                  ) : (
                    <p>
                      <button
                        className="change-number-txt"
                        onClick={() => this.props.changeUrltoggle()}
                      >
                        <span className="edit-ican">
                          <Icon component={editican} />
                        </span>
                        {this.state.SupportUrl && !!this.state.SupportUrl.length
                          ? "Change URL"
                          : "Add URL"}
                      </button>
                    </p>
                  )}
                </div>

                <div className="input-number-data">
                  <p
                    className={
                      this.props.ChangeUrl
                        ? "input-email-txt"
                        : "input-email-txt2"
                    }
                  >
                    {this.props.ChangeUrl ? (
                      <Input
                        type="text"
                        placeholder="Enter a support url"
                        value={this.state.SupportUrl}
                        onChange={(e) => this.handlechangeurl(e.target.value)}
                      />
                    ) : (
                      <p className="disable-phonenumber">
                        {this.state.SupportUrl && !!this.state.SupportUrl.length
                          ? this.state.SupportUrl
                          : "Not available"}
                      </p>
                    )}
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </Card>
        <SupportEmail
          supportEmail2={this.state.updateEmailModal.supportemail}
          pasteFuncEmail={(e) => this.pasteFuncEmail(e)}
          visible={this.state.supportEmail.visible}
          emailotp={this.state.emailotp}
          updateEmail={() => this.updateEmail()}
          chnagenumber={this.state.chnagenumber}
          toggleChnageNumber={() => this.toggleChnageNumber()}
          otpChnageEmail={(field, value) => this.otpChnageEmail(field, value)}
          verifyEmailContinue={() => this.verifyEmailContinue()}
          handleEmailChnage={(value) => this.handleEmailChnage2(value)}
          onCancel={() => this.cancelToggleSupportModal()}
          storeid={this.props.storeid}
        />
        <SupportPhoneNumber
          visible={this.state.supportNumber.visible}
          pasteFunc={(e) => this.pasteFunc(e)}
          toggleSupportNumberModal={() => this.toggleSupportNumberModal()}
          onCancel={() => this.cancelToggleSupportNumberModal()}
          handleContactChange={(value) => this.handleContactChange(value)}
          supportNumber2={this.state.supportNumber2.contactno}
          chnagenumber={this.state.chnagenumber}
          toggleChnageNumber={() => this.toggleChnageNumber()}
          otpChnageNumber={(field, value) => this.otpChnageNumber(field, value)}
          phoneotp={this.state.phoneotp}
          verifyPhoneContinue={() => this.verifyPhoneContinue()}
          updatePhonenumber={() => this.updatePhonenumber()}
          prefix_1={this.state.supportNumber2.prefix_1}
          changePrefixState={(field, value) =>
            this.changePrefixState(field, value)
          }
          storeid={this.props.storeid}
        />
      </>
    );
  }
}

export default ChannelBranding;
