import React, { Component } from "react";
import { Tooltip } from "antd";
import {
  getCurrencySymbol,
  formatNumber,
} from "../../../../../Helpers/Pricing";
import "./index.scss";
import { SHOPIFY_NAME } from "../../../../../constants";

export class OrderPlacedV2 extends Component {
  render() {
    const transaction = this.props.transaction;
    return (
      <div
        className={`${
          this.props.storegeo === "global"
            ? "Transactions_head gb"
            : "Transactions_head"
        }`}
      >
        <div className="modal_headerdata">
          <div className="Vforderid_txt">
            <span> VFOrder ID : {transaction.transaction_data.vforderid}</span>
          </div>
          <div className="Header_name">
            <span style={{ marginRight: "8px" }}>
              Transaction ID : {""}
              <span>{transaction.transaction_vfid}</span>
            </span>
            <span
              style={{ borderRight: "2px solid #e3e3e3", marginRight: "8px" }}
            />
            <span>
              {`${SHOPIFY_NAME} Order Name:`}
              <span>
                {transaction.transaction_data.items &&
                transaction.transaction_data.items[0] &&
                transaction.transaction_data.items[0].orderid
                  ? transaction.transaction_data.items[0].orderid
                  : ""}
              </span>
            </span>
          </div>
        </div>

        <table className="Transactions-table">
          <thead>
            <tr>
              <th>Product Details</th>
              <th>Qty</th>

              {/* gst columns hide if store is global */}

              {this.props.storegeo === "global" ? null : (
                <th>Taxable Unit Price</th>
              )}

              {this.props.storegeo === "global" ? null : (
                <th>Taxable Amount</th>
              )}

              {this.props.storegeo === "global" ? null : <th>GST Amount</th>}

              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {transaction.transaction_data.items.map((item) =>
              item.bundle && item.bundle.length > 0 ? (
                item.bundle.map((bundleitem) => (
                  <tr key={bundleitem.vfsku}>
                    <td>
                      {
                        <div
                          className={`${
                            this.props.storegeo === "global"
                              ? "transaction_images gb"
                              : "transaction_images"
                          }`}
                        >
                          <div className="wraper">
                            {" "}
                            <img src={bundleitem.img} alt="" />{" "}
                            <div className="side">
                              {" "}
                              <p>
                                {" "}
                                <Tooltip
                                  title={bundleitem.aliname}
                                  placement="topLeft"
                                >
                                  {" "}
                                  {bundleitem && bundleitem.aliname
                                    ? bundleitem.aliname.substring(0, 16)
                                    : null}{" "}
                                </Tooltip>{" "}
                              </p>{" "}
                              <p
                                style={{
                                  margin: "0 0 0 0px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {" "}
                                {bundleitem && bundleitem.vfsku
                                  ? bundleitem.vfsku
                                  : null}{" "}
                                X{" "}
                                {bundleitem && bundleitem.quantity
                                  ? bundleitem.quantity
                                  : null}{" "}
                              </p>{" "}
                            </div>{" "}
                          </div>
                        </div>
                      }
                    </td>

                    <td>
                      {
                        <div className="transaction_quantity">
                          <p>
                            {bundleitem && bundleitem.quantity
                              ? bundleitem.quantity * item.quantity
                              : null}
                          </p>
                        </div>
                      }
                    </td>

                    {this.props.storegeo === "global" ? null : (
                      <td>
                        {
                          <div>
                            <p>
                              {getCurrencySymbol(
                                transaction.transaction_currency
                              )}{" "}
                              {
                                transaction.transaction_data.costs.pcosts[
                                  bundleitem.vfsku
                                ].product
                              }
                            </p>
                          </div>
                        }
                      </td>
                    )}

                    {this.props.storegeo === "global" ? null : (
                      <td>
                        {
                          <div className="transaction_Textable">
                            <p>
                              {getCurrencySymbol(
                                transaction.transaction_currency
                              )}{" "}
                              {transaction.transaction_data.costs.pcosts[
                                bundleitem.vfsku
                              ].product * bundleitem.quantity}
                            </p>
                          </div>
                        }
                      </td>
                    )}

                    {this.props.storegeo === "global" ? null : (
                      <td>
                        {
                          <div className="transaction_GST">
                            <p>
                              {getCurrencySymbol(
                                transaction.transaction_currency
                              )}{" "}
                              {formatNumber(transaction.transaction_data.costs.pcosts[
                                bundleitem.vfsku
                              ].gst * bundleitem.quantity,2)}
                            </p>
                          </div>
                        }
                      </td>
                    )}

                    <td>
                      {
                        <div className="transaction_Total">
                          <p
                            className={
                              transaction.transaction_type === "debit"
                                ? "red_text"
                                : "green_text"
                            }
                          >
                            {getCurrencySymbol(
                              transaction.transaction_currency
                            )}{" "}
                            {formatNumber(
                              transaction.transaction_data.costs.pcosts[
                                bundleitem.vfsku
                              ].total * bundleitem.quantity,
                              2
                            )}
                          </p>
                        </div>
                      }
                    </td>
                  </tr>
                ))
              ) : (
                <tr key={item.vfsku}>
                  <td>
                    {
                      <div
                        className={`${
                          this.props.storegeo === "global"
                            ? "transaction_images gb"
                            : "transaction_images"
                        }`}
                      >
                        <div className="wraper">
                          {" "}
                          <img src={item.img} alt="" />{" "}
                          <div className="side">
                            {" "}
                            <p>
                              {" "}
                              <Tooltip title={item.aliname} placement="topLeft">
                                {" "}
                                {item && item.aliname
                                  ? item.aliname.substring(0, 16)
                                  : null}{" "}
                              </Tooltip>{" "}
                            </p>{" "}
                            <p
                              style={{
                                margin: "0 0 0 0px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {" "}
                              {item && item.vfsku ? item.vfsku : null}
                              {/* X{" "}
                              {item && item.quantity ? item.quantity : null}{" "} */}
                            </p>{" "}
                          </div>{" "}
                        </div>
                      </div>
                    }
                  </td>

                  <td>
                    {
                      <div className="transaction_quantity">
                        <p>{item && item.quantity ? item.quantity : null}</p>
                      </div>
                    }
                  </td>

                  {this.props.storegeo === "global" ? null : (
                    <td>
                      {
                        <div>
                          <p>
                            {getCurrencySymbol(
                              transaction.transaction_currency
                            )}{" "}
                            {
                              transaction.transaction_data.costs.pcosts[
                                item.vfsku
                              ].product
                            }
                          </p>
                        </div>
                      }
                    </td>
                  )}

                  {this.props.storegeo === "global" ? null : (
                    <td>
                      {
                        <div className="transaction_Textable">
                          <p>
                            {getCurrencySymbol(
                              transaction.transaction_currency
                            )}{" "}
                            {transaction.transaction_data.costs.pcosts[
                              item.vfsku
                            ].product * item.quantity}
                          </p>
                        </div>
                      }
                    </td>
                  )}

                  {this.props.storegeo === "global" ? null : (
                    <td>
                      {
                        <div className="transaction_GST">
                          <p>
                            {getCurrencySymbol(
                              transaction.transaction_currency
                            )}{" "}
                            {formatNumber(transaction.transaction_data.costs.pcosts[
                              item.vfsku
                            ].gst * item.quantity,2)}
                          </p>
                        </div>
                      }
                    </td>
                  )}

                  <td>
                    {
                      <div className="transaction_Total">
                        <p
                          className={
                            transaction.transaction_type === "debit"
                              ? "red_text"
                              : "green_text"
                          }
                        >
                          {getCurrencySymbol(transaction.transaction_currency)}{" "}
                          {formatNumber(
                            transaction.transaction_data.costs.pcosts[
                              item.vfsku
                            ].total * item.quantity,
                            2
                          )}
                        </p>
                      </div>
                    }
                  </td>
                </tr>
              )
            )}
            <tr className="no-border">
              <td style={{ textAlign: "left" }}>Total</td>
              {this.props.storegeo === "global" ? null : <td />}
              <td />

              {this.props.storegeo === "global" ? null : (
                <td>
                  {" "}
                  <p className="blue-text">
                    {getCurrencySymbol(transaction.transaction_currency)}{" "}
                    {formatNumber(transaction.transaction_data.costs.base, 2)}
                  </p>
                </td>
              )}

              {this.props.storegeo === "global" ? null : (
                <td>
                  {" "}
                  <p className="blue-text">
                    {getCurrencySymbol(transaction.transaction_currency)}{" "}
                    {formatNumber(transaction.transaction_data.costs.gst, 2)}
                  </p>
                </td>
              )}

              <td>
                {" "}
                <p
                  className={
                    transaction.transaction_type === "debit"
                      ? "red_text"
                      : "green_text"
                  }
                  style={{ whiteSpace: "nowrap" }}
                >
                  {getCurrencySymbol(transaction.transaction_currency)}{" "}
                  {formatNumber(transaction.transaction_amount, 2)}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default OrderPlacedV2;
