import React, { Component } from "react";
import { Table, Icon } from "antd";
import "./index.scss";

class VariantsData extends Component {
  render() {
    const columns = [
      {
        title: "Image",
        dataIndex: "images",
        width: 50,
        render: (images) => {
          let src = "";
          if (images.length > 0) {
            src = images[0];
          }
          return (
            <span className="variant-image">
              <img
                src={src}
                style={{ width: 50, height: 50, objectFit: "cover" }}
                alt=""
              />
              <Icon
                type="zoom-in"
                onClick={() =>
                  this.props.toggleShowImageModal(0, images, "direct")
                }
              />
            </span>
          );
        },
      },
      {
        title: "vF Variant SKU",
        dataIndex: "vfsku",
        width: 150,
      },
      {
        title: "Variant Specs",
        dataIndex: "specs",
        width: 200,
        render: (specs) => Object.values(specs).join("/"),
      },
      // {
      //   title: "AliExpress Price",
      //   dataIndex: "alipriceusd",
      //   width: 150,
      //   render: (alipriceusd) =>
      //     `$ ${alipriceusd &&
      //       alipriceusd.pp &&
      //       alipriceusd.shipping &&
      //       (Number(alipriceusd.pp) + Number(alipriceusd.shipping)).toFixed(
      //         2
      //       )}`,
      // },
    ];
    const product = this.props.product;
    if (!product) {
      return null;
    }
    const variants = product.variants.map((v) => ({
      ...v,
      aliexpress_links: product.aliexpress_links,
    }));
    return (
      <div className="variantData">
        <Table
          scroll={{ y: 400 }}
          rowKey={(r) => r.vfsku}
          columns={columns}
          dataSource={variants}
          pagination={false}
        />
      </div>
    );
  }
}

export default VariantsData;
