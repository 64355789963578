import React, { Component } from 'react'
import { connect } from "react-redux";
import ShipmentForm from './ShipmentForm';
import ShipmentNav from './ShippingNav';

 class ShipmentSetting extends Component {
  render() {
    return (
        <>
        <div className='container-shipment-setting ' >
       <ShipmentNav/>
       <ShipmentForm />
       </div>
       </>
    
    )
  }
}
const mapStateToProps = (state) => {
    return {
      auth: state.auth.auth,
    };
  };
  
  export default connect(mapStateToProps)(ShipmentSetting );