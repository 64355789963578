import React from "react";
import { Row, Col, Tooltip } from "antd";
import { formatNumber, getCurrencySymbol } from "../../../../Helpers/Pricing";

import StatisticCard from "../../Common/StatisticCard";

const formatCurrency = (n, currency, decimals) => {
  let addK = false;
  let number = n;
  if (number >= 1000) {
    number = number / 1000;
    addK = true;
  }
  let ret = `${getCurrencySymbol(currency)} ${formatNumber(number, decimals)}${
    addK ? "K" : ""
  }`;
  return addK ? (
    <Tooltip title={`${getCurrencySymbol(currency)} ${formatNumber(n, 2)}`}>
      {ret}
    </Tooltip>
  ) : (
    ret
  );
};

const StatisticCardS = (props) => {
  return (
    <React.Fragment>
      <Row gutter={{ xs: 10, md: 24, lg: 30 }} style={{ marginBottom: "32px" }}>
        <Col xs={24} sm={12} md={12} lg={12} xl={6}>
          <StatisticCard
            header="Sales in Store"
            tooltip={
              <Tooltip
                title="The orders that you have received
              on your store."
              >
                {/* <Icon type="exclamation-circle-o" /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  style={{ marginTop: "4px", marginRight: "-8px" }}
                >
                  <path
                    id="Exclusion_1086"
                    data-name="Exclusion 1086"
                    d="M7,14A6.979,6.979,0,0,1,0,7,6.979,6.979,0,0,1,7,0a6.979,6.979,0,0,1,7,7,6.979,6.979,0,0,1-7,7ZM7,.985a5.864,5.864,0,0,0-2.338.478A5.951,5.951,0,0,0,2.748,2.748a6.139,6.139,0,0,0-1.3,1.914A6.01,6.01,0,0,0,.985,7a5.861,5.861,0,0,0,.478,2.337,5.951,5.951,0,0,0,1.285,1.914,6.109,6.109,0,0,0,1.914,1.3A6,6,0,0,0,7,13.015a5.847,5.847,0,0,0,2.337-.479,5.922,5.922,0,0,0,1.914-1.285,6.109,6.109,0,0,0,1.3-1.914A5.993,5.993,0,0,0,13.015,7a5.849,5.849,0,0,0-.479-2.338,5.922,5.922,0,0,0-1.285-1.914,6.139,6.139,0,0,0-1.914-1.3A6.008,6.008,0,0,0,7,.985Z"
                    fill="rgba(255,255,255,0.91)"
                  />
                  <g
                    id="Group_3567"
                    data-name="Group 3567"
                    transform="translate(-455 -881)"
                  >
                    <path
                      id="Path"
                      d="M3.8,5.277a.492.492,0,1,1,0,.984H.492a.475.475,0,0,1-.349-.144.5.5,0,0,1,0-.7.475.475,0,0,1,.349-.144H1.654V.984H.834A.464.464,0,0,1,.485.834.482.482,0,0,1,.342.492.475.475,0,0,1,.485.144.475.475,0,0,1,.834,0H2.146A.475.475,0,0,1,2.5.144a.475.475,0,0,1,.144.349V5.277Z"
                      transform="translate(459.812 885.375)"
                      fill="rgba(255,255,255,0.91)"
                    />
                  </g>
                  <path
                    id="Path-2"
                    data-name="Path"
                    d="M.144.841A.467.467,0,0,1,0,.5.49.49,0,0,1,.144.15.464.464,0,0,1,.492,0,.464.464,0,0,1,.841.15.49.49,0,0,1,.984.5.467.467,0,0,1,.841.841.475.475,0,0,1,.492.984.475.475,0,0,1,.144.841Z"
                    transform="translate(6.467 2.516)"
                    fill="rgba(255,255,255,0.91)"
                  />
                </svg>
              </Tooltip>
            }
            value={formatCurrency(
              props.loadcounterdata &&
                props.loadcounterdata.store_sale &&
                props.loadcounterdata.store_sale.amount
                ? props.loadcounterdata.store_sale.amount
                : 0,
              props.store_currency,
              2
            )}
            footer={
              <p style={{ margin: "0" }}>
                <span style={{ marginRight: "10px" }}>Count :</span>
                <span>
                  {props.loadcounterdata &&
                  props.loadcounterdata.store_sale &&
                  props.loadcounterdata.store_sale.count
                    ? props.loadcounterdata.store_sale.count
                    : 0}
                </span>
              </p>
            }
          />
        </Col>

        <Col xs={24} sm={12} md={12} lg={12} xl={6}>
          <StatisticCard
            header="Orders to vFulfill"
            tooltip={
              <Tooltip
                title="The orders that you have verified &
              forwarded to vFulfill to be fulfilled."
              >
                {/* <Icon type="exclamation-circle-o" /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  style={{ marginTop: "3px", marginRight: "-8px" }}
                >
                  <path
                    id="Exclusion_1086"
                    data-name="Exclusion 1086"
                    d="M7,14A6.979,6.979,0,0,1,0,7,6.979,6.979,0,0,1,7,0a6.979,6.979,0,0,1,7,7,6.979,6.979,0,0,1-7,7ZM7,.985a5.864,5.864,0,0,0-2.338.478A5.951,5.951,0,0,0,2.748,2.748a6.139,6.139,0,0,0-1.3,1.914A6.01,6.01,0,0,0,.985,7a5.861,5.861,0,0,0,.478,2.337,5.951,5.951,0,0,0,1.285,1.914,6.109,6.109,0,0,0,1.914,1.3A6,6,0,0,0,7,13.015a5.847,5.847,0,0,0,2.337-.479,5.922,5.922,0,0,0,1.914-1.285,6.109,6.109,0,0,0,1.3-1.914A5.993,5.993,0,0,0,13.015,7a5.849,5.849,0,0,0-.479-2.338,5.922,5.922,0,0,0-1.285-1.914,6.139,6.139,0,0,0-1.914-1.3A6.008,6.008,0,0,0,7,.985Z"
                    fill="rgba(255,255,255,0.91)"
                  />
                  <g
                    id="Group_3567"
                    data-name="Group 3567"
                    transform="translate(-455 -881)"
                  >
                    <path
                      id="Path"
                      d="M3.8,5.277a.492.492,0,1,1,0,.984H.492a.475.475,0,0,1-.349-.144.5.5,0,0,1,0-.7.475.475,0,0,1,.349-.144H1.654V.984H.834A.464.464,0,0,1,.485.834.482.482,0,0,1,.342.492.475.475,0,0,1,.485.144.475.475,0,0,1,.834,0H2.146A.475.475,0,0,1,2.5.144a.475.475,0,0,1,.144.349V5.277Z"
                      transform="translate(459.812 885.375)"
                      fill="rgba(255,255,255,0.91)"
                    />
                  </g>
                  <path
                    id="Path-2"
                    data-name="Path"
                    d="M.144.841A.467.467,0,0,1,0,.5.49.49,0,0,1,.144.15.464.464,0,0,1,.492,0,.464.464,0,0,1,.841.15.49.49,0,0,1,.984.5.467.467,0,0,1,.841.841.475.475,0,0,1,.492.984.475.475,0,0,1,.144.841Z"
                    transform="translate(6.467 2.516)"
                    fill="rgba(255,255,255,0.91)"
                  />
                </svg>
              </Tooltip>
            }
            value={formatCurrency(
              props.loadcounterdata &&
                props.loadcounterdata.vfulfill_orders &&
                props.loadcounterdata.vfulfill_orders.amount
                ? props.loadcounterdata.vfulfill_orders.amount
                : 0,
              props.store_currency,
              2
            )}
            footer={
              <p style={{ margin: "0" }}>
                <span style={{ marginRight: "10px" }}>Count :</span>
                <span>
                  {props.loadcounterdata &&
                  props.loadcounterdata.vfulfill_orders &&
                  props.loadcounterdata.vfulfill_orders.count
                    ? props.loadcounterdata.vfulfill_orders.count
                    : 0}
                </span>
              </p>
            }
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={6}>
          <StatisticCard
            header="Delivered Orders"
            tooltip={
              <Tooltip
                title="The orders that have been 
              delivered."
              >
                {/* <Icon type="exclamation-circle-o" /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  style={{ marginTop: "3px", marginRight: "-8px" }}
                >
                  <path
                    id="Exclusion_1086"
                    data-name="Exclusion 1086"
                    d="M7,14A6.979,6.979,0,0,1,0,7,6.979,6.979,0,0,1,7,0a6.979,6.979,0,0,1,7,7,6.979,6.979,0,0,1-7,7ZM7,.985a5.864,5.864,0,0,0-2.338.478A5.951,5.951,0,0,0,2.748,2.748a6.139,6.139,0,0,0-1.3,1.914A6.01,6.01,0,0,0,.985,7a5.861,5.861,0,0,0,.478,2.337,5.951,5.951,0,0,0,1.285,1.914,6.109,6.109,0,0,0,1.914,1.3A6,6,0,0,0,7,13.015a5.847,5.847,0,0,0,2.337-.479,5.922,5.922,0,0,0,1.914-1.285,6.109,6.109,0,0,0,1.3-1.914A5.993,5.993,0,0,0,13.015,7a5.849,5.849,0,0,0-.479-2.338,5.922,5.922,0,0,0-1.285-1.914,6.139,6.139,0,0,0-1.914-1.3A6.008,6.008,0,0,0,7,.985Z"
                    fill="rgba(255,255,255,0.91)"
                  />
                  <g
                    id="Group_3567"
                    data-name="Group 3567"
                    transform="translate(-455 -881)"
                  >
                    <path
                      id="Path"
                      d="M3.8,5.277a.492.492,0,1,1,0,.984H.492a.475.475,0,0,1-.349-.144.5.5,0,0,1,0-.7.475.475,0,0,1,.349-.144H1.654V.984H.834A.464.464,0,0,1,.485.834.482.482,0,0,1,.342.492.475.475,0,0,1,.485.144.475.475,0,0,1,.834,0H2.146A.475.475,0,0,1,2.5.144a.475.475,0,0,1,.144.349V5.277Z"
                      transform="translate(459.812 885.375)"
                      fill="rgba(255,255,255,0.91)"
                    />
                  </g>
                  <path
                    id="Path-2"
                    data-name="Path"
                    d="M.144.841A.467.467,0,0,1,0,.5.49.49,0,0,1,.144.15.464.464,0,0,1,.492,0,.464.464,0,0,1,.841.15.49.49,0,0,1,.984.5.467.467,0,0,1,.841.841.475.475,0,0,1,.492.984.475.475,0,0,1,.144.841Z"
                    transform="translate(6.467 2.516)"
                    fill="rgba(255,255,255,0.91)"
                  />
                </svg>
              </Tooltip>
            }
            value={formatCurrency(
              props.loadcounterdata &&
                props.loadcounterdata.delivered_orders &&
                props.loadcounterdata.delivered_orders.amount
                ? props.loadcounterdata.delivered_orders.amount
                : 0,
              props.store_currency,
              2
            )}
            footer={
              <p style={{ margin: "0" }}>
                <span style={{ marginRight: "10px" }}>Count :</span>
                <span>
                  {props.loadcounterdata &&
                  props.loadcounterdata.delivered_orders &&
                  props.loadcounterdata.delivered_orders.count
                    ? props.loadcounterdata.delivered_orders.count
                    : 0}
                </span>
              </p>
            }
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={6}>
          <StatisticCard
            showCount={false}
            header="Cost of Orders"
            tooltip={
              <Tooltip
                title="Total cost of products and services
              used to fulfill the orders"
              >
                {/* <Icon type="exclamation-circle-o" /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  style={{ marginTop: "3px", marginRight: "-8px" }}
                >
                  <path
                    id="Exclusion_1086"
                    data-name="Exclusion 1086"
                    d="M7,14A6.979,6.979,0,0,1,0,7,6.979,6.979,0,0,1,7,0a6.979,6.979,0,0,1,7,7,6.979,6.979,0,0,1-7,7ZM7,.985a5.864,5.864,0,0,0-2.338.478A5.951,5.951,0,0,0,2.748,2.748a6.139,6.139,0,0,0-1.3,1.914A6.01,6.01,0,0,0,.985,7a5.861,5.861,0,0,0,.478,2.337,5.951,5.951,0,0,0,1.285,1.914,6.109,6.109,0,0,0,1.914,1.3A6,6,0,0,0,7,13.015a5.847,5.847,0,0,0,2.337-.479,5.922,5.922,0,0,0,1.914-1.285,6.109,6.109,0,0,0,1.3-1.914A5.993,5.993,0,0,0,13.015,7a5.849,5.849,0,0,0-.479-2.338,5.922,5.922,0,0,0-1.285-1.914,6.139,6.139,0,0,0-1.914-1.3A6.008,6.008,0,0,0,7,.985Z"
                    fill="rgba(255,255,255,0.91)"
                  />
                  <g
                    id="Group_3567"
                    data-name="Group 3567"
                    transform="translate(-455 -881)"
                  >
                    <path
                      id="Path"
                      d="M3.8,5.277a.492.492,0,1,1,0,.984H.492a.475.475,0,0,1-.349-.144.5.5,0,0,1,0-.7.475.475,0,0,1,.349-.144H1.654V.984H.834A.464.464,0,0,1,.485.834.482.482,0,0,1,.342.492.475.475,0,0,1,.485.144.475.475,0,0,1,.834,0H2.146A.475.475,0,0,1,2.5.144a.475.475,0,0,1,.144.349V5.277Z"
                      transform="translate(459.812 885.375)"
                      fill="rgba(255,255,255,0.91)"
                    />
                  </g>
                  <path
                    id="Path-2"
                    data-name="Path"
                    d="M.144.841A.467.467,0,0,1,0,.5.49.49,0,0,1,.144.15.464.464,0,0,1,.492,0,.464.464,0,0,1,.841.15.49.49,0,0,1,.984.5.467.467,0,0,1,.841.841.475.475,0,0,1,.492.984.475.475,0,0,1,.144.841Z"
                    transform="translate(6.467 2.516)"
                    fill="rgba(255,255,255,0.91)"
                  />
                </svg>
              </Tooltip>
            }
            value={
              props.auth.store.store_geo === "in"
                ? formatCurrency(
                    props.loadcounterdata &&
                      props.loadcounterdata.order_cost &&
                      props.loadcounterdata.order_cost.amount
                      ? props.loadcounterdata.order_cost.amount
                      : 0,
                    props.store_currency,
                    2
                  )
                : formatCurrency(
                    props.loadcounterdata &&
                      props.loadcounterdata.order_cost &&
                      props.loadcounterdata.order_cost.amount
                      ? props.loadcounterdata.order_cost.amount
                      : 0,
                    props.store_currency,
                    2
                  )
            }
            footer={
              <p style={{ margin: "0" }}>
                <span style={{ marginRight: "10px" }}>Count :</span>
                <span>
                  {props.loadcounterdata &&
                  props.loadcounterdata.order_cost &&
                  props.loadcounterdata.order_cost.count
                    ? props.loadcounterdata.order_cost.count
                    : 0}
                </span>
              </p>
            }
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default StatisticCardS;
