import React, { Component } from "react";
import VideoTooltip from "../../Common/VideoTooltip";
import "./index.scss";

class SettingNav extends Component {
  state = {
    settingsbtn: true,
    channelbranding: false,
  };

  render() {
    return (
      <div className="preferences_seetting_data fullorder_data">
        <div className=" settingnav ">
          <div className="ordfill">
            <div className="settingwrap">
              <div
                className="left breadcurmb-txt"
                style={{ marginLeft: "7px" }}
              >
                <div
                  style={{
                    color: "#000",
                    fontSize: "12px",
                    letterSpacing: "0.032em",
                    lineHeight: "24px",
                  }}
                >
                  Settings
                </div>
                <VideoTooltip
                  title="Settings Page"
                  subtitle="Important store settings."
                  contentHeadings={["Store Settings"]}
                  content={[
                    "Add your support details and store logo to enhance your branding experience on your order. These details are printed on your order invoice.",
                  ]}
                  thumbnail=""
                  videolink=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_data" />
      </div>
    );
  }
}

export default SettingNav;
