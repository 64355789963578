import React, { Component } from "react";
import { Divider, Checkbox, Select, Icon, Tooltip, Button } from "antd";
import "./index.scss";
import { timezones } from "../../../../Helpers/Timezones";
import { ReactComponent as Tooltipican } from "../../../../assets/images/icons/tooltipican.svg";
import { ReactComponent as Downloadicon } from "../../../../assets/images/icons/download_new.svg";
import moment from "moment";

export default class PincodeDownload extends Component {
  render() {
    return (
      <div className="settings-container">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3 className="title">Pincodes List</h3>
        </div>

        <Divider />

        <div className="options-container34">
          <div className="option">
            {" "}
            <a
              href={
                process.env.REACT_APP_API_URL + `/settings/serviceable_pincodes`
              }
              className="underline-animation"
              target="_blank"
            >
              Serviceable Pincodes{" "}
              <span className="d-container">
                <Downloadicon />{" "}
              </span>
            </a>
            <div className="bottom-line">
              ( Last uploaded at:{" "}
              <span className="date">
                {moment(this.props.pincodes_updated_on).format("MMM DD, YYYY")}{" "}
              </span>
              )
            </div>
          </div>
          <div className="option">
            <a
              href="https://d24fzeiqvvundc.cloudfront.net/freevalue/ModerateRTOPincodes.xlsx"
              className="underline-animation"
            >
              Moderate RTO Risk Pincodes{" "}
              <span className="d-container">
                <Downloadicon />
              </span>
            </a>
            <div className="bottom-line">
              ( Last uploaded at:{" "}
              <span className="date">
                {moment(this.props.pincodes_moderate_updated_on).format(
                  "MMM DD, YYYY"
                )}{" "}
              </span>{" "}
              )
            </div>
          </div>
          <div className="option">
            <a
              href="https://d24fzeiqvvundc.cloudfront.net/freevalue/AggressiveRTOPincodes.xlsx"
              className="underline-animation"
            >
              High RTO Risk Pincodes{" "}
              <span className="d-container">
                <Downloadicon />
              </span>
            </a>
            <div className="bottom-line">
              ( Last uploaded at:{" "}
              <span className="date">
                {moment(this.props.pincodes_highrisk_updated_on).format(
                  "MMM DD, YYYY"
                )}{" "}
              </span>
              )
            </div>
          </div>
        </div>
      </div>
    );
  }
}
