import React, { Component } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Card,
  Tag,
  InputNumber,
  message,
  Tooltip,
  Skeleton,
} from "antd";
import logo from "../../../../../../assets/images/logos/logo_white.svg";
import "./index.scss";
import SelectVariantModal from "./SelectVariantModal";
import { set } from "lodash";
import axios from "axios";
import {
  formatNumber,
  getCurrencySymbol,
} from "../../../../../../Helpers/Pricing";

class InventoryModal extends Component {
  state = {
    pricingLoading: true,
    location: "",
    quantity: 100,
    minQuantity: 2,
    variants: [{ vfsku: undefined, quantity: 1 }],
    mix_skus: 0,
    continueLoading: false,
    loading: false,
    variantModal: {
      visible: false,
    },
    pricing: {},
    priceDirty: false,
  };

  onChange(key, value) {
    const upd = {
      [key]: value,
    };
    if (key === "quantity") {
      upd.priceDirty = true;
    }
    this.setState(upd);
  }

  onChangeVariant = (key, val) => {
    const currentState = { ...this.state };
    this.setState(set(currentState, key, val));
  };

  toggleVariantModal() {
    this.setState({
      variantModal: {
        visible: !this.state.variantModal.visible,
      },
    });
  }

  onAddVariant() {
    this.setState({
      variants: [...this.state.variants, { vfsku: undefined, quantity: 1 }],
    });
  }

  deleteVariant(i) {
    this.setState({
      variants: this.state.variants.filter((e, index) => index !== i),
    });
  }

  createInventory() {
    let pricing = Object.keys(this.state.pricing);
    pricing = pricing.sort(
      (a, b) => Number(a.split("-")[0]) - Number(b.split("-")[0])
    );
    const minQuantity = Number(pricing[0].split("-")[0]);
    if (this.state.quantity < minQuantity) {
      message.error(
        `You need to order minimum ${minQuantity} quantity to create inventory!`
      );
      return;
    }
    if (this.state.variants.filter((v) => v.vfsku).length === 0) {
      message.error("Please select variants to create inventory!");
      return;
    }
    if (this.state.mix_skus !== 1) {
      const variantQuantitySum = this.state.variants
        .filter((v) => v.vfsku)
        .reduce((acc, v) => acc + v.quantity, 0);
      if (variantQuantitySum !== this.state.quantity) {
        message.error(
          "Please update the selected variant quantity to match total quantity!"
        );
        return;
      }
    }
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL + "/sourcing/create_sourcing_request",
          method: "POST",
          data: {
            storeid: this.props.storeid,
            product_vfid: this.props.product.vfprodid,
            mix_skus: this.state.mix_skus,
            skus: this.state.variants,
            fc: this.state.location,
            quantity: this.state.quantity,
            currency: this.props.geo === "in" ? "INR" : "USD",
            pricing: this.state.pricing,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
              });
              this.props.toggleInventoryModal();
              message.success("Sourcing Request created successfully!");
              this.props.history.push(
                `/${this.props.storeid}/inventory/sourcing-requests`
              );
            } else {
              this.setState({
                loading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
            message.error(err.message);
          });
      }
    );
  }

  onVariantSave(total) {
    const variants = this.state.variants;
    const variantSum = variants.reduce((acc, v) => acc + v.quantity, 0);
    const invalidVariants = variants.filter((v) => !v.vfsku || v.quantity < 1);
    if (invalidVariants.length) {
      message.error("Please select an SKU for each variant!");
      return;
    }
    if (Number(variantSum) !== Number(this.state.quantity)) {
      message.error(
        "Sum of quantities of all variants must match the total sourcing quantity!"
      );
      return;
    }
    this.toggleVariantModal();
  }

  onRandomizeQuantity() {
    let variantQuantity = 0;
    let variantsLength = this.state.variants.length;
    let newVariants = [...this.state.variants];
    // check modulus
    if (this.state.quantity % variantsLength === 0) {
      // if modulus === 0
      variantQuantity = this.state.quantity / variantsLength;

      for (let i = 0; i < variantsLength; i++) {
        newVariants[i].quantity = variantQuantity;
      }
      this.setState({
        variants: newVariants,
      });
    } else {
      // if not then
      let mod = this.state.quantity % this.state.variants.length;
      variantQuantity = Math.floor(
        this.state.quantity / this.state.variants.length
      );
      for (let i = 0; i < variantsLength - 1; i++) {
        newVariants[i].quantity = variantQuantity;
      }
      newVariants[variantsLength - 1].quantity = variantQuantity + mod;
      this.setState({
        variants: newVariants,
      });
    }
  }

  loadPricing() {
    this.setState(
      {
        pricingLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/products/get_moq_pricing",
          method: "POST",
          data: {
            storeid: this.props.storeid,
            vfprodid: this.props.product.vfprodid,
            qty: this.state.quantity,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                pricingLoading: false,
                pricing: res.data.pricing,
                priceDirty: false,
              });
            } else {
              this.setState({
                pricingLoading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((err) => {
            this.setState({
              pricingLoading: false,
            });
            message.error(err.message);
          });
      }
    );
  }

  componentDidUpdate(pp) {
    if (this.props.visible && !pp.visible) {
      const product = this.props.product;
      const pricing = product.sourcing_pricing;
      const ranges = Object.keys(pricing).sort(
        (a, b) => a.split("-")[0] - b.split("-")[0]
      );
      let start = 100;
      if (ranges.length) {
        start = ranges[0].split("-")[0];
      }
      this.setState(
        {
          quantity: start,
          minQuantity: start,
        },
        () => this.loadPricing()
      );
    }
    if (!this.props.visible && pp.visible) {
      this.setState({
        pricingLoading: true,
        location: "",
        quantity: 100,
        minQuantity: 2,
        variants: [{ vfsku: undefined, quantity: 1 }],
        mix_skus: 1,
        continueLoading: false,
        loading: false,
        variantModal: {
          visible: false,
        },
        pricing: {},
      });
    }
  }

  setQuantity(range) {
    const numericRange = range.split("-").map((r) => Number(r));
    this.setState(
      { quantity: Math.floor((numericRange[0] + numericRange[1]) / 2) },
      () => this.loadPricing()
    );
  }

  render() {
    const product = this.props.product;
    const pricing = this.state.pricing;
    const ranges = Object.keys(pricing).sort(
      (a, b) => a.split("-")[0] - b.split("-")[0]
    );
    let range = ranges[ranges.length - 1];
    for (const r of ranges) {
      const end = r.split("-")[1];
      if (this.state.quantity <= end) {
        range = r;
        break;
      }
    }

    const currency = this.props.geo === "in" ? "INR" : "USD";
    const savingsAmount =
      this.state.quantity && this.state.location
        ? product.cost_data.vfmrp * this.state.quantity -
          pricing[range][this.state.location].total
        : 0;

    return (
      <Modal
        title={
          <div className="inventories-header">
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={logo} alt="vFulfill" />
              <p>Add Purchase Request</p>
            </div>
            <Button
              type="link"
              style={{ color: "#FFFFFF" }}
              onClick={this.props.toggleInventoryModal}
            >
              Cancel & Go back
            </Button>
          </div>
        }
        closable={false}
        visible={this.props.visible}
        className="inventories-modal"
        footer={null}
        width={1000}
      >
        <Row>
          <p className="modal-note">
            Note: Please note that the above purchase order pricing is only an
            indicative pricing. Once, you raise this purchase order, it will be
            verified by vFulfill Sourcing Team and then, confirmed. The
            indicative pricing mostly varies positively or negatively by 10-15%
            in actual cases. Also note, all purchase orders are final, when
            added
          </p>
        </Row>
        <Row className="main-inventory-container">
          <Col md={16}>
            <Card className="product-card">
              <div className="product-info">
                <img
                  src={product.images.length > 0 ? product.images[0].url : ""}
                  alt="not found"
                />
                <div style={{ marginLeft: 10 }}>
                  <div className="product-name">
                    <Tooltip title={product.name}>
                      <p
                        style={{
                          color: "#0068ff",
                          display: "-webkit-box",
                          "-webkit-line-clamp": "2",
                          "-webkit-box-orient": "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {product.name}
                      </p>
                    </Tooltip>
                  </div>
                  <div>
                    <div>
                      <h4>vF Product ID:</h4>
                      <p>{product.vfprodid}</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <h4>
                India Landing Cost For Bulk Quantity: <span>110</span>
              </h4> */}
              {this.state.pricingLoading ? (
                <Skeleton active />
              ) : (
                <>
                  <div className="bulk-price">
                    {Object.keys(pricing)
                      .sort(
                        (a, b) =>
                          Number(a.split("-")[0]) - Number(b.split("-")[0])
                      )
                      .map((range) => {
                        const ppqs = Object.values(
                          this.state.pricing[range]
                        ).map((v) => Number(v.avg));
                        const minPrice = Math.min(...ppqs);
                        const maxPrice = Math.max(...ppqs);

                        return (
                          <Tag
                            color="blue"
                            key={range}
                            style={{ margin: "5px 10px", cursor: "pointer" }}
                            onClick={() => this.setQuantity(range)}
                          >
                            <p>
                              {range} Pcs. <br />{" "}
                              <span>
                                {minPrice === maxPrice
                                  ? `${getCurrencySymbol(
                                      currency
                                    )} ${formatNumber(minPrice)}`
                                  : `${getCurrencySymbol(
                                      currency
                                    )} ${formatNumber(
                                      minPrice
                                    )} - ${getCurrencySymbol(
                                      currency
                                    )} ${formatNumber(maxPrice)}`}{" "}
                                Per Piece
                              </span>
                            </p>
                          </Tag>
                        );
                      })}
                  </div>
                  <div>
                    <h4 style={{ marginBottom: "8px" }}>
                      Select Stocking Location:
                    </h4>
                    {(this.props.geo === "in"
                      ? [
                          "china_express_freight",
                          "india_express_freight",
                          "india_cargo_freight",
                          // "india_sea_freight",
                        ]
                      : ["china_express_freight", "us_express_freight"]
                    ).map((type) => {
                      const names = {
                        china_express_freight: "China FC",
                        us_express_freight: "US FC | VF Air Express Freight",
                        india_express_freight:
                          "India FC | VF Air Express Freight",
                        india_cargo_freight: "India FC | VF Air Cargo Freight",
                        india_sea_freight: "India FC | VF Sea Freight",
                      };
                      return (
                        <div
                          key={type}
                          className={`${
                            !pricing[range] ||
                            !pricing[range][type] ||
                            type === "us_express_freight"
                              ? "disabled-location"
                              : this.state.location === type
                              ? "stock-location selected-location"
                              : "stock-location"
                          }`}
                          onClick={() =>
                            pricing[range] && pricing[range][type]
                              ? this.onChange("location", type)
                              : () => {}
                          }
                        >
                          {(pricing[range] && pricing[range][type]) ||
                          type === "us_express_freight" ? (
                            <span />
                          ) : (
                            <p className="location-error">
                              Not Available For Quantity Entered
                            </p>
                          )}
                          <div style={{ flex: 1 }}>
                            <div className="location-name">
                              <p>{names[type]}</p>
                              {pricing[range] &&
                                pricing[range][type] &&
                                pricing[range][type]["ppq"] && (
                                  <p>
                                    {getCurrencySymbol(currency)}{" "}
                                    {formatNumber(pricing[range][type]["ppq"])}{" "}
                                    Per Piece
                                  </p>
                                )}
                            </div>
                            <div className="stock-info">
                              <p>Stock Lead Time:</p>
                              {pricing[range] &&
                                pricing[range][type] &&
                                pricing[range][type]["lead_time"] && (
                                  <p>
                                    {pricing[range][type]["lead_time"]} days
                                  </p>
                                )}
                            </div>
                            <div className="stock-info">
                              <p>Inventory Live Time:</p>
                              {pricing[range] &&
                                pricing[range][type] &&
                                pricing[range][type]["fc_live_time"] && (
                                  <p>
                                    {pricing[range][type]["fc_live_time"]} days
                                  </p>
                                )}
                            </div>
                            <div className="stock-info">
                              <p>
                                Avg. Order Delivery Time (after inventory is
                                live):
                              </p>
                              {pricing[range] &&
                                pricing[range][type] &&
                                pricing[range][type]["order_delivery_time"] && (
                                  <p>
                                    {
                                      pricing[range][type][
                                        "order_delivery_time"
                                      ]
                                    }{" "}
                                    days
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </Card>
          </Col>
          <Col md={8}>
            {this.state.pricingLoading ? (
              <Skeleton active />
            ) : (
              <Card className="order-from-card">
                <p className="order-heading">ORDER FORM:</p>
                <hr />
                <div className="order-info">
                  <p>Enter Quantity:</p>
                  <InputNumber
                    type="number"
                    min={this.state.minQuantity}
                    onChange={(val) => this.onChange("quantity", val)}
                    value={this.state.quantity}
                  />
                </div>
                <div className="order-info">
                  <p>India Landing Cost:</p>
                  <p style={{ textAlign: "center" }}>
                    {" "}
                    {pricing[range] &&
                      pricing[range][this.state.location] &&
                      `${getCurrencySymbol(currency)} ${formatNumber(
                        pricing[range][this.state.location].ppq
                      )} per piece`}
                  </p>
                </div>

                <Button
                  block
                  className="primary"
                  style={{
                    marginTop: "16px",
                  }}
                  onClick={() => this.loadPricing()}
                  loading={this.state.pricingLoading}
                >
                  Update Quantity
                </Button>

                {/* <p style={{ marginTop: 15 }}>
                  <Switch
                    size="small"
                    checked={this.state.mix_skus === 1}
                    onChange={(checked) =>
                      this.onChange("mix_skus", checked ? 1 : 0)
                    }
                  />{" "}
                  Randomize Variant Quantities
                </p> */}

                <Button
                  type="primary"
                  block
                  style={{
                    marginTop: "16px",
                    borderRadius: "0px",
                    height: "36px",
                  }}
                  onClick={() => this.toggleVariantModal()}
                >
                  Select Variants
                </Button>
                <hr />
                <div className="order-info">
                  <p>Total Amount:</p>
                  <p style={{ textAlign: "center" }}>
                    {pricing[range] &&
                      pricing[range][this.state.location] &&
                      `${getCurrencySymbol(currency)} ${formatNumber(
                        pricing[range][this.state.location].total
                      )}`}
                  </p>
                </div>
                <hr />
                {pricing[range] &&
                  pricing[range][this.state.location] &&
                  savingsAmount > 0 && (
                    <small style={{ color: "#208B14" }}>
                      You are saving {getCurrencySymbol(currency)}{" "}
                      {formatNumber(savingsAmount)} more on this quantity than
                      if you had ordered same quantity on retail.
                    </small>
                  )}
              </Card>
            )}
            <Button
              block
              className="inventory-btn"
              onClick={() => this.createInventory()}
              loading={this.state.loading}
              disabled={this.state.priceDirty}
            >
              Add Purchase Request
            </Button>
            {this.state.priceDirty && (
              <p style={{ fontSize: 10, textAlign: "center", marginTop: 5 }}>
                Please click on "Update Quantity" to get the updated price.
              </p>
            )}
          </Col>
        </Row>
        <SelectVariantModal
          mix_skus={this.state.mix_skus}
          visible={this.state.variantModal.visible}
          toggleVariantModal={() => this.toggleVariantModal()}
          onChangeVariant={(key, value) => this.onChangeVariant(key, value)}
          variants={this.state.variants}
          onAddVariant={() => this.onAddVariant()}
          deleteVariant={(i) => this.deleteVariant(i)}
          onVariantSave={() => this.onVariantSave()}
          onRandomizeQuantity={() => this.onRandomizeQuantity()}
          continueLoading={this.state.continueLoading}
          product={product}
        />
      </Modal>
    );
  }
}

export default InventoryModal;
