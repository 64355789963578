import React, { Component } from "react";
import { Button, Card, Icon, Select, message } from "antd";
import { connect } from "react-redux";
import Header from "../Header";
import axios from "axios";
import "./index.scss";
// import { ISDCodes } from "../../../Helpers/ISD";
import "intl-tel-input/build/css/intlTelInput.css";
import IntlTelInput from "react-intl-tel-input-v2";
import PhoneInput from "react-phone-input-2";
import { SHOPIFY_NAME } from "../../../constants";
import "react-phone-input-2/lib/style.css";

const { Option } = Select;

class QuestionsScreen extends Component {
  state = {
    mobileValid: true,
    showQuestions: false,
    update: false,
    questions: {
      rate_ecom_business: "",
      active_store_status: "",
      platform_use: [],
      niche: [],
      market: "",
      choosed_countries: "",
      payment_method: "",
      cod_knowledge: "",
      current_modal: "",
      future_modal: "",
      orders_now: "",
      orders_todo: "",
      traffic_sources: [],
      traffic_resource_other: [],
      store_platform_other: [],

      // dropshipStatus: "yes_india",
      run_ecom_business: "",
      run_ecom_business_india: "",
      order_volume: "",
      monthly_media_spent: "",

      // problem: "no_easy_way",
      // other_problem: "",
      // future_plans: "Yes",
      user_f_name:
        this.props.other.profile && this.props.other.profile.user_fname
          ? this.props.other.profile.user_fname
          : "",
      user_l_name:
        this.props.other.profile && this.props.other.profile.user_lname
          ? this.props.other.profile.user_lname
          : "",
      user_email:
        this.props.other.profile && this.props.other.profile.user_email
          ? this.props.other.profile.user_email
          : "",
      num:
        this.props.other.profile && this.props.other.profile.user_contact
          ? this.props.other.profile.user_contact
          : "",
      ext: "",
      whatsapp_ext: "",
      phonecall_ext: "",
      telegram_ext: "",

      whatsapp:
        this.props.other.profile && this.props.other.profile.user_contact
          ? this.props.other.profile.user_contact_ext +
            this.props.other.profile.user_contact
          : "",
      commnucation_mode: "",
      skype: "",
      telegram: "",
      fb_messenger: "",
      email:
        this.props.other.profile && this.props.other.profile.user_email
          ? this.props.other.profile.user_email
          : "",
      phonecall:
        this.props.other.profile && this.props.other.profile.user_contact
          ? this.props.other.profile.user_contact_ext +
            this.props.other.profile.user_contact
          : "",
    },

    loading: false,
    form: {
      name: "abc",
      email: "abc",
    },
    data: {
      type: "",
    },
  };

  componentDidUpdate() {
    if (
      this.props.other &&
      this.props.other.profile &&
      this.props.other.profile.user_email &&
      this.state.update === false
    ) {
      this.setState({
        ...this.state,
        update: true,
        questions: {
          rate_ecom_business: "",
          active_store_status: "",
          platform_use: [],
          niche: [],
          market: "",
          choosed_countries: "",
          payment_method: "",
          cod_knowledge: "",
          current_modal: "",
          future_modal: "",
          orders_now: "",
          orders_todo: "",
          traffic_sources: [],
          traffic_resource_other: [],
          store_platform_other: [],

          // dropshipStatus: "yes_india",
          run_ecom_business: "",
          run_ecom_business_india: "",
          order_volume: "",
          monthly_media_spent: "",

          // problem: "no_easy_way",
          // other_problem: "",
          // future_plans: "Yes",
          user_f_name:
            this.props.other.profile && this.props.other.profile.user_fname
              ? this.props.other.profile.user_fname
              : "",
          user_l_name:
            this.props.other.profile && this.props.other.profile.user_lname
              ? this.props.other.profile.user_lname
              : "",
          user_email:
            this.props.other.profile && this.props.other.profile.user_email
              ? this.props.other.profile.user_email
              : "",
          num:
            this.props.other.profile && this.props.other.profile.user_contact
              ? this.props.other.profile.user_contact
              : "",
          ext: "",
          whatsapp_ext: "",
          phonecall_ext: "",
          telegram_ext: "",

          whatsapp:
            this.props.other.profile && this.props.other.profile.user_contact
              ? this.props.other.profile.user_contact_ext +
                this.props.other.profile.user_contact
              : "",
          commnucation_mode: "",
          skype: "",
          telegram: "",
          fb_messenger: "",
          email:
            this.props.other.profile && this.props.other.profile.user_email
              ? this.props.other.profile.user_email
              : "",
          phonecall:
            this.props.other.profile && this.props.other.profile.user_contact
              ? this.props.other.profile.user_contact_ext +
                this.props.other.profile.user_contact
              : "",
        },
      });
    }
  }

  saveSignupQuestions() {
    if (this.state.questions.traffic_sources.includes("others")) {
      if (
        !!this.state.questions.traffic_resource_other.length &&
        this.state.questions.traffic_sources.includes("others")
      ) {
      } else {
        message.error("Please enter value for other details. ");
        return;
      }
    } else {
    }

    if (this.state.questions.platform_use.includes("others")) {
      if (
        !!this.state.questions.store_platform_other.length &&
        this.state.questions.platform_use.includes("others")
      ) {
      } else {
        message.error("Please enter value for other details. ");
        return;
      }
    }

    let dataForSend = { ...this.state.questions };

    if (this.state.questions.commnucation_mode === "whatsapp") {
      dataForSend = {
        ...dataForSend,
        whatsapp: this.state.questions.whatsapp.replace(
          this.state.questions.whatsapp_ext,
          ""
        ),
      };
    }

    if (this.state.questions.commnucation_mode === "phonecall") {
      dataForSend = {
        ...dataForSend,
        phonecall: this.state.questions.phonecall.replace(
          this.state.questions.phonecall_ext,
          ""
        ),
      };
    }

    if (this.state.questions.commnucation_mode === "telegram") {
      dataForSend = {
        ...dataForSend,
        telegram: this.state.questions.telegram.replace(
          this.state.questions.telegram_ext,
          ""
        ),
      };
    }

    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/save_signup_questions",
          method: "post",
          data: {
            questions: { ...dataForSend },
          },
          withCredentials: true,
        })
          .then((res) => {
            this.setState({
              loading: false,
            });
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              window.location.href = "/switch-store";
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
          });
      }
    );
  }

  setQuestionAnswer(q, a) {
    this.setState((state) => ({
      questions: {
        ...state.questions,
        [q]: a,
      },
    }));
  }

  setQuestionAnswer3(q, a) {
    if (a !== "i_am_yet_to_set_up_my_first_store") {
      this.setState((state) => ({
        questions: {
          ...state.questions,
          [q]: a,
          current_modal: "",
        },
      }));
    } else {
      this.setState((state) => ({
        questions: {
          ...state.questions,
          [q]: a,
        },
      }));
    }
  }

  setQuestionAnswer4(q, a) {
    if (a !== "prepaid") {
      this.setState((state) => ({
        questions: {
          ...state.questions,
          [q]: a,
          cod_knowledge: "",
        },
      }));
    } else {
      this.setState((state) => ({
        questions: {
          ...state.questions,
          [q]: a,
        },
      }));
    }
  }

  submitValidation() {
    let select = document.querySelectorAll(".multi");
    let withoutSelect = document.querySelectorAll(".op");
    withoutSelect.forEach((e) => {
      if (e.querySelector(".ant-select-selection-selected-value")) {
      } else {
        e.classList.add("red");
      }
    });

    select.forEach((el) => {
      if (el.querySelector(".ant-select-selection__choice")) {
      } else {
        el.classList.add("red");
      }
    });

    if (this.state.showQuestions) {
      let d = document.querySelectorAll(".ss");

      var flg = 0;
      d.forEach((e) => {
        if (e.classList.contains("red")) {
          flg = 1;
        }
      });

      if (flg === 0) {
        if (
          (this.state.questions.commnucation_mode === "skype" &&
            this.state.questions.skype.length) ||
          (this.state.questions.commnucation_mode === "whatsapp" &&
            this.state.questions.whatsapp.length) ||
          (this.state.questions.commnucation_mode === "fb_messenger" &&
            this.state.questions.fb_messenger.length) ||
          (this.state.questions.commnucation_mode === "telegram" &&
            this.state.questions.telegram.length) ||
          (this.state.questions.commnucation_mode === "phonecall" &&
            this.state.questions.phonecall.length) ||
          (this.state.questions.commnucation_mode === "email" &&
            this.state.questions.email.length)
        ) {
          if (!!this.state.mobileValid) {
            this.saveSignupQuestions();
          } else {
            message.error("Phone number is not valid.");
          }
        } else {
          message.error(
            "Please enter value for preferred mode of communication."
          );
        }
      } else {
        message.error("All Questions are Mandatory!");
      }
    } else {
      this.setState({
        showQuestions: true,
      });
    }
  }

  handleChange3(field, value) {
    if (!value.includes("others")) {
      this.setState((state) => ({
        questions: {
          ...state.questions,
          [field]: value,
          store_platform_other: [],
        },
      }));
    } else {
      this.setState((state) => ({
        questions: {
          ...state.questions,
          [field]: value,
        },
      }));
    }
  }

  setQuestionAnswer2(q, a) {
    if (a.indexOf("na") !== -1) {
      this.setState((state) => ({
        questions: {
          ...state.questions,
          [q]: ["na"],
        },
      }));
    } else {
      this.setState((state) => ({
        questions: {
          ...state.questions,
          [q]: a,
        },
      }));
    }
  }

  updateField(k, v) {
    if (v === "email" || v === "skype" || v === "fb_messenger") {
      this.setState((state) => ({
        mobileValid: true,
        questions: {
          ...state.questions,
          [k]: v,
        },
      }));
    } else {
      this.setState((state) => ({
        questions: {
          ...state.questions,
          [k]: v,
        },
      }));
    }
  }

  // changephonenumber = (field, value) => {
  //   this.setState({
  //     questions: {
  //       user_phone_number: {
  //         [field]: value,
  //       },
  //     },
  //   });
  // };

  changewhatsupext(field, value) {
    this.setState((state) => ({
      questions: {
        ...state.questions,
        [field]: value,
      },
    }));
  }

  handleChange(field, value) {
    this.setState((state) => ({
      questions: {
        ...state.questions,
        [field]: value,
      },
    }));
  }

  // onChange(value) {
  //   this.setState({
  //     store_platform_other: value.length ? value[value.length - 1] : [],
  //   });
  // }

  handleChange2(field, value) {
    if (!value.includes("others")) {
      this.setState((state) => ({
        questions: {
          ...state.questions,
          [field]: value,
          traffic_resource_other: [],
        },
      }));
    } else {
      this.setState((state) => ({
        questions: {
          ...state.questions,
          [field]: value,
        },
      }));
    }
  }

  // whatsupdata(field, value) {
  //   this.setState((state) => ({
  //     questions: {
  //       ...state.questions,
  //       whatsapp: {
  //         [field]: value,
  //       },
  //     },
  //   }));
  // }

  // telegram handler
  telegramChangeHandler(val, c) {
    let customVal = val.replace(c.dialCode, "");

    if (customVal.replaceAll(" ", "").length < 10) {
      this.setState((state) => ({
        mobileValid: false,
        questions: {
          ...state.questions,
          whatsapp: "",
          phonecall: "",
          skype: "",
          telegram: val,
          fb_messenger: "",
          whatsapp_ext: "",
          phonecall_ext: "",
          telegram_ext: c.dialCode,
        },
      }));
    } else {
      this.setState((state) => ({
        mobileValid: true,
        questions: {
          ...state.questions,
          whatsapp: "",
          phonecall: "",
          skype: "",
          telegram: val,
          fb_messenger: "",
          whatsapp_ext: "",
          phonecall_ext: "",
          telegram_ext: c.dialCode,
        },
      }));
    }
  }

  // whats app handler
  whatsappChangeHandler(val, c) {
    let customVal = val.replace(c.dialCode, "");

    if (customVal.replaceAll(" ", "").length < 10) {
      this.setState((state) => ({
        mobileValid: false,
        questions: {
          ...state.questions,
          whatsapp: val,
          phonecall: "",
          skype: "",
          telegram: "",
          fb_messenger: "",
          whatsapp_ext: c.dialCode,
          phonecall_ext: "",
          telegram_ext: "",
        },
      }));
    } else {
      this.setState((state) => ({
        mobileValid: true,
        questions: {
          ...state.questions,
          whatsapp: val,
          phonecall: "",
          skype: "",
          telegram: "",
          fb_messenger: "",
          whatsapp_ext: c.dialCode,
          phonecall_ext: "",
          telegram_ext: "",
        },
      }));
    }
  }

  // phone call handler
  phonecallChangeHandler(val, c) {
    let customVal = val.replace(c.dialCode, "");

    if (customVal.replaceAll(" ", "").length < 10) {
      this.setState((state) => ({
        mobileValid: false,
        questions: {
          ...state.questions,
          phonecall: val,
          whatsapp: "",
          skype: "",
          telegram: "",
          fb_messenger: "",
          whatsapp_ext: "",
          phonecall_ext: c.dialCode,
          telegram_ext: "",
        },
      }));
    } else {
      this.setState((state) => ({
        mobileValid: true,
        questions: {
          ...state.questions,
          phonecall: val,
          whatsapp: "",
          skype: "",
          telegram: "",
          fb_messenger: "",
          whatsapp_ext: "",
          phonecall_ext: c.dialCode,
          telegram_ext: "",
        },
      }));
    }
  }

  render() {
    const inputProps = {
      placeholder: "Enter your phone number",
    };
    // const value = {
    //   iso2: "ae",
    //   dialCode: "971",
    //   phone: this.state.questions.num,
    // };

    const phonecallValue = {
      // iso2: "in",
      // dialCode: "91",
      phone: this.state.questions.phonecall,
    };

    const whatsappValue = {
      iso2: "",
      dialCode: "",

      phone: this.state.questions.whatsapp,
      defaultCountry: "in",
    };
    const codevalue = {
      dialCode: 91,
    };
    return (
      <Card
        className="form-container-card"
        style={{ width: 625, marginTop: "2%" }}
      >
        <Header
          style={{ color: "#4B4B4B" }}
          title={
            <p
              style={{
                fontSize: "16px",
                fontWeight: "500",
                letterSpacing: "0.032em",
                lineHeight: "18px",
                marginBottom: "6px",
              }}
            >
              We want to know you better!
            </p>
          }
          subtitle={
            <p
              style={{
                fontSize: "12px",
                fontWeight: "400",
                letterSpacing: "0.032em",
                lineHeight: "18px",
              }}
            >
              Please tell us more about yourself. It would <br />
              help us provide you a custom experience.
            </p>
          }
        />
        <br />

        <div className="questions-form-step1">
          <>
            <div>
              {/* <div>
                  <p>
                    Do you run any eCom business currently, or have experience
                    of running one?
                  </p>
                  <Radio.Group
                    onChange={(e) =>
                      this.setQuestionAnswer(
                        "run_ecom_business",
                        e.target.value
                      )
                    }
                  >
                    <div className="radiobutton_data">
                      <div>
                        <Radio value="Yes">Yes</Radio>
                      </div>
                      <div>
                        <Radio value="No">No</Radio>
                      </div>
                    </div>
                  </Radio.Group>
                </div> */}

              {/* new */}

              <div>
                <p>
                  How'd you rate your experience in running an eCommerce
                  business? <b style={{ color: "#ED0000" }}>*</b>
                </p>
                {/* <Radio.Group
                    onChange={(e) =>
                      this.setQuestionAnswer(
                        "run_ecom_business",
                        e.target.value
                      )
                    }>
                    <div className="radiobutton_data">
                      <div>
                        <Radio value="Yes">Yes</Radio>
                      </div>
                      <div>
                        <Radio value="No">No</Radio>
                      </div>
                    </div>
                  </Radio.Group> */}
                <Select
                  className="op ss"
                  placeholder="Please select your experience"
                  // defaultValue={this.state.questions.niche}
                  style={{ width: "100%" }}
                  onChange={(val) =>
                    this.setQuestionAnswer("rate_ecom_business", val)
                  }
                >
                  <Option style={{ fontSize: "12px" }} value="beginner">
                    Beginner (0-1 year)
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="intermediate">
                    {" "}
                    Intermediate (1-3 years)
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="advanced">
                    {" "}
                    Advanced (3-5+ years)
                  </Option>
                </Select>
              </div>

              <br />

              <div>
                {/* <div>
                    <p>
                      Do you run any eCom business in India, or have experience
                      of running one?
                    </p>
                    <Radio.Group
                      onChange={(e) =>
                        this.setQuestionAnswer(
                          "run_ecom_business_india",
                          e.target.value
                        )
                      }
                    >
                      <div className="radiobutton_data">
                        <div>
                          <Radio value="Yes">Yes</Radio>
                        </div>
                        <div>
                          <Radio value="No">No</Radio>
                        </div>
                      </div>
                    </Radio.Group>
                  </div> */}

                {/* second */}

                <div>
                  <p>
                    Do you have any active store running right now?{" "}
                    <b style={{ color: "#ED0000" }}>*</b>
                  </p>
                  <Select
                    className="op ss"
                    placeholder="Please select a option"
                    // defaultValue={this.state.questions.niche}
                    style={{ width: "100%" }}
                    onChange={(val) =>
                      this.setQuestionAnswer("active_store_status", val)
                    }
                  >
                    <Option
                      style={{ fontSize: "12px" }}
                      value="yeah_i_have_an_active_store"
                    >
                      Yeah I have an active store.
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="i_have_had_stores_in_the_past"
                    >
                      {" "}
                      I have had stores in the past.
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="i_am_yet_to_set_up_my_first_store"
                    >
                      {" "}
                      I am yet to set up my first store.
                    </Option>
                  </Select>
                </div>
              </div>
              <br />

              {/* third */}

              <div>
                <div>
                  <p>
                    Which platform do you use/prefer to build your store?{" "}
                    <b style={{ color: "#ED0000" }}>*</b>
                  </p>
                  <Select
                    className="multi ss"
                    mode="multiple"
                    placeholder="Please select a platform"
                    // defaultValue={this.state.questions.niche}
                    style={{ width: "100%", fontSize: "12px" }}
                    onChange={(val) => this.handleChange3("platform_use", val)}
                    showArrow={true}
                  >
                    <Option style={{ fontSize: "12px" }} value="shopify">
                      {`${SHOPIFY_NAME}`}
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="shopify_plus">
                      Store Plus
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="woo_commerce">
                      WooCommerce
                    </Option>

                    <Option style={{ fontSize: "12px" }} value="big_commerce">
                      BigCommerce
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="custom_set_up">
                      Custom Set Up
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="others">
                      Others
                    </Option>
                  </Select>
                </div>

                {this.state.questions.platform_use.includes("others") ? (
                  <div className="dd" style={{ marginTop: "10px" }}>
                    <p>
                      Others<b style={{ color: "#ED0000" }}>*</b>
                    </p>
                    <span
                      style={{
                        display: "flex",
                      }}
                    >
                      <Select
                        mode="tags"
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        placeholder="Enter the Type for your product"
                        // defaultValue={product.tags}
                        onChange={(value) =>
                          this.handleChange("store_platform_other", value)
                        }
                      >
                        {this.state.questions.store_platform_other.map(
                          (tag) => (
                            <Option
                              key={tag}
                              value={tag}
                              className="tag-select"
                            >
                              {tag}
                            </Option>
                          )
                        )}
                      </Select>
                    </span>
                  </div>
                ) : null}
              </div>
              <br />

              <div>
                <p>
                  Which niche do you usually operate in / looking to operate in?{" "}
                  <b style={{ color: "#ED0000" }}>*</b>{" "}
                </p>
                <Select
                  className="multi ss"
                  placeholder="Please select a niche"
                  mode="multiple"
                  value={this.state.questions.niche}
                  style={{ width: "100%", fontSize: "12px" }}
                  onChange={(val) => this.setQuestionAnswer2("niche", val)}
                  showArrow={true}
                >
                  <Option style={{ fontSize: "12px" }} value="na">
                    Not Applicable
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="general">
                    {" "}
                    General
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="beauty_health">
                    Beauty & Health
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="home_garden">
                    Home & Garden
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="kitchen_supplies">
                    Kitchen Supplies
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="work_from_home">
                    Work from Home
                  </Option>
                  <Option
                    style={{ fontSize: "12px" }}
                    value="gadgets_accessories"
                  >
                    Gadgets & Accessories
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="mother_kids">
                    Mother & Kids
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="pet_care">
                    Pet Care
                  </Option>
                  <Option
                    style={{ fontSize: "12px" }}
                    value="automobile_accessories"
                  >
                    Automobile Accessories
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="sports_fitness">
                    Sports & Fitness
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="toys_hobbies">
                    Toys & Hobbies
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="travel_outdoors">
                    Travel & Outdoors
                  </Option>
                  <Option
                    style={{ fontSize: "12px" }}
                    value="jewellery_accessories"
                  >
                    Jewellery & Accessories
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="COVID_Essentials">
                    COVID Essentials
                  </Option>
                </Select>
                <br />
              </div>
              <br />

              {/* fifth */}
              <div>
                <p>
                  Which market do you sell in / looking to sell in?{" "}
                  <b style={{ color: "#ED0000" }}>*</b>{" "}
                </p>

                <Select
                  className="op ss"
                  placeholder="Please select a market"
                  // mode="multiple"
                  // defaultValue={this.state.questions.niche}
                  style={{ width: "100%" }}
                  onChange={(val) => this.setQuestionAnswer("market", val)}
                >
                  <Option style={{ fontSize: "12px" }} value="Global">
                    Global
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="India">
                    {" "}
                    India
                  </Option>
                  <Option
                    style={{ fontSize: "12px" }}
                    value="Specific Countries"
                  >
                    Specific Countries
                  </Option>
                </Select>
                <br />
              </div>
              <br />
              {/* six */}

              {this.state.questions.market === "Specific Countries" ? (
                <div>
                  <p>
                    Choose countries: <b style={{ color: "#ED0000" }}>*</b>{" "}
                  </p>
                  <Select
                    className="multi ss"
                    placeholder="Choose countries "
                    mode="multiple"
                    // defaultValue={this.state.questions.niche}
                    style={{ width: "100%" }}
                    onChange={(val) =>
                      this.setQuestionAnswer2("choosed_countries", val)
                    }
                  >
                    <Option style={{ fontSize: "12px" }} value="Afganistan">
                      Afghanistan
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Albania">
                      Albania
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Algeria">
                      Algeria
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="American Samoa">
                      American Samoa
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Andorra">
                      Andorra
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Angola">
                      Angola
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Anguilla">
                      Anguilla
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Antigua & Barbuda"
                    >
                      Antigua & Barbuda
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Argentina">
                      Argentina
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Armenia">
                      Armenia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Aruba">
                      Aruba
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Australia">
                      Australia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Austria">
                      Austria
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Azerbaijan">
                      Azerbaijan
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Bahamas">
                      Bahamas
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Bahrain">
                      Bahrain
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Bangladesh">
                      Bangladesh
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Barbados">
                      Barbados
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Belarus">
                      Belarus
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Belgium">
                      Belgium
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Belize">
                      Belize
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Benin">
                      Benin
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Bermuda">
                      Bermuda
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Bhutan">
                      Bhutan
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Bolivia">
                      Bolivia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Bonaire">
                      Bonaire
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Bosnia & Herzegovina"
                    >
                      Bosnia & Herzegovina
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Botswana">
                      Botswana
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Brazil">
                      Brazil
                    </Option>

                    <Option style={{ fontSize: "12px" }} value="Brunei">
                      Brunei
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Bulgaria">
                      Bulgaria
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Burkina Faso">
                      Burkina Faso
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Burundi">
                      Burundi
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Cambodia">
                      Cambodia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Cameroon">
                      Cameroon
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Canada">
                      Canada
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Canary Islands">
                      Canary Islands
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Cape Verde">
                      Cape Verde
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Cayman Islands">
                      Cayman Islands
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Central African Republic"
                    >
                      Central African Republic
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Chad">
                      Chad
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Channel Islands"
                    >
                      Store Islands
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Chile">
                      Chile
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="China">
                      China
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Christmas Island"
                    >
                      Christmas Island
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Cocos Island">
                      Cocos Island
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Colombia">
                      Colombia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Comoros">
                      Comoros
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Congo">
                      Congo
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Cook Islands">
                      Cook Islands
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Costa Rica">
                      Costa Rica
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Cote DIvoire">
                      Cote DIvoire
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Croatia">
                      Croatia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Cuba">
                      Cuba
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Curaco">
                      Curacao
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Cyprus">
                      Cyprus
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Czech Republic">
                      Czech Republic
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Denmark">
                      Denmark
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Djibouti">
                      Djibouti
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Dominica">
                      Dominica
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Dominican Republic"
                    >
                      Dominican Republic
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="East Timor">
                      East Timor
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Ecuador">
                      Ecuador
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Egypt">
                      Egypt
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="El Salvador">
                      El Salvador
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Equatorial Guinea"
                    >
                      Equatorial Guinea
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Eritrea">
                      Eritrea
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Estonia">
                      Estonia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Ethiopia">
                      Ethiopia
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Falkland Islands"
                    >
                      Falkland Islands
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Faroe Islands">
                      Faroe Islands
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Fiji">
                      Fiji
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Finland">
                      Finland
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="France">
                      France
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="French Guiana">
                      French Guiana
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="French Polynesia"
                    >
                      French Polynesia
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="French Southern Ter"
                    >
                      French Southern Ter
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Gabon">
                      Gabon
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Gambia">
                      Gambia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Georgia">
                      Georgia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Germany">
                      Germany
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Ghana">
                      Ghana
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Gibraltar">
                      Gibraltar
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Great Britain">
                      Great Britain
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Greece">
                      Greece
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Greenland">
                      Greenland
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Grenada">
                      Grenada
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Guadeloupe">
                      Guadeloupe
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Guam">
                      Guam
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Guatemala">
                      Guatemala
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Guinea">
                      Guinea
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Guyana">
                      Guyana
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Haiti">
                      Haiti
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Hawaii">
                      Hawaii
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Honduras">
                      Honduras
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Hong Kong">
                      Hong Kong
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Hungary">
                      Hungary
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Iceland">
                      Iceland
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Indonesia">
                      Indonesia
                    </Option>

                    <Option style={{ fontSize: "12px" }} value="Iran">
                      Iran
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Iraq">
                      Iraq
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Ireland">
                      Ireland
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Isle of Man">
                      Isle of Man
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Israel">
                      Israel
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Italy">
                      Italy
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Jamaica">
                      Jamaica
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Japan">
                      Japan
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Jordan">
                      Jordan
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Kazakhstan">
                      Kazakhstan
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Kenya">
                      Kenya
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Kiribati">
                      Kiribati
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Korea North">
                      Korea North
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Korea Sout">
                      Korea South
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Kuwait">
                      Kuwait
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Kyrgyzstan">
                      Kyrgyzstan
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Laos">
                      Laos
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Latvia">
                      Latvia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Lebanon">
                      Lebanon
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Lesotho">
                      Lesotho
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Liberia">
                      Liberia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Libya">
                      Libya
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Liechtenstein">
                      Liechtenstein
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Lithuania">
                      Lithuania
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Luxembourg">
                      Luxembourg
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Macau">
                      Macau
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Macedonia">
                      Macedonia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Madagascar">
                      Madagascar
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Malaysia">
                      Malaysia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Malawi">
                      Malawi
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Maldives">
                      Maldives
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Mali">
                      Mali
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Malta">
                      Malta
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Marshall Islands"
                    >
                      Marshall Islands
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Martinique">
                      Martinique
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Mauritania">
                      Mauritania
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Mauritius">
                      Mauritius
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Mayotte">
                      Mayotte
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Mexico">
                      Mexico
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Midway Islands">
                      Midway Islands
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Moldova">
                      Moldova
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Monaco">
                      Monaco
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Mongolia">
                      Mongolia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Montserrat">
                      Montserrat
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Morocco">
                      Morocco
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Mozambique">
                      Mozambique
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Myanmar">
                      Myanmar
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Nambia">
                      Nambia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Nauru">
                      Nauru
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Nepal">
                      Nepal
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Netherland Antilles"
                    >
                      Netherland Antilles
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Netherlands">
                      Netherlands (Holland, Europe)
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Nevis">
                      Nevis
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="New Caledonia">
                      New Caledonia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="New Zealand">
                      New Zealand
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Nicaragua">
                      Nicaragua
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Niger">
                      Niger
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Nigeria">
                      Nigeria
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Niue">
                      Niue
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Norfolk Island">
                      Norfolk Island
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Norway">
                      Norway
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Oman">
                      Oman
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Pakistan">
                      Pakistan
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Palau Island">
                      Palau Island
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Palestine">
                      Palestine
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Panama">
                      Panama
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Papua New Guinea"
                    >
                      Papua New Guinea
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Paraguay">
                      Paraguay
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Peru">
                      Peru
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Phillipines">
                      Philippines
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Pitcairn Island"
                    >
                      Pitcairn Island
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Poland">
                      Poland
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Portugal">
                      Portugal
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Puerto Rico">
                      Puerto Rico
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Qatar">
                      Qatar
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Republic of Montenegro"
                    >
                      Republic of Montenegro
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Republic of Serbia"
                    >
                      Republic of Serbia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Reunion">
                      Reunion
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Romania">
                      Romania
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Russia">
                      Russia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Rwanda">
                      Rwanda
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="St Barthelemy">
                      St Barthelemy
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="St Eustatius">
                      St Eustatius
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="St Helena">
                      St Helena
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="St Kitts-Nevis">
                      St Kitts-Nevis
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="St Lucia">
                      St Lucia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="St Maarten">
                      St Maarten
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="St Pierre & Miquelon"
                    >
                      St Pierre & Miquelon
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="St Vincent & Grenadines"
                    >
                      St Vincent & Grenadines
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Saipan">
                      Saipan
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Samoa">
                      Samoa
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Samoa American">
                      Samoa American
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="San Marino">
                      San Marino
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Sao Tome & Principe"
                    >
                      Sao Tome & Principe
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Saudi Arabia">
                      Saudi Arabia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Senegal">
                      Senegal
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Seychelles">
                      Seychelles
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Sierra Leone">
                      Sierra Leone
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Singapore">
                      Singapore
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Slovakia">
                      Slovakia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Slovenia">
                      Slovenia
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Solomon Islands"
                    >
                      Solomon Islands
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Somalia">
                      Somalia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="South Africa">
                      South Africa
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Spain">
                      Spain
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Sri Lanka">
                      Sri Lanka
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Sudan">
                      Sudan
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Suriname">
                      Suriname
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Swaziland">
                      Swaziland
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Sweden">
                      Sweden
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Switzerland">
                      Switzerland
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Syria">
                      Syria
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Tahiti">
                      Tahiti
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Taiwan">
                      Taiwan
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Tajikistan">
                      Tajikistan
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Tanzania">
                      Tanzania
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Thailand">
                      Thailand
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Togo">
                      Togo
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Tokelau">
                      Tokelau
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Tonga">
                      Tonga
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Trinidad & Tobago"
                    >
                      Trinidad & Tobago
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Tunisia">
                      Tunisia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Turkey">
                      Turkey
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Turkmenistan">
                      Turkmenistan
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Turks & Caicos Is"
                    >
                      Turks & Caicos Is
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Tuvalu">
                      Tuvalu
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Uganda">
                      Uganda
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="United Kingdom">
                      United Kingdom
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Ukraine">
                      Ukraine
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="United Arab Erimates"
                    >
                      United Arab Emirates
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="United States of America"
                    >
                      United States of America
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Uraguay">
                      Uruguay
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Uzbekistan">
                      Uzbekistan
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Vanuatu">
                      Vanuatu
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Vatican City State"
                    >
                      Vatican City State
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Venezuela">
                      Venezuela
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Vietnam">
                      Vietnam
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Virgin Islands (Brit)"
                    >
                      Virgin Islands (Brit)
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Virgin Islands (USA)"
                    >
                      Virgin Islands (USA)
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Wake Island">
                      Wake Island
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="Wallis & Futana Is"
                    >
                      Wallis & Futana Is
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Yemen">
                      Yemen
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Zaire">
                      Zaire
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Zambia">
                      Zambia
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="Zimbabwe">
                      Zimbabwe
                    </Option>
                  </Select>
                  <br />
                  <br />
                </div>
              ) : null}

              {/* seven */}
              <div>
                <p>
                  Which method do you use / prefer to collect payments for
                  orders? <b style={{ color: "#ED0000" }}>*</b>
                </p>
                <Select
                  className="op "
                  placeholder="Please select a method"
                  // mode="multiple"
                  // defaultValue={this.state.questions.niche}
                  style={{ width: "100%" }}
                  onChange={(val) =>
                    this.setQuestionAnswer("payment_method", val)
                  }
                >
                  <Option style={{ fontSize: "12px" }} value="prepaid">
                    Prepaid
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="cash_on_delivery">
                    {" "}
                    Cash on Delivery
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="both">
                    Both
                  </Option>
                  <Option
                    style={{ fontSize: "12px" }}
                    value="i_currently_use_prepaid_but_looking_to_explore_cod_options."
                  >
                    I currently use prepaid but looking to explore COD options.
                  </Option>
                </Select>
                <br />
              </div>

              {/* eight */}
              {this.state.questions.payment_method === "prepaid" ? (
                <div>
                  <br />
                  <p>
                    How'd you rate your knowledge with Cash on Delivery model &
                    processes involved?
                    <b style={{ color: "#ED0000" }}>*</b>
                  </p>
                  <Select
                    className="op ss"
                    placeholder="Please select a option"
                    // mode="multiple"
                    // defaultValue={this.state.questions.niche}
                    style={{ width: "100%" }}
                    onChange={(val) =>
                      this.setQuestionAnswer("cod_knowledge", val)
                    }
                  >
                    <Option
                      style={{ fontSize: "12px" }}
                      value="i_am_well_aware_about_cod_as_an_option_&_the_processes_involved_like_ndr_etc"
                    >
                      I am well aware about COD as an option & the processes
                      involved like NDR etc.
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="i'd_need_help_with_complete_knowledge_on_cod"
                    >
                      {" "}
                      I'd need help with complete knowledge on COD.
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="both">
                      Both
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="i_currently_use_prepaid_but_looking_to_explore_cod_options"
                    >
                      I currently use prepaid but looking to explore COD
                      options.
                    </Option>
                  </Select>
                  <br />
                </div>
              ) : null}

              {/* nine */}

              {this.state.questions.active_store_status ===
              "i_am_yet_to_set_up_my_first_store" ? (
                <div>
                  <br />
                  <p>
                    Which model do you currently use to run your store /
                    eCommerce business?
                    <b style={{ color: "#ED0000" }}>*</b>
                  </p>
                  <Select
                    className="op ss"
                    placeholder="Please select a model"
                    // mode="multiple"
                    // defaultValue={this.state.questions.niche}
                    style={{ width: "100%" }}
                    onChange={(val) =>
                      this.setQuestionAnswer("current_modal", val)
                    }
                  >
                    <Option style={{ fontSize: "12px" }} value="stock_shiping">
                      Stock-shiping (Keep stock to fulfill orders)
                    </Option>
                    <Option style={{ fontSize: "12px" }} value="dropshipping">
                      {" "}
                      Drop-shipping (No stock - fulfillment on demand)
                    </Option>
                    <Option
                      style={{ fontSize: "12px" }}
                      value="direct_2_consumer"
                    >
                      Direct2Consumer (D2C - Keep private label stock to fulfill
                      orders)
                    </Option>
                  </Select>
                  <br />
                </div>
              ) : null}

              {/* ten */}

              <div>
                <br />
                <p>
                  Which model are you looking to use to run your store /
                  eCommerce business in the coming future?{" "}
                  <b style={{ color: "#ED0000" }}>*</b>
                </p>
                <Select
                  className="op ss"
                  placeholder="Please select a model"
                  // mode="multiple"
                  // defaultValue={this.state.questions.niche}
                  style={{ width: "100%" }}
                  onChange={(val) =>
                    this.setQuestionAnswer("future_modal", val)
                  }
                >
                  <Option style={{ fontSize: "12px" }} value="stock_shiping">
                    Stock-shiping (Keep stock to fulfill orders)
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="dropshipping">
                    {" "}
                    Drop-shipping (No stock - fulfillment on demand)
                  </Option>
                  <Option
                    style={{ fontSize: "12px" }}
                    value="direct_2_consumer"
                  >
                    Direct2Consumer (D2C - Keep private label stock to fulfill
                    orders)
                  </Option>
                </Select>
              </div>
              <br />

              {/* eleven */}
              <div>
                <p>
                  How many orders are you getting on your stores, per month
                  right now? <b style={{ color: "#ED0000" }}>*</b>
                </p>
                <Select
                  className="op ss"
                  placeholder="Please select a quantity"
                  // mode="multiple"
                  // defaultValue={this.state.questions.niche}
                  style={{ width: "100%" }}
                  onChange={(val) => this.setQuestionAnswer("orders_now", val)}
                >
                  <Option
                    style={{ fontSize: "12px" }}
                    value="i_am_yet_to_start_marketing"
                  >
                    I am yet to start marketing
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="300-600">
                    {" "}
                    300-600
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="600-1500">
                    600-1500
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="1500-3000">
                    1500-3000
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="3000-9000">
                    3000-9000
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="9000-25000">
                    9000-25000
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="25000-75000">
                    25000-75000
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="75000+">
                    {" "}
                    75000+
                  </Option>
                </Select>
                <br />
              </div>
              <br />

              {/* twelve */}
              <div>
                <p>
                  How many orders do you project to do, per month after the
                  coming 3 months? <b style={{ color: "#ED0000" }}>*</b>
                </p>
                <Select
                  className="op ss"
                  placeholder="Please select a quantity"
                  // mode="multiple"
                  // defaultValue={this.state.questions.niche}
                  style={{ width: "100%" }}
                  onChange={(val) => this.setQuestionAnswer("orders_todo", val)}
                >
                  <Option
                    style={{ fontSize: "12px" }}
                    value="i_am_yet_to_start_marketing"
                  >
                    I am yet to start marketing
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="300-600">
                    {" "}
                    300-600
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="600-1500">
                    600-1500
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="1500-3000">
                    1500-3000
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="3000-9000">
                    3000-9000
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="9000-25000">
                    9000-25000
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="25000-75000">
                    25000-75000
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="75000+">
                    {" "}
                    75000+
                  </Option>
                </Select>
                <br />
              </div>
              <br />

              {/* thirteen */}

              <div>
                <p>
                  What are the traffic sources for your store right now?{" "}
                  <b style={{ color: "#ED0000" }}>*</b>{" "}
                </p>

                <Select
                  className="multi ss"
                  placeholder="Please select a traffic sources"
                  mode="multiple"
                  // defaultValue={this.state.questions.niche}
                  style={{ width: "100%", fontSize: "12px" }}
                  onChange={(val) => this.handleChange2("traffic_sources", val)}
                  showArrow={true}
                >
                  <Option
                    style={{ fontSize: "12px" }}
                    value="i_am_yet_to_start_marketing_my_store"
                  >
                    I am yet to start marketing my store
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="facebook_ads">
                    Facebook Ads
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="instagram_ads">
                    Instagram Ads
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="google_ads">
                    Google Ads
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="native_ads">
                    Native Ads
                  </Option>
                  <Option
                    style={{ fontSize: "12px" }}
                    value="influencer_marketing"
                  >
                    Influencer Marketing
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="tiktok">
                    Tik Tok
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="snapchat">
                    Snapchat
                  </Option>

                  <Option style={{ fontSize: "12px" }} value="whatsapp">
                    WhatsApp
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="youtube">
                    Youtube
                  </Option>
                  <Option style={{ fontSize: "12px" }} value="others">
                    Others
                  </Option>
                </Select>
                <br />

                {this.state.questions.traffic_sources.includes("others") ? (
                  <div className="dd" style={{ marginTop: "10px" }}>
                    <p>
                      Others<b style={{ color: "#ED0000" }}>*</b>
                    </p>
                    <span
                      style={{
                        display: "flex",
                      }}
                    >
                      <Select
                        mode="tags"
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        placeholder="Enter the Type for your product"
                        // defaultValue={product.tags}
                        onChange={(value) =>
                          this.handleChange("traffic_resource_other", value)
                        }
                      >
                        {this.state.questions.traffic_resource_other.map(
                          (tag) => (
                            <Option
                              key={tag}
                              value={tag}
                              className="tag-select"
                            >
                              {tag}
                            </Option>
                          )
                        )}
                      </Select>
                    </span>
                  </div>
                ) : null}
              </div>

              <br />
              {/* fourteen */}
              <div className="dd">
                <p>
                  What is the preferred mode of communication for you?{" "}
                  <b style={{ color: "#ED0000" }}>*</b>
                </p>

                <div className={`ant-form-item`}>
                  <Select
                    className="op ss"
                    placeholder="Please select preferred mode of communication."
                    onChange={(val) =>
                      this.updateField("commnucation_mode", val)
                    }
                    style={{
                      width: "100%",
                      background: "#F5FAFF",
                      border: "1px solid F5FAFF",
                    }}
                  >
                    <Option
                      style={{ border: "1px solid #CECECE", fontSize: "12px" }}
                      value="phonecall"
                    >
                      Phonecall
                    </Option>
                    <Option
                      style={{ border: "1px solid #CECECE", fontSize: "12px" }}
                      value="email"
                    >
                      Email
                    </Option>
                    <Option
                      style={{ border: "1px solid #CECECE", fontSize: "12px" }}
                      value="skype"
                    >
                      Skype
                    </Option>

                    <Option
                      style={{ border: "1px solid #CECECE", fontSize: "12px" }}
                      value="whatsapp"
                    >
                      Whatsapp
                    </Option>

                    <Option
                      style={{ border: "1px solid #CECECE" }}
                      value="telegram"
                    >
                      Telegram
                    </Option>
                    <Option
                      style={{ border: "1px solid #CECECE", fontSize: "12px" }}
                      value="fb_messenger"
                    >
                      FB Messenger
                    </Option>
                  </Select>
                </div>
              </div>

              {this.state.questions.commnucation_mode === "skype" && (
                <div className="dd">
                  <p>
                    Your Skype ID <b style={{ color: "#ED0000" }}>*</b>
                  </p>
                  <span
                    style={{
                      display: "flex",
                    }}
                  >
                    <input
                      type="text"
                      onChange={(e) =>
                        this.handleChange("skype", e.target.value)
                      }
                      style={{ padding: "10px" }}
                    />
                  </span>
                </div>
              )}

              {this.state.questions.commnucation_mode === "email" && (
                <div className="dd">
                  <p>
                    Your Email Address <b style={{ color: "#ED0000" }}>*</b>
                  </p>
                  <span
                    style={{
                      display: "flex",
                    }}
                  >
                    <input
                      readOnly
                      type="text"
                      value={
                        this.props.other.profile &&
                        this.props.other.profile.user_email
                          ? this.props.other.profile.user_email
                          : null
                      }
                      style={{ padding: "10px" }}
                    />
                  </span>
                </div>
              )}

              {this.state.questions.commnucation_mode === "fb_messenger" && (
                <div className="dd">
                  <p>
                    Your Facebook Address<b style={{ color: "#ED0000" }}>*</b>
                  </p>
                  <span
                    style={{
                      display: "flex",
                    }}
                  >
                    <input
                      type="text"
                      onChange={(e) =>
                        this.handleChange("fb_messenger", e.target.value)
                      }
                      style={{ padding: "10px" }}
                    />
                  </span>
                </div>
              )}

              {this.state.questions.commnucation_mode === "telegram" && (
                <div className="dd">
                  <p>
                    Your Telegram Address<b style={{ color: "#ED0000" }}>*</b>
                  </p>
                  <span
                    style={{
                      display: "flex",
                    }}
                  >
                    <PhoneInput
                      className={this.state.mobileValid ? "" : "custom-phn"}
                      country={""}
                      placeholder="Enter your telegram number"
                      value={this.state.questions.telegram}
                      onChange={(val, country) =>
                        this.telegramChangeHandler(val, country)
                      }
                    />
                  </span>
                </div>
              )}

              {this.state.questions.commnucation_mode === "whatsapp" && (
                <div className="nn">
                  <p>
                    Your Whatsapp Number <b style={{ color: "#ED0000" }}>*</b>
                  </p>
                  {/* <IntlTelInput
                    //preferredCountries={[this.state.countryCode]}
                    // readOnly
                    // type="number"
                    inputClassName="form-control"
                    inputProps={inputProps}
                    value={whatsappValue}
                    // defaultCountry={"IN"}
                    onChange={(val) => this.whatsappChangeHandler(val)}
                    // value={}
                    // type="tel"
                  /> */}

                  <PhoneInput
                    className={this.state.mobileValid ? "" : "custom-phn"}
                    country={""}
                    placeholder="Enter your whatsapp number"
                    value={this.state.questions.whatsapp}
                    onChange={(val, country) =>
                      this.whatsappChangeHandler(val, country)
                    }
                  />
                </div>
              )}

              {this.state.questions.commnucation_mode === "phonecall" && (
                <div className="nn">
                  <p>
                    Your Phone Number <b style={{ color: "#ED0000" }}>*</b>
                  </p>

                  {/* <IntlTelInput
                    //preferredCountries={[this.state.countryCode]}
                    inputClassName="form-control"
                    onChange={(val) => this.phonecallChangeHandler(val)}
                    inputProps={inputProps}
                    value={phonecallValue}
                    // value={}
                    // type="tel"
                  /> */}

                  <PhoneInput
                    className={this.state.mobileValid ? "" : "custom-phn"}
                    country={""}
                    placeholder="Enter your phone number"
                    value={this.state.questions.phonecall}
                    onChange={(val, c) => this.phonecallChangeHandler(val, c)}
                  />
                </div>
              )}
            </div>
          </>
        </div>
        <div
          className="questions-form-footer"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "-32px",
          }}
        >
          <Button
            className="skip"
            type="cancel"
            onClick={() => {
              window.location.href = "./switch-store";
            }}
          >
            Skip
          </Button>
          <Button
            className="btn-green next"
            onClick={() => this.submitValidation()}
            loading={this.state.loading}
          >
            Continue <Icon type="arrow-right" />
          </Button>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({ auth: state.auth.auth });
export default connect(mapStateToProps)(QuestionsScreen);
