import React, { Component } from "react";
import { Tooltip, Icon, Select, message } from "antd";
import { formatNumber, getCurrencySymbol } from "../../../../Helpers/Pricing";
import { ReactComponent as RestockingDownArrows } from "../../../../assets/images/icons/RestockingDownArrows.svg";
import { ReactComponent as GoldenStar } from "../../../../assets/images/icons/GoldenStar.svg";
import { ReactComponent as TooltipIcon } from "../../../../assets/images/icons/TooltipIcon.svg";
import { ReactComponent as downArrow } from "../../../../assets/images/icons/downArrow.svg";
import { ReactComponent as WhiteHeart } from "../../../../assets/images/icons/whiteHeart.svg";

const { Option } = Select;

class QuantityPicker extends Component {
  state = {
    value: 0,
  };

  componentDidUpdate(pp) {
    if (pp.selectedType !== this.props.selectedType) {
      this.setState({ value: 0 }, () => {
        this.onInputChange(this.state.value);
      });
    }
  }

  increment() {
    if (
      this.props.selectedType === "vf" &&
      this.state.value >= this.props.vf_inventory
    ) {
      return;
    } else {
      this.setState(
        (state) => ({
          value: Number(state.value) + 1,
        }),
        () => this.onInputChange(this.state.value)
      );
    }
  }

  componentDidMount() {
    if (!!this.props.product && this.props.index === 0) {
      this.setState({ value: 0 }, () => {
        this.onInputChange(
          this.props.product.moq_sourcing_table[0].range.split("-")[0]
        );
      });
    }
  }

  decrement() {
    this.setState(
      (state) => ({
        value: Math.max(0, state.value - 1),
      }),
      () => this.onInputChange(this.state.value)
    );
  }

  onInputChange(v) {
    if (this.props.selectedType === "vf" && v > this.props.vf_inventory) {
      message.error(
        "Cannot enter more Quantity than available VF Inventory Quantity."
      );
      return;
    } else {
      this.props.onUpdatemoqvqlue(v);
      let value = v;
      if (!value) {
        value = 0;
      }
      if (/^\d+$/.test(value)) {
        this.setState({ value: Number(value) }, () =>
          this.props.setValue(this.state.value)
        );
      }
    }
  }

  render() {
    return (
      <div
        onMouseEnter={() => this.props.mouseHandler()}
        onMouseOutCapture={() => this.props.mouseLeaveHandler()}
        className="booking-qty-parent-wraper"
      >
        <div className={`booking-qty ${this.props.disabled ? "disabled" : ""}`}>
          <button onClick={() => this.decrement()}>-</button>
          <input
            value={this.state.value}
            onChange={(e) => this.onInputChange(e.target.value)}
          />
          <button onClick={() => this.increment()}>+</button>
        </div>
      </div>
    );
  }
}

class VariantSelector extends Component {
  state = {
    variants: {},
    selectedVariant: -1,
    freightData: "",
  };

  svgRef = React.createRef();

  handleCheckboxChange = (event) => {
    this.props.onUpdate(event);
    this.setState({ freightData: event });
    this.props.updateFreightData(event);
  };

  componentDidMount() {
    const variants = {};
    this.props.variants.forEach((v) => (variants[v.vfsku] = 0));
    this.setState({ variants });
  }

  setValue(sku, value) {
    this.setState(
      (state) => ({
        variants: {
          ...state.variants,
          [sku]: value,
        },
      }),
      () => this.props.updateSelectedVariants(sku, Number(value))
    );
  }

  setSelectedVariant(index) {
    this.setState({ selectedVariant: index }, () =>
      this.props.setSelectedImage(
        this.props.cr.length -
          (this.props.variants.filter((f) => f.default === 1).length - index)
      )
    );
  }

  transitTimeCalc(status) {
    if (status === "") {
      return "N/A";
    }

    if (status === "vf") {
      return "0 days";
    }

    if (status === "air") {
      return "8-10 days";
    }
    if (status === "sea") {
      return "30-40 days";
    }
  }

  mouseHandler() {
    if (this.state.freightData === "") {
      let starIcon = this.svgRef.current.querySelector(".golden-star");
      let heading = this.svgRef.current.querySelector(
        ".inventory-freight-type p"
      );
      starIcon.classList.add("rotationClass");
      heading.classList.add("underlineAnimatio");
    }
  }

  mouseLeaveHandler() {
    if (this.state.freightData === "") {
      let starIcon = this.svgRef.current.querySelector(".golden-star");
      let heading = this.svgRef.current.querySelector(
        ".inventory-freight-type p"
      );
      starIcon.classList.remove("rotationClass");
      heading.classList.remove("underlineAnimatio");
    }
  }

  render() {
    const variants = this.props.variants || [];
    const currency = this.props.currency;
    return (
      <>
        <div className="raise-bulk-order-pricing-table-wraper">
          <div className="heading-wraper">
            <div className="heading">Raise Bulk Store Order</div>
          </div>
          <div ref={this.svgRef} className="pricing-wraper">
            <div className="table-headings">
              <div className="inventory-freight-type">
                <Icon className="golden-star" component={GoldenStar} />
                <p>Select Inventory Freight Type:</p>
                <div className="type-select">
                  <Select
                    suffixIcon={<Icon component={downArrow} />}
                    defaultValue=""
                    value={this.state.freightData}
                    onChange={(e) => this.handleCheckboxChange(e)}
                    dropdownMatchSelectWidth={false}
                  >
                    <Option disabled value="">
                      None
                    </Option>
                    {/* <Option value="vf">VF Inventory</Option> */}
                    <Option value="air">Transit Via Air</Option>
                    <Option value="sea">Transit Via Sea</Option>
                  </Select>
                </div>
              </div>

              <div className="transit-time">
                <p>Transit time:</p>{" "}
                <span>{this.transitTimeCalc(this.state.freightData)}</span>
              </div>
            </div>

            <div className="table-sub-headings">
              <div className="variants">Variant(s)</div>
              {/* <div className="vf-days">
                VF Inventory
                <span className="product-tooltip">
                  <Tooltip
                    title={
                      "The inventory stored at vFulfill warehouse for instant allocation of bulk quantity and faster deliveries. We generally keep these for High Performing or Promising products."
                    }
                  >
                    <Icon
                      className="product-tooltip-icon"
                      component={TooltipIcon}
                    />
                  </Tooltip>
                </span>
              </div> */}
              <div className="air-days">Pricing</div>
              <div className="booking-qty">Booking Qty.</div>
            </div>

            {/* table row */}

            {variants.map((variant, index) => {
              const variantPrice = this.props.pricing;
              if (variantPrice <= 0) {
                return null;
              }
              return (
                <div
                  onClick={() => this.setSelectedVariant(index)}
                  className="table-row-data"
                >
                  <div className="variants-details">
                    <div className="img">
                      <img
                        src={variant.images[0]}
                        alt={`variant ${variant.vfsku}`}
                      />
                    </div>{" "}
                    <div className="sku-details">
                      <div className="sku">
                        <p>SKU:</p>{" "}
                        <Tooltip title="Variant ID"> {variant.vfsku}</Tooltip>{" "}
                      </div>
                      <div className="specs">
                        <p>Specs:</p>{" "}
                        {variant.allspecs.length > 14 ? (
                          <Tooltip title={variant.allspecs}>
                            {" "}
                            <span>{variant.allspecs.slice(0, 14) + "…"}</span>
                          </Tooltip>
                        ) : (
                          <span>{variant.allspecs}</span>
                        )}{" "}
                      </div>
                    </div>
                  </div>
                  {/* <div className="vf-inventory">{variant.vf_inventory}</div> */}
                  <div className="air-days">
                    {" "}
                    {getCurrencySymbol(currency)} {formatNumber(variantPrice)}
                  </div>

                  {this.state.freightData === "" ? (
                    <Tooltip
                      title={
                        <p className="small-tooltip-text">
                          Please select “Select Inventory Freight Type” first to
                          be able to Book Bulk Quantity.
                        </p>
                      }
                    >
                      <div>
                        <QuantityPicker
                          index={index}
                          product={false}
                          selectedType={this.state.freightData}
                          mouseHandler={(val) => this.mouseHandler(val)}
                          mouseLeaveHandler={(val) =>
                            this.mouseLeaveHandler(val)
                          }
                          vf_inventory={variant.vf_inventory}
                          disabled={true}
                          setValue={(value) =>
                            this.setValue(variant.vfsku, value)
                          }
                          onUpdatemoqvqlue={this.props.onUpdatemoqvqlue}
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <QuantityPicker
                      index={index}
                      product={this.props.product}
                      mouseHandler={() => this.mouseHandler()}
                      mouseLeaveHandler={() => this.mouseLeaveHandler()}
                      selectedType={this.state.freightData}
                      vf_inventory={variant.vf_inventory}
                      disabled={false}
                      setValue={(value) => this.setValue(variant.vfsku, value)}
                      onUpdatemoqvqlue={this.props.onUpdatemoqvqlue}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default VariantSelector;
