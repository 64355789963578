import React from "react";
import { Table, Row, Tooltip } from "antd";
import {
  getCurrencySymbol,
  formatNumber,
} from "../../../../../Helpers/Pricing";

const OrderPlaced = (props) => {
  const transaction = props.transaction;
  // let items = [];

  // if (transaction) {
  //   items = transaction.transaction_data.items;
  // }

  let reviewColumns = [
    {
      title: "vFulfill Order ID",
      dataIndex: null,
      width: "9%",
      render: () => transaction.transaction_data.vforderid,
    },
    {
      title: "Order Name",
      dataIndex: null,
      width: "6.33%",
      render: () =>
        transaction.transaction_data.items &&
        transaction.transaction_data.items[0] &&
        transaction.transaction_data.items[0].orderid
          ? transaction.transaction_data.items[0].orderid
          : "",
    },
    {
      title: "Order Details",
      dataIndex: "items",
      // width: "28%",
      className: "products-list",
      render: () =>
        transaction.transaction_data.items.map((item) => (
          <Row
            key={item.lineitem_id}
            style={{ display: "flex", alignItems: "start" }}
          >
            <img src={item.img} alt="" style={{ width: 50 }} />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: 10,
              }}
            >
              <p
                style={{
                  flex: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: 215,
                  marginBottom: 5,
                }}
              >
                <Tooltip title={item.name} placement="topLeft">
                  {item.name}
                </Tooltip>
              </p>

              <p style={{ flex: 1, margin: 0, marginBottom: 5 }}>
                <Tooltip title="vFulfill Selling Price">
                  <span style={{ background: "#f0f2f5", padding: "0 5px" }}>
                    {getCurrencySymbol(transaction.transaction_currency)}{" "}
                    {formatNumber(item.item_mrp)} X {item.quantity}
                  </span>
                </Tooltip>
              </p>
              <p>
                <span style={{ background: "#f0f2f5", padding: "0 5px" }}>
                  SKU: {item.sku}
                </span>
              </p>
            </div>
          </Row>
        )),
    },
  ];

  if (transaction.transaction_data.costs.product_total) {
    reviewColumns.push({
      title: "Total Product Price",
      dataIndex: null,
      width: "12.5%",
      render: () => (
        <p className="blue-text">
          {getCurrencySymbol(transaction.transaction_currency)}{" "}
          {formatNumber(
            Number(transaction.transaction_data.costs.product_total).toFixed(2)
          )}
        </p>
      ),
    });
  }

  if (
    transaction.transaction_data.costs.fulfillment &&
    transaction.transaction_data.costs.fulfillment.total
  ) {
    reviewColumns.push({
      title: "Fulfilment Fee",
      dataIndex: null,
      width: "12.5%",
      render: () => {
        const amount =
          transaction.transaction_data.costs.fulfillment &&
          transaction.transaction_data.costs.fulfillment.total
            ? transaction.transaction_data.costs.fulfillment.total
            : 0;
        return (
          <p className="blue-text">
            {getCurrencySymbol(transaction.transaction_currency)}{" "}
            {formatNumber(Number(amount).toFixed(2))}
          </p>
        );
      },
    });
  }

  reviewColumns.push({
    title: "Forward Shipping Fee",
    dataIndex: "costs.shipping.total",
    width: "12.5%",
    render: () => (
      <p className="blue-text">
        {getCurrencySymbol(transaction.transaction_currency)}{" "}
        {formatNumber(
          Number(transaction.transaction_data.costs.shipping.total).toFixed(2)
        )}
      </p>
    ),
  });

  if (transaction.transaction_currency === "INR") {
    reviewColumns.push({
      title: "COD Fee",
      key: "fee",
      dataIndex: "costs",
      width: "8.33%",
      render: () => {
        const costs = transaction.transaction_data.costs;
        let text = costs.cod && costs.cod.total ? costs.cod.total : 0;
        return (
          <p className="blue-text">
            {Number(text) === 0
              ? "N.A."
              : `${getCurrencySymbol(
                  transaction.transaction_currency
                )} ${formatNumber(Number(text).toFixed(2))}`}
          </p>
        );
      },
    });
  }

  reviewColumns.push({
    title: "Total",
    key: "total",
    dataIndex: "costs",
    width: "11.34%",
    render: () => {
      return (
        <p className="blue-text">
          {getCurrencySymbol(transaction.transaction_currency)}{" "}
          {formatNumber(transaction.transaction_amount, 2)}
        </p>
      );
    },
  });

  const zeroFF =
    !transaction.transaction_data.costs.fulfillment ||
    transaction.transaction_data.costs.fulfillment.total <= 0;
  const zeroPP = transaction.transaction_data.costs.product_total <= 0;
  const hasCOD =
    transaction.transaction_currency === "INR" &&
    transaction.transaction_data.costs &&
    transaction.transaction_data.costs.cod &&
    transaction.transaction_data.costs.cod.total > 0;

  let detailsColWidth =
    100 -
    15.33 - // order id & name
    12.5 - // shipping
    10.34; // total

  if (!zeroPP) {
    detailsColWidth -= 12.5;
  }
  if (!zeroFF) {
    detailsColWidth -= 12.5;
  }
  if (hasCOD) {
    detailsColWidth -= 8.33;
  }

  return (
    <>
      <Table
        rowKey={(record) => `${record["orderid"]}_${record["lineitem_id"]}`}
        pagination={false}
        columns={reviewColumns}
        dataSource={[transaction]}
        scroll={{ y: 300 }}
      />
      {transaction && (
        <div
          style={{
            display: "table",
            width: "100%",
            padding: "10px 0",
            boxShadow: "0 -50px 50px rgba(0,0,0,0.25)",
            marginTop: "2px",
          }}
        >
          <div
            style={{
              width: "15.33%",
              display: "inline-block",
              padding: "4px 16px",
            }}
          />
          <div
            style={{
              width: `${detailsColWidth}%`,
              display: "inline-block",
              padding: "4px 16px 4px 78px",
            }}
          >
            <h3 style={{ margin: 0 }}>Total</h3>
          </div>
          {transaction.transaction_data.costs.product_total ? (
            <div
              style={{
                width: "12.5%",
                display: "inline-block",
                padding: "4px 16px",
              }}
            >
              {getCurrencySymbol(transaction.transaction_currency)}{" "}
              {formatNumber(
                transaction.transaction_data.costs.product_total,
                2
              )}
            </div>
          ) : null}
          {transaction.transaction_data.costs.fulfillment &&
          transaction.transaction_data.costs.fulfillment.total ? (
            <div
              style={{
                width: "12.5%",
                display: "inline-block",
                padding: "4px 16px",
              }}
            >
              {getCurrencySymbol(transaction.transaction_currency)}{" "}
              {formatNumber(
                transaction.transaction_data.costs.fulfillment &&
                  transaction.transaction_data.costs.fulfillment.total
                  ? transaction.transaction_data.costs.fulfillment.total
                  : 0,
                2
              )}
            </div>
          ) : null}
          <div
            style={{
              width: "12.5%",
              display: "inline-block",
              padding: "4px 16px",
            }}
          >
            {getCurrencySymbol(transaction.transaction_currency)}{" "}
            {formatNumber(transaction.transaction_data.costs.shipping.total, 2)}
          </div>
          {transaction.transaction_currency === "INR" && (
            <div
              style={{
                width: "8.33%",
                display: "inline-block",
                padding: "4px 16px",
              }}
            >
              {getCurrencySymbol(transaction.transaction_currency)}{" "}
              {formatNumber(
                transaction.transaction_data.costs.cod &&
                  transaction.transaction_data.costs.cod.total
                  ? transaction.transaction_data.costs.cod.total
                  : 0,
                2
              )}
            </div>
          )}
          <div
            style={{
              width: "10.34%",
              display: "inline-block",
              padding: "4px 16px",
              fontSize: 16,
            }}
          >
            <b>
              {getCurrencySymbol(transaction.transaction_currency)}{" "}
              {formatNumber(transaction.transaction_amount, 2)}
            </b>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderPlaced;
