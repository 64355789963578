import React, { Component } from "react";
import { Modal, Button, Icon } from "antd";
import "./index.scss";

class ShowImageModal extends Component {
  state = {
    index: 0,
    leftIconDisabled: true,
    rightIconDisabled: true,
  };
  componentDidUpdate(pp) {
    if (this.props.visible !== pp.visible) {
      this.setState(
        {
          index: this.props.defaultIndex,
        },
        () => {
          this.setState((state) => ({
            leftIconDisabled: state.index === 0,
            rightIconDisabled: state.index === this.props.files.length - 1,
          }));
        }
      );
    }
  }

  // this function used for  move image to left side
  onLeftClick() {
    let currentIndex = this.state.index;
    currentIndex--;
    if (currentIndex < 0) {
      currentIndex = 0;
    }
    this.setState({
      index: currentIndex,
      leftIconDisabled: currentIndex === 0,
      rightIconDisabled: currentIndex === this.props.files.length - 1,
    });
  }
  // this function used for  move image to right  side
  onRightClick() {
    let currentIndex = this.state.index;
    currentIndex++;
    if (currentIndex >= this.props.files.length) {
      currentIndex = this.props.files.length - 1;
    }
    this.setState({
      index: currentIndex,
      leftIconDisabled: currentIndex === 0,
      rightIconDisabled: currentIndex === this.props.files.length - 1,
    });
  }
  render() {
    let files = this.props.files;
    let index = this.state.index;
    let imageTag = true;

    if (files.length > 0) {
      if (files[index].filetype === "video") {
        imageTag = false;
      }
    }

    return (
      <Modal
        title={null}
        visible={this.props.visible}
        onCancel={this.props.toggleShowImageModal}
        width={800}
        className="image-modal"
        // footer={
        //   <div>
        //     <a
        //       target="_self"
        //       download=""
        //       className="ant-btn btn-primary"
        //       href={`${
        //         process.env.REACT_APP_API_URL
        //       }/disputes/get_dispute_media/${files[index]}`}
        //       // download={files[index]}
        //     >
        //       Download
        //     </a>
        //   </div>
        // }
        footer={null}
      >
        <Button
          className="image-arrow"
          style={{ left: 0 }}
          onClick={() => this.onLeftClick()}
          disabled={this.state.leftIconDisabled}
        >
          <Icon type="left" />
        </Button>

        {!!files && files[index] && !!files[index].file ? (
          <div className="img-wraper">
            {" "}
            {imageTag ? (
              <img
                src={`${files[index].file}`}
                alt="No found"
                className="image-size"
              />
            ) : (
              <video controls className="video-size">
                <source src={`${files[index].file}`} type="video/mp4" />
              </video>
            )}
          </div>
        ) : null}

        <Button
          className="image-arrow"
          style={{ right: 0 }}
          onClick={() => this.onRightClick()}
          disabled={this.state.rightIconDisabled}
        >
          <Icon type="right" />
        </Button>
      </Modal>
    );
  }
}
export default ShowImageModal;
