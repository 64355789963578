import React, { Component } from "react";
import { Tabs, Button } from "antd";
import "./index.scss";
import Resetpassword from "./Resetpassword";
const { TabPane } = Tabs;
class RightContainer extends Component {
  state = {
    activeKey: "1",
  };
  render() {
    return (
      <div className="right-container-signup">
        <Tabs activeKey={this.state.activeKey}>
          <TabPane key="1">
            <Resetpassword {...this.props} />
          </TabPane>
          <TabPane key="2">
            <div>3</div>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default RightContainer;
