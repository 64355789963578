import React, { Component } from "react";
import { Row, Skeleton, Alert, Card, Pagination, Modal, message } from "antd";
import axios from "axios";
import "./index.scss";
import DisputeCard from "./DisputeCard";
import NotFoundCard from "../Common/NotFoundCard";
import ErrorCard from "../Common/ErrorCard";
import AddDisputeModal from "./AddDisputeModal";
import ShowImageModal from "./ShowImageModal";
import { connect } from "react-redux";
import qs from "qs";
import DisputeNav from "./DisputeNav";

class Disputes extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: "",
    errorCode: "",
    disputes: [],
    shipments: [],
    tabcount: [],
    total: 0,
    paging: {
      page: 1,
      pageSize: 25,
    },
    addDisputeModal: {
      visible: false,
    },
    filters: {
      startDate: "",
      endDate: "",
      status: "",
      search: "",
    },
    showImageModal: {
      visible: false,
      index: 0,
      files: [],
    },
  };

  // this function used for show  big images
  toggleShowImageModal(i = 0, files = []) {
    this.setState({
      showImageModal: {
        visible: !this.state.showImageModal.visible,
        index: i,
        // imageTag: imageTag,
        files: files,
      },
    });
  }

  // this function used for open escalation modal
  toggleAddDisputeModal() {
    this.setState({
      addDisputeModal: {
        visible: !this.state.addDisputeModal.visible,
      },
    });
  }

  componentDidMount() {
    const params = qs.parse(window.location.search.replace(/\?/, ""));

    if (params.search) {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: params.search,
            status: "all",
          },
        },
        () => this.loadData()
      );
    } else if (window.location.hash) {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: window.location.hash,
            status: "all",
          },
        },
        () => this.loadData()
      );
    } else {
      this.loadData();
    }
  }

  componentDidUpdate(pp) {
    if (pp.location.search !== this.props.location.search) {
      const params = qs.parse(this.props.location.search.replace(/\?/g, ""));

      if (window.location.hash) {
        this.setState(
          {
            filters: {
              ...this.state.filters,
              search: window.location.hash,
            },
          },
          () => this.loadData()
        );
      } else {
        this.setState(
          {
            filters: {
              ...this.state.filters,
              search: params.search,
            },
            paging: { page: 1, pageSize: 10 },
          },
          () => this.loadData()
        );
      }
    }
  }
  // this  function used for load the all dispute store data, all data show in disputes state
  loadData() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/disputes/get_store_disputes",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            filters: this.state.filters,
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                disputes: res.data.dispute,
                total: res.data.total,
                tabcount: res.data.tab_counts,
                loading: false,
                error: false,
                errorMsg: "",
              });
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
                loading: false,
              });
            }
          })
          .catch((e) => {
            // this.setState({
            //   error: true,
            //   errorMsg: e.message,
            //   loading: false,
            // });

            if (!!e.request) {
              this.setState({
                error: true,
                errorCode: e.request.status,
                errorMsg: e.request.statusText,
                loading: false,
              });
            } else {
              this.setState({
                error: false,
                errorMsg: e.message,
                loading: true,
              });
            }
          });
      }
    );
  }

  // this function used for search escalation link
  searchhandler() {
    if (window.location.href.includes("escalations")) {
      this.props.history.push(
        `/${this.props.match.params.storegeo}/${this.props.match.params.storeid}/escalation/escalations?search=${this.state.filters.search}`
      );
    } else {
      this.props.history.push(
        `/${this.props.match.params.storegeo}/${this.props.match.params.storeid}/escalation/escalations?search=${this.state.filters.search}`
      );
    }
  }

  // this function used for filter data in global search
  changeFilter(kvpairs) {
    this.setState(
      {
        filters: { ...this.state.filters, ...kvpairs },
        paging: { ...this.state.paging, page: 1 },
      },
      () => this.searchhandler(),
      () => this.loadData()
    );
  }

  // this function used for range picker and tabs
  changeFilter2(kvpairs) {
    this.setState(
      {
        filters: { ...this.state.filters, ...kvpairs },
        paging: { ...this.state.paging, page: 1 },
      },

      () => this.loadData()
    );
  }

  //this function used for go  particular page in pagination
  goToPage(page) {
    this.setState(
      {
        paging: { ...this.state.paging, page },
      },
      () => {
        this.loadData();
      }
    );
  }

  // this function used for event in global search
  changeHandler(kvpairs) {
    this.setState({
      filters: { ...this.state.filters, ...kvpairs },
    });
  }

  // this function used for  show size of pagination
  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        paging: { ...this.state.paging, pageSize: pageSize },
      },
      () => this.loadData()
    );
  }

  render() {
    const disputeStatusColors = {
      open: "#FC824E",
      pending: "#FC554E",
      close: "#2BC823",
      in_processing: "#FC554E",
      cancelled: "#f44336",
    };
    const disputeStatusText = {
      open: "Open",
      pending: "in_Processing",
      close: "Closed",
      cancelled: "Cancelled",
    };
    const shipmentStatusColors = {
      processing: "#FC554E",
      manifested: "#FF7A45",
      transit: "#FFC53D",
      delivered: "#73D13D",
      rto: "#FF4D4F",
      ndr_actionable: "#FF4D4F",
    };
    const shipmentStatusText = {
      processing: "In Processing",
      manifested: "In Processing",
      transit: "In Transit",
      delivered: "Delivered",
      rto: "Returned",
      ndr_actionable: "NDR",
    };

    const PageWrapper = (children) => {
      return (
        <div className="my-disputes">
          <DisputeNav
            filters={this.state.filters}
            changeHandler={(e) => this.changeHandler(e)}
            changeFilter={(e) => this.changeFilter(e)}
            changeFilter2={(e) => this.changeFilter2(e)}
            toggleAddDisputeModal={() => this.toggleAddDisputeModal()}
            store={this.props.store}
            tabcounts={this.state.tabcount}
            loading={this.state.loading}
          />

          {children}
          <Row>
            <Pagination
              locale={{ jump_to: "Go to page" }}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              showQuickJumper
              showSizeChanger
              pageSizeOptions={["10", "25", "50"]}
              onShowSizeChange={(p, d) => this.onShowSizeChange(p, d)}
              current={this.state.paging.page}
              onChange={(page) => this.goToPage(page)}
              total={this.state.total}
              defaultPageSize={this.state.paging.pageSize}
            />
          </Row>
        </div>
      );
    };

    if (this.state.loading) {
      return PageWrapper(
        <Row className="skeleton">
          <Skeleton active />
        </Row>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <ErrorCard
          errorMsg={this.state.errorMsg}
          errorCode={this.state.errorCode}
        />
      );
    }

    return PageWrapper(
      <div>
        {this.state.disputes.length > 0 ? (
          this.state.disputes.map((dispute) => (
            <DisputeCard
              {...this.props}
              key={dispute._id["$oid"]}
              dispute={dispute}
              disputeStatusColors={disputeStatusColors}
              disputeStatusText={disputeStatusText}
              shipmentStatusColors={shipmentStatusColors}
              shipmentStatusText={shipmentStatusText}
              toggleShowImageModal={(i, files) =>
                this.toggleShowImageModal(i, files)
              }
              status={this.state.filters.status}
              loadData={() => this.loadData()}
              storeid={this.props.match.params.storeid}
              dispute_vfid={dispute.dispute_vfid}
            />
          ))
        ) : (
          <NotFoundCard
            mainHeading={"Oops! No Data Found."}
            secondHeading={
              <p>Looks like there are no escalations in this page.</p>
            }
          />
        )}

        <AddDisputeModal
          {...this.props}
          visible={this.state.addDisputeModal.visible}
          toggleAddDisputeModal={() => this.toggleAddDisputeModal()}
          onAddCountinue={(type, id) => this.onAddCountinue(type, id)}
          loading={this.state.addDisputeModal.loading}
          shipments={this.state.shipments}
          selectShipment={this.state.addDisputeModal.selectShipment}
          reloadList={() => this.loadData()}
        />
        <ShowImageModal
          toggleShowImageModal={() => this.toggleShowImageModal()}
          visible={this.state.showImageModal.visible}
          files={this.state.showImageModal.files}
          defaultIndex={this.state.showImageModal.index}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  store: state.auth.auth.store,
});
export default connect(mapStateToProps)(Disputes);
