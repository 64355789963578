import React, { Component } from "react";
import { Button, Input, Modal, Icon, message } from "antd";
import { ReactComponent as editican } from "../../../../../assets/images/icons/editican.svg";
import { ReactComponent as rightican } from "../../../../../assets/images/icons/right.svg";
import axios from "axios";
import "../../../index.scss";

class SupportEmail extends Component {
  state = {
    minutes: 0,
    seconds: 30,
    loading: false,
    myinterval: 0,
  };

  startTimer() {
    if (this.state.myinterval != 0) {
      clearInterval(this.state.myinterval);
    }
    this.setState(
      {
        seconds: 30,
      },
      () => {
        this.state.myinterval = setInterval(() => {
          const { seconds, minutes } = this.state;
          if (seconds > 0) {
            this.setState(({ seconds }) => ({
              seconds: seconds - 1,
            }));
          }
          if (seconds === 0) {
            if (minutes === 0) {
              clearInterval(this.state.myinterval);
            } else {
              this.setState(({ minutes }) => ({
                seconds: 30,
              }));
            }
          }
        }, 1000);
      }
    );
  }

  updateEmail() {
    // if (this.props.resetTimer) {
    this.startTimer();
    this.props.updateEmail();
    // } else {
    // this.props.updatePhonenumber();
    // }
  }

  resendOtp(event) {
    event.preventDefault();
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/generateotp",
          method: "post",
          withCredentials: true,
          data: {
            type: "email",
            location: "support",
            status: "resend",
            email: this.props.supportEmail2,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
              });
              message.success(res.data.msg);
              this.startTimer();
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
            message.error(e.msg);
          });
      }
    );
  }

  inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  componentDidUpdate(pp) {
    if (this.props.visible !== pp.visible) {
      if (!this.props.visible && this.myInterval) {
        clearInterval(this.myInterval);
      }
      if (this.props.visible) {
        this.startTimer();
      }
    }
  }

  render() {
    const { visible, onCancel } = this.props;
    const emailVerify =
      !!this.props.emailotp.emailotp1.length &&
      !!this.props.emailotp.emailotp2.length &&
      !!this.props.emailotp.emailotp3.length &&
      !!this.props.emailotp.emailotp4.length &&
      !!this.props.emailotp.emailotp5.length &&
      !!this.props.emailotp.emailotp6.length;
    return (
      <Modal
        className="support-email-phone-modal"
        title={
          <div className="email-header">
            <p className="support-phone-number-txt">Support Email Address</p>
            <p className="number-verify-txt">
              Please enter the OTP received on provided email to verify
            </p>
          </div>
        }
        visible={visible}
        onCancel={onCancel}
        closable={!this.props.saving}
        afterClose={this.resetModal}
        width={456}
        footer={null}
      >
        <div>
          <div className="header-support-txt">
            <p>Your Email Address</p>
            {this.props.chnagenumber ? (
              <p>
                <button
                  className="save-number-txt"
                  onClick={() => this.updateEmail()}
                >
                  <span className="save-ican">
                    <Icon component={rightican} />
                  </span>
                  Save Email Address
                </button>
              </p>
            ) : (
              <p>
                <button
                  className="change-number-txt"
                  onClick={() => this.props.toggleChnageNumber()}
                >
                  <span className="edit-ican">
                    <Icon component={editican} />
                  </span>
                  Change Email Address
                </button>
              </p>
            )}
          </div>

          <div className="input-number-data">
            <p className="input-email-txt">
              {this.props.chnagenumber ? (
                <Input
                  type="text"
                  placeholder="Enter text here"
                  value={this.props.supportEmail2}
                  onChange={(e) => this.props.handleEmailChnage(e.target.value)}
                />
              ) : (
                <p className="disable-phonenumber">
                  {this.props.supportEmail2}
                </p>
              )}
            </p>
          </div>
          <p className="otp-number-txt">
            Your will receive an OTP on this email address.
          </p>
        </div>

        <p className="otp-txt">Please wait 1-2 min for the OTP to arrive</p>

        <div>
          <form onSubmit={this.handleSubmit}>
            <div className="otp-verify">
              <input
                onPaste={(e) => this.props.pasteFuncEmail(e)}
                name="otp1"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.emailotp.emailotp1}
                onKeyPress={this.keyPressed}
                onChange={(e) =>
                  this.props.otpChnageEmail("emailotp1", e.target.value)
                }
                tabIndex="1"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp2"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.emailotp.emailotp2}
                onChange={(e) =>
                  this.props.otpChnageEmail("emailotp2", e.target.value)
                }
                tabIndex="2"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp3"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.emailotp.emailotp3}
                onChange={(e) =>
                  this.props.otpChnageEmail("emailotp3", e.target.value)
                }
                tabIndex="3"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp4"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.emailotp.emailotp4}
                onChange={(e) =>
                  this.props.otpChnageEmail("emailotp4", e.target.value)
                }
                tabIndex="4"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp5"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.emailotp.emailotp5}
                onChange={(e) =>
                  this.props.otpChnageEmail("emailotp5", e.target.value)
                }
                tabIndex="5"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
              <input
                name="otp6"
                type="text"
                autoComplete="off"
                className="otp-number"
                value={this.props.emailotp.emailotp6}
                onChange={(e) =>
                  this.props.otpChnageEmail("emailotp6", e.target.value)
                }
                tabIndex="6"
                maxLength="1"
                onKeyUp={(e) => this.inputfocus(e)}
              />
            </div>
            <div className="resend-code">
              <span>
                <button
                  className={
                    this.state.minutes == 0 && this.state.seconds === 0
                      ? "resend-code-txt "
                      : "resend-code-txt2"
                  }
                  loading={this.state.loading}
                  onClick={(e) => this.resendOtp(e)}
                >
                  You can resend code in
                </button>
              </span>
              <span>
                {this.state.minutes}:
                {this.state.seconds < 10
                  ? `0${this.state.seconds}`
                  : this.state.seconds}
              </span>
            </div>
            <Button
              className={
                emailVerify ? "verify-continue-btn" : "verify-continue-btn2"
              }
              loading={this.props.emailLoading}
              disabled={!emailVerify}
              type="submit"
              onClick={() => this.props.verifyEmailContinue()}
            >
              Verify & Continue
            </Button>
            <Button className="cancel-btn" type="submit" onClick={onCancel}>
              Cancel
            </Button>
          </form>
        </div>
      </Modal>
    );
  }
}

export default SupportEmail;
