import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Icon, Button } from "antd";
import logo from "../../assets/images/logos/logo.svg";
import { ReactComponent as arrowican } from "../../assets/images/icons/arrow.svg";
// import Woocommerce from "../../assets/images/icons/logoWoocommerce.png";
import { ReactComponent as Shopify } from "../../assets/images/icons/shopify2.svg";
// import { ReactComponent as Custom } from "../../assets/images/icons/custom.svg";
import { ReactComponent as Woocommerce } from "../../assets/images/icons/wocommerce2.svg";
// import Shopify from "../../assets/images/icons/logoShopify.png";
import KycWocommerce from "./KycWocommerce";
import ShopifyConnect from "./ShopifyConnect";
import VerifyProfile from "./VerifyProfile";
import axios from "axios";
import { connect } from "react-redux";
import "./index.scss";

class OnboardingKYC extends Component {
  state = {
    loading: false,
    activeStep: 2,
    kycData: {},
    kyctype: "",
    stepStatus: {
      4: 0,
      5: 0,
      6: 0,
      7: 0,
    },
    type: "",
  };

  setType = (type) => {
    this.setState({ type });
  };

  previousStep() {
    this.setState({
      stepStatus: {
        ...this.state.stepStatus,
        4: 0,
      },
      activeStep: 4,
    });
  }

  componentDidMount() {
    axios({
      url: process.env.REACT_APP_API_URL + "/user/get_onboarding_kyc",
      method: "post",
      data: {
        storeid: this.props.auth.store.id,
      },
      withCredentials: true,
    })
      .then((res) => {
        res.data.steps[2] = 1;
        res.data.steps[3] = 1;
        // if(res.data.steps[2]!==1 || res.data.steps[3] !== 1)
        this.setState(
          {
            stepStatus: { ...res.data.steps },
            kycData: res.data.kycData,
            loading: false,
            error: "",
          },
          () => {
            const firstIncompleteStep = Object.keys(this.state.stepStatus).find(
              (step) =>
                this.state.stepStatus[step] === 0 &&
                step > this.state.activeStep
            );

            this.setState({
              activeStep: Number(firstIncompleteStep),
            });
            if (Object.values(res.data.steps).indexOf(0) === -1) {
              this.props.history.push("/switch-store");
            }
          }
        );
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: e.message,
        });
      });
  }

  stepcompletestatus() {
    if (this.state.stepStatus[4] === 1 && this.state.stepStatus[5] === 1) {
      return "completed";
    }
    if (this.state.activeStep === 4 || this.state.activeStep === 5) {
      return "active";
    }
  }

  stepCompletestatus2() {
    if (this.state.stepStatus[6] === 1) {
      return "completed";
    }
    if (this.state.activeStep === 6) {
      return "active";
    }
  }

  markCurrentCompleted = (cb) => {
    this.setState(
      (state) => ({
        stepStatus: {
          ...state.stepStatus,
          [state.activeStep]: 1,
        },
      }),
      () => {
        if (cb) {
          cb();
        }
      }
    );
  };

  goToNextStep = (markCurrentCompleted = true) => {
    const nextStepUpdate = () => {
      let firstIncompleteStep = Object.keys(this.state.stepStatus).find(
        (step) =>
          this.state.stepStatus[step] === 0 && step > this.state.activeStep
      );
      if (
        !markCurrentCompleted &&
        (Number(firstIncompleteStep) === 4 || Number(firstIncompleteStep) === 5)
      ) {
        firstIncompleteStep = 6;
      }
      if (firstIncompleteStep !== undefined) {
        this.setState((state) => ({
          activeStep: Number(firstIncompleteStep),
        }));
      } else {
        this.props.history.push("/switch-store");
      }
    };

    if (markCurrentCompleted) {
      this.markCurrentCompleted(() => {
        nextStepUpdate();
      });
    } else {
      nextStepUpdate();
    }
  };

  render() {
    let currentKYCStep = 4;
    if (this.state.stepStatus["4"] !== 1) {
      currentKYCStep = 4;
    } else if (this.state.stepStatus["5"] !== 1) {
      currentKYCStep = 5;
    } else if (this.state.stepStatus["6"] !== 1) {
      currentKYCStep = 6;
    }

    return (
      <div className="onboardingkyc-container">
        <div className="onboarding">
          {(this.state.activeStep === 4 ||
            this.state.activeStep === 5 ||
            this.state.activeStep === 6) && (
            <Row type="flex" justify="center">
              <img className="logo" src={logo} alt="Logo" />
            </Row>
          )}

          {this.state.loading ? (
            <Row>
              <Col xs={8} offset={8}>
                <Card loading />
              </Col>
            </Row>
          ) : (
            <>
              {(this.state.activeStep === 4 || this.state.activeStep === 5) && (
                <>
                  <VerifyProfile
                    {...this.props}
                    kycData={this.state.kycData}
                    step={currentKYCStep}
                    goToNextStep={this.goToNextStep}
                    stepStatus={this.state.stepStatus}
                    activeStep={this.state.activeStep}
                    nonindiankycdata1={this.state.nonindiankycdata1}
                    nonIndianPreviousDataStoe={(key, value) =>
                      this.nonIndianPreviousDataStoe(key, value)
                    }
                    previousStep={() => this.previousStep()}
                  />
                </>
              )}

              {this.state.activeStep === 6 && (
                <div className="content-container">
                  {this.state.type === "shopify" ? (
                    <ShopifyConnect
                      {...this.props}
                      goToNextStep={this.goToNextStep}
                    />
                  ) : this.state.type === "woocommerce" ? (
                    <KycWocommerce
                      {...this.props}
                      goToNextStep={this.goToNextStep}
                    />
                  ) : (
                    <>
                      <p className="select-option-txt">
                        Select your Store Platform :
                      </p>
                      <div className="profile-indian-global">
                        <div className="profile-indian">
                          <button
                            className="profile-type-card"
                            onClick={() => this.setType("shopify")}
                          >
                            <span className="Shopify_flag">
                              <Icon component={Shopify} />
                            </span>

                            <span className="Shopify_footer">Shopify</span>
                            <span className="footer">Continue</span>
                          </button>
                        </div>

                        <div className="profile-global">
                          <button
                            href="#"
                            className="profile-type-card"
                            onClick={() => this.setType("woocommerce")}
                          >
                            <span className="wocommerce_flag">
                              <Icon component={Woocommerce} />
                            </span>
                            <span className="Shopify_footer">WooCommerce</span>
                            <span className="footer">Continue</span>
                          </button>
                        </div>
                      </div>
                      <div className="Skip_woocommerce">
                        <Link
                          to={`/${this.props.auth.store.store_geo}/${
                            this.props.auth.store.id
                          }`}
                        >
                          <Button className="onboarding-skip">
                            Skip This Step
                          </Button>
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        <div className="onboarding-sidebar">
          {/* <div className="go-dashboard">
            {window.location.href.split("#").length > 1 ? (
              <Link to={`/profile`}>
                Go To Dashboard
                <Icon component={arrowican} />
              </Link>
            ) : (
              <Link
                to={`/${this.props.auth.store.store_geo}/${
                  this.props.auth.store.id
                }`}
              >
                Go To Dashboard
                <Icon component={arrowican} />
              </Link>
            )}
          </div> */}
          <p className="welcome-vfulfill">Welcome To vFulfill</p>

          <div className="steps">
            {/* <ul>
              <li className="completed getting-started">
                Verify Email & Number
              </li>
              <li
                className={`${
                  this.state.activeStep === 4 ||
                  this.state.activeStep === 5 ||
                  this.state.activeStep === 6
                    ? "active"
                    : ""
                } ${
                  this.state.stepStatus[6] === 1 ? "completed" : "not-completed"
                }`}
              >
                Complete Your Profile
              </li>
              <li
                className={`${this.state.activeStep === 6 ? "active" : ""} ${
                  this.state.stepStatus[6] === 1 ? "completed" : "not-completed"
                }`}
              >
                Setting up your store
              </li>
              <li className="not-completed">Preparing for orders</li>
            </ul> */}

            <div className="right-side-step">
              <div className="kyc-step ">
                <div className="flex-step">
                  <div className="circle completed" />
                  <div className="step-concat">
                    <span className="onboarding-step-txt">
                      Verify Email & Number
                    </span>
                  </div>
                </div>
              </div>

              <div className="kyc-step ">
                <div className="flex-step">
                  <div
                    className={`circle ${this.stepcompletestatus()}
                      `}
                  />
                  <div className="step-concat">
                    <span className="onboarding-step-txt Profile-txt">
                      Complete Your Profile
                    </span>
                  </div>
                </div>
              </div>

              <div className="kyc-step ">
                <div className="flex-step">
                  <div
                    className={`circle ${this.stepCompletestatus2()}
                      `}
                  />
                  <div className="step-concat">
                    <span className="onboarding-step-txt">
                      Setting up your store
                    </span>
                  </div>
                </div>
              </div>

              <div className="kyc-step ">
                <div className="flex-step">
                  <div className="circle " />
                  <div className="step-concat">
                    <span className="onboarding-step-txt">
                      Preparing for orders
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(OnboardingKYC);
