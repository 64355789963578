import React, { Component } from "react";
import "./index.scss";
import { ReactComponent as Circle } from "../../assets/images/icons/circleShadowSignup.svg";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import LeftContainer from "./LeftContainer";
import RightContainer from "./RightContainer";
// import { ReactComponent as EnablerLogos } from "../../assets/images/enablerlogos.svg";

class Signupnew extends Component {
  state = {
    whatsapp: true,
    email: "",
    signupapiurl: "/login/signup_insider",
  };
  componentDidMount() {
    document.title = this.props.title;
  }
  render() {
    const { auth } = this.props;
    if (auth.logged_in) return <Redirect to={`/switch-store`} />;
    return (
      <div className="signup-container-new">
        <span className="shadow-container">
          <Circle />
        </span>
        <div className="mainCard-signup">
          <LeftContainer />
          <RightContainer />
        </div>
        {/* <div className="enabler-logos">
          <EnablerLogos />
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(Signupnew);
