import { message, Spin,Upload,Icon } from "antd";
import React from "react";
import axios from "axios";
import "./index.scss";
import { ReactComponent as CrossTick } from "../../../../../../../assets/images/icons/close.svg";
import { ReactComponent as VideoTick } from "../../../../../../../assets/images/icons/video-play.svg";
import { ReactComponent as FilesIcon } from "../../../../../../../assets/images/icons/files.svg";
const { Dragger } = Upload;
class ImageUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedImages: [],
      loading: false,
    };
    this.fileInputRef = React.createRef();
  }

  handleButtonClick = () => {
    this.fileInputRef.current.click();
  };

  render() {
    const { selectedImages } = this.state;
    if (this.props.loading) {
      return <div><Spin /></div>;
    }
    return (
      <div className="opops">
        <h2 className="heading">
        Upload Private Label Design: ( jpg, .jpeg, .png, .ai, .eps, .cdr, .pdf)
        </h2>
        <Dragger
          accept=".jpg, .jpeg, .png, .ai, .eps, .cdr, .pdf"
          {...this.props.imageprops}
          className="dragger-packing"
        >
          <p className="ant-upload-drag-icon">
            <Icon type="upload" />
          </p>
          <p className="ant-upload-text" style={{fontSize:"12px",color:"rgba(0, 0, 0, 0.65)"}}>
            Click or drag file to this area to upload{" "}
          </p>
        </Dragger>
        <div className="cointainer">
       
          {this.props.selectedImages.length > 0 && (
            <div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {this.props.selectedImages.map((image, index) => {
                  let arr = image.split(".");
                  let type = "image";
                  let imageTYPE = ["jpeg", "jpg", "png", "gif", "bmp"];
                  if (imageTYPE.includes(arr[arr.length - 1])) {
                    type = "image";
                  } else if (arr[arr.length - 1] === "pdf") {
                    type = "pdf";
                  } else {
                    type = "other";
                  }
                  if (type === "pdf") {
                    return (
                      <span className="image-cointainer">
                        <iframe
                          src={image}
                          style={{
                            height: "110px",
                            width: "110px",
                            marginRight: "10px",
                          }}
                        />
                        <span
                          className="icon-cointainer-pdf"
                          onClick={() => this.props.handleCancel(index)}
                        >
                          <CrossTick />
                        </span>
                      </span>
                    );
                  }
                  if (type === "other") {
                    return (
                      <span className="image-cointainer">
                        <span className="io-container">
                          <FilesIcon />
                        </span>

                        <span
                          className="icon-cointainer"
                          onClick={() => this.props.handleCancel(index)}
                        >
                          <CrossTick />
                        </span>
                      </span>
                    );
                  }
                  return (
                    <span className="image-cointainer">
                      {" "}
                      <img
                        key={index}
                        src={image}
                        alt={`Selected ${index + 1}`}
                        style={{
                          maxWidth: "110px",
                          marginRight: "10px",
                          height: "110px",
                          marginBottom: 12,
                        }}
                      />
                      <span
                        className="icon-cointainer"
                        onClick={() => this.props.handleCancel(index)}
                      >
                        <CrossTick />
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ImageUploader;
