import React, { Component } from "react";
import ShipmentRequestsNav from "./ShipmentRequestsNav";
import NotFoundCard from "../../Common/NotFoundCard";
import ErrorCard from "../../Common/ErrorCard";
import ShipmentRequestCard from "./ShipmentRequestCard";
import { Row, Skeleton, Pagination, message } from "antd";
import AddRequestModal from "./AddRequestModal";
import qs from "qs";
import axios from "axios";

class ShipmentRequests extends Component {
  state = {
    activeTab: "rvp",
    count: "",
    filters: {
      search: "",
      endDate: "",
      startDate: "",
      status: "",
      page_type: "rvp",
    },
    paging: {
      page: 1,
      per_page_count: 20,
      pageSize: 10,
    },

    shipmentRequest: [],
    errorMsg: "",
    errorCode: "",

    addRequestModal: {
      visible: false,
    },
    loading: false,
    shipments: [],
    type: undefined,
    id: "",
    loading2: false,
    selectShipment: "",
    selectedShipment: "",
    arrow2: "",
    toggleDisputeData: undefined,
    toggleNewRequestModal: {
      visible: false,
    },
  };

  toggleShipmentDisputeModal() {
    this.setState({
      toggleNewRequestModal: {
        visible: !this.state.visible,
      },
    });
  }

  CloseToggleShipmentDisputeModal() {
    this.setState({
      toggleNewRequestModal: {
        visible: false,
      },
    });
    this.toggleAddRequestModal();
  }

  toggleAddRequestModal() {
    this.setState({
      addRequestModal: {
        visible: !this.state.addRequestModal.visible,
      },
      type: "",
      id: "",
      selectShipment: "",
      selectedShipment: "",
    });
  }

  handleChange(key, value) {
    this.setState(
      {
        ...this.state,
        [key]: value,
      },
      () => this.setState({ arrow2: !this.state.arrow2 })
    );
  }

  handleChange2(key, value) {
    this.setState({
      ...this.state,
      [key]: value,
    });
  }

  onCountinue() {
    this.setState(
      {
        loading2: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/disputes/get_shipments",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            type: this.state.type,
            search_id: this.state.id,
            request: true,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                shipments: res.data.shipments,
                loading2: false,
              });
              this.toggleShipmentDisputeModal();
            } else {
              this.setState({
                loading2: false,
              });
              message.warning(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              loading2: false,
            });
            message.warning(e.message);
          });
      }
    );
  }

  changeFilter(filters) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...filters,
        },
        paging: { ...this.state.paging, page: 1 },
      },
      () => this.loadData()
    );
  }

  tabHandler(tab) {
    this.setState({ loading: true }, () => {
      this.setState(
        {
          activeTab: tab,
          loading: false,
          filters: {
            ...this.state.filters,
            page_type: tab,
          },
        },
        () => this.loadData()
      );
    });
  }

  changeHandler(kvpairs) {
    this.setState({
      filters: { ...this.state.filters, ...kvpairs },
    });
  }

  goToPage(page) {
    this.setState(
      {
        paging: { ...this.state.paging, page },
      },
      () => {
        this.loadData();
      }
    );
  }

  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        paging: { ...this.state.paging, pageSize: pageSize },
      },
      () => this.loadData()
    );
  }

  componentDidMount() {
    const params = qs.parse(window.location.search.replace(/\?/, ""));
    if (params.search) {
      if (this.props.location.hash === "#refund_end_customer") {
        this.setState(
          {
            activeTab: "refund",
            filters: {
              ...this.state.filters,
              page_type: "refund",
              search: params.search,
            },
          },
          () => this.loadData()
        );
      } else if (this.props.location.hash === "#reverse_pickup") {
        this.setState(
          {
            activeTab: "rvp",
            filters: {
              ...this.state.filters,
              page_type: "rvp",
              search: params.search,
            },
          },
          () => this.loadData()
        );
      } else if (this.props.location.hash === "#replacement") {
        this.setState(
          {
            activeTab: "replacement",
            filters: {
              ...this.state.filters,
              page_type: "replacement",
              search: params.search,
            },
          },
          () => this.loadData()
        );
      } else {
        this.setState(
          {
            filters: {
              ...this.state.filters,
              search: params.search,
            },
          },
          () => this.loadData()
        );
      }
    } else {
      this.loadData();
    }
  }

  componentDidUpdate(pp) {
    if (pp.location.search !== this.props.location.search) {
      const params = qs.parse(this.props.location.search.replace(/\?/g, ""));
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: params.search,
          },
          paging: { page: 1, pageSize: 10 },
        },
        () => this.loadData()
      );
    }
  }

  loadData() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL + "/disputes/get_shipment_escalation",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            filters: this.state.filters,
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                shipmentRequest: res.data.dispute,
                total: res.data.total,
                count: res.data.tab_counts,
                loading: false,
                error: false,
                errorMsg: "",
              });
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
                loading: false,
              });
            }
          })
          .catch((e) => {
            // this.setState({
            //   error: true,
            //   errorMsg: e.message,
            //   loading: false,
            // });

            if (!!e.request) {
              this.setState({
                error: true,
                errorCode: e.request.status,
                errorMsg: e.request.statusText,
                loading: false,
              });
            } else {
              this.setState({
                error: false,
                errorMsg: e.message,
                loading: true,
              });
            }
          });
      }
    );
  }

  downloadCSV() {
    axios({
      url: process.env.REACT_APP_API_URL + "/disputes/get_shipment_escalation",
      method: "post",
      withCredentials: true,
      data: {
        storeid: this.props.match.params.storeid,
        filters: this.state.filters,
        page: this.state.paging.page,
        pageSize: this.state.paging.pageSize,
        downloadcsv: 1,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  }

  render() {
    const disputeStatusColors = {
      open: "#FC824E",
      in_processing: "#FC554E",
      close: "#2BC823",
    };
    const disputeStatusText = {
      open: "Open",
      in_processing: "In Processing",
      close: "Closed",
    };

    const PageWrapper = (children) => {
      return (
        <div className="requestnav">
          <ShipmentRequestsNav
            changeFilter={(e) => this.changeFilter(e)}
            changeHandler={(e) => this.changeHandler(e)}
            rtoChangeHandler={(e) => this.rtoChangeHandler(e)}
            changeHandler2={(e) => this.changeHandler2(e)}
            tabHandler={(e) => this.tabHandler(e)}
            loading={this.state.loading}
            filters={this.state.filters}
            count={this.state.count}
            activeTab={this.state.activeTab}
            storeId={this.props.match.params.storeid}
            downloadCSV={() => this.downloadCSV()}
            hash={this.props.location.hash}
            toggleAddRequestModal={() => this.toggleAddRequestModal()}
          />

          {children}
          <Row>
            <Pagination
              locale={{ jump_to: "Go to page" }}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              showQuickJumper
              showSizeChanger
              pageSizeOptions={["10", "25", "50"]}
              onShowSizeChange={(p, d) => this.onShowSizeChange(p, d)}
              current={this.state.paging.page}
              onChange={(page) => this.goToPage(page)}
              total={this.state.total}
              defaultPageSize={this.state.paging.pageSize}
            />
          </Row>
        </div>
      );
    };

    if (this.state.loading) {
      return PageWrapper(
        <Row className="skeleton">
          <Skeleton active />
        </Row>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <ErrorCard
          errorMsg={this.state.errorMsg}
          errorCode={this.state.errorCode}
        />
      );
    }

    return PageWrapper(
      <div>
        {this.state.shipmentRequest.length > 0 ? (
          this.state.shipmentRequest.map((dispute) => (
            <ShipmentRequestCard
              {...this.props}
              key={dispute._id["$oid"]}
              dispute={dispute}
              disputeStatusColors={disputeStatusColors}
              disputeStatusText={disputeStatusText}
              // shipmentStatusColors={shipmentStatusColors}
              // shipmentStatusText={shipmentStatusText}
              toggleShowImageModal={(i, files) =>
                this.toggleShowImageModal(i, files)
              }
              activeTab={this.state.activeTab}
              refundDetail={dispute.refund_details}
              storeid={this.props.match.params.storeid}
            />
          ))
        ) : (
          <NotFoundCard
            mainHeading={"Oops! No Data Found."}
            secondHeading={
              <p>Looks like there are no escalations in this page.</p>
            }
          />
        )}
        {this.state.addRequestModal.visible ? (
          <AddRequestModal
            {...this.props}
            visible={this.state.addRequestModal.visible}
            toggleAddRequestModal={() => this.toggleAddRequestModal()}
            loading={this.state.addRequestModal.loading}
            // shipments={this.state.shipmentRequest}
            shipments={this.state.shipments}
            type={this.state.type}
            id={this.state.id}
            loading2={this.state.loading2}
            // selectShipment={this.state.addDisputeModal.selectShipment}
            reloadList={() => this.loadData()}
            selectShipment={this.state.selectShipment}
            selectedShipment={this.state.selectedShipment}
            arrow2={this.state.arrow2}
            handleChange={(key, value) => this.handleChange(key, value)}
            handleChange2={(key, value) => this.handleChange2(key, value)}
            onCountinue={() => this.onCountinue()}
            // toggleIfShipment={() => this.toggleIfShipment()}

            visible2={this.state.toggleNewRequestModal.visible}
            toggleShipmentDisputeModal={() => this.toggleShipmentDisputeModal()}
            CloseToggleShipmentDisputeModal={() =>
              this.CloseToggleShipmentDisputeModal()
            }
            activeTab={this.state.activeTab}
          />
        ) : null}
      </div>
    );
  }
}

export default ShipmentRequests;
