import React, { Component } from "react";
import "./index.scss";
import { Modal, Form, Input, Button, Select, message } from "antd";
import { ReactComponent as CloseIcon } from "../../../../assets/images/icons/close.svg";
import axios from "axios";

const { Option } = Select;

class AddUserForm extends React.Component {
  state = {
    loading: false,
    contiuneLoading: false,
    customRole: false,
    addNewRole: false,
    rolePermissions: {},
    stores: [],
    storeLoading: false,
    selectedStore: [],
    role_id: this.props.user.role_id,
  };

  fetchStores = (store) => {
    this.setState(
      {
        storeLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/store/get_user_stores",
          method: "post",
          data: {
            store,
          },
          withCredentials: true,
        })
          .then((res) => {
            this.setState({
              storeLoading: false,
              stores: res.data.stores,
            });
          })
          .catch((e) => {
            this.setState({
              storeLoading: false,
              stores: [],
            });
          });
      }
    );
  };

  componentDidUpdate(pp) {
    if (pp.new_role_id !== this.props.new_role_id) {
      this.props.form.setFieldsValue({ role: this.props.new_role_id });
      this.setState({
        customRole: false,
        addNewRole: false,
      });
    }
  }

  addEditUser() {
    this.props.form.validateFields((err, values) => {
      const { name, email, role, stores } = values;
      const { storeid, user, closeModal } = this.props;
      let url;
      let data = {};
      let successMessage = "";
      if (this.props.modalType === "add_modal") {
        url = "/store/add_user";
        data = {
          stores,
          storeid,
          name,
          role,
          email,
        };
        successMessage = "User added successfully";
      } else {
        url = "/store/edit_user";
        data = {
          stores,
          storeid,
          uid: user.id,
          role,
        };
        successMessage = "User edited successfully";
      }
      axios({
        url: process.env.REACT_APP_API_URL + url,
        method: "post",
        data: data,
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            closeModal();
            this.setState({ loading: false, contiuneLoading: false });
            message.success(successMessage);
          } else {
            message.error(res.data.msg);
            this.setState({ loading: false, contiuneLoading: false });
          }
        })
        .catch((e) => {
          message.error(e.message);
          this.setState({ loading: false, contiuneLoading: false });
        });
    });
  }

  continueWithoutReview() {
    this.setState(
      {
        contiuneLoading: true,
      },
      () => {
        this.addEditUser();
      }
    );
  }
  componentWillMount() {
    this.fetchStores();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { name, email } = values;

        if (this.props.modalType === "add_modal") {
          if (this.state.addNewRole) {
            this.props.togglePermissionsDrawer(name, email);
          } else if (this.state.customRole) {
            this.props.togglePermissionsDrawer(
              name,
              email,
              this.state.rolePermissions
            );
          } else {
            this.setState(
              {
                loading: true,
              },
              () => {
                this.addEditUser();
              }
            );
          }
        } else {
          if (this.state.addNewRole) {
            this.props.togglePermissionsDrawer(name, email);
          } else if (this.state.customRole) {
            this.props.togglePermissionsDrawer(
              name,
              email,
              this.state.rolePermissions
            );
          } else {
            this.setState(
              {
                loading: true,
              },
              () => {
                this.addEditUser();
              }
            );
          }
        }
      }
    });
  };
  handleChange(value) {
    const role = this.props.roles.find((e) => e._id["$oid"] === value);
    if (value === "addnew") {
      this.setState({
        addNewRole: true,
        customRole: false,
      });
    } else if (role.role_type === "custom") {
      this.setState({
        roleid: value,
        rolePermissions: role,
        customRole: true,
        addNewRole: false,
      });
    }
  }

  storeChange(val) {
    this.setState({
      selectedStore: val,
    });
  }

  render() {
    const { modalType, user } = this.props;
    const { getFieldDecorator } = this.props.form;
    const roleType = {
      predefined: "Pre-Defined Role",
      custom: "Custom Created Role",
      addnew: "Select Custom Permissions for User",
    };

    const roles = this.props.roles;

    let editUser = "Edit User";

    let addUser = "Continue";

    if (modalType === "add_modal") {
      if (this.state.addNewRole) {
        addUser = "Set Permissions";
      } else if (this.state.customRole) {
        addUser = "Review Permissions";
      }
    } else if (modalType === "edit_modal") {
      if (this.state.addNewRole) {
        editUser = "Set Permissions";
      } else if (this.state.customRole) {
        editUser = "Review Permissions";
      }
    }

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item>
          <Input.Group>
            <p className="label">User’s Name</p>
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "User's name is required!" }],
              initialValue: modalType === "edit_modal" ? user.name : "",
            })(
              <Input
                placeholder="Enter User’s Name"
                disabled={modalType === "edit_modal"}
              />
            )}
          </Input.Group>
        </Form.Item>

        <Form.Item>
          <Input.Group>
            <p className="label">Email Id</p>
            {getFieldDecorator("email", {
              rules: [
                {
                  type: "email",
                  message: "Invalid email address!",
                },
                {
                  required: true,
                  message: "User's email address is required!",
                },
              ],
              initialValue: modalType === "edit_modal" ? user.email : "",
            })(
              <Input
                placeholder="Enter User’s Email Id"
                disabled={modalType === "edit_modal"}
              />
            )}
          </Input.Group>
        </Form.Item>

        <Form.Item label="Select Stores">
          {getFieldDecorator("stores", {
            rules: [
              {
                required: true,
                message: "Please select store!",
              },
            ],
            initialValue:
              modalType === "edit_modal" ? Object.keys(user.user_stores) : [],
          })(
            <Select
              placeholder="Choose a store for User"
              style={{ width: "100%" }}
              onChange={(val) => this.storeChange(val)}
              mode="multiple"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.stores.map((s) => (
                <Option value={s.id["$oid"]} key={s.id["$oid"]}>
                  {s.storename}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Select User Role">
          {getFieldDecorator("role", {
            rules: [
              {
                required: true,
                message: "Please select user role!",
              },
            ],
            initialValue: modalType === "edit_modal" ? user.role_id : [],
          })(
            <Select
              placeholder="Choose a Role for User"
              onChange={(value) => this.handleChange(value)}
            >
              {roles.map((r) => {
                return (
                  <Option value={r._id["$oid"]} key={r._id["$oid"]}>
                    {r.role_name}{" "}
                    <span className="role-select">
                      ({roleType[r.role_type]})
                    </span>
                  </Option>
                );
              })}
              <Option value="addnew">
                Create Custom Role +
                <span className="role-select">({roleType["addnew"]})</span>
              </Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item className="form-footer">
          <Button
            className="submit-button"
            type="default"
            size="large"
            htmlType="submit"
            disabled={this.state.contiuneLoading}
            loading={this.state.loading}
          >
            {modalType === "edit_modal" ? editUser : addUser}
          </Button>
          {this.state.customRole && (
            <Button
              type="link"
              onClick={() => this.continueWithoutReview()}
              loading={this.state.contiuneLoading}
            >
              I want to Continue without Reviewing
            </Button>
          )}
        </Form.Item>
      </Form>
    );
  }
}
const WrappedAddUserForm = Form.create({ name: "add_user" })(AddUserForm);

class UserModal extends Component {
  render() {
    const { modalVisible, modalType, closeModal, domid, user } = this.props;

    return (
      <Modal
        width={450}
        className="user-modal"
        visible={modalVisible}
        // onOk={closeModal}
        onCancel={closeModal}
        destroyOnClose={true}
        title={
          <p className="ant-modal-title custom">
            {modalType === "add_modal" ? "Add New User" : "Edit User"}
            <CloseIcon onClick={closeModal} />
          </p>
        }
        footer={null}
      >
        <WrappedAddUserForm
          {...this.props}
          modalType={modalType}
          closeModal={closeModal}
          domid={domid}
          user={user}
        />
      </Modal>
    );
  }
}

export default UserModal;
