import React, { Component } from "react";
import { Modal, Button, Select, Input, message } from "antd";
import "./index.scss";
// import DisputeModals from "../../Shipments/DisputeModals";
import AddNewRequestModal from "../AddNewRequestModal";
// import axios from "axios";
const { Option } = Select;

class AddRequestModal extends Component {
  state = {
    arrow2: "",
    shipments: [],
    replacement: null,
    rvpStatus: null,
    refundedstatus: null,
    gst_enabled: null,
    request_status_data: null,
    items: null,
    setAwb: "",
    tracking_url: null,
    shopify_order_link: null,
    order_vfid: null,
    order_shopify_name: null,
    shipment_vfid: null,
  };

  componentDidUpdate(pp) {
    if (this.props.shipments !== pp.shipments) {
      this.setState({
        shipments: this.props.shipments,
        replacement: this.props.shipments[0]["request_status"]["replacement"],
        rvpStatus: this.props.shipments[0]["request_status"]["rvp"],
        refundedstatus: this.props.shipments[0]["request_status"]["refund"],
        gst_enabled: this.props.shipments[0]["gst_enabled"],
        request_status_data: this.props.shipments[0]["request_status_data"],
        items: this.props.shipments[0]["items"],
        setAwb: this.props.shipments[0]["awb"],
        tracking_url: this.props.shipments[0]["tracking_url"],
        shopify_order_link: this.props.shipments[0]["shopify_order_link"],
        order_vfid: this.props.shipments[0]["order_vfid"],
        order_shopify_name: this.props.shipments[0]["order_shopify_name"],
        shipment_vfid: this.props.shipments[0]["shipment_vfid"],
      });
    }
  }

  componentDidMount() {
    this.setState({
      shipments: this.props.shipments,
    });
  }
  render() {
    return (
      <>
        <Modal
          title="Add New Request"
          visible={this.props.visible}
          className="add-dispute-modal"
          closable={false}
          maskClosable={false}
          onCancel={() => this.props.toggleAddRequestModal()}
          destroyOnClose={true}
          footer={
            <div>
              <Button
                style={{
                  color: "white",
                  background: `${
                    !!this.props.id && !!this.props.type
                      ? `#1370FC`
                      : `lightblue`
                  }`,
                  pointerEvents: `${
                    !!this.props.id && !!this.props.type ? `visible` : `none`
                  }`,
                }}
                onClick={() => this.props.onCountinue()}
                loading={this.props.loading2}
              >
                Continue
              </Button>
              <Button onClick={() => this.props.toggleAddRequestModal()}>
                Cancel & Close
              </Button>
            </div>
          }
        >
          <>
            <div className="dispute-form">
              <div className="dispute-reason">
                <p>Type of Order ID:</p>
                <Select
                  onDropdownVisibleChange={(val) =>
                    this.setState({ arrow2: val })
                  }
                  suffixIcon={
                    <svg
                      style={{
                        transition: "all 0.5s",
                        transform: `${
                          this.state.arrow2
                            ? "rotateX(180deg)"
                            : "rotateX(0deg)"
                        }`,
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="8"
                      viewBox="0 0 16 8"
                    >
                      <g
                        id="Polygon_33"
                        data-name="Polygon 33"
                        transform="translate(16 8) rotate(180)"
                        fill={`${this.state.arrow2 ? "898989" : "none"}`}
                      >
                        <path d="M8,0l8,8H0Z" stroke="none" />
                        <path
                          d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                          stroke="none"
                          fill="#898989"
                        />
                      </g>
                    </svg>
                  }
                  placeholder="Select out of WayBill Number, vF Order Id or Shopify ID"
                  onChange={(val) => this.props.handleChange("type", val)}
                  value={!!this.props.type ? this.props.type : undefined}
                  style={{ width: 200 }}
                  bordered={false}
                >
                  <Option className="op" value="awb">
                    WayBill Number
                  </Option>
                  <Option className="op" value="order_vfid">
                    vF Order Id
                  </Option>
                  <Option className="op" value="shopify_orderid">
                    Shopify Order ID
                  </Option>
                </Select>
              </div>
              <div className="dispute-reason">
                <p>Enter ID:</p>
                <Input
                  rows={4}
                  value={this.props.id}
                  placeholder="Please enter correct ID"
                  onChange={(e) =>
                    this.props.handleChange2("id", e.target.value)
                  }
                />
              </div>
              {/* {this.props.selectShipment && (
              <div className="dispute-reason">
                <p>Select Shipment:</p>
                <Select
                  placeholder="Select Shipment"
                  onChange={(val) =>
                    this.props.handleChange("selectedShipment", val)
                  }
                  style={{ width: 200 }}
                >
                  {shipments.map((shipment) => (
                    <Option value={shipment.awb}>{shipment.awb}</Option>
                  ))}
                </Select>
              </div>
            )} */}
            </div>

            {/* */}
          </>
        </Modal>
        <AddNewRequestModal
          toggleShipmentDisputeModal={() =>
            this.props.toggleShipmentDisputeModal()
          }
          visible={this.props.visible2}
          data={this.state.shipments}
          match={this.props.match}
          // setArrowdata={() => this.setArrowdata()}
          status={this.state.status}
          items={this.state.items}
          replacementStatus={this.state.replacement}
          rvpStatus={this.state.rvpStatus}
          refundedstatus={this.state.refundedstatus}
          shipmentTotalAmount={this.state.shipments.shipmentTotalAmount}
          request_status_data={this.state.request_status_data}
          gst_enabled={this.state.gst_enabled}
          CloseToggleShipmentDisputeModal={() =>
            this.props.CloseToggleShipmentDisputeModal()
          }
          setAwb={this.state.setAwb}
          tracking_url={this.state.tracking_url}
          shopify_order_link={this.state.shopify_order_link}
          order_vfid={this.state.order_vfid}
          order_shopify_name={this.state.order_shopify_name}
          shipment_vfid={this.state.shipment_vfid}
          activeTab={this.props.activeTab}
          toggleAddRequestModal={() => this.props.toggleAddRequestModal()}
        />
      </>
    );
  }
}

export default AddRequestModal;
