import React, { Component } from "react";
import { Radio, Alert, Button, Input } from "antd";
import { ReactComponent as ClickOK } from "../../../../../assets/images/icons/check-circle.svg";
import { SelfDomain } from "./SelfDomain";
import { VfullfillDomain } from "./VfullfillDomain";
export default class ContainerDomain extends Component {
  state = {
    selfInfoVisible: false,
    vFullFillVisible: false,
  };
  render() {
    const {
      disabled,
      domainSetting,
      domainSettingOn,
      vfsubdomain,
    } = this.props;
    const selfstring = "";
    // if (domainSettingOn) {
    //   return (
    //     <div>
    //       {" "}
    //       <div className="sub-heading">Domain Settings</div>
    //       <div>
    //         <Alert
    //           style={{ marginBottom: "20px", marginTop: "20px" }}
    //           message="Important Information filled"
    //           description="Please note the data below updates every 2 hours."
    //           type="info"
    //           showIcon
    //         />
    //         <Radio.Group
    //           name="radiogroup"
    //           value={domainSetting.select}
    //           //   onChange={(e) => this.props.handleRadioDomainSetting(e)}
    //           disabled={true}
    //         >
    //           <Radio value={"vFullfill"}>vFullfill Sub-Domain</Radio>
    //           <Radio value={"self"}>My Custom Sub-Domain</Radio>
    //         </Radio.Group>
    //       </div>
    //       {!!(domainSetting.select === "vFullfill") && (
    //         <div className="container-mainDomain">
    //           <div className="title-input">vFullfill Domain</div>
    //           <input
    //             value={domainSetting.vfullfillDomain}
    //             onChange={(e) => this.props.handleDominSettingInputChange(e)}
    //             disabled={true}
    //             className="input"
    //           />{" "}
    //           {/* <span
    //             className="verified"
    //             style={{ color: "#36c626", fontWeight: "500" }}
    //           >
    //             Verified{" "}
    //             <span className="icon-container">
    //               <ClickOK />
    //             </span>{" "}
    //           </span> */}
    //         </div>
    //       )}
    //       {!!(domainSetting.select === "self") && (
    //         <div className="container-mainDomain">
    //           <div className="title-input"> Your Domain</div>
    //           <input
    //             value={domainSetting.self}
    //             //   onChange={(e) => this.props.handleDominSettingInputChange(e)}
    //             disabled={true}
    //             className="input"
    //           />
    //         </div>
    //       )}
    //     </div>
    //   );
    // }
    return (
      <div className="domain-cointainer-main">
        {" "}
        <div className="sub-heading">Domain Settings</div>
        <div>
          {/* {!!(domainSetting.select == "vFullfill") && (
            <Alert
              style={{ marginBottom: "20px", marginTop: "20px" }}
              message="Important Information: vFullfill Sub Domain"
              description="By choosing this option, the Branded tracking Page will be installed on a subdomain managed by vFulfill. You will be able to access the application using a unique URL provided by vFulfill.."
              type="info"
              showIcon
            />
          )} */}
          {/* 
          {!!(domainSetting.select == "self") && (
            <Alert
              style={{ marginBottom: "20px", marginTop: "20px" }}
              message="Important Information: Custom Sub Domain"
              description={
                <div style={{ height: "150px", overflow: "auto" }}>
                  <div style={{ marginBottom: "10px" }}>
                    If you have chosen the option to install the Branded
                    tracking Page on your own Branded subdomain, please follow
                    the steps below to complete the DNS settings. These settings
                    are necessary to ensure that your subdomain points to the
                    correct location.
                  </div>
                  <div>
                    <div>
                      <span style={{ fontWeight: 500 }}>Step 1:</span> Obtain
                      Your Unique Subdomain{" "}
                    </div>
                    <div>
                      Remember your unique subdomain name (e.g.,
                      "tracking.yourdomain.com").
                    </div>
                  </div>
                  <div>
                    <div>
                      <span style={{ fontWeight: 500 }}>Step 2:</span> Access
                      Your Domain Registrar or DNS Provider
                    </div>
                    <div>
                      Login to your domain registrar or DNS provider (e.g.,
                      GoDaddy, Namecheap).
                    </div>
                  </div>
                  <div>
                    <div>
                      <span style={{ fontWeight: 500 }}>Step 3:</span> Find DNS
                      Settings{" "}
                    </div>
                    <div>
                      Locate the DNS settings or DNS management section.
                    </div>
                  </div>{" "}
                  <div>
                    <div>
                      {" "}
                      <span style={{ fontWeight: 500 }}>Step 4:</span> Create a
                      CNAME Record{" "}
                    </div>
                    <div>
                      Add a new CNAME record:Name/Alias: "tracking" (for
                      "tracking.yourdomain.com"). Value/Points to:
                      "trackingpage.vfulfill.io" (provided during installation).
                    </div>
                  </div>
                  <div>
                    <div>
                      {" "}
                      <span style={{ fontWeight: 500 }}>Step 5:</span> Save the
                      Changes{" "}
                    </div>
                    <div>
                      Save the CNAME record and wait for DNS propagation (up to
                      24 hours).
                    </div>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <div>
                      {" "}
                      <span style={{ fontWeight: 500 }}>Step 6:</span> Verify
                      Setup
                    </div>
                    <div>
                      Access your Branded Tracking page at
                      "tracking.yourdomain.com".
                    </div>
                  </div>
                  <div>
                    <div>
                      For more info or any help, contact our support team.
                    </div>
                  </div>
                </div>
              }
              type="info"
              showIcon
            />
          )} */}
          {/* {!domainSetting.select && (
            <Alert
              style={{ marginBottom: "20px", marginTop: "20px" }}
              message="Important Information"
              description=""
              type="info"
              showIcon
            />
          )} */}
        </div>
        <div>
          <Radio.Group
            name="radiogroup"
            value={domainSetting.select}
            onChange={(e) => this.props.handleRadioDomainSetting(e)}
            disabled={disabled}
            className="radio-group-domain"
          >
            <Radio value={"vFullfill"} style={{ fontSize: "12px" }}>
              vFulfill Domain
            </Radio>
            <Radio value={"self"} style={{ fontSize: "12px" }}>
              My Custom Domain
            </Radio>
          </Radio.Group>
        </div>
        <div>
          {!!(domainSetting.select === "vFullfill") && (
            <div className="container-mainDomain">
              {/* <div className="title-input">vFullfill Domain</div> */}
              <Input
                value={domainSetting.vfullfillDomain}
                onChange={(e) => this.props.handleDominSettingInputChange(e)}
                disabled={disabled}
                placeholder="Example : yourdomainname"
                className={disabled ? "inputdd" : ""}
                addonAfter={vfsubdomain || ""}
              />{" "}
              {!domainSetting.verifyed ? (
                <Button
                  className={
                    !domainSetting.vfullfillDomain
                      ? "clickable disable"
                      : "clickable"
                  }
                  style={{ height: "37px" }}
                  onClick={() => this.props.handleVerifyDomain()}
                  disabled={!domainSetting.vfullfillDomain}
                >
                  Check Availability
                </Button>
              ) : (
                <span
                  className="verified"
                  style={{ color: "#36c626", fontWeight: "500" }}
                >
                  Available{" "}
                  <span className="icon-container">
                    <ClickOK />
                  </span>{" "}
                </span>
              )}
            </div>
          )}
          {!!(domainSetting.select === "self") && (
            <div className="self-input">
              <Input
                addonBefore="https://"
                value={domainSetting.self}
                onChange={(e) => this.props.handleDominSettingInputChange(e)}
                disabled={disabled}
                className={disabled ? "inputdd" : ""}
                placeholder="Example : yourdomain.example.com"
              />
            </div>
          )}
          {!!(domainSetting.select === "self") && (
            <div
              className="underline-animation"
              style={{ fontSize: "12px", marginTop: "12px", cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  selfInfoVisible: true,
                });
              }}
            >
              More Information
            </div>
          )}
          {!!(domainSetting.select === "vFullfill") && (
            <div
              className="underline-animation"
              style={{ fontSize: "12px", marginTop: "12px", cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  vFullFillVisible: true,
                });
              }}
            >
              More Information
            </div>
          )}
        </div>
        <SelfDomain
          visible={this.state.selfInfoVisible}
          cancel={() => {
            this.setState({ selfInfoVisible: false });
          }}
        />
        <VfullfillDomain
          visible={this.state.vFullFillVisible}
          cancel={() => {
            this.setState({ vFullFillVisible: false });
          }}
        />
      </div>
    );
  }
}
