import React, { Component } from "react";
import { Modal, Icon, Button } from "antd";
import { ReactComponent as rightArrow } from "../../../../assets/images/icons/long-arrow-pointing-to-the-right.svg";
import { ReactComponent as successIcon } from "../../../../assets/images/icons/sucees.svg";
import { formatNumber } from "../../../../Helpers/Pricing";
import "./index.scss";

class WithdrawalSuccess extends Component {
  render() {
    return (
      <Modal
        className="withdrawalSuccessModal"
        visible={this.props.visible}
        title={null}
        onCancel={() => this.props.toggleSuccessModal()}
        footer={null}
      >
        <div style={{ textAlign: "center" }}>
          <div className="withdrawal-sucess-content">
            <Icon component={successIcon} />
            <h1>WITHDRAWAL REQUEST SUCESSFULLY SENT!</h1>
            <p>
              Your withdrawal request for ₹ {formatNumber(this.props.amount)}{" "}
              has been successfully forwarded to vFulfill’s finance team for
              processing.
            </p>
          </div>
          <div className="footer-button">
            <Button onClick={() => window.location.reload()}>
              Continue To Dashboard
              <Icon component={rightArrow} />
            </Button>
          </div>
          <div>
            <p className="withdrawal-time">
              *Your withdrawal request should be processed in 1-2 business
              day(s) time. Your requested withdrawal amount has been put on hold
              in your account balance, while we process this request.
            </p>
          </div>
        </div>
      </Modal>
    );
  }
}

export default WithdrawalSuccess;
