import React, { Component } from "react";
import "./index.scss";
import { DatePicker, Select, Icon, Button } from "antd";
import moment from "moment";
import Globalsearch from "../../../Common/Globalsearch";

// import { ReactComponent as Arrow } from "../../../../../assets/images/icons/arrow.svg";

const { RangePicker } = DatePicker;
const { Option } = Select;
class ShipmentRequestsNav extends Component {
  state = {
    reversepickupBtn: true,
    refundbtn: false,
    replacementbtn: false,
    all: false,
    loading: false,
    logsLoading: false,
  };

  componentDidUpdate(pp) {
    if (pp.activeTab !== this.props.activeTab) {
      if (this.props.activeTab === "refund") {
        this.setState({
          reversepickupBtn: false,
          refundbtn: true,
          replacementbtn: false,
        });
      } else if (this.props.activeTab === "rvp") {
        this.setState({
          reversepickupBtn: true,
          refundbtn: false,
          replacementbtn: false,
        });
      } else if (this.props.activeTab === "replacement") {
        this.setState({
          reversepickupBtn: false,
          refundbtn: false,
          replacementbtn: true,
        });
      } else {
        this.setState({
          reversepickupBtn: true,
          refundbtn: false,
          replacementbtn: false,
        });
      }
    }
  }

  getnavbaracess() {
    if (this.props.activeTab === "rvp") {
      return "Reverse Pickup";
    } else if (this.props.activeTab === "refund") {
      return "Refund";
    } else if (this.props.activeTab === "replacement") {
      return "Replacement";
    } else {
      return "All";
    }
  }

  render() {
    const getnavbaracess = this.getnavbaracess();
    return (
      <div className="shipment_request_data fullorder_data">
        <div className="shipmentrequestnav">
          <div className="shipmentrequestfill">
            <div className="shipmentrequestfillwrap" style={{ height: "32px" }}>
              <div className="left">
                <div
                  style={{
                    color: "#000",
                    fontSize: "12px",
                    letterSpacing: "0.032em",
                    lineHeight: "24px",
                  }}
                >
                  Requests <span>&#62;</span> <span>Post Delivery</span>{" "}
                  <span>&#62;</span>{" "}
                  <span className="underline">{getnavbaracess}</span>
                </div>
              </div>

              <div className="right">
                <div className="store-filters" />
                <p className="show_data_from" style={{ marginLeft: "8px" }}>
                  Show Data From:{" "}
                </p>

                <RangePicker
                  className="shipment-request-datepicker"
                  disabledDate={(current) => {
                    return current > moment();
                  }}
                  style={{ marginLeft: "10px" }}
                  value={[
                    this.props.filters.startDate === "" ||
                    this.props.filters.startDate === null
                      ? undefined
                      : moment(this.props.filters.startDate, "YYYY-MM-DD"),
                    this.props.filters.endDate === "" ||
                    this.props.filters.startDate === null
                      ? undefined
                      : moment(this.props.filters.endDate, "YYYY-MM-DD"),
                  ]}
                  ranges={{
                    Today: [moment(), moment()],
                    "This Week": [
                      moment().startOf("week"),
                      moment().endOf("week"),
                    ],
                    "Last 15 Days": [moment().subtract("days", 15), moment()],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                    "Last Month": [
                      moment()
                        .subtract(1, "month")
                        .startOf("month"),
                      moment()
                        .subtract(1, "month")
                        .endOf("month"),
                    ],
                    "Last Year": [
                      moment()
                        .subtract(1, "year")
                        .startOf("year"),
                      moment()
                        .subtract(1, "year")
                        .endOf("year"),
                    ],
                  }}
                  onChange={(_, dateString) =>
                    this.props.changeFilter({
                      startDate: dateString[0],
                      endDate: dateString[1],
                    })
                  }
                />
              </div>
            </div>

            <div className="secondwrap">
              <div className="filtertab">
                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-inventory-underline-animation "
                      : this.state.reversepickupBtn
                      ? "tab  hover-inventory-underline-animation-click"
                      : "tab hover-inventory-underline-animation"
                  }
                >
                  <button
                    className={this.state.reversepickupBtn ? "active" : ""}
                    style={{ fontWeight: 500 }}
                    onClick={() => {
                      this.setState(
                        {
                          reversepickupBtn: true,
                          refundbtn: false,
                          replacementbtn: false,
                          all: false,
                        },
                        this.props.tabHandler("rvp")
                      );
                    }}
                  >
                    Reverse Pickup
                  </button>

                  <div
                    className={` ${
                      this.state.reversepickupBtn
                        ? "backgroundcircle"
                        : "circle"
                    }`}
                  >
                    {this.props.count && this.props.count.rvp
                      ? this.props.count.rvp > 1000
                        ? (Number(this.props.count.rvp) / 1000).toFixed(1) + "k"
                        : this.props.count.rvp < 1000
                        ? this.props.count.rvp
                        : null
                      : this.props.count.rvp === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                {/* Delivered */}

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-inventory-underline-animation "
                      : this.state.refundbtn
                      ? "tab  hover-inventory-underline-animation-click"
                      : "tab hover-inventory-underline-animation"
                  }
                >
                  <button
                    className={this.state.refundbtn ? "active" : ""}
                    style={{ fontWeight: 500 }}
                    onClick={() => {
                      this.setState(
                        {
                          reversepickupBtn: false,
                          refundbtn: true,
                          replacementbtn: false,
                          all: false,
                        },
                        this.props.tabHandler("refund")
                      );
                    }}
                  >
                    Refund
                  </button>
                  <div
                    className={` ${
                      this.state.refundbtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.count && this.props.count.refund
                      ? this.props.count.refund > 1000
                        ? (Number(this.props.count.refund) / 1000).toFixed(1) +
                          "k"
                        : this.props.count.refund < 1000
                        ? this.props.count.refund
                        : null
                      : this.props.count.refund === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>
                {/* bad inventoy */}
                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-inventory-underline-animation "
                      : this.state.replacementbtn
                      ? "tab  hover-inventory-underline-animation-click"
                      : "tab hover-inventory-underline-animation"
                  }
                >
                  <button
                    className={this.state.replacementbtn ? "active" : ""}
                    onClick={() => {
                      this.setState(
                        {
                          reversepickupBtn: false,
                          refundbtn: false,
                          replacementbtn: true,
                          all: false,
                        },
                        this.props.tabHandler("replacement")
                      );
                    }}
                  >
                    Replacement
                  </button>
                  <div
                    className={` ${
                      this.state.replacementbtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.count && this.props.count.replacement
                      ? this.props.count.replacement > 1000
                        ? (Number(this.props.count.replacement) / 1000).toFixed(
                            1
                          ) + "k"
                        : this.props.count.replacement < 1000
                        ? this.props.count.replacement
                        : null
                      : this.props.count.replacement === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                {/* <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-inventory-underline-animation "
                      : this.state.all
                      ? "tab  hover-inventory-underline-animation-click"
                      : "tab hover-inventory-underline-animation"
                  }
                >
                  <button
                    className={this.state.all ? "active" : ""}
                    onClick={() => {
                      this.setState(
                        {
                          reversepickupBtn: false,
                          refundbtn: false,
                          replacementbtn: false,
                          all: true,
                        },
                        this.props.tabHandler("all")
                      );
                    }}
                  >
                    All
                  </button>
                  <div
                    className={` ${
                      this.state.all ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.count && this.props.count.all
                      ? this.props.count.all > 1000
                        ? (Number(this.props.count.all) / 1000).toFixed(1) + "k"
                        : this.props.count.all < 1000
                        ? this.props.count.all
                        : null
                      : this.props.count.all === 0
                      ? "0"
                      : "..."}
                  </div>
                </div> */}
              </div>
              <div className="right_data_request">
                <div className="add_new_request">
                  <Button
                    onClick={() => this.props.toggleAddRequestModal()}
                    className="clickable"
                  >
                    Add New Request
                  </Button>
                </div>

                <div className="donload_csv">
                  <Button
                    onClick={() => this.props.downloadCSV()}
                    className="clickable"
                  >
                    Download CSV
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_data">
          <div className="Globalsearch">
            <Globalsearch
              class={"nextwidth"}
              data={this.props.filters}
              change={(value) =>
                this.props.changeHandler({ search: value.target.value })
              }
              searchFunc={(value) => this.props.changeFilter({ search: value })}
              placeholder={"What are you looking for?"}
            />
          </div>

          {/* {this.props.activeTab === "all" ? (
            <div className="shipment_request_dropdown">
              <Select
                style={{ fontWeight: 500, fontSize: 12, width: 288 }}
                value={this.props.filters.status}
                // open={true}
                className="shipment_request_servicebale-select"
                placeholder="Request Type: All"
                onChange={(val) =>
                  this.props.changeFilter({
                    status: val,
                    startDate: this.props.filters.startDate,
                    endDate: this.props.filters.endDate,
                    search: this.props.filters.search,
                  })
                }
                onVisibleChange={(a) =>
                  this.setState({ arrow: !this.state.arrow })
                }
                suffixIcon={
                  <Icon
                    className={`arrow ${
                      this.state.arrow ? "arrow-fill" : null
                    }`}
                    component={Arrow}
                    style={{
                      marginLeft: "4px",
                      fontSize: "16px",
                    }}
                  />
                }
              >
                <Option value="" className="requestnav_shipment_commondropdown">
                  {" "}
                  All
                </Option>
                <Option
                  value="rvp"
                  className="requestnav_shipment_commondropdown"
                >
                  Reverse Pickup
                </Option>

                <Option
                  value="refund"
                  className="requestnav_shipment_commondropdown"
                >
                  Refund
                </Option>

                <Option
                  value="replacement"
                  className="requestnav_shipment_commondropdown"
                >
                  Replacement
                </Option>
              </Select>
            </div>
          ) : null} */}
        </div>
      </div>
    );
  }
}

export default ShipmentRequestsNav;
