import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  DatePicker,
  Alert,
  Select,
  Table,
  Tooltip as AntTooltip,
  Icon,
  message,
  Skeleton,
} from "antd";
import { Chart, Geom, Axis, Tooltip } from "bizcharts";
import { formatNumber, getCurrencySymbol } from "../../../../Helpers/Pricing";
import { ReactComponent as NoGraphIcon } from "../../../../assets/images/icons/500.svg";
import moment from "moment";
import axios from "axios";
import { connect } from "react-redux";
import { hasPermission } from "../../../../Helpers/Permissions";
import { SHOPIFY_NAME } from "../../../../constants";
import qs from "qs";
import "./index.scss";

const { RangePicker } = DatePicker;
const { Option } = Select;

const remittanceStatus = {
  pending: "Pending",
  processed: "Processed",
};

const shipmentStatuses = {
  rto: "RTO",
  delivered: "Delivered",
  transit: "In Transit",
  manifested: "Manifested",
};

const columnData = {
  vforderid: {
    title: "vF Order Id",
    width: "150px",
    dataIndex: "vforderid",
    sortValue: 1,
  },
  vf_order_date: {
    width: "150px",
    title: "vF Order Date",
    dataIndex: "vf_order_date",
    sortValue: 2,
    render: (_, row) =>
      row.items.map((item, i) => (
        <p key={i}>{moment(item.confirmation_date).format("DD MMM, YYYY")}</p>
      )),
  },
  shopify_store_name: {
    width: "150px",
    title: "Shopify Store Name",
    dataIndex: "shopify_store_name",
    sortValue: 3,
    render: (_, row) => <p>{row.storename}</p>,
  },
  shopify_order_date: {
    width: "150px",
    title: `${SHOPIFY_NAME} Order Date`,
    dataIndex: "shopify_order_date",
    sortValue: 4,
    render: (_, row) => (
      <p>{moment(row.order_filterdate).format("DD MMM, YYYY")}</p>
    ),
  },
  shopify_order_name: {
    width: "150px",
    title: "Shopify Order Name",
    dataIndex: "shopify_order_name",
    sortValue: 5,
    render: (_, row) => <p>{row.shopify_ordername}</p>,
  },
  shopify_order_status: {
    width: "150px",
    title: "Shopify Order Status",
    dataIndex: "shopify_order_status",
    sortValue: 6,
    render: (shopify_order_status) =>
      shopify_order_status ? shopify_order_status : "NA",
  },
  customer_name: {
    width: "150px",
    title: "Customer Name",
    dataIndex: "customer_name",
    sortValue: 7,
    render: (_, row) => (
      <p>
        {row.customer_details && row.customer_details.name
          ? row.customer_details.name
          : ""}
      </p>
    ),
  },
  delivery_address_pin_code: {
    width: "150px",
    title: "Delivery Address Pin Code",
    dataIndex: "delivery_address_pin_code",
    sortValue: 8,
    render: (_, row) => (
      <p>
        {row.shipping_address && row.shipping_address.zip
          ? row.shipping_address.zip
          : ""}
      </p>
    ),
  },
  order_content_x_qty: {
    width: "150px",
    title: "Order Content X Qty.",
    className: "order-content",
    dataIndex: "order_content_x_qty",
    sortValue: 9,
    render: (_, row) =>
      row.items.map((item, i) => (
        <p key={i}>
          <AntTooltip
            title={`${item.name} X ${item.quantity}`}
            placement="topLeft"
          >
            {" "}
            {item.name} X {item.quantity}
          </AntTooltip>
        </p>
      )),
  },
  payment_type: {
    width: "150px",
    title: "Payment Type",
    dataIndex: "payment_type",
    sortValue: 10,
  },
  total_order_amount: {
    width: "150px",
    title: "Total Order Amount",
    dataIndex: "total_order_amount",
    sortValue: 11,
    render: (_, row) => (
      <p>
        {getCurrencySymbol(row.order_currency)}{" "}
        {formatNumber(row.order_total_paid)}
      </p>
    ),
  },
  vf_order_Status: {
    width: "150px",
    title: "vF Order Status",
    dataIndex: "vf_order_Status",
    sortValue: 12,
    render: () => "Ordered",
  },
  total_product_price: {
    title: "Total Product Price",
    width: "150px",
    dataIndex: "total_product_price",
    sortValue: 13,
    render: (_, row) => (
      <p>
        {getCurrencySymbol("INR")} {formatNumber(row.vfcosts.product_total)}
      </p>
    ),
  },
  total_forword_shipping_fee: {
    title: "Total Forward Shipping Fee",
    width: "150px",
    dataIndex: "total_forword_shipping_fee",
    sortValue: 14,
    render: (_, row) => (
      <p>
        {getCurrencySymbol("INR")} {formatNumber(row.vfcosts.shipping_total)}
      </p>
    ),
  },
  total_cod_fee: {
    title: "Total COD Fee",
    width: "150px",
    dataIndex: "total_cod_fee",
    sortValue: 15,
    render: (_, row) => (
      <p>
        {getCurrencySymbol("INR")} {formatNumber(row.vfcosts.cod_fee_total)}
      </p>
    ),
  },
  total_vf_cost: {
    width: "150px",
    title: "Total vF Cost",
    dataIndex: "total_vf_cost",
    sortValue: 16,
    render: (_, row) => (
      <p>
        {getCurrencySymbol("INR")} {formatNumber(row.vfcosts.total)}
      </p>
    ),
  },

  waybill_number: {
    width: "150px",
    title: "WayBill Number",
    dataIndex: "waybill_number",
    sortValue: 17,
    render: (_, row) =>
      Object.keys(row.shipments).length > 0
        ? Object.keys(row.shipments).map((awb, i) => <p key={i}>{awb}</p>)
        : "NA",
  },
  shipment_current_status: {
    width: "150px",
    title: "Shipment Current Status",
    dataIndex: "shipment_current_status",
    sortValue: 18,
    render: (_, row) =>
      Object.keys(row.shipments).length > 0
        ? Object.values(row.shipments).map((s, i) => (
            <p key={i}>{shipmentStatuses[s.status]}</p>
          ))
        : "NA",
  },
  waybill_content: {
    width: "150px",
    title: "WayBill Content",
    className: "order-content",
    dataIndex: "waybill_content",
    sortValue: 19,
    render: (_, row) =>
      Object.values(row.shipments).length > 0
        ? Object.values(row.shipments)[0].items.map((item, i) => (
            <p key={i}>
              <AntTooltip
                title={`${item.name} X ${item.quantity}`}
                placement="topLeft"
              >
                {" "}
                {item.name} X {item.quantity}
              </AntTooltip>
            </p>
          ))
        : "NA",
  },
  manifestation_date: {
    width: "150px",
    title: "Manifestation Date",
    dataIndex: "manifestation_date",
    sortValue: 20,
    render: (manifestation_date) =>
      manifestation_date ? manifestation_date : "NA",
  },
  shipped_date: {
    width: "150px",
    title: "Shipped Date",
    dataIndex: "shipped_date",
    sortValue: 21,
    render: (shipped_date) => (shipped_date ? shipped_date : "NA"),
  },
  first_attempt_date: {
    width: "150px",
    title: "First Attempt Date",
    dataIndex: "first_attempt_date",
    sortValue: 22,
    render: (first_attempt_date) =>
      first_attempt_date ? first_attempt_date : "NA",
  },
  subsequent_attempt_Date: {
    width: "150px",
    title: "Subsequent Attempt Date",
    dataIndex: "subsequent_attempt_Date",
    sortValue: 23,
    render: (subsequent_attempt_Date) =>
      subsequent_attempt_Date ? subsequent_attempt_Date : "NA",
  },
  last_attempt_Date: {
    width: "150px",
    title: "Last Attempt Date",
    dataIndex: "last_attempt_Date",
    sortValue: 24,
    render: (last_attempt_Date) =>
      last_attempt_Date ? last_attempt_Date : "NA",
  },
  total_cod_amount: {
    width: "150px",
    title: "Total COD Amount",
    dataIndex: "total_cod_amount",
    sortValue: 25,
    render: (_, row) =>
      row.cod_transactions.total ? (
        <p>
          {getCurrencySymbol("INR")} {formatNumber(row.cod_transactions.total)}
        </p>
      ) : (
        "NA"
      ),
  },
  cod_remittance_status: {
    width: "150px",
    title: "COD Remittance Status",
    dataIndex: "cod_remittance_status",
    sortValue: 26,
    render: (_, row) =>
      Object.keys(row.cod_transactions["awbs"]).length > 0
        ? remittanceStatus[
            Object.values(row.cod_transactions["awbs"])[0].status
          ]
        : "NA",
  },
  cod_remittance_transaction_id: {
    width: "150px",
    title: "COD Remittance Transaction ID",
    dataIndex: "cod_remittance_transaction_id",
    sortValue: 27,
    render: (_, row) =>
      Object.keys(row.cod_transactions["awbs"]).length > 0
        ? Object.values(row.cod_transactions["awbs"])[0].transaction_vfid
        : "NA",
  },
  total_buy_back_credit: {
    width: "150px",
    title: "Total Buy Back Credited",
    dataIndex: "total_buy_back_credit",
    sortValue: 28,
    render: (_, row) =>
      row.buyback.rto_total_buyback ? (
        <p>
          {getCurrencySymbol("INR")}{" "}
          {formatNumber(row.buyback.rto_total_buyback)}
        </p>
      ) : (
        "NA"
      ),
  },
  buy_back_credit_transaction_id: {
    width: "150px",
    title: "Buy Back Credit Transaction ID",
    dataIndex: "buy_back_credit_transaction_id",
    sortValue: 29,
    render: (_, row) =>
      Object.keys(row.buyback["transactions"]).length > 0
        ? Object.values(row.buyback["transactions"])[0].transaction_vfid
        : "NA",
  },
};

const defaultCols = [
  "vforderid",
  "vf_order_date",
  "shopify_order_name",
  "payment_type",
  "total_order_amount",
  "vf_order_Status",
  "total_vf_cost",
  "waybill_number",
  "shipment_current_status",
  "first_attempt_date",
  "total_cod_amount",
  "cod_remittance_status",
  "total_buy_back_credit",
];

class OverallMISReport extends Component {
  state = {
    currency: "INR",
    stats: {
      total_orders_on_shopify: 0,
      total_orders_to_vf: 0,
      total_cod_orders: 0,
      total_prepaid_orders: 0,
      total_vf_Cost: 0,
      total_shipments_delivered: 0,
      total_shipments_returned: 0,
      total_shipments_in_transit: 0,
      total_cod_orders_value: 0,
      total_cod_remitted: 0,
      total_cod_pending_to_remit: 0,
      total_vf_buy_back_credited: 0,
    },
    filters: {
      startDate: moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
    paging: {
      page: 1,
      pageSize: 10,
      total: 0,
    },
    loading: false,
    error: false,
    errorMsg: "",
    statsLoading: false,
    chartsLoading: false,
    chartStats: [],
    orders: [],
    columns: defaultCols.map((c) => columnData[c]),
    columnData: {},
  };

  componentDidMount() {
    const inExclusive = [
      "total_cod_fee",
      "first_attempt_date",
      "subsequent_attempt_Date",
      "last_attempt_Date",
      "total_cod_amount",
      "cod_remittance_status",
      "cod_remittance_transaction_id",
      "total_buy_back_credit",
      "buy_back_credit_transaction_id",
    ];
    if (this.props.store.store_geo === "global") {
      inExclusive.forEach((k) => delete columnData[k]);
    }
    this.setState(
      {
        columnData,
        columns:
          this.props.store.store_geo === "global"
            ? defaultCols
                .filter((k) => inExclusive.indexOf(k) === -1)
                .map((c) => columnData[c])
            : defaultCols.map((c) => columnData[c]),
      },
      () => {
        this.getOrders();
        this.getStats();
        this.getCharts();
      }
    );
  }

  getOrders() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/reporting/get_orders",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
            filters: this.state.filters,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                orders: res.data.orders,
                loading: false,
                error: false,
                errorMsg: "",
                paging: { ...this.state.paging, total: res.data.total },
              });
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
                loading: false,
              });
            }
          })
          .catch((e) => {
            this.setState({
              error: true,
              errorMsg: e.message,
              loading: false,
            });
          });
      }
    );
  }

  getStats() {
    this.setState(
      {
        statsLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/reporting/overall_mis_report",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            filters: this.state.filters,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                statsLoading: false,
                stats: {
                  total_orders_on_shopify:
                    res.data.stats.total_orders_on_shopify,
                  total_orders_to_vf: res.data.stats.total_orders_to_vf,
                  total_cod_orders: res.data.stats.total_cod_orders,
                  total_prepaid_orders: res.data.stats.total_prepaid_orders,
                  total_vf_Cost: res.data.stats.total_vf_cost,
                  total_shipments_delivered:
                    res.data.stats.total_shipments_delivered,
                  total_shipments_returned:
                    res.data.stats.total_shipments_returned,
                  total_shipments_in_transit:
                    res.data.stats.total_shipments_in_transit,
                  total_cod_orders_value: res.data.stats.total_cod_orders_value,
                  total_cod_remitted: res.data.stats.total_cod_remitted,
                  total_cod_pending_to_remit:
                    res.data.stats.total_cod_pending_to_remit,
                  total_vf_buy_back_credited:
                    res.data.stats.total_vf_buyback_credited,
                },
              });
            } else {
              this.setState({
                statsLoading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              statsLoading: false,
            });
            message.error(e.message);
          });
      }
    );
  }

  getCharts() {
    this.setState(
      {
        chartsLoading: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL + "/reporting/overall_mis_graph_data",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            filters: this.state.filters,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                chartsLoading: false,
                chartStats: res.data.stats,
              });
            } else {
              this.setState({
                chartsLoading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              chartsLoading: false,
            });
            message.error(e.message);
          });
      }
    );
  }

  filterChanged() {
    this.getOrders();
    this.getStats();
    this.getCharts();
  }

  handleChange(value) {
    this.setState({
      columns: value
        .map((c) => this.state.columnData[c])
        .sort((a, b) => a.sortValue - b.sortValue),
    });
  }

  unselectColumn(index) {
    this.setState({
      columns: this.state.columns
        .filter((_, i) => i !== index)
        .sort((a, b) => a.sortValue - b.sortValue),
    });
  }
  goToPage = (page) => {
    this.setState(
      {
        paging: { ...this.state.paging, page: page.current },
      },
      () => this.getOrders()
    );
  };

  render() {
    const { store } = this.props;
    const PageWrapper = (children) => {
      return (
        <div className="overallReport">
          <Row>
            <div className="heading">
              <h1 className="content-page-title" style={{ marginBottom: 0 }}>
                Overall MIS Report
              </h1>
              <h3
                className="content-page-subtitle"
                style={{ marginBottom: 30 }}
              >
                Details for Orders in the Selected Period:
                <span style={{ float: "right", marginTop: "-4px" }}>
                  Show data from:
                  <RangePicker
                    disabledDate={(current) => {
                      return current > moment();
                    }}
                    value={[
                      this.state.filters.startDate === ""
                        ? null
                        : moment(this.state.filters.startDate),
                      this.state.filters.endDate === ""
                        ? null
                        : moment(this.state.filters.endDate),
                    ]}
                    ranges={{
                      "Last Month": [
                        moment()
                          .subtract(1, "months")
                          .startOf("month"),
                        moment()
                          .subtract(1, "months")
                          .endOf("month"),
                      ],
                      "Last 3 Month": [
                        moment()
                          .subtract(2, "months")
                          .startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    onChange={(_, dateString) =>
                      this.setState(
                        {
                          filters: {
                            startDate: dateString[0],
                            endDate: dateString[1],
                          },
                        },
                        () => this.filterChanged()
                      )
                    }
                  />
                </span>
              </h3>
            </div>
          </Row>
          {children}
        </div>
      );
    };

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <Row>
          <Alert
            message="There was an error"
            description={this.state.errorMsg}
            type="error"
          />
        </Row>
      );
    }

    const order_stats = {
      total_orders_on_shopify: "Total Orders on Shopify",
      total_orders_to_vf: "Total Orders to vF",
      total_cod_orders: "Total COD Orders",
      total_prepaid_orders: "Total Prepaid Orders",
      total_vf_Cost: "Total vF Cost",
      total_shipments_delivered: "Total Shipments Delivered",
      total_shipments_returned: "Total Shipments Returned",
      total_shipments_in_transit: "Total Shipments in Transit",
      total_cod_orders_value: "Total COD Orders Value",
      total_cod_remitted: "Total COD Remitted",
      total_cod_pending_to_remit: "Total COD Pending to Remit",
      total_vf_buy_back_credited: "Total vF Buy Back Credited",
    };
    if (this.props.store.store_geo === "global") {
      [
        "total_cod_orders",
        "total_cod_orders_value",
        "total_cod_remitted",
        "total_cod_pending_to_remit",
        "total_vf_buy_back_credited",
      ].forEach((k) => delete order_stats[k]);
    }
    const tooltip = {
      total_orders_on_shopify: "Total Orders on Shopify",
      total_orders_to_vf: "Total Orders to vF",
      total_cod_orders: "Total COD Orders",
      total_prepaid_orders: "Total Prepaid Orders",
      total_vf_Cost: "Total vF Cost",
      total_shipments_delivered: "Total Shipments Delivered",
      total_shipments_returned: "Total Shipments Returned",
      total_shipments_in_transit: "Total Shipments in Transit",
      total_cod_orders_value:
        "This is the total COD amount for all your orders in this period. Assume this as the total amount of COD that would be collected, when 100% of your shipments are delivered.",
      total_cod_remitted: "Total COD Remitted",
      total_cod_pending_to_remit: "Total COD Pending to Remit",
      total_vf_buy_back_credited: "Total vF Buy Back Credited",
    };

    const pagination = {
      current: this.state.paging.page,
      pageSize: this.state.paging.pageSize,
      total: this.state.paging.total,
    };

    return PageWrapper(
      <React.Fragment>
        <Row gutter={{ lg: 30 }}>
          {Object.keys(order_stats).map((k) => (
            <Col
              xs={24}
              md={12}
              lg={8}
              xl={6}
              style={{ marginBottom: 56 }}
              key={k}
            >
              <Card
                className="reporting-card"
                loading={this.state.statsLoading}
              >
                <div className="header">
                  {order_stats[k]}
                  <span className="header-tooltip">
                    <AntTooltip title={tooltip[k]}>
                      <Icon type="exclamation-circle-o" />
                    </AntTooltip>
                  </span>
                </div>
                <div className="body">
                  {order_stats[k] === "Total vF Cost" ||
                  order_stats[k] === "Total COD Orders Value" ||
                  order_stats[k] === "Total COD Pending to Remit" ||
                  order_stats[k] === "Total COD Remitted" ||
                  order_stats[k] === "Total vF Buy Back Credited"
                    ? `${getCurrencySymbol(this.state.currency)} ${formatNumber(
                        this.state.stats[k]
                      )}`
                    : this.state.stats[k]}
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <Row style={{ marginBottom: 30 }}>
          <Card>
            {this.state.chartsLoading ? (
              <Skeleton />
            ) : Object.keys(this.state.chartStats).length > 0 ? (
              <Chart height={400} data={this.state.chartStats} forceFit>
                <Axis name="date" />
                <Axis name="orders" />
                <Tooltip
                  crosshairs={{
                    type: "y",
                  }}
                  containerTpl='<div class="g2-tooltip"><p class="g2-tooltip-title"></p><table class="g2-tooltip-list"></table></div>'
                  itemTpl={`<tr class=&quot;g2-tooltip-list-item&quot;><td style=&quot;color:{color}&quot;><span class=&quot;g2-tooltip-list-item-dot&quot;></span><span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span> {name}: </td><td>{value}</td></tr>`}
                  offset={50}
                  g2-tooltip={{
                    position: "absolute",
                    visibility: "hidden",
                    border: "1px solid #000",
                    borderRadius: "4px",
                    backgroundColor: "rgba(0, 0, 0, 0.9)",
                    color: "#fff",
                    opacity: "1",
                    padding: "1rem 1.25rem",
                    transition: "top 200ms,left 200ms",
                  }}
                />
                <Geom type="line" position="date*orders" />
              </Chart>
            ) : (
              <Row
                style={{
                  marginBottom: 50,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <NoGraphIcon />
                <div style={{ textAlign: "left", marginLeft: 30 }}>
                  <h1 style={{ marginBottom: 0 }}>Not Enough Data!</h1>
                  <p style={{ margin: 0, fontSize: 18 }}>
                    We could not find enough data to build a graph!
                  </p>
                </div>
              </Row>
            )}
          </Card>
        </Row>

        <Row>
          <div className="heading">
            <h1 className="content-page-title" style={{ marginBottom: "10px" }}>
              Overall Reporting Analysis Data:
              <span style={{ float: "right" }}>
                {hasPermission(store, "export_overall_mis_report") && (
                  <a
                    href={`/reporting/overall_mis_report_csv?${qs.stringify(
                      {
                        storeid: this.props.match.params.storeid,
                        filters: this.state.filters,
                        columns: this.state.columns.map((c) => c.dataIndex),
                      },
                      { encodeValuesOnly: true }
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      background: "#25BE05",
                      border: "none",
                      color: "white",
                    }}
                    className="ant-btn"
                  >
                    <Icon type="download" /> Download CSV
                  </a>
                )}
              </span>
            </h1>
          </div>
        </Row>
        <Row>
          <div className="selected-column">
            <h3 className="content-page-subtitle">Display Columns : </h3>
            <Select
              mode="multiple"
              placeholder="Select from dropdown"
              value={this.state.columns.map((column) => `${column.dataIndex}`)}
              onChange={(value) => this.handleChange(value)}
              className="display-column-select"
            >
              {Object.values(this.state.columnData).map((c) => (
                <Option value={c.dataIndex} key={c.dataIndex}>
                  {c.title}
                </Option>
              ))}
            </Select>
          </div>
        </Row>
        <Row style={{ marginBottom: "30px" }}>
          {this.state.columns.map((column, i) => (
            <div className="selectedValue" key={i}>
              {column.title}{" "}
              <Icon type="close" onClick={() => this.unselectColumn(i)} />
            </div>
          ))}
          <div />
        </Row>
        <Row>
          <Table
            columns={this.state.columns}
            dataSource={this.state.orders}
            loading={this.state.loading}
            rowKey={(row) => row._id["$oid"]}
            onChange={(page) => this.goToPage(page)}
            scroll={{ x: 175 * this.state.columns.length }}
            pagination={{
              ...pagination,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};
export default connect(mapStateToProps)(OverallMISReport);
