import React from "react";
import { Row, Col, Icon, Button } from "antd";
import "./ImportListProductBasic.scss";
import Tick from "../../../../../../assets/images/icons/tick2.png";

class DefaultMessage extends React.Component {
  render() {
    return (
      <div style={{ width: "100%" }}>
        {this.props.messageStatus === "default" && (
          <Row
            style={{
              width: "100%",
              background: "#F5FFF4",
              border: "1px solid #2BC822",
              padding: "10px",
              borderRadius: "3px",
              marginTop: "16px",
            }}
          >
            <Col md={24}>
              <p
                style={{
                  fontSize: "12px",
                  color: "#000000D9",
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    width: "24px",
                    height: "24px",
                    fontSize: "24px",
                    lineHeight: "24px",
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "8px",
                  }}
                >
                  <img style={{ marginTop: "-5px" }} src={Tick} alt="tick" />
                </span>
                This product has been successfully Cataloged. You can simply add
                it to your store and start selling it. Or, view product in VF
                Catalog for more details.
              </p>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default DefaultMessage;
