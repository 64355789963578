import React, { Component } from "react";
import { Row, Alert, Icon, message } from "antd";
import axios from "axios";
import "./index.scss";
import moment from "moment";
import { connect } from "react-redux";
import RtoReportNav from "./NdrReportNav";
import PieChart from "./PieChart";
import CustomTable from "../Common/CustomTable";

class NdrReport extends Component {
  state = {
    loading: false,
    ndrs: [],
    error: false,
    errorMsg: "",
    startDate: moment()
      .subtract(30, "days")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),

    ndrReasonChart: [],
    ndrReasonChartLoading: false,
    ndrReasonChartError: false,
    ndrReasonChartErrorMsg: "",
    ndrReasonChartErrorCode: "",

    rtoData: [],
    rtoDataLoading: false,
    rtoDataError: false,
    rtoDataErrorMsg: "",
    rtoDataErrorCode: "",

    rtoDownloadCsvLoading: false,

    pageSize: 10,
    page: 1,
    total: 0,
  };
  myData = this.state.ndrReasonChart;

  goToPage(page) {
    this.setState(
      {
        page: page,
      },
      () => {
        this.rtoJourney();
      }
    );
  }

  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        pageSize: pageSize,
      },
      () => this.rtoJourney()
    );
  }

  componentDidUpdate() {
    this.myData = this.state.ndrReasonChart;
  }

  componentDidMount() {
    this.ndrReasonChart();
    // this.rtoJourney();
  }

  componentWillUnmount() {
    if (this.cancelToken1) {
      this.cancelToken1.cancel();
    }
    if (this.cancelToken2) {
      this.cancelToken2.cancel();
    }
  }

  ndrReasonChart() {
    this.setState(
      {
        ndrReasonChartLoading: true,
      },
      () => {
        if (this.cancelToken1) {
          this.cancelToken1.cancel();
        }
        this.cancelToken1 = axios.CancelToken.source();
        axios({
          url: process.env.REACT_APP_API_URL + "/bqreporting/ndr_reason_chart",
          method: "post",
          cancelToken: this.cancelToken1.token,
          data: {
            store_id: this.props.match.params.storeid,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                ndrReasonChart: this.dataHandler(res),
                ndrReasonChartLoading: false,
                ndrReasonChartError: false,
                ndrReasonChartErrorMsg: "",
              });
            } else {
              this.setState({
                ndrReasonChart: [],

                ndrReasonChartError: true,
                ndrReasonChartErrorMsg: res.data.msg,
                ndrReasonChartLoading: false,
              });
            }
          })
          .catch((e) => {
            if (!!e.request) {
              this.setState({
                ndrReasonChartError: true,
                ndrReasonChartErrorCode: e.request.status,
                ndrReasonChartErrorMsg: e.request.statusText,
                ndrReasonChartLoading: false,
              });
            } else {
              this.setState({
                ndrReasonChartError: false,
                ndrReasonChartErrorMsg: e.message,
                ndrReasonChartLoading: true,
              });
            }
          });
      }
    );
  }
  rtoJourney() {
    this.setState(
      {
        rtoDataLoading: true,
      },
      () => {
        if (this.cancelToken2) {
          this.cancelToken2.cancel();
        }
        this.cancelToken2 = axios.CancelToken.source();
        axios({
          url: process.env.REACT_APP_API_URL + "/bqreporting/rto_journey",
          method: "post",
          cancelToken: this.cancelToken2.token,
          data: {
            store_id: this.props.match.params.storeid,
            pageSize: this.state.pageSize,
            page: this.state.page,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                rtoData: res.data.data,
                total: res.data.total,
                rtoDataLoading: false,
                rtoDataError: false,
                rtoDataErrorMsg: "",
              });
            } else {
              this.setState({
                rtoData: [],
                rtoDataLoading: true,
                rtoDataError: res.data.msg,
                rtoDataErrorMsg: false,
              });
            }
          })
          .catch((e) => {
            if (!!e.request) {
              this.setState({
                rtoDataLoading: false,
                rtoDataError: true,
                rtoDataErrorMsg: e.request.statusText,
                rtoDataErrorCode: e.request.status,
              });
            } else {
              this.setState({
                rtoDataLoading: true,
                rtoDataError: false,
                rtoDataErrorMsg: e.message,
              });
            }
          });
      }
    );
  }

  startDateHandler(startDate) {
    if (!!startDate) {
      return moment(startDate).format("DD MMM, YYYY");
    } else {
      return "";
    }
  }

  endDateHandler(endDate) {
    if (!!endDate) {
      return moment(endDate).format("DD MMM, YYYY");
    } else {
      return "";
    }
  }

  dataHandler(res) {
    try {
      let data = [];

      if (res.data.data.length) {
        data = res.data.data.map((d) => {
          d.activeTooltip = false;
          d.percantage =
            (100 /
              res.data.data.map((d) => d.value).reduce((a, b) => a + b, 0)) *
            d.value;
          return d;
        });
        return data;
      } else {
        return data;
      }
    } catch (error) {
      return [];
    }
  }

  customData(tooltiptext) {
    if (tooltiptext) {
      let data = this.state.ndrReasonChart.map((d) => {
        if (d.name === tooltiptext) {
          d.activeTooltip = true;
        } else {
          d.activeTooltip = false;
        }

        return d;
      });

      this.setState({ ndrReasonChart: data });
    } else {
      let data = this.state.ndrReasonChart.map((d) => {
        d.activeTooltip = false;

        return d;
      });

      this.setState({ ndrReasonChart: data });
    }
  }

  // download csv

  rtoDownloadCsv() {
    this.setState(
      {
        rtoDownloadCsvLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/bqreporting/rto_journey",
          method: "post",
          data: {
            downloadcsv: 1,
            store_id: this.props.match.params.storeid,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
          },
          withCredentials: true,
        })
          .then((res) => {
            this.setState({ rtoDownloadCsvLoading: false });

            if (res.data.success === 1) {
              message.success(res.data.msg);
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({ rtoDownloadCsvLoading: false });

            message.error(e.message);
          });
      }
    );
  }

  changeFilter(e) {
    this.setState(
      {
        startDate: moment(e.startDate).format("YYYY-MM-DD"),
        endDate: moment(e.endDate).format("YYYY-MM-DD"),
      },
      () => {
        this.ndrReasonChart();
        // this.rtoJourney();
      }
    );
  }

  render() {
    const PageWrapper = (children) => {
      return (
        <>
          <div className="order-reporting-wraper">
            <RtoReportNav
              filters={this.state}
              {...this.props}
              changeFilter={(e) => this.changeFilter(e)}
              changeHandler={(e) => this.changeHandler(e)}
              ndrs={this.state.ndrs}
              ndrcount={this.state.ndrcount}
              storeid={this.props.match.params.storeid}
              loading={false}
            />
            <Row className="tabs-row">{children}</Row>
          </div>
        </>
      );
    };

    return PageWrapper(
      <>
        {/* {this.state.ndrReasonChartLoading ? null : (
          <h2
            style={{ marginBottom: "12px" }}
            className="content-page-subtitle"
          >
            Data shown for your Shopify Orders between{" "}
            {moment(this.state.startDate).format("DD MMM, YYYY")} -{" "}
            {moment(this.state.endDate).format("DD MMM, YYYY")}
          </h2>
        )} */}
        {this.state.ndrReasonChartLoading ? null : (
          <div className="alert-wrap-reporting">
            <Alert
              style={{ marginBottom: "40px" }}
              message="Important Information"
              description="Please note the data below updates every 2 hours."
              type="info"
              showIcon
            />
            <span
              className="ex-ic"
              style={{
                width: "24px",
                height: "24px",
                fontSize: "24px",
                lineHeight: "24px",
                display: "inline-block",
                color: "#0068FF",
              }}
            >
              <Icon type="exclamation-circle" theme="filled" />
            </span>
          </div>
        )}
        <PieChart
          graphName={"NDR Reasons"}
          customData={(d) => this.customData(d)}
          loading={this.state.ndrReasonChartLoading}
          data={this.state.ndrReasonChart}
          data2={JSON.parse(JSON.stringify(this.state.ndrReasonChart))}
          error={this.state.ndrReasonChartError}
          errorMsg={this.state.ndrReasonChartErrorMsg}
          errorCode={this.state.ndrReasonChartErrorCode}
        />

        {/* <CustomTable
          customColumns={true}
          data={this.state.rtoData}
          downloadCSVLoading={this.state.rtoDownloadCsvLoading}
          downloadCSV={() => this.rtoDownloadCsv()}
          loading={this.state.rtoDataLoading}
          error={this.state.rtoDataError}
          errorMsg={this.state.rtoDataErrorMsg}
          errorCode={this.state.rtoDataErrorCode}
          startDate={this.startDateHandler(this.state.startDate)}
          endDate={this.endDateHandler(this.state.endDate)}
          graphName={"RTO Journey"}
          currentPage={this.state.page}
          pageSize={this.state.pageSize}
          total={this.state.total}
          goToPage={(e) => this.goToPage(e)}
          onShowSizeChange={(a, b) => this.onShowSizeChange(a, b)}
        /> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  store: state.auth.auth.store,
});
export default connect(mapStateToProps)(NdrReport);
