import React, { Component } from "react";
import { Icon, Tooltip } from "antd";
import { Link } from "react-router-dom";
// import { ReactComponent as ShipmentStatusIcon } from "../../../../../assets/images/icons/shopping-cart.svg";
import "./index.scss";
import { getCurrencySymbol } from "../../../../../Helpers/Pricing";
// import Shipmentprogressbar from "../../../Orders/ManageOrders/OrderCardNew/GlobalContant/ShipmentProgressbar";
// import Shipmentprogressbar2 from "../ShipmentProgressbar";
import OrderProductTitle from "../../../Common/GlobalCommon/ProductTitle/OrderTitle";
import moment from "moment";

class LineItems extends Component {
  render() {
    const { shipmentStatusColors, shipmentStatusText } = this.props;
    const lineItems = this.props.lineItems;
    const shipment = this.props.shipment;
    return (
      <div className="line-items-tab">
        <div className="line-items-tab_header_upper_data">
          <p className="flex33">Variant Details</p>
          {/* <p className="flex44">Order Status</p> */}
          <p className="flex55">
            <div className="Quantity">Quantity</div>
            <div className="star">x</div>
            <div className="Shopify_Selling_Price">Shopify Selling Price</div>
          </p>
          <p className="flex66">Action</p>
        </div>

        {lineItems.map((li) => {
          return (
            <div style={{ marginBottom: "8px" }}>
              <div className={`line-item `} key={li.lineitem_id}>
                <div className="line-item-product-detail">
                  <div>
                    <span
                      className="line-item-img"
                      style={{ background: `url(${li.img})` }}
                    />
                  </div>
                  <div>
                    <p className="blue-text line-item-name">
                      {/* <Tooltip title={li.name} placement="topLeft">
                        {li.name}
                      </Tooltip> */}

                      <OrderProductTitle
                        orderProductname={li.name}
                        productShopifyid={li.product_shopifyid}
                        storeShopifyadmin={li.product_link}
                      />
                    </p>

                    <p className="line-item-name-sku" style={{ color: "#000" }}>
                      <span style={{ color: "#898989" }}>SKU: </span>
                      <Tooltip title={li.sku} placement="topLeft">
                        {li.sku ? `#${li.sku}` : "Not Avaiable"}
                      </Tooltip>
                    </p>

                    {!!li.vfsku ? (
                      <p className="line-item-sku">
                        <span style={{ color: "#898989" }}>VFSKU: </span>{" "}
                        <Link
                          to={`./products/${li.vfsku.split("-")[0]}`}
                          href="#"
                          target="_blank"
                        >
                          {li.vfsku}{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 17.528 17.811"
                          >
                            <g
                              id="Open_In_New_Window_Icon"
                              transform="translate(-961.212 -332.939)"
                            >
                              <path
                                id="Path_84"
                                data-name="Path 84"
                                d="M-4591.894-691.587h-4.312v16h16v-4.1"
                                transform="translate(5558.167 1025.587)"
                                fill="none"
                                stroke="#000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                              />
                              <g
                                id="Group_3178"
                                data-name="Group 3178"
                                transform="translate(968.509 334)"
                              >
                                <path
                                  id="Path_85"
                                  data-name="Path 85"
                                  d="M-4590.015-682.718l9.17-9.27"
                                  transform="translate(4590.015 691.988)"
                                  fill="none"
                                  stroke="#000"
                                  stroke-linecap="round"
                                  stroke-width="1.5"
                                />
                                <path
                                  id="Path_86"
                                  data-name="Path 86"
                                  d="M-4587.394-691.587h6.091v5.81"
                                  transform="translate(4590.756 691.587)"
                                  fill="none"
                                  stroke="#000"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.5"
                                />
                              </g>
                            </g>
                          </svg>
                        </Link>
                      </p>
                    ) : null}

                    {li.allspecs ? (
                      <p className="line-item-sku" style={{ color: "#000" }}>
                        <span style={{ color: "#898989" }}>Specs: </span>{" "}
                        {li.allspecs === "" ? "Default" : li.allspecs}
                      </p>
                    ) : null}
                  </div>
                </div>

                {/* <div className="openordersvg">
                  {shipment.status === "cancelled" ? (
                    <p style={{ fontSize: "12px" }}>cancelled</p>
                  ) : (
                    <Tooltip title={shipmentStatusText[this.props.status]}>
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                      >
                        <g
                          id="Group_3644"
                          data-name="Group 3644"
                          transform="translate(-640 -571)"
                        >
                          <circle
                            id="Ellipse_42"
                            data-name="Ellipse 42"
                            cx="8"
                            cy="8"
                            r="8"
                            transform="translate(640 571)"
                            fill="#1161fb"
                          />
                          <path
                            id="path16264"
                            d="M265.67,581.052l-.231-.24a.636.636,0,0,0-.923,0l-3.921,4.081-1.391-1.447a.635.635,0,0,0-.923,0l-.231.24a.7.7,0,0,0,0,.961l2.082,2.169a.638.638,0,0,0,.923,0l4.615-4.8a.7.7,0,0,0,0-.961Z"
                            transform="translate(386.14 -4.813)"
                            fill="#fff"
                          />
                        </g>
                      </svg>
                    </Tooltip>
                  )}
                </div> */}

                <div className="quntyandpices">
                  <span className="Quantity">
                    {" "}
                    {li.quantity} {li.quantity === 1 ? "piece" : "pieces"}
                  </span>
                  <span className="star">x</span>
                  <span className="Shopify_Selling_Price">
                    <Tooltip title="Shopify Selling Price">
                      {getCurrencySymbol(
                        this.props.geo === "in"
                          ? "INR"
                          : this.props.currencies.store_currency
                      )}{" "}
                      {li.item_total_sold_price.amount +
                        this.props.shipment.shipping_perquantity}
                    </Tooltip>
                  </span>
                </div>

                <div
                  className={
                    shipment.status === "lost" ? "lost-data" : "end_data"
                  }
                >
                  <div
                    style={{
                      color: shipmentStatusColors[this.props.status],
                      display: "flex",
                      alignItems: "center",
                      letterSpacing: "0.032em",
                      fontWeight: 500,
                      lineHeight: "16px",
                    }}
                  >
                    {shipment.status === "cancelled" ? (
                      <p>Cancelled</p>
                    ) : (
                      <div>
                        {" "}
                        {/* <Icon
                          component={ShipmentStatusIcon}
                          style={{ marginRight: "8px", marginTop: "6px" }}
                        /> */}
                      </div>
                    )}

                    <div style={{ color: "#9B9B9B" }}>
                      <div
                        className={
                          shipmentStatusText[this.props.status] ===
                          "RTO in-Transit"
                            ? "rto-in-transit-status other-status"
                            : "other-status"
                        }
                      >
                        {shipmentStatusText[this.props.status] ===
                        "In Transit" ? (
                          <span style={{ marginRight: "18px" }}>
                            {shipmentStatusText[this.props.status]}
                          </span>
                        ) : (
                          shipmentStatusText[this.props.status]
                        )}
                      </div>
                      <div>
                        {" "}
                        {(this.props.status === "delivered" ||
                          this.props.status === "rto") && (
                          <p
                            className="delivered-date"
                            style={{
                              color: shipmentStatusColors[this.props.status],
                            }}
                          >
                            {this.props.status === "rto" ? null : null}{" "}
                            {moment(this.props.shipment.closed_on).format(
                              "MMM DD, YYYY"
                            )}
                          </p>
                        )}
                      </div>

                      {/* <div>
                        {" "}
                        {this.props.status === "transit" && (
                          <p
                            className="delivered-date"
                            style={{
                              color: shipmentStatusColors[this.props.status],
                            }}
                          >
                            {moment(this.props.shipment.shipment_date).format(
                              "MMM DD, YYYY"
                            )}
                          </p>
                        )}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {/* <Shipmentprogressbar lineItems={lineItems} shipment={shipment} /> */}
      </div>
    );
  }
}

export default LineItems;
