import React from "react";
import { Modal, Table } from "antd";
import moment from "moment";
import "./index.scss";

const NDRRemarksModal = props => {
  return (
    <Modal
      className="ndr-modal"
      title="NDR Remarks"
      onCancel={() => props.toggleNDRRemarksModal()}
      visible={props.visible}
      width={800}
      footer={null}
      destroyOnClose
    >
      <Table
        dataSource={props.remarks}
        pagination={false}
        columns={[
          {
            title: "Date",
            dataIndex: "date",
            render: date => moment(date).format("DD MMM YYYY | HH:mm")
          },
          {
            title: "Remark",
            dataIndex: "remark"
          },
          {
            title: "Added By",
            dataIndex: "addedbyname"
          }
        ]}
      />
    </Modal>
  );
};

export default NDRRemarksModal;
