import React, { Component } from "react";
import "./index.scss";
import { ReactComponent as Circle } from "../../../assets/images/icons/circleShadowSignup.svg";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import LeftContainer from "./LeftContainer";
import RightContainer from "./RightContainer";

class EmailVerify extends Component {
  state = {
    whatsapp: true,
    email: "",
    signupapiurl: "/login/signup_insider",
  };
  componentDidMount() {
    document.title = this.props.title;
  }
  render() {
    if(this.props.user.email_verified){
      return <Redirect to={"/switch-store"}/>
    }
    return (
      <div className="signup-container-new1">
        <span className="shadow-container">
          <Circle />
        </span>
        <div className="mainCard-signup">
          <LeftContainer />
          <RightContainer  {...this.props}/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.auth.user,
  };
};

export default connect(mapStateToProps)(EmailVerify);
