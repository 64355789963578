import React from "react";
import "./index.scss";
import moment from "moment";

import { Tooltip } from "antd";

class Private_request_latest_price extends React.Component {
  render() {
    return (
      <>
        <span className="">
          {moment().diff(moment(this.props.product.costUpdated), "days") > 7 ? (
            this.props.product.request_latest_price.success === 0 ? (
              this.props.product.request_latest_price.success === 0 &&
              this.props.requestCount === 0 ? (
                <Tooltip
                  title={
                    <p
                      style={{ textAlign: "center", fontSize: "10px" }}
                      className="cursor-pointer"
                    >
                      Request Latest Price
                      <span
                        style={{
                          fontWeight: "500",
                          textDecoration: "underline",
                          display: "block",
                        }}
                      >
                        Pricing Requests Left: 0
                      </span>
                      <span
                        style={{
                          marginTop: "20px",
                          display: "block",
                        }}
                      >
                        <i>*Your 5 Pricing Requests will be</i>
                      </span>
                      <span
                        style={{
                          display: "block",
                        }}
                      >
                        <i>reset next week.</i>
                      </span>
                    </p>
                  }
                >
                  <p className="refresh_tooltip_ican2">
                    {/* {moment().diff(moment(product.costUpdated), "days") > 7 ? ( */}

                    <a
                      href="#!"
                      className="req-latest-price"
                      // onClick={() => this.requestUpdatedQuotation()}
                    >
                      <svg
                        style={{ fill: "#B2B2B2" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="12.8"
                        viewBox="0 0 16 12.8"
                      >
                        <path
                          id="icons8-refresh"
                          d="M8,3A6.382,6.382,0,0,0,3.844,4.533a.533.533,0,1,0,.693.81,5.334,5.334,0,0,1,8.771,3.523H11.733l2.133,3.2L16,8.867H14.373A6.406,6.406,0,0,0,8,3ZM2.133,6.733,0,9.933H1.627a6.4,6.4,0,0,0,10.529,4.333.533.533,0,1,0-.693-.81A5.334,5.334,0,0,1,2.693,9.933H4.267Z"
                          transform="translate(0 -3)"
                        />
                      </svg>
                    </a>
                  </p>
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    <p
                      style={{ textAlign: "center", fontSize: "10px" }}
                      className="cursor-pointer"
                    >
                      Request Latest Price
                      <span
                        style={{
                          fontWeight: "500",
                          textDecoration: "underline",
                          display: "block",
                          fontSize: "10px"
                        }}
                      >
                        {this.props.product.request_latest_price.msg}
                      </span>
                    </p>
                  }
                >
                  <p className="refresh_tooltip_ican2">
                    {/* {moment().diff(moment(product.costUpdated), "days") > 7 ? ( */}

                    <a
                      href="#!"
                      className="req-latest-price"
                      // onClick={() => this.requestUpdatedQuotation()}
                    >
                      <svg
                        style={{ fill: "#B2B2B2" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="12.8"
                        viewBox="0 0 16 12.8"
                      >
                        <path
                          id="icons8-refresh"
                          d="M8,3A6.382,6.382,0,0,0,3.844,4.533a.533.533,0,1,0,.693.81,5.334,5.334,0,0,1,8.771,3.523H11.733l2.133,3.2L16,8.867H14.373A6.406,6.406,0,0,0,8,3ZM2.133,6.733,0,9.933H1.627a6.4,6.4,0,0,0,10.529,4.333.533.533,0,1,0-.693-.81A5.334,5.334,0,0,1,2.693,9.933H4.267Z"
                          transform="translate(0 -3)"
                        />
                      </svg>
                    </a>
                  </p>
                </Tooltip>
              )
            ) : (
              <Tooltip
                title={
                  this.props.requestCount === 0 ? (
                    <p
                      style={{ textAlign: "center", fontSize: "10px" }}
                      className="cursor-pointer"
                    >
                      Request Latest Price
                      <span
                        style={{
                          fontWeight: "500",
                          textDecoration: "underline",
                          display: "block",
                          fontSize: "10px"
                        }}
                      >
                        Pricing Requests Left: 0
                      </span>
                      <span
                        style={{
                          marginTop: "20px",
                          display: "block",
                        }}
                      >
                        <i>*Your 5 Pricing Requests will be</i>
                      </span>
                      <span
                        style={{
                          display: "block",
                        }}
                      >
                        <i>reset next week.</i>
                      </span>
                    </p>
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "10px",
                        letterSpacing: "0.032em",

                      }}
                      className="cursor-pointer"
                    >
                      Request latest price{" "}
                      <span
                        style={{
                          textAlign: "center",
                          textDecoration: "underline",
                          display: "block",
                          fontSize: "10px"
                        }}
                      >
                        {" "}
                        Pricing Requests Left: {this.props.requestCount}
                      </span>{" "}
                    </p>
                  )
                }
              >
                <p className="refresh_tooltip_ican">
                  {/* {moment().diff(moment(product.costUpdated), "days") > 7 ? ( */}

                  <a
                    href="#!"
                    className={`req-latest-price request-tooltip  ${
                      this.props.requestCount === 0 ||
                      this.props.clicked === true
                        ? `sv2`
                        : null
                    }`}
                    onClick={() => this.props.requestUpdatedQuotation()}
                  >
                    <svg
                      className="sv"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="12.8"
                      viewBox="0 0 16 12.8"
                    >
                      <path
                        id="icons8-refresh"
                        d="M8,3A6.382,6.382,0,0,0,3.844,4.533a.533.533,0,1,0,.693.81,5.334,5.334,0,0,1,8.771,3.523H11.733l2.133,3.2L16,8.867H14.373A6.406,6.406,0,0,0,8,3ZM2.133,6.733,0,9.933H1.627a6.4,6.4,0,0,0,10.529,4.333.533.533,0,1,0-.693-.81A5.334,5.334,0,0,1,2.693,9.933H4.267Z"
                        transform="translate(0 -3)"
                      />
                    </svg>

                    {/* <div
                        className="loader"
                        style={{
                          width: 15,
                          height: 15,
                          marginLeft: 8,
                          verticalAlign: "bottom",
                        }}
                      /> */}
                  </a>
                </p>
              </Tooltip>
            )
          ) : (
            <Tooltip
              title={
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "10px",
                    letterSpacing: "0.032em",
                  }}
                  className="cursor-pointer"
                >
                  Request Latest Price
                  <span
                    style={{
                      fontWeight: "500",
                      textDecoration: "underline",
                      display: "block",
                      fontSize: "10px"
                    }}
                  >
                    Pricing last updated on:{" "}
                    {moment().diff(
                      moment(this.props.product.costUpdated),
                      "days"
                    ) === 1
                      ? `${moment().diff(
                          moment(this.props.product.costUpdated),
                          "days"
                        )} Day ago`
                      : `${moment().diff(
                          moment(this.props.product.costUpdated),
                          "days"
                        )} Days ago`}
                  </span>
                  <p>
                    You can request latest price after (
                    {7 -
                      moment().diff(
                        moment(this.props.product.costUpdated),
                        "days"
                      ) ===
                    1
                      ? `1 Day`
                      : `${7 -
                          moment().diff(
                            moment(this.props.product.costUpdated),
                            "days"
                          )} Days`}
                    )
                  </p>
                </p>
              }
            >
              <a
                href="#!"
                className="req-latest-price"
                // onClick={() => this.requestUpdatedQuotation()}
              >
                <svg
                  style={{ fill: "#B2B2B2" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="12.8"
                  viewBox="0 0 16 12.8"
                >
                  <path
                    id="icons8-refresh"
                    d="M8,3A6.382,6.382,0,0,0,3.844,4.533a.533.533,0,1,0,.693.81,5.334,5.334,0,0,1,8.771,3.523H11.733l2.133,3.2L16,8.867H14.373A6.406,6.406,0,0,0,8,3ZM2.133,6.733,0,9.933H1.627a6.4,6.4,0,0,0,10.529,4.333.533.533,0,1,0-.693-.81A5.334,5.334,0,0,1,2.693,9.933H4.267Z"
                    transform="translate(0 -3)"
                  />
                </svg>
              </a>
            </Tooltip>
          )}
        </span>
      </>
    );
  }
}

export default Private_request_latest_price;
