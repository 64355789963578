import React, { Component } from "react";
import { Row, Col, Button, Select, Modal, Checkbox, Spin, message } from "antd";
import axios from "axios";
import debounce from "lodash/debounce";
import PRODUCT_PLACEHOLDER_IMAGE from "../../../../../assets/images/icons/image_placeholder.png";
import "./index.scss";
const { Option } = Select;

class OverrideProductModal extends Component {
  state = {
    shopifyProductId: null,
    fetching: false,
    loading: false,
    shopifyProducts: [],
    overrideTitleAndDetails: false,
    oveerideImages: false,
  };

  handleChange = (field, value) => {
    this.setState({
      ...this.state,
      [field]: value,
    });
  };

  resetModal = () => {
    this.setState({
      shopifyProductId: null,
      fetching: false,
      loading: false,
      shopifyProducts: [],
      overrideTitleAndDetails: false,
      oveerideImages: false,
    });
  };

  fetchShopifyProducts(search) {
    const that = this;
    this.setState(
      {
        shopifyProducts: [],
        fetching: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL + "/products/fetch_products_shopify",
          method: "post",
          data: {
            storeid: this.props.storeid,
            search,
          },
          withCredentials: true,
        })
          .then((res) => {
            that.setState({
              shopifyProducts: res.data ? res.data : [],
              fetching: false,
            });
          })
          .catch((e) => {
            that.setState({
              shopifyProducts: [],
              fetching: false,
            });
          });
      }
    );
  }

  overrideProduct() {
    const that = this;
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/products/override_product",
          method: "post",
          data: {
            storeid: this.props.storeid,
            shopifyProductId: this.state.shopifyProductId,
            productId: this.props.overrideData._id["$oid"],
            overrideTitleAndDetails: this.state.overrideTitleAndDetails
              ? this.state.overrideTitleAndDetails
              : false,
            overrideImages: this.state.overrideImages
              ? this.state.overrideImages
              : false,
            from: this.props.sourcePage,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              that.props.onProductOverrideSuccessfull(
                this.props.overrideData._id["$oid"]
              );
              message.success("Product overridden successfully");
            } else {
              that.setState({
                loading: false,
              });
              message.error("There was an error overriding this product");
            }
          })
          .catch((e) => {
            that.setState({
              loading: false,
            });
            message.error("There was an error overriding this product");
          });
      }
    );
  }

  render() {
    const { visible, onCancel, overrideData } = this.props;
    const { overrideTitleAndDetails, oveerideImages } = this.state;

    return (
      <Modal
        className="product-override-modal"
        title="Select an existing product"
        visible={visible}
        onCancel={onCancel}
        afterClose={this.resetModal}
        maskClosable={false}
        closable={!this.state.loading}
        footer={[
          <Button key="back" onClick={onCancel} disabled={this.state.loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => this.overrideProduct()}
            loading={this.state.loading}
          >
            Override
          </Button>,
        ]}
      >
        <div>
          <Row>
            <Col xs={24} md={11}>
              <p className="input-label">Existing Product</p>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a product"
                optionFilterProp="children"
                onChange={(value) => this.setState({ shopifyProductId: value })}
                notFoundContent={
                  this.state.fetching ? <Spin size="small" /> : null
                }
                onSearch={debounce(
                  (val) => this.fetchShopifyProducts(val),
                  500
                )}
                size="large"
                filterOption={false}
              >
                {this.state.shopifyProducts.map((p) => (
                  <Option key={p.id} value={p.id}>
                    <img
                      src={p.img ? p.img : PRODUCT_PLACEHOLDER_IMAGE}
                      style={{ maxWidth: "30px" }}
                      alt={p.title}
                    />
                    <span style={{ marginLeft: "15px" }}>{p.title}</span>
                  </Option>
                ))}
              </Select>
            </Col>

            <Col xs={24} md={{ span: 11, offset: 1 }}>
              {overrideData && (
                <div style={{ marginTop: "50px" }}>
                  <img
                    src={
                      overrideData.gallery && overrideData.gallery[0]
                        ? overrideData.gallery[0]
                        : PRODUCT_PLACEHOLDER_IMAGE
                    }
                    style={{ maxWidth: "30px", verticalAlign: "middle" }}
                    alt={overrideData.name}
                  />
                  <p
                    style={{
                      margin: "0 0 0 20px",
                      verticalAlign: "middle",
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "calc(100% - 50px)",
                      display: "inline-block",
                    }}
                  >
                    {overrideData.name}
                  </p>
                </div>
              )}
            </Col>
          </Row>

          <div className="checkbox-container">
            <Checkbox
              checked={overrideTitleAndDetails}
              onChange={(e) =>
                this.handleChange("overrideTitleAndDetails", e.target.checked)
              }
            >
              Override Title and Details
            </Checkbox>
            <p className="sub-text">
              Selecting this option will replace your existing product title &
              details with title & details from overriding product
            </p>

            <Checkbox
              checked={oveerideImages}
              onChange={(e) =>
                this.handleChange("oveerideImages", e.target.checked)
              }
            >
              Override Images
            </Checkbox>
            <p className="sub-text">
              Selecting this option will replace all your existing product
              images with the images from overriding product
            </p>
          </div>
        </div>
      </Modal>
    );
  }
}

export default OverrideProductModal;
