import React, { Component } from "react";
import { Button, Input, Modal, Icon, message, Spin } from "antd";
import axios from "axios";
import "./index.scss";
import { connect } from "react-redux";
import { ReactComponent as EditiCON } from "../../../../assets/images/icons/editican.svg";
import { ReactComponent as RightArrow } from "../../../../assets/images/icons/rightarrow.svg";
import { signIn } from "../../../../store/actions/authActions";
import analytics from "../../../../analytics";
// import { signIn } from "../../../../../../store/actions/authActions";
import { ReactComponent as VfulfillLogo } from "../../../../assets/images/icons/vFULLfillLogo.svg";
class OtpVerifyContainer extends Component {
  state = {
    minutes: 0,
    seconds: 30,
    loading: false,
    editError: false,
    loadingVerification: false,
    loadingedit: false,
    email: this.props.email,
    myinterval: 0,
    edit: false,
    emailotp: {
      emailotp1: "",
      emailotp2: "",
      emailotp3: "",
      emailotp4: "",
      emailotp5: "",
      emailotp6: "",
    },
  };

  startTimer() {
    if (this.state.myinterval != 0) {
      clearInterval(this.state.myinterval);
    }
    this.setState(
      {
        minutes: 0,
        seconds: 30,
      },
      () => {
        this.state.myinterval = setInterval(() => {
          const { seconds, minutes } = this.state;
          if (seconds > 0) {
            this.setState(({ seconds }) => ({
              seconds: seconds - 1,
            }));
          }
          if (seconds === 0) {
            if (minutes === 0) {
              clearInterval(this.state.myinterval);
            } else {
              this.setState(({ minutes }) => ({
                minutes: minutes - 1,
                seconds: 59,
              }));
            }
          }
        }, 1000);
      }
    );
  }

  // updateEmail() {
  //   this.props.updateEmail();
  //   this.startTimer();
  // }
  updateEmail() {
    this.setState(
      {
        editError: false,
      },
      () => {
        if (
          this.state.email.match(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          this.setState(
            {
              loadingedit: true,
            },
            () => {
              axios({
                url:
                  process.env.REACT_APP_API_URL + "/user/update_email_address",
                method: "post",
                data: {
                  email: this.state.email,
                },
                withCredentials: true,
              })
                .then((res) => {
                  this.setState({
                    loadingedit: false,
                  });
                  if (res.data.success === 1) {
                    message.success("Email updated successfully.");
                    this.setState({
                      edit: false,
                      emailotp: {
                        emailotp1: "",
                        emailotp2: "",
                        emailotp3: "",
                        emailotp4: "",
                        emailotp5: "",
                        emailotp6: "",
                      },
                    });
                    this.startTimer();
                  } else {
                    message.error(res.data.msg);
                  }
                })
                .catch((e) => {
                  message.error(e.message);
                  this.setState({
                    loadingedit: false,
                  });
                });
            }
          );
        } else {
          this.setState({
            editError: true,
          });
        }
      }
    );
  }
  resendOtp(event) {
    event.preventDefault();
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/generate_otp_email",
          method: "post",
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
              });
              this.startTimer();
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
            message.error(e.message);
          });
      }
    );
  }
  pasteFuncEmail(ev) {
    console.log("in", ev);
    const clip = ev.clipboardData.getData("text"); // Get clipboard data
    const pin = clip.replace(/\s/g, ""); // Sanitize string
    const ch = [...pin]; // Create array of chars
    console.log("in", ch);
    if (ch.length === 6) {
      this.setState({
        emailotp: {
          emailotp1: ch[0],
          emailotp2: ch[1],
          emailotp3: ch[2],
          emailotp4: ch[3],
          emailotp5: ch[4],
          emailotp6: ch[5],
        },
      });
    }
  }
  handleSubmit(e) {
    e.preventDefault();

    analytics.track("OTP Verification Clicked");
    this.setState(
      {
        loadingVerification: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/verify_otp_email",
          method: "post",
          data: {
            location: "main",
            email: {
              user_email: this.state.email,
              otp: parseInt(
                this.state.emailotp.emailotp1 +
                  this.state.emailotp.emailotp2 +
                  this.state.emailotp.emailotp3 +
                  this.state.emailotp.emailotp4 +
                  this.state.emailotp.emailotp5 +
                  this.state.emailotp.emailotp6
              ),
            },
          },
          withCredentials: true,
        })
          .then((res) => {
            this.setState({ loadingVerification: false });
            if (res.data.success) {
              // message.success(res.data.msg);

              analytics.track("OTP Verification Success");

              this.props.signIn({
                email: this.state.email,
                password: this.props.password,
              });
            } else {
              message.error(res.data.msg);
              analytics.track("OTP Failed");
            }
          })
          .catch((err) => {
            message.error(err.message);
            this.setState({ loadingVerification: false });
          });
      }
    );
  }
  otpChnageEmail(field, value) {
    this.setState({
      emailotp: {
        ...this.state.emailotp,
        [field]: value,
      },
    });
  }
  inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };
  componentDidMount() {
    this.startTimer();
  }
  componentDidUpdate(pp) {
    if (pp.activeKey !== this.props.activeKey) {
      if (this.state.myinterval != 0) {
        clearInterval(this.state.myinterval);
      }
      this.startTimer();
    }
  }
  componentWillUnmount() {
    clearInterval(this.state.myinterval);
  }
  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value,
    });
  };
  render() {
    const emailVerify =
      !!this.state.emailotp.emailotp1.length &&
      !!this.state.emailotp.emailotp2.length &&
      !!this.state.emailotp.emailotp3.length &&
      !!this.state.emailotp.emailotp4.length &&
      !!this.state.emailotp.emailotp5.length &&
      !!this.state.emailotp.emailotp6.length;
    return (
      <div className="otpVerifyContaner">
        {" "}
        <div class="logoRightContainer">
          <VfulfillLogo />
        </div>
        <div className="heading-bar">
          <div className="main-heading">Verify Email Address.</div>
          <div className="sub-heading">
            We need to verify your email address.
          </div>
        </div>
        <div className="email-section">
          {!this.state.edit ? (
            <div className="email-filled">
              <input
                value={this.state.email}
                className="input-email"
                disabled={true}
              />
              <span
                className="edit"
                onClick={() => {
                  this.setState({
                    edit: true,
                  });
                }}
              >
                <span className="editiconContainer">
                  <EditiCON />
                </span>{" "}
                <span className="edit-s">Edit</span>
              </span>
            </div>
          ) : (
            <div>
              <div className="email-filled">
                <input
                  value={this.state.email}
                  className="input-email"
                  onChange={this.handleEmailChange}
                />
              </div>
            </div>
          )}

          <div className="otp-info">
            Your will receive an OTP on this email address.
          </div>
          {!!this.state.editError && (
            <div className="error-text">Please enter valid email!</div>
          )}
        </div>
        <div className="otp-section">
          {" "}
          {!this.state.edit ? (
            <form onSubmit={(e) => this.handleSubmit(e)}>
              {" "}
              <div className="otp-verify">
                <input
                  onPaste={(e) => this.pasteFuncEmail(e)}
                  name="otp1"
                  type="text"
                  autoComplete="off"
                  className="otp-number"
                  value={this.state.emailotp.emailotp1}
                  onKeyPress={this.keyPressed}
                  onChange={(e) =>
                    this.otpChnageEmail("emailotp1", e.target.value)
                  }
                  tabIndex="1"
                  maxLength="1"
                  onKeyUp={(e) => this.inputfocus(e)}
                />
                <input
                  name="otp2"
                  type="text"
                  autoComplete="off"
                  className="otp-number"
                  value={this.state.emailotp.emailotp2}
                  onChange={(e) =>
                    this.otpChnageEmail("emailotp2", e.target.value)
                  }
                  tabIndex="2"
                  maxLength="1"
                  onKeyUp={(e) => this.inputfocus(e)}
                />
                <input
                  name="otp3"
                  type="text"
                  autoComplete="off"
                  className="otp-number"
                  value={this.state.emailotp.emailotp3}
                  onChange={(e) =>
                    this.otpChnageEmail("emailotp3", e.target.value)
                  }
                  tabIndex="3"
                  maxLength="1"
                  onKeyUp={(e) => this.inputfocus(e)}
                />
                <input
                  name="otp4"
                  type="text"
                  autoComplete="off"
                  className="otp-number"
                  value={this.state.emailotp.emailotp4}
                  onChange={(e) =>
                    this.otpChnageEmail("emailotp4", e.target.value)
                  }
                  tabIndex="4"
                  maxLength="1"
                  onKeyUp={(e) => this.inputfocus(e)}
                />
                <input
                  name="otp5"
                  type="text"
                  autoComplete="off"
                  className="otp-number"
                  value={this.state.emailotp.emailotp5}
                  onChange={(e) =>
                    this.otpChnageEmail("emailotp5", e.target.value)
                  }
                  tabIndex="5"
                  maxLength="1"
                  onKeyUp={(e) => this.inputfocus(e)}
                />
                <input
                  name="otp6"
                  type="text"
                  autoComplete="off"
                  className="otp-number"
                  value={this.state.emailotp.emailotp6}
                  onChange={(e) =>
                    this.otpChnageEmail("emailotp6", e.target.value)
                  }
                  tabIndex="6"
                  maxLength="1"
                  onKeyUp={(e) => this.inputfocus(e)}
                />
              </div>
              <div className="msg-otp">
                Please wait 30 seconds for OTP to arrive
              </div>
              {this.state.loading ? (
                <Spin size="small" />
              ) : (
                <div className="resend-code">
                  <span>
                    <button
                      className={
                        this.state.minutes == 0 && this.state.seconds === 0
                          ? " resend"
                          : "disableresend resend"
                      }
                      loading={this.state.loading}
                      onClick={(e) => this.resendOtp(e)}
                    >
                      Resend Code
                    </button>
                  </span>
                  {this.state.seconds === 0 &&
                  this.state.minutes === 0 ? null : (
                    <span className="time-container">
                      in {this.state.minutes}:
                      {this.state.seconds < 10
                        ? `0${this.state.seconds}`
                        : this.state.seconds}
                    </span>
                  )}
                </div>
              )}
              <div className="submit-section">
                <div>
                  <Button
                    //   className={
                    //     emailVerify ? "verify-continue-btn" : "verify-continue-btn2"
                    //   }
                    disabled={!emailVerify}
                    className={
                      emailVerify
                        ? "verify-container"
                        : "disable-verify-container"
                    }
                    type="submit"
                    onClick={(e) => this.handleSubmit(e)}
                    loading={this.state.loadingVerification}
                  >
                    Verify OTP & Proceed
                    <span className="rightlogocontainer">
                      {" "}
                      <RightArrow />
                    </span>
                  </Button>
                </div>
                {/* <div>
                  <Button
                    className="go-back-btn"
                    onClick={() => {
                      this.props.changeTab();
                    }}
                  >
                    Go Back
                  </Button>
                </div> */}
              </div>
            </form>
          ) : (
            <div className="change-btn-container">
              {" "}
              <Button
                disabled={!this.state.email}
                className="change-emailbtn"
                onClick={() => this.updateEmail()}
                loading={this.state.loadingedit}
              >
                Save Email
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OtpVerifyContainer);
