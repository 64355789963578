import { Tooltip, Icon } from "antd";
import StarRating from "./StarRating";
import React from "react";
import ClickToCopy from "../../Common/ClickToCopy";
import { ReactComponent as InventoryAvailable } from "../../../../assets/images/icons/InventoryAvailable.svg";
import { ReactComponent as InventoryNotAvailable } from "../../../../assets/images/icons/InventoryNotAvailable.svg";
import { ReactComponent as RestockingWatch } from "../../../../assets/images/icons/RestockingWatch.svg";

const BasicDetails = ({
  product,
  goToReviewsTab,
  setActiveTab,
  geo,
  mainRef,
}) => {
  let averageRating = 0;
  let totalRatings = 0;
  let totalOrders = 0;
  let inventoryStatus = product.available_vFinventory.total > 0 ? true : false;

  if (product.alireviewsRatings && product.alireviewsRatings.average_rating) {
    averageRating = product.alireviewsRatings.average_rating;
  } else if (
    product.advertising_data &&
    product.advertising_data.average_rating
  ) {
    averageRating = product.advertising_data.average_rating;
  }

  if (product.alireviewsRatings && product.alireviewsRatings.total_rating) {
    totalRatings = product.alireviewsRatings.total_rating;
  } else if (
    product.advertising_data &&
    product.advertising_data.total_rating
  ) {
    totalRatings = product.advertising_data.total_rating;
  }

  if (product.alireviewsRatings.order_count) {
    totalOrders = product.alireviewsRatings.order_count;
  } else if (
    product.advertising_data &&
    product.advertising_data.aliexpress_analyics &&
    product.advertising_data.aliexpress_analyics.length
  ) {
    const orderData = product.advertising_data.aliexpress_analyics.find(
      (d) => d.key === "Orders:"
    );

    if (orderData) {
      totalOrders = orderData.value;
    }
  }

  function scrollDown() {
    setActiveTab();

    mainRef.current.parentElement.scrollTo(0, 1150);
  }

  function inventoryStatusHandler() {
    if (!!inventoryStatus) {
      return (
        <>
          <Icon component={InventoryAvailable} />
          <p>Inventory Available</p>
        </>
      );
    } else {
      return null;
    }
  }

  return (
    <div
      style={{
        borderBottom: "1px solid #CECECE",
        paddingBottom: 24,
        marginBottom: 32,
      }}
    >
      {/* product title */}
      <div className="product-title-wraper">
        <div className="product-name">{product.catalogname}</div>

        <div className="product-id-wraper">
          <div className="line">|</div>

          <div className="product-id">
            <p> PRODUCT ID:</p>

            <p>
              <ClickToCopy textToCopy={product.vfprodid} />
            </p>
          </div>
        </div>
      </div>

      {/* product sub heading */}

      {product.headline ? (
        <p className="product-sub-heading">{product.headline}</p>
      ) : null}

      {/* rating star wraper */}

      <div className="star-wraper">
        {product.inner_circle_product === 1 ? (
          <>
            {" "}
            <div
              className={totalRatings > 0 ? `star-parent` : null}
              onClick={() => scrollDown()}
            >
              <StarRating rating={averageRating} />
            </div>
            <div className="line-global">|</div>
          </>
        ) : null}

        {/* <div className="inventory-status">{inventoryStatusHandler()}</div> */}

        {/* to do  */}
        {/* <div className="inventory-restocking-status">
          <div className="line-global">|</div>
          <Icon component={RestockingWatch} />

          <p>
            Avg. Restocking Time:{" "}
            <span>
              {product.attributes.restocking_time.start}-
              {product.attributes.restocking_time.end} days
            </span>{" "}
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default BasicDetails;
