import React from "react";
import { Modal, Button, Tooltip } from "antd";
import "./index.scss";
import { Link } from "react-router-dom";

const ViewBundleModal = (props) => {
  return (
    <Modal
      className="view-product-bundle-modal"
      title="Product Bundle"
      onCancel={() => props.toggleViewBundleModal()}
      visible={props.bundle && props.bundle.length > 0}
      footer={
        <Button
          className="bundle-cancel-modal"
          onClick={() => props.toggleViewBundleModal()}
        >
          Close Window
        </Button>
      }
      width={560}
    >
      <>
        <div className="bundel-variant-header">
          <p className="variant-detail-txt">Variant Details</p>
          <p className="specifications-txt">Specifications</p>
          <p className="quantity-txt">Quantity</p>
        </div>

        <div className="bundle-bottom-variant-data">
          {!!props.bundle ? (
            props.bundle.map((bundleitem) => {
              return (
                <div className="bundle-variant-bottom-data">
                  <p className="variant-detail-txt">
                    <img src={bundleitem.img} alt="#" />
                    <div className="side">
                      {" "}
                      <p>
                        {bundleitem &&
                        bundleitem.name &&
                        bundleitem.name.length > 20 ? (
                          <Tooltip title={bundleitem.name}>
                            <Link
                              to={`/${props.store.store_geo}/${
                                props.store.id
                              }/products/${bundleitem.vfsku.split("-")[0]}`}
                              target="_blank"
                            >
                              {" "}
                              {`${bundleitem.name.substring(0, 20)}...`}
                            </Link>
                          </Tooltip>
                        ) : bundleitem && bundleitem.name ? (
                          <Link
                            to={`/${props.store.store_geo}/${
                              props.store.id
                            }/products/${bundleitem.vfsku.split("-")[0]}`}
                            target="_blank"
                          >
                            {" "}
                            {bundleitem.name}
                          </Link>
                        ) : (
                          <span className="not-available">Not Available</span>
                        )}
                      </p>{" "}
                      <p
                        style={{
                          margin: "0 0 0 0px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <span>VFSKU:</span>
                        {""}
                        <span className="bundleitem-vfsku">
                          {" "}
                          {bundleitem && bundleitem.vfsku ? (
                            bundleitem.vfsku
                          ) : (
                            <span className="not-available">Not Available</span>
                          )}
                        </span>
                      </p>{" "}
                      <p
                        style={{
                          margin: "0 0 0 0px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <span>Specs:</span>
                        {""}
                        <span className="bundleitem-vfsku">
                          {" "}
                          {bundleitem && bundleitem.allspecs ? (
                            bundleitem.allspecs
                          ) : (
                            <span className="not-available">Not Available</span>
                          )}
                        </span>
                      </p>{" "}
                    </div>
                  </p>

                  <p className="specifications-txt">
                    {bundleitem &&
                    bundleitem.allspecs &&
                    bundleitem.allspecs.length > 10 ? (
                      <Tooltip title={bundleitem.allspecs}>
                        {`${bundleitem.allspecs.substring(0, 10)}...`}
                      </Tooltip>
                    ) : bundleitem.allspecs ? (
                      bundleitem.allspecs
                    ) : (
                      <span className="not-available">Not Available</span>
                    )}
                  </p>

                  {bundleitem && bundleitem.quantity ? (
                    <p className="quantity-txt">
                      {bundleitem && bundleitem.quantity
                        ? bundleitem.quantity
                        : null}{" "}
                      {bundleitem && bundleitem.quantity === 1
                        ? "piece"
                        : "pieces"}
                    </p>
                  ) : (
                    <span className="not-available">Not Available</span>
                  )}
                </div>
              );
            })
          ) : (
            <h3 className="no-bundle-data">No Bundle Data Found!</h3>
          )}
        </div>
      </>
    </Modal>
  );
};

export default ViewBundleModal;
