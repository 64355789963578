import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../../../store/actions/authActions";
import { Layout, Menu, Icon } from "antd";
import logo from "../../../assets/images/logos/logo_white.svg";
import { ReactComponent as Dash } from "../../../assets/images/icons/dashb.svg";
import { ReactComponent as Home } from "../../../assets/images/icons/home.svg";
import { ReactComponent as Prod } from "../../../assets/images/icons/prod.svg";
import { ReactComponent as Ord } from "../../../assets/images/icons/Ord.svg";
import { ReactComponent as Ship } from "../../../assets/images/icons/ship.svg";
import { ReactComponent as Nde } from "../../../assets/images/icons/nde.svg";
import { ReactComponent as Reporting } from "../../../assets/images/icons/reporting.svg";
import { ReactComponent as Inv } from "../../../assets/images/icons/inv.svg";
import { ReactComponent as Req } from "../../../assets/images/icons/req.svg";
import { ReactComponent as Set } from "../../../assets/images/icons/set.svg";
import { ReactComponent as Call } from "../../../assets/images/icons/phone-call.svg";
import { ReactComponent as Escalations } from "../../../assets/images/icons/escalations.svg";
import { ReactComponent as Track } from "../../../assets/images/icons/trackinglog.svg";
import { hasPermission } from "../../../Helpers/Permissions";
import analytics from "../../../analytics";
import "./Sidebar.scss";
const { Sider } = Layout;
const SubMenu = Menu.SubMenu;

class Sidebar extends Component {
  state = {
    ordersTab: false,
    ndrtab: false,
    requesttab: false,
    productsTab: false,
    arrow: false,
    escalationTab: false,
    downloadTabs: false,
    reqSourcingCount: "",
    reqPricingCount: "",
    openKeys: [
      "products",
      "orders",
      "shipments",
      "reporting",
      "ndr",
      "inventory",
      "escalation",
    ],
    selectedKeys: [],
    snap: true,
    counts: {
      products: 0,
      import_list: 0,
      linked_products: 0,
      my_products: 0,
      rfq_requests: 0,
      orders: 0,
      orders_open: 0,
      orders_processing: 0,
      close_order: 0,
      orders_all: 0,
      orders_ndr: 0,
    },
  };

  reportKey() {
    if (window.location.pathname.includes("reports")) {
      return `reports`;
    } else if (window.location.pathname.includes("transactionsReports")) {
      return `transactionsReports`;
    } else {
      return `NdrReports`;
    }
  }

  updateSelectedStates(path) {
    if (path.length === "28" || path.length === "29" || path.length === "30") {
      this.setState({ snap: true });
    } else if (path.length > "30" || path.length < "20") {
      this.setState({ snap: false });
    }

    const selectedKey = [path];
    const openKeys = [];
    if (
      path.match(/\/products\//) &&
      (path !== "/products/" || this.state.openKeys.indexOf("products") === -1)
    ) {
      openKeys.push("products");
      this.setState({
        ordersTab: false,
        ndrtab: false,
        requesttab: false,
        productsTab: false,
        escalationTab: false,
        downloadTabs: false,
      });
    } else if (
      path.match(/\/orders/) &&
      (path !== "/orders/" || this.state.openKeys.indexOf("orders") === -1)
    ) {
      openKeys.push("orders");
    } else if (
      (path.match(/\/tracking$/) || path.match(/\/ndr$/)) &&
      (path !== "/shipments/" ||
        this.state.openKeys.indexOf("shipments") === -1)
    ) {
      openKeys.push("shipments");
      this.setState({
        ordersTab: false,
        ndrtab: false,
        requesttab: false,
        productsTab: false,
        escalationTab: false,
        downloadTabs: false,
      });
    } else if (
      path.match(/\/reporting\//) &&
      this.state.openKeys.indexOf("reporting") === -1
    ) {
      openKeys.push("reporting");
    } else if (
      path.match(/\/ndr\//) &&
      (path !== "/ndr/" || this.state.openKeys.indexOf("ndr") === -1)
    ) {
      openKeys.push("ndr");
    } else if (
      path.match(/\/inventory\//) &&
      this.state.openKeys.indexOf("inventory") === -1
    ) {
      openKeys.push("inventory");
      this.setState({
        ordersTab: false,
        ndrtab: false,
        requesttab: false,
        productsTab: false,
        escalationTab: false,
        downloadTabs: false,
      });
    } else if (
      path.match(/\/requests\//) &&
      (path !== "/requests/" || this.state.openKeys.indexOf("requests") === -1)
    ) {
      openKeys.push("requests");
    } else if (
      path.match(/\/downloads\//) &&
      (path !== "/downloads/" ||
        this.state.openKeys.indexOf("downloadTabs") === -1)
    ) {
      openKeys.push("downloadTabs");
    } else if (
      path.match(/\/escalation\//) &&
      (path !== "/escalation/" ||
        this.state.openKeys.indexOf("escalation") === -1)
    ) {
      openKeys.push("escalation");
    } else if (path.includes("contact-center")) {
      this.setState({
        ordersTab: false,
        ndrtab: false,
        requesttab: false,
        productsTab: false,
        escalationTab: false,
        downloadTabs: false,
      });
    } else if (path.includes("settings")) {
      this.setState({
        ordersTab: false,
        ndrtab: false,
        requesttab: false,
        productsTab: false,
        escalationTab: false,
        downloadTabs: false,
      });
    }

    this.setState({
      selectedKeys: selectedKey,
      openKeys,
    });
  }

  componentDidMount() {
    this.updateSelectedStates(window.location.pathname);
    this.unlisten = this.props.history.listen((location) => {
      this.updateSelectedStates(location.pathname);
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  // Function to modify the Page count to Nearest thousand value, ex : 4.2K
  modifyCount(count) {
    if (count > 1000) {
      const modValue = (Number(count) / 1000).toFixed(1).toString();
      const result = `${modValue}K`;
      return result;
    } else {
      return count;
    }
  }

  render() {
    const props = this.props;
    const store = props.store;
    const storeid = props.store.id;
    const storegeo = props.store.store_geo ? props.store.store_geo : "in";

    return (
      <Sider
        className={`sidebar ${
          props.sidebarOpen ? "sidebar-open" : "sidebar-closed"
        } ${this.props.appStatus === true ? `upheader` : `downheader`} `}
        width={"14rem"}
        breakpoint="md"
        collapsed={!props.sidebarOpen}
        collapsedWidth={0}
      >
        <div className="logo">
          <a
            onClick={() =>
              window.location.assign(
                `${process.env.REACT_APP_NEW_APP}/switch-store`
              )
            }
            href="#"
          >
            <img src={logo} alt="vFulfill" />
          </a>
        </div>
        <Menu
          mode="inline"
          openKeys={this.state.openKeys}
          selectedKeys={this.state.selectedKeys}
          style={{
            borderRight: 0,
            display: "flex",
            flexDirection: "column",
            marginTop: "24px",
          }}
        >
          {/* {hasPermission(store, "access_snapshot_page") && (
            <Menu.Item
              onClick={() =>
                window.location.assign(
                  `${process.env.REACT_APP_NEW_APP}/switch-store/${storegeo}/${props.store.id}?redirect=${storegeo}/${props.store.id}`
                )
              }
              className={`navbarFlex home ${
                this.state.snap === true ? `ant-menu-item-selected` : ``
              }`}
              key={`/${storegeo}/${storeid}/`}
            >
              <Link to={`/${storegeo}/${storeid}/`}>
                <Icon component={Home} />
                Home
              </Link>
            </Menu.Item>
          )} */}

          <Menu.Item
            onClick={() =>
              window.location.assign(
                `${process.env.REACT_APP_NEW_APP}/switch-store`
              )
            }
            className={`navbarFlex snapshot-tour-step`}
            key={`/snapshot/`}
          >
            <a href="#">
              <Icon component={Dash} />
              Getting Started
            </a>
          </Menu.Item>

          <Menu.Item
            className={`navbarFlex snapshot-tour-step ${
              this.state.snap === true ? `ant-menu-item-selected` : ``
            }`}
            key={`/${storegeo}/${storeid}/`}
          >
            <Link to={`/${storegeo}/${props.store.id}/`}>
              <Icon component={Dash} />
              Snapshot
            </Link>
          </Menu.Item>

          {(hasPermission(store, "view_discover_products_page") ||
            hasPermission(store, "view_import_list_page") ||
            hasPermission(store, "view_products")) && (
            <SubMenu
              className="navbarFlex4 product-tour-step"
              key="products"
              onTitleClick={() =>
                this.setState(
                  {
                    openKeys: !this.state.productsTab ? ["products"] : [],
                    selectedKeys: [
                      `/${storegeo}/${props.store.id}/products/import-list`,
                    ],
                    productsTab: !this.state.productsTab,
                    ordersTab: false,
                    ndrtab: false,
                    requesttab: false,
                  },
                  this.props.history.push(
                    `/${storegeo}/${props.store.id}/products/import-list`
                  )
                )
              }
              title={
                <div className="navbarFlex3">
                  <div className="wr">
                    <Icon component={Prod} />
                    <span>Products</span>
                  </div>
                  <div className="prc">
                    {!!this.props.count.my_products
                      ? this.modifyCount(this.props.count.my_products)
                      : "0"}
                  </div>
                </div>
              }
            >
              {hasPermission(store, "view_discover_products_page") && (
                <Menu.Item
                  className="navbarFlex2"
                  style={{ marginTop: "8px" }}
                  onClick={() =>
                    window.location.assign(
                      `${process.env.REACT_APP_NEW_APP}/product-research`
                    )
                  }
                  key={`/${storegeo}/${props.store.id}/products/search-products`}
                >
                  <a>
                    Product Research
                    <span className="sidebar-count">
                      <svg
                        className="svI"
                        xmlns="http://www.w3.org/2000/svg"
                        width="13.019"
                        height="13.207"
                        viewBox="0 0 13.019 13.207"
                      >
                        <g
                          id="Group_3577"
                          data-name="Group 3577"
                          transform="translate(-961.462 -333.293)"
                        >
                          <path
                            id="Path_84"
                            data-name="Path 84"
                            d="M-4591.471-691.587h-4.733v12h12v-4.576"
                            transform="translate(5558.167 1025.587)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                          />
                          <g
                            id="Group_3178"
                            data-name="Group 3178"
                            transform="translate(967.669 334)"
                          >
                            <path
                              id="Path_85"
                              data-name="Path 85"
                              d="M-4590.015-685.817l6.1-6.171"
                              transform="translate(4590.015 691.988)"
                              fill="none"
                              stroke="#fff"
                              stroke-linecap="round"
                              stroke-width="1"
                            />
                            <path
                              id="Path_86"
                              data-name="Path 86"
                              d="M-4587.394-691.587h4.055v3.868"
                              transform="translate(4589.632 691.587)"
                              fill="none"
                              stroke="#fff"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                  </a>
                </Menu.Item>
              )}

              {hasPermission(store, "view_import_list_page") && (
                <Menu.Item
                  // className={`navbarFlex2
                  //  ${
                  //    this.state.openKeys.includes("products")
                  //      ? `ant-menu-item-selected`
                  //      : ``
                  //  }
                  // `}

                  className={`navbarFlex2`}
                  key={`/${storegeo}/${props.store.id}/products/import-list`}
                >
                  <Link
                    to={`/${storegeo}/${props.store.id}/products/import-list`}
                  >
                    My Products{" "}
                    <span className="sidebar-count">
                      {this.modifyCount(this.props.count.my_products)}
                    </span>
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item
                className={`navbarFlex2 ${
                  this.state.openKeys.includes("rfq")
                    ? `ant-menu-item-selected`
                    : ``
                }`}
                key={`/${storegeo}/${props.store.id}/products/rfq`}
                onClick={() =>
                  window.location.assign(
                    `${process.env.REACT_APP_NEW_APP}/request/quotations`
                  )
                }
              >
                <Link to={`/${storegeo}/${props.store.id}/products/rfq`}>
                  Quotations
                  <span className="sidebar-count">
                    {this.modifyCount(this.props.count.rfq_requests)}
                  </span>
                </Link>
              </Menu.Item>
              {/* <Menu.Item
                onClick={() =>
                  analytics.track("Ecom Clicked", () =>
                    window.open(
                      process.env.REACT_APP_API_URL + `/products/ecom_insider`
                    )
                  )
                }
                className="navbarFlex2"
                style={{ marginTop: "8px" }}
                key={`/${storegeo}/${props.store.id}/products/search-products`}
              >
                <a>
                  Ecom Insider
                  <span className="sidebar-count">
                    <svg
                      className="svI"
                      xmlns="http://www.w3.org/2000/svg"
                      width="13.019"
                      height="13.207"
                      viewBox="0 0 13.019 13.207"
                    >
                      <g
                        id="Group_3577"
                        data-name="Group 3577"
                        transform="translate(-961.462 -333.293)"
                      >
                        <path
                          id="Path_84"
                          data-name="Path 84"
                          d="M-4591.471-691.587h-4.733v12h12v-4.576"
                          transform="translate(5558.167 1025.587)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                        />
                        <g
                          id="Group_3178"
                          data-name="Group 3178"
                          transform="translate(967.669 334)"
                        >
                          <path
                            id="Path_85"
                            data-name="Path 85"
                            d="M-4590.015-685.817l6.1-6.171"
                            transform="translate(4590.015 691.988)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-width="1"
                          />
                          <path
                            id="Path_86"
                            data-name="Path 86"
                            d="M-4587.394-691.587h4.055v3.868"
                            transform="translate(4589.632 691.587)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                          />
                        </g>
                      </g>
                    </svg>
                  </span>
                </a>
              </Menu.Item> */}
            </SubMenu>
          )}

          {(hasPermission(store, "view_open_orders") ||
            hasPermission(store, "view_all_orders")) && (
            <SubMenu
              key="orders"
              className="orders-tour-step"
              // onTitleClick={() => this.updateSelectedStates("/orders/")}
              onTitleClick={() =>
                window.location.assign(
                  `${process.env.REACT_APP_NEW_APP}/orders/open`
                )
              }
              // this.setState(
              //   {
              //     openKeys: !this.state.ordersTab ? ["orders"] : [],
              //     selectedKeys: [
              //       `/${storegeo}/${props.store.id}/orders/open`,
              //     ],
              //     ordersTab: !this.state.ordersTab,
              //     ndrtab: false,
              //     productsTab: false,
              //     requesttab: false,
              //     escalationTab: false,
              //     downloadTabs: false,
              //   },
              //   this.props.history.push(
              //     `/${storegeo}/${props.store.id}/orders/open`
              //   )
              // )

              title={
                <div className="navbarFlex3">
                  <div className="wrap">
                    <Icon component={Ord} />
                    <span>Orders</span>
                  </div>
                  <div className="prc">
                    {!!this.props.count.orders
                      ? this.modifyCount(this.props.count.orders)
                      : "0"}
                  </div>
                </div>
              }
            >
              {hasPermission(store, "view_open_orders") && (
                <Menu.Item
                  className="navbarFlex2"
                  style={{ marginTop: "8px" }}
                  key={`/${storegeo}/${props.store.id}/orders/open`}
                  onClick={() =>
                    window.location.assign(
                      `${process.env.REACT_APP_NEW_APP}/orders?currentPage=open`
                    )
                  }
                >
                  {/* <Link to={`/${storegeo}/${props.store.id}/orders/open`}> */}
                  To Be Placed{" "}
                  <span className="sidebar-count">
                    {this.modifyCount(this.props.count.orders_open)}
                  </span>
                  {/* </Link> */}
                </Menu.Item>
              )}

              {hasPermission(store, "view_all_orders") && (
                <Menu.Item
                  className="navbarFlex2"
                  key={`/${storegeo}/${props.store.id}/orders/processing`}
                  onClick={() =>
                    window.location.assign(
                      `${process.env.REACT_APP_NEW_APP}/orders?currentPage=in_processing`
                    )
                  }
                >
                  {/* <Link to={`/${storegeo}/${props.store.id}/orders/processing`}> */}
                  In-Processing{" "}
                  <span className="sidebar-count">
                    {this.modifyCount(this.props.count.orders_processing)}
                  </span>
                  {/* </Link> */}
                </Menu.Item>
              )}

              {hasPermission(store, "view_all_orders") && (
                <Menu.Item
                  className="navbarFlex2"
                  key={`/${storegeo}/${props.store.id}/orders/closedOrders`}
                  onClick={() =>
                    window.location.assign(
                      `${process.env.REACT_APP_NEW_APP}/orders?currentPage=closed`
                    )
                  }
                >
                  {/* <Link
                    to={`/${storegeo}/${props.store.id}/orders/closedOrders`}
                  > */}
                  Closed Orders{" "}
                  <span className="sidebar-count totalClosed">
                    {this.modifyCount(this.props.count.close_order)}
                  </span>
                  {/* </Link> */}
                </Menu.Item>
              )}

              {hasPermission(store, "view_all_orders") && (
                <Menu.Item
                  className="navbarFlex2"
                  style={{ marginBottom: "0px" }}
                  key={`/${storegeo}/${props.store.id}/orders`}
                  onClick={() =>
                    window.location.assign(
                      `${process.env.REACT_APP_NEW_APP}/orders/open`
                    )
                  }
                >
                  {/* <Link to={`/${storegeo}/${props.store.id}/orders`}> */}
                  All Orders{" "}
                  <span className="sidebar-count">
                    {this.modifyCount(this.props.count.orders_all)}
                  </span>
                  {/* </Link> */}
                </Menu.Item>
              )}
            </SubMenu>
          )}

          {hasPermission(store, "view_shipments") && (
            <Menu.Item
              className="navbarFlex shipment-tour-step"
              key={`/${storegeo}/${storeid}/tracking`}
              onClick={() =>
                window.location.assign(
                  `${process.env.REACT_APP_NEW_APP}/shipments`
                )
              }
            >
              <Link to={`/${storegeo}/${storeid}/tracking`}>
                <Icon component={Ship} />
                Shipments
              </Link>
            </Menu.Item>
          )}

          {storegeo === "in" && hasPermission(store, "view_ndr") && (
            <Menu.Item
              className="navbarFlex2 ndr-tour-step"
              style={{ marginTop: "0px" }}
              key={`/in/${props.store.id}/ndr`}
            >
              <Icon component={Nde} />
              <Link to={`/in/${props.store.id}/ndr`}>
                NDR{" "}
                <span className="prc">
                  {!!this.props.count.open_ndr_count
                    ? this.modifyCount(this.props.count.open_ndr_count)
                    : "0"}
                </span>{" "}
              </Link>
            </Menu.Item>
          )}

          {hasPermission(store, "view_inventory") && (
            <Menu.Item
              className="navbarFlex2 inventory-tour-step"
              onTitleClick={() => this.updateSelectedStates("/inventory/")}
              key={`/${storegeo}/${props.store.id}/live-inventory`}
            >
              <Icon component={Inv} />
              <Link to={`/${storegeo}/${props.store.id}/live-inventory`}>
                Inventory
                <span className="prc">
                  {!!this.props.count.live_inventory
                    ? this.modifyCount(this.props.count.live_inventory)
                    : "0"}
                </span>{" "}
              </Link>
            </Menu.Item>
          )}

          <SubMenu
            key="requests"
            className="request-tour-step"
            onTitleClick={() =>
              this.setState(
                {
                  openKeys: !this.state.requesttab ? ["requests"] : [],
                  selectedKeys: [
                    `/${storegeo}/${props.store.id}/requests/sourcing-requests`,
                  ],
                  ndrtab: false,
                  productsTab: false,
                  ordersTab: false,
                  escalationTab: false,
                  downloadTabs: false,
                  requesttab: !this.state.requesttab,
                },
                this.props.history.push(
                  `/${storegeo}/${props.store.id}/requests/sourcing-requests`
                )
              )
            }
            title={
              <div className="navbarFlex3">
                <div className="div">
                  <Icon component={Req} />
                  <span>Requests</span>
                </div>
              </div>
            }
          >
            <Menu.Item
              className="navbarFlex2"
              style={{ marginTop: "8px" }}
              key={`/${storegeo}/${props.store.id}/requests/sourcing-requests`}
            >
              <Link
                to={`/${storegeo}/${props.store.id}/requests/sourcing-requests`}
              >
                Sourcing
              </Link>
            </Menu.Item>

            <Menu.Item
              className="navbarFlex2"
              key={`/${storegeo}/${props.store.id}/requests/pricing-requests`}
            >
              <Link
                to={`/${storegeo}/${props.store.id}/requests/pricing-requests`}
              >
                Pricing Updates
              </Link>
            </Menu.Item>

            <Menu.Item
              className="navbarFlex2"
              key={`/${storegeo}/${props.store.id}/requests/shipment-requests`}
            >
              <Link
                to={`/${storegeo}/${props.store.id}/requests/shipment-requests`}
              >
                Post Delivery
              </Link>
            </Menu.Item>
          </SubMenu>

          {/* escalations */}
          <SubMenu
            key="escalation"
            className="escalation-tour-step"
            onTitleClick={() =>
              this.setState(
                {
                  openKeys: !this.state.escalationTab ? ["escalation"] : [],
                  selectedKeys: [
                    `/${storegeo}/${props.store.id}/escalation/escalations`,
                  ],
                  ndrtab: false,
                  productsTab: false,
                  ordersTab: false,
                  requesttab: false,
                  downloadTabs: false,
                  escalationTab: !this.state.escalationTab,
                },
                this.props.history.push(
                  `/${storegeo}/${props.store.id}/escalation/escalations`
                )
              )
            }
            title={
              <div className="navbarFlex3">
                <div className="div">
                  <Icon color={"red"} component={Escalations} />
                  <span>Escalations</span>
                </div>
              </div>
            }
          >
            <Menu.Item
              className="navbarFlex2"
              style={{ marginBottom: "0px", marginTop: "8px" }}
              key={`/${storegeo}/${storeid}/escalation/escalations`}
            >
              <Link to={`/${storegeo}/${storeid}/escalation/escalations`}>
                Delivered Escalations
              </Link>
            </Menu.Item>

            <Menu.Item
              className="navbarFlex2"
              style={{ marginTop: "8px", marginBottom: "0px" }}
              key={`/${storegeo}/${storeid}/escalation/ndr-escalations`}
            >
              <Link to={`/${storegeo}/${storeid}/escalation/ndr-escalations`}>
                NDR Escalations
              </Link>
            </Menu.Item>
          </SubMenu>

          {storegeo === "in" && (
            <Menu.Item
              className="navbarFlex2 reporting-tour-step"
              style={{ marginTop: "0px" }}
              key={`/in/${props.store.id}/${this.reportKey()}`}
            >
              <Icon component={Reporting} />
              <Link to={`/in/${props.store.id}/reports`}>
                Reporting
                {/* <div className="prc2"> Beta </div> */}
              </Link>
            </Menu.Item>
          )}

          {store.store_platform === "woocommerce" ? null : storegeo === "in" &&
            store.store_role === "owner" ? (
            <Menu.Item
              className="contact-center-tour-step"
              key={`/${storegeo}/${props.store.id}/contact-center`}
            >
              <Link to={`/${storegeo}/${props.store.id}/contact-center`}>
                <Icon style={{ opacity: 0.6 }} component={Call} />
                Services
                {/* <div className="prc2"> Beta </div> */}
              </Link>
            </Menu.Item>
          ) : null}
          {hasPermission(store, "view_settings") && (
            <Menu.Item
              className="navbarFlex tracking-tour-step"
              key={`/${storegeo}/${storeid}/shippingSettings`}
            >
              <Link to={`/${storegeo}/${storeid}/shippingSettings`}>
                <Icon component={Track} />
                Tracking Page
              </Link>
            </Menu.Item>
          )}
          {hasPermission(store, "view_settings") && (
            <Menu.Item
              className="navbarFlex settings-tour-step"
              key={`/${storegeo}/${storeid}/settings`}
            >
              <Link to={`/${storegeo}/${storeid}/settings`}>
                <Icon component={Set} />
                Store Settings
              </Link>
            </Menu.Item>
          )}
        </Menu>
      </Sider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
    count: state.auth.count,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
