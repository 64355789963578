import React, { Component } from "react";
import {
  Row,
  Card,
  Skeleton,
  Alert,
  Select,
  Button,
  message,
  Icon,
  notification,
  Tooltip,
} from "antd";
import PLACEHOLDER_PRODUCT_IMAGE from "../../../../../assets/images/icons/image_placeholder.png";
import axios from "axios";
import { connect } from "react-redux";
import "./index.scss";
// import VariantQuantitylSelect from "./VariantQuantitylSelect";
import BundleVariantnav from "./BundleVariantNav";
import VaraintBundleMatchSelecter from "./VaraintBundleMatchSelecter";
import { ReactComponent as NotificationIcon } from "../../../../../assets/images/icons/order-notification.svg";
import { ReactComponent as Tooltipican } from "../../../../../assets/images/icons/tooltipican.svg";
import { SHOPIFY_NAME } from "../../../../../constants";
// const { Option } = Select;

class BundledVariantMatching extends Component {
  state = {
    loading: true,
    shopifyProduct: null,
    aliProduct: null,
    mapping: {},
  };

  componentDidMount() {
    axios({
      url:
        process.env.REACT_APP_API_URL + "/products/get_variant_matching_data",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        aliId: this.props.match.params.aliIds.split(","),
        productId: this.props.match.params.productId,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          let mapping = {};
          res.data.shopifyProduct.variants.forEach((v) => {
            mapping[v.variant_shopifyid] = [
              { aliId: "", skuPropIds: "", qty: 1 },
            ];
          });
          this.setState({
            shopifyProduct: res.data.shopifyProduct,
            aliProduct: res.data.aliProduct,
            mapping: mapping,
            loading: false,
          });
        } else {
          this.setState({
            error: true,
            errorMsg: res.data.msg,
            loading: false,
          });
        }
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMsg: e.message,
          loading: false,
        });
      });
  }

  onAddVariant(svid) {
    this.setState((state) => ({
      mapping: {
        ...state.mapping,
        [svid]: [...state.mapping[svid], { aliId: "", skuPropIds: "", qty: 1 }],
      },
    }));
  }

  removeVariant(shopifyId, idx) {
    const mapping = [
      ...this.state.mapping[shopifyId].slice(0, idx),
      ...this.state.mapping[shopifyId].slice(idx + 1),
    ];
    if (mapping.length === 0) {
      mapping.push({ aliId: "", skuPropIds: "", qty: 1 });
    }
    this.setState((state) => ({
      mapping: {
        ...state.mapping,
        [shopifyId]: mapping,
      },
    }));
  }

  mapProduct(shopifyId, mappedIndex, aliPId, aliVId) {
    const mapping = [...this.state.mapping[shopifyId]];
    mapping[mappedIndex]["aliId"] = aliPId;
    mapping[mappedIndex]["skuPropIds"] = aliVId;
    this.setState((state) => ({
      mapping: {
        ...state.mapping,
        [shopifyId]: mapping,
      },
    }));
  }

  updateMatchQty(shopifyId, mappedIndex, quantity) {
    const mapping = [...this.state.mapping[shopifyId]];
    mapping[mappedIndex]["qty"] = quantity;
    this.setState((state) => ({
      mapping: {
        ...state.mapping,
        [shopifyId]: mapping,
      },
    }));
  }

  submitMatchData() {
    const matchData = this.state.mapping;
    const hasError = Object.values(matchData).some((mappings) =>
      mappings.some((mapping) => {
        if (
          mapping.aliId.trim().length === 0 ||
          mapping.skuPropIds.trim().length === 0 ||
          mapping.qty <= 0
        ) {
          return true;
        }
        return false;
      })
    );
    if (hasError) {
      message.error("Please match all Store variants");
    } else {
      axios({
        url:
          process.env.REACT_APP_API_URL +
          "/products/match_product_variants_bundle",
        method: "post",
        data: {
          storeid: this.props.match.params.storeid,
          matchdata: this.state.mapping,
          product_mongoid: this.props.match.params.productId,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            // message.success("Product linked successfully");
            if (this.props.location.hash.includes("#order")) {
              notification.open({
                bottom: "5%",

                className: "custom-notification",
                placement: "bottomLeft",
                duration: 6,
                style: { background: "#E9FFE8", border: "2px solid #2A9C27" },
                description: (
                  <div className="notification-wraper">
                    <Icon
                      className="notification-icon-svg"
                      component={NotificationIcon}
                    />
                    <div className="content">
                      <h5>
                        <h2>Product Bundle Created</h2> <span>!</span>{" "}
                      </h5>
                      <p>
                        The product bundle for order{" "}
                        <a
                          href={`./open?search=${
                            this.props.location.hash.split("=")[1]
                          }`}
                        >
                          {this.props.location.hash.split("=")[1]}
                        </a>{" "}
                        has been created.
                      </p>

                      <p>
                        {" "}
                        <a
                          href={`./open?search=${
                            this.props.location.hash.split("=")[1]
                          }`}
                        >
                          View{" "}
                        </a>{" "}
                        product bundle.
                      </p>
                    </div>
                  </div>
                ),
                icon: null,
              });

              this.props.history.push(
                `/${this.props.store.store_geo}/${
                  this.props.match.params.storeid
                }/orders/open`
              );
            } else {
              message.success("Product Bundle Created successfully");
              this.props.history.push(
                `/${this.props.store.store_geo}/${
                  this.props.match.params.storeid
                }/products/store-products`
              );
            }
          } else {
            message.error("There was an error");
          }
        })
        .catch((e) => {
          message.error(e.message);
        });
    }
  }

  render() {
    const PageWrapper = (children) => {
      return (
        <div>
          <BundleVariantnav orderurl={this.props.location.hash} />
          {children}
        </div>
      );
    };

    if (this.state.loading) {
      return PageWrapper(
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={this.state.errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    return PageWrapper(
      <div className="variant-mapping">
        <p className="ali-product-name">
          {" "}
          {`Creating ${SHOPIFY_NAME} Product Bundle:`}
          <span className="link-product-name">
            {this.state.shopifyProduct.name.length > 30 ? (
              <Tooltip title={this.state.shopifyProduct.name}>
                {`${this.state.shopifyProduct.name.substring(0, 30)}...`}
              </Tooltip>
            ) : this.state.shopifyProduct.name ? (
              this.state.shopifyProduct.name
            ) : (
              "Not Available"
            )}
          </span>{" "}
          with Import List Product(s):{" "}
          <span className="link-product-name">
            {this.state.aliProduct[0].name.length > 30 ? (
              <span>
                <span className="tooltipText">
                  <Tooltip title={this.state.aliProduct[0].name}>
                    {`${this.state.aliProduct[0].name.substring(0, 30)}...`}
                  </Tooltip>
                </span>
                <span className="aliproduct-length">
                  {" "}
                  + {this.state.aliProduct.length - 1} More{" "}
                  <Tooltip
                    title={this.state.aliProduct.map((name, i) => {
                      if (i === 0) {
                        return;
                      } else if (this.state.aliProduct.length === 1) {
                        return name.name;
                      } else if (this.state.aliProduct.length - 1 === i) {
                        return name.name;
                      } else {
                        return name.name + ", ";
                      }
                    })}
                  >
                    {" "}
                    <Icon style={{ height: "16px" }} component={Tooltipican} />
                  </Tooltip>
                </span>
              </span>
            ) : this.state.aliProduct[0].name ? (
              <span>
                <span> {this.state.aliProduct[0].name}</span>
                <span className="aliproduct-length">
                  {" "}
                  + {this.state.aliProduct.length - 1} More{" "}
                  <Tooltip
                    title={this.state.aliProduct.map((name, i) => {
                      if (i === 0) {
                        return;
                      } else if (this.state.aliProduct.length === 1) {
                        return name.name;
                      } else if (this.state.aliProduct.length - 1 === i) {
                        return name.name;
                      } else {
                        return name.name + ", ";
                      }
                    })}
                  >
                    {" "}
                    <Icon style={{ height: "16px" }} component={Tooltipican} />
                  </Tooltip>
                </span>
              </span>
            ) : (
              "Not Available"
            )}
          </span>
        </p>

        <Card>
          <div className="variant-matching">
            <div className="header-txt">
              <p>{`Product Variants In ${SHOPIFY_NAME}`}</p>
              <p> Product Variants In Import List</p>
            </div>
          </div>

          {this.state.shopifyProduct.variants.map((sv) => (
            <div key={sv.vskuid} className="varaint-matching-data">
              <div
                className="left-varaint-bundle-image-data"
                style={{
                  borderRadius: "2px",
                  height: "64px",
                  background: "#f5f5f5",
                }}
              >
                <img
                  src={sv.img ? sv.img : PLACEHOLDER_PRODUCT_IMAGE}
                  style={{
                    maxWidth: "48px",
                    padding: "2px",
                    border: "1px solid #979797",
                  }}
                  alt={sv.specs}
                />
                <span
                  style={{
                    marginLeft: "16px",
                    color: "#212121",
                    letterSpacing: "0.032em",
                    fontSize: "12px",
                  }}
                >
                  {sv.allspecs}
                </span>
              </div>

              <VaraintBundleMatchSelecter
                onAddVariant={(a) => this.onAddVariant(a)}
                sv={sv}
                mapping={this.state.mapping}
                aliProduct={this.state.aliProduct}
                shopifyProduct={this.state.shopifyProduct}
                mapProduct={(a, b, c, d) => this.mapProduct(a, b, c, d)}
                updateMatchQty={(a, b, c) => this.updateMatchQty(a, b, c)}
                removeVariant={(a, b) => this.removeVariant(a, b)}
              />
            </div>
          ))}
        </Card>
        <Row className="variant-save">
          <Button
            className="save-continue"
            type="primary"
            onClick={() => this.submitMatchData()}
          >
            Save & Continue
          </Button>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state.auth.auth.store,
});
export default connect(mapStateToProps)(BundledVariantMatching);
