import React, { Component } from "react";

class ImageViewer extends Component {
  state = {
    current: 0,
  };

  updateCurrentImage(idx) {
    this.setState({ current: idx }, () => this.props.onChange(idx));
  }

  componentDidUpdate(pp) {
    if (
      pp.selected !== this.props.selected &&
      this.props.selected >= 0 &&
      this.props.selected < this.props.images.length
    ) {
      this.setState({ current: this.props.selected });
    }
  }

  render() {
    const allImages = this.props.images;
    const current = this.props.images[this.state.current];

    return (
      <>
        <img
          src={current}
          style={{
            height: 282,
            width: 282,
            objectFit: "cover",
            borderRadius: 2,
            border: "2px solid #F5F5F5",
          }}
          alt={`main product`}
        />
        <ul
          className="inline-list mt8 mb0"
          style={{ whiteSpace: "nowrap", overflow: "auto" }}
        >
          {allImages.map((img, index) => {
            if (index === current) {
              return null;
            }
            return (
              <li key={index} className="mr8">
                <img
                  src={img}
                  alt={`product ${index}`}
                  style={{
                    width: 48,
                    height: 48,
                    objectFit: "cover",
                    cursor: "pointer",
                    borderRadius: 2,
                    border: `${index === this.state.current ? 2 : 1}px solid ${
                      index === this.state.current ? "#1370FC" : "#B2B2B2"
                    }`,
                  }}
                  onClick={() => this.updateCurrentImage(index)}
                />
              </li>
            );
          })}
        </ul>
      </>
    );
  }
}

export default ImageViewer;
