import React, { Component } from "react";
import { Modal, Upload, Icon, Button, Checkbox } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { SHOPIFY_NAME } from "../../../../constants";
import "./index.scss";
import NDRReAttempt from "../NDRReattempt";

const { Dragger } = Upload;

export default class NDREscalationModal extends Component {
  state = {
    fileList: [],
    clear: this.props.clear,
  };

  componentWillUnmount() {
    // console.log("unmount");
  }

  render() {
    // const { fileList } = this.state;
    // const props = {
    //   multiple: true,
    //   onRemove: (file) => {
    //     this.setState((state) => {
    //       const index = state.fileList.indexOf(file);
    //       const newFileList = state.fileList.slice();
    //       newFileList.splice(index, 1);
    //       return {
    //         fileList: newFileList,
    //       };
    //     });
    //   },
    //   beforeUpload: (file) => {
    //     this.setState((state) => ({
    //       // fileList: [.file]
    //       fileList: [...state.fileList, file],
    //     }));
    //     return false;
    //   },
    //   fileList,
    // };

    return (
      <>
        <Modal
          width={700}
          className="Escalate_NDR"
          title="Complete NDR Escalation Form"
          visible={this.props.visible}
          onCancel={this.props.toggleNdrEscalationModal}
          destroyOnClose={true}
          footer={[
            <Button
              key="back"
              className="cancel"
              onClick={this.props.toggleNdrEscalationModal}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              className={
                !!this.props.fileList.length && !!this.props.remarks
                  ? "success_btn"
                  : "btn_disabled"
              }
              loading={this.props.uploading}
              onClick={() =>
                this.props.confirmNdrEscalationModal(this.props.fileList)
              }
            >
              Continue
            </Button>,
          ]}
        >
          <div className="ndr-details">
            <div className="waybill details">
              <p>WayBill Number:</p>
              <div className="id">
                <a
                  href={`https://track.vcfl.express/?waybill=${
                    !!this.props.awb ? this.props.awb : null
                  }`}
                  target="_blank"
                >
                  {!!this.props.awb ? this.props.awb : null}
                </a>
              </div>
            </div>
            <div className="Shopify details">
              <p>{`${SHOPIFY_NAME}Id:`} </p>
              <div className="id">
                <a
                  href={
                    !!this.props.shopifyLink ? this.props.shopifyLink : null
                  }
                  target="_blank"
                >
                  {" "}
                  {!!this.props.shopifyId ? this.props.shopifyId : null}
                </a>
              </div>
            </div>
            <div className="Order details">
              <p>Order Number:</p>
              <div className="id">
                <a
                  href={`./orders?search=${
                    !!this.props.shipment_vfid ? this.props.shipment_vfid : null
                  }`}
                  target="_blank"
                >
                  {" "}
                  {!!this.props.shipment_vfid ? this.props.shipment_vfid : null}
                </a>
              </div>
            </div>
          </div>

          <p className="remarks">
            Escalation Description: <span>*</span>{" "}
          </p>
          <TextArea
            value={this.props.remarks}
            placeholder="Please mention the reason for raising an NDR escalation."
            // onChange={(e) => this.setState({ remarks: e.target.value })}
            onChange={(e) => this.props.removeRemarkhnadler(e.target.value)}
          />

          <p className="upload-text">
            Upload Attachments:(jpg, png, jpeg, wav, mp4, gif, mp3, asc,
            wmv, avi, mkv)
          </p>
          <Dragger
            accept=".jpg,.png,.jpeg,.webp,.wav,.mp4,.gif,.mp3,.asc"
            {...this.props.imageprops}
          >
            <p className="ant-upload-drag-icon">
              <Icon type="upload" />
            </p>
            <p className="ant-upload-text">Upload Images/ Videos/ Audio</p>
          </Dragger>

          <div className="re-Attemptcheckbox">
            <Checkbox
              defaultChecked={false}
              onChange={() => this.props.reattempttoggle()}
            >
              Re-Attempt
            </Checkbox>
          </div>

          <NDRReAttempt
            show={this.props.reattemptagain}
            {...this.props}
            toggleNdrEscalationModal={() =>
              this.props.toggleNdrEscalationModal()
            }
            newDataHandler={(o) => this.props.newDataHandler(o)}
            datehandler={(o) => this.props.datehandler(o)}
            error={this.props.error}
          />
        </Modal>
      </>
    );
  }
}
