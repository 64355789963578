import React, { Component } from "react";
import { Tooltip } from "antd";
// import { ReactComponent as ShipmentStatusIcon } from "../../../../../assets/images/icons/shopping-cart.svg";
// import { ReactComponent as circle } from "../../../../../assets/images/icons/circle-check.svg";
import "./index.scss";
import { getCurrencySymbol } from "../../../../../Helpers/Pricing";
import OrderProductTitle from "../../../Common/GlobalCommon/ProductTitle/OrderTitle";

class ShipmentInfo extends Component {
  state = {
    Ndrstatus: "",
  };

  componentDidMount() {
    if (
      this.props.shipment.ndr_status === "pending" &&
      this.props.shipment.status === "ndr_actionable"
    ) {
      return this.setState({ Ndrstatus: "Open" });
    } else if (
      this.props.shipment.ndr_status === "pending" &&
      this.props.shipment.status !== "ndr_actionable"
    ) {
      return this.setState({ Ndrstatus: this.props.shipment.status });
    } else if (
      this.props.shipment.ndr_status === "responded" &&
      this.props.shipment.status !== "ndr_actionable"
    ) {
      return this.setState({ Ndrstatus: this.props.shipment.status });
    } else if (
      this.props.shipment.ndr_status === "responded" &&
      this.props.shipment.status === "ndr_actionable"
    ) {
      return this.setState({ Ndrstatus: "Responded" });
    } else if (
      this.props.shipment.ndr_status === "expired" &&
      this.props.shipment.status !== "ndr_actionable"
    ) {
      return this.setState({ Ndrstatus: this.props.shipment.status });
    } else if (
      this.props.shipment.ndr_status === "expired" &&
      this.props.shipment.status === "ndr_actionable"
    ) {
      return this.setState({ Ndrstatus: "Expired" });
    }
  }

  render() {
    const lineItems = this.props.lineItems;

    return (
      <div className="shipment-info-tab">
        <div className="line-items-tab_header_upper_data">
          <p className="flex33">Variant Details</p>
          <p className="flex55">
            <div className="Quantity">Quantity</div>
            <div className="star">x</div>
            <div className="Shopify_Selling_Price">Shopify Selling Price</div>
          </p>
          <p className="flex66">Status</p>
        </div>
        {lineItems.map((li) => {
          return (
            <div className={`line-item `} key={li.lineitem_id}>
              <div className="line-item-product-detail">
                <div>
                  <span
                    className="line-item-img"
                    style={{ background: `url(${li.img})` }}
                  />
                </div>
                <div>
                  <p className="blue-text line-item-name">
                    {/* <Tooltip title={li.name} placement="topLeft">
                      {li.name}
                    </Tooltip> */}
                    <OrderProductTitle
                      orderProductname={li.name}
                      productShopifyid={li.product_shopifyid}
                      storeShopifyadmin={li.product_link}
                    />
                  </p>
                  <p className="line-item-sku" style={{ color: "#000" }}>
                    <span style={{ color: "#898989" }}>SKU: </span>
                    <Tooltip title={li.sku} placement="topLeft">
                      {li.sku ? `#${li.sku}` : "Not Avaiable"}
                    </Tooltip>
                  </p>

                  <p className="line-item-sku" style={{ color: "#000" }}>
                    <span style={{ color: "#898989" }}>Specs: </span>{" "}
                    {li.allspecs === "" ? "Default" : li.allspecs}
                  </p>
                </div>
              </div>

              <div className="quntyandpices">
                <span className="Quantity">
                  {" "}
                  {li.quantity} {li.quantity === 1 ? "piece" : "pieces"}
                </span>
                <span className="star">x</span>
                <span className="Shopify_Selling_Price">
                  <Tooltip title="Shopify Selling Price">
                    {getCurrencySymbol("INR")}{" "}
                    {li.item_total_sold_price.amount +
                      this.props.shipment.shipping_perquantity}
                  </Tooltip>
                </span>
              </div>

              <div className="end_data">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    letterSpacing: "0.032em",
                    fontWeight: 500,
                    lineHeight: "16px",
                  }}
                >
                  <div style={{ color: "#9B9B9B" }}>
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        letterSpacing: "0.032em",
                        fontWeight: 500,
                        lineHeight: "16px",
                      }}
                    >
                      {/* {this.props.filters.status === "pending" &&
                      this.props.shipment.status === "ndr_actionable" ? (
                        "Open NDR"
                      ) : this.props.filters.status === "responded" &&
                        this.props.shipment.status === "ndr_actionable" ? (
                        "Responded NDR"
                      ) : this.props.filters.status === "expired" &&
                        this.props.shipment.status === "ndr_actionable" ? (
                        "Expired NDR"
                      ) : (
                        <span
                          className={
                            this.props.shipment.status === "rto"
                              ? "rtouppercase"
                              : "normalcase"
                          }
                        >
                          {this.props.shipment.status}
                        </span>
                      )} */}
                      {/* 
                      {this.props.filters.status === "pending" &&
                      this.props.shipment.status === "ndr_actionable"
                        ? "Open nder"
                        : this.props.filters.status === "responded" &&
                          this.props.shipment.status !== "ndr_actionable"
                        ? this.props.shipment.status
                        : this.props.filters.status === "expired" &&
                          this.props.shipment.status === "ndr_actionable"
                        ? this.props.shipment.status
                        : "Expired NDR"} */}

                      {/* {this.props.filters.status === "responded" &&
                      this.props.shipment.status !== "ndr_actionable"
                        ? this.props.shipment.status
                        : "Responded NDR"}
                      {this.props.filters.status === "expired" &&
                      this.props.shipment.status === "ndr_actionable"
                        ? this.props.shipment.status
                        : "Expired NDR"} */}

                      {/* {this.state.Ndrstatus} */}
                      <span
                        className={
                          this.props.shipment.status === "rto"
                            ? "rtouppercase"
                            : (this.props.shipment.ndr_status === "expired" &&
                                this.props.shipment.status ===
                                  "ndr_actionable") ||
                              (this.props.shipment.ndr_status === "responded" &&
                                this.props.shipment.status === "ndr_actionable")
                            ? "normalcase"
                            : "normalcase"
                        }
                      >
                        {this.state.Ndrstatus}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default ShipmentInfo;
