import React from "react";
import moment from "moment";
import { Tooltip } from "antd";
import "../index.scss";

class Notes extends React.Component {
  render() {
    return (
      <div
        style={{
          background: "white",
          padding: "12px 16px",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
        }}
      >
        {this.props.ndr && this.props.ndr.length > 0 ? (
          <div className="thead">
            <p className="flex-description">Description</p>
            <p className="flex-added-on"> Added On</p>
            <p className="flex-addedby" style={{ marginRight: "13px" }}>
              Added By
            </p>
          </div>
        ) : null}
        {this.props.ndr && this.props.ndr.length > 0 ? (
          this.props.ndr &&
          this.props.ndr.map((note, idx) => (
            <div
              className="flex"
              key={idx}
              style={{
                padding: "8px 16px",
                background: "#F5F5F5",
                marginBottom: 8,
                marginTop: "-5px",
              }}
            >
              <div className="flex-description">
                {note.remark && note.remark.length <= 45 ? (
                  note.remark
                ) : (
                  <Tooltip title={note.remark}>
                    {" "}
                    {note.remark ? `${note.remark.substring(0, 45)}...` : null}
                  </Tooltip>
                )}
              </div>
              <div className="flex-added-on">
                {moment(note.added_on).format("DD MMM, YYYY | HH:mm")}
              </div>
              <div className="flex-addedby">by vFulfill Team</div>
            </div>
          ))
        ) : (
          <div
            style={{
              padding: 16,
              textAlign: "center",
              background: "white",
              color: "#000",
              fontWeight: 500,
            }}
          >
            No Notes Added!
          </div>
        )}
      </div>
    );
  }
}

export default Notes;
