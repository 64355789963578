import React from "react";
import AppUpdateModal from "./index";
import { SHOPIFY_CLIENT_ID, BASE_URL, SHOPIFY_NAME } from "../../../constants";
import longArrowImage from "../../../assets/images/longArrow.png";
import "./index.scss";

class UpdateHeader extends React.Component {
  state = {
    visible: false,
  };

  closeModal() {
    this.setState({ visible: false });
  }

  render() {
    return (
      <div>
        <div
          className={`important-info ${
            this.props.appStatus === true ? `upheader1` : `downheader2`
          }`}
        >
          <div id="fade-in" className="imp-div">
            <div className="ic">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g
                  id="Group_3638"
                  data-name="Group 3638"
                  transform="translate(-115 -112)"
                >
                  <circle
                    id="Ellipse_48"
                    data-name="Ellipse 48"
                    cx="12"
                    cy="12"
                    r="12"
                    transform="translate(115 112)"
                    fill="#212121"
                  />
                  <g
                    id="Group_3637"
                    data-name="Group 3637"
                    transform="translate(-0.5 -1.375)"
                  >
                    <line
                      id="Line_1"
                      data-name="Line 1"
                      y2="8"
                      transform="translate(127.5 119.75)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-width="3"
                    />
                    <circle
                      id="Ellipse_49"
                      data-name="Ellipse 49"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(126 130)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <h5>Important:</h5>
            {/* <p>
              {`vFulfill App has been upgraded and updated over ${SHOPIFY_NAME}
              Platform. We suggest following these set of instructions to make
              sure you are using the latest version of the app:`}
            </p> */}

            <p style={{ marginLeft: "24px", marginRight: "56px" }}>
              Dear Partner, Shopify has updated their API and we need you to
              re-authenticate your Shopify store with vFulfill’s Shopify App
              ASAP. This must be completed urgently before 30th June midnight to
              avoid any disruption & ensure smooth operations.
            </p>
            <a
              className="clickable link"
              style={{
                position: "relative",
                width: "16.5rem",
                textAlign: "center",
              }}
              href={
                `${
                  this.props.store_url
                }/oauth/authorize?client_id=${SHOPIFY_CLIENT_ID}&scope=read_product_listings,read_products,write_products,read_orders,write_orders,read_inventory,write_customers,write_inventory,write_price_rules,read_draft_orders,write_draft_orders,read_customers,write_customers,read_checkouts,write_checkouts,read_fulfillments,read_merchant_managed_fulfillment_orders,write_merchant_managed_fulfillment_orders,read_assigned_fulfillment_orders,write_assigned_fulfillment_orders,read_third_party_fulfillment_orders,write_third_party_fulfillment_orders,write_fulfillments&redirect_uri=` +
                BASE_URL +
                `login/shopify_callback&state=${this.props.auth.store.id}`
              }

              // onClick={() => this.setState({ visible: true })}
            >
              Re-authenticate App
              <img src={longArrowImage} alt="arrow" className="arrow-image" />
            </a>
          </div>
        </div>
        <AppUpdateModal
          storename={this.props.storeName}
          url={this.props.url}
          geo={this.props.geo}
          closeModal={() => this.closeModal()}
          visible={this.state.visible}
        />
      </div>
    );
  }
}

export default UpdateHeader;
