import React, { useState } from "react";

import "./index.scss";
import { Button, Modal, Tooltip, Icon } from "antd";
import { ReactComponent as TooltipIcon } from "../../../../../assets/images/icons/TooltipIcon.svg";
import { ReactComponent as RightTick } from "../../../../../assets/images/icons/wrong_check.svg";
import AddFundsModal from "../../../MyWallet/AddFundsModal";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class NoBalanceModal extends React.Component {
  state = {
    clients: {
      razorpay: "",
    },
    addFundsModal: {
      visible: false,
    },
  };
  toggleAddFundsModal() {
    this.setState((state) => ({
      addFundsModal: {
        ...state.addFundsModal,
        visible: !state.addFundsModal.visible,
      },
    }));
  }

  render() {
    const { store, user } = this.props;
    return (
      <>
        {/*  Open Raise Bulk Order Request */}
        <Modal
          width={478}
          className={`recomended-packaging-modal ${
            !!this.props.data && this.props.data.length === 0
              ? `hide-border`
              : ``
          } `}
          visible={this.props.visible}
          footer={
            <>
              <div className="btns">
                <Button
                  className="clickable-dropdown"
                  onClick={() => this.toggleAddFundsModal()}
                >
                  Add Amount
                </Button>
                <Button onClick={() => this.props.cancel()} type="cancel">
                  Close Window
                </Button>
              </div>
            </>
          }
          // onOk={handleOk}
          title={
            <>
              <div>
                <span>
                  <Icon component={RightTick} />
                </span>{" "}
                <h1>Oops! </h1>
              </div>{" "}
            </>
          }
          // onCancel={handleCancel2}
        >
          <h1 style={{ textAlign: "center" }}>
            Your wallet balance is insufficient to complete this action. Kindly
            recharge your wallet to proceed.
          </h1>

          {this.state.addFundsModal.visible && (
            <AddFundsModal
              clients={this.state.clients}
              visible={this.state.addFundsModal.visible}
              toggleAddFundsModal={() => this.toggleAddFundsModal()}
              stripeOption={user.user_kyc_origin === "non-india" ? true : false}
              fetchData={() => {
                this.props.cancel();
              }}
            />
          )}
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
    user: state.auth.auth.user,
  };
};
export default connect(mapStateToProps)(NoBalanceModal);
