import React, { Component } from "react";
import { Modal, Icon, Button, Tabs } from "antd";
import { ReactComponent as rightArrow } from "../../../../assets/images/icons/long-arrow-pointing-to-the-right.svg";
import { ReactComponent as wireSuccessIcon } from "../../../../assets/images/icons/wire-transfer-success.svg";
import "./index.scss";
import ClickToCopy from "../../Common/ClickToCopy";
const { TabPane } = Tabs;

const AccountDetails = ({ details }) => {
  const copyAll = () => {
    let textToCopy = "";
    // Iterate through details object keys
    Object.keys(details).forEach((key) => {
      // Append each textToCopy value to textToCopy string
      textToCopy += details[key] + "\n";
    });
    // Create a temporary textarea element to copy the text
    const tempElement = document.createElement("textarea");
    tempElement.value = textToCopy.trim();
    document.body.appendChild(tempElement);
    tempElement.select();
    document.execCommand("copy");
    document.body.removeChild(tempElement);
  };

  return (
    <div className="account-details">
      <p>
        Account Name:
        <span>
          {" "}
          <ClickToCopy fullText={true} textToCopy={details.ac_name}>
            {/* {(copy) => (
              <a href="#" onClick={copy}>
                <Icon type="copy" />
              </a>
            )} */}
          </ClickToCopy>
        </span>
      </p>
      <p>
        Account Number:
        <span>
          {" "}
          <ClickToCopy fullText={true} textToCopy={details.ac_num}>
            {/* {(copy) => (
              <a href="#" onClick={copy}>
                <Icon type="copy" />
              </a>
            )} */}
          </ClickToCopy>
        </span>
      </p>
      <p>
        Bank Name:
        <span>
          {" "}
          <ClickToCopy fullText={true} textToCopy={details.bank_name}>
            {/* {(copy) => (
              <a href="#" onClick={copy}>
                <Icon type="copy" />
              </a>
            )} */}
          </ClickToCopy>
        </span>
      </p>
      <p>
        IFSC Code:
        <span>
          {" "}
          <ClickToCopy fullText={true} textToCopy={details.bank_ifsc}>
            {/* {(copy) => (
              <a href="#" onClick={copy}>
                <Icon type="copy" />
              </a>
            )} */}
          </ClickToCopy>
        </span>
      </p>
      <p>
        Branch Name:
        <span>
          {" "}
          <ClickToCopy fullText={true} textToCopy={details.bank_branch}>
            {/* {(copy) => (
              <a href="#" onClick={copy}>
                <Icon type="copy" />
              </a>
            )} */}
          </ClickToCopy>
        </span>
      </p>

      <p className="copy-all" onClick={() => copyAll()}>
        Copy All
        <span>
          <ClickToCopy textToCopy={""}></ClickToCopy>{" "}
        </span>
      </p>
    </div>
  );
};
class WireTransferSuccessModal extends Component {
  state = {
    bank: "icici",
    details: {
      hdfc: {
        ac_name: "VCFL EXPRESS PRIVATE LIMITED",
        ac_num: "50200043366411",
        bank_name: "HDFC BANK",
        bank_ifsc: "HDFC0004817",
        bank_branch: "SCO-37, SECTOR - 18, GURGAON",
      },
      icici: {
        ac_name: "VCFL EXPRESS PVT. LTD.",
        ac_num: "114505001669",
        bank_name: "ICICI BANK",
        bank_ifsc: "ICIC0001145",
        bank_branch: "UDYOG VIHAR GURGAON BRANCH",
      },
    },
  };
  setBank(bank) {
    this.setState({
      bank,
    });
  }
  render() {
    return (
      <Modal
        className="wireSuccessModal"
        visible={this.props.visible}
        width={480}
        title={null}
        closable={this.props.hideFooter}
        onCancel={() => this.props.toggleSuccessModal()}
        footer={null}
      >
        <div style={{ textAlign: "center" }}>
          <div className="transaction-sucess-content">
            <Icon component={wireSuccessIcon} />
            <h1>Please follow the process carefully.</h1>
            <p>Transfer funds to the account with following details:</p>
          </div>
          <Tabs
            activeKey={this.state.bank}
            onChange={(bank) => this.setBank(bank)}
          >
            <TabPane tab="ICICI Bank" key="icici">
              <AccountDetails details={this.state.details["icici"]} />
            </TabPane>
            <TabPane tab="HDFC Bank" key="hdfc">
              <AccountDetails details={this.state.details["hdfc"]} />
            </TabPane>
          </Tabs>
          <div className="copy-message">
            <p>
              Please add the message{" "}
              <span>"vFulfill Funds for {this.props.id}" </span>
              in the remarks while transferring the amount
            </p>
            <ClickToCopy textToCopy={`vFulfill Funds for ${this.props.id}`}>
              {(copy) => <Button onClick={copy}>Copy the message</Button>}
            </ClickToCopy>
          </div>
          <div className="footer-button">
            {!this.props.hideFooter && (
              <Button onClick={() => window.location.reload()} type="link">
                Continue To Dashboard
                <Icon component={rightArrow} />
              </Button>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default WireTransferSuccessModal;
