import React, { Component } from "react";
import { Input, Button, message } from "antd";
import Header from "../Header";
// import Footer from "../Footer";
import OnboardingInput from "../OnboardingInput";
import { SHOPIFY_CLIENT_ID, BASE_URL, SHOPIFY_NAME } from "../../../constants";
import { Link } from "react-router-dom";
import { ReactComponent as Illustartion } from "../../../assets/images/illustrations/onboarding/illustration-shopify.svg";
import "./index.scss";

class ShopifyConnect extends Component {
  state = {
    shopname: "",
  };

  setShopName = (val) => {
    this.setState({ shopname: val });
  };

  extractShopName() {
    const parsed = this.state.shopname.match(
      /(https?:\/\/)?([a-zA-Z0-9-]+)(\.myshopify.com)?/
    );
    const shopname = parsed && parsed[2] ? parsed[2] : this.state.shopname;
    this.setState({ shopname });
  }

  shopifyAuth = () => {
    if (this.state.shopname.length === 0) {
      message.error(`Please enter your ${SHOPIFY_NAME} name.`);
      return;
    }
    window.location = `https://${
      this.state.shopname
    }.myshopify.com/admin/oauth/authorize?client_id=${SHOPIFY_CLIENT_ID}&scope=read_product_listings,read_products,write_products,read_orders,write_orders,read_inventory,write_customers,write_inventory&redirect_uri=${BASE_URL}login/shopify_callback&state=${
      this.props.match.params.storeid
    }`;

    // window.location = `https://${
    //   this.state.shopname
    // }.myshopify.com/admin/oauth/authorize?client_id=${SHOPIFY_CLIENT_ID}&scope=read_product_listings,read_products,write_products,read_orders,write_orders,read_inventory,write_customers,write_inventory&redirect_uri=${BASE_URL}login/shopify_callback&state=${
    //   this.props.match.params.storeid
    // }`;
  };

  render() {
    return (
      <>
        <div className="shopify-connect">
          <Header
            title={
              <p className="shopify-connect-title">
                {`Connect your ${SHOPIFY_NAME}`}
              </p>
            }
            subtitle={
              <p className="shopify-connect-subtitle">
                {` Connect your existing ${SHOPIFY_NAME} to your vFulfill account`}
              </p>
            }
          />

          <div className="content-container">
            <Illustartion className="shopify-connect-img" />
            <OnboardingInput
              label={
                <p className="shopify-connect-label">
                  {` Please enter your ${SHOPIFY_NAME} name.`}
                  {""}
                </p>
              }
              addon=".myshopify.com"
              render={() => (
                <Input
                  value={this.state.shopname}
                  onChange={(e) => this.setShopName(e.target.value)}
                  onBlur={(e) => this.extractShopName()}
                  className="input-field shopify-field"
                />
              )}
            />
            <div>
              <Button
                type="primary"
                className=" shopify-connect-btn"
                onClick={this.shopifyAuth}
              >
                Continue
              </Button>
            </div>
            <div>
              <Link
                to={`/${this.props.auth.store.store_geo}/${
                  this.props.auth.store.id
                }`}
              >
                <button className="skip">Skip This Step</button>
              </Link>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </>
    );
  }
}

export default ShopifyConnect;
