import React, { Component } from "react";
import { Modal, Button } from "antd";
import { Link } from "react-router-dom";
import "./index.scss";
// import { ReactComponent as CircleCheck } from "../../../../../assets/images/icons/circle-check.svg";

class DisputeRaisedModal extends Component {
  render() {
    return (
      <Modal
        title={null}
        visible={this.props.visible}
        className="dispute-raised-modal"
        closable={false}
        maskClosable={false}
        footer={null}
        width={392}
        height={176}
      >
        <>
          <div className="dispute-raised-header">
            <div className="di">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g
                  id="Group_3391"
                  data-name="Group 3391"
                  transform="translate(-368 -600)"
                >
                  <circle
                    id="Ellipse_24"
                    data-name="Ellipse 24"
                    cx="12"
                    cy="12"
                    r="12"
                    transform="translate(368 600)"
                    fill="#2bc822"
                  />
                  <path
                    id="path16264"
                    d="M265.67,581.052l-.231-.24a.636.636,0,0,0-.923,0l-3.921,4.081-1.391-1.447a.635.635,0,0,0-.923,0l-.231.24a.7.7,0,0,0,0,.961l2.082,2.169a.638.638,0,0,0,.923,0l4.615-4.8a.7.7,0,0,0,0-.961Z"
                    transform="translate(118.14 28.187)"
                    fill="#fff"
                  />
                </g>
              </svg>

              <h6>Escalation Raised Successfully!</h6>
            </div>

            <p>
              The escalation Id{" "}
              <span style={{ color: "#0068FF", fontWeight: "bold" }}>
                {this.props.disputeid}
              </span>{" "}
              has been raised and can be seen in the escalation listing under
              shipments.
            </p>

            <Link
              to={`/${this.props.store.store_geo}/${
                this.props.store.id
              }/requests/escalations?search=${this.props.disputeid}`}
            >
              <Button
                style={{ marginTop: 15 }}
                onClick={this.props.raisedOnCancel}
              >
                Continue
              </Button>
            </Link>
          </div>
        </>
      </Modal>
    );
  }
}

export default DisputeRaisedModal;
