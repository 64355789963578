import React from "react";
import { Icon } from "antd";
// import { ReactComponent as Download } from "../../../../";
import { ReactComponent as Download } from "../../../../assets/images/icons/download.svg";
import { ReactComponent as Pause } from "../../../../assets/images/icons/pause-icon.svg";
import { ReactComponent as Play } from "../../../../assets/images/icons/play-icon.svg";

import "./index.scss";

class Audio extends React.Component {
  state = {
    audio: false,
    songInfo: {
      currentTime: 0,
      duration: 0,
      animationPercentage: 0,
    },
  };

  audioRef = React.createRef();

  timeUpdateHandler(e) {
    const current = e.target.currentTime;
    const duration = e.target.duration;

    // percentage
    const percentage = (current / duration) * 100;

    this.setState({
      songInfo: {
        ...this.state.songInfo,
        currentTime: current,
        duration,
        animationPercentage: percentage,
      },
    });
  }

  songEndHandler() {
    this.setState({ audio: false });
  }

  btnHandler() {
    if (!this.state.audio) {
      this.setState({ audio: true });
      this.audioRef.current.play();
    } else {
      this.setState({ audio: false });
      this.audioRef.current.pause();
    }
  }

  getTime(time) {
    return (
      Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
    );
  }

  dragHandler(time) {
    this.audioRef.current.currentTime = time.target.value;
    this.setState({
      songInfo: {
        ...this.state.songInfo,
        currentTime: time.target.value,
      },
    });
  }

  render() {
    return (
      <div className="audio-wraper">
        <div className="time-control">
          <div onClick={() => this.btnHandler()} className="play-pause">
            {this.state.audio ? (
              <Icon component={Pause} />
            ) : (
              <Icon component={Play} />
            )}
          </div>

          <div className="track">
            <input
              type="range"
              onChange={(e) => this.dragHandler(e)}
              min="0"
              value={this.state.songInfo.currentTime}
              max={this.state.songInfo.duration || 0}
            />
            <div
              style={{
                transform: `translateX(${
                  this.state.songInfo.animationPercentage
                }%)`,
              }}
              className="animate-track"
            />
          </div>

          <a
            href={
              `${process.env.REACT_APP_API_URL}` +
              "/products/download_product_media?url=" +
              this.props.src
            }
            download="filename.mp3"
            className="download-btn"
          >
            <Icon component={Download} />
          </a>
        </div>
        <audio
          onLoadedMetadata={(e) => this.timeUpdateHandler(e)}
          onTimeUpdate={(e) => this.timeUpdateHandler(e)}
          src={this.props.src}
          ref={this.audioRef}
          type="audio/mp3"
          onEnded={() => this.songEndHandler()}
        />
      </div>
    );
  }
}

export default Audio;
