import React, { Component } from "react";
import ReactQuill from "react-quill";
import { Modal, Input } from "antd";

import "react-quill/dist/quill.snow.css";
import "./index.scss";

class ImportListProductDescription extends Component {
  state = {
    value: null,
    modalVisible: false,
    confirmLoading: false,
    imgUrl: null,
    urlError: false,
  };

  componentDidMount = () => {
    this.setState({
      ...this.state,
      value: this.props.product.description,
    });
  };

  showModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  handleOk = () => {
    const { imgUrl } = this.state;

    if (
      imgUrl.match(
        /^(?:http(s)?:\/\/)?[\w.-]+(?:.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
      )
    ) {
      let imgTag = "<img src=" + imgUrl + ">";

      this.setState({
        modalVisible: false,
        value: this.state.value + imgTag,
        urlError: false,
      });
    } else {
      this.setState({
        urlError: true,
      });
    }
  };

  handleCancel = () => {
    this.setState({
      modalVisible: false,
      urlError: false,
    });
  };

  addImgUrl = (e) => {
    this.setState({
      ...this.state,
      imgUrl: e.target.value,
    });
  };

  onEditorChange(editor) {
    setTimeout(() => {
      this.setState(
        {
          value: editor.getHTML(),
        },
        () => this.props.onFieldChange("description", editor.getHTML())
      );
    }, 100);
  }

  render() {
    const { modalVisible, confirmLoading, imgUrl, value } = this.state;

    const modules = {
      toolbar: {
        container: [
          [{ font: ["serif", "sans-serif", "monospace"] }],
          [{ header: [1, 2, 3, 4, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          [{ align: ["right", "center", "justify", false] }],
          ["clean"],
        ],
        handlers: {
          image: this.showModal,
        },
      },
    };

    const formats = [
      "font",
      "header",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
      "align",
    ];

    return (
      <div>
        <ReactQuill
          name="editor"
          theme="snow"
          background="white"
          modules={modules}
          formats={formats}
          placeholder="Your product description..."
          value={value || ""}
          onBlur={(a, s, editor) => this.onEditorChange(editor)}
        />
        <Modal
          title="Add Image URL"
          visible={modalVisible}
          onOk={this.handleOk}
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
        >
          <div style={{ marginBottom: 16 }}>
            <Input value={imgUrl} onChange={this.addImgUrl} />
            <p
              style={{ color: "red" }}
              className={this.state.urlError === true ? "" : "hidden"}
            >
              Please enter a valid url
            </p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ImportListProductDescription;
