import React, { Component } from "react";
import { Select, Icon } from "antd";
import { ReactComponent as Arrow } from "../../../../assets/images/icons/arrow.svg";
import "./index.scss";
const { Option } = Select;

class SingleSelectFilter extends Component {
  state = {
    selected: "",
    open: false,
    searchText: "",
    arrow: false,
  };

  componentDidUpdate(pp) {
    if (pp.value !== this.props.value) {
      this.setState({ selected: this.props.value });
    }
  }

  render() {
    const selectProps = {
      style: { width: "312px", height: "40px" },
      dropdownMatchSelectWidth: true,
      dropdownClassName: "single-filter-overlay",
      maxTagCount: "1",

      value: this.props.value,
      suffixIcon: <Icon style={{ fontSize: "16px" }} component={Arrow} />,
      onSearch: (t) => this.setState({ searchText: t }),
      filterOption: (input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
      onChange: (d) =>
        this.setState({ selected: d }, () =>
          this.props.changeHandler(this.state.selected)
        ),
      onDropdownVisibleChange: (d) => this.setState({ arrow: d }),
    };

    const PageWrapper = (children) => {
      return (
        <div className="single-filter-wraper">
          <div className="custom-text">Payment Mode: </div>
          <Select {...selectProps}>
            <Option value="">All</Option>
            <Option value={0}>Prepaid</Option>
            <Option value={1}>Cash On Delivery</Option>
          </Select>
        </div>
      );
    };

    return PageWrapper();
  }
}

export default SingleSelectFilter;
