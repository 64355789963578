import React from "react";
import "./index.scss";
import { Radio } from "antd";

function NonDeliveryReport(props) {
  return (
    <div className="non-delivery-reports">
      {/* <div className="enable-order-text">
        <p>Take following action(s) if a customer is not reachable on call:</p>

        <div className="calling-services">
          <Radio.Group
            value={props.data.customerNotReachable}
            onChange={(e) =>
              props.updateSetting("ndr", "customerNotReachable", e.target.value)
            }
          >
            <Radio value="RE_ATTEMPT_ANYWAYS">
              Re-attempt anyways (A delivery is re-attempted a total of 3 times)
            </Radio>
            <Radio value="ORDER_RTO">Mark the Order as RTO</Radio>
          </Radio.Group>
        </div>
      </div> */}
    </div>
  );
}

export default NonDeliveryReport;
