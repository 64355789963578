import React, { Component } from "react";
import { Input, Form, Icon, Upload } from "antd";
import { ReactComponent as individualican } from "../../../../../../assets/images/icons/individualican.svg";
import { ReactComponent as arrowican } from "../../../../../../assets/images/icons/arrow.svg";
import Header from "../../../../Header";

const Dragger = Upload.Dragger;
class Bankdetail extends Component {
  render() {
    const { getFieldDecorator, getFieldsValue } = this.props.form;

    const bankData = getFieldsValue(["bank[accno]", "bank[re_accno]"]).bank;

    return (
      <>
        {this.props.currentStep === 2 && (
          <Header
            title="Enter Banking Details"
            subtitle="Enter your banking details to begin setting up a wallet in vFulfill."
          />
        )}

        {this.props.kycType === "Organisation"
          ? this.props.currentStep === 2 && (
              <p className="gst-individual">
                <span>
                  <Icon component={individualican} />
                </span>
                <span className="flag">
                  I am an <span className="registered-india">Organisation</span>{" "}
                  operating <span className="registered-india">with GST</span>{" "}
                  in India
                </span>
              </p>
            )
          : this.props.currentStep === 2 && (
              <p className="gst-individual">
                <span>
                  <Icon component={individualican} />
                </span>
                <span className="flag">
                  I am an <span className="registered-india">Individual</span>{" "}
                  operating{" "}
                  <span className="registered-india">without GST</span> in India
                </span>
              </p>
            )}

        {this.props.currentStep === 2 && (
          <div className="top-header-steps">
            <p>
              Step {this.props.currentStep} out of {this.props.totalSteps}
            </p>
            <button
              className="back-btn"
              onClick={() => this.props.profileBakButton()}
            >
              <span>
                <Icon component={arrowican} />
              </span>
              Previous Step
            </button>
          </div>
        )}

        <Form className="profile-form">
          {this.props.currentStep === 2 ? (
            <div
              style={{
                display: this.props.currentStep === 2 ? "block" : "none",
              }}
            >
              <Form.Item label="Beneficiary Name*">
                {getFieldDecorator("bank[beneficiary_name]", {
                  rules: [
                    {
                      required: true,
                      message: "Enter Beneficiary Name",
                    },
                  ],
                })(<Input placeholder="Enter Beneficiary Name" />)}
              </Form.Item>

              <Form.Item label="Account Number">
                {getFieldDecorator("bank[accno]", {
                  rules: [
                    {
                      required: true,
                      message: "Enter your Bank Account Number",
                    },
                  ],
                })(<Input placeholder="Enter your Bank Account Number" />)}
              </Form.Item>

              <Form.Item
                label="Re-Enter Account Number"
                validateStatus={
                  bankData.accno === bankData.re_accno ? "success" : "error"
                }
                help={
                  bankData.accno === bankData.re_accno
                    ? ""
                    : "Account Numbers do not match"
                }
              >
                {getFieldDecorator("bank[re_accno]", {
                  rules: [
                    {
                      required: true,
                      message: "Enter your Bank Account Number",
                    },
                  ],
                })(<Input placeholder="Enter your Bank Account Number" />)}
              </Form.Item>

              <Form.Item label="Branch IFSC Code">
                {getFieldDecorator("bank[ifsc]", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter your Bank IFSC Code!",
                    },
                  ],
                })(<Input placeholder="Enter Your IFSC Code" />)}
              </Form.Item>

              <Form.Item className="upload-file">
                {getFieldDecorator("cancelled_cheque", {
                  rules: [
                    {
                      required: true,
                      message: "Please upload cancel cheque",
                    },
                  ],
                })(
                  <div className="ant-row ant-form-item">
                    <div className="ant-col ant-form-item-label upload-label">
                      <label className="ant-form-item-required">
                        {this.props.kycType === "Organisation"
                          ? "Business "
                          : ""}
                        Cancelled cheque (webp, jpeg, jpg, png, pdf)
                      </label>
                    </div>
                    <Dragger
                      accept=".webp,.jpeg,.jpg,.png,.pdf"
                      fileList={
                        this.props.files.cancelled_cheque
                          ? this.props.files.cancelled_cheque
                          : []
                      }
                      action={
                        process.env.REACT_APP_API_URL + "/user/upload_kyc_doc"
                      }
                      withCredentials="true"
                      onChange={({ fileList }) =>
                        this.props.onFileUpload("cancelled_cheque", fileList)
                      }
                      onRemove={() =>
                        this.props.onRemoveCancelCheque("cancelled_cheque")
                      }
                    >
                      <p className="ant-upload-drag-icon">
                        <Icon type="upload" /> Upload Document (Drop files here
                        or Click to Upload)
                      </p>
                    </Dragger>

                    <p className="aadhar-card-name">
                      {" "}
                      {this.props.files.cancelled_cheque &&
                      this.props.files.cancelled_cheque &&
                      this.props.files.cancelled_cheque.length &&
                      this.props.files.cancelled_cheque[0].originFileObj
                        ? this.props.files.cancelled_cheque[0].originFileObj
                            .name
                        : null}{" "}
                    </p>
                    {this.props.files.cancelled_cheque &&
                    Object.keys(this.props.files.cancelled_cheque).length >
                      0 ? (
                      <button
                        onClick={() =>
                          this.props.onRemoveCancelCheque("cancelled_cheque")
                        }
                      >
                        X
                      </button>
                    ) : null}
                  </div>
                )}
              </Form.Item>
            </div>
          ) : null}
        </Form>
      </>
    );
  }
}

export default Form.create()(Bankdetail);
