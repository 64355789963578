import React, { Component } from "react";
import { Button, Modal, Radio } from "antd";
import { connect } from "react-redux";
import "./index.scss";

const RadioGroup = Radio.Group;

class LinkProductModal extends Component {
  state = {
    importer: null,
  };

  handleChange = (field, value) => {
    this.setState({
      ...this.state,
      [field]: value,
    });
  };

  resetModal = () => {
    this.setState({
      importer: null,
    });
  };

  render() {
    const { importer } = this.state;

    return (
      <Modal
        className="product-link-modal"
        title={<p className="link-product-txt">Link Product(s)</p>}
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        afterClose={this.resetModal}
        width="472px"
        footer={[
          <Button
            key="submit"
            type="primary"
            className="next-button"
            onClick={() => this.props.onConfirm(importer, this.props.orderway)}
            disabled={this.state.importer === null ? true : false}
          >
            Next
          </Button>,
          <Button
            key="back"
            className="cancle-button"
            onClick={this.props.onCancel}
          >
            Cancel
          </Button>,
        ]}
      >
        <div>
          <RadioGroup
            onChange={(e) => this.handleChange("importer", e.target.value)}
            value={importer}
          >
            <Radio value="importList">
              <span className="label-text">
                Link the product from Import List
              </span>
            </Radio>
            <Radio value="bundle">
              <span className="label-text">
                Create a product bundle from Import List
              </span>
            </Radio>
          </RadioGroup>
          <p className="link-product-note-txt">
            {" "}
            *Note - Please make sure you have the required product in Import
            List to link with. If there isn’t any, then you can import from
            either VF Catalog or Ali Express before continuing.
          </p>
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  store: state.auth.auth.store,
});
export default connect(mapStateToProps)(LinkProductModal);
