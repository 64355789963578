import React, { Component } from "react";
import "./index.scss";
import Globalsearch from "../../Common/Globalsearch";
import { SHOPIFY_NAME } from "../../../../constants";

class Managestornav extends Component {
  render() {
    return (
      <div className="storlistingnav fullorder_data">
        <div className="stornav">
          <div className="ordfill">
            <div className="ordfillwrap">
              <div className="left" style={{ marginLeft: "7px" }}>
                <div
                  style={{
                    color: "#000",
                    fontSize: "12px",
                    letterSpacing: "0.032em",
                    lineHeight: "24px",
                  }}
                >
                  Manage Stores
                </div>
              </div>
              <div className="download_csv">
                <button
                  className="clickable"
                  style={{
                    width: "120px",
                    height: "32px",
                  }}
                  onClick={() => this.props.toggleAddStoreModal()}
                >
                  Add Store
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_data">
          <div className="Globalsearch">
            <Globalsearch
              class={"five-lg"}
              data={this.props.filters}
              change={(value) =>
                this.props.changeHandler({ search: value.target.value })
              }
              searchFunc={(value) => this.props.changeFilter({ search: value })}
              placeholder={`Search by Store Name, ${SHOPIFY_NAME} Link `}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Managestornav;
