import React, { Component } from "react";
// import { message, Button } from "antd";
// import vcimage from "../../../../assets/images/icons/vc.png";
//import ContactCenterLogs from "./ContactCenterLogs";
import ContactCenterSettings from "./ContactCenterSettings";
import "./index.scss";
// import axios from "axios";

class ContactCenterTab extends Component {
  state = {
    showSaveButton: true,
    loading: false,
  };

  // setShowSaveButton(show) {
  //   this.setState({
  //     showSaveButton: show,
  //   });
  // }

  // onTabChange(tab) {
  //   let show = false;
  //   if (tab === "settings") {
  //     show = true;
  //   }
  //   this.setShowSaveButton(show);
  // }

  // saveSetting() {
  //   this.setState(
  //     {
  //       loading: true,
  //     },
  //     () => {
  //       axios({
  //         url:
  //           process.env.REACT_APP_API_URL + "/Contactcenter/set_store_services",
  //         method: "post",
  //         data: {
  //           contact_center_settings: this.settingsRef.getSettings(),
  //           storeid: this.props.match.params.storeid,
  //         },
  //         withCredentials: true,
  //       })
  //         .then((res) => {
  //           if (
  //             res.data.success === 1 &&
  //             res.data.msg !== "Contact center service updated!"
  //           ) {
  //             this.setState((state) => ({
  //               loading: false,
  //             }));
  //             message.success("Setting saved successfully!");
  //           } else if (
  //             res.data.success === 1 &&
  //             res.data.msg === "Contact center service updated!"
  //           ) {
  //             this.setState((state) => ({
  //               loading: false,
  //             }));
  //             message.success("Setting updated successfully!");
  //           }
  //         })
  //         .catch((e) => {
  //           this.setState({
  //             loading: false,
  //           });
  //           message.error("There was an error saving the setting!");
  //         });
  //     }
  //   );
  // }

  render() {
    return (
      <div className="contact-tab-container">
        <div className="contact-tab-list">
          <div className="contact-tab-header">
            <div className="colling-services">
              <h2>Contact Center Calling Services</h2>
            </div>
          </div>

          <ContactCenterSettings
            storeId={this.props.match.params.storeid}
            updateSetting={(a, b, c) => this.props.updateSetting(a, b, c)}
            cod={this.props.cod}
            prepaid={this.props.prepaid}
            ndr={this.props.ndr}
            fetchContactCenter={() => this.props.fetchContactCenter()}
          />
        </div>
      </div>
    );
  }
}

export default ContactCenterTab;
