import {
  Button,
  Checkbox,
  Divider,
  Icon,
  message,
  Radio,
  Switch,
  Alert,
  Tooltip,
} from "antd";

import React, { Component } from "react";
import "./index.scss";
import axios from "axios";
import { ReactComponent as Tooltipican } from "../../../../assets/images/icons/tooltipican.svg";
import { connect } from "react-redux";
class OrderConfirmation extends Component {
  state = {
    enableOrderConfirmation: this.props.data.order_management_enable,
    loading: false,
  };
  onSubmitData() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/store/update_order_management_confirmation_setting",
          method: "POST",
          data: {
            storeid: this.props.auth.store.id,
            order_management_enable: 1,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success) {
              this.setState({
                enableOrderConfirmation:
                  res.data.order_confirmation_management_setting
                    .order_management_enable,
                loading: false,
              });
              message.success("Order Verification Managed by vfulfill enabled");
            } else {
              message.error(res.data.msg);
              this.setState({
                loading: false,
              });
            }
          })
          .catch((e) => {
            message.error(e.message);
            this.setState({
              loading: false,
            });
          });
      }
    );
  }
  render() {
    return (
      <div className="settings-container">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3 className="title">
            Order Confirmation{" "}
            <span
              className="time-zone-tooltip"
              style={{
                marginLeft: "10px",
              }}
            >
              <Tooltip title="Enabling this would direct all Order Management Operations to be handled by vFulfill Team.">
                <Icon component={Tooltipican} />
              </Tooltip>
            </span>
          </h3>
        </div>

        <Divider />

        <div className="options-container35">
          <div className="order-switch-container">
            <div>
              {" "}
              <span className="label">Enable :</span>{" "}
              <Switch
                checked={this.state.enableOrderConfirmation ? true : false}
                loading={this.state.loading}
                disabled={this.state.enableOrderConfirmation}
                onChange={(checked) => {
                  if (checked) {
                    this.onSubmitData();
                  }
                }}
              />
              {this.state.enableOrderConfirmation ? (
                <span
                  className="time-zone-tooltip time-zone-tooltip-custom"
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  <Tooltip title="Please get in touch with your account manager to turn this off.">
                    <Icon component={Tooltipican} />
                  </Tooltip>
                </span>
              ) : null}
            </div>
          </div>
          <Alert
            style={{ fontSize: "14px" }}
            description="This service will cost you (₹) 25 per order."
            message="With this feature enabled, vFulfill manages your Order Confirmation, NDR Management, and Order Shipment Updates through automated systems like IVR, WhatsApp, and a call center, ensuring seamless communication with your customers."
            type="info"
            showIcon
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(OrderConfirmation);
