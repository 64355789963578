import React, { Component } from "react";
import { Tooltip } from "antd";
import {
  getCurrencySymbol,
  formatNumber,
} from "../../../../../Helpers/Pricing";
import { SHOPIFY_NAME } from "../../../../../constants";

// import "./index.scss";

export class Buybackhandling extends Component {
  render() {
    const transaction = this.props.transaction;
    return (
      <div className="Transactions_head">
        <div className="modal_headerdata">
          <div className="Vforderid_txt">
            <span style={{ marginRight: "8px" }}>
              {" "}
              VFOrder ID : {transaction.transaction_data.vforderid}
            </span>
          </div>
          <div className="Header_name">
            <span style={{ marginRight: "8px" }}>
              Transaction ID : {""}
              <span>{transaction.transaction_vfid}</span>
            </span>
            <span
              style={{ borderRight: "2px solid #e3e3e3", marginRight: "8px" }}
            />
            <span style={{ marginRight: "8px" }}>
              {`${SHOPIFY_NAME} Order Name :`}
              <span>
                {transaction.transaction_data.items &&
                transaction.transaction_data.items[0] &&
                transaction.transaction_data.items[0].orderid
                  ? transaction.transaction_data.items[0].orderid
                  : ""}
              </span>
            </span>
            {/* <span
              style={{ borderRight: "2px solid #e3e3e3", marginRight: "8px" }}
            /> */}
            {/* <span>
              Awb:{" "}
              <span style={{ marginRight: "8px" }}>
                {transaction.transaction_data &&
                transaction.transaction_data.awb
                  ? transaction.transaction_data.awb
                  : ""}
              </span>
            </span> */}
          </div>
        </div>

        <table className="Transactions-table">
          <thead>
            <tr>
              <th>Product Details</th>
              <th>Qty</th>
              <th>Buyback Percentage</th>

              {this.props.storegeo === "global" ? null : (
                <th>Taxable Unit Price</th>
              )}
              {this.props.storegeo === "global" ? null : (
                <th>Taxable Amount</th>
              )}
              {this.props.storegeo === "global" ? null : <th>GST Amount</th>}

              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {transaction.transaction_data.items.map((item) =>
              item.bundle && item.bundle.length > 0 ? (
                item.bundle.map((bundleitem) => (
                  <tr key={bundleitem.vfsku}>
                    <td>
                      {
                        <div
                          className="transaction_images"
                          style={{ width: "140px" }}
                        >
                          <div className="wraper">
                            {" "}
                            <img src={bundleitem.img} alt="" />{" "}
                            <div className="side">
                              {" "}
                              <p>
                                {" "}
                                <Tooltip
                                  title={bundleitem.aliname}
                                  placement="topLeft"
                                >
                                  {" "}
                                  {bundleitem && bundleitem.aliname
                                    ? bundleitem.aliname.substring(0, 16)
                                    : null}{" "}
                                </Tooltip>{" "}
                              </p>{" "}
                              <p
                                style={{
                                  margin: "0 0 0 0px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {" "}
                                {bundleitem && bundleitem.vfsku
                                  ? bundleitem.vfsku
                                  : null}{" "}
                                X{" "}
                                {bundleitem && bundleitem.quantity
                                  ? bundleitem.quantity
                                  : null}{" "}
                              </p>{" "}
                            </div>{" "}
                          </div>
                        </div>
                      }
                    </td>

                    <td>
                      {
                        <div className="transaction_quantity">
                          <p>
                            {bundleitem && bundleitem.quantity
                              ? bundleitem.quantity * item.quantity
                              : null}
                          </p>
                        </div>
                      }
                    </td>

                    <td>
                      {
                        <div className="buyback_percantage">
                          <span style={{ marginRight: "1px" }}>
                            {
                              transaction.transaction_data.costs.pcosts[
                                bundleitem.vfsku
                              ].buyback_percentage
                            }
                          </span>
                          <span>%</span>
                        </div>
                      }
                    </td>

                    {this.props.storegeo === "global" ? null : (
                      <td>
                        {
                          <div>
                            <p>
                              {getCurrencySymbol(
                                transaction.transaction_currency
                              )}{" "}
                              {
                                transaction.transaction_data.costs.pcosts[
                                  bundleitem.vfsku
                                ].total_nongst
                              }
                            </p>
                          </div>
                        }
                      </td>
                    )}

                    {this.props.storegeo === "global" ? null : (
                      <td>
                        {
                          <div className="transaction_Textable">
                            <p>
                              {getCurrencySymbol(
                                transaction.transaction_currency
                              )}{" "}
                              {transaction.transaction_data.costs.pcosts[
                                bundleitem.vfsku
                              ].buyback * bundleitem.quantity}
                            </p>
                          </div>
                        }
                      </td>
                    )}
                    {this.props.storegeo === "global" ? null : (
                      <td>
                        {
                          <div className="transaction_GST">
                            <p>
                              {getCurrencySymbol(
                                transaction.transaction_currency
                              )}{" "}
                              {formatNumber(
                                transaction.transaction_data.costs.pcosts[
                                  bundleitem.vfsku
                                ].gst * bundleitem.quantity,
                                2
                              )}
                            </p>
                          </div>
                        }
                      </td>
                    )}

                    <td>
                      {
                        <div className="transaction_Total">
                          <p className="blue-text">
                            {getCurrencySymbol(
                              transaction.transaction_currency
                            )}{" "}
                            {formatNumber(
                              transaction.transaction_data.costs.pcosts[
                                bundleitem.vfsku
                              ].total * bundleitem.quantity,
                              2
                            )}
                          </p>
                        </div>
                      }
                    </td>
                  </tr>
                ))
              ) : (
                <tr key={item.vfsku}>
                  <td style={{ width: "180px" }}>
                    {
                      <div className="transaction_images">
                        <div className="wraper">
                          {" "}
                          <img src={item.img} alt="" />{" "}
                          <div className="side">
                            {" "}
                            <p>
                              {" "}
                              <Tooltip title={item.aliname} placement="topLeft">
                                {" "}
                                {item && item.aliname
                                  ? item.aliname.substring(0, 16)
                                  : null}{" "}
                              </Tooltip>{" "}
                            </p>{" "}
                            <p
                              style={{
                                margin: "0 0 0 0px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {" "}
                              {item && item.vfsku ? item.vfsku : null} X{" "}
                              {item && item.quantity ? item.quantity : null}{" "}
                            </p>{" "}
                          </div>{" "}
                        </div>
                      </div>
                    }
                  </td>

                  <td>
                    {
                      <div className="transaction_quantity">
                        <p>{item && item.quantity ? item.quantity : null}</p>
                      </div>
                    }
                  </td>

                  <td>
                    {
                      <div className="buyback_percantage">
                        <span style={{ marginRight: "1px" }}>
                          {
                            transaction.transaction_data.costs.pcosts[
                              item.vfsku
                            ].buyback_percentage
                          }
                        </span>
                        <span>%</span>
                      </div>
                    }
                  </td>

                  {this.props.storegeo === "global" ? (
                    <td />
                  ) : (
                    <td>
                      {
                        <div>
                          <p>
                            {getCurrencySymbol(
                              transaction.transaction_currency
                            )}{" "}
                            {
                              transaction.transaction_data.costs.pcosts[
                                item.vfsku
                              ].total_nongst
                            }
                          </p>
                        </div>
                      }
                    </td>
                  )}

                  {this.props.storegeo === "global" ? (
                    <td />
                  ) : (
                    <td>
                      {
                        <div className="transaction_Textable">
                          <p>
                            {getCurrencySymbol(
                              transaction.transaction_currency
                            )}{" "}
                            {transaction.transaction_data.costs.pcosts[
                              item.vfsku
                            ].buyback * item.quantity}
                          </p>
                        </div>
                      }
                    </td>
                  )}

                  {this.props.storegeo === "global" ? (
                    <td />
                  ) : (
                    <td>
                      {
                        <div className="transaction_GST">
                          <p>
                            {getCurrencySymbol(
                              transaction.transaction_currency
                            )}{" "}
                            {formatNumber(
                              transaction.transaction_data.costs.pcosts[
                                item.vfsku
                              ].gst * item.quantity,
                              2
                            )}
                          </p>
                        </div>
                      }
                    </td>
                  )}

                  <td>
                    {
                      <div
                        className={
                          transaction.transaction_type === "debit"
                            ? "red_text"
                            : "green_text"
                        }
                      >
                        <p className="blue-text">
                          {getCurrencySymbol(transaction.transaction_currency)}{" "}
                          {formatNumber(
                            transaction.transaction_data.costs.pcosts[
                              item.vfsku
                            ].total * item.quantity,
                            2
                          )}
                        </p>
                      </div>
                    }
                  </td>
                </tr>
              )
            )}
            <tr className="no-border">
              <td />

              <td>Total</td>

              {this.props.storegeo === "global" ? null : <td />}
              {this.props.storegeo === "global" ? null : <td />}
              {this.props.storegeo === "global" ? null : <td />}

              <td />
              <td>
                {" "}
                <p
                  className={
                    transaction.transaction_type === "debit"
                      ? "red_text"
                      : "green_text"
                  }
                  style={{ whiteSpace: "nowrap" }}
                >
                  {getCurrencySymbol(transaction.transaction_currency)}{" "}
                  {formatNumber(transaction.transaction_amount, 2)}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default Buybackhandling;
