import React from "react";
import "./index.scss";
import { Checkbox, Tooltip, Icon, Dropdown, Menu } from "antd";
import { ReactComponent as internalfilledheartican } from "../../../../assets/images/icons/internalfilledheartican.svg";
import { ReactComponent as newProducts } from "../../../../assets/images/icons/NewProducts.svg";
import { ReactComponent as innerCircle } from "../../../../assets/images/icons/innerCircle.svg";
import { ReactComponent as everGreen } from "../../../../assets/images/icons/everGreen.svg";
import { ReactComponent as allProducts } from "../../../../assets/images/icons/allProducts.svg";
import { ReactComponent as packaging } from "../../../../assets/images/icons/packaging.svg";

const CategoriesBar = ({
  filters,
  onChange,
  categories,
  niches,
  niche,
  loading,
}) => {
  const categoriesData = {};
  categories.forEach((cat) => {
    if (cat.id.indexOf("-") === -1) {
      categoriesData[cat.id] = { ...cat, categories: [] };
    } else {
      const parentId = cat.id.split("-")[0];
      categoriesData[parentId]["categories"].push(cat);
    }
  });

  const menu = (
    <Menu>
      {niches &&
        niches.map((parentCat) => (
          <Menu.Item className="cc" va key={parentCat.id}>
            <Checkbox
              checked={niche.includes(parentCat.id)}
              className="ccc "
              onChange={(e) =>
                onChange("niche", {
                  status: e.target.checked,
                  name: parentCat.id,
                })
              }
            >
              {" "}
              <span className="underline-animation">{parentCat.name}</span>
            </Checkbox>
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <div className="categories-bar">
      <div className="categories-bar-content" style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <ul className="filters-list">
            <li className="">
              <Dropdown overlayClassName="niche-overlay" overlay={menu}>
                <div className="niche-hov">
                  Select Niche{" "}
                  <svg
                    style={{
                      marginLeft: "5px",
                      transition: "all 0.5s",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_49"
                      data-name="Polygon 49"
                      transform="translate(16 8) rotate(180)"
                      fill="none"
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#000"
                      />
                    </g>
                  </svg>
                </div>
              </Dropdown>
            </li>

            <li
              className={
                loading
                  ? "hov underline-animation hovercursor-pointer"
                  : "hov underline-animation"
              }
              id={`${
                filters.inner_circle_product === 1 ||
                filters.newproduct === 1 ||
                filters.evergreen === 1 ||
                filters.watchlist ||
                filters.packaging === 1
                  ? `in-active`
                  : `active`
              }`}
              onClick={() => onChange("all", filters.all === 1 ? 0 : 1)}
            >
              <Icon component={allProducts} />
              <span> All Products</span>
            </li>

            <li
              id={`${filters.newproduct === 1 ? `active` : `in-active`}`}
              className={
                loading
                  ? "hov hov underline-animation hovercursor-pointer"
                  : "hov hov underline-animation"
              }
              onClick={() =>
                onChange("newproduct", filters.newproduct === 1 ? 0 : 1)
              }
            >
              {" "}
              <Icon component={newProducts} />
              <span role="img" aria-label="New Products">
                New Products{" "}
              </span>
            </li>

            <li
              id={`${
                filters.inner_circle_product === 1 ? `active` : `in-active`
              }`}
              className={
                loading
                  ? "hov hov underline-animation hovercursor-pointer"
                  : "hov hov underline-animation"
              }
              onClick={() =>
                onChange(
                  "inner_circle_product",
                  filters.inner_circle_product === 1 ? 0 : 1
                )
              }
            >
              <Icon component={innerCircle} />

              <span role="img" aria-label="Inner Circle Products">
                Inner Circle Products
              </span>
            </li>

            <li
              id={`${filters.evergreen === 1 ? `active` : `in-active`}`}
              className={
                loading
                  ? "hov hov underline-animation hovercursor-pointer"
                  : "hov hov underline-animation"
              }
              onClick={() =>
                onChange("evergreen", filters.evergreen === 1 ? 0 : 1)
              }
            >
              <Icon component={everGreen} />

              <span role="img" aria-label="evergreen">
                Evergreen Products
              </span>
            </li>

            <li
              id={`${filters.packaging === 1 ? `active` : `in-active`}`}
              className={
                loading
                  ? "hov hovercursor-pointer hov underline-animation"
                  : "hov hov underline-animation"
              }
              onClick={() =>
                onChange("packaging", filters.packaging === 1 ? 0 : 1)
              }
            >
              {" "}
              <Icon component={packaging} />
              <span role="img" aria-label="packaging">
                Packaging{" "}
              </span>
              <div className="beta">Beta</div>
            </li>

            <li
              className={
                loading
                  ? "hov hov underline-animation hovercursor-pointer"
                  : "hov hov underline-animation"
              }
              id={`${filters.watchlist === 1 ? `active` : `in-active`}`}
              onClick={() =>
                onChange("watchlist", filters.watchlist === 1 ? 0 : 1)
              }
            >
              <Icon component={internalfilledheartican} />
              <span> My Wishlist</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CategoriesBar;
