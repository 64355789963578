import React, { Component } from "react";
import {
  Row,
  Col,
  Skeleton,
  Alert,
  // Select,
  Icon,
  Pagination,
  // DatePicker,
  Card,
  message,
  Dropdown,
  Menu,
  Tag,
  Checkbox,
  Modal,
  notification,
  Button,
} from "antd";
import moment from "moment";
import axios from "axios";
import qs from "qs";
// import OrderCard from "./OrderCard";
import SendMsgToCstmrModal from "./SendMsgToCstmrModal";
import AddNoteModal from "./AddNoteModal";
import OrderConfirmationModal from "./OrderConfirmationModal";
// import { ReactComponent as FFStatusIcon } from "../../../../assets/images/icons/ff_status_icon.svg";
import { ReactComponent as CircleCheck } from "../../../../assets/images/icons/circle-check.svg";
import { ReactComponent as CircleCross } from "../../../../assets/images/icons/circle-cross.svg";
import { ReactComponent as CirclePause } from "../../../../assets/images/icons/circle-pause.svg";
import { ReactComponent as OpenOrderIcon } from "../../../../assets/images/icons/open-order-icon.svg";
import { ReactComponent as NotLinkedIcon } from "../../../../assets/images/icons/unlinkedorder.svg";
import { ReactComponent as NonServiceableIcon } from "../../../../assets/images/icons/nonservicable.svg";
import { ReactComponent as ServiceableIcon } from "../../../../assets/images/icons/servicable.svg";
import { ReactComponent as NotFulfillIcon } from "../../../../assets/images/icons/not-fulfill-icon.svg";
import { ReactComponent as CallingIcon } from "../../../../assets/images/icons/callicon.svg";

import { ReactComponent as ShipmentStatusIcon } from "../../../../assets/images/icons/shopping-cart.svg";
// import { ReactComponent as PaymentStatusIcon } from "../../../../assets/images/icons/payment_status_icon.svg";

import LinkProductModal from "../../Products/MyProducts/LinkProductModal";
import AliExpressModal from "../../Products/MyProducts/LinkProductModal/AliExpressModal";
import ImportListModal from "../../Products/MyProducts/LinkProductModal/ImportListModal";
import BundleModal from "../../Products/MyProducts/LinkProductModal/BundleModal";
import NDREscalationModal from "./NDREscalationModal";
import NDRModal from "./NDRModal";
import NDRRemarksModal from "./NDRRemarksModal";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";
import { hasPermission } from "../../../../Helpers/Permissions";
import "./index.scss";
import ViewBundleModal from "./ViewBundleModal";
// import Globalsearch from "../../Common/Globalsearch";
import OrderCardNew from "./OrderCardNew";
import Ordernav from "./Ordernav";
import ActionNotification from "../../Common/ActionNotification";
import NotFoundCard from "../../../Dashboard/Common/NotFoundCard/index";
import ErrorCard from "../../../Dashboard/Common/ErrorCard";
import { SHOPIFY_NAME } from "../../../../constants";
import GlobakBulkSearch from "../../Common/GlobakBulkSearch";
import GlobakBulkOrder from "../../Common/GlobalBulkOrder";

// import { onLoad } from "@sentry/browser";

// const { RangePicker } = DatePicker;
// const { Option } = Select;
// const { Search } = Input;
const { confirm } = Modal;

class ManageOrders extends Component {
  state = {
    isDateRangeValid: true,
    count: 3, // Used for store geo timer or countdown()
    loading: true,
    modalLoading: false,
    error: false,
    showMavFilters: true,
    errorMsg: "",
    errorCode: "",
    visibleBulk: false, //modal bulk search
    visibleBulkOrderPlace: false,
    bulkOrderPlace: [],
    bulkplaceLoading: false,
    orders: [],
    orderscount: [],
    selected: {},
    selectedConfirmed: {},
    tags: [],
    total: 0,
    store_settings: null,
    currencies: null,
    selectAllOrders: false,
    linkedNotifications: false,
    tabIsProcessing: false,
    tabIsClosedOrders: false,
    tabIsopen: false,
    tabIsallOrder: false,

    toBeShippedBtn: false,
    shippedBtn: false,
    allBtn: true,

    toBeShippedBtn2: false,
    shippedBtn2: false,
    allBtn2: true,
    sort: "date-desc",
    paging: {
      page: 1,
      per_page_count: 20,
    },
    filters: {
      startDate: moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      dateType: "shopify",
      search: "",
      fulfillmentStatus: [],
      paymentStatus: [],
      vfOrderStatus: [],
      shipmentStatus: [],
      pagename: [],
      is_servicable: [],
      duplicate_orders: [],
      bulkSearch: [], //bulk search value cointainer
      tags: [],
      mav_filters: {
        rto_risk: [],
        reason: [],
      },
    },

    notesModal: {
      visible: false,
      saving: false,
      id: undefined,
      cb: undefined,
    },

    sendMsgModal: {
      visible: false,
      saving: false,
      id: undefined,
      error: undefined,
    },
    confirmationModal: {
      visible: false,
      id: undefined,
      type: "verify",
      saving: false,
      cancelReason: "",
      refund: false,
      refundAmount: 0,
      snoozeDate: "",
      isCod: false,
      shippingCost: { orders: {} },
      notfulfillcheck: false,
      cancelOnShopify: false,
    },
    linkProductModal: {
      visible: false,
      importListModalVisible: false,
      bundleModalVisible: false,
      aliExpressModalVisible: false,
      id: null,
      productData: {},
    },

    orderLinkProductModal: {
      importListModalVisible: false,
      id: null,
      productData: {},
    },

    orderBundleProductModal: {
      bundleModalVisible: false,
      id: null,
      productData: {},
    },

    ndrModal: {
      visible: false,
      awb: "",
      shipment_vfid: "",
      address: {
        customer_details: {},
        shipping_address: {},
      },
    },
    ndrEscalationModal: {
      visible: false,
      orderid: "",
      shipmentid: "",
      uploading: false,
    },
    ndrRemarksModal: {
      visible: false,
      remarks: [],
    },
    viewBundleModal: {
      bundle: null,
    },
    provisional: false,
    allordernav: {
      openorderbtn: true,
      InProcessingbtn: false,
      ClosedOrdersbtn: false,
      allBtn: false,
    },
    closeordernav: {
      deliveredbtn: true,
      cancelbtn: false,
      donotfullfilbtn: false,
      allbtn3: false,
      rtobtn: false,
    },

    inprocessordernav: {
      to_be_shipedbtn: false,
      allBtn4: true,
      placebtn: false,
    },

    openordernav: {
      openbtn: true,
      calling: false,
      Verifiedbtn: false,
      onholdbtn: false,
      notlinkbtn: false,
      allbtn5: false,
    },

    riskScore: [],
    modalvforderid: "",
    cardRiskScore: undefined,
    ordershopifyid: "",
    multiplevforderid: [],
    bulkSelect: undefined,
    bulkCopy: undefined,
    apiPlaceResponse: {},
  };

  calenderRef = React.createRef();

  // Function used to call API once the action is selected on feedback notification
  feedBackHandler(actionTaken, oldUser = false) {
    if (actionTaken === "submit") {
      window.open(
        "https://apps.shopify.com/vfulfill-cod-dropshipping?surface_detail=vfulfill&surface_inter_position=1&surface_intra_position=1&surface_type=search",
        "_blank"
      );
    }

    notification.close("firstTimeUserFeedback");

    axios({
      url: process.env.REACT_APP_API_URL + "/store/feedback_form_action",
      method: "post",
      data: {
        action: actionTaken,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
        } else {
        }
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMsg: e.message,
          loading: false,
        });
      });
  }
  //bulk place visible or not
  togglebulkplaceaction() {
    this.setState({
      visibleBulkOrderPlace: !this.state.visibleBulkOrderPlace,
    });
  }
  //handle change in bulk place text fields
  bulkFieldplaceChange = (k, v) => {
    // if (!v) {

    //   return;
    // }
    this.setState({
      [k]: v.split("\n"),
    });
  };
  //function for reset the bulk order place
  resetModalBulkPlace = () => {
    this.setState({
      visibleBulkOrderPlace: false,
      bulkOrderPlace: [],
    });
  };
  bulkPlaceSubmit = () => {
    this.setState(
      {
        bulkplaceLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/orders/bulk_place_order",
          method: "post",
          data: {
            orders: [
              ...new Set(
                this.state.bulkOrderPlace
                  .filter((item) => item !== "")
                  .map((d) => String(d).trim())
              ),
            ],
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success) {
              message.success(res.data.msg);
              setTimeout(() => {
                let url = window.location.origin + "/downloads#2";
                window.location.href = url;
              }, 2000);

              this.setState(
                {
                  visibleBulkOrderPlace: false,
                  bulkOrderPlace: [],
                },
                () => {}
              );
            } else {
              message.error(res.data.msg || "error");
              setTimeout(() => {
                let url = window.location.origin + "/downloads#2";
                window.location.href = url;
              }, 2000);
            }
            // this.setState({
            //   bulkplaceLoading: false,
            // });
          })
          .catch((e) => {
            message.error(e.message || "Something went wrong");
            this.setState({
              bulkplaceLoading: false,
            });
          });
      }
    );
  };
  //bulk search visible or not
  togglebulksearchaction() {
    this.setState({
      visibleBulk: !this.state.visibleBulk,
    });
  }
  //function for reset the bulk search
  resetModal = (searchId = false) => {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          page: 1,
        },

        filters: {
          ...this.state.filters,
          bulkSearch: searchId
            ? this.state.filters.bulkSearch.filter((item) => item !== searchId)
            : [],
        },
      },
      () => this.loadData()
    );
  };
  //handle change in bulk search text fields
  bulkFieldChange = (k, v) => {
    if (!v) {
      this.resetModal();
      return;
    }
    this.setState({
      filters: {
        ...this.state.filters,
        [k]: v.split("\n"),
      },
    });
  };
  //handle search bulk  submit
  bulkSearchHandler() {
    this.setState(
      {
        filters: { ...this.state.filters, search: "" },
      },
      () => {
        if (this.state.filters.bulkSearch.length > 500) {
          message.error("The number of max search item should be 500");
          return;
        }

        this.setState(
          {
            filters: {
              ...this.state.filters,
              bulkSearch: [
                ...new Set(
                  this.state.filters.bulkSearch
                    .filter((item) => item !== "")
                    .map((d) => String(d).trim())
                ),
              ],
            },
          },
          () => this.loadData()
        );
      }
    );
  }

  getDefaultFilters() {
    return {
      startDate: this.state.filters.startDate,
      endDate: this.state.filters.endDate,
      dateType: "shopify",
      search: "",
      fulfillmentStatus: [],
      paymentStatus: [],
      vfOrderStatus: [],
      shipmentStatus: [],
      pagename: [],
    };
  }

  componentDidUpdate(pp) {
    if (pp.pageType !== this.props.pageType) {
      let url = this.props.pageType;
      if (url === "processingorders") {
        this.setState(
          {
            tabIsProcessing: true,
            tabIsClosedOrders: false,
            tabIsopen: false,
            tabIsallOrder: false,
            orderscount: [],
            inprocessordernav: {
              allBtn4: true,
            },
            filters: {
              ...this.state.filters,
              startDate: "",
              endDate: "",
            },
          },
          () =>
            this.changeFilter({
              vfOrderStatus: ["all"],
              search: "",
              fulfillmentStatus: [],
              paymentStatus: [],
              shipmentStatus: [],
              closeorderStatus: [],
              duplicate_orders: [],
              pagename: ["all"],
              startDate: "",
              endDate: "",
              mav_filters: {
                rto_risk: [],
                reason: [],
              },
            })
        );
      } else if (url === "closedOrders") {
        this.setState(
          {
            tabIsProcessing: false,
            tabIsClosedOrders: true,
            tabIsopen: false,
            tabIsallOrder: false,
            orderscount: [],

            closeordernav: { deliveredbtn: true },
            filters: {
              ...this.state.filters,
              startDate: "",
              endDate: "",
            },
          },
          () =>
            this.changeFilter({
              startDate: "",
              endDate: "",
              vfOrderStatus: [],
              search: "",
              fulfillmentStatus: [],
              paymentStatus: [],
              shipmentStatus: [],
              duplicate_orders: [],
              closeorderStatus: [],
              pagename: [],
              mav_filters: {
                rto_risk: [],
                reason: [],
              },
            })
        );
      } else if (url === "openorders") {
        this.setState(
          {
            tabIsProcessing: false,
            tabIsClosedOrders: false,
            tabIsopen: true,
            tabIsallOrder: false,
            orderscount: [],
            openordernav: { openbtn: true },
            filters: {
              ...this.state.filters,
              startDate: "",
              endDate: "",
            },
          },
          () =>
            this.changeFilter({
              ...this.state.filters,
              startDate: "",
              endDate: "",
              vfOrderStatus: [],
              search: "",
              fulfillmentStatus: [],
              duplicate_orders: [],
              paymentStatus: [],
              shipmentStatus: [],
              closeorderStatus: [],
              pagename: [],
              mav_filters: {
                rto_risk: [],
                reason: [],
              },
            })
        );
      } else if (url === "allorders") {
        this.setState(
          {
            tabIsProcessing: false,
            tabIsClosedOrders: false,
            tabIsopen: false,
            tabIsallOrder: true,
            orderscount: [],
            allordernav: { allBtn: true },
            filters: {
              ...this.state.filters,
              startDate: this.state.filters.startDate,
              endDate: this.state.filters.endDate,
            },
          },
          () =>
            this.changeFilter({
              vfOrderStatus: ["all"],
              startDate: "",
              endDate: "",
              search: "",
              fulfillmentStatus: [],
              duplicate_orders: [],
              paymentStatus: [],
              shipmentStatus: [],
              closeorderStatus: [],
              pagename: ["all"],
              mav_filters: {
                rto_risk: [],
                reason: [],
              },
            })
        );
      }

      // this.setState(
      //   {
      //     orders: [],
      //     selected: {},
      //     selectedConfirmed: {},
      //     total: 0,
      //     filters: this.getDefaultFilters(this.props.pageType),

      //     paging: {
      //       page: 1,
      //       per_page_count: 25,
      //     },
      //   },
      //   () => this.loadData()
      // );
      if (this.calenderRef.current.classList.contains("border-orange")) {
        this.calenderRef.current.classList.remove("border-orange");
      }
    }
  }

  childtop(statedata) {
    this.setState({
      allordernav: { ...statedata },
    });
  }

  closeordernavtoggle(statedata2) {
    this.setState({
      closeordernav: { ...statedata2 },
    });
  }

  inprocessnavtoggle(statedata3) {
    this.setState({
      inprocessordernav: { ...statedata3 },
    });
  }

  openordernavtoggle(statedata4) {
    this.setState({
      openordernav: { ...statedata4 },
    });
  }

  // componentDidMount() {
  //   this.loadData();

  //   let url = this.props.pageType;

  //   if (url === "processingorders") {
  //     this.setState({
  //       tabIsProcessing: true,
  //       tabIsClosedOrders: false,
  //       tabIsopen: false,
  //       tabIsallOrder: false,
  //       inprocessordernav: {
  //         placebtn: true,
  //       },
  //     });
  //   } else if (url === "closedOrders") {
  //     this.setState({
  //       tabIsProcessing: false,
  //       tabIsClosedOrders: true,

  //       tabIsallOrder: false,
  //       closeordernav: { deliveredbtn: true },
  //     });
  //   } else if (url === "openorders") {
  //     this.setState({
  //       tabIsProcessing: false,
  //       tabIsClosedOrders: false,
  //       tabIsopen: true,
  //       tabIsallOrder: false,
  //       openordernav: { openbtn: true },
  //     });
  //   } else if (url === "allorders") {
  //     this.setState({
  //       tabIsProcessing: false,
  //       tabIsClosedOrders: false,
  //       tabIsopen: false,
  //       tabIsallOrder: true,
  //       allordernav: { openorderbtn: true },
  //     });
  //   }
  // }

  componentDidMount() {
    const params = window.location.href.split("=")[1];
    let url = this.props.pageType;

    if (!!params) {
      if (url === "openorders") {
        let searchValue = params;
        this.setState(
          {
            filters: {
              ...this.state.filters,
              search: searchValue,
              pagename: ["all"],
              vfOrderStatus: ["all"],
              startDate: "",
              endDate: "",
              mav_filters: {
                rto_risk: [],
                reason: [],
              },
            },
            tabIsProcessing: false,
            tabIsClosedOrders: false,
            tabIsopen: true,
            tabIsallOrder: false,
            openordernav: {
              openbtn: false,
              Verifiedbtn: false,
              onholdbtn: false,
              notlinkbtn: false,
              allbtn5: true,
            },
            linkedNotifications: true,
          },
          () => this.loadData()
        );
      }

      if (url === "processingorders") {
        this.setState(
          {
            filters: {
              ...this.state.filters,
              search: params,
              pagename: ["all"],
              vfOrderStatus: ["all"],
              startDate: "",
              endDate: "",
              mav_filters: {
                rto_risk: [],
                reason: [],
              },
            },
            tabIsProcessing: true,
            tabIsClosedOrders: false,
            tabIsopen: false,
            tabIsallOrder: false,
            inprocessordernav: {
              to_be_shipedbtn: false,
              allBtn4: true,
              placebtn: false,
            },
          },
          () => this.loadData()
        );
      }
      if (url === "closedOrders") {
        this.setState(
          {
            filters: {
              ...this.state.filters,
              search: params,
              pagename: ["all"],
              vfOrderStatus: ["all"],
              startDate: this.state.filters.startDate,
              endDate: this.state.filters.endDate,
              mav_filters: {
                rto_risk: [],
                reason: [],
              },
            },
            tabIsProcessing: false,
            tabIsClosedOrders: true,
            tabIsopen: false,
            tabIsallOrder: false,
            tabIsProcessing: false,

            closeordernav: {
              deliveredbtn: false,
              cancelbtn: false,
              donotfullfilbtn: false,
              allbtn3: true,
              rtobtn: false,
            },
          },
          () => this.loadData()
        );
      }

      if (url === "allorders") {
        this.setState(
          {
            filters: {
              ...this.state.filters,
              search: params,
              pagename: ["all"],
              vfOrderStatus: ["all"],
              startDate: "",
              endDate: "",
              mav_filters: {
                rto_risk: [],
                reason: [],
              },
            },
            tabIsProcessing: false,
            tabIsClosedOrders: false,
            tabIsopen: false,
            tabIsallOrder: true,

            allordernav: {
              openorderbtn: false,
              InProcessingbtn: false,
              ClosedOrdersbtn: false,
              allBtn: true,
            },
          },
          () => this.loadData()
        );
      }
    } else {
      let url = this.props.pageType;

      // When is in-processing tab is clicked/active, state set to show navigation
      if (url === "processingorders") {
        this.setState(
          {
            tabIsProcessing: true,
            tabIsClosedOrders: false,
            tabIsopen: false,
            tabIsallOrder: false,
            inprocessordernav: {
              allBtn4: true,
            },
          },
          () =>
            this.changeFilter({
              vfOrderStatus: ["all"],
              startDate: "",
              endDate: "",
              search: "",
              fulfillmentStatus: [],
              paymentStatus: [],
              duplicate_orders: [],
              shipmentStatus: [],
              closeorderStatus: [],
              pagename: ["all"],
              mav_filters: {
                rto_risk: [],
                reason: [],
              },
            })
        );

        // When is closed tab is clicked/active, state set to show navigation
      } else if (url === "closedOrders") {
        this.setState(
          {
            tabIsProcessing: false,
            tabIsClosedOrders: true,
            tabIsopen: false,
            tabIsallOrder: false,
            closeordernav: { deliveredbtn: true },
            filters: {
              ...this.state.filters,
              startDate: "",
              endDate: "",
            },
          },
          () => this.loadData()
        );
        // When to be placed tab is clicked/active, state set to show navigation
      } else if (url === "openorders") {
        this.setState(
          {
            tabIsProcessing: false,
            tabIsClosedOrders: false,
            tabIsopen: true,
            tabIsallOrder: false,
            openordernav: { openbtn: true },
            filters: {
              ...this.state.filters,
              startDate: "",
              endDate: "",
            },
          },
          () => this.loadData()
        );
        // When all orders tab is cliked/active, state set to show navigation
      } else if (url === "allorders") {
        this.setState(
          {
            tabIsProcessing: false,
            tabIsClosedOrders: false,
            tabIsopen: false,
            tabIsallOrder: true,
            allordernav: { allBtn: true },
          },
          () =>
            this.changeFilter({
              vfOrderStatus: ["all"],
              startDate: "",
              endDate: "",
              search: "",
              fulfillmentStatus: [],
              paymentStatus: [],
              duplicate_orders: [],
              shipmentStatus: [],
              closeorderStatus: [],
              pagename: ["all"],
              mav_filters: {
                rto_risk: [],
                reason: [],
              },
            })
        );
      }
    }
  }

  // Function is called on page load to fetch order data from API
  loadData() {
    this.setState(
      {
        loading: true,
        orders: [],
        selected: {},
        selectedConfirmed: {},
        selectAllOrders: false,
        visibleBulk: false,
      },
      () => {
        if (this.cancelToken) {
          this.cancelToken.cancel();
        }
        this.cancelToken = axios.CancelToken.source();
        axios({
          url: process.env.REACT_APP_API_URL + "/orders/my_orders",
          method: "post",
          cancelToken: this.cancelToken.token,
          data: {
            type: this.props.pageType,
            storeid: this.props.match.params.storeid,
            page: this.state.paging.page,
            per_page_count: this.state.paging.per_page_count,
            filters: this.state.filters,
            sort: this.state.sort,
          },
          withCredentials: true,
        })
          .then((res) => {
            // Conditional check if the feedback notification is for old user or new user
            if (res.data.success === 1) {
              if (
                res.data.show_feedback_form === 1 &&
                this.props.pageType === "openorders" &&
                this.state.filters.pagename.length === 0
              ) {
                this.oldUserFeedback(res.data.show_feedback_form_name);
              }

              this.setState({
                orders: res.data.orders,
                orderscount: res.data.order_counts,
                tags: res.data.tags,
                store_settings: res.data.store_settings,
                currencies: res.data.currencies,
                total: res.data.total,
                showMavFilters: res.data.show_mav_filters,
                loading: false,
                filters: {
                  ...this.state.filters,
                  // bulkSearch: [], //bulk search value cointainer
                },
                error: false,
                errorMsg: "",
              });
            } else {
              if (res.data.msg === "Invalid Filter Combination") {
                message.info(res.data.msg);
                this.setState({
                  orders: [],
                  loading: false,
                });
              } else {
                this.setState({
                  error: true,
                  errorMsg: res.data.msg,
                  loading: false,
                });
              }
            }
          })
          .catch((e) => {
            // this.setState({
            //   error: true,
            //   errorMsg: e.message,
            //   loading: true,
            // });

            if (!!e.request) {
              this.setState({
                error: true,
                errorCode: e.request.status,
                errorMsg: e.request.statusText,
                loading: false,
              });
            } else {
              this.setState({
                error: false,
                errorMsg: e.message,
                loading: true,
              });
            }
          });
      }
    );
    // return;

    if (
      !!this.state.filters &&
      !!this.state.filters.shipmentStatus &&
      !!this.state.filters.shipmentStatus.length
    ) {
    } else {
      this.setState({ allBtn: true, toBeShippedBtn: false, shippedBtn: false });
      this.setState({
        allBtn2: true,
        toBeShippedBtn2: false,
        shippedBtn2: false,
      });
    }
  }

  goToPage(page) {
    this.setState(
      {
        paging: { ...this.state.paging, page },
      },
      () => {
        this.loadData();
      }
    );
  }

  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        paging: { ...this.state.paging, per_page_count: pageSize },
      },
      () => this.loadData()
    );
  }

  changeFilter(kvpairs) {
    this.setState(
      {
        filters: { ...this.state.filters, ...kvpairs },
        paging: { ...this.state.paging, page: 1 },
      },
      () => this.loadData()
    );
  }

  shopifyOrderDateFilter(kvpairs) {
    this.setState(
      {
        filters: { ...this.state.filters, ...kvpairs },
        paging: { ...this.state.paging },
      },
      () => this.loadData()
    );
  }

  rangePickerChange(filters) {
    let monthDifference = moment(new Date(filters.endDate)).diff(
      new Date(filters.startDate),
      "months",
      true
    );

    if (monthDifference <= 6 || !monthDifference) {
      this.setState(
        {
          isDateRangeValid: true,
          filters: { ...this.state.filters, ...filters },
          paging: { ...this.state.paging, page: 1 },
        },
        () => this.loadData()
      );

      if (this.calenderRef.current.classList.contains("border-orange")) {
        this.calenderRef.current.classList.remove("border-orange");
      }
    } else {
      this.setState(
        {
          isDateRangeValid: false,
          filters: { ...this.state.filters, ...filters },
          paging: { ...this.state.paging, page: 1 },
        },
        () => this.loadData()
      );
    }
  }

  toggleModal = (modalVisibleField, selectedOrderId) => {
    this.setState(
      {
        ...this.state,
        [modalVisibleField]: !this.state[modalVisibleField],
      },
      () => {
        if (typeof selectedOrderId === "string" && selectedOrderId.length) {
          this.setState({
            ...this.state,
            selectedOrderId,
          });
        }
      }
    );
  };

  addNote = (value) => {
    const { note, selectedOption } = value;
    const selecttype = selectedOption[0];
    if (typeof note === "string" && note.length > 0) {
      this.setState(
        {
          notesModal: {
            ...this.state.notesModal,
            saving: true,
          },
        },
        () => {
          axios({
            url: process.env.REACT_APP_API_URL + "/orders/add_note",
            method: "post",
            data: {
              storeid: this.props.match.params.storeid,
              orderid: this.state.notesModal.id,
              note,
              selecttype,
            },
            withCredentials: true,
          })
            .then((res) => {
              this.setState({
                notesModal: {
                  ...this.state.notesModal,
                  saving: false,
                },
              });
              if (res.data.success === 1) {
                message.success("Note added successfully.");
                if (this.state.notesModal.cb) {
                  this.state.notesModal.cb(res.data.note);
                }
                this.toggleNotesModal();
              } else {
                message.error("There was an error adding the note.");
              }
            })
            .catch((e) => {
              this.setState({
                notesModal: {
                  ...this.state.notesModal,
                  saving: false,
                },
              });
              message.error(e.message);
            });
        }
      );
    } else {
      message.error("Please enter a note to add.");
    }
  };

  onSelectChange(id, items, cb) {
    this.setState(
      (state) => {
        let selected = {
          ...state.selected,
          [id]: items,
        };
        if (Object.keys(items).length === 0) {
          delete selected[id];
        }
        return {
          selected,
        };
      },
      () => {
        if (cb) cb();
      }
    );
  }

  sendMessage(details) {
    let { subject, message, senderName, replyTo } = details;
    subject = typeof subject === "string" && subject.length > 0 ? subject : "";
    message =
      typeof message === "string" && String(message).length > 0
        ? String(message)
        : "";
    senderName =
      typeof senderName === "string" && String(senderName).length > 0
        ? String(senderName)
        : "";
    replyTo =
      typeof replyTo === "string" && String(replyTo).length > 0
        ? String(replyTo)
        : "";
    if (
      subject.length &&
      String(message).length &&
      senderName.length &&
      replyTo.length
    ) {
      this.setState(
        {
          sendMsgModal: {
            ...this.state.sendMsgModal,
            saving: true,
            error: undefined,
          },
        },
        () => {
          axios({
            url: process.env.REACT_APP_API_URL + "/orders/send_message",
            method: "post",
            data: {
              storeid: this.props.match.params.storeid,
              orderid: this.state.sendMsgModal.id,
              subject,
              message,
              senderName,
              replyTo,
            },
            withCredentials: true,
          })
            .then((res) => {
              if (res.data.success === 1) {
                this.setState(
                  {
                    sendMsgModal: {
                      ...this.state.sendMsgModal,
                      saving: false,
                      error: undefined,
                    },
                  },
                  () => {
                    this.toggleSendMsgModal();
                    message.success("Message sent successfully!");
                  }
                );
              } else {
                this.setState({
                  sendMsgModal: {
                    ...this.state.sendMsgModal,
                    saving: false,
                    error: res.data.msg,
                  },
                });
              }
            })
            .catch((e) => {
              this.setState({
                sendMsgModal: {
                  ...this.state.sendMsgModal,
                  saving: false,
                  error: e.message,
                },
              });
            });
        }
      );
    } else {
      this.setState({
        sendMsgModal: {
          ...this.state.sendMsgModal,
          error: "Please enter all fields!",
        },
      });
    }
  }

  selectOrders = (type) => {
    if (type === "all") {
      this.setState({
        selected: this.state.orders
          .filter(
            (order) =>
              !(
                order.order_error === "productnotconnected" ||
                order.order_status === "ordered"
              )
          )
          .map((o) => o._id["$oid"]),
      });
    } else if (type === "none") {
      this.setState({
        selected: {},
        selectedConfirmed: {},
      });
    } else if (type === "to_order") {
      this.setState({
        selected: this.state.orders
          .filter((order) => order.order_status === "to_order")
          .map((o) => o._id["$oid"]),
      });
    }
  };

  setShopifyOrderCancel(checked) {
    this.setState({
      confirmationModal: {
        ...this.state.confirmationModal,
        cancelOnShopify: checked,
      },
    });
  }

  // When order is placed and store geo is not set then this method is called to redirect the user to set geo page
  countDown() {
    let count = this.state.count;

    if (count > 0) {
      this.setState({ count: this.state.count - 1 }, () =>
        setTimeout(() => this.countDown(), 1000)
      );
    } else {
      window.location.href = "/manage-stores";
    }
  }

  moveToCallingHandler() {
    this.setState(
      {
        confirmationModal: {
          ...this.state.confirmationModal,
          saving: true,
        },
      },
      () => {
        window.onbeforeunload = function(event) {
          return "";
        };

        axios({
          // todo
          url: process.env.REACT_APP_API_URL + "/orders/move_to_calling",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            orderid: this.state.orders
              .filter((o) =>
                this.state.multiplevforderid.includes(o.order_vfid)
              )
              .map((d) => d._id["$oid"]),
          },
          withCredentials: true,
        })
          .then((res) => {
            window.onbeforeunload = undefined;
            return res;
          })
          .then((res) => {
            let products_id = !!this.state.bulkSelect
              ? Object.keys(this.state.bulkCopy)
              : Object.keys(this.state.selected);
            let selectedOrders = [];
            let selectedShopify = [];

            this.state.orders.forEach((s) => {
              if (products_id.includes(s.order_shopify.name)) {
                selectedOrders.push(s.order_vfid);
                selectedShopify.push(s);
              }
            });

            let shopify_id = selectedShopify[0].order_shopify.id;
            let order_id = selectedOrders[0];

            let order_length = selectedOrders.length;

            if (res.data.success === 1) {
              // for first time user feedback

              message.success(res.data.msg);

              return this.setState(
                {
                  confirmationModal: {
                    visible: false,
                    id: undefined,
                    type: "move_to_calling",
                    saving: false,
                    cancelReason: "",
                    shippingCost: { orders: {} },
                    snoozeDate: "",
                  },
                },
                () => this.loadData()
              );
              if (
                this.state.confirmationModal.type === "verifyorder" &&
                res.data.show_feedback_form === 1
              ) {
                this.firstTimeUserFeedback(order_id);
              }

              // action notification
              if (this.state.confirmationModal.type === "verifyorder") {
                ActionNotification({
                  notificationType: "verifyorder",
                  productLength: order_length,
                  shopifyId: shopify_id,
                  orderId: order_id,
                  showShopifyId: products_id[0],
                  shopifyStore: this.props.store.store_shopifyadmin,
                  selectedOrders: selectedOrders,
                });
              } else if (this.state.confirmationModal.type === "verify") {
                ActionNotification({
                  notificationType: "verify",
                  productLength: order_length,
                  shopifyId: shopify_id,
                  orderId: order_id,
                  showShopifyId: products_id[0],
                  shopifyStore: this.props.store.store_shopifyadmin,
                  selectedOrders: selectedOrders,
                });
              } else if (this.state.confirmationModal.type === "hold") {
                ActionNotification({
                  notificationType: "hold",
                  productLength: order_length,
                  shopifyId: shopify_id,
                  orderId: order_id,
                  showShopifyId: products_id[0],
                  shopifyStore: this.props.store.store_shopifyadmin,
                  selectedOrders: selectedOrders,
                });
              } else if (this.state.confirmationModal.type === "nofulfill") {
                ActionNotification({
                  notificationType: "nofulfill",
                  productLength: order_length,
                  shopifyId: shopify_id,
                  orderId: order_id,
                  showShopifyId: products_id[0],
                  shopifyStore: this.props.store.store_shopifyadmin,
                  selectedOrders: selectedOrders,
                });
              } else if (this.state.confirmationModal.type === "cancel") {
                ActionNotification({
                  notificationType: "cancel",
                  productLength: order_length,
                  shopifyId: shopify_id,
                  orderId: order_id,
                  showShopifyId: products_id[0],
                  shopifyStore: this.props.store.store_shopifyadmin,
                  selectedOrders: selectedOrders,
                });
              } else {
                ActionNotification({
                  notificationType: "unhold",
                  productLength: order_length,
                  shopifyId: shopify_id,
                  orderId: order_id,
                  showShopifyId: products_id[0],
                  shopifyStore: this.props.store.store_shopifyadmin,
                  selectedOrders: selectedOrders,
                });
              }

              this.setState(
                {
                  confirmationModal: {
                    visible: false,
                    id: undefined,
                    type: "verify",
                    saving: false,
                    cancelReason: "",
                    shippingCost: { orders: {} },
                    snoozeDate: "",
                  },
                },
                () => this.loadData()
              );
            } else {
              if (
                res.data.msg ===
                "Please set your STORE GEO before placing orders."
              ) {
                message.loading(res.data.msg + " Redirecting in 3 seconds", [
                  4,
                ]);

                this.setState(
                  {
                    confirmationModal: {
                      ...this.state.confirmationModal,
                      saving: false,
                    },
                  },
                  () => this.countDown()
                );
              } else {
                this.setState({
                  confirmationModal: {
                    ...this.state.confirmationModal,
                    saving: false,
                  },
                });
                message.error(res.data.msg);
              }
            }
          })
          .catch((e) => {
            this.setState({
              confirmationModal: {
                ...this.state.confirmationModal,
                saving: false,
              },
            });
            message.error(e.message);
          });
      }
    );
  }

  prepareDataForVerification() {
    let data;

    let customBulkCopy = cloneDeep(this.state.bulkCopy);
    let selectedConfirm = cloneDeep(this.state.selectedConfirmed);

    for (const key in customBulkCopy) {
      const ogDataItem = selectedConfirm[key];
      if (ogDataItem && Object.keys(ogDataItem).length > 0) {
        const retailAllow = ogDataItem[Object.keys(ogDataItem)[0]].retail_allow;
        for (const innerKey in customBulkCopy[key]) {
          customBulkCopy[key][innerKey].retail_allow = retailAllow
            ? true
            : false;
        }
      }
    }

    data = !!this.state.bulkCopy ? customBulkCopy : selectedConfirm;

    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        for (let innerKey in data[key]) {
          if (
            data[key][innerKey].hasOwnProperty("retail_allow") &&
            !data[key][innerKey]["retail_allow"]
          ) {
            delete data[key];
            break;
          }
        }
      }
    }

    return data;
  }

  confirmSelected(e) {
    let data = this.prepareDataForVerification();

    if (e === "verifyorder" && !Object.entries(data).length) {
      return message.info("Please Select Atleast 1 Order");
    }

    this.setState(
      {
        confirmationModal: {
          ...this.state.confirmationModal,
          saving: true,
        },
      },
      () => {
        window.onbeforeunload = function(event) {
          return "";
        };

        axios({
          // todo
          url: process.env.REACT_APP_API_URL + "/orders/confirm_order",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            type:
              e === undefined ? "unhold" : this.state.confirmationModal.type,
            cancelReason: this.state.confirmationModal.cancelReason,
            refund: this.state.confirmationModal.refund,
            refundAmount: this.state.confirmationModal.refundAmount,
            snoozeDate:
              e === undefined ? "" : this.state.confirmationModal.snoozeDate,
            cancelOnShopify: this.state.confirmationModal.cancelOnShopify,
            items: e === "verifyorder" ? data : this.state.selectedConfirmed,
            id: this.state.confirmationModal.id,
          },
          withCredentials: true,
        })
          .then((res) => {
            window.onbeforeunload = undefined;
            return res;
          })
          .then((res) => {
            let products_id = !!this.state.bulkSelect
              ? Object.keys(this.state.bulkCopy)
              : Object.keys(this.state.selected);
            let selectedOrders = [];
            let selectedShopify = [];

            this.state.orders.forEach((s) => {
              if (products_id.includes(s.order_shopify.name)) {
                selectedOrders.push(s.order_vfid);
                selectedShopify.push(s);
              }
            });

            let shopify_id = selectedShopify[0].order_shopify.id;
            let order_id = selectedOrders[0];

            let order_length = selectedOrders.length;

            if (res.data.success === 1) {
              // for first time user feedback
              if (
                this.state.confirmationModal.type === "verifyorder" &&
                res.data.show_feedback_form === 1
              ) {
                this.firstTimeUserFeedback(order_id);
              }

              // action notification
              if (this.state.confirmationModal.type === "verifyorder") {
                ActionNotification({
                  notificationType: "verifyorder",
                  productLength: order_length,
                  shopifyId: shopify_id,
                  orderId: order_id,
                  showShopifyId: products_id[0],
                  shopifyStore: this.props.store.store_shopifyadmin,
                  selectedOrders: selectedOrders,
                });
              } else if (this.state.confirmationModal.type === "verify") {
                ActionNotification({
                  notificationType: "verify",
                  productLength: order_length,
                  shopifyId: shopify_id,
                  orderId: order_id,
                  showShopifyId: products_id[0],
                  shopifyStore: this.props.store.store_shopifyadmin,
                  selectedOrders: selectedOrders,
                });
              } else if (this.state.confirmationModal.type === "hold") {
                ActionNotification({
                  notificationType: "hold",
                  productLength: order_length,
                  shopifyId: shopify_id,
                  orderId: order_id,
                  showShopifyId: products_id[0],
                  shopifyStore: this.props.store.store_shopifyadmin,
                  selectedOrders: selectedOrders,
                });
              } else if (this.state.confirmationModal.type === "nofulfill") {
                ActionNotification({
                  notificationType: "nofulfill",
                  productLength: order_length,
                  shopifyId: shopify_id,
                  orderId: order_id,
                  showShopifyId: products_id[0],
                  shopifyStore: this.props.store.store_shopifyadmin,
                  selectedOrders: selectedOrders,
                });
              } else if (this.state.confirmationModal.type === "cancel") {
                ActionNotification({
                  notificationType: "cancel",
                  productLength: order_length,
                  shopifyId: shopify_id,
                  orderId: order_id,
                  showShopifyId: products_id[0],
                  shopifyStore: this.props.store.store_shopifyadmin,
                  selectedOrders: selectedOrders,
                });
              } else {
                ActionNotification({
                  notificationType: "unhold",
                  productLength: order_length,
                  shopifyId: shopify_id,
                  orderId: order_id,
                  showShopifyId: products_id[0],
                  shopifyStore: this.props.store.store_shopifyadmin,
                  selectedOrders: selectedOrders,
                });
              }

              this.setState(
                {
                  confirmationModal: {
                    visible: false,
                    id: undefined,
                    type: "verify",
                    saving: false,
                    cancelReason: "",
                    shippingCost: { orders: {} },
                    snoozeDate: "",
                  },
                },
                () => this.loadData()
              );
            } else {
              if (
                res.data.msg ===
                "Please set your STORE GEO before placing orders."
              ) {
                message.loading(res.data.msg + " Redirecting in 3 seconds", [
                  4,
                ]);

                this.setState(
                  {
                    confirmationModal: {
                      ...this.state.confirmationModal,
                      saving: false,
                    },
                  },
                  () => this.countDown()
                );
              } else {
                this.setState({
                  confirmationModal: {
                    ...this.state.confirmationModal,
                    saving: false,
                  },
                });
                message.error(res.data.msg);
              }
            }
          })
          .catch((e) => {
            this.setState({
              confirmationModal: {
                ...this.state.confirmationModal,
                saving: false,
              },
            });
            message.error(e.message);
          });
      }
    );
  }

  toggleNotesModal(id = undefined, cb = undefined) {
    this.setState({
      notesModal: {
        visible: !this.state.notesModal.visible,
        id,
        cb,
      },
    });
  }

  toggleSendMsgModal(id = undefined) {
    this.setState({
      sendMsgModal: {
        visible: !this.state.sendMsgModal.visible,
        id,
      },
    });
  }

  updateCustomerShippingAddress(oid, shipping_address, customer_details) {
    this.setState((state) => ({
      orders: state.orders.map((o) => {
        if (o._id["$oid"] === oid) {
          return {
            ...o,
            shipping_address,
            customer_details,
          };
        } else {
          return o;
        }
      }),
    }));
  }

  toggleConfirmationModal(
    type = undefined,
    id = undefined,
    modalOrder = null,
    bulk = false
  ) {
    this.setState({ bulkSelect: !!bulk ? true : false }, () => {
      let missingPhone = [];
      let isCod = false;
      let selectedmultiplvforderid = [];
      let risk = [];
      // let selectedConfirmed = { ...this.state.selectedConfirmed };
      if (!this.state.confirmationModal.visible) {
        Object.keys(this.state.selected).forEach((oid) => {
          const o = this.state.orders.find(
            (_o) => _o.order_shopify.name === oid
          );

          if (
            !/^[A-Za-z.\s]*$/.test(o.shipping_address.name) ||
            o.shipping_address === undefined ||
            !o.shipping_address.address1 ||
            o.shipping_address.address1.length > 200 ||
            (!!o.shipping_address.address2 &&
            !!o.shipping_address.address2.length > 200
              ? true
              : false) ||
            !/^[~`!@#$% ^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/.test(
              o.shipping_address.address1
            ) ||
            !o.shipping_address.city ||
            !o.shipping_address.zip ||
            !o.shipping_address.province ||
            !o.shipping_address.country ||
            o.shipping_address.phone === null ||
            o.shipping_address.phone === undefined ||
            o.shipping_address.phone.trim().length === 0 ||
            o.shipping_address.phone.trim().length < 10 ||
            o.shipping_address.phone.trim().length > 13 ||
            !/^[0-9+\s]+$/.test(o.shipping_address.phone)
          ) {
            if (!!/^[A-Za-z.\s]*$/.test(o.shipping_address.name)) {
              if (
                !/^[~`!@#$% ^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/.test(
                  o.shipping_address.name
                )
              ) {
                missingPhone.push(o.order_shopify.name);
              }
            }

            if (
              !!o.shipping_address.address2 &&
              !!o.shipping_address.address2.length
            ) {
              if (
                !/^[~`!@#$% ^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/.test(
                  o.shipping_address.address2
                )
              ) {
                missingPhone.push(o.order_shopify.name);
              }
            }

            if (o.customer_details.email && o.customer_details.email.length) {
              if (
                !/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
                  o.customer_details.email
                )
              ) {
                missingPhone.push(o.order_shopify.name);
              }
            }

            missingPhone.push(o.order_shopify.name);
          }
          if (o.is_cod === 1) {
            isCod = true;
          }

          selectedmultiplvforderid.push(o.order_vfid);
          risk.push(o.score);

          if (modalOrder !== null) {
            this.setState({
              modalvforderid: modalOrder.order_vfid,
              ordershopifyid: modalOrder.order_shopify.id,
              multiplevforderid: selectedmultiplvforderid,
              riskScore: risk,
              cardRiskScore: o.score,
            });
          } else {
            this.setState({
              modalvforderid: o.order_vfid,
              ordershopifyid: o.order_shopify.id,
              multiplevforderid: selectedmultiplvforderid,
              riskScore: risk,
              cardRiskScore: o.score,
            });
          }
        });
      }

      if (missingPhone.length) {
        Modal.error({
          title: "Missing Data",
          content: (
            <div>
              Please check whether the <b>Address, Phone Number and Email</b>{" "}
              are correct for following orders:
              {missingPhone.map((p) => (
                <div>{p}</div>
              ))}
            </div>
          ),
        });
        return;
      }
      let ordersToDisplay = [];

      if (id && this.state.selected[id]) {
        ordersToDisplay = Object.values(this.state.selected[id]).map((li) => ({
          ...li,
          orderid: id,
        }));
      } else {
        Object.keys(this.state.selected).forEach((orderid) => {
          ordersToDisplay.push(
            ...Object.values(this.state.selected[orderid]).map((li) => ({
              ...li,
              orderid,
            }))
          );
        });
      }
      let refundAmount = ordersToDisplay.reduce(
        (acc, c) => acc + c.item_total_sold_price.amount * c.quantity,
        0
      );
      this.setState(
        {
          confirmationModal: {
            id,
            refund: this.state.confirmationModal.refund,
            refundAmount,
            shippingLoader:
              type === "verifyorder" || type === "verify" ? true : false,
            shippingCost: { orders: {} },
            visible: !this.state.confirmationModal.visible,
            // selectedConfirmed: selectedConfirmed,
            type,
            isCod,
          },
          selectedConfirmed: cloneDeep(this.state.selected),
          bulkCopy: !!bulk ? cloneDeep(this.state.selected) : null,
        },
        () => {
          if (
            this.state.confirmationModal.visible &&
            (type === "verify" || type === "verifyorder")
          ) {
            axios({
              url: process.env.REACT_APP_API_URL + "/orders/get_shipping_cost",
              method: "post",
              data: {
                items: ordersToDisplay.map((o) => ({
                  orderid: o._id,
                  id: o["lineitem_id"],
                  quantity: o["quantity"],
                })),
                storeid: this.props.match.params.storeid,
              },
              withCredentials: true,
            })
              .then((res) => {
                let selectedConfirmed = cloneDeep(this.state.selected);
                Object.keys(selectedConfirmed).forEach((soid) => {
                  Object.keys(selectedConfirmed[soid]).forEach((liid) => {
                    res.data.items.forEach((i) => {
                      if (selectedConfirmed[soid][liid]["_id"] === i.orderid) {
                        if (i.liid.indexOf("_inventory") !== -1) {
                          selectedConfirmed[soid][i.liid] = {
                            ...selectedConfirmed[soid][
                              i.liid.split("_inventory")[0]
                            ],
                          };
                          selectedConfirmed[soid][i.liid]["inventory"] = true;
                          selectedConfirmed[soid][i.liid]["orderQty"] =
                            i.quantity;
                        }
                        if (i.liid.indexOf("_csr") !== -1) {
                          selectedConfirmed[soid][i.liid] = {
                            ...selectedConfirmed[soid][i.liid.split("_csr")[0]],
                          };
                          selectedConfirmed[soid][i.liid][
                            "csrinventory"
                          ] = true;
                          selectedConfirmed[soid][i.liid]["orderQty"] =
                            i.quantity;
                        }
                        if (
                          selectedConfirmed[soid][liid]["lineitem_id"] ===
                          i.liid
                        ) {
                          selectedConfirmed[soid][liid]["item_mrp"] =
                            i.item_mrp;

                          selectedConfirmed[soid][liid]["retail_allow"] =
                            i.retail_allow;
                          selectedConfirmed[soid][liid]["orderQty"] =
                            i.quantity;
                        }
                      }
                    });
                  });
                });
                const keysToDelete = [];
                Object.keys(selectedConfirmed).forEach((soid) => {
                  Object.keys(selectedConfirmed[soid]).forEach((liid) => {
                    if (
                      !res.data.items.find(
                        (i) =>
                          selectedConfirmed[soid][liid]["_id"] === i.orderid &&
                          liid === i.liid
                      )
                    ) {
                      keysToDelete.push({
                        soid,
                        liid,
                      });
                    }
                  });
                });
                keysToDelete.forEach(
                  (k) => delete selectedConfirmed[k.soid][k.liid]
                );
                this.setState({
                  confirmationModal: {
                    ...this.state.confirmationModal,
                    shippingLoader: false,
                    shippingCost: res.data,
                  },
                  selectedConfirmed,

                  apiPlaceResponse: res.data,
                });
              })
              .catch((e) => {
                message.error(e.message);
              });
          }
        }
      );
    });
  }

  onChangeConfirmationModalField(key, val) {
    this.setState({
      confirmationModal: {
        ...this.state.confirmationModal,
        [key]: val,
      },
    });
  }

  onChnagenotfulfill(key, val) {
    this.setState({
      confirmationModal: {
        ...this.state.confirmationModal,
        [key]: val,
      },
    });
  }

  // This function used to open link modal
  toggleLinkModal(id = null, productData = {}) {
    this.setState({
      orderLinkProductModal: {
        ...this.state.orderLinkProductModal,
        id,
        productData,
        importListModalVisible: !this.state.orderLinkProductModal
          .importListModalVisible,
      },
    });
  }

  //  This function used to open bundle modal
  toggleBundleLinkModal(id = null, productData = {}) {
    this.setState({
      orderBundleProductModal: {
        ...this.state.orderBundleProductModal,
        id,
        productData,
        bundleModalVisible: !this.state.orderBundleProductModal
          .bundleModalVisible,
      },
    });
  }

  toggleNdrEscalationModal(orderid = undefined, shipmentid = undefined) {
    this.setState({
      ndrEscalationModal: {
        visible: !this.state.ndrEscalationModal.visible,
        orderid,
        shipmentid,
      },
    });
  }

  splitUsingImportList(aliid, orderway) {
    if (aliid === null) {
      message.error("Select a product");
    } else {
      this.setState({ modalLoading: true });

      axios({
        url:
          process.env.REACT_APP_API_URL + "/products/link_product_import_list",
        method: "post",
        data: {
          storeid: this.props.match.params.storeid,
          product_mongoid: this.state.orderLinkProductModal.id,
          product_aliid: aliid,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.setState({ modalLoading: false });

            this.props.history.push(
              `/${this.props.match.params.storeid}/variants/${aliid}/${this.state.orderLinkProductModal.id}`
            );
          } else {
            this.setState({ modalLoading: false });
            message.error(res.data.msg);
          }
        })
        .catch((e) => {
          this.setState({ modalLoading: false });
          message.error(e.message);
        });
    }
  }

  toggleNDRModal(
    awb = "",
    shipment_vfid = "",
    address = { customer_details: {}, shipping_address: {} }
  ) {
    this.setState((state) => ({
      ndrModal: {
        ...state.ndrModal,
        visible: !state.ndrModal.visible,
        awb,
        shipment_vfid,
        address,
      },
    }));
  }

  toggleNDRRemarksModal(remarks = []) {
    this.setState((state) => ({
      ndrRemarksModal: {
        ...state.ndrRemarksModal,
        visible: !state.ndrRemarksModal.visible,
        remarks,
      },
    }));
  }

  confirmNdrEscalationModal(file) {
    const formData = new FormData();
    formData.append("file", file[0]);
    formData.append("storeid", this.props.match.params.storeid);
    formData.append("orderid", this.state.ndrEscalationModal.orderid);
    formData.append("shipmentid", this.state.ndrEscalationModal.shipmentid);

    this.setState(
      {
        ndrEscalationModal: {
          ...this.state.ndrEscalationModal,
          uploading: true,
        },
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/orders/ndr_escalation",
          method: "post",
          data: formData,
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState((state) => ({
                ndrEscalationModal: {
                  ...this.state.ndrEscalationModal,
                  visible: !state.ndrEscalationModal.visible,
                  uploading: false,
                },
              }));
              message.success("Updated Successfully!");
            } else {
              this.setState({
                ndrEscalationModal: {
                  ...this.state.ndrEscalationModal,
                  uploading: false,
                },
              });
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              ndrEscalationModal: {
                ...this.state.ndrEscalationModal,
                uploading: false,
              },
            });
            message.error(e.message);
          });
      }
    );
  }

  onNDRReattemptSuccess() {
    this.loadData();
  }

  markShipmentRTO(oid, sid) {
    confirm({
      title: "Are you sure?",
      content: "Are you sure you want to mark this order as RTO?",
      onOk: () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/orders/mark_shipment_rto",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            orderid: oid,
            shipmentid: sid,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.loadData();
              message.success("Update Successfully!");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
          });
      },
      onCancel: () => {},
    });
  }

  // function for using sorting
  sorterFunction(sort) {
    this.setState(
      {
        sort: sort,

        paging: { ...this.state.paging },
      },
      () => this.loadData()
    );
  }

  toggleViewBundleModal(bundle = null) {
    this.setState({
      viewBundleModal: {
        bundle,
      },
    });
  }

  changeHandler(kvpairs) {
    this.setState({
      filters: { ...this.state.filters, ...kvpairs },
    });
  }

  // firstTimeUserFeedback
  firstTimeUserFeedback(name) {
    notification.open({
      bottom: "5%",

      className: "custom-feedback",
      placement: "bottomLeft",
      duration: 6,
      key: "firstTimeUserFeedback",
      style: { border: "2px solid #2A9C27" },
      description: (
        <div class="feedback">
          <div class="svg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
            >
              <circle
                id="Ellipse_45"
                data-name="Ellipse 45"
                cx="24"
                cy="24"
                r="24"
                fill="#2a9c27"
              />
              <g
                id="Group_3904"
                data-name="Group 3904"
                transform="translate(-1114 26)"
              >
                <g
                  id="Rectangle_2671"
                  data-name="Rectangle 2671"
                  transform="translate(1126 -12)"
                  fill="none"
                  stroke="#fff"
                  stroke-width="1.2"
                >
                  <rect width="24" height="5" stroke="none" />
                  <rect x="0.6" y="0.6" width="22.8" height="3.8" fill="none" />
                </g>
                <g
                  id="Rectangle_2672"
                  data-name="Rectangle 2672"
                  transform="translate(1127 -9)"
                  fill="none"
                  stroke="#fff"
                  stroke-width="1.2"
                >
                  <rect width="22" height="18" stroke="none" />
                  <rect
                    x="0.6"
                    y="0.6"
                    width="20.8"
                    height="16.8"
                    fill="none"
                  />
                </g>
                <g
                  id="Group_3333"
                  data-name="Group 3333"
                  transform="translate(1131.009 5.532)"
                >
                  <g
                    id="Rectangle_2673"
                    data-name="Rectangle 2673"
                    transform="translate(-0.009 0.468)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="1"
                  >
                    <rect width="2" height="1" stroke="none" />
                    <rect x="0.5" y="0.5" width="1" fill="none" />
                  </g>
                  <g
                    id="Rectangle_2674"
                    data-name="Rectangle 2674"
                    transform="translate(2.991 0.468)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="1"
                  >
                    <rect width="2" height="1" stroke="none" />
                    <rect x="0.5" y="0.5" width="1" fill="none" />
                  </g>
                  <g
                    id="Rectangle_2675"
                    data-name="Rectangle 2675"
                    transform="translate(6.991 0.468)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="1"
                  >
                    <rect width="1" height="1" stroke="none" />
                    <rect x="0.5" y="0.5" fill="none" />
                  </g>
                  <g
                    id="Rectangle_2676"
                    data-name="Rectangle 2676"
                    transform="translate(9.991 0.468)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="1"
                  >
                    <rect width="2" height="1" stroke="none" />
                    <rect x="0.5" y="0.5" width="1" fill="none" />
                  </g>
                  <g
                    id="Rectangle_2677"
                    data-name="Rectangle 2677"
                    transform="translate(12.991 0.468)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="1"
                  >
                    <rect width="2" height="1" stroke="none" />
                    <rect x="0.5" y="0.5" width="1" fill="none" />
                  </g>
                </g>
                <path
                  id="Path_140"
                  data-name="Path 140"
                  d="M5776,504.069l1.694,2.465,5.861-5.438"
                  transform="translate(-4641.234 -504.748)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </g>
            </svg>
          </div>

          <h1>You just placed your First order !</h1>
          <h2>
            <a href={`./processing?search=${name}`}>View </a>placed order.
          </h2>
          <div class="line" />
          <h3>How is your experience with vFulfill?</h3>

          <div class="paragraph">
            <p class="paragraph-first">
              Your feedback would help us to make improvements to our app!
            </p>
            <p>{`Please leave a review on the ${SHOPIFY_NAME} App Store.`}</p>
          </div>

          <div class="btns">
            <button
              className="clickable-dropdown"
              onClick={() => this.feedBackHandler("submit")}
            >
              Give Feedback
            </button>
            <button onClick={() => this.feedBackHandler("skip")}>
              Skip for now
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="16"
                viewBox="0 0 8 16"
              >
                <g
                  id="Polygon_46"
                  data-name="Polygon 46"
                  transform="translate(8) rotate(90)"
                  fill="none"
                >
                  <path d="M8,0l8,8H0Z" stroke="none" />
                  <path
                    d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                    stroke="none"
                    fill="#898989"
                  />
                </g>
              </svg>
            </button>
          </div>
        </div>
      ),
      icon: null,
    });
  }

  // old user feedback

  oldUserFeedback(name) {
    notification.open({
      bottom: "5%",

      className: "old-user-custom-feedback",
      placement: "bottomLeft",
      duration: 6,
      key: "firstTimeUserFeedback",
      style: { border: "2px solid #2A9C27" },
      description: (
        <div class="feedback-old-store">
          <div class="svg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
            >
              <g
                id="Group_3934"
                data-name="Group 3934"
                transform="translate(-136 -400)"
              >
                <circle
                  id="Ellipse_45"
                  data-name="Ellipse 45"
                  cx="24"
                  cy="24"
                  r="24"
                  transform="translate(136 400)"
                  fill="#2a9c27"
                />
                <g
                  id="Polygon_47"
                  data-name="Polygon 47"
                  transform="translate(148 412)"
                  fill="none"
                >
                  <path
                    d="M12,0l4.14,7.275L24,9.167l-5.3,6.388L19.416,24,12,20.673,4.584,24,5.3,15.555,0,9.167,7.86,7.275Z"
                    stroke="none"
                  />
                  <path
                    d="M 12.00000095367432 4.043889999389648 L 9.169970512390137 9.017230033874512 L 3.589206695556641 10.36043453216553 L 7.363531112670898 14.90857028961182 L 6.864070892333984 20.78484344482422 L 12.00000095367432 18.48068046569824 L 17.13593101501465 20.78484344482422 L 16.63647079467773 14.90857028961182 L 20.41079521179199 10.36042785644531 L 14.8300313949585 9.017239570617676 L 12.00000095367432 4.043889999389648 M 12.00000095367432 0 L 16.14000129699707 7.275409698486328 L 24 9.167180061340332 L 18.69866180419922 15.55541038513184 L 19.41641044616699 24 L 12.00000095367432 20.6727294921875 L 4.583591461181641 24 L 5.301340103149414 15.55541038513184 L 1.9073486328125e-06 9.167189598083496 L 7.860000610351562 7.275409698486328 L 12.00000095367432 0 Z"
                    stroke="none"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </div>

          <h1>Hey {name}!</h1>

          <div class="line" />
          <h3>How is your experience with vFulfill?</h3>

          <div class="paragraph">
            <p class="paragraph-first">
              Your feedback would help us to make improvements to our app!
            </p>
            <p>{`Please leave a review on the ${SHOPIFY_NAME} App Store.`}</p>
          </div>

          <div class="btns">
            <button
              onClick={() => this.feedBackHandler("submit", true)}
              className="clickable-dropdown"
            >
              Give Feedback
            </button>
            <button onClick={() => this.feedBackHandler("skip")}>
              Skip for now
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="16"
                viewBox="0 0 8 16"
              >
                <g
                  id="Polygon_46"
                  data-name="Polygon 46"
                  transform="translate(8) rotate(90)"
                  fill="none"
                >
                  <path d="M8,0l8,8H0Z" stroke="none" />
                  <path
                    d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                    stroke="none"
                    fill="#898989"
                  />
                </g>
              </svg>
            </button>
          </div>
        </div>
      ),
      icon: null,
    });
  }

  download() {
    axios({
      url: process.env.REACT_APP_API_URL + "/orders/my_orders",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        type: this.props.pageType,
        filters: this.state.filters,
        downloadcsv: 1,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMsg: e.message,
          loading: false,
        });
      });
  }

  downloadCSV() {
    axios({
      url: process.env.REACT_APP_API_URL + "/orders/my_orders",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        type: this.props.pageType,
        filters: this.state.filters,
        downloadcsv: 1,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMsg: e.message,
          loading: false,
        });
      });
  }

  // bulk select unselect function

  bulkHandler(e) {
    let selected = this.state.selected;
    let selectedConfirmed = this.state.bulkCopy;

    if (e.checked) {
      let keyName = e.value;
      let keyValue = selected[keyName];

      selectedConfirmed = { ...selectedConfirmed, [keyName]: keyValue };
    } else {
      delete selectedConfirmed[e.value];
    }

    this.setState({ bulkCopy: selectedConfirmed });
  }

  // mav

  mavRtoRisk(e) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          mav_filters: { ...this.state.filters.mav_filters, rto_risk: e },
        },
      },
      () => this.loadData()
    );
  }

  mavReason(e) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          mav_filters: { ...this.state.filters.mav_filters, reason: e },
        },
      },
      () => this.loadData()
    );
  }
  tagChangeHandler(e) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          tags: e,
        },
      },
      () => this.loadData()
    );
  }

  render() {
    const paymentStatusColors = {
      paid: "#73D13D",
      partially_paid: "#4569FF",
      pending: "#FEC62D",
      refunded: "#FF4D4F",
      partially_refunded: "#FFC53D",
    };
    const paymentStatusText = {
      paid: "Fully Paid",
      partially_paid: "Partially Paid",
      pending: "Pending",
      refunded: "Refunded",
      partially_refunded: "Partially Refunded",
    };
    const shipmentStatusColors = {
      processing: "#9B9B9B",
      manifested: "#9B9B9B",
      transit: "#9B9B9B",
      delivered: "#9B9B9B",
      rto: "#F12F1A",
      ndr_actionable: "#FF4D4F",
      delivered_return: "#FD7A3B",
      cancelled: "#9b9b9b",
      rto_in_transit: "#af8100",
    };
    const shipmentStatusText = {
      processing: "In Processing",
      manifested: "In Processing",
      transit: "In Transit",
      delivered: "Delivered",
      rto: "Returned",
      ndr_actionable: "NDR",
      cancelled: "Cancelled",
      rto_in_transit: "RTO In-Transit",
    };
    const fulfillmentStatusColors = {
      shipped: "#73D13D",
      unfulfilled: "#FF4D4F",
      partial: "#FF7A45",
    };
    const fulfillmentStatusText = {
      shipped: "Fulfilled",
      unfulfilled: "Unfulfilled",
      partial: "Partially Fulfilled",
    };
    const vfOrderStatusColors = {
      verified: "#2BC822",
      verified_ordered: "#4569FF",
      not_fulfill: "#9B9B9B",
      hold: "#9B9B9B",
      cancelled: "#9B9B9B",
      open: "#FFC53D",
      notlinked: "#1161FB",
      not_serviceable: "#9B9B9B",
      ready_to_ship: "#D241FD",
      in_processing: "#FD7A3B",
      in_transit: "#F8C62D",
      rto_in_transit: "#af8100",
    };
    const vfOrderStatusIcons = {
      verified: CircleCheck,
      rto_in_transit: ShipmentStatusIcon,
      verified_ordered: CircleCheck,
      not_fulfill: NotFulfillIcon,
      hold: CirclePause,
      cancelled: CircleCross,
      open: OpenOrderIcon,
      notlinked: NotLinkedIcon,
      serviceable: ServiceableIcon,
      not_serviceable: NonServiceableIcon,
      cart: ShipmentStatusIcon,
      calling_inprogress: CallingIcon,
    };
    const vfOrderStatusText = {
      verified: "Verified",
      verified_ordered: "Verified & Ordered",
      not_fulfill: "Do Not Fulfill",
      hold: "On Hold",
      cancelled: "Cancelled Order",
      open: "Open",
      notlinked: "Not Linked",
      not_serviceable: "Not Serviceable",
      calling_inprogress: "Calling",
    };

    const PageTitles = {
      allorders: "All Orders",
      openorders: "To Be Placed",
      processingorders: "In-Processing",
      trackorders: "Order Tracking",
      ndr: "Non Delivery Report",
      closedOrders: "Closed Orders",
    };

    const PageWrapper = (children) => {
      return (
        <div className="my-orders">
          <Ordernav
            store={this.props.store}
            sort={this.state.sort}
            orders={this.state.orders}
            tags={this.state.tags}
            showMavFilters={this.state.showMavFilters}
            sorterFunction={(e) => this.sorterFunction(e)}
            pageType={this.props.pageType}
            PageTitles={PageTitles}
            loadAllData={() => this.loadData()}
            {...this.props}
            changeFilter={(e) => this.changeFilter(e)}
            shopifyOrderDateFilter={(e) => this.shopifyOrderDateFilter(e)}
            shipmentStatusColors={shipmentStatusColors}
            paymentStatusColors={paymentStatusColors}
            paymentStatus={this.state.filters.paymentStatus}
            order_counts={this.state.orderscount}
            vfOrderStatus={this.state.filters.vfOrderStatus}
            serviceableStatus={this.state.filters.is_servicable}
            duplicate_orders={this.state.filters.duplicate_orders}
            vfOrderStatusColors={vfOrderStatusColors}
            vfOrderStatusIcons={vfOrderStatusIcons}
            filters={this.state.filters}
            changeHandler={(e) => this.changeHandler(e)}
            tabIsProcessing={this.state.tabIsProcessing}
            tabIsClosedOrders={this.state.tabIsClosedOrders}
            tabIsopen={this.state.tabIsopen}
            tabIsallOrder={this.state.tabIsallOrder}
            childtop={(e) => this.childtop(e)}
            allordernav={this.state.allordernav}
            closeordernavtoggle={(e) => this.closeordernavtoggle(e)}
            closeordernav={this.state.closeordernav}
            inprocessnavtoggle={(e) => this.inprocessnavtoggle(e)}
            inprocessordernav={this.state.inprocessordernav}
            openordernavtoggle={(e) => this.openordernavtoggle(e)}
            openordernav={this.state.openordernav}
            storeid={this.props.match.params.storeid}
            shipmentStatus={this.state.filters.shipmentStatus}
            selectAllOrders={this.state.selectAllOrders}
            loading={this.state.loading}
            calenderRef={this.calenderRef}
            rangePickerChange={(e) => this.rangePickerChange(e)}
            downloadCSV={() => this.downloadCSV()}
            togglebulksearchaction={() => this.togglebulksearchaction()} //bulk search open close logic
            resetModal={() => this.resetModal()} //
            mavRtoRisk={(e) => this.mavRtoRisk(e)}
            mavReason={(e) => this.mavReason(e)}
            tagChangeHandler={(e) => this.tagChangeHandler(e)}
          />

          {!!this.state.filters.bulkSearch &&
          !!this.state.filters.bulkSearch.length ? (
            <div id="fade-in" className="bulk-list-wraper">
              <div className="detailswrp">
                <p>
                  Showing <span>{this.state.total}</span> Results for Bulk
                  Search
                </p>

                <div className="right">
                  {/* <button
                    onClick={() => this.togglebulksearchaction()}
                    className="clickable"
                  >
                    Edit Bulk Search
                  </button> */}
                  <button
                    onClick={() => this.resetModal()}
                    className="clickable err"
                  >
                    Clear All
                  </button>
                </div>
              </div>

              <div className="lst-wraper">
                <div className="left">
                  {this.state.filters.bulkSearch.map((d) => (
                    <Tag
                      closable
                      onClose={(e) => {
                        e.preventDefault();
                        this.resetModal(d);
                      }}
                    >
                      {d}
                    </Tag>
                  ))}
                </div>
              </div>
            </div>
          ) : null}

          <Row style={{ marginBottom: "16px" }}>
            <Col xs={20}>
              {this.state.filters.startDate && this.state.filters.endDate ? (
                <h2 className="content-page-subtitle">
                  Orders from{" "}
                  {moment(this.state.filters.startDate).format("DD MMM, YYYY")}{" "}
                  - {moment(this.state.filters.endDate).format("DD MMM, YYYY")}
                </h2>
              ) : // <h2 className="content-page-subtitle">Your Orders</h2>
              null}

              {(this.state.orders && this.state.orders.length === 0) ||
              this.state.openordernav.notlinkbtn ||
              this.state.openordernav.allbtn5
                ? null
                : this.props.pageType === "openorders" && (
                    <Checkbox
                      checked={this.state.selectAllOrders}
                      onChange={(e) =>
                        this.setState({ selectAllOrders: e.target.checked })
                      }
                    >
                      {" "}
                      Select All Orders
                    </Checkbox>
                  )}
            </Col>

            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              xs={4}
            >
              {this.props.pageType === "openorders" &&
              Object.values(this.state.selected).filter(
                (s) => Object.keys(s).length !== 0
              ).length > 0 ? (
                <Dropdown.Button
                  overlay={
                    <Menu
                      onClick={({ key }) => this.toggleConfirmationModal(key)}
                      className="custom_dropdown2"
                    >
                      {this.state.openordernav &&
                        this.state.openordernav.calling != true && (
                          <Menu.Item key="move_to_calling">
                            <a href="#">Move to Calling</a>
                          </Menu.Item>
                        )}

                      <Menu.Item key="verify">
                        <a href="#">Verify</a>
                      </Menu.Item>

                      <Menu.Item key="hold">
                        <a href="#">Put On Hold</a>
                      </Menu.Item>

                      <Menu.Item key="nofulfill">
                        <a href="#">Do Not Fulfill</a>
                      </Menu.Item>
                      {/* <Menu.Divider />
										<Menu.Item key="cancel">
											<a href="#">Cancel</a>
										</Menu.Item> */}
                    </Menu>
                  }
                  // className="clickable"
                  type="primary"
                  icon={<Icon type="caret-down" />}
                  size="large"
                  onClick={() =>
                    this.toggleConfirmationModal(
                      hasPermission(this.props.store, "verify_confirm_order")
                        ? "verifyorder"
                        : "verify",
                      undefined,

                      null,
                      true
                    )
                  }
                >
                  {hasPermission(this.props.store, "verify_confirm_order")
                    ? "Verify & Order"
                    : "Verify"}
                </Dropdown.Button>
              ) : hasPermission(this.props.store, "export_orders_data") ||
                hasPermission(
                  this.props.store,
                  "export_orders_data_with_customer_information"
                ) ? null : null // </a> //   Download CSV // > //   className="ant-btn ant-btn-primary ant-btn-lg clickable" //   rel="noopener noreferrer" //   target="_blank" //   )}`} //     { encodeValuesOnly: true } //     }, //       filters: this.state.filters, //       storeid: this.props.match.params.storeid, //       type: this.props.pageType, //     { //   href={`/orders/export_csv?${qs.stringify( // <a
              }
            </Col>
            {/* <Col
              xs={3}
              style={{
                textAlign: "right",
              }}
            >
              {(this.props.pageType === "openorders" &&
                this.state.openordernav.openbtn === true) ||
              this.state.openordernav.calling === true ||
              this.state.openordernav.Verifiedbtn === true ? (
                <Button
                  className="clickable"
                  onClick={() => {
                    this.togglebulkplaceaction();
                  }}
                >
                  Bulk Place
                </Button>
              ) : null}
            </Col> */}
          </Row>

          {children}

          <Row>
            <Pagination
              locale={{ jump_to: "Go to page" }}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} orders`
              }
              showSizeChanger
              showQuickJumper
              pageSizeOptions={["10", "25", "50"]}
              onShowSizeChange={(p, d) => this.onShowSizeChange(p, d)}
              current={this.state.paging.page}
              onChange={(page) => this.goToPage(page)}
              total={this.state.total}
              pageSize={this.state.paging.per_page_count}
            />
          </Row>
          <GlobakBulkSearch
            bulksearchtext={this.state.filters}
            visible={this.state.visibleBulk}
            togglebulksearchaction={() => this.togglebulksearchaction()}
            resetModal={() => this.resetModal()}
            loading={this.state.loading}
            bulkFieldChange={(k, v) => this.bulkFieldChange(k, v)}
            submitbulkaction={() => this.bulkSearchHandler()}
            page={"orders"}
          />
          <GlobakBulkOrder
            bulksearchtext={this.state.bulkOrderPlace}
            visible={this.state.visibleBulkOrderPlace}
            togglebulksearchaction={() => this.togglebulkplaceaction()}
            resetModal={() => this.resetModalBulkPlace()}
            loading={this.state.bulkplaceLoading}
            clearData={() => {
              this.setState({
                bulkOrderPlace: [],
              });
            }}
            bulkFieldChange={(k, v) => this.bulkFieldplaceChange(k, v)}
            submitbulkaction={() => this.bulkPlaceSubmit()}
            page={"orders"}
          />

          <SendMsgToCstmrModal
            visible={this.state.sendMsgModal.visible}
            saving={this.state.sendMsgModal.saving}
            error={this.state.sendMsgModal.error}
            toggleModal={() => this.toggleSendMsgModal()}
            onConfirm={(details) => this.sendMessage(details)}
            onCancel={() => this.toggleSendMsgModal()}
          />

          <AddNoteModal
            visible={this.state.notesModal.visible}
            toggleModal={() => this.toggleNotesModal()}
            onConfirm={(value) => this.addNote(value)}
            saving={this.state.notesModal.saving}
            onCancel={() => this.toggleNotesModal()}
          />

          {this.state.confirmationModal.visible ? (
            <OrderConfirmationModal
              showMavFilters={this.state.showMavFilters}
              bulkCopy={this.state.bulkCopy}
              selectedConfirmed={this.state.selectedConfirmed}
              cardRiskScore={this.state.cardRiskScore}
              apiPlaceResponse={this.state.apiPlaceResponse}
              bulkSelect={this.state.bulkSelect}
              bulkHandler={(e) => this.bulkHandler(e)}
              visible={this.state.confirmationModal.visible}
              type={this.state.confirmationModal.type}
              shippingLoader={this.state.confirmationModal.shippingLoader}
              shippingCost={this.state.confirmationModal.shippingCost}
              saving={this.state.confirmationModal.saving}
              orders={this.state.selectedConfirmed}
              currencies={this.state.currencies}
              id={this.state.confirmationModal.id}
              onCancel={() => this.toggleConfirmationModal()}
              onChangeConfirmationModalField={(field, val) =>
                this.onChangeConfirmationModalField(field, val)
              }
              onChnagenotfulfill={(field, val) =>
                this.onChnagenotfulfill(field, val)
              }
              cancelReason={this.state.confirmationModal.cancelReason}
              refund={this.state.confirmationModal.refund}
              refundAmount={this.state.confirmationModal.refundAmount}
              snoozeDate={this.state.confirmationModal.snoozeDate}
              isCod={this.state.confirmationModal.isCod}
              onConfirm={(e) => this.confirmSelected(e)}
              moveToCallingHandler={(e) => this.moveToCallingHandler(e)}
              storegeo={this.props.store.store_geo}
              ordersdata={this.state.orders}
              modalvforderid={this.state.modalvforderid}
              multiplevforderid={this.state.multiplevforderid}
              riskScore={this.state.riskScore}
              // provisional={provisional}
              store={this.props.store}
              ordershopifyid={this.state.ordershopifyid}
              notfulfillcheck={this.state.confirmationModal.notfulfillcheck}
              selectAllOrders={this.state.selectAllOrders}
              setShopifyOrderCancel={(e) => this.setShopifyOrderCancel(e)}
              cancelOnShopify={this.state.confirmationModal.cancelOnShopify}
            />
          ) : null}

          {/* 
          <LinkProductModal
            visible={this.state.linkProductModal.visible}
            onCancel={() => this.toggleLinkProductModal()}
            onConfirm={(importer) => {
              this.toggleLinkModal(`${importer}ModalVisible`);
            }}
          /> */}

          {/* <AliExpressModal
            {...this.props}
            visible={this.state.linkProductModal.aliExpressModalVisible}
            linkProductModalId={this.state.linkProductModal.id}
            onCancel={() => this.toggleLinkModal("aliExpressModalVisible")}
          /> */}

          <ImportListModal
            {...this.props}
            visible={this.state.orderLinkProductModal.importListModalVisible}
            product={this.state.orderLinkProductModal.productData}
            onCancel={() => this.toggleLinkModal()}
            onConfirm={(aliid) => this.splitUsingImportList(aliid, "order")}
            loading={this.state.modalLoading}
          />

          <BundleModal
            {...this.props}
            visible={this.state.orderBundleProductModal.bundleModalVisible}
            linkProductModalId={this.state.orderBundleProductModal.id}
            onCancel={() => this.toggleBundleLinkModal()}
            data={this.state.orderBundleProductModal.productData}
            orderway="order"
          />

          <NDRModal
            {...this.props}
            {...this.state.ndrModal}
            onNDRReattemptSuccess={(remark) =>
              this.onNDRReattemptSuccess(remark)
            }
            toggleNDRModal={() => this.toggleNDRModal()}
          />
          <NDRRemarksModal
            {...this.props}
            {...this.state.ndrRemarksModal}
            toggleNDRRemarksModal={() => this.toggleNDRRemarksModal()}
          />
          <NDREscalationModal
            {...this.props}
            {...this.state.ndrEscalationModal}
            toggleNdrEscalationModal={() => this.toggleNdrEscalationModal()}
            confirmNdrEscalationModal={(file) =>
              this.confirmNdrEscalationModal(file)
            }
          />
          <ViewBundleModal
            toggleViewBundleModal={() => this.toggleViewBundleModal()}
            bundle={this.state.viewBundleModal.bundle}
            store={this.props.store}
          />
        </div>
      );
    };

    if (this.state.loading) {
      return PageWrapper(
        <Row style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </Row>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <ErrorCard
          errorMsg={this.state.errorMsg}
          errorCode={this.state.errorCode}
        />
      );
    }

    if (
      this.state.orders.length === 0 &&
      !!this.state.filters.startDate &&
      !!this.state.filters.endDate
    ) {
      return PageWrapper(
        <NotFoundCard
          mainHeading={"Oops! No Data Found."}
          secondHeading={
            <p>
              Looks like there are no orders in <span>current Date Range</span>.
            </p>
          }
          thirdHeading={
            <p>
              Please <span>select another Date Range</span> from Date Picker to
              filter data.
            </p>
          }
        />
      );
    }

    if (this.state.orders.length === 0) {
      return PageWrapper(
        <NotFoundCard
          mainHeading={"Oops! No Data Found."}
          secondHeading={<p>Looks like there are no orders in this page.</p>}
        />
      );
    }

    return PageWrapper(
      <div>
        {this.state.orders.map((order) => (
          <OrderCardNew
            showMavFilters={this.state.showMavFilters}
            pageTab={this.state.filters.pagename}
            store={this.props.store}
            key={order._id["$oid"]}
            {...this.props}
            filters={this.state.filters}
            selectAllOrders={this.state.selectAllOrders}
            // toggleLinkProductModal={(id, pd) => this.toggleLinkModal(id, pd)}
            toggleLinkProductModal={(id, pd) => this.toggleLinkModal(id, pd)}
            toggleBundleLinkModal={(id, pd) =>
              this.toggleBundleLinkModal(id, pd)
            }
            vfOrderStatus={this.state.filters.vfOrderStatus}
            fulfillmentStatus={this.state.filters.fulfillmentStatus}
            paymentStatus={this.state.filters.paymentStatus}
            shipmentStatus={this.state.filters.shipmentStatus}
            paymentStatusColors={paymentStatusColors}
            paymentStatusText={paymentStatusText}
            shipmentStatusColors={shipmentStatusColors}
            shipmentStatusText={shipmentStatusText}
            fulfillmentStatusColors={fulfillmentStatusColors}
            fulfillmentStatusText={fulfillmentStatusText}
            vfOrderStatusColors={vfOrderStatusColors}
            vfOrderStatusIcons={vfOrderStatusIcons}
            vfOrderStatusText={vfOrderStatusText}
            pageType={this.props.pageType}
            order={order}
            onSelectChange={(id, items, cb) =>
              this.onSelectChange(id, items, cb)
            }
            currencies={this.state.currencies}
            toggleNotesModal={(id, cb) => this.toggleNotesModal(id, cb)}
            toggleSendMsgModal={(id) => this.toggleSendMsgModal(id)}
            toggleConfirmationModal={(type, id, order) =>
              this.toggleConfirmationModal(type, id, order)
            }
            toggleNDRModal={(awb, shipment_vfid, address) =>
              this.toggleNDRModal(awb, shipment_vfid, address)
            }
            toggleNDRRemarksModal={(remarks) =>
              this.toggleNDRRemarksModal(remarks)
            }
            toggleNdrEscalationModal={(oid, sid) =>
              this.toggleNdrEscalationModal(oid, sid)
            }
            reloadData={() => this.loadData()}
            updateCustomerShippingAddress={(oid, add, details) =>
              this.updateCustomerShippingAddress(oid, add, details)
            }
            markShipmentRTO={(oid, sid) => this.markShipmentRTO(oid, sid)}
            toggleViewBundleModal={(b) => this.toggleViewBundleModal(b)}
            closeordernav={this.state.closeordernav}
            allordernav={this.state.allordernav}
            openordernav={this.state.openordernav}
          />
        ))}

        <br />

        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};
export default connect(mapStateToProps)(ManageOrders);
