import React, { Component } from "react";
import {
  Row,
  Skeleton,
  Alert,
  Table,
  Switch,
  message,
  Icon,
  Tooltip,
  Pagination,
} from "antd";
class RtoTable extends Component {
  render() {
    let valueAspreKey = {
      A: "Zone A",
      B: "Zone B",
      C: "Zone C",
      D: "Zone D",
      E: "Zone E",
      minimum_reverse_pickup_charges: "Minimum Resverse Pickup Charges",
      cod_fees: "COD Fees Changes",
      rto_handling_fees: "RTO Handling Fees",
    };
    let chargeableAsPerKey = {
      A: "Intra City Shipping Charges",
      B: "Intra State Shipping Charges",
      C: "Shipping to Metros",
      D: "Shipping to Rest of India",
      E:
        "Shipping to Special Zones like, J&K, Kerala, Rural Locations, Remote Areas, etc.",
      minimum_reverse_pickup_charges: "",
      rto_handling_fees: "RTO Shipment Handling Fees",
    };
    const columns = [
      {
        title: "Non Delivery - RTO Shipping",
        dataIndex: "key",
        width: "30%",
        render: (key, obj) => {
          return <div>{valueAspreKey[key]}</div>;
        },
      },

      {
        title: "Chargeables",
        dataIndex: "key",
        width: "40%",
        render: (key, obj) => {
          return <div>{chargeableAsPerKey[key]}</div>;
        },
      },
      {
        title: " Prices in ₹ ( For Each 500 gm SLAB)",
        dataIndex: "key",
        width: "30%",
        render: (key, obj) => {
          if (key === "minimum_reverse_pickup_charges") {
            return (
              <div>
                {obj["minimum_reverse_pickup_charges"].replace("INR", "₹")}{" "}
              </div>
            );
          }
          if (key === "rto_handling_fees") {
            return <div>{obj["rto_handling_fees"].replace("INR", "₹")} </div>;
          }
          return <div>{obj.rtof500.replace("INR", "₹")}</div>;
        },
      },
    ];
    return (
      <Table
        rowKey={(row, i) => row.key}
        columns={columns}
        dataSource={this.props.data}
        // onChange={(page) => this.goToPage(page)}
        pagination={false}
        width="500px"
      />
    );
  }
}
export default RtoTable;
