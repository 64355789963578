// import { Tooltip } from "antd";
import React, { Component } from "react";
import "./index.scss";
import moment from "moment";

class Shipmentprogressbar extends Component {
  state = {
    shipmentsVisible: false,
    shipment: this.props.order,
  };

  render() {
    return (
      <div className="shipmentprogressbar_data">
        <div className="shipmentprogressbar_data3">
          <div className="line-container">
            <div className="progress-line">
              <div
                className="progress"
                style={{
                  width:
                    this.state.shipment.status === "manifested"
                      ? "48%"
                      : this.state.shipment.status === "transit" ||
                        this.state.shipment.status === "ndr_actionble" ||
                        this.state.shipment.status === " ndr "
                      ? "79%"
                      : this.state.shipment.status === "delivered"
                      ? "100%"
                      : "100%",
                }}
              />
              <div className="status">
                <div className="dot completed" />
                <div
                  style={{
                    color: "#2bc822",
                    fontSize: "12px",
                    marginLeft: "-16px",
                  }}
                >
                  {this.state.shipment.status === "rto"
                    ? "Return Initiated"
                    : "Ordered"}
                </div>
              </div>

              {this.state.shipment.status === "cancelled" ||
              this.state.shipment.status === "rto" ? null : (
                <div className="status">
                  <div
                    className={`dot ${
                      this.state.shipment.status === "manifested" ||
                      this.state.shipment.status === "transit" ||
                      this.state.shipment.status === "ndr_actionble" ||
                      this.state.shipment.status === "ndr" ||
                      this.state.shipment.status === "delivered" ||
                      this.state.shipment.status === "rto"
                        ? "completed"
                        : ""
                    }`}
                    style={{
                      border:
                        this.state.shipment.status === "manifested"
                          ? " 5px solid #B8F0B3"
                          : null,
                    }}
                  />
                  <div
                    className={
                      this.state.shipment.status === "manifested" ||
                      this.state.shipment.status === "transit" ||
                      this.state.shipment.status === "ndr_actionble" ||
                      this.state.shipment.status === "ndr" ||
                      this.state.shipment.status === "delivered" ||
                      this.state.shipment.status === "rto"
                        ? "shipment_step_color"
                        : "original_color"
                    }
                  >
                    Packed
                  </div>
                </div>
              )}

              {this.state.shipment.status === "cancelled" ||
              this.state.shipment.status === "rto" ? null : (
                <div className="status">
                  <div
                    className={`dot ${
                      this.state.shipment.status === "transit" ||
                      this.state.shipment.status === "ndr_actionble" ||
                      this.state.shipment.status === " ndr " ||
                      this.state.shipment.status === "delivered" ||
                      this.state.shipment.status === "rto"
                        ? "completed"
                        : ""
                    }`}
                    style={{
                      border:
                        this.state.shipment.status === "transit" ||
                        this.state.shipment.status === "ndr_actionble" ||
                        this.state.shipment.status === " ndr "
                          ? " 5px solid #B8F0B3"
                          : null,
                    }}
                  />
                  <div
                    className={
                      this.state.shipment.status === "transit" ||
                      this.state.shipment.status === "ndr_actionble" ||
                      this.state.shipment.status === " ndr " ||
                      this.state.shipment.status === "delivered" ||
                      this.state.shipment.status === "rto"
                        ? "shipment_step_color"
                        : "original_color"
                    }
                  >
                    Shipped
                  </div>
                </div>
              )}

              <div className="status" style={{ left: "1px" }}>
                <div
                  className={`dot ${
                    this.state.shipment.status === "delivered" ||
                    this.state.shipment.status === "rto" ||
                    this.state.shipment.status === "cancelled"
                      ? "completed"
                      : ""
                  }`}
                  style={{
                    border:
                      this.state.shipment.status === "delivered" ||
                      this.state.shipment.status === "rto"
                        ? " 5px solid #B8F0B3"
                        : null,
                  }}
                />
                <div
                  className={
                    this.state.shipment.status === "delivered"
                      ? "shipment_step_color"
                      : "original_color"
                  }
                >
                  {this.state.shipment.status === "cancelled" ? (
                    <p style={{ marginRight: "-27px", color: "#F12F1A" }}>
                      cancelled
                    </p>
                  ) : this.state.shipment.status === "delivered" ||
                    this.state.shipment.status === "transit" ||
                    this.state.shipment.status === "ndr_actionble" ||
                    this.state.shipment.status === " ndr " ||
                    this.state.shipment.status === "manifested" ? (
                    <p style={{ marginRight: "-27px" }}>Delivered</p>
                  ) : (
                    <p style={{ color: "#2bc822", marginLeft: "-24px" }}>
                      {" "}
                      {this.state.shipment.status === "rto"
                        ? "Returned"
                        : null}{" "}
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* order shipment new key closed_on added */}

            <div className="Order_tracking_status">
              <p style={{ color: "#000" }}>
                {this.state.shipment.status === "delivered" &&
                this.state.shipment &&
                this.state.shipment.closed_on ? (
                  <>
                    <p style={{ color: "#898989" }}>Order Tracking Status:</p>
                    Delivered on{" "}
                    {moment(this.state.shipment.closed_on).format(
                      "MMM DD, YYYY"
                    )}
                  </>
                ) : this.state.shipment.status === "rto" &&
                  this.state.shipment &&
                  this.state.shipment.closed_on ? (
                  <>
                    <p style={{ color: "#898989" }}>Order Tracking Status:</p>
                    Return on{" "}
                    {moment(this.state.shipment.closed_on).format(
                      "MMM DD, YYYY"
                    )}
                  </>
                ) : this.state.shipment.status === "transit" ? (
                  <>
                    <p style={{ color: "#898989" }}>Order Tracking Status:</p>
                    In Transit
                  </>
                ) : this.state.shipment.status === "manifested" ? (
                  <>
                    <p style={{ color: "#898989" }}>Order Tracking Status:</p>
                    To Be Shipped
                  </>
                ) : null}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Shipmentprogressbar;
