import React from "react";

const MediaTab = ({ images = [], videos = [], toggleZoomModal }) => {
  return (
    <div>
      {images.length > 0 ? (
        <div>
          <p className="f12 mb16" style={{ color: "#898989" }}>
            Product Images:
          </p>
          <ul
            style={{
              width: "100%",
              overflowX: "auto",
              listStyleType: "none",
              padding: 0,
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {images.map((img, index) => (
              <li
                key={index}
                style={{
                  width: 144,
                  height: 144,
                  marginRight: 24,
                  position: "relative",
                  marginBottom: 24,
                }}
              >
                <img
                  src={img.url}
                  alt="product"
                  onClick={() => toggleZoomModal(img.url, "image")}
                  style={{
                    width: 144,
                    height: 144,
                    objectFit: "cover",
                    borderRadius: 2,
                    border: "1px solid #D0D0D0",
                    cursor: "pointer",
                  }}
                />
                <a
                  href={
                    process.env.REACT_APP_API_URL +
                    `/products/download_product_media?url=${img.url}`
                  }
                  download
                  style={{
                    position: "absolute",
                    bottom: 16,
                    right: 16,
                    padding: "6px 8px",
                    background: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.8"
                    height="19.6"
                    viewBox="0 0 16.8 19.6"
                  >
                    <g data-name="Group 2197">
                      <g data-name="Group 2193">
                        <g data-name="Group 2192">
                          <path
                            data-name="Path 52"
                            d="M16.154 13.411a.633.633 0 00-.646.619v3.714a.633.633 0 01-.646.619H1.934a.633.633 0 01-.646-.619V14.03a.647.647 0 00-1.292 0v3.714A1.9 1.9 0 001.934 19.6h12.928a1.9 1.9 0 001.938-1.857v-3.714a.633.633 0 00-.646-.618z"
                            fill="#1370fc"
                          />
                        </g>
                      </g>
                      <g data-name="Group 2195">
                        <g data-name="Group 2194">
                          <path
                            data-name="Path 53"
                            d="M12.316 11.892a.69.69 0 00-.957 0l-2.27 2.265V.688a.689.689 0 00-1.378 0v13.469l-2.269-2.265a.688.688 0 00-.974.972L7.912 16.3a.69.69 0 00.974 0l3.445-3.438a.687.687 0 00-.015-.97z"
                            fill="#1370fc"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      {videos.length > 0 ? (
        <div>
          <p className="f12 mb16" style={{ color: "#898989" }}>
            Product Videos:
          </p>
          <ul
            style={{
              width: "100%",
              overflowX: "auto",
              listStyleType: "none",
              padding: 0,
              display: "flex",
            }}
          >
            {videos.map((video, index) => (
              <li
                key={index}
                style={{
                  height: 144,
                  marginRight: 24,
                  position: "relative",
                }}
              >
                <video src={video.url} controls style={{ height: "100%" }}>
                  <source src={video.url} type="video/mp4" />
                </video>
                <a
                  href={
                    process.env.REACT_APP_API_URL +
                    `/products/download_product_media?url=${video.url}`
                  }
                  download
                  style={{
                    position: "absolute",
                    bottom: 16,
                    right: 16,
                    padding: "6px 8px",
                    background: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.8"
                    height="19.6"
                    viewBox="0 0 16.8 19.6"
                  >
                    <g data-name="Group 2197">
                      <g data-name="Group 2193">
                        <g data-name="Group 2192">
                          <path
                            data-name="Path 52"
                            d="M16.154 13.411a.633.633 0 00-.646.619v3.714a.633.633 0 01-.646.619H1.934a.633.633 0 01-.646-.619V14.03a.647.647 0 00-1.292 0v3.714A1.9 1.9 0 001.934 19.6h12.928a1.9 1.9 0 001.938-1.857v-3.714a.633.633 0 00-.646-.618z"
                            fill="#1370fc"
                          />
                        </g>
                      </g>
                      <g data-name="Group 2195">
                        <g data-name="Group 2194">
                          <path
                            data-name="Path 53"
                            d="M12.316 11.892a.69.69 0 00-.957 0l-2.27 2.265V.688a.689.689 0 00-1.378 0v13.469l-2.269-2.265a.688.688 0 00-.974.972L7.912 16.3a.69.69 0 00.974 0l3.445-3.438a.687.687 0 00-.015-.97z"
                            fill="#1370fc"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default MediaTab;
