import React, { Component } from "react";
import "./index.scss";
export default class DeliveryAddressContainer extends Component {
  render() {
    const { obj } = this.props;
    return (
      <div className="delivery-address-component">
        <div className="item width-100">
          <div className="label">
            Address Name:
            <span className="address-name">
              {" "}
              {obj.address_name ? obj.address_name : "Not Available"}
            </span>
          </div>
          {/* <div className="value">
            {obj.address_name ? obj.address_name : "Not Available"}
          </div> */}
        </div>
        <div className="item width-49">
          <div className="label">Address Line 1:</div>
          <div className="value">
            {obj.address1 ? obj.address1 : "Not Available"}
          </div>
        </div>
        <div className="item width-49">
          <div className="label">Address Line 2:</div>
          <div className="value">
            {obj.address2 ? obj.address2 : "Not Available"}
          </div>
        </div>
        <div className="item ">
          <div className="label">State :</div>
          <div className="value">{obj.state ? obj.state : "Not Available"}</div>
        </div>
        <div className="item">
          <div className="label">City:</div>
          <div className="value">{obj.city ? obj.city : "Not Available"}</div>
        </div>
        <div className="item">
          <div className="label">Pincode:</div>
          <div className="value">
            {obj.pincode ? obj.pincode : "Not Available"}
          </div>
        </div>
      </div>
    );
  }
}
