import React from "react";
import { Col, Icon, Card } from "antd";
import { ReactComponent as OrderIcon } from "../../../../assets/images/icons/open-orders-snapshot.svg";
import { ReactComponent as ProcessingIcon } from "../../../../assets/images/icons/processing-snapshot.svg";
import { ReactComponent as NotificationIcon } from "../../../../assets/images/icons/notifications-snapshot.svg";

// import "./index.scss";

const OrderStatus = (props) => {
  return (
    <React.Fragment>
      <div
        className="sc"
        gutter={24}
        style={{ marginBottom: "2rem", marginTop: "2rem" }}
      >
        <Col>
          <Card
            className={`extra-stats ${
              props.analytics && props.analytics.open_orders === 0
                ? "is-zero"
                : ""
            } `}
            onClick={() =>
              props.history.push(
                `/${props.match.params.storegeo}/${
                  props.auth.store.id
                }/orders/open`
              )
            }
          >
            <div className="scwrap">
              <span className="icon">
                <Icon component={OrderIcon} />
              </span>
              <span className="title">Open Orders </span>
            </div>

            <span className="number">
              {" "}
              {props.analytics && props.analytics.open_orders
                ? props.analytics.open_orders
                : 0}
            </span>
          </Card>
        </Col>
        <Col>
          <Card
            className={`extra-stats ${
              props.analytics && props.analytics.orders_processing === 0
                ? "is-zero"
                : ""
            } `}
            onClick={() =>
              props.history.push(
                `/${props.match.params.storegeo}/${props.auth.store.id}/ndr`
              )
            }
          >
            <div className="scwrap">
              <span className="icon">
                <Icon component={ProcessingIcon} />
              </span>
              <span className="title">Open NDR</span>
            </div>

            <span className="number">
              {" "}
              {props.analytics && props.analytics.open_ndrs
                ? props.analytics.open_ndrs
                : 0}
            </span>
          </Card>
        </Col>
        <Col>
          <Card
            className={`extra-stats ${
              props.analytics && props.analytics.notificatios === 0
                ? "is-zero"
                : ""
            } `}
            // onClick={() =>
            //   props.history.push(
            //     `/${props.match.params.storegeo}/${
            //       props.auth.store.id
            //     }/all-notification`
            //   )
            // }
            onClick={() => props.linketonotification()}
          >
            <div className="scwrap">
              <span className="icon">
                <Icon component={NotificationIcon} />
              </span>
              <span className="title">Notifications</span>
            </div>

            <span className="number">
              {props.analytics && props.analytics.notificatios
                ? props.analytics.notificatios
                : 0}
            </span>
          </Card>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default OrderStatus;
