import React, { Component } from "react";
import {
  Modal,
  Icon,
  Button,
  Select,
  Input,
  Upload,
  message,
  Collapse,
} from "antd";
import { SHOPIFY_NAME } from "../../../../../constants";
import "./index.scss";
import axios from "axios";
import ActionNotification from "../../../Common/ActionNotification";
import book from "../../../../../assets/images/icons/book.png";
const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;

export const reasons = {
  // not_delivered:
  //   "End Customer Reported Product Not delivered but Logistics showing delivered",
  // empty_box: "End Customer Reported Empty Box recieved",
  // not_working: "End Customer Reported Product not working",
  // different_variant: "End Customer Reported Different Variant Recieved",
  // not_worth: "End Customer Reported Product is not worth it",
  // partially_shipped: "Order Partially Shipped",
  // item_missing: "Item missing in order.",
  not_delivered: "Product not delivered but app showing delivered",
  empty_box: "Empty box received ",
  // not_working: "Product not working. ",
  damaged_product: "Damaged product received",
  partially_received: "Partial order received",
  wrong_product_delivered: "Wrong product delivered",
};

export const escalationAction = {
  reverse_pickup_refund: "Reverse pickup and provide refund",
  only_refund: "VF cost refund ",
  send_replacement: "No need to reverse pickup, just provide replacement",
  reverse_pickup_replacement: "Reverse pickup and provide replacement",
};

class DisputeModal extends Component {
  state = {
    selectedLineItems: [],
    dispute_reason: "",
    dispute_description: "",
    files: [],
    loading: false,
    escalation_action: "",
  };
  handleChange(key, value) {
    this.setState({
      ...this.state,
      [key]: value,
    });
  }

  handleChange2(key, value) {
    this.setState(
      {
        ...this.state,
        [key]: value,
      },
      () =>
        this.setState({
          arrow2: !this.state.arrow2,
        })
    );
  }

  handleChange3(key, value) {
    this.setState(
      {
        ...this.state,
        [key]: value,
      },
      () =>
        this.setState({
          arrow9: !this.state.arrow9,
        })
    );
  }

  onSave = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL + "/disputes/raise_shipment_dispute",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            files: this.state.files,
            reason: this.state.dispute_reason,
            description: this.state.dispute_description,
            items: this.props.selectedLineItems,
            shipment_vfid: this.props.data.shipment_vfid,
            escalation_action: this.state.escalation_action,
          },
          withCredentials: true,
        })
          .then((res) => {
            this.setState({
              loading: false,
            });
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              ActionNotification({
                notificationType: "escalate",
                awb: this.props.data.awb,
              });
              this.props.onCountinue(res.data.dispute_id);
              this.props.reloadList();
              this.props.resetModal();
            } else if (res.data.success === -1) {
              this.props.onFailed(
                "Escalation for a shipment cannot be raised if the delivery date is more than 7 days ago.",
                false
              );
            } else if (res.data.success === -2) {
              this.props.onFailed(res.data.msg, true);
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
            message.error(e.message);
          });
      }
    );
  };

  componentDidUpdate(pp) {
    if (!this.props.visible && pp.visible) {
      this.setState({
        selectedLineItems: [],
        dispute_reason: "",
        dispute_description: "",
        files: [],
        loading: false,
      });
    }
  }

  render() {
    const props = {
      name: "file",
      multiple: true,
      action: process.env.REACT_APP_API_URL + "/disputes/upload_dispute_file",
      accept: ".png,.jpg,.jpeg,.mp4",
      withCredentials: true,
      onChange: (info) => {
        if (info.file.status !== "uploading") {
        }
        if (info.file.status === "done") {
          if (info.file.response.success === 1) {
            this.setState({
              files: [...this.state.files, info.file.response.file],
            });

            message.success(`File uploaded successfully.`);
          } else {
            message.error(`${info.file.response.msg}`);
          }
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    if (this.props.data === undefined) {
      return null;
    }
    const shipment = this.props.data;
    const lineItemsLength = shipment.items.length;
    return (
      <Modal
        title={
          <div className="modal-title">
            <p>Complete Escalation Form</p>
            <p>{lineItemsLength} Line Items(Products)</p>
          </div>
        }
        visible={this.props.visible}
        className="dispute-modal"
        closable={false}
        width={672}
        destroyOnClose
        maskClosable={false}
        onCancel={() => this.props.onCancel()}
        footer={
          <div>
            <Button
              style={{
                color: "white",
                background: `${
                  !!this.state.dispute_description &&
                  !!this.state.dispute_reason &&
                  !!this.state.files.length &&
                  !!this.state.escalation_action.length
                    ? `#1370FC`
                    : `lightblue`
                }`,
                pointerEvents: `${
                  !!this.state.dispute_description &&
                  !!this.state.dispute_reason &&
                  !!this.state.files.length &&
                  !!this.state.escalation_action.length
                    ? `visible`
                    : `none`
                }`,
              }}
              onClick={() => this.onSave()}
              loading={this.state.loading}
            >
              Continue
            </Button>
            <Button onClick={this.props.onCancel}>Cancel</Button>
          </div>
        }
      >
        <>
          <div className="fields esc-details ">
            <div className="field details">
              <p className="field-label"> WayBill Number (AWB): </p>
              <p className="field-value id">
                {" "}
                <span className="blue-text">
                  <a
                    href={shipment.tracking_url}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {shipment.awb}
                  </a>
                </span>
              </p>
            </div>
            <div className="field details">
              <p className="field-label"> {`${SHOPIFY_NAME}Id:`}</p>
              <p className="field-value id">
                <span className="blue-text">{shipment.order_shopify_name}</span>{" "}
              </p>
            </div>
            <div className="field details">
              <p className="field-label"> vF Order:</p>
              <p className="field-value id">
                {" "}
                <span className="blue-text">{shipment.order_vfid}</span>
              </p>
            </div>
          </div>

          {/* 


          <div className="dispute-header">
            <div>
              <p>
                WayBill Number:{" "}
                <span className="blue-text">{shipment.awb}</span>
              </p>
            </div>

            <div>
              <p style={{ margin: 0 }}>
                Shopify Id:{" "}
                <span className="blue-text">{shipment.order_shopify_name}</span>
              </p>
            </div>
          </div> */}
          {/* <div className="dispute-header">
            <div>
              <p style={{ margin: 0 }}>
                vF Order:{" "}
                <span className="blue-text">{shipment.order_vfid}</span>
              </p>
            </div>
          </div> */}
          <div className="dispute-form">
            <div className="dispute-reason">
              <p>
                <b style={{ color: "#f5222d" }} />
                Escalation Reason <span className="star">*</span>
              </p>
              <Select
                onDropdownVisibleChange={(val) =>
                  this.setState({ arrow2: val })
                }
                suffixIcon={
                  <svg
                    style={{
                      transition: "all 0.5s",
                      transform: `${
                        this.state.arrow2 ? "rotateX(180deg)" : "rotateX(0deg)"
                      }`,
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_33"
                      data-name="Polygon 33"
                      transform="translate(16 8) rotate(180)"
                      fill={`${this.state.arrow2 ? "898989" : "none"}`}
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#898989"
                      />
                    </g>
                  </svg>
                }
                placeholder="Select a valid escalation reason"
                onChange={(val) => this.handleChange2("dispute_reason", val)}
                style={{ width: 200 }}
              >
                {Object.keys(reasons).map((reason) => (
                  <Option
                    value={reason}
                    key={reason}
                    className="op dispute-option"
                  >
                    {reasons[reason]}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="dispute-reason">
              <p>
                <b style={{ color: "#f5222d" }} />
                Desired outcome from escalation <span className="star">*</span>
              </p>
              <Select
                onDropdownVisibleChange={(val) =>
                  this.setState({ arrow9: val })
                }
                suffixIcon={
                  <svg
                    style={{
                      transition: "all 0.5s",
                      transform: `${
                        this.state.arrow9 ? "rotateX(180deg)" : "rotateX(0deg)"
                      }`,
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_33"
                      data-name="Polygon 33"
                      transform="translate(16 8) rotate(180)"
                      fill={`${this.state.arrow9 ? "898989" : "none"}`}
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#898989"
                      />
                    </g>
                  </svg>
                }
                placeholder="Expected resolution of escalations."
                onChange={(val) => this.handleChange3("escalation_action", val)}
                style={{ width: 200 }}
              >
                {Object.keys(escalationAction).map((action) => (
                  <Option
                    value={action}
                    key={action}
                    className="op dispute-option"
                  >
                    {escalationAction[action]}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="dispute-reason">
              <p>
                {" "}
                <b style={{ color: "#f5222d" }} />
                Escalation Description <span className="star">*</span>
              </p>
              <TextArea
                rows={4}
                placeholder="Please mention the reason for raising an NDR escalation."
                onChange={(e) =>
                  this.handleChange("dispute_description", e.target.value)
                }
              />
            </div>
            <div className="dispute-reason">
              <p>
                <b style={{ color: "#f5222d" }} />
                Upload Attachments(jpg, png, jpeg,webp, wav, mp4, gif, mp3, asc,
                wmv, avi, mkv )<span className="star">*</span>
              </p>
              <Upload
                {...props}
                accept=".jpg,.png,.jpeg,.webp,.wav,.mp4,.gif,.mp3,.asc"
              >
                <Button>
                  <Icon type="upload" /> Upload Image/ Video
                </Button>
              </Upload>
            </div>

            <div className="collapse_infromation">
              <Collapse bordered={true} accordion={true}>
                <p className="important_guide_txt">
                  <span className="book_img">
                    <img src={book} alt="#" />
                  </span>
                  <span>Important Guidelines</span>
                </p>
                <Panel
                  header={
                    <p className="following_instructions">
                      Please upload the right media to allow us to take action
                      on your escalation, otherwise the escalation will be
                      marked obsolete
                    </p>
                  }
                  key="1"
                  showArrow={true}
                >
                  <div className="panel-content">
                    <div className="left-content">
                      <p className="uploD_txt">
                        Upload media in evidence for the escalation
                      </p>

                      <ul className="upload_instructions">
                        <li>
                          1. Clear images of the pre-shipped packaging, post
                          shipped packaging.
                        </li>
                        <li>
                          2. Clear images showing the shipping label to confirm
                          the right product.
                        </li>
                        <li>3. A video showing the unboxing of the product.</li>
                      </ul>
                      <p>
                        <sapn className="note_txt">NOTE: </sapn>
                        <span className="vfulfil_txt">
                          vFulfill team will use these images and videos as
                          evidence to file a escalation with the courier and
                          initiate the refund process if eligibility is met.
                        </span>
                      </p>
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </div>
        </>
      </Modal>
    );
  }
}

export default DisputeModal;
