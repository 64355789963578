import React, { Component } from "react";
import { Checkbox, Icon } from "antd";
import Header from "../../../Header";
import { ReactComponent as individualican } from "../../../../../assets/images/icons/individualican.svg";
import { ReactComponent as organisationican } from "../../../../../assets/images/icons/organisationican.svg";
// import { ReactComponent as arrowican } from "../../../../../assets/images/icons/arrow.svg";

import { ReactComponent as indainflagican } from "../../../../../assets/images/icons/indianFlag.svg";

import "./index.scss";

class KycIndividualOrganisation extends Component {
  render() {
    return (
      <>
        <div className="kyc-individual-organisation">
          <Header
            title="Let Us Know More About You"
            subtitle="Let’s get done with completing & verifying your profile info"
          />

          <p className="deDisclaimer-txt">
            <b className="disclaimer">NOTE:</b> If you work with your own GST
            you are required to register as an organisation.
          </p>

          <div className="checkbox-card">
            <div className="individual-card">
              <div className="checkbox-profile-type-card">
                <Checkbox
                  checked={this.props.kycType === "Individual"}
                  onChange={() => this.props.setKYCType("Individual")}
                >
                  <p className="small-indian-flag">
                    <Icon component={indainflagican} />
                  </p>
                  <p>
                    <Icon component={individualican} />
                  </p>

                  <p className="flag">
                    I am an <span className="registered-india">Individual</span>{" "}
                    operating{" "}
                    <span className="registered-india">without GST</span> in
                    India
                  </p>
                  <p className="footer">Continue</p>
                </Checkbox>
              </div>
            </div>

            <div className="organisation-card">
              <div className="checkbox-profile-type-card">
                <Checkbox
                  checked={this.props.kycType === "Organisation"}
                  onChange={() => this.props.setKYCType("Organisation")}
                >
                  <p className="organisation-small-indian-flag">
                    <Icon component={indainflagican} />
                  </p>
                  <p>
                    <Icon component={organisationican} />
                  </p>

                  <p className="flag">
                    I am an{" "}
                    <span className="not-registered-india">Organization</span>{" "}
                    operating{" "}
                    <span className="not-registered-india">with GST</span> in
                    India
                  </p>
                  <p className="footer">Continue</p>
                </Checkbox>
              </div>
              {/* <button
                className="back-btn"
                onClick={() => this.props.toggleFirststepBackbutton()}
              >
                <span>
                  <Icon component={arrowican} />
                </span>
                Previous Step
              </button> */}
            </div>
          </div>
          <div className="skip-now">
            <a href="#!" onClick={() => this.props.goToNextStep(false)}>
              Skip For Now
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default KycIndividualOrganisation;
