import React, { Component } from "react";
import {
  Divider,
  Button,
  //   Switch,
  //   Alert,
  Input,
  Select,
  Radio,
  Icon,
  Row,
  Col,
  Tooltip,
} from "antd";
import "./index.scss";
// import { ReactComponent as warehouse } from "../../../../assets/images/icons/warehouse.svg";
import { ReactComponent as house } from "../../../../assets/images/icons/rtohouse.svg";
import { ISDCodes } from "../../../../Helpers/ISD";
const { Option } = Select;

class RTOSettings extends Component {
  state = {
    settings: {
      buyback: 0,
      address_line_1: "",
      address_line_2: "",
      city: "",
      province: "",
      zip: "",
      country: "IN",
      contact_1: "",
      contact_2: "",
      email: "",
      location: "vf_fc",
      // note: "",
      prefix_1: "IN +91",
      prefix_2: "IN +91",
      ...this.props.settings,
    },
    errors: {},
    hideDescription: false,
  };

  onChangeBuyback(value) {
    if (value === "off") {
      this.setState(
        {
          settings: {
            ...this.state.settings,
            buyback: value,
          },

          hideDescription: true,
        },
        () => this.updateSetting()
      );
      // setTimeout(, 1000)
    } else {
      this.setState(
        {
          settings: {
            ...this.state.settings,
            buyback: value,
          },
          hideDescription: false,
        },
        () => this.updateSetting()
      );

      // setTimeout(, 1000)
    }
  }

  toggleDescription() {
    this.setState({
      hideDescription: !this.state.hideDescription,
    });
  }

  changeState = (field, value) => {
    this.setState(
      {
        settings: {
          ...this.state.settings,
          [field]: value,
        },
      },
      () => this.props.onSettingChange()
    );
  };

  updateSetting() {
    let errors = {};
    if (this.state.settings.location === "self") {
      if (this.state.settings.address_line_1.trim() === "") {
        errors.address_line_1 = "This is a required field!";
      }
      if (this.state.settings.city.trim() === "") {
        errors.city = "This is a required field!";
      }
      if (this.state.settings.province.trim() === "") {
        errors.province = "This is a required field!";
      }
      if (this.state.settings.zip.trim() === "") {
        errors.zip = "This is a required field!";
      }
      if (this.state.settings.country.trim() === "") {
        errors.country = "This is a required field!";
      }
      if (this.state.settings.contact_1.trim() === "") {
        errors.contact_1 = "This is a required field!";
      }
      if (this.state.settings.email.trim() === "") {
        errors.email = "This is a required field!";
      }

      this.setState({
        errors: errors,
      });
    }

    if (Object.keys(errors).length === 0) {
      this.props.onUpdate(this.state.settings);
    }
  }

  render() {
    return (
      <div className="settings-container other-settings-container">
        <h3 className="title">
          RTO Settings
          {/* <Button
            type="primary"
            style={{ float: "right" }}
            onClick={() => this.updateSetting()}
          >
            Save Settings
          </Button> */}
        </h3>
        <Divider />
        <div className="options-container">
          <p className="buyback">Buyback Service</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "13px",
            }}
          >
            <Button type="link" onClick={() => this.toggleDescription()}>
              {`${this.state.hideDescription ? "Show" : "Hide"} Description`}
              <Icon type={this.state.hideDescription ? "down" : "up"} />
            </Button>
            <Radio.Group
              value={this.state.settings.buyback}
              buttonStyle="solid"
              onChange={(e) => this.onChangeBuyback(e.target.value)}
            >
              <Radio.Button
                value={1}
                style={{
                  background: this.state.settings.buyback === 1 && "#21da40",
                }}
              >
                On
              </Radio.Button>
              <Radio.Button
                value={0}
                style={{
                  background: this.state.settings.buyback === 0 && "#DA2121",
                }}
              >
                Off
              </Radio.Button>
            </Radio.Group>
          </div>
          {!this.state.hideDescription && (
            <div className="description">
              <p>
                <b>Buyback is a service provided by vFulfill </b>to help you
                from taking total loss in case a product is not accepted by the
                end customer. When it is active i.e. "Switched On", vFulfill
                will buy the product from you at a percentage of original price
                which varies from one product to another. Buyback is available
                on select products and they are tagged in the catalog.
              </p>
            </div>
          )}

          <Divider />
          <p>RTO Location</p>
          <div className="select-location">
            <div
              className={`${
                this.state.settings.location === "vf_fc"
                  ? "select-location-card selected"
                  : "select-location-card"
              }`}
              onClick={() => this.changeState("location", "vf_fc")}
            >
              <div className="select-header">
                <span />{" "}
                <p>{this.state.settings.location === "vf_fc" && "Selected"}</p>
              </div>
              <svg
                id="shipping-and-delivery"
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
              >
                <g
                  id="Group_1110"
                  data-name="Group 1110"
                  transform="translate(0 0)"
                >
                  <g id="Group_1109" data-name="Group 1109">
                    <path
                      id="Path_1"
                      data-name="Path 1"
                      d="M47.6,13.712,24.4.112A.8.8,0,0,0,23.614.1L.414,12.9A.8.8,0,0,0,0,13.6V47.2a.8.8,0,0,0,.8.8h4a.8.8,0,0,0,.8-.8v-28H42.4v28a.8.8,0,0,0,.8.8h4a.8.8,0,0,0,.8-.8V14.4A.8.8,0,0,0,47.6,13.712ZM46.4,46.4H44v-28a.8.8,0,0,0-.8-.8H4.8a.8.8,0,0,0-.8.8v28H1.6V14.074L23.988,1.721,46.4,14.86V46.4Z"
                      transform="translate(0 -0.002)"
                      fill="#2bc822"
                    />
                  </g>
                </g>
                <g
                  id="Group_1112"
                  data-name="Group 1112"
                  transform="translate(27.683 35.633)"
                >
                  <g id="Group_1111" data-name="Group 1111">
                    <path
                      id="Path_2"
                      data-name="Path 2"
                      d="M327.217,392.531h-10.6a.882.882,0,0,0-.883.883v10.6a.882.882,0,0,0,.883.883h10.6a.882.882,0,0,0,.883-.883v-10.6A.882.882,0,0,0,327.217,392.531Zm-.883,10.6H317.5V394.3h8.833Z"
                      transform="translate(-315.734 -392.531)"
                      fill="#2bc822"
                    />
                  </g>
                </g>
                <g
                  id="Group_1114"
                  data-name="Group 1114"
                  transform="translate(32.42 36.516)"
                >
                  <g id="Group_1113" data-name="Group 1113">
                    <path
                      id="Path_3"
                      data-name="Path 3"
                      d="M353.4,401.064v2.65h-1.767v-2.65h-1.767V404.6a.882.882,0,0,0,.883.883h3.533a.882.882,0,0,0,.883-.883v-3.533Z"
                      transform="translate(-349.866 -401.064)"
                      fill="#2bc822"
                    />
                  </g>
                </g>
                <g
                  id="Group_1116"
                  data-name="Group 1116"
                  transform="translate(19.366 35.633)"
                >
                  <g id="Group_1115" data-name="Group 1115">
                    <path
                      id="Path_4"
                      data-name="Path 4"
                      d="M224.817,392.531h-10.6a.883.883,0,0,0-.883.883v10.6a.883.883,0,0,0,.883.883h10.6a.882.882,0,0,0,.883-.883v-10.6A.882.882,0,0,0,224.817,392.531Zm-.883,10.6H215.1V394.3h8.833Z"
                      transform="translate(-213.334 -392.531)"
                      fill="#2bc822"
                    />
                  </g>
                </g>
                <g
                  id="Group_1118"
                  data-name="Group 1118"
                  transform="translate(22.931 36.516)"
                >
                  <g id="Group_1117" data-name="Group 1117">
                    <path
                      id="Path_5"
                      data-name="Path 5"
                      d="M251,401.064v2.65h-1.767v-2.65h-1.767V404.6a.883.883,0,0,0,.883.883h3.533a.882.882,0,0,0,.883-.883v-3.533Z"
                      transform="translate(-247.466 -401.064)"
                      fill="#2bc822"
                    />
                  </g>
                </g>
                <g
                  id="Group_1120"
                  data-name="Group 1120"
                  transform="translate(27.683 26.337)"
                >
                  <g id="Group_1119" data-name="Group 1119">
                    <path
                      id="Path_6"
                      data-name="Path 6"
                      d="M327.217,290.131h-10.6a.882.882,0,0,0-.883.883v10.6a.883.883,0,0,0,.883.883h10.6a.882.882,0,0,0,.883-.883v-10.6A.882.882,0,0,0,327.217,290.131Zm-.883,10.6H317.5V291.9h8.833Z"
                      transform="translate(-315.734 -290.131)"
                      fill="#2bc822"
                    />
                  </g>
                </g>
                <g
                  id="Group_1122"
                  data-name="Group 1122"
                  transform="translate(32.42 27.734)"
                >
                  <g id="Group_1121" data-name="Group 1121">
                    <path
                      id="Path_7"
                      data-name="Path 7"
                      d="M353.4,298.664v2.65h-1.767v-2.65h-1.767V302.2a.882.882,0,0,0,.883.883h3.533a.882.882,0,0,0,.883-.883v-3.533Z"
                      transform="translate(-349.866 -298.664)"
                      fill="#2bc822"
                    />
                  </g>
                </g>
                <g
                  id="Group_1124"
                  data-name="Group 1124"
                  transform="translate(5.3 23.913)"
                >
                  <g id="Group_1123" data-name="Group 1123">
                    <rect
                      id="Rectangle_223"
                      data-name="Rectangle 223"
                      width="37.4"
                      height="1.767"
                      fill="#2bc822"
                    />
                  </g>
                </g>
                <g
                  id="Group_1126"
                  data-name="Group 1126"
                  transform="translate(5.3 20.725)"
                >
                  <g id="Group_1125" data-name="Group 1125">
                    <rect
                      id="Rectangle_224"
                      data-name="Rectangle 224"
                      width="37.4"
                      height="1.767"
                      fill="#2bc822"
                    />
                  </g>
                </g>
                <g
                  id="Group_1128"
                  data-name="Group 1128"
                  transform="translate(18.7 11.07)"
                >
                  <g id="Group_1127" data-name="Group 1127">
                    <path
                      id="Path_8"
                      data-name="Path 8"
                      d="M214.518,119.464h-8.833a.884.884,0,0,0-.883.883v3.533a.884.884,0,0,0,.883.883h8.833a.883.883,0,0,0,.883-.883v-3.533A.883.883,0,0,0,214.518,119.464ZM213.634,123h-7.067v-1.767h7.067Z"
                      transform="translate(-204.801 -119.464)"
                      fill="#2bc822"
                    />
                  </g>
                </g>
              </svg>

              <p>vF Warehouse</p>
            </div>

            <div
              className={`${
                this.state.settings.location === "self"
                  ? "select-location-card selected"
                  : "select-location-card"
              }`}
            >
              <div className="select-header">
                <span />{" "}
                <p>{this.state.settings.location === "self" && "Selected"}</p>
              </div>
              <Icon component={house} />
              <p>
                My Address <br />
                <small>
                  Coming Soon{" "}
                  <Tooltip title="You will soon be able to receive RTO shipments at your own address.">
                    <Icon type="info-circle" style={{ fontSize: 11 }} />
                  </Tooltip>
                </small>
              </p>
            </div>
          </div>
          <div className="description">
            {this.state.settings.location === "vf_fc" && (
              <p>
                <b>You have selected RTO to be done to vF Warehouse.</b> In this
                case we will store the product in our vFulfill warehouse. It
                will be added back to your dashboard as an inventory and sent
                out whenever there is a new order for same product.
                <br />
                <b>
                  vFulfill charges weekly fees depending on the size of each RTO
                  product after one month from the date it was Returned.
                </b>
              </p>
            )}
            {this.state.settings.location === "self" && (
              <p>
                <b>You have selected RTO to be done to your address.</b> In this
                case we will need you to provide us with the address you need
                the product to be returned to.
              </p>
            )}
          </div>
          {this.state.settings.location === "self" && (
            <>
              <p style={{ marginTop: "24px" }}>RTO Address</p>
              <Row className="rto-address" gutter={30}>
                <Col
                  md={24}
                  className={`ant-form-item-control ${
                    this.state.errors.address_line_1 ? "has-error" : ""
                  }`}
                >
                  <label>Address Line 1</label>
                  <Input
                    type="text"
                    placeholder="Enter Address Line 1"
                    value={this.state.settings.address_line_1}
                    onChange={(e) =>
                      this.changeState("address_line_1", e.target.value)
                    }
                    // style={{ marginBottom: 15 }}
                  />
                  {this.state.errors.address_line_1 && (
                    <div className="ant-form-explain">
                      {this.state.errors.address_line_1}
                    </div>
                  )}
                </Col>
                <Col md={24} className={`ant-form-item-control`}>
                  <label>Address Line 2</label>
                  <Input
                    type="text"
                    placeholder="Enter Address Line 2"
                    value={this.state.settings.address_line_2}
                    onChange={(e) =>
                      this.changeState("address_line_2", e.target.value)
                    }
                    // style={{ marginBottom: 15 }}
                  />
                </Col>
                <Col
                  md={12}
                  className={`ant-form-item-control ${
                    this.state.errors.city ? "has-error" : ""
                  }`}
                >
                  <label>City</label>
                  <Input
                    type="text"
                    placeholder="Enter City Name"
                    value={this.state.settings.city}
                    onChange={(e) => this.changeState("city", e.target.value)}
                    // style={{ marginBottom: 15 }}
                  />
                  {this.state.errors.city && (
                    <div className="ant-form-explain">
                      {this.state.errors.city}
                    </div>
                  )}
                </Col>
                <Col
                  md={12}
                  className={`ant-form-item-control ${
                    this.state.errors.province ? "has-error" : ""
                  }`}
                >
                  <label>State</label>
                  <Input
                    type="text"
                    placeholder="Enter State Name"
                    value={this.state.settings.province}
                    onChange={(e) =>
                      this.changeState("province", e.target.value)
                    }
                    // style={{ marginBottom: 15 }}
                  />
                  {this.state.errors.province && (
                    <div className="ant-form-explain">
                      {this.state.errors.province}
                    </div>
                  )}
                </Col>
                <Col
                  md={12}
                  className={`ant-form-item-control ${
                    this.state.errors.country ? "has-error" : ""
                  }`}
                >
                  <label>Country</label>
                  <Input
                    type="text"
                    placeholder="Enter Country Name"
                    value={this.state.settings.country}
                    onChange={(e) =>
                      this.changeState("country", e.target.value)
                    }
                    disabled={true}
                    // style={{ marginBottom: 15 }}
                  />
                  {this.state.errors.country && (
                    <div className="ant-form-explain">
                      {this.state.errors.country}
                    </div>
                  )}
                </Col>
                <Col
                  md={12}
                  className={`ant-form-item-control ${
                    this.state.errors.zip ? "has-error" : ""
                  }`}
                >
                  <label>Pincode</label>
                  <Input
                    type="text"
                    placeholder="Enter Pincode"
                    value={this.state.settings.zip}
                    onChange={(e) => this.changeState("zip", e.target.value)}
                    // style={{ marginBottom: 15 }}
                  />
                  {this.state.errors.zip && (
                    <div className="ant-form-explain">
                      {this.state.errors.zip}
                    </div>
                  )}
                </Col>
                <Col
                  md={12}
                  className={`ant-form-item-control ${
                    this.state.errors.contact_1 ? "has-error" : ""
                  }`}
                >
                  <label>Contact Number 1</label>
                  <Input.Group
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Select
                      style={{ width: 100, marginTop: "7px" }}
                      value={this.state.settings.prefix_1}
                      onChange={(val) => this.changeState("prefix_1", val)}
                    >
                      {ISDCodes.map((isd) => (
                        <Option
                          key={`${isd.iso2.toUpperCase()} +${isd.dialCode}`}
                        >
                          {isd.iso2.toUpperCase()} +{isd.dialCode}
                        </Option>
                      ))}
                    </Select>
                    <Input
                      type="text"
                      placeholder="Enter Contact Number 1"
                      value={this.state.settings.contact_1}
                      onChange={(e) =>
                        this.changeState("contact_1", e.target.value)
                      }
                      // style={{ marginBottom: 15 }}
                    />
                  </Input.Group>

                  {this.state.errors.contact_1 && (
                    <div className="ant-form-explain">
                      {this.state.errors.contact_1}
                    </div>
                  )}
                </Col>
                <Col
                  md={12}
                  className={`ant-form-item-control ${
                    this.state.errors.contact_2 ? "has-error" : ""
                  }`}
                >
                  <label>Contact Number 2</label>
                  <Input.Group
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Select
                      style={{ width: 100, marginTop: "7px" }}
                      value={this.state.settings.prefix_2}
                      onChange={(val) => this.changeState("prefix_2", val)}
                    >
                      {ISDCodes.map((isd) => (
                        <Option
                          key={`${isd.iso2.toUpperCase()} +${isd.dialCode}`}
                        >
                          {isd.iso2.toUpperCase()} +{isd.dialCode}
                        </Option>
                      ))}
                    </Select>
                    <Input
                      type="text"
                      placeholder="Enter Contact Number 2"
                      value={this.state.settings.contact_2}
                      onChange={(e) =>
                        this.changeState("contact_2", e.target.value)
                      }
                      // style={{ marginBottom: 15 }}
                    />
                  </Input.Group>
                  {this.state.errors.contact_2 && (
                    <div className="ant-form-explain">
                      {this.state.errors.contact_2}
                    </div>
                  )}
                </Col>
                <Col
                  md={24}
                  className={`ant-form-item-control ${
                    this.state.errors.email ? "has-error" : ""
                  }`}
                >
                  <label>Email Id</label>
                  <Input
                    type="text"
                    placeholder="Enter Email Id"
                    value={this.state.settings.email}
                    onChange={(e) => this.changeState("email", e.target.value)}
                    // style={{ marginBottom: 15 }}
                  />
                  {this.state.errors.email && (
                    <div className="ant-form-explain">
                      {this.state.errors.email}
                    </div>
                  )}
                </Col>
                {/* <Col
                  md={24}
                  className={`ant-form-item-control ${
                    this.state.errors.note ? "has-error" : ""
                  }`}
                >
                  <label>Enter A Note</label>
                  <Input.TextArea
                    type="text"
                    placeholder="Enter A Note Here"
                    value={this.state.settings.note}
                    onChange={(e) => this.changeState("note", e.target.value)}
                  />
                </Col> */}
                <Button
                  type="primary"
                  style={{ float: "right" }}
                  onClick={() => this.updateSetting()}
                >
                  Save Settings
                </Button>
              </Row>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default RTOSettings;
