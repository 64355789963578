import React, { Component } from "react";
import { DatePicker, Button } from "antd";
import moment from "moment";
import Globalsearch from "../../Common/Globalsearch";
import { hasPermission } from "../../../../Helpers/Permissions";
import "./index.scss";

const { RangePicker } = DatePicker;

class DisputeNav extends Component {
  state = {
    allBtn: false,
    openbtn: true,
    closebtn: false,
    pendingbtn: false,
    mount: false,
  };

  componentDidUpdate() {
    let status = this.props.filters.status;

    if (status === "all" && this.state.mount !== true) {
      this.setState({
        allBtn: true,
        openbtn: false,
        closebtn: false,
        pendingbtn: false,
        mount: true,
      });
    }
  }

  render() {
    return (
      <div className="picing-request-nav fullorder_data">
        <div className="request-nav">
          <div className="ordfill">
            <div className="ordfillwrap">
              <div className="left">
                <div className="content-page-title breadcurmb-txt">
                  Escalations <span style={{ margin: "0 8px" }}>&gt;</span>
                  <span className="escalations-txt">Delivered Escalations</span>
                </div>
              </div>

              <div className="right">
                <p className="show-data-from">Show Data From: </p>

                <RangePicker
                  disabledDate={(current) => {
                    return current > moment();
                  }}
                  className="range-picker"
                  value={[
                    this.props.filters.startDate === ""
                      ? null
                      : moment(this.props.filters.startDate, "YYYY-MM-DD"),
                    this.props.filters.endDate === ""
                      ? null
                      : moment(this.props.filters.endDate, "YYYY-MM-DD"),
                  ]}
                  ranges={{
                    Today: [moment(), moment()],
                    "This Week": [
                      moment().startOf("week"),
                      moment().endOf("week"),
                    ],
                    "Last 15 Days": [moment().subtract("days", 15), moment()],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                    "Last Month": [
                      moment()
                        .subtract(1, "month")
                        .startOf("month"),
                      moment()
                        .subtract(1, "month")
                        .endOf("month"),
                    ],
                    "Last Year": [
                      moment()
                        .subtract(1, "year")
                        .startOf("year"),
                      moment()
                        .subtract(1, "year")
                        .endOf("year"),
                    ],
                  }}
                  onChange={(_, dateString) =>
                    this.props.changeFilter2({
                      startDate: dateString[0],
                      endDate: dateString[1],
                    })
                  }
                />
              </div>
            </div>
            <div className="secondwrap">
              <div className="filtertab">
                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-escalations-underline-animation "
                      : this.state.openbtn
                      ? "tab  hover-escalations-underline-animation-click"
                      : "tab hover-escalations-underline-animation"
                  }
                >
                  <button
                    className={this.state.openbtn ? "active" : ""}
                    onClick={() => {
                      this.setState({
                        allBtn: false,
                        openbtn: true,
                        closebtn: false,
                        pendingbtn: false,
                      });
                      this.props.changeFilter2({
                        status: "",
                        startDate: "",
                        endDate: "",
                      });
                    }}
                  >
                    Open
                  </button>

                  <div
                    className={` ${
                      this.state.openbtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.tabcounts && this.props.tabcounts.open
                      ? this.props.tabcounts.open > 1000
                        ? (Number(this.props.tabcounts.open) / 1000).toFixed(
                            1
                          ) + "k"
                        : this.props.tabcounts.open < 1000
                        ? this.props.tabcounts.open
                        : null
                      : this.props.tabcounts.open === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-escalations-underline-animation "
                      : this.state.pendingbtn
                      ? "tab  hover-escalations-underline-animation-click"
                      : "tab hover-escalations-underline-animation"
                  }
                >
                  <button
                    className={this.state.pendingbtn ? "active" : ""}
                    onClick={() => {
                      this.setState({
                        allBtn: false,
                        openbtn: false,
                        closebtn: false,
                        pendingbtn: true,
                      });
                      this.props.changeFilter2({
                        status: "in_processing",
                        startDate: "",
                        endDate: "",
                      });
                    }}
                  >
                    In-Processing
                  </button>

                  <div
                    className={` ${
                      this.state.pendingbtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.tabcounts && this.props.tabcounts.pending
                      ? this.props.tabcounts.pending > 1000
                        ? (Number(this.props.tabcounts.pending) / 1000).toFixed(
                            1
                          ) + "k"
                        : this.props.tabcounts.pending < 1000
                        ? this.props.tabcounts.pending
                        : null
                      : this.props.tabcounts.pending === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-escalations-underline-animation "
                      : this.state.closebtn
                      ? "tab  hover-escalations-underline-animation-click"
                      : "tab hover-escalations-underline-animation"
                  }
                >
                  <button
                    className={this.state.closebtn ? "active" : ""}
                    onClick={() => {
                      this.setState({
                        allBtn: false,
                        openbtn: false,
                        closebtn: true,
                        pendingbtn: false,
                      });
                      this.props.changeFilter2({
                        status: "close",
                        startDate: "",
                        endDate: "",
                      });
                    }}
                  >
                    Closed
                  </button>

                  <div
                    className={` ${
                      this.state.closebtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.tabcounts && this.props.tabcounts.close
                      ? this.props.tabcounts.close > 1000
                        ? (Number(this.props.tabcounts.close) / 1000).toFixed(
                            1
                          ) + "k"
                        : this.props.tabcounts.close < 1000
                        ? this.props.tabcounts.close
                        : null
                      : this.props.tabcounts.close === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-escalations-underline-animation "
                      : this.state.allBtn
                      ? "tab  hover-escalations-underline-animation-click"
                      : "tab hover-escalations-underline-animation"
                  }
                >
                  <button
                    className={this.state.allBtn ? "active" : ""}
                    onClick={() => {
                      this.setState({
                        allBtn: true,
                        openbtn: false,
                        closebtn: false,
                        pendingbtn: false,
                      });
                      this.props.changeFilter2({
                        status: "all",
                        startDate: "",
                        endDate: "",
                      });
                    }}
                  >
                    All
                  </button>

                  <div
                    className={` ${
                      this.state.allBtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.tabcounts && this.props.tabcounts.all
                      ? this.props.tabcounts.all > 1000
                        ? (Number(this.props.tabcounts.all) / 1000).toFixed(1) +
                          "k"
                        : this.props.tabcounts.all < 1000
                        ? this.props.tabcounts.all
                        : null
                      : this.props.tabcounts.all === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>
              </div>
              <div className="download-csv">
                {hasPermission(this.props.store, "raise_dispute") && (
                  <Button
                    className="clickable"
                    onClick={() => this.props.toggleAddDisputeModal()}
                  >
                    Add New Escalation
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-data">
          <div className="Globalsearch">
            <Globalsearch
              class={"nextwidth"}
              data={this.props.filters}
              tooltip={true}
              placeholder={
                "Search by Escalation ID, AWB, Product ID, Product Name"
              }
              change={(value) =>
                this.props.changeHandler({ search: value.target.value })
              }
              searchFunc={(value) => this.props.changeFilter({ search: value })}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DisputeNav;
