import React from "react";
import { Divider, Input, message, Button, Modal } from "antd";
import axios from "axios";
import AppSubscriptionComplete from "../../../../AppSubscriptionComplete";
import "./index.scss";

class CouponCodeField extends React.Component {
  state = { couponCode: "", loading: false, showModal: false, modalMsg: "" };

  sendCoupon() {
    this.setState({ loading: true }, () => {
      axios({
        url: process.env.REACT_APP_API_URL + "/stripe/get_coupon_pricing",
        method: "post",
        withCredentials: true,
        data: {
          coupon_code: this.props.state.couponCode,
          plan_id: this.props.planId,
        },
      })
        .then((res) => {
          this.setState({ loading: false });

          if (res.data.success === 2) {
            return this.setState({ modalMsg: res.data.msg, showModal: true });
          }

          if (res.data.success === 1) {
            this.props.setState({
              clientSecretKeyLoading: true,
            });

            setTimeout(
              () =>
                this.props.setState({
                  clientSecretKey: res.data.client_secret,
                  couponPrice: res.data.new_plan_price,
                  clientSecretKeyLoading: false,
                }),
              2000
            );
          } else {
            message.error(res.data.msg);
          }
        })
        .catch((e) => {
          message.error(e.message);
          this.setState({
            loading: false,
          });
        });
    });
  }

  removeCoupon() {
    this.setState({ loading: true }, () => {
      axios({
        url: process.env.REACT_APP_API_URL + "/stripe/get_coupon_pricing",
        method: "post",
        withCredentials: true,
        data: {
          remove: 1,
          plan_id: this.props.planId,
        },
      })
        .then((res) => {
          this.setState({ loading: false });

          if (res.data.success === 1) {
            this.props.setState({
              clientSecretKeyLoading: true,
            });

            setTimeout(
              () =>
                this.props.setState({
                  clientSecretKey: res.data.client_secret,
                  couponCode: "",
                  couponPrice: "",
                  clientSecretKeyLoading: false,
                }),
              1000
            );
          } else {
            message.error(res.data.msg);
          }
        })
        .catch((e) => {
          message.error(e.message);
          this.setState({
            loading: false,
          });
        });
    });
  }

  render() {
    return (
      <div className="coupon-code-field">
        <Input
          disabled={!!this.props.state.couponPrice}
          value={this.props.state.couponCode}
          addonAfter={
            !!this.props.state.couponPrice ? (
              <Button
                loading={this.state.loading}
                type="primary"
                // disabled={this.props.state.couponCode.length === 0}
                onClick={() => this.removeCoupon()}
              >
                Remove
              </Button>
            ) : (
              <Button
                loading={this.state.loading}
                type="primary"
                disabled={this.props.state.couponCode.length === 0}
                onClick={() => this.sendCoupon()}
              >
                Apply
              </Button>
            )
          }
          onChange={(e) => this.props.setState({ couponCode: e.target.value })}
          placeholder="Coupon Code"
        />

        <Modal footer={null} visible={this.state.showModal}>
          <AppSubscriptionComplete text={this.state.modalMsg} custom={true} />
        </Modal>
      </div>
    );
  }
}

export default CouponCodeField;
