import React from "react";
import { Select } from "antd";
import "./ImportListProductBasic.scss";

class SingleTagSelect extends React.Component {
  state = {
    tag: this.props.value ? this.props.value : "",
    refreshingPrice: false,
    currentImgIndex: 0,
    requestCatalog: false,
    escalated: false,
    rejected: false,
  };
  onChange(value) {
    this.setState(
      {
        tag: value.length ? value[value.length - 1] : "",
      },
      () => {
        this.props.onChange(this.state.tag);
      }
    );
  }

  requestCatalog() {
    this.setState({ requestCatalog: true });
  }

  render() {
    return this.state.tag ? (
      <Select
        showSearch
        mode="tags"
        style={this.props.style}
        placeholder={this.props.placeholder}
        optionFilterProp="children"
        value={this.state.tag}
        onChange={(value) => this.onChange(value)}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {this.props.children}
      </Select>
    ) : (
      <Select
        showSearch
        mode="tags"
        style={this.props.style}
        placeholder={this.props.placeholder}
        optionFilterProp="children"
        onChange={(value) => this.onChange(value)}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {this.props.children}
      </Select>
    );
  }
}

export default SingleTagSelect;
