import React, { Component } from "react";
import "./index.scss";
import { Carousel } from "antd";
import TabFirstContainer from "./TabFirstContainer";
import TabSecondContainer from "./TabSecondContainer";
export default class LeftContainer extends Component {
  CarouselRef = React.createRef();
  render() {
    return (
      <div className="left-card-containerS">
        <Carousel  ref={this.CarouselRef} autoplay={false}  className="Tabs-container">
          
          <TabFirstContainer/>
         <TabSecondContainer/>
        </Carousel>
      </div>
    );
  }
}
