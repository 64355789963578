import React from "react";
import { Table, Row, Tooltip } from "antd";
import {
  getCurrencySymbol,
  formatNumber,
} from "../../../../../Helpers/Pricing";

const RTO = (props) => {
  const transaction = props.transaction;
  let items = [];

  if (transaction) {
    items = transaction.transaction_data.items;
  }

  const reviewColumns = [
    {
      title: "vFulfill Order ID",
      dataIndex: "vforderid",
      width: "9%",
      render: () => transaction.transaction_data.vforderid,
    },
    {
      title: "Order Details",
      dataIndex: "items",
      width: "40.5%",
      className: "products-list",
      render: () =>
        transaction.transaction_data.items.map((item) => (
          <Row
            key={item.lineitem_id}
            style={{ display: "flex", alignItems: "start" }}
          >
            <img src={item.img} alt="" style={{ width: 50 }} />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: 10,
              }}
            >
              <p
                style={{
                  flex: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: 215,
                  marginBottom: 5,
                }}
              >
                <Tooltip title={item.name} placement="topLeft">
                  {item.name}
                </Tooltip>
              </p>

              <p style={{ flex: 1, margin: 0, marginBottom: 5 }}>
                <Tooltip title="vFulfill Selling Price">
                  <span style={{ background: "#f0f2f5", padding: "0 5px" }}>
                    {getCurrencySymbol("INR")} {formatNumber(item.item_mrp)} X{" "}
                    {item.quantity}
                  </span>
                </Tooltip>
              </p>
              <p>
                <span style={{ background: "#f0f2f5", padding: "0 5px" }}>
                  SKU: {item.sku}
                </span>
              </p>
            </div>
          </Row>
        )),
    },
    {
      title: "RTO buy back",
      dataIndex: "costs.product_total",
      width: "12.5%",
      render: () => (
        <p className="blue-text">
          {getCurrencySymbol("INR")}{" "}
          {formatNumber(
            Number(transaction.transaction_data.costs.product_total).toFixed(2)
          )}
        </p>
      ),
    },
    {
      title: "RTO Shipping",
      dataIndex: "costs.shipping.total",
      width: "12.5%",
      render: () => (
        <p className="blue-text">
          {getCurrencySymbol("INR")}{" "}
          {formatNumber(
            Number(transaction.transaction_data.costs.shipping.total).toFixed(2)
          )}
        </p>
      ),
    },
    {
      title: "COD Debit",
      dataIndex: "costs",
      width: "8.33%",
      render: () => {
        const costs = transaction.transaction_data.costs;
        let text = costs.cod && costs.cod.total ? costs.cod.total : 0;
        return (
          <p className="blue-text">
            {Number(text) === 0
              ? "N.A."
              : `${getCurrencySymbol("INR")} ${formatNumber(
                  Number(text).toFixed(2)
                )}`}
          </p>
        );
      },
    },
    {
      title: "Total",
      dataIndex: "costs",
      width: "10.34%",
      render: () => {
        return (
          <p className="blue-text">
            {getCurrencySymbol("INR")}{" "}
            {formatNumber(transaction.transaction_amount, 2)}
          </p>
        );
      },
    },
  ];

  return (
    <>
      <Table
        rowKey={(record) => `${record["orderid"]}_${record["lineitem_id"]}`}
        pagination={false}
        columns={reviewColumns}
        dataSource={items}
        scroll={{ y: 300 }}
      />
      {transaction && (
        <div
          style={{
            display: "table",
            width: "100%",
            padding: "10px 0",
            boxShadow: "0 -50px 50px rgba(0,0,0,0.25)",
            marginTop: "2px",
          }}
        >
          <div
            style={{
              width: "9%",
              display: "inline-block",
              padding: "4px 16px",
            }}
          />
          <div
            style={{
              width: "44%",
              display: "inline-block",
              padding: "4px 16px 4px 78px",
            }}
          >
            <h3 style={{ margin: 0 }}>Total</h3>
          </div>
          <div
            style={{
              width: "13.5%",
              display: "inline-block",
              padding: "4px 16px",
            }}
          >
            {/* {getCurrencySymbol("INR")}{" "}
            {formatNumber(
              Object.values(transaction.transaction_data).reduce(
                (acc, t) => acc + t.costs.product_total,
                0
              )
            )} */}
          </div>
          <div
            style={{
              width: "13.5%",
              display: "inline-block",
              padding: "4px 16px",
            }}
          >
            {/* {getCurrencySymbol("INR")}{" "}
            {formatNumber(
              Object.values(transaction.transaction_data).reduce(
                (acc, t) => acc + t.costs.shipping.total,
                0
              )
            )} */}
          </div>
          <div
            style={{
              width: "8.33%",
              display: "inline-block",
              padding: "4px 16px",
            }}
          >
            {/* {getCurrencySymbol("INR")}{" "}
            {formatNumber(
              Object.values(transaction.transaction_data).reduce(
                (acc, t) =>
                  acc +
                  (t.costs.cod && t.costs.cod.total ? t.costs.cod.total : 0),
                0
              )
            )} */}
          </div>
          <div
            style={{
              width: "10.34%",
              display: "inline-block",
              padding: "4px 16px",
              fontSize: 16,
            }}
          >
            <b>
              {getCurrencySymbol("INR")}{" "}
              {formatNumber(Number(transaction.transaction_amount), 2)}
            </b>
          </div>
        </div>
      )}
    </>
  );
};

export default RTO;
