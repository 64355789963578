import React, { Component } from "react";
import { Alert, Skeleton, Row, Icon, Card, Pagination } from "antd";
import PricingCard from "./PricingCard";
import axios from "axios";
import "./index.scss";
import NotFoundCard from "../../Common/NotFoundCard";
import ErrorCard from "../../Common/ErrorCard";
import PricingRequestNav from "./PricingRequestNav";
import qs from "qs";

export const statusName = {
  pending: "Pending",
  approved: "Approved",
  sourced: "Sourced",
  transit: "Transit",
  inventory_live: "Inventory Live",
  cancelled: "Cancelled",
};
export const statusColor = {
  pending: "#FC824E",
  approved: "#15dd24",
  sourced: "#43A0E8",
  transit: "#DDD715",
  inventory_live: "#15DD24",
  cancelled: "#FC824E",
};

class SourcingRequests extends Component {
  state = {
    filters: {
      search: "",
      status: "pending",
    },
    total: 0,
    paging: {
      page: 1,
      pageSize: 25,
    },
    orders: [],
    loading: false,
    error: false,
    errorMsg: "",
    tabcounts: [],
  };

  //  this function used for fetch  all request and  show  all data in order state
  fetchOrders() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/pricingrequest/get_request",
          method: "POST",
          data: {
            storeid: this.props.match.params.storeid,
            filters: this.state.filters,
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                orders: res.data.data,
                total: res.data.total,
                tabcounts: res.data.tab_counts,
              });
            } else {
              this.setState({
                loading: false,
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((e) => {
            if (!!e.request) {
              this.setState({
                error: true,
                errorCode: e.request.status,
                errorMsg: e.request.statusText,
                loading: false,
              });
            } else {
              this.setState({
                error: false,
                errorMsg: e.message,
                loading: true,
              });
            }
          });
      }
    );
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search.replace(/\?/g, ""));

    if (params.search) {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: params.search,
          },
        },
        () => this.fetchOrders()
      );
    } else {
      this.fetchOrders();
    }
  }

  // this function used for event handler in  global search
  changeHandler(kvpairs) {
    this.setState({
      filters: { ...this.state.filters, ...kvpairs },
    });
  }

  // this function used  for filter the data in  global search
  changeFilter(filters) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...filters,
        },
      },
      () => this.fetchOrders()
    );
  }

  // this function used for go to particular page in  pagination
  goToPage(page) {
    this.setState(
      {
        loading: true,
        paging: {
          ...this.state.paging,
          page,
        },
      },
      () => this.fetchOrders()
    );
  }

  // this function used  for show page in  pagination
  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        paging: { ...this.state.paging, pageSize: pageSize },
      },
      () => this.fetchOrders()
    );
  }

  render() {
    const PageWrapper = (children) => {
      return (
        <div className="dropdown-data">
          <PricingRequestNav
            filters={this.state.filters}
            changeFilter={(e) => this.changeFilter(e)}
            changeHandler={(e) => this.changeHandler(e)}
            tabcounts={this.state.tabcounts}
            loading={this.state.loading}
          />
          <div className="pricing-requests-list">{children}</div>
        </div>
      );
    };

    if (this.state.loading) {
      return PageWrapper(
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <ErrorCard
          errorMsg={this.state.errorMsg}
          errorCode={this.state.errorCode}
        />
      );
    }

    if (
      !this.state.loading &&
      !this.state.error &&
      this.state.orders.length === 0
    ) {
      return PageWrapper(
        <NotFoundCard
          mainHeading={"Oops! No Data Found."}
          secondHeading={<p>Looks like there are no products in this page.</p>}
        />
      );
    }

    return PageWrapper(
      <>
        {this.state.orders.map((o) => (
          <PricingCard
            request={o}
            key={o.request_id["$oid"]}
            storeid={this.props.match.params.storeid}
            geo={this.props.match.params.storegeo}
            statusName={statusName}
            statusColor={statusColor}
          />
        ))}
        <Pagination
          locale={{ jump_to: "Go to page" }}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          showQuickJumper
          showSizeChanger
          pageSizeOptions={["10", "25", "50"]}
          onShowSizeChange={(p, d) => this.onShowSizeChange(p, d)}
          current={this.state.paging.page}
          pageSize={this.state.paging.pageSize}
          total={this.state.total}
          onChange={(page) => this.goToPage(page)}
        />
      </>
    );
  }
}

export default SourcingRequests;
