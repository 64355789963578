import React from "react";
import { Modal, Skeleton, Tabs, message, Icon } from "antd";
import StripePaymentForm from "./StripeScreen";
import axios from "axios";

import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import "./index.scss";

const { TabPane } = Tabs;

class EditPaymentModal extends React.Component {
  state = {
    activeKey: "1",
    plans: null,
    selectedPlan: null,
    loading: false,
    planType: "month",
    isPlanLoading: true,
    planName: "",
    clientSecretKey: "",
    trial: false,
    plan_id: "",
    website_signup: {},
    isFromWebsite: false,
  };

  render() {
    return (
      <Modal
        destroyOnClose={true}
        footer={null}
        className="edit-payment-modal"
        onCancel={() => this.props.setState({ editPaymentModal: false })}
        visible={true}
      >
        <StripePaymentForm
          loadData={() => this.props.loadData()}
          trial={this.state.trial}
          state={this.state}
          setState={(state) => this.props.setState(state)}
          plans={this.state.plans}
          planName={this.state.planName}
          planType={this.state.planType}
          //   clientSecretKey={this.state.clientSecretKey}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(EditPaymentModal);
