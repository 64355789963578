import { Button, Modal } from "antd";
import { data } from "browserslist";
import React, { Component } from "react";
import "./index.scss";
export default class VariantModal extends Component {
  render() {
    const { qty, modalData, name, type } = this.props;
    console.log(this.props);
    return (
      <Modal
        className="rfq-variant-modal"
        title="Variant Breakdown"
        width={600}
        footer={<Button onClick={() => this.props.onCancel()}>Close</Button>}
        destroyOnClose={true}
        visible={this.props.modalData.visible}
        onCancel={() => this.props.onCancel()}
      >
        <div className="modal-data-container">
          <div className="heading-row">
            <div className="img-details">
              {/* <img src={modalData.variantData[0].image} alt="" /> */}
              <div className="text-wraper">
                <span className="name-pro">{modalData.name}</span>
              </div>
            </div>

            <div className="heading-qty">Qty</div>
          </div>
          <div>
            {modalData.variantData.map((v) => {
              return (
                <div className="variant-row">
                  {" "}
                  <div className="img-details">
                    <img
                      src={
                        this.props.modalData.type === "external"
                          ? v.images[0]
                          : v.image
                      }
                      alt=""
                    />
                    <div className="text-wraper">
                      <span>{v.name}</span>

                      <div className="vf">
                        <span>Specs:</span>{" "}
                        {this.props.modalData.type === "external"
                          ? v.vfsku + " (" + v.allspecs + ")"
                          : v.specs}
                      </div>
                    </div>
                  </div>
                  <div className="qty-variant">
                    {this.props.modalData.type === "external" ? (
                      <span>{modalData.qty}</span>
                    ) : (
                      v.qty
                    )}
                  </div>{" "}
                </div>
              );
            })}
          </div>
          {/* <div className="Total-row">
            <div className="total">Total</div>{" "}
            <div className="qty">{modalData.qty}</div>
          </div> */}
        </div>
      </Modal>
    );
  }
}
