import React, { Component } from "react";
import { Tabs, Button } from "antd";
import "./index.scss";
import SignInSection from "./SignInSection";
import ForgotPasswordForm from "./ForgotPasswordForm";
const { TabPane } = Tabs;
class RightContainer extends Component {
  state = {
    activeKey: "1",
    email: "",
    password: "",
  };

  componentDidMount() {
    window.location.assign(process.env.REACT_APP_NEW_APP + "/login");
  }

  render() {
    return (
      <div className="right-container-signup">
        <Tabs activeKey={this.state.activeKey}>
          <TabPane key="1">
            {this.state.activeKey === "1" ? (
              <SignInSection
                title={this.props.title}
                changeTab={() => {
                  this.setState({
                    activeKey: "2",
                  });
                }}
                getEmail={(email) => {
                  this.setState({
                    email: email,
                  });
                }}
              />
            ) : (
              <div></div>
            )}
          </TabPane>
          <TabPane key="2">
            {this.state.activeKey === "2" ? (
              <ForgotPasswordForm
                title={this.props.title}
                changeTab={() => {
                  this.setState({
                    activeKey: "1",
                  });
                }}
                activeKey={this.state.activeKey}
                email={this.state.email}
                password={this.state.password}
              />
            ) : (
              <div></div>
            )}
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default RightContainer;
