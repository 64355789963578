import React, { Component } from "react";
import { Button, Form } from "antd";
import Profiledetail from "./Formdetail/ProfileDetail";
import Bankdetail from "./Formdetail/BankDetail";
// import Uploaddetail from "./Formdetail/UploadDetail";

class NonIndiankycForm extends Component {
  disablebutton() {
    if (
      this.props.kycType === "Individual" &&
      this.props.step === 4 &&
      this.props.currentStep === 1 &&
      Object.entries(this.props.files).length &&
      this.props.files.hasOwnProperty("id_card") &&
      !!this.props.files.id_card.length &&
      !!this.props.files.id_card[0]["response"]
    ) {
      return "ContinueEnable";
    } else if (
      this.props.kycType === "Individual" &&
      this.props.step === 5 &&
      this.props.currentStep === 2
    ) {
      return "ContinueEnable";
    } else if (
      this.props.kycType === "Organisation" &&
      this.props.step === 4 &&
      this.props.currentStep === 1 &&
      Object.entries(this.props.files).length &&
      this.props.files.hasOwnProperty("org_id_doc") &&
      !!this.props.files.org_id_doc.length &&
      !!this.props.files.org_id_doc[0]["response"] &&
      this.props.files.hasOwnProperty("signatory_id_card") &&
      !!this.props.files.signatory_id_card.length &&
      !!this.props.files.signatory_id_card[0]["response"]
    ) {
      return "ContinueEnable";
    } else if (
      this.props.kycType === "Organisation" &&
      this.props.step === 5 &&
      this.props.currentStep === 2
    ) {
      return "ContinueEnable";
    } else {
      return "ContinueDisable";
    }
  }

  render() {
    return (
      <>
        <Form>
          <Profiledetail
            currentStep={this.props.currentStep}
            form={this.props.form}
            questions={this.props.questions}
            step={this.props.step}
            totalSteps={this.props.totalSteps}
            kycType={this.props.kycType}
            files={this.props.files}
            kycData={this.props.kycData}
            phonecallChangeHandler={(val) =>
              this.props.phonecallChangeHandler(val)
            }
            toggleBackButton={() => this.props.toggleBackButton()}
            removeIdentityCard={(docType) =>
              this.props.removeIdentityCard(docType)
            }
            onFileUpload={(docType, files) =>
              this.props.onFileUpload(docType, files)
            }
            onremoveorgidcard={(docType) =>
              this.props.onremoveorgidcard(docType)
            }
            onremovesignatureidcard={(docType) =>
              this.props.onremovesignatureidcard(docType)
            }
            VerifyPhone={() => this.props.VerifyPhone()}
            VerifyEmail={() => this.props.VerifyEmail()}
            numberOtpLoading={this.props.numberOtpLoading}
            emailOtpLoading={this.props.emailOtpLoading}
            handleSupportEmailChange={(field, value) =>
              this.props.handleSupportEmailChange(field, value)
            }
            // handleSupportPhoneChange={(field, value) =>
            //   this.props.handleSupportPhoneChange(field, value)
            // }

            handleSupportPhoneChange={(ob) =>
              this.props.handleSupportPhoneChange(ob)
            }
            supportNumber2={this.props.supportNumber2}
            supportEmail2={this.props.supportEmail2}
            changePrefixState={(field, value) =>
              this.props.changePrefixState(field, value)
            }
            prefix_1={this.props.prefix_1}
            phoneverified={this.props.phoneverified}
            emailverified={this.props.emailverified}
            verifiedmsg={this.props.verifiedmsg}
            verifiedcontact={this.props.verifiedcontact}
            nonindiankycdata1={this.props.nonindiankycdata1}
            nonIndianPreviousDataStoe={(key, value) =>
              this.props.nonIndianPreviousDataStoe(key, value)
            }
          />

          <Bankdetail
            currentStep={this.props.currentStep}
            form={this.props.form}
            questions={this.props.questions}
            step={this.props.step}
            totalSteps={this.props.totalSteps}
            kycType={this.props.kycType}
            files={this.props.files}
            kycData={this.props.kycData}
            profileBakButton={() => this.props.profileBakButton()}
          />

          {/* <Uploaddetail
            step={this.props.step}
            totalSteps={this.props.totalSteps}
            kycType={this.props.kycType}
            files={this.props.files}
            kycData={this.props.kycData}
            onremovesignatureidcard={(docType) =>
              this.props.onremovesignatureidcard(docType)
            }
            removeIdentityCard={(docType) =>
              this.props.removeIdentityCard(docType)
            }
            onremoveorgidcard={(docType) =>
              this.props.onremoveorgidcard(docType)
            }
            removeAddresProof={(docType) =>
              this.props.removeAddresProof(docType)
            }
            onFileUpload={(docType, files) =>
              this.props.onFileUpload(docType, files)
            }
            onRemoveCancelCheque={(docType) =>
              this.props.onRemoveCancelCheque(docType)
            }
            questions={this.props.questions}
            currentStep={this.props.currentStep}
            goToNextStep={this.props.goToNextStep}
            form={this.props.form}
            uploadBcakButton={() => this.props.uploadBcakButton()}
          /> */}
        </Form>

        {this.props.currentStep === 3 ? null : (
          <>
            <Button
              className={`${this.disablebutton()}`}
              onClick={() => this.props.goToNextPart()}
            >
              Continue
            </Button>
            <div className="skip-step">
              <a href="#!" onClick={() => this.props.goToNextStep(false)}>
                <Button className="skip-btn">Skip this step</Button>
              </a>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Form.create()(NonIndiankycForm);
