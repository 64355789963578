import React, { Component } from "react";
import QuestionScreeen2Step1 from "./QuestionScreeen2Step1";
import QuestionScreen2Step2 from "./QuestionScreen2Step2";
import QuestionScreen2Step3 from "./QuestionScreen3Step3";
import "./index.scss";

class QuestionScreen2 extends Component {
  state = {
    questionScreen: {
      questionScreen2_step1: 1,
      questionScreen2_step2: 0,
      questionScreen2_step3: 0,
    },

    activStep: {
      activStep1: 1,
      activStep2: 0,
      activStep3: 0,
    },

    step1_options: {
      Step1Selected: "",
    },
    step2_options: {
      Step2Selected: "",
    },
  };

  onChangeStep1(value) {
    this.setState({
      step1_options: {
        Step1Selected: value,
      },
    });
  }

  onChangeStep2(value) {
    this.setState({
      step2_options: {
        Step2Selected: value,
      },
    });
  }

  toggleContinue(value) {
    if (value === "questionScreen2_step2") {
      this.setState({
        questionScreen: {
          questionScreen2_step1: 0,
          questionScreen2_step2: 1,
          questionScreen2_step3: 0,
        },
        activStep: {
          activStep1: 1,
          activStep2: 1,
          activStep3: 0,
        },
      });
    } else {
      this.setState({
        questionScreen: {
          questionScreen2_step1: 0,
          questionScreen2_step2: 0,
          questionScreen2_step3: 1,
        },
        activStep: {
          activStep1: 1,
          activStep2: 1,
          activStep3: 1,
        },
      });
    }
  }

  render() {
    var step1_options = [
      {
        key: "dropshipping",
        value: "Dropshipping",
      },
      {
        key: "d2c_dtc",
        value: "D2C / DTC",
      },
      {
        key: "digial_media",
        value: "Digital Media Buying / Online Marketing",
      },
      {
        key: "performance_agency",
        value: "Performance Agency / Network",
      },
    ];

    var step2_options = {
      dropshipping: [
        {
          key: "dropshipping_prepaid_nonindia",
          value:
            "Currently <b>Dropshipping on Prepaid mode from China</b> in countries like US, UK, etc., <b>and want to explore India COD eCommerce</b> for its low CPAs & huge scale.",
        },
        {
          key: "dropshipping_cod_nonindia",
          value:
            "Currently <b>Dropshipping in a COD market</b> like Europe, South America, etc. from a local fulfillment centre <b>and want to explore India COD eCommerce</b> for its low CPAs & volume potential. ",
        },
        {
          key: "dropshipping_india",
          value:
            "Already <b>Dropshipping in India</b> on COD <b>and want to scale without operational challenges.</b>",
        },
      ],
      d2c: [
        {
          key: "d2c_prepaid_nonindia",
          value:
            "Currently <b>running a DTC brand outside India in a prepaid economy</b> like US, UK, etc. <b>and want to expand in India </b> for its scale potential & low CACs. ",
        },
        {
          key: "d2c_cod_nonindia",
          value:
            "Currently </b>running a DTC brand outside India in a COD economy</b> like Europe, South America, etc. <b>and want to expand in India </b> for its scale potential & low CACs.",
        },
        {
          key: "d2c_india",
          value:
            "Currently <b>running a DTC brand in India and want to launch new products & have one partner manage all operations.</b>",
        },
      ],
      media_buying: [
        {
          key: "media_buying_prepaid_nonindia",
          value:
            "Currently <b>buying traffic for worldwide offers</b> like US, UK, etc., <b>and want to launch an owned eCom offer in India</b>, with all operations managed from a single platform.",
        },
        {
          key: "media_buying_cod_nonindia",
          value:
            "Currently <b>buying traffic for COD markets</b> like South America, Europe, etc., <b>and want to launch an owned eCom offer in India</b>, with all operations managed from a single platform.",
        },
        {
          key: "media_buying_india",
          value:
            "Currently <b>buying traffic for offers in India, and want to launch an eCom business in India</b>, with all operations managed from a single platform.  ",
        },
      ],
      agency: [
        {
          key: "agency_prepaid_nonindia",
          value:
            "Currently <b>managing traffic for worldwide offers</b> like US, UK, etc., <b>and want to launch an owned eCom offer in India</b>, with all operations managed from a single platform. ",
        },
        {
          key: "agency_cod_nonindia",
          value:
            "Currently <b>managing traffic for COD markets offers</b> like South America, Europe, etc., <b>and want to launch an owned eCom offer in India</b>, with all operations managed from a single platform. ",
        },
        {
          key: "agency_india",
          value:
            "Currently <b>managing traffic offers in India, and want to launch an owned eCom offer in India,</b> with all operations managed from a single platform.",
        },
      ],
    };

    return (
      <div className="questionScreen2-wraper">
        {this.state.questionScreen.questionScreen2_step1 === 1 && (
          <QuestionScreeen2Step1
            step1_options={step1_options}
            onChangeStep1={(value) => this.onChangeStep1(value)}
            Step1Selected={this.state.step1_options.Step1Selected}
            questionScreen2_step1={this.state.questionScreen}
            toggleContinue={(value) => this.toggleContinue(value)}
            activeStep={this.state.activStep}
          />
        )}
        {this.state.questionScreen.questionScreen2_step2 === 1 && (
          <QuestionScreen2Step2
            user_contact_ext={this.props.user_contact_ext}
            step2_options={step2_options}
            onChangeStep2={(value) => this.onChangeStep2(value)}
            Step1Selected={this.state.step1_options.Step1Selected}
            Step2Selected={this.state.step2_options.Step2Selected}
            questionScreen2_step2={this.state.questionScreen}
            toggleContinue={(value) => this.toggleContinue(value)}
            activeStep={this.state.activStep}
          />
        )}

        {this.state.questionScreen.questionScreen2_step3 === 1 && (
          <QuestionScreen2Step3
            user_contact_ext={this.props.user_contact_ext}
            Step1Selected={this.state.step1_options.Step1Selected}
            Step2Selected={this.state.step2_options.Step2Selected}
            questionScreen2_step2={this.state.questionScreen}
            activeStep={this.state.activStep}
            user_contact={this.props.user_contact}
            user_email={this.props.user_email}
            user_skype={this.props.user_skype}
            storeid={this.props.storeid}
          />
        )}
      </div>
    );
  }
}

export default QuestionScreen2;
