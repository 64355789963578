import React, { Component } from "react";
import { Modal, Select, Button, Icon, InputNumber } from "antd";
import "./index.scss";

const { Option } = Select;

class SelectVariantModal extends Component {
  render() {
    if (!this.props.product || !this.props.product.variants) return null;
    let numbers = this.props.variants.map((v, i) => v.quantity);
    let Total =
      numbers && numbers.length > 0 && numbers.reduce((acc, n) => acc + n);

    const product = this.props.product.variants;

    return (
      <Modal
        title="Select Variants"
        visible={this.props.visible}
        onOk={this.handleOk}
        onCancel={this.props.toggleVariantModal}
        footer={null}
        className="variants-modal"
      >
        <div className="variant-container">
          <div className="variants-data">
            <div className="variant-header">
              <p>Product Variant</p>
              <p>Quantity</p>
            </div>
            {this.props.variants &&
              this.props.variants.map((v, i) => (
                <div className="variant-body" key={i}>
                  <Icon
                    type="close-circle"
                    theme="filled"
                    onClick={() => this.props.deleteVariant(i)}
                  />

                  <Select
                    onChange={(val) =>
                      this.props.onChangeVariant(`variants.${i}.vfsku`, val)
                    }
                    style={{ width: 357 }}
                    placeholder="Select Variant"
                    value={v.vfsku}
                  >
                    {product.map((e, i) => (
                      <Option value={e.vfsku} key={e.vfsku}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            alt=""
                            style={{ maxHeight: 20, marginRight: 10 }}
                            src={e.images && e.images[0] ? e.images[0] : ""}
                          />
                          SKU: {e.vfsku} | {e.allspecs}
                        </div>
                      </Option>
                    ))}
                  </Select>
                  <InputNumber
                    onChange={(val) =>
                      this.props.onChangeVariant(`variants.${i}.quantity`, val)
                    }
                    disabled={this.props.mix_skus === 1}
                    value={v.quantity}
                  />
                </div>
              ))}
            <div className="add-variant-btn">
              <Button type="link" onClick={this.props.onAddVariant}>
                <Icon type="plus" /> Add Variant
              </Button>
            </div>
            <div className="total-quantity">
              <p style={{ flex: 1 }}>Total</p>
              <p style={{ width: "100px", marginLeft: "10px" }}>{Total}</p>
            </div>
            <div className="variant-footer">
              <Button
                type="primary"
                disabled={this.props.mix_skus === 1}
                onClick={() => this.props.onRandomizeQuantity()}
              >
                Auto Adjust Quantities
              </Button>
              <Button
                className="save-btn"
                onClick={() => this.props.onVariantSave()}
                loading={this.props.continueLoading}
              >
                Save & Continue
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default SelectVariantModal;
