import { Tooltip } from "antd";
import React, { Component } from "react";

export default class Container1 extends Component {
  render() {
    let {
      disabled,
      logoUrl,
      faviconUrl,
      toggleZoomModal,
      store_name,
      handleChangeStoreName,
      handleFaviconChange,
      handleLogoChange,
    } = this.props;
    return (
      <div className="brand_logo">
        <div className="logo-heading-cointainer">
          {!disabled ? (
            <p className="brand_name_label">
              Brand Logo{" "}
              <span className="italic-text">
                (Recommended size: 250px X 250px)
              </span>
            </p>
          ) : (
            <p className="brand_name_label">Brand Logo</p>
          )}
          {!disabled ? (
            <p className="brand_name_label">
              Favicon {" "}
              <span className="italic-text">
                (Recommended size: 32px X 32px)
              </span>
            </p>
          ) : (
            <p className="brand_name_label">Favicon </p>
          )}
        </div>
        <div className="uploader-cointainer">
          <div className="brand_logo_uploder">
            {!disabled && (
              <div className="brand_logo_ican">
                <div
                  className="drage-image"
                  onClick={() => this.fileInput.click()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                  >
                    <g
                      id="Group_3680"
                      data-name="Group 3680"
                      transform="translate(-936 -1024)"
                    >
                      <g
                        id="Group_2193"
                        data-name="Group 2193"
                        transform="translate(936 1044.009)"
                      >
                        <g id="Group_2192" data-name="Group 2192">
                          <path
                            id="Path_52"
                            data-name="Path 52"
                            d="M47.835,307.2a1.215,1.215,0,0,0-1.231,1.2v7.195a1.215,1.215,0,0,1-1.231,1.2H20.758a1.215,1.215,0,0,1-1.231-1.2V308.4a1.231,1.231,0,0,0-2.462,0v7.195a3.646,3.646,0,0,0,3.692,3.6H45.374a3.646,3.646,0,0,0,3.692-3.6V308.4A1.215,1.215,0,0,0,47.835,307.2Z"
                            transform="translate(-17.066 -307.2)"
                            fill="#898989"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_2195"
                        data-name="Group 2195"
                        transform="translate(944.006 1024)"
                      >
                        <g
                          id="Group_2194"
                          data-name="Group 2194"
                          transform="translate(0)"
                        >
                          <path
                            id="Path_53"
                            data-name="Path 53"
                            d="M152.323,6.7a1.673,1.673,0,0,1-1.855,0l-4.4-3.29V22.983c0,.552-.6,1-1.335,1s-1.335-.447-1.335-1V3.412L139,6.7a1.668,1.668,0,0,1-1.888-.025.83.83,0,0,1,0-1.388l6.674-5a1.668,1.668,0,0,1,1.888,0l0,0,6.674,5A.834.834,0,0,1,152.323,6.7Z"
                            transform="translate(-136.742 0)"
                            fill="#898989"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                  <p
                    style={{
                      marginTop: "8px",
                      fontSize: "10px",
                      marginBottom: "0px",
                      lineHeight: "16px",
                      textAlign: "center",
                      fontWeight: 500
                    }}
                  >
                    Click To Upload
                  </p>
                </div>
                <input
                  style={{ display: "none" }}
                  type="file"
                  ref={(fileInput) => (this.fileInput = fileInput)}
                  accept="image/png, image/jpeg, image/webp"
                  onChange={handleLogoChange}
                />
              </div>
            )}
            {!!logoUrl ? (
              <div className="images_show">
                <div
                  className="photo-container"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <div style={{ position: "relative" }}>
                    <img
                      key="1"
                      style={{
                        width: 135,
                        height: 110,
                        objectFit: "cover",
                        borderRadius: 2,
                        border: "1px solid #D0D0D0",
                        cursor: "pointer",
                        marginRight: "8px",
                        marginTop: "0px",
                      }}
                      className="photo-uploaded"
                      src={logoUrl}
                      alt="upload"
                      onClick={() => toggleZoomModal(logoUrl, "image")}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="images_show">
                <div
                  className="photo-container"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  {" "}
                  <Tooltip title={"No Image"} placement="leftBottom">
                    <div style={{ position: "relative" }}>
                      <img
                        key="1"
                        style={{
                          width: 135,
                          height: 110,
                          objectFit: "cover",
                          borderRadius: 2,
                          border: "1px solid #D0D0D0",
                          cursor: "pointer",
                          marginRight: "8px",
                          marginTop: "0px",
                        }}
                        className="photo-uploaded"
                        src="https://user-images.githubusercontent.com/43302778/106805462-7a908400-6645-11eb-958f-cd72b74a17b3.jpg"
                        alt="upload"
                      />
                    </div>{" "}
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
          <div className="brand_logo_uploder">
            {!disabled && (
              <div className="brand_logo_ican">
                <div
                  className="drage-image"
                  onClick={() => this.fileInput1.click()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                  >
                    <g
                      id="Group_3680"
                      data-name="Group 3680"
                      transform="translate(-936 -1024)"
                    >
                      <g
                        id="Group_2193"
                        data-name="Group 2193"
                        transform="translate(936 1044.009)"
                      >
                        <g id="Group_2192" data-name="Group 2192">
                          <path
                            id="Path_52"
                            data-name="Path 52"
                            d="M47.835,307.2a1.215,1.215,0,0,0-1.231,1.2v7.195a1.215,1.215,0,0,1-1.231,1.2H20.758a1.215,1.215,0,0,1-1.231-1.2V308.4a1.231,1.231,0,0,0-2.462,0v7.195a3.646,3.646,0,0,0,3.692,3.6H45.374a3.646,3.646,0,0,0,3.692-3.6V308.4A1.215,1.215,0,0,0,47.835,307.2Z"
                            transform="translate(-17.066 -307.2)"
                            fill="#898989"
                          />
                        </g>
                      </g>
                      <g
                        id="Group_2195"
                        data-name="Group 2195"
                        transform="translate(944.006 1024)"
                      >
                        <g
                          id="Group_2194"
                          data-name="Group 2194"
                          transform="translate(0)"
                        >
                          <path
                            id="Path_53"
                            data-name="Path 53"
                            d="M152.323,6.7a1.673,1.673,0,0,1-1.855,0l-4.4-3.29V22.983c0,.552-.6,1-1.335,1s-1.335-.447-1.335-1V3.412L139,6.7a1.668,1.668,0,0,1-1.888-.025.83.83,0,0,1,0-1.388l6.674-5a1.668,1.668,0,0,1,1.888,0l0,0,6.674,5A.834.834,0,0,1,152.323,6.7Z"
                            transform="translate(-136.742 0)"
                            fill="#898989"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>

                  <p
                    style={{
                      marginTop: "8px",
                      fontSize: "10px",
                      marginBottom: "0px",
                      lineHeight: "16px",
                      textAlign: "center",
                      fontWeight: 500
                    }}
                  >
                    Click To Upload
                  </p>
                </div>
                <input
                  style={{ display: "none" }}
                  type="file"
                  ref={(fileInput1) => (this.fileInput1 = fileInput1)}
                  accept="image/png, image/jpeg, image/webp"
                  onChange={handleFaviconChange}
                />
              </div>
            )}
            {!faviconUrl ? (
              <div className="images_show">
                <div
                  className="photo-container"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <Tooltip title={"No Image"} placement="leftBottom">
                    <div style={{ position: "relative" }}>
                      <img
                        key="1"
                        style={{
                          width: 135,
                          height: 110,
                          objectFit: "cover",
                          borderRadius: 2,
                          border: "1px solid #D0D0D0",
                          cursor: "pointer",
                          marginRight: "8px",
                          marginTop: "0px",
                        }}
                        className="photo-uploaded"
                        src="https://user-images.githubusercontent.com/43302778/106805462-7a908400-6645-11eb-958f-cd72b74a17b3.jpg"
                        alt="upload"
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>
            ) : (
              <div className="images_show">
                <div
                  className="photo-container"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <div style={{ position: "relative" }}>
                    <img
                      key="1"
                      style={{
                        width: 135,
                        height: 110,
                        objectFit: "cover",
                        borderRadius: 2,
                        border: "1px solid #D0D0D0",
                        cursor: "pointer",
                        marginRight: "8px",
                        marginTop: "0px",
                      }}
                      className="photo-uploaded"
                      src={faviconUrl}
                      alt="upload"
                      onClick={() => toggleZoomModal(faviconUrl, "image")}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="cointainer-input">
          <div className="container-main">
            <div className="title-input">Brand name </div>
            <input
              disabled={true}
              className="input"
              value={store_name}
              onChange={handleChangeStoreName}
            />
          </div>
        </div>
      </div>
    );
  }
}
