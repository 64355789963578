import React from "react";
import { Checkbox, Icon } from "antd";
import Filterrange from "./Filterrange";
import { ReactComponent as mediaican } from "../../../../assets/images/icons/mediaican.svg";
import { ReactComponent as buybackican } from "../../../../assets/images/icons/buyback.svg";
import { ReactComponent as addcopyican } from "../../../../assets/images/icons/addcopy.svg";
import { ReactComponent as expressican } from "../../../../assets/images/icons/expressshiping.svg";
import { ReactComponent as InventoryAvailable } from "../../../../assets/images/icons/InventoryAvailable.svg";

const Filters = ({
  filters,
  onChange,
  storegeo,
  clearAll,
  clearAllNiche,
  categories = [],
  firstInputeOnchnage,
  secondInputeOnchnage,
  onRangeFilterChange,
  step,
  value,
  loadData,
  loading,
}) => {
  let [parentCategoryId] = filters.category.split("-");
  let mainCategory = categories.find((c) => c.id === parentCategoryId);
  let nicheData = filters.niche.map((c) => c.replace(/_/g, " "));

  if (!mainCategory) {
    mainCategory = { name: "" };
  }

  let selectedCategory = categories.find((c) => c.id === filters.category);
  if (!selectedCategory) {
    selectedCategory = { name: "" };
  }

  return (
    <div className="filters">
      {filters.packaging === 1 ? (
        <div className="no-filters">No Filters Applicable</div>
      ) : (
        <>
          {" "}
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
              fontWeight: 500,
            }}
          >
            <p className="f16" style={{ color: "#212121" }}>
              Filter Products:
            </p>
            <a
              className="underline f8"
              onClick={() => clearAll()}
              style={{ color: "#ACACAC" }}
              href="#!"
            >
              Clear All Filters
            </a>
          </div>
          <div>
            <div
              className={`f12 filter-tag ${
                filters.media === 1 ? "active" : ""
              }`}
            >
              <span className="filter-available">
                <Checkbox
                  checked={filters.media === 1}
                  onChange={(e) => onChange("media", e.target.checked ? 1 : 0)}
                />{" "}
              </span>
              <span className="ican-image">
                <Icon component={mediaican} />
              </span>
              <p className="filter-text"> Media Available</p>
            </div>

            <div
              className={`f12 filter-tag ${
                filters.ad_copy_available === 1 ? "active" : ""
              }`}
            >
              <span className="filter-available">
                <Checkbox
                  checked={filters.ad_copy_available === 1}
                  onChange={(e) =>
                    onChange("ad_copy_available", e.target.checked ? 1 : 0)
                  }
                />{" "}
              </span>
              <span className="ican-image">
                <Icon component={addcopyican} />
              </span>
              <p className="filter-text"> Ad Copy Available</p>
            </div>

            <div
              className={`f12 filter-tag ${
                filters.inventory_available === 1 ? "active" : ""
              }`}
            >
              <span>
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={filters.inventory_available === 1}
                  onChange={(e) =>
                    onChange("inventory_available", e.target.checked ? 1 : 0)
                  }
                />{" "}
              </span>
              <span className="ican-image">
                <Icon component={InventoryAvailable} />
              </span>
              <p className="filter-text">Inventory Available</p>
            </div>

            <div
              className={`f12 filter-tag ${filters.potd === 1 ? "active" : ""}`}
            >
              <span>
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={filters.potd === 1}
                  onChange={(e) => onChange("potd", e.target.checked ? 1 : 0)}
                />{" "}
              </span>
              <span className="ican-image">💰</span>
              <p className="filter-text">POTD Awardee</p>
            </div>

            {filters.niche.length > 0 ? (
              <div className="niche-container">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontSize: "12px", color: "#212121" }}>
                    Niche Selected:
                  </p>
                  <p
                    style={{
                      fontSize: "8px",
                      color: "#ACACAC",
                      lineHeight: "18px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => clearAllNiche()}
                  >
                    Clear Niche(s)
                  </p>
                </div>

                <div
                  style={{
                    background: "#FFEAA5",
                    minHeight: "32px",
                    padding: "8px",
                    marginTop: "12px",
                  }}
                >
                  <span className="niche-item">{nicheData.join(", ")}</span>
                </div>
              </div>
            ) : null}
            {filters.category !== "" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "space-between",
                    fontWeight: 500,
                  }}
                >
                  <p
                    className="f12"
                    style={{ color: "#212121", margin: "3px 0 12px 0" }}
                  >
                    Category Selected:
                  </p>
                  <a
                    className="underline f8"
                    onClick={() => onChange("category", "")}
                    style={{ color: "#ACACAC" }}
                    href="#!"
                  >
                    Clear Category
                  </a>
                </div>
                <div
                  style={{
                    color: "#212121",
                    background: "#FFEAA5",
                    padding: 12,
                    borderRadius: 2,
                  }}
                  className="f12"
                >
                  {mainCategory.name ? `${mainCategory.name} >` : null}{" "}
                  <span className="underline">{selectedCategory.name}</span>
                </div>
              </>
            ) : null}
          </div>
          <Filterrange
            firstInputeOnchnage={(e) => firstInputeOnchnage(e)}
            secondInputeOnchnage={(e) => secondInputeOnchnage(e)}
            onRangeFilterChange={(e) => onRangeFilterChange(e)}
            step={step}
            value={value}
            loadData={() => loadData()}
            loading={loading}
            filters={filters}
          />
        </>
      )}
    </div>
  );
};

export default Filters;
