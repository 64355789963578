import React, { Component } from "react";
import { Button, Input, Modal, Select } from "antd";
import "./index.scss";
const { Option } = Select;
class AddNoteModal extends Component {
  state = {
    note: null,
    selectedOption: [],
    notFill: false,
  };

  handleChange =  (field, value) => {
    this.setState({
      ...this.state,
      [field]: value,
    });
  };

  resetModal = () => {
    this.setState({
      note: null,
      selectedOption: [],
      notFill: false,
    });
  };
  handleTypeChange = (value) => {
    this.setState({
      selectedOption: [value],
      notFill: false,
    });
  };

  render() {
    const { visible, error, onCancel, onConfirm } = this.props;
    const { note } = this.state;

    if (error) {
      // console.log(error);
    }
    return (
      <Modal
        className="orders-add-note-modal"
        title="Add Note"
        visible={visible}
        onCancel={onCancel}
        closable={!this.props.saving}
        afterClose={this.resetModal}
        width={560}
        footer={[
          <Select
            value={this.state.selectedOption}
            placeholder="Select Note Type"
            // style={{ width: 120, position: "relative", left: "-152px" }}
            onChange={this.handleTypeChange}
            className={
              this.state.notFill ? "trunRed select-type" : " select-type"
            }
          >
            <Option key="Generic">Generic Remark </Option>
            <Option key="Calling">Calling Remark </Option>
          </Select>,
          <Button className="add-note-cancel" key="back" onClick={onCancel}>
            Cancel
          </Button>,
          <Button
            className={note&&this.state.selectedOption.length ?"add-note-btn clickable-dropdown":"add-note-btn off-btn clickable-dropdown"}
            key="submit"
            type="primary"
            onClick={() => {
              if (this.state.selectedOption.length) {
                onConfirm(this.state);
              } else {
                this.setState({
                  notFill: true,
                });
           
              }
            }}
            loading={this.props.saving}
          >
            Add Note
          </Button>,
        ]}
      >
        <div>
          <Input.TextArea
            value={note}
            placeholder="Enter text here"
            onChange={(e) => this.handleChange("note", e.target.value)}
            autosize={{ minRows: 6 }}
          />
        </div>
      </Modal>
    );
  }
}

export default AddNoteModal;
