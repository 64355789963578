import React, { Component } from "react";
import { Tag, Button, Row, Col, Icon } from "antd";
import { ReactComponent as attcheck } from "../../../../../../assets/images/icons/attcheck.svg";
import { ReactComponent as attcross } from "../../../../../../assets/images/icons/attcross.svg";
import "./index.scss";
import ProductAttributes from "../ProductAttributes";

class ProductSummary extends Component {
  state = {};

  render() {
    let product = this.props.productData;
    return (
      <div className="product-summary">
        <Row style={{ marginBottom: "24px" }} className="descri">
          <h4>Description:</h4>
          <div
            style={{
              padding: "10px",
              background: "#f0f0f0",
              height: "123px",
              overflow: "hidden",
            }}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: product.description.substr(0, 100),
              }}
            />

            <span
              style={{ position: "absolute", right: "10px", bottom: "10px" }}
            >
              <Button
                onClick={() =>
                  this.props.toggleContentModal(product.description)
                }
              >
                View Full Description
              </Button>
            </span>
          </div>
        </Row>
        {product && product.features && product.features.length > 0 && (
          <Row style={{ marginBottom: "24px" }} className="feature">
            <h4>Features:</h4>
            <ul>
              {product.features.map((f, i) => (
                <li key={i}>{f}</li>
              ))}
            </ul>
          </Row>
        )}

        <Row style={{ marginBottom: "24px" }} className="category">
          {product && product.categories && product.categories.length > 0 && (
            <Col md={12}>
              <h4>Categories:</h4>
              {product.categories.map((c, i) => (
                <Tag color="blue" key={i}>
                  {c.name}
                </Tag>
              ))}
            </Col>
          )}
          {product && product.niche && product.niche.length > 0 && (
            <Col md={12}>
              <h4>Niche:</h4>
              {this.props.niches
                .filter((n) => product.niche.indexOf(n.id) !== -1)
                .map((n) => {
                  return (
                    <Tag color="blue" key={n.id}>
                      {n.name}
                    </Tag>
                  );
                })}
            </Col>
          )}
        </Row>
        <hr />
        <Row style={{ marginBottom: "60px" }} className="attri">
          <h4>Product Attributes:</h4>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <p>
              Comes With A Box{" "}
              {product.attributes.comeswithabox === 1 ? (
                <Icon component={attcheck} />
              ) : (
                <Icon component={attcross} />
              )}
            </p>
            <p>
              Fragile{" "}
              {product.attributes.fragile === 1 ? (
                <Icon component={attcheck} />
              ) : (
                <Icon component={attcross} />
              )}
            </p>
            <p>
              Bluetooth Enabled{" "}
              {product.attributes.bluetooth === 1 ? (
                <Icon component={attcheck} />
              ) : (
                <Icon component={attcross} />
              )}
            </p>
            <p>
              Video Available{" "}
              {product.videos && product.videos.length > 0 ? (
                <Icon component={attcheck} />
              ) : (
                <Icon component={attcross} />
              )}
            </p>
          </div>
          <ProductAttributes product={product} />
        </Row>
      </div>
    );
  }
}

export default ProductSummary;
