import React, { Component } from "react";
import {
  Button,
  Input,
  Modal,
  Icon,
  message,
  Form,
  Alert,
  Tabs,
  Row,
} from "antd";
import axios from "axios";
import "./index.scss";
import { connect } from "react-redux";
import { signIn } from "../../../../store/actions/authActions";
class Resetpassword extends Component {
  state = {
    loading: false,
    showMsg: false,
    msgType: "error",
    msg: "",
    confirmDirty: false,
  };

  componentDidMount() {
    document.title = this.props.title;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true }, () => {
          axios({
            url: process.env.REACT_APP_API_URL + "/login/verify_password_reset",
            method: "post",
            data: {
              hash: this.props.match.params.hash,
              password: values.password,
              cpassword: values.cpassword,
            },
            withCredentials: true,
          })
            .then((res) => {
              if (res.data.success === 1) {
                this.props.history.push("/login");
                message.success("Password Reset Successfully");
              } else {
                this.setState({
                  showMsg: true,
                  loading: false,
                  msgType: "error",
                  msg: res.data.msg,
                });
              }
            })
            .catch((e) => {
              this.setState({
                showMsg: true,
                loading: false,
                msgType: "error",
                msg: e.message,
              });
            });
        });
      }
    });
  };

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("The passwords that you entered do not match!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(["cpassword"], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="resetSection">
        <div className="heading-bar">
          <div className="main-heading">Welcome Back</div>
          <div className="sub-heading">Log In to access your dashboard</div>
        </div>{" "}
        <div className="heading-reset">Reset Your Password</div>
        <div className="line-blue"></div>
        <Form onSubmit={this.handleSubmit} className="forgot-form" noValidate>
          <div className="flex-from">
            <Form.Item>
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Please enter a valid password",
                  },
                  // {
                  //   pattern: /^(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*\d).{6,}$/,
                  //   message:
                  //     "Password: 6+ chars, 1 special, 1 uppercase, 1 number",
                  // },
                  {
                    min: 5,
                    message: "Password must be at least 5 characters long",
                  },
                  { validator: this.validateToNextPassword },
                ],
              })(
                <Input.Password
                  className="input-signup22"
                  type="password"
                  placeholder="Enter your new password"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("cpassword", {
                rules: [
                  {
                    required: true,
                    message: "Please enter a valid password",
                  },
                  { validator: this.compareToFirstPassword },
                ],
              })(
                <Input.Password
                  className="input-signup22"
                  type="password"
                  placeholder="Re-enter your new password"
                  onBlur={this.handleConfirmBlur}
                />
              )}
            </Form.Item>
            <Form.Item>
              {this.state.showMsg && (
                <Alert
                  message={this.state.msg}
                  type={this.state.msgType}
                  className="alert"
                />
              )}
              <Button
                htmlType="submit"
                className="change-emailbtn"
                style={{
                  pointerEvents: this.state.sentSuccessfully ? "none" : "auto",
                }}
                loading={this.state.loading}
              >
                Continue
              </Button>
            </Form.Item>
          </div>
        </Form>
        <div className="login-page-footer">
          <Row type="flex">
            <p className="copy-line">
              Copyright &copy; {new Date().getFullYear()} vFulfill
            </p>
          </Row>
          <Row type="flex">
            <ul className="footer-links">
              <li>
                <a href="https://www.vfulfill.io/legals/terms">Terms</a>
              </li>
              <li>
                <a href="https://www.vfulfill.io/legals/privacy">Privacy</a>
              </li>
              <li>
                <a href="https://help.vfulfill.io">Help</a>
              </li>
            </ul>
          </Row>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Resetpassword));
