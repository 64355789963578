import React from "react";
import { Card, Button, Icon, Tooltip, Menu, Dropdown } from "antd";
// import { ReactComponent as FFStatusIcon } from "../../../../assets/images/icons/ff_status_icon.svg";
import { ReactComponent as PaymentStatusIcon } from "../../../../assets/images/icons/payment_status_icon.svg";
import { ReactComponent as ShipmentStatusIcon } from "../../../../assets/images/icons/shopping-cart.svg";
import { ReactComponent as CircleCheck } from "../../../../assets/images/icons/circle-check.svg";

import moment from "moment";
import "./index.scss";
import CustomerInfo from "./CustomerInfo";
import LineItems from "./LineItems";
import { hasPermission } from "../../../../Helpers/Permissions";
import Shipmentprogressbar2 from "./ShipmentProgressbar";
import VfIdCopyBadge from "../../Common/VfIdCopyBadge";
// import CustomerInfo from "../../Orders/ManageOrders/OrderCardNew/GlobalContant/CustomerInfo/index";
// import Notes from "../../Orders/ManageOrders/OrderCardNew/GlobalContant/Notes/index";
// import Logs from "../../Orders/ManageOrders/OrderCardNew/GlobalContant/Logs/index";
import { ReactComponent as Tooltipican } from "../../../../assets/images/icons/tooltipican.svg";
import ShipmentRequestModal from "./ShipmentRequestModal";

class ShipmentCard extends React.Component {
  state = {
    shipment: this.props.shipment,
    activeTab: "tracking",
    errors: {},
    shipmentsVisible: {},
    notes: [],
    logs: [],
    visible: false,
    status: "",
    request_status: undefined,
    replacement:
      this.props.shipmentStatus[0] === "delivered" &&
      this.props.requestStatus &&
      this.props.requestStatus.replacement !== undefined
        ? this.props.requestStatus.replacement
        : null,

    rvpStatus:
      this.props.shipmentStatus[0] === "delivered" &&
      this.props.requestStatus &&
      this.props.requestStatus.rvp !== undefined
        ? this.props.requestStatus.rvp
        : null,

    refundedstatus:
      this.props.shipmentStatus[0] === "delivered" &&
      this.props.requestStatus &&
      this.props.requestStatus.refund !== undefined
        ? this.props.requestStatus.refund
        : null,

    escalatedStatus:
      this.props.shipmentStatus[0] === "delivered" &&
      this.props.requestStatus &&
      this.props.requestStatus.escalated !== undefined
        ? this.props.requestStatus.escalated
        : null,
  };

  toggleShipmentDisputeModal(value) {
    this.setState({
      visible: !this.state.visible,
      showHideheaderdata: false,
      status: value,
    });
  }

  setActiveTab = (activeTab) => {
    this.setState({
      activeTab,
    });
  };

  setArrowdata() {
    this.setState(
      {
        showHideheaderdata: false,
      },
      () => this.props.toggle(this.state.shipment)
    );
  }

  render() {
    const shipment = this.state.shipment;

    const {
      paymentStatusColors,
      paymentStatusText,
      shipmentStatusColors,
      shipmentStatusText,
    } = this.props;
    let lineItems = this.state.shipment.items;
    const disputeDeadline = moment
      .utc(this.state.shipment.closed_on)
      .add(7, "days");
    let disputeDisabled = true;
    if (disputeDeadline.diff(moment.utc()) > 0) {
      disputeDisabled = false;
    }

    const menu = (
      <Menu
        className="custom_dropdown3"
        style={{
          minWidth: 184,
          marginLeft: -40,
          marginTop: 6,
          borderRadius: 0,
        }}
      >
        {this.state.rvpStatus === 0 ? (
          <Menu.Item>
            <div className="review_pickup_flex">
              <Tooltip
                title="This action will be charged from your wallet after confirmation"
                className="review_line_btn disabled"
              >
                <Button
                  onClick={() =>
                    this.toggleShipmentDisputeModal("reverse_pickup")
                  }
                  className="review_line_btn"
                >
                  Reverse Pickup
                </Button>
                <span
                  style={{
                    marginLeft: "-8px",
                  }}
                >
                  <Icon className="antCircleCheck" component={Tooltipican} />
                </span>
              </Tooltip>
            </div>
          </Menu.Item>
        ) : (
          <Menu.Item>
            <div className="review_pickup_flex">
              <Tooltip
                title="Action already taken"
                className="review_line_btn disabled"
              >
                <Button className="review_line_btn disabled">
                  <span style={{ marginRight: "8px" }}> Reverse Pickup</span>
                  <span
                    style={{
                      verticalAlign: "middle",
                    }}
                  >
                    <Icon className="antCircleCheck" component={CircleCheck} />
                  </span>
                </Button>
              </Tooltip>
            </div>
          </Menu.Item>
        )}

        {this.props.gst_enabled === 1 ? null : this.state.refundedstatus ===
          0 ? (
          <Menu.Item>
            <div className="review_pickup_flex">
              <Tooltip
                title="This action will be charged from your wallet after confirmation"
                className="review_line_btn disabled"
              >
                <Button
                  className="review_line_btn"
                  onClick={() =>
                    this.toggleShipmentDisputeModal("refund_end_customer")
                  }
                >
                  Refund End Customer
                </Button>
                <span
                  style={{
                    marginLeft: "-8px",
                  }}
                >
                  <Icon className="antCircleCheck" component={Tooltipican} />
                </span>
              </Tooltip>
            </div>
          </Menu.Item>
        ) : this.state.refundedstatus === 1 ? (
          <Menu.Item>
            <div className="review_pickup_flex">
              <Tooltip
                title="Action already taken"
                className="review_line_btn disabled"
              >
                <Button className="review_line_btn disabled">
                  <span style={{ marginRight: "8px" }}>
                    {" "}
                    Refund End Customer
                  </span>
                  <span
                    style={{
                      verticalAlign: "middle",
                    }}
                  >
                    <Icon className="antCircleCheck" component={CircleCheck} />
                  </span>
                </Button>
              </Tooltip>
            </div>
          </Menu.Item>
        ) : (
          <Menu.Item>
            <div className="review_pickup_flex">
              <Tooltip
                title="You need to refund customer on your behalf"
                className="review_line_btn disabled"
              >
                <Button className="review_line_btn disabled">
                  <span style={{ marginRight: "8px" }}>
                    {" "}
                    Refund End Customer
                  </span>
                  <span
                    style={{
                      verticalAlign: "middle",
                    }}
                  >
                    <Icon className="antCircleCheck" component={CircleCheck} />
                  </span>
                </Button>
              </Tooltip>
            </div>
          </Menu.Item>
        )}

        {this.state.replacement === 0 ? (
          <Menu.Item>
            <Button
              className="review_line_btn"
              onClick={() => this.toggleShipmentDisputeModal("replacement")}
            >
              Replacement
            </Button>
          </Menu.Item>
        ) : (
          <Menu.Item>
            <div className="review_pickup_flex">
              <Tooltip
                title="Action already taken"
                className="review_line_btn disabled"
              >
                <Button className="review_line_btn disabled">
                  <span style={{ marginRight: "8px" }}> Replacement</span>
                  <span
                    style={{
                      verticalAlign: "middle",
                    }}
                  >
                    <Icon className="antCircleCheck" component={CircleCheck} />
                  </span>
                </Button>
              </Tooltip>
            </div>
          </Menu.Item>
        )}

        {this.state.escalatedStatus === 0 ? (
          <Menu.Item key="1">
            <div className="review_pickup_flex">
              <Tooltip
                title="(Escalations can be raised only within 7 days from the
                date of delivery)"
                className="review_line_btn disabled"
              >
                <Button
                  onClick={() => this.setArrowdata()}
                  disabled={disputeDisabled}
                  className="review_line_btn"
                >
                  {" "}
                  Raise An Escalation
                </Button>
                <span
                  style={{
                    marginLeft: "-8px",
                  }}
                >
                  <Icon className="antCircleCheck" component={Tooltipican} />
                </span>
              </Tooltip>
            </div>
          </Menu.Item>
        ) : this.state.escalatedStatus === 1 ? (
          <Menu.Item key="1">
            <div className="review_pickup_flex">
              <Tooltip
                title="Action already taken"
                className="review_line_btn disabled"
              >
                <Button className="review_line_btn disabled">
                  <span style={{ marginRight: "8px" }}>
                    {" "}
                    Raise An Escalation
                  </span>
                  <span
                    style={{
                      verticalAlign: "middle",
                    }}
                  >
                    <Icon className="antCircleCheck" component={CircleCheck} />
                  </span>
                </Button>
              </Tooltip>
            </div>
          </Menu.Item>
        ) : (
          <Menu.Item key="1">
            <div className="review_pickup_flex">
              <Tooltip
                title="You have already raised a request against this shipment"
                className="review_line_btn disabled"
              >
                <Button className="review_line_btn disabled">
                  <span style={{ marginRight: "8px" }}>
                    {" "}
                    Raise An Escalation
                  </span>
                  <span
                    style={{
                      verticalAlign: "middle",
                    }}
                  >
                    <Icon className="antCircleCheck" component={Tooltipican} />
                  </span>
                </Button>
              </Tooltip>
            </div>
          </Menu.Item>
        )}
      </Menu>
    );

    return (
      <>
        <Card id="fade-in" className="shipment-card">
          <div className="header-row">
            <div className="vforderid_tag">
              <VfIdCopyBadge
                text={"VF Order"}
                hashSign={true}
                textToCopy={shipment.order_vfid}
              />
            </div>

            <h3>
              WayBill Number(AWB){": "}
              {shipment.status === "manifested" ? (
                <span style={{ color: "#212121" }}>{shipment.awb}</span>
              ) : (
                <span className="blue-text">
                  <a
                    href={shipment.tracking_url}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {shipment.awb}
                  </a>
                </span>
              )}
            </h3>

            <div
              style={{
                width: "400px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <h3 className="middledata">
                <span className="Order_text">
                  Order:{" "}
                  <span className="blue-text">
                    <a
                      href={
                        shipment.shopify_order_link
                          ? shipment.shopify_order_link
                          : "#"
                      }
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {shipment.order_shopify_name}
                    </a>
                  </span>
                </span>
              </h3>
              <div>
                {" "}
                <p
                  style={{
                    margin: "0 8px",
                    color: "#898989",
                    fontweight: "400",
                  }}
                >
                  |
                </p>
              </div>

              <div
                style={{
                  fontSize: "12px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="shipment-date">
                  <Tooltip
                    title={paymentStatusText[shipment.order_payment_status]}
                  >
                    <Icon
                      component={PaymentStatusIcon}
                      style={{
                        color:
                          paymentStatusColors[shipment.order_payment_status],
                        marginRight: "8px",
                        marginTop: "-2px",
                      }}
                    />
                  </Tooltip>
                </div>
                <Tooltip
                  title={`Total Order Cost = Total Shopify Item Selling Price +  Shipping Price`}
                >
                  ₹{" "}
                  {shipment && shipment.shipmentTotalAmount
                    ? shipment.shipmentTotalAmount
                    : "0"}
                </Tooltip>
              </div>
              <div>
                {" "}
                <p
                  style={{
                    margin: "0 8px",
                    color: "#898989",
                    fontweight: "400",
                  }}
                >
                  |
                </p>
              </div>
              <div className="middledata_shipment">
                <div style={{ color: "#949494", fontSize: "12px" }}>
                  <Tooltip title="Shipment Packed Date">
                    {moment(shipment.shipment_date).format("DD MMM, YYYY")}
                  </Tooltip>
                </div>
              </div>
            </div>

            {!!shipment.hasDispute ? (
              <Button disabled>Escalation Raised!</Button>
            ) : (
              <div
                className={
                  this.props.shipmentStatus[0] === "delivered"
                    ? "shipment-action shipment-action_bg "
                    : "shipment-action"
                }
              >
                {shipment.status === "delivered" &&
                hasPermission(
                  this.props.store,
                  "raise_disputes_on_shipments"
                ) ? (
                  shipment.hasDispute ? (
                    <p
                      style={{
                        margin: 0,
                        color: shipmentStatusColors["processing"],
                      }}
                    >
                      Dispute in Process
                    </p>
                  ) : (
                    <Dropdown
                      overlay={menu}
                      onVisibleChange={(e) => {
                        this.setState({ showHideheaderdata: e });
                      }}
                      // visible={true}
                    >
                      <span>
                        Request Action
                        <span>
                          {" "}
                          <svg
                            style={{
                              marginLeft: "5px",
                              transition: "all 0.5s",
                              transform: `${
                                this.state.showHideheaderdata
                                  ? "rotateX(180deg)"
                                  : "rotateX(0deg)"
                              }`,
                              cursor: "pointer",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="8"
                            viewBox="0 0 16 8"
                          >
                            <g
                              id="Polygon_49"
                              data-name="Polygon 49"
                              transform="translate(16 8) rotate(180)"
                              fill={`${
                                this.state.showHideheaderdata ? "#fff" : "none"
                              }`}
                            >
                              <path d="M8,0l8,8H0Z" stroke="none" />
                              <path
                                d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                                stroke="none"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </span>
                      </span>
                    </Dropdown>
                  )
                ) : (
                  <div
                    style={{
                      color: shipmentStatusColors[shipment.status],
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {shipment.status === "cancelled" ? (
                      <p>Cancelled</p>
                    ) : (
                      <div className="icansvg">
                        {" "}
                        <Icon
                          component={
                            shipment.status === "manifested"
                              ? CircleCheck
                              : ShipmentStatusIcon
                          }
                          style={{ marginTop: "6px" }}
                        />
                      </div>
                    )}

                    <div
                      style={{
                        color: "#9B9B9B",
                        fontWeight: "500",
                        letterSpacing: "0.032em",
                        lineHeight: "16px",
                      }}
                    >
                      <div
                        style={{
                          marginTop:
                            shipmentStatusText[shipment.status] === "In Transit"
                              ? "3px"
                              : null,
                        }}
                      >
                        {" "}
                        {shipmentStatusText[shipment.status] === "In Transit"
                          ? shipmentStatusText[shipment.status]
                          : shipmentStatusText[shipment.status]}
                      </div>

                      <div>
                        {" "}
                        {(shipment.status === "delivered" ||
                          shipment.status === "rto") && (
                          <p
                            className="delivered-date"
                            style={{
                              color: shipmentStatusColors[shipment.status],
                            }}
                          >
                            {shipment.status === "rto" ? null : "Delivered"}{" "}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="tabs-row">
            <ul>
              <li
                className={this.state.activeTab === "tracking" ? "active" : ""}
                onClick={() => this.setActiveTab("tracking")}
              >
                Tracking
              </li>

              <li
                className={this.state.activeTab === "lineItems" ? "active" : ""}
                onClick={() => this.setActiveTab("lineItems")}
              >
                Line Items
              </li>
              <li
                className={
                  this.state.activeTab === "customerInfo" ? "active" : ""
                }
                onClick={() => this.setActiveTab("customerInfo")}
              >
                Customer Info
              </li>
            </ul>
          </div>

          <div
            className={`tab-container ${
              this.state.activeTab !== "lineItems" ? "hidden" : ""
            }`}
          >
            <LineItems
              {...this.props}
              status={shipment.status}
              shipment={this.props.shipment}
              lineItems={lineItems}
            />
          </div>

          <div
            className={`tab-container ${
              this.state.activeTab !== "customerInfo" ? "hidden" : ""
            }`}
          >
            <CustomerInfo shipment={shipment} />
          </div>

          <div
            className={`tab-container ${
              this.state.activeTab !== "tracking" ? "hidden" : ""
            }`}
          >
            <Shipmentprogressbar2 lineItems={lineItems} shipment={shipment} />
          </div>
        </Card>
        {this.state.visible ? (
          <ShipmentRequestModal
            toggleShipmentDisputeModal={() => this.toggleShipmentDisputeModal()}
            visible={this.state.visible}
            data={this.state.shipment}
            match={this.props.match}
            // setArrowdata={() => this.setArrowdata()}
            status={this.state.status}
            items={this.state.shipment.items}
            replacementStatus={this.state.replacement}
            rvpStatus={this.state.rvpStatus}
            refundedstatus={this.state.refundedstatus}
            shipmentTotalAmount={this.state.shipment.shipmentTotalAmount}
            request_status_data={this.state.shipment.request_status_data}
            gst_enabled={this.props.gst_enabled}
          />
        ) : null}
      </>
    );
  }
}

export default ShipmentCard;
