import React from "react";
import { Divider } from "antd";
import { getCurrencySymbol } from "../../../../../../Helpers/Pricing";
import CouponCodeField from "./CouponCodeField";
import "./index.scss";

class CardFooter extends React.Component {
  state = { visible: false };

  priceHandler() {
    if (!!this.props.trial) {
      return getCurrencySymbol(this.props.card.currency) + 1;
    }

    if (!!this.props.state.couponPrice) {
      return (
        <div className="custom-price-strike">
          <h3>
            {getCurrencySymbol(this.props.card.currency)}{" "}
            {this.props.state.couponPrice}
          </h3>

          <p>
            {getCurrencySymbol(this.props.card.currency)}{" "}
            {this.props.card.amount}
          </p>
        </div>
      );
    }

    return getCurrencySymbol(this.props.card.currency) + this.props.card.amount;
  }

  render() {
    return (
      <div className="card-footer">
        <Divider />

        <div className="total-amount">
          <h1>Total Amount:</h1>{" "}
          <h2>
            {this.priceHandler()}

            {/* {!!this.props.trial ? 1 : this.props.card.amount} */}
          </h2>
        </div>

        {!this.props.trial && (
          <CouponCodeField
            planId={this.props.planId}
            state={this.props.state}
            setState={(e) => this.props.setState(e)}
          />
        )}

        <div className="terms">
          By clicking, you agree to the{" "}
          <a target="_blank" href="https://vfulfill.io/legals/terms">
            T&C, Privacy Policy, Cancellation Policy
          </a>{" "}
          {/* and <a href="">Refund Policy</a>. */}
        </div>
      </div>
    );
  }
}

export default CardFooter;
