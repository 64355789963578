import React, { Component } from "react";
import LoginPageContainer from "../LoginPageContainer";
import { Form, Icon, Input, Button, Tabs, Alert, message } from "antd";
import axios from "axios";

const TabPane = Tabs.TabPane;

class ResetPasswordForm extends Component {
  state = {
    loading: false,
    showMsg: false,
    msgType: "error",
    msg: "",
    confirmDirty: false,
  };

  componentDidMount() {
    document.title = this.props.title;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true }, () => {
          axios({
            url: process.env.REACT_APP_API_URL + "/login/verify_password_reset",
            method: "post",
            data: {
              hash: this.props.match.params.hash,
              password: values.password,
              cpassword: values.cpassword,
            },
            withCredentials: true,
          })
            .then((res) => {
              if (res.data.success === 1) {
                this.props.history.push("/login");
                message.success("Password Reset Successfully");
              } else {
                this.setState({
                  showMsg: true,
                  loading: false,
                  msgType: "error",
                  msg: res.data.msg,
                });
              }
            })
            .catch((e) => {
              this.setState({
                showMsg: true,
                loading: false,
                msgType: "error",
                msg: e.message,
              });
            });
        });
      }
    });
  };

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("The passwords that you entered do not match!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(["cpassword"], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <LoginPageContainer>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Reset Your Password" key="1">
            <Form
              onSubmit={this.handleSubmit}
              className="forgot-form"
              noValidate
            >
              <Form.Item>
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter a valid password",
                    },
                    { validator: this.validateToNextPassword },
                  ],
                })(
                  <Input
                    size="large"
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="Enter your new password"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("cpassword", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter a valid password",
                    },
                    { validator: this.compareToFirstPassword },
                  ],
                })(
                  <Input
                    size="large"
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="Re-enter your new password"
                    onBlur={this.handleConfirmBlur}
                  />
                )}
              </Form.Item>
              <Form.Item>
                {this.state.showMsg && (
                  <Alert message={this.state.msg} type={this.state.msgType} />
                )}
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{
                    pointerEvents: this.state.sentSuccessfully
                      ? "none"
                      : "auto",
                  }}
                  loading={this.state.loading}
                >
                  Continue
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </LoginPageContainer>
    );
  }
}

export default Form.create()(ResetPasswordForm);
