import React, { Component } from "react";
import { Modal, Icon, Button } from "antd";
import { formatNumber } from "../../../../Helpers/Pricing";
import { ReactComponent as rightArrow } from "../../../../assets/images/icons/long-arrow-pointing-to-the-right.svg";
import { ReactComponent as successIcon } from "../../../../assets/images/icons/sucees.svg";
import "./index.scss";

class TransactionSuccess extends Component {
  render() {
    return (
      <Modal
        className="transactionSuccessModal"
        visible={this.props.visible}
        width={480}
        title={null}
        onCancel={() => this.props.toggleSuccessModal()}
        footer={null}
      >
        <div style={{ textAlign: "center" }}>
          <div className="transaction-sucess-content">
            <Icon component={successIcon} />
            <h1>TRANSACTION SUCESSFUL!</h1>
            <p>
              You have successfully added ₹ {formatNumber(this.props.amount)} to
              your wallet.
            </p>
          </div>
          <div className="footer-button">
            <Button onClick={() => window.location.reload()}>
              Continue To Dashboard
              <Icon component={rightArrow} />
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default TransactionSuccess;
