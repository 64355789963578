import React from "react";
import { Button, Skeleton, message } from "antd";
import { loadStripe } from "@stripe/stripe-js";
import { getCurrencySymbol } from "../../../../Helpers/Pricing";
import {
  Elements,
  ElementsConsumer,
  PaymentElement,
} from "@stripe/react-stripe-js";

import MoverCard from "../PlansScreen/Cards/MoverCard";
import ShakerCard from "../PlansScreen/Cards/ShakerCard";
import EarthQuakerCard from "../PlansScreen/Cards/EarthQuakerCard";

import CardFooter from "../PlansScreen/Cards/CardFooter";
import analytics from "../../../../analytics";
import "./index.scss";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

class PaymentForm extends React.Component {
  state = {
    stripeObj: null,
    loading: false,
    cardArr: [],
  };

  // componentDidMount() {
  //   const loadStripeAsync = async () => {
  //     const stripe = await stripePromise;

  //     this.setState({ stripeObj: stripe }, () =>
  //       console.log("ddddddddd", this.state.stripeObj)
  //     );
  //   };

  //   loadStripeAsync();
  // }

  componentDidMount() {
    this.setState({
      cardArr:
        this.props.planType === "month"
          ? !!this.props.plans && !!this.props.plans.monthly.length
            ? this.props.plans.monthly
            : []
          : !!this.props.plans && !!this.props.plans.yearly.length
          ? this.props.plans.yearly
          : [],
    });
  }

  cardTypesHandler() {
    return (
      !!this.state.cardArr &&
      this.state.cardArr
        .filter((card) => card.plan_id === this.props.state.plan_id)
        .map((card) => {
          if (String(card.plan_name).includes("Earthquaker")) {
            return (
              <EarthQuakerCard
                website_signup={this.props.website_signup}
                footer={
                  <CardFooter
                    state={this.props.state}
                    planId={this.props.planId}
                    setState={(state) => this.props.setState(state)}
                    trial={this.props.state.trial}
                    card={card}
                  />
                }
                preview={true}
                loading={this.props.loading}
                getCurrencySymbol={(currency) => getCurrencySymbol(currency)}
                state={this.props.state}
                setState={(state) => this.props.setState(state)}
                card={card}
                planType={this.props.planType}
                tabHandler={(key) => this.props.tabHandler(key)}
              />
            );
          }

          if (String(card.plan_name).includes("Shaker")) {
            return (
              <ShakerCard
                website_signup={this.props.website_signup}
                footer={
                  <CardFooter
                    state={this.props.state}
                    planId={this.props.planId}
                    setState={(state) => this.props.setState(state)}
                    trial={this.props.state.trial}
                    card={card}
                  />
                }
                preview={true}
                loading={this.props.loading}
                state={this.props.state}
                setState={(state) => this.props.setState(state)}
                card={card}
                planType={this.props.planType}
                tabHandler={(key) => this.props.tabHandler(key)}
              />
            );
          }
          if (String(card.plan_name).includes("Mover")) {
            return (
              <MoverCard
                website_signup={this.props.website_signup}
                footer={
                  <CardFooter
                    state={this.props.state}
                    planId={this.props.planId}
                    setState={(state) => this.props.setState(state)}
                    trial={this.props.state.trial}
                    card={card}
                  />
                }
                preview={true}
                loading={this.props.loading}
                state={this.props.state}
                setState={(state) => this.props.setState(state)}
                card={card}
                planType={this.props.planType}
                tabHandler={(key) => this.props.tabHandler(key)}
              />
            );
          }
        })
    );
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    analytics.track("Plan Payment Clicked");
    const { elements, stripe } = this.props;
    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    this.setState({ loading: true });

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // handleError(submitError);

      this.setState({ loading: false });
      return;
    }

    // Use the clientSecret and Elements instance to confirm the setup
    const { error } = await stripe.confirmPayment({
      elements,

      confirmParams: {
        return_url: "https://example.com/order/123/complete",
      },
      // Uncomment below if you only want redirect for redirect-based payments
      redirect: "if_required",
    });

    await stripe
      .retrievePaymentIntent(this.props.clientSecretKey)
      .then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            analytics.track("Payment Succeeded");
            analytics.track("Plan Subscribe" + `${this.props.planName}`);
            this.props.setState({ activeKey: "3" });
            break;
          case "processing":
            message.info("Your payment is processing.");

            break;
          case "requires_payment_method":
            break;
          default:
            break;
        }
      });

    this.setState({ loading: false });

    if (error) {
      message.error(error.message, 5);

      analytics.track("Payment Failed" + error.code);

      // handleError(error);
    }
  };

  render() {
    return (
      <>
        <div id="fade-in" className="stripe-screen-wraper">
          <div className="stripe-header">
            <h1>Confirm Your Growth Plan</h1>
          </div>

          <div className="stripe-card-wraper">
            {!this.props.stripe ? (
              <Skeleton />
            ) : (
              <div className="payment-element-wraper">
                <h1>Enter your Card Details</h1>
                <div className="payment-element">
                  <PaymentElement />

                  <div className="btn-wraper">
                    <Button
                      loading={this.state.loading}
                      onClick={(e) => this.handleSubmit(e)}
                    >
                      Continue with Payment
                    </Button>
                  </div>
                </div>

                <div className="secure">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.967"
                    height="22"
                    viewBox="0 0 16.967 22"
                  >
                    <path
                      id="lock_FILL1_wght200_GRAD200_opsz48"
                      d="M186.967-841a1.894,1.894,0,0,1-1.39-.577,1.894,1.894,0,0,1-.577-1.39v-10.649a1.886,1.886,0,0,1,.577-1.4,1.908,1.908,0,0,1,1.39-.568h1.646V-858.1a4.733,4.733,0,0,1,1.419-3.481A4.7,4.7,0,0,1,193.484-863a4.7,4.7,0,0,1,3.453,1.419,4.735,4.735,0,0,1,1.418,3.481v2.518H200a1.908,1.908,0,0,1,1.39.568,1.886,1.886,0,0,1,.577,1.4v10.649a1.894,1.894,0,0,1-.577,1.39A1.894,1.894,0,0,1,200-841Zm6.524-5.378a1.809,1.809,0,0,0,1.328-.55,1.8,1.8,0,0,0,.55-1.324,1.843,1.843,0,0,0-.557-1.322,1.794,1.794,0,0,0-1.335-.574,1.768,1.768,0,0,0-1.328.576,1.88,1.88,0,0,0-.55,1.338,1.769,1.769,0,0,0,.557,1.308A1.839,1.839,0,0,0,193.491-846.378Zm-3.62-9.2H197.1V-858.1a3.524,3.524,0,0,0-1.053-2.582,3.472,3.472,0,0,0-2.558-1.06,3.476,3.476,0,0,0-2.559,1.06,3.522,3.522,0,0,0-1.055,2.582Z"
                      transform="translate(-185 863)"
                      fill="#898989"
                    />
                  </svg>
                  Guaranteed safe & secure checkout
                </div>
              </div>
            )}

            <div className="preview-card-wraper">
              <div className="change-plan">
                <h1>Your Plan Details</h1>

                <h2
                  onClick={(e) => {
                    analytics.track("Plan Change Clicked");
                    this.props.setState({
                      activeKey: "1",
                      clientSecretKey: "",
                      isFromWebsite: false,
                    });
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="svg-icon"
                    width="16"
                    height="16"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                  >
                    <path d="M810.666667 469.333333H304.64l154.88-186.026666a42.666667 42.666667 0 1 0-65.706667-54.613334l-213.333333 256a50.773333 50.773333 0 0 0-3.84 6.4c0 2.133333 0 3.413333-2.986667 5.546667A42.666667 42.666667 0 0 0 170.666667 512a42.666667 42.666667 0 0 0 2.986666 15.36c0 2.133333 0 3.413333 2.986667 5.546667a50.773333 50.773333 0 0 0 3.84 6.4l213.333333 256A42.666667 42.666667 0 0 0 426.666667 810.666667a42.666667 42.666667 0 0 0 27.306666-9.813334 42.666667 42.666667 0 0 0 5.546667-60.16L304.64 554.666667H810.666667a42.666667 42.666667 0 0 0 0-85.333334z" />
                  </svg>
                  Change Plan
                </h2>
              </div>
              {/* <MoverCard footer={<CardFooter />} preview={true} /> */}
              {this.cardTypesHandler()}
            </div>
          </div>
        </div>
      </>
    );
  }
}
class StripePaymentForm extends React.Component {
  render() {
    if (this.props.state && this.props.state.clientSecretKeyLoading) {
      return <Skeleton />;
    }

    return (
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret: this.props.clientSecretKey,
        }}
      >
        <ElementsConsumer>
          {(ctx) => (
            <PaymentForm
              website_signup={this.props.website_signup}
              planId={this.props.planId}
              planName={this.props.planName}
              state={this.props.state}
              setState={(state) => this.props.setState(state)}
              plans={this.props.plans}
              planType={this.props.planType}
              clientSecretKey={this.props.clientSecretKey}
              tabHandler={(e) => this.props.tabHandler(e)}
              {...ctx}
            />
          )}
        </ElementsConsumer>
      </Elements>
    );
  }
}
export default StripePaymentForm;
