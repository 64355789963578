import React from "react";
import { Row, Col, Icon, Button } from "antd";
import Tick from "../../../../../../assets/images/icons/tick.png";
import "./ImportListProductBasic.scss";

class RejectedMessage extends React.Component {
  render() {
    return (
      <div style={{ width: "100%" }}>
        {this.props.escalated === 3 ? (
          <Row
            style={{
              width: "100%",
              background: "#FDF7F0",
              border: "1px solid #FD9526",
              padding: "10px",
              borderRadius: "3px",
              marginTop: "16px",
            }}
          >
            <Col md={24}>
              <p
                style={{
                  fontSize: "12px",
                  color: "#000000D9",
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    width: "24px",
                    height: "24px",
                    fontSize: "24px",
                    lineHeight: "24px",
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "8px",
                  }}
                >
                  <img style={{ marginTop: "-5px" }} src={Tick} alt="tick" />
                </span>
                Your ticket regarding this product has been raised. Our team
                will contact you shortly to resolve the issue
              </p>
            </Col>
          </Row>
        ) : (
          this.props.messageStatus === "rejected" && (
            <Row
              style={{
                width: "100%",
                background: "#FFF7F5",
                border: "1px solid #FD4726",
                padding: "10px",
                borderRadius: "3px",
                marginTop: "16px",
              }}
            >
              <Col md={1}>
                <span
                  style={{
                    width: "24px",
                    height: "24px",
                    fontSize: "24px",
                    lineHeight: "24px",
                    display: "inline-block",
                    color: "#FD4726",
                  }}
                >
                  <Icon type="exclamation-circle" theme="filled" />
                </span>
              </Col>
              <Col md={23}>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#000000D9",
                  }}
                >
                  Uh-oh! Looks like we are unable to catalog this product at
                  this point in time. This is mostly because our catalog team
                  thinks that we cannot fulfill orders on this product .
                </p>
                {this.props.vfRemark ? (
                  <p
                    style={{
                      fontSize: "12px",
                      color: "#000000D9",
                      marginTop: "18px"
                    }}
                  >
                    vFulfill Catalog Team Remarks: <span style={{fontWeight:"600"}}>"{this.props.vfRemark}"</span>
                  </p>
                ) : (
                  ""
                )}
                <div style={{ display: "flex", justifyContent: "flexStart" }}>
                  <p style={{ marginTop: "20px", marginRight: "18px" }}>
                    Think it could be a mistake?
                  </p>
                  <Button
                    className="rejected"
                    style={{
                      backgroundColor: "#FD4726",
                      color: "#fff",
                      textAlign: "center",
                      display: "inline-block",
                      // padding: "8px 12px",
                      borderRadius: "2px",
                      marginTop: "12px",
                      cursor: "pointer",
                      fontSize: "12px",
                    }}
                    onClick={() => this.props.rejected()}
                    loading={this.props.loading}
                    disabled={this.props.loading}
                  >
                    Raise a ticket
                  </Button>
                </div>
              </Col>
            </Row>
          )
        )}
      </div>
    );
  }
}

export default RejectedMessage;
