import React, { Component } from "react";
import { connect } from "react-redux";
import { Skeleton, Card, Pagination, message } from "antd";
import "./index.scss";
import Wishlistproductnav from "./WishlistProductNav";
import Wishlistcard from "./wishliscard";
import ActionNotification from "../../Common/ActionNotification";
import qs from "qs";
import axios from "axios";
import NotFoundCard from "../../Common/NotFoundCard";

class WishlistProduct extends Component {
  state = {
    loading: true,
    wishlistproduct: [],
    paging: {
      page: 1,
      per_page_count: 10,
    },
    listing: 1,
    filters: {
      search: "",
      watchlist: 1,
    },
    urlSearched: false,
  };

  componentDidMount() {
    const params = qs.parse(this.props.location.search.replace(/\?/g, ""));
    if (params.search) {
      this.setState(
        {
          urlSearched: true,
          filters: {
            ...this.state.filters,
            search: params.search,
          },
        },
        () => this.loadData()
      );
    } else {
      this.loadData();
    }
  }

  componentDidUpdate(pp) {
    if (pp.location.search !== this.props.location.search) {
      const params = qs.parse(this.props.location.search.replace(/\?/g, ""));
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: params.search,
          },
          paging: { page: 1, per_page_count: 10 },
        },
        () => this.loadData()
      );
    }
  }

  goToPage(page) {
    this.setState(
      {
        paging: { ...this.state.paging, page },
      },
      () => {
        this.loadData();
      }
    );
  }

  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        paging: { ...this.state.paging, per_page_count: pageSize },
      },
      () => this.loadData()
    );
  }

  searchhandler() {
    if (window.location.href.includes("import-list")) {
      this.props.history.push(
        `/${this.props.match.params.storegeo}/${
          this.props.match.params.storeid
        }/products/import-list?search=${this.state.filters.search}`
      );
    } else {
      this.props.history.push(
        `/${this.props.match.params.storegeo}/${
          this.props.match.params.storeid
        }/products/wishlist-products?search=${this.state.filters.search}`
      );
    }
  }

  changeFilter(kvpairs) {
    this.setState(
      {
        filters: { ...this.state.filters, ...kvpairs },
        paging: { ...this.state.paging, page: 1 },
      },
      () => this.searchhandler(),
      () => this.loadData()
    );
  }

  importProducts = (productIds, name = null) => {
    axios({
      url:
        process.env.REACT_APP_API_URL + "/products/import_products_aliexpress",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        productids: productIds,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          !!name
            ? ActionNotification({
                notificationType: "importProduct",
                name: name,
              })
            : ActionNotification({
                notificationType: "ali-express-import",
              });
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  loadData() {
    this.setState({ loading: true }, () => {
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.cancelToken = axios.CancelToken.source();
      axios({
        url: process.env.REACT_APP_API_URL + "/products/search",
        method: "post",
        cancelToken: this.cancelToken.token,
        data: {
          storeid: this.props.match.params.storeid,
          page: this.state.paging.page,
          per_page_count: this.state.paging.per_page_count,
          filters: this.state.filters,
          listing: this.state.listing,
        },
        withCredentials: true,
      })
        .then((res) => {
          this.setState({ loading: false });
          return res;
        })
        .then((res) => {
          if (res.data.success === 1) {
            const upd = {
              wishlistproduct: res.data.products,
              total: res.data.total,
              requestCount:
                !!res.data.products.length &&
                res.data.products[0].request_latest_price
                  ? res.data.products[0].request_latest_price.count
                  : null,
              loading: false,
            };
            this.setState(upd);
          } else {
            this.setState({
              error: true,
              errorMsg: res.data.msg,
              loading: false,
            });
          }
        })
        .catch((e) => {
          this.setState({
            error: true,
            errorMsg: e.message,
            loading: false,
          });
        });
    });
  }

  changeHandler(kvpairs) {
    this.setState({
      filters: { ...this.state.filters, ...kvpairs },
    });
  }

  lessRequestCount() {
    if (this.state.requestCount > 0) {
      this.setState({ requestCount: this.state.requestCount - 1 });
    }
  }

  render() {
    const currency = this.props.match.params.storegeo === "in" ? "INR" : "USD";
    return (
      <>
        <Wishlistproductnav
          loading={this.state.loading}
          count={this.props.count}
          changeHandler={(e) => this.changeHandler(e)}
          changeFilter={(e) => this.changeFilter(e)}
          data={this.state.filters}
          wishlistproduct={this.state.wishlistproduct}
          urlSearched={this.state.urlSearched}
        />

        {this.state.loading ? (
          <div>
            <Skeleton active />
          </div>
        ) : (
          <div className="wishlit-product-listing">
            <div className="wishlist-content">
              <div className="content">
                {this.state.loading ? (
                  <Skeleton active />
                ) : this.state.wishlistproduct.length > 0 ? (
                  <>
                    <div className="product-grid">
                      {this.state.wishlistproduct.map((p) => (
                        <Wishlistcard
                          key={p._id["$oid"]}
                          wishlistproduct={p}
                          lessRequestCount={() => this.lessRequestCount()}
                          requestCount={this.state.requestCount}
                          currency={currency}
                          storeid={this.props.match.params.storeid}
                          storegeo={this.props.match.params.storegeo}
                          importProduct={(id, name) =>
                            this.importProducts([id], name)
                          }
                        />
                      ))}
                    </div>
                    <Pagination
                      locale={{ jump_to: "Go to page" }}
                      showTotal={(total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`
                      }
                      showQuickJumper
                      showSizeChanger
                      pageSizeOptions={["10", "25", "50"]}
                      onShowSizeChange={(p, d) => this.onShowSizeChange(p, d)}
                      current={this.state.paging.page}
                      onChange={(page) => this.goToPage(page)}
                      total={this.state.total}
                      defaultPageSize={this.state.paging.per_page_count}
                    />
                  </>
                ) : (
                  <NotFoundCard
                    mainHeading={"Oops! No Data Found."}
                    secondHeading={
                      <p>Looks like there are no products in this page.</p>
                    }
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
    count: state.auth.count,
  };
};
export default connect(mapStateToProps)(WishlistProduct);
