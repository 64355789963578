import { Row, Pagination, Card, Skeleton } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import PrivateProductCard from "./PrivateProductCard";
import "./index.scss";
import Privateproductnav from "./PrivateProductnav";
import qs from "qs";
import NotFoundCard from "../../Common/NotFoundCard";

class PrivateProduct extends Component {
  state = {
    urlSearched: false,
    loading: true,
    products: [],
    gst_enabled: 0,
    paging: {
      page: 1,
      per_page_count: 10,
    },
    filters: {
      updates: "",
      linkStatus: this.props.match.params.linkStatus
        ? this.props.match.params.linkStatus
        : "",
      search: "",
    },
  };

  componentDidMount() {
    const params = qs.parse(this.props.location.search.replace(/\?/g, ""));
    if (params.search) {
      this.setState(
        {
          urlSearched: true,
          filters: {
            ...this.state.filters,
            search: params.search,
          },
        },
        () => this.loadData()
      );
    } else {
      this.loadData();
    }
  }
  componentDidUpdate(pp) {
    if (pp.location.search !== this.props.location.search) {
      const params = qs.parse(this.props.location.search.replace(/\?/g, ""));
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: params.search,
          },
          paging: { page: 1, per_page_count: 10 },
        },
        () => this.loadData()
      );
    }
  }

  goToPage(page) {
    this.setState(
      {
        paging: { ...this.state.paging, page },
      },
      () => {
        this.loadData();
      }
    );
  }

  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        paging: { ...this.state.paging, per_page_count: pageSize },
      },
      () => this.loadData()
    );
  }

  searchhandler() {
    if (window.location.href.includes("import-list")) {
      this.props.history.push(
        `/${this.props.match.params.storegeo}/${
          this.props.match.params.storeid
        }/products/import-list?search=${this.state.filters.search}`
      );
    } else {
      this.props.history.push(
        `/${this.props.match.params.storegeo}/${
          this.props.match.params.storeid
        }/products/private-products?search=${this.state.filters.search}`
      );
    }
  }

  changeFilter(kvpairs) {
    this.setState(
      {
        filters: { ...this.state.filters, ...kvpairs },
        paging: { ...this.state.paging, page: 1 },
      },
      () => this.searchhandler(),
      () => this.loadData()
    );
  }

  loadData() {
    this.setState({ loading: true }, () => {
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.cancelToken = axios.CancelToken.source();
      axios({
        url: process.env.REACT_APP_API_URL + "/products/get_privateProduct",
        method: "post",
        cancelToken: this.cancelToken.token,
        data: {
          storeid: this.props.match.params.storeid,
          page: this.state.paging.page,
          per_page_count: this.state.paging.per_page_count,
          filters: this.state.filters,
          listing: 1,
        },
        withCredentials: true,
      })
        .then((res) => {
          this.setState({ loading: false });
          return res;
        })
        .then((res) => {
          if (res.data.success === 1) {
            const upd = {
              products: res.data.products,
              total: res.data.total,
              loading: false,
              requestCount:
                !!res.data.products.length &&
                res.data.products[0].request_latest_price
                  ? res.data.products[0].request_latest_price.count
                  : null,
              niches: res.data.niches,
              categories: res.data.categories,
              mapping: res.data.mapping,
              gst_enabled: res.data.gst_enabled,
            };
            this.setState(upd);
          } else {
            this.setState({
              error: true,
              errorMsg: res.data.msg,
              loading: false,
            });
          }
        })
        .catch((e) => {
          this.setState({
            error: true,
            errorMsg: e.message,
            loading: false,
          });
        });
    });
  }

  changeHandler(kvpairs) {
    this.setState({
      filters: { ...this.state.filters, ...kvpairs },
    });
  }

  lessRequestCount() {
    if (this.state.requestCount > 0) {
      this.setState({ requestCount: this.state.requestCount - 1 });
    }
  }

  render() {
    const currency = this.props.match.params.storegeo === "in" ? "INR" : "USD";
    return (
      <>
        <Privateproductnav
          urlSearched={this.state.urlSearched}
          loading={this.state.loading}
          count={this.props.count}
          changeHandler={(e) => this.changeHandler(e)}
          changeFilter={(e) => this.changeFilter(e)}
          data={this.state.filters}
          products={this.state.products}
        />

        {this.state.loading ? (
          <Row style={{ marginBottom: "30px" }}>
            <Skeleton active />
          </Row>
        ) : (
          <div>
            <div className="Private-catalog-listing">
              <div className="catalog-listing-content">
                <div className="content">
                  {this.state.loading ? (
                    <Skeleton active />
                  ) : this.state.products.length > 0 ? (
                    <>
                      <div className="product-grid">
                        {this.state.products.map((p) => (
                          <PrivateProductCard
                            key={p._id["$oid"]}
                            product={p}
                            lessRequestCount={() => this.lessRequestCount()}
                            requestCount={this.state.requestCount}
                            currency={currency}
                            storeid={this.props.match.params.storeid}
                            storegeo={this.props.match.params.storegeo}
                            gst_enabled={this.state.gst_enabled}
                          />
                        ))}
                      </div>
                      <Pagination
                        locale={{ jump_to: "Go to page" }}
                        showTotal={(total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`
                        }
                        showQuickJumper
                        showSizeChanger
                        pageSizeOptions={["10", "25", "50"]}
                        onShowSizeChange={(p, d) => this.onShowSizeChange(p, d)}
                        current={this.state.paging.page}
                        onChange={(page) => this.goToPage(page)}
                        total={this.state.total}
                        defaultPageSize={this.state.paging.per_page_count}
                      />
                    </>
                  ) : (
                    <NotFoundCard
                      mainHeading={"Oops! No Data Found."}
                      secondHeading={
                        <p>Looks like there are no products in this page.</p>
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
    count: state.auth.count,
  };
};
export default connect(mapStateToProps)(PrivateProduct);
