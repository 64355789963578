import React, { Component } from "react";
import "./index.scss";
import { ReactComponent as VfulfillLogo } from "../../../../../assets/images/icons/vFULLfillLogo.svg";
import { ReactComponent as GreenArrow } from "../../../../../assets/images/icons/greenArrow.svg";
export default class TabSecondContainer extends Component {
  render() {
    return (
      <div className="tabSecondContainer">
        <div className="logocontainer">
          <VfulfillLogo />
        </div>
        <div className="dis">
        <div className="dis-container">
          <div className="top-data">
            “vFulfill is <span className="para">hands-down everything you need to run your eCommerce
            business in India.</span> They helped us scale several winners and also
            start a D2C brand on a couple of those products. Recommended 5/5.”
          </div>
          <div className="name">Hardik Bhatia</div>
          <div className="infodata">
          <div className="info">Internet eCompreneur &</div>
          <div className="info">CEO at Koozo India</div>
          </div>
          
          <div className="stat"> <span className="greenArrowContainer"><GreenArrow/></span>$1.7M + in  1 year</div>
        </div>
        </div>
      </div>
    );
  }
}
