import React, { Component } from "react";
import { DatePicker, Select,message } from "antd";
import moment from "moment";
import Globalsearch from "../../Common/Globalsearch";
import "./index.scss";
import axios from "axios";
const { Option } = Select;
const { RangePicker } = DatePicker;

class NdrEscalationNav extends Component {
  state = {
    allBtn: false,
    openbtn: true,
    closebtn: false,
    pendingbtn: false,
    mount: false,
  };

  componentDidUpdate() {
    let status = this.props.filters.status;

    if (status === "all" && this.state.mount !== true) {
      this.setState({
        allBtn: true,
        openbtn: false,
        closebtn: false,
        pendingbtn: false,
        mount: true,
      });
    }
  }
  download() {
    axios({
      url: process.env.REACT_APP_API_URL + "/ndr/get_ndr_escalation",
      method: "post",
      data: {
        storeid: this.props.storeid,
        filters: this.props.filters,
        page: this.props.page,
        pageSize: this.props.pageSize,
        downloadcsv: 1,
        ndr_bucket: this.props.ndr_bucket,
        ndr_count: this.props.ndr_count,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMsg: e.message,
          loading: false,
        });
      });
  }

  render() {
    return (
      <div className="picing-request-nav fullorder_data">
        <div className="request-nav">
          <div className="ordfill">
            <div className="ordfillwrap">
              <div className="left">
                <div className="content-page-title breadcurmb-txt">
                  Escalations <span style={{ margin: "0 8px" }}>&gt;</span>
                  <span className="escalations-txt">NDR Escalations</span>
                </div>
              </div>

              <div className="right">
                <p className="show-data-from">Show Data From: </p>

                <RangePicker
                  className="range-picker"
                  value={[
                    this.props.filters.startDate === ""
                      ? null
                      : moment(this.props.filters.startDate, "YYYY-MM-DD"),
                    this.props.filters.endDate === ""
                      ? null
                      : moment(this.props.filters.endDate, "YYYY-MM-DD"),
                  ]}
                  onChange={(_, dateString) =>
                    this.props.changeFilter2({
                      startDate: dateString[0],
                      endDate: dateString[1],
                    })
                  }
                  ranges={{
                    Today: [moment(), moment()],
                    "This Week": [
                      moment().startOf("week"),
                      moment().endOf("week"),
                    ],
                    "Last 15 Days": [moment().subtract("days", 15), moment()],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                    "Last Month": [
                      moment()
                        .subtract(1, "month")
                        .startOf("month"),
                      moment()
                        .subtract(1, "month")
                        .endOf("month"),
                    ],
                    "Last Year": [
                      moment()
                        .subtract(1, "year")
                        .startOf("year"),
                      moment()
                        .subtract(1, "year")
                        .endOf("year"),
                    ],
                  }}
                />
              </div>
            </div>
            <div className="secondwrap">
              <div className="filtertab">
                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-ndrEscalations-underline-animation "
                      : this.state.openbtn
                      ? "tab  hover-ndrEscalations-underline-animation-click"
                      : "tab hover-ndrEscalations-underline-animation"
                  }
                >
                  <button
                    className={this.state.openbtn ? "active" : ""}
                    onClick={() => {
                      this.setState({
                        allBtn: false,
                        openbtn: true,
                        closebtn: false,
                        pendingbtn: false,
                      });
                      this.props.changeFilter2({
                        status: "",
                        startDate: "",
                        endDate: "",
                      });
                    }}
                  >
                    Open
                  </button>

                  <div
                    className={` ${
                      this.state.openbtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.tabcounts && this.props.tabcounts.open
                      ? this.props.tabcounts.open > 1000
                        ? (Number(this.props.tabcounts.open) / 1000).toFixed(
                            1
                          ) + "k"
                        : this.props.tabcounts.open < 1000
                        ? this.props.tabcounts.open
                        : null
                      : this.props.tabcounts.open === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-ndrEscalations-underline-animation "
                      : this.state.pendingbtn
                      ? "tab  hover-ndrEscalations-underline-animation-click"
                      : "tab hover-ndrEscalations-underline-animation"
                  }
                >
                  <button
                    className={this.state.pendingbtn ? "active" : ""}
                    onClick={() => {
                      this.setState({
                        allBtn: false,
                        openbtn: false,
                        closebtn: false,
                        pendingbtn: true,
                      });
                      this.props.changeFilter2({
                        status: "in_processing",
                        startDate: "",
                        endDate: "",
                      });
                    }}
                  >
                    In-Processing
                  </button>

                  <div
                    className={` ${
                      this.state.pendingbtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.tabcounts && this.props.tabcounts.in_processing
                      ? this.props.tabcounts.in_processing > 1000
                        ? (
                            Number(this.props.tabcounts.in_processing) / 1000
                          ).toFixed(1) + "k"
                        : this.props.tabcounts.in_processing < 1000
                        ? this.props.tabcounts.in_processing
                        : null
                      : this.props.tabcounts.in_processing === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-ndrEscalations-underline-animation "
                      : this.state.closebtn
                      ? "tab  hover-ndrEscalations-underline-animation-click"
                      : "tab hover-ndrEscalations-underline-animation"
                  }
                >
                  <button
                    className={this.state.closebtn ? "active" : ""}
                    onClick={() => {
                      this.setState({
                        allBtn: false,
                        openbtn: false,
                        closebtn: true,
                        pendingbtn: false,
                      });
                      this.props.changeFilter2({
                        status: "close",
                        startDate: "",
                        endDate: "",
                      });
                    }}
                  >
                    Closed
                  </button>

                  <div
                    className={` ${
                      this.state.closebtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.tabcounts && this.props.tabcounts.close
                      ? this.props.tabcounts.close > 1000
                        ? (Number(this.props.tabcounts.close) / 1000).toFixed(
                            1
                          ) + "k"
                        : this.props.tabcounts.close < 1000
                        ? this.props.tabcounts.close
                        : null
                      : this.props.tabcounts.close === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-ndrEscalations-underline-animation "
                      : this.state.allBtn
                      ? "tab  hover-ndrEscalations-underline-animation-click"
                      : "tab hover-ndrEscalations-underline-animation"
                  }
                >
                  <button
                    className={this.state.allBtn ? "active" : ""}
                    onClick={() => {
                      this.setState({
                        allBtn: true,
                        openbtn: false,
                        closebtn: false,
                        pendingbtn: false,
                      });
                      this.props.changeFilter2({
                        status: "all",
                        startDate: "",
                        endDate: "",
                      });
                    }}
                  >
                    All
                  </button>

                  <div
                    className={` ${
                      this.state.allBtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.tabcounts && this.props.tabcounts.all
                      ? this.props.tabcounts.all > 1000
                        ? (Number(this.props.tabcounts.all) / 1000).toFixed(1) +
                          "k"
                        : this.props.tabcounts.all < 1000
                        ? this.props.tabcounts.all
                        : null
                      : this.props.tabcounts.all === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>
              </div>
              <div  className="download_csv">
                <button
                  className="clickable"
                  style={{
                    width: "120px",
                  }}
                  onClick={() => this.download()}
                >
                  Download CSV
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-data">
          <div className="Globalsearch">
            <Globalsearch
              class={"nextwidth"}
              data={this.props.filters}
              tooltip={true}
              placeholder={
                "Search by Escalation ID, AWB, Product ID, Product Name"
              }
              change={(value) =>
                this.props.changeHandler({ search: value.target.value })
              }
              searchFunc={(value) => this.props.changeFilter({ search: value })}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default NdrEscalationNav;
