import React from "react";
import {
  // Row,
  // Col,
  Input,
  InputNumber,
  // Button,
  DatePicker,
  // message,
} from "antd";
import moment from "moment";
// import axios from "axios";
import "./index.scss";

class NDRReAttempt extends React.Component {
  state = {
    order: {
      customer_details: {
        email: "",
      },
      shipping_address: {
        name: "",
        address1: "",
        address2: "",
        zip: "",
        country: "",
        city: "",
        province: "",
        phone: "",
      },
    },
    date: null,
    errors: {},
    saving: false,
  };

  componentDidMount() {
    this.setState(
      {
        order: this.props.address,
      },
      () => this.props.newDataHandler(this.state.order)
    );
  }

  updateCustomer(type, key, val) {
    this.setState(
      {
        order: {
          ...this.state.order,
          [type]: {
            ...this.state.order[type],
            [key]: val,
          },
        },
      },
      () => this.props.newDataHandler(this.state.order)
    );
  }

  setDate = (date) => {
    this.setState(
      {
        date,
      },
      () => this.props.datehandler(date)
    );
  };

  render() {
    const order = this.state.order;
    const show = this.props.show;
    return show ? (
      <div className="ndr-reattamp_modal">
        <div className="upper_data">
          <div
            className={`ant-form-item-control ${
              this.props.error.customer_details_name ? "has-error" : ""
            }`}
          >
            <label>Full Name</label>
            <Input
              type="text"
              value={order.shipping_address.name}
              onChange={(e) =>
                this.updateCustomer("shipping_address", "name", e.target.value)
              }
            />
            {this.props.error.customer_details_name && (
              <div className="ant-form-explain">
                {this.props.error.customer_details_name}
              </div>
            )}
          </div>

          <div
            className={`ant-form-item-control ${
              this.props.error.shipping_address_address1 ? "has-error" : ""
            }`}
          >
            <label>Address Line #1</label>
            <Input
              type="text"
              value={order.shipping_address.address1}
              onChange={(e) =>
                this.updateCustomer(
                  "shipping_address",
                  "address1",
                  e.target.value
                )
              }
            />
            {this.props.error.shipping_address_address1 && (
              <div className="ant-form-explain">
                {this.props.error.shipping_address_address1}
              </div>
            )}
          </div>

          <div>
            <div
              className={`ant-form-item-control ${
                this.props.error.shipping_address_zip ? "has-error" : ""
              }`}
            >
              <label>Pin Code</label>
              <InputNumber
                style={{ width: "100%" }}
                value={order.shipping_address.zip}
                onChange={(e) =>
                  this.updateCustomer("shipping_address", "zip", e)
                }
              />
              {this.props.error.shipping_address_zip && (
                <div className="ant-form-explain">
                  {this.props.error.shipping_address_zip}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="middle_data">
          <div
            className={`ant-form-item-control ${
              this.props.error.customer_details_email ? "has-error" : ""
            }`}
          >
            <label>Email Address</label>
            <Input
              type="text"
              value={order.customer_details.email}
              onChange={(e) =>
                this.updateCustomer("customer_details", "email", e.target.value)
              }
            />
            {this.props.error.customer_details_email && (
              <div className="ant-form-explain">
                {this.props.error.customer_details_email}
              </div>
            )}
          </div>

          <div className="ant-form-item-control">
            <label>Address Line #2</label>
            <Input
              type="text"
              value={order.shipping_address.address2}
              onChange={(e) =>
                this.updateCustomer(
                  "shipping_address",
                  "address2",
                  e.target.value
                )
              }
            />
          </div>

          <div
            className={`ant-form-item-control ${
              this.props.error.shipping_address_country ? "has-error" : ""
            }`}
          >
            <label>Country</label>
            <Input
              type="text"
              value={order.shipping_address.country}
              onChange={(e) =>
                this.updateCustomer(
                  "shipping_address",
                  "country",
                  e.target.value
                )
              }
            />
            {this.props.error.shipping_address_country && (
              <div className="ant-form-explain">
                {this.props.error.shipping_address_country}
              </div>
            )}
          </div>
        </div>

        <div className="bottom_data">
          <div className="ant-form-item-control">
            <label>Mobile Number</label>
            <Input
              type="text"
              value={order.shipping_address.phone}
              onChange={(e) =>
                this.updateCustomer("shipping_address", "phone", e.target.value)
              }
            />
          </div>

          <div
            className={`ant-form-item-control ${
              this.props.error.shipping_address_city ? "has-error" : ""
            }`}
          >
            <label>City/Town</label>
            <Input
              type="text"
              value={order.shipping_address.city}
              onChange={(e) =>
                this.updateCustomer("shipping_address", "city", e.target.value)
              }
            />
            {this.props.error.shipping_address_city && (
              <div className="ant-form-explain">
                {this.props.error.shipping_address_city}
              </div>
            )}
          </div>

          <div
            className={`ant-form-item-control ${
              this.props.error.shipping_address_province ? "has-error" : ""
            }`}
          >
            <label>State</label>
            <Input
              type="text"
              value={order.shipping_address.province}
              onChange={(e) =>
                this.updateCustomer(
                  "shipping_address",
                  "province",
                  e.target.value
                )
              }
            />
            {this.props.error.shipping_address_province && (
              <div className="ant-form-explain">
                {this.props.error.shipping_address_province}
              </div>
            )}
          </div>

          <div
            className={`ant-form-item-control ${
              this.props.error.date ? "has-error" : ""
            }`}
          >
            <label>Re-Attempt On</label>
            <DatePicker
              value={this.state.date}
              disabledDate={(current) =>
                current &&
                (current <
                  moment()
                    .subtract(1, "days")
                    .endOf("day") ||
                  current >
                    moment()
                      .add(7, "days")
                      .endOf("day") ||
                  current.isoWeekday() === 7)
              }
              onChange={this.setDate}
              style={{ width: "100%" }}
            />
            {this.props.error.date && (
              <div className="ant-form-explain">{this.props.error.date}</div>
            )}
          </div>
        </div>

        <div
          style={{ marginTop: "10px", display: "flex", justifyContent: "end" }}
        >
          {/* <Button
            className="clickable"
            type="primary"
            size="large"
            onClick={() => this.saveNDRRequest()}
            loading={this.state.saving}
          >
            Re-Attempt Delivery
          </Button> */}
        </div>
      </div>
    ) : null;
  }
}

export default NDRReAttempt;
