import React from "react";
import TourCard from "./TourCard";

export const steps = [
  {
    target: ".snapshot-tour-step",
    content: (
      <TourCard
        name={"Snapshot"}
        step={"1"}
        innerText={
          "Snapshot provides the summary of activity in your Dashboard with Quick Overview of Reports along with Actionable and Important Updates."
        }
        progress={"60%"}
      />
    ),
    disableBeacon: true,
    placement: "right",
    placementBeacon: "right",
  },
  {
    target: ".product-tour-step",
    content: (
      <TourCard
        name={"Product Research & Management"}
        step={"2"}
        innerText={
          "You can research New Products, Export them to your Shopify Store and Take Relevant Actions."
        }
        progress={"64%"}
      />
    ),
    placement: "right",
    placementBeacon: "right",
  },
  {
    target: ".orders-tour-step",
    content: (
      <TourCard
        name={"Orders"}
        step={"3"}
        innerText={
          "It provides access to all the modules that you will need to manage your Orders. You can verify, place & track your Orders here."
        }
        progress={"68%"}
      />
    ),
    placement: "right",
    placementBeacon: "right",
  },
  {
    target: ".shipment-tour-step",
    content: (
      <TourCard
        name={"Shipment"}
        step={"4"}
        innerText={
          "You can manage & track all the Orders that have been packed, shipped or returned and stay updated on their Delivery status."
        }
        progress={"72%"}
      />
    ),
    placement: "right",
    placementBeacon: "right",
  },
  {
    target: ".ndr-tour-step",
    content: (
      <TourCard
        name={"NDR"}
        step={"5"}
        innerText={
          "All the Shipments that have failed an attempt of delivery are shown here. From here you can take necessary actions like initiating a Re-attempt, RTO or Escalating an NDR."
        }
        progress={"76%"}
      />
    ),
    placement: "right",
    placementBeacon: "right",
  },
  {
    target: ".inventory-tour-step",
    content: (
      <TourCard
        name={"Inventory"}
        step={"6"}
        innerText={
          "Here you can stay updated about your Inventory and its regular log that is with vFulfill and plan accordingly."
        }
        progress={"80%"}
      />
    ),
    placement: "right",
    placementBeacon: "right",
  },
  {
    target: ".request-tour-step",
    content: (
      <TourCard
        name={"Request"}
        step={"7"}
        innerText={
          "All your Sourcing Requests for Bulk Orders and Pricing Update Requests are shown here with the most recent status of your Requests."
        }
        progress={"84%"}
      />
    ),
    placement: "right",
    placementBeacon: "right",
  },
  {
    target: ".escalation-tour-step",
    content: (
      <TourCard
        name={"Escalations"}
        step={"8"}
        innerText={
          "Urgent Escalations for Delivered Orders or NDR are listed here for you to keep a track on their status."
        }
        progress={"88%"}
      />
    ),
    placement: "right",
    placementBeacon: "right",
  },
  {
    target: ".reporting-tour-step",
    content: (
      <TourCard
        name={"Reporting"}
        step={"9"}
        innerText={
          "You can now browse through reports of how your business is doing in terms of Orders, Transactions & NDR. Keeping a track of these will help you understand the overall status of your business."
        }
        progress={"92%"}
      />
    ),
    placement: "right",
    placementBeacon: "right",
  },
  {
    target: ".contact-center-tour-step",
    content: (
      <TourCard
        name={"Contact Center"}
        step={"10"}
        innerText={
          "Manage Contact Center Services for verification of details for the Orders & NDR Management."
        }
        progress={"96%"}
      />
    ),
    placement: "right",
    placementBeacon: "right",
  },
  {
    target: ".settings-tour-step",
    content: (
      <TourCard
        name={"Settings"}
        step={"11"}
        innerText={
          "Manage your Brand, Support and Store Settings here. These settings are important to be configured to ensure that your brand is visible properly on Shipping Labels and Invoices."
        }
        progress={"100%"}
      />
    ),
    placement: "right",
    placementBeacon: "right",
  },
];
