import React, { Component } from "react";
import "./index.scss";
import { Icon } from "antd";

class Media extends Component {
  render() {
    return (
      <div className="media">
        <div className="product-images">
          <h4>Images:</h4>
          {this.props.productData.images.length > 0 ? (
            <div
              style={{
                display: "flex",
                overflow: "auto",
              }}
            >
              {this.props.productData.images.map((image, i) => (
                <div style={{ position: "relative" }} key={i}>
                  <img src={image.url} alt="No found" />

                  <Icon
                    type="eye"
                    style={{
                      bottom: "50%",
                      right: "50%",
                      background: "white",
                      color: "#0366fb",
                      cursor: "pointer",
                    }}
                    theme="filled"
                    onClick={() =>
                      this.props.toggleShowImageModal(
                        i,
                        this.props.productData.images
                      )
                    }
                  />

                  <a
                    href={`/products/download_product_media?url=${image.url}`}
                    download="file"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon type="download" />
                  </a>
                </div>
              ))}
            </div>
          ) : (
            <div className="no-media">
              <p>Media Not Available</p>
            </div>
          )}
        </div>

        <br />
        <br />

        <div className="product-video">
          <h4>Videos:</h4>
          {this.props.productData.videos.length > 0 ? (
            <div
              style={{
                display: "flex",
                overflow: "auto",
              }}
            >
              {this.props.productData.videos.map((img, i) => (
                <div style={{ position: "relative" }} key={i}>
                  <video src={img} controls>
                    <source src={img} type="video/mp4" />
                  </video>

                  <a
                    href={img}
                    download="file"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon type="download" />
                  </a>
                </div>
              ))}
            </div>
          ) : (
            <div className="no-media">
              <p>Media Not Available</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Media;
