import React from "react";
import { Button, Input, Modal, Tooltip, message } from "antd";
import { hasPermission } from "../../../../../../../Helpers/Permissions";
import axios from "axios";
import "../../index.scss";

class CustomerInfo extends React.Component {
  state = {
    order: this.props.order,
    customerTab: {
      editing: false,
      saving: false,
    },
    errors: {},
  };

  componentDidMount() {
    this.validateCustomerDetails();
  }

  validateCustomerDetails() {
    const order = this.state.order;
    let errors = {};
    let phone = order.shipping_address.phone;
    let name = order.shipping_address.name;

    if (phone === null) {
      errors.shipping_address_phone = "This is a required field";
    }

    if (phone !== null && phone.toString().length < 10) {
      errors.shipping_address_phone = "Enter atleast 10 digit number!";
    }
    if (phone !== null && phone.toString().length > 13) {
      errors.shipping_address_phone = "Number cannot exceed 13 digits!";
    }

    if (!/^[0-9+\s]+$/.test(phone)) {
      errors.shipping_address_phone = "Invalid Mobile Number";
    }

    if (!order.shipping_address.name || order.shipping_address.name === "") {
      errors.customer_details_name = "This is a required field!";
    }

    if (!/^[A-Za-z.\s]*$/.test(name)) {
      errors.customer_details_name = "Invalid Name";
    }

    if (
      !!order.shipping_address &&
      !!order.shipping_address.address1 &&
      order.shipping_address.address1.length > 200
    ) {
      errors.shipping_address_address1 = "Max limit upto 200 characters!";
    }
    if (
      !!order.shipping_address &&
      !!order.shipping_address.address2 &&
      order.shipping_address.address2.length > 200
    ) {
      errors.shipping_address_address2 = "Max limit upto 200 characters!";
    }

    if (!order.shipping_address.zip || order.shipping_address.zip === "") {
      errors.shipping_address_zip = "This is a required field!";
    }
    if (
      !order.shipping_address.country ||
      order.shipping_address.country === ""
    ) {
      errors.shipping_address_country = "This is a required field!";
    }
    if (!order.shipping_address.city || order.shipping_address.city === "") {
      errors.shipping_address_city = "This is a required field!";
    }
    if (
      !order.shipping_address.province ||
      order.shipping_address.province === ""
    ) {
      errors.shipping_address_province = "This is a required field!";
    }
    // if (!order.shipping_address.phone || order.shipping_address.phone === "") {
    //   errors.shipping_address_phone = "This is a required field!";
    // }

    if (!/^[0-9+\s]+$/.test(phone)) {
      errors.shipping_address_phone = "Please enter a valid phone number!";
    }

    if (!/[a-zA-Z0-9\s.'"-]+$/.test(name)) {
      errors.customer_details_name = "Invalid Name";
    }

    if (!!/^[A-Za-z.\s]*$/.test(name)) {
      if (!/^[~`!@#$% ^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/.test(name)) {
        errors.customer_details_name = "Invalid Name";
      }
    }

    if (
      order.customer_details &&
      order.customer_details.email &&
      order.customer_details.email.length
    ) {
      if (
        !/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
          order.customer_details.email
        )
      ) {
        errors.customer_details_email = "Invalid Email";
      }
    }

    if (
      !order.shipping_address.address1 ||
      order.shipping_address.address1 === ""
    ) {
      errors.shipping_address_address1 = "This is a required field!";
    }

    if (
      !/^[~`!@#$% ^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/.test(
        order.shipping_address.address1
      )
    ) {
      errors.shipping_address_address1 = "Incorrect Address language";
    }

    if (
      !!order.shipping_address.address2 &&
      !!order.shipping_address.address2.length
    ) {
      if (
        !/^[~`!@#$% ^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/.test(
          order.shipping_address.address2
        )
      ) {
        errors.shipping_address_address2 = "Incorrect Address language";
      }
    }

    this.setState({ errors: errors });
    return errors;
  }
  toggleCustomerEdit(validateOnly = false) {
    if (!this.state.customerTab.editing) {
      this.setState({
        customerTab: {
          ...this.state.customerTab,
          editing: !this.state.customerTab.editing,
        },
      });
    } else {
      const errors = this.validateCustomerDetails();
      if (!validateOnly && Object.keys(errors).length === 0) {
        this.setState(
          {
            customerTab: {
              ...this.state.customerTab,
              saving: true,
            },
          },
          () => {
            const order = this.state.order;

            axios({
              url:
                process.env.REACT_APP_API_URL + "/orders/update_customer_info",
              method: "post",
              data: {
                storeid: this.props.match.params.storeid,
                orderid: this.state.order._id["$oid"],
                customer_details: order.customer_details,
                shipping_address: order.shipping_address,
              },
              withCredentials: true,
            })
              .then((res) => {
                let editing = true;
                if (res.data.success === 1) {
                  this.props.clear();
                  if (
                    res.data.serviceable === 0 &&
                    res.data.serviceable !== this.state.order.is_servicable
                  ) {
                    if (this.props.pageType === "openorders") {
                      Modal.info({
                        maskClosable: false,
                        title: "Customer Information Updated Successfully!",
                        content: `The updated address is not serviceable and hence the order has been removed from open orders. You can find the order on All Orders Page`,
                        onOk: () => {
                          this.props.reloadData();
                        },
                      });
                    } else {
                      message.success(
                        "Customer Information Updated Successfully!"
                      );
                    }
                  } else {
                    message.success(
                      "Customer Information Updated Successfully!"
                    );
                  }
                  editing = false;
                  this.props.updateCustomerShippingAddress(
                    this.state.order._id["$oid"],
                    order.shipping_address,
                    order.customer_details
                  );
                } else {
                  message.error(res.data.msg);

                  editing = false;
                }
                this.setState({
                  customerTab: {
                    ...this.state.customerTab,
                    saving: false,
                    editing,
                  },
                });
              })
              .catch((e) => {
                message.error(e.message);
                this.setState({
                  customerTab: {
                    ...this.state.customerTab,
                    saving: false,
                    editing: true,
                  },
                });
              });
          }
        );
      }
    }
  }

  updateCustomer(type, key, val) {
    this.setState({
      order: {
        ...this.state.order,
        [type]: {
          ...this.state.order[type],
          [key]: val,
        },
      },
    });
  }

  render() {
    const order = this.state.order;

    return (
      <div
        className={`tab-container ${
          this.props.activeTab !== "customerInfo" ? "hidden" : ""
        }`}
      >
        <div className="customer-info-tab">
          <div
            className={
              this.state.customerTab.editing
                ? "customerinfo_header"
                : "customerinfo-header-editable-state"
            }
          >
            <div
              className={`ant-form-item-control ${
                this.state.errors.customer_details_name ? "has-error" : ""
              }`}
            >
              <label>Full Name:</label>
              <Input
                className="capitalize-text"
                type="text"
                value={
                  order.shipping_address && order.shipping_address.name
                    ? order.shipping_address.name.toLowerCase()
                    : null
                }
                onChange={(e) =>
                  this.updateCustomer(
                    "shipping_address",
                    "name",
                    e.target.value
                  )
                }
                readOnly={!this.state.customerTab.editing}
              />
              {this.state.errors.customer_details_name && (
                <div className="ant-form-explain">
                  {this.state.errors.customer_details_name}
                </div>
              )}
            </div>

            <Tooltip placement="right" title={order.shipping_address.address1}>
              <div
                className={`ant-form-item-control ${
                  this.state.errors.shipping_address_address1 ? "has-error" : ""
                }`}
              >
                <label>Address Line #1:</label>
                <Input
                  type="text"
                  value={order.shipping_address.address1}
                  onChange={(e) =>
                    this.updateCustomer(
                      "shipping_address",
                      "address1",
                      e.target.value
                    )
                  }
                  readOnly={!this.state.customerTab.editing}
                />
                {this.state.errors.shipping_address_address1 && (
                  <div className="ant-form-explain">
                    {this.state.errors.shipping_address_address1}
                  </div>
                )}
              </div>
            </Tooltip>

            <div
              className={`ant-form-item-control ${
                this.state.errors.shipping_address_zip ? "has-error" : ""
              }`}
            >
              <label>Pin Code:</label>
              <Input
                type={`${
                  this.props.store.store_geo === "global" ? `text` : "number"
                }`}
                value={order.shipping_address.zip}
                onChange={(e) =>
                  this.updateCustomer("shipping_address", "zip", e.target.value)
                }
                readOnly={!this.state.customerTab.editing}
              />

              {/* <Input
                type="number"
                value={order.shipping_address.phone}
                onChange={(e) =>
                  this.updateCustomer(
                    "shipping_address",
                    "phone",
                    e.target.value
                  )
                }
                readOnly={!this.state.customerTab.editing}
              /> */}

              {this.state.errors.shipping_address_zip && (
                <div className="ant-form-explain">
                  {this.state.errors.shipping_address_zip}
                </div>
              )}
            </div>
          </div>

          <div
            className={
              this.state.customerTab.editing
                ? "customerinfo_middledata"
                : "customerinfo-middledata-editable-state"
            }
          >
            <div
              className={`ant-form-item-control ${
                this.state.errors.customer_details_email ? "has-error" : ""
              }`}
            >
              <label>Email Address:</label>
              <Input
                type="text"
                value={order.customer_details.email}
                onChange={(e) =>
                  this.updateCustomer(
                    "customer_details",
                    "email",
                    e.target.value
                  )
                }
                readOnly={!this.state.customerTab.editing}
              />
              {this.state.errors.customer_details_email && (
                <div className="ant-form-explain">
                  {this.state.errors.customer_details_email}
                </div>
              )}
            </div>
            <Tooltip placement="right" title={order.shipping_address.address2}>
              <div
                className={`ant-form-item-control ${
                  this.state.errors.shipping_address_address2 ? "has-error" : ""
                }`}
              >
                <label>Address Line #2:</label>
                <Input
                  type="text"
                  value={order.shipping_address.address2}
                  onChange={(e) =>
                    this.updateCustomer(
                      "shipping_address",
                      "address2",
                      e.target.value
                    )
                  }
                  readOnly={!this.state.customerTab.editing}
                />

                {this.state.errors.shipping_address_address2 && (
                  <div className="ant-form-explain">
                    {this.state.errors.shipping_address_address2}
                  </div>
                )}
              </div>
            </Tooltip>

            <div
              className={`ant-form-item-control ${
                this.state.errors.shipping_address_country ? "has-error" : ""
              }`}
            >
              <label>Country:</label>
              <Input
                type="text"
                value={order.shipping_address.country}
                onChange={(e) =>
                  this.updateCustomer(
                    "shipping_address",
                    "country",
                    e.target.value
                  )
                }
                readOnly={!this.state.customerTab.editing}
              />
              {this.state.errors.shipping_address_country && (
                <div className="ant-form-explain">
                  {this.state.errors.shipping_address_country}
                </div>
              )}
            </div>
          </div>

          <div
            className={
              this.state.customerTab.editing
                ? "customerinfo_footerdata"
                : "customerinfo-footerdata-editable-state"
            }
          >
            <div
              className={`ant-form-item-control ${
                this.state.errors.shipping_address_phone ? "has-error" : ""
              }`}
            >
              <label>Mobile Number:</label>
              <Input
                type="text"
                value={order.shipping_address.phone}
                onChange={(e) =>
                  this.updateCustomer(
                    "shipping_address",
                    "phone",
                    e.target.value
                  )
                }
                readOnly={!this.state.customerTab.editing}
              />
              {this.state.errors.shipping_address_phone && (
                <div className="ant-form-explain">
                  {this.state.errors.shipping_address_phone}
                </div>
              )}
            </div>

            <div
              className={`ant-form-item-control ${
                this.state.errors.shipping_address_city ? "has-error" : ""
              }`}
            >
              <label>City/Town:</label>
              <Input
                type="text"
                value={order.shipping_address.city}
                onChange={(e) =>
                  this.updateCustomer(
                    "shipping_address",
                    "city",
                    e.target.value
                  )
                }
                readOnly={!this.state.customerTab.editing}
              />
              {this.state.errors.shipping_address_city && (
                <div className="ant-form-explain">
                  {this.state.errors.shipping_address_city}
                </div>
              )}
            </div>

            <div
              className={`ant-form-item-control ${
                this.state.errors.shipping_address_province ? "has-error" : ""
              }`}
            >
              <label>State:</label>
              <Input
                type="text"
                value={order.shipping_address.province}
                onChange={(e) =>
                  this.updateCustomer(
                    "shipping_address",
                    "province",
                    e.target.value
                  )
                }
                readOnly={!this.state.customerTab.editing}
              />
              {this.state.errors.shipping_address_province && (
                <div className="ant-form-explain">
                  {this.state.errors.shipping_address_province}
                </div>
              )}
            </div>
          </div>
          {hasPermission(this.props.store, "update_customer_details") &&
            (this.props.pageType === "closedOrders" ? null : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "16px",
                }}
              >
                {this.state.customerTab.editing ? (
                  <Button
                    className="btn-save-customer"
                    onClick={() => this.toggleCustomerEdit()}
                    loading={this.state.customerTab.saving}
                  >
                    Save Changes
                  </Button>
                ) : (
                  <Button
                    className="btn-edit-customer"
                    onClick={() => this.toggleCustomerEdit()}
                  >
                    Edit Info
                  </Button>
                )}
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default CustomerInfo;
