import React from "react";
import { Form, Button, Input, Alert } from "antd";
import { SHOPIFY_NAME } from "../../../../constants";
import "./index.scss";

const handleSubmit = (e, props) => {
  e.preventDefault();
  props.form.validateFields((err, values) => {
    if (!err) {
      props.handleOk(values.shop);
    }
  });
};

const ConnectToShopify = (props) => {
  const { getFieldDecorator, setFieldsValue } = props.form;

  return (
    <div className="shoipify_store_data">
      <Alert
        message={`You will be redirected to Shopify to authorize vFulfill.`}
        type="info"
        showIcon
      />
      <Form
        onSubmit={(e) => handleSubmit(e, props)}
        className="connect-shopify-form"
        noValidate
      >
        <p className="shopify_label">{`Enter the URL of your ${SHOPIFY_NAME}`}</p>
        <Form.Item>
          {getFieldDecorator("shop", {
            rules: [
              {
                required: true,
                type: "string",
                pattern: "^[a-z0-9-]+$",
                message: "Please enter a valid Store Shop URL",
              },
            ],
          })(
            <Input
              size="large"
              type="text"
              placeholder="store-name"
              suffix=".myshopify.com"
            />
          )}
        </Form.Item>
        <p className="shopify-store_link">
          <div>
            <p>{`Don’t have a Shopify ${SHOPIFY_NAME} ?`}</p>
          </div>
          <div>
            <a
              href="https://shopify.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Create New Store
            </a>
          </div>
        </p>

        <div className="connect_shopify_store_btn">
          <Button
            className="go_back_btn"
            onClick={()=>props.goBack()}
          >
            <span className="svg_go_back">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="16"
                viewBox="0 0 8 16"
              >
                <g
                  id="Polygon_63"
                  data-name="Polygon 63"
                  transform="translate(0 16) rotate(-90)"
                  fill="none"
                >
                  <path
                    d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                    stroke="none"
                    fill="#898989"
                  />
                </g>
              </svg>
            </span>
            <span className="go_back_txt">Go Back</span>
          </Button>

          <Button
            size="large"
            type="default"
            htmlType="button"
            className="cancel_btn"
            onClick={() => {
              setFieldsValue({ shop: "" });
              props.onCancel();
            }}
          >
            Cancel
          </Button>

          <Button size="large" className="connect_btn" htmlType="submit">
            Connect Store
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Form.create()(ConnectToShopify);
