import React, { Component } from "react";
import "./index.scss";
import { Select, Button, message } from "antd";
import Globalsearch from "../../../Common/Globalsearch";
import axios from "axios";
import qs from "qs";

const { Option } = Select;
class SourcingRequestNav extends Component {
  state = {
    intransitbtn: true,
    deliveredbtn: false,
    returnedbtn: false,
  };

  downloadCSV() {
    axios({
      url:
        process.env.REACT_APP_API_URL + "/sourcing/download_sourcing_requests",
      method: "POST",
      data: {
        storeid: this.props.storeid,
        filters: this.props.filters,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  }

  render() {
    return (
      <div className="Sourcing_nav_data fullorder_data sourceingfilter">
        <div className=" Sourcing_nav ">
          <div className="ordfill">
            <div className="ordfillwrap">
              <div className="left" style={{ marginLeft: "7px" }}>
                <div style={{ fontSize: 12, marginBottom: 12, color: "#000" }}>
                  <span>Requests</span>
                  <span style={{ margin: "0 8px" }}>&gt;</span>
                  <span style={{ textDecoration: "underline" }}>Sourcing</span>
                </div>
              </div>
              <div className="right">
                {" "}
                <a
                  className="clickable ant-btn"
                  target="_blank"
                  href={
                    process.env.REACT_APP_API_URL +
                    `/sourcing/download_sourcing_requests?${qs.stringify(
                      {
                        filters: this.props.filters,
                      },
                      { encodeValuesOnly: true }
                    )}`
                  }
                >
                  {" "}
                  Download CSV
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_data">
          <div className="Globalsearch">
            <Globalsearch
              class={"nextwidth"}
              sourcingplaceholderkey={true}
              data={this.props.filters}
              placeholder={"Search by Product ID or Request ID"}
              change={(value) =>
                this.props.changeHandler({ search: value.target.value })
              }
              searchFunc={(value) => this.props.changeFilter({ search: value })}
            />
          </div>

          <div
            className="drop"
            style={{
              display: "flex",
              float: "left",
              alignItems: "center",
              marginLeft: "-32px",
            }}
          >
            <Select
              value={this.props.filters.status}
              className="filter"
              placeholder="Sourcing Request Status: All"
              onDropdownVisibleChange={(val) => this.setState({ arrow: val })}
              onChange={(val) => this.props.changeFilter2({ status: val })}
              dropdownMatchSelectWidth={false}
              style={{ fontWeight: 500, fontSize: 12, width: "288px" }}
              suffixIcon={
                <svg
                  className="antsvg"
                  style={{
                    transition: "all 0.5s",
                    transform: `${
                      this.state.arrow ? "rotateX(180deg)" : "rotateX(0deg)"
                    }`,
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="8"
                  viewBox="0 0 16 8"
                >
                  <g
                    id="Polygon_49"
                    data-name="Polygon 49"
                    transform="translate(16 8) rotate(180)"
                    fill={`${this.state.arrow ? "#898989" : "none"}`}
                  >
                    <path d="M8,0l8,8H0Z" stroke="none" />
                    <path
                      d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                      stroke="none"
                      fill="#898989"
                    />
                  </g>
                </svg>
              }
            >
              <Option value="" className="commondropdown">
                All
              </Option>
              <Option value="pending" className="commondropdown">
                Pending
              </Option>
              <Option value="approved" className="commondropdown">
                Approved
              </Option>
              <Option value="sourced" className="commondropdown">
                Sourcing Initiated
              </Option>
              <Option
                value="in_production"
                className="commondropdown"
              >
                In Production
              </Option>
              <Option value="china_qc_checked" className="commondropdown">
                QC & Labelling
              </Option>

              <Option value="transit" className="commondropdown">
                In-Transit
              </Option>
              <Option value="india_customs_cleared" className="commondropdown">
                In Customs
              </Option>
              <Option value="enroute_india_fc" className="commondropdown">
                Enroute India FC
              </Option>
              <Option value="reached_india_fc" className="commondropdown">
                Reached India FC
              </Option>
              <Option value="custom_packaging" className="commondropdown">
                Custom Packaging
              </Option>
              <Option value="quality_check" className="commondropdown">
                Quality Check
              </Option>

              <Option value="partial_inventory_live" className="commondropdown">
                Partial Inventory Live
              </Option>

              <Option value="inventory_live" className="commondropdown">
                Inventory Live
              </Option>
              <Option
                value="partially_handover_to_partner"
                className="commondropdown"
              >
                Partially Delivered To Partner
              </Option>
              <Option value="handover_to_partner" className="commondropdown">
                Delivered To Partner
              </Option>

              {/* <Option
                value="cancelled"
                className="sourcing-request-status-cancel"
              >
                Cancelled
              </Option> */}
            </Select>
          </div>
        </div>
      </div>
    );
  }
}

export default SourcingRequestNav;
