import React from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Icon,
  Tooltip,
  Menu,
  Dropdown,
  Checkbox,
  Modal,
  message,
  Avatar,
} from "antd";
import "./index.scss";
import ShipmentInfo from "./ShipmentInfo";
import CustomerInfo from "./CustomerInfo";
import ViewRemark from "./ViewRemark";
import Notes from "./Logs";
import VfIdCopyBadge from "../../Common/VfIdCopyBadge";
import { ReactComponent as PaymentStatusIcon } from "../../../../assets/images/icons/payment_status_icon.svg";
import { ReactComponent as Right } from "../../../../assets/images/icons/right.svg";
import { ReactComponent as Newtab } from "../../../../assets/images/icons/newtab.svg";
import axios from "axios";
import moment from "moment";
import { hasPermission } from "../../../../Helpers/Permissions";
import ActionNotification from "../../Common/ActionNotification";
import { SHOPIFY_NAME } from "../../../../constants";

const { confirm } = Modal;

class NdrCard extends React.Component {
  state = {
    ndr: this.props.ndr,
    activeTab: "viewRemark",
    errors: {},
  };

  setActiveTab = (activeTab) => {
    this.setState({
      activeTab,
    });
  };

  toggleNdrEscalationModal = () => {
    this.setState({ showHideheaderdata: false });
    this.props.toggleNdrEscalationModal();
  };

  toggleNdrReAttemptModal = () => {
    this.setState({ showHideheaderdata: false });
    this.props.toggleNdrReAttemptModal();
  };

  toggleNdrEscalationModal = () => {
    this.setState({ showHideheaderdata: false });
    this.props.toggleNdrEscalationModal();
  };

  initiateRto = () => {
    this.setState({ showHideheaderdata: false });
    confirm({
      title: "Confirm RTO Initiate",
      content: "Are you sure you want to initiate RTO for this Shipment?",
      onOk: () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/shipments/initiate_rto",
          method: "POST",
          data: {
            awb: this.state.ndr.awb,
            storeid: this.props.match.params.storeid,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              // message.success("RTO intiated successfully!");

              ActionNotification({
                notificationType: "rto-initiated",
                awb: this.state.ndr.awb,
              });

              this.props.reloadList();
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((err) => {
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  ndrStatusHandler(status, awb) {
    if (status === "Escalate & Reattempt Delivery") {
      return (
        <Menu>
          <Menu.Item className="li-item">
            <a
              href={`./escalation/ndr-escalations?search=${awb}`}
              target="_blank"
              onClick={() =>
                this.setState({
                  showHideheaderdata: !this.state.showHideheaderdata,
                })
              }
            >
              {" "}
              View in Escalations <Icon className="right" component={Newtab} />
            </a>
          </Menu.Item>
          <Menu.Item>
            <a
              href={`./tracking?search=${awb}`}
              target="_blank"
              onClick={() =>
                this.setState({
                  showHideheaderdata: !this.state.showHideheaderdata,
                })
              }
            >
              {" "}
              View in Shipments <Icon className="right" component={Newtab} />
            </a>
          </Menu.Item>
        </Menu>
      );
    }

    if (status === "Reattempt Delivery" || status === "RTO Initiated") {
      return (
        <Menu>
          <Menu.Item>
            <a
              href={`./tracking?search=${awb}`}
              target="_blank"
              onClick={() =>
                this.setState({
                  showHideheaderdata: !this.state.showHideheaderdata,
                })
              }
            >
              {" "}
              View in Shipments <Icon className="right" component={Newtab} />
            </a>
          </Menu.Item>
        </Menu>
      );
    }

    if (status === "NDR Escalated") {
      return (
        <Menu>
          <Menu.Item>
            <a
              href={`./escalation/ndr-escalations?search=${awb}`}
              target="_blank"
              onClick={() =>
                this.setState({
                  showHideheaderdata: !this.state.showHideheaderdata,
                })
              }
            >
              View in Escalations <Icon className="right" component={Newtab} />
            </a>
          </Menu.Item>
        </Menu>
      );
    }
  }

  disableChecker() {
    if (
      this.props.ndr.hasOwnProperty("ndr_bucket_code") &&
      this.props.ndr.ndr_bucket_code == 14
    ) {
      return true;
    } else {
      return false;
    }
  }

  movedToCalling() {
    axios({
      url: process.env.REACT_APP_API_URL + "/shipments/set_ndr_calling",
      method: "post",
      data: {
        awb: this.state.ndr.awb,
        storeid: this.props.store.id,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          message.success(res.data.msg);
          // message.success("Updated Successfully!");
          this.props.loadData();
        } else {
          message.info(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  }

  render() {
    const ndr = this.state.ndr;
    let ndr_remarks = ndr.ndr_remarks;
    let lineItems = this.state.ndr.items;
    const count = ndr_remarks.filter((remark) => remark.addedby === "system");

    const shipmentStatusColors = {
      processing: "#FF7A45",
      manifested: "#FF7A45",
      transit: "#FFC53D",
      delivered: "#73D13D",
      rto: "#E61047",
      ndr_actionable: "#FF4D4F",
    };
    const shipmentStatusText = {
      processing: "In Processing",
      manifested: "In Processing",
      transit: "In Transit",
      delivered: "Delivered",
      rto: "Returned",
      ndr_actionable: "NDR",
    };

    const menu = (
      <div className="dropdowndropmenustyle">
        <Menu style={{ textAlign: "center" }}>
          {ndr.ndr_status !== "ndr_calling" && (
            <Menu.Item key="0" onClick={() => this.movedToCalling()}>
              Move To Calling
            </Menu.Item>
          )}
          <Menu.Item key="1" onClick={() => this.initiateRto()}>
            Initiate RTO
          </Menu.Item>

          <Menu.Item
            disabled={this.disableChecker()}
            key="2"
            onClick={() => this.toggleNdrReAttemptModal()}
          >
            Re-Attempt
          </Menu.Item>
          {/* <Menu.Item key="3" onClick={() => this.toggleNdrEscalationModal()}>
            Escalate
          </Menu.Item> */}
          <Menu.Item
            key="4"
            onClick={() => this.toggleNdrEscalationModal(true)}
          >
            Escalate Or Re-Attempt
          </Menu.Item>
        </Menu>
      </div>
    );

    let mostRecentDate = moment(
      Math.max.apply(
        null,
        ndr_remarks
          .filter((e) => e.addedby.toLowerCase() === "system")
          .map((e) => {
            return moment(e.date).valueOf();
          })
      )
    );
    let finalDate;

    let new_time = moment(mostRecentDate).add(24, "hours");
    const d = moment.duration(new_time.diff(moment.utc()));

    if (d <= 0) {
      finalDate = 0 + " Hours " + 0 + " Min";
    } else {
      finalDate = d.hours() + " Hours " + d.minutes() + " Min";
    }

    const ndr_actions = {
      rto: "RTO Initiated",
      escalated: "NDR Escalated",
      reattempt: "Reattempt Delivery",
      escalated_reattempt: "Escalate & Reattempt Delivery",
    };

    const paymentStatusColors = {
      paid: "#73D13D",
      partially_paid: "#4569FF",
      pending: "#fec62d",
      refunded: "#FF4D4F",
      partially_refunded: "#FFC53D",
    };
    const paymentStatusText = {
      paid: "Fully Paid",
      partially_paid: "Partially Paid",
      pending: "Cash On Delivery",
      refunded: "Refunded",
      partially_refunded: "Partially Refunded",
    };

    return (
      <Card id="test" className="ndr-card">
        {ndr.hasOwnProperty("ndr_bucket_code") ? (
          <p className="bucket-badge">
            {this.props.bucket[ndr.ndr_bucket_code]}
          </p>
        ) : null}

        <div className="header-row" style={{ justifyContent: "flex-end" }}>
          <div className="vforderid_tag">
            <VfIdCopyBadge
              text={"VF Order"}
              hashSign={true}
              textToCopy={ndr.order_vfid}
            />
          </div>

          <div className="header-top-row">
            <p
              style={{
                background: "#F5FAFF",
                color: "#212121",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Tooltip title="Re-Attempt Count">
                <span
                  style={{
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="tooltip_icon_svg"
                >
                  NDR Count: {ndr.ndr_count}{" "}
                  <span
                    style={{
                      margin: "auto",
                      marginLeft: "5px",
                      marginTop: "2px",
                    }}
                  >
                    {" "}
                    <svg
                      className="product-tooltip-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                    >
                      <g data-name="Group 2529">
                        <g
                          transform="translate(0 1)"
                          fill="none"
                          stroke="#898989"
                        >
                          <circle cx="8" cy="8" r="8" stroke="none" />
                          <circle cx="8" cy="8" r="7.5" />
                        </g>
                        <text
                          transform="translate(6.5)"
                          fill="#898989"
                          fontSize="12"
                          fontFamily="Poppins-Medium, Poppins"
                          fontWeight="500"
                          letterSpacing=".032em"
                        >
                          <tspan x="0" y="13">
                            i
                          </tspan>
                        </text>
                      </g>
                    </svg>
                  </span>
                </span>
              </Tooltip>
            </p>
            {ndr.ndr_status === "expired" && (
              <p
                className="action_taken"
                style={{
                  background: "#fff9f5",
                  color: "#212121",
                  alignItems: "center",
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <span style={{ margin: "auto" }}>
                  <span style={{ color: "#DD8222" }}>{finalDate}</span> left to
                  take any action.
                </span>{" "}
              </p>
            )}
            {ndr.ndr_status === "responded" && (
              <p
                className="action_taken"
                style={{
                  background: "#fff9f5",
                  color: "#212121",
                  alignItems: "center",
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <span style={{ margin: "auto" }}>
                  <span style={{ color: "#DD8222", marginRight: "8px" }}>
                    Action Taken:
                  </span>
                  {""}
                  {ndr_actions[ndr.ndr_action]}{" "}
                </span>{" "}
              </p>
            )}
          </div>
        </div>

        <div
          className="upper_middle_data"
          style={{ paddingTop: "5px", paddingBottom: "10px" }}
        >
          <div className="middle_row">
            <div className="waybill_number">
              {this.props.pendingTab ? (
                <Checkbox
                  onClick={(e) =>
                    this.props.selectHandler({
                      checked: e.target.checked,
                      awb: ndr.awb,
                    })
                  }
                  checked={ndr.checked}
                />
              ) : null}

              <p>
                WayBill Number(AWB){": "}
                <span className="blue-text">
                  <a
                    href={ndr.tracking_url}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {ndr.awb}
                  </a>
                </span>
              </p>
            </div>

            <div className="order_no">
              <h3 className="middledata">
                <span className="Order_text">
                  Store Order:{" "}
                  <span className="blue-text">
                    <a
                      // href={`/${ndr.storename}/admin/orders/${
                      //   this.props.shopifyfulfillment.order_id
                      // }`}
                      // href="#"

                      href={ndr.order_shopifylink}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <span className="blue-text">
                        {ndr.order_shopify_name}
                      </span>{" "}
                    </a>
                  </span>
                </span>
                <span style={{ margin: "0 8px", color: "#898989" }}>|</span>
              </h3>

              <span className="shipment-date">
                <Tooltip title={paymentStatusText[ndr.order_payment_status]}>
                  <Icon
                    component={PaymentStatusIcon}
                    style={{
                      color: paymentStatusColors[ndr.order_payment_status],
                      marginTop: "-1px",
                      marginRight: "8px",
                    }}
                  />
                </Tooltip>

                <p>
                  <Tooltip
                    title={`Total Order Cost = Total ${SHOPIFY_NAME} Item Selling Price + Shipping Price`}
                  >
                    ₹ {ndr && ndr.total_order_cost ? ndr.total_order_cost : "0"}
                  </Tooltip>
                </p>
                <span style={{ margin: "0 8px", color: "#898989" }}>|</span>
                <span style={{ color: "#949494" }}>
                  <Tooltip title="Shipment Packed Date">
                    {moment(ndr.shipment_date).format("DD MMM, YYYY")}
                  </Tooltip>
                </span>
              </span>
            </div>

            <div className="Take_action">
              {hasPermission(this.props.store, "allow_ndr_actions") && (
                <div className="ndr-action">
                  {ndr.ndr_status === "pending" ||
                  ndr.ndr_status === "ndr_calling" ? (
                    <Dropdown
                      overlay={menu}
                      destroyPopupOnHide={true}
                      className="dropdownmenu4"
                      onVisibleChange={(e) => {
                        this.setState({ showHideheaderdata: e });
                      }}
                      // visible={true}
                    >
                      <Button style={{ fontSize: "12px" }}>
                        Take Action{" "}
                        <svg
                          style={{
                            marginLeft: "5px",
                            transition: "all 0.5s",
                            transform: `${
                              this.state.showHideheaderdata
                                ? "rotateX(180deg)"
                                : "rotateX(0deg)"
                            }`,
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="8"
                          viewBox="0 0 16 8"
                        >
                          <g
                            id="Polygon_49"
                            data-name="Polygon 49"
                            transform="translate(16 8) rotate(180)"
                            fill={`${
                              this.state.showHideheaderdata ? "#fff" : "none"
                            }`}
                          >
                            <path d="M8,0l8,8H0Z" stroke="none" />
                            <path
                              d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                              stroke="none"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </Button>
                    </Dropdown>
                  ) : null}
                  {ndr.ndr_status === "responded" ? (
                    // (ndr.closed_on && !ndr.closed_on.length)

                    ndr.status === "rto" || ndr.status === "delivered" ? (
                      <span
                        style={{
                          color: shipmentStatusColors[ndr.status],
                          display: "flex",
                        }}
                      >
                        {shipmentStatusText[ndr.status]}{" "}
                        <div className="status-ndr">
                          <p style={{ marginLeft: "4px" }}>
                            on {moment(ndr.closed_on).format("DD MMM, YYYY")}
                          </p>
                        </div>
                      </span>
                    ) : (
                      <Dropdown
                        overlayClassName="drop-menu"
                        overlay={this.ndrStatusHandler(
                          ndr_actions[ndr.ndr_action],
                          ndr.awb
                        )}
                        destroyPopupOnHide={true}
                        className="action-taken-drop"
                        onVisibleChange={(e) => {
                          this.setState({ showHideheaderdata: e });
                        }}
                        // visible={true}
                      >
                        <Button
                          className="action-taken-drop-btn"
                          style={{ fontSize: "12px" }}
                        >
                          <Icon className="right" component={Right} />
                          Action Taken{" "}
                          <svg
                            style={{
                              marginLeft: "5px",
                              transition: "all 0.5s",
                              transform: `${
                                this.state.showHideheaderdata
                                  ? "rotateX(180deg)"
                                  : "rotateX(0deg)"
                              }`,
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="8"
                            viewBox="0 0 16 8"
                          >
                            <g
                              id="Polygon_49"
                              data-name="Polygon 49"
                              transform="translate(16 8) rotate(180)"
                              fill={`${
                                this.state.showHideheaderdata ? "#fff" : "none"
                              }`}
                            >
                              <path d="M8,0l8,8H0Z" stroke="none" />
                              <path
                                d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                                stroke="none"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </Button>
                      </Dropdown>
                    )
                  ) : null}
                  {ndr.ndr_status === "expired" &&
                    (ndr.status === "delivered" || ndr.status === "rto" ? (
                      <span
                        style={{
                          color: shipmentStatusColors[ndr.status],
                          display: "flex",
                        }}
                      >
                        {shipmentStatusText[ndr.status]}{" "}
                        <div className="status-ndr">
                          <p style={{ marginLeft: "4px" }}>
                            on {moment(ndr.closed_on).format("DD MMM, YYYY")}
                          </p>
                        </div>
                      </span>
                    ) : (
                      <Tooltip
                        overlayClassName="expired-tooltip"
                        placement="topRight"
                        title={
                          <div>
                            <p>Oops! Can’t take any action!</p>
                            <p>
                              You cannot take any action after 24 hours from
                              generation of NDR. <br />
                              Please click{" "}
                              <a
                                href="https://help.vfulfill.io/portal/en/kb/articles/managing-non-delivery-reports-ndrs"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                this link
                              </a>{" "}
                              to know more.
                            </p>
                          </div>
                        }
                      >
                        <span
                          className="span-button2"
                          style={{
                            background: "#E61047",
                            width: "160px",
                          }}
                        >
                          Can’t take action{" "}
                          <span
                            className="order_tooltip"
                            style={{ marginLeft: "8px" }}
                          >
                            <svg
                              className="order-tooltip-icon"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                            >
                              <g data-name="Group 2529">
                                <g
                                  transform="translate(0 1)"
                                  fill="none"
                                  stroke="#fff"
                                >
                                  <circle cx="8" cy="8" r="8" stroke="none" />
                                  <circle cx="8" cy="8" r="7.5" />
                                </g>
                                <text
                                  transform="translate(6.5)"
                                  fill="#fff"
                                  fontSize="12"
                                  fontFamily="Poppins-Medium, Poppins"
                                  fontWeight="500"
                                  letterSpacing=".032em"
                                >
                                  <tspan x="0" y="13">
                                    i
                                  </tspan>
                                </text>
                              </g>
                            </svg>
                          </span>
                        </span>
                      </Tooltip>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <Row className="tabs-row-card">
          <Col xs={20}>
            <ul>
              <li
                className={
                  this.state.activeTab === "shipmentInfo" ? "active" : ""
                }
                onClick={() => this.setActiveTab("shipmentInfo")}
              >
                Shipment Info
              </li>
              <li
                className={
                  this.state.activeTab === "customerInfo" ? "active" : ""
                }
                onClick={() => this.setActiveTab("customerInfo")}
              >
                Customer Info
              </li>
              <li
                className={this.state.activeTab === "Notes" ? "active" : ""}
                onClick={() => this.setActiveTab("Notes")}
              >
                Notes{" "}
                <Avatar
                  style={{
                    backgroundColor: "#FF9400",
                    verticalAlign: "middle",
                    marginLeft: 10,
                  }}
                >
                  {ndr.admin_ndr_remarks && ndr.admin_ndr_remarks.length > 0
                    ? ndr.admin_ndr_remarks.length
                    : 0}
                </Avatar>
              </li>
              <li
                className={
                  this.state.activeTab === "viewRemark" ? "active" : ""
                }
                onClick={() => this.setActiveTab("viewRemark")}
              >
                View Remarks
                <Avatar
                  style={{
                    backgroundColor: "#FF9400",
                    verticalAlign: "middle",
                    marginLeft: 10,
                  }}
                >
                  {ndr.ndr_remarks && ndr.ndr_remarks.length > 0
                    ? ndr.ndr_remarks.length
                    : 0}
                </Avatar>
              </li>
            </ul>
          </Col>
        </Row>

        <div
          className={`tab-container ${
            this.state.activeTab !== "shipmentInfo" ? "hidden" : "nothidden"
          }`}
        >
          <ShipmentInfo {...this.props} shipment={ndr} lineItems={lineItems} />
        </div>

        <div
          className={`tab-container ${
            this.state.activeTab !== "customerInfo" ? "hidden" : ""
          }`}
        >
          <CustomerInfo shipment={ndr} />
        </div>

        <div
          className={`tab-container ${
            this.state.activeTab !== "viewRemark" ? "hidden" : ""
          }`}
        >
          <ViewRemark
            ndr={ndr}
            bucket={this.props.bucket}
            ndr_remarks={ndr.ndr_remarks}
          />
        </div>
        <div
          className={`tab-container ${
            this.state.activeTab !== "Notes" ? "hidden" : ""
          }`}
        >
          <Notes ndr={ndr.admin_ndr_remarks} />
        </div>
      </Card>
    );
  }
}
export default NdrCard;
