import React, { Component } from "react";
import { Drawer, Icon, Menu, Row, Col, Button, Modal, message } from "antd";
import "./index.scss";

import Media from "./Media";
import AdvertisingData from "./AdvertisingData";
import VariantData from "./VariantsData";
// import AliExpressAnalytics from "./AliExpressAnalytics";
import PricingRelatedData from "./PricingRelatedData";
import OrderTrendGraph from "./OrderTrendGraph";
import ProductSummary from "./ProductSummary";
import ShowImageModal from "./ShowImageModal";
import ViewContent from "./ViewContent";
import InventoryModal from "./InventoryModal";
import ProductAttributes from "./ProductAttributes";
import {
  formatNumber,
  getCurrencySymbol,
} from "../../../../../Helpers/Pricing";
import moment from "moment";
import axios from "axios";
import { connect } from "react-redux";

class ProductOverviewDrawer extends Component {
  state = {
    index: 0,
    showImageModal: {
      visible: false,
      index: 0,
      files: [],
      type: "",
    },
    contentModal: {
      visible: false,
      data: "",
    },
    inventoryModal: {
      visible: false,
    },
  };

  toggleInventoryModal() {
    this.setState({
      inventoryModal: {
        visible: !this.state.inventoryModal.visible,
      },
    });
  }

  toggleContentModal(data = "") {
    this.setState({
      contentModal: {
        visible: !this.state.contentModal.visible,
        data: data,
      },
    });
  }

  toggleShowImageModal(i = 0, files = [], type = "") {
    this.setState({
      showImageModal: {
        visible: !this.state.showImageModal.visible,
        index: i,
        files: files,
        type: type,
      },
    });
  }

  handleClick() {}
  showImage(i) {
    this.setState({
      index: i,
    });
  }

  showPricingModal(type) {
    const content = {
      retail: (
        <>
          <p>
            For your retail orders,{" "}
            <b>
              we procure from a relevant supplier / factory / manufacturer on a
              single order basis, i.e. 1:1 sourcing
            </b>
            . For such orders, retail orders landing cost is comprising of our:
          </p>
          <p>
            <b>
              1. Single product purchase price
              <br />
              2. Freight Charges (for China to India Shipping)
              <br />
              3. Customs Duty (custom duty paid to import the product)
              <br />
            </b>
          </p>
          <p>
            Please note that, you will be only charged a local shipping (LMD)
            fee and a COD Handling Fee (<i>provided that the order is on COD</i>
            ) on the top of this pricing, as & basis the delivery pin code of
            your order on this product.
          </p>
          <p>
            Retail Landing Cost is mostly higher than a Bulk Product Landing
            Cost, in the terms of cheaper product purchase price (when sourced
            in bulk vs based on single orders), and the freight shipping fee (
            <i>usually cheaper for a bulk quantity</i>)
          </p>
        </>
      ),
      bulk: (
        <>
          <p>
            As & <b>when we prepare the catalog of any product</b>, we go about{" "}
            <b>
              hunting for quotations on different bulk quantities of that
              product. Based on the inputs{" "}
            </b>
            received from our network of manufacturers / suppliers / factories,
            <b>we prepare a bulk order landing cost table.</b>
          </p>

          <p>
            For any specific quantity, a{" "}
            <b>Bulk India Landing Cost comprises</b> of the following:
            <br />
            1. <b>Our purchase price estimation</b> for the bulk quantity
            entered
            <br />
            2. <b>Freight charges</b>
            (for China to India Shipping - varies across different quantities
            based on the different freight mediums available) <br />
            3.
            <b>Customs duty</b> (custom duty paid to import the product)
          </p>

          <p>
            Please note that,{" "}
            <b>
              the bulk India landing cost is a function of the quantity of the
              product that you enter
            </b>{" "}
            and is based on the estimation of the latest quotations we worked
            upon (
            <i>
              you may choose to check the “last modified date” for the pricing
              data, to get an idea
            </i>
            ) and, is subject to change.
          </p>
        </>
      ),
    };

    const title = {
      bulk: `Understanding Bulk Order Pricing`,
      retail: `Understanding Retail India Landing Cost`,
    };
    const modal = Modal.confirm({
      content: (
        <div>
          <div className="title">
            <span>{title[type]}</span>
            <span style={{ cursor: "pointer" }} onClick={() => modal.destroy()}>
              <Icon type="close" />
            </span>
          </div>
          <div className="body">{content[type]}</div>
        </div>
      ),
      cancelText: "Close",
      icon: null,
      className: "bulk-pricing-modal",
      okButtonProps: {
        style: { display: "none" },
      },
    });
  }
  escalateBulkPricing() {
    axios({
      url: process.env.REACT_APP_API_URL + "/products/escalate_bulk_pricing",
      method: "POST",
      data: {
        vfprodid: this.props.productData.vfprodid,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          message.success("Requested successfully");
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  render() {
    const product = this.props.productData;
    if (!product) {
      return null;
    }

    const showBulk = this.props.store.store_geo === "in";
    const currency = this.props.store.store_geo === "global" ? "USD" : "INR";
    return (
      <Drawer
        width={"100%"}
        title={null}
        placement="right"
        className="productOverviewDrawer"
        closable={false}
        visible={this.props.visible}
      >
        <div className="drawer-main-section">
          <div className="drawer-sidebar">
            <div className="go-back">
              <a href="#!" onClick={() => this.props.productViewDrawerToggle()}>
                <Icon type="arrow-left" /> Go Back
              </a>
            </div>
            <Menu
              onClick={() => this.handleClick()}
              style={{ width: 256 }}
              mode="vertical"
              defaultSelectedKeys={["product_overview"]}
            >
              <Menu.Item key="product_overview">
                <a href="#product_overview">Product Overview</a>
              </Menu.Item>
              <Menu.Item key="product_summary">
                <a href="#product_summary">Product Summary</a>
              </Menu.Item>
              <Menu.Item key="pricing">
                <a href="#pricing">Pricing & Related Data</a>
              </Menu.Item>
              {/* {product &&
                ((product.images && product.images.length > 0) ||
                  (product.videos && product.videos.length > 0)) && ( */}
              <Menu.Item key="media">
                <a href="#media">Media</a>
                Media
              </Menu.Item>
              {/* )} */}
              {/* {product && product.advertising_data && ( */}
              <Menu.Item key="advertising">
                <a href="#advertising">Advertising Data</a>
              </Menu.Item>
              {/* )} */}

              {product && product.variants && product.variants.length > 0 && (
                <Menu.Item key="variants">
                  <a href="#variants">Variants</a>
                  Variants
                </Menu.Item>
              )}
              {product &&
                product.advertising_data &&
                (product.advertising_data.total_units_sold ||
                  product.advertising_data.total_rating ||
                  product.advertising_data.rating_listing ||
                  product.advertising_data.average_rating ||
                  product.advertising_data.total_rating ||
                  product.advertising_data.user_ratings ||
                  product.advertising_data.wow_factor ||
                  product.advertising_data.product_quality ||
                  product.advertising_data.packaging_quality) && (
                  <Menu.Item key="review_rating">
                    <a href="#review_rating">Reviews & Ratings</a>
                  </Menu.Item>
                )}
            </Menu>
          </div>
          <div className="drawer-main-body">
            <div className="modalTitle" id="product_overview">
              <div className="product-info">
                <div className="product-image">
                  <img
                    alt="not found"
                    src={product.images.length > 0 ? product.images[0].url : ""}
                    style={{ width: "100%" }}
                  />
                  <div className="blackbox">
                    {product.images.length > 0 &&
                      product.images.map((p, i) => (
                        <img
                          src={p.url}
                          key={i}
                          onClick={() =>
                            this.toggleShowImageModal(i, product.images)
                          }
                          alt="not found"
                        />
                      ))}
                  </div>
                  <p className="overview-dates">
                    Product First Added On:{" "}
                    {moment(product.createdAt).format("MMM DD, YYYY")}
                  </p>
                  <p className="overview-dates">
                    Last Updated On:{" "}
                    {moment(product.costUpdated).format("MMM DD, YYYY")}
                  </p>
                </div>
                <div className="product-details">
                  <div className="product-internal-info">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "80%" }}>
                        <h4 style={{ marginBottom: "0px" }}>Product Name: </h4>
                        <p>{product.name}</p>
                      </div>

                      <span style={{ float: "right" }}>
                        {" "}
                        <Icon
                          type="plus"
                          style={{
                            transform: "rotate(45deg)",
                            fontSize: "2rem",
                          }}
                          onClick={this.props.productViewDrawerToggle}
                        />
                      </span>
                    </div>
                    <div>
                      <h4 style={{ marginBottom: "0px" }}>vF Product ID:</h4>
                      <p>{product.vfprodid}</p>
                    </div>

                    <div>
                      <h4 style={{ marginBottom: "0px" }}>
                        India Landing Cost:
                      </h4>
                      <Row style={{ marginBottom: 10 }}>
                        <Col xs={18}>
                          For Retail Orders:{" "}
                          <span
                            style={{
                              fontWeight: 600,
                              textDecoration: "underline",
                            }}
                          >{`${getCurrencySymbol(currency)} ${formatNumber(
                            product.cost_data.vfmrp
                          )}`}</span>
                        </Col>
                        <Col xs={6}>
                          <span
                            onClick={() => this.showPricingModal("retail")}
                            style={{ cursor: "pointer" }}
                          >
                            <Icon type="info-circle" /> Understand Retail
                          </span>
                        </Col>
                      </Row>
                      {showBulk &&
                        this.props.getPriceRange(product) !==
                          `₹ ${formatNumber(product.cost_data.vfmrp)}` && (
                          <Row>
                            <Col xs={18}>
                              For Bulk Orders:{" "}
                              <span
                                style={{
                                  fontWeight: 600,
                                  textDecoration: "underline",
                                }}
                              >
                                {this.props.getPriceRange(product)}
                              </span>
                            </Col>
                            <Col xs={6}>
                              <span
                                onClick={() => this.showPricingModal("bulk")}
                                style={{ cursor: "pointer" }}
                              >
                                <Icon type="info-circle" /> Understand Bulk
                              </span>
                            </Col>
                          </Row>
                        )}
                    </div>

                    {showBulk
                      ? Object.keys(product.sourcing_pricing)
                          .sort(
                            (a, b) =>
                              Number(a.split("-")[0]) - Number(b.split("-")[0])
                          )
                          .map((range) =>
                            Object.values(product.sourcing_pricing[range]).map(
                              (v) => Number(v.ppq)
                            )
                          )
                          .filter((p) => p.length).length > 0 && (
                          <div style={{ marginTop: 10 }}>
                            <a
                              href="#!"
                              onClick={() => this.toggleInventoryModal()}
                            >
                              Looking to buy in bulk? Explore Bulk Pricing
                            </a>
                          </div>
                        )
                      : null}

                    {/* {((product.attributes &&
                      (product.attributes.featured === 1 ||
                        product.attributes.bestseller === 1)) ||
                      (product.buyback === 1 &&
                        product.buyback_percentage > 0) ||
                      product.inventory_available === 1) && ( */}
                    <div style={{ marginTop: 10 }}>
                      <h4>Other Attributes</h4>
                      <ProductAttributes product={product} />
                    </div>
                    {/* )} */}

                    <div className="explore-pricing">
                      {showBulk ? (
                        Object.keys(product.sourcing_pricing)
                          .sort(
                            (a, b) =>
                              Number(a.split("-")[0]) - Number(b.split("-")[0])
                          )
                          .map((range) =>
                            Object.values(product.sourcing_pricing[range]).map(
                              (v) => Number(v.ppq)
                            )
                          )
                          .filter((p) => p.length).length > 0 ? (
                          <Button
                            type="primary"
                            onClick={() => this.toggleInventoryModal()}
                          >
                            Explore Pricing
                          </Button>
                        ) : (
                          <Button
                            type="primary"
                            onClick={() => this.escalateBulkPricing()}
                          >
                            Request Bulk Pricing
                          </Button>
                        )
                      ) : null}
                      <Button
                        className="add-product"
                        onClick={() =>
                          this.props.importProduct([product.vfprodid])
                        }
                      >
                        Add Product To Import List
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="overview-section">
              <div
                className="product-container"
                id="product_summary"
                style={{ paddingTop: 15 }}
              >
                <h1>Product Summary</h1>
                <ProductSummary
                  productData={product}
                  niches={this.props.niches}
                  toggleContentModal={(data) => this.toggleContentModal(data)}
                />
              </div>
              <div
                className="pricing-data"
                id="pricing"
                style={{ paddingTop: 15 }}
              >
                <h1>Pricing & Related Data</h1>
                <PricingRelatedData
                  showBulk={showBulk}
                  storegeo={this.props.store.store_geo}
                  explorePricing={() => this.toggleInventoryModal()}
                  getPriceRange={this.props.getPriceRange}
                  productData={product}
                  storeid={this.props.storeid}
                  escalateBulkPricing={() => this.escalateBulkPricing()}
                />
              </div>
              <OrderTrendGraph product={product} />
              {product &&
                ((product.images && product.images.length > 0) ||
                  (product.videos && product.videos.length > 0)) && (
                  <div className="media-container" id="media">
                    <h1>Media</h1>
                    <Media
                      productData={product}
                      toggleShowImageModal={(i, files) =>
                        this.toggleShowImageModal(i, files)
                      }
                    />
                  </div>
                )}

              <div className="data-container" id="advertising">
                <h1>Advertising Data</h1>
                {product && product.advertising_data ? (
                  <AdvertisingData
                    product={product}
                    toggleShowImageModal={(i, files, type) =>
                      this.toggleShowImageModal(i, files, type)
                    }
                    toggleContentModal={(data) => this.toggleContentModal(data)}
                  />
                ) : (
                  <div className="advertising-data-not-available">
                    <h2>Advertising Data Not Available</h2>
                    <p>
                      Advertising data is not available for this product yet. We
                      are working on getting it for you right now and this space
                      will be updated with the relevant data soon.
                    </p>
                  </div>
                )}
              </div>

              {product && product.variants && product.variants.length > 0 && (
                <div className="data-container" id="variants">
                  <h1>Variants</h1>
                  <VariantData
                    product={product}
                    toggleShowImageModal={(i, files, type) =>
                      this.toggleShowImageModal(i, files, type)
                    }
                  />
                </div>
              )}
              {/* {product &&
                product.advertising_data &&
                (product.advertising_data.total_units_sold ||
                  product.advertising_data.total_rating ||
                  product.advertising_data.rating_listing ||
                  product.advertising_data.average_rating ||
                  product.advertising_data.total_rating ||
                  product.advertising_data.user_ratings ||
                  product.advertising_data.wow_factor ||
                  product.advertising_data.product_quality ||
                  product.advertising_data.packaging_quality) && (
                  <div className="data-container" id="review_rating">
                    <h1>Reviews & Ratings</h1>
                    <AliExpressAnalytics product={product} />
                  </div>
                )} */}
              {product.aliproduct_ids && product.aliproduct_ids[0] && (
                <div className="data-container" id="review_rating">
                  <h1>Reviews & Ratings</h1>
                  <div className="ali-express-analytics">
                    <iframe
                      style={{ width: "100%", border: "none", minHeight: 400 }}
                      title="reviews"
                      src={`https://feedback.aliexpress.com/display/productEvaluation.htm?productId=${
                        product.aliproduct_ids[0]
                      }&ownerMemberId=236789777&companyId=244825918&memberType=seller&startValidDate=&i18n=true`}
                    />
                  </div>
                </div>
              )}
              <ShowImageModal
                visible={this.state.showImageModal.visible}
                toggleShowImageModal={() => this.toggleShowImageModal()}
                files={this.state.showImageModal.files}
                defaultIndex={this.state.showImageModal.index}
                type={this.state.showImageModal.type}
              />
              <ViewContent
                visible={this.state.contentModal.visible}
                toggleContentModal={() => this.toggleContentModal()}
                data={this.state.contentModal.data}
              />
              <InventoryModal
                {...this.props}
                storeid={this.props.storeid}
                visible={this.state.inventoryModal.visible}
                product={product}
                geo={this.props.store.store_geo}
                toggleInventoryModal={() => this.toggleInventoryModal()}
              />
            </div>
          </div>
        </div>
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};
export default connect(mapStateToProps)(ProductOverviewDrawer);
