import React from "react";

import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import VariantSelector from "../VariantSelector";
import BookInventoryFloatingWidget from "../BookInventoryFloatingWidget";
import "./index.scss";
import { Drawer, Button, Radio, Divider } from "antd";

class PlaceOrderDrawer extends React.Component {
  state = {
    loading: false,
    freightData: "",
    bulkSample: false,
    moqIndex: null,

    minimumMoq: null,
    minimumMoqFreight: null,
  };

  handleCheckboxChange = (event, index) => {
    console.log("dddddddddoo", event, index);

    this.setState(
      {
        freightData: event,
        bulkSample: false,
        moqIndex: index,
      },
      () => this.props.onUpdate(event, index)
    );

    this.props.updateFreightData(event);
  };

  moqName = {
    "Via Air": "air_price",
    "Via Sea": "sea_price",
    Domestic: "domestic_price",
    Sample: "sample_price",
  };

  moqRangeArray = this.props.moq2;

  findIndexByMoq(entries, targetMoq) {
    let closestIndex = -1;
    let minDifference = Infinity;

    entries.forEach((entry, index) => {
      const difference = Math.abs(entry.moq - targetMoq);
      if (difference < minDifference) {
        minDifference = difference;
        closestIndex = index;
      }
    });

    return closestIndex;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.moqvalue !== this.props.moqvalue) {
      if (
        this.props.moqvalue < this.state.minimumMoq &&
        this.props.freightkey !== "Sample"
      ) {
        this.handleCheckboxChange("Sample", 0);
      } else if (
        this.props.moqvalue >= this.state.minimumMoq &&
        this.props.freightkey === "Sample"
      ) {
        let index = this.findIndexByMoq(
          this.props.moq2[this.moqName[this.state.minimumMoqFreight]],
          this.props.moqvalue
        );
        this.handleCheckboxChange(this.state.minimumMoqFreight, index);
      } else if (this.props.freightkey !== "Sample") {
        let index = this.findIndexByMoq(
          this.props.moq2[this.moqName[this.props.freightkey]],
          this.props.moqvalue
        );

        this.handleCheckboxChange(this.props.freightkey, index);
      }
    }
  }

  componentDidMount() {
    if (this.props.moq2 && this.props.moq2.domestic_price) {
      return this.setState({
        minimumMoq: this.props.moq2.domestic_price[0]["moq"],
        minimumMoqFreight: "Domestic",
      });
    }
    if (this.props.moq2 && this.props.moq2.air_price) {
      return this.setState({
        minimumMoq: this.props.moq2.air_price[0]["moq"],
        minimumMoqFreight: "Via Air",
      });
    }

    if (this.props.moq2 && this.props.moq2.sea_price) {
      return this.setState({
        minimumMoq: this.props.moq2.sea_price[0]["moq"],
        minimumMoqFreight: "Via Sea",
      });
    }
  }

  handleBulkSample = (event, index) => {
    this.props.onUpdate(event);
    this.setState({ freightData: event, moqIndex: index, bulkSample: true });
    this.props.updateFreightData("Sample");
  };

  moqTable2Handler(data, name) {
    if (Array.isArray(data)) {
      return data.map((item, index) => {
        return (
          <div
            key={index}
            onClick={(e) => this.handleCheckboxChange(name, index)}
            className={`new-moq-table-row ${
              this.state.freightData === name && this.state.moqIndex === index
                ? `selected`
                : ``
            }`}
          >
            <div className="select">
              <Radio
                checked={
                  this.state.freightData === name &&
                  this.state.moqIndex === index
                }
              ></Radio>
            </div>
            <div className="type">{name}</div>
            <div className="moq">{!!item.moq ? item.moq : "-"}</div>
            <div className="transit-time">
              {!!item["transit_time"] ? item["transit_time"] : "-"}
            </div>
            <div className="price">
              {!!item["total"] ? `₹ ` + item["total"] : "-"}
            </div>
          </div>
        );
      });
    }
  }

  render() {
    return (
      <Drawer
        className="place-order-drawer"
        title={
          <div className="drawer-title">
            Raising Sourcing Request
            <p>Please complete your Sourcing request:</p>
          </div>
        }
        width={700}
        placement="right"
        closable={true}
        onClose={() => this.props.setState({ placeOrderDrawerVisible: false })}
        visible={this.props.visible}
      >
        <div className="step-head">
          <p>Step 1 of 2</p>
          <span>Select a Freight Type</span>
        </div>

        <div className="new-moq-table">
          <div className="main-heading">
            <div className="select">Select</div>
            <div className="type">Shipping Type</div>
            <div className="moq">MOQ</div>
            <div className="transit-time">Transit Time</div>
            <div className="price">Price/Qty</div>
          </div>

          {this.moqTable2Handler(
            this.props.moq2 && this.props.moq2.sample_price,
            "Sample"
          )}
          {this.moqTable2Handler(
            this.props.moq2 && this.props.moq2.domestic_price,
            "Domestic"
          )}
          {this.moqTable2Handler(
            this.props.moq2 && this.props.moq2.air_price,
            "Via Air"
          )}
          {this.moqTable2Handler(
            this.props.moq2 && this.props.moq2.sea_price,
            "Via Sea"
          )}
        </div>

        <Divider />
        <div className="step-head">
          <p>Step 2 of 2</p>
          <span>Edit Product Quantity</span>
        </div>

        <VariantSelector
          quantity={this.props.quantity}
          bulkSample={this.state.bulkSample}
          moqIndex={this.state.moqIndex}
          product={this.props.state.product}
          currency={"INR"}
          variants={this.props.state.product.variants}
          cr={this.props.state.product.carousal_image}
          pricing={this.props.state.product.retail_sourcing.variants}
          setSelectedImage={(index) => this.props.setSelectedImage(index)}
          updateSelectedVariants={(k, v) =>
            this.props.updateSelectedVariants(k, v)
          }
          freightData={this.state.freightData}
          onUpdate={this.props.onUpdate}
          updateFreightData={this.props.updateFreightData}
          onUpdatemoqvqlue={this.props.onUpdatemoqvqlue}
          errorText={this.props.errorText}
        />

        <BookInventoryFloatingWidget
          radio={"in"}
          handleAirChange={(e, a) => this.handleCheckboxChange("Via Air", 0)}
          sampleHandler={(e, a) => this.handleBulkSample("Sample", 0)}
          bulkSample={this.state.bulkSample}
          moqIndex={this.state.moqIndex}
          minimumMoq={this.state.minimumMoq}
          storeGeo={this.props.match.params.storegeo === "in" ? "in" : "global"}
          geo={
            !!this.state.radio
              ? this.state.radio
              : this.props.match.params.storegeo
          }
          currency={"INR"}
          suggestedPrice={this.props.state.product.suggested_sellingprice}
          globalSuggested={
            this.props.state.product.global_suggestedsellingprice
          }
          vfprodid={this.props.match.params.vfprodid}
          storeid={this.props.match.params.storeid}
          variants={this.props.state.selectedVariants}
          gstPercentage={this.props.state.product.hsn_attributes.gst}
          quantity={this.props.quantity}
          // importProducts={(ids) => this.importProducts(ids)}
          catalogName={this.props.state.product.catalogname}
          typ={this.props.state.fieldVal}
          catalogImage={this.props.state.product.images}
          selectedImgIndex={this.props.state.selectedImageIndex}
          importStoreData={this.props.state.fixCheckout}
          freightData={this.props.state.freightkey}
          product={this.props.state.product}
          moqvalue={this.props.state.moqvalue}
          onClose={() =>
            this.props.setState({ placeOrderDrawerVisible: false })
          }
          loadProductData={() => this.props.defaultStateSet()}
          variantsErrorInfo={(d) => this.props.variantsErrorInfo(d)}
        />
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};

export default connect(mapStateToProps)(PlaceOrderDrawer);
