import React, { Component } from 'react';
import { Button, Input, Modal, Row, Col, Alert } from 'antd';
import { connect } from 'react-redux'
import './index.scss';

class SendMsgToCstmrModal extends Component {
    state = {
        senderName: this.props.auth.store.store_name,
        replyTo: '',
        subject: '',
        message: ''
    };

    handleChange = (field, value) => {
        this.setState({
            ...this.state,
            [field]: value
        });
    };

    resetModal = () => {
        this.setState({
            senderName: '',
            replyTo: '',
            subject: '',
            message: ''
        });
    };

    render() {
        const { visible, onCancel, onConfirm } = this.props;
        const { senderName, replyTo, subject, message } = this.state;
        return (
            <Modal
                className="orders-send-msg-to-customer-modal"
                title="Send Message To Customer"
                visible={visible}
                onCancel={onCancel}
                afterClose={this.resetModal}
                closable={!this.props.saving}
                footer={[
                    <Button key="back" onClick={onCancel}>Cancel</Button>,
                    <Button key="submit" type="primary" onClick={() => onConfirm(this.state)} loading={this.props.saving}>Send</Button>,
                ]}
            >
                <div>
                    <Row>
                        <Col span={24} md={11}>
                            <p className="input-label no-top-margin">Sent from</p>
                            <Input
                                type="text"
                                value={senderName}
                                onChange={(e) => this.handleChange("senderName", e.target.value)}
                                placeholder="Sender Name"
                            />
                        </Col>

                        <Col span={24} md={{ span: 11, offset: 2 }}>
                            <p className="input-label no-top-margin">Reply to</p>
                            <Input
                                type="email"
                                value={replyTo}
                                onChange={(e) => this.handleChange("replyTo", e.target.value)}
                                placeholder="replytothisemail@mail.com"
                            />
                        </Col>

                        <Col span={24}>
                            <p className="input-label">Subject</p>
                            <Input
                                type="text"
                                value={subject}
                                onChange={(e) => this.handleChange("subject", e.target.value)}
                                placeholder="Your order #ORD11283 has been processed successfully"
                            />
                        </Col>
                        <Col span={24}>
                            <p className="input-label">Message</p>
                            <Input.TextArea
                                value={message}
                                onChange={(e) => this.handleChange("message", e.target.value)}
                                autosize={{ minRows: 6 }}
                            />
                        </Col>
                    </Row>
                    {this.props.error && (
                        <Row style={{ margin: "15px 0" }}>
                            <Alert type="error" message={this.props.error} />
                        </Row>
                    )}

                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    }
}

export default connect(mapStateToProps)(SendMsgToCstmrModal);