import { Button, message, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import { data } from "browserslist";
import React, { Component } from "react";
import "./index.scss";
export class ViewReasonModal extends Component {
  render() {
    console.log(this.props.data);
    return (
      <div>
        <Modal
          className="cancel-modal234"
          title="Rejection Reason"
          width={700}
          footer={
            <>
              <Button
             
                onClick={() => this.props.onCancel()}
              >
                {" "}
                Close
              </Button>{" "}
            </>
          }
          destroyOnClose={true}
          visible={this.props.data.visible}
          onCancel={() => this.props.onCancel()}
        >
          <div style={{ marginBottom: "8px" }}>
            <div className="reason-container">
              {" "}
              <span>Reason : </span> {this.props.data.reason}
            </div>
            <div className="by">
              <span>By : </span>vFulfill Team
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
