import React from "react";
import {
  Modal,
  Row,
  Col,
  Input,
  InputNumber,
  Button,
  DatePicker,
  message,
} from "antd";
import moment from "moment";
import axios from "axios";
import "./index.scss";
import ActionNotification from "../../Common/ActionNotification";

class NDRReAttemptModal extends React.Component {
  state = {
    order: {
      customer_details: {
        email: "",
      },
      shipping_address: {
        name: "",
        address1: "",
        address2: "",
        zip: "",
        country: "",
        city: "",
        province: "",
        phone: "",
      },
    },
    date: null,
    errors: {},
    saving: false,
  };

  componentDidUpdate(pp) {
    if (JSON.stringify(this.props.address) !== JSON.stringify(pp.address)) {
      this.setState({
        order: this.props.address,
      });
    }
    if (this.props.visible !== pp.visible) {
      this.setState({
        date: null,
      });
    }
  }

  updateCustomer(type, key, val) {
    this.setState({
      order: {
        ...this.state.order,
        [type]: {
          ...this.state.order[type],
          [key]: val,
        },
      },
    });
  }

  setDate = (date) => {
    this.setState({
      date,
    });
  };

  saveNDRRequest = () => {
    const order = this.state.order;
    let errors = {};
    if (order.shipping_address.name === "") {
      errors.customer_details_name = "This is a required field!";
    }
    if (order.shipping_address.address1 === "") {
      errors.shipping_address_address1 = "This is a required field!";
    }
    if (order.shipping_address.zip === "") {
      errors.shipping_address_zip = "This is a required field!";
    }
    if (order.customer_details.email === "") {
      errors.customer_details_email = "This is a required field!";
    }
    if (order.shipping_address.country === "") {
      errors.shipping_address_country = "This is a required field!";
    }
    if (order.shipping_address.city === "") {
      errors.shipping_address_city = "This is a required field!";
    }
    if (order.shipping_address.province === "") {
      errors.shipping_address_province = "This is a required field!";
    }
    if (!moment(this.state.date).isValid()) {
      errors.date = "This is a required field!";
    }
    this.setState({ errors: errors });
    if (Object.keys(errors).length === 0) {
      this.setState(
        {
          saving: true,
        },
        () => {
          axios({
            url: process.env.REACT_APP_API_URL + "/orders/reattempt_delivery",
            method: "post",
            data: {
              storeid: this.props.match.params.storeid,
              awb: this.props.awb,
              shipment_vfid: this.props.shipment_vfid,
              customer_details: order.customer_details,
              shipping_address: order.shipping_address,
              date: this.state.date.format("YYYY-MM-DD"),
            },
            withCredentials: true,
          })
            .then((res) => {
              if (res.data.success === 1) {
                // message.success("Re-Attempt request created successfully!");
                ActionNotification({
                  notificationType: "re-attempt",
                  awb: this.props.awb,
                });
                this.props.onNDRReattemptSuccess();
                this.props.toggleNdrReAttemptModal();
              } else {
                message.error(res.data.msg);
              }
              this.setState({
                saving: false,
              });
            })
            .catch((e) => {
              message.error(e.message);
              this.setState({
                saving: false,
              });
            });
        }
      );
    }
  };

  render() {
    const order = this.state.order;
    return (
      <Modal
        className="ndr-modal"
        title="Re-Attempt Delivery"
        onCancel={() => this.props.toggleNdrReAttemptModal()}
        visible={this.props.visible}
        width={800}
        footer={null}
        destroyOnClose
      >
        <div>
          <Row gutter={20}>
            <Col xs={8}>
              <div
                className={`ant-form-item-control ${
                  this.state.errors.customer_details_name ? "has-error" : ""
                }`}
              >
                <label>Full Name</label>
                <Input
                  type="text"
                  value={order.shipping_address.name}
                  onChange={(e) =>
                    this.updateCustomer(
                      "shipping_address",
                      "name",
                      e.target.value
                    )
                  }
                />
                {this.state.errors.customer_details_name && (
                  <div className="ant-form-explain">
                    {this.state.errors.customer_details_name}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={8}>
              <div
                className={`ant-form-item-control ${
                  this.state.errors.shipping_address_address1 ? "has-error" : ""
                }`}
              >
                <label>Address Line #1</label>
                <Input
                  type="text"
                  value={order.shipping_address.address1}
                  onChange={(e) =>
                    this.updateCustomer(
                      "shipping_address",
                      "address1",
                      e.target.value
                    )
                  }
                />
                {this.state.errors.shipping_address_address1 && (
                  <div className="ant-form-explain">
                    {this.state.errors.shipping_address_address1}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={8}>
              <div
                className={`ant-form-item-control ${
                  this.state.errors.shipping_address_zip ? "has-error" : ""
                }`}
              >
                <label>Pin Code</label>
                <InputNumber
                  style={{ width: "100%" }}
                  value={order.shipping_address.zip}
                  onChange={(e) =>
                    this.updateCustomer("shipping_address", "zip", e)
                  }
                />
                {this.state.errors.shipping_address_zip && (
                  <div className="ant-form-explain">
                    {this.state.errors.shipping_address_zip}
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={8}>
              <div
                className={`ant-form-item-control ${
                  this.state.errors.customer_details_email ? "has-error" : ""
                }`}
              >
                <label>Email Address</label>
                <Input
                  type="text"
                  value={order.customer_details.email}
                  onChange={(e) =>
                    this.updateCustomer(
                      "customer_details",
                      "email",
                      e.target.value
                    )
                  }
                />
                {this.state.errors.customer_details_email && (
                  <div className="ant-form-explain">
                    {this.state.errors.customer_details_email}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={8}>
              <div className="ant-form-item-control">
                <label>Address Line #2</label>
                <Input
                  type="text"
                  value={order.shipping_address.address2}
                  onChange={(e) =>
                    this.updateCustomer(
                      "shipping_address",
                      "address2",
                      e.target.value
                    )
                  }
                />
              </div>
            </Col>
            <Col xs={8}>
              <div
                className={`ant-form-item-control ${
                  this.state.errors.shipping_address_country ? "has-error" : ""
                }`}
              >
                <label>Country</label>
                <Input
                  type="text"
                  value={order.shipping_address.country}
                  onChange={(e) =>
                    this.updateCustomer(
                      "shipping_address",
                      "country",
                      e.target.value
                    )
                  }
                />
                {this.state.errors.shipping_address_country && (
                  <div className="ant-form-explain">
                    {this.state.errors.shipping_address_country}
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row gutter={20} style={{ display: "flex" }}>
            <Col xs={8}>
              <div className="ant-form-item-control">
                <label>Mobile Number</label>
                <Input
                  type="text"
                  value={order.shipping_address.phone}
                  onChange={(e) =>
                    this.updateCustomer(
                      "shipping_address",
                      "phone",
                      e.target.value
                    )
                  }
                />
              </div>
            </Col>
            <Col xs={4}>
              <div
                className={`ant-form-item-control ${
                  this.state.errors.shipping_address_city ? "has-error" : ""
                }`}
              >
                <label>City/Town</label>
                <Input
                  type="text"
                  value={order.shipping_address.city}
                  onChange={(e) =>
                    this.updateCustomer(
                      "shipping_address",
                      "city",
                      e.target.value
                    )
                  }
                />
                {this.state.errors.shipping_address_city && (
                  <div className="ant-form-explain">
                    {this.state.errors.shipping_address_city}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={4}>
              <div
                className={`ant-form-item-control ${
                  this.state.errors.shipping_address_province ? "has-error" : ""
                }`}
              >
                <label>State</label>
                <Input
                  type="text"
                  value={order.shipping_address.province}
                  onChange={(e) =>
                    this.updateCustomer(
                      "shipping_address",
                      "province",
                      e.target.value
                    )
                  }
                />
                {this.state.errors.shipping_address_province && (
                  <div className="ant-form-explain">
                    {this.state.errors.shipping_address_province}
                  </div>
                )}
              </div>
            </Col>
            <Col
              xs={8}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <div
                className={`ant-form-item-control ${
                  this.state.errors.date ? "has-error" : ""
                }`}
              >
                <label>Re-Attempt On</label>
                <DatePicker
                  value={this.state.date}
                  disabledDate={(current) =>
                    current &&
                    (current <
                      moment()
                        .subtract(1, "days")
                        .endOf("day") ||
                      current >
                        moment()
                          .add(7, "days")
                          .endOf("day") ||
                      current.isoWeekday() === 7)
                  }
                  onChange={this.setDate}
                  style={{ width: "100%" }}
                />
                {this.state.errors.date && (
                  <div className="ant-form-explain">
                    {this.state.errors.date}
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col xs={24} style={{ textAlign: "center" }}>
              <Button
                className="btn-save-customer"
                type="primary"
                size="large"
                onClick={() => this.saveNDRRequest()}
                loading={this.state.saving}
              >
                Re-Attempt Delivery
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

export default NDRReAttemptModal;
