import React from "react";
import { Row, Col, Select, Tooltip, message } from "antd";
import EditableText from "../../../../Common/EditableText";
import { GetAliURL } from "../../../../../../Helpers/URL";
import "./ImportListProductBasic.scss";
import axios from "axios";
import {
  toStoreCurrency,
  formatNumber,
  getCurrencySymbol,
} from "../../../../../../Helpers/Pricing";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SingleTagSelect from "./SingleTagSelect";
import EscalatedMessage from "./EscalatedMessage";
import RemindedMessage from "./RemindedMessage";
import RejectedMessage from "./RejectedMessage";
import StoreConnected from "./StoreConnected";
import StoreConnected2 from "./StoreConnected2";
import DefaultMessage from "./DefaultMessage";
import ViewInVf from "../../../../Common/GlobalCommon/ViewInVf";
import ViewInAli from "../../../../Common/GlobalCommon/ViewInAli";
import RequestLattestPrice from "./Requestlattestprice";
import AddStoreModal from "../../../../Common/AddStoreModal";
import moment from "moment";
import ClickToCopy from "../../../../Common/ClickToCopy";
import { SHOPIFY_NAME } from "../../../../../../constants";

const { Option } = Select;

class ImportListProductBasic extends React.Component {
  state = {
    currentImgIndex: 0,
    loading: false,
    response: "",
    reminder: false,
    escalated: false,
    messagedata: "",
    rejectedMessage: false,
    status: 0,
    addStoreModal: false,
    clicked: false,
  };

  requestUpdatedQuotation() {
    this.setState(
      {
        loading: true,
      },
      () => {
        if (this.cancelToken) {
          this.cancelToken.cancel();
        }
        this.cancelToken = axios.CancelToken.source();
        axios({
          url: process.env.REACT_APP_API_URL + "/user/create_partner_request",
          method: "post",
          cancelToken: this.cancelToken.token,
          data: {
            type: "vfcatalog_update_quotation",
            data: { vfprodid: this.props.product.vfprod.vfprodid },
            storeid: this.props.product.product_storeid["$oid"],
          },
          withCredentials: true,
        })
          .then((res) => {
            this.setState({
              loading: false,
              responsemessage: res.data.msg,
              status: res.data.success,
              clicked: true,
            });

            this.props.lessRequestCount();
            return res;
          })

          .catch((e) => {
            this.setState({
              responsemessage: "There was an error!",
            });
          });
      }
    );
  }
  nextImg() {
    this.setState((state) => ({
      currentImgIndex:
        state.currentImgIndex === this.props.product.vfprod.gallery.length - 1
          ? 0
          : state.currentImgIndex + 1,
    }));
  }

  prevImg() {
    this.setState((state) => ({
      currentImgIndex:
        state.currentImgIndex === 0
          ? this.props.product.vfprod.gallery.length - 1
          : state.currentImgIndex - 1,
    }));
  }

  productMessage = (product) => {
    let messageStatus = "default";

    if (product.catalog_complete === 1) {
      messageStatus = "default";
    } else {
      if (product.rejected === 1) {
        messageStatus = "rejected";
      } else {
        if (product.escalated === 0) {
          messageStatus = "doescalate";
        } else if (product.escalated === 1) {
          messageStatus = "escalated";
        } else if (product.escalated === -1) {
          messageStatus = "doreminder";
        } else if (product.escalated === -2) {
          messageStatus = "reminded";
        }
      }
    }

    if (this.props.storeConnected === false) {
      messageStatus = "notConnected";
    }

    if (this.props.app_uninstall === true) {
      return (messageStatus = "uninstall");
    }

    return messageStatus;
  };

  componentDidMount() {
    this.setState({
      messagedata: this.productMessage(this.props.product),
    });
  }

  escalated = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/products/escalated_my_product",
          method: "post",
          data: {
            id: this.props.product._id.$oid,
            store_id: this.props.product.product_storeid.$oid,
          },
          withCredentials: true,
        })
          .then((res) => {
            message.destroy();
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({ messagedata: "escalated" });
              message.success("Requested Successfully!");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
          });
      }
    );
  };

  rejectedproduct = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/products/recatalog_reject_product_request",
          method: "post",
          data: {
            id: this.props.product._id.$oid,
            store_id: this.props.product.product_storeid.$oid,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({ rejectedMessage: true });
              this.props.loadData();
              message.success("Ticket raised sucessfully!");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
          });
      }
    );
  };

  reminder = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/products/sent_reminder_my_product",
          method: "post",
          data: {
            id: this.props.product._id.$oid,
            store_id: this.props.product.product_storeid.$oid,
          },
          withCredentials: true,
        })
          .then((res) => {
            message.destroy();
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({ messagedata: "reminded" });
              message.success("Reminder Successfully!");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
          });
      }
    );
  };

  // add store handler
  addStoreHandler() {
    this.setState({ addStoreModal: !this.addStoreModal });
  }

  toggleAddStoreModal() {
    this.setState({
      addStoreModal: !this.state.addStoreModal,
    });
  }

  tooltip() {
    // store not connected
    if (
      this.props.storeConnected === false ||
      (this.props.storeConnected === true && this.props.app_uninstall === true)
    ) {
      return (
        <span style={{ color: "rgb(137, 137, 137)" }} className="abs">
          {" "}
          <Tooltip
            title={
              <p
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  letterSpacing: "0.032em",
                }}
              >
                {`You can request latest pricing after you have connected your
                ${SHOPIFY_NAME}`}
              </p>
            }
            className="cursor-pointer"
          >
            Request Latest Price
          </Tooltip>
        </span>
      );
    }
    // product not catalog
    if (this.state.messagedata !== "default") {
      return (
        <span style={{ color: "rgb(137, 137, 137)" }} className="abs">
          <Tooltip
            title={
              <p
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  letterSpacing: "0.032em",
                }}
              >
                You can request latest pricing after this product has been
                catalog
              </p>
            }
            className="cursor-pointer"
          >
            Request Latest Price
          </Tooltip>
        </span>
      );
    }

    if (this.state.messagedata === "default") {
      if (
        moment().diff(moment(this.props.product.vfprod.lastupdated), "days") > 7
      ) {
        if (
          this.props.product.request_latest_price.success === 0 &&
          this.props.requestCount === 0
        ) {
          return (
            <span style={{ color: "rgb(137, 137, 137)" }} className="abs">
              <Tooltip
                title={
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "10px",
                      letterSpacing: "0.032em",
                    }}
                    className="cursor-pointer"
                  >
                    Request Latest Price
                    <span
                      style={{
                        fontWeight: "500",
                        textDecoration: "underline",
                        display: "block",
                      }}
                    >
                      Pricing Requests Left: 0
                    </span>
                    <span
                      style={{
                        marginTop: "20px",
                        display: "block",
                      }}
                    >
                      <i>*Your 5 Pricing Requests will be</i>
                    </span>
                    <span
                      style={{
                        display: "block",
                      }}
                    >
                      <i>reset next week.</i>
                    </span>
                  </p>
                }
                className="cursor-pointer"
              >
                Request Latest Price
              </Tooltip>
            </span>
          );
        }

        if (
          this.props.product.request_latest_price.success === 0 &&
          this.props.requestCount !== 0
        ) {
          return (
            <span style={{ color: "rgb(137, 137, 137)" }} className="abs">
              <Tooltip
                title={
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                      letterSpacing: "0.032em",
                    }}
                    className="cursor-pointer"
                  >
                    Request Latest Price
                    <span
                      style={{
                        fontWeight: "500",
                        textDecoration: "underline",
                        display: "block",
                      }}
                    >
                      {this.props.product.request_latest_price.msg}
                    </span>
                  </p>
                }
              >
                <span
                  style={{ float: "right", fontSize: "12px" }}
                  className="cursor-pointer"
                >
                  Request Latest Price
                </span>
              </Tooltip>
            </span>
          );
        }

        if (
          this.props.product.request_latest_price.success !== 0 &&
          this.props.requestCount === 0
        ) {
          return (
            <Tooltip
              title={
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "10px",
                    letterSpacing: "0.032em",
                  }}
                  className="cursor-pointer"
                >
                  Request Latest Price
                  <span
                    style={{
                      fontWeight: "500",
                      textDecoration: "underline",
                      display: "block",
                    }}
                  >
                    Pricing Requests Left: 0
                  </span>
                  <span
                    style={{
                      marginTop: "20px",
                      display: "block",
                    }}
                  >
                    <i>*Your 5 Pricing Requests will be</i>
                  </span>
                  <span
                    style={{
                      display: "block",
                    }}
                  >
                    <i>reset next week.</i>
                  </span>
                </p>
              }
              className="cursor-pointer"
            >
              Request Latest Price
            </Tooltip>
          );
        }

        //
        if (
          this.props.product.request_latest_price.success !== 0 &&
          this.props.requestCount !== 0
        ) {
          return (
            <span>
              <Tooltip
                title={
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                      letterSpacing: "0.032em",
                    }}
                    className="cursor-pointer"
                  >
                    Request latest price
                    <span
                      style={{
                        textAlign: "center",
                        textDecoration: "underline",
                        display: "block",
                      }}
                    >
                      Pricing Requests Left: {this.props.requestCount}
                    </span>
                  </p>
                }
                className="cursor-pointer"
              >
                <span style={{ float: "right" }}>
                  <span
                    style={{ color: "#0068FF" }}
                    className={`req-latest-price-import cursor-pointer ${
                      this.props.requestCount === 0 ||
                      this.state.clicked === true
                        ? `sv3`
                        : null
                    }`}
                    onClick={() => this.requestUpdatedQuotation()}
                  >
                    Request Latest Price
                  </span>
                </span>
              </Tooltip>
            </span>
          );
        }
      } else {
        return (
          <Tooltip
            title={
              <p
                style={{
                  textAlign: "center",
                  fontSize: "10px",
                  letterSpacing: "0.032em",
                }}
                className="cursor-pointer"
              >
                Request Latest Price
                <span
                  style={{
                    fontWeight: "500",
                    textDecoration: "underline",
                    display: "block",
                  }}
                >
                  Pricing last updated on:{" "}
                  {moment().diff(
                    moment(this.props.product.vfprod.lastupdated),
                    "days"
                  ) === 1
                    ? `${moment().diff(
                        moment(this.props.product.vfprod.lastupdated),
                        "days"
                      )} Day ago`
                    : `${moment().diff(
                        moment(this.props.product.vfprod.lastupdated),
                        "days"
                      )} Days ago`}
                </span>
                <p>
                  You can request latest price after (
                  {7 -
                    moment().diff(
                      moment(this.props.product.vfprod.lastupdated),
                      "days"
                    ) ===
                  1
                    ? `1 Day`
                    : `${7 -
                        moment().diff(
                          moment(this.props.product.vfprod.lastupdated),
                          "days"
                        )} Days`}
                  )
                </p>
              </p>
            }
          >
            <span className="abs cursor-pointer"> Request Latest Price</span>
          </Tooltip>
        );
      }
    }
  }

  render() {
    const props = this.props;
    const { product, currencies } = this.props;
   
    let messageStatus = this.productMessage(product);

    const vfmrp = { ...product.vfprod.retail_sourcing };
    if (props.store.store_geo === "in") {
      vfmrp.min = vfmrp.min;
      vfmrp.max = vfmrp.max;
      vfmrp.avg = vfmrp.avg;
    }

    const vfmrp2 = { ...product.vfprod.retail_sourcing };
    if (props.store.store_geo === "in" && currencies.store_currency !== "INR") {
      vfmrp2.min = vfmrp2.min;
      vfmrp2.max = vfmrp2.max;
      vfmrp2.avg = vfmrp2.avg;
    }

    return (
      <div>
        <Row type="flex" className="import-list-product-basic">
          <Col xs={24} md={4}>
            <div
              className="product-img-container"
              style={{ position: "relative", zIndex: "9", margin: "0 auto" }}
            >
              <div className="import_list_card_image">
                <img
                  src={
                    product.vfprod.gallery &&
                    product.vfprod.gallery[this.state.currentImgIndex] ===
                      "https:"
                      ? "https://demofree.sirv.com/nope-not-here.jpg"
                      : product.vfprod.gallery[this.state.currentImgIndex]
                  }
                  alt={product.name}
                  // style={{ maxWidth: "100%" }}
                />
              </div>
              <button className="prev-img" onClick={() => this.prevImg()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.163"
                  height="41"
                  viewBox="0 0 17.163 41"
                >
                  <g>
                    <path
                      d="M.915 19.4L14.63.932a.95.95 0 011.616.038 1.942 1.942 0 010 2.139L3.339 20.491l12.907 17.382a1.937 1.937 0 010 2.176.95.95 0 01-1.616 0L.915 21.579a1.937 1.937 0 010-2.179z"
                      fill="#fff"
                      stroke="#fff"
                    />
                  </g>
                </svg>
              </button>

              <button className="next-img" onClick={() => this.nextImg()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.163"
                  height="41"
                  viewBox="0 0 17.163 41"
                >
                  <g>
                    <path
                      d="M16.247 19.4L2.532.932A.95.95 0 00.916.97a1.942 1.942 0 000 2.139l12.907 17.382L.916 37.873a1.937 1.937 0 000 2.176.95.95 0 001.616 0l13.715-18.47a1.937 1.937 0 000-2.179z"
                      fill="#fff"
                      stroke="#fff"
                    />
                  </g>
                </svg>
              </button>
            </div>

            <p
              style={{
                fontSize: ".75rem",
                textAlign: "center",
                color: "#FF005E",
                marginLeft: "1rem",
              }}
            >
              {product.vfprod && product.vfprod.prod_status === "1" ? (
                <>
                  {/* <Link
                    to={`/${props.store.store_geo}/${props.store.id}/products/${
                      product.vfprod.vfprodid
                    }`}
                    target="_blank"
                    style={{ color: "#FF005E" }}
                  >
                    View In VF Catalog
                  </Link> */}
                  <ViewInVf
                    storegeo={props.store.store_geo}
                    id={props.store.id}
                    vfid={product.vfprod.vfprodid}
                  />
                  <p>
                    {/* <span className="id-copy">{product.vfprod.vfprodid}</span> */}

                    <ClickToCopy textToCopy={product.vfprod.vfprodid} />
                  </p>
                </>
              ) : (
                <>
                  {/* <a
                    href={GetAliURL(
                      `https://aliexpress.com/item/-/${
                        product.vfprod.productId
                      }.html`
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#FF005E" }}
                  >
                    View On AliExpress
                  </a> */}

                  <ViewInAli id={product.vfprod.productId} />

                  <p>
                    {/* <span className="id-copy"> {product.vfprod.productId}</span> */}

                    <ClickToCopy textToCopy={product.vfprod.productId} />
                  </p>
                </>
              )}
            </p>
          </Col>
          <Col
            xs={24}
            md={20}
            style={{ paddingLeft: "32px", marginTop: "3px" }}
          >
            <Row>
              <p style={{ marginBottom: "5px" }}>
                <b style={{ color: "#898989" }}>Product Title:</b>
              </p>
              <Col xs={24} md={24}>
                <EditableText
                  className="badge"
                  type="importList"
                  placeholder="Enter title"
                  text={product.name ? product.name : null}
                  color="#262626"
                  fontSize=".875rem"
                  style={{
                    marginRight: "8px",
                    background: "#f5f5f5",
                    padding: "3px 8px",
                    height: "25px",
                  }}
                  onTextChange={(value) => props.onFieldChange("name", value)}
                />
              </Col>
            </Row>

            <Row>
              <p style={{ marginBottom: "5px" }}>
                <b style={{ color: "#898989" }}>Category:</b>
              </p>
              <Select
                showSearch
                mode="multiple"
                style={{ width: "100%", marginBottom: "10px" }}
                placeholder="Select a collection for your product"
                optionFilterProp="children"
                onChange={(value) => props.onFieldChange("collection", value)}
                defaultValue={product.collection}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {props.collections.map((c) => (
                  <Option key={c.id} value={String(c.id)}>
                    {c.handle}
                  </Option>
                ))}
              </Select>
            </Row>

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <p style={{ marginBottom: ".25rem" }}>
                  <b style={{ color: "#898989" }}>Tags:</b>
                </p>
                <SingleTagSelect
                  style={{ width: "100%", marginBottom: "1rem" }}
                  placeholder="Select a product Tags for this product"
                  value={product.type}
                  onChange={(value) => props.onFieldChange("type", value)}
                >
                  {props.types.map((type) => (
                    <Option key={type} value={type} className="type-select">
                      {type}
                    </Option>
                  ))}
                </SingleTagSelect>
              </Col>
              <Col xs={24} md={12}>
                <p style={{ marginBottom: ".25rem" }}>
                  <b style={{ color: "#898989" }}>Type:</b>
                </p>
                <Select
                  mode="tags"
                  style={{ width: "100%", marginBottom: "1rem" }}
                  optionFilterProp="children"
                  placeholder="Enter the Type for your product"
                  defaultValue={product.tags}
                  onChange={(value) => props.onFieldChange("tags", value)}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {props.tags.map((tag) => (
                    <Option key={tag} value={tag} className="tag-select">
                      {tag}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <p className="selling-price">
                  <b
                    style={{
                      color: "#898989",
                      BorderRadius: "0px",
                      marginBottom: "0.25rem",
                    }}
                  >
                    Suggested Selling Price:{" "}
                  </b>
                  <br />

                  <span
                    style={{
                      width: "100%",
                      display: "inline-block",
                      background: "#f5f5f5",
                      padding: "7px 8px",
                      height: "32px",
                      color: "#8080808c",
                      borderRadius: "2px",
                      textAlign: "center",
                      marginTop: "0.25rem",
                    }}
                  >
                    {props.store.store_geo !== "in" ? (
                      <>
                        {!product.vfprod.global_suggested_sellingprice ? (
                          <span
                            className="blue-text"
                            style={{ margin: "auto" }}
                          >
                            In Processing...
                          </span>
                        ) : (
                          <span href="#!" style={{ color: "#1C6513" }}>
                            {/* {getCurrencySymbol(
                              props.store.store_geo === "in" ? "INR" : "USD"
                            )}{" "}
                            {`${formatNumber(
                              product.vfprod.global_suggested_sellingprice.toFixed(
                                2
                              )
                            )}`} */}
                            {props.store.store_geo !== "in" &&
                            this.props.currencies.store_currency !== "USD"
                              ? getCurrencySymbol(
                                  props.currencies.store_currency
                                ) +
                                " " +
                                formatNumber(
                                  product.vfprod.global_suggested_sellingprice
                                )
                              : getCurrencySymbol(
                                  props.store.store_geo === "in" ? "INR" : "USD"
                                ) +
                                " " +
                                formatNumber(
                                  product.vfprod.global_suggested_sellingprice
                                )}
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        {!product.vfprod.suggested_sellingprice ? (
                          <span
                            className="blue-text"
                            style={{ margin: "auto" }}
                          >
                            In Processing...{" "}
                            {product.vfprod.suggested_sellingprice}
                          </span>
                        ) : (
                          <span href="#!" style={{ color: "#1C6513" }}>
                            {getCurrencySymbol(
                              props.store.store_geo === "in" ? "INR" : "USD"
                            )}{" "}
                            {`${formatNumber(
                              product.vfprod.suggested_sellingprice
                            )}`}
                          </span>
                        )}
                      </>
                    )}
                  </span>
                </p>
              </Col>

              <Col xs={24} md={12}>
                <p className="selling-price">
                  <b
                    style={{
                      color: "#898989",
                      marginRight: "4px",
                      BorderRadius: "0px",
                    }}
                  >
                    {(vfmrp || vfmrp.avg) &&
                    product.vfprod &&
                    product.vfprod.retail_pricing_discount
                      ? "Special Product Cost:"
                      : "Estimated Product Cost:"}
                  </b>

                  {this.tooltip()}

                  <span
                    className="special-cost-product-tooltip"
                    style={{
                      marginLeft: `${
                        product.vfprod && product.vfprod.retail_pricing_discount
                          ? `174px`
                          : `190px`
                      }`,
                      marginTop: "1px",
                    }}
                  >
                    <Tooltip
                      title={
                        "This price is inclusive of Product purchase price, Local & Cross Border freight charges & Custom duty. Price is exclusive of any taxes applicable."
                      }
                    >
                      <svg
                        className="product-tooltip-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 17"
                      >
                        <g data-name="Group 2529">
                          <g
                            transform="translate(0 1)"
                            fill="none"
                            stroke="#898989"
                          >
                            <circle cx="8" cy="8" r="8" stroke="none" />
                            <circle cx="8" cy="8" r="7.5" />
                          </g>
                          <text
                            transform="translate(6.5)"
                            fill="#898989"
                            fontSize="12"
                            fontFamily="Poppins-Medium, Poppins"
                            fontWeight="500"
                            letterSpacing=".032em"
                          >
                            <tspan x="0" y="13">
                              i
                            </tspan>
                          </text>
                        </g>
                      </svg>
                    </Tooltip>
                  </span>

                  <span
                    className="ff"
                    style={{
                      width: "100%",
                      background: "#f5f5f5",
                      height: "32px",
                      color: "#8080808c",
                      borderRadius: "2px",
                      textAlign: "center",
                      border:
                        (vfmrp || vfmrp.avg) &&
                        product.vfprod &&
                        product.vfprod.retail_pricing_discount
                          ? "2px solid red"
                          : "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "5px",
                    }}
                  >
                    {!vfmrp || !vfmrp.avg ? (
                      <span className="blue-text" style={{ margin: "auto" }}>
                        In Processing...
                      </span>
                    ) : (
                      <>
                        {product.vfprod &&
                        product.vfprod.retail_pricing_discount ? (
                          <span
                            className="ff2"
                            style={{
                              background: "#FD104D",
                              padding: "6px 8px 6px",
                              display: "inline-block",
                              color: "#fff",
                              fontSize: "12px",
                              marginLeft: -1,
                              minWidth: "27%",
                            }}
                          >
                            {product.vfprod &&
                              product.vfprod.retail_pricing_discount}
                            % off
                          </span>
                        ) : null}

                        <span
                          className="ff3"
                          style={{
                            padding: "6px 8px",
                            display: "inline-block",
                            fontSize: "12px",
                            textAlign: "center",
                            width: "100%",
                            color: "#898989",
                          }}
                        >
                          <React.Fragment>
                            {vfmrp.min !== vfmrp.max ? (
                              <Tooltip
                                title={`$ ${formatNumber(
                                  vfmrp.min
                                )} - $ ${formatNumber(vfmrp.max)}`}
                              >
                                <span
                                  className="ff6"
                                  style={{
                                    color: "#1c6513",
                                  }}
                                >
                                  {" "}
                                  {getCurrencySymbol(
                                    props.store.store_geo === "in"
                                      ? "INR"
                                      : "USD"
                                  )}{" "}
                                  {formatNumber(vfmrp2.avg)}
                                </span>
                              </Tooltip>
                            ) : (
                              <span
                                className="ff6"
                                style={{
                                  color: "#1c6513",
                                }}
                              >
                                {" "}
                                {getCurrencySymbol(
                                  props.store.store_geo === "in" ? "INR" : "USD"
                                )}{" "}
                                {formatNumber(vfmrp2.avg)}
                              </span>
                            )}
                          </React.Fragment>
                        </span>
                      </>
                    )}
                  </span>
                </p>
              </Col>
            </Row>
          </Col>

          <RequestLattestPrice
            store={this.props.store}
            product={this.props.product}
            responsemessage={this.state.responsemessage}
            status={this.state.status}
            requestUpdatedQuotation={() => this.requestUpdatedQuotation()}
            loading={this.state.loading}
          />

          <RejectedMessage
            escalated={
              this.props.product && this.props.product.escalated
                ? this.props.product.escalated
                : null
            }
            messageStatus={messageStatus}
            rejected={() => this.rejectedproduct()}
            messagedata={this.state.messagedata}
            rejectedMessage={this.state.rejectedMessage}
            loading={this.state.loading}
            vfRemark={this.props.product && this.props.product.reject_partner_remarks?this.props.product.reject_partner_remarks:""}
          />
          <EscalatedMessage
            messageStatus={messageStatus}
            escalated={() => this.escalated()}
            messagedata={this.state.messagedata}
            loading={this.state.loading}
          />
          <RemindedMessage
            messageStatus={messageStatus}
            reminder={() => this.reminder()}
            messagedata={this.state.messagedata}
            loading={this.state.loading}
          />

          <DefaultMessage messageStatus={messageStatus} />

          <StoreConnected
            addStoreModal={() => this.addStoreHandler()}
            style={{ width: "100%" }}
            messageStatus={messageStatus}
            messagedata={this.state.messagedata}
          />

          <StoreConnected2
            addStoreModal={() => this.props.importToStore()}
            style={{ width: "100%" }}
            messageStatus={messageStatus}
          />

          {!!this.state.addStoreModal && (
            <AddStoreModal
              storeid={this.props.product.product_storeid.$oid}
              visible={this.state.addStoreModal}
              handleCancel={() => this.toggleAddStoreModal()}
              alignButtonsRight={true}
              user={this.props.auth.user}
              history={this.props.history}
            />
          )}

          {messageStatus === "default" && <p />}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};
export default connect(mapStateToProps)(ImportListProductBasic);
