import React, { Component } from "react";
import { Icon, message, Collapse, Button, Modal } from "antd";
import { connect } from "react-redux";
import { ReactComponent as roketIcon } from "../../../../assets/images/icons/roketStart.svg";
import { ReactComponent as completeIcon } from "../../../../assets/images/icons/complete-profile.svg";
import { ReactComponent as orderIcon } from "../../../../assets/images/icons/preparing_order.svg";
import { ReactComponent as storeIcon } from "../../../../assets/images/icons/store-setting.svg";
import jennyImage from "../../../../assets/images/icons/jenny.png";
import {
  SHOPIFY_NAME,
  SHOPIFY_CLIENT_ID,
  BASE_URL,
} from "../../../../constants";
import { ReactComponent as Info } from "../../../../assets/images/icons/red-info.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import "./index.scss";
const { Panel } = Collapse;

class NextSteps extends Component {
  state = {
    stateCounter: this.props.stateCounter === true ? 1 : 0,
    stepsModalVisible: false,
    resendingEmail: false,
  };

  toggleStepsModal = () => {
    this.setState({
      stepsModalVisible: !this.state.stepsModalVisible,
    });
  };

  sendVerificationEmail = () => {
    if (this.state.resendingEmail) return;
    this.setState(
      {
        resendingEmail: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/resend_activation_email",
          method: "post",
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                resendingEmail: false,
              });
              message.success("Verification Email sent successfully!");
            } else {
              this.setState({
                resendingEmail: false,
              });
              message.error(
                "There was an error sending the verification email!"
              );
            }
          })
          .catch((e) => {
            this.setState({
              resendingEmail: false,
            });
            message.error(e.message);
          });
      }
    );
  };

  discover() {
    axios({
      url: process.env.REACT_APP_API_URL + "/user/discover_product_set",
      method: "post",
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          message.success("Discovered");
          window.location.assign(
            `/${this.props.auth.store.store_geo}/${
              this.props.match.params.storeid
            }/products/search-products`
          );
        } else {
          message.error("There was an error");
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  }

  render() {
    const steps = {};
    this.props.steps.forEach((s) => {
      steps[s.id] = s.completed;
    });

    // if (
    //   this.props.steps.length === Object.values(steps).filter((s) => s).length
    // ) {
    //   return null;
    // }

    const header_1 = () => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          color:
            Number(steps["EMAIL_VERIFIED"]) +
              Number(steps["PROFILE_COMPLETED"]) ===
              2 && "white",
        }}
      >
        <Icon
          component={roketIcon}
          style={{
            color:
              Number(steps["EMAIL_VERIFIED"]) +
                Number(steps["PROFILE_COMPLETED"]) ===
                2 && "white",
          }}
        />{" "}
        <span>Getting Started</span>
      </div>
    );

    const header_2 = () => (
      <div
        // style={{
        //   display: "flex",
        //   alignItems: "center",
        //   color:
        //     Number(steps["KYC"]) +
        //       Number(steps["BANK"]) +
        //       Number(steps["DOCS"]) ===
        //       3 && "white",
        // }}

        style={{
          display: "flex",
          alignItems: "center",
          color: Number(steps["KYC"]) + Number(steps["BANK"]) === 2 && "white",
        }}
      >
        <Icon component={completeIcon} /> <span>Complete Your Profile</span>
      </div>
    );

    const header_3 = () => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          color:
            this.props.stateCounter === true
              ? Number(steps["STORE_CONNECTED"]) +
                  1 +
                  Number(steps["ADD_FUNDS"]) +
                  Number(steps["STORE_GEO_SET"]) ===
                  3 && "white"
              : Number(steps["STORE_CONNECTED"]) +
                  Number(steps["ADD_FUNDS"]) +
                  Number(steps["STORE_GEO_SET"]) ===
                  3 && "white",
        }}
      >
        <Icon component={orderIcon} /> <span>Preparing for orders</span>
      </div>
    );

    const header_4 = () => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          color:
            Number(steps["CONTACT_CENTER"]) +
              Number(steps["CONFIGURE_SETTINGS"]) +
              Number(steps["BUILD_PRODUCT_LIST"]) +
              Number(steps["DISCOVER_PRODUCTS"] === true ? 1 : 0) ===
              4 && "white",
        }}
      >
        <Icon component={storeIcon} /> <span>Setting up your store</span>
      </div>
    );

    const right_1 = () => (
      <div
        style={{
          color:
            Number(steps["EMAIL_VERIFIED"]) +
              Number(steps["PROFILE_COMPLETED"]) ===
              2 && "white",
        }}
      >
        <span
          className="step-complete"
          style={{
            color:
              Number(steps["EMAIL_VERIFIED"]) +
                Number(steps["PROFILE_COMPLETED"]) ===
                2 && "#bcf9b8",
          }}
        >
          {Number(steps["EMAIL_VERIFIED"]) + Number(steps["PROFILE_COMPLETED"])}
          /2
        </span>{" "}
        <span style={{ fontSize: ".75rem", fontWeight: "400" }}>
          Steps Completed
        </span>
      </div>
    );
    const right_2 = () => (
      <div
        // style={{
        //   color:
        //     Number(steps["KYC"]) +
        //       Number(steps["BANK"]) +
        //       Number(steps["DOCS"]) ===
        //       3 && "white",
        // }}

        style={{
          color: Number(steps["KYC"]) + Number(steps["BANK"]) === 2 && "white",
        }}
      >
        <span
          className="step-complete"
          // style={{
          //   color:
          //     Number(steps["KYC"]) +
          //       Number(steps["BANK"]) +
          //       Number(steps["DOCS"]) ===
          //       3 && "#bcf9b8",
          // }}

          style={{
            color:
              Number(steps["KYC"]) + Number(steps["BANK"]) === 2 && "#bcf9b8",
          }}
        >
          {/* {Number(steps["KYC"]) + Number(steps["BANK"]) + Number(steps["DOCS"])}
          /3 */}
          {Number(steps["KYC"]) + Number(steps["BANK"])}
          /2
        </span>{" "}
        <span style={{ fontSize: ".75rem", fontWeight: "400" }}>
          Steps Completed
        </span>
      </div>
    );

    const right_3 = () => (
      <div
        style={{
          color:
            this.props.stateCounter === true
              ? Number(steps["STORE_CONNECTED"]) +
                  1 +
                  Number(steps["ADD_FUNDS"]) +
                  Number(steps["STORE_GEO_SET"]) ===
                  3 && "white"
              : Number(steps["STORE_CONNECTED"]) +
                  Number(steps["ADD_FUNDS"]) +
                  Number(steps["STORE_GEO_SET"]) ===
                  3 && "white",
        }}
      >
        <span
          className="step-complete"
          style={{
            color:
              this.props.stateCounter === true
                ? Number(steps["STORE_CONNECTED"]) +
                    Number(steps["ADD_FUNDS"]) +
                    1 +
                    Number(steps["STORE_GEO_SET"]) ===
                    3 && "white"
                : Number(steps["STORE_CONNECTED"]) +
                    Number(steps["ADD_FUNDS"]) +
                    Number(steps["STORE_GEO_SET"]) ===
                    3 && "#bcf9b8",
          }}
        >
          {this.props.stateCounter === true
            ? Number(steps["STORE_CONNECTED"]) +
              Number(steps["ADD_FUNDS"]) +
              1 +
              Number(steps["STORE_GEO_SET"])
            : Number(steps["STORE_CONNECTED"]) +
              Number(steps["ADD_FUNDS"]) +
              Number(steps["STORE_GEO_SET"])}
          /3
        </span>{" "}
        <span style={{ fontSize: ".75rem", fontWeight: "400" }}>
          Steps Completed
        </span>
      </div>
    );

    const right_4 = () => (
      <div
        style={{
          color:
            Number(steps["CONTACT_CENTER"]) +
              Number(steps["BUILD_PRODUCT_LIST"]) +
              Number(steps["DISCOVER_PRODUCTS"] === true ? 1 : 0) +
              Number(steps["CONFIGURE_SETTINGS"]) ===
              4 && "white",
        }}
      >
        <span
          className="step-complete"
          style={{
            color:
              Number(steps["CONTACT_CENTER"]) +
                Number(steps["BUILD_PRODUCT_LIST"]) +
                Number(steps["DISCOVER_PRODUCTS"] === true ? 1 : 0) +
                Number(steps["CONFIGURE_SETTINGS"]) ===
                4 && "#bcf9b8",
          }}
        >
          {Number(steps["CONTACT_CENTER"]) +
            Number(steps["BUILD_PRODUCT_LIST"]) +
            Number(steps["DISCOVER_PRODUCTS"] === true ? 1 : 0) +
            Number(steps["CONFIGURE_SETTINGS"])}
          /4{" "}
        </span>

        <span style={{ fontSize: ".75rem", fontWeight: "400" }}>
          Steps Completed
        </span>
      </div>
    );

    const global_header3 = () => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          color:
            this.props.stateCounter === true
              ? Number(steps["STORE_CONNECTED"]) +
                  1 +
                  Number(steps["CONFIGURE_SETTINGS"]) ===
                  2 && "white"
              : Number(steps["STORE_CONNECTED"]) +
                  Number(steps["CONFIGURE_SETTINGS"]) ===
                  2 && "white",
        }}
      >
        <Icon component={orderIcon} /> <span>Preparing for orders</span>
      </div>
    );

    const globa_right_3 = () => (
      <div
        style={{
          color:
            this.props.stateCounter === true
              ? Number(steps["STORE_CONNECTED"]) +
                  1 +
                  Number(steps["CONFIGURE_SETTINGS"]) ===
                  2 && "white"
              : Number(steps["STORE_CONNECTED"]) +
                  Number(steps["CONFIGURE_SETTINGS"]) ===
                  2 && "white",
        }}
      >
        <span
          className="step-complete"
          style={{
            color:
              this.props.stateCounter === true
                ? Number(steps["STORE_CONNECTED"]) +
                    1 +
                    Number(steps["CONFIGURE_SETTINGS"]) ===
                    2 && "white"
                : Number(steps["STORE_CONNECTED"]) +
                    Number(steps["CONFIGURE_SETTINGS"]) ===
                    2 && "#bcf9b8",
          }}
        >
          {this.props.stateCounter === true
            ? Number(steps["STORE_CONNECTED"]) +
              1 +
              Number(steps["CONFIGURE_SETTINGS"])
            : Number(steps["STORE_CONNECTED"]) +
              Number(steps["CONFIGURE_SETTINGS"])}
          /2
        </span>{" "}
        <span style={{ fontSize: ".75rem", fontWeight: "400" }}>
          Steps Completed
        </span>
      </div>
    );

    const geo = this.props.auth.store.store_geo;

    return (
      <div className="next-steps-card">
        {this.props.app_uninstall ? (
          <div className="notification-app-reinstall">
            <Info />
            <div className="ic-wr">
              <h3>Shopify Store Disconnected!</h3>
              <p>
                It seems like vFulfill App was uninstalled from your Shopify
                Store. Please click on the following
                <a
                  href={
                    `https://${
                      this.props.auth.store.store_shopifyadmin
                    }/admin/oauth/authorize?client_id=${SHOPIFY_CLIENT_ID}&scope=read_product_listings,read_products,write_products,read_orders,write_orders,read_inventory,write_customers,write_inventory,write_price_rules,read_draft_orders,write_draft_orders,read_customers,write_customers,read_checkouts,write_checkouts,read_fulfillments,read_merchant_managed_fulfillment_orders,write_merchant_managed_fulfillment_orders,read_assigned_fulfillment_orders,write_assigned_fulfillment_orders,read_third_party_fulfillment_orders,write_third_party_fulfillment_orders,write_fulfillments&redirect_uri=` +
                    BASE_URL +
                    `login/shopify_callback&state=${this.props.auth.store.id}`
                  }
                >
                  link
                </a>
                to reconnect it.
              </p>
            </div>
          </div>
        ) : (
          <>
            <div className="quick-start">
              <div className="left-quick-start">
                <p>Your Quick Start Guide</p>
              </div>
              <div className="right-quick-start">
                <div className="quick-start-jenny">
                  <h3>Hi! I’m Julie. Here to help.</h3>
                  <p>
                    Let’s get started and set up vFulfill for you. In case you
                    need something let me know below in the chat.
                  </p>
                </div>
                <img src={jennyImage} alt="" />
              </div>
            </div>

            <Collapse bordered={false} accordion={true}>
              <Panel
                header={header_1()}
                key="step_1"
                showArrow={false}
                extra={right_1()}
                style={{
                  backgroundImage:
                    Number(steps["EMAIL_VERIFIED"]) +
                      Number(steps["PROFILE_COMPLETED"]) ===
                      2 && "linear-gradient(to right, #62D561 , #38CE35)",
                }}
              >
                <div className="panel-content">
                  <div className="left-content">
                    <h3>Verify Your Details</h3>
                    <p>
                      Verify your details to start using vFulfill uninterrupted
                    </p>
                  </div>
                  <div className="right-content">
                    <Icon
                      type="check-circle"
                      theme="filled"
                      style={{ color: "#67d367" }}
                    />{" "}
                    Completed
                  </div>
                </div>

                <div className="panel-content">
                  <div className="left-content">
                    <h3>Few questions for us to personalize your experience</h3>
                    <p>
                      We want to give you a personalised experience smoother as
                      it can be. For that, we need to ask you a few questions.
                    </p>
                  </div>
                  <div className="right-content">
                    {steps["PROFILE_COMPLETED"] ? (
                      <div className="right-content">
                        <Icon
                          type="check-circle"
                          theme="filled"
                          style={{ color: "#67d367" }}
                        />{" "}
                        Completed
                      </div>
                    ) : (
                      <Link
                        to={`/onboarding?=email_verified`}
                        className="ant-btn clickable-dropdown"
                      >
                        Continue
                      </Link>
                    )}
                  </div>
                </div>
              </Panel>

              <Panel
                header={header_2()}
                key="step_2"
                showArrow={false}
                extra={right_2()}
                style={{
                  backgroundImage:
                    Number(steps["KYC"]) + Number(steps["BANK"]) === 2 &&
                    "linear-gradient(to right, #62D561 , #38CE35)",
                }}
              >
                <div className="panel-content">
                  <div className="left-content">
                    <h3>Let us know more about you</h3>
                    <p>Fill in or review your necessary basic details.</p>
                  </div>
                  <div className="right-content">
                    {steps["KYC"] ? (
                      <div className="right-content">
                        <Icon
                          type="check-circle"
                          theme="filled"
                          style={{ color: "#67d367" }}
                        />{" "}
                        Completed
                      </div>
                    ) : (
                      <Link
                        to={`/${this.props.match.params.storeid}/onboarding`}
                        className="ant-btn clickable-dropdown"
                      >
                        Continue
                      </Link>
                    )}
                  </div>
                </div>

                <div className="panel-content">
                  <div className="left-content">
                    <h3>Add your banking data</h3>
                    <p>
                      Time to work on some legalities. Enter your banking
                      details to begin setting up a wallet in vFulfill.
                    </p>
                  </div>
                  <div className="right-content">
                    {steps["BANK"] ? (
                      <div className="right-content">
                        <Icon
                          type="check-circle"
                          theme="filled"
                          style={{ color: "#67d367" }}
                        />{" "}
                        Completed
                      </div>
                    ) : (
                      <Link
                        to={`/${this.props.match.params.storeid}/onboarding`}
                        className="ant-btn clickable-dropdown"
                      >
                        Add Banking Data
                      </Link>
                    )}
                  </div>
                </div>
              </Panel>

              <Panel
                header={
                  this.props.auth.store.store_geo === "global"
                    ? global_header3()
                    : header_3()
                }
                key="step_3"
                showArrow={false}
                extra={
                  this.props.auth.store.store_geo === "global"
                    ? globa_right_3()
                    : right_3()
                }
                style={{
                  backgroundImage:
                    this.props.auth.store.store_geo === "global"
                      ? this.props.stateCounter === true
                        ? Number(steps["STORE_CONNECTED"]) +
                            1 +
                            Number(steps["ADD_FUNDS"]) ===
                            2 && "linear-gradient(to right, #62D561 , #38CE35)"
                        : Number(steps["STORE_CONNECTED"]) +
                            Number(steps["ADD_FUNDS"]) ===
                            2 && "linear-gradient(to right, #62D561 , #38CE35)"
                      : this.props.stateCounter === true
                      ? Number(steps["STORE_CONNECTED"]) +
                          Number(steps["STORE_GEO_SET"]) +
                          1 +
                          Number(steps["ADD_FUNDS"]) ===
                          3 && "linear-gradient(to right, #62D561 , #38CE35)"
                      : Number(steps["STORE_CONNECTED"]) +
                          Number(steps["STORE_GEO_SET"]) +
                          Number(steps["ADD_FUNDS"]) ===
                          3 && "linear-gradient(to right, #62D561 , #38CE35)",
                }}
              >
                <div className="panel-content">
                  <div className="left-content">
                    <h3>{`Connect your ${SHOPIFY_NAME}`}</h3>
                    <p>
                      {`Connect your existing ${SHOPIFY_NAME} with vFulfill dashboard to
                  start managing it`}
                    </p>
                  </div>
                  <div className="right-content">
                    {steps["STORE_CONNECTED"] ? (
                      <div className="right-content">
                        <Icon
                          type="check-circle"
                          theme="filled"
                          style={{ color: "#67d367" }}
                        />{" "}
                        Connected
                      </div>
                    ) : (
                      <Button
                        className="clickable-dropdown"
                        onClick={() => this.props.toggleShopifyModal()}
                      >
                        Connect Store
                      </Button>
                    )}
                  </div>
                </div>

                {/* Add Funds */}

                <div className="panel-content">
                  <div className="left-content">
                    <h3>Add Funds to Back Order to vFulfill</h3>
                    <p>
                      Set up your funds and wallet to be able to order products
                      from vFulfill.
                    </p>
                  </div>
                  <div className="right-content">
                    {steps["ADD_FUNDS"] ? (
                      <div className="right-content">
                        <Icon
                          type="check-circle"
                          theme="filled"
                          style={{ color: "#67d367" }}
                        />{" "}
                        Completed
                      </div>
                    ) : (
                      <Link
                        className="ant-btn clickable-dropdown"
                        to={`/${geo}/my-wallet`}
                      >
                        Add Funds
                      </Link>
                    )}
                  </div>
                </div>

                {/* Set Store Geo */}
                <div className="panel-content">
                  <div className="left-content">
                    <h3>Set Your Store Geo</h3>
                    <p>
                      Manage your store geo, it is geography you are selling
                      with vFulfill.
                    </p>
                  </div>
                  <div className="right-content ">
                    {steps["STORE_GEO_SET"] ? (
                      <div
                        className={
                          this.props.setstoregeo === "in"
                            ? "right-content india-txt"
                            : "right-content global-txt"
                        }
                      >
                        <Icon
                          type="check-circle"
                          theme="filled"
                          style={{ color: "#67d367" }}
                        />{" "}
                        {this.props.setstoregeo === "in" ? "India" : "Global"}
                      </div>
                    ) : (
                      <Link
                        className="ant-btn clickable-dropdown"
                        to={`/manage-stores`}
                      >
                        Set Store Geo
                      </Link>
                    )}
                  </div>
                </div>
              </Panel>

              <Panel
                header={header_4()}
                key="step_4"
                showArrow={false}
                extra={right_4()}
                style={{
                  backgroundImage:
                    Number(steps["CONTACT_CENTER"]) +
                      Number(steps["BUILD_PRODUCT_LIST"]) +
                      Number(steps["DISCOVER_PRODUCTS"] === true ? 1 : 0) +
                      Number(steps["CONFIGURE_SETTINGS"]) ===
                      4 && "linear-gradient(to right, #62D561 , #38CE35)",
                }}
              >
                {" "}
                <div className="panel-content">
                  <div className="left-content">
                    <h3>Review app preferences</h3>
                    <p>
                      Get cosy with the vFulfill app and tune settings to your
                      preferred way.
                    </p>
                  </div>
                  <div className="right-content">
                    {steps["CONFIGURE_SETTINGS"] ? (
                      <div className="right-content">
                        <Icon
                          type="check-circle"
                          theme="filled"
                          style={{ color: "#67d367" }}
                        />{" "}
                        Configured
                      </div>
                    ) : (
                      <Link
                        className="ant-btn clickable-dropdown"
                        to={`/${geo}/${
                          this.props.match.params.storeid
                        }/settings`}
                      >
                        Review App Preferences
                      </Link>
                    )}
                  </div>
                </div>
                {/* Product Research */}
                <div className="panel-content">
                  <div className="left-content">
                    <h3>Product Research</h3>
                    <p>
                      Checkout our catalog and discover “New & Hot Selling”
                      products, researched and added by vFulfill Product
                      Research team daily.
                    </p>
                  </div>
                  <div className="right-content">
                    {steps["DISCOVER_PRODUCTS"] === true ? (
                      <div className="right-content">
                        <Icon
                          type="check-circle"
                          theme="filled"
                          style={{ color: "#67d367" }}
                        />{" "}
                        Completed
                      </div>
                    ) : (
                      <span
                        onClick={() => {
                          this.discover();
                        }}
                        className="ant-btn clickable-dropdown"
                      >
                        Product Research
                      </span>
                    )}
                  </div>
                </div>
                {/* Import Products to your Store */}
                <div className="panel-content">
                  <div className="left-content">
                    <h3>Import Products to your Store</h3>
                    <p>
                      It is time to start dropshipping! Begin your amazing
                      journey with us by starting product imports to your
                      store..
                    </p>
                  </div>
                  <div className="right-content">
                    {steps["BUILD_PRODUCT_LIST"] ? (
                      <div className="right-content">
                        <Icon
                          type="check-circle"
                          theme="filled"
                          style={{ color: "#67d367" }}
                        />{" "}
                        Completed
                      </div>
                    ) : (
                      <Link
                        className="ant-btn clickable-dropdown"
                        to={`/${geo}/${
                          this.props.match.params.storeid
                        }/products/import-list`}
                      >
                        Add Products
                      </Link>
                    )}
                  </div>
                </div>
                {/* contact center */}
                {this.props.auth.store.store_geo === "global" ? null : (
                  <div className="panel-content">
                    <div className="left-content">
                      <h3>Review contact center settings</h3>
                      <p>
                        Steps to guide you how to enable contact center services
                      </p>
                    </div>
                    <div className="right-content">
                      {steps["CONTACT_CENTER"] || this.props.stateCounter ? (
                        <div className="right-content">
                          <Icon
                            type="check-circle"
                            theme="filled"
                            style={{ color: "#67d367" }}
                          />{" "}
                          Reviewed
                        </div>
                      ) : (
                        <>
                          <Link
                            onClick={() => this.props.enableModal()}
                            className="ant-btn clickable-dropdown"
                            to={`/${geo}/${
                              this.props.match.params.storeid
                            }/contact-center`}
                          >
                            Contact Center Settings
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </Panel>
            </Collapse>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(NextSteps);
