import React, { Component } from "react";
import { Button, message, Card } from "antd";
import { connect } from "react-redux";
import axios from "axios";
import SupportPhoneNumber from "./OtpVerification/Phonemodal";
import SupportEmail from "./OtpVerification/EmailModal";
import "./index.scss";

class VerifyEmail extends Component {
  state = {
    updateEmailModal: {
      supportemail:
        this.props.auth.user && this.props.auth.user.user_email
          ? this.props.auth.user.user_email
          : "",
      loading: false,
    },
    email: this.props.auth.user.user_email,
    loading: false,

    contactno: "",
    prefix_1: !!this.props.user_contact_ext ? this.props.user_contact_ext : "",
    emailotp: {
      emailotp1: "",
      emailotp2: "",
      emailotp3: "",
      emailotp4: "",
      emailotp5: "",
      emailotp6: "",
    },
    phoneotp: {
      phoneotp1: "",
      phoneotp2: "",
      phoneotp3: "",
      phoneotp4: "",
      phoneotp5: "",
      phoneotp6: "",
    },
    saveChangeEmail: false,
    chnagenumber: false,
  };

  // paste function

  pasteFunc(ev) {
    const clip = ev.clipboardData.getData("text"); // Get clipboard data
    const pin = clip.replace(/\s/g, ""); // Sanitize string
    const ch = [...pin]; // Create array of chars

    if (ch.length === 6) {
      this.setState({
        phoneotp: {
          phoneotp1: ch[0],
          phoneotp2: ch[1],
          phoneotp3: ch[2],
          phoneotp4: ch[3],
          phoneotp5: ch[4],
          phoneotp6: ch[5],
        },
      });
    }
  }

  pasteFuncEmail(ev) {
    const clip = ev.clipboardData.getData("text"); // Get clipboard data
    const pin = clip.replace(/\s/g, ""); // Sanitize string
    const ch = [...pin]; // Create array of chars

    if (ch.length === 6) {
      this.setState({
        emailotp: {
          emailotp1: ch[0],
          emailotp2: ch[1],
          emailotp3: ch[2],
          emailotp4: ch[3],
          emailotp5: ch[4],
          emailotp6: ch[5],
        },
      });
    }
  }

  changePrefixState = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  togglesaveChangeEmail() {
    this.setState({
      saveChangeEmail: !this.state.saveChangeEmail,
    });
  }

  ChnageNumber() {
    this.setState({
      chnagenumber: !this.state.chnagenumber,
    });
  }

  handleEmailChange(value) {
    this.setState({
      updateEmailModal: {
        ...this.state.updateEmailModal,
        supportemail: value,
      },
    });
  }

  handleChange(value1, event) {
    this.setState({
      emailotp: {
        ...this.state.emailotp,
        [value1]: event.target.value,
      },
    });
  }
  // handleContactChange(value) {
  //   this.setState({
  //     contactno: value,
  //   });
  // }

  handleContactChange(ob) {
    let onlyNumber = ob.value
      .replace(/[^0-9]+/g, "")
      .slice(
        !!Object.entries(ob.extraVal).length ? ob.extraVal.dialCode.length : 0
      );

    if (ob.value === 0 && ob.value === "") {
      this.setState({
        contactno: "",
        prefix_1: "",
      });
    } else if (parseInt(ob.value)) {
      this.setState({
        contactno: onlyNumber,
        prefix_1: ob.extraVal.dialCode,
      });
    }
  }

  toggleChnageNumber(value1, event) {
    this.setState({
      phoneotp: {
        ...this.state.phoneotp,
        [value1]: event.target.value,
      },
    });
  }

  verifyContinue = () => {
    let data = {};
    if (this.props.contact_verified === 0 && this.props.user_activated === 0) {
      data = {
        mobile: {
          code: this.state.prefix_1.includes("+")
            ? this.state.prefix_1.split("+")[1]
            : this.state.prefix_1,
          number: this.state.contactno,
          otp: Number(
            this.state.phoneotp.phoneotp1 +
              this.state.phoneotp.phoneotp2 +
              this.state.phoneotp.phoneotp3 +
              this.state.phoneotp.phoneotp4 +
              this.state.phoneotp.phoneotp5 +
              this.state.phoneotp.phoneotp6
          ),
        },

        email: {
          user_email: this.state.email,
          otp: Number(
            this.state.emailotp.emailotp1 +
              this.state.emailotp.emailotp2 +
              this.state.emailotp.emailotp3 +
              this.state.emailotp.emailotp4 +
              this.state.emailotp.emailotp5 +
              this.state.emailotp.emailotp6
          ),
        },
      };
    } else if (this.props.contact_verified === 0) {
      data = {
        mobile: {
          code: this.state.prefix_1.includes("+")
            ? this.state.prefix_1.split("+")[1]
            : this.state.prefix_1,
          number: this.state.contactno,
          otp: Number(
            this.state.phoneotp.phoneotp1 +
              this.state.phoneotp.phoneotp2 +
              this.state.phoneotp.phoneotp3 +
              this.state.phoneotp.phoneotp4 +
              this.state.phoneotp.phoneotp5 +
              this.state.phoneotp.phoneotp6
          ),
        },
      };
    } else if (this.props.user_activated === 0) {
      data = {
        email: {
          user_email: this.state.email,
          otp: Number(
            this.state.emailotp.emailotp1 +
              this.state.emailotp.emailotp2 +
              this.state.emailotp.emailotp3 +
              this.state.emailotp.emailotp4 +
              this.state.emailotp.emailotp5 +
              this.state.emailotp.emailotp6
          ),
        },
      };
    }

    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/verify_otp",
          method: "post",
          withCredentials: true,

          data: {
            ...data,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
              });
              message.success(res.data.msg);
              this.props.goToNextStep();
            } else {
              this.setState({
                loading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
            message.error(e.message);
          });
      }
    );
  };

  updatePhonenumber() {
    this.setState(
      {
        loading: true,
      },

      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/update_contact_no",
          method: "post",
          data: {
            phone: {
              code: this.state.prefix_1.includes("+")
                ? this.state.prefix_1.split("+")[1]
                : this.state.prefix_1,
              number: this.state.contactno,
            },
          },
          withCredentials: true,
        })
          .then((res) => {
            this.setState({
              ...this.state.contactno,
            });
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                ...this.state.contactno,
                ...this.state.prefix_1,
                loading: false,
              });
              message.success("Phone number has been updated!");
              this.ChnageNumber();
            } else {
              this.setState({
                ...this.state.contactno,
                ...this.state.prefix_1,
                loading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
            this.setState({
              ...this.state.contactno,
              ...this.state.prefix_1,
              loading: false,
            });
          });
      }
    );
  }

  updateEmail() {
    if (
      this.state.updateEmailModal.supportemail.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      this.setState(
        (state) => ({
          updateEmailModal: {
            ...state.updateEmailModal,
            loading: true,
          },
        }),
        () => {
          axios({
            url: process.env.REACT_APP_API_URL + "/user/update_email_address",
            method: "post",
            data: {
              email: this.state.updateEmailModal.supportemail,
            },
            withCredentials: true,
          })
            .then((res) => {
              this.setState((state) => ({
                updateEmailModal: {
                  ...state.updateEmailModal,
                  loading: false,
                },
              }));
              return res;
            })
            .then((res) => {
              if (res.data.success === 1) {
                try {
                  const user = JSON.parse(localStorage.getItem("user"));
                  user.user_email = this.state.updateEmailModal.supportemail;
                  localStorage.setItem("user", JSON.stringify(user));
                } catch (e) {}

                this.setState({
                  updateEmailModal: {
                    supportemail: this.state.updateEmailModal.supportemail,
                    loading: false,
                  },
                  email: this.state.updateEmailModal.supportemail,
                });
                this.togglesaveChangeEmail();
                message.success("OTP has been sent on your updated email.");
              } else {
                this.setState({
                  updatEmailModal: {
                    ...this.state.updateEmailModal,
                    loading: false,
                  },
                });
                message.error(res.data.msg);
              }
            })
            .catch((e) => {
              message.error(e.message);
              this.setState({
                updatEmailModal: {
                  ...this.state.updateEmailModal,
                  loading: false,
                },
              });
            });
        }
      );
    } else {
      message.error("Please enter a valid Email Address.");
    }
  }

  // onChangeEmailModalField = (k, v) => {
  //   this.setState((state) => ({
  //     updateEmailModal: {
  //       ...state.updateEmailModal,
  //       [k]: v,
  //     },
  //   }));
  // };

  verificationPoll = () => {
    axios({
      url: process.env.REACT_APP_API_URL + "/user/generateotp",
      method: "post",
      withCredentials: true,
    }).then((res) => {
      if (res.data.success === 1) {
        // message.success(
        //   "Verification OTP Has been sent to Email and Phone Number."
        // );

        message.success(res.data.msg);
      }
    });
  };

  // verificationPoll = () => {
  //   axios({
  //     url: process.env.REACT_APP_API_URL + "/user/get_profile",
  //     method: "post",
  //     withCredentials: true,
  //   })
  //     .then((res) => {
  //       if (res.data.profile && res.data.profile.user_activated === 1) {
  //         this.props.goToNextStep();
  //         this.props.getphonenumber(res.data.profile.user_contact);
  //       } else {
  //         setTimeout(() => this.verificationPoll(), 3000);
  //       }
  //     })
  //     .catch((e) => setTimeout(() => this.verificationPoll(), 3000));
  // };

  componentDidMount() {
    this.verificationPoll();
  }

  componentDidUpdate(pp) {
    if (pp.profile !== this.props.profile) {
      this.setState({
        contactno: this.props.profile.user_contact,
        prefix_1:
          this.props.profile && this.props.profile.user_contact_ext === null
            ? ""
            : this.props.profile.user_contact_ext,
      });
    }
  }

  resendVerificationEmail = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/resend_activation_email",
          method: "post",
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
              });
              message.success("Verification Email sent successfully!");
            } else {
              this.setState({
                loading: false,
              });
              message.error(
                "There was an error sending the verification email!"
              );
            }
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
            message.error(e.message);
          });
      }
    );
  };

  render() {
    const PhoneVerify =
      !!this.state.phoneotp.phoneotp1.length &&
      !!this.state.phoneotp.phoneotp2.length &&
      !!this.state.phoneotp.phoneotp3.length &&
      !!this.state.phoneotp.phoneotp4.length &&
      !!this.state.phoneotp.phoneotp5.length &&
      !!this.state.phoneotp.phoneotp6.length;

    const emailVefrify =
      !!this.state.emailotp.emailotp1.length &&
      !!this.state.emailotp.emailotp2.length &&
      !!this.state.emailotp.emailotp3.length &&
      !!this.state.emailotp.emailotp4.length &&
      !!this.state.emailotp.emailotp5.length &&
      !!this.state.emailotp.emailotp6.length;

    return (
      <div>
        <div className="otp-verification-form">
          {this.props.contact_verified === 0 &&
          this.props.user_activated === 0 ? (
            <Card>
              <div className="header-txt">
                <p className="account-txt">Let Us Set Up Your Account</p>
                <p className="verify-emai-mobile-txt">
                  Verify your Mobile Number & Email
                </p>
              </div>
              <SupportPhoneNumber
                pasteFunc={(e) => this.pasteFunc(e)}
                toggleChnageNumber={(value, event) =>
                  this.toggleChnageNumber(value, event)
                }
                phoneotp={this.state.phoneotp}
                handleContactChange={(value) => this.handleContactChange(value)}
                contactno={this.state.contactno}
                chnagenumber={this.state.chnagenumber}
                ChnageNumbertoggle={() => this.ChnageNumber()}
                updatePhonenumber={() => this.updatePhonenumber()}
                changePrefixState={(field, value) =>
                  this.changePrefixState(field, value)
                }
                prefix_1={this.state.prefix_1}
                loading={this.state.loading}
              />

              <SupportEmail
                pasteFuncEmail={(e) => this.pasteFuncEmail(e)}
                email={this.state.updateEmailModal.supportemail}
                handleChange={(value, event) => this.handleChange(value, event)}
                emailotp={this.state.emailotp}
                handleEmailChange={(value) => this.handleEmailChange(value)}
                togglesaveChangeEmail={() => this.togglesaveChangeEmail()}
                saveChangeEmail={this.state.saveChangeEmail}
                updateEmail={() => this.updateEmail()}
                email2={this.state.email}
                loading={this.state.updateEmailModal.loading}
              />

              <Button
                className={
                  PhoneVerify && emailVefrify
                    ? "verify-continue-btn"
                    : "verify-continue-btn2"
                }
                disabled={!PhoneVerify && !emailVefrify}
                onClick={() => this.verifyContinue()}
                loading={this.state.loading}
              >
                Verify & Continue
              </Button>
            </Card>
          ) : this.props.contact_verified === 0 ? (
            <Card>
              <div className="header-txt">
                <p className="account-txt">Let Us Set Up Your Account</p>
                <p className="verify-emai-mobile-txt">
                  Verify your Mobile Number
                </p>
              </div>
              <SupportPhoneNumber
                toggleChnageNumber={(value, event) =>
                  this.toggleChnageNumber(value, event)
                }
                pasteFunc={(e) => this.pasteFunc(e)}
                phoneotp={this.state.phoneotp}
                handleContactChange={(value) => this.handleContactChange(value)}
                contactno={this.state.contactno}
                chnagenumber={this.state.chnagenumber}
                ChnageNumbertoggle={() => this.ChnageNumber()}
                updatePhonenumber={() => this.updatePhonenumber()}
                changePrefixState={(field, value) =>
                  this.changePrefixState(field, value)
                }
                prefix_1={this.state.prefix_1}
                loading={this.state.loading}
              />

              <Button
                className={
                  PhoneVerify ? "verify-continue-btn" : "verify-continue-btn2"
                }
                disabled={!PhoneVerify}
                onClick={() => this.verifyContinue()}
                loading={this.state.loading}
              >
                Verify & Continue
              </Button>
            </Card>
          ) : this.props.user_activated === 0 ? (
            <Card>
              <div className="header-txt">
                <p className="account-txt">Let Us Set Up Your Account</p>
                <p className="verify-emai-mobile-txt">Verify your Email</p>
              </div>
              <SupportEmail
                email={this.state.updateEmailModal.supportemail}
                handleChange={(value, event) => this.handleChange(value, event)}
                emailotp={this.state.emailotp}
                handleEmailChange={(value) => this.handleEmailChange(value)}
                togglesaveChangeEmail={() => this.togglesaveChangeEmail()}
                saveChangeEmail={this.state.saveChangeEmail}
                updateEmail={() => this.updateEmail()}
                email2={this.state.email}
                loading={this.state.updateEmailModal.loading}
              />

              <Button
                className={
                  emailVefrify ? "verify-continue-btn" : "verify-continue-btn2"
                }
                disabled={!emailVefrify}
                onClick={() => this.verifyContinue()}
                loading={this.state.loading}
              >
                Verify & Continue
              </Button>
            </Card>
          ) : null}
        </div>
        {/* <p style={{ background: "#fff" }} /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ auth: state.auth.auth });
export default connect(mapStateToProps)(VerifyEmail);
