import React, { Component } from "react";
import "./index.scss";
import { ReactComponent as Iconi } from "../../../../../assets/images/icons/TooltipIcon.svg";
import { Select, Tooltip } from "antd";
const { Option } = Select;
export default class Cointainer2 extends Component {
  render() {
    let {
      disabled,
      pageTitle,
      pageDescription,
      footerText,
      primaryTextFontSize,
      secondoryTextFontSize,
      footerFontSize,
      sectionFontSize,
      handleSecondTextChange,
      handleSecondFontChange,
      handleFooterTextChange,
    } = this.props;

    return (
      <div>
        <div className="sub-heading">Page Settings</div>
        <div className="cointainer-input">
          <div className="container-2">
            <div className="container-main">
              <div className="title-input-cointainer">
                <div className="title-input">
                  {" "}
                  Page Title <span style={{ color: "red" }}>*</span>
                </div>
                <Tooltip
                  title={
                    <span style={{ position: "relative" }}>
                      Please enter page title that best represents your brand
                      for your tracking page.
                    </span>
                  }
                >
                  <span
                    className="icon-i-ontracking"
                    style={{ position: "relative", top: "4px" }}
                  >
                    <Iconi />
                  </span>
                </Tooltip>
              </div>
              <input
                disabled={disabled}
                className="input"
                value={pageTitle}
                onChange={(e) =>
                  handleSecondTextChange({
                    name: "page_title",
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div className="container-main">
              <div className="title-input-cointainer">
                <div className="title-input">
                  {" "}
                  Footer Text <span style={{ color: "red" }}>*</span>{" "}
                </div>
                <Tooltip
                  title={
                    <span style={{ position: "relative" }}>
                      Please enter a footer text as a disclaimer for your brand
                      store.
                    </span>
                  }
                >
                  <span
                    className="icon-i-ontracking"
                    style={{ position: "relative", top: "4px" }}
                  >
                    <Iconi />
                  </span>
                </Tooltip>
              </div>
              <input
                disabled={disabled}
                className="input"
                value={footerText}
                onChange={handleFooterTextChange}
              />
            </div>
          </div>
          <div className="container-main">
            <div className="title-input-cointainer">
              <div className="title-input">
                {" "}
                Page Description <span style={{ color: "red" }}>*</span>{" "}
              </div>
              <Tooltip
                title={
                  <span style={{ position: "relative" }}>
                    Please enter page description that best represents your
                    specific needs for branding purpose.
                  </span>
                }
              >
                <span
                  className="icon-i-ontracking"
                  style={{ position: "relative", top: "4px" }}
                >
                  <Iconi />
                </span>
              </Tooltip>{" "}
            </div>
            {/* <input disabled={disabled} className="input" /> */}
            <textarea
              style={{ minHeight:"103px"}}
              disabled={disabled}
              className="input"
              onChange={(e) =>
                handleSecondTextChange({
                  name: "page_description",
                  value: e.target.value,
                })
              }
              value={pageDescription}
            />
          </div>
          {/* <div className="container-main">
            <div className="title-input">
              Page Title Font Size{" "}
              <Tooltip
                title={
                  <span style={{ position: "relative" }}>
                    Minimum font size 12px
                  </span>
                }
              >
                <span className="icon-i-ontracking" style={{ position: "relative", top: "4px" }}>
                  <Iconi />
                </span>
              </Tooltip>
            </div>
            <input
              disabled={disabled}
              className="input"
              type="number"
              value={primaryTextFontSize}
              onChange={(e) =>
                handleSecondFontChange({
                  name: "primary_text",
                  value: e.target.value,
                })
              }
              onBlur={(e) => {
                if (Number(e.target.value) < 12) {
                  handleSecondFontChange({
                    name: "primary_text",
                    value: "",
                  });
                }
              }}
            />
          </div> */}
        </div>
        <div className="cointainer-input">
          {/* <div className="container-main">
            <div>
              <div className="title-input">
                Body Text Font Size
                <Tooltip
                  title={
                    <span style={{ position: "relative" }}>
                      Minimum font size 12px
                    </span>
                  }
                >
                  <span
                     className="icon-i-ontracking"
                    style={{ position: "relative", top: "4px", right: "-4px" }}
                  >
                    <Iconi />
                  </span>
                </Tooltip>
              </div>
              <input
                disabled={disabled}
                className="input"
                type="number"
                value={secondoryTextFontSize}
                onChange={(e) =>
                  handleSecondFontChange({
                    name: "secondary_text",
                    value: e.target.value,
                  })
                }
                onBlur={(e) => {
                  if (Number(e.target.value) < 12) {
                    handleSecondFontChange({
                      name: "secondary_text",
                      value: "",
                    });
                  }
                }}
              />
            </div>
            <div>
              <div className="title-input">
                Section Title Font Size
                <Tooltip
                  title={
                    <span style={{ position: "relative" }}>
                      Minimum font size 12px
                    </span>
                  }
                >
                  <span
                  className="icon-i-ontracking"
                    style={{ position: "relative", top: "4px", right: "-4px" }}
                  >
                    <Iconi />
                  </span>
                </Tooltip>
              </div>
              <input
                disabled={disabled}
                className="input"
                type="number"
                value={sectionFontSize}
                onChange={(e) =>
                  handleSecondFontChange({
                    name: "section_title_text",
                    value: e.target.value,
                  })
                }
                onBlur={(e) => {
                  if (Number(e.target.value) < 12) {
                    handleSecondFontChange({
                      name: "section_title_text",
                      value: "",
                    });
                  }
                }}
              />
            </div>
          </div> */}
        </div>
        <div className="cointainer-input">
          
          {/* <div className="container-main">
            <div className="title-input">
              Footer Text Font Size
              <Tooltip
                  title={
                    <span style={{ position: "relative" }}>
                      Minimum font size 12px
                    </span>
                  }
                >
                  <span
                    className="icon-i-ontracking"
                    style={{ position: "relative", top: "4px", right: "-4px" }}
                  >
                    <Iconi />
                  </span>
                </Tooltip>
            </div>
            <input
              disabled={disabled}
              className="input"
              type="number"
              value={footerFontSize}
              onChange={(e) =>
                handleSecondFontChange({
                  name: "footer_text",
                  value: e.target.value,
                })
              }
              onBlur={(e) => {
                if (Number(e.target.value) < 12) {
                  handleSecondFontChange({
                    name: "footer_text",
                    value: "",
                  });
                }
              }}
            />
          </div> */}
        </div>
      </div>
    );
  }
}
