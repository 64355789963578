import React from "react";

const ZoomModal = ({ visible, src, type, toggleModal }) => {
  return (
    <div style={{ display: visible ? "block" : "none", position: "relative" }}>
      <button
        style={{
          position: "fixed",
          top: 12,
          right: 12,
          border: "none",
          background: "transparent",
          color: "white",
          zIndex: 99999,
        }}
        onClick={() => toggleModal()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          style={{ widt: 32, height: 32, zIndex: 9999 }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0,0,0,0.6)",
          zIndex: 9999,
        }}
      />
      {type === "image" ? (
        <img
          src={src}
          alt="zoomed media"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            position: "fixed",
            padding: 30,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
          }}
        />
      ) : null}
    </div>
  );
};

export default ZoomModal;
