import React, { Component } from "react";
import "./index.scss";
import { ReactComponent as Circle } from "../../assets/images/icons/circleShadowSignup.svg";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import LeftContainer from "../Signupnew/LeftContainer";
import RightContainer from "./RightContainer";

class LoginNew extends Component {
  componentDidMount() {
    document.title = this.props.title;
  }
  render() {
    const { auth } = this.props;
    if (auth.logged_in) return <Redirect to={`/switch-store`} />;
    return (
      <div className="signin-container-new">
        <span className="shadow-container">
          <Circle />
        </span>
        <div className="mainCard-signup">
          <LeftContainer  title={this.props.title}/>
          <RightContainer title={this.props.title} />
        </div>
      </div>
    );
  }d
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(LoginNew);
