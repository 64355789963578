import React, { Component } from "react";
import DisputeModal from "./DisputeModal";
import LineItemsModal from "./LineItemsModal";
import DisputeRaisedModal from "./DisputeRaisedModal";
import DisputeFailedModal from "./DisputeFailedModal";

class DisputeModals extends Component {
  state = {
    step: 0,
    shipment: this.props.data,
    selectedLineItems: [],
    failedReason: "",
    failedReasonStatus: false,
    disputeid: "",
  };

  componentDidUpdate(pp) {
    if (pp.data === undefined && this.props.data !== undefined) {
      this.setState({
        shipment: {
          ...this.props.data,
          items: this.props.data.items.map((item) => {
            return {
              ...item,
              originalQty: item.quantity,
            };
          }),
        },
        step:
          this.props.data.items
            .map((item) => item.quantity)
            .reduce((a, b) => a + b) > 1
            ? 1
            : 2,
      });
    }
  }

  onCountinueLineItem() {
    this.setState({
      step: 2,
    });
  }
  onCountinueDispute(disputeid) {
    this.setState({
      step: 3,
      disputeid: disputeid,
    });
  }
  onFailedDispute(msg, status) {
    this.setState({
      step: 4,
      failedReason: msg,
      failedReasonStatus: status,
    });
  }
  // raisedSuccessfully() {
  //   this.setState({
  //     step: 0,
  //     shipment: undefined
  //   });
  //   this.props.resetModal();
  // }

  raisedOnCancel() {
    this.setState({
      step: 0,
      shipment: undefined,
      selectedLineItems: [],
    });
    this.props.resetModal();
    this.props.reloadList();
  }

  onCancel() {
    this.setState({
      step: 0,
      shipment: undefined,
      selectedLineItems: [],
    });
    this.props.resetModal();
  }

  lineItemSelectedChange(id) {
    let selectedLineItems = [...this.state.selectedLineItems];
    const findIndex = selectedLineItems.findIndex((li) => li === id);
    if (findIndex === -1) {
      selectedLineItems.push(id);
    } else {
      selectedLineItems = [
        ...selectedLineItems.slice(0, findIndex),
        ...selectedLineItems.slice(findIndex + 1),
      ];
    }
    this.setState({
      selectedLineItems,
    });
  }

  onQtyChange = (id, qty) => {
    this.setState((state) => ({
      shipment: {
        ...state.shipment,
        items: state.shipment.items.map((li) => {
          if (li.lineitem_id === id) {
            return { ...li, quantity: qty };
          } else {
            return { ...li };
          }
        }),
      },
    }));
  };

  render() {
    let selectitems = this.state.selectedLineItems;
    if (
      selectitems.length === 0 &&
      this.state.shipment &&
      this.state.shipment.items &&
      this.state.shipment.items.length === 1 &&
      this.state.shipment.items[0].quantity === 1
    ) {
      selectitems.push(this.state.shipment.items[0].lineitem_id);
    }

    let selectedLineItems =
      this.state.shipment && this.state.shipment.items
        ? this.state.shipment.items.filter((item) =>
            selectitems.find((id) => id === item.lineitem_id)
          )
        : [];

    return (
      <>
        <LineItemsModal
          visible={this.state.step === 1}
          data={this.state.shipment}
          lineItemSelectedChange={(id) => this.lineItemSelectedChange(id)}
          onQtyChange={(id, qty) => this.onQtyChange(id, qty)}
          selectedLineItems={this.state.selectedLineItems}
          onCountinue={() => this.onCountinueLineItem()}
          onCancel={() => this.onCancel()}
        />
        <DisputeModal
          {...this.props}
          visible={this.state.step === 2}
          data={this.state.shipment}
          selectedLineItems={selectedLineItems}
          onCountinue={(disputeid) => this.onCountinueDispute(disputeid)}
          onFailed={(msg, status) => this.onFailedDispute(msg, status)}
          onCancel={() => this.onCancel()}
          reloadList={() => this.props.reloadList()}
          resetModal={() => this.props.resetModal()}
        />
        <DisputeRaisedModal
          {...this.props}
          visible={false}
          onCountinue={() => this.onCancel()}
          disputeid={this.state.disputeid}
          raisedOnCancel={() => this.raisedOnCancel()}
        />
        <DisputeFailedModal
          visible={this.state.step === 4}
          onCountinue={() => this.onCancel()}
          failedReason={this.state.failedReason}
          failedReasonStatus={this.state.failedReasonStatus}
        />
      </>
    );
  }
}
export default DisputeModals;
