import React from "react";
import { Modal,Button } from "antd";
export const VfullfillDomain = ({ cancel, visible }) => {
  return (
    <Modal
      visible={visible}
      onCancel={() => {
        cancel();
      }}
      footer={
        <Button
          className="clickable"
          onClick={() => {
            cancel();
          }}
        >
          OK
        </Button>
      }
      title="Important Information"
    >
      <div>
        By choosing this option, the Branded tracking Page will be installed on
        a subdomain managed by vFulfill. You will be able to access the
        application using a unique URL provided by vFulfill.
      </div>
    </Modal>
  );
};
