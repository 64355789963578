import React from "react";

import {
  Alert,
  Row,
  Skeleton,
  Menu,
  Dropdown,
  Icon,
  Button,
  Table,
} from "antd";
import DatamapsIndia from "react-datamaps-india";
import "./index.scss";
import NotFoundCard from "../../../Common/NotFoundCard";
import ErrorCard from "../../../Common/ErrorCard";
import TooltipTitle from "../../../Common/GlobalTooltipIconBlack";
const Map = (props) => {
  const {
    data,
    startDate,
    endDate,
    loading,
    error,
    errorMsg,
    errorCode,
  } = props;

  const exportObj = {
    total_order_count: "Total Order Count",
    delivered_orders: "Delivered Orders",
    id: "State ID",
    province_code: "State",
    delivery_percentage: "Delivery Percentage",
    cod_orders: "COD Orders",
    prepaid_orders: "Prepaid Orders",
    delivered_cod_orders: "Delivered COD Orders",
    delivered_prepaid_orders: "Delivered Prepaid Orders",
  };

  function tooltipHandler(value) {
    return (
      <>
        <div className="bold-5">
          {value.name}{" "}
          {value.value || value === 0 ? `: ${value.value} %` : null}
        </div>

        {!!value.total || value.total === 0 ? (
          <div> Total Orders Count: {value.total}</div>
        ) : null}

        {!!value.count || value.count === 0 ? (
          <div> Total Delivered Orders Count: {value.count}</div>
        ) : null}
      </>
    );
  }

  function exportJSONToCSV(objArray) {
    var arr = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    var str =
      `${Object.keys(arr[0])
        .map((value) => `"${exportObj[value]}"`)
        .join(",")}` + "\r\n";

    var csvContent = arr.reduce((st, next) => {
      st +=
        `${Object.values(next)
          .map((value) => `"${value}"`)
          .join(",")}` + "\r\n";
      return st;
    }, str);
    var element = document.createElement("a");
    element.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
    element.target = "_blank";
    element.download = "export.csv";
    element.click();
  }

  const column1 = [
    {
      title: "State",
      dataIndex: "province_code",
      key: "1",
      width: 170,
      fixed: "left",
      sorter: (a, b) =>
        !!a.province_code
          ? a.province_code.localeCompare(b.province_code)
          : null,
    },
    {
      title: "Total Delivered Orders",
      dataIndex: "delivered_orders",
      key: "2",
      width: 180,
      sorter: (a, b) => a.delivered_orders - b.delivered_orders,
    },
    {
      title: (
        <p className="table-title-icon custom">
          Overall Delivery %{" "}
          <TooltipTitle
            tooltipText={`This delivery rate is derived from “All Orders placed on vFulfill vs Successful Deliveries"`}
          />{" "}
        </p>
      ),

      dataIndex: "delivery_percentage",
      key: "3",
      width: 200,
      sorter: (a, b) => a.delivery_percentage - b.delivery_percentage,
    },
    {
      title: "Total COD Orders",
      dataIndex: "cod_orders",
      key: "4",
      width: 160,
      sorter: (a, b) => a.cod_orders - b.cod_orders,
    },
    {
      title: "Delivered COD Orders",
      dataIndex: "delivered_cod_orders",
      key: "5",
      width: 180,
      sorter: (a, b) => a.delivered_cod_orders - b.delivered_cod_orders,
    },
    {
      title: "Total Prepaid Orders",
      dataIndex: "prepaid_orders",
      key: "6",
      width: 170,
      sorter: (a, b) => a.prepaid_orders - b.prepaid_orders,
    },
    {
      title: "Delivered Prepaid Orders",
      dataIndex: "delivered_prepaid_orders",
      key: "7",
      width: 200,
      sorter: (a, b) => a.delivered_prepaid_orders - b.delivered_prepaid_orders,
    },
    {
      title: "Total",
      dataIndex: "total_order_count",
      key: "8",
      width: 100,
      fixed: "right",
      sorter: (a, b) => a.total_order_count - b.total_order_count,
    },
  ];
  const column2 = [
    {
      title: "State",
      dataIndex: "province_code",
      key: "1",
      width: 170,
      fixed: "left",
      sorter: (a, b) =>
        !!a.province_code
          ? a.province_code.localeCompare(b.province_code)
          : null,
    },
    {
      title: "Total Delivered Orders",
      dataIndex: "delivered_orders",
      key: "2",
      width: 180,
      sorter: (a, b) => a.delivered_orders - b.delivered_orders,
    },

    {
      title: "Total COD Orders",
      dataIndex: "cod_orders",
      key: "3",
      width: 160,
      sorter: (a, b) => a.cod_orders - b.cod_orders,
    },
    {
      title: "Delivered COD Orders",
      dataIndex: "delivered_cod_orders",
      key: "4",
      width: 180,
      sorter: (a, b) => a.delivered_cod_orders - b.delivered_cod_orders,
    },
    {
      title: "Total Prepaid Orders",
      dataIndex: "prepaid_orders",
      key: "5",
      width: 170,
      sorter: (a, b) => a.prepaid_orders - b.prepaid_orders,
    },
    {
      title: "Delivered Prepaid Orders",
      dataIndex: "delivered_prepaid_orders",
      key: "6",
      width: 200,
      sorter: (a, b) => a.delivered_prepaid_orders - b.delivered_prepaid_orders,
    },
    {
      title: (
        <p style={{ marginLeft: "12px" }} className="table-title-icon custom">
          Overall Delivery %{" "}
          <TooltipTitle
            tooltipText={`This delivery rate is derived from “All Orders placed on vFulfill vs Successful Deliveries"`}
          />
        </p>
      ),
      dataIndex: "delivery_percentage",
      key: "7",
      width: 180,
      fixed: "right",
      sorter: (a, b) => a.delivery_percentage - b.delivery_percentage,
    },
  ];

  if (loading) {
    return (
      <Row style={{ marginBottom: "30px" }}>
        <Skeleton active />
      </Row>
    );
  }

  if (error && !loading) {
    return (
      <div id="fade-in" className="maps-wraper">
        <div className="map-head-wraper">
          <div className="left">
            <div className="topPerformingProductHead">
              <span className="name">Top Performing States</span> |{" "}
              <span>
                {startDate} - {endDate}
              </span>
            </div>
            <div className="product-heads">
              <div className="name-head">{props.subHeadingName}</div>
            </div>
          </div>
        </div>

        <ErrorCard errorMsg={errorMsg} errorCode={errorCode} />
      </div>
    );
  }

  if (Object.entries(data).length === 0) {
    return (
      <div id="fade-in" className="maps-wraper">
        <div className="map-head-wraper">
          <div className="left">
            <div className="topPerformingProductHead">
              <span className="name">Top Performing States</span> |{" "}
              <span>
                {startDate} - {endDate}
              </span>
            </div>
            <div className="product-heads">
              <div className="name-head">{props.subHeadingName}</div>
            </div>
          </div>
        </div>
        <NotFoundCard
          mainHeading={"Oops! No Data Found."}
          secondHeading={
            <p>
              Looks like there are no data in <span>current Date Range</span>.
            </p>
          }
          thirdHeading={
            <p>
              Please <span>select another Date Range</span> from Date Picker to
              filter data.
            </p>
          }
        />
      </div>
    );
  }

  return (
    <div id="fade-in" className="maps-wraper">
      <div className="map-head-wraper">
        <div className="left">
          <div className="topPerformingProductHead">
            <span className="name">Top Performing States</span> |{" "}
            <span>
              {startDate} - {endDate}
            </span>
          </div>
          <div className="product-heads">
            <div className="name-head">{props.subHeadingName}</div>
          </div>
        </div>

        <Button
          className="clickable"
          onClick={() =>
            exportJSONToCSV(
              JSON.stringify(
                props.originalData.map(({ id, ...rest }) => ({ ...rest }))
              )
            )
          }
        >
          Export CSV
        </Button>
      </div>

      <DatamapsIndia
        regionData={{
          ...data,
        }}
        hoverComponent={({ value }) => {
          return (
            <div className="graph-custom-tooltip">{tooltipHandler(value)}</div>
          );
        }}
        mapLayout={{
          title: "",
          legendTitle: "Delivery Percentage %",
          startColor: "#E0EFFE",
          endColor: "#0058D6",
          hoverTitle: "Count",
          noDataColor: "#AFB6C0",
          borderColor: "#8D8D8D",
          hoverBorderColor: "#0043A3",
          hoverColor: "#0043A3",
          height: 70,
          weight: 30,
        }}
      />

      <div className="map-table-wraper">
        <Table
          className="map-table"
          columns={props.map2 ? column2 : column1}
          scroll={{ y: 471, x: 500 }}
          rowKey={(record, i) => i}
          dataSource={props.originalData}
          loading={loading}
          filtered={true}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default Map;
