import React from "react";
import { Tooltip, Icon } from "antd";
import { ReactComponent as copyIcon } from "../../../../assets/images/icons/copy-icon1.svg";
import "./index.scss";

class ClickToCopy extends React.Component {
  state = {
    copySuccess: this.props.defaultText ? this.props.defaultText : "Copy",
  };

  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand("copy");
    // if (e.target && e.target.focus) {
    e.target.focus();
    // }
    this.setState({
      copySuccess: "Copied!",
    });
  };

  render() {
    return (
      <span className="click-to-copy">
        {document.queryCommandSupported("copy") && (
          <>
            <Tooltip
              placement="right"
              title={
                <p style={{ textAlign: "center", fontSize: "10px" }}>
                  {this.state.copySuccess}
                </p>
              }
              onVisibleChange={(visible) =>
                !visible &&
                this.setState({
                  copySuccess: this.props.defaultText
                    ? this.props.defaultText
                    : "Copy",
                })
              }
            >
              {this.props.children ? (
                this.props.children(this.copyToClipboard)
              ) : (
                <span
                  className={`hover-underline-animation1 ${
                    this.props.width ? `hover-underline-animation2` : null
                  }  `}
                  onClick={this.copyToClipboard}
                >
                  {!!this.props.product ? (
                    <span>{this.props.product.vfprodid}</span>
                  ) : (
                    <span className="sp" style={{ fontWeight: "400" }}>
                      {this.props.textToCopy &&
                      this.props.textToCopy.length > 13 &&
                      !this.props.fullText ? (
                        <Tooltip title={this.props.textToCopy}>
                          {this.props.textToCopy.slice(0, 13) + ".."}
                        </Tooltip>
                      ) : (
                        this.props.textToCopy
                      )}
                    </span>
                  )}

                  <Icon
                    style={{ fontSize: "12px important" }}
                    component={copyIcon}
                  />
                  {/* <svg>{copyIcon}</svg>   */}
                  {/* <i src={copyIcon} /> */}
                </span>
              )}
            </Tooltip>
            <textarea
              style={{
                width: 0,
                height: "0 !important",
                padding: 0,
                margin: 0,
                borderColor: "transparent",
                lineHeight: 0,
                outline: "none",
                background: "transparent",
              }}
              ref={(textarea) => (this.textArea = textarea)}
              value={this.props.textToCopy}
              readOnly
            />
          </>
        )}
      </span>
    );
  }
}

export default ClickToCopy;
