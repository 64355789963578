import React, { Component } from "react";
import { Input, Tooltip } from "antd";
import "./EditableText.scss";

class EditableText extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;

    this.setTextInputRef = (element) => {
      this.textInput = element;
    };

    this.focusTextInput = () => {
      if (this.textInput) this.textInput.focus();
    };
  }

  state = {
    field: null,
    value: this.props.text ? this.props.text : "",
    editable: false,
  };

  toggleEditMode() {
    this.setState(
      {
        editable: !this.state.editable,
      },
      () => {
        if (this.state.editable) {
          this.focusTextInput();
        } else {
          this.props.onTextChange(this.state.value);
        }
      }
    );
  }

  onChange(e) {
    this.setState({
      value: e.target.value,
    });
  }

  render() {
    let color = "#000";
    if (this.props.color) {
      color = this.props.color;
    }

    const elem = this.state.editable ? (
      <Input.TextArea
        ref={this.setTextInputRef}
        value={this.state.value}
        onBlur={() => this.toggleEditMode()}
        onChange={(e) => this.onChange(e)}
        className={this.props.type === "importList" && "importlistclass"}
      />
    ) : (
      <p style={{ marginBottom: 10, display: "flex", alignItems: "center" }}>
        <span

contenteditable="true"
          style={{
            ...this.props.style,
            display: "inline-block",
            width: "calc(100% - 20px)",
            verticalAlign: "top",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            paddingRight: 15,
            color,
          }}
        >
          {this.state.value}
        </span>
        <span
          style={{
            color: "#FF005E",
            cursor: "pointer",
            display: "inline-block",
          }}
          onClick={() => this.toggleEditMode()}
        >
          <Tooltip title="Edit">
            <svg
              id="edit"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16.002"
              viewBox="0 0 16 16.002"
            >
              <path
                id="Shape"
                d="M15.356,16H.642a.643.643,0,0,1,0-1.286H15.356a.643.643,0,0,1,0,1.286ZM3.916,12.656a.574.574,0,0,1-.524-.8l1.782-4.2A.576.576,0,0,1,5.3,7.472L12.388.378a1.29,1.29,0,0,1,1.821,0l1.416,1.416a1.29,1.29,0,0,1,0,1.822L8.529,10.706a.575.575,0,0,1-.182.121l-4.2,1.781A.581.581,0,0,1,3.916,12.656ZM11.281,3.3h0L6.3,8.283,5.264,10.737,7.717,9.7,12.7,4.718,11.281,3.3ZM13.3,1.284h0l0,0L12.19,2.395,13.6,3.809,14.711,2.7l0,0L13.3,1.284Z"
                transform="translate(0 0)"
                fill="#ff005e"
              />
            </svg>
          </Tooltip>
        </span>
      </p>
    );

    return <div className="editable-text">{elem}</div>;
  }
}

export default EditableText;
