import React, { Component } from "react";
import {
  Modal,
  message,
  Icon,
  Input,
  Button,
  Radio,
  Tabs,
  Tooltip,
} from "antd";
import axios from "axios";
import { connect } from "react-redux";
import { ReactComponent as rightArrow } from "../../../../assets/images/icons/long-arrow-pointing-to-the-right.svg";
import { ReactComponent as AddFundIllustration } from "../../../../assets/images/illustrations/onboarding/illustration-verify-email.svg";
// import { ReactComponent as paypalIcon } from "../../../../assets/images/icons/paypal.svg";
import { ReactComponent as wiretransferIcon } from "../../../../assets/images/icons/wire-transfer.svg";
import { ReactComponent as creditIcon } from "../../../../assets/images/icons/credit-debit.svg";
import { formatNumber, getCurrencySymbol } from "../../../../Helpers/Pricing";
import "./index.scss";
import TransactionSuccess from "../TransactionSuccessModal";
import TransactionFailed from "../TransactionFailedModal";
import StripePaymentForm from "./StripeScreen";
import WireTransferSuccessModal from "../WireTransferSuccessModal";
import WireTransferSuccessOutSideIndiaModal from "../WireTransferSuccessOutSideIndiaModal";

const { TabPane } = Tabs;
class AddFundsModal extends Component {
  state = {
    loading: false,
    paymentType: "INR",
    amountToAdd: 1,
    feeMultiplier: 0.035,
    feeFixed: 0,
    paymentMethod: "cc",
    razorpay: {
      orderid: "",
      amount: 0,
    },
    successModal: {
      wireSuccess: false,
      id: "",
    },
    wireSuccessModal: {
      visible: false,
      id: "",
    },

    wireSuccessModalNRI: {
      visible: false,
      id: "",
    },

    failedModal: {
      visible: false,
      text: null,
    },

    currencyData: {
      base: null,
      payement_gateway_fees: null,
      total: null,
    },

    stripePayment: {
      visible: false,
      clientSecretKey: "",
      amount: 0,
      stripePayment: 0,
      amount_inr: 0,
    },
  };

  componentDidMount() {
    this.setState({ amountToAdd: 5000, paymentType: "INR" });
  }

  tabChangeHandler(e) {
    if (e === "INR") {
      this.setState({ amountToAdd: 5000, paymentType: "INR" });
    }

    if (e === "USD") {
      this.setState({ amountToAdd: 100, paymentType: "USD" }, () =>
        this.inrToDollar()
      );
    }
  }

  generateRazorpayOrder() {
    axios({
      url: process.env.REACT_APP_API_URL + "/payments/generate_razorpay_order",
      method: "post",
      data: {
        amount: this.state.amountToAdd,
        currency: this.state.paymentType,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          if (this.state.paymentType === "USD") {
            this.setState({
              stripePayment: {
                clientSecretKey: res.data.client_secret,
                visible: true,
                amount: res.data.amount,
                amount_inr: res.data.amount_inr,
              },
            });
          } else {
            const options = {
              key: this.props.clients.razorpay,
              amount: res.data.amount,
              currency: "INR",
              name: "vFulfill",
              description: "Add Funds",
              image:
                "https://vfulfill.io/assets/images/blackBackgroundVfulfillLogo.png",
              order_id: res.data.orderid,
              handler: (response) => {
                axios({
                  url:
                    process.env.REACT_APP_API_URL +
                    "/payments/razorpay_payment_response",
                  method: "post",
                  data: {
                    response,
                  },
                  withCredentials: true,
                })
                  .then((res) => {
                    if (res.data.success === 1) {
                      this.setState({
                        successModal: {
                          visible: true,
                        },
                      });
                    } else {
                      this.setState({
                        failedModal: {
                          visible: true,
                        },
                      });
                    }
                  })
                  .catch((e) => {
                    message.error(e.message);
                  });
              },
              prefill: {
                name: this.props.auth.user.user_fname,
                email: this.props.auth.user.user_email,
              },
              theme: {
                color: "#72A0FF",
              },
            };
            const rzp1 = new window.Razorpay(options);
            rzp1.open();
          }
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        // console.log(e);
        message.error("There was an error!");
      });
  }

  generateWireOrder() {
    axios({
      url:
        process.env.REACT_APP_API_URL + "/payments/generate_wiretransfer_order",
      method: "post",
      data: {
        amount: this.state.amountToAdd,
        currency: this.state.paymentType,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          if (this.state.paymentType === "USD") {
            this.setState({
              wireSuccessModalNRI: {
                visible: true,
                id: res.data.orderid,
              },
            });
          } else {
            this.setState({
              wireSuccessModal: {
                visible: true,
                id: res.data.orderid,
              },
            });
          }
        } else {
          if (res.data.msg === "You already have a pending raised requests !") {
            this.setState({
              failedModal: {
                visible: true,
                text: "You already have a pending raised requests !",
              },
            });
          } else {
            this.setState({
              failedModal: {
                visible: true,
              },
            });
          }
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  inrToDollar() {
    this.setState({ loading: true }, () => {
      axios({
        url: process.env.REACT_APP_API_URL + "/payments/currency_converter",
        method: "post",
        data: {
          amount: this.state.amountToAdd,
          currency: this.state.paymentType,
        },
        withCredentials: true,
      })
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.success === 1) {
            this.setState({ currencyData: res.data.inr });
          } else {
            message.info(res.data.msg);
          }
        })
        .catch((err) => {
          this.setState({ loading: false });

          message.error(err.message);
        });
    });
  }

  toggleTransactionSuccessModal() {
    let method = this.state.paymentMethod;
    if (method === "cc") {
      this.setState({
        successModal: {
          ...this.state.successModal,
          visible: !this.state.successModal.visible,
        },
      });
    } else if (method === "wire") {
      this.setState({
        wireSuccessModal: {
          ...this.state.wireSuccessModal,
          visible: !this.state.wireSuccessModal.visible,
        },
      });
    }
  }

  toggleFailedModal() {
    this.setState({
      failedModal: {
        ...this.state.failedModal,
        visible: !this.state.failedModal.visible,
        text: null,
      },
    });
  }

  changeMethod(value) {
    let feeMultiplier = 0;
    let feeFixed = 0;
    if (value === "cc") {
      feeMultiplier = 0.035;
    } else if (value === "paypal") {
      feeMultiplier = 0.044;
      feeFixed = 0.3 * this.props.inrValue;
    } else if (value === "wire") {
      feeMultiplier = 0;
      feeFixed = 0;
    }
    this.setState({
      paymentMethod: value,
      feeMultiplier,
      feeFixed,
    });
  }

  setAmount(n) {
    if (this.state.paymentType === "USD") {
      return this.setState({ amountToAdd: n }, () => this.inrToDollar());
    } else {
      return this.setState({
        amountToAdd: n,
      });
    }
  }

  continueWithPayment() {
    const method = this.state.paymentMethod;
    if (method === "cc") {
      this.generateRazorpayOrder();
    } else if (method === "wire") {
      this.generateWireOrder();
    }
  }

  closeTransactionSuccessModalNRI() {
    this.setState({
      wireSuccessModalNRI: {
        visible: false,
        id: "",
      },
    });
  }

  footerHandler() {
    if (!!this.state.stripePayment.clientSecretKey) {
      return null;
    }

    if (this.state.paymentType === "INR" && this.state.amountToAdd >= 5000) {
      return (
        <Button
          block
          disabled={this.state.loading}
          onClick={() => {
            this.continueWithPayment();
          }}
        >
          CONTINUE WITH PAYMENT
          <Icon component={rightArrow} />
        </Button>
      );
    }

    if (this.state.paymentType === "USD" && this.state.amountToAdd >= 100) {
      return (
        <Button
          block
          disabled={this.state.loading}
          onClick={() => {
            this.continueWithPayment();
          }}
        >
          CONTINUE WITH PAYMENT
          <Icon component={rightArrow} />
        </Button>
      );
    }

    return (
      <Button
        block
        disabled={true}
        onClick={() => {
          this.continueWithPayment();
        }}
      >
        CONTINUE WITH PAYMENT
        <Icon component={rightArrow} />
      </Button>
    );
  }

  render() {
    if (this.props.auth.store && this.props.auth.store.store_geo === "global") {
      return (
        <Modal
          visible={this.props.visible}
          title={null}
          onOk={() => this.props.toggleAddFundsModal()}
          onCancel={() => this.props.toggleAddFundsModal()}
          footer={null}
        >
          <div style={{ textAlign: "center", padding: 30 }}>
            <AddFundIllustration />
            <p style={{ fontSize: "1.2rem", margin: "15px 0" }}>
              Contact your Account manager to add funds into your account
            </p>
            <a
              className="ant-btn"
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto: ${this.props.auth.user.user_manager.email}`}
              style={{
                background: "#36c626",
                borderColor: "#36c626",
                color: "white",
              }}
              size="large"
            >
              Contact Now
            </a>
          </div>
        </Modal>
      );
    }
    return (
      <>
        <Modal
          className="addfundsmodal"
          visible={this.props.visible}
          width={580}
          title={
            <div className="head-background">
              <div>
                <h1>ADD FUNDS</h1>
                <p>TO YOUR vFULFILL WALLET</p>
              </div>
            </div>
          }
          onCancel={() => this.props.toggleAddFundsModal()}
          footer={this.footerHandler()}
          closable={!!this.state.stripePayment.clientSecretKey ? false : true}
        >
          {!!this.state.stripePayment.clientSecretKey ? (
            <StripePaymentForm
              clientSecretKey={this.state.stripePayment.clientSecretKey}
              loading={this.state.loading}
              amount={this.state.stripePayment.amount}
              amount_inr={this.state.stripePayment.amount_inr}
              tabHandler={() => {
                this.setState({
                  stripePayment: {
                    visible: false,
                    clientSecretKey: "",
                    amount: 0,
                  },
                });
              }}
              closeModal={() => {
                this.props.toggleAddFundsModal();
                this.props.fetchData();
              }}
            />
          ) : (
            <Tabs
              className="addfunds-tabs"
              activeKey={this.state.paymentType}
              onChange={(e) => this.tabChangeHandler(e)}
            >
              <TabPane tab="Pay in INR" key="INR">
                <div style={{ textAlign: "center", padding: "0 30px 0 30px" }}>
                  <div className="funds-input">
                    <p>₹</p>
                    <Input
                      type="number"
                      step="1"
                      value={this.state.amountToAdd}
                      onChange={(e) =>
                        this.setAmount(
                          Math.sign(e.target.value) == 1 ? e.target.value : ""
                        )
                      }
                    />
                  </div>

                  <p className="fund-err">
                    {this.state.amountToAdd < 5000
                      ? "Minimum Amount Allowed Rs. 5000"
                      : ""}
                  </p>

                  <div className="funds-amount">
                    <span onClick={() => this.setAmount(5000)}>₹ 5000</span>
                    <span onClick={() => this.setAmount(10000)}>₹ 10000</span>
                    <span onClick={() => this.setAmount(15000)}>₹ 15000</span>
                  </div>
                  <div className="total-amount">
                    <p>
                      Amount: {getCurrencySymbol("INR")}{" "}
                      {formatNumber(
                        !!this.state.amountToAdd ? this.state.amountToAdd : 0
                      )}
                    </p>
                  </div>
                  <div className="total-fee">
                    <p>
                      Payment Gateway Fee: {getCurrencySymbol("INR")}{" "}
                      {formatNumber(
                        this.state.amountToAdd * this.state.feeMultiplier +
                          this.state.feeFixed
                      )}{" "}
                    </p>
                  </div>
                  <div className="total-name">
                    <p>
                      Total Amount: {getCurrencySymbol("INR")}{" "}
                      {formatNumber(
                        Number(this.state.amountToAdd) +
                          Number(this.state.amountToAdd) *
                            Number(this.state.feeMultiplier) +
                          Number(this.state.feeFixed)
                      )}
                    </p>
                  </div>
                  <div className="select-payment">
                    <p>Select Payment Method</p>
                    <Radio.Group
                      buttonStyle="solid"
                      value={this.state.paymentMethod}
                      onChange={(e) => this.changeMethod(e.target.value)}
                    >
                      {/* <Tooltip title="Credit in 1-2 Business Days"> */}

                      <div className="wire-div">
                        <Radio.Button value="wire">
                          <Icon component={wiretransferIcon} /> Wire Transfer{" "}
                          <span className="feeCharge"> (0% Fee)</span>
                        </Radio.Button>

                        <div className="abs-text">
                          Credit in 1-2 Business Days
                        </div>
                      </div>
                      {/* </Tooltip> */}

                      {/* <Tooltip title="Instant Credit"> */}

                      <div className="cc-div">
                        <Radio.Button value="cc">
                          <Icon component={creditIcon} /> Instant Credit{" "}
                          <span className="feeCharge"> (3.5% Fee)</span>
                        </Radio.Button>

                        <div className="abs-text">Instant Credit</div>
                      </div>
                      {/* </Tooltip> */}
                    </Radio.Group>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Pay in USD" key="USD">
                <div style={{ textAlign: "center", padding: "0 30px 0 30px" }}>
                  <div className="funds-input">
                    <p>$</p>
                    <Input
                      type="number"
                      step="1"
                      value={this.state.amountToAdd}
                      onChange={(e) =>
                        this.setAmount(
                          Math.sign(e.target.value) == 1 ? e.target.value : ""
                        )
                      }
                    />
                  </div>

                  <p className="fund-err">
                    {this.state.amountToAdd < 100
                      ? "Minimum Amount Allowed $ 100"
                      : ""}
                  </p>

                  <div className="funds-amount">
                    <span onClick={() => this.setAmount(200)}>$ 200</span>
                    <span onClick={() => this.setAmount(500)}>$ 500</span>
                    <span onClick={() => this.setAmount(1000)}>$ 1000</span>
                  </div>
                  <div className="total-amount">
                    <p>
                      Amount: {getCurrencySymbol("USD")}{" "}
                      {formatNumber(
                        !!this.state.amountToAdd ? this.state.amountToAdd : 0
                      )}{" "}
                      <span>
                        ({getCurrencySymbol("INR")}{" "}
                        {this.state.loading ? (
                          <Icon type="loading" />
                        ) : (
                          this.state.currencyData.base &&
                          this.state.currencyData.base.toLocaleString("en-in")
                        )}
                        )
                      </span>
                    </p>
                  </div>
                  <div className="total-fee">
                    <p>
                      Payment Gateway Fee: {getCurrencySymbol("USD")}{" "}
                      {formatNumber(
                        this.state.amountToAdd * this.state.feeMultiplier +
                          this.state.feeFixed
                      )}{" "}
                      <span>
                        ({getCurrencySymbol("INR")}{" "}
                        {this.state.paymentMethod === "wire" ? (
                          0
                        ) : this.state.loading ? (
                          <Icon type="loading" />
                        ) : (
                          this.state.currencyData.payement_gateway_fees &&
                          this.state.currencyData.payement_gateway_fees.toLocaleString(
                            "en-in"
                          )
                        )}
                        )
                      </span>
                    </p>
                  </div>
                  <div className="total-name">
                    <p>
                      Total Amount: {getCurrencySymbol("USD")}{" "}
                      {formatNumber(
                        Number(this.state.amountToAdd) +
                          Number(this.state.amountToAdd) *
                            Number(this.state.feeMultiplier) +
                          Number(this.state.feeFixed)
                      )}{" "}
                      <span>
                        ({getCurrencySymbol("INR")}{" "}
                        {this.state.paymentMethod === "wire" ? (
                          this.state.currencyData.base &&
                          this.state.currencyData.base.toLocaleString("en-in")
                        ) : this.state.loading ? (
                          <Icon type="loading" />
                        ) : (
                          !!this.state.currencyData.total &&
                          this.state.currencyData.total.toLocaleString("en-in")
                        )}
                        )
                      </span>
                    </p>
                  </div>
                  <div className="select-payment">
                    <p>Select Payment Method</p>
                    <Radio.Group
                      buttonStyle="solid"
                      value={this.state.paymentMethod}
                      onChange={(e) => this.changeMethod(e.target.value)}
                    >
                      {/* <Tooltip title="Credit in 1-2 Business Days"> */}

                      <div className="wire-div">
                        <Radio.Button value="wire">
                          <Icon component={wiretransferIcon} /> Wire Transfer{" "}
                          <span className="feeCharge"> (0% Fee)</span>
                        </Radio.Button>

                        <div className="abs-text">
                          Credit in 1-2 Business Days
                        </div>
                      </div>
                      {/* </Tooltip> */}

                      {/* <Tooltip title="Instant Credit"> */}

                      <div className="cc-div">
                        <Radio.Button value="cc">
                          <Icon component={creditIcon} /> Instant Credit{" "}
                          <span className="feeCharge"> (3.5% Fee)</span>
                        </Radio.Button>

                        <div className="abs-text">Instant Credit</div>
                      </div>
                      {/* </Tooltip> */}
                    </Radio.Group>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          )}
        </Modal>
        <TransactionSuccess
          amount={this.state.amountToAdd}
          visible={this.state.successModal.visible}
          toggleSuccessModal={() => this.toggleTransactionSuccessModal()}
        />
        <TransactionFailed
          error={
            !!this.state.failedModal && !!this.state.failedModal.text
              ? this.state.failedModal.text
              : null
          }
          visible={this.state.failedModal.visible}
          toggleFailedModal={() => this.toggleFailedModal()}
        />
        <WireTransferSuccessModal
          hideFooter={false}
          id={this.state.wireSuccessModal.id}
          visible={this.state.wireSuccessModal.visible}
          toggleSuccessModal={() => this.toggleTransactionSuccessModal()}
        />

        <WireTransferSuccessOutSideIndiaModal
          hideFooter={false}
          id={this.state.wireSuccessModalNRI.id}
          visible={this.state.wireSuccessModalNRI.visible}
          toggleSuccessModal={() => this.closeTransactionSuccessModalNRI()}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(AddFundsModal);
