import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "antd";

import { ReactComponent as BackIcon } from "../../../../assets/images/icons/backIcon.svg";

const BreadCrumbs = ({ name, match }) => {
  return (
    <div className="f12 mb16" style={{ color: "#212121" }}>
      <Icon style={{ marginRight: "6px" }} component={BackIcon} />

      <Link
        style={{ color: "#212121" }}
        to={`/${match.params.storegeo}/${
          match.params.storeid
        }/products/search-products?packaging=true`}
        className="mr8"
      >
        Discover Packaging
      </Link>
      <span className="mr8">&gt;</span>
      <span className="mr8 underline">{name}</span>
    </div>
  );
};

export default BreadCrumbs;
