import { Tooltip } from "antd";
import React, { Component } from "react";
import "./index.scss";
import moment from "moment";

const progress1 = ["manifested", ""];
class Shipmentprogressbar2 extends Component {
  state = {
    shipmentsVisible: false,
    shipment: this.props.shipment,
  };

  toggleshipmentvisiable() {
    this.setState({
      shipmentsVisible: !this.state.shipmentsVisible,
    });
  }

  render() {
    return (
      <div className="shipmentprogressbar_data">
        <div className="shipmentprogressbar_data2">
          <div className="line-container">
            <div className="progress-line">
              <div
                className="progress"
                style={{
                  animation:
                    this.state.shipment.status === "manifested"
                      ? "load-34 1.2s linear forwards"
                      : this.state.shipment.status === "transit" ||
                        this.state.shipment.status === "ndr_actionble" ||
                        this.state.shipment.status === "ndr"
                      ? "load-66 1.2s linear forwards"
                      : this.state.shipment.status === "delivered" ||
                        this.state.shipment.status === "rto" ||
                        this.state.shipment.status === "cancelled" ||
                        this.state.shipment.status === "rto_in_transit" ||
                        this.state.shipment.status === "lost"
                      ? "load-100 1.2s linear forwards"
                      : "",
                }}
              />
              <div className="status">
                <div className="dot completed" />
                <div className="time-and-status-wraper">
                  <div className="shipment_step_color">{"Ordered"}</div>
                  <div className="date">
                    {this.state.shipment && this.state.shipment.shipment_date
                      ? moment(
                          this.state.shipment.items[0].confirmation_date
                        ).format("DD MMM, YYYY")
                      : ""}
                  </div>
                </div>
              </div>

              {/* packed */}

              <div className="status">
                <div
                  className={`dot completed${
                    this.state.shipment.status === "manifested"
                      ? ` dot-active`
                      : ``
                  }`}
                />

                <div className="time-and-status-wraper">
                  <div className="shipment_step_color">Packed</div>
                  <div className="date">
                    {this.state.shipment && this.state.shipment.shipment_date
                      ? moment(this.state.shipment.shipment_date).format(
                          "DD MMM, YYYY"
                        )
                      : null}
                  </div>
                </div>
              </div>

              {this.state.shipment.status === "cancelled" ? null : (
                <div className="status">
                  <div
                    className={`dot ${
                      this.state.shipment.status === "manifested"
                        ? ""
                        : "completed"
                    }  ${
                      this.state.shipment.status === "transit" ||
                      this.state.shipment.status === "ndr_actionble" ||
                      this.state.shipment.status === " ndr "
                        ? "dot-active"
                        : ""
                    }`}
                  />

                  <div className="time-and-status-wraper">
                    <div
                      className={
                        this.state.shipment.status === "manifested"
                          ? "original_color"
                          : "shipment_step_color"
                      }
                    >
                      Shipped
                    </div>

                    <div className="date">
                      {this.state.shipment.status === "manifested"
                        ? null
                        : this.state.shipment &&
                          !!this.state.shipment.shipped_on
                        ? moment(this.state.shipment.shipped_on).format(
                            "DD MMM, YYYY"
                          )
                        : this.state.shipment &&
                          this.state.shipment.shipment_date
                        ? moment(this.state.shipment.shipment_date).format(
                            "DD MMM, YYYY"
                          )
                        : null}
                    </div>
                  </div>
                </div>
              )}

              <div
                className={
                  this.state.shipment.status === "rto_in_transit"
                    ? "custom-rto-in-transit status "
                    : "status"
                }
              >
                <div
                  className={`dot ${
                    this.state.shipment.status === "delivered" ||
                    this.state.shipment.status === "rto" ||
                    this.state.shipment.status === "cancelled" ||
                    this.state.shipment.status === "rto_in_transit" ||
                    this.state.shipment.status === "lost"
                      ? "completed"
                      : ""
                  }  ${
                    this.state.shipment.status === "delivered" ||
                    this.state.shipment.status === "rto" ||
                    this.state.shipment.status === "cancelled" ||
                    this.state.shipment.status === "rto_in_transit" ||
                    this.state.shipment.status === "lost"
                      ? "dot-active"
                      : ""
                  }`}
                />

                <div
                  className={
                    this.state.shipment.status === "delivered" ||
                    this.state.shipment.status === "rto" ||
                    this.state.shipment.status === "rto_in_transit"
                      ? "shipment_step_color"
                      : "original_color"
                  }
                >
                  {this.state.shipment.status === "cancelled" ? (
                    <div
                      className="time-and-status-wraper"
                      style={{ marginRight: "-64px", color: "#F12F1A" }}
                    >
                      <div>Cancelled</div>
                      <div className="date">
                        {this.state.shipment && this.state.shipment.cancelled_at
                          ? moment(this.state.shipment.cancelled_at).format(
                              "DD MMM, YYYY"
                            )
                          : null}
                      </div>
                    </div>
                  ) : this.state.shipment.status === "delivered" ||
                    this.state.shipment.status === "transit" ||
                    this.state.shipment.status === "ndr_actionble" ||
                    this.state.shipment.status === " ndr " ||
                    this.state.shipment.status === "manifested" ? (
                    <div
                      className="time-and-status-wraper"
                      style={{ marginRight: "-64px" }}
                    >
                      <div>Delivered</div>
                      <div className="date">
                        {" "}
                        {this.state.shipment && this.state.shipment.closed_on
                          ? moment(this.state.shipment.closed_on).format(
                              "DD MMM, YYYY"
                            )
                          : null}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={
                        this.state.shipment.status === "rto_in_transit"
                          ? "rto-dot-active return-time-and-status-wraper"
                          : "return-time-and-status-wraper"
                      }
                    >
                      <div>
                        {this.state.shipment.status === "rto" ? (
                          "Returned"
                        ) : this.state.shipment.status === "lost" ? (
                          <span className="lost-shipment">Lost</span>
                        ) : this.state.shipment.status === "rto_in_transit" ? (
                          "Est. RTO Delivery Date"
                        ) : null}{" "}
                      </div>
                      <div className="date">
                        {this.state.shipment &&
                        this.state.shipment.status === "rto" &&
                        this.state.shipment.hasOwnProperty("closed_on")
                          ? moment(this.state.shipment.closed_on).format(
                              "DD MMM, YYYY"
                            )
                          : this.state.shipment &&
                            this.state.shipment.status === "rto_in_transit" &&
                            this.state.shipment.hasOwnProperty("expected_date")
                          ? moment(this.state.shipment.expected_date).format(
                              "DD MMM, YYYY"
                            )
                          : ""}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* order shipment new key closed_on added */}
            <div className="Order_tracking_status">
              {/* <p style={{ color: "#000" }}>
                {this.state.shipment.status === "delivered" &&
                this.state.shipment &&
                this.state.shipment.closed_on ? (
                  <>
                    <p style={{ color: "#898989" }}>
                      Shipment Tracking Status:
                    </p>
                    Delivered on{" "}
                    {moment(this.state.shipment.closed_on).format(
                      "MMM DD, YYYY"
                    )}
                  </>
                ) : this.state.shipment.status === "rto" &&
                  this.state.shipment &&
                  this.state.shipment.closed_on ? (
                  <>
                    <p style={{ color: "#898989" }}>
                      Shipment Tracking Status:
                    </p>
                    Returned on{" "}
                    {moment(this.state.shipment.closed_on).format(
                      "MMM DD, YYYY"
                    )}
                  </>
                ) : this.state.shipment.status === "rto" &&
                  this.state.shipment &&
                  this.state.shipment.rto_marked_on ? (
                  <>
                    <p style={{ color: "#898989" }}>
                      Shipment Tracking Status:
                    </p>
                    Return marked on{" "}
                    {moment(this.state.shipment.rto_marked_on).format(
                      "MMM DD, YYYY"
                    )}
                  </>
                ) : this.state.shipment.status === "transit" ? (
                  <>
                    <p style={{ color: "#898989" }}>
                      Shipment Tracking Status:
                    </p>
                    In Transit
                  </>
                ) : this.state.shipment.status === "manifested" ? (
                  <>
                    <p style={{ color: "#898989" }}>
                      Shipment Tracking Status:
                    </p>
                    Ready To Ship
                  </>
                ) : null}
              </p> */}

              <p style={{ color: "#000" }}>
                {this.state.shipment.status === "rto_in_transit" ? (
                  !!this.state.shipment.est_rto_live_date ? (
                    <>
                      <p style={{ color: "#898989" }}>
                        Expected RTO Live Date:
                      </p>
                      {moment(this.state.shipment.est_rto_live_date).format(
                        "DD MMM, YYYY"
                      )}
                    </>
                  ) : (
                    <>
                      <p style={{ color: "#898989" }}>
                        Expected RTO Live Date:
                      </p>
                      <p> Not Available</p>
                    </>
                  )
                ) : this.state.shipment.status === "delivered" &&
                  this.state.shipment &&
                  this.state.shipment.closed_on ? (
                  <>
                    <p style={{ color: "#898989" }}>
                      Shipment Tracking Status:
                    </p>
                    Delivered on{" "}
                    {moment(this.state.shipment.closed_on).format(
                      "DD MMM, YYYY"
                    )}
                  </>
                ) : this.state.shipment.status === "rto" &&
                  this.state.shipment &&
                  this.state.shipment.closed_on ? (
                  <>
                    <p style={{ color: "#898989" }}>
                      Shipment Tracking Status:
                    </p>
                    Returned on{" "}
                    {moment(this.state.shipment.closed_on).format(
                      "DD MMM, YYYY"
                    )}
                  </>
                ) : this.state.shipment.status !== "delivered" ||
                  this.state.shipment.status !== "rto" ||
                  this.state.shipment.status !== "ndr_actionable" ? (
                  !!this.state.shipment.expected_date ? (
                    <>
                      <p style={{ color: "#898989" }}>Delivery Expected By:</p>
                      {moment(this.state.shipment.expected_date).format(
                        "DD MMM, YYYY"
                      )}
                    </>
                  ) : (
                    <>
                      {(this.state.shipment.status === "rto" &&
                        this.state.shipment &&
                        !!this.state.shipment.closed_on) ||
                      (this.state.shipment.status === "delivered" &&
                        this.state.shipment &&
                        !!this.state.shipment.closed_on) ? (
                        <p style={{ color: "#898989" }}>
                          Delivery Expected By:
                        </p>
                      ) : (
                        <p style={{ color: "#898989" }}>
                          Shipment Tracking Status:
                        </p>
                      )}

                      <p> Not Available</p>
                    </>
                  )
                ) : !!this.state.shipment &&
                  !!this.state.shipment.rto_marked_on ? (
                  <>
                    <p style={{ color: "#898989" }}>
                      Shipment Tracking Status:
                    </p>
                    Return marked on{" "}
                    {moment(this.state.shipment.rto_marked_on).format(
                      "DD MMM, YYYY"
                    )}
                  </>
                ) : null}
              </p>
            </div>
          </div>

          {/* <div className="moredetail">
            {this.state.shipmentsVisible ? (
              <button onClick={() => this.toggleshipmentvisiable()}>
                Less Details
                <span className="lessDetails">
                  <svg
                    style={{
                      transition: "all 0.5s",
                      transform: `${
                        this.state.shipmentsVisible
                          ? "rotateX(180deg)"
                          : "rotateX(0deg)"
                      }`,
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_49"
                      data-name="Polygon 49"
                      transform="translate(16 8) rotate(180)"
                      fill={`${
                        this.state.shipmentsVisible ? "#FF0049" : "none"
                      }`}
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#FF0049"
                      />
                    </g>
                  </svg>
                </span>
              </button>
            ) : (
              <button onClick={() => this.toggleshipmentvisiable()}>
                More Details
                <span className="moredeatil">
                  <svg
                    style={{
                      transition: "all 0.5s",
                      transform: `${
                        this.state.shipmentsVisible
                          ? "rotateX(180deg)"
                          : "rotateX(0deg)"
                      }`,
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                  >
                    <g
                      id="Polygon_49"
                      data-name="Polygon 49"
                      transform="translate(16 8) rotate(180)"
                      fill={`${
                        this.state.shipmentsVisible ? "#898989" : "none"
                      }`}
                    >
                      <path d="M8,0l8,8H0Z" stroke="none" />
                      <path
                        d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                        stroke="none"
                        fill="#FF0049"
                      />
                    </g>
                  </svg>
                </span>
              </button>
            )}
          </div> */}
        </div>

        {/* {this.state.shipmentsVisible === true ? (
          this.state.shipment.shipments_journey.length > 0 ? (
            <div className="shipment_track_status">
              {this.state.shipment.shipments_journey.map((shipment) => (
                <div className="footer_data">
                  <div className="footer_data_text" style={{ color: "#000" }}>
                    <div className="circle">
                      {" "}
                      <div className="line" />
                    </div>

                    <div className="shipment-date">
                      <span>{shipment.date}</span>
                    </div>

                    <div className="shipmen-remark"> {shipment.remark}</div>
                    <div className="Shipment-location">
                      <span>{shipment.location}</span>
                      <span>|</span>
                      <span>{shipment.city}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="detail_not_available" style={{ fontWeight: 500 }}>
              {this.state.shipment.status === "manifested" ? (
                <span>
                  {" "}
                  Tracking details will be visible once , The shipment is
                  in-transit
                </span>
              ) : (
                <span>Details Not Available</span>
              )}
            </p>
          )
        ) : null} */}

        {this.state.shipment.shipments_journey.length > 0 ? (
          <div className="ist-timezone-txt">
            <p>
              NOTE: The following shipment status dates are under IST timezone
            </p>
            <div
              className={
                this.state.shipment &&
                this.state.shipment.shipments_journey &&
                this.state.shipment.shipments_journey.length === 1
                  ? "shipment_track"
                  : "shipment_track_status"
              }
            >
              {this.state.shipment.shipments_journey.map((shipment) => (
                <div className="footer_data">
                  <div className="footer_data_text" style={{ color: "#000" }}>
                    <div className="circle">
                      {" "}
                      <div className="line" />
                    </div>

                    <div className="shipment-date">
                      <span className="date-moment">
                        {" "}
                        {moment(shipment.date).format("DD MMM, YYYY")}
                      </span>
                      <span>|</span>
                      <span> {moment(shipment.date).format("dddd")}</span>
                    </div>

                    <div className="shipmen-remark">
                      {shipment && shipment.remark.length <= 45 ? (
                        shipment.remark
                      ) : (
                        <Tooltip title={shipment.remark}>
                          {" "}
                          {shipment.remark
                            ? `${shipment.remark.substring(0, 45)}...`
                            : null}
                        </Tooltip>
                      )}
                    </div>
                    <div className="Shipment-location">
                      <span>{shipment.location}</span>
                      <span>|</span>
                      <span>{shipment.city}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p className="detail_not_available" style={{ fontWeight: 500 }}>
            {this.state.shipment.status === "manifested" ? (
              <span>
                {" "}
                Tracking details will be visible once the shipment is In-Transit
              </span>
            ) : (
              <span>Details Not Available</span>
            )}
          </p>
        )}
      </div>
    );
  }
}

export default Shipmentprogressbar2;
