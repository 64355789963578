import React, { Component } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
  Tooltip,
  Legend,
} from "recharts";
import moment from "moment";
import { Row, Skeleton, Icon, Select } from "antd";
import { ReactComponent as OrangeCircle } from "../../../../../assets/images/icons/blueCircle.svg";
import { ReactComponent as BlueCircle } from "../../../../../assets/images/icons/OrangeCircle.svg";
import NotFoundCard from "../../../Common/NotFoundCard";
import ErrorCard from "../../../Common/ErrorCard";
import { formatBalance, formatNumber } from "../../../../../Helpers/Pricing";
import "./index.scss";
import Sorter from "../CustomSorter";
const Option = Select.Option;
class Graph extends Component {
  CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return this.graphTooltipHandler(this.props.data, label);
    }
    return null;
  };

  graphTooltipHandler(data, label) {
    if (this.props.graphName === "Orders to VF Status") {
      let graphdata = data.find((data) => data.item_shipmentstatus === label);

      return (
        <div className="graph-custom-tooltip">
          {/* <div className="date">
            {this.props.startDate} - {this.props.endDate}
          </div> */}
          <div className="details">
            <div className="icon">
              <Icon component={OrangeCircle} />
            </div>
            <div className="text">
              <p>
                {graphdata.item_shipmentstatus} -{" "}
                <span className="light-bold">
                  {" "}
                  {!!graphdata.total_order_count
                    ? graphdata.total_order_count
                    : 0}{" "}
                  Shipments
                </span>{" "}
                {/* <p> */}
                {/* </p> */}
              </p>
            </div>
          </div>
        </div>
      );
    }

    if (this.props.graphName === "Orders on Store vs Orders to VF") {
      let graphdata = this.props.originalData.find(
        // (data) => moment(data.date).format("DD MMM") === label
        (data) => data.date === label
      );

      return (
        <div className="graph-custom-tooltip">
          <div className="date">
            {this.props.sortingOrder === "months"
              ? moment(graphdata.date).format("MMM, YYYY")
              : moment(graphdata.date).format("DD MMM, YYYY")}
          </div>
          <div className="details">
            <div className="icon">
              <Icon component={BlueCircle} />
            </div>
            <div className="text">
              <p>Orders On Store -</p>
              <p>
                <span style={{ fontWeight: "400" }}>Amount: </span>
                {!!formatBalance(graphdata.total_shopify_orders_amount)
                  ? `₹ ` + formatBalance(graphdata.total_shopify_orders_amount)
                  : 0}
              </p>
              <p>
                <span style={{ fontWeight: "400" }}>Count:</span>{" "}
                {!!graphdata.total_shopify_orders_count
                  ? graphdata.total_shopify_orders_count
                  : 0}
              </p>
            </div>
          </div>

          <div className="details last">
            <div className="icon">
              <Icon component={OrangeCircle} />
            </div>
            <div className="text">
              <p>Orders to vFulfill -</p>
              <p>
                {" "}
                <span style={{ fontWeight: "400" }}>Amount:</span>{" "}
                {!!formatBalance(graphdata.total_vf_orders_amount)
                  ? `₹ ` + formatBalance(graphdata.total_vf_orders_amount)
                  : 0}
              </p>
              <p>
                <span style={{ fontWeight: "400" }}>Count:</span>{" "}
                {!!graphdata.total_vf_orders_count
                  ? graphdata.total_vf_orders_count
                  : 0}
              </p>
            </div>
          </div>
        </div>
      );
    }

    if (this.props.graphName === "Daily COD Collection Amount") {
      let graphdata = this.props.originalData.find(
        // (data) => moment(data.date).format("DD MMM") === label
        (data) => data.date === label
      );

      return (
        <div className="graph-custom-tooltip">
          <div className="date">
            {moment(graphdata.date).format("DD MMM, YYYY")}
          </div>
          <div className="details">
            <div className="icon">
              <Icon component={BlueCircle} />
            </div>
            <div className="text">
              <p>COD Collection Amount -</p>
              <p>
                {" "}
                <span style={{ fontWeight: "400" }}>Amount:</span>{" "}
                {!!formatNumber(graphdata.amount)
                  ? `₹ ` + formatNumber(graphdata.amount)
                  : 0}
              </p>
            </div>
          </div>
        </div>
      );
    }

    if (
      this.props.graphName === "Total Order Amount vs Delivered Order Amount"
    ) {
      let graphdata = this.props.originalData.find(
        // (data) => moment(data.date).format("DD MMM") === label
        (data) => data.date === label
      );

      return (
        <div className="graph-custom-tooltip">
          <div className="date">
            {this.props.sortingOrder === "months"
              ? moment(graphdata.date).format("MMM, YYYY")
              : moment(graphdata.date).format("DD MMM, YYYY")}
          </div>
          <div className="details">
            <div className="icon">
              <Icon component={BlueCircle} />
            </div>
            <div className="text">
              <p>Total Order Amount -</p>
              <p>
                <span style={{ fontWeight: "400" }}>Amount: </span>
                {!!formatBalance(graphdata.pp_cost)
                  ? `₹ ` + formatBalance(graphdata.pp_cost)
                  : formatBalance(graphdata.pp_cost)}
              </p>

              <p>
                {" "}
                <span style={{ fontWeight: "400" }}> Count: </span>
                {!!graphdata.pp_count ? graphdata.pp_count : 0}
              </p>
            </div>
          </div>

          <div className="details last">
            <div className="icon">
              <Icon component={OrangeCircle} />
            </div>
            <div className="text">
              <p>Delivered Order Amount -</p>
              <p>
                <span style={{ fontWeight: "400" }}>Amount: </span>
                {!!formatBalance(graphdata.delivered_pp_cost)
                  ? `₹ ` + formatBalance(graphdata.delivered_pp_cost)
                  : 0}
              </p>
              <p>
                {" "}
                <span style={{ fontWeight: "400" }}> Count: </span>{" "}
                {!!graphdata.delivered_pp_count
                  ? graphdata.delivered_pp_count
                  : 0}
              </p>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  graphDataHandler(data, graphName) {
    return data;
  }

  kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
      : Math.sign(num) * Math.abs(num);
  }

  onlyMonth(d) {
    return moment(d).format("MMM");
  }

  dateAndMonth(d) {
    return moment(d).format("DD MMM");
  }

  normalValue(d) {
    return d;
  }

  custom() {
    if (
      (this.props.graphName ===
        "Total Order Amount vs Delivered Order Amount" ||
        this.props.graphName === "Orders on Store vs Orders to VF") &&
      this.props.sortingOrder === "months"
    ) {
      return this.onlyMonth;
    }

    if (this.props.graphName === "Orders to VF Status") {
      return this.normalValue;
    }

    return this.dateAndMonth;
  }
  renderCustomizedLabel(props) {
    const { x, y, width, height, value } = props;
    const radius = 10;

    return (
      <g>
        {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
        <text
          x={x + width / 2}
          y={y - radius}
          fill="#000"
          fontSize="10px"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {!!value ? this.kFormatter(value) : null}
        </text>
      </g>
    );
  }

  render() {
    const {
      data,
      firstBar,
      secondBar,
      yAxisKey,
      graphName,
      startDate,
      endDate,
      loading,
      firstLegendText,
      secondLegendText,
      error,
      errorCode,
      errorMsg,
    } = this.props;

    const graphdata = this.graphDataHandler(data, graphName);

    const PageWrapper = (children) => {
      return <div>{children}</div>;
    };

    if (loading) {
      return (
        <Row style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </Row>
      );
    }

    if (!loading && error) {
      return (
        <div className="orders-to-vf-graph-wraper" id="fade-in">
          <div className="graph-head">
            {" "}
            <div className="graph-head-child">
              <span className="name">{graphName}</span> |{" "}
              <span>
                {startDate} - {endDate}
              </span>
            </div>
            {graphName === "Orders on Store vs Orders to VF" ? (
              <div className="compare" />
            ) : null}
          </div>
          <ErrorCard errorMsg={errorMsg} errorCode={errorCode} />
        </div>
      );
    }

    if (this.props.data.length === 0) {
      return (
        <div className="orders-to-vf-graph-wraper" id="fade-in">
          <div className="graph-head">
            {" "}
            <div className="graph-head-child">
              <span className="name">{graphName}</span> |{" "}
              <span>
                {startDate} - {endDate}
              </span>
            </div>
            {graphName === "Orders on Store vs Orders to VF" ? (
              <div className="compare">
                {" "}
                {/* <Sorter
                  sortingOrder={this.props.sortingOrder}
                  sorterFunction={(e) => this.props.dropdownChange(e)}
                /> */}
              </div>
            ) : null}
          </div>

          <NotFoundCard
            mainHeading={"Oops! No Data Found."}
            secondHeading={
              <p>
                Looks like there are no graph data in{" "}
                <span>current Date Range</span>.
              </p>
            }
            thirdHeading={
              <p>
                Please <span>select another Date Range</span> from Date Picker
                to filter data.
              </p>
            }
          />
        </div>
      );
    }

    return PageWrapper(
      <div className="orders-to-vf-graph-wraper" id="fade-in">
        <div className="graph-head">
          {" "}
          <div className="graph-head-child">
            <span className="name">{graphName}</span> |{" "}
            <span>
              {startDate} - {endDate}
            </span>
          </div>
          <div className="abs-div">
            {" "}
            <div className="first-legend">
              <Icon
                // component={BlueCircle}
                component={
                  graphName === "Orders to VF Status"
                    ? OrangeCircle
                    : BlueCircle
                }
              />{" "}
              <span>{firstLegendText}</span>{" "}
            </div>
            {secondLegendText ? (
              <div className="second-legend">
                <Icon component={OrangeCircle} />{" "}
                <span>{secondLegendText}</span>{" "}
              </div>
            ) : null}
          </div>
          {graphName === "Orders on Store vs Orders to VF" ? (
            <div className="compare">
              {" "}
              <Sorter
                sortingOrder={this.props.sortingOrder}
                sorterFunction={(e) => this.props.dropdownChange(e)}
              />
            </div>
          ) : null}
          {graphName === "Total Order Amount vs Delivered Order Amount" ? (
            <div className="compare">
              {" "}
              <Sorter
                sortingOrder={this.props.sortingOrder}
                sorterFunction={(e) => this.props.dropdownChange(e)}
              />
            </div>
          ) : null}
        </div>

        <ResponsiveContainer height={300}>
          <BarChart data={graphdata}>
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <XAxis tickFormatter={this.custom()} dataKey={yAxisKey} />
            <YAxis />
            <Tooltip cursor={false} content={this.CustomTooltip} />

            <Legend />

            <Bar
              label={firstBar}
              dataKey={firstBar}
              barSize={18}
              fill={`${
                graphName === "Orders to VF Status" ? `#2C9AFC` : `#F85F21`
              }`}
            >
              {" "}
              <LabelList
                dataKey={firstBar}
                content={(d) => this.renderCustomizedLabel(d)}
              />
            </Bar>

            {secondBar ? (
              <Bar
                label={secondBar}
                dataKey={secondBar}
                barSize={18}
                fill="#2C9AFC"
              >
                {" "}
                <LabelList
                  dataKey={secondBar}
                  content={(d) => this.renderCustomizedLabel(d)}
                />
              </Bar>
            ) : null}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default Graph;
