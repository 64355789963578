import React, { Component } from "react";
import { Modal, Input, message, Button } from "antd";
import "./index.scss";
import axios from "axios";

export default class AddCouponModal extends Component {
  state = {
    loading: false,
    couponCode: "",
    requestLoading: false,
    // successModal: {
    //   visible: false
    // }
  };

  addCouponRequest() {
    this.setState(
      {
        requestLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/wallet/add_coupon",
          method: "POST",
          data: {
            coupon_code: this.state.couponCode,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                requestLoading: false,
              });
              message.success("Coupon Added Successfully");
              this.props.toggleAddCouponModal();
              window.location.reload();
            } else {
              this.setState({
                requestLoading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((err) => {
            this.setState({
              requestLoading: false,
            });
            message.error(err.message);
          });
      }
    );
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  // toggleSuccessModal() {
  //   this.setState({
  //     successModal: {
  //       ...this.state.successModal,
  //       visible: !this.state.successModal.visible
  //     }
  //   });
  // }

  render() {
    return (
      <>
        <Modal
          className="withdrawalModal"
          title={
            <div className="head-background">
              <div>
                <h1>ADD COUPON</h1>
                <p>TO YOUR vFULFILL ACCOUNT</p>
              </div>
            </div>
          }
          visible={this.props.visible}
          onCancel={() => this.props.toggleAddCouponModal()}
          width={480}
          destroyOnClose="true"
          footer={
            <Button
              block
              onClick={() => this.addCouponRequest()}
              loading={this.state.requestLoading}
            >
              Add Coupon Code
            </Button>
          }
        >
          <div style={{ textAlign: "center" }}>
            <div style={{ padding: "24px 30px" }}>
              <div className="withdrawal-request">
                <p>Enter Your Coupon Code Here:</p>
              </div>
              <div className="funds-input">
                <Input
                  value={this.state.couponCode}
                  onChange={(e) => this.onChange("couponCode", e.target.value)}
                />
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
