import React, { Component } from "react";
import Header from "../Header";
// import Footer from "../Footer";
import { Link } from "react-router-dom";
import { message, Icon } from "antd";
import IndianProfile from "./IndianProfile";
import NonIndianProfile from "./NonIndianProfile";
import "./index.scss";
import axios from "axios";
import { ReactComponent as indainflagican } from "../../../assets/images/icons/indianFlag.svg";
import { ReactComponent as globalflagican } from "../../../assets/images/icons/Globalflag.svg";
class VerifyProfile extends Component {
  state = {
    type: "",
    kycType: "",
    childStep: null,
  };

  childToParrentSteps(value) {
    this.setState({ childStep: value });
  }

  toggleFirststepBackbutton() {
    this.setState({ type: "" });
  }

  componentDidUpdate(pp) {
    if (JSON.stringify(pp.kycData) !== JSON.stringify(this.props.kycData)) {
      this.setState({
        type: this.props.kycData.kyc_country
          ? this.props.kycData.kyc_country
          : "",
      });
    }
  }
  componentDidMount() {
    this.setState({
      type: this.props.kycData.kyc_country,
    });
  }

  parrentToChildKycValue(value) {
    this.setState({
      kycType: value,
    });
  }

  setType = (type) => {
    this.setState({ type });
  };

  saveUserKYC = (values) => {
    axios({
      url: process.env.REACT_APP_API_URL + "/user/save_user_kyc",
      method: "post",
      data: {
        kycdata: {
          ...values,
          kyc_country: this.state.type,
          name: values.individual_name,
        },
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          message.success("Details saved successfully.");
          this.props.goToNextStep();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  render() {
    return (
      <>
        <div className="verify-profile">
          {this.state.type === undefined || this.state.type === "" ? (
            <Header
              title="Let Us Know More About You"
              subtitle="Fill in or review your necessary basic details."
            />
          ) : null}

          <div className="content-container">
            {this.state.type === "indian" ? (
              <IndianProfile
                {...this.props}
                kycData={this.props.kycData}
                goToNextStep={this.props.goToNextStep}
                saveUserKYC={(values) => this.saveUserKYC(values)}
                stepStatus={this.props.stepStatus}
                parrentToChildKycValue={(val) =>
                  this.parrentToChildKycValue(val)
                }
                toggleFirststepBackbutton={() =>
                  this.toggleFirststepBackbutton()
                }
                childToParrentSteps={(e) => this.childToParrentSteps(e)}
                activeStep={this.props.activeStep}
                previousdatastoe={(key, value) =>
                  this.props.previousdatastoe(key, value)
                }
                kycData1={this.props.kycData1}
                previousStep={() => this.props.previousStep()}
              />
            ) : this.state.type === "non_indian" ? (
              <NonIndianProfile
                {...this.props}
                kycData={this.props.kycData}
                goToNextStep={this.props.goToNextStep}
                saveUserKYC={(values) => this.saveUserKYC(values)}
                stepStatus={this.props.stepStatus}
                toggleFirststepBackbutton={() =>
                  this.toggleFirststepBackbutton()
                }
                parrentToChildKycValue={(val) =>
                  this.parrentToChildKycValue(val)
                }
                nonindiankycdata1={this.props.nonindiankycdata1}
                nonIndianPreviousDataStoe={(key, value) =>
                  this.props.nonIndianPreviousDataStoe(key, value)
                }
              />
            ) : (
              <>
                <p className="select-option-txt">
                  Please select the relevant option. You are:
                </p>
                <div className="profile-indian-global">
                  <div className="profile-indian">
                    <button
                      className="profile-type-card"
                      onClick={() => this.setType("indian")}
                    >
                      <Icon component={indainflagican} />
                      <span className="flag">
                        An {""}
                        <span className="registered-india">
                          Individual or Organisation
                        </span>{" "}
                        Registered in India
                      </span>
                      <span className="footer">Continue</span>
                    </button>
                  </div>

                  <div className="profile-global">
                    <button
                      href="#"
                      className="profile-type-card"
                      onClick={() => this.setType("non_indian")}
                    >
                      <Icon component={globalflagican} />
                      <span className="flag">
                        An {""}
                        <span className="not-registered-india">
                          Individual or Organisation{" "}
                        </span>
                        Registered outside India
                      </span>
                      <span className="footer">Continue</span>
                    </button>
                  </div>
                </div>
                <Link
                  to={`/${this.props.auth.store.store_geo}/${
                    this.props.auth.store.id
                  }`}
                >
                  <button className="onboarding-skip">Skip This Step</button>
                </Link>
              </>
            )}
          </div>
          {/* <div
            className={
              this.state.type === "" || this.state.kycType === ""
                ? "first-step-footer"
                : null
            }
          >
            {this.state.type === "" || this.state.kycType === "" ? (
              <Footer />
            ) : null}
          </div> */}
        </div>
      </>
    );
  }
}

export default VerifyProfile;
