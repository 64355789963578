import React, { Component } from "react";
import { Modal, Button } from "antd";
import { SHOPIFY_NAME } from "../../../../../../constants";
import "./index.scss";

class ReconectStoremodal extends Component {
  render() {
    const { visible, onCancel } = this.props;
    return (
      <Modal
        className="reconnect_storeModal"
        title={
          <div
            style={{
              width: "100%",
              borderRadius: "3px",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <svg
              style={{ marginRight: "10px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <circle
                id="Ellipse_24"
                data-name="Ellipse 24"
                cx="12"
                cy="12"
                r="12"
                fill="#fd9526"
              />
              <path
                id="Path_104"
                data-name="Path 104"
                d="M6084-815.835v8"
                transform="translate(-6072 821.727)"
                fill="#fff"
                stroke="#fff"
                stroke-linecap="round"
                stroke-width="2"
              />
              <g
                id="Path_105"
                data-name="Path 105"
                transform="translate(11 16)"
                fill="#fff"
              >
                <path
                  d="M 1 1.5 C 0.7243000268936157 1.5 0.5 1.275699973106384 0.5 1 C 0.5 0.7243000268936157 0.7243000268936157 0.5 1 0.5 C 1.275699973106384 0.5 1.5 0.7243000268936157 1.5 1 C 1.5 1.275699973106384 1.275699973106384 1.5 1 1.5 Z"
                  stroke="none"
                />
                <path
                  d="M 1 0 C 1.552279949188232 0 2 0.4477200508117676 2 1 C 2 1.552279949188232 1.552279949188232 2 1 2 C 0.4477200508117676 2 0 1.552279949188232 0 1 C 0 0.4477200508117676 0.4477200508117676 0 1 0 Z"
                  stroke="none"
                  fill="#fff"
                />
              </g>
            </svg>

            <span
              style={{
                textAlign: "center",
                marginTop: "16px",
                fontWeight: 500,
                letterSpacing: "0.032em",
                lineHeight: "24px",
              }}
            >
              {`${SHOPIFY_NAME} Not Connected!`}
            </span>
          </div>
        }
        visible={visible}
        onCancel={onCancel}
        // afterClose={this.resetModal}
        width={392}
        maskClosable={false}
        footer={[
          <div className="d">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="16"
              viewBox="0 0 8 16"
              style={{ display: "block" }}
            >
              <g
                id="Polygon_63"
                data-name="Polygon 63"
                transform="translate(0 16) rotate(-90)"
                fill="none"
              >
                <path
                  d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                  stroke="none"
                  fill="#898989"
                />
              </g>
            </svg>
            <Button
              className="bt"
              key="back"
              onClick={() => this.props.onCancel()}
            >
              Cancel & Go Back
            </Button>
          </div>,
        ]}
      >
        <div className="middledata">
          <p>
            {`Seems like your ${SHOPIFY_NAME} is no longer connected with vFulfill.`}
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p />
            <a
              href={this.props.shopifyConnectURL}
            >{`Connect ${SHOPIFY_NAME}`}</a>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ReconectStoremodal;
