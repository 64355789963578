import React from "react";
import "./index.scss";
import { Radio } from "antd";

function PrepaidOrders(props) {
  return (
    <div className="prepaid-order-content">
      {/* <div className="enable-order-text">
        <p>Use Contact Centre Calling Services to:</p>
        <div className="calling-services">
          <Radio.Group
            value={props.data.callingServiceUse}
            onChange={(e) =>
              props.updateSetting(
                "prepaid",
                "callingServiceUse",
                e.target.value
              )
            }
          >
            <Radio value="NEW_ORDER">Verify all newly placed orders</Radio>
            <Radio value="MANUAL_ORDER">
              Verify only those Orders which are manually marked for Calling
            </Radio>
          </Radio.Group>
        </div>
      </div> */}

      <div className="enable-order-text">
        <p>Take following action(s) on Orders Confirmed from Contact Center:</p>

        <div className="calling-services">
          <Radio.Group
            value={props.data.ordersConfirmed}
            onChange={(e) =>
              props.updateSetting("prepaid", "ordersConfirmed", e.target.value)
            }
          >
            <Radio value="ORDER_VERIFIED">Mark the Order as verified</Radio>
            <Radio value="PLACE_ORDER">
              Mark the Order as Verified & also place the Order to vFulfill
            </Radio>
          </Radio.Group>
        </div>
      </div>

      {/* <div className="enable-order-text">
        <p>Take following action(s) if Customer declines:</p>

        <div className="calling-services">
          <Radio.Group
            value={props.data.CustomerDecline}
            onChange={(e) =>
              props.updateSetting("prepaid", "CustomerDecline", e.target.value)
            }
          >
            <Radio value="CANCEL_ORDER">Cancel the Order</Radio>
            <Radio value="ORDER_PROCESS_REFUND">
              Cancel the Order & also process Refund
            </Radio>
            <Radio value="ORDER_VERIFIED">
              Mark the Order as Verified anyway
            </Radio>
            <Radio value="VERIFIED_PLACE_ORDER">
              Mark the Order as Verified anyway & also place the Order to
              vFulfill
            </Radio>
            <Radio value="DO_NOTHING">
              Do Nothing (A note will be created with the remarks from Customer)
            </Radio>
          </Radio.Group>
        </div>
      </div> */}

      {/* <div className="enable-order-text">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <p>Take following action(s) on if Customer is not Reachable:</p>
          </div>

          <div className="contact-center-tooltip">
            <span className="tooltiptext">
              We declare a customer <u>Non-reachable</u> only when we have made{" "}
              <u>7 calling attempts to his phone number.</u>
            </span>

            <svg
              className="reachable-message"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 17"
              style={{
                marginTop: "15px",
                marginRight: "8px",
                color: "#898989",
              }}
            >
              <g id="Group_3299" transform="translate(-736 -651)">
                <g
                  id="Ellipse_41"
                  transform="translate(736 652)"
                  fill="none"
                  stroke="#898989"
                >
                  <circle cx="8" cy="8" r="8" stroke="none" />
                  <circle cx="8" cy="8" r="7.5" fill="none" />
                </g>
                <text
                  transform="translate(742.5 651)"
                  fill="#898989"
                  fontSize="12"
                  fontFamily="Poppins-Medium, Poppins"
                  fontWeight="500"
                  letterSpacing="0.032em"
                >
                  <tspan x="0" y="13">
                    i
                  </tspan>
                </text>
              </g>
            </svg>
          </div>
        </div>
        <div className="calling-services">
          <Radio.Group
            value={props.data.customerNotReachable}
            onChange={(e) =>
              props.updateSetting(
                "prepaid",
                "customerNotReachable",
                e.target.value
              )
            }
          >
            <Radio value="CANCEL_ORDER">Cancel the Order</Radio>
            <Radio value="ORDER_PROCESS_REFUND">
              Cancel the Order & also process Refund
            </Radio>
            <Radio value="ORDER_VERIFIED">
              Mark the Order as Verified anyway
            </Radio>
            <Radio value="VERIFIED_PLACE_ORDER">
              Mark the Order as Verified anyway & also place the Order to
              vFulfill
            </Radio>
            <Radio value="DO_NOTHING">
              Do Nothing (A note will be created with the remarks from Customer)
            </Radio>
          </Radio.Group>
        </div>
      </div> */}
    </div>
  );
}

export default PrepaidOrders;
