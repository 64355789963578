import React, { Component } from "react";
import { Select, Checkbox, Divider, Icon, Button } from "antd";
import { ReactComponent as Arrow } from "../../../../../assets/images/icons/arrow.svg";
import "./index.scss";
const { Option, OptGroup } = Select;

class MavFilter extends Component {
  state = {
    selected: this.props.value,
    open: false,
    searchText: "",
    arrow: false,
  };

  componentDidUpdate(pp) {
    if (pp.value !== this.props.value) {
      this.setState({ selected: this.props.value });
    }
  }

  selectAll() {
    let selected = this.props.tags;
    this.setState({ selected: selected }, () =>
      this.props.changeHandler(this.state.selected)
    );
  }

  render() {
    const selectProps = {
      mode: "multiple",
      // open: true,
      style: { width: this.props.width },
      value: this.props.value,
      searchValue: this.state.searchText,
      dropdownMatchSelectWidth: true,
      dropdownClassName: this.props.overlayClass,
      maxTagCount: 2,
      dropdownRender: (menu) => (
        <div>
          {menu}
          <Divider style={{ margin: "4px 0" }} />
          <div
            className="overlay-footer"
            style={{ cursor: "pointer" }}
            onMouseDown={(e) => e.preventDefault()}
          >
            {this.state.selected.length > 0 ? (
              <Button
                type=""
                onClick={() =>
                  this.setState({ selected: [] }, () =>
                    this.props.changeHandler(this.state.selected)
                  )
                }
              >
                Clear All
              </Button>
            ) : (
              <Button onClick={() => this.selectAll()} type="primary">
                Select All
              </Button>
            )}
          </div>
        </div>
      ),
      onSearch: (t) => this.setState({ searchText: t }),
      filterOption: (input, option) =>
        String(option.props.children)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0,
      onChange: (d) =>
        this.setState({ selected: d }, () =>
          this.props.changeHandler(this.state.selected)
        ),
      onDropdownVisibleChange: (d) => this.setState({ arrow: d }),
    };

    const PageWrapper = (children) => {
      return (
        <div style={{ height: "40px" }}>
          <div className={`tag-filter-wraper`}>
            <div className="custom-text">
              Shopify Tags:
              {!!this.state.selected.length ||
              !!this.state.searchText.length ? null : (
                <span>All</span>
              )}
            </div>

            <Select {...selectProps}>
              {this.props.tags.map((d, i) => {
                return (
                  <Option key={d} value={d}>
                    {d}
                  </Option>
                );
              })}
            </Select>
            <div
              className={`custom-arrow-icon ${this.state.arrow ? `open` : ``}`}
            >
              <Arrow />
            </div>
          </div>
        </div>
      );
    };

    return PageWrapper();
  }
}

export default MavFilter;
