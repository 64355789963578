import React from "react";
import { Modal, Button } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import "./index.scss";

class OverDueModal extends React.Component {
  dateHandler(date) {
    let d = moment(date).add(7, "days");

    if (date) {
      return d.format("DD MMM");
    }
  }

  render() {
    return (
      <Modal
        width={420}
        footer={null}
        closable={true}
        className="overdue-modal"
        onCancel={() => this.props.onCancel()}
        visible={this.props.visible}
        title={
          <>
            <h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="48"
                viewBox="0 -960 960 960"
                width="48"
              >
                <path d="M360-840v-80h240v80H360Zm80 440h80v-240h-80v240Zm40 320q-74 0-139.5-28.5T226-186q-49-49-77.5-114.5T120-440q0-74 28.5-139.5T226-694q49-49 114.5-77.5T480-800q62 0 119 20t107 58l56-56 56 56-56 56q38 50 58 107t20 119q0 74-28.5 139.5T734-186q-49 49-114.5 77.5T480-80Zm0-80q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-280Z" />
              </svg>{" "}
              Important Notice!
            </h1>

            {!!this.props.due_from ? (
              <p>
                Payment Overdue by {this.props.due_from}{" "}
                {this.props.due_from == 1 ? "day" : "days"}.
              </p>
            ) : (
              <p>Payment Overdue from now</p>
            )}
          </>
        }
      >
        <>
          <p>
            We regret to inform you that we encountered an issue processing your
            recent subscription payment. As a result, your account payment is
            currently {this.props.due_from}{" "}
            {this.props.due_from == 1 ? "day" : "days"} overdue.
          </p>

          <br />

          <p>
            Unless the outstanding amount is settled, your account will be{" "}
            <b>
              suspended on{" "}
              {!!this.props.due_from_date
                ? this.dateHandler(this.props.due_from_date)
                : null}
            </b>
            .
          </p>

          <br />

          <p>
            To avoid any inconvenience, we kindly request that you take
            immediate action to make the necessary payment.
          </p>

          <Link to={`/profile?plan`}>
            <Button onClick={() => this.props.onCancel()} block type="primary">
              Make Payment Now
            </Button>
          </Link>

          <Button onClick={() => this.props.onCancel()} block type="cancel">
            Skip For Now
          </Button>
        </>
      </Modal>
    );
  }
}

export default OverDueModal;
