import React, { Component } from "react";
import { Form, message } from "antd";
import KycIndividualOrganisation from "./KycIndividualOrganisation";
import Kycform from "./Kycform";
import SupportEmail from "./Kycform/SupportEmailModal";
import SupportPhoneNumber from "./Kycform/SupportPhoneNoModal";
import axios from "axios";

class IndianProfile extends Component {
  state = {
    currentStep: this.props.step - 3,
    totalSteps: 2,
    kycType: this.props.kycData.kyc_type ? this.props.kycData.kyc_type : "",
    files: {},
    questions: {
      phonecall: this.props.kycData.phone_number
        ? this.props.kycData.phone_number
        : "",
      dialCode: "IND +91",
    },
    supportNumber2: {
      contactno: "",
      prefix_1: "91",
    },
    phoneverified: 0,
    emailverified: 0,
    supportEmail: {
      visible: false,
    },
    reset: false,
    supportNumber: {
      visible: false,
    },
    supportEmail2: {
      EmailInpute: "",
    },
    loading: false,
    chnagenumber: false,
    emailotp: {
      emailotp1: "",
      emailotp2: "",
      emailotp3: "",
      emailotp4: "",
      emailotp5: "",
      emailotp6: "",
    },
    phoneotp: {
      phoneotp1: "",
      phoneotp2: "",
      phoneotp3: "",
      phoneotp4: "",
      phoneotp5: "",
      phoneotp6: "",
    },
    verifiedmsg: false,
    verifiedEmailId: "",
    verifiedcontact: false,
    verifiedPhoneNO: "",
    kycData1: {},

    numberOtpLoading: false,
    emailOtpLoading: false,
  };

  // paste function

  pasteFunc(ev) {
    const clip = ev.clipboardData.getData("text"); // Get clipboard data
    const pin = clip.replace(/\s/g, ""); // Sanitize string
    const ch = [...pin]; // Create array of chars

    if (ch.length === 6) {
      this.setState({
        phoneotp: {
          phoneotp1: ch[0],
          phoneotp2: ch[1],
          phoneotp3: ch[2],
          phoneotp4: ch[3],
          phoneotp5: ch[4],
          phoneotp6: ch[5],
        },
      });
    }
  }

  pasteFuncEmail(ev) {
    const clip = ev.clipboardData.getData("text"); // Get clipboard data
    const pin = clip.replace(/\s/g, ""); // Sanitize string
    const ch = [...pin]; // Create array of chars

    if (ch.length === 6) {
      this.setState({
        emailotp: {
          emailotp1: ch[0],
          emailotp2: ch[1],
          emailotp3: ch[2],
          emailotp4: ch[3],
          emailotp5: ch[4],
          emailotp6: ch[5],
        },
      });
    }
  }

  previousdatastoe(key, value) {
    this.setState({
      kycData1: {
        ...this.state.kycData1,
        [key]: value,
      },
    });
  }

  otpChnageNumber(field, value) {
    this.setState({
      phoneotp: {
        ...this.state.phoneotp,
        [field]: value,
      },
    });
  }

  toggleChnageNumber() {
    this.setState({
      chnagenumber: !this.state.chnagenumber,
    });
  }

  toggleSupportNumberModal() {
    this.setState({
      supportNumber: {
        visible: !this.state.supportNumber.visible,
      },
    });
  }

  againReset() {
    this.setState({
      reset: false,
    });
  }

  // phonecallChangeHandler(val) {
  //   if (val.phone.length === 0 && val.phone === "") {
  //     this.setState((state) => ({
  //       questions: {
  //         ...state.questions,
  //         phonecall: "",
  //       },
  //     }));
  //   } else if (val.phone.length <= 10 && parseInt(val.phone)) {
  //     this.setState((state) => ({
  //       questions: {
  //         ...state.questions,
  //         phonecall: parseInt(val.phone),
  //       },
  //     }));
  //   }
  // }

  handleContactChange(value) {
    if (value.length === 0 && value === "") {
      this.setState({
        supportNumber2: {
          ...this.state.supportNumber2,
          contactno: "",
        },
      });
    } else if (value.length <= 10 && parseInt(value)) {
      this.setState({
        supportNumber2: {
          ...this.state.supportNumber2,
          contactno: value,
        },
      });
    }
  }

  updatePhonenumber() {
    this.setState(
      {
        numberOtpLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/generateotp",
          method: "post",
          data: {
            type: "mobile",
            status: "new",
            location: "support",
            change: 1,
            mobile: {
              number: this.state.supportNumber2.contactno,
              code: "91",
            },
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                numberOtpLoading: false,
                reset: !this.state.reset,
                supportNumber2: {
                  ...this.state.supportNumber2,
                },
              });

              message.success("Phone number has been updated!!");
              this.toggleChnageNumber();
            } else {
              this.setState({
                // reset: false,
                ...this.state.supportNumber2.contactno,
                numberOtpLoading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
            this.setState({
              ...this.state.supportNumber2.contactno,
              numberOtpLoading: false,
            });
          });
      }
    );
  }

  VerifyPhone() {
    this.setState(
      {
        numberOtpLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/generateotp",
          method: "post",
          withCredentials: true,
          data: {
            type: "mobile",
            location: "support",
            status: "new",
            mobile: {
              number: this.state.supportNumber2.contactno,
              code: "91",
            },
          },
        })
          .then((res) => {
            if (res.data.success === 2) {
              message.success(res.data.msg);
              this.setState({
                numberOtpLoading: false,
                verifiedcontact: true,
                verifiedPhoneNO: this.state.supportNumber2.contactno,
                phoneverified: 1,
              });
              return;
            } else {
              if (res.data.success === 1) {
                this.setState({
                  numberOtpLoading: false,
                });
                this.toggleSupportNumberModal();
                message.success(res.data.msg);
              } else {
                this.setState({
                  numberOtpLoading: false,
                });
                message.error(res.data.msg);
              }
            }
          })
          .catch((e) => {
            this.setState({
              numberOtpLoading: false,
            });
            message.error(e.msg);
          });
      }
    );
  }

  verifyPhoneContinue = () => {
    this.setState(
      {
        numberOtpLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/verify_otp",
          method: "post",
          withCredentials: true,
          data: {
            location: "support",
            type: "mobile",
            mobile: {
              number: this.state.supportNumber2.contactno,
              code: "91",
              otp: Number(
                this.state.phoneotp.phoneotp1 +
                  this.state.phoneotp.phoneotp2 +
                  this.state.phoneotp.phoneotp3 +
                  this.state.phoneotp.phoneotp4 +
                  this.state.phoneotp.phoneotp5 +
                  this.state.phoneotp.phoneotp6
              ),
            },
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                numberOtpLoading: false,
                phoneverified: 1,
                supportNumber2: {
                  ...this.state.supportNumber2,
                },
              });
              message.success(res.data.msg);
              this.toggleSupportNumberModal();
            } else {
              this.setState({
                numberOtpLoading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              numberOtpLoading: false,
            });
            message.error(e.message);
          });
      }
    );
  };

  toggleSupportModal() {
    this.setState({
      supportEmail: {
        visible: !this.state.supportEmail.visible,
      },
    });
  }

  handleEmailChnage(value) {
    this.setState({
      supportEmail2: {
        ...this.state.supportEmail2,
        EmailInpute: value,
      },
    });
  }
  otpChnageEmail(field, value) {
    this.setState({
      emailotp: {
        ...this.state.emailotp,
        [field]: value,
      },
    });
  }

  verifyEmailContinue = () => {
    this.setState(
      {
        emailOtpLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/verify_otp",
          method: "post",
          withCredentials: true,
          data: {
            location: "support",
            type: "email",
            email: {
              email: this.state.supportEmail2.EmailInpute,
              otp: Number(
                this.state.emailotp.emailotp1 +
                  this.state.emailotp.emailotp2 +
                  this.state.emailotp.emailotp3 +
                  this.state.emailotp.emailotp4 +
                  this.state.emailotp.emailotp5 +
                  this.state.emailotp.emailotp6
              ),
            },
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                emailverified: 1,
                supportEmail2: {
                  ...this.state.supportEmail2,
                },
                emailOtpLoading: false,
              });
              message.success(res.data.msg);
              this.toggleSupportModal();
            } else {
              this.setState({
                emailOtpLoading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              emailOtpLoading: false,
            });
            message.error(e.message);
          });
      }
    );
  };

  updateEmail() {
    if (
      this.state.supportEmail2.EmailInpute.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      this.setState(
        {
          loading: true,
        },
        () => {
          axios({
            url: process.env.REACT_APP_API_URL + "/user/generateotp",
            method: "post",
            data: {
              type: "email",
              status: "new",
              location: "support",
              email: this.state.supportEmail2.EmailInpute,
              change: 1,
            },
            withCredentials: true,
          })
            .then((res) => {
              if (res.data.success === 1) {
                this.setState({
                  supportEmail2: {
                    ...this.state.supportEmail2,
                  },
                });

                message.success("Email updated successfully.");
                this.toggleChnageNumber();
              } else {
                this.setState({
                  supportEmail2: {
                    ...this.state.supportEmail2,
                  },
                  loading: false,
                });
                message.error(res.data.msg);
              }
            })
            .catch((e) => {
              message.error(e.message);
              this.setState({
                supportEmail2: {
                  ...this.state.supportEmail2,
                },
                loading: false,
              });
            });
        }
      );
    }
  }

  VerifyEmail() {
    if (
      this.state.supportEmail2.EmailInpute.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      this.setState(
        {
          emailOtpLoading: true,
        },
        () => {
          axios({
            url: process.env.REACT_APP_API_URL + "/user/generateotp",
            method: "post",
            withCredentials: true,
            data: {
              type: "email",
              location: "support",
              status: "new",
              email: this.state.supportEmail2.EmailInpute,
            },
          })
            .then((res) => {
              if (res.data.success === 2) {
                message.success(res.data.msg);
                this.setState({
                  verifiedmsg: true,
                  verifiedEmailId: this.state.supportEmail2.EmailInpute,
                  emailverified: 1,
                  emailOtpLoading: false,
                });
                return;
              } else {
                if (res.data.success === 1) {
                  this.setState({
                    emailOtpLoading: false,
                  });
                  this.toggleSupportModal();
                  message.success(res.data.msg);
                } else {
                  this.setState({
                    emailOtpLoading: false,
                  });
                  message.error(res.data.msg);
                }
              }
            })
            .catch((e) => {
              this.setState({
                emailOtpLoading: false,
              });
              message.error(e.msg);
            });
        }
      );
    } else {
      message.error("Please enter a valid Email Address.");
    }
  }

  handleSupportEmailChange(field, value) {
    if (value.length > 0) {
      if (value === this.state.verifiedEmailId || this.state.verifiedmsg) {
        if (value === this.state.verifiedEmailId) {
          this.setState({ verifiedmsg: true });
        } else {
          this.setState({ verifiedmsg: false });
        }
      } else {
        this.setState({
          supportEmail2: {
            ...this.state.supportEmail2,
            [field]: value,
          },
        });
      }
    }
  }

  handleSupportPhoneChange(ob) {
    let onlyNumber = ob.value
      .replace(/[^0-9]+/g, "")
      .slice(
        !!Object.entries(ob.extraVal).length ? ob.extraVal.dialCode.length : 0
      );

    if (onlyNumber.length > 0) {
      if (
        onlyNumber === this.state.verifiedPhoneNO ||
        this.state.verifiedcontact
      ) {
        if (onlyNumber === this.state.verifiedPhoneNO) {
          this.setState({ verifiedcontact: true });
        } else {
          this.setState({ verifiedcontact: false });
        }
      } else {
        this.setState({
          supportNumber2: {
            ...this.state.supportNumber2,
            [ob.field]: onlyNumber,
            prefix_1: ob.extraVal.dialCode,
          },
        });
      }
    }
  }

  // handleSupportPhoneChange(field, value) {
  //   if (value.length > 0) {
  //     if (value === this.state.verifiedPhoneNO || this.state.verifiedcontact) {
  //       if (value === this.state.verifiedPhoneNO) {
  //         this.setState({ verifiedcontact: true });
  //       } else {
  //         this.setState({ verifiedcontact: false });
  //       }
  //     } else {
  //       this.setState({
  //         supportNumber2: {
  //           ...this.state.supportNumber2,
  //           [field]: value,
  //         },
  //       });
  //     }
  //   }
  // }

  toggleBackButton() {
    this.setState(
      {
        kycType: "",
      },
      () => this.props.parrentToChildKycValue(this.state.kycType)
    );
  }

  profileBakButton() {
    this.setState(
      {
        currentStep: 1,
        files: {},
      },
      () => this.props.previousStep()
    );
  }

  uploadBcakButton() {
    this.setState({
      currentStep: 2,
    });
  }

  componentDidMount() {
    const { setFieldsValue } = this.props.form;
    const { bank = {}, address = {}, ...rest } = this.props.kycData;
    const data = { ...rest };
    Object.keys(bank).forEach((k) => (data[`bank[${k}]`] = bank[k]));
    Object.keys(address).forEach((k) => (data[`address[${k}]`] = address[k]));
    setFieldsValue(data);
    this.props.childToParrentSteps(this.state.currentStep);
  }

  goToNextPart = () => {
    if (this.props.activeStep === 5 && this.state.currentStep === 2) {
    } else {
      if (
        (this.state.phoneverified === 0 &&
          this.state.kycType === "Individual") ||
        (this.state.phoneverified === 0 &&
          this.state.kycType === "Organisation")
      ) {
        message.error("Please verify your phone number.");
        return;
      }

      if (
        (this.state.emailverified === 0 &&
          this.state.kycType === "Individual") ||
        (this.state.emailverified === 0 &&
          this.state.kycType === "Organisation")
      ) {
        message.error("Please verify your Email ID.");
        return;
      }
    }

    if (this.state.currentStep === 1) {
      let fieldsToValidate = [];
      if (this.state.kycType === "Individual") {
        fieldsToValidate = [
          "individual_name",
          "address",
          "prefix",
          "phone",
          "pan_number",
          "aadhar_number",
          "prefix",
          "pan_card",
          "country",
          "support_phone",
          "email",
        ];
      } else {
        fieldsToValidate = [
          "org_name",
          "org_pan",
          "org_cin",
          "org_gst",
          "org_authpan",
          "org_authpanowner",
          "address",
          "support_phone",
          "prefix",
          "Gst_certificate",
          "email",
        ];
      }
      this.props.form.validateFields(fieldsToValidate, (err, values) => {
        if (!err) {
          let docs = {};

          Object.keys(this.state.files).forEach(
            (k) => (docs[k] = this.state.files[k][0].name)
          );
          delete values["Gst_certificate"];
          delete values["org_pan"];
          delete values["pan_card"];
          const data = { docs };
          this.props.saveUserKYC({
            ...data,
            ...values,
            kyc_type: this.state.kycType,
            // phone: this.state.questions.phonecall,
            dialCode: this.state.questions.dialCode.split("+")[1],
            country: "india",
          });
          this.setState((state) => ({
            currentStep: state.currentStep + 1,
          }));
        }
      });
    } else if (this.state.currentStep === 2) {
      this.props.form.validateFields(
        ["bank", "cancelled_cheque"],
        (err, values) => {
          if (!err && values.bank.accno === values.bank.re_accno) {
            let docs = {};
            Object.keys(this.state.files).forEach(
              (k) => (docs[k] = this.state.files[k][0].name)
            );
            delete values["cancelled_cheque"];
            const data = { docs };
            this.props.saveUserKYC({
              ...data,
              ...values,
              kyc_type: this.state.kycType,
            });
            this.setState((state) => ({
              currentStep: state.currentStep + 1,
            }));
          }
        }
      );
    }
    // } else {
    //   this.props.form.validateFieldsAndScroll((err, values) => {
    //     if (!err) {
    //       let docs = {};
    //       Object.keys(this.state.files).forEach(
    //         (k) => (docs[k] = this.state.files[k][0].name)
    //       );
    //       if (
    //         Object.values(docs).filter((d) => d.trim().length > 0).length !==
    //         (this.state.kycType === "Individual" ? 4 : 5)
    //       ) {
    //         message.error("Please upload all the required documents!");
    //         return;
    //       }
    //       const data = { docs };
    //       this.props.saveUserKYC({
    //         ...data,
    //         kyc_type: this.state.kycType,
    //       });
    //     }
    //   });
    // }
  };

  onFileUpload = (docType, files) => {
    let fileList = [...files];
    fileList = files.slice(-1).map((file) => {
      if (file.response && file.response.success === 1) {
        file.name = file.response.file;
      }
      return file;
    });
    const file = fileList[0];
    if (file && file.response && file.response.success !== 1) {
      message.error(file.response.msg);
      return;
    }
    this.setState((state) => ({
      files: {
        ...state.files,
        [docType]: [file],
      },
    }));
  };

  removeAddresProof = (docType) => {
    if ((docType = "address_proof"))
      this.setState((state) => ({
        files: {
          ...state.files,
          [docType]: [],
        },
      }));
  };

  removeadharcard = (docType) => {
    if ((docType = "aadhar_card"))
      this.setState((state) => ({
        files: {
          ...state.files,
          [docType]: [],
        },
      }));
  };

  onremovepencard = (docType) => {
    if ((docType = "pan_card"))
      this.setState((state) => ({
        files: {
          ...state.files,
          [docType]: [],
        },
      }));
  };

  onRemoveCancelCheque = (docType) => {
    if ((docType = "cancelled_cheque"))
      this.setState((state) => ({
        files: {
          ...state.files,
          [docType]: [],
        },
      }));
  };

  onRemoveGstCertificate = (docType) => {
    if ((docType = "gst_certificate"))
      this.setState((state) => ({
        files: {
          ...state.files,
          [docType]: [],
        },
      }));
  };

  onremovesignaturepencard = (docType) => {
    if ((docType = "signatory_pan_card"))
      this.setState((state) => ({
        files: {
          ...state.files,
          [docType]: [],
        },
      }));
  };

  setKYCType(key) {
    this.setState(
      {
        kycType: key,
        kycData1: {},
      },
      () => this.props.parrentToChildKycValue(this.state.kycType)
    );
  }

  render() {
    return (
      <>
        {this.state.kycType === "Individual" ||
        this.state.kycType === "Organisation" ? null : (
          <KycIndividualOrganisation
            kycType={this.state.kycType}
            setKYCType={(a) => this.setKYCType(a)}
            toggleFirststepBackbutton={() =>
              this.props.toggleFirststepBackbutton()
            }
            goToNextStep={this.props.goToNextStep}
          />
        )}

        {this.state.kycType === "Individual" ||
        this.state.kycType === "Organisation" ? (
          <Kycform
            step={this.props.step}
            totalSteps={this.state.totalSteps}
            kycType={this.state.kycType}
            files={this.state.files}
            numberOtpLoading={this.state.numberOtpLoading}
            emailOtpLoading={this.state.emailOtpLoading}
            kycData={this.props.kycData}
            phonecallChangeHandler={(val) => this.phonecallChangeHandler(val)}
            goToNextPart={this.goToNextPart}
            onremovesignaturepencard={(docType) =>
              this.onremovesignaturepencard(docType)
            }
            onremovepencard={(docType) => this.onremovepencard(docType)}
            removeadharcard={(docType) => this.removeadharcard(docType)}
            removeAddresProof={(docType) => this.removeAddresProof(docType)}
            onRemoveCancelCheque={(docType) =>
              this.onRemoveCancelCheque(docType)
            }
            onFileUpload={(docType, files) => this.onFileUpload(docType, files)}
            onRemoveGstCertificate={(docType) =>
              this.onRemoveGstCertificate(docType)
            }
            questions={this.state.questions}
            currentStep={this.state.currentStep}
            goToNextStep={this.props.goToNextStep}
            form={this.props.form}
            toggleBackButton={() => this.toggleBackButton()}
            profileBakButton={() => this.profileBakButton()}
            uploadBcakButton={() => this.uploadBcakButton()}
            visible={this.state.supportEmail.visible}
            toggleSupportModal={() => this.toggleSupportModal()}
            visible2={this.state.supportNumber.visible}
            toggleSupportNumberModal={() => this.toggleSupportNumberModal()}
            activeStep={this.props.activeStep}
            // handleSupportPhoneChange={(field, value) =>
            //   this.handleSupportPhoneChange(field, value)
            // }

            handleSupportPhoneChange={(ob) => this.handleSupportPhoneChange(ob)}
            supportNumber2={this.state.supportNumber2.contactno}
            prefix_1={this.state.supportNumber2.prefix_1}
            supportEmail2={this.state.supportEmail2.EmailInpute}
            handleSupportEmailChange={(field, value) =>
              this.handleSupportEmailChange(field, value)
            }
            VerifyPhone={() => this.VerifyPhone()}
            VerifyEmail={() => this.VerifyEmail()}
            phoneverified={this.state.phoneverified}
            emailverified={this.state.emailverified}
            verifiedmsg={this.state.verifiedmsg}
            verifiedcontact={this.state.verifiedcontact}
            previousdatastoe={(key, value) => this.previousdatastoe(key, value)}
            kycData1={this.state.kycData1}
          />
        ) : null}

        <SupportEmail
          emailLoading={this.state.emailOtpLoading}
          visible={this.state.supportEmail.visible}
          pasteFuncEmail={(e) => this.pasteFuncEmail(e)}
          toggleSupportModal={() => this.toggleSupportModal()}
          onCancel={() => this.toggleSupportModal()}
          supportEmail2={this.state.supportEmail2.EmailInpute}
          emailotp={this.state.emailotp}
          verifyEmailContinue={() => this.verifyEmailContinue()}
          handleEmailChnage={(value) => this.handleEmailChnage(value)}
          chnagenumber={this.state.chnagenumber}
          toggleChnageNumber={() => this.toggleChnageNumber()}
          otpChnageEmail={(field, value) => this.otpChnageEmail(field, value)}
          updateEmail={() => this.updateEmail()}
        />

        <SupportPhoneNumber
          numberLoading={this.state.numberOtpLoading}
          pasteFunc={(e) => this.pasteFunc(e)}
          visible={this.state.supportNumber.visible}
          toggleSupportNumberModal={() => this.toggleSupportNumberModal()}
          onCancel={() => this.toggleSupportNumberModal()}
          supportNumber2={this.state.supportNumber2.contactno}
          handleContactChange={(value) => this.handleContactChange(value)}
          chnagenumber={this.state.chnagenumber}
          toggleChnageNumber={() => this.toggleChnageNumber()}
          otpChnageNumber={(field, value) => this.otpChnageNumber(field, value)}
          phoneotp={this.state.phoneotp}
          verifyPhoneContinue={() => this.verifyPhoneContinue()}
          updatePhonenumber={() => this.updatePhonenumber()}
          resetTimer={this.state.reset}
        />
      </>
    );
  }
}

export default Form.create()(IndianProfile);
