import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  DatePicker,
  Alert,
  Select,
  Table,
  Tooltip,
  Icon,
  message,
} from "antd";
import { formatNumber, getCurrencySymbol } from "../../../../Helpers/Pricing";
import moment from "moment";
import axios from "axios";
import { connect } from "react-redux";
import { hasPermission } from "../../../../Helpers/Permissions";
import { SHOPIFY_NAME } from "../../../../constants";
import "./index.scss";
import qs from "qs";

const { RangePicker } = DatePicker;
const { Option } = Select;
const status = {
  rto: "RTO",
  delivered: "Delivered",
  transit: "In Transit",
};

const buyback_applicable = {
  1: "Yes",
  0: "No",
};
const buyback_type = {
  rto: "RTO Order BuyBack",
  cancelled: "Cancelled Order Buyback",
};

const columnData = {
  vforderid: {
    title: "vF Order Id",
    width: "150px",
    dataIndex: "vforderid",
    sortValue: 1,
  },
  vf_order_date: {
    width: "150px",
    title: "vF Order Date",
    dataIndex: "vf_order_date",
    sortValue: 2,
    render: (date) => <p>{moment(date).format("DD MMM, YYYY")}</p>,
  },
  shopify_store_name: {
    width: "150px",
    title: "Shopify Store Name",
    dataIndex: "shopify_store_name",
    sortValue: 3,
  },
  shopify_order_date: {
    width: "150px",
    title: `${SHOPIFY_NAME} Order Date`,
    dataIndex: "shopify_order_date",
    sortValue: 4,
    render: (date) => <p>{moment(date).format("DD MMM, YYYY")}</p>,
  },
  shopify_order_name: {
    width: "150px",
    title: "Shopify Order Name",
    dataIndex: "shopify_order_name",
    sortValue: 5,
  },
  buy_back_type: {
    width: "150px",
    title: "Buy Back Type",
    dataIndex: "buy_back_type",
    sortValue: 7,
    render: (buy_back_type) =>
      buy_back_type ? Object.values(buyback_type[buy_back_type]) : "NA",
  },
  buy_back_applicable: {
    width: "150px",
    title: "Buy Back Applicable",
    dataIndex: "buy_back_applicable",
    sortValue: 8,
    render: (buy_back_applicable) =>
      buy_back_applicable
        ? Object.values(buyback_applicable[buy_back_applicable])
        : "NA",
  },
  waybill_number: {
    width: "150px",
    title: "WayBill Number",
    dataIndex: "waybill_number",
    sortValue: 9,
  },
  transaction_id: {
    width: "150px",
    title: "Transaction ID",
    dataIndex: "transaction_id",
    sortValue: 10,
  },
  credit_date: {
    width: "150px",
    title: "Transaction Date",
    dataIndex: "credit_date",
    sortValue: 11,
    render: (date) => <p>{moment(date).format("DD MMM, YYYY")}</p>,
  },
  current_status: {
    width: "150px",
    title: "Current Status",
    dataIndex: "current_status",
    sortValue: 12,
    render: (current_status) => Object.values(status[current_status]),
  },
  waybill_rto_date: {
    width: "150px",
    title: "WayBill RTO Date",
    dataIndex: "waybill_rto_date",
    sortValue: 13,
    render: (waybill_rto_date) => (waybill_rto_date ? waybill_rto_date : "NA"),
  },
  buyback_total_product_price: {
    width: "150px",
    title: "Total Product Price Credited",
    dataIndex: "buyback_total_product_price",
    sortValue: 14,
    render: (amount) => (
      <p>
        {getCurrencySymbol("INR")} {formatNumber(amount)}
      </p>
    ),
  },
  buyback_cod_refund: {
    width: "150px",
    title: "Total COD Fee Refunded",
    dataIndex: "buyback_cod_refund",
    sortValue: 15,
    render: (amount) => (
      <p>
        {getCurrencySymbol("INR")} {formatNumber(amount)}
      </p>
    ),
  },
  buyback_reverse_shipping: {
    width: "150px",
    title: "Total Reverse Shipping Charged",
    dataIndex: "buyback_reverse_shipping",
    sortValue: 16,
    render: (amount) => (
      <p>
        {getCurrencySymbol("INR")} {formatNumber(amount)}
      </p>
    ),
  },
  buyback_net_credit: {
    width: "150px",
    title: "Net Buy Back Credit",
    dataIndex: "buyback_net_credit",
    sortValue: 17,
    render: (amount) => (
      <p>
        {getCurrencySymbol("INR")} {formatNumber(amount)}
      </p>
    ),
  },
  buyback_credit_status: {
    width: "150px",
    title: "Net Buy Back Credit Status",
    dataIndex: "buyback_credit_status",
    sortValue: 18,
  },
};

const defaultCols = [
  "vforderid",
  "waybill_number",
  "current_status",
  "buyback_total_product_price",
  "buyback_cod_refund",
  "buyback_reverse_shipping",
  "buyback_net_credit",
  "buyback_credit_status",
];

class VfBuyBack extends Component {
  state = {
    currency: "INR",
    stats: {
      totalorders: 0,
      totalordersinrto: 0,
      totalbuybackcredit: 0,
      totalppcredit: 0,
      totalcodfee: 0,
      totalreverseshipping: 0,
    },
    filters: {
      startDate: moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
    paging: {
      page: 1,
      pageSize: 10,
      total: 0,
    },
    page: 1,
    pageSize: 10,
    loading: false,
    error: false,
    errorMsg: "",
    statsLoading: false,
    orders: [],
    columns: defaultCols.map((c) => columnData[c]),
  };

  componentDidMount() {
    this.getOrders();
    this.getStats();
  }

  getOrders() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/reporting/get_shipments",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
            filters: this.state.filters,
            type: "rto",
          },
          withCredentials: true,
        })
          .then((res) => {
            // console.log(res);
            if (res.data.success === 1) {
              this.setState({
                orders: res.data.orders,
                loading: false,
                error: false,
                errorMsg: "",
                paging: { ...this.state.paging, total: res.data.total },
              });
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
                loading: false,
              });
            }
          })
          .catch((e) => {
            this.setState({
              error: true,
              errorMsg: e.message,
              loading: false,
            });
          });
      }
    );
  }

  getStats() {
    this.setState(
      {
        statsLoading: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL + "/reporting/vf_buyback_credit_mis",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            filters: this.state.filters,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                statsLoading: false,
                stats: {
                  totalorders: res.data.stats.total_orders,
                  totalordersinrto: res.data.stats.total_orders_in_rto,
                  totalbuybackcredit: res.data.stats.total_buyback_credit,
                  totalppcredit: res.data.stats.total_product_price_credited,
                  totalcodfee: res.data.stats.total_cod_fee_refunded,
                  totalreverseshipping:
                    res.data.stats.total_reverse_shipping_charged,
                },
              });
            } else {
              this.setState({
                statsLoading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              statsLoading: false,
            });
            message.error(e.message);
          });
      }
    );
  }

  filterChanged() {
    this.getOrders();
    this.getStats();
  }

  handleChange(value) {
    this.setState({
      columns: value
        .map((c) => columnData[c])
        .sort((a, b) => a.sortValue - b.sortValue),
    });
  }

  unselectColumn(index) {
    this.setState({
      columns: this.state.columns
        .filter((_, i) => i !== index)
        .sort((a, b) => a.sortValue - b.sortValue),
    });
  }
  goToPage = (page) => {
    this.setState(
      {
        paging: { ...this.state.paging, page: page.current },
      },
      () => this.getOrders()
    );
  };

  render() {
    const { store } = this.props;
    const PageWrapper = (children) => {
      return (
        <div className="remittance">
          <Row>
            <div className="heading">
              <h1 className="content-page-title" style={{ marginBottom: 0 }}>
                vF Buy Back Credit MIS
              </h1>
              <h3
                className="content-page-subtitle"
                style={{ marginBottom: 30 }}
              >
                Buy Back Credit for Orders in the Selected Period:
                <span style={{ float: "right", marginTop: "-4px" }}>
                  Show data from:
                  <RangePicker
                    disabledDate={(current) => {
                      return current > moment();
                    }}
                    value={[
                      this.state.filters.startDate === ""
                        ? null
                        : moment(this.state.filters.startDate),
                      this.state.filters.endDate === ""
                        ? null
                        : moment(this.state.filters.endDate),
                    ]}
                    ranges={{
                      "Last Month": [
                        moment()
                          .subtract(1, "months")
                          .startOf("month"),
                        moment()
                          .subtract(1, "months")
                          .endOf("month"),
                      ],
                      "Last 3 Month": [
                        moment()
                          .subtract(2, "months")
                          .startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    onChange={(_, dateString) =>
                      this.setState(
                        {
                          filters: {
                            startDate: dateString[0],
                            endDate: dateString[1],
                          },
                        },
                        () => this.filterChanged()
                      )
                    }
                  />
                </span>
              </h3>
            </div>
          </Row>
          {children}
        </div>
      );
    };

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <Row>
          <Alert
            message="There was an error"
            description={this.state.errorMsg}
            type="error"
          />
        </Row>
      );
    }

    const order_stats = {
      totalorders: "Total Orders",
      totalordersinrto: "Total Orders In RTO",
      totalbuybackcredit: "Total Buy Back Credit",
      totalppcredit: "Total Product Price Credited",
      totalcodfee: "Total COD Fee Refunded",
      totalreverseshipping: "Total Reverse Shipping Charged",
    };
    const tooltip = {
      totalorders: "Total Orders",
      totalordersinrto: "Total Orders In RTO",
      totalbuybackcredit: "Total Buy Back Credit",
      totalppcredit: "Total Product Price Credited",
      totalcodfee: "Total COD Fee Refunded",
      totalreverseshipping: "Total Reverse Shipping Charged",
    };

    const pagination = {
      current: this.state.paging.page,
      pageSize: this.state.paging.pageSize,
      total: this.state.paging.total,
    };

    return PageWrapper(
      <React.Fragment>
        <Row gutter={{ lg: 30 }}>
          {Object.keys(order_stats).map((k) => (
            <Col
              xs={24}
              md={12}
              lg={8}
              xl={8}
              style={{ marginBottom: 56 }}
              key={k}
            >
              <Card
                className="reporting-card"
                loading={this.state.statsLoading}
              >
                <div className="header">
                  {order_stats[k]}
                  <span className="header-tooltip">
                    <Tooltip title={tooltip[k]}>
                      <Icon type="exclamation-circle-o" />
                    </Tooltip>
                  </span>
                </div>
                <div className="body">
                  {order_stats[k] === "Total Orders" ||
                  order_stats[k] === "Total Orders In RTO"
                    ? this.state.stats[k]
                    : `${getCurrencySymbol(this.state.currency)} ${formatNumber(
                        this.state.stats[k]
                      )}`}
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <Row>
          <div className="heading">
            <h1 className="content-page-title" style={{ marginBottom: "10px" }}>
              vF Buy Back Credit Analysis Data
              <span style={{ float: "right" }}>
                {hasPermission(store, "export_buy_back_report") && (
                  <a
                    href={`/reporting/vf_buyback_credit_mis_csv?${qs.stringify(
                      {
                        storeid: this.props.match.params.storeid,
                        filters: this.state.filters,
                        columns: this.state.columns.map((c) => c.dataIndex),
                      },
                      { encodeValuesOnly: true }
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      background: "#25BE05",
                      border: "none",
                      color: "white",
                    }}
                    className="ant-btn"
                  >
                    <Icon type="download" /> Download CSV
                  </a>
                )}
              </span>
            </h1>
          </div>
        </Row>
        <Row>
          <div className="selected-column">
            <h3 className="content-page-subtitle">Display Columns : </h3>
            <Select
              mode="multiple"
              placeholder="Select from dropdown"
              value={this.state.columns.map((column) => `${column.dataIndex}`)}
              onChange={(value) => this.handleChange(value)}
              className="display-column-select"
            >
              {Object.values(columnData).map((c) => (
                <Option value={c.dataIndex} key={c.dataIndex}>
                  {c.title}
                </Option>
              ))}
            </Select>
          </div>
        </Row>
        <Row style={{ marginBottom: "30px" }}>
          {this.state.columns.map((column, i) => (
            <div className="selectedValue" key={i}>
              {column.title}{" "}
              <Icon type="close" onClick={() => this.unselectColumn(i)} />
            </div>
          ))}
          <div />
        </Row>
        <Row>
          <Table
            columns={this.state.columns}
            dataSource={this.state.orders}
            loading={this.state.loading}
            rowKey={(row) => row.awb}
            onChange={(page) => this.goToPage(page)}
            scroll={{ x: 175 * this.state.columns.length }}
            pagination={{
              ...pagination,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};
export default connect(mapStateToProps)(VfBuyBack);
