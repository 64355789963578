import React, { Component } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import {
  formatNumber,
  getCurrencySymbol,
} from "../../../../../Helpers/Pricing";
import moment from "moment";

import { ReactComponent as NoGraphIcon } from "../../../../../assets/images/icons/500.svg";
import "./index.scss";

class Salesgraph extends Component {
  getIntroOfPage = (label) => {
    const salesData = this.props.sales;
    // const topsaledata = salesData.slice(0, 5);
    const topsaledata = salesData;

    var dt = topsaledata.map((item) => {
      if (label === item.date) {
        return (
          <div>
            <p>{item.date}</p>

            <div className="ic-sales">
              <div className="svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="6"
                  viewBox="0 0 6 6"
                >
                  <path
                    id="Oval_1526_Copy_257"
                    data-name="Oval 1526 Copy 257"
                    d="M3,0A3,3,0,1,1,0,3,3,3,0,0,1,3,0Z"
                    fill="#2c9aff"
                  />
                </svg>
              </div>
              <div className="content">
                <h5>Sales in Store -</h5>

                <p style={{ fontSize: "14px" }}>
                  Amount:{" "}
                  <span>
                    {this.props.auth.store.store_geo === "in"
                      ? `₹ ${formatNumber(item["Sale Amount"], 2)}`
                      : `${getCurrencySymbol(
                          this.props.store_currency
                        )}  ${formatNumber(item["Sale Amount"], 2)} `}{" "}
                    |
                  </span>{" "}
                  Count: <span>{item.sale_count}</span>
                </p>
              </div>
            </div>

            {/* orders */}

            <div className="ic-orders">
              <div className="svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="6"
                  viewBox="0 0 6 6"
                >
                  <circle
                    id="Oval_1526_Copy_257"
                    data-name="Oval 1526 Copy 257"
                    cx="3"
                    cy="3"
                    r="3"
                    fill="#f85f21"
                  />
                </svg>
              </div>
              <div className="content">
                <h5>Orders to vFulfill -</h5>

                <p style={{ fontSize: "14px" }}>
                  Amount:{" "}
                  <span>
                    {this.props.auth.store.store_geo === "in"
                      ? item["Order Amount"]
                        ? `₹ ${formatNumber(item["Order Amount"], 2)}`
                        : "₹ 0.00"
                      : item["Order Amount"]
                      ? `${getCurrencySymbol(
                          this.props.store_currency
                        )}  ${formatNumber(item["Order Amount"], 2)}`
                      : `${getCurrencySymbol(
                          this.props.store_currency
                        )} 0.00`}{" "}
                    |
                  </span>{" "}
                  Count: <span>{item.order_count}</span>
                </p>
              </div>
            </div>

            {/*  */}
          </div>
        );
      } else {
        return dt;
      }
    });
    return dt;
  };

  CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            border: "1px solid #000",
            borderRadius: "4px",
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            color: "#fff",
            opacity: "1",
            padding: "1rem 1.25rem",
            transition: "top 200ms,left 200ms",
            fontSize: "12px",
          }}
        >
          <div className="intro">{this.getIntroOfPage(label)}</div>
        </div>
      );
    }
    return null;
  };

  graphDataHandler(data, graphName) {
    if (graphName === "Sales in Store") {
      let graphData = data.map((d) => {
        d.date = moment(d.date).format("DD MMM");
        return d;
      });
      return graphData;
    }
    return data;
  }

  render() {
    const PageWrapper = (children) => {
      return <div>{children}</div>;
    };
    const salesData = this.props.sales;
    // const topsaledata = salesData.slice(0, 5);
    const topsaledata = this.graphDataHandler(salesData, "Sales in Store");

    return PageWrapper(
      <div className="Sold_graph-sales">
        {this.props.sales.length > 0 ? (
          <BarChart
            width={1000}
            height={280}
            data={topsaledata}
            margin={{
              top: 30,
              right: 20,
              left: 20,
              bottom: 5,
            }}
            style={{ width: "100%" }}
          >
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip cursor={false} content={this.CustomTooltip} />

            <Legend />
            <Bar
              label="Sale"
              dataKey="Sale Amount"
              barSize={18}
              fill="#2C9AFC"
            />
            <Bar
              label="Order"
              dataKey="Order Amount"
              barSize={18}
              fill="#F85F21"
            />
          </BarChart>
        ) : (
          <div
            style={{
              marginBottom: "2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <NoGraphIcon style={{ width: "20rem" }} />
            <div style={{ textAlign: "left", marginLeft: 30 }}>
              <h1 style={{ marginBottom: 0, fontSize: "2rem" }}>
                Not Enough Data!
              </h1>
              <p style={{ margin: 0, fontSize: "1rem" }}>
                We could not find enough data to build a graph...
              </p>
            </div>
          </div>
        )}

        {this.props.sales.length > 0 ? (
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="data-point-sales"
          >
            <div className="first">
              <svg
                style={{ marginRight: "0.5rem" }}
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
              >
                <circle
                  id="Oval_1526_Copy_257"
                  data-name="Oval 1526 Copy 257"
                  cx="5"
                  cy="5"
                  r="5"
                  fill="#2c9afc"
                />
              </svg>

              <p>Sales in Store</p>
            </div>
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="second"
            >
              <svg
                style={{ marginRight: "0.5rem" }}
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
              >
                <circle
                  id="Oval_1526_Copy_257"
                  data-name="Oval 1526 Copy 257"
                  cx="5"
                  cy="5"
                  r="5"
                  fill="#f85f21"
                />
              </svg>

              <p>Orders to vFulfill</p>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Salesgraph;
