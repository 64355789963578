import React, { Component } from "react";
import { Button, Modal, Input, message, Tabs } from "antd";
import TooltipComp from "../../Common/GlobalTooltipIcon";
import axios from "axios";
import MediaTab from "./MediaTab";
import { Link } from "react-router-dom";
import "./index.scss";
import moment from "moment";
import MediaModal from "./MediaModal";
// import { ReactComponent as CalanderIcon } from "../../../../assets/images/icons/Calander.svg";
import { ReactComponent as CalanderIc } from "../../../../assets/images/icons/calander.svg";

const { TabPane } = Tabs;

const { TextArea } = Input;

class QuoteModal extends Component {
  state = {
    showUserRemark: false,
    userRemark: "",
    saving: false,
    remarkSent: false,
    initialRemarks: "",

    mediaFilesArr: [],
    activeTab: "1",
    mediaModalVisual: false,
  };

  handleRemarksSubmit = () => {
    this.setState(
      {
        saving: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/rfqrequests/add_remarks",
          method: "post",
          data: {
            request_vfid: this.props.request_vfid,
            remarks: this.state.userRemark,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              message.success(res.data.msg);

              this.setState(
                {
                  saving: false,
                  remarkSent: true,
                },
                () => this.props.loadData()
              );
            } else {
              message.info(res.data.msg);

              this.setState({
                saving: false,
              });
            }
          })
          .catch((e) => {
            this.setState({
              saving: false,
            });
          });
      }
    );
  };

  render() {
    let moq = this.props.moq;
    console.log(this.props.currentRow);
    const daysHandler = (moq, type, index) => {
      try {
        let startDays = moq[index][type].production_time;
        let endDays = moq[index][type].transit_time;

        return <>{Number(startDays) + Number(endDays)} days</>;
      } catch {
        return null;
      }

      // let startDays = moq[0][type].production_time;
      // let endDays =
      //   moq[moq.length - 1][type].transit_time;
    };
    let show = 0;
    if (!!this.props.currentRow.show_domestic) {
      show += 1;
    }
    if (!!this.props.currentRow.show_air) {
      show += 1;
    }
    if (!!this.props.currentRow.show_sea) {
      show += 1;
    }
    // console.log("hi", this.props.currentRow);
    return (
      <Modal
        onCancel={() => this.props.onCancel()}
        width={show == 1 ? 600 : 850}
        title={
          <>
            <h1>View Quotation </h1>
            <h2>
              Here are the most updated details with respect to your Request
            </h2>
            {this.props.currentRow.expired_at ? (
              <h2>
                Valid Upto :{" "}
                <span style={{ color: "#212121" }}>
                  <span
                    style={{
                      display: "inline-block",
                      position: "relative",
                      top: "3px",
                      marginRight: "4px",
                    }}
                  >
                    <CalanderIc />
                  </span>
                  {moment(this.props.currentRow.expired_at).format(
                    "MMMM DD, YYYY"
                  )}
                </span>
              </h2>
            ) : null}
            {this.props.currentRow.applicable_weight ? (
              <h2>
                Applicable weight for this product :
                <span style={{ color: "#212121" }}>
                  {" "}
                  {this.props.currentRow.applicable_weight} ({" "}
                  <span style={{ color: "#898989" }}>Slab : </span>{" "}
                  {this.props.currentRow.applicable_slab}{" "})
                </span>
              </h2>
            ) : null}
          </>
        }
        footer={
          <div className="footer-btns">
            {this.state.activeTab === "1" ? (
              <div className="grp">
                <Button onClick={() => this.props.onCancel()}>Close</Button>

                {this.props.currentRow &&
                (!!this.props.currentRow.vfprodid ||
                  (!!this.props.currentRow.mapped_vfpd &&
                    !!this.props.currentRow.mapped_vfpd.vfprodid)) ? (
                  <a
                    style={{ marginLeft: "16px" }}
                    target="_blank"
                    className="ant-btn ant-btn-primary"
                    href={`../products2/${
                      this.props.currentRow && !!this.props.currentRow.vfprodid
                        ? this.props.currentRow.vfprodid
                        : !!this.props.currentRow.mapped_vfpd &&
                          !!this.props.currentRow.mapped_vfpd.vfprodid
                        ? this.props.currentRow.mapped_vfpd.vfprodid
                        : ``
                    }`}
                  >
                    Raise Sourcing Request
                  </a>
                ) : null}
              </div>
            ) : (
              <Button
                onClick={() =>
                  this.setState({ activeTab: "1", mediaFilesArr: [] })
                }
              >
                Go back
              </Button>
            )}
          </div>
        }
        className="quote-modal"
        visible={this.props.visible}
        destroyOnClose={true}
      >
        <Tabs activeKey={this.state.activeTab}>
          <TabPane key="1">
            <div className="modal-conten-wraper">
              <div className="moq-range-wraper">
                <div className="moq-heading">
                  <div className="moq">MOQ</div>

                  {!!this.props.currentRow &&
                    !!this.props.currentRow.show_domestic && (
                      <div className="domestic">Domestic</div>
                    )}

                  {!!this.props.currentRow &&
                    !!this.props.currentRow.show_air && (
                      <div className="air">International Air</div>
                    )}

                  {!!this.props.currentRow &&
                    !!this.props.currentRow.show_sea && (
                      <div className="sea">International Sea</div>
                    )}
                </div>

                <div className="loop-line-item-wraper ">
                  {moq && moq && !!moq.length
                    ? moq.map((e, i) => {
                        return (
                          <div className="loop-line">
                            <div className="moq">
                              {e.start}-{e.end}
                            </div>

                            {!!this.props.currentRow &&
                            !!this.props.currentRow.show_domestic ? (
                              !!e.domestic.pp ? (
                                <div className="domestic">
                                  <span>
                                    <span style={{ fontWeight: "600" }}>
                                      ₹ {e.domestic.pp}
                                    </span>{" "}
                                    {!!this.props.currentRow.gst && (
                                      <span className="gst">
                                        + GST{" "}
                                        <span>
                                          ({this.props.currentRow.gst}%)
                                        </span>
                                      </span>
                                    )}
                                  </span>
                                  <div className="days">
                                    {daysHandler(moq, "domestic", i)}
                                    <TooltipComp tooltipText="Production days + Transit days" />
                                  </div>
                                  {e.domestic.filesURL &&
                                  !!e.domestic.filesURL.length ? (
                                    <Button
                                      onClick={() =>
                                        this.setState({
                                          // activeTab: "2",
                                          mediaModalVisual: true,
                                          mediaFilesArr: e.domestic.filesURL,
                                        })
                                      }
                                      className="view-media"
                                    >
                                      View Media
                                    </Button>
                                  ) : null}
                                </div>
                              ) : (
                                <div>-</div>
                              )
                            ) : null}

                            {!!this.props.currentRow &&
                            !!this.props.currentRow.show_air ? (
                              !!e.air.pp ? (
                                <div className="air">
                                  ₹ {e.air.pp}
                                  <div className="days">
                                    {daysHandler(moq, "air", i)}{" "}
                                    <TooltipComp tooltipText="Production days + Transit days" />
                                  </div>
                                  {e.air.filesURL && !!e.air.filesURL.length ? (
                                    <Button
                                      onClick={() =>
                                        this.setState({
                                          // activeTab: "2",
                                          mediaModalVisual: true,
                                          mediaFilesArr: e.air.filesURL,
                                        })
                                      }
                                      className="view-media"
                                    >
                                      View Media
                                    </Button>
                                  ) : null}
                                </div>
                              ) : (
                                <div>-</div>
                              )
                            ) : null}

                            {!!this.props.currentRow &&
                            !!this.props.currentRow.show_sea ? (
                              !!e.sea.pp ? (
                                <div className="sea">
                                  ₹ {e.sea.pp}
                                  <div className="days">
                                    {daysHandler(moq, "sea", i)}
                                    <TooltipComp tooltipText="Production days + Transit days" />
                                  </div>
                                  {e.sea.filesURL && !!e.sea.filesURL.length ? (
                                    <Button
                                      onClick={() =>
                                        this.setState({
                                          // activeTab: "2",
                                          mediaModalVisual: true,
                                          mediaFilesArr: e.sea.filesURL,
                                        })
                                      }
                                      className="view-media"
                                    >
                                      View Media
                                    </Button>
                                  ) : null}
                                </div>
                              ) : (
                                <div>-</div>
                              )
                            ) : null}
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>

              <div className="remark-wraper">
                {!!this.props.currentRow &&
                  !!this.props.currentRow.external_remarks && (
                    <div className="vf-remark">
                      <p>Remarks from vFulfill</p>

                      <TextArea
                        value={this.props.currentRow.external_remarks}
                        disabled
                        style={{ color: "#212121" }}
                        rows={3}
                      />
                    </div>
                  )}
              </div>
            </div>
          </TabPane>
          <TabPane key="2">
            {this.state.mediaFilesArr.length && (
              <MediaTab mediaFilesArr={this.state.mediaFilesArr} />
            )}
          </TabPane>
        </Tabs>
        {this.state.mediaModalVisual && (
          <MediaModal
            visible={this.state.mediaModalVisual}
            onCancel={() => {
              this.setState({
                mediaModalVisual: false,
                mediaFilesArr: [],
              });
            }}
            mediaFilesArr={this.state.mediaFilesArr}
          />
        )}
      </Modal>
    );
  }
}

export default QuoteModal;
