import React from "react";
import { formatBalance, formatNumber } from "../../../../Helpers/Pricing";
import GlobalTooltipIconWhite from "../Common/../../Common/GlobalTooltipIconWhite";
import { ReactComponent as Arrow } from "../../../../assets/images/icons/fillArrow.svg";

import { Tooltip } from "antd";

const CounterCard = (props) => {
  const {
    title,
    amount,
    count,
    data,

    tooltip,
    tooltipText,
    per,
    custom,
    type,
    overall,
  } = props;

  function numDifferentiation(num) {
    if (!!num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
        : Math.sign(num) * Math.abs(num);
    } else {
      return 0;
    }
  }

  if (type === "attempted_orders_delivered") {
    return (
      <div className="counter-card">
        <div className="head">
          {title}{" "}
          {tooltip ? (
            <GlobalTooltipIconWhite tooltipText={tooltipText} />
          ) : null}
        </div>
        <div className="body">
          <div className="amount">
            <span>Amount </span>
            {/* <p>₹ {formatBalance(!!amount ? amount : 0)/}</p> */}

            <Tooltip
              title={
                `₹ ` +
                formatBalance(
                  props.data && props.data.total_delivered_orders_amount
                )
              }
            >
              <p>
                {numDifferentiation(
                  props.data && props.data.total_delivered_orders_amount
                )}
              </p>
            </Tooltip>
          </div>
          <div className="h-line" />
          <div className="count">
            <div className="flex-wraper-count">
              <div className="attempt">
                <span> {"Count:"} </span>
                <p>{count}</p>
              </div>

              <div className="attempt">
                <span> {props.percantage ? "" : "Count:"} </span>
                <p>{props.percantage?<span>( {amount} % )</span>:{amount}}</p>
              </div>
            </div>

            <div className="icon">
              <Tooltip
                overlayClassName="custom-bg-tooltip"
                color="white"
                placement="right"
                title={
                  <div className="custom-div-tooltip">
                    <h2> COD Attempted Delivered</h2>

                    <p>
                      {" "}
                      Count: {data.total_cod_order_attempts_orders}{" "}
                      <span style={{ color: "#898989" }}>
                        of {data.total_order_attempts_orders}{" "}
                      </span>
                    </p>
                    <p>
                      Delivery Rate:{" "}
                      {data.cod_orders_attempts_delivery_percentage}%
                    </p>

                    <div className="line" />

                    <h2>Prepaid Attempted Delivered</h2>

                    <p>
                      {" "}
                      Count: {data.total_prepaid_order_attempts_orders}{" "}
                      <span style={{ color: "#898989" }}>
                        of {data.total_order_attempts_orders}{" "}
                      </span>
                    </p>
                    <p>
                      Delivery Rate:{" "}
                      {data.prepaid_orders_attempts_delivery_percentage}%
                    </p>
                  </div>
                }
              >
                <Arrow />
              </Tooltip>{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (type === "overall_orders_delivered") {
    return (
      <div className="counter-card">
        <div className="head">
          {title}{" "}
          {tooltip ? (
            <GlobalTooltipIconWhite tooltipText={tooltipText} />
          ) : null}
        </div>
        <div className="body">
          <div className="amount">
            <span>Amount </span>
            {/* <p>₹ {formatBalance(!!amount ? amount : 0)/}</p> */}

            <Tooltip
              title={
                `₹ ` +
                formatBalance(
                  props.data && props.data.total_delivered_orders_amount
                )
              }
            >
              <p>
                {numDifferentiation(
                  props.data && props.data.total_delivered_orders_amount
                )}
              </p>
            </Tooltip>
          </div>
          <div className="h-line" />
          <div className="count">
            <div className="flex-wraper-count">
              <div className="attempt">
                <span> {"Count:"} </span>
                <p>{count}</p>
              </div>

              <div className="attempt">
                <span> {props.percantage ? "" : "Count:"} </span>
                <p>{props.percantage?<span>( {amount} % )</span>:{amount}}</p>
              </div>
            </div>
            <div className="icon">
              <Tooltip
                overlayClassName="custom-bg-tooltip"
                color="white"
                placement="right"
                title={
                  <div className="custom-div-tooltip">
                    <h2>COD Overall Delivered</h2>

                    <p>
                      Count: {data.cod_delivered_orders}{" "}
                      <span style={{ color: "#898989" }}>
                        {" "}
                        of {data.total_cod_orders}{" "}
                      </span>{" "}
                    </p>
                    <p>Delivery Rate: {data.cod_orders_delivery_percentage}%</p>

                    <div className="line" />

                    <h2>Prepaid Overall Delivered</h2>

                    <p>
                      {" "}
                      Count: {data.prepaid_delivered_orders}{" "}
                      <span style={{ color: "#898989" }}>
                        {" "}
                        of {data.total_prepaid_orders}{" "}
                      </span>{" "}
                    </p>
                    <p>
                      Delivery Rate: {data.prepaid_orders_delivery_percentage}%
                    </p>
                  </div>
                }
              >
                <Arrow />
              </Tooltip>{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (type === "vforder") {
    return (
      <div className="counter-card">
        <div className="head">
          {title}{" "}
          {tooltip ? (
            <GlobalTooltipIconWhite tooltipText={tooltipText} />
          ) : null}
        </div>

        <div className="body">
          <div className="amount">
            <span>{!!custom ? custom : "Amount"}</span>

            <p>
              {!!numDifferentiation(amount) ? (
                <Tooltip title={!custom ? `₹ ` + formatNumber(amount) : ""}>
                  {" "}
                  <span className="amountp">
                    {`₹ ` + numDifferentiation(amount)}
                  </span>
                </Tooltip>
              ) : (
                0
              )}
            </p>
          </div>
          <div className="h-line" />
          <div className="count">
            <span> {props.percantage ? "Delivery %:" : "Count:"} </span>
            <p>{!!count ? (props.round ? Math.round(count) : count) : 0}</p>

            <div className="icon">
              <Tooltip
                overlayClassName="custom-bg-tooltip"
                color="white"
                placement="right"
                title={
                  <div className="custom-div-tooltip">
                    <h2>Total COD Orders to VF</h2>

                    <p>
                      Amount:
                      <span style={{ color: "#898989" }}>
                        {" "}
                        {!!numDifferentiation(data.total_cod_orders_amount)
                          ? `₹ ` +
                            numDifferentiation(data.total_cod_orders_amount)
                          : 0}
                      </span>{" "}
                    </p>

                    <p>
                      Count:
                      {/* {data.cod_delivered_orders} */}{" "}
                      <span style={{ color: "#898989" }}>
                        {data.total_cod_orders}{" "}
                      </span>{" "}
                    </p>

                    <div className="line" />

                    <h2>Total Prepaid Orders to VF</h2>

                    <p>
                      Amount:
                      <span style={{ color: "#898989" }}>
                        {" "}
                        {!!numDifferentiation(data.total_prepaid_orders_amount)
                          ? `₹ ` +
                            numDifferentiation(data.total_prepaid_orders_amount)
                          : 0}
                      </span>{" "}
                    </p>
                    <p>
                      Count:{" "}
                      <span style={{ color: "#898989" }}>
                        {data.total_prepaid_orders}{" "}
                      </span>{" "}
                    </p>
                  </div>
                }
              >
                <Arrow />
              </Tooltip>{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (type === "total_orders_attempts") {
    return (
      <div className="counter-card">
        <div className="head">
          {title}{" "}
          {tooltip ? (
            <GlobalTooltipIconWhite tooltipText={tooltipText} />
          ) : null}
        </div>
        <div className="body">
          <div className="amount">
            <span>Amount </span>
            {/* <p>₹ {formatBalance(!!amount ? amount : 0)/}</p> */}

            <Tooltip
              title={
                `₹ ` +
                formatBalance(
                  props.data && props.data.total_order_attempts_orders_amount
                )
              }
            >
              <p>
                {numDifferentiation(
                  props.data && props.data.total_order_attempts_orders_amount
                )}
              </p>
            </Tooltip>
          </div>
          <div className="h-line" />
          <div className="count">
            <div className="flex-wraper-count">
              <div className="attempt">
                <span> {"Count:"} </span>
                <p>{count}</p>
              </div>

              <div className="attempt">
                <span> {props.percantage ? "" : "Count:"} </span>
                <p>
                  {per ? (Number.isInteger(per) ? <span>( {per} % )</span> :<span>( {per.toFixed(2)} % )</span> ) : 0}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (type === "remittance_processed") {
    let processedData =
      props.data.remit &&
      props.data.remit.filter(
        (item) => item.transaction_status === "processed"
      );

    let cod =
      !!processedData && processedData.find((item) => item.type === "cod");

    let prepaid =
      !!processedData && processedData.find((item) => item.type === "prepaid");

    let codAmount = cod && cod.amount ? cod.amount : 0;
    let prepaidAmount = prepaid && prepaid.amount ? prepaid.amount : 0;

    let codCount = cod && cod.order_count ? cod.order_count : 0;
    let prepaidCount = prepaid && prepaid.order_count ? prepaid.order_count : 0;

    let totalAmount = codAmount + prepaidAmount;
    let toalCount = codCount + prepaidCount;

    return (
      <div className="counter-card">
        <div className="head">
          {title}{" "}
          {tooltip ? (
            <GlobalTooltipIconWhite
              tooltipText={
                "Delivered Order Amount that has been remitted to you."
              }
            />
          ) : null}
        </div>

        <div className="body">
          <div className="amount">
            <span>{!!custom ? custom : "Amount"}</span>

            <p>
              {!!numDifferentiation(totalAmount) ? (
                <Tooltip
                  title={!custom ? `₹ ` + formatNumber(totalAmount) : ""}
                >
                  {" "}
                  <span className="amountp">
                    {`₹ ` + numDifferentiation(totalAmount)}
                  </span>
                </Tooltip>
              ) : (
                0
              )}
            </p>
          </div>
          <div className="h-line" />
          <div className="count">
            <span> {props.percantage ? "Delivery %:" : "Count:"} </span>
            <p>{Math.round(toalCount)}</p>

            <div className="icon">
              <Tooltip
                overlayClassName="custom-bg-tooltip"
                color="white"
                placement="right"
                title={
                  <div className="custom-div-tooltip">
                    <h2>COD Remittance Processed</h2>

                    <p>
                      Amount:
                      <span style={{ color: "#898989" }}>
                        {" "}
                        {!!numDifferentiation(codAmount)
                          ? `₹ ` + numDifferentiation(codAmount)
                          : 0}
                      </span>{" "}
                    </p>

                    <p>
                      Count:
                      {/* {data.cod_delivered_orders} */}{" "}
                      <span style={{ color: "#898989" }}>{codCount} </span>{" "}
                    </p>

                    <div className="line" />

                    <h2>Prepaid Remittance Processed</h2>

                    <p>
                      Amount:
                      <span style={{ color: "#898989" }}>
                        {" "}
                        {!!numDifferentiation(prepaidAmount)
                          ? `₹ ` + numDifferentiation(prepaidAmount)
                          : 0}
                      </span>{" "}
                    </p>
                    <p>
                      Count:{" "}
                      <span style={{ color: "#898989" }}>{prepaidCount} </span>{" "}
                    </p>
                  </div>
                }
              >
                <Arrow />
              </Tooltip>{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (type === "remittance_pending") {
    let processedData =
      props.data.remit &&
      props.data.remit.filter((item) => item.transaction_status === "pending");

    let cod =
      !!processedData && processedData.find((item) => item.type === "cod");

    let prepaid =
      !!processedData && processedData.find((item) => item.type === "prepaid");

    let codAmount = cod && cod.amount ? cod.amount : 0;
    let prepaidAmount = prepaid && prepaid.amount ? prepaid.amount : 0;

    let codCount = cod && cod.order_count ? cod.order_count : 0;
    let prepaidCount = prepaid && prepaid.order_count ? prepaid.order_count : 0;

    let totalAmount = codAmount + prepaidAmount;
    let toalCount = codCount + prepaidCount;

    return (
      <div className="counter-card">
        <div className="head">
          {title}{" "}
          {tooltip ? (
            <GlobalTooltipIconWhite
              tooltipText={
                "Delivered Order Amount that has been pending for remittance"
              }
            />
          ) : null}
        </div>

        <div className="body">
          <div className="amount">
            <span>{!!custom ? custom : "Amount"}</span>

            {!!numDifferentiation(totalAmount) ? (
              <Tooltip title={!custom ? `₹ ` + formatNumber(totalAmount) : ""}>
                {" "}
                <span className="amountp">
                  {`₹ ` + numDifferentiation(totalAmount)}
                </span>
              </Tooltip>
            ) : (
              0
            )}
          </div>
          <div className="h-line" />
          <div className="count">
            <span> {props.percantage ? "Delivery %:" : "Count:"} </span>
            <p>{Math.round(toalCount)}</p>

            <div className="icon">
              <Tooltip
                overlayClassName="custom-bg-tooltip"
                color="white"
                placement="right"
                title={
                  <div className="custom-div-tooltip">
                    <h2>COD Remittance Pending </h2>

                    <p>
                      Amount:
                      <span style={{ color: "#898989" }}>
                        {" "}
                        {!!numDifferentiation(codAmount)
                          ? `₹ ` + numDifferentiation(codAmount)
                          : 0}
                      </span>{" "}
                    </p>

                    <p>
                      Count:
                      {/* {data.cod_delivered_orders} */}{" "}
                      <span style={{ color: "#898989" }}>{codCount} </span>{" "}
                    </p>

                    <div className="line" />

                    <h2>Prepaid Remittance Pending</h2>

                    <p>
                      Amount:
                      <span style={{ color: "#898989" }}>
                        {" "}
                        {!!numDifferentiation(prepaidAmount)
                          ? `₹ ` + numDifferentiation(prepaidAmount)
                          : 0}
                      </span>{" "}
                    </p>
                    <p>
                      Count:{" "}
                      <span style={{ color: "#898989" }}>{prepaidCount} </span>{" "}
                    </p>
                  </div>
                }
              >
                <Arrow />
              </Tooltip>{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="counter-card">
      <div className="head">
        {title}{" "}
        {tooltip ? <GlobalTooltipIconWhite tooltipText={tooltipText} /> : null}
      </div>
      {!!props.singleValue ? (
        <div className="body-percentage">
          {!!props.singleValueData ? props.singleValueData : 0} %
        </div>
      ) : props.attempts ? (
        <div className="body">
          <div className="amount">
            <span>Count:</span>
            {/* <p>₹ {formatBalance(!!amount ? amount : 0)/}</p> */}
            <p>{!!count ? (props.round ? Math.round(count) : count) : 0}</p>
          </div>
          <div className="h-line" />
          <div className="count">
            <span> {props.percantage ? "Attempt % :" : "Count:"} </span>
            <p>{per ? (Number.isInteger(per) ? per : per.toFixed(2)) : 0}</p>
          </div>
        </div>
      ) : overall ? (
        <div className="body">
          <div className="amount">
            <span>Count:</span>
            {/* <p>₹ {formatBalance(!!amount ? amount : 0)/}</p> */}
            <p>{!!count ? (props.round ? Math.round(count) : count) : 0}</p>
          </div>
          <div className="h-line" />
          <div className="count">
            <span> {props.percantage ? "Attempt % :" : "Count:"} </span>
            <p>{amount}</p>

            <div className="icon">
              <Tooltip
                overlayClassName="custom-bg-tooltip"
                color="white"
                placement="right"
                title={
                  <div className="custom-div-tooltip">
                    <h2>COD Overall Delivered</h2>

                    <p>
                      Count: {data.cod_delivered_orders}{" "}
                      <span style={{ color: "#898989" }}>
                        {" "}
                        of {data.total_cod_orders}{" "}
                      </span>{" "}
                    </p>
                    <p>Delivery Rate: {data.cod_orders_delivery_percentage}%</p>

                    <div className="line" />

                    <h2>Prepaid Overall Delivered</h2>

                    <p>
                      {" "}
                      Count: {data.prepaid_delivered_orders}{" "}
                      <span style={{ color: "#898989" }}>
                        {" "}
                        of {data.total_prepaid_orders}{" "}
                      </span>{" "}
                    </p>
                    <p>
                      Delivery Rate: {data.prepaid_orders_delivery_percentage}%
                    </p>
                  </div>
                }
              >
                <Arrow />
              </Tooltip>{" "}
            </div>
          </div>
        </div>
      ) : custom ? (
        <div className="body">
          <div className="amount">
            <span>Count:</span>
            {/* <p>₹ {formatBalance(!!amount ? amount : 0)/}</p> */}
            <p>{!!count ? (props.round ? Math.round(count) : count) : 0}</p>
          </div>
          <div className="h-line" />
          <div className="count">
            <span> {props.percantage ? "Attempt % :" : "Count:"} </span>
            <p>{amount}</p>

            <div className="icon">
              <Tooltip
                overlayClassName="custom-bg-tooltip"
                color="white"
                placement="right"
                title={
                  <div className="custom-div-tooltip">
                    <h2> COD Attempted Delivered</h2>

                    <p>
                      {" "}
                      Count: {data.total_cod_order_attempts_orders}{" "}
                      <span style={{ color: "#898989" }}>
                        of {data.total_order_attempts_orders}{" "}
                      </span>
                    </p>
                    <p>
                      Delivery Rate:{" "}
                      {data.cod_orders_attempts_delivery_percentage}%
                    </p>

                    <div className="line" />

                    <h2>Prepaid Attempted Delivered</h2>

                    <p>
                      {" "}
                      Count: {data.total_prepaid_order_attempts_orders}{" "}
                      <span style={{ color: "#898989" }}>
                        of {data.total_order_attempts_orders}{" "}
                      </span>
                    </p>
                    <p>
                      Delivery Rate:{" "}
                      {data.prepaid_orders_attempts_delivery_percentage}%
                    </p>
                  </div>
                }
              >
                <Arrow />
              </Tooltip>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="body">
          <div className="amount">
            <span>{!!custom ? custom : "Amount"}</span>
            {/* <p>₹ {formatBalance(!!amount ? amount : 0)}</p> */}
            <p>
              {!!numDifferentiation(amount) ? (
                <Tooltip title={!custom ? `₹ ` + formatNumber(amount) : ""}>
                  {" "}
                  <span className="amountp">
                    {`₹ ` + numDifferentiation(amount)}
                  </span>
                </Tooltip>
              ) : (
                0
              )}
            </p>
          </div>
          <div className="h-line" />
          <div className="count">
            <span> {props.percantage ? "Delivery %:" : "Count:"} </span>
            <p>{!!count ? (props.round ? Math.round(count) : count) : 0}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CounterCard;
