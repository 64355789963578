import React, { Component } from "react";
import { Button, Slider } from "antd";

class Filterrange extends Component {
  render() {
    return (
      // to do
      // <div className="filter-range">
      //   <p className="range">
      //     <span className="inventory-restocking-time-txt">
      //       Inventory Restocking Time
      //     </span>
      //     {/* <span className="refresh-svg">
      //       <Icon component={refreshsvg} />
      //     </span> */}
      //   </p>
      //   <div>
      //     <>
      //       <Slider
      //         range
      //         min={0}
      //         max={51}
      //         step={this.props.step}
      //         value={this.props.value}
      //         onChange={(e) => this.props.onRangeFilterChange(e)}
      //       />
      //     </>
      //     <div className="type-range">
      //       <input
      //         type="number"
      //         className="start-range"
      //         value={this.props.value[0]}
      //         onChange={(e) => this.props.firstInputeOnchnage(e.target.value)}
      //       />
      //       <span>-</span>
      //       <input
      //         type="number"
      //         className="end-range"
      //         value={this.props.value[1]}
      //         onChange={(e) => this.props.secondInputeOnchnage(e.target.value)}
      //       />
      //       <span className="days"> Days</span>
      //     </div>
      //     <Button
      //       onClick={() => this.props.loadData()}
      //       className="clickable set-range-btn"
      //     >
      //       Submit
      //     </Button>
      //   </div>
      // </div>
      null
    );
  }
}

export default Filterrange;
