import React, { Component } from "react";
import { Row, Alert, Icon } from "antd";
import axios from "axios";
import "./index.scss";
import moment from "moment";
import { connect } from "react-redux";
import DeductedFromWallet from "./DeductedFromWallet";
import AddedInWallet from "./AddedInWallet";
import AddedInWallet2 from "./AddedInWallet";

class TransactionsReport extends Component {
  state = {
    loading: false,
    ndrs: [],
    error: false,
    errorMsg: "",
    total_amount: 0,
    transactionsCounter: {},
    transactionsCounterLoading: false,
    money_added: {},
    money_widthdrawal: {},
    transactionData: {},
  };

  componentDidMount() {
    this.transactionsCounter();
  }

  // componentDidUpdate(pp) {
  //   if (
  //     pp.transactionSummaryFilters.startDate !==
  //       "" ||
  //     pp.transactionSummaryFilters.endDate !==
  //       ""
  //   ) {
  //     this.setState(
  //       {
  //         startDate: "",
  //         endDate: "",
  //       },
  //       () => this.changeFilter()
  //     );
  //   }
  // }

  componentWillUnmount() {
    if (this.cancelToken1) {
      this.cancelToken1.cancel();
    }
    if (this.cancelToken2) {
      this.cancelToken2.cancel();
    }
    if (this.cancelToken3) {
      this.cancelToken3.cancel();
    }
    if (this.cancelToken4) {
      this.cancelToken4.cancel();
    }
  }

  transactionsCounter() {
    this.setState(
      {
        transactionsCounterLoading: true,
      },
      () => {
        if (this.cancelToken1) {
          this.cancelToken1.cancel();
        }
        this.cancelToken1 = axios.CancelToken.source();
        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/bqreporting/transaction_global_summary",
          method: "post",
          cancelToken: this.cancelToken1.token,
          data: {
            currency: this.props.currency,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                transactionData: res.data.data.transaction_summary,
                money_added: res.data.data.money_added,
                remit_reverse: res.data.data.remit_reverse,
                money_widthdrawal:
                  res.data.data.money_widthdrawal.funds_withdraw,
                total_amount: res.data.data.total_amount,
                transactionsCounterLoading: false,
                error: false,
                errorMsg: "",
              });
            } else {
              this.setState({
                transactionData: {},
                money_added: {},
                money_widthdrawal: {},
                transactionsCounterLoading: false,
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((e) => {
            this.setState({
              error: true,
              errorMsg: e.message,
              transactionsCounterLoading: true,
            });
          });
      }
    );
  }

  changeFilter(e) {
    this.transactionsCounter();
  }

  startDateHandler(startDate) {
    if (!!startDate) {
      return moment(startDate).format("DD MMM, YYYY");
    } else {
      return "";
    }
  }

  endDateHandler(endDate) {
    if (!!endDate) {
      return moment(endDate).format("DD MMM, YYYY");
    } else {
      return "";
    }
  }

  totalAmountHandler(data) {
    let totalAmountData = Object.entries(data);
    let totalAmount = 0;

    totalAmountData.forEach((d) => {
      if (d[1] > 0) {
        totalAmount += d[1];
      } else {
        totalAmount += 0;
      }
    });

    if (!!totalAmount) {
      return totalAmount;
    } else {
      return 0;
    }
  }

  amountHandler(amount) {
    if (!!amount) {
      return amount;
    } else {
      return 0;
    }
  }

  currentWalletBalance() {
    let data = this.state.money_added;

    let money_added_in_wallet = this.amountHandler(
      Number(
        this.state.money_added && this.state.money_added.total
          ? this.state.money_added.total
          : 0
      )
    );

    let totalMoneyDeducted = this.amountHandler(
      Number(
        this.state.transactionData && this.state.transactionData.total
          ? this.state.transactionData.total
          : 0
      )
    );

    let withdraw = this.state.money_widthdrawal;

    let totalAmount = this.state.total_amount;

    return {
      money_added_in_wallet: money_added_in_wallet,
      money_deducted: totalMoneyDeducted,
      withdrawn: withdraw,
      total: totalAmount,
    };
  }

  render() {
    const PageWrapper = (children) => {
      return children;
    };

    return PageWrapper(
      <>
        <div className="alert-wrap-reporting">
          <Alert
            style={{ marginBottom: "40px" }}
            message="Important Information"
            description="Please note the data below updates every 2 hours."
            type="info"
            showIcon
          />
          <span
            className="ex-ic"
            style={{
              width: "24px",
              height: "24px",
              fontSize: "24px",
              lineHeight: "24px",
              display: "inline-block",
              color: "#0068FF",
            }}
          >
            <Icon type="exclamation-circle" theme="filled" />
          </span>
        </div>
        <div className="wallet-container">
          <DeductedFromWallet
            currency={this.props.currency}
            data={this.state.transactionData}
            loading={this.state.transactionsCounterLoading}
            startDate={this.startDateHandler(this.state.startDate)}
            endDate={this.endDateHandler(this.state.endDate)}
          />
          <div>
            <AddedInWallet
              show_remit_reverse={false}
              currency={this.props.currency}
              title="Money Added In Wallet"
              data={this.state.money_added}
              total={
                this.state.money_added && this.state.money_added.total
                  ? this.state.money_added.total
                  : 0
              }
              loading={this.state.transactionsCounterLoading}
              startDate={this.startDateHandler(this.state.startDate)}
              endDate={this.endDateHandler(this.state.endDate)}
            />

            <AddedInWallet2
              show_remit_reverse={true}
              remit_reverse={this.state.remit_reverse}
              currency={this.props.currency}
              title="Current Wallet Balance"
              data={this.currentWalletBalance()}
              loading={this.state.transactionsCounterLoading}
              startDate={this.startDateHandler(this.state.startDate)}
              endDate={this.endDateHandler(this.state.endDate)}
            />
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  store: state.auth.auth.store,
});
export default connect(mapStateToProps)(TransactionsReport);
