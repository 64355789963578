import React, { Component } from "react";
import { Card, Button, Radio } from "antd";
import Progressbar from "./Progressbar";
import "./index.scss";

class QuestionScreen2Step2 extends Component {
  state = {};

  render() {
    return (
      <Card className="questionScreen2">
        <div className="questionScreen2-header-txt">
          <p className="questionScreen2-account-txt">
            Let Us Set Up Your Account
          </p>
          <p className="step1">Step 2 of 3</p>
        </div>

        <Progressbar
          questionScreen_step={this.props.questionScreen2_step2}
          activeStep={this.props.activeStep}
        />

        <p className="question-following-txt">
          Which of the following best describes your need?
        </p>

        <div className="step-list2">
          {this.props.Step1Selected === "dropshipping" && (
            <ul>
              {this.props.step2_options.dropshipping.map((step2) => (
                <li className="questionScreenStep2">
                  <Radio
                    className="step2-list-checkbox"
                    checked={this.props.Step2Selected === step2.key}
                    onChange={() => this.props.onChangeStep2(step2.key)}
                  >
                    {" "}
                    <span dangerouslySetInnerHTML={{ __html: step2.value }} />
                  </Radio>
                </li>
              ))}
            </ul>
          )}
          {this.props.Step1Selected === "d2c_dtc" && (
            <ul>
              {this.props.step2_options.d2c.map((step2) => (
                <li className="questionScreenStep2">
                  <Radio
                    className="step2-list-checkbox"
                    checked={this.props.Step2Selected === step2.key}
                    onChange={() => this.props.onChangeStep2(step2.key)}
                  >
                    {" "}
                    <span dangerouslySetInnerHTML={{ __html: step2.value }} />
                  </Radio>
                </li>
              ))}
            </ul>
          )}
          {this.props.Step1Selected === "digial_media" && (
            <ul>
              {this.props.step2_options.media_buying.map((step2) => (
                <li className="questionScreenStep2">
                  <Radio
                    className="step2-list-checkbox"
                    checked={this.props.Step2Selected === step2.key}
                    onChange={() => this.props.onChangeStep2(step2.key)}
                  >
                    <span dangerouslySetInnerHTML={{ __html: step2.value }} />
                  </Radio>
                </li>
              ))}
            </ul>
          )}
          {this.props.Step1Selected === "performance_agency" && (
            <ul>
              {this.props.step2_options.agency.map((step2) => (
                <li className="questionScreenStep2">
                  <Radio
                    className="step2-list-checkbox"
                    checked={this.props.Step2Selected === step2.key}
                    onChange={() => this.props.onChangeStep2(step2.key)}
                  >
                    <span dangerouslySetInnerHTML={{ __html: step2.value }} />
                  </Radio>
                </li>
              ))}
            </ul>
          )}
        </div>
        <Button
          onClick={() => this.props.toggleContinue("questionScreen2_step3")}
          className={
            !!this.props.Step2Selected.length ? "continue-btn2" : "continue-btn"
          }
        >
          Continue
        </Button>
      </Card>
    );
  }
}

export default QuestionScreen2Step2;
