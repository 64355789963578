import React, { Component } from "react";
import "./index.scss";
import { ReactComponent as VfulfillLogo } from "../../../../assets/images/icons/vFULLfillLogo.svg";
import { ReactComponent as RightLogo } from "../../../../assets/images/icons/greenroundcorrect.svg";
export default class TabFirstContainer extends Component {
  render() {
    return (
      <div className="tabFirstContainer">
        <div className="logocontainer">
          <VfulfillLogo />
        </div>
        <div className="bar-container">
          <div className="bar">
            <span className="right-logo">
              <RightLogo />
            </span>{" "}
            <span className="data">Hassle Free eCom Operations</span>
          </div>
          <div className="bar">
            <span className="right-logo">
              <RightLogo />
            </span>{" "}
            <span className="data">Winning Products Ideas</span>
          </div>
          <div className="bar">
            <span className="right-logo">
              <RightLogo />
            </span>{" "}
            <span className="data">Fastest Sourcing from China / India</span>
          </div>
          <div className="bar">
            <span className="right-logo">
              <RightLogo />
            </span>{" "}
            <span className="data">1 Day Fulfillment</span>
          </div>
          <div className="bar">
            <span className="right-logo">
              <RightLogo />
            </span>{" "}
            <span className="data">Fast COD Shipping</span>
          </div>
          <div className="bar">
            <span className="right-logo">
              <RightLogo />
            </span>{" "}
            <span className="data">AI Business Insights</span>
          </div>
          <div className="bar">
            <span className="right-logo">
              <RightLogo />
            </span>{" "}
            <span className="data">100% Transparent Accounting</span>
          </div>

        </div>
      </div>
    );
  }
}
