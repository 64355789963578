import React from "react";
import Found from "../../../../assets/images/gif/found.gif";
import "./index.scss";

const NotFoundCard = (props) => {
  const { mainHeading, secondHeading, thirdHeading } = props;
  return (
    <div className="not-found-wraper">
      <div>
        <img src={Found} alt="" />
      </div>

      <h2>{mainHeading}</h2>
      {!!secondHeading ? secondHeading : null}

      {!!thirdHeading ? thirdHeading : null}
    </div>
  );
};

export default NotFoundCard;
