import React, { Component } from "react";
import "./index.scss";

class BundleVariantnav extends Component {
  render() {
    return (
      <div className="bundle-variant-nav-data fullorder_data">
        <div className="variant-nav">
          <div className="ordfill">
            <div className="ordfillwrap">
              <div className="left" style={{ marginLeft: "7px" }}>
                <div
                  style={{
                    color: "#000",
                    fontSize: "12px",
                    letterSpacing: "0.032em",
                    lineHeight: "24px",
                  }}
                >
                  <span>
                    {" "}
                    {this.props.orderurl.includes("#order")
                      ? "Orders"
                      : "Store Products"}{" "}
                  </span>
                  {this.props.orderurl.includes("#order") ? (
                    <span className="arrow-txt">&gt;</span>
                  ) : null}
                  {this.props.orderurl.includes("#order") ? (
                    <span className="variant-mapping-txt">Open Orders </span>
                  ) : null}
                  <span className="arrow-txt">&gt;</span>
                  <span className="variant-mapping-txt">Link Product(s) </span>
                  <span className="arrow-txt">&gt;</span>
                  <span className="variant-mapping-txt">
                    Product Bundle Variant Mapping
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BundleVariantnav;
