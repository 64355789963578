import React, { Component } from "react";
import { Form, message } from "antd";
import "intl-tel-input/build/css/intlTelInput.css";
import KycIndividualOrganisation from "./KycIndividualOrganisation";
import NonIndiankycForm from "./NonIndiankycForm/";
import GlobalSupportEmail from "./NonIndiankycForm/GlobalSupportEmailModal";
import GlobalSupportPhoneNumber from "./NonIndiankycForm/GlobalSupportPhoneNoModal";
import axios from "axios";

class NonIndianProfile extends Component {
  state = {
    currentStep: this.props.step - 3,
    totalSteps: 2,
    kycType: this.props.kycData.kyc_type ? this.props.kycData.kyc_type : "",
    files: {},
    questions: {
      phonecall: this.props.kycData.phone_number
        ? this.props.kycData.phone_number
        : "",
      dialCode: "86",
    },
    phoneverified: 0,
    emailverified: 0,
    chnageNumberEmail: false,
    gloabalsupportEmail: {
      visible: false,
    },
    gloablsupportNumber: {
      visible: false,
    },
    globalemailotp: {
      emailotp1: "",
      emailotp2: "",
      emailotp3: "",
      emailotp4: "",
      emailotp5: "",
      emailotp6: "",
    },
    globalphoneotp: {
      phoneotp1: "",
      phoneotp2: "",
      phoneotp3: "",
      phoneotp4: "",
      phoneotp5: "",
      phoneotp6: "",
    },
    supportNumber2: {
      contactno: "",
      prefix_1:
        this.props.kycData && this.props.kycData.phone_code
          ? this.props.kycData.phone_code
          : "86",
    },

    supportEmail2: {
      EmailInpute: "",
    },
    verifiedmsg: false,
    verifiedEmailId: "",
    verifiedcontact: false,
    verifiedPhoneNO: "",
    nonindiankycdata1: {},

    emailOtpLoading: false,
    numberOtpLoading: false,
  };
  // global phone modal logic

  nonIndianPreviousDataStoe(key, value) {
    this.setState({
      nonindiankycdata1: {
        ...this.state.nonindiankycdata1,
        [key]: value,
      },
    });
  }

  changePrefixState = (field, value) => {
    this.setState({
      supportNumber2: {
        ...this.state.supportNumber2,
        [field]: value,
      },
    });
  };

  toggleGlobalPhoneNumber() {
    this.setState({
      gloablsupportNumber: {
        visible: !this.state.gloablsupportNumber.visible,
      },
    });
  }
  otpChnageNumber(field, value) {
    this.setState({
      globalphoneotp: {
        ...this.state.globalphoneotp,
        [field]: value,
      },
    });
  }
  handleContactChange(ob) {
    let onlyNumber = ob.value
      .replace(/[^0-9]+/g, "")
      .slice(
        !!Object.entries(ob.extraVal).length ? ob.extraVal.dialCode.length : 0
      );

    if (ob.value === 0 && ob.value === "") {
      this.setState({
        supportNumber2: {
          ...this.state.supportNumber2,
          contactno: "",
          prefix_1: "",
        },
      });
    } else if (ob.value.length <= 12 && parseInt(ob.value)) {
      this.setState({
        supportNumber2: {
          ...this.state.supportNumber2,
          contactno: onlyNumber,
          prefix_1: ob.extraVal.dialCode,
        },
      });
    }
  }
  // handleSupportPhoneChange(field, value) {
  //   this.setState({
  //     supportNumber2: {
  //       ...this.state.supportNumber2,
  //       [field]: value,
  //     },
  //   });
  // }

  handleSupportPhoneChange(ob) {
    let onlyNumber = ob.value
      .replace(/[^0-9]+/g, "")
      .slice(
        !!Object.entries(ob.extraVal).length ? ob.extraVal.dialCode.length : 0
      );

    if (onlyNumber.length > 0) {
      if (
        onlyNumber === this.state.verifiedPhoneNO ||
        this.state.verifiedcontact
      ) {
        if (onlyNumber === this.state.verifiedPhoneNO) {
          this.setState({ verifiedcontact: true });
        } else {
          this.setState({ verifiedcontact: false });
        }
      } else {
        this.setState({
          supportNumber2: {
            ...this.state.supportNumber2,
            [ob.field]: onlyNumber,
            prefix_1: ob.extraVal.dialCode,
          },
        });
      }
    }
  }

  updatePhonenumber() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/generateotp",
          method: "post",
          data: {
            type: "mobile",
            status: "new",
            location: "support",
            mobile: {
              number: this.state.supportNumber2.contactno,
              code: this.state.supportNumber2.prefix_1,
            },
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                supportNumber2: {
                  ...this.state.supportNumber2,
                },
              });

              message.success("Phone number has been updated!!");
              this.toggleChnageNumberemail();
            } else {
              this.setState({
                ...this.state.supportNumber2.contactno,
                loading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
            this.setState({
              ...this.state.supportNumber2.contactno,
              loading: false,
            });
          });
      }
    );
  }

  VerifyPhone() {
    this.setState(
      {
        numberOtpLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/generateotp",
          method: "post",
          withCredentials: true,
          data: {
            type: "mobile",
            location: "support",
            status: "new",
            mobile: {
              number: this.state.supportNumber2.contactno,
              code: this.state.supportNumber2.prefix_1,
            },
          },
        })
          .then((res) => {
            if (res.data.success === 2) {
              message.success(res.data.msg);
              this.setState({
                numberOtpLoading: false,
                verifiedcontact: true,
                verifiedPhoneNO: this.state.supportNumber2.contactno,
                phoneverified: 1,
              });
              return;
            } else {
              if (res.data.success === 1) {
                this.setState({
                  numberOtpLoading: false,
                });
                this.toggleGlobalPhoneNumber();
                message.success(res.data.msg);
              } else {
                message.error(res.data.msg);
                this.setState({
                  numberOtpLoading: false,
                });
              }
            }
          })
          .catch((e) => {
            this.setState({
              numberOtpLoading: false,
            });
            message.error(e.msg);
          });
      }
    );
  }

  verifyPhoneContinue = () => {
    this.setState(
      {
        numberOtpLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/verify_otp",
          method: "post",
          withCredentials: true,
          data: {
            location: "support",
            type: "mobile",
            mobile: {
              number: this.state.supportNumber2.contactno,
              code: this.state.supportNumber2.prefix_1,
              otp: Number(
                this.state.globalphoneotp.phoneotp1 +
                  this.state.globalphoneotp.phoneotp2 +
                  this.state.globalphoneotp.phoneotp3 +
                  this.state.globalphoneotp.phoneotp4 +
                  this.state.globalphoneotp.phoneotp5 +
                  this.state.globalphoneotp.phoneotp6
              ),
            },
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                numberOtpLoading: false,
                phoneverified: 1,
                supportNumber2: {
                  ...this.state.supportNumber2,
                },
              });
              message.success(res.data.msg);
              this.toggleGlobalPhoneNumber();
            } else {
              this.setState({
                numberOtpLoading: false,
              });

              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              numberOtpLoading: false,
            });
            message.error(e.message);
          });
      }
    );
  };

  //global email modal logic
  toggleGlobalSupportEmail() {
    this.setState({
      gloabalsupportEmail: {
        visible: !this.state.gloabalsupportEmail.visible,
      },
    });
  }

  // handleSupportEmailChange(field, value) {
  //   this.setState({
  //     supportEmail2: {
  //       ...this.state.supportEmail2,
  //       [field]: value,
  //     },
  //   });
  // }

  handleSupportEmailChange(field, value) {
    if (value.length > 0) {
      if (value === this.state.verifiedEmailId || this.state.verifiedmsg) {
        if (value === this.state.verifiedEmailId) {
          this.setState({ verifiedmsg: true });
        } else {
          this.setState({ verifiedmsg: false });
        }
      } else {
        this.setState({
          supportEmail2: {
            ...this.state.supportEmail2,
            [field]: value,
          },
        });
      }
    }
  }

  otpChnageEmail(field, value) {
    this.setState({
      globalemailotp: {
        ...this.state.globalemailotp,
        [field]: value,
      },
    });
  }
  handleEmailChnage(value) {
    this.setState({
      supportEmail2: {
        ...this.state.supportEmail2,
        EmailInpute: value,
      },
    });
  }

  verifyEmailContinue = () => {
    this.setState(
      {
        emailOtpLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/verify_otp",
          method: "post",
          withCredentials: true,
          data: {
            location: "support",
            type: "email",
            email: {
              email: this.state.supportEmail2.EmailInpute,
              otp: Number(
                this.state.globalemailotp.emailotp1 +
                  this.state.globalemailotp.emailotp2 +
                  this.state.globalemailotp.emailotp3 +
                  this.state.globalemailotp.emailotp4 +
                  this.state.globalemailotp.emailotp5 +
                  this.state.globalemailotp.emailotp6
              ),
            },
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                emailOtpLoading: false,
                emailverified: 1,
                supportEmail2: {
                  ...this.state.supportEmail2,
                },
              });
              message.success(res.data.msg);
              this.toggleGlobalSupportEmail();
            } else {
              this.setState({
                emailOtpLoading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              emailOtpLoading: false,
            });
            message.error(e.message);
          });
      }
    );
  };

  updateEmail() {
    if (
      this.state.supportEmail2.EmailInpute.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      this.setState(
        {
          loading: true,
        },
        () => {
          axios({
            url: process.env.REACT_APP_API_URL + "/user/generateotp",
            method: "post",
            data: {
              type: "email",
              status: "new",
              location: "support",
              email: this.state.supportEmail2.EmailInpute,
            },
            withCredentials: true,
          })
            .then((res) => {
              if (res.data.success === 1) {
                this.setState({
                  supportEmail2: {
                    ...this.state.supportEmail2,
                  },
                });

                message.success("Email updated successfully.");
                this.toggleChnageNumberemail();
              } else {
                this.setState({
                  supportEmail2: {
                    ...this.state.supportEmail2,
                  },
                  loading: false,
                });
                message.error(res.data.msg);
              }
            })
            .catch((e) => {
              message.error(e.message);
              this.setState({
                supportEmail2: {
                  ...this.state.supportEmail2,
                },
                loading: false,
              });
            });
        }
      );
    }
  }

  VerifyEmail() {
    if (
      this.state.supportEmail2.EmailInpute.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      this.setState(
        {
          emailOtpLoading: true,
        },
        () => {
          axios({
            url: process.env.REACT_APP_API_URL + "/user/generateotp",
            method: "post",
            withCredentials: true,
            data: {
              type: "email",
              location: "support",
              status: "new",
              email: this.state.supportEmail2.EmailInpute,
            },
          })
            .then((res) => {
              if (res.data.success === 2) {
                message.success(res.data.msg);
                this.setState({
                  emailOtpLoading: false,
                  verifiedmsg: true,
                  verifiedEmailId: this.state.supportEmail2.EmailInpute,
                  emailverified: 1,
                });
                return;
              } else {
                if (res.data.success === 1) {
                  this.setState({
                    emailOtpLoading: false,
                  });
                  this.toggleGlobalSupportEmail();
                  message.success(res.data.msg);
                } else {
                  this.setState({
                    emailOtpLoading: false,
                  });
                  message.error(res.data.msg);
                }
              }
            })
            .catch((e) => {
              this.setState({
                emailOtpLoading: false,
              });
              message.error(e.msg);
            });
        }
      );
    } else {
      message.error("Please enter a valid Email Address.");
    }
  }

  //common logic

  toggleChnageNumberemail() {
    this.setState({
      chnageNumberEmail: !this.state.chnageNumberEmail,
    });
  }

  toggleBackButton() {
    this.setState(
      {
        kycType: "",
      },
      () => this.props.parrentToChildKycValue(this.state.kycType)
    );
  }

  profileBakButton() {
    this.setState(
      {
        currentStep: 1,
        files: {},
      },
      () => this.props.previousStep()
    );
  }

  uploadBcakButton() {
    this.setState({
      currentStep: 2,
    });
  }

  // phonecallChangeHandler(val) {
  //   this.setState((state) => ({
  //     questions: {
  //       dialCode: val.dialCode,
  //       phonecall: val.phone,
  //     },
  //   }));
  // }

  componentDidMount() {
    const { setFieldsValue } = this.props.form;
    const { bank = {}, address = {}, ...rest } = this.props.kycData;
    const data = { ...rest };
    Object.keys(bank).forEach((k) => (data[`bank[${k}]`] = bank[k]));
    Object.keys(address).forEach((k) => (data[`address[${k}]`] = address[k]));
    setFieldsValue(data);
  }

  goToNextPart = () => {
    if (this.state.currentStep === 2) {
    } else {
      // if (
      //   (this.state.phoneverified === 0 &&
      //     this.state.kycType === "Individual") ||
      //   (this.state.phoneverified === 0 &&
      //     this.state.kycType === "Organisation")
      // ) {
      //   message.error("Please enter a valid phone number.");
      //   return;
      // }
      // if (
      //   (this.state.emailverified === 0 &&
      //     this.state.kycType === "Individual") ||
      //   (this.state.emailverified === 0 &&
      //     this.state.kycType === "Organisation")
      // ) {
      //   message.error("Please enter a valid email address.");
      //   return;
      // }
    }

    if (this.state.currentStep === 1) {
      let fieldsToValidate = [];
      if (this.state.kycType === "Individual") {
        fieldsToValidate = [
          "individual_name",
          "address",
          "id_num",
          "phone",
          "prefix",
          "upload_id_num",
          "payment_info",
        ];
      } else {
        fieldsToValidate = [
          "org_name",
          "org_idnum",
          "org_idnum1",
          "org_authidnum",
          "org_authowner",
          "address",
          "phone",
          "prefix",
          "org_authidnum1",
        ];
      }
      this.props.form.validateFields(fieldsToValidate, (err, values) => {
        if (!err) {
          let docs = {};
          Object.keys(this.state.files).forEach(
            (k) => (docs[k] = this.state.files[k][0].name)
          );
          delete values["upload_id_num"];
          delete values["org_authidnum"];
          delete values["org_idnum"];
          const data = { docs };
          this.props.saveUserKYC({
            ...values,
            ...data,
            kyc_type: this.state.kycType,
            phone: this.state.questions.phonecall,
            prefix: `+` + this.state.questions.dialCode,
          });
          this.setState((state) => ({
            currentStep: state.currentStep + 1,
          }));
        }
      });
    } else if (this.state.currentStep === 2) {
      this.props.form.validateFields(["bank"], (err, values) => {
        console.log(values);
        if (!err) {
          if (!values.bank.accno && !values.bank.iban) {
            message.error(
              "Bank Account number or IBAN is required to proceed."
            );
            return;
          }
          if (
            !values.bank.routing_number &&
            !values.bank.swift &&
            !values.bank.bic_code
          ) {
            message.error(
              "Please enter either the SWIFT/BIC Code/Routing number"
            );
            return;
          }

          this.props.saveUserKYC({
            ...values,
            kyc_type: this.state.kycType,
          });
          this.setState((state) => ({
            currentStep: state.currentStep + 1,
          }));
        }
      });
    }
    // } else {
    //   this.props.form.validateFieldsAndScroll((err, values) => {
    //     if (!err) {
    //       let docs = {};
    //       Object.keys(this.state.files).forEach(
    //         (k) => (docs[k] = this.state.files[k][0].name)
    //       );
    //       if (
    //         Object.values(docs).filter((d) => d.trim().length > 0).length !==
    //         (this.state.kycType === "Individual" ? 3 : 4)
    //       ) {
    //         message.error("Please upload all the required documents!");
    //         return;
    //       }
    //       const data = { docs };

    //       this.props.saveUserKYC({
    //         ...data,
    //         kyc_type: this.state.kycType,
    //       });
    //     }
    //   });
    // }
  };

  onFileUpload = (docType, files) => {
    let fileList = [...files];
    fileList = files.slice(-1).map((file) => {
      if (file.response && file.response.success === 1) {
        file.name = file.response.file;
      }
      return file;
    });

    const file = fileList[0];
    if (file && file.response && file.response.success !== 1) {
      message.error(file.response.msg);
      return;
    }

    this.setState((state) => ({
      files: {
        ...state.files,
        [docType]: [file],
      },
    }));
  };

  setKYCType(key) {
    this.setState(
      {
        kycType: key,
        nonindiankycdata1: {},
      },
      () => this.props.parrentToChildKycValue(this.state.kycType)
    );
  }

  removeAddresProof = (docType) => {
    if ((docType = "address_proof"))
      this.setState((state) => ({
        files: {
          ...state.files,
          [docType]: [],
        },
      }));
  };

  removeIdentityCard = (docType) => {
    if ((docType = "id_card"))
      this.setState((state) => ({
        files: {
          ...state.files,
          [docType]: [],
        },
      }));
  };

  onremoveorgidcard = (docType) => {
    if ((docType = "org_id_doc"))
      this.setState((state) => ({
        files: {
          ...state.files,
          [docType]: [],
        },
      }));
  };

  onRemoveCancelCheque = (docType) => {
    if ((docType = "cancelled_cheque"))
      this.setState((state) => ({
        files: {
          ...state.files,
          [docType]: [],
        },
      }));
  };

  onremovesignatureidcard = (docType) => {
    if ((docType = "signatory_id_card"))
      this.setState((state) => ({
        files: {
          ...state.files,
          [docType]: [],
        },
      }));
  };

  render() {
    return (
      <>
        {this.state.kycType === "Individual" ||
        this.state.kycType === "Organisation" ? null : (
          <KycIndividualOrganisation
            kycType={this.state.kycType}
            setKYCType={(a) => this.setKYCType(a)}
            toggleFirststepBackbutton={() =>
              this.props.toggleFirststepBackbutton()
            }
            goToNextStep={this.props.goToNextStep}
          />
        )}

        {this.state.kycType === "Individual" ||
        this.state.kycType === "Organisation" ? (
          <NonIndiankycForm
            numberOtpLoading={this.state.numberOtpLoading}
            emailOtpLoading={this.state.emailOtpLoading}
            questions={this.state.questions}
            currentStep={this.state.currentStep}
            goToNextStep={this.props.goToNextStep}
            step={this.props.step}
            totalSteps={this.state.totalSteps}
            kycType={this.state.kycType}
            files={this.state.files}
            kycData={this.props.kycData}
            form={this.props.form}
            // phonecallChangeHandler={(val) => this.phonecallChangeHandler(val)}
            goToNextPart={this.goToNextPart}
            onFileUpload={(docType, files) => this.onFileUpload(docType, files)}
            removeAddresProof={(docType) => this.removeAddresProof(docType)}
            removeIdentityCard={(docType) => this.removeIdentityCard(docType)}
            onremoveorgidcard={(docType) => this.onremoveorgidcard(docType)}
            onremovesignatureidcard={(docType) =>
              this.onremovesignatureidcard(docType)
            }
            onRemoveCancelCheque={(docType) =>
              this.onRemoveCancelCheque(docType)
            }
            toggleBackButton={() => this.toggleBackButton()}
            profileBakButton={() => this.profileBakButton()}
            uploadBcakButton={() => this.uploadBcakButton()}
            VerifyPhone={() => this.VerifyPhone()}
            VerifyEmail={() => this.VerifyEmail()}
            handleSupportEmailChange={(field, value) =>
              this.handleSupportEmailChange(field, value)
            }
            handleSupportPhoneChange={(ob) => this.handleSupportPhoneChange(ob)}
            supportNumber2={this.state.supportNumber2.contactno}
            supportEmail2={this.state.supportEmail2.EmailInpute}
            changePrefixState={(field, value) =>
              this.changePrefixState(field, value)
            }
            prefix_1={this.state.supportNumber2.prefix_1}
            phoneverified={this.state.phoneverified}
            emailverified={this.state.emailverified}
            verifiedmsg={this.state.verifiedmsg}
            verifiedcontact={this.state.verifiedcontact}
            nonindiankycdata1={this.state.nonindiankycdata1}
            nonIndianPreviousDataStoe={(key, value) =>
              this.nonIndianPreviousDataStoe(key, value)
            }
          />
        ) : null}

        <GlobalSupportEmail
          numberOtpLoading={this.state.numberOtpLoading}
          emailOtpLoading={this.state.emailOtpLoading}
          toggleGlobalSupportEmail={() => this.toggleGlobalSupportEmail()}
          onCancel={() => this.toggleGlobalSupportEmail()}
          visible={this.state.gloabalsupportEmail.visible}
          otpChnageEmail={(field, value) => this.otpChnageEmail(field, value)}
          emailotp={this.state.globalemailotp}
          toggleChnageNumberemail={() => this.toggleChnageNumberemail()}
          chnageNumberEmail={this.state.chnageNumberEmail}
          updateEmail={() => this.updateEmail()}
          verifyEmailContinue={() => this.verifyEmailContinue()}
          handleEmailChnage={(value) => this.handleEmailChnage(value)}
          supportEmail2={this.state.supportEmail2.EmailInpute}
        />
        <GlobalSupportPhoneNumber
          numberOtpLoading={this.state.numberOtpLoading}
          emailOtpLoading={this.state.emailOtpLoading}
          toggleGlobalPhoneNumber={() => this.toggleGlobalPhoneNumber()}
          onCancel={() => this.toggleGlobalPhoneNumber()}
          visible={this.state.gloablsupportNumber.visible}
          otpChnageNumber={(field, value) => this.otpChnageNumber(field, value)}
          phoneotp={this.state.globalphoneotp}
          toggleChnageNumberemail={() => this.toggleChnageNumberemail()}
          chnageNumberEmail={this.state.chnageNumberEmail}
          updatePhonenumber={() => this.updatePhonenumber()}
          verifyPhoneContinue={() => this.verifyPhoneContinue()}
          handleContactChange={(value) => this.handleContactChange(value)}
          supportNumber2={this.state.supportNumber2.contactno}
          changePrefixState={(field, value) =>
            this.changePrefixState(field, value)
          }
          prefix_1={this.state.supportNumber2.prefix_1}
        />
      </>
    );
  }
}

export default Form.create()(NonIndianProfile);
