import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import "./index.scss";
class ProductTitle extends React.Component {
  render() {
    return (
      <div className="product-title">
        {/* check packaging or not */}
        {this.props.packaging ? (
          <p
            className="f12 clamp1 mb4"
            style={{
              color: "#1268FB",
              fontWeight: 500,
              WebkitBoxOrient: "vertical",
              marginRight: 30,
            }}
          >
            <Tooltip title={this.props.product.catalogname}>
              <Link
                className="f12 mb4 hover-underline-animation"
                to={`/${this.props.storegeo}/${this.props.storeid}/packaging/${
                  this.props.product.vfprodid
                }`}
                target="_blank"
              >
                {this.props.product.catalogname &&
                this.props.product.catalogname
                  ? this.props.product.catalogname
                  : "Product Name not Available"}
              </Link>
            </Tooltip>
          </p>
        ) : (
          <p
            className="f12 clamp1 mb4"
            style={{
              color: "#1268FB",
              fontWeight: 500,
              WebkitBoxOrient: "vertical",
              marginRight: 30,
            }}
          >
            <Tooltip title={this.props.product.catalogname}>
              <Link
                className="f12 mb4 hover-underline-animation"
                to={`/${this.props.storegeo}/${this.props.storeid}/products/${
                  this.props.product.vfprodid
                }`}
                target="_blank"
              >
                {this.props.product.catalogname &&
                this.props.product.catalogname
                  ? this.props.product.catalogname
                  : "Product Name not Available"}
              </Link>
            </Tooltip>
          </p>
        )}
      </div>
    );
  }
}

export default ProductTitle;
