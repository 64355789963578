import React, { Component } from "react";
import { Row } from "antd";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
import DataSet from "@antv/data-set";
import { ReactComponent as NoGraphIcon } from "../../../../../../assets/images/icons/500.svg";
import { formatNumber } from "../../../../../../Helpers/Pricing";

class OrderTrendGraph extends Component {
  render() {
    if (
      !(
        this.props.product &&
        this.props.product.advertising_data &&
        this.props.product.advertising_data.ordertrend
      )
    ) {
      return null;
    }
    const salesData = Object.entries(
      this.props.product.advertising_data.ordertrend || {}
    ).map((e) => ({
      date: e[0],
      Orders: e[1],
    }));
    const cols = {
      date: {
        range: [0, 1],
      },
    };
    const ds = new DataSet();
    const dv = ds.createView().source(salesData);
    dv.transform({
      type: "fold",
      fields: ["Orders"],
      key: "Date",
      value: "Orders",
    });

    return (
      <div>
        <h4>Order Trend Graph</h4>
        {salesData.reduce((acc, c) => {
          return acc + c.Orders;
        }, 0) > 0 ? (
          <Chart height={400} data={dv} scale={cols} forceFit>
            <Legend />
            <Axis name="date" visible={false} />
            <Axis
              name="Orders"
              label={{
                formatter: (val) => {
                  return formatNumber(val, 0);
                },
              }}
            />
            <Tooltip
              crosshairs={{
                type: "y",
              }}
              containerTpl='<div class="g2-tooltip"><p class="g2-tooltip-title"></p><table class="g2-tooltip-list"></table></div>'
              itemTpl={`<tr class=&quot;g2-tooltip-list-item&quot;><td style=&quot;color:{color}&quot;><span class=&quot;g2-tooltip-list-item-dot&quot;></span><span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span> {name}: </td><td>{value}</td></tr>`}
              offset={50}
              g2-tooltip={{
                position: "absolute",
                visibility: "hidden",
                border: "1px solid #000",
                borderRadius: "4px",
                backgroundColor: "rgba(0, 0, 0, 0.9)",
                color: "#fff",
                opacity: "1",
                padding: "1rem 1.25rem",
                transition: "top 200ms,left 200ms",
              }}
            />
            <Geom type="line" position="date*Orders" size={2} color={"Date"} />
            <Geom
              type="point"
              position="date*Orders"
              size={4}
              shape={"circle"}
              color={"Date"}
              style={{
                stroke: "#fff",
                lineWidth: 1,
              }}
            />
          </Chart>
        ) : (
          <Row
            style={{
              marginBottom: 50,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NoGraphIcon />
            <div style={{ textAlign: "left", marginLeft: 30 }}>
              <h1 style={{ marginBottom: 0 }}>Not Enough Data!</h1>
              <p style={{ margin: 0, fontSize: 18 }}>
                We could not find enough data to build a graph!
              </p>
            </div>
          </Row>
        )}
      </div>
    );
  }
}

export default OrderTrendGraph;
