import React from "react";
import StarRating from "./StarRating";

// const PieChart = ({ percentage, text }) => {
//   return (
//     <svg height="80" width="80" viewBox="0 0 80 80">
//       <circle r="40" cx="40" cy="40" fill="#FEB2C6" />
//       <circle
//         r="20"
//         cx="40"
//         cy="40"
//         fill="transparent"
//         stroke="#FD104D"
//         strokeWidth="40"
//         strokeDasharray={`calc(${percentage} * 125.6 / 100) 125.6`}
//         transform="rotate(-90) translate(-80)"
//       />
//       <circle r="30" cx="40" cy="40" fill="white" />
//       <text
//         x="50%"
//         y="50%"
//         fontSize="13"
//         fill="#212121"
//         dominantBaseline="middle"
//         textAnchor="middle"
//       >
//         {text}
//       </text>
//     </svg>
//   );
// };

const ReviewsTab = ({ data = {} }) => {
  const { rating = {}, user_ratings = [] } = data;
  let averageRating = 0;
  let totalRatings = 0;

  if (data.total_rating) {
    totalRatings = data.total_rating;
  }
  if (data.average_rating) {
    averageRating = data.average_rating;
  }

  return (
    <div>
      <div style={{ display: "flex", marginBottom: 24 }}>
        <div style={{ flex: 1, marginRight: 24 }}>
          <p className="f12 mb16" style={{ color: "#898989" }}>
            Product Ratings:
          </p>
          <ul style={{ margin: 0, padding: 0 }}>
            {Object.entries(rating).map((rating, index) => {
              return (
                <li
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                  className="mb8"
                >
                  <span className="f12" style={{ width: 50 }}>
                    {rating[0].replace(/_/g, " ")}
                  </span>
                  <span
                    style={{
                      flex: 1,
                      background: "#FFF0C4",
                      margin: "0px 16px",
                      height: 8,
                      borderRadius: 4,
                    }}
                  >
                    <span
                      style={{
                        width: rating[1],
                        background: "#FEBA2C",
                        height: 8,
                        borderRadius: 4,
                        display: "flex",
                      }}
                    />
                  </span>
                  <span
                    className="f12"
                    style={{
                      width: 46,
                      padding: 8,
                      background: "#F5F5F5",
                      borderRadius: 25,
                      textAlign: "center",
                    }}
                  >
                    {rating[1]}
                  </span>
                </li>
              );
            })}
          </ul>
          <p className="f12" style={{ color: "#898989" }}>
            Average Rating: <StarRating rating={averageRating} /> (
            {totalRatings} Ratings)
          </p>
        </div>
        <div style={{ flex: 1, marginLeft: 24 }}>
          {/* <p className="f12 mb16" style={{ color: "#898989" }}>
            Delivery Ratings:
          </p>
          <ul
            style={{
              listStyleType: "none",
              margin: 0,
              padding: 0,
              display: "flex",
            }}
          >
            <li
              style={{
                marginRight: 24,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PieChart percentage={25} text="1/4" />
              <p
                style={{
                  fontSize: 12,
                  textAlign: "center",
                  marginTop: 12,
                  color: "#212121",
                }}
              >
                Packaging
                <br />
                Quality
              </p>
            </li>
            <li
              style={{
                marginRight: 24,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PieChart percentage={50} text="2/4" />
              <p
                style={{
                  fontSize: 12,
                  textAlign: "center",
                  marginTop: 12,
                  color: "#212121",
                }}
              >
                Delivery
                <br />
                Experience
              </p>
            </li>
            <li
              style={{
                marginRight: 24,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PieChart percentage={75} text="3/4" />
              <p
                style={{
                  fontSize: 12,
                  textAlign: "center",
                  marginTop: 12,
                  color: "#212121",
                }}
              >
                Customer
                <br />
                Experience
              </p>
            </li>
          </ul>
          <p className="f12 mb16" style={{ color: "#898989" }}>
            Average Rating: <span style={{ color: "#212121" }}>8/10</span> (
            {totalRatings} Ratings)
          </p> */}
        </div>
      </div>
      {user_ratings.length > 0 ? (
        <div>
          <p className="f12 mb16" style={{ color: "#898989" }}>
            Top Reviews:
          </p>
          <ul
            style={{
              listStyleType: "none",
              display: "flex",
              flexDirection: "column",
              margin: 0,
              padding: 0,
            }}
          >
            {user_ratings.map((review, index) => {
              return (
                <li
                  key={index}
                  style={{
                    padding: "12px 18px",
                    background: "#FFF0C4",
                    marginBottom: 16,
                  }}
                >
                  <p
                    className="f12"
                    style={{ color: "#212121", marginBottom: 8 }}
                  >
                    {review.review}
                  </p>
                  <div
                    className="f12"
                    style={{
                      color: "#898989",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p style={{ marginBottom: 0 }}>{review.date}</p>
                    <p style={{ marginBottom: 0 }}>
                      <StarRating rating={Number(review.rating)} />
                    </p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default ReviewsTab;
