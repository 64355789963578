import React, { Component } from "react";
import { Icon } from "antd";
import "./index.scss";
import WithdrawalSummary from "./WithdrawalSummary";
import Transactions from "./Transactions";
import MyWalletSummaryNav from "./MyWalletNav";
import axios from "axios";
import { message } from "antd";
// import OldTransactionsLog from "./OldTransactionslog";
import moment from "moment";
import TransactionSummary from "./TransactionsReport";

class MyWallet extends Component {
  state = {
    isDateRangeValid: true,
    walletsummarynav: {
      WalletSummarybtn: true,
      TransactionLogsbtn: false,
      transactionSummary: false,
    },
    loading2: {
      transactions: true,
    },

    loading: {
      transactions: true,
    },
    filters: {
      startDate: moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      filterBy: "",
      transactionHeads: [],
    },
    sort: {
      field: "transaction_date",
      order: "descend",
    },
    transaction_graph: {
      startDate: moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      data: [{ name: "Debit" }, { name: "Credit" }],
    },
    transactionSummaryFilters: {
      startDate: moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
    transactions: [], // it store transactions of wallet summary and transaction log
    user_kycapproved: 0,
    kyc_submit: 0,

    pagination: {
      showSizeChanger: true,
      total: 0,
      page: 1,
      per_page_count: 20,
    },
    storid: "",
  };

  // refs
  calenderRef = React.createRef();
  child = React.createRef();

  // globalHeads
  globalHeadsArr = [
    {
      value: "cashback_addfunds",
      label: "Add Funds - Cashback",
      category: "wallet_transactions",
    },
    {
      value: "order_confirmation_management_fee",
      label: "Order Management Fee",
      category: "service",
    },
    {
      value: "funds_added_admin",
      label: "Add Funds - Manual",
      category: "wallet_transactions",
    },
    {
      value: "razorpay_addfunds",
      label: "Add Funds - Razorpay",
      category: "wallet_transactions",
    },

    {
      value: "order_placed_ff",
      label: "Fulfilment Fees",
      category: "service",
    },

    {
      value: "coupon_addfunds",
      label: "Coupon Funds",
      category: "wallet_transactions",
    },
    {
      value: "credit_note",
      label: "Credit Note",
      category: "wallet_transactions",
    },
    {
      value: "order_placed_fs",
      label: "Forward Shipping",
      category: "service",
    },
    {
      value: "buyback_handling_fee",
      label: "BuyBack Handling Fee",
      category: "service",
    },
    {
      value: "credit_note_ff",
      label: "Fulfilment Fee Credit",
      category: "service",
    },
    {
      value: "funds_added",
      label: "Fund Addition",
      category: "wallet_transactions",
    },
    {
      value: "withdraw_funds",
      label: "Fund Withdrawal",
      category: "wallet_transactions",
    },
    {
      value: "inventory_rtv",
      label: "Inventory RTV",
      category: "service",
    },
    {
      value: "inward_fees",
      label: "Inward Fees",
      category: "service",
    },
    {
      value: "management_fee",
      label: "Tax Management Fee",
      category: "service",
    },
    {
      value: "management_fee_reverse",
      label: "Tax Management Fee Reverse",
      category: "service",
    },
    {
      value: "orderamount_gst_deductions_reverse",
      label: "Order Amount Gst Return",
      category: "remittance",
    },
    {
      value: "order_cancellation_fee",
      label: "Order Cancellation Fee",
      category: "service",
    },
    {
      value: "order_refund",
      label: "Order Refund",
      category: "other",
    },
    {
      value: "payment_gatway_fee",
      label: "Payment Gateway Fee",
      category: "service",
    },
    {
      value: "prepaid_remittance",
      label: "Prepaid Remittance",
      category: "remittance",
    },
    {
      value: "prepaid_remittance_reverse",
      label: "Prepaid Remittance Reverse",
      category: "remittance",
    },
    {
      value: "order_placed_pp",
      label: "Product Price",
      category: "product",
    },
    {
      value: "credit_note_pp",
      label: "Product Price Credit",
      category: "product",
    },
    {
      value: "orderamount_gst_deductions",
      label: "Remittance GST Deductions",
      category: "remittance",
    },
    {
      value: "order_reverse_pickup",
      label: "Reverse Pick Up",
      category: "service",
    },
    {
      value: "credit_note_fs",
      label: "Shipping Charges Credit",
      category: "service",
    },
    {
      value: "client_sourcing_request",
      label: "Sourcing Request",
      category: "product",
    },
    {
      value: "storage_charges",
      label: "Storage Charges",
      category: "service",
    },
    {
      value: "admin_note",
      label: "System Transaction",
      category: "wallet_transactions",
    },
    {
      value: "order_placed",
      label: "vF Cost",
      category: "other",
    },
    {
      value: "wire_addfunds",
      label: "Wire Add Funds",
      category: "wallet_transactions",
    },
  ];
  // India theads
  inHeadsArr = [
    {
      value: "cashback_addfunds",
      label: "Add Funds - Cashback",
      category: "wallet_transactions",
    },

    {
      value: "wingman_charges",
      label: "Wingman Charges",
      category: "wingman_charges",
    },
    {
      value: "order_confirmation_management_fee",
      label: "Order Management Fee",
      category: "service",
    },
    {
      value: "funds_added_admin",
      label: "Add Funds - Manual",
      category: "wallet_transactions",
    },
    {
      value: "razorpay_addfunds",
      label: "Add Funds - Razorpay",
      category: "wallet_transactions",
    },
    {
      value: "buyback_handling_fee",
      label: "BuyBack Handling Fee",
      category: "service",
    },
    {
      value: "order_placed_codf",
      label: "COD Fees",
      category: "service",
    },

    {
      value: "cod_remit_new",
      label: "COD Remittance",
      category: "remittance",
    },
    {
      value: "cod_remit_reverse",
      label: "COD Remittance Reverse",
      category: "remittance",
    },
    {
      value: "coupon_addfunds",
      label: "Coupon Funds",
      category: "wallet_transactions",
    },
    {
      value: "credit_note",
      label: "Credit Note",
      category: "wallet_transactions",
    },
    {
      value: "order_placed_fs",
      label: "Forward Shipping",
      category: "service",
    },
    {
      value: "order_placed_ff",
      label: "Fulfilment Fees",
      category: "service",
    },
    {
      value: "credit_note_ff",
      label: "Fulfilment Fee Credit",
      category: "service",
    },
    // new 5

    {
      value: "platform_fee_fixed",
      label: "Fixed Convenience Fee",
      category: "service",
    },
    {
      value: "credit_note_platform_fee_fixed",
      label: "Fixed Convenience Fee Reverse",
      category: "service",
    },
    {
      value: "platform_fee_percentage",
      label: "Percentage Convenience Fee",
      category: "service",
    },
    {
      value: "credit_note_platform_fee_percentage",
      label: "Percentage Convenience Fee Reverse",
      category: "service",
    },
    {
      value: "client_sourcing_request_reverse",
      label: "Client Sourcing Request Reverse",
      category: "product",
    },
    {
      value: "inward_fees_reverse",
      label: "Inward Fees Reverse",
      category: "service",
    },
    // {
    //   value: "platform_fee_percentage_reverse",
    //   label: "Reverse Convenience Fee",
    //   category: "service",
    // },

    // end
    {
      value: "funds_added",
      label: "Fund Addition",
      category: "wallet_transactions",
    },
    {
      value: "withdraw_funds",
      label: "Fund Withdrawal",
      category: "wallet_transactions",
    },
    {
      value: "inventory_rtv",
      label: "Inventory RTV",
      category: "service",
    },
    {
      value: "inward_fees",
      label: "Inward Fees",
      category: "service",
    },
    {
      value: "management_fee",
      label: "Tax Management Fee",
      category: "service",
    },
    {
      value: "management_fee_reverse",
      label: "Tax Management Fee Reverse",
      category: "service",
    },
    {
      value: "orderamount_gst_deductions_reverse",
      label: "Order Amount GST Return",
      category: "remittance",
    },
    {
      value: "order_cancellation_fee",
      label: "Order Cancellation Fee",
      category: "service",
    },
    {
      value: "order_refund",
      label: "Order Refund",
      category: "other",
    },
    {
      value: "payment_gatway_fee",
      label: "Payment Gateway Fee",
      category: "service",
    },
    {
      value: "prepaid_remittance",
      label: "Prepaid Remittance",
      category: "remittance",
    },
    {
      value: "prepaid_remittance_reverse",
      label: "Prepaid Remittance Reverse",
      category: "remittance",
    },
    {
      value: "order_placed_pp",
      label: "Product Price",
      category: "product",
    },
    {
      value: "credit_note_pp",
      label: "Product Price Credit",
      category: "product",
    },
    {
      value: "orderamount_gst_deductions",
      label: "Remittance GST Deductions",
      category: "remittance",
    },
    {
      value: "codf_reverse",
      label: "Reverse COD Fees",
      category: "service",
    },
    {
      value: "order_reverse_pickup",
      label: "Reverse Pick Up",
      category: "service",
    },
    {
      value: "rto_remit",
      label: "RTO Charges",
      category: "other",
    },
    {
      value: "rto_handling_fee",
      label: "RTO Handling Fee",
      category: "service",
    },
    {
      value: "rto_shipping",
      label: "RTO Shipping",
      category: "service",
    },
    {
      value: "credit_note_fs",
      label: "Shipping Charges credit",
      category: "service",
    },
    {
      value: "client_sourcing_request",
      label: "Sourcing Request",
      category: "product",
    },
    {
      value: "storage_charges",
      label: "Storage Charges",
      category: "service",
    },
    {
      value: "admin_note",
      label: "System Transaction",
      category: "wallet_transactions",
    },
    {
      value: "verified_order_cancellation",
      label: "Verified Order Cancellation Fee",
      category: "service",
    },
    {
      value: "order_placed",
      label: "vF Cost",
      category: "other",
    },
    {
      value: "wire_addfunds",
      label: "Wire Add Funds",
      category: "wallet_transactions",
    },
    {
      value: "customer_refund_request",
      label: "Refund Request",
      category: "service",
    },
  ];

  walletnavtoggle(statedata4) {
    this.setState({
      walletsummarynav: { ...statedata4 },
    });
  }

  storetoggle(statedata4) {
    this.setState({
      storid: statedata4,
    });
  }
  // start  graph code

  onDateChange(date) {
    this.setState(
      {
        transaction_graph: {
          ...this.state.transaction_graph,
          ...date,
          data: [{ name: "Debit" }, { name: "Credit" }],
        },
        loading: {
          ...this.state.loading,
          graph: true,
        },
      },
      () => this.fetchTransactionGraph()
    );
  }

  fetchTransactionGraph() {
    this.setState(
      {
        loading: {
          transactions: true,
        },
      },
      () => {
        if (this.cancelToken1) {
          this.cancelToken1.cancel();
        }
        this.cancelToken1 = axios.CancelToken.source();
        axios({
          url: process.env.REACT_APP_API_URL + "/wallet/get_transaction_count",
          method: "post",
          cancelToken: this.cancelToken1.token,
          data: {
            currency:
              this.props.match.params.storegeo === "global" ? "USD" : "INR",
            start: this.state.transaction_graph.startDate,
            end: this.state.transaction_graph.endDate,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                transaction_graph: {
                  ...this.state.transaction_graph,
                  data: res.data.transactions,
                },
                loading: { ...this.state.loading, graph: false },
              });
            }
          })
          .catch((e) => {
            if (!!e.request) {
              message.error("There was an error fetching todays transactions!");
            }
          });
      }
    );
  }
  // start  graph code

  /// for Transaction Logs

  fetchTransactions() {
    this.setState(
      {
        loading2: {
          transactions: true,
        },
      },
      () => {
        if (this.cancelToken1) {
          this.cancelToken1.cancel();
        }
        this.cancelToken1 = axios.CancelToken.source();
        axios({
          url: process.env.REACT_APP_API_URL + "/wallet/get_transactions",
          method: "post",
          cancelToken: this.cancelToken1.token,
          data: {
            currency:
              this.props.match.params.storegeo === "global" ? "USD" : "INR",
            storeid: this.state.storid,
            page: this.state.pagination.page,
            pageSize: this.state.pagination.per_page_count,
            filters: this.state.filters,
            sort: this.state.sort,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                transactions: res.data.transactions,
                loading2: { ...this.state.loading, transactions: false },
                kyc_submit: res.data.kyc_submit,
                user_kycapproved: res.data.user_kycapproved,
                pagination: {
                  ...this.state.pagination,
                  total: res.data.total,
                },
              });
            }
          })
          .catch((e) => {
            if (!!e.request) {
              message.error("There was an error fetching transactions!");
            }
          });
      }
    );
  }

  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        pagination: { ...this.state.pagination, per_page_count: pageSize },
      },
      () => this.fetchTransactions()
    );
  }

  goToPage = (page, sorter) => {
    const sort = {
      field: sorter.field,
      order: sorter.order,
    };
    this.setState(
      {
        pagination: { ...this.state.pagination, page },
        sort,
      },
      () => this.fetchTransactions()
    );
  };

  // function for conditionally set transaction heads
  transactionHeadsHandler(obj) {
    if (obj["transactionHeads"].includes("clear_all")) {
      this.setState(
        {
          filters: { ...this.state.filters, transactionHeads: [] },
          pagination: { ...this.state.pagination, page: 1 },
        },
        () => this.fetchTransactions()
      );
      return;
    }

    this.setState(
      {
        filters: { ...this.state.filters, ...obj },
        pagination: { ...this.state.pagination, page: 1 },
      },
      () => this.fetchTransactions()
    );
  }

  onChangeFilter(kvpairs) {
    this.setState(
      {
        filters: { ...this.state.filters, ...kvpairs },
        pagination: { ...this.state.pagination, page: 1 },
      },
      () => this.fetchTransactions()
    );
  }

  rangePickerChange(filters) {
    //

    this.setState(
      {
        isDateRangeValid: true,
        filters: { ...this.state.filters, ...filters },
        pagination: { ...this.state.pagination, page: 1 },
      },
      () => this.fetchTransactions()
    );
  }

  changeHandler(kvpairs) {
    this.setState({
      filters: { ...this.state.filters, ...kvpairs },
    });
  }

  transactionSummaryFilterHandler(kv) {
    this.setState({
      transactionSummaryFilters: { ...kv },
    });
  }

  // End Transaction Logs

  componentDidMount() {
    this.fetchTransactionGraph();
    // this.fetchWithdrawal();
    this.fetchTransactions();
    this.storetoggle();
  }

  removeHead(d) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          transactionHeads: this.state.filters.transactionHeads.filter(
            (t) => t !== d
          ),
        },
      },
      () => this.fetchTransactions()
    );

    this.child.current.removeModalTick(d);
  }

  downloadCSV() {
    axios({
      url: process.env.REACT_APP_API_URL + "/wallet/export_all_transactions",
      method: "post",
      data: {
        currency: this.props.match.params.storegeo === "global" ? "USD" : "INR",
        filters: this.state.filters,
        sort: this.state.sort,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMsg: e.message,
          loading: false,
        });
      });
  }

  labelHandler(label) {
    if (this.props.match.params.storegeo === "global") {
      let name = this.globalHeadsArr.find((l) => l.value === label);
      return !!name && !!name.label ? name.label : "test";
    } else {
      let name = this.inHeadsArr.find((l) => {
        return l.value === label;
      });
      return !!name && !!name.label ? name.label : "test";
    }
  }

  render() {
    const transactionHeads = this.state.filters.transactionHeads;

    return (
      <>
        <MyWalletSummaryNav
          {...this.props}
          transactionSummaryFilterHandler={(kv) =>
            this.transactionSummaryFilterHandler(kv)
          }
          walletnavtoggle={(e) => this.walletnavtoggle(e)}
          walletsummarynav={this.state.walletsummarynav}
          transactionHeadsFunc={(e) => this.transactionHeadsHandler(e)}
          filters={this.state.filters}
          sort={this.state.sort}
          transaction_graph={this.state.transaction_graph}
          onDateChange={(e) => this.onDateChange(e)}
          onChangeFilter={(e) => this.onChangeFilter(e)}
          changeHandler={(e) => this.changeHandler(e)}
          loading2={this.state.loading2}
          loading={this.state.loading}
          downloadCSV={() => this.downloadCSV()}
          calenderRef={this.calenderRef}
          transactionSummaryFilters={this.state.transactionSummaryFilters}
          ref={this.child}
          rangePickerChange={(e) => this.rangePickerChange(e)}
          // removeloading={() => this.removeloading()}
        />
        <div id="wallet_summary" className="wallet-tabs">
          {/* condition to render walletsummary or Transaction Logs */}
          {this.state.walletsummarynav.WalletSummarybtn ? (
            <WithdrawalSummary
              {...this.props}
              graph={this.state.loading.graph}
              fetchTransactionGraph={() => this.fetchTransactionGraph()}
              user_kycapproved={this.state.user_kycapproved}
              kyc_submit={this.state.kyc_submit}
              loading2={this.state.loading2.transactions}
            />
          ) : (
            <>
              <div className="chips-wraper">
                {!!transactionHeads
                  ? transactionHeads.map((d, i) => (
                      <div className="selectedValueChips" key={i}>
                        {this.labelHandler(d)}
                        <Icon onClick={() => this.removeHead(d)} type="close" />
                      </div>
                    ))
                  : null}
              </div>

              <Transactions
                {...this.props}
                fetchTransactions={() => this.fetchTransactions()}
                transactions={this.state.transactions}
                pagination={this.state.pagination}
                loading={this.state.loading2}
                storetoggle={(e) => this.storetoggle(e)}
                goToPage={(a, b) => this.goToPage(a, b)}
                onShowSizeChange={(a, b) => this.onShowSizeChange(a, b)}
              />
            </>
          )}
        </div>
      </>
    );
  }
}

export default MyWallet;
