import React, { Component } from "react";
import { Row, Col, Card } from "antd";
import logo from "../../assets/images/logos/logo_dark_transparent.svg";
import VerifyEmail from "./VerifyEmail";
import StartQuestionsScreen from "./StartQuestionsScreen";
import QuestionsScreen from "./QuestionsScreen";
import QuestionScreen2 from "./QuestionScreen2";

import { connect } from "react-redux";

import "./index.scss";
import axios from "axios";
import { Redirect } from "react-router-dom";

class Onboarding extends Component {
  state = {
    loading: false,
    activeStep: 2,
    stepStatus: {
      1: 0,
      2: 0,
      3: 0,
      // 4: 0
    },
    phonenumber: "",
    other: {},
    contact_verified: "",
    user_activated: "",
    user_contact_ext: "",
    user_contact: "",
    user_email: "",
    user_skype: "",
  };

  componentDidMount() {
    if (!this.props.auth.store || !this.props.auth.store.id)
      return <Redirect to={`/switch-store`} />;

    if (!this.props.children) return <Redirect to={`/switch-store`} />;

    axios({
      url: process.env.REACT_APP_API_URL + "/user/get_onboarding",
      method: "post",
      data: {
        storeid: this.props.auth.store.id,
      },
      withCredentials: true,
    })
      .then((res) => {
        this.setState(
          {
            stepStatus: { ...res.data, 4: undefined },
            loading: false,
            error: "",
          },
          () => {
            this.goToNextStep(false);
          }
        );
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: e.message,
        });
      });

    // profile oth
    axios({
      url: process.env.REACT_APP_API_URL + "/user/get_profile",
      method: "post",
      withCredentials: true,
    })
      .then((res) => {
        this.setState({
          other: { ...res.data },
          contact_verified: res.data.profile.contact_verified,
          user_activated: res.data.profile.user_activated,
          user_contact_ext: res.data.profile.user_contact_ext,
          user_contact: res.data.profile.user_contact,
          user_email: res.data.profile.user_email,
          user_skype: res.data.profile.user_skype,
          loading: false,
          error: "",
        });

        // if (res.data.profile.user_questionaire === 1) {
        //   // window.location.assign("./this.props.auth.store.id");
        //   window.location.assign(`./${this.props.auth.store.id}/onboarding`);
        // }
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: e.message,
        });
      });
  }

  markCurrentCompleted = (cb) => {
    this.setState(
      (state) => ({
        stepStatus: {
          ...state.stepStatus,
          [state.activeStep]: 1,
        },
      }),
      () => {
        if (cb) {
          cb();
        }
      }
    );
  };

  getphonenumber = (val) => {
    this.setState({
      phonenumber: val,
    });
  };

  goToNextStep = (markCurrentCompleted = true) => {
    const nextStepUpdate = () => {
      // const firstIncompleteStep = Object.keys(this.state.stepStatus).find(
      //   (step) => this.state.stepStatus[step] === 0
      // );

      // console.log("firstIncompleteStep", firstIncompleteStep);

      // if (firstIncompleteStep !== undefined) {
      //   this.setState((state) => ({
      //     activeStep: Number(firstIncompleteStep),
      //   }));
      // } else {
      //   // window.location = "/switch-store";
      // }

      this.setState((state) => ({
        activeStep: state.activeStep + 1,
      }));
    };

    if (markCurrentCompleted) {
      this.markCurrentCompleted(() => {
        nextStepUpdate();
      });
    } else {
      nextStepUpdate();
    }
    // this.getphonenumber();
  };

  render() {
    console.log(this.state);
    if (!this.props.auth.store || !this.props.auth.store.id)
      return <Redirect to={`/switch-store`} />;

    // if (!this.props.children) return <Redirect to={`/switch-store`} />;
    if (this.props.auth.user.show_onboarding === false) {
      return <Redirect to="switch-store" />;
    }

    return (
      <div className="onboarding-container">
        <div className="onboarding">
          <Row style={{ position: "absolute" }} type="flex">
            <img className="logo" src={logo} alt="Logo" />
          </Row>
          {this.state.loading ? (
            <Row>
              <Col xs={8} offset={8}>
                <Card loading />
              </Col>
            </Row>
          ) : (
            <>
              {window.location.search === "?=email_verified" ? (
                // <QuestionsScreen
                //   other={this.state.other}
                //   goToNextStep={this.goToNextStep}
                //   getprofile={this.props.profile}
                //   phonenumber={this.state.phonenumber}
                // />
                <QuestionScreen2
                  user_contact={this.state.user_contact}
                  user_email={this.state.user_email}
                  user_skype={this.state.user_skype}
                  storeid={this.props.auth.store.id}
                  user_contact_ext={this.state.user_contact_ext}
                />
              ) : (
                <>
                  {this.state.activeStep === 1 && (
                    // <StartQuestionsScreen goToNextStep={this.goToNextStep} />
                    <VerifyEmail
                      goToNextStep={this.goToNextStep}
                      getphonenumber={this.getphonenumber}
                      profile={this.state.other.profile}
                      contact_verified={this.state.contact_verified}
                      user_activated={this.state.user_activated}
                      user_contact_ext={this.state.user_contact_ext}
                      storeid={this.props.auth.store.id}
                    />
                  )}
                  {this.state.activeStep === 2 && (
                    <StartQuestionsScreen goToNextStep={this.goToNextStep} />
                  )}
                  {this.state.activeStep === 3 && (
                    <div>
                      {/* <QuestionsScreen
                        other={this.state.other}
                        goToNextStep={this.goToNextStep}
                        getprofile={this.props.profile}
                        phonenumber={this.state.phonenumber}
                      /> */}
                      <QuestionScreen2
                        user_contact_ext={this.state.user_contact_ext}
                        user_contact={this.state.user_contact}
                        user_email={this.state.user_email}
                        user_skype={this.state.user_skype}
                        storeid={this.props.auth.store.id}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(Onboarding);
