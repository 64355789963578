import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Icon,
  message,
  Card,
  Skeleton,
  Alert,
  Tooltip,
  Select,
  Pagination,
} from "antd";
import ImportProductModal from "../ImportList/ImportProductModal";
import axios from "axios";
import moment from "moment";
import { ReactComponent as bookmarkActive } from "../../../../assets/images/icons/bookmark-active.svg";
import { ReactComponent as bookmarkInactive } from "../../../../assets/images/icons/bookmark-inactive.svg";
import { ReactComponent as hotSellingIcon } from "../../../../assets/images/icons/hotselling-active.svg";
import { ReactComponent as hotSellingInactive } from "../../../../assets/images/icons/hotselling-inactive.svg";
import { ReactComponent as shippingIcon } from "../../../../assets/images/icons/express-active.svg";
import { ReactComponent as shippingInactive } from "../../../../assets/images/icons/express-inactive.svg";
import { ReactComponent as adCopyIcon } from "../../../../assets/images/icons/ad-copy-active.svg";
import { ReactComponent as adCopyInactive } from "../../../../assets/images/icons/adcopy-inactive.svg";
import { ReactComponent as buyBackIcon } from "../../../../assets/images/icons/buyback-active.svg";
import { ReactComponent as buyBackInactive } from "../../../../assets/images/icons/buyback-inactive.svg";
import { ReactComponent as videoIcon } from "../../../../assets/images/icons/video-active.svg";
import { ReactComponent as videoInactive } from "../../../../assets/images/icons/video-inactive.svg";
import { ReactComponent as rightArrow } from "../../../../assets/images/icons/long-arrow.svg";
import { ReactComponent as shirt } from "../../../../assets/images/icons/shirt.svg";
import { ReactComponent as house } from "../../../../assets/images/icons/house.svg";
import { ReactComponent as construction } from "../../../../assets/images/icons/construction.svg";
import { ReactComponent as natural } from "../../../../assets/images/icons/natural.svg";
import { ReactComponent as pet } from "../../../../assets/images/icons/healthcare-and-medical.svg";
import { ReactComponent as electronics } from "../../../../assets/images/icons/electronics.svg";
import { ReactComponent as gadget } from "../../../../assets/images/icons/gadget.svg";
import { ReactComponent as automotive } from "../../../../assets/images/icons/automotive.svg";
import { ReactComponent as sportsnfitness } from "../../../../assets/images/icons/sportsnfitness.svg";
import { ReactComponent as combs } from "../../../../assets/images/icons/combs.svg";
import { ReactComponent as general } from "../../../../assets/images/icons/general.svg";
import { ReactComponent as office } from "../../../../assets/images/icons/stationary.svg";
import { ReactComponent as games } from "../../../../assets/images/icons/games.svg";
import { ReactComponent as bathroom } from "../../../../assets/images/icons/bathroom.svg";
import { ReactComponent as lightandcable } from "../../../../assets/images/icons/lightandcable.svg";
import { ReactComponent as machinerEquipment } from "../../../../assets/images/icons/machiner&Equipment.svg";
import { ReactComponent as musicinstrument } from "../../../../assets/images/icons/musicinstrument.svg";
import { ReactComponent as bags } from "../../../../assets/images/icons/bags.svg";
import { ReactComponent as babyCare } from "../../../../assets/images/icons/babyCare.svg";
import { ReactComponent as safetyAndSecurity } from "../../../../assets/images/icons/safetyAndSecurity.svg";
import { ReactComponent as eyewear } from "../../../../assets/images/icons/eyewear.svg";
import { ReactComponent as phone } from "../../../../assets/images/icons/phone.svg";
import { ReactComponent as footwear } from "../../../../assets/images/icons/footwear.svg";
import { ReactComponent as furniture } from "../../../../assets/images/icons/furniture.svg";
import { ReactComponent as health } from "../../../../assets/images/icons/healthcare-and-medicals.svg";
import { ReactComponent as toolsandaccessories } from "../../../../assets/images/icons/toolsandaccessories.svg";
import { ReactComponent as others } from "../../../../assets/images/icons/commerce-and-shopping.svg";
import { ReactComponent as jewelery } from "../../../../assets/images/categories/jewelery.svg";
import { ReactComponent as toys } from "../../../../assets/images/categories/toys.svg";
import { ReactComponent as watch } from "../../../../assets/images/categories/watch.svg";
import ShowImageModal from "./ProductOverviewDrawer/ShowImageModal";
import "./index.scss";
import ProductOverviewDrawer from "./ProductOverviewDrawer";
import { formatNumber, getCurrencySymbol } from "../../../../Helpers/Pricing";
import { connect } from "react-redux";
import ProductNiche from "./ProductNiche";
import NicheCategories from "./NicheCategories";
import { Link } from "react-router-dom";
import qs from "qs";
import NotFoundCard from "../../Common/NotFoundCard";

const { Option } = Select;
export const nicheIcons = {
  Clothing: shirt,
  "Sports & Fitness": sportsnfitness,
  "Health & Wellness": health,
  Beauty: natural,
  "Electronics & Gadgets": gadget,
  "Automobile & Accessories": automotive,
  "Hair Care & Styling": combs,
  "Computer Devices & Peripherals": electronics,
  "Home Improvement": house,
  "Pet Care": pet,
  "Grocery & Gourmet Food": others,
  "Jewellery & Accessories": jewelery,
  "Office Stationery & Accessories": office,
  "Games & Accessories": games,
  "Bathroom & Fixtures": bathroom,
  "Home Decor": house,
  "Machinery & Equipments": machinerEquipment,
  "Building Material": construction,
  "Musical Instruments": musicinstrument,
  "Bags & Luggage": bags,
  Toys: toys,
  "Baby Care": babyCare,
  "Safety Equipments & Security Systems": safetyAndSecurity,
  "Tools & Accessories": toolsandaccessories,
  "Lights & Cables": lightandcable,
  General: general,
  Footwear: footwear,
  "Phone & Accessories": phone,
  Furniture: furniture,
  "Watch & Accessories": watch,
  "Eyewear & Accessories": eyewear,
  Other: others,
};

const defaultState = {
  importProductModalVisible: false,
  showAllCategories: false,
  selectedCategory: "",
  products: [],
  total: 0,
  loading: true,
  error: false,
  errorMsg: "",
  niches: [],
  categories: [],
  mapping: {},

  showCategory: false,
  greekSearchcategory: "",
  niche: [],
  defaultNiches: [],
  showMore: false,
  filtersCollapse: false,
  filters: {
    search: "",
    selectedCategories: {},
    selectedNiches: {},
    faster_shipping_methods: [],
    top_categories: [],
    top_products: [],
    available_media: [],
  },
  showImageModal: {
    visible: false,
    index: 0,
    files: [],
    type: "",
  },
  paging: {
    page: 1,
    per_page_count: 20,
  },
  productViewDrawer: {
    visible: false,
    product: undefined,
  },
};
class SearchProducts extends Component {
  state = {
    ...defaultState,
  };
  toggleCategory() {
    this.setState({
      showCategory: !this.state.showCategory,
    });
  }

  scrollToTop = () => {
    const c = document.querySelector("header");
    if (c) {
      c.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  };

  showCategories(e, id) {
    e.stopPropagation();
    this.toggleCategory();
    this.setState(
      {
        niche: this.state.niches.filter((n) => n.id === id),
        greekSearchcategory: id,
      },
      () => this.scrollToTop()
    );
  }

  selectNiche(key, value) {
    if (this.state.filters.selectedNiches[key]) {
      let newNiche = this.state.filters.selectedNiches;
      let newCategory = this.state.filters.selectedCategories;

      delete newNiche[key];
      delete newCategory[key];
      this.setState({
        filters: {
          ...this.state.filters,
          selectedNiches: newNiche,
          selectedCategories: newCategory,
        },
      });
    } else {
      this.setState({
        filters: {
          ...this.state.filters,
          selectedNiches: {
            ...this.state.filters.selectedNiches,
            [key]: value,
          },
          selectedCategories: {
            ...this.state.filters.selectedCategories,
            [key]: [],
          },
        },
      });
    }
  }
  selectCategory(key, value) {
    if (value.length === this.state.mapping[key].length) {
      this.setState({
        filters: {
          ...this.state.filters,
          selectedCategories: {
            [key]: [],
          },
          selectedNiches: {
            ...this.state.filters.selectedNiches,
            [key]: 1,
          },
        },
      });
    } else {
      this.setState({
        filters: {
          ...this.state.filters,
          selectedCategories: {
            ...this.state.filters.selectedCategories,
            [key]: value,
          },
          selectedNiches: {
            ...this.state.filters.selectedNiches,
            [key]: 0,
          },
        },
      });
    }
  }

  goToPage(page) {
    this.setState(
      {
        paging: { ...this.state.paging, page },
      },
      () => {
        this.loadData();
      }
    );
  }

  productViewDrawerToggle(p = undefined) {
    if (this.props.openDetails && !p) {
      this.props.history.push(
        `/${this.props.match.params.storeid}/products/search-products`
      );
    }
    this.setState({
      productViewDrawer: {
        ...this.state.productViewDrawer,
        visible: !this.state.productViewDrawer.visible,
        product: p,
      },
    });
  }

  loadData() {
    this.setState({ loading: true }, () => {
      axios({
        url: process.env.REACT_APP_API_URL + "/products/search",
        method: "post",
        data: {
          storeid: this.props.match.params.storeid,
          category: this.state.selectedCategory,
          page: this.state.paging.page,
          per_page_count: this.state.paging.per_page_count,
          filters: this.state.filters,
        },
        withCredentials: true,
      })
        .then((res) => {
          this.setState({ loading: false });
          return res;
        })
        .then((res) => {
          if (res.data.success === 1) {
            const upd = {
              products: res.data.products,
              total: res.data.total,
              loading: false,
              niches: res.data.niches,
              showMore: false,
              defaultNiches: res.data.niches.slice(0, 7),
              categories: res.data.categories,
              mapping: res.data.mapping,
            };
            if (this.props.openDetails) {
              if (
                res.data.products[0] &&
                res.data.products[0].vfprodid === this.props.match.params.prodid
              ) {
                upd.productViewDrawer = {
                  visible: true,
                  product: res.data.products[0],
                };
              }
            }
            this.setState(upd);
          } else {
            this.setState({
              error: true,
              errorMsg: res.data.msg,
              loading: false,
            });
          }
        })
        .catch((e) => {
          this.setState({
            error: true,
            errorMsg: e.message,
            loading: false,
          });
        });
    });
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search.replace(/\?/g, ""));
    if (params.search) {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: params.search,
          },
        },
        () => this.loadData()
      );
    } else {
      this.loadData();
    }
  }
  componentDidUpdate(pp) {
    if (pp.location.search !== this.props.location.search) {
      const params = qs.parse(this.props.location.search.replace(/\?/g, ""));
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: params.search,
          },
          paging: { page: 1, per_page_count: 20 },
        },
        () => this.loadData()
      );
    }
  }
  toggleShowImageModal(i = 0, files = [], type = "") {
    this.setState({
      showImageModal: {
        visible: !this.state.showImageModal.visible,
        index: i,
        files: files,
        type: type,
      },
    });
  }

  productWatch(id) {
    axios({
      url: process.env.REACT_APP_API_URL + "/products/toggle_watchlist_product",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        vfprodid: id,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState((state) => ({
            products: state.products.map((p) => {
              if (p.vfprodid === id) {
                return { ...p, product_watch: p.product_watch === 1 ? 0 : 1 };
              }
              return p;
            }),
          }));
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  selectAll(key, value) {
    let newCategory = this.state.filters.selectedCategories;
    delete newCategory[key];
    if (value === false) {
      /*deselected All*/
      this.setState({
        filters: {
          ...this.state.filters,
          selectedNiches: {
            ...this.state.filters.selectedNiches,
            [key]: value === false ? 0 : 1,
          },
          selectedCategories: newCategory,
        },
      });
    } else {
      /*selected All*/
      this.setState({
        filters: {
          ...this.state.filters,
          selectedNiches: {
            ...this.state.filters.selectedNiches,
            [key]: value === false ? 0 : 1,
          },
          selectedCategories: {
            ...this.state.filters.selectedCategories,
            [key]: [],
          },
        },
      });
    }
  }

  toggleImportProductModal = () => {
    this.setState({
      importProductModalVisible: !this.state.importProductModalVisible,
    });
  };

  importProducts = (productIds) => {
    axios({
      url:
        process.env.REACT_APP_API_URL + "/products/import_products_aliexpress",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        productids: productIds,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          message.success(
            "Product import in progress. Please visit import list section to view imported products"
          );
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  addStars = (number) => {
    if (number === 1) {
      return <Icon type="star" theme="filled" />;
    }

    return (
      <span>
        <Icon type="star" theme="filled" /> {this.addStars(number - 1)}
      </span>
    );
  };

  switchCategory(id) {
    this.setState(
      {
        products: [],
        selectedCategory: id,
        loading: true,
      },
      () => this.loadData()
    );
  }

  onChange(key, value) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [key]: value,
        },
        paging: { page: 1, per_page_count: 20 },
      },
      () => this.loadData()
    );
  }

  onChangeRange(key, value) {
    this.setState({
      filters: {
        ...this.state.filters,
        price_range: {
          ...this.state.filters.price_range,
          [key]: value,
        },
      },
    });
  }

  updateSearch() {
    this.setState(
      {
        showMore: false,
        defaultNiches: this.state.niches.slice(0, 7),
        filters: {
          ...this.state.filters,
          search: "",
        },
      },
      () => this.loadData()
    );
  }

  resetSearch() {
    this.setState(
      {
        showMore: false,
        defaultNiches: this.state.niches.slice(0, 7),
        filters: {
          ...this.state.filters,
          search: "",
          top_categories: [],
          selectedCategories: {},
          selectedNiches: {},
        },
      },
      () => this.loadData()
    );
  }
  onClickShowMore() {
    this.setState({
      showMore: true,
      defaultNiches: this.state.niches,
    });
  }

  getPriceRange(product) {
    let sortedPrices = [];

    if (this.props.store.store_geo === "in") {
      Object.keys(product.moq_sourcing).forEach((range) => {
        Object.values(product.moq_sourcing[range]).forEach((v) =>
          sortedPrices.push(v.avg)
        );
      });
    }

    if (!product.provisional) {
      sortedPrices.push(product.retail_sourcing.min);
    }
    sortedPrices = sortedPrices.sort((a, b) => a - b);

    const minPrice = sortedPrices[0];
    const maxPrice = sortedPrices[sortedPrices.length - 1];
    const currency = this.props.store.store_geo === "global" ? "USD" : "INR";
    return minPrice === maxPrice
      ? `${getCurrencySymbol(currency)} ${formatNumber(minPrice)}`
      : `${getCurrencySymbol(currency)} ${formatNumber(
          minPrice
        )} - ${getCurrencySymbol(currency)} ${formatNumber(maxPrice)}`;
  }

  selectTopSearch(val) {
    const categories = {};
    const niches = {};

    val.forEach((v) => {
      if (v === "covid_19") {
        categories.CUSTOMCAT1 = [];
        niches.CUSTOMCAT1 = 1;
      } else if (v === "home_improvement") {
        categories.home_improvement = [];
        niches.home_improvement = 1;
      } else if (v === "personal_care") {
        categories["health_&_wellness"] = [];
        niches["health_&_wellness"] = 1;
        categories["beauty"] = [];
        niches["beauty"] = 1;
      }
    });

    this.setState(
      {
        filters: {
          ...this.state.filters,
          top_categories: val,
          selectedCategories: categories,
          selectedNiches: niches,
        },
        paging: { page: 1, per_page_count: 20 },
      },
      () => this.loadData()
    );
  }

  render() {
    let newSelectedNiche = [];
    let nicheKeys = Object.keys(this.state.filters.selectedNiches);

    nicheKeys.map((k, i) =>
      this.state.niches.forEach((e, i) => {
        if (e.id === k) {
          return (newSelectedNiche = [
            ...newSelectedNiche,
            {
              key: e.name,
              value: !this.state.mapping[k]
                ? ["No category"]
                : this.state.filters.selectedNiches[k] === 1
                ? ["All"]
                : !this.state.filters.selectedCategories[k]
                ? ["No selected category"]
                : this.state.filters.selectedCategories[k] &&
                  this.state.filters.selectedCategories[k].length > 0 &&
                  this.state.filters.selectedCategories[k].map((m) =>
                    this.state.categories
                      .filter((c) => c.id === m)
                      .map((e) => e.name)
                  ),
            },
          ]);
        }
      })
    );
    // const store = this.props.store;

    // product.images &&
    //   product.images.length > 0 &&
    //   product.images.map((e, i) => console.log(e));

    const PageWrapper = (children) => {
      return (
        <div className="search-products-container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1 className="content-page-title" style={{ marginBottom: "0px" }}>
              Product Research
            </h1>
            {/* <Search
              allowClear={true}
              value={this.state.filters.search}
              onChange={(e) =>
                this.setState({
                  filters: { ...this.state.filters, search: e.target.value },
                })
              }
              placeholder="Search by Product ID, Tags, Name"
              defaultValue={this.state.filters.search}
              onSearch={(val) => this.onChange("search", val)}
              style={{ width: 308, borderRadius: "0px" }}
            /> */}
          </div>
          <Row>
            {!this.state.showCategory && (
              <ProductNiche
                niches={this.state.niches}
                defaultNiches={this.state.defaultNiches}
                showMore={this.state.showMore}
                onClickShowMore={() => this.onClickShowMore()}
                selectNiche={(key, value) => this.selectNiche(key, value)}
                selectedNiches={Object.keys(this.state.filters.selectedNiches)}
                showCategories={(e, id) => this.showCategories(e, id)}
                nicheIcons={nicheIcons}
                loading={this.state.loading}
              />
            )}
            {this.state.showCategory && (
              <NicheCategories
                allCategories={this.state.categories}
                mapping={this.state.mapping}
                selectedNiches={this.state.filters.selectedNiches}
                selectedCategories={this.state.filters.selectedCategories}
                niche={this.state.niche}
                nicheIcons={nicheIcons}
                selectCategory={(key, value) => this.selectCategory(key, value)}
                toggleCategory={() => this.toggleCategory()}
                greekSearchcategory={this.state.greekSearchcategory}
                selectAll={(key, value) => this.selectAll(key, value)}
              />
            )}
          </Row>
          {!this.state.loading && (
            <Row>
              <Col md={6}>
                {this.state.showMore && (
                  <p style={{ margin: "10px 0 0 5px" }}>
                    <a
                      href="#!"
                      onClick={() =>
                        this.setState({
                          showMore: false,
                          defaultNiches: this.state.niches.slice(0, 7),
                        })
                      }
                    >
                      Collapse
                    </a>
                  </p>
                )}
              </Col>
              <Col md={6} />
              <Col md={6}>
                <Button
                  onClick={() => this.resetSearch()}
                  style={{ width: "100%", borderRadius: "0px" }}
                >
                  Reset Product Search
                </Button>
              </Col>
              <Col md={6}>
                <Button
                  type="primary"
                  onClick={() => this.updateSearch()}
                  style={{ width: "100%", borderRadius: "0px" }}
                >
                  Update Product Search
                </Button>
              </Col>
              <div className="search-button" />
            </Row>
          )}

          <Row style={{ marginTop: "24px", display: "flex", flexWrap: "wrap" }}>
            {newSelectedNiche &&
              newSelectedNiche.length > 0 &&
              newSelectedNiche.map((e, i) => (
                <p key={i}>
                  <span style={{ color: "#0068ff", marginLeft: "5px" }}>
                    {" "}
                    {e.key}
                  </span>{" "}
                  {e.value && e.value.length > 1 ? (
                    <Tooltip title={e.value && e.value.join(", ")}>
                      {" "}
                      {e.value.length} Selected
                    </Tooltip>
                  ) : (
                    e.value && e.value.join(", ")
                  )}{" "}
                  <span style={{ color: "#0068ff" }}> | </span>
                </p>
              ))}
          </Row>

          <Row>
            <Col xs={24} md={16}>
              <ImportProductModal
                visible={this.state.importProductModalVisible}
                onCancel={() => this.toggleImportProductModal()}
                onConfirm={(productIds) => this.importProducts(productIds)}
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: "24px" }}>
            <div className="filter-container">
              <p>Filters: </p>
            </div>
            <Col xs={24} md={6}>
              <Select
                className="filter first"
                showSearch={false}
                placeholder="Top Searched Categories"
                style={{ width: "100%" }}
                onChange={(val) => this.selectTopSearch(val)}
                mode="multiple"
                value={this.state.filters.top_categories}
              >
                <Option value="covid_19">Covid 19</Option>
                <Option value="home_improvement">Home Improvement</Option>
                <Option value="personal_care">Personal Care</Option>
              </Select>
            </Col>
            <Col xs={24} md={6}>
              <Select
                className="filter"
                showSearch={false}
                placeholder="Top Products"
                style={{ width: "100%" }}
                onChange={(val) => this.onChange("top_products", val)}
                mode="multiple"
                value={this.state.filters.top_products}
              >
                <Option value="featured">Featured</Option>
                <Option value="hot_selling">Hot Selling</Option>
                <Option value="available_in_india">Available In India</Option>
                <Option value="buyback">Buyback</Option>
                <Option value="trending">Trending</Option>
              </Select>
            </Col>
            <Col xs={24} md={6}>
              <Select
                className="filter"
                showSearch={false}
                placeholder="Available Media"
                style={{ width: "100%" }}
                onChange={(val) => this.onChange("available_media", val)}
                mode="multiple"
                value={this.state.filters.available_media}
              >
                <Option value="product_video">Product Video</Option>
                <Option value="ad_copy">Ad Data</Option>
                {/* <Option value="sample_ads">Sample Ads</Option> */}
              </Select>
            </Col>
            <Col xs={24} md={6}>
              <Select
                className="filter last"
                showSearch={false}
                placeholder="Faster Shipping Methods"
                style={{ width: "100%" }}
                onChange={(val) =>
                  this.onChange("faster_shipping_methods", val)
                }
                mode="multiple"
                value={this.state.filters.faster_shipping_methods}
              >
                <Option value="express_shipping">Express Shipping</Option>
                <Option value="expedite_shipping">Expedite Shipping</Option>
              </Select>
            </Col>
          </Row>

          <Row>
            <div className="product-and-sort">
              <p>Products: </p>
            </div>
          </Row>
          <div className="list-category-container">{children}</div>
          <ProductOverviewDrawer
            niches={this.state.niches}
            storeid={this.props.match.params.storeid}
            visible={this.state.productViewDrawer.visible}
            importProduct={(id) => this.importProducts([id])}
            productViewDrawerToggle={() => this.productViewDrawerToggle()}
            getPriceRange={(p) => this.getPriceRange(p)}
            productData={this.state.productViewDrawer.product}
          />
        </div>
      );
    };

    if (this.state.loading) {
      return PageWrapper(
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={this.state.errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    if (this.state.products.length === 0) {
      return PageWrapper(
        <NotFoundCard
          mainHeading={"Oops! No Data Found."}
          secondHeading={<p>Looks like there are no products in this page.</p>}
        />
      );
    }

    return PageWrapper(
      <>
        <Row gutter={30}>
          {this.state.products.map((product, i) => (
            <Col
              xs={24}
              sm={12}
              md={12}
              key={i}
              className="list-category-column"
            >
              <Card className="list-category-card">
                <div
                  style={{ display: "flex", padding: "10px 24px 20px 10px" }}
                >
                  <div style={{ width: "144px", marginRight: "24px" }}>
                    <img
                      className="image-container"
                      src={
                        product.images &&
                        product.images.length &&
                        product.images[0].url
                      }
                      alt="not found"
                    />
                    {product.product_watch ? (
                      <Icon
                        component={bookmarkActive}
                        className="watch-product"
                        onClick={() => this.productWatch(product.vfprodid)}
                      />
                    ) : (
                      <Icon
                        component={bookmarkInactive}
                        className="watch-product"
                        onClick={() => this.productWatch(product.vfprodid)}
                      />
                    )}

                    <div className="slider-image">
                      {product.images &&
                        product.images.length > 0 &&
                        product.images.map((e, i) => (
                          <img
                            src={e.url}
                            alt="not found"
                            key={i}
                            onClick={() =>
                              this.toggleShowImageModal(i, product.images)
                            }
                          />
                        ))}
                    </div>
                  </div>

                  <div className="list-category-card-content">
                    <h3 className="product-name">
                      <Tooltip title={product.name} placement="topLeft">
                        <span
                          style={{
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {product.name}
                        </span>
                      </Tooltip>
                    </h3>
                    <p className="product-field">
                      Product ID:
                      <b style={{ color: "black" }}> {product.vfprodid}</b>
                    </p>
                    <p className="product-field">India Landing Cost:</p>
                    <p className="landing-cost">
                      {this.getPriceRange(product)}
                    </p>
                    <p
                      className="product-field"
                      style={{ textAlign: "center", fontSize: "8px" }}
                    >
                      <i>
                        Cost Last Updated On:{" "}
                        {moment(product.costUpdated).format("DD MMM, YYYY")}
                      </i>
                    </p>
                    <div className="other-attributes">
                      <Tooltip title="Hot Selling">
                        {product.hotselling === 1 ? (
                          <Icon component={hotSellingIcon} />
                        ) : (
                          <Icon component={hotSellingInactive} />
                        )}
                      </Tooltip>
                      <Tooltip title="Buy Back">
                        {" "}
                        {product.buyback === 1 &&
                        product.buyback_percentage > 0 ? (
                          <Icon component={buyBackIcon} />
                        ) : (
                          <Icon component={buyBackInactive} />
                        )}
                      </Tooltip>
                      <Tooltip title="Video Available">
                        {" "}
                        {product.videos && product.videos.length > 0 ? (
                          <Icon component={videoIcon} />
                        ) : (
                          <Icon component={videoInactive} />
                        )}
                      </Tooltip>
                      <Tooltip title="Ad Copy">
                        {" "}
                        {product.ad_copies && product.ad_copies.length > 0 ? (
                          <Icon component={adCopyIcon} />
                        ) : (
                          <Icon component={adCopyInactive} />
                        )}
                      </Tooltip>
                      <Tooltip title="Express Shipping">
                        {" "}
                        {product.inventory.india === 1 ? (
                          <Icon component={shippingIcon} />
                        ) : (
                          <Icon component={shippingInactive} />
                        )}
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className="viewAll">
                  <Link
                    to={`/${this.props.match.params.storegeo}/${
                      this.props.match.params.storeid
                    }/products/${product.vfprodid}`}
                    // onClick={(e) => {
                    //   if (!e.metaKey && !e.ctrlKey) {
                    //     e.preventDefault();
                    //     this.productViewDrawerToggle(product);
                    //   }
                    // }}
                  >
                    View More <Icon component={rightArrow} />
                  </Link>
                  <Button
                    className="footer"
                    onClick={() => this.importProducts([product.vfprodid])}
                  >
                    Add To Import List
                  </Button>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
        <Row>
          <Pagination
            locale={{ jump_to: "Go to page" }}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            showQuickJumper
            current={this.state.paging.page}
            onChange={(page) => this.goToPage(page)}
            total={this.state.total}
            defaultPageSize={this.state.paging.per_page_count}
          />
        </Row>
        <ShowImageModal
          visible={this.state.showImageModal.visible}
          toggleShowImageModal={() => this.toggleShowImageModal()}
          files={this.state.showImageModal.files}
          defaultIndex={this.state.showImageModal.index}
          type={this.state.showImageModal.type}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};
export default connect(mapStateToProps)(SearchProducts);
