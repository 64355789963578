import React, { Component } from "react";
import { Row, Skeleton, Alert, Pagination, message, Card } from "antd";
import axios from "axios";
import MyProduct from "./MyProduct";
import LinkProductModal from "./LinkProductModal";
import AliExpressModal from "./LinkProductModal/AliExpressModal";
import ImportListModal from "./LinkProductModal/ImportListModal";
import BundleModal from "./LinkProductModal/BundleModal";
import OverrideProductModal from "../ImportList/OverrirdeProductModal";
import EstimatePriceModal from "./EstimatePriceModal";

import { formatNumber } from "../../../../Helpers/Pricing";
import Myproductnav from "./MyproductNav";
import NotFoundCard from "../../Common/NotFoundCard";
import "./index.scss";

import qs from "qs";

class MyProducts extends Component {
  state = {
    urlSearched: false,
    loading: true,
    error: false,
    errorMsg: "",
    products: [],
    modalLoading: false,
    niches: [],
    total: 0,
    currencies: null,
    store_settings: null,
    paging: {
      page: 1,
      per_page_count: 10,
    },
    filters: {
      updates: "",
      linkStatus: this.props.match.params.linkStatus
        ? this.props.match.params.linkStatus
        : "",
      search: "",
    },
    linkProductModal: {
      visible: false,
      id: null,
    },
    estPriceModal: {
      visible: false,
      product: null,
    },
    overrideProductModalVisible: false,
    overrideData: null,
    importListModalVisible: false,
    aliExpressModalVisible: false,
    bundleModalVisible: false,

    linkImportListModal: {
      importListModalVisible: false,
      id: null,
      linkStoreProduct: true,
    },

    bundleProductModal: {
      bundleModalVisible: false,
      id: null,
      storeProduct: true,
    },
  };

  componentDidUpdate(pp) {
    if (
      pp.match.params.linkStatus !== this.props.match.params.linkStatus ||
      pp.location.search !== this.props.location.search
    ) {
      const params = qs.parse(this.props.location.search.replace(/\?/g, ""));
      this.setState(
        {
          filters: {
            updates: "",
            linkStatus: this.props.match.params.linkStatus
              ? this.props.match.params.linkStatus
              : "",
            search: params.search,
          },
          products: [],
          loading: true,
        },
        () => this.loadData()
      );
    }
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search.replace(/\?/g, ""));
    if (params.search) {
      this.setState(
        {
          urlSearched: true,
          filters: {
            ...this.state.filters,
            search: params.search,
          },
        },
        () => this.loadData()
      );
    } else {
      this.loadData();
    }
  }

  loadData() {
    this.setState(
      {
        loading: true,
        products: [],
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/products/my_products",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            page: this.state.paging.page,
            per_page_count: this.state.paging.per_page_count,
            filters: this.state.filters,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                products: res.data.products,

                requestCount:
                  !!res.data.products.length &&
                  res.data.products[0].request_latest_price
                    ? res.data.products[0].request_latest_price.count
                    : null,
                niches: res.data.niches,
                store_settings: res.data.store_settings,
                total: res.data.total,
                currencies: res.data.currencies,
                loading: false,
              });
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
                loading: false,
              });
            }
          })
          .catch((e) => {
            this.setState({
              error: true,
              errorMsg: e.message,
              loading: false,
            });
          });
      }
    );
  }

  goToPage(page) {
    this.setState(
      {
        paging: { ...this.state.paging, page },
      },
      () => {
        this.loadData();
      }
    );
  }

  // changeFilter(key, value) {
  //   alert()
  //   this.setState(
  //     {
  //       filters: { ...this.state.filters, [key]: value },
  //       paging: { ...this.state.paging, page: 1 },
  //     },
  //     () => this.loadData()
  //   );
  // }
  searchhandler() {
    if (window.location.href.includes("import-list")) {
      this.props.history.push(
        `/${this.props.match.params.storegeo}/${
          this.props.match.params.storeid
        }/products/import-list?search=${this.state.filters.search}`
      );
    } else {
      this.props.history.push(
        `/${this.props.match.params.storegeo}/${
          this.props.match.params.storeid
        }/products/store-products?search=${this.state.filters.search}`
      );
    }
  }

  changeFilter(kvpairs) {
    this.setState(
      {
        filters: { ...this.state.filters, ...kvpairs },
        paging: { ...this.state.paging, page: 1 },
      },
      () => this.searchhandler(),
      () => this.loadData()
    );
  }

  changeFilter2(kvpairs) {
    this.setState(
      {
        filters: { ...this.state.filters, ...kvpairs },
        paging: { ...this.state.paging, page: 1 },
      },

      () => this.loadData()
    );
  }

  deleteProducts(ids, deleteFromShopify) {
    axios({
      url: process.env.REACT_APP_API_URL + "/products/delete_products",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        deleteFromShopify: deleteFromShopify,
        productids: ids,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            products: this.state.products.filter(
              (p) => ids.indexOf(p._id["$oid"]) === -1,
              []
            ),
          });
          message.success("Product Deleted!");
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  }

  // togglebundelmodal() {
  //   this.setState({
  //     bundleModalVisible: !this.state.bundleModalVisible,
  //   });
  // }

  toggleLinkImportListmodal(id = null) {
    this.setState({
      linkImportListModal: {
        ...this.state.linkImportListModal,
        id,
        importListModalVisible: !this.state.linkImportListModal
          .importListModalVisible,
      },
    });
  }

  toggleBundelModal(id = null) {
    this.setState({
      bundleProductModal: {
        ...this.state.bundleProductModal,
        id,
        bundleModalVisible: !this.state.bundleProductModal.bundleModalVisible,
      },
    });
  }

  toggleModal = (modalStateField, anotherField) => {
    this.setState(
      {
        ...this.state,
        [modalStateField]: !this.state[modalStateField],
      },
      () => {
        if (anotherField) {
          this.setState({
            ...this.state,
            [anotherField]: !this.state[anotherField],
          });
        }
      }
    );
  };

  toggleOverrideModal(productData) {
    this.setState({
      overrideProductModalVisible: !this.state.overrideProductModalVisible,
      overrideData: productData ? productData : null,
    });
  }

  onProductOverrideSuccessfull(id) {
    let prods = this.state.products.filter((p) => {
      return p._id["$oid"] !== id;
    });
    this.setState({
      products: prods,
      overrideProductModalVisible: false,
    });
  }

  toggleLinkProductModal(id = null) {
    this.setState({
      linkProductModal: {
        ...this.state.linkProductModal,
        id,
        visible: !this.state.linkProductModal.visible,
      },
    });
  }

  splitUsingImportList(aliid, orderway) {
    if (aliid === null) {
      message.error("Select a product");
    } else {
      this.setState({ modalLoading: true });

      axios({
        url:
          process.env.REACT_APP_API_URL + "/products/link_product_import_list",
        method: "post",
        data: {
          storeid: this.props.match.params.storeid,
          product_mongoid: this.state.linkProductModal.id,
          product_aliid: aliid,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.setState({ modalLoading: false });

            this.props.history.push(
              `/${this.props.match.params.storeid}/variants/${aliid}/${
                this.state.linkImportListModal.id
              }`
            );
          } else {
            this.setState({ modalLoading: false });
            message.error(res.data.msg);
          }
        })
        .catch((e) => {
          this.setState({ modalLoading: false });
          message.error(e.message);
        });
    }
  }

  toggleEstPriceModal = (product = null) => {
    this.setState((state) => ({
      estPriceModal: {
        ...state.estPriceModal,
        visible: !state.estPriceModal.visible,
        product,
      },
    }));
  };

  importProducts = (productIds) => {
    axios({
      url:
        process.env.REACT_APP_API_URL + "/products/import_products_aliexpress",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        productids: productIds,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          message.success(
            "Product import in progress. Please visit import list section to view imported products"
          );
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  };
  buyback(ids, storeid, checked) {
    this.setState(
      {
        escalateLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/products/enable_buyback",
          method: "POST",
          data: {
            //storeid: this.props.storeid,
            // productid: this.state.products.vfprod.vfprodid,
            // productid:
            //   this.state.products && this.state.products.vfprod
            //     ? this.state.product.vfprod
            //         .map((ids) => {
            //           console.log(ids.vfprod.vfprodid === ids, "ksfhksdjhfks");
            //           return ids.vfprod.vfprodid === ids
            //             ? this.state.products.vfprod.vfprodid
            //             : -1;
            //         })
            //         .filter((i) => i !== -1)[0]
            //     : [],
            // key: this.state.products.buyback,
            productid: ids,
            storeid: storeid,
            buyback: checked ? 1 : 0,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                escalateLoading: false,
                escalateNowButton: true,
              });
              message.success("Buyback Updated!");
            } else {
              this.setState({
                escalateLoading: false,
              });
              message.warning(res.data.msg);
            }
          })
          .catch((err) => {
            this.setState({
              escalateLoading: false,
            });
            message.warning(err.message);
          });
      }
    );
  }
  getPriceRange(product) {
    let sortedPrices = [];

    Object.keys(product.sourcing_pricing).forEach((range) => {
      Object.values(product.sourcing_pricing[range]).forEach((v) =>
        sortedPrices.push(v.avg)
      );
    });

    if (!product.provisional) {
      sortedPrices.push(product.cost_data.vfmrp);
    }
    sortedPrices = sortedPrices.sort((a, b) => a - b);

    const minPrice = sortedPrices[0];
    const maxPrice = sortedPrices[sortedPrices.length - 1];

    return minPrice === maxPrice
      ? `₹ ${formatNumber(minPrice)}`
      : `₹ ${formatNumber(minPrice)} - ₹ ${formatNumber(maxPrice)}`;
  }

  changeHandler(kvpairs) {
    this.setState({
      filters: { ...this.state.filters, ...kvpairs },
    });
  }

  lessRequestCount() {
    if (this.state.requestCount > 0) {
      this.setState({ requestCount: this.state.requestCount - 1 });
    }
  }

  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        paging: { ...this.state.paging, per_page_count: pageSize },
      },
      () => this.loadData()
    );
  }

  render() {
    const { aliExpressModalVisible } = this.state.linkProductModal;
    const {
      importListModalVisible,
      linkStoreProduct,
    } = this.state.linkImportListModal;
    const { bundleModalVisible, storeProduct } = this.state.bundleProductModal;

    const PageWrapper = (children) => {
      return (
        <div className="my-products">
          <Myproductnav
            loading={this.state.loading}
            count={this.props.count}
            changeHandler={(e) => this.changeHandler(e)}
            changeFilter={(e) => this.changeFilter(e)}
            data={this.state.filters}
            linkStatus={this.props.match.params.linkStatus}
            changeFilter2={(e) => this.changeFilter2(e)}
            total={this.state.total}
            products={this.state.products}
            urlSearched={this.state.urlSearched}
          />

          {children}
          <Row>
            <Pagination
              locale={{ jump_to: "Go to page" }}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              showQuickJumper
              showSizeChanger
              pageSizeOptions={["10", "25", "50"]}
              onShowSizeChange={(p, d) => this.onShowSizeChange(p, d)}
              current={this.state.paging.page}
              onChange={(page) => this.goToPage(page)}
              total={this.state.total}
              defaultPageSize={this.state.paging.per_page_count}
            />
          </Row>
          <OverrideProductModal
            visible={this.state.overrideProductModalVisible}
            overrideData={this.state.overrideData}
            storeid={this.props.match.params.storeid}
            onProductOverrideSuccessfull={(id) =>
              this.onProductOverrideSuccessfull(id)
            }
            sourcePage="import-list"
            onCancel={() => this.toggleOverrideModal()}
            onConfirm={() => this.toggleOverrideModal()}
          />
          <LinkProductModal
            visible={this.state.linkProductModal.visible}
            onCancel={() => this.toggleLinkProductModal()}
            onConfirm={(importer) => {
              this.toggleModal(
                `${importer}ModalVisible`,
                "linkProductModalVisible"
              );
            }}
          />
          <AliExpressModal
            {...this.props}
            visible={aliExpressModalVisible}
            linkProductModalId={this.state.linkProductModal.id}
            onCancel={() => this.toggleModal("aliExpressModalVisible")}
          />
          <ImportListModal
            {...this.props}
            visible={importListModalVisible}
            loading={this.state.modalLoading}
            product={this.state.products.find(
              (p) => p._id["$oid"] === this.state.linkImportListModal.id
            )}
            onConfirm={(aliid) => this.splitUsingImportList(aliid)}
            onCancel={() => this.toggleLinkImportListmodal()}
            linkStoreProduct={linkStoreProduct}
          />
          <BundleModal
            {...this.props}
            visible={bundleModalVisible}
            linkProductModalId={this.state.bundleProductModal.id}
            onCancel={() => this.toggleBundelModal()}
            storeProduct={storeProduct}
          />
          <EstimatePriceModal
            {...this.state.estPriceModal}
            storeid={this.props.match.params.storeid}
            currencies={this.state.currencies}
            onCancel={() => this.toggleEstPriceModal()}
            onConfirm={this.getVFPrice}
          />
        </div>
      );
    };

    if (this.state.loading) {
      return PageWrapper(
        <Row style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </Row>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <Row>
          <Alert
            message="There was an error"
            description={this.state.errorMsg}
            type="error"
          />
        </Row>
      );
    }

    return PageWrapper(
      <div>
        {this.state.products.length > 0 ? (
          this.state.products.map((product) => (
            <MyProduct
              toggleEstPriceModal={() => this.toggleEstPriceModal(product)}
              product={product}
              pricing={{
                ...this.state.store_settings,
                commission:
                  product.aliproduct && product.aliproduct.ali_commission
                    ? product.aliproduct.ali_commission
                    : 0,
              }}
              deleteProduct={(id, deleteFromShopify) =>
                this.deleteProducts([id], deleteFromShopify)
              }
              key={product._id.$id}
              currencies={this.state.currencies}
              toggleOverrideModal={(data) => this.toggleOverrideModal(data)}
              linkProduct={() =>
                this.toggleLinkProductModal(product._id["$oid"])
              }
              geo={this.props.match.params.storegeo}
              storeid={this.props.match.params.storeid}
              loadData={() => this.loadData()}
              lessRequestCount={() => this.lessRequestCount()}
              requestCount={this.state.requestCount}
              vfprod={product.vfprod}
              linkid={this.state.linkProductModal.id}
              viewvariantmatching={product._id["$oid"]}
              toggleBundelModal={() =>
                this.toggleBundelModal(product._id["$oid"])
              }
              toggleLinkImportListmodal={() =>
                this.toggleLinkImportListmodal(product._id["$oid"])
              }
              history={this.props.history}
              productId={product._id["$oid"]}
              flexibleFullfillmentOn={product.flexible_fullfillment_on}
            />
          ))
        ) : (
          <NotFoundCard
            mainHeading={"Oops! No Data Found."}
            secondHeading={
              <p>Looks like there are no products in this page.</p>
            }
          />
        )}
      </div>
    );
  }
}

export default MyProducts;
