import React from "react";
import { notification, Icon } from "antd";
import { ReactComponent as NotificationIcon } from "../../../../assets/images/icons/order-notification.svg";
import "./index.scss";
const ActionNotification = (props) => {
  let durationTimeout = 6;

  // import to aliexpres notification
  function productImportAliHandler(name) {
    notification.open({
      bottom: "5%",
      className: "custom-notification import-ali",
      placement: "bottomLeft",
      duration: durationTimeout,
      style: { background: "#E9FFE8", border: "2px solid #2A9C27" },
      description: (
        <div className="notification-wraper">
          <Icon
            className="notification-icon-svg"
            component={NotificationIcon}
          />
          <div className="content">
            <h5>
              <h2>Product Import Initiated</h2> <span>!</span>{" "}
            </h5>

            <p>The Product is being Imported to vFulfill Import List.</p>

            <p>
              {" "}
              <a href={`./import-list`}>Click here </a> to view in Import List.
            </p>
          </div>
        </div>
      ),
      icon: null,
    });
  }

  // import to store notification
  function productNotificationHandler(name) {
    notification.open({
      bottom: "5%",
      className: "custom-notification import-ali",
      placement: "bottomLeft",
      duration: durationTimeout,
      style: { background: "#E9FFE8", border: "2px solid #2A9C27" },
      description: (
        <div className="notification-wraper">
          <Icon
            className="notification-icon-svg"
            component={NotificationIcon}
          />
          <div className="content">
            <h5>
              <h2>Product Imported</h2> <span>!</span>{" "}
            </h5>

            <p>
              The Product{" "}
              <a href={`./import-list`}>
                {!!name && name.length < 10 ? name : name.slice(0, 10) + "..."}
              </a>{" "}
              has been imported to Import List.
            </p>

            <p>
              {" "}
              <a href={`./import-list`}>Click here </a> to view in Import List.
            </p>
          </div>
        </div>
      ),
      icon: null,
    });
  }

  // order verify notification
  function verifyOrderNotificationHandler(props) {
    notification.open({
      bottom: "5%",
      className: "custom-notification",
      placement: "bottomLeft",
      duration: durationTimeout,
      style: { background: "#E9FFE8", border: "2px solid #2A9C27" },
      description: (
        <div className="notification-wraper">
          <Icon
            className="notification-icon-svg"
            component={NotificationIcon}
          />
          <div className="content">
            <h5>
              <h2>Order Verified</h2> <span>!</span>{" "}
            </h5>

            <p>
              Your order{" "}
              <a
                href={`https://${props.shopifyStore}/admin/orders/${
                  props.shopifyId
                }`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {props.showShopifyId}{" "}
              </a>
              has been verified.
            </p>

            {!!props.orderId && props.productLength > 1 ? (
              <p>
                {" "}
                <a href={`./open?search=${props.selectedOrders.toString()}`}>
                  Click here{" "}
                </a>{" "}
                to view orders.
              </p>
            ) : (
              <p>
                {" "}
                <a href={`./open?search=${props.orderId}`}>Click here </a> to
                view order.
              </p>
            )}

            {props.productLength > 1 ? (
              <>
                <h3 />
                <h4>+{props.productLength - 1} More Verified Orders</h4>
              </>
            ) : null}
          </div>
        </div>
      ),
      icon: null,
    });
  }

  //hold order  notification
  function holdOrderNotificationHandler(props) {
    console.log("Order Verify Notification Handler", props);
    notification.open({
      bottom: "5%",
      className: "custom-notification custom-notification-hold",
      placement: "bottomLeft",
      duration: durationTimeout,
      style: { background: "#FFEAA5", border: "2px solid #F3B62D" },
      description: (
        <div className="notification-wraper">
          <Icon
            className="notification-icon-svg"
            component={NotificationIcon}
          />
          <div className="content">
            <h5>
              <h2>Order On Hold</h2> <span>!</span>{" "}
            </h5>

            <p>
              Your order{" "}
              <a
                href={`https://${props.shopifyStore}/admin/orders/${
                  props.shopifyId
                }`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {props.showShopifyId}{" "}
              </a>
              has been put On Hold.
            </p>

            {!!props.orderId && props.productLength > 1 ? (
              <p>
                {" "}
                <a href={`./open?search=${props.selectedOrders.toString()}`}>
                  Click here{" "}
                </a>{" "}
                to view orders.
              </p>
            ) : (
              <p>
                {" "}
                <a href={`./open?search=${props.orderId}`}>Click here </a> to
                view order.
              </p>
            )}

            {props.productLength > 1 ? (
              <>
                <h3 />
                <h4>+{props.productLength - 1} More Hold Orders</h4>
              </>
            ) : null}
          </div>
        </div>
      ),
      icon: null,
    });
  }

  // unhold orders notification
  function unHoldOrderNotificationHandler(props) {
    notification.open({
      bottom: "5%",
      className: "custom-notification",
      placement: "bottomLeft",
      duration: durationTimeout,
      style: { background: "#E9FFE8", border: "2px solid #2A9C27" },
      description: (
        <div className="notification-wraper">
          <Icon
            className="notification-icon-svg"
            component={NotificationIcon}
          />
          <div className="content">
            <h5>
              <h2>Order Unheld</h2> <span>!</span>{" "}
            </h5>

            <p>
              Your order{" "}
              <a
                href={`https://${props.shopifyStore}/admin/orders/${
                  props.shopifyId
                }`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {props.showShopifyId}{" "}
              </a>
              has been moved to Open Orders.
            </p>

            {!!props.orderId && props.productLength > 1 ? (
              <p>
                {" "}
                <a href={`./open?search=${props.selectedOrders.toString()}`}>
                  Click here{" "}
                </a>{" "}
                to view orders.
              </p>
            ) : (
              <p>
                {" "}
                <a href={`./open?search=${props.orderId}`}>Click here </a> to
                view order.
              </p>
            )}

            {props.productLength > 1 ? (
              <>
                <h3 />
                <h4>+{props.productLength - 1} More Unheld Orders</h4>
              </>
            ) : null}
          </div>
        </div>
      ),
      icon: null,
    });
  }

  // cancel orders notification
  function cancelOrderNotificationHandler(props) {
    notification.open({
      bottom: "5%",
      className: "custom-notification custom-notification-cancel",
      placement: "bottomLeft",
      duration: durationTimeout,
      style: { background: "#FFEDF1", border: "2px solid #C70C3C" },
      description: (
        <div className="notification-wraper">
          <Icon
            className="notification-icon-svg"
            component={NotificationIcon}
          />
          <div className="content">
            <h5>
              <h2>Order Cancelled</h2> <span>!</span>{" "}
            </h5>

            <p>
              Your order{" "}
              <a
                href={`https://${props.shopifyStore}/admin/orders/${
                  props.shopifyId
                }`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {props.showShopifyId}{" "}
              </a>
              has been cancelled.
            </p>

            {!!props.orderId && props.productLength > 1 ? (
              <p>
                {" "}
                <a
                  href={`./closedOrders?search=${props.selectedOrders.toString()}`}
                >
                  Click here{" "}
                </a>{" "}
                to view orders.
              </p>
            ) : (
              <p>
                {" "}
                <a href={`./closedOrders?search=${props.orderId}`}>
                  Click here{" "}
                </a>{" "}
                to view order.
              </p>
            )}

            {props.productLength > 1 ? (
              <>
                <h3 />
                <h4>+{props.productLength - 1} More cancelled Orders</h4>
              </>
            ) : null}
          </div>
        </div>
      ),
      icon: null,
    });
  }

  // do not fulfill orders notification
  function notFulfillOrderNotificationHandler(props) {
    console.log("Order Verify Notification Handler", props);
    notification.open({
      bottom: "5%",
      className: "custom-notification custom-notification-hold",
      placement: "bottomLeft",
      duration: durationTimeout,
      style: { background: "#FFEAA5", border: "2px solid #F3B62D" },
      description: (
        <div className="notification-wraper">
          <Icon
            className="notification-icon-svg"
            component={NotificationIcon}
          />
          <div className="content">
            <h5>
              <h2>Do Not Fulfill Order</h2> <span>!</span>{" "}
            </h5>

            <p>
              Order{" "}
              <a
                href={`https://${props.shopifyStore}/admin/orders/${
                  props.shopifyId
                }`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {props.showShopifyId}{" "}
              </a>
              marked not be fulfilled by vFulfill.
            </p>

            {!!props.orderId && props.productLength > 1 ? (
              <p>
                {" "}
                <a
                  href={`./closedOrders?search=${props.selectedOrders.toString()}`}
                >
                  Click here{" "}
                </a>{" "}
                to view orders.
              </p>
            ) : (
              <p>
                {" "}
                <a href={`./closedOrders?search=${props.orderId}`}>
                  Click here{" "}
                </a>{" "}
                to view order.
              </p>
            )}

            {props.productLength > 1 ? (
              <>
                <h3 />
                <h4>+{props.productLength - 1} More unfulfilled Orders</h4>
              </>
            ) : null}
          </div>
        </div>
      ),
      icon: null,
    });
  }

  // placed orders notification
  function placedOrderNotificationHandler(props) {
    console.log("Order Verify Notification Handler", props);
    notification.open({
      bottom: "5%",
      className: "custom-notification",
      placement: "bottomLeft",
      duration: durationTimeout,
      style: { background: "#E9FFE8", border: "2px solid #2A9C27" },
      description: (
        <div className="notification-wraper">
          <Icon
            className="notification-icon-svg"
            component={NotificationIcon}
          />
          <div className="content">
            <h5>
              <h2>Order Placed</h2> <span>!</span>{" "}
            </h5>

            <p>
              Your order{" "}
              <a
                href={`https://${props.shopifyStore}/admin/orders/${
                  props.shopifyId
                }`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {props.showShopifyId}{" "}
              </a>{" "}
              has been placed.
            </p>

            {!!props.orderId && props.productLength > 1 ? (
              <p>
                {" "}
                <a
                  href={`./processing?search=${props.selectedOrders.toString()}`}
                >
                  Click here{" "}
                </a>{" "}
                to view orders.
              </p>
            ) : (
              <p>
                {" "}
                <a href={`./processing?search=${props.orderId}`}>
                  Click here{" "}
                </a>{" "}
                to view order.
              </p>
            )}

            {props.productLength > 1 ? (
              <>
                <h3 />
                <h4>+{props.productLength - 1} More Orders Placed</h4>
              </>
            ) : null}
          </div>
        </div>
      ),
      icon: null,
    });
  }

  // rto initiated notification
  function rtoInitateNotificationHandler(name) {
    notification.open({
      bottom: "5%",
      className: "custom-notification",
      placement: "bottomLeft",
      duration: durationTimeout,
      style: { background: "#E9FFE8", border: "2px solid #2A9C27" },
      description: (
        <div className="notification-wraper">
          <Icon
            className="notification-icon-svg"
            component={NotificationIcon}
          />
          <div className="content">
            <h5>
              <h2>RTO Initated</h2> <span>!</span>{" "}
            </h5>
            <p>
              RTO for AWB <a href={`./ndr?search=${props.awb}`}>{props.awb}</a>
            </p>
            has been initiated.
            <p>
              {" "}
              <a href={`./ndr?search=${props.awb}`}>Click here </a> to view
              status.
            </p>
          </div>
        </div>
      ),
      icon: null,
    });
  }

  // re-attempt notification
  function reAttemptNotificationHandler(name) {
    notification.open({
      bottom: "5%",
      className: "custom-notification",
      placement: "bottomLeft",
      duration: durationTimeout,
      style: { background: "#E9FFE8", border: "2px solid #2A9C27" },
      description: (
        <div className="notification-wraper">
          <Icon
            className="notification-icon-svg"
            component={NotificationIcon}
          />
          <div className="content">
            <h5>
              <h2>Re-attempt Initiated</h2> <span>!</span>{" "}
            </h5>
            <p>
              Re-attempting delivery for AWB{" "}
              <a href={`./ndr?search=${props.awb}`}>{props.awb}</a>
            </p>

            <p>
              {" "}
              <a href={`./ndr?search=${props.awb}`}>Click here </a> to view
              status.
            </p>
          </div>
        </div>
      ),
      icon: null,
    });
  }

  // re-attempt and escalate notification
  function reAttemptAndEscalateNotificationHandler(name) {
    notification.open({
      bottom: "5%",
      className: "custom-notification",
      placement: "bottomLeft",
      duration: durationTimeout,
      style: { background: "#E9FFE8", border: "2px solid #2A9C27" },
      description: (
        <div className="notification-wraper">
          <Icon
            className="notification-icon-svg"
            component={NotificationIcon}
          />
          <div className="content">
            <h5>
              <h2>Escalation Raised</h2> <span>!</span>{" "}
            </h5>
            <p>
              Escalating & Re-attempting delivery for AWB{" "}
              <a href={`./escalation/ndr-escalations?search=${props.awb}`}>
                {props.awb}
              </a>
            </p>

            <p>
              {" "}
              <a href={`./escalation/ndr-escalations?search=${props.awb}`}>
                Click here{" "}
              </a>{" "}
              to view status.
            </p>
          </div>
        </div>
      ),
      icon: null,
    });
  }
   // re-attempt and escalate notification `1
  function reAttemptAndEscalateNotificationHandler1(name) {
    notification.open({
      bottom: "5%",
      className: "custom-notification",
      placement: "bottomLeft",
      duration: durationTimeout,
      style: { background: "#E9FFE8", border: "2px solid #2A9C27" },
      description: (
        <div className="notification-wraper">
          <Icon
            className="notification-icon-svg"
            component={NotificationIcon}
          />
          <div className="content">
            <h5>
              <h2>Escalation Raised</h2> <span>!</span>{" "}
            </h5>
            <p>
              Escalating & Re-attempting delivery for AWB{" "}
              <a href={`./ndr-escalations?search=${props.awb}`}>
                {props.awb}
              </a>
            </p>

            <p>
              {" "}
              <a href={`./ndr-escalations?search=${props.awb}`}>
                Click here{" "}
              </a>{" "}
              to view status.
            </p>
          </div>
        </div>
      ),
      icon: null,
    });
  }
  // re-attempt and escalate notification
  function escalateNotificationHandler(name) {
    notification.open({
      bottom: "5%",
      className: "custom-notification escalate",
      placement: "bottomLeft",
      duration: durationTimeout,
      style: { background: "#E9FFE8", border: "2px solid #2A9C27" },
      description: (
        <div className="notification-wraper">
          <Icon
            className="notification-icon-svg"
            component={NotificationIcon}
          />
          <div className="content">
            <h5>
              <h2>Escalation Raised</h2> <span>!</span>{" "}
            </h5>
            <p>
              Escalation for AWB{" "}
              <a href={`./escalation/escalations?search=${props.awb}`}>
                {props.awb}{" "}
              </a>{" "}
              has been raised.
            </p>

            <p>
              {" "}
              <a href={`./escalation/escalations?search=${props.awb}`}>
                Click here{" "}
              </a>{" "}
              to view status.
            </p>
          </div>
        </div>
      ),
      icon: null,
    });
  }

  function NotificationHandler() {
    if (props.notificationType === "importProduct") {
      return productNotificationHandler(props.name);
    } else if (props.notificationType === "verify") {
      return verifyOrderNotificationHandler(props);
    } else if (props.notificationType === "hold") {
      return holdOrderNotificationHandler(props);
    } else if (props.notificationType === "unhold") {
      return unHoldOrderNotificationHandler(props);
    } else if (props.notificationType === "cancel") {
      return cancelOrderNotificationHandler(props);
    } else if (props.notificationType === "nofulfill") {
      return notFulfillOrderNotificationHandler(props);
    } else if (props.notificationType === "verifyorder") {
      return placedOrderNotificationHandler(props);
    } else if (props.notificationType === "rto-initiated") {
      return rtoInitateNotificationHandler(props);
    } else if (props.notificationType === "re-attempt") {
      return reAttemptNotificationHandler(props);
    } else if (props.notificationType === "re-attempt-and-escalate") {
      return reAttemptAndEscalateNotificationHandler(props);
    } else if (props.notificationType === "re-attempt-and-escalate-1") {
      return reAttemptAndEscalateNotificationHandler1(props);
    } else if (props.notificationType === "escalate") {
      return escalateNotificationHandler(props);
    } else if (props.notificationType === "ali-express-import") {
      return productImportAliHandler(props);
    }
  }

  return NotificationHandler(props);
};

export default ActionNotification;
