import React from "react";
import { Form, Button } from "antd";
import { SHOPIFY_NAME } from "../../../../constants";

// const handleSubmit = (e, props) => {
//   e.preventDefault();
//   props.form.validateFields((err, values) => {
//     if (!err) {
//       props.handleOk(values.shop);
//     }
//   });
// };

const ConnectToShopify = (props) => {
  // const { getFieldDecorator, setFieldsValue } = props.form;

  return (
    <div>
      {/* <Alert
        message="You will be redirected to Shopify to authorize vFulfill."
        type="info"
        showIcon
      /> */}
      {/* <Form onSubmit={(e) => handleSubmit(e, props)} className="connect-shopify-form" noValidate>
        <p style={{ marginTop: "16px" }}><b>Enter the URL of your Shopify store</b></p>
        <Form.Item>
          {getFieldDecorator('shop', {
            rules: [{ required: true, type: "string", pattern: "^[a-z0-9-]+$", message: 'Please enter a valid Shopify Shop URL' }],
          })(
            <Input size="large" type="text" placeholder="store-name" suffix=".myshopify.com" />
          )}
        </Form.Item>
        <p>Don’t have a Shopify store? <a href="https://shopify.com" target="_blank" rel="noopener noreferrer">Create New Store</a></p>

        <div style={{ textAlign: props.alignButtonsRight ? 'right' : 'left' }}>
          <Button size="large" type="primary" htmlType="submit">Connect</Button>&nbsp;&nbsp;&nbsp;
          <Button size="large" type="default" htmlType="button" onClick={() => { setFieldsValue({ 'shop': '' }); props.onCancel() }}>Cancel</Button>
        </div>
      </Form> */}
      {props.username === true ? (
        <p className="user_description">
          Hey{" "}
          <b className="user-firstname">
            {props.userauth &&
            props.userauth.user &&
            props.userauth.user.user_firstname
              ? props.userauth.user.user_firstname
              : null}
          </b>
          {`, you will need to follow the following steps to add & connect your
          ${SHOPIFY_NAME} to vFulfill:`}
        </p>
      ) : (
        <p className="user_description">
          Hey{" "}
          <b className="user-firstname">
            {" "}
            {props.user && props.user.user_firstname
              ? props.user.user_firstname
              : props.user.user_fname}
          </b>
          {`, you will need to follow the following steps to add & connect your
          ${SHOPIFY_NAME} to vFulfill:`}
        </p>
      )}
      <div>
        <div className="middle_data">
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="52.412"
              height="59.334"
              viewBox="0 0 52.412 59.334"
            >
              <g
                id="Group_3624"
                data-name="Group 3624"
                transform="translate(0 -0.394)"
              >
                <path
                  id="Path_337"
                  data-name="Path 337"
                  d="M45.876,11.8a.54.54,0,0,0-.513-.47c-.214,0-4.442-.085-4.442-.085s-3.545-3.417-3.887-3.8a1.421,1.421,0,0,0-1.281-.171s-.683.214-1.794.555A12.737,12.737,0,0,0,33.1,5.736c-1.239-2.392-3.118-3.674-5.339-3.674h0a1.752,1.752,0,0,0-.47.043c-.085-.085-.128-.171-.214-.214A4.746,4.746,0,0,0,23.365.4C20.5.482,17.641,2.575,15.292,6.249a24.076,24.076,0,0,0-3.246,8.372c-3.289,1.025-5.6,1.751-5.681,1.751-1.666.513-1.709.555-1.922,2.136C4.357,19.7,0,53.406,0,53.406l36.564,6.322L52.412,55.8C52.369,55.8,45.919,12.1,45.876,11.8ZM32.122,8.427q-1.281.384-2.819.9a15.8,15.8,0,0,0-.854-5.211C30.627,4.455,31.695,6.932,32.122,8.427ZM27.38,9.879c-1.922.6-4.015,1.239-6.108,1.879a14.733,14.733,0,0,1,3.076-5.98,6.213,6.213,0,0,1,2.05-1.5A12.536,12.536,0,0,1,27.38,9.879Zm-3.93-7.561a2.777,2.777,0,0,1,1.709.47A7.569,7.569,0,0,0,22.938,4.54a16.65,16.65,0,0,0-3.8,7.945c-1.751.555-3.46,1.068-5,1.538C15.164,9.367,19.051,2.447,23.451,2.319Z"
                  transform="translate(0 0)"
                  fill="#95bf47"
                />
                <g
                  id="Group_3623"
                  data-name="Group 3623"
                  transform="translate(9.739 7.231)"
                >
                  <path
                    id="Path_338"
                    data-name="Path 338"
                    d="M94.4,20.5c-.214,0-4.442-.085-4.442-.085S86.412,17,86.07,16.614a.686.686,0,0,0-.47-.214V68.9l15.847-3.93s-6.45-43.7-6.493-44A.621.621,0,0,0,94.4,20.5Z"
                    transform="translate(-58.775 -16.4)"
                    fill="#5e8e3e"
                  />
                  <path
                    id="Path_339"
                    data-name="Path 339"
                    d="M40.826,44.25l-1.837,6.877a10.235,10.235,0,0,0-4.485-.769c-3.588.214-3.588,2.477-3.588,3.033.214,3.076,8.287,3.759,8.757,10.978.342,5.681-2.99,9.568-7.86,9.867A11.978,11.978,0,0,1,22.8,71.118l1.239-5.254s3.246,2.435,5.809,2.264a2.266,2.266,0,0,0,2.221-2.435c-.256-4.015-6.834-3.759-7.262-10.38-.342-5.553,3.289-11.149,11.32-11.661A9.621,9.621,0,0,1,40.826,44.25Z"
                    transform="translate(-22.8 -31.991)"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </p>
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="43.033"
              height="38.73"
              viewBox="0 0 43.033 38.73"
              style={{ marginTop: "12px" }}
            >
              <path
                id="icons8-connect"
                d="M38.578,3a6.488,6.488,0,0,0-6.455,6.455,6.337,6.337,0,0,0,.651,2.761l-8,8H14.507a6.455,6.455,0,1,0,0,4.3H24.777l8,8a6.337,6.337,0,0,0-.651,2.761,6.455,6.455,0,1,0,6.455-6.455,6.337,6.337,0,0,0-2.761.651l-7.106-7.106,7.106-7.106a6.337,6.337,0,0,0,2.761.651,6.455,6.455,0,1,0,0-12.91Zm0,4.3a2.152,2.152,0,1,1-2.152,2.152A2.12,2.12,0,0,1,38.578,7.3ZM8.455,20.213A2.152,2.152,0,1,1,6.3,22.365,2.12,2.12,0,0,1,8.455,20.213Zm30.123,12.91a2.152,2.152,0,1,1-2.152,2.152A2.12,2.12,0,0,1,38.578,33.123Z"
                transform="translate(-2 -3)"
              />
            </svg>
          </p>

          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="53.757"
              height="53.715"
              viewBox="0 0 53.757 53.715"
            >
              <g
                id="Group_3682"
                data-name="Group 3682"
                transform="translate(-3469 3159.358)"
              >
                <path
                  id="path53330"
                  d="M-.448,0H53.288l-.021,53.715H-.469Z"
                  transform="translate(3469.469 -3159.358)"
                  fill="#191919"
                />
                <path
                  id="path53332"
                  d="M442.343,379.578c.005.008.017.013.022.023a.247.247,0,0,1,.025.032c.005.01.017.023.022.035s.012.025.018.037.012.027.017.04a.186.186,0,0,1,.012.042c0,.013.005.029.01.044a.192.192,0,0,1,.005.045.392.392,0,0,1,0,.046c0,.01,0,.022,0,.033s0,.031,0,.046,0,.031,0,.045a.229.229,0,0,1-.01.045.213.213,0,0,1-.012.043.208.208,0,0,1-.017.042c-.005.013-.012.027-.017.039s-.012.025-.018.037-.017.023-.022.034a.192.192,0,0,1-.025.031.376.376,0,0,1-.028.027.257.257,0,0,1-.028.023.136.136,0,0,1-.031.018c-.01,0-.022.01-.031.013l-.032.01a.167.167,0,0,1-.033,0h-.022a.185.185,0,0,1-.033,0,.135.135,0,0,1-.033-.008l-.032-.012a.125.125,0,0,1-.031-.017l-.031-.02c-.01-.008-.018-.017-.028-.024a.144.144,0,0,1-.025-.028.249.249,0,0,1-.025-.031c-.005-.01-.017-.023-.022-.035s-.012-.025-.018-.038-.012-.026-.017-.04a.189.189,0,0,1-.012-.042c0-.013-.005-.029-.01-.044a.194.194,0,0,1-.005-.045.223.223,0,0,1,0-.027L434.7,399.421h6.3l.04,0a.422.422,0,0,0,.056-.01.446.446,0,0,0,.056-.018.464.464,0,0,0,.054-.025.527.527,0,0,0,.052-.032.573.573,0,0,0,.05-.039.653.653,0,0,0,.046-.046q.022-.025.043-.052t.039-.058q.018-.03.035-.063c.01-.021.02-.043.028-.066l0-.008,7.067-19.432Zm-1.006,18.427.025.027-.025-.027Zm-.778.123-.018.036Zm-.035.063q-.017.033-.031.067Q440.507,398.224,440.523,398.191Zm1.041.451q0,.038,0,.075Q441.564,398.68,441.565,398.642Z"
                  transform="translate(3053.783 -3521.935)"
                  fill="#67e9d9"
                />
                <g
                  id="Group_3681"
                  data-name="Group 3681"
                  transform="translate(3469 -3159.358)"
                >
                  <g id="Group_3680" data-name="Group 3680">
                    <g id="Group_3679" data-name="Group 3679">
                      <g id="Group_3678" data-name="Group 3678">
                        <g id="Group_3677" data-name="Group 3677">
                          <g id="Group_3676" data-name="Group 3676">
                            <g id="Group_3675" data-name="Group 3675">
                              <g id="Group_3674" data-name="Group 3674">
                                <g id="Group_3673" data-name="Group 3673">
                                  <path
                                    id="path53334"
                                    d="M234.041,376.7l3.765,11.053.01.028q.061.161.131.314l.3.876.19.558,2.284,6.705.01.028q.012.033.025.064t.033.064q.018.031.038.06t.042.054a.656.656,0,0,0,.046.048.593.593,0,0,0,.049.041.543.543,0,0,0,.052.035.489.489,0,0,0,.054.027.457.457,0,0,0,.056.02.408.408,0,0,0,.113.015h6.33L243.8,385.641l-.01-.028q-.061-.161-.131-.314l-.3-.876-.19-.558-2.284-6.705-.01-.028q-.013-.033-.028-.064t-.034-.064q-.018-.031-.038-.059a.678.678,0,0,0-.042-.054.649.649,0,0,0-.046-.048.592.592,0,0,0-.049-.041.522.522,0,0,0-.052-.034.5.5,0,0,0-.054-.027.468.468,0,0,0-.056-.02.442.442,0,0,0-.057-.012.416.416,0,0,0-.056,0h-6.327Zm5.927.223q-.021.027-.039.058h0q.019-.03.039-.058Zm-.074.121q-.017.033-.031.067h0q.014-.035.031-.067Zm-.038.091q-.009.024-.017.048h0c.005-.016.011-.032.017-.048Zm1.019.735q-.015.035-.031.067h0Q240.861,377.909,240.875,377.874Zm-.045.095-.018.036h0Zm-.031,17.424h0l-.018.036.018-.036Zm-.035.063h0q-.017.033-.031.067.014-.035.031-.067Zm1.048.536q0,.034-.005.068Q241.81,396.026,241.811,395.992Zm-.042.219h0c-.005.016-.011.032-.017.048Q241.762,396.235,241.77,396.211Zm-.025.071h0q-.015.035-.031.067Q241.73,396.316,241.744,396.282Zm-.065.131h0q-.019.03-.039.058Q241.661,396.443,241.679,396.412Z"
                                    transform="translate(-223.544 -359.842)"
                                    fill="#5db3ff"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <path
                  id="path53336"
                  d="M562.342,379.358l-4,.008h-.4l-11.625-.041h-.093a.678.678,0,0,0-.078.008c-.025,0-.052.008-.077.013a.385.385,0,0,0-.075.02l-.072.025-.069.031a.519.519,0,0,0-.064.036.454.454,0,0,0-.059.041.6.6,0,0,0-.054.045c-.017.017-.033.032-.048.049s-.025.031-.037.047l-.018.031-2.14,6.214,10.176-.02v0l6.385-.008Z"
                  transform="translate(2949.922 -3521.704)"
                  fill="#fefefe"
                />
                <path
                  id="path53338"
                  d="M657.534,613.436H648.8l-1.748,4.812,8.735-.005Z"
                  transform="translate(2850.931 -3745.335)"
                  fill="#fefefe"
                />
              </g>
            </svg>
          </p>
        </div>

        <div>
          <div className="footer_description">
            <p>
              {" "}
              {`1. Create / Login to your ${SHOPIFY_NAME} which you want to connect
              with vFulfill.`}
            </p>
            <p>
              {` 2. Go to our ${SHOPIFY_NAME} App Store Listing{" "}`}
              <span style={{ marginRight: "8px" }}>
                {" "}
                <a
                  href="https://apps.shopify.com/vfulfill-cod-dropshipping"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.259"
                    height="11.448"
                    viewBox="0 0 11.259 11.448"
                  >
                    <g
                      id="Group_3617"
                      data-name="Group 3617"
                      transform="translate(-961.362 -333.152)"
                    >
                      <path
                        id="Path_84"
                        data-name="Path 84"
                        d="M-4594.261-691.587h-1.944v10h10V-683.4"
                        transform="translate(5558.167 1025.587)"
                        fill="none"
                        stroke="#1370fc"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.2"
                      />
                      <g
                        id="Group_3178"
                        data-name="Group 3178"
                        transform="translate(965.64 334)"
                      >
                        <path
                          id="Path_85"
                          data-name="Path 85"
                          d="M-4590.015-685.789l6.133-6.2"
                          transform="translate(4590.015 691.988)"
                          fill="none"
                          stroke="#1370fc"
                          stroke-linecap="round"
                          stroke-width="1.2"
                        />
                        <path
                          id="Path_86"
                          data-name="Path 86"
                          d="M-4587.394-691.587h4.074v3.886"
                          transform="translate(4589.643 691.587)"
                          fill="none"
                          stroke="#1370fc"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.2"
                        />
                      </g>
                    </g>
                  </svg>
                </a>
              </span>
            </p>
            <p>
              {" "}
              {` 3. Click on “Add App” button to install the vFulfill App on your
              ${SHOPIFY_NAME}.`}
            </p>
            <p>
              {" "}
              4. You will see an App Installation page - click on the Install
              App button to confirm the installation of vFulfill App.
            </p>
          </div>
        </div>
      </div>
      <div className="footer_button">
        <p />
        <Button
          style={{
            width: "161px",
            fontSize: "12px",
            color: "#898989",
            background: "#f5f5f5",
          }}
          onClick={() => {
            props.onCancel();
          }}
        >
          Close Window
        </Button>
      </div>
    </div>
  );
};

export default Form.create()(ConnectToShopify);
