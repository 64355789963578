import React, { Component } from "react";
import { Input, Form, Icon, Select, Upload, Button } from "antd";
import Header from "../../../../Header";
import { ReactComponent as individualican } from "../../../../../../assets/images/icons/individualican.svg";
import { ReactComponent as arrowican } from "../../../../../../assets/images/icons/arrow.svg";
import { ReactComponent as rightican } from "../../../../../../assets/images/icons/right.svg";
import { ReactComponent as GreenTick } from "../../../../../../assets/images/icons/green-tick.svg";

import "intl-tel-input/build/css/intlTelInput.css";
// import IntlTelInput from "react-intl-tel-input-v2";
import { ISDCodes } from "../../../../../../Helpers/ISD";
import { countryList } from "./../../../../../../Helpers/countryList";
import GlobalTooltipIcon from "../../../../../../Components/Dashboard/Common/GlobalTooltipIcon";
import ReactPhoneInput from "react-phone-input-2";

const { Option } = Select;
const Dragger = Upload.Dragger;
class Profiledetail extends Component {
  state = {
    selectedCity: "",
    arrow2: false,
    arrow: false,
    paymentinfo: "",
  };

  selectPaymentinfo(e) {
    this.setState({
      ...this.state,
      paymentinfo: e,
    });
  }

  cityChange(e) {
    this.setState({
      ...this.state,
      selectedCity: e,
      arrow2: !this.state.arrow2,
    });
  }

  cityHandler() {
    this.setState({ arrow2: !this.state.arrow2 });
  }

  numberValueHandler(dialcode, number) {
    return `${dialcode}` + `${number}`;
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <>
        {this.props.currentStep === 1 && (
          <Header
            title="Let Us Know More About You"
            subtitle="Let’s get done with completing & verifying your profile info"
          />
        )}

        {this.props.kycType === "Organisation"
          ? this.props.currentStep === 1 && (
              <p className="gst-individual">
                <span>
                  <Icon component={individualican} />
                </span>
                <span className="flag">
                  I am an <span className="registered-india">Organization</span>{" "}
                  operating outside India
                </span>
              </p>
            )
          : this.props.currentStep === 1 && (
              <p className="gst-individual">
                <span>
                  <Icon component={individualican} />
                </span>

                <span className="flag">
                  I am an <span className="registered-india">Individual</span>{" "}
                  operating outside India
                </span>
              </p>
            )}

        {this.props.currentStep === 1 && (
          <div className="top-header-steps">
            <p>
              Step {this.props.currentStep} out of {this.props.totalSteps}
            </p>
            <button
              className="back-btn"
              onClick={() => this.props.toggleBackButton()}
            >
              <span>
                <Icon component={arrowican} />
              </span>
              Previous Step
            </button>
          </div>
        )}

        <Form>
          {this.props.currentStep === 1 ? (
            <div
              style={{
                display: this.props.currentStep === 1 ? "block" : "none",
              }}
            >
              {this.props.kycType === "Individual" && (
                <>
                  <Form.Item label="Name">
                    {getFieldDecorator("individual_name", {
                      initialValue: this.props.nonindiankycdata1
                        .individual_name,
                      rules: [
                        {
                          required: true,
                          message: "Please enter your Name!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter your Full Name"
                        onChange={(e) =>
                          this.props.nonIndianPreviousDataStoe(
                            "individual_name",
                            e.target.value
                          )
                        }
                        value={this.props.nonindiankycdata1.individual_name}
                      />
                    )}
                  </Form.Item>
                </>
              )}

              {this.props.kycType === "Organisation" && (
                <>
                  <Form.Item label="Business Name">
                    {getFieldDecorator("org_name", {
                      initialValue: this.props.nonindiankycdata1.org_name,
                      rules: [
                        {
                          required: true,
                          message: "Please enter your Name!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter your Business/Organisation Name"
                        onChange={(e) =>
                          this.props.nonIndianPreviousDataStoe(
                            "org_name",
                            e.target.value
                          )
                        }
                        value={this.props.nonindiankycdata1.org_name}
                      />
                    )}
                  </Form.Item>
                </>
              )}

              <Form.Item
                label="Building/ Apartment/ Street Number"
                className="address-input"
              >
                {getFieldDecorator("address[line1]", {
                  initialValue: this.props.nonindiankycdata1.line1,
                  rules: [
                    {
                      required: true,
                      message: "Enter address line 1!",
                    },
                  ],
                })(
                  <Input
                    placeholder="Enter address line 1"
                    onChange={(e) =>
                      this.props.nonIndianPreviousDataStoe(
                        "line1",
                        e.target.value
                      )
                    }
                    value={this.props.nonindiankycdata1.line1}
                  />
                )}
              </Form.Item>

              <Form.Item
                label="Area/ Locality/ Society"
                className="address-input"
              >
                {getFieldDecorator("address[line2]", {
                  initialValue: this.props.nonindiankycdata1.line2,
                  rules: [
                    {
                      required: true,
                      message: "Please enter address line 2!",
                    },
                  ],
                })(
                  <Input
                    placeholder="Enter address line 2"
                    onChange={(e) =>
                      this.props.nonIndianPreviousDataStoe(
                        "line2",
                        e.target.value
                      )
                    }
                    value={this.props.nonindiankycdata1.line2}
                  />
                )}
              </Form.Item>

              <div className="country-wrapper">
                <div className="global-country">
                  <Form.Item label="Country">
                    {getFieldDecorator("address[country]", {
                      initialValue: this.state.selectedCity,
                      rules: [
                        {
                          required: true,
                          message: "Please Select your  country!",
                        },
                      ],
                    })(
                      <Select
                        placeholder="Select Country"
                        showSearch
                        value={this.state.selectedCity}
                        onChange={(e) => this.cityChange(e)}
                        filterOption={(input, option) => {
                          return (
                            option.key
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            option.props.title
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                        className="city-select"
                        onDropdownVisibleChange={() => this.cityHandler()}
                        suffixIcon={
                          <svg
                            style={{
                              transition: "all 0.5s",
                              transform: `${
                                this.state.arrow2
                                  ? "rotateX(180deg)"
                                  : "rotateX(0deg)"
                              }`,
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="8"
                            viewBox="0 0 16 8"
                          >
                            <g
                              id="Polygon_33"
                              data-name="Polygon 33"
                              transform="translate(16 8) rotate(180)"
                              fill={`${this.state.arrow2 ? "898989" : "none"}`}
                            >
                              <path d="M8,0l8,8H0Z" stroke="none" />
                              <path
                                d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                                stroke="none"
                                fill="#898989"
                              />
                            </g>
                          </svg>
                        }
                      >
                        {countryList.map((item) => {
                          return (
                            <Option
                              title={item.name}
                              key={item.name}
                              id={item.code}
                              value={item.name}
                              className="profile-dropdown"
                            >
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </div>
                <div className="state-wrapper">
                  <Form.Item label="State">
                    {getFieldDecorator("address[state]", {
                      initialValue: this.props.nonindiankycdata1.state,
                      rules: [
                        {
                          required: true,
                          message: "Please enter your state!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="State"
                        onChange={(e) =>
                          this.props.nonIndianPreviousDataStoe(
                            "state",
                            e.target.value
                          )
                        }
                        value={this.props.nonindiankycdata1.state}
                      />
                    )}
                  </Form.Item>
                </div>
              </div>

              <div className="city-pincode-wrapper">
                <div className="pincode-wrapper">
                  <Form.Item label="City">
                    {getFieldDecorator("address[city]", {
                      initialValue: this.props.nonindiankycdata1.city,
                      rules: [
                        {
                          required: true,
                          message: "Please enter your city!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter City"
                        onChange={(e) =>
                          this.props.nonIndianPreviousDataStoe(
                            "city",
                            e.target.value
                          )
                        }
                        value={this.props.nonindiankycdata1.city}
                      />
                    )}
                  </Form.Item>
                </div>

                <div className="pincode-wrapper">
                  <Form.Item label="Pincode">
                    {getFieldDecorator("address[pincode]", {
                      initialValue: this.props.nonindiankycdata1.pincode,
                      rules: [
                        {
                          required: true,
                          message: "Please enter your pincode!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Pincode"
                        onChange={(e) =>
                          this.props.nonIndianPreviousDataStoe(
                            "pincode",
                            e.target.value
                          )
                        }
                        value={this.props.nonindiankycdata1.pincode}
                      />
                    )}
                  </Form.Item>
                </div>
              </div>

              {this.props.kycType === "Organisation" && (
                <>
                  <div className="phone-field-wraper custom">
                    {this.props.verifiedcontact ? (
                      <p className="already-verify-email">
                        <span className="save-ican">
                          <Icon component={rightican} />
                        </span>
                        Already verified
                      </p>
                    ) : this.props.phoneverified === 1 ? (
                      <div className="verified-div-non-ind">
                        {" "}
                        <Icon component={GreenTick} />
                      </div>
                    ) : (
                      <Button
                        loading={this.props.numberOtpLoading}
                        className={
                          this.props.supportNumber2 &&
                          !!this.props.supportNumber2.length &&
                          this.props.supportNumber2.length >= 8
                            ? "verify-email2 anchor clickable"
                            : "verify-email anchor clickable"
                        }
                        onClick={() => this.props.VerifyPhone()}
                      >
                        Verify
                      </Button>
                    )}
                    {/* <span className="verifyphone-tooltip">
                      {" "}
                      <GlobalTooltipIcon tooltipText="Allowed phone number range is 10-12 digits" />
                    </span> */}

                    <Form.Item
                      label={
                        <span className="label-phone-no">
                          Support Phone Number
                        </span>
                      }
                      className="input-phone-txt phone-input-validation"
                    >
                      {getFieldDecorator("support_phone", {
                        initialValue: this.numberValueHandler(
                          this.props.prefix_1,
                          this.props.supportNumber2
                        ),
                        rules: [
                          {
                            max: 14,
                            required: false,
                            message: "Please enter valid phone number!",
                            min: 8,
                          },
                        ],
                      })(
                        <ReactPhoneInput
                          placeholder="Enter phone number"
                          enableSearchField
                          excludeCountries={["in"]}
                          disabled={
                            this.props.phoneverified === 1 ? true : false
                          }
                          value={this.numberValueHandler(
                            this.props.prefix_1,
                            this.props.supportNumber2
                          )}
                          onChange={(val, extra) =>
                            this.props.handleSupportPhoneChange({
                              value: val,
                              extraVal: extra,
                              field: "contactno",
                            })
                          }
                        />
                      )}
                    </Form.Item>
                  </div>

                  <div className="email-field-wraper">
                    {this.props.verifiedmsg ? (
                      <p className="already-verify-email">
                        <span className="save-ican">
                          <Icon component={rightican} />
                        </span>
                        Already verified
                      </p>
                    ) : this.props.emailverified === 1 ? (
                      <div className="verified-div-email">
                        {" "}
                        <Icon component={GreenTick} />
                      </div>
                    ) : (
                      <Button
                        loading={this.props.emailOtpLoading}
                        className={
                          !!this.props.supportEmail2.length
                            ? "verify-phone2 anchor clickable"
                            : "verify-phone anchor clickable"
                        }
                        onClick={() => this.props.VerifyEmail()}
                      >
                        Verify
                      </Button>
                    )}

                    <Form.Item label="Support Email Address">
                      {getFieldDecorator("email", {
                        initialValue: this.props.supportEmail2,
                        rules: [
                          {
                            required: false,
                            message: "Please Enter support email address!",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Enter support email address"
                          onChange={(e) =>
                            this.props.handleSupportEmailChange(
                              "EmailInpute",
                              e.target.value
                            )
                          }
                          disabled={
                            this.props.emailverified === 1 ? true : false
                          }
                        />
                      )}
                    </Form.Item>
                  </div>
                  <Form.Item label="Business Identity Number #">
                    {getFieldDecorator("org_idnum1", {
                      initialValue: this.props.nonindiankycdata1.org_idnum1,
                      rules: [
                        {
                          required: true,
                          message:
                            "Enter your Business Identity Number (EIN etc.)",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter your Business Identity Number (EIN etc.)"
                        onChange={(e) =>
                          this.props.nonIndianPreviousDataStoe(
                            "org_idnum1",
                            e.target.value
                          )
                        }
                        value={this.props.nonindiankycdata1.org_idnum1}
                      />
                    )}
                  </Form.Item>

                  <Form.Item>
                    {getFieldDecorator("org_idnum", {
                      rules: [
                        {
                          required: true,
                          message: "Please upload Business Identity document ",
                        },
                      ],
                    })(
                      <div className="ant-row ant-form-item">
                        <div className="ant-col ant-form-item-label upload-label">
                          <label className="ant-form-item-required">
                            Business Identification Document (webp, jpeg jpg,
                            png, pdf)
                          </label>
                        </div>
                        <Dragger
                          accept=".webp,.jpeg,.jpg,.png,.pdf"
                          fileList={
                            this.props.files.org_id_doc
                              ? this.props.files.org_id_doc
                              : []
                          }
                          action={
                            process.env.REACT_APP_API_URL +
                            "/user/upload_kyc_doc"
                          }
                          withCredentials="true"
                          onChange={({ fileList }) =>
                            this.props.onFileUpload("org_id_doc", fileList)
                          }
                        >
                          <p className="ant-upload-drag-icon">
                            <Icon type="upload" /> Upload Documents (Drop files
                            here or Click to Upload)
                          </p>
                        </Dragger>

                        <p className="aadhar-card-name">
                          {" "}
                          {this.props.files.org_id_doc &&
                          this.props.files.org_id_doc &&
                          this.props.files.org_id_doc.length &&
                          this.props.files.org_id_doc[0].originFileObj
                            ? this.props.files.org_id_doc[0].originFileObj.name
                            : null}{" "}
                        </p>
                        {this.props.files.org_id_doc &&
                        Object.keys(this.props.files.org_id_doc).length > 0 ? (
                          <button
                            onClick={() =>
                              this.props.onremoveorgidcard("org_id_doc")
                            }
                          >
                            X
                          </button>
                        ) : null}
                      </div>
                    )}
                    <p className="etc-txt2">
                      (Driver’s licence, Passport, SSN etc)
                    </p>
                  </Form.Item>
                  <div className="authorized-signatory">
                    <Form.Item
                      label="Authorized Signatory’s ID Number"
                      className="authorized_signatory"
                    >
                      {getFieldDecorator("org_authidnum1", {
                        initialValue: this.props.nonindiankycdata1
                          .org_authidnum1,
                        rules: [
                          {
                            required: true,
                            message:
                              "Enter Authorized Signatory’s Identity Number (SSN etc.)",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Enter Authorized Signatory’s Identity Number (SSN etc.)"
                          onChange={(e) =>
                            this.props.nonIndianPreviousDataStoe(
                              "org_authidnum1",
                              e.target.value
                            )
                          }
                          value={this.props.nonindiankycdata1.org_authidnum1}
                        />
                      )}
                    </Form.Item>
                  </div>

                  <Form.Item>
                    {getFieldDecorator("org_authidnum", {
                      rules: [
                        {
                          required: true,
                          message:
                            " please uplaod Authorized Signatory’s Identity Card  document ",
                        },
                      ],
                    })(
                      <div
                        className="ant-row ant-form-item "
                        style={{ marginBottom: "-16px" }}
                      >
                        <div className="ant-col ant-form-item-label upload-label">
                          <label className="ant-form-item-required">
                            Authorized Signatory’s Identity Card ( webp,
                            jpeg, jpg, png, pdf )
                          </label>
                        </div>
                        <Dragger
                          accept=".webp,.jpeg,.jpg,.png,.pdf"
                          fileList={
                            this.props.files.signatory_id_card
                              ? this.props.files.signatory_id_card
                              : []
                          }
                          action={
                            process.env.REACT_APP_API_URL +
                            "/user/upload_kyc_doc"
                          }
                          withCredentials="true"
                          onChange={({ fileList }) =>
                            this.props.onFileUpload(
                              "signatory_id_card",
                              fileList
                            )
                          }
                        >
                          <p className="ant-upload-drag-icon">
                            <Icon type="upload" /> Upload Documents (Drop files
                            here or Click to Upload)
                          </p>
                        </Dragger>

                        <p className="aadhar-card-name">
                          {" "}
                          {this.props.files.signatory_id_card &&
                          this.props.files.signatory_id_card &&
                          this.props.files.signatory_id_card.length &&
                          this.props.files.signatory_id_card[0].originFileObj
                            ? this.props.files.signatory_id_card[0]
                                .originFileObj.name
                            : null}{" "}
                        </p>

                        {this.props.files.signatory_id_card &&
                        Object.keys(this.props.files.signatory_id_card).length >
                          0 ? (
                          <button
                            onClick={() =>
                              this.props.onremovesignatureidcard(
                                "signatory_id_card"
                              )
                            }
                          >
                            X
                          </button>
                        ) : null}
                      </div>
                    )}
                    <p className="etc-txt4">
                      (Driver’s licence, Passport, SSN etc)
                    </p>
                  </Form.Item>
                </>
              )}

              {this.props.kycType === "Individual" && (
                <>
                  <div className="phone-field-wraper custom">
                    {this.props.verifiedcontact ? (
                      <p className="already-verify-email">
                        <span className="save-ican">
                          <Icon component={rightican} />
                        </span>
                        Already verified
                      </p>
                    ) : this.props.phoneverified === 1 ? (
                      <div className="verified-div-non-ind">
                        {" "}
                        <Icon component={GreenTick} />
                      </div>
                    ) : (
                      <Button
                        loading={this.props.numberOtpLoading}
                        className={
                          this.props.supportNumber2 &&
                          !!this.props.supportNumber2.length &&
                          this.props.supportNumber2.length >= 8
                            ? "verify-email2 anchor clickable"
                            : "verify-email anchor clickable"
                        }
                        onClick={() => this.props.VerifyPhone()}
                      >
                        Verify
                      </Button>
                    )}

                    {/* <span className="verifyphone-tooltip">
                      {" "}
                      <GlobalTooltipIcon tooltipText="Allowed phone number range is 10-12 digits" />
                    </span> */}

                    <Form.Item
                      label={
                        <span className="label-phone-no">
                          Support Phone Number
                        </span>
                      }
                      className="input-phone-txt phone-input-validation"
                    >
                      {getFieldDecorator("support_phone", {
                        // initialValue: this.props.supportNumber,
                        initialValue: this.numberValueHandler(
                          this.props.prefix_1,
                          this.props.supportNumber2
                        ),
                        rules: [
                          {
                            max: 14,
                            required: false,
                            message: "Please enter valid phone number!",
                            min: 8,
                          },
                        ],
                      })(
                        <ReactPhoneInput
                          placeholder="Enter phone number"
                          enableSearchField
                          excludeCountries={["in"]}
                          disabled={
                            this.props.phoneverified === 1 ? true : false
                          }
                          value={this.numberValueHandler(
                            this.props.prefix_1,
                            this.props.supportNumber2
                          )}
                          onChange={(val, extra) =>
                            this.props.handleSupportPhoneChange({
                              value: val,
                              extraVal: extra,
                              field: "contactno",
                            })
                          }
                        />
                        // </>
                      )}
                    </Form.Item>
                  </div>

                  <div className="email-field-wraper">
                    {this.props.verifiedmsg ? (
                      <p className="already-verify-email">
                        <span className="save-ican">
                          <Icon component={rightican} />
                        </span>
                        Already verified
                      </p>
                    ) : this.props.emailverified === 1 ? (
                      <div className="verified-div-non-ind">
                        {" "}
                        <Icon component={GreenTick} />
                      </div>
                    ) : (
                      <Button
                        loading={this.props.emailOtpLoading}
                        className={
                          !!this.props.supportEmail2.length
                            ? "verify-phone2 anchor clickable"
                            : "verify-phone anchor clickable"
                        }
                        onClick={() => this.props.VerifyEmail()}
                      >
                        Verify
                      </Button>
                    )}

                    <Form.Item label="Support Email Address">
                      {getFieldDecorator("email", {
                        initialValue: this.props.supportEmail2,
                        rules: [
                          {
                            required: false,
                            message: "Please Enter support email address!",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Enter support email address"
                          onChange={(e) =>
                            this.props.handleSupportEmailChange(
                              "EmailInpute",
                              e.target.value
                            )
                          }
                          disabled={
                            this.props.emailverified === 1 ? true : false
                          }
                        />
                      )}
                    </Form.Item>
                  </div>
                  <Form.Item label="Identity Proof Id">
                    {getFieldDecorator("id_num", {
                      initialValue: this.props.nonindiankycdata1.id_num,
                      rules: [
                        {
                          required: true,
                          message: "Add your Identity Number!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Add your Identity Number"
                        onChange={(e) =>
                          this.props.nonIndianPreviousDataStoe(
                            "id_num",
                            e.target.value
                          )
                        }
                        value={this.props.nonindiankycdata1.id_num}
                      />
                    )}
                  </Form.Item>

                  <Form.Item className="non-india-upload-file">
                    {getFieldDecorator("upload_id_num", {
                      rules: [
                        {
                          required: true,
                          message: "Please upload indenty crad!",
                        },
                      ],
                    })(
                      <div className="ant-row ant-form-item">
                        <div className="ant-col ant-form-item-label upload-label">
                          <label className="ant-form-item-required">
                            Identity Card(webp, jpeg, jpg, png, pdf)
                          </label>
                        </div>
                        <Dragger
                          accept=".webp,.jpeg,.jpg,.png,.pdf"
                          fileList={
                            this.props.files.id_card
                              ? this.props.files.id_card
                              : []
                          }
                          action={
                            process.env.REACT_APP_API_URL +
                            "/user/upload_kyc_doc"
                          }
                          withCredentials="true"
                          onChange={({ fileList }) =>
                            this.props.onFileUpload("id_card", fileList)
                          }
                        >
                          <p className="ant-upload-drag-icon">
                            <Icon type="upload" /> Upload Documents (Drop files
                            here or Click to Upload)
                          </p>
                        </Dragger>

                        <p className="aadhar-card-name">
                          {" "}
                          {this.props.files.id_card &&
                          this.props.files.id_card &&
                          this.props.files.id_card.length &&
                          this.props.files.id_card[0].originFileObj
                            ? this.props.files.id_card[0].originFileObj.name
                            : null}{" "}
                        </p>

                        {this.props.files.id_card &&
                        Object.keys(this.props.files.id_card).length > 0 ? (
                          <button
                            onClick={() =>
                              this.props.removeIdentityCard("id_card")
                            }
                          >
                            X
                          </button>
                        ) : null}
                      </div>
                    )}
                  </Form.Item>
                  <p className="etc-txt">
                    (Driver’s licence, Passport, SSN etc)
                  </p>

                  {/* <Form.Item
                    label="Other Payment Info"
                    className="otherPayment"
                  >
                    {getFieldDecorator("payment_info", {
                      rules: [
                        {
                          required: true,
                          message: "Please Select payment!",
                        },
                      ],
                    })(
                      <Select
                        placeholder="Select Other Payment Info"
                        showSearch
                        value={this.state.paymentinfo}
                        onChange={(e) => this.selectPaymentinfo(e)}
                        className="payment-select"
                        onDropdownVisibleChange={() => this.cityHandler()}
                        suffixIcon={
                          <svg
                            style={{
                              transition: "all 0.5s",
                              transform: `${
                                this.state.arrow
                                  ? "rotateX(180deg)"
                                  : "rotateX(0deg)"
                              }`,
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="8"
                            viewBox="0 0 16 8"
                          >
                            <g
                              id="Polygon_33"
                              data-name="Polygon 33"
                              transform="translate(16 8) rotate(180)"
                              fill={`${this.state.arrow ? "898989" : "none"}`}
                            >
                              <path d="M8,0l8,8H0Z" stroke="none" />
                              <path
                                d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                                stroke="none"
                                fill="#898989"
                              />
                            </g>
                          </svg>
                        }
                      >
                        <Option value=" Whatsapp" className="commondropdown">
                          Whatsapp
                        </Option>
                        <Option value="UPI" className="commondropdown">
                          UPI
                        </Option>

                        <Option
                          value="escalPytmated"
                          className="commondropdown"
                        >
                          Pytm
                        </Option>
                      </Select>
                    )}
                  </Form.Item> */}
                </>
              )}
            </div>
          ) : null}
        </Form>
      </>
    );
  }
}

export default Form.create()(Profiledetail);
