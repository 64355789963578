import React, { Component } from "react";
import { Button, Input, Modal, Alert, message } from "antd";
import axios from "axios";

import "./index.scss";

class AliExpressModal extends Component {
  state = {
    productId: "",
    productURL: "",
    error: false,
    errorMsg: "",
    checkInterval: null,
    loading: false,
  };

  verifyScrape(id, scrapeid, scrapeCount) {
    if (scrapeCount <= 0) {
      message.error("There was an error fetching the product");
      this.setState({ loading: false });
      return;
    }
    setTimeout(() => {
      axios({
        url: process.env.REACT_APP_API_URL + "/products/get_aliproduct_scraped",
        method: "post",
        data: {
          scrapeid,
          storeid: this.props.match.params.storeid,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.productId !== undefined) {
            const productId = res.data.productId;
            axios({
              url:
                process.env.REACT_APP_API_URL +
                "/products/link_product_import_list",
              method: "post",
              data: {
                storeid: this.props.match.params.storeid,
                product_mongoid: this.props.linkProductModalId,
                product_aliid: res.data.productId,
              },
              withCredentials: true,
            })
              .then((res) => {
                if (res.data.success === 1) {
                  this.props.history.push(
                    `/${
                      this.props.match.params.storeid
                    }/variants/${productId}/${this.props.linkProductModalId}`
                  );
                } else {
                  message.error(res.data.msg);
                }
              })
              .catch((e) => {
                message.error(e.message);
              });
          } else {
            this.verifyScrape(id, scrapeid, --scrapeCount);
          }
        })
        .catch((e) => {
          this.verifyScrape(id, scrapeid, --scrapeCount);
        });
    }, 3000);
  }

  handleOk = () => {
    let ids = [];
    if (this.state.productId.trim()) {
      ids.push(this.state.productId);
    }
    if (this.state.productURL.trim()) {
      ids.push(this.state.productURL);
    }
    if (ids.length) {
      const productId = ids[0];
      this.setState({
        error: false,
        errorMsg: "",
      });
      axios({
        url:
          process.env.REACT_APP_API_URL + "/products/link_product_aliexpress",
        method: "post",
        data: {
          storeid: this.props.match.params.storeid,
          productId: productId,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.setState({ loading: true }, () =>
              this.verifyScrape(productId, res.data.scrapeid, 8)
            );
          } else {
            message.error(res.data.msg);
          }
        })
        .catch((e) => {
          message.error(e.message);
        });
    } else {
      this.setState({
        error: true,
        errorMsg: "Please enter atleast either Product Id or URL",
      });
    }
  };

  singleFieldChange = (k, v) => {
    this.setState({ [k]: v });
  };

  resetModal = () => {
    this.setState({
      productId: "",
      productURL: "",
    });
  };

  render() {
    const { productId, productURL, error, errorMsg } = this.state;

    return (
      <Modal
        className="link-product-ali-express-modal"
        title="Add Product from AliExpress"
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        afterClose={this.resetModal}
        maskClosable={false}
        closable={!this.state.loading}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={this.handleOk}
            loading={this.state.loading}
            className="next-button"
          >
            {this.state.loading ? "Fetching Product Data" : "Next"}
          </Button>,
          <Button
            key="back"
            onClick={this.props.onCancel}
            disabled={this.state.loading}
            className="cancle-button"
          >
            Cancel
          </Button>,
        ]}
      >
        <div>
          <div>
            <p className="input-label">Product ID</p>
            <Input
              placeholder="Enter the ID of the product you want to import"
              value={productId}
              onChange={(e) =>
                this.singleFieldChange("productId", e.target.value)
              }
            />
            <p style={{ color: "#979797" }}>or</p>
            <p className="input-label">Product URL</p>
            <Input
              placeholder="Enter the URL of the product you want to import"
              value={productURL}
              onChange={(e) =>
                this.singleFieldChange("productURL", e.target.value)
              }
            />
          </div>
          {error && (
            <Alert
              message={errorMsg}
              type="error"
              showIcon
              style={{ marginTop: "15px" }}
            />
          )}
        </div>
      </Modal>
    );
  }
}

export default AliExpressModal;
