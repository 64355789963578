import React, { Component } from "react";
import { Tabs, Button } from "antd";
import "./index.scss";
import SignupSection from "./SignupSection";
import OtpVerifyContainer from "./OtpVerifyContainer";
const { TabPane } = Tabs;
class RightContainer extends Component {
  state = {
    activeKey: "1",
    email: "",
    password: "",
  };

  componentDidMount() {
    window.location.assign(process.env.REACT_APP_NEW_APP + "/signup");
  }
  render() {
    return (
      <div className="right-container-signup">
        <Tabs activeKey={this.state.activeKey}>
          <TabPane key="1">
            <SignupSection
              changeTab={() => {
                this.setState({
                  activeKey: "2",
                });
              }}
              getCredentials={(email, password) => {
                this.setState({
                  email: email,
                  password: password,
                });
              }}
            />
            {/* <Button onClick={() => {}}>click</Button> */}
          </TabPane>
          <TabPane key="2">
            <OtpVerifyContainer
              changeTab={() => {
                this.setState({
                  activeKey: "1",
                });
              }}
              activeKey={this.state.activeKey}
              email={this.state.email}
              password={this.state.password}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default RightContainer;
