import React from "react";
import { Table, Row, Tooltip } from "antd";
import {
  getCurrencySymbol,
  formatNumber,
} from "../../../../../Helpers/Pricing";

const CreditNote = (props) => {
  const transaction = props.transaction;
  let items = [];

  if (transaction) {
    items.push({ ...transaction.transaction_data });
  }

  const reviewColumns = [
    {
      title: "vFulfill Order ID",
      dataIndex: "vforderid",
      width: "9%",
    },
    {
      title: "Order Name",
      dataIndex: "items[0].orderid",
      width: "6.33%",
    },
    {
      title: "Order Details",
      dataIndex: "items",
      width: "40.5%",
      className: "products-list",
      render: (items) =>
        items.map((item) => (
          <Row
            key={item.lineitem_id}
            style={{ display: "flex", alignItems: "start" }}
          >
            <img src={item.img} alt="" style={{ width: 50 }} />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: 10,
              }}
            >
              <p
                style={{
                  flex: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: 215,
                  marginBottom: 5,
                }}
              >
                <Tooltip title={item.name} placement="topLeft">
                  {item.name}
                </Tooltip>
              </p>

              <p style={{ flex: 1, margin: 0, marginBottom: 5 }}>
                <Tooltip title="vFulfill Selling Price">
                  <span style={{ background: "#f0f2f5", padding: "0 5px" }}>
                    {getCurrencySymbol("INR")} {formatNumber(item.item_mrp)} X{" "}
                    {item.quantity}
                  </span>
                </Tooltip>
              </p>
              <p>
                <span style={{ background: "#f0f2f5", padding: "0 5px" }}>
                  SKU: {item.sku}
                </span>
              </p>
            </div>
          </Row>
        )),
    },
    {
      title: "Total Product Price",
      dataIndex: "costs.product_total",
      width: "12.5%",
      render: (text) => (
        <p className="blue-text">
          {getCurrencySymbol("INR")} {formatNumber(Number(text).toFixed(2))}
        </p>
      ),
    },
    {
      title: "Forward Shipping Fee",
      dataIndex: "costs.shipping.total",
      width: "12.5%",
      render: (text) => (
        <p className="blue-text">
          {getCurrencySymbol("INR")} {formatNumber(Number(text).toFixed(2))}
        </p>
      ),
    },
    {
      title: "COD Fee",
      key: "fee",
      dataIndex: "costs",
      width: "8.33%",
      render: (costs) => {
        let text = costs.cod && costs.cod.total ? costs.cod.total : 0;
        return (
          <p className="blue-text">
            {Number(text) === 0
              ? "N.A."
              : `${getCurrencySymbol("INR")} ${formatNumber(
                  Number(text).toFixed(2)
                )}`}
          </p>
        );
      },
    },
    {
      title: "Total",
      key: "total",
      dataIndex: "costs",
      width: "11.34%",
      render: (costs) => (
        <p className="blue-text">
          {getCurrencySymbol("INR")}{" "}
          {formatNumber(
            Number(costs.product_total) +
              Number(costs.shipping.total) +
              Number(costs.cod && costs.cod.total ? costs.cod.total : 0)
          )}
        </p>
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey={(record) => `${record["orderid"]}_${record["lineitem_id"]}`}
        pagination={false}
        columns={reviewColumns}
        dataSource={items}
        scroll={{ y: 300 }}
      />
      {transaction && (
        <div
          style={{
            display: "table",
            width: "100%",
            padding: "10px 0",
            boxShadow: "0 -50px 50px rgba(0,0,0,0.25)",
            marginTop: "2px",
          }}
        >
          <div
            style={{
              width: "15.33%",
              display: "inline-block",
              padding: "4px 16px",
            }}
          />
          <div
            style={{
              width: "40.5%",
              display: "inline-block",
              padding: "4px 16px 4px 78px",
            }}
          >
            <h3 style={{ margin: 0 }}>Total</h3>
          </div>
          <div
            style={{
              width: "12.5%",
              display: "inline-block",
              padding: "4px 16px",
            }}
          >
            {getCurrencySymbol("INR")}{" "}
            {formatNumber(transaction.transaction_data.costs.product_total)}
          </div>
          <div
            style={{
              width: "12.5%",
              display: "inline-block",
              padding: "4px 16px",
            }}
          >
            {getCurrencySymbol("INR")}{" "}
            {formatNumber(transaction.transaction_data.costs.shipping.total)}
          </div>
          <div
            style={{
              width: "8.33%",
              display: "inline-block",
              padding: "4px 16px",
            }}
          >
            {getCurrencySymbol("INR")}{" "}
            {formatNumber(transaction.transaction_data.costs.cod.total)}
          </div>
          <div
            style={{
              width: "10.34%",
              display: "inline-block",
              padding: "4px 16px",
              fontSize: 16,
            }}
          >
            <b>
              {getCurrencySymbol("INR")}{" "}
              {formatNumber(transaction.transaction_amount, 2)}
            </b>
          </div>
        </div>
      )}
    </>
  );
};

export default CreditNote;
