import React from "react";
import "./index.scss";
import { Icon, Tooltip, Popover } from "antd";
import { ReactComponent as TooltipIconWhite } from "../../../../assets/images/icons/TooltipIconWhite.svg";

export default function GlobalTooltipIcon(props) {
  const tooltipText = props.tooltipText;

  if (props.custom) {
    return (
      <Popover
        className="eed-logs"
        title={<div className="heading-eddlogs">Inventory Projection</div>}
        placement="bottom"
        content={
          <div className="edd-test custom">
            <p className="edd-log-text">
              <div className="item">
                <span className="heading">Projected Inventory :</span>
                <span>
                  {" "}
                  {props.data.projected_inventory
                    ? props.data.projected_inventory + " days"
                    : "0 days"}
                </span>
              </div>
              <div className="item">
                <span className="heading">Restocking Time :</span>{" "}
                <span>
                  {" "}
                  {props.data.restocking_time
                    ? props.data.restocking_time + " days"
                    : "0 days"}
                </span>
              </div>
            </p>
          </div>
        }
      >
        {" "}
        <Icon
          component={TooltipIconWhite}
          className="global-tooltip-icon-w"
          name="tooltip"
        />
      </Popover>
    );
  }

  return (
    <span className="global-tooltip-w">
      <Tooltip title={<p>{tooltipText}</p>}>
        <Icon
          component={TooltipIconWhite}
          className="global-tooltip-icon-w"
          name="tooltip"
        />
      </Tooltip>
    </span>
  );
}
