import React, { Component } from "react";
import { Button, Modal, Select, Spin, Tooltip } from "antd";
import axios from "axios";
import "./index.scss";

const { Option } = Select;

class ViewlinkImportListModal extends Component {
  state = {
    products: [],
    productId: null,
  };

  handleChange(value) {
    this.setState({
      productId: value,
    });
  }

  componentDidMount() {
    this.fetchProducts();
  }

  fetchProducts(value) {
    const that = this;
    this.setState(
      {
        products: [],
        fetching: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/products/get_linked_product_products_search",
          method: "post",
          data: {
            storeid: this.props.storeid,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              that.setState({
                products: res.data.data ? res.data.data : [],
                fetching: false,
                productId: value,
              });
            }
          })
          .catch((e) => {
            that.setState({
              products: [],
              fetching: false,
            });
          });
      }
    );
  }

  render() {
    const { visible, onCancel } = this.props;

    return (
      <Modal
        className="link-product-import-list-modal"
        title={
          <div>
            <p className="link-import-list">
              Link the Product from Import List
            </p>
            <p className="existing-product-txt">Select an existing product</p>
          </div>
        }
        visible={visible}
        onCancel={onCancel}
        destroyOnClose={true}
        footer={[
          <Button
            key="submit"
            loading={this.props.loading}
            type="primary"
            onClick={() => this.props.onConfirm()}
            className={
              this.state.productId === undefined
                ? "next-button2"
                : "next-button"
            }
            disabled={this.state.productId === undefined ? true : false}
          >
            Next
          </Button>,
          <Button key="back" onClick={onCancel} className="cancle-button">
            Cancel
          </Button>,
        ]}
      >
        <div className="link-import-list-modal">
          <div className="existing-product">
            <p className="input-label">Existing Product</p>
            {this.props.sv && (
              <div className="image-with-txt">
                <img
                  src={
                    this.props.sv.gallery && this.props.sv.gallery[0]
                      ? this.props.sv.gallery[0]
                      : null
                  }
                  alt="#"
                />
                <p className="image-product-name">
                  {this.props.product &&
                  !!this.props.product.name &&
                  this.props.product.name.length > 35 ? (
                    <Tooltip title={this.props.product.name}>
                      {this.props.product && this.props.product.name
                        ? `${this.props.product.name.substring(0, 35)}...`
                        : "Product Name not Available"}
                    </Tooltip>
                  ) : (
                    <span>
                      {this.props.product &&
                      !!this.props.product.name &&
                      this.props.product.name
                        ? this.props.product.name
                        : "Product Name not Available"}
                    </span>
                  )}
                </p>
              </div>
            )}
          </div>

          <div className="link-product">
            <p className="input-label">Link With</p>
            <Select
              showSearch
              style={{ width: "100%", borderRadius: "0px" }}
              placeholder="Select a product"
              optionFilterProp="children"
              dropdownClassName="option-varaint-data"
              onChange={(value) => this.handleChange(value)}
              notFoundContent={
                this.state.fetching ? <Spin size="small" /> : null
              }
              size="large"
              filterOption={(input, option) =>
                option.props.children[1].props.children[1].props.title
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onDropdownVisibleChange={(val) => this.setState({ arrow1: val })}
              suffixIcon={
                <svg
                  style={{
                    transition: "all 0.5s",
                    transform: `${
                      this.state.arrow1 ? "rotateX(180deg)" : "rotateX(0deg)"
                    }`,
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="8"
                  viewBox="0 0 16 8"
                >
                  <g
                    id="Polygon_49"
                    data-name="Polygon 49"
                    transform="translate(16 8) rotate(180)"
                    fill={`${this.state.arrow1 ? "#898989" : "none"}`}
                  >
                    <path d="M8,0l8,8H0Z" stroke="none" />
                    <path
                      d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                      stroke="none"
                      fill="#898989"
                    />
                  </g>
                </svg>
              }
            >
              {this.state.products.map((p) => (
                <Option key={p.product_aliid} value={p.product_aliid}>
                  <img
                    src={p && p.gallery ? p.gallery[0] : null}
                    style={{
                      verticalAlign: "middle",
                      border: "1px solid #898989",
                      padding: "4px",
                      width: "40px",
                      height: "40px",
                    }}
                    alt="#"
                    className="option-image"
                  />
                  <span style={{ marginLeft: "8px" }}>
                    {" "}
                    <Tooltip title={p.name}>
                      {p.name
                        ? `${p.name.substring(0, 40)}...`
                        : " Name not Available"}
                    </Tooltip>
                  </span>
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ViewlinkImportListModal;
