import React from "react";
import "./index.scss";
import { Button, Modal, Checkbox, Input } from "antd";

class TransactionHeadsModal extends React.Component {
  state = {
    other: [],
    product: [],
    remittance: [],
    service: [],
    wallet_transactions: [],
    heads: [],
    searchText: "",
  };

  heads = [];

  componentDidMount() {
    if (this.props.storegeo === "global") {
      let headsArr = [
        {
          value: "cashback_addfunds",
          label: "Add Funds - Cashback",
          category: "wallet_transactions",
        },
        {
          value: "funds_added_admin",
          label: "Add Funds - Manual",
          category: "wallet_transactions",
        },
        {
          value: "razorpay_addfunds",
          label: "Add Funds - Razorpay",
          category: "wallet_transactions",
        },
        {
          value: "stripe_addfunds",
          label: "Add Funds - Stripe",
          category: "wallet_transactions",
        },

        {
          value: "order_placed_ff",
          label: "Fulfilment Fees",
          category: "service",
        },

        {
          value: "coupon_addfunds",
          label: "Coupon Funds",
          category: "wallet_transactions",
        },
        {
          value: "credit_note",
          label: "Credit Note",
          category: "wallet_transactions",
        },
        {
          value: "order_placed_fs",
          label: "Forward Shipping",
          category: "service",
        },
        {
          value: "buyback_handling_fee",
          label: "BuyBack Handling Fee",
          category: "service",
        },
        {
          value: "credit_note_ff",
          label: "Fulfilment Fee Credit",
          category: "service",
        },
        {
          value: "funds_added",
          label: "Fund Addition",
          category: "wallet_transactions",
        },
        {
          value: "withdraw_funds",
          label: "Fund Withdrawal",
          category: "wallet_transactions",
        },
        {
          value: "inventory_rtv",
          label: "Inventory RTV",
          category: "service",
        },
        {
          value: "inward_fees",
          label: "Inward Fees",
          category: "service",
        },
        {
          value: "management_fee",
          label: "Tax Management Fee",
          category: "service",
        },
        {
          value: "management_fee_reverse",
          label: "Tax Management Fee Reverse",
          category: "service",
        },
        {
          value: "order_confirmation_management_fee",
          label: "Order Management Fee",
          category: "service",
        },
        {
          value: "orderamount_gst_deductions_reverse",
          label: "Order Amount Gst Return",
          category: "remittance",
        },
        {
          value: "order_cancellation_fee",
          label: "Order Cancellation Fee",
          category: "service",
        },
        {
          value: "order_refund",
          label: "Order Refund",
          category: "other",
        },
        {
          value: "payment_gatway_fee",
          label: "Payment Gateway Fee",
          category: "service",
        },
        {
          value: "prepaid_remittance",
          label: "Prepaid Remittance",
          category: "remittance",
        },
        {
          value: "prepaid_remittance_reverse",
          label: "Prepaid Remittance Reverse",
          category: "remittance",
        },
        {
          value: "order_placed_pp",
          label: "Product Price",
          category: "product",
        },
        {
          value: "credit_note_pp",
          label: "Product Price Credit",
          category: "product",
        },

        {
          value: "orderamount_gst_deductions",
          label: "Remittance GST Deductions",
          category: "remittance",
        },
        {
          value: "order_reverse_pickup",
          label: "Reverse Pick Up",
          category: "service",
        },
        {
          value: "credit_note_fs",
          label: "Shipping Charges Credit",
          category: "service",
        },

        {
          value: "client_sourcing_request",
          label: "Sourcing Request",
          category: "product",
        },
        {
          value: "storage_charges",
          label: "Storage Charges",
          category: "service",
        },
        {
          value: "admin_note",
          label: "System Transaction",
          category: "wallet_transactions",
        },
        {
          value: "order_placed",
          label: "vF Cost",
          category: "other",
        },
        {
          value: "wire_addfunds",
          label: "Wire Add Funds",
          category: "wallet_transactions",
        },
      ];
      this.heads = headsArr;
      this.setState({ ...this.state, heads: headsArr });
    } else {
      let headsArr = [
        {
          value: "cashback_addfunds",
          label: "Add Funds - Cashback",
          category: "wallet_transactions",
        },
        {
          value: "wingman_charges",
          label: "Wingman Charges",
          category: "wallet_transactions",
        },
        {
          value: "client_sourcing_request_reverse",
          label: "Client Sourcing Request Reverse",
          category: "product",
        },
        {
          value: "inward_fees_reverse",
          label: "Inward Fees Reverse",
          category: "service",
        },
        {
          value: "funds_added_admin",
          label: "Add Funds - Manual",
          category: "wallet_transactions",
        },
        {
          value: "razorpay_addfunds",
          label: "Add Funds - Razorpay",
          category: "wallet_transactions",
        },
        {
          value: "stripe_addfunds",
          label: "Add Funds - Stripe",
          category: "wallet_transactions",
        },
        {
          value: "buyback_handling_fee",
          label: "BuyBack Handling Fee",
          category: "service",
        },
        {
          value: "order_placed_codf",
          label: "COD Fees",
          category: "service",
        },

        {
          value: "cod_remit_new",
          label: "COD Remittance",
          category: "remittance",
        },
        {
          value: "cod_remit_reverse",
          label: "COD Remittance Reverse",
          category: "remittance",
        },
        {
          value: "coupon_addfunds",
          label: "Coupon Funds",
          category: "wallet_transactions",
        },
        {
          value: "credit_note",
          label: "Credit Note",
          category: "wallet_transactions",
        },
        {
          value: "order_placed_fs",
          label: "Forward Shipping",
          category: "service",
        },
        {
          value: "order_placed_ff",
          label: "Fulfilment Fees",
          category: "service",
        },
        {
          value: "credit_note_ff",
          label: "Fulfilment Fee Credit",
          category: "service",
        },
        // new 5

        {
          value: "platform_fee_fixed",
          label: "Fixed Convenience Fee",
          category: "service",
        },
        {
          value: "order_confirmation_management_fee",
          label: "Order Management Fee",
          category: "service",
        },
        {
          value: "credit_note_platform_fee_fixed",
          label: "Fixed Convenience Fee Reverse",
          category: "service",
        },
        {
          value: "platform_fee_percentage",
          label: "Percentage Convenience Fee",
          category: "service",
        },
        {
          value: "credit_note_platform_fee_percentage",
          label: "Percentage Convenience Fee Reverse",
          category: "service",
        },
        // {
        //   value: "platform_fee_percentage_reverse",
        //   label: "Reverse Convenience Fee",
        //   category: "service",
        // },

        // end
        {
          value: "funds_added",
          label: "Fund Addition",
          category: "wallet_transactions",
        },
        {
          value: "withdraw_funds",
          label: "Fund Withdrawal",
          category: "wallet_transactions",
        },
        {
          value: "inventory_rtv",
          label: "Inventory RTV",
          category: "service",
        },
        {
          value: "inward_fees",
          label: "Inward Fees",
          category: "service",
        },
        {
          value: "management_fee",
          label: "Tax Management Fee",
          category: "service",
        },
        {
          value: "management_fee_reverse",
          label: "Tax Management Fee Reverse",
          category: "service",
        },
        {
          value: "orderamount_gst_deductions_reverse",
          label: "Order Amount GST Return",
          category: "remittance",
        },
        {
          value: "order_cancellation_fee",
          label: "Order Cancellation Fee",
          category: "service",
        },
        {
          value: "order_refund",
          label: "Order Refund",
          category: "other",
        },
        {
          value: "payment_gatway_fee",
          label: "Payment Gateway Fee",
          category: "service",
        },
        {
          value: "prepaid_remittance",
          label: "Prepaid Remittance",
          category: "remittance",
        },
        {
          value: "prepaid_remittance_reverse",
          label: "Prepaid Remittance Reverse",
          category: "remittance",
        },
        {
          value: "order_placed_pp",
          label: "Product Price",
          category: "product",
        },
        {
          value: "credit_note_pp",
          label: "Product Price Credit",
          category: "product",
        },
        {
          value: "orderamount_gst_deductions",
          label: "Remittance GST Deductions",
          category: "remittance",
        },
        {
          value: "codf_reverse",
          label: "Reverse COD Fees",
          category: "service",
        },
        {
          value: "order_reverse_pickup",
          label: "Reverse Pick Up",
          category: "service",
        },

        {
          value: "rto_remit",
          label: "RTO Charges",
          category: "other",
        },
        {
          value: "rto_handling_fee",
          label: "RTO Handling Fee",
          category: "service",
        },
        {
          value: "rto_shipping",
          label: "RTO Shipping",
          category: "service",
        },
        {
          value: "credit_note_fs",
          label: "Shipping Charges credit",
          category: "service",
        },
        {
          value: "client_sourcing_request",
          label: "Sourcing Request",
          category: "product",
        },
        {
          value: "storage_charges",
          label: "Storage Charges",
          category: "service",
        },
        {
          value: "admin_note",
          label: "System Transaction",
          category: "wallet_transactions",
        },
        {
          value: "verified_order_cancellation",
          label: "Verified Order Cancellation Fee",
          category: "service",
        },
        {
          value: "order_placed",
          label: "vF Cost",
          category: "other",
        },
        {
          value: "wire_addfunds",
          label: "Wire Add Funds",
          category: "wallet_transactions",
        },
      ];
      this.heads = headsArr;

      this.setState({ ...this.state, heads: headsArr });
    }
  }

  removeModalTick(d) {
    let stateHeadsObj = {
      other: this.state.other,
      product: this.state.product,
      remittance: this.state.remittance,
      service: this.state.service,
      wallet_transactions: this.state.wallet_transactions,
    };

    stateHeadsObj = JSON.parse(JSON.stringify(stateHeadsObj));

    for (let key in stateHeadsObj) {
      // code block to be executed

      if (stateHeadsObj[key].includes(d)) {
        stateHeadsObj[key] = stateHeadsObj[key].filter((t) => t !== d);
      }
    }

    this.setState({ ...this.state, ...stateHeadsObj });
  }

  dataForFilter() {
    this.props.handler([
      ...this.state.other,
      ...this.state.product,
      ...this.state.remittance,
      ...this.state.service,
      ...this.state.wallet_transactions,
    ]);
    this.props.toggleModal();
  }

  // single checkbox handler
  selectHandler(obj) {
    let checked = obj.value;

    // for service selection
    if (obj.category === "service") {
      let arr = this.state.service;
      !!checked
        ? arr.push(obj.head)
        : (arr = arr.filter((e) => e !== obj.head));
      this.setState({ service: arr });
    }

    // for wallet_transactions selection
    if (obj.category === "wallet_transactions") {
      let arr = this.state.wallet_transactions;
      !!checked
        ? arr.push(obj.head)
        : (arr = arr.filter((e) => e !== obj.head));
      this.setState({ wallet_transactions: arr });
    }

    // for remittance selection
    if (obj.category === "remittance") {
      let arr = this.state.remittance;
      !!checked
        ? arr.push(obj.head)
        : (arr = arr.filter((e) => e !== obj.head));
      this.setState({ remittance: arr });
    }

    // for product selection
    if (obj.category === "product") {
      let arr = this.state.product;
      !!checked
        ? arr.push(obj.head)
        : (arr = arr.filter((e) => e !== obj.head));
      this.setState({ product: arr });
    }

    // for other selection
    if (obj.category === "other") {
      let arr = this.state.other;
      !!checked
        ? arr.push(obj.head)
        : (arr = arr.filter((e) => e !== obj.head));
      this.setState({ other: arr });
    }
  }

  // Select Al checkbox handler
  selectAllHandler(obj) {
    let checked = obj.value;

    // for service selection
    if (obj.category === "service") {
      let filterData = this.state.heads
        .filter((v) => v.category === "service")
        .map((e) => e.value);
      this.setState({ service: !!checked ? filterData : [] });
    }

    // for wallet_transactions selection
    if (obj.category === "wallet_transactions") {
      let filterData = this.state.heads
        .filter((v) => v.category === "wallet_transactions")
        .map((e) => e.value);
      this.setState({ wallet_transactions: !!checked ? filterData : [] });
    }

    // for remittance selection
    if (obj.category === "remittance") {
      let filterData = this.state.heads
        .filter((v) => v.category === "remittance")
        .map((e) => e.value);
      this.setState({ remittance: !!checked ? filterData : [] });
    }

    // for product selection
    if (obj.category === "product") {
      let filterData = this.state.heads
        .filter((v) => v.category === "product")
        .map((e) => e.value);
      this.setState({ product: !!checked ? filterData : [] });
    }

    // for other selection
    if (obj.category === "other") {
      let filterData = this.state.heads
        .filter((v) => v.category === "other")
        .map((e) => e.value);
      this.setState({ other: !!checked ? filterData : [] });
    }
  }

  searchHandler(searchText) {
    let filter = this.heads.filter((d) => {
      return d.label.toLowerCase().includes(searchText.toLowerCase());
    });

    this.setState({ heads: filter, searchText: searchText });
  }

  checkBoxHandler(cat) {
    let stateObj = new Object(this.state);

    let data = this.state.heads.filter((f) => f.category === cat);

    if (data.length === 0) {
      return <div className="not-found">No keywords match your search.</div>;
    } else {
      return data.map((m) => {
        return (
          <Checkbox
            checked={stateObj[cat].includes(m.value)}
            onChange={(e) =>
              this.selectHandler({
                category: cat,
                value: e.target.checked,
                selectType: "single",
                head: m.value,
              })
            }
          >
            {m.label}
          </Checkbox>
        );
      });
    }
  }

  render() {
    return (
      <>
        <Modal
          className="Transaction-heads-modal"
          title={
            <>
              <h2>Transaction Heads</h2>
              <p>
                Select custom fields to filter transactions and download as
                well.{" "}
              </p>{" "}
              <Input
                className="search-input-transactions"
                type="text"
                value={this.state.searchText}
                placeholder="Search Transaction Heads"
                onChange={(d) => this.searchHandler(d.target.value)}
              />
            </>
          }
          visible={this.props.visible}
          footer={
            <>
              {" "}
              <Button
                onClick={() =>
                  this.setState(
                    {
                      other: [],
                      remittance: [],
                      product: [],
                      service: [],
                      wallet_transactions: [],
                    },
                    () => this.props.cancelHandler()
                  )
                }
                type="cancel"
              >
                Cancel
              </Button>{" "}
              <Button
                onClick={() => this.dataForFilter()}
                type="primary"
                className="clickable-dropdown"
              >
                Confirm
              </Button>{" "}
            </>
          }
          onOk={() => this.props.toggleModal()}
          onCancel={() =>
            this.setState(
              {
                other: [],
                remittance: [],
                product: [],
                service: [],
                wallet_transactions: [],
              },
              () => this.props.cancelHandler()
            )
          }
        >
          {/*Product  */}
          <div className="wraper-first wraper search">
            <div className="select-all-wraper">
              <h1>Product</h1>

              {this.state.searchText.length === 0 ? (
                <div className="first-select-all">
                  <Checkbox
                    checked={this.state.product.length === 4 ? true : false}
                    onChange={(e) =>
                      this.selectAllHandler({
                        category: "product",
                        value: e.target.checked,
                        selectType: "All",
                      })
                    }
                  >
                    {this.state.product.length === 3
                      ? "Deselect All"
                      : "Select All"}
                  </Checkbox>
                </div>
              ) : null}
            </div>

            <div className="single-select-wraper">
              {this.checkBoxHandler("product", this.state.product)}
            </div>
          </div>

          {/* service heads */}
          <div className="wraper-first wraper">
            <div className="select-all-wraper">
              <h1>Service</h1>

              {this.state.searchText.length === 0 ? (
                <div className="first-select-all">
                  <Checkbox
                    checked={
                      this.props.storegeo === "global"
                        ? this.state.service.length === 14
                          ? true
                          : false
                        : this.state.service.length === 24
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      this.selectAllHandler({
                        category: "service",
                        value: e.target.checked,
                        selectType: "All",
                      })
                    }
                  >
                    {this.props.storegeo === "global"
                      ? this.state.service.length === 14
                        ? "Deselect All"
                        : "Select All"
                      : this.state.service.length === 24
                      ? "Deselect All"
                      : "Select All"}
                  </Checkbox>
                </div>
              ) : null}
            </div>

            <div className="single-select-wraper">
              {this.checkBoxHandler("service")}
            </div>
          </div>

          {/* remittance */}

          <div className="wraper-first wraper">
            <div className="select-all-wraper">
              <h1>Remittance</h1>

              {this.state.searchText.length === 0 ? (
                <div className="first-select-all">
                  <Checkbox
                    checked={
                      this.props.storegeo === "global"
                        ? this.state.remittance.length === 4
                          ? true
                          : false
                        : this.state.remittance.length === 6
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      this.selectAllHandler({
                        category: "remittance",
                        value: e.target.checked,
                        selectType: "All",
                      })
                    }
                  >
                    {this.props.storegeo === "global"
                      ? this.state.remittance.length === 4
                        ? "Deselect All"
                        : "Select All"
                      : this.state.remittance.length === 6
                      ? "Deselect All"
                      : "Select All"}
                  </Checkbox>
                </div>
              ) : null}
            </div>

            <div className="single-select-wraper">
              {this.checkBoxHandler("remittance", this.state.remittance)}
            </div>
          </div>

          {/* wallet transaction heads */}
          <div className="wraper-first wraper">
            <div className="select-all-wraper">
              <h1>Wallet Transactions</h1>

              {this.state.searchText.length === 0 ? (
                <div className="first-select-all">
                  <Checkbox
                    checked={
                      this.state.wallet_transactions.length === 11
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      this.selectAllHandler({
                        category: "wallet_transactions",
                        value: e.target.checked,
                        selectType: "All",
                      })
                    }
                  >
                    {this.state.wallet_transactions.length === 11
                      ? "Deselect All"
                      : "Select All"}
                  </Checkbox>
                </div>
              ) : null}
            </div>

            <div className="single-select-wraper">
              {this.checkBoxHandler("wallet_transactions")}
            </div>
          </div>

          {/* Other */}
          <div className="wraper-first wraper">
            <div className="select-all-wraper">
              <h1>Other</h1>

              {this.state.searchText.length === 0 ? (
                <div className="first-select-all">
                  <Checkbox
                    checked={
                      this.props.storegeo === "global"
                        ? this.state.other.length === 2
                          ? true
                          : false
                        : this.state.other.length === 3
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      this.selectAllHandler({
                        category: "other",
                        value: e.target.checked,
                        selectType: "All",
                      })
                    }
                  >
                    {this.props.storegeo === "global"
                      ? this.state.other.length === 2
                        ? "Deselect All"
                        : "Select All"
                      : this.state.other.length === 3
                      ? "Deselect All"
                      : "Select All"}
                  </Checkbox>
                </div>
              ) : null}
            </div>

            <div className="single-select-wraper">
              {this.checkBoxHandler("other")}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default TransactionHeadsModal;
