import React from "react";
import { Link } from "react-router-dom";

import "./index.scss";

class AddToStore extends React.Component {
  render() {
    return (
      <div className="add-to-store ">
        <p
          className="add-store-hover-underline-animation"
          style={{ textAlign: "center" }}
        >
          <Link
            to={`/${this.props.storegeo}/${
              this.props.storeid
            }/products/import-list/?search=${this.props.product.vfprodid}`}
            rel="noopener noreferrer"
            style={{
              color: "#FD9526",
              fontSize: "12px",
              display: "flex",
              lineHeight: "24px",
              letterSpacing: "0.032em",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
            >
              <path
                id="Shape"
                d="M8,16A8,8,0,0,1,2.343,2.343,8,8,0,0,1,13.657,13.657,7.948,7.948,0,0,1,8,16Zm0-5a.7.7,0,1,0,.7.7A.7.7,0,0,0,8,11ZM8,2.984a.626.626,0,0,0-.625.625V9.375a.625.625,0,1,0,1.25,0V3.61A.626.626,0,0,0,8,2.984Z"
                transform="translate(0 0)"
                fill="#fd9526"
              />
            </svg>

            <span style={{ marginRight: "6px" }}>Add To Store</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="11.207"
              viewBox="0 0 11 11.207"
            >
              <g
                id="Group_3850"
                data-name="Group 3850"
                transform="translate(-961.462 -333.293)"
              >
                <path
                  id="Path_84"
                  data-name="Path 84"
                  d="M-4592.26-691.587h-3.945v10h10V-685.4"
                  transform="translate(5558.167 1025.587)"
                  fill="none"
                  stroke="#fd9526"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
                <g
                  id="Group_3178"
                  data-name="Group 3178"
                  transform="translate(964.865 334)"
                >
                  <path
                    id="Path_85"
                    data-name="Path 85"
                    d="M-4590.015-685.029l6.885-6.96"
                    transform="translate(4590.015 691.988)"
                    fill="none"
                    stroke="#fd9526"
                    stroke-linecap="round"
                    stroke-width="1"
                  />
                  <path
                    id="Path_86"
                    data-name="Path 86"
                    d="M-4586.394-691.587h3.573v3.362"
                    transform="translate(4589.918 691.587)"
                    fill="none"
                    stroke="#fd9526"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                </g>
              </g>
            </svg>
          </Link>
        </p>
      </div>
    );
  }
}

export default AddToStore;
