import React, { Component } from "react";
import { Button, InputNumber, Modal, Tooltip, Icon } from "antd";
import "./index.scss";

class MultiplierModal extends Component {
  state = {
    value: null,
  };

  handleChange = (field, value) => {
    this.setState({
      ...this.state,
      [field]: value,
    });
  };

  resetModal = () => {
    this.setState({
      value: null,
    });
  };

  render() {
    const { visible, onCancel, onConfirm } = this.props;
    const { value } = this.state;

    return (
      <Modal
        className="setMultiplier"
        title={
          <div className="setMultiplierTitle">
            <h2>
              Set A Multiplier{" "}
              <Tooltip
                title="New Price = Multiplier X Old Price"
                style={{
                  fontSize: ".75rem",
                }}
                className="multiplierTool"
              >
                <Icon
                  type="info-circle"
                  style={{
                    fontSize: ".875rem",
                    marginLeft: ".25rem",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </h2>
            <p>Enter a multiplier for all the variants</p>
          </div>
        }
        visible={visible}
        width={320}
        onCancel={onCancel}
        afterClose={this.resetModal}
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={(e) => onConfirm(this.state.value)}
          >
            Apply
          </Button>,
        ]}
      >
        <div>
          <InputNumber
            value={value}
            placeholder="Enter multiplier"
            onChange={(e) => this.handleChange("value", e)}
          />
        </div>
      </Modal>
    );
  }
}

export default MultiplierModal;
