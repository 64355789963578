import React, { Component } from "react";
import {
  Row,
  Card,
  Skeleton,
  Alert,
  // Select,
  Button,
  message,
  notification,
  Icon,
  Tooltip,
} from "antd";
import PLACEHOLDER_PRODUCT_IMAGE from "../../../../../assets/images/icons/image_placeholder.png";
import { ReactComponent as NotificationIcon } from "../../../../../assets/images/icons/order-notification.svg";
import axios from "axios";
import Variantnav from "./Variantnav";
import { connect } from "react-redux";
import VaraintMatchSelecter from "./VaraintMatchSelecter";
import { SHOPIFY_NAME } from "../../../../../constants";
import "./index.scss";

// const { Option } = Select;

class VariantMatching extends Component {
  state = {
    loading: true,
    shopifyProduct: null,
    aliProduct: null,
    mapping: {},
  };

  componentDidMount() {
    axios({
      url:
        process.env.REACT_APP_API_URL + "/products/get_variant_matching_data",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        aliId: [this.props.match.params.aliId],
        productId: this.props.match.params.productId,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          let mapping = {};
          res.data.shopifyProduct.variants.forEach((v) => {
            mapping[v.variant_shopifyid] = v.skuPropIds ? v.skuPropIds : [];
          });
          this.setState({
            shopifyProduct: res.data.shopifyProduct,
            aliProduct: res.data.aliProduct[0],
            mapping: mapping,
            loading: false,
          });
        } else {
          this.setState({
            error: true,
            errorMsg: res.data.msg,
            loading: false,
          });
        }
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMsg: e.message,
          loading: false,
        });
      });
  }

  mapProduct(shopifyId, aliId) {
    this.setState({
      mapping: { ...this.state.mapping, [shopifyId]: aliId },
    });
  }

  submitMatchData() {
    const matchData = this.state.mapping;
    let arr = Object.values(matchData);
    for (let i = 0; i < arr.length; i++) {
      let a = arr[i].length === 0;
      if (a) {
        message.error(`Please match all ${SHOPIFY_NAME} variants`);
        return false;
      }
    }
    axios({
      url: process.env.REACT_APP_API_URL + "/products/match_product_variants",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        matchdata: this.state.mapping,
        aliid: this.props.match.params.aliId,
        product_mongoid: this.props.match.params.productId,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          // message.success("Product linked successfully");

          if (this.props.location.hash.includes("#order")) {
            notification.open({
              bottom: "5%",

              className: "custom-notification",
              placement: "bottomLeft",
              duration: 6,
              style: { background: "#E9FFE8", border: "2px solid #2A9C27" },
              description: (
                <div className="notification-wraper">
                  <Icon
                    className="notification-icon-svg"
                    component={NotificationIcon}
                  />
                  <div className="content">
                    <h5>
                      <h2>Product Linked</h2> <span>!</span>{" "}
                    </h5>
                    <p>
                      The product respective to order{" "}
                      <a
                        href={`./open?search=${
                          this.props.location.hash.split("=")[1]
                        }`}
                      >
                        #{this.props.location.hash.split("=")[1]}
                      </a>{" "}
                      has been linked.
                    </p>

                    <p>
                      {" "}
                      <a
                        href={`./open?search=${
                          this.props.location.hash.split("=")[1]
                        }`}
                      >
                        View{" "}
                      </a>{" "}
                      Linked order.
                    </p>
                  </div>
                </div>
              ),
              icon: null,
            });

            this.props.history.push(
              `/${this.props.store.store_geo}/${
                this.props.match.params.storeid
              }/orders/open`
            );
          } else {
            notification.open({
              bottom: "5%",

              className: "custom-notification",
              placement: "bottomLeft",
              duration: 6,
              style: { background: "#E9FFE8", border: "2px solid #2A9C27" },
              description: (
                <div className="notification-wraper">
                  <Icon
                    className="notification-icon-svg"
                    component={NotificationIcon}
                  />
                  <div className="content">
                    <h5>
                      <h2>Product Linked</h2> <span>!</span>{" "}
                    </h5>
                    <p>
                      The product respective to order{" "}
                      <a
                        href={`./store-products?search=${
                          this.props.match.params.aliId
                        }`}
                      >
                        #{this.props.match.params.aliId}
                      </a>{" "}
                      has been linked.
                    </p>

                    <p>
                      {" "}
                      <a
                        href={`./store-products?search=${
                          this.props.match.params.aliId
                        }`}
                      >
                        View{" "}
                      </a>{" "}
                      Linked order.
                    </p>
                  </div>
                </div>
              ),
              icon: null,
            });

            this.props.history.push(
              `/${this.props.store.store_geo}/${
                this.props.match.params.storeid
              }/products/store-products`
            );
          }
        } else {
          message.error("There was an error");
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  }

  render() {
    const PageWrapper = (children) => {
      return (
        <div>
          <Variantnav orderurl={this.props.location.hash} />
          {children}
        </div>
      );
    };

    if (this.state.loading) {
      return PageWrapper(
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={this.state.errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    return PageWrapper(
      <div className="variant-matching">
        <p className="ali-product-name">
          {" "}
          {`Linking ${SHOPIFY_NAME} Product`}
          <span className="link-product-name">
            {this.state.shopifyProduct.name.length > 30 ? (
              <Tooltip title={this.state.shopifyProduct.name}>
                {`${this.state.shopifyProduct.name.substring(0, 30)}...`}
              </Tooltip>
            ) : this.state.shopifyProduct.name ? (
              this.state.shopifyProduct.name
            ) : (
              "Not Available"
            )}
          </span>{" "}
          with VF Catalog Product{" "}
          <span className="link-product-name">
            {this.state.aliProduct.name.length > 30 ? (
              <Tooltip title={this.state.aliProduct.name}>
                {`${this.state.aliProduct.name.substring(0, 30)}...`}
              </Tooltip>
            ) : this.state.aliProduct.name ? (
              this.state.aliProduct.name
            ) : (
              "Not Available"
            )}
          </span>
        </p>

        <Card>
          <>
            <div className="header-txt">
              <p>
                {``} Product Variants In {}
              </p>
              <p> VF Product Variants In Import List</p>
            </div>
            {this.state.shopifyProduct.variants.map((sv) => (
              <div className="varaint-matching-data" key={sv.vskuid}>
                <div
                  style={{
                    borderRadius: "2px",
                    minHeight: "64px",
                    background: "#f5f5f5",
                  }}
                  className="left-varaint-image-data"
                >
                  <img
                    src={sv.img ? sv.img : PLACEHOLDER_PRODUCT_IMAGE}
                    style={{
                      maxWidth: "48px",
                      padding: "2px",
                      border: "1px solid #979797",
                    }}
                    alt="#"
                  />
                  <span style={{ marginLeft: "16px" }}>{sv.allspecs}</span>
                </div>

                <VaraintMatchSelecter
                  mapProduct={(a, b) => this.mapProduct(a, b)}
                  shopifyProduct={this.state.shopifyProduct}
                  aliProduct={this.state.aliProduct}
                  sv={sv}
                />
              </div>
            ))}
          </>
        </Card>

        <div style={{ marginTop: "15px" }}>
          <Button
            className="save-continue"
            type="primary"
            onClick={() => this.submitMatchData()}
            style={{ float: "right" }}
          >
            Save & Continue
          </Button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  store: state.auth.auth.store,
});
export default connect(mapStateToProps)(VariantMatching);
