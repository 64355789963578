import React from "react";
import { formatBalance } from "../../../../Helpers/Pricing";

import { Row, Skeleton, Tooltip } from "antd";
import "./index.scss";
import NotFoundCard from "../../Common/NotFoundCard";
import ErrorCard from "../../Common/ErrorCard";

const DeductedFromWallet = (props) => {
  const {
    data,
    startDate,
    endDate,
    loading,
    error,
    errorCode,
    errorMsg,
  } = props;

  function amountHandler(amount,color) {
    if (!!amount) {
      return (
        <div className= {`delivered-order-head   ${color?color: "debit"}`}>
          ₹ {formatBalance(amount)}
        </div>
      );
    } else {
      return <div className="delivered-order-head ">0</div>;
    }
  }

  if (loading) {
    return (
      <Row style={{ marginBottom: "30px" }}>
        <Skeleton active />
      </Row>
    );
  }

  // error card
  if (!loading && error) {
    return (
      <div id="fade-in" className="deductedFromWallet">
        <div className="topPerformingProductHead">
          {" "}
          <span className="name">Money Deducted From Wallet</span> |{" "}
          <span>
            {startDate} - {endDate}
          </span>
        </div>

        <ErrorCard errorMsg={errorMsg} errorCode={errorCode} />
      </div>
    );
  }

  if (Object.entries(data).length === 0) {
    return (
      <div id="fade-in" className="deductedFromWallet">
        <div className="topPerformingProductHead">
          {" "}
          <span className="name">Money Deducted From Wallet</span> |{" "}
          <span>
            {startDate} - {endDate}
          </span>
        </div>

        <NotFoundCard
          mainHeading={"Oops! No Data Found."}
          secondHeading={
            <p>
              Looks like there are no data in <span>current Date Range</span>.
            </p>
          }
          thirdHeading={
            <p>
              Please <span>select another Date Range</span> from Date Picker to
              filter data.
            </p>
          }
        />
      </div>
    );
  }
  return (
    <div id="fade-in" className="deductedFromWallet">
      <div className="topPerformingProductHead">
        {" "}
        <span className="name">Money Deducted From Wallet</span> |{" "}
        <span>
          {startDate} - {endDate}
        </span>
      </div>
      <div className="product-heads">
        <div className="name-head">VF Order Charges</div>
         <div  className="delivered-order-head"> Pending  </div>
        <div className="delivered-order-head">Processed</div>
      </div>

      <div className="product-row-wraper">
        <div className="product-row">
          <div className="name-head">Product Price</div>
          {amountHandler(data.pending_pp_cost,"yellow")}
          {amountHandler(data.pp_cost)}
        </div>

        <div className="product-row">
          <div className="name-head">Sourcing Request</div>
          {amountHandler(data.pending_client_sourcing_request,"yellow")}
          {amountHandler(data.client_sourcing_request)}
        </div>

        <div className="product-row">
          <div className="name-head">Fulfillment Fees</div>
          {amountHandler(data.pending_ff_cost,"yellow")}
          {amountHandler(data.ff_cost)}
        </div>

        <div className="product-row">
          <div className="name-head">Forward Shipping</div>
          {amountHandler(data.pending_fs_cost,"yellow")}
          {amountHandler(data.fs_cost)}
        </div>

        <div className="product-row">
          <div className="name-head">COD Charges</div>
          {amountHandler(data.pending_codf_cost,"yellow")}
          {amountHandler(data.codf_cost)}
        </div>

        <div className="product-row">
          <div className="name-head">RTO Handling Fee</div>
          {amountHandler(data.pending_rto_handling_cost,"yellow")}
          {amountHandler(data.rto_handling_cost)}
        </div>

        <div className="product-row">
          <div className="name-head">RTO Shipping</div>
          {amountHandler(data.pending_rto_shipping_cost,"yellow")}
          {amountHandler(data.rto_shipping_cost)}
        </div>

        <div className="product-row">
          <div className="name-head">Order GST</div>
          {amountHandler(data.pending_orderamount_gst_deductions,"yellow")}
          {amountHandler(data.orderamount_gst_deductions)}
        </div>

        <div className="product-row">
          <div className="name-head">Management Fee</div>
          {amountHandler(data.pending_management_fee,"yellow")}
          {amountHandler(data.management_fee)}
        </div>

        <div className="product-row">
          <div className="name-head">Gateway Charges</div>
          {amountHandler(data.pending_payment_gatway_charges,"yellow")}
          {amountHandler(data.payment_gatway_charges)}
        </div>
        {!!data.vf_cost ||data.pending_vf_cost? (
          <div className="product-row">
            <div className="name-head">VF Cost</div>
            {amountHandler(data.pending_vf_cost,"yellow")}
            {amountHandler(data.vf_cost)}
          </div>
        ) : null}
        {!!data.rto_remit_debit|| data.pending_rto_remit_debit ? (
          <div className="product-row">
            <div className="name-head">RTO Remit</div>
            {amountHandler(data.pending_rto_remit_debit,"yellow")}
            {amountHandler(data.rto_remit_debit)}
          </div>
        ) : null}

        {!!data.platform_fee ||data.pending_platform_fee? (
          <div className="product-row">
            <div className="name-head">Convenience Fee</div>
            {amountHandler(data.pending_platform_fee,"yellow")}
            {amountHandler(data.platform_fee)}
          </div>
        ) : null}

        {!!data.platform_fee_reverse|| data.pending_platform_fee_reverse ? (
          <div className="product-row ">
            <div className="name-head">Convenience Fee Reverse</div>
            {amountHandler(data.pending_platform_fee_reverse,"yellow")}
            {amountHandler(data.platform_fee_reverse)}
          </div>
        ) : null}

        {!!data.inward_fees || data.pending_inward_fees? (
          <div className="product-row">
            <div className="name-head">Inward Fee</div>
            {amountHandler(data.pending_inward_fees,"yellow")}
            {amountHandler(data.inward_fees)}
          </div>
        ) : null}

        <div className="product-row last-row">
          <div className="name-head total">Total</div>
          {amountHandler(data.total_pending,"yellow")}
          {amountHandler(data.total)}
        </div>
      </div>
    </div>
  );
};

export default DeductedFromWallet;
