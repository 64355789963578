import React from "react";
import "./index.scss";
class TourCard extends React.Component {
  render() {
    const { innerText, name, progress } = this.props;

    return (
      <div className="product-tour">
        <div className="tour-progress">
          <div className="bar" style={{ width: `${progress}` }} />
        </div>
        <div className="tour-head">
          <h2>{name}</h2>
        </div>
        <div className="tour-img-wraper">
          <div className="tour-left">
            <div className="bullet-div">
              <p>{innerText}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TourCard;
