import React, { Component } from "react";
import { Button, Card, Icon } from "antd";
import { connect } from "react-redux";
import Header from "../Header";
import { ReactComponent as Illustartion } from "../../../assets/images/illustrations/onboarding/illustration-questions.svg";
import { ReactComponent as Arrow } from "../../../assets/images/icons/white-right-arrow.svg";

class StartQuestionsScreen extends Component {
  render() {
    const name = this.props.auth.user.user_fname.split(" ")[0];

    return (
      <Card className="form-container-card">
        <Illustartion className="illustartion-img" />

        <Header
          title={<p className="header-txt">{`Great, you’re in ${name}!`} </p>}
          subtitle={
            <div className="header-subtitle">
              Let us know a bit more about you. It helps us to Personalize your
              account and strategise your growth plan in India.
            </div>
          }
        />

        <Button
          className="btn-green myaccount-btn"
          onClick={() => this.props.goToNextStep()}
          size="large"
        >
          Help Me Set Up My Account
          <Icon component={Arrow} />
        </Button>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({ auth: state.auth.auth });
export default connect(mapStateToProps)(StartQuestionsScreen);
