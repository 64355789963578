import React, { Component } from "react";
import { Button, Modal, Select, Spin, Tooltip } from "antd";
import axios from "axios";
import debounce from "lodash/debounce";
import "./index.scss";
// import RadioGroup from "antd/lib/radio/group";
const { Option } = Select;
class ViewBundleModal extends Component {
  state = {
    productIds: [],
    products: [],
  };

  handleOk = () => {
    this.props.history.push(
      `/${this.props.storeid}/bundle/${this.props.aliId}/${
        this.props.productId
      }#${this.props.orderway}${
        this.props.data && this.props.data.vfod
          ? `=` + this.props.data.vfod
          : ""
      }`
    );
  };

  removeField = (idx) => {
    const productIds = [
      ...this.state.productIds.slice(0, idx),
      ...this.state.productIds.slice(idx + 1),
    ];
    this.setState({ productIds });
  };

  resetModal = () => {
    this.setState({
      productIds: [""],
    });
  };

  fetchProducts(search) {
    const that = this;
    this.setState(
      {
        products: [],
        fetching: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/products/get_import_product_listing",
          method: "post",
          data: {
            storeid: this.props.storeid,
            search,
          },
          withCredentials: true,
        })
          .then((res) => {
            that.setState({
              products: res.data ? res.data : [],
              fetching: false,
            });
          })
          .catch((e) => {
            that.setState({
              products: [],
              fetching: false,
            });
          });
      }
    );
  }

  selectProducts(productIds) {
    this.setState({
      productIds,
    });
  }

  render() {
    return (
      <Modal
        className="link-product-bundle-modal"
        title="Creating product bundle"
        visible={this.props.bundleVisible}
        onCancel={this.props.onCancel}
        afterClose={this.resetModal}
        maskClosable={false}
        closable={!this.state.loading}
        destroyOnClose={true}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={this.handleOk}
            loading={this.state.loading}
            className="next-button"
            disabled={this.state.productIds.length === 0 ? true : false}
          >
            {this.state.loading ? "Fetching Product Data" : "Next"}
          </Button>,
          <Button
            key="back"
            onClick={this.props.onCancel}
            disabled={this.state.loading}
            className="cancle-button"
          >
            Cancel
          </Button>,
        ]}
      >
        <div>
          <p className="input-label">Link With</p>
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Select a product"
            optionFilterProp="children"
            onChange={(value) => this.selectProducts(value)}
            notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
            onSearch={debounce((val) => this.fetchProducts(val), 500)}
            size="large"
            mode="multiple"
            filterOption={false}
          >
            {this.state.products.map((p) => (
              <Option key={p.product_aliid} value={p.product_aliid}>
                <img
                  src={p && p.gallery ? p.gallery[0] : null}
                  alt={p.name}
                  style={{
                    verticalAlign: "middle",
                    border: "1px solid #898989",
                    padding: "4px",
                    width: "30px",
                    height: "30px",
                  }}
                />
                <span style={{ marginLeft: "15px", fontSize: "12px" }}>
                  {" "}
                  <Tooltip title={p.name}>
                    {p.name
                      ? `${p.name.substring(0, 20)}...`
                      : " Name not Available"}
                  </Tooltip>
                </span>
              </Option>
            ))}
          </Select>
        </div>
      </Modal>
    );
  }
}

export default ViewBundleModal;
