import React, { Component } from "react";
import { Card, Tooltip, Button, Icon, Carousel } from "antd";
import { ReactComponent as arrow } from "../../../../assets/images/icons/arrow.svg";
import { ReactComponent as openInNew } from "../../../../assets/images/icons/openInNewWhite.svg";

import "./index.scss";

const { Meta } = Card;

class SnapshotStepCard extends Component {
  render() {
    const contentStyle = {
      height: "160px",
      color: "#fff",
      lineHeight: "160px",
      textAlign: "center",
      background: "#364d79",
    };
    return (
      <div className="snapshot_card">
        <div className="left">
          <Carousel autoplay>
            <div onClick={() => this.props.cardClickHandler()}>
              <h3 style={contentStyle}>1</h3>
            </div>
            <div>
              <h3 style={contentStyle}>2</h3>
            </div>
          </Carousel>
        </div>
        <div className="right">
          <Carousel autoplay>
            <div>
              <h3 style={contentStyle}>1</h3>
            </div>
            <div>
              <h3 style={contentStyle}>2</h3>
            </div>
          </Carousel>
        </div>
      </div>
    );
  }
}

export default SnapshotStepCard;
