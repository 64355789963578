import React from "react";
import "./index.scss";
import moment from "moment";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";

class Logs extends React.Component {
  render() {
    return (
      <div
        className={`tab-container repitiveOrders ${
          this.props.activeTab !== "repitiveOrders" ? "hidden" : ""
        }`}
      >
        <div className="notes-tab">
          {" "}
          {this.props.logs && this.props.logs.length > 0 ? (
            <div className="header_upper_data">
              <p className="flex-action">Type</p>
              <p>Shopify Date</p>
              <p className="flex-date">Order ID</p>

              <p className="flex-addedby">Order Status</p>
            </div>
          ) : null}
          {this.props.logs && this.props.logs.length > 0 ? (
            this.props.logs.map((n) => (
              <div className="note repitiveOrders" key={n.date}>
                <div className="flex-action"> {n.order_type}</div>

                <div>
                  {n.shopify_order_date
                    ? moment(n.shopify_order_date).format(
                        "DD MMM, YYYY | hh:mm A"
                      )
                    : "-"}
                </div>

                <div className="flex-date hover-underline-animation">
                  {this.props.pageType === "allorders" ? (
                    <Link
                      to={`./orders?search=${n.order_id}`}
                      target="_blank"
                      className="hover-underline-animation"
                    >
                      {n.order_id}
                    </Link>
                  ) : (
                    <Link
                      to={`../orders?search=${n.order_id}`}
                      target="_blank"
                      className="hover-underline-animation"
                    >
                      {n.order_id}
                    </Link>
                  )}
                </div>
                <div className="flex-addedby">{n.order_status}</div>
              </div>
            ))
          ) : (
            <h3 style={{ textAlign: "center" }}>No Duplicate Orders Found!</h3>
          )}
        </div>
      </div>
    );
  }
}

export default Logs;
