import React, { Component } from "react";
import { Row, Skeleton, Alert } from "antd";
import axios from "axios";
import "./index.scss";
import { connect } from "react-redux";
import { updateStoreStatus } from "../../../store/actions/authActions";
import RtoTable from "./RtoTable";
import LmdTable from "./LmdTable";
import Fullfillmenttable from "./FullfillmentTable";
import PlatformFeeTable from "./PlatformFeeTable";
import WarehouseTable from "./WarehouseTable";
import NotFoundCard from "../Common/NotFoundCard";
class RateCards extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: "",
    LmdtableData: [], //DATA FOR BOTH RTO AND FULLFILLMENT TABLE
    rtotableData: [],
    fullfillmenttableData: [],
    platformFeetableData: [],
    warehouseingTableData: [],
    // filters: {
    //   search: "",
    // },
  };

  loadData() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/store/get_ratecards",
          method: "post",
          data: {
            storeid: this.props.store.id,
          },

          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              let FULLdata = res.data.data;
              let keyArr = Object.keys(FULLdata);
              let LmdDataArr = [];
              let rtoDataArr = [];
              let fullfillmentDataArr = [];
              let platformfeesDataArr = [];
              let warehouseingDataArr = [];
              //set the data for various table on the base of its data type
              keyArr.forEach((service) => {
                if (service === "lmd_fees") {
                  let zoneArr = Object.keys(FULLdata[service]);
                  zoneArr.forEach((zone) => {
                    if (zone === "minimum_reverse_pickup_charges") {
                      let rtoOBJ = {};
                      rtoOBJ["key"] = zone;

                      rtoOBJ = { ...rtoOBJ, [zone]: FULLdata[service][zone] };
                      LmdDataArr.push(rtoOBJ);
                      // rtoDataArr.push(rtoOBJ);
                    } else {
                      let rtoOBJ = {};
                      rtoOBJ["key"] = zone;

                      rtoOBJ = { ...rtoOBJ, ...FULLdata[service][zone] };
                      LmdDataArr.push(rtoOBJ);
                      rtoDataArr.push(rtoOBJ);
                    }
                  });
                }
                if (service === "fc_india_fulfilment_peritem_fees") {
                  let fullfillmentTYPEArr = Object.keys(FULLdata[service]);
                  fullfillmentTYPEArr.forEach((type) => {
                    if (type === "vas_per_order_charges") {
                    } else {
                      let typeObj = {};
                      typeObj["key"] = type;
                      typeObj[type] = FULLdata[service][type];
                      fullfillmentDataArr.push(typeObj);
                    }
                  });
                }
                if (service === "platform_fees") {
                  let platformFeetypeArr = Object.keys(FULLdata[service]);
                  platformFeetypeArr.forEach((type) => {
                    if (type === "fixed") {
                    } else {
                      let typeObj = {};
                      typeObj["key"] = type;
                      typeObj[type] = FULLdata[service][type];
                      platformfeesDataArr.push(typeObj);
                    }
                  });
                }
                if (service === "management_fees") {
                  let managementFeeOBJ = {};
                  managementFeeOBJ[service] = FULLdata[service];
                  managementFeeOBJ.key = service;
                  platformfeesDataArr.push(managementFeeOBJ);
                }
                if (
                  service === "rto_handling_fees" ||
                  service === "fc_india_storage_fees" ||
                  service === "rtv_fees" ||
                  service === "fc_india_inward_fees"
                ) {
                  let serviceOBJ = {};
                  serviceOBJ[service] = FULLdata[service];
                  serviceOBJ.key = service;
                  warehouseingDataArr.push(serviceOBJ);
                }
                if (service === "cod_fees") {
                  let serviceOBJ = {};
                  serviceOBJ[service] = FULLdata[service];
                  serviceOBJ.key = service;
                  LmdDataArr.push(serviceOBJ);
                }
                if (service === "rto_handling_fees") {
                  let serviceOBJ = {};
                  serviceOBJ[service] = FULLdata[service];
                  serviceOBJ.key = service;
                  rtoDataArr.push(serviceOBJ);
                }
              });
              this.setState({
                warehouseingTableData: warehouseingDataArr,
                LmdtableData: LmdDataArr,
                rtotableData: rtoDataArr,
                fullfillmenttableData: fullfillmentDataArr,
                platformFeetableData: platformfeesDataArr,
              });
              this.setState({
                stores: res.data.data,
                loading: false,
                error: false,
                errorMsg: "",
              });
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
                loading: false,
              });
            }
          })
          .catch((e) => {
            this.setState({
              error: true,
              errorMsg: e.message,
              loading: false,
            });
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  }
  // changeHandler(kvpairs) {
  //   this.setState({
  //     filters: { ...this.state.filters, ...kvpairs },
  //   });
  // }

  // changeFilter(kvpairs) {
  //   this.setState(
  //     {
  //       filters: { ...this.state.filters, ...kvpairs },
  //       pagination: { ...this.state.pagination, page: 1 },
  //     },
  //     () => this.loadData()
  //   );
  // }

  // goToPage(page) {
  //   this.setState(
  //     {
  //       pagination: { ...this.state.pagination, page },
  //     },
  //     () => {
  //       this.loadData();
  //     }
  //   );
  // }

  // onShowSizeChange(current, pageSize) {
  //   this.setState(
  //     {
  //       pagination: { ...this.state.pagination, pageSize: pageSize },
  //     },
  //     () => this.loadData()
  //   );
  // }

  componentDidMount() {
    this.loadData();
  }

  render() {
    const { user } = this.props;

    const PageWrapper = (children) => {
      return (
        <div className="store-listing">
          {/* <Managestornav
            toggleAddStoreModal={() => this.toggleAddStoreModal()}
            changeFilter={(e) => this.changeFilter(e)}
            changeHandler={(e) => this.changeHandler(e)}
            filters={this.state.filters}
          /> */}

          {children}
        </div>
      );
    };

    if (this.state.loading) {
      return PageWrapper(
        <Row style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </Row>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <Row>
          <Alert
            message="There was an error"
            description={this.state.errorMsg}
            type="error"
          />
        </Row>
      );
    }

    return PageWrapper(
      <div className="rate-cards-page-cointainer">
        {/* {!!this.state.LmdtableData.length && (
          <div className="table-cointainer">
            <LmdTable data={this.state.LmdtableData} />
          </div>
        )}
        {!!this.state.rtotableData.length && (
          <div className="table-cointainer">
            <RtoTable data={this.state.rtotableData} />
          </div>
        )} */}

        <Alert
          showIcon
          message={
            <div className="page-subtitle-custom">
              We have moved Shipping Rate cards from this page to Shipment
              Settings. You can access them from the left navigation menu or
              click on this{" "}
              <a
                onClick={() =>
                  window.location.assign(
                    `${process.env.REACT_APP_NEW_APP}/settings`
                  )
                }
                href="#"
              >
                link
              </a>{" "}
              for quick access.
            </div>
          }
          type="warning"
        />

        <br />

        {!!this.state.fullfillmenttableData.length && (
          <div className="table-cointainer">
            <Fullfillmenttable data={this.state.fullfillmenttableData} />
          </div>
        )}
        {!!this.state.platformFeetableData.length && (
          <div className="table-cointainer">
            <PlatformFeeTable data={this.state.platformFeetableData} />
          </div>
        )}
        {!!this.state.warehouseingTableData.length && (
          <div className="table-cointainer">
            {" "}
            <WarehouseTable data={this.state.warehouseingTableData} />
          </div>
        )}

        {!this.state.LmdtableData.length &&
        !this.state.rtotableData.length &&
        !this.state.fullfillmenttableData.length &&
        !this.state.platformFeetableData.length &&
        !this.state.warehouseingTableData.length ? (
          <NotFoundCard
            mainHeading={"Oops! No Data Found."}
            secondHeading={<p>Looks like there is no RateCard Found.</p>}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
    user: state.auth.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    storeStatusUpdated: (store) => dispatch(updateStoreStatus(store)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RateCards);

// export default RateCards;
