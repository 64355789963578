import React, { Component } from "react";
import { Modal, Radio } from "antd";
import moment from "moment";
import VfullfillForm from "./vfullfillForm";
import ExternalForm from "./ExternalForm";
import "./index.scss";
import { Tabs } from "antd";

const { TabPane } = Tabs;

class RaiseRFQModal extends Component {
  state = {
    type: "external",
    externalFormProduct: {},
  };

  externalFormProductHandler = (e) => {
    this.setState({
      externalFormProduct: e,
      type: "vfulfill",
    });
  };

  render() {
    return (
      <Modal
        onCancel={() =>
          this.setState({ externalFormProduct: {} }, () =>
            this.props.onCancel()
          )
        }
        title={
          <>
            <h1>Request for Quotation</h1>
            <h2>
              Please provide relevant information for the Product you want to
              Request Quote for
            </h2>
          </>
        }
        footer={null}
        className="rfq-modal"
        visible={this.props.visible}
        destroyOnClose={true}
      >
        <Tabs
          animated={true}
          activeKey={this.state.type}
          onChange={(e) => this.setState({ type: e })}
        >
          <TabPane tab="External Product" key="external">
            {this.state.type === "external" && (
              <ExternalForm
                externalFormProductHandler={(e) =>
                  this.externalFormProductHandler(e)
                }
                onCancel={() => this.props.onCancel()}
                activeTabHandler={(s) => this.props.activeTabHandler(s)}
              />
            )}
          </TabPane>
          <TabPane tab="vFulfill Product" key="vfulfill">
            {this.state.type === "vfulfill" && (
              <VfullfillForm
                props={this.props}
                externalFormProduct={this.state.externalFormProduct}
                onCancel={() => this.props.onCancel()}
                activeTabHandler={(s) => this.props.activeTabHandler(s)}
              />
            )}
          </TabPane>
        </Tabs>
      </Modal>
    );
  }
}

export default RaiseRFQModal;
