import React, { Component } from "react";
import "./index.scss";
import axios from "axios";
import { Link } from "react-router-dom";

import { DatePicker, message, Select } from "antd";
import Sorter from "../../Common/ShopifyDateSorter";
import moment from "moment";
const { RangePicker } = DatePicker;
class OrdersReportNav extends Component {
  state = {
    ndr: this.props.ndrs,
    allbtn: true,
    mount: false,
  };

  render() {
    return (
      <div className="order-transaction-nav fullorder_data">
        <div className=" Ndrnav ">
          <div className="ordfill">
            <div className="ordfillwrap">
              <div className="left" style={{ marginLeft: "7px" }}>
                <div
                  style={{
                    color: "#000",
                    fontSize: "12px",
                    letterSpacing: "0.032em",
                    lineHeight: "24px",
                  }}
                >
                  Reporting
                </div>
              </div>
              <div className="right">
                <p
                  className="show_data_from"
                  style={{ marginLeft: "8px", color: "#6e6e6e" }}
                >
                  Show Data From:{" "}
                </p>
                <RangePicker
                  format="DD MMM, YYYY"
                  allowClear={false}
                  disabledDate={(current) => {
                    return current > moment();
                  }}
                  style={{ marginLeft: "10px", maxWidth: "300px" }}
                  value={[
                    this.props.filters.startDate === "" ||
                    this.props.filters.startDate === null
                      ? ""
                      : moment(this.props.filters.startDate, "YYYY-MM-DD"),
                    this.props.filters.endDate === "" ||
                    this.props.filters.startDate === null
                      ? ""
                      : moment(this.props.filters.endDate, "YYYY-MM-DD"),
                  ]}
                  ranges={{
                    Today: [moment(), moment()],
                    "This Week": [
                      moment().startOf("week"),
                      moment().endOf("week"),
                    ],
                    "Last 15 Days": [moment().subtract("days", 15), moment()],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                    "Last Month": [
                      moment()
                        .subtract(1, "month")
                        .startOf("month"),
                      moment()
                        .subtract(1, "month")
                        .endOf("month"),
                    ],
                    "Last Year": [
                      moment()
                        .subtract(1, "year")
                        .startOf("year"),
                      moment()
                        .subtract(1, "year")
                        .endOf("year"),
                    ],
                  }}
                  onChange={(_, dateString) =>
                    this.props.changeFilter({
                      startDate: dateString[0],
                      endDate: dateString[1],
                    })
                  }
                />
              </div>
            </div>
            <div className="secondwrap">
              <div className="filtertab">
                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-ndr-underline-animation "
                      : this.state.openndrbtn
                      ? "tab  hover-ndr-underline-animation-click"
                      : "tab hover-ndr-underline-animation"
                  }
                >
                  <button value="rto">
                    {" "}
                    <Link style={{ color: "#898989" }} to="./reports">
                      Store Orders
                    </Link>
                  </button>
                </div>
                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-ndr-underline-animation "
                      : this.state.openndrbtn
                      ? "tab  hover-ndr-underline-animation-click"
                      : "tab hover-ndr-underline-animation"
                  }
                >
                  <Link style={{ color: "#898989" }} to="./transactionsReports">
                    <button
                      className={this.state.openndrbtn ? "active" : ""}
                      value=""
                    >
                      Transactions
                    </button>
                  </Link>
                </div>
                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-ndr-underline-animation "
                      : this.state.allbtn
                      ? "tab  hover-ndr-underline-animation-click"
                      : "tab hover-ndr-underline-animation"
                  }
                >
                  <Link style={{ color: "#898989" }} to="./NdrReports">
                    <button className="active" value="">
                      NDR
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_data" />
      </div>
    );
  }
}

export default OrdersReportNav;
