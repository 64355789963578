import { Button, message, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import React, { Component } from "react";

export class ReopenModal extends Component {
  state = {
    cancel_reason: "",
    loading: false,
  };
  handleReasonChange(e) {
    this.setState({
      cancel_reason: e.target.value,
    });
  }
  handleSubmit() {
    if (!this.state.cancel_reason) {
      return message.error("Please enter a reason to re-open quotation");
    }
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/rfqrequests/reopen_rfq",
          method: "post",
          data: {
            id: this.props.data.id,
            reason: this.state.cancel_reason,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              message.success(res.data.msg);
              this.props.rowHandler(this.props.data.index);
              this.props.onCancel();
            } else {
              message.error(res.data.msg);
            }
            this.setState({
              loading: false,
            });
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
            message.error(e.message);
          });
      }
    );
  }
  render() {
    return (
      <div>
        <Modal
          className="cancel-modal23"
          title="Re-Open Quotation Request"
          width={700}
          footer={
            <>
              <Button
                className="clickable"
                disabled={this.state.loading}
                onClick={() => this.handleSubmit()}
              >
                {" "}
                Re-Open Quotation
              </Button>{" "}
              {/* <Button onClick={() => this.props.onCancel()}>Close</Button> */}
            </>
          }
          destroyOnClose={true}
          visible={this.props.data.visible}
          onCancel={() => this.props.onCancel()}
        >
          <div style={{ marginBottom: "8px" }}>
            <TextArea
              value={this.state.cancel_reason}
              onChange={(e) => this.handleReasonChange(e)}
              placeholder="Please enter reason to re-open quotation request"
              style={{
                paddingLeft: "11px",
                paddingTop: "5px",
                fontSize: "12px",
                background: "#f5f5f5",
                border: "1px solid #898989 !important",
                borderRadius: "0px",
                color: "#212121",
                letterSpacing: "0.032em",
                lineHeight: "20px",
                fontSize: "12px",
                height: "128px",
                padding: "4px 11px",
              }}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
