import React from "react";
import { getCurrencySymbol } from "../../../../../../Helpers/Pricing";

import "./index.scss";

class ProductCostField extends React.Component {
  gstHandler(product) {
    try {
      return (
        <p className="gst-partner">+ GST ({product.hsn_attributes.gst}%)</p>
      );
    } catch (error) {
      return null;
    }
  }

  priceHandler() {
    if (!!Object.entries(this.props.product.moq_sourcing).length) {
      return (
        <div class="parent">
          <h1>
            {getCurrencySymbol(this.props.currency === "INR" ? "INR" : "USD")}{" "}
            {
              Object.entries(this.props.product.moq_sourcing)[0][1][
                "india_express_freight"
              ]["ppq"]
            }
          </h1>{" "}
          {this.gstHandler(this.props.product)}
        </div>
      );
    }

    if (!!Object.entries(this.props.product.moq_sourcing_sea).length) {
      return (
        <div class="parent">
          <h1>
            {getCurrencySymbol(this.props.currency === "INR" ? "INR" : "USD")}{" "}
            {
              Object.entries(this.props.product.moq_sourcing_sea)[0][1][
                "india_sea_freight"
              ]["ppq"]
            }
          </h1>{" "}
          {this.gstHandler(this.props.product)}
        </div>
      );
    }

    return (
      <div class="parent">
        <h1>
          {getCurrencySymbol(this.props.currency === "INR" ? "INR" : "USD")}{" "}
          {this.props.product.retail_sourcing.min !==
          this.props.product.retail_sourcing.max
            ? `${this.props.product.retail_sourcing.avg}`
            : `${this.props.product.retail_sourcing.min}`}
        </h1>{" "}
        {this.gstHandler(this.props.product)}
      </div>
    );
  }

  render() {
    return <div>{this.priceHandler()}</div>;
  }
}

export default ProductCostField;
