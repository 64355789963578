import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Row, Col } from "antd";
import loginImageNight from "../../assets/images/backgrounds/artNight.png";
import loginImageDay from "../../assets/images/backgrounds/artDay.png";
// import loginImageDay from "../../assets/images/backgrounds/artDaywithoutBorder.svg";
import logo from "../../assets/images/logos/logo.svg";
import moment from "moment";
import { connect } from "react-redux";

import "./LoginPageContainer.scss";

class LoginPageContainer extends Component {
  render() {
    const { auth } = this.props;
    if (auth.logged_in) return <Redirect to={`/switch-store`} />;
    return (
      <div className="login-page-container">
        <Row
          type="flex"
          justify="space-around"
          align="middle"
          style={{ height: "100%" }}
        >
          <Col xs={0} sm={0} md={14} lg={14} style={{ height: "100%" }}>
            <div className="day-night-image" style={{ height: "100%" }}>
              <img
                src={
                  moment().hours() > 6 && moment().hours() <= 18
                    ? loginImageDay
                    : loginImageNight
                }
                style={{ width: "100%", height: "100%" }}
                alt="No data found"
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} style={{ height: "100%" }}>
            <div className="login-container">
              <div className="login_form">
                <Row style={{ textAlign: "center" }}>
                  <img className="logo" src={logo} alt="Logo" />
                </Row>
                <Row style={{ textAlign: "center" }}>
                  <h1 className="login-page-heading">Welcome Back</h1>
                </Row>
                <Row style={{ textAlign: "center" }}>
                  <p className="tagline">Log In to access your dashboard</p>
                </Row>

                {this.props.children}
              </div>

              <div className="login-page-footer">
                <Row type="flex" justify="center">
                  <p>Copyright &copy; {new Date().getFullYear()} vFulfill</p>
                </Row>
                <Row type="flex" justify="center">
                  <ul className="footer-links">
                    <li>
                      <a href="https://www.vfulfill.io/legals/terms">Terms</a>
                    </li>
                    <li>
                      <a href="https://www.vfulfill.io/legals/privacy">
                        Privacy
                      </a>
                    </li>
                    <li>
                      <a href="https://help.vfulfill.io">Help</a>
                    </li>
                  </ul>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(LoginPageContainer);
