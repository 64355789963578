import React, { Component } from "react";
import { ReactComponent as EyeIcon } from "../../../../../assets/images/icons/eye.svg";
export default class ActionBar extends Component {
  render() {
    let { disabled, handleEdit, handleClear, handleDownload } = this.props;
    return (
      <>
        {!disabled ? (
          <>
            <button className="btnpre" onClick={handleDownload} type="button">
              <span className="icon-container">
                <EyeIcon />
              </span>
              Preview{" "}
            </button>
            <button
              type="submit"
              className="btn edit-btn"
              style={{ marginRight: "5px" }}
            >
              Submit
            </button>

            <button
              className="btn  cancel-btn"
              onClick={handleClear}
              type="button"
            >
              Cancel
            </button>
          </>
        ) : (
          <>
            <button className="btnpre" onClick={handleDownload} type="button">
              <span className="icon-container">
                <EyeIcon />
              </span>
              Preview
            </button>
            <button
              type="submit"
              className="btn edit-btn"
              style={{ display: "none" }}
            >
              Submit
            </button>
            <button onClick={handleEdit} className="btn edit-btn" type="button">
              Edit Settings
            </button>
          </>
        )}
      </>
    );
  }
}
