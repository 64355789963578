import React, { Component } from 'react'
import ShopifyConnect from './ShopifyConnect'
import { SHOPIFY_NAME } from "../../../../../constants";
export default class ShopifyFormContant extends Component {
  render() {
    return (
      <div> 
        <p className="title_txt"> {`Add New Shopify ${SHOPIFY_NAME}`}</p>
        <ShopifyConnect
          handleSubmitFrom={(e) => this.props.handleSubmit(e)}
          handleGoBackToLoctionType={() => this.props.handleGoBackToLoctionType()}
          handleCancel={this.props.handleCancel}
          loading={this.props.loading}
        />
      </div>
    )
  }
}
