import React, { Component } from "react";
import { Modal, Input, message, Button } from "antd";
import "./index.scss";
import axios from "axios";
import WithdrawalSuccess from "../WithdrawalSuccessModal";
import { formatNumber, getCurrencySymbol } from "../../../../Helpers/Pricing";

export default class WithdrawalModal extends Component {
  state = {
    loading: false,
    amount: Number(this.props.balance) >= 5000 ? 5000 : 1,
    requestLoading: false,
    successModal: {
      visible: false,
    },
  };

  withdrawalRequest() {
    if (Number(this.props.balance) < 5000) {
      if (Number(this.state.amount) <= 0) {
        return;
      }
    } else if (
      Number(this.state.amount) > Number(this.props.balance) ||
      Number(this.state.amount) < 5000
    ) {
      return;
    }

    this.setState(
      {
        requestLoading: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL + "/wallet/wallet_withdrawal_request",
          method: "POST",
          data: {
            amount: this.state.amount,
            bankid: this.state.bankid,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                requestLoading: false,
              });

              message.success("Withdraw Request Created Successfully");
              this.props.toggleWithdrawalModal();
              window.location.reload();
            } else {
              this.setState({
                requestLoading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((err) => {
            this.setState({
              requestLoading: false,
            });
            message.error(err.message);
          });
      }
    );
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  toggleSuccessModal() {
    this.setState({
      successModal: {
        ...this.state.successModal,
        visible: !this.state.successModal.visible,
      },
    });
  }

  render() {
    return (
      <>
        <Modal
          className="withdrawalModal"
          title={
            <div className="head-background">
              <div>
                <h1>WITHDRAW FUNDS</h1>
                <p>FROM YOUR vFULFILL WALLET</p>
              </div>
            </div>
          }
          visible={this.props.visible}
          onCancel={() => this.props.toggleWithdrawalModal()}
          width={480}
          destroyOnClose="true"
          footer={
            <Button
              block
              onClick={() => this.withdrawalRequest()}
              loading={this.state.requestLoading}
            >
              CONFIRM WITHDRAWAL
            </Button>
          }
        >
          <div style={{ textAlign: "center" }}>
            <div style={{ padding: "24px 30px" }}>
              <div className="withdrawal-request">
                <p>Enter The Amount To Raise The Withdrawal Request For:</p>
              </div>
              <div className="funds-input">
                <p>{getCurrencySymbol(this.props.currency)} </p>
                <Input
                  value={this.state.amount}
                  onChange={(e) =>
                    this.onChange(
                      "amount",
                      Number(e.target.value.replace(/\D/g, ""))
                    )
                  }
                />
              </div>
              <div className="total-name">
                <p>
                  Your Current Balance: {getCurrencySymbol(this.props.currency)}{" "}
                  {formatNumber(this.props.balance)}
                </p>
              </div>
            </div>
            {Number(this.state.amount) > Number(this.props.balance) && (
              <div className="balance-exceeded-warning">
                Withdrawal Amount Cannot Be More Than Account Balance!
              </div>
            )}
            {Number(this.props.balance) >= 5000 &&
              Number(this.state.amount) < 5000 && (
                <div className="balance-exceeded-warning">
                  Minimum Withdraw Funds Limit Is 5000!
                </div>
              )}
            {Number(this.state.amount) <= 0 && (
              <div className="balance-exceeded-warning">
                Please Enter Valid Amount !
              </div>
            )}
          </div>
        </Modal>
        <WithdrawalSuccess
          amount={this.state.amount}
          visible={this.state.successModal.visible}
          toggleSuccessModal={() => this.toggleSuccessModal()}
        />
      </>
    );
  }
}
