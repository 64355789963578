import React, { Component } from "react";
import {
  Modal,
  Row,
  Col,
  Tooltip,
  Icon,
  Button,
  Checkbox,
  InputNumber,
  message,
} from "antd";
import { SHOPIFY_NAME } from "../../../../../constants";
import "./index.scss";
import moment from "moment";

class LineItemsModal extends Component {
  state = {
    editLineItem: {},
  };
  onEdit(key) {
    this.setState({
      editLineItem: {
        [key]: true,
      },
    });
  }

  onSave(id) {
    this.setState({
      editLineItem: {},
    });
    this.props.lineItemSelectedChange(id);
  }
  onCountinue() {
    if (this.props.selectedLineItems.length < 1) {
      return message.error("Please select at least one line item");
    }
    this.props.onCountinue();
  }

  render() {
    if (this.props.data === undefined) {
      return null;
    }
    const shipment = this.props.data;
    const lineItems = this.props.data.items;
    const lineItemsLength = shipment.items.length;
    const disputeDeadline = moment.utc(shipment.closed_on).add(7, "days");
    return (
      <Modal
        title={
          <div className="modal-title">
            <p>
              Review Line Items
              <br />{" "}
              {/* <span
                style={{
                  fontSize: "12px",
                  color: "#FF7A3B",
                  fontWeight: "500",
                }}
              >
                (Escalations for this shipment can only be raised till{" "}
                {moment(disputeDeadline).format("DD MMM, YYYY")})
              </span> */}
            </p>
            <p
              style={{
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.032em",
                marginTop: "5px",
              }}
            >
              {lineItemsLength} Line Items(Products)
            </p>
          </div>
        }
        visible={this.props.visible}
        className="review-line-item"
        closable={false}
        maskClosable={false}
        width={900}
        footer={
          <div>
            <Button
              className="clickable"
              onClick={() => this.onCountinue()}
              type="primary"
            >
              Continue <Icon type="caret-right" />
            </Button>
            <Button
              onClick={this.props.onCancel}
              style={{ borderRadius: "3px" }}
            >
              Cancel
            </Button>
          </div>
        }
      >
        <>
          <div className="modal-header">
            <div>
              <p>
                WayBill Number(AWB){": "}
                {/* <span className="blue-text">{shipment.awb}</span> */}
                <span className="blue-text">
                  <a
                    href={shipment.tracking_url}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {shipment.awb}
                  </a>
                </span>
              </p>
            </div>

            <div>
              <p style={{ margin: 0 }}>
                {`${SHOPIFY_NAME} id:`}{" "}
                <span className="blue-text">
                  <a
                    href={
                      shipment.shopify_order_link
                        ? shipment.shopify_order_link
                        : "#"
                    }
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {shipment.order_shopify_name}
                  </a>
                </span>
              </p>
            </div>
            <div className="order_vfid">
              <p style={{ margin: 0 }}>
                vF Order:{" "}
                <span style={{ color: "#8989889" }}>{shipment.order_vfid}</span>
              </p>
            </div>
          </div>
          <div className="line-items-tab">
            {lineItems.map((li, i) => {
              return (
                <div
                  className={`line-item ${
                    this.props.selectedLineItems.find(
                      (item) => item === li.lineitem_id
                    ) !== undefined
                      ? "selected"
                      : ""
                  }`}
                  key={li.lineitem_id}
                >
                  <div className="line-item-product-detail">
                    <div className="mr">
                      <Checkbox
                        checked={
                          this.props.selectedLineItems.find(
                            (item) => item === li.lineitem_id
                          ) !== undefined
                        }
                        onChange={() =>
                          this.props.lineItemSelectedChange(li.lineitem_id)
                        }
                      />
                    </div>
                    <div className="mr">
                      <span
                        className="line-item-img"
                        style={{ background: `url(${li.img})` }}
                      />
                    </div>
                    <div className="mr">
                      <p className="blue-text line-item-name">
                        <Tooltip title={li.name} placement="topLeft">
                          {li.name}
                        </Tooltip>
                      </p>
                      <p className="line-item-sku">
                        <span style={{ color: "#898989" }}> SKU: </span>#
                        {li.sku}
                      </p>
                      <p className="line-item-sku">
                        <span style={{ color: "#898989" }}> Specs: </span>
                        {li.allspecs === "" ? "Default" : li.allspecs}
                      </p>
                    </div>
                  </div>

                  <div className="quantity">
                    {this.state.editLineItem[li.lineitem_id] ? (
                      <>
                        <span style={{ color: "black" }}>Qty: </span>
                        <InputNumber
                          value={li.quantity}
                          min={1}
                          max={li.originalQty}
                          onChange={(val) =>
                            this.props.onQtyChange(li.lineitem_id, val)
                          }
                        />
                      </>
                    ) : (
                      <p>
                        <span style={{ color: "#898989", fontSize: "12px" }}>
                          Qty:{" "}
                        </span>
                        <span
                          className="mr-3"
                          style={{ color: "black", fontSize: "12px" }}
                        >
                          {li.quantity}
                        </span>
                      </p>
                    )}
                  </div>
                  <div>
                    <a
                      href="#"
                      onClick={() =>
                        this.state.editLineItem[li.lineitem_id]
                          ? this.onSave(li.lineitem_id)
                          : this.onEdit(li.lineitem_id)
                      }
                      style={{ color: "#FF005B" }}
                    >
                      {this.state.editLineItem[li.lineitem_id]
                        ? "save"
                        : "Edit"}
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      </Modal>
    );
  }
}

export default LineItemsModal;
