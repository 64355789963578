import moment from "moment";
import React from "react";
import { Tooltip, Icon, message } from "antd";
import axios from "axios";
import { ReactComponent as Tooltipican } from "../../../../../assets/images/icons/tooltipican.svg";
import ProductID from "../../../Common/GlobalPrivateProduct/SubComp/ProductidGlobal";
import { ReactComponent as Previmg } from "../../../../../assets/images/icons/prev-img.svg";
import { ReactComponent as Nxtimg } from "../../../../../assets/images/icons/nextimage.svg";
import ProductSpecialTag from "../../../Common/GlobalPrivateProduct/SubComp/ProductSpecialTag";
import PrViewInVFCatalogButton from "../../../Common/GlobalPrivateProduct/SubComp/PR_ViewInVFCatalog_Button";
import OpenInStore from "../../../Common/GlobalCommon/OpenInStore";
import AddToStore from "../../../Common/GlobalCommon/AddToStore";
import ProductTitle from "../../../Common/GlobalCommon/ProductTitle";
import ProductCostField from "../../../Common/GlobalPrivateProduct/SubComp/ProductCostField";
import InventoryAvailable from "../../../Common/GlobalCommon/InventoryAvailable";
import RequestLatestPrice from "../../../Common/GlobalPrivateProduct/SubComp/Private_request_latest_price";

class Wishlistcard extends React.Component {
  state = {
    imgIndex: 0,
    in_watchlist: this.props.wishlistproduct.product_watch === 1 ? 1 : 0,
    response: "",
    status: 0,
    loading: false,
    clicked: false,
  };

  productWatch(id) {
    axios({
      url: process.env.REACT_APP_API_URL + "/products/toggle_watchlist_product",
      method: "post",
      data: {
        storeid: this.props.storeid,
        vfprodid: this.props.wishlistproduct.vfprodid,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState(
            (state) => ({
              in_watchlist: state.in_watchlist === 1 ? 0 : 1,
            }),
            () => {
              this.state.in_watchlist === 1
                ? message.success("Product added to Wishlist")
                : message.success("Product removed from Wishlist");
            }
          );
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  componentDidUpdate(pp) {
    if (
      pp.wishlistproduct.product_watch !==
      this.props.wishlistproduct.product_watch
    ) {
      this.setState({
        in_watchlist: this.props.wishlistproduct.product_watch,
      });
    }
  }

  requestUpdatedQuotation() {
    this.setState(
      {
        loading: true,
      },
      () => {
        if (this.cancelToken) {
          this.cancelToken.cancel();
        }
        this.cancelToken = axios.CancelToken.source();
        axios({
          url: process.env.REACT_APP_API_URL + "/user/create_partner_request",
          method: "post",
          cancelToken: this.cancelToken.token,
          data: {
            type: "vfcatalog_update_quotation",
            data: { vfprodid: this.props.wishlistproduct.vfprodid },
            storeid: this.props.storeid,
          },
          withCredentials: true,
        })
          .then((res) => {
            this.setState({
              loading: false,
              response: res.data.msg,
              status: res.data.success,
              clicked: true,
            });

            this.props.lessRequestCount();

            return res;
          })

          .catch((e) => {
            this.setState({
              response: "There was an error!",
            });
            console.log(e);
          });
      }
    );
  }

  nextImage() {
    this.setState(({ imgIndex }) => ({
      imgIndex:
        imgIndex === this.props.wishlistproduct.carousal_image.length - 1
          ? 0
          : imgIndex + 1,
    }));
  }

  prevImage() {
    this.setState(({ imgIndex }) => ({
      imgIndex:
        imgIndex === 0
          ? this.props.wishlistproduct.carousal_image.length - 1
          : imgIndex - 1,
    }));
  }

  render() {
    const {
      wishlistproduct,
      storeid,
      storegeo,
      currency,
      importProduct,
    } = this.props;

    return (
      <div className="product-card" id="test">
        {wishlistproduct.potd === 1 ? (
          <p className="potd-label f12">
            {" "}
            POTD for {moment(wishlistproduct.potd_date).format("DD MMM, YYYY")}
          </p>
        ) : null}

        <div className="content">
          <div className="left">
            <div className="product-img">
              <img
                src={wishlistproduct.carousal_image[this.state.imgIndex]}
                alt={wishlistproduct.catalogname}
              />
              <button onClick={() => this.prevImage()} className="prev-img">
                <Icon component={Previmg} />
              </button>
              <button onClick={() => this.nextImage()} className="next-img">
                <Icon component={Nxtimg} />
              </button>
            </div>
            {/* <div>
              {wishlistproduct.shopifyconnected === 0 ? (
                <AddToStore
                  storegeo={storegeo}
                  storeid={storeid}
                  product={wishlistproduct}
                />
              ) : (
                <OpenInStore product={wishlistproduct} />
              )}
            </div> */}
            <p style={{ marginTop: "8px" }} />
            <ProductSpecialTag product={wishlistproduct} />
          </div>

          <div className="details">
            <ProductTitle
              product={wishlistproduct}
              storegeo={storegeo}
              storeid={storeid}
            />
            <ProductID product={wishlistproduct} />

            <div
              className={
                wishlistproduct.request_latest_price === 1
                  ? "request_lattest_ican"
                  : "request_lattest_ican2"
              }
            >
              <div
                className="f12"
                style={{
                  color: "#898989",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ marginRight: "8px" }}>
                  {wishlistproduct.custom_pricing_discount > 0
                    ? "Special Cost (For Your Tier):"
                    : "Product Cost:"}
                </span>
                <span className="product-tooltipican">
                  <Tooltip
                    title={
                      <p style={{ textAlign: "center", fontSize: "10px" }}>
                        This price is inclusive of{" "}
                        <span
                          style={{
                            fontWeight: 500,
                            textDecoration: "underline",
                          }}
                        >
                          Product purchase price, Local & Cross Border freight
                          charges & Custom duty
                        </span>
                        . <i>Price is exclusive of any taxes applicable.</i>
                      </p>
                    }
                  >
                    <Icon style={{ height: "16px" }} component={Tooltipican} />
                  </Tooltip>
                </span>
              </div>
              <RequestLatestPrice
                requestUpdatedQuotation={() => this.requestUpdatedQuotation()}
                requestCount={this.props.requestCount}
                clicked={this.state.clicked}
                product={wishlistproduct}
              />
            </div>

            <ProductCostField product={wishlistproduct} currency={currency} />

            <div
              style={{
                fontSize: "10px",
                lineHeight: "16px",
                letterSpacing: "0.032em",
              }}
            >
              {this.state.response ? (
                this.state.status === 0 ? (
                  <span
                    className="f12"
                    style={{
                      marginBottom: 0,
                      background: "#FFFCE3",
                      padding: "4px 8px",
                      color: "#898989",
                      display: "inline-flex",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_3136"
                        data-name="Group 3136"
                        transform="translate(-12648 1256)"
                      >
                        <path
                          id="Polygon_47"
                          data-name="Polygon 47"
                          d="M7.106,1.789a1,1,0,0,1,1.789,0l6.382,12.764A1,1,0,0,1,14.382,16H1.618a1,1,0,0,1-.894-1.447Z"
                          transform="translate(12648 -1256)"
                          fill="#ffea46"
                        />
                        <g
                          id="Group_3135"
                          data-name="Group 3135"
                          transform="translate(12629.9 -1268)"
                        >
                          <path
                            id="Shape"
                            d="M26.709,22.316l-.077.786a.255.255,0,0,1-.255.23h-.558a.255.255,0,0,1-.255-.23L25.1,18.278a.252.252,0,0,1,.066-.195.257.257,0,0,1,.19-.083h1.486a.257.257,0,0,1,.19.083.252.252,0,0,1,.066.2l-.243,2.514Z"
                            transform="translate(0 0)"
                            fill="#212121"
                          />
                          <ellipse
                            id="Oval"
                            cx="0.759"
                            cy="0.801"
                            rx="0.759"
                            ry="0.801"
                            transform="translate(25.34 24.399)"
                            fill="#212121"
                          />
                        </g>
                      </g>
                    </svg>{" "}
                    <span
                      className="underline"
                      style={{
                        letterSpacing: "0.032em",
                        fontSize: "10px",
                        fontWeight: "400",
                        marginLeft: "8px",
                      }}
                    >
                      {this.state.response}
                    </span>
                  </span>
                ) : (
                  <span
                    className="f12"
                    style={{
                      marginBottom: 0,
                      background: "#F5FFF4",
                      padding: "4px 8px",
                      color: "#898989",
                      display: "inline-flex",
                    }}
                  >
                    <svg
                      id="exclamation-circle"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                    >
                      <path
                        id="Shape"
                        d="M12,24A12,12,0,0,1,3.515,3.515,12,12,0,0,1,20.486,20.486,11.922,11.922,0,0,1,12,24Zm0-7.5a1.055,1.055,0,1,0,1.055,1.055A1.056,1.056,0,0,0,12,16.5ZM12,4.477a.938.938,0,0,0-.937.938v8.648a.937.937,0,1,0,1.875,0V5.414A.939.939,0,0,0,12,4.477Z"
                        fill="#2bc822"
                      />
                    </svg>{" "}
                    <span
                      className="underline"
                      style={{
                        letterSpacing: "0.032em",
                        fontSize: "12px",
                        fontWeight: "400",
                        marginLeft: "8px",
                      }}
                    >
                      {this.state.response}
                    </span>
                  </span>
                )
              ) : (
                <div style={{ display: "flex" }} className="f12">
                  <p
                    style={{
                      marginRight: "8px",
                      marginBottom: "0px",
                    }}
                  >
                    {wishlistproduct.costUpdated ? (
                      <p className="f8 italic mb16">
                        Cost Last Updated On:{" "}
                        <span className="cost-update-date">
                          {moment(wishlistproduct.costUpdated).format(
                            "MMM DD, YYYY"
                          )}
                        </span>
                      </p>
                    ) : null}
                  </p>
                </div>
              )}
            </div>
            <InventoryAvailable
              product={wishlistproduct}
              restocking_time={wishlistproduct.attributes.restocking_time}
            />
          </div>
        </div>

        <PrViewInVFCatalogButton
          storeid={storeid}
          product={wishlistproduct}
          storegeo={storegeo}
          importProduct={importProduct}
          Wishlistcard={true}
          in_watchlist={this.state.in_watchlist}
          productWatch={() => this.productWatch()}
        />
      </div>
    );
  }
}

// Conflict Resolved
export default Wishlistcard;
