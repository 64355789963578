import React, { Component } from "react";
import "./index.scss";
import {
  Button,
  Table,
  Skeleton,
  Row,
  Alert,
  Modal,
  Tooltip,
  Card,
  Switch,
  message,
  Pagination,
} from "antd";

import { connect } from "react-redux";
import UserModal from "./UserModal";
import axios from "axios";
import PermissionsDrawer from "./PermissionsDrawer";
import moment from "moment";
import Usersettingnav from "./Usersettingnav";

class UserSettings extends Component {
  state = {
    error: false,
    errorMsg: "",
    loading: true,
    users: [],
    roles: [],
    roleLoading: false,
    total: 0,
    // pagination: { showSizeChanger: true, total: 0 },
    modalVisible: false,
    modalType: "add_modal",
    userToEdit: {},
    permissionsDrawer: {
      visible: false,
      name: "",
      email: "",
      permissions: {},
      id: "",
      role_name: "",
    },

    manageusers: {
      userlistbtn: true,
      rolebtn: false,
    },
    pagination: {
      showSizeChanger: true,
      total: 0,
      page: 1,
      per_page_count: 10,
    },
    filters: {
      search: "",
    },
    filters2: {
      search: "",
    },
  };

  changeFilter(kvpairs) {
    this.setState(
      {
        filters: { ...this.state.filters, ...kvpairs },
        paging: { ...this.state.pagination, page: 1 },
      },
      () => this.fetch()
    );
  }

  changeHandler(kvpairs) {
    this.setState({
      filters: { ...this.state.filters, ...kvpairs },
    });
  }

  changeFilter2(kvpairs) {
    this.setState(
      {
        filters2: { ...this.state.filters2, ...kvpairs },
        paging: { ...this.state.pagination, page: 1 },
      },
      () => this.getRoles()
    );
  }

  changeHandler2(kvpairs) {
    this.setState({
      filters2: { ...this.state.filters2, ...kvpairs },
    });
  }

  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        pagination: { ...this.state.pagination, per_page_count: pageSize },
      },
      () => this.fetch()
    );
  }

  manageuserstoggle(statedata4) {
    this.setState({
      manageusers: { ...statedata4 },
    });
  }

  goToPage(page) {
    this.setState(
      {
        pagination: { ...this.state.pagination, page },
      },
      () => {
        this.fetch();
      }
    );
  }

  togglePermissionsDrawer(
    name = "",
    email = "",
    permissions = {},
    id = "",
    roleName = ""
  ) {
    this.setState({
      permissionsDrawer: {
        visible: !this.state.permissionsDrawer.visible,
        name: name,
        email: email,
        permissions: permissions,
        id: id,
        role_name: roleName,
      },
    });
  }

  getRoles() {
    this.setState(
      {
        roleLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/roles/get_roles",
          method: "POST",
          data: {
            storeid: this.props.auth.store.id,
            filters: this.state.filters2,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                roles: res.data.roles,
                roleLoading: false,
              });
            } else {
              message.warning(res.data.msg);
              this.setState({
                roleLoading: false,
              });
            }
          })
          .catch((err) => {
            message.warning(err.message);
            this.setState({
              roleLoading: false,
            });
          });
      }
    );
  }

  fetch = () => {
    this.setState(
      {
        roleLoading: true,
      },
      () =>
        axios({
          url: process.env.REACT_APP_API_URL + "/store/get_subusers",
          method: "post",
          data: {
            storeid: this.props.auth.store.id,
            filters: this.state.filters,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                users: res.data.users,
                pagination: {
                  ...this.state.pagination,
                  total: res.data.total,
                },
                roleLoading: false,
                loading: false,
              });
            }
          })
          .catch((e) => {
            this.setState({
              error: true,
              errorMsg: e.message,
              roleLoading: false,
              loading: false,
            });
          })
    );
  };

  componentDidMount = () => {
    this.fetch();
    this.getRoles();
  };

  onTabChange(key) {
    if (key === "users") {
      this.fetch();
    } else {
      this.getRoles();
    }
  }

  changeState = (newState) => {
    this.setState({
      ...this.state,
      ...newState,
    });
  };
  onDeactivateUser(uid, checked) {
    Modal.confirm({
      title: `${checked ? "Activate" : "Deactivate"} User`,
      content: `Are you sure you want to ${
        checked ? "activate" : "deactivate"
      } the user?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () =>
        axios({
          url: process.env.REACT_APP_API_URL + "/store/toggle_user",
          method: "post",
          data: {
            userid: uid,
            storeid: this.props.auth.store.id,
            status: checked ? 1 : 0,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.fetch();
              if (checked === true) {
                message.success("User is activatied successfully!");
              } else {
                message.success("User is deactivatied successfully!");
              }
            } else {
              message.warning(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
          }),
    });
  }

  getRoleName(role_name, role_id) {
    this.setState({
      role_name: role_name,
      role_id: role_id,
    });
  }

  render() {
    const {
      error,
      errorMsg,
      loading,
      users,

      modalType,
      modalVisible,
      userToEdit,
    } = this.state;

    if (loading) {
      return (
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!loading && error) {
      return (
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    const columns = [
      {
        title: "User Name",
        dataIndex: "name",
        key: "name",
        render: (text) => <p className="user-name">{text}</p>,
      },
      {
        title: "Email Id",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "User Role",
        dataIndex: "role",
      },
      {
        title: "Actions",
        dataIndex: "id",
        key: "actions",
        render: (_, record) => (
          <div className="actions-div">
            <Tooltip title="Edit Permissions">
              <Button
                type="default"
                className="edit-btn"
                onClick={() => {
                  this.changeState({
                    modalVisible: true,
                    modalType: "edit_modal",
                    userToEdit: record,
                  });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16.002"
                  viewBox="0 0 16 16.002"
                >
                  <path
                    id="Shape"
                    d="M15.356,16H.642a.643.643,0,0,1,0-1.286H15.356a.643.643,0,0,1,0,1.286ZM3.916,12.656a.574.574,0,0,1-.524-.8l1.782-4.2A.576.576,0,0,1,5.3,7.472L12.388.378a1.29,1.29,0,0,1,1.821,0l1.416,1.416a1.29,1.29,0,0,1,0,1.822L8.529,10.706a.575.575,0,0,1-.182.121l-4.2,1.781A.581.581,0,0,1,3.916,12.656ZM11.281,3.3h0L6.3,8.283,5.264,10.737,7.717,9.7,12.7,4.718,11.281,3.3ZM13.3,1.284h0l0,0L12.19,2.395,13.6,3.809,14.711,2.7l0,0L13.3,1.284Z"
                    fill="#ff005e"
                  />
                </svg>
              </Button>
            </Tooltip>
            <Tooltip
              title={`User is ${
                record.user_disabled === 1 ? "deactivated" : "activated"
              }`}
            >
              <Switch
                checked={record.user_disabled === 1 ? false : true}
                onChange={(checked) =>
                  this.onDeactivateUser(record.id, checked)
                }
              />
            </Tooltip>
          </div>
        ),
        width: 200,
      },
    ];
    let roles = this.state.roles.filter((e) => e.role_type === "custom");
    const rolesColumns = [
      {
        title: "Role",
        dataIndex: "role_name",
        render: (text) => <p className="user-name">{text}</p>,
      },
      {
        title: "Created On",
        dataIndex: "role_createdon",
        render: (date) => <p>{moment(date).format("DD MMM, YYYY")}</p>,
      },
      {
        title: "Created By",
        dataIndex: "role_createdby_name",
      },
      {
        title: "Actions",
        dataIndex: "id",
        key: "actions",
        width: 250,
        render: (_, record) => (
          <div className="actions-div">
            <button
              style={{ color: "#FF005B" }}
              onClick={() =>
                this.togglePermissionsDrawer(
                  record.role_name,
                  "",
                  record.role_permissions,
                  record._id["$oid"],
                  record.role_name
                )
              }
              className="review-role"
            >
              <span style={{ marginRight: "8px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16.002"
                  viewBox="0 0 16 16.002"
                >
                  <path
                    id="Shape"
                    d="M15.356,16H.642a.643.643,0,0,1,0-1.286H15.356a.643.643,0,0,1,0,1.286ZM3.916,12.656a.574.574,0,0,1-.524-.8l1.782-4.2A.576.576,0,0,1,5.3,7.472L12.388.378a1.29,1.29,0,0,1,1.821,0l1.416,1.416a1.29,1.29,0,0,1,0,1.822L8.529,10.706a.575.575,0,0,1-.182.121l-4.2,1.781A.581.581,0,0,1,3.916,12.656ZM11.281,3.3h0L6.3,8.283,5.264,10.737,7.717,9.7,12.7,4.718,11.281,3.3ZM13.3,1.284h0l0,0L12.19,2.395,13.6,3.809,14.711,2.7l0,0L13.3,1.284Z"
                    fill="#ff005e"
                  />
                </svg>
              </span>
              Review Role
            </button>
          </div>
        ),
      },
    ];
    const dataSource = users.map((user) => ({
      key: user._id.$oid,
      id: user._id.$oid,
      name: user.user_fname,
      email: user.user_email,
      role: user.user_role,
      role_id: user.user_role_id,
      user_store_names: user.user_store_names,
      user_disabled: user.user_disabled,
      user_stores: user.user_stores,
      permissions:
        user.user_permissions && user.user_permissions[this.props.auth.store.id]
          ? user.user_permissions[this.props.auth.store.id]
          : [],
    }));

    return (
      <div>
        <Usersettingnav
          fetch={() => this.fetch()}
          getRoles={() => this.getRoles()}
          changeState={(a, b) => this.changeState(a, b)}
          modalVisible={this.state.modalVisible}
          togglePermissionsDrawer={() => this.togglePermissionsDrawer()}
          users={this.state.users}
          roles={this.state.roles}
          manageuserstoggle={(e) => this.manageuserstoggle(e)}
          changeFilter={(e) => this.changeFilter(e)}
          changeHandler={(e) => this.changeHandler(e)}
          changeFilter2={(e) => this.changeFilter2(e)}
          changeHandler2={(e) => this.changeHandler2(e)}
          filters={this.state.filters}
          filters2={this.state.filters2}
          {...this.props}
          loading={this.state.roleLoading}
        />
        <Card className="user-card">
          {this.state.manageusers.userlistbtn ? (
            <div className="user-settings">
              <Table
                rowKey={(row) => row.key}
                className="records-table"
                dataSource={dataSource}
                loading={this.state.roleLoading}
                columns={columns}
                pagination={false}
              />
            </div>
          ) : (
            <div className="user-settings">
              <Table
                rowKey={(row) => row._id["$oid"]}
                className="records-table"
                loading={this.state.roleLoading}
                dataSource={roles}
                columns={rolesColumns}
                pagination={false}
              />
            </div>
          )}

          <UserModal
            modalType={modalType}
            modalVisible={modalVisible}
            closeModal={() => {
              this.changeState({ modalVisible: false });
              this.fetch();
            }}
            storeid={this.props.auth.store.id}
            user={userToEdit}
            roles={this.state.roles}
            new_role_name={this.state.role_name}
            new_role_id={this.state.role_id}
            togglePermissionsDrawer={(name, email, role) =>
              this.togglePermissionsDrawer(
                name,
                email,
                role ? role.role_permissions : {},
                "",
                role ? role.role_name : ""
              )
            }
          />
          <PermissionsDrawer
            modalType={modalType}
            storeid={this.props.auth.store.id}
            visible={this.state.permissionsDrawer.visible}
            togglePermissionsDrawer={(permissions) =>
              this.togglePermissionsDrawer(
                this.state.permissionsDrawer.name,
                this.state.permissionsDrawer.email,
                permissions,
                this.state.permissionsDrawer.id,
                this.state.permissionsDrawer.role_name
              )
            }
            name={this.state.permissionsDrawer.name}
            email={this.state.permissionsDrawer.email}
            permissions={this.state.permissionsDrawer.permissions}
            id={this.state.permissionsDrawer.id}
            getRoles={() => this.getRoles()}
            roles={this.state.roles}
            roleName={this.state.permissionsDrawer.role_name}
            getRoleName={(name, id) => this.getRoleName(name, id)}
          />
        </Card>

        {this.state.manageusers.userlistbtn ? (
          <Pagination
            locale={{ jump_to: "Go to page" }}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            showSizeChanger
            showQuickJumper
            pageSizeOptions={["10", "25", "50"]}
            onShowSizeChange={(p, d) => this.onShowSizeChange(p, d)}
            current={this.state.pagination.page}
            onChange={(page) => this.goToPage(page)}
            total={this.state.pagination.total}
            defaultPageSize={this.state.pagination.per_page_count}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(UserSettings);
