import React from "react";
import { Modal, Skeleton, Tabs, message } from "antd";
import axios from "axios";
import Lottie from "react-lottie";
import PaymentSuccessAnim from "../../../../assets/images/jsonAnimationIcon/paymentSuccess.json";
import { Redirect } from "react-router-dom";
import "./index.scss";

class AppSubscriptionComplete extends React.Component {
  state = {
    iconStop: false,
    countdownTime: 3,
  };

  componentDidMount() {
    this.interval = setInterval(() => {
      if (this.state.countdownTime === 0) {
        clearInterval(this.interval);
      } else {
        this.setState({ countdownTime: this.state.countdownTime - 1 });
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: PaymentSuccessAnim,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    if (this.state.countdownTime === 0) {
      window.location.assign(process.env.REACT_APP_NEW_APP + `/switch-store`);
    }

    return (
      <div id="fade-in" className="transaction-complete-wraper">
        <div className="payment-icon">
          <Lottie
            options={defaultOptions}
            isStopped={this.state.iconStop}
            height={40}
            width={40}
          />
        </div>
        <h1>
          {!!this.props.custom ? this.props.text : "Payment Successfull"}{" "}
        </h1>

        <h2>Redirecting to Home in {this.state.countdownTime} seconds </h2>
      </div>
    );
  }
}

export default AppSubscriptionComplete;
