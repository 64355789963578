import React, { Component } from "react";
import { Input, message, Button } from "antd";
import Header from "../Header";
import { Link } from "react-router-dom";
import "./index.scss";
import axios from "axios";
class KycWocommerce extends Component {
  state = {
    woCommerceDetail: {
      shop_name: "",
      shop_url: "",
      shop_consumer_key: "",
      shop_consumer_secret: "",
    },
    loading: false,
  };

  handleChange(key, value) {
    this.setState({
      woCommerceDetail: {
        ...this.state.woCommerceDetail,
        [key]: value,
      },
    });
  }

  emptywoCommerceDetail() {
    this.setState({
      woCommerceDetail: {
        shop_name: "",
        shop_url: "",
        shop_consumer_key: "",
        shop_consumer_secret: "",
      },
    });
  }

  onSaveChange() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/store/add_woocommerce_store",
          method: "post",
          data: {
            ...this.state.woCommerceDetail,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
              });
              this.props.goToNextStep();
              this.emptywoCommerceDetail();
              this.props.history.push(`/switch-store/${res.data.id}`);
              message.success("Store successfully connected");
            } else {
              this.setState({
                loading: false,
              });
              message.error(res.data.msg);
            }
          })

          .catch((e) => {
            this.setState({
              loading: false,
            });
            message.error(e.message);
          });
      }
    );
  }

  render() {
    return (
      <div className="woCommerce_detail_input">
        <Header
          title={
            <p className="shopify-connect-title">
              {`Connect your WooCommerce store`}
            </p>
          }
          subtitle={
            <p className="shopify-connect-subtitle">
              {` Enter your  WooCommerce  details to begin setting up a wallet in vFulfill.`}
            </p>
          }
        />
        <div className="upeer_label_margin">
          <p className="common_label">
            Shop Name <span className="star">*</span>
          </p>
          <Input
            className="shop_name"
            placeholder="Enter Your Shop Name "
            value={this.state.woCommerceDetail.shop_name}
            onChange={(e) => this.handleChange("shop_name", e.target.value)}
          />
        </div>

        <div className="upeer_label_margin">
          <p className="common_label">
            Shop URL <span className="star">*</span>
          </p>
          <Input
            className="shop_url"
            placeholder="Enter Shop URL"
            value={this.state.woCommerceDetail.shop_url}
            onChange={(e) => this.handleChange("shop_url", e.target.value)}
          />
        </div>

        <div className="upeer_label_margin">
          <p className="common_label">
            Shop Consumer Key <span className="star">*</span>
          </p>
          <Input
            className="shop_consumer_key"
            placeholder="Enter Shop Consumer key"
            value={this.state.woCommerceDetail.shop_consumer_key}
            onChange={(e) =>
              this.handleChange("shop_consumer_key", e.target.value)
            }
          />
        </div>

        <div className="upeer_label_margin">
          <p className="common_label">
            Shop Consumer Secret <span className="star">*</span>
          </p>
          <Input
            className="shop_consumer_secret"
            placeholder="Enter Shop Consumer Secret"
            value={this.state.woCommerceDetail.shop_consumer_secret}
            onChange={(e) =>
              this.handleChange("shop_consumer_secret", e.target.value)
            }
          />
        </div>

        <div className="bottom_button">
          <div>
            <Button
              className="clickable_btn"
              onClick={() => this.onSaveChange()}
            >
              Continue
            </Button>
          </div>
          <div class="skip-step">
            <Link
              to={`/${this.props.auth.store.store_geo}/${
                this.props.auth.store.id
              }`}
            >
              <button className="onboarding-skip">Skip This Step</button>
            </Link>
          </div>
        </div>
      </div>
    );
    // }
  }
}

export default KycWocommerce;
