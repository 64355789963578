import React, { Component } from "react";
import "./index.scss";
import {
  Row,
  Form,
  Icon,
  Input,
  Button,
  Card,
  Checkbox,
  message,
  Switch,
} from "antd";
import logo from "../../assets/images/logos/logo_dark_transparent.svg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import qs from "qs";
import { Redirect } from "react-router-dom";
import ReactPhoneInput from "react-phone-input-2";

class Signup extends Component {
  state = {
    whatsapp: true,
    email: "",
    signupapiurl: "/login/signup_insider",
  };
  numberValueHandler(dialcode, number) {
    return `${dialcode}` + `${number}`;
  }
  handleContactChange(ob) {
    let onlyNumber = ob.value
      .replace(/[^0-9]+/g, "")
      .slice(
        !!Object.entries(ob.extraVal).length ? ob.extraVal.dialCode.length : 0
      );

    if (ob.value === 0 && ob.value === "") {
      this.setState({
        contactno: "",
        prefix_1: "",
      });
    } else if (parseInt(ob.value)) {
      this.setState({
        contactno: onlyNumber,
        prefix_1: ob.extraVal.dialCode,
      });
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    console.log("clicked");
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // values.user_fname = `${values.firstname} ${values.lastname}`;
        // values.activation_codes = this.state.activation_codes.map(ac => ac.code);
        values.organisation = "asda";
        values.known_from = "adasd";
        values.phone = {
          code: this.state.prefix_1,
          number: this.state.contactno,
        };
        axios({
          url: process.env.REACT_APP_API_URL + this.state.signupapiurl,
          method: "post",
          data: {
            ...values,
            whatsapp: this.state.whatsapp,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              window.location.href = res.data.redirect;
              // message.success("Done");
            } else {
              // if (res.data.codes) {
              // 	this.setState({
              // 		activation_codes: this.state.activation_codes.map(ac => {
              // 			if (res.data.codes[ac.code] && res.data.codes[ac.code].success !== 1) {
              // 				return { ...ac, error: res.data.codes[ac.code].msg }
              // 			} else {
              // 				return ac;
              // 			}
              // 		})
              // 	})
              // }
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
          });
      }
    });
  };

  componentDidMount() {
    document.title = this.props.title;
    const params = qs.parse(this.props.location.search.replace(/\?/g, ""));
    if (params.email !== undefined) {
      this.setState({ email: params.email });
      console.log(params.email);
    }
    if (params["utm_source"] == "awc22") {
      this.setState({ signupapiurl: "/login/signup_insider2" });
    }
    // const params = queryString.parse(this.props.location.search);
    // if (params.activation_code) {
    // 	this.setState({
    // 		activation_codes: [{ id: Date.now(), code: params.activation_code, error: '' }],
    // 	})
    // }
  }

  componentWillReceiveProps(props) {
    document.title = props.title;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { auth } = this.props;
    if (auth.logged_in) return <Redirect to={`/switch-store`} />;
    return (
      <div className="signup-container">
        <div class="signup">
          <Row style={{ position: "absolute" }} type="flex">
            <img className="logo" src={logo} alt="Logo" />
          </Row>
          <div>
            <div className="signup-form-container">
              {/* <Logo className="logo" /> */}
              <Card className="signup-card">
                <h2 className="title">Let's create your account.</h2>
                {/* <h5 className="sub-title">Get started with special plan of LetConvert.</h5> */}

                <Form onSubmit={this.handleSubmit} className="signup-form">
                  <div className="flex">
                    <Form.Item>
                      <span className="label">First Name</span>
                      {getFieldDecorator("fname", {
                        rules: [
                          {
                            required: true,
                            message: "Please input your first name!",
                          },
                        ],
                      })(
                        <Input
                          prefix={
                            <Icon
                              type="idcard"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          placeholder="Your First Name"
                        />
                      )}
                    </Form.Item>

                    <Form.Item>
                      <span className="label">Last Name</span>
                      {getFieldDecorator("lname", {
                        rules: [
                          {
                            required: true,
                            message: "Please input your last name!",
                          },
                        ],
                      })(
                        <Input
                          prefix={
                            <Icon
                              type="idcard"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          placeholder="Your Last Name"
                        />
                      )}
                    </Form.Item>
                  </div>

                  <Form.Item>
                    <span className="label">Email Address</span>
                    {getFieldDecorator("email", {
                      initialValue: this.state.email,
                      rules: [
                        { required: true, message: "Please input your email!" },
                        {
                          type: "email",
                          message: "Please enter a valid email address.",
                        },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder="Your Email Id"
                      />
                    )}
                  </Form.Item>

                  <Form.Item>
                    <span className="label">Set Up A Password</span>
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter a valid password!",
                        },
                      ],
                    })(
                      <Input.Password
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type="password"
                        placeholder="Your Password"
                      />
                    )}
                  </Form.Item>

                  <Form.Item>
                    <span className="label">Enter Your Phone Number</span>
                    <ReactPhoneInput
                      placeholder="Your Phone Number"
                      value={this.numberValueHandler("+91")}
                      onChange={(val, extra) =>
                        this.handleContactChange({
                          value: val,
                          extraVal: extra,
                          field: "contactno",
                        })
                      }
                      enableSearchField
                    />
                  </Form.Item>

                  <div className="sms-switch">
                    {" "}
                    <div className="left-text">Get OTP on</div>
                    <div className="right-text">
                      <span
                        style={{
                          color: `${
                            this.state.whatsapp ? "#898989" : "#1890ff"
                          }`,
                        }}
                      >
                        SMS
                      </span>
                      <div className="custom-switch">
                        <Switch
                          checked={this.state.whatsapp}
                          onChange={(e) => this.setState({ whatsapp: e })}
                        />
                      </div>
                      <span
                        style={{
                          color: `${
                            this.state.whatsapp ? "#36c626" : "#898989"
                          }`,
                        }}
                      >
                        WhatsApp
                      </span>
                    </div>
                  </div>
                  <Form.Item>
                    {getFieldDecorator("user_receive_updates", {
                      rules: [],
                      valuePropName: "checked",
                      initialValue: true,
                    })(
                      <Checkbox>
                        I'd like to receive product updates, offers and tips via
                        email
                      </Checkbox>
                    )}
                    <span className="label checkbox-label">
                      You may opt out at any time using the Unsubscribe link
                      provided in your emails.
                    </span>
                  </Form.Item>

                  <div className="flex">
                    <p className="label disclaimer">
                      By continuing, I acknowledge that I've read and I agree to
                      the{" "}
                      <a href="https://vfulfill.io/legals/user-agreement/">
                        User Agreement
                      </a>
                      ,{" "}
                      <a href="https://vfulfill.io/legals/terms">
                        Terms of Service
                      </a>{" "}
                      and{" "}
                      <a href="https://vfulfill.io/legals/privacy-policy">
                        Privacy Policy
                      </a>
                      .
                    </p>

                    <Button
                      type="primary"
                      htmlType="submit"
                      className="form-button"
                    >
                      Continue
                    </Button>
                  </div>
                </Form>

                <p className="bottom-link">
                  Already have an account? <Link to="/login">Login.</Link>
                </p>
              </Card>
              {/* <OnboardingFooter /> */}d
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(Form.create()(Signup));
