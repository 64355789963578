import React, { Component } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Globalsearch from "../../../Common/Globalsearch";
import VideoTooltip from "../../../Common/VideoTooltip";
import { Select } from "antd";

const { Option } = Select;

class Myproductnav extends Component {
  state = {
    arrow: false,
  };
  render() {
    return (
      <div className="usersettingnav fullorder_data">
        <div className="settingnav">
          <div className="ordfill">
            <div className="ordfillwrap">
              <div className="left">
                <div className="breadcurmb-txt">
                  Products <span>&#62;</span>{" "}
                  <span className="breadcurmb-underline">My Products</span>{" "}
                  <VideoTooltip
                    title="My Products"
                    subtitle="vFulfill product research tool to helps you to find products in the niche you would like to do business."
                    contentHeadings={[
                      "Import List",
                      "Private Products",
                      "Store Products",
                      "My Wishlist",
                    ]}
                    content={[
                      "Products that you import from AliExpress or from vFulfill catalog.",
                      "All products that have been shortlisted by you and are also linked with vFulfill catalog. ",
                      "List of all the products that have been published on your store.",
                      "Products that have been added by you to Wishlist from vFulfill Catalog.",
                    ]}
                    thumbnail=""
                    videolink=""
                  />
                </div>
              </div>
            </div>
            <div className="secondwrap">
              <div className="filtertab">
                <div className="tab hover-ndr-underline-animation">
                  <button className="import-list-txt">
                    {!!this.props.urlSearched ? (
                      <Link to="../import-list">Import List</Link>
                    ) : (
                      <Link to="./import-list">Import List</Link>
                    )}
                  </button>

                  <div className="circle">
                    {this.props.count && this.props.count.import_list === 0
                      ? "0"
                      : this.props.count &&
                        this.props.count.import_list &&
                        !this.props.loading
                      ? this.props.count.import_list
                      : "..."}
                  </div>
                </div>

                <div className="tab hover-ndr-underline-animation">
                  <button className="private-products-txt">
                    {" "}
                    {!!this.props.urlSearched ? (
                      <Link to="../private-products">Private Products</Link>
                    ) : (
                      <Link to="./private-products">Private Products</Link>
                    )}
                  </button>

                  <div className="circle">
                    {this.props.count && this.props.count.linked_products === 0
                      ? "0"
                      : this.props.count &&
                        this.props.count.linked_products &&
                        !this.props.loading
                      ? this.props.count.linked_products
                      : "..."}
                  </div>
                </div>

                <div
                  className={
                    !this.props.loading
                      ? "tab  hover-ndr-underline-animation-click"
                      : "non-cursor-pointer tab hover-ndr-underline-animation "
                  }
                >
                  <button className="store-product-txt">
                    {" "}
                    {!!this.props.urlSearched ? (
                      <Link to="../store-products">Store Products</Link>
                    ) : (
                      <Link to="./store-products">Store Products</Link>
                    )}
                  </button>

                  <div className="backgroundcircle">
                    {this.props.count && this.props.count.my_products === 0
                      ? "0"
                      : this.props.data && this.props.data.linkStatus === ""
                      ? this.props.total === 0
                        ? "..."
                        : this.props.total
                      : this.props.data &&
                        this.props.data.linkStatus === "linked"
                      ? this.props.total
                      : this.props.data &&
                        this.props.data.linkStatus === "not_linked"
                      ? this.props.total
                      : this.props.total}
                  </div>
                </div>

                <div className="tab hover-ndr-underline-animation">
                  <button className="wishlist-product-txt">
                    {" "}
                    {!!this.props.urlSearched ? (
                      <Link to="../wishlist-products">My Wishlist</Link>
                    ) : (
                      <Link to="./wishlist-products">My Wishlist</Link>
                    )}
                  </button>

                  <div className="circle">
                    {this.props.count && this.props.count.my_wishlist === 0
                      ? "0"
                      : this.props.count &&
                        this.props.count.my_wishlist &&
                        !this.props.loading
                      ? this.props.count.my_wishlist
                      : "..."}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_data">
          <div className="Globalsearch">
            <Globalsearch
              data={this.props.data}
              change={(value) =>
                this.props.changeHandler({ search: value.target.value })
              }
              searchFunc={(value) => this.props.changeFilter({ search: value })}
              placeholder={"Search by Product Name or ID"}
            />
          </div>

          {this.props.linkStatus ? null : (
            <div className="linkedStatusDropdown">
              <div className="product-status">
                <Select
                  className="filter-option"
                  placeholder="Product Status: All"
                  onDropdownVisibleChange={(val) =>
                    this.setState({ arrow: val })
                  }
                  value={this.props.data.linkStatus}
                  onChange={(value) =>
                    this.props.changeFilter2({ linkStatus: value })
                  }
                  suffixIcon={
                    <svg
                      style={{
                        marginTop: "2px",
                        transition: "all 0.5s",
                        transform: `${
                          this.state.arrow ? "rotateX(180deg)" : "rotateX(0deg)"
                        }`,
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="8"
                      viewBox="0 0 16 8"
                    >
                      <g
                        id="Polygon_49"
                        data-name="Polygon 49"
                        transform="translate(16 8) rotate(180)"
                        fill={`${this.state.arrow ? "#898989" : "none"}`}
                      >
                        <path d="M8,0l8,8H0Z" stroke="none" />
                        <path
                          d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                          stroke="none"
                          fill="#000"
                        />
                      </g>
                    </svg>
                  }
                >
                  <Option value="" className="commondropdown">
                    All
                  </Option>
                  <Option value="linked" className="commondropdown">
                    {" "}
                    Linked
                  </Option>
                  <Option value="not_linked" className="not-linked-status">
                    Not Linked
                  </Option>
                </Select>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    count: state.auth.count,
  };
};

export default connect(mapStateToProps)(Myproductnav);
