import React, { Component } from "react";
import {
  Input,
  Button,
  Row,
  Alert,
  Skeleton,
  Form,
  Divider,
  message,
} from "antd";
import { connect } from "react-redux";
import axios from "axios";
import "./index.scss";

class BasicDetails extends Component {
  state = {
    saving: false,
    loading: true,
    error: false,
    basicDetails: this.props.basicDetails,
    passwordloading: false,
    settings: {
      password: "",
      newpassword: "",
    },
  };

  changeState = (field, value) => {
    this.setState({
      settings: {
        ...this.state.settings,
        [field]: value,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState(
          {
            passwordloading: true,
          },
          () => {
            axios({
              url: process.env.REACT_APP_API_URL + "/user/update_password",
              method: "post",
              data: values,
              withCredentials: true,
            })
              .then((res) => {
                if (res.data.success === 1) {
                  this.setState({
                    passwordloading: false,
                  });
                  const { setFieldsValue } = this.props.form;

                  setFieldsValue({
                    current_password: "",
                    new_password: "",
                  });
                  message.success("Password updated successfully!");
                } else {
                  message.error(res.data.msg);
                }
              })
              .catch((e) => {
                this.setState({
                  passwordloading: false,
                });
                message.error(e.message);
              });
          }
        );
      }
    });
  };

  componentDidMount() {
    this.props.getuserdetail();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    if (this.props.loading) {
      return <Skeleton active />;
    }

    if (!this.props.loading && this.props.error) {
      return (
        <Row>
          <Alert
            message="There was an error"
            description={this.props.errorMsg}
            type="error"
          />
        </Row>
      );
    }

    return (
      <div className="basic-details-container">
        <p style={{ marginBottom: "15px", color: "#212121" }}>Basic Details</p>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "100%", marginRight: "8px" }}>
            <p>Name</p>
            <p className="fname">
              {this.props.basicDetails && this.props.basicDetails.name
                ? this.props.basicDetails.name.toLowerCase()
                : null}
            </p>
          </div>
          <div style={{ width: "100%", marginLeft: "8px" }}>
            <p>Email Address</p>
            <p className="basic-detail-field">
              {this.props.basicDetails && this.props.basicDetails.email
                ? this.props.basicDetails.email.toLowerCase()
                : null}
            </p>
          </div>
        </div>

        {this.props.basicDetails &&
        this.props.basicDetails.phoneNumber === null ? null : (
          <div style={{ width: "50%", paddingRight: "8px" }}>
            <p>Phone Number</p>

            <p
              style={{
                fontSize: "12px",
                marginTop: "5px",
                background: "#f5f5f5",
                padding: "8px 16px",
                color: "#212121",
                height: "32px",
              }}
            >
              {this.props.basicDetails && this.props.basicDetails.phoneNumber
                ? this.props.basicDetails.phoneNumber
                    .toString()
                    .replace("IN", "")
                : null}
            </p>
          </div>
        )}

        <Divider />

        <p style={{ marginBottom: "15px", color: "#212121" }}>
          Change Password
        </p>

        <Form onSubmit={this.handleSubmit} className="login-form" noValidate>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "100%", marginRight: "8px" }}>
              <Form.Item label="Current Password">
                {getFieldDecorator("current_password", {
                  rules: [
                    {
                      required: false,
                      type: "string",
                      message: "Please enter your current password!",
                    },
                  ],
                })(
                  <Input.Password
                    size="large"
                    placeholder="Your Current Password"
                    onChange={(e) =>
                      this.changeState("password", e.target.value)
                    }
                  />
                )}
              </Form.Item>
            </div>
            <div style={{ width: "100%", marginLeft: "8px" }}>
              <Form.Item label="New Password">
                {getFieldDecorator("new_password", {
                  rules: [
                    {
                      required: true,
                      type: "string",
                      message: "Please enter your new password!",
                    },
                  ],
                })(
                  <Input.Password
                    size="large"
                    placeholder="Your New Password"
                    onChange={(e) =>
                      this.changeState("newpassword", e.target.value)
                    }
                  />
                )}
              </Form.Item>
            </div>
          </div>

          <Form.Item>
            <Button
              className={
                this.state.settings.password.length > 0 &&
                this.state.settings.newpassword.length > 0
                  ? "clickablebtn"
                  : "clickablebtn2"
              }
              size="large"
              type="primary"
              htmlType="submit"
              loading={this.state.passwordloading}
              style={{ float: "right" }}
              // disabled={
              //   this.state.settings.password.length ===
              //   this.state.settings.newpassword.length
              //     ? false
              //     : true
              // }
            >
              Save New Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ auth: state.auth.auth });

export default Form.create()(connect(mapStateToProps)(BasicDetails));
