import React, { Component } from "react";
import { Drawer, Icon, Checkbox, Button, message, Tooltip, Modal } from "antd";
// import { ReactComponent as rightArrow } from "../../../../assets/images/icons/long-arrow.svg";
// import { ReactComponent as importantIcon } from "../../../../assets/images/icons/important.png";
import importantIcon from "../../../../assets/images/icons/important.png";
import { ReactComponent as Arrow } from "../../../../assets/images/icons/arrow.svg";
import "./index.scss";
import RoleSaveModal from "../RoleSaveModal";
import axios from "axios";

const permission = {
  snapshot: {
    access_snapshot_page: "Access Snapshot Page",
  },
  product: {
    view_discover_products_page: "View Discover Products Page",
    view_import_list_page: "View Import List",
    view_products: "View Products",
    import_export_products: "Private Products",
    link_products: "Store Products",
    delete_products: "Delete Products",
  },
  order: {
    view_open_orders: "View Open Orders",
    view_all_orders: "View All Orders",
    verify_modify_orders: "Verify & Modify Orders",
    verify_confirm_order: "Verify & Confirm Order",
    cancel_order: "Cancel an Order",
    add_note_order: "Add Note to Order",
    update_customer_details: "Update Customer Details",
    export_orders_data: "Export Orders Data",
    export_orders_data_with_customer_information:
      "Export Orders Data with Customer Information",
  },
  shipment: {
    view_shipments: "View Shipments",
    raise_disputes_on_shipments: "Raise Escalation on Shipments",
    export_shipments_data: "Export Shipments Data",
  },
  ndr: {
    view_ndr: "View NDR",
    allow_ndr_actions: "Allow NDR Actions",
    export_ndr_data: "Export NDR Data",
  },
  reporting: {
    view_overall_mis_report: "View Overall MIS Report",
    view_vf_cost_report: "View VF Cost Report",
    view_cod_remittance_report: "View COD Remittance Report",
    view_buy_back_report: "View Buy Back Report",
    view_shipments_mis_report: "View Shipments MIS Report",
    export_overall_mis_report: "Export Overall MIS Report",
    export_vf_cost_report: "Export VF Cost Report",
    export_cod_remittance_report: "Export COD Remittance Report",
    export_buy_back_report: "Export Buy Back Report",
    export_shipments_mis_report: "Export Shipments MIS Report",
  },
  dispute: {
    view_disputes: "View Escalations",
    raise_dispute: "Raise a Escalations",
  },
  requests: {
    pricing_request_view: "View Pricing Requests",
    pricing_request_add: "Add Pricing Requests",
    sourcing_request_view: "View Sourcing Requests",
    raise_booking_request: "Raise Booking Requests",
  },
  inventory: {
    view_inventory: "View Inventory Page",
  },
  setting: {
    view_settings: "View Settings",
    modify_settings: "Modify Settings",
  },
  wallet: {
    access_wallet: "Access Wallet",
    add_funds_to_wallet: "Add Funds to Wallet",
    withdraw_funds_from_wallet: "Withdraw Funds from Wallet",
    export_wallet_transactions_data: "Export Wallet Transactions Data",
  },
  global: {
    view_organisation_kyc_bank_data: "View Organisation KYC & Bank Data",
    add_shopify_store_to_account: "Add Channel Store to Account",
    manage_users_permissions: "Manage Users & Permissions",
  },
};

class PermissionsDrawer extends Component {
  state = {
    loading: false,
    dependentPermissions: {
      import_export_products: [
        "view_discover_products_page",
        "view_import_list_page",
        "view_products",
        "link_products",
      ],
      link_products: ["view_import_list_page", "view_products"],
      delete_products: ["view_import_list_page", "view_products"],
      verify_modify_orders: [
        "view_all_orders",
        "view_open_orders",
        "add_note_order",
      ],
      verify_confirm_order: [
        "view_all_orders",
        "view_open_orders",
        "add_note_order",
      ],
      cancel_order: ["view_open_orders", "add_note_order", "view_open_orders"],
      update_customer_details: ["verify_modify_orders", "add_note_order"],
      export_orders_data: ["view_open_orders", "view_all_orders"],
      export_orders_data_with_customer_information: [
        "view_open_orders",
        "view_all_orders",
      ],
      raise_disputes_on_shipments: ["view_shipments"],
      export_shipments_data: ["view_shipments"],

      allow_ndr_actions: ["view_ndr"],
      export_ndr_data: ["view_ndr"],
      raise_dispute: ["view_disputes"],
      modify_settings: ["view_settings"],

      add_funds_to_wallet: ["access_wallet"],
      withdraw_funds_from_wallet: ["access_wallet"],
      export_wallet_transactions_data: ["access_wallet"],
    },
    permission: {},
    selectAll: {},
    roleSaveModal: {
      visible: false,
      role: "",
    },
    permissionAccept: {
      visible: false,
    },
  };

  componentDidUpdate(pp) {
    let selected = {};

    Object.keys(this.props.permissions).forEach((s) => {
      if (
        this.props.permissions[s].length === Object.keys(permission[s]).length
      ) {
        selected = { ...selected, [s]: true };
      }
    });

    if (
      pp.name !== this.props.name ||
      pp.permissions !== this.props.permissions ||
      pp.roleName !== this.props.roleName
    ) {
      this.setState({
        selectAll: selected,
        permission: { ...this.props.permissions },
        roleSaveModal: {
          ...this.state.roleSaveModal,
          role: this.props.roleName,
        },
      });
    }
  }

  toggleRoleSaveModal() {
    this.setState({
      roleSaveModal: {
        ...this.state.roleSaveModal,
        visible: !this.state.roleSaveModal.visible,
      },
    });
  }
  onRoleChange(value) {
    this.setState({
      roleSaveModal: {
        ...this.state.roleSaveModal,
        role: value,
      },
    });
  }

  onPermissionChange(section, key, checked, send = true) {
    if (key === "export_wallet_transactions_data" && checked && send) {
      this.setState({
        permissionAccept: {
          visible: true,
          section: section,
          key: key,
          checked: checked,
        },
      });
    } else {
      let perms = this.state.permission[section]
        ? this.state.permission[section]
        : [];
      let depenedent = this.state.dependentPermissions[key]
        ? this.state.dependentPermissions[key]
        : [];

      if (perms.find((e) => e === key)) {
        perms = perms.filter((e) => e !== key);
      } else {
        depenedent.forEach((value) => {
          if (perms.indexOf(value) === -1) perms.push(value);
        });
        perms = [...perms, key];
      }

      let selectAll =
        Object.keys(permission[section]).length === perms.length ? true : false;
      this.setState({
        selectAll: {
          ...this.state.selectAll,
          [section]: selectAll,
        },
        permission: {
          ...this.state.permission,
          [section]: perms,
        },
      });
    }

    if (!!this.state.permissionAccept.visible) {
      this.setState({
        permissionAccept: {
          visible: false,
        },
      });
    }
  }

  selectTwoSection(a, b) {
    this.selectAll(a);
    setTimeout(() => {
      this.selectAll(b);
    }, 100);
  }

  selectAll(section, key) {
    if (section === "wallet") {
      let selectContent = false;
      let perms = this.state.permission[section]
        ? this.state.permission[section]
        : [];
      if (perms.length > 0) {
        perms = [];
        selectContent = false;
      } else {
        perms = Object.keys(permission[section]);
        selectContent = true;

        perms = perms.slice(0, perms.length - 1);
      }
      this.setState({
        selectAll: {
          ...this.state.selectAll,
          [section]: selectContent,
        },

        permission: {
          ...this.state.permission,
          [section]: perms,
        },
      });
    } else {
      let selectContent = false;
      let perms = this.state.permission[section]
        ? this.state.permission[section]
        : [];
      if (perms.length > 0) {
        perms = [];
        selectContent = false;
      } else {
        perms = Object.keys(permission[section]);
        selectContent = true;
      }
      this.setState({
        selectAll: {
          ...this.state.selectAll,
          [section]: selectContent,
        },

        permission: {
          ...this.state.permission,
          [section]: perms,
        },
      });
    }
  }

  onContinue() {
    let permissionLength = [];
    Object.values(this.state.permission).forEach((e) => {
      permissionLength = [...permissionLength, ...e];
    });
    if (permissionLength.length === 0) {
      message.warning("Please select atleast a permission");
      return;
    } else {
      this.props.togglePermissionsDrawer(this.state.permission);
      this.toggleRoleSaveModal();
    }
  }

  onContinueWithSelection() {
    let section = Object.keys(permission);
    let perms = {};
    section.forEach((s) => {
      perms[s] = Object.keys(permission[s]);
    });

    this.props.togglePermissionsDrawer(perms);
    this.toggleRoleSaveModal();
  }

  saveRole() {
    this.props.getRoleName(this.state.roleSaveModal.role);
    this.setState(
      {
        loading: true,
      },
      () => {
        let data = {};
        let successMessage = "";
        if (this.props.id) {
          data = {
            storeid: this.props.storeid,
            name: this.state.roleSaveModal.role,
            permissions: this.state.permission,
            id: this.props.id,
          };
          successMessage = "Role edited sucessfully";
        } else {
          data = {
            storeid: this.props.storeid,
            name: this.state.roleSaveModal.role,
            permissions: this.state.permission,
          };
          successMessage = "Role added sucessfully";
        }
        axios({
          url: process.env.REACT_APP_API_URL + "/roles/add_role",
          method: "POST",
          data: data,
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
              });
              message.success(successMessage);
              this.props.getRoles();
              this.props.getRoleName(res.data.role_name, res.data.role_id);
              this.toggleRoleSaveModal();
            } else {
              this.setState({
                loading: false,
              });
              message.warning(res.data.msg);
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
            message.error(err.message);
          });
      }
    );
  }

  render() {
    const email = this.props.email;
    const name = this.props.name;
    return (
      <Drawer
        width={1000}
        title={
          <div className="modalTitle">
            <div style={{ width: "90%" }}>
              <p className="add-new-role-txt">
                {name ? name : "+ Add New Role"}{" "}
              </p>
              <p
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "90%",
                  display: "block",
                  whiteSpace: "nowrap",
                }}
              >
                {email}
              </p>
            </div>

            <Icon
              type="close"
              onClick={() => this.props.togglePermissionsDrawer()}
            />
          </div>
        }
        placement="right"
        className="permissionDrawer"
        closable={false}
        visible={this.props.visible}
      >
        <div className="main-container">
          <div className="permission-container">
            <div className="snapshot-select">
              <p className="checkbox-title">Snapshot</p>
              <p
                onClick={(e) => this.selectAll("snapshot")}
                className={
                  this.state.selectAll.snapshot ? "deselect" : "select"
                }
              >
                {this.state.selectAll.snapshot ? "Deselect All" : "Select All"}
              </p>
            </div>

            <div className="permission-option">
              {Object.keys(permission.snapshot).map((s) => (
                <Checkbox
                  onChange={(e) =>
                    this.onPermissionChange("snapshot", s, e.target.checked)
                  }
                  checked={
                    this.state.permission.snapshot
                      ? this.state.permission.snapshot.find((e) => e === s) &&
                        true
                      : false
                  }
                  key={s}
                >
                  {permission.snapshot[s]}
                </Checkbox>
              ))}
            </div>
          </div>

          <div className="permission-container">
            <div className="snapshot-select">
              <p className="checkbox-title">Products</p>
              <p
                onClick={(e) => this.selectAll("product")}
                className={this.state.selectAll.product ? "deselect" : "select"}
              >
                {" "}
                {this.state.selectAll.product ? "Deselect All" : "Select All"}
              </p>
            </div>

            <div className="permission-option">
              {Object.keys(permission.product).map((s) => (
                <Checkbox
                  onChange={(e) =>
                    this.onPermissionChange("product", s, e.target.checked)
                  }
                  checked={
                    this.state.permission.product
                      ? this.state.permission.product.find((e) => e === s) &&
                        true
                      : false
                  }
                  key={s}
                >
                  {permission.product[s]}
                </Checkbox>
              ))}
            </div>
          </div>

          <div className="permission-container">
            <div className="snapshot-select">
              <p className="checkbox-title">Orders</p>
              <p>
                {" "}
                <span
                  onClick={(e) => this.selectAll("order")}
                  className={this.state.selectAll.order ? "deselect" : "select"}
                >
                  {this.state.selectAll.order ? "Deselect All" : "Select All"}
                </span>
              </p>
            </div>

            <div className="permission-option">
              {Object.keys(permission.order).map((s) => (
                <Checkbox
                  onChange={(e) =>
                    this.onPermissionChange("order", s, e.target.checked)
                  }
                  checked={
                    this.state.permission.order
                      ? this.state.permission.order.find((e) => e === s) && true
                      : false
                  }
                  key={s}
                >
                  {permission.order[s]}
                </Checkbox>
              ))}
            </div>
          </div>

          <div className="permission-container">
            <div className="snapshot-select">
              <p className="checkbox-title">Shipments</p>
              <p>
                {" "}
                <span
                  onClick={(e) => this.selectAll("shipment")}
                  className={
                    this.state.selectAll.shipment ? "deselect" : "select"
                  }
                >
                  {this.state.selectAll.shipment
                    ? "Deselect All"
                    : "Select All"}
                </span>
              </p>
            </div>

            <div className="permission-option">
              {Object.keys(permission.shipment).map((s) => (
                <Checkbox
                  onChange={(e) =>
                    this.onPermissionChange("shipment", s, e.target.checked)
                  }
                  checked={
                    this.state.permission.shipment
                      ? this.state.permission.shipment.find((e) => e === s) &&
                        true
                      : false
                  }
                  key={s}
                >
                  {permission.shipment[s]}
                </Checkbox>
              ))}
            </div>
          </div>

          <div className="permission-container">
            <div className="snapshot-select">
              <p className="checkbox-title">View NDR</p>
              <p>
                {" "}
                <span
                  onClick={(e) => this.selectAll("ndr")}
                  className={this.state.selectAll.ndr ? "deselect" : "select"}
                >
                  {this.state.selectAll.ndr ? "Deselect All" : "Select All"}
                </span>
              </p>
            </div>

            <div className="permission-option">
              {Object.keys(permission.ndr).map((s) => (
                <Checkbox
                  onChange={(e) =>
                    this.onPermissionChange("ndr", s, e.target.checked)
                  }
                  checked={
                    this.state.permission.ndr
                      ? this.state.permission.ndr.find((e) => e === s) && true
                      : false
                  }
                  key={s}
                >
                  {permission.ndr[s]}
                </Checkbox>
              ))}
            </div>
          </div>

          <div className="permission-container">
            <div className="snapshot-select">
              <p className="checkbox-title">Requests</p>
              <p>
                {" "}
                <span
                  onClick={(e) => this.selectTwoSection("dispute", "requests")}
                  className={
                    this.state.selectAll.requests &&
                    this.state.selectAll.dispute
                      ? "deselect"
                      : "select"
                  }
                >
                  {this.state.selectAll.requests && this.state.selectAll.dispute
                    ? "Deselect All"
                    : "Select All"}
                </span>
              </p>
            </div>

            <div className="permission-option">
              {Object.keys(permission.requests).map((s) => (
                <Checkbox
                  onChange={(e) =>
                    this.onPermissionChange("requests", s, e.target.checked)
                  }
                  checked={
                    this.state.permission.requests
                      ? this.state.permission.requests.find((e) => e === s) &&
                        true
                      : false
                  }
                  key={s}
                >
                  {permission.requests[s]}
                </Checkbox>
              ))}
              {Object.keys(permission.dispute).map((s) => (
                <Checkbox
                  onChange={(e) =>
                    this.onPermissionChange("dispute", s, e.target.checked)
                  }
                  checked={
                    this.state.permission.dispute
                      ? this.state.permission.dispute.find((e) => e === s) &&
                        true
                      : false
                  }
                  key={s}
                >
                  {permission.dispute[s]}
                </Checkbox>
              ))}
            </div>
          </div>

          <div className="permission-container">
            <div className="snapshot-select">
              <p className="checkbox-title">Inventory</p>
              <p>
                {" "}
                <span
                  onClick={(e) => this.selectAll("inventory")}
                  className={
                    this.state.selectAll.inventory ? "deselect" : "select"
                  }
                >
                  {this.state.selectAll.inventory
                    ? "Deselect All"
                    : "Select All"}
                </span>
              </p>
            </div>

            <div className="permission-option">
              {Object.keys(permission.inventory).map((s) => (
                <Checkbox
                  onChange={(e) =>
                    this.onPermissionChange("inventory", s, e.target.checked)
                  }
                  checked={
                    this.state.permission.inventory
                      ? this.state.permission.inventory.find((e) => e === s) &&
                        true
                      : false
                  }
                  key={s}
                >
                  {permission.inventory[s]}
                </Checkbox>
              ))}
            </div>
          </div>

          <div className="permission-container">
            <div className="snapshot-select">
              <p className="checkbox-title">Settings</p>
              <p>
                <span
                  onClick={(e) => this.selectAll("setting")}
                  className={
                    this.state.selectAll.setting ? "deselect" : "select"
                  }
                >
                  {this.state.selectAll.setting ? "Deselect All" : "Select All"}
                </span>
              </p>
            </div>

            <div className="permission-option">
              {Object.keys(permission.setting).map((s) => (
                <Checkbox
                  onChange={(e) =>
                    this.onPermissionChange("setting", s, e.target.checked)
                  }
                  checked={
                    this.state.permission.setting
                      ? this.state.permission.setting.find((e) => e === s) &&
                        true
                      : false
                  }
                  key={s}
                >
                  {permission.setting[s]}
                </Checkbox>
              ))}
            </div>
          </div>

          <div className="permission-container">
            <div className="snapshot-select">
              <p className="checkbox-title">Wallet</p>
              <p>
                {" "}
                <span
                  onClick={(e) => this.selectAll("wallet")}
                  className={
                    this.state.selectAll.wallet ? "deselect" : "select"
                  }
                >
                  {this.state.selectAll.wallet ? "Deselect All" : "Select All"}
                </span>
              </p>
            </div>

            <div className="permission-option">
              {Object.keys(permission.wallet).map((s) => (
                <Tooltip
                  zIndex="999999"
                  title={
                    permission.wallet[s] === "Export Wallet Transactions Data"
                      ? null
                      : null
                  }
                >
                  {permission.wallet[s] ===
                  "Export Wallet Transactions Data" ? (
                    <Checkbox
                      onChange={(e) =>
                        this.onPermissionChange(
                          "wallet",
                          s,
                          e.target.checked,
                          `${
                            this.state.permission.wallet
                              ? this.state.permission.wallet.find(
                                  (e) => e === s
                                ) && true
                              : false
                          }`
                        )
                      }
                      checked={
                        this.state.permission.wallet
                          ? this.state.permission.wallet.find((e) => e === s) &&
                            true
                          : false
                      }
                      key={s}
                    >
                      {permission.wallet[s]}
                    </Checkbox>
                  ) : (
                    <Checkbox
                      onChange={(e) =>
                        this.onPermissionChange("wallet", s, e.target.checked)
                      }
                      checked={
                        this.state.permission.wallet
                          ? this.state.permission.wallet.find((e) => e === s) &&
                            true
                          : false
                      }
                      key={s}
                    >
                      {permission.wallet[s]}
                    </Checkbox>
                  )}
                </Tooltip>
              ))}
            </div>
          </div>

          <div className="permission-container">
            <div className="snapshot-select">
              <p className="checkbox-title">Global</p>
              <p>
                {" "}
                <span
                  onClick={(e) => this.selectAll("global")}
                  className={
                    this.state.selectAll.global ? "deselect" : "select"
                  }
                >
                  {this.state.selectAll.global ? "Deselect All" : "Select All"}
                </span>
              </p>
            </div>

            <div className="permission-option">
              {Object.keys(permission.global).map((s) => (
                <Checkbox
                  onChange={(e) =>
                    this.onPermissionChange("global", s, e.target.checked)
                  }
                  checked={
                    this.state.permission.global
                      ? this.state.permission.global.find((e) => e === s) &&
                        true
                      : false
                  }
                  key={s}
                >
                  {permission.global[s]}
                </Checkbox>
              ))}
            </div>
          </div>
          <div className="drawer-footer">
            <Button onClick={() => this.onContinue()}>
              Continue <Icon className="arrow-right" component={Arrow} />
            </Button>

            <button
              onClick={() => this.onContinueWithSelection()}
              className="all-permissions-continue"
            >
              Select All Permissions & Continue
            </button>
          </div>
        </div>
        <RoleSaveModal
          visible={this.state.roleSaveModal.visible}
          toggleRoleSaveModal={() => this.toggleRoleSaveModal()}
          onRoleChange={(val) => this.onRoleChange(val)}
          saveRole={() => this.saveRole()}
          roleName={this.state.roleSaveModal.role}
          id={this.props.id}
          loading={this.state.loading}
        />
        <Modal
          title="Important"
          visible={this.state.permissionAccept.visible}
          onOk={() =>
            this.onPermissionChange(
              this.state.permissionAccept.section,
              this.state.permissionAccept.key,
              this.state.permissionAccept.checked,
              false
            )
          }
          className="permission-modal-checkbox"
          onCancel={() =>
            this.setState({ permissionAccept: { visible: false } })
          }
          okText="Continue"
          cancelText="Cancel"
        >
          <div className="modal-content">
            <img src={importantIcon} alt="" />

            <p>
              This will permit the assigned users to be able to download Wallet
              CSV for all the stores in the organisation.
            </p>
          </div>
        </Modal>
      </Drawer>
    );
  }
}

export default PermissionsDrawer;
