import React, { Component } from "react";
import { Modal, Button, Icon, Input } from "antd";
import { ReactComponent as Arrow } from "../../../../assets/images/icons/arrow.svg";
import "./index.scss";

class RoleSaveModal extends Component {
  render() {
    return (
      <Modal
        title={null}
        footer={null}
        visible={this.props.visible}
        className="role-modal"
        destroyOnClose={true}
      >
        <div className="role-container">
          <div className="role-header">
            <p className="save-role">Save Role As</p>
            <Icon type="close" onClick={this.props.toggleRoleSaveModal} />
          </div>
          <div className="role-body">
            <p>Save the User Role Settings as</p>
            <Input
              placeholder="Enter User Role Name"
              value={this.props.roleName}
              onChange={(e) => this.props.onRoleChange(e.target.value)}
            />
          </div>
          <Button
            className="drawer-footer"
            onClick={this.props.saveRole}
            loading={this.props.loading}
          >
            Continue
            <Icon className="arrow-right" component={Arrow} />
          </Button>
        </div>
      </Modal>
    );
  }
}

export default RoleSaveModal;
