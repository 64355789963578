import React from "react";
import { formatNumber, getCurrencySymbol } from "../../../../Helpers/Pricing";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { hasPermission } from "../../../../Helpers/Permissions";
import { Checkbox, Tooltip, Icon, Select } from "antd";
import { ReactComponent as RestockingDownArrows } from "../../../../assets/images/icons/RestockingDownArrows.svg";
import { ReactComponent as GoldenStar } from "../../../../assets/images/icons/GoldenStar.svg";
import { ReactComponent as TooltipIcon } from "../../../../assets/images/icons/TooltipIcon.svg";
import { ReactComponent as downArrow } from "../../../../assets/images/icons/downArrow.svg";
import { ReactComponent as WhiteHeart } from "../../../../assets/images/icons/whiteHeart.svg";
import { ReactComponent as RequestIcon } from "../../../../assets/images/icons/requestIcon.svg";
const { Option } = Select;
class BulkPricing extends React.Component {
  state = {
    loading: false,
    response: "",
    retail: true,
    bulk: true,
    sea: true,
    freightData: "air",
    checked: true,
    disabled: false,
    defaultChecked: false,
    selectfreight: false,
    varients: {},
  };

  toggleChecked = () => {
    this.setState({ checked: !this.state.checked });
  };

  toggleDisable = () => {
    this.setState({ disabled: !this.state.disabled });
  };

  onChangeRetail = (e) => {
    this.setState({
      retail: e.target.checked,
    });
  };

  handleCheckboxChange = (event) => {
    this.props.onUpdate(event);
    this.setState({ freightData: event });
    this.props.updateFreightData(event);
  };

  requestUpdatedQuotation() {
    this.setState(
      {
        loading: true,
      },
      () => {
        if (this.cancelToken) {
          this.cancelToken.cancel();
        }
        this.cancelToken = axios.CancelToken.source();
        axios({
          url: process.env.REACT_APP_API_URL + "/user/create_partner_request",
          method: "post",
          cancelToken: this.cancelToken.token,
          data: {
            type: "vfcatalog_update_quotation",
            data: { vfprodid: this.props.product.vfprodid },
            storeid: this.props.storeid,
            freight_type: this.state.freightData,
          },
          withCredentials: true,
        })
          .then((res) => {
            this.setState({ loading: false });
            return res;
          })
          .then((res) => {
            this.setState({
              response: res.data.msg,
            });
          })
          .catch((e) => {
            this.setState({
              response: "There was an error!",
            });
            // console.log(e);
          });
      }
    );
  }

  // scroll down
  scrollDown() {
    this.props.setActiveTab("variants");
    // console.log(
    //   " this.props.mainRef.current.parent",
    //   this.props.mainRef.current.parentElement
    // );
    this.props.mainRef.current.parentElement.scrollTo(0, 1700);
  }

  render() {
    const checkboxdata = this.state.selectfreight === false ? true : false;
    const { product, quantity, currency } = this.props;
    let moqStart = 999999;
    if (Object.entries(product.moq_sourcing).length > 0) {
      const rangeVals = Object.entries(product.moq_sourcing)[0][0]
        .split("-")
        .map((rv) => Number(rv));
      moqStart = rangeVals[0];
    }

    // let moqSeaStart = 999999;
    // if (Object.entries(product.moq_sourcing_sea).length > 0) {
    //   const seaRangeVals = Object.entries(product.moq_sourcing_sea)[0][0]
    //     .split("-")
    //     .map((rv) => Number(rv));
    //   moqSeaStart = seaRangeVals[0];
    // }

    return (
      <div className="bulk-pricing-wraper">
        {/* retail_sourcing price */}
        {/* <RetailOrderSourcing
          currency={getCurrencySymbol(currency)}
          retailPrice={formatNumber(
            product.default_retail_sourcing
              ? product.default_retail_sourcing.min
              : product.retail_sourcing.min
          )}
        /> */}
        {/* Bulk order pricing table start */}

        {this.props.geo.toLowerCase() === "in" ? (
          <BulkOrderPricing
            scrollDown={() => this.scrollDown()}
            currency={getCurrencySymbol(currency)}
            data={product.moq_sourcing_table}
          />
        ) : null}

        {/* Recommended packaging */}

        {/* Indian air */}
        {/* {Object.keys(product.moq_sourcing).length > 0 &&
        this.props.geo.toLowerCase() === "in" ? (
          <div style={{ marginBottom: 16 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                <span className="bulk-price-checkbox">
                  <Checkbox
                    checked={
                      quantity >= moqStart && this.state.freightData === "air"
                        ? this.state.checked
                        : !this.state.checked
                    }
                    // defaultChecked={false}
                    disabled={
                      quantity >= moqStart ? !checkboxdata : checkboxdata
                    }
                    onChange={() => this.handleCheckboxChange("air")}
                    value={this.state.freightData}
                  />
                </span>
                <span
                  style={{
                    fontSize: 12,
                    padding: "4px 12px 5px",
                    color: "white",
                    background:
                      quantity >= moqStart && this.state.freightData === "air"
                        ? "#1370FC"
                        : "#B5DAFE",
                    borderTopRightRadius: 2,
                  }}
                >
                  Bulk Pricing Table
                </span>
              </span>
              <span
                style={{
                  fontSize: 12,
                  padding: "4px 12px",
                  color: "#898989",
                  background: "#F5F5F5",
                  borderTopLeftRadius: 2,
                  borderTopRightRadius: 2,
                }}
              >
                Air Freight Transit time: 8-10 days
              </span>
            </div>
            <div
              className="f12"
              style={{
                background: "#F5FAFF",
                borderRadius: 2,
                marginBottom: 11,
                overflow: "auto",
                border: "2px solid",
                borderColor:
                  quantity >= moqStart && this.state.freightData === "air"
                    ? "#1370FC"
                    : "#B5DAFE",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <div style={{ flex: 1, minWidth: "22%" }}>
                  <div
                    style={{
                      paddingTop: 12,
                      paddingBottom: 12,
                      paddingLeft: 24,
                      color: "#898989",
                      borderBottom: "1px solid #B5DAFE",
                      borderTopLeftRadius: 2,
                    }}
                  >
                    Quantity:
                  </div>
                  <div
                    style={{
                      paddingTop: 12,
                      paddingBottom: 12,
                      paddingLeft: 24,
                      color: "#898989",
                    }}
                  >
                    Price:
                  </div>
                </div>
                {Object.entries(product.moq_sourcing).map(
                  ([range, data], index) => {
                    const rangeVals = range.split("-").map((rv) => Number(rv));
                    return (
                      <div
                        key={range}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                          minWidth: "20%",
                          fontWeight:
                            quantity >= moqStart &&
                            this.state.freightData === "air"
                              ? "bold"
                              : "normal",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            paddingTop: 12,
                            paddingBottom: 12,
                            borderBottom: "1px solid #B5DAFE",
                          }}
                        >
                          {rangeVals[0]}
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            paddingTop: 12,
                            paddingBottom: 12,
                          }}
                        >
                          {`${getCurrencySymbol(currency)} ${formatNumber(
                            product.moq_sourcing[range].india_express_freight
                              .ppq
                          )}`}
                          {product.default_moq_sourcing &&
                          product.default_moq_sourcing[range] &&
                          product.default_moq_sourcing[range]
                            .india_express_freight &&
                          product.default_moq_sourcing[range]
                            .india_express_freight.ppq ? (
                            <div
                              className="f12"
                              style={{
                                color: "#F66161",
                                textDecoration: "line-through",
                              }}
                            >
                              {`${getCurrencySymbol(currency)} ${formatNumber(
                                product.default_moq_sourcing[range]
                                  .india_express_freight.ppq
                              )}`}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        ) : null} */}
        {/* Indian sea */}
        {/* {product &&
        product.moq_sourcing_sea &&
        Object.keys(product.moq_sourcing_sea).length > 0 &&
        this.props.geo.toLowerCase() === "in" ? (
          <div style={{ marginBottom: 16 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                <span className="sea-freight-checkbox">
                  <Checkbox
                    checked={
                      quantity >= moqStart && this.state.freightData === "sea"
                    }
                    disabled={
                      quantity >= moqStart ? !checkboxdata : checkboxdata
                    }
                    defaultChecked={
                      quantity >= moqStart && this.state.freightData === "sea"
                        ? !checkboxdata
                        : checkboxdata
                    }
                    onChange={() => this.handleCheckboxChange("sea")}
                    value={this.state.freightData}
                  />
                </span>
                <span
                  style={{
                    fontSize: 12,
                    padding: "4px 12px 5px",
                    color: "white",
                    background:
                      quantity >= moqStart && this.state.freightData === "sea"
                        ? "#1370FC"
                        : "#B5DAFE",
                    // borderTopLeftRadius: 2,
                    borderTopRightRadius: 2,
                  }}
                >
                  Bulk Pricing Table
                </span>
              </span>
              <span
                style={{
                  fontSize: 12,
                  padding: "4px 12px",
                  color: "#898989",
                  // background: quantity >= moqStart ? "#1370FC" : "#F5F5F5",
                  background: "#F5F5F5",
                  borderTopLeftRadius: 2,
                  borderTopRightRadius: 2,
                }}
              >
                Sea Freight Transit time: 30-40 days
              </span>
            </div>
            <div
              className="f12"
              style={{
                background: "#F5FAFF",
                borderRadius: 2,
                marginBottom: 11,
                overflow: "auto",
                border: "2px solid",
                borderColor:
                  quantity >= moqStart && this.state.freightData === "sea"
                    ? "#1370FC"
                    : "#B5DAFE",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <div style={{ flex: 1, minWidth: "22%" }}>
                  <div
                    style={{
                      paddingTop: 12,
                      paddingBottom: 12,
                      paddingLeft: 24,
                      color: "#898989",
                      borderBottom: "1px solid #B5DAFE",
                      borderTopLeftRadius: 2,
                    }}
                  >
                    Quantity:
                  </div>
                  <div
                    style={{
                      paddingTop: 12,
                      paddingBottom: 12,
                      paddingLeft: 24,
                      color: "#898989",
                    }}
                  >
                    Price:
                  </div>
                </div>
                {Object.entries(product.moq_sourcing_sea).map(
                  ([range, data], index) => {
                    const seaRangeVals = range
                      .split("-")
                      .map((rv) => Number(rv));

                    return (
                      <div
                        key={range}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                          minWidth: "20%",
                          fontWeight:
                            // quantity >= seaRangeVals[0] &&
                            // quantity <= seaRangeVals[1]
                            //   ? "bold"
                            //   : "normal",
                            quantity >= moqStart &&
                            this.state.freightData === "sea"
                              ? "bold"
                              : "normal",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            paddingTop: 12,
                            paddingBottom: 12,
                            borderBottom: "1px solid #B5DAFE",
                          }}
                        >
                          {seaRangeVals[0]}
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            paddingTop: 12,
                            paddingBottom: 12,
                          }}
                        >
                          {product &&
                          product.moq_sourcing_sea[range].india_sea_freight.ppq
                            ? `${getCurrencySymbol(currency)} ${formatNumber(
                                product.moq_sourcing_sea[range]
                                  .india_sea_freight.ppq
                              )}`
                            : null}
                          {product.default_moq_sourcing_sea &&
                          product.default_moq_sourcing_sea[range] &&
                          product.default_moq_sourcing_sea[range]
                            .india_sea_freight &&
                          product.default_moq_sourcing_sea[range]
                            .india_sea_freight.ppq ? (
                            <div
                              className="f12"
                              style={{
                                color: "#F66161",
                                textDecoration: "line-through",
                              }}
                            >
                              {`${getCurrencySymbol(currency)} ${formatNumber(
                                product.default_moq_sourcing_sea[range]
                                  .india_sea_freight.ppq
                              )}`}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        ) : null} */}
        {/* Global whole sale */}
        {Object.keys(product.moq_sourcing).length > 0 &&
        this.props.geo.toLowerCase() === "global" ? (
          <div style={{ marginBottom: 16 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                <span className="bulk-price-checkbox">
                  <Checkbox
                    checked={
                      quantity >= moqStart && this.state.freightData === "air"
                        ? this.state.checked
                        : !this.state.checked
                    }
                    // defaultChecked={false}
                    disabled={
                      quantity >= moqStart ? !checkboxdata : checkboxdata
                    }
                    onChange={() => this.handleCheckboxChange("air")}
                    value={this.state.freightData}
                  />
                </span>
                <span
                  style={{
                    fontSize: 12,
                    padding: "4px 12px 5px",
                    color: "white",
                    background:
                      quantity >= moqStart && this.state.freightData === "air"
                        ? "#1370FC"
                        : "#B5DAFE",
                    borderTopRightRadius: 2,
                  }}
                >
                  Bulk Pricing Table
                </span>
              </span>
              <span
                style={{
                  fontSize: 12,
                  padding: "4px 12px",
                  color: "#898989",
                  background: "#F5F5F5",
                  borderTopLeftRadius: 2,
                  borderTopRightRadius: 2,
                }}
              >
                Lead time: 3-4 days
              </span>
            </div>
            <div
              className="f12"
              style={{
                background: "#F5FAFF",
                borderRadius: 2,
                marginBottom: 11,
                overflow: "auto",
                border: "2px solid",
                borderColor:
                  quantity >= moqStart && this.state.freightData === "air"
                    ? "#1370FC"
                    : "#B5DAFE",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <div style={{ flex: 1, minWidth: "22%" }}>
                  <div
                    style={{
                      paddingTop: 12,
                      paddingBottom: 12,
                      paddingLeft: 24,
                      color: "#898989",
                      borderBottom: "1px solid #B5DAFE",
                      borderTopLeftRadius: 2,
                    }}
                  >
                    Quantity:
                  </div>
                  <div
                    style={{
                      paddingTop: 12,
                      paddingBottom: 12,
                      paddingLeft: 24,
                      color: "#898989",
                    }}
                  >
                    Price:
                  </div>
                </div>
                {Object.entries(product.moq_sourcing).map(
                  ([range, data], index) => {
                    const rangeVals = range.split("-").map((rv) => Number(rv));
                    return (
                      <div
                        key={range}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                          minWidth: "20%",
                          fontWeight:
                            quantity >= moqStart &&
                            this.state.freightData === "air"
                              ? "bold"
                              : "normal",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            paddingTop: 12,
                            paddingBottom: 12,
                            borderBottom: "1px solid #B5DAFE",
                          }}
                        >
                          {rangeVals[0]}
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            paddingTop: 12,
                            paddingBottom: 12,
                          }}
                        >
                          {`${getCurrencySymbol(currency)} ${formatNumber(
                            product.moq_sourcing[range].india_express_freight
                              .ppq
                          )}`}
                          {product.default_moq_sourcing &&
                          product.default_moq_sourcing[range] &&
                          product.default_moq_sourcing[range]
                            .india_express_freight &&
                          product.default_moq_sourcing[range]
                            .india_express_freight.ppq ? (
                            <div
                              className="f12"
                              style={{
                                color: "#F66161",
                                textDecoration: "line-through",
                              }}
                            >
                              {`${getCurrencySymbol(currency)} ${formatNumber(
                                product.default_moq_sourcing[range]
                                  .india_express_freight.ppq
                              )}`}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        ) : null}
        {hasPermission(this.props.store, "pricing_request_add") &&
        product.updateQuotationFlag === 1 ? (
          this.state.response ? (
            <p
              className="f12"
              style={{
                marginBottom: 20,
                background: "#F0FFEF",
                padding: "4px 8px",
                color: "#898989",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                style={{ verticalAlign: "middle" }}
              >
                <g transform="translate(-368 -600)">
                  <circle
                    cx="12"
                    cy="12"
                    r="12"
                    transform="translate(368 600)"
                    fill="#2bc822"
                  />
                  <path
                    d="M383.81 609.038l-.231-.24a.636.636 0 00-.923 0l-3.921 4.081-1.391-1.447a.635.635 0 00-.923 0l-.231.24a.7.7 0 000 .961l2.082 2.169a.638.638 0 00.923 0l4.615-4.8a.7.7 0 000-.961z"
                    fill="#fff"
                  />
                </g>
              </svg>{" "}
              <span className="underline">
                {this.state.response} You can see your pricing update requests{" "}
                <a
                  href={`/${this.props.geo}/${
                    this.props.storeid
                  }/requests/pricing-requests?search=${product.vfprodid}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </span>
            </p>
          ) : (
            <div style={{ display: "flex" }} className="f12">
              <p
                className="last-updated-pricing"
                style={{ flex: 1, marginBottom: 20 }}
              >
                Pricing last updated on:{" "}
                <span>
                  {" "}
                  {moment(product.costUpdated).format("MMM DD, YYYY")} (
                  {moment().diff(moment(product.costUpdated), "days")} Days ago)
                </span>
              </p>

              {/* {product.request_latest_price.success === 0 ? (
                product.request_latest_price.success === 0 &&
                product.request_latest_price.count === 0 ? (
                  <Tooltip
                    title={
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          letterSpacing: "0.032em",
                        }}
                        className="cursor-pointer"
                      >
                        {" "}
                        Request Latest Price
                        <span
                          style={{
                            fontWeight: "500",
                            textDecoration: "underline",
                            display: "block",
                          }}
                        >
                          Pricing Requests Left: 0
                        </span>
                        <span
                          style={{
                            marginTop: "20px",
                            display: "block",
                          }}
                        >
                          <i>*Your 5 Pricing Requests will be</i>
                        </span>
                        <span
                          style={{
                            display: "block",
                          }}
                        >
                          <i>reset next week.</i>
                        </span>
                      </p>
                    }
                  >
                    <span
                      style={{ float: "right", fontSize: "12px" }}
                      className="cursor-pointer request-latest-price disabled"
                    >
                      <Icon
                        className="disable-request"
                        component={RequestIcon}
                      />
                      Request Latest Price
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          letterSpacing: "0.032em",
                        }}
                        className="cursor-pointer "
                      >
                        Request Latest Price
                        <span
                          style={{
                            fontWeight: "500",
                            textDecoration: "underline",
                            display: "block",
                          }}
                        >
                          {product.request_latest_price.msg}
                        </span>
                      </p>
                    }
                  >
                    <span
                      className="cursor-pointer request-latest-price disabled"
                      style={{ float: "right", fontSize: "12px" }}
                    >
                      <Icon className="request-icon" component={RequestIcon} />
                      Request Latest Price
                    </span>
                  </Tooltip>
                )
              ) : (
                <Tooltip
                  title={
                    <p
                      className="cursor-pointer"
                      style={{ textAlign: "center", fontSize: "12px" }}
                    >
                      Request latest price{" "}
                      <span
                        style={{
                          textAlign: "center",
                          textDecoration: "underline",
                          display: "block",
                        }}
                      >
                        {" "}
                        Pricing Requests Left:{" "}
                        {product.request_latest_price.count}
                      </span>{" "}
                    </p>
                  }
                >
                  <span style={{ float: "right" }}>
                    <span
                      style={{ color: "#0058D6" }}
                      className="underline request-latest-price cursor-pointer"
                      onClick={() => this.requestUpdatedQuotation()}
                    >
                      <Icon
                        className="disable-request"
                        component={RequestIcon}
                      />
                      Request Latest Price
                      <div
                        className="loader"
                        style={{
                          display: this.state.loading ? "inline-block" : "none",
                          width: 15,
                          height: 15,
                          marginLeft: 8,
                          verticalAlign: "bottom",
                        }}
                      />
                    </span>
                  </span>
                </Tooltip>
              )} */}
            </div>
          )
        ) : (
          <p className="last-updated-pricing">
            Pricing last updated on:{" "}
            <span>
              {" "}
              {moment(product.costUpdated).format("MMM DD, YYYY")} (
              {moment().diff(moment(product.costUpdated), "days")} Days ago)
            </span>
          </p>
        )}
      </div>
    );
  }
}

// Retail order price with currency
const RetailOrderSourcing = (props) => {
  const { retailPrice, currency } = props;

  return (
    <div className="retail-order-pricing-wraper">
      <div className="heading">Retail Store Order Pricing</div>
      <div className="pricing-wraper">
        <div className="price">
          <p>
            {currency} {retailPrice}
          </p>
          <span>/piece</span>
        </div>
        <div className="description">
          Includes Product Purchase Price, Local & Cross Border Freight Charges
          & Custom Duty. Excludes any taxes applicable.
        </div>
      </div>
    </div>
  );
};

// Bulk order pricing component
const BulkOrderPricing = (props) => {
  return (
    <div className="bulk-order-pricing-table-wraper">
      <div className="heading-wraper">
        <div className="heading">Bulk Store Order Pricing</div>
        {/* to do */}
        {/* <button onClick={() => props.scrollDown()} className="restocking-btn">
          Inventory Restocking Time <Icon component={RestockingDownArrows} />{" "}
        </button> */}
      </div>
      <div className="pricing-wraper">
        <div className="table-headings">
          <div className="transit-time">Transit time</div>
          <div style={{ display: "none" }} className="vf-days">
            <Icon component={GoldenStar} />0 days
            <Icon component={GoldenStar} />
          </div>
          <div className="air-days">8 - 10 days</div>
          <div className="sea-days">30 - 40 days</div>
        </div>

        <div className="table-sub-headings">
          <div className="transit-time">MOQ</div>
          <div style={{ display: "none" }} className="vf-days">
            VF Inventory{" "}
            <span className="product-tooltip">
              <Tooltip
                title={
                  <p className="small-tooltip-text">
                    The inventory stored at vFulfill warehouse for instant
                    allocation of bulk quantity and faster deliveries. We
                    generally keep these for High Performing or Promising
                    products.
                  </p>
                }
              >
                <Icon
                  className="product-tooltip-icon"
                  component={TooltipIcon}
                />
              </Tooltip>
            </span>
          </div>
          <div className="air-days">Transit Via Air</div>
          <div className="sea-days">Transit Via Sea</div>
        </div>

        {props.data.map((product) => {
          return (
            <div className="table-row-data">
              <div className="transit-time">
                {product.range === "-" ? "-" : product.range.split("-")[0]}
              </div>
              <div style={{ display: "none" }} className="vf-days">
                {product.vf === "-" ? "-" : props.currency + " " + product.vf}
              </div>
              <div className="air-days">
                {product.air === "-" ? "-" : props.currency + " " + product.air}
              </div>
              <div className="sea-days">
                {product.sea === "-" ? "-" : props.currency + " " + product.sea}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};

export default connect(mapStateToProps)(BulkPricing);
