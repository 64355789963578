import React, { Component } from "react";
import Globalsearch from "../../../Common/Globalsearch";
import "./index.scss";

class PricingRequestNav extends Component {
  state = {
    allBtn: false,
    updatedBtn: false,
    pendingbtn: true,
  };
  render() {
    return (
      <div className="PricingRequestNav fullorder_data">
        <div className="RequestNav">
          <div className="ordfill">
            <div className="ordfillwrap">
              <div className="leftdata">
                <div className="requeststxt">
                  <span>Requests</span>
                  <span style={{ margin: "0 8px" }}>&gt;</span>
                  <span className="pricing-updates-underline">
                    Pricing Updates
                  </span>
                </div>
              </div>
            </div>
            <div className="secondwrap">
              <div className="filtertab">
                {/* all tbas  */}

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-pricing-update-underline-animation "
                      : this.state.pendingbtn
                      ? "tab  hover-pricing-update-underline-animation-click"
                      : "tab hover-pricing-update-underline-animation"
                  }
                >
                  <button
                    className={this.state.pendingbtn ? "active" : ""}
                    value="rto"
                    onClick={() => {
                      this.setState({
                        allBtn: false,
                        updatedBtn: false,
                        pendingbtn: true,
                      });
                      this.props.changeFilter({
                        status: "pending",
                      });
                    }}
                  >
                    Pending
                  </button>

                  <div
                    className={` ${
                      this.state.pendingbtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.tabcounts && this.props.tabcounts.pending_count
                      ? this.props.tabcounts.pending_count > 1000
                        ? (
                            Number(this.props.tabcounts.pending_count) / 1000
                          ).toFixed(1) + "k"
                        : this.props.tabcounts.pending_count < 1000
                        ? this.props.tabcounts.pending_count
                        : null
                      : this.props.tabcounts.pending_count === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-pricing-update-underline-animation "
                      : this.state.updatedBtn
                      ? "tab  hover-pricing-update-underline-animation-click"
                      : "tab hover-pricing-update-underline-animation"
                  }
                >
                  <button
                    className={this.state.updatedBtn ? "active" : ""}
                    value="delivered"
                    onClick={() => {
                      this.setState({
                        allBtn: false,
                        updatedBtn: true,
                        pendingbtn: false,
                      });
                      this.props.changeFilter({
                        status: "",
                      });
                    }}
                  >
                    Updated
                  </button>

                  <div
                    className={` ${
                      this.state.updatedBtn ? "backgroundcircle" : "circle "
                    }`}
                  >
                    {this.props.tabcounts && this.props.tabcounts.updated_count
                      ? this.props.tabcounts.updated_count > 1000
                        ? (
                            Number(this.props.tabcounts.updated_count) / 1000
                          ).toFixed(1) + "k"
                        : this.props.tabcounts.updated_count < 1000
                        ? this.props.tabcounts.updated_count
                        : null
                      : this.props.tabcounts.updated_count === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-pricing-update-underline-animation "
                      : this.state.allBtn
                      ? "tab  hover-pricing-update-underline-animation-click"
                      : "tab hover-pricing-update-underline-animation"
                  }
                >
                  <button
                    className={this.state.allBtn ? "active" : "non-active"}
                    value=""
                    onClick={() => {
                      this.setState({
                        allBtn: true,
                        updatedBtn: false,
                        pendingbtn: false,
                      });
                      this.props.changeFilter({
                        status: "all",
                      });
                    }}
                  >
                    All
                  </button>

                  <div
                    className={` ${
                      this.state.allBtn ? "backgroundcircle" : "circle"
                    }`}
                  >
                    {this.props.tabcounts && this.props.tabcounts.all_count
                      ? this.props.tabcounts.all_count > 1000
                        ? (
                            Number(this.props.tabcounts.all_count) / 1000
                          ).toFixed(1) + "k"
                        : this.props.tabcounts.all_count < 1000
                        ? this.props.tabcounts.all_count
                        : null
                      : this.props.tabcounts.all_count === 0
                      ? "0"
                      : "..."}
                  </div>
                </div>

                {/*  Updated tabs  */}

                {/* pending tabs */}
              </div>
            </div>
          </div>
        </div>

        {/* global search */}

        <div className="bottom-data">
          <div className="Globalsearch">
            <Globalsearch
              class={"nextwidth"}
              data={this.props.filters}
              placeholder={"Search by Product ID or Request ID"}
              change={(value) =>
                this.props.changeHandler({ search: value.target.value })
              }
              searchFunc={(value) => this.props.changeFilter({ search: value })}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PricingRequestNav;
