import React, { Component } from 'react'
import WingmanSettings from './WingmanSettings'

export default class WingmanTab extends Component {
  render() {
    console.log("test1",this.props.cod,this.props.prepaid);
    return (
        <div className="contact-tab-container">
        <div className="contact-tab-list">
          <div className="contact-tab-header">
            <div className="colling-services">
              <h2>Wingman  Services</h2>
            </div>
          </div>
          <WingmanSettings 
        //   storeId={this.props.match.params.storeid}
          updateSetting={(a, b, c) => this.props.updateSetting(a, b, c)}
          cod={this.props.cod}
          prepaid={this.props.prepaid}
          ndr={this.props.ndr}
        //   fetchContactCenter={() => this.props.fetchContactCenter()}
          />
          {/* <ContactCenterSettings
            
          /> */}
        </div>
      </div>
    )
  }
}
