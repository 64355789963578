import React from "react";
import { Modal, message } from "antd";
import axios from "axios";
import {
  SHOPIFY_CLIENT_ID,
  BASE_URL,
  SHOPIFY_NAME,
} from "../../../../constants";
import ConnectToShopify2 from "../../Settings/Shopify/ConnectToShopify2";

import "./index.scss";

class AddStoreModal2 extends React.Component {
  state = {
    geo: "in",
    selectedGeo: "global",
    addStoreModalVisible: true,
  };

  handleSubmit = (shop, storeid, geo) => {
    geo = "in";

    axios({
      url: process.env.REACT_APP_API_URL + "/store/add_store",
      method: "post",
      data: {
        shop,
        storeid,
        geo,
        timezone: new Date().getTimezoneOffset(),
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          window.location =
            `https://${shop}.myshopify.com/admin/oauth/authorize?client_id=${SHOPIFY_CLIENT_ID}&scope=read_product_listings,read_products,write_products,read_orders,write_orders,read_inventory,write_customers,write_inventory,write_price_rules,read_draft_orders,write_draft_orders,read_customers,write_customers,read_checkouts,write_checkouts,read_fulfillments,read_merchant_managed_fulfillment_orders,write_merchant_managed_fulfillment_orders,read_assigned_fulfillment_orders,write_assigned_fulfillment_orders,read_third_party_fulfillment_orders,write_third_party_fulfillment_orders,write_fulfillments&redirect_uri=` +
            BASE_URL +
            `login/shopify_callback&state=${res.data.id}`;
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  componentDidUpdate(pp) {
    if (pp.visible !== this.props.visible) {
      this.setState({
        geo: "",
        selectedGeo: "global",
      });
    }
  }

  toggleAddStoreModal() {
    this.setState({
      addStoreModalVisible: !this.state.addStoreModalVisible,
    });
  }

  render() {
    const props = this.props;

    return (
      <Modal
        className="switch-store-modal"
        width={530}
        title={
          <h3
            style={{
              fontWeight: "500",
              textAlign: "center",
              fontSize: "16px",
              lineHeight: "24px",
              letterSpacing: "0.032em",
            }}
          >
            {`Add New ${SHOPIFY_NAME}`}
          </h3>
        }
        visible={this.state.addStoreModalVisible}
        // visible={true}
        onCancel={() => this.toggleAddStoreModal()}
        footer={null}
        destroyOnClose={true}
      >
        <ConnectToShopify2
          showCancel={true}
          alignButtonsRight={true}
          onCancel={() => this.toggleAddStoreModal()}
          handleOk={(val) => this.handleSubmit(val, props.storeid)}
          {...props}
        />
        {/* )} */}
      </Modal>
    );
  }
}

export default AddStoreModal2;
