import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import "./index.scss";
import { Alert, Button, message, Skeleton, Switch, Icon } from "antd";
import BreadCrumbs from "./BreadCrumbs";
import ImageViewer from "./ImageViewer";
import BasicDetails from "./BasicDetails";
// import BulkPricing from "./BulkPricing";
import GlobalBulkPricing from "./GlobalBulkPricing";
import VariantSelector from "./VariantSelector";
import BookInventoryFloatingWidget from "./BookInventoryFloatingWidget/index.js";
import OverviewTab from "./OverviewTab";
import ShippingTab from "./ShippingTab";
import VariantsTab from "./VariantsTab/index.js";
import MediaTab from "./MediaTab";
import AdvertisingTab from "./AdvertisingTab";
import ReviewsTab from "./ReviewsTab";
// import CategoriesBar from "../CategoriesBar";
// import { Link } from "react-router-dom";
import ZoomModal from "./ZoomModal";
import { ReactComponent as global } from "../../../../assets/images/icons/global.svg";
import { ReactComponent as india } from "../../../../assets/images/icons/india.svg";
import ActionNotification from "../../Common/ActionNotification";

const defaultState = {
  loading: true,
  appInstall: false,
  error: false,
  product: null,
  selectedImageIndex: 0,
  activeTab: "overview",
  fixCheckout: false,
  checkoutLeft: 0,
  radio: undefined,
  checkoutTop: 0,
  checkoutHeight: 0,
  selectedVariants: {},
  // selectedVariantsData: {},
  categories: [],
  niches: [],
  // filters: [],

  filters: {
    niche: [],
  },
  bookingConfirmationModal: {
    visible: false,
  },
  zoomModal: {
    visible: false,
  },
  freightkey: "",
  moqvalue: "",
  bulkpricerange: "",
};

class CatalogProduct extends Component {
  constructor(props) {
    super(props);
    this.bottomSectionRef = React.createRef();
    this.checkoutBoxRef = React.createRef();
    this.mainRef = React.createRef();
  }

  state = { ...defaultState };

  onUpdatemoqvqlue = (val) => {
    this.setState({
      moqvalue: val,
    });
  };

  onUpdatebulkpricerange = (val) => {
    this.setState({
      bulkpricerange: val,
    });
  };

  updateSelectedVariants(k, v) {
    this.setState((state) => ({
      selectedVariants: {
        ...state.selectedVariants,
        [k]: Number(v),
      },
    }));
  }
  updateFreightData = (ftype) => {
    this.setState({
      freightkey: ftype,
    });
  };
  // updateSelectedVariantsData(k, v) {
  //   this.setState((state) => ({
  //     selectedVariantsData: {
  //       ...state.selectedVariantsData,
  //       [k]: Number(v),
  //     },
  //   }));
  // }

  getSelectedVariantQuantity() {
    return Object.values(this.state.selectedVariants).reduce(
      (acc, qty) => acc + qty,
      0
    );
  }

  // getSelectedVariantQuantitySea() {
  //   return Object.values(this.state.selectedVariantsData).reduce(
  //     (acc, qty) => acc + qty,
  //     0
  //   );
  // }

  onUpdate = (val) => {
    this.setState({
      fieldVal: val,
    });
  };

  setActiveTab(activeTab) {
    this.setState({ activeTab });
  }

  scrollListener = () => {
    if (!!this.mainRef.current) {
      let y = Math.abs(this.mainRef.current.getBoundingClientRect().y);

      const rect = this.checkoutBoxRef.current.getBoundingClientRect();
      if (this.bottomSectionRef.current && this.checkoutBoxRef.current) {
        if (y > 652) {
          this.setState({
            fixCheckout: true,
            checkoutLeft: rect.left,
            checkoutTop: this.state.appInstall ? 130 : 45,
            checkoutHeight: rect.height,
          });
        } else {
          this.setState({
            fixCheckout: false,
            checkoutLeft: 0,
            checkoutTop: 0,
          });
        }
      }
    }
  };

  loadProductData() {
    this.setState({ loading: true, error: false }, () => {
      axios({
        url: process.env.REACT_APP_API_URL + "/products/search",
        method: "post",
        data: {
          storeid: this.props.match.params.storeid,
          filters: { id: this.props.match.params.vfprodid, packaging: 1 },
          geo: !!this.state.radio
            ? this.state.radio
            : this.props.match.params.storegeo,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            const upd = {};
            if (res.data.products.length) {
              upd.loading = false;
              upd.product = res.data.products[0];
              upd.categories = res.data.categories;
              upd.niches = res.data.niches;
              upd.selectedVariants = {};
              upd.error = false;
            } else {
              upd.error = true;
              upd.loading = false;
            }
            this.setState(upd);
          } else {
            this.setState({
              error: true,
              errorMsg: res.data.msg,
              loading: false,
            });
          }
        })
        .catch((e) => {
          this.setState({
            error: true,
            errorMsg: e.message,
            loading: false,
          });
        });
    });
  }

  defaultStateSet() {
    this.setState(
      {
        loading: true,
        error: false,
        appInstall: false,
        product: null,
        selectedImageIndex: 0,
        activeTab: "overview",
        fixCheckout: false,
        checkoutLeft: 0,
        radio: undefined,
        checkoutTop: 0,
        checkoutHeight: 0,
        selectedVariants: {},
        // selectedVariantsData: {},
        categories: [],
        niches: [],
        // filters: [],

        filters: {
          niche: [],
        },
        bookingConfirmationModal: {
          visible: false,
        },
        zoomModal: {
          visible: false,
        },
        freightkey: "",
        moqvalue: "",
        bulkpricerange: "",
      },
      () => this.loadProductData()
    );
  }

  componentDidUpdate(pp) {
    if (!!this.state.radio) {
    } else {
      if (pp.match.params.storegeo !== this.props.match.params.storegeo) {
        this.setState({ ...defaultState }, () => this.loadProductData());
      }
    }
  }

  componentDidMount() {
    setTimeout(() => {
      let appInstall = document.querySelector(".important-info");

      if (appInstall !== null) {
        this.setState({ appInstall: true });
      }
    }, 3000);
    window.addEventListener("scroll", this.scrollListener, true);
    this.loadProductData();
    this.setState({ radio: this.props.match.params.storegeo });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollListener, true);
  }

  importProducts = (productIds, name) => {
    axios({
      url:
        process.env.REACT_APP_API_URL + "/products/import_products_aliexpress",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        productids: productIds,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          // message.success(
          //   "Product import in progress. Please visit import list section to view imported products"
          // );

          ActionNotification({
            notificationType: "importProduct",
            name: name,
          });
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  setSelectedImage(selectedImageIndex) {
    this.setState({ selectedImageIndex });
  }

  hasAdvertisingData() {
    return (
      this.state.product &&
      this.state.product.advertising_data &&
      (this.state.product.advertising_data.facebook_targeting ||
        this.state.product.advertising_data.facebook_ad_videos ||
        this.state.product.advertising_data.instagram_influencers)
    );
  }

  hasReviewData() {
    return (
      this.state.product &&
      this.state.product.advertising_data &&
      (this.state.product.advertising_data.rating_listing ||
        this.state.product.advertising_data.user_ratings)
    );
  }

  toggleZoomModal(src, type) {
    this.setState((state) => ({
      zoomModal: {
        ...state.zoomModal,
        src,
        type,
        visible: !state.zoomModal.visible,
      },
    }));
  }

  onFilterChange(key, value) {
    let tempItem = [];
    const {
      filters: { niche },
    } = this.state;
    if (key === "niche") {
      tempItem = niche.length > 0 ? [...niche] : [];
      const index = tempItem.indexOf(value.name);
      if (value.status && index === -1) {
        tempItem.push(value.name);
      } else if (!value.status && index !== -1) {
        tempItem.splice(index, 1);
      }
    }

    this.setState({
      filters: {
        ...this.state.filters,
        [key]: key === "niche" ? tempItem : value,
      },
      paging: { page: 1, per_page_count: 20 },
    });
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active />;
    }

    if (this.state.error) {
      return (
        <Alert
          message="Error"
          description="There was an error fetching this product."
          type="error"
          showIcon
        />
      );
    }

    const product = this.state.product;
    const currency = !!this.state.radio
      ? this.state.radio === "in"
        ? "INR"
        : "USD"
      : this.props.match.params.storegeo === "in"
      ? "INR"
      : "USD";

    return (
      <div className="catalog-product" ref={this.mainRef}>
        <div className="top-section-container">
          {/* <CategoriesBar
            categories={this.state.categories}
            niches={this.state.niches}
            geo={this.props.match.params.storegeo}
            storeid={this.props.match.params.storeid}
            filters={this.state.filters}
            product={this.state.products}
            onChange={(k, v) => this.onFilterChange(k, v)}
          /> */}

          <div className="top-section">
            <BreadCrumbs {...this.props} name={product.catalogname} />
            {/* global radios */}

            {this.props.match.params.storegeo ===
            "in" ? null : product.public === 1 ? (
              <div className="gr" style={{ marginBottom: "10px" }}>
                <Icon component={india} />{" "}
                <span style={{ margin: "0 8px" }}>
                  <Switch
                    onClick={() =>
                      this.setState(
                        {
                          radio:
                            this.state.radio === "global" ? "in" : "global",
                        },
                        () => this.loadProductData()
                      )
                    }
                    checked={this.state.radio === "global"}
                  />
                </span>
                <Icon component={global} />
              </div>
            ) : null}

            <div style={{ display: "flex" }}>
              {/* left image div */}
              <div style={{ width: 272, marginRight: 56 }}>
                <ImageViewer
                  images={product.carousal_image.map((img) => img)}
                  selected={this.state.selectedImageIndex}
                  onChange={(idx) => this.setSelectedImage(idx)}
                />
                {/* <Button
                  type="primary"
                  className="w100 btn-green f16 py8 add-to-store mt16 mb16"
                  onClick={() =>
                    this.importProducts([product.vfprodid], product.name)
                  }
                >
                  Add to Import List
                </Button> */}
                {product.hsn_attributes && product.hsn_attributes.hsn ? (
                  <p
                    className="f12"
                    style={{
                      marginTop: "24px",
                      background: "#E3E3E3",
                      color: "#898989",
                      width: "100%",
                      textAlign: "center",
                      padding: "4px 0",
                    }}
                  >
                    HSN: {product.hsn_attributes.hsn}
                  </p>
                ) : null}
                <p className="cost-updated">
                  {" "}
                  Product Details Last Updated On:{" "}
                  <span>
                    {" "}
                    {moment(product.costUpdated).format("MMM DD, YYYY")}
                  </span>
                </p>
              </div>

              {/* center div */}
              <div
                className="center-details"
                style={{ flex: 1, marginRight: 40 }}
              >
                <BasicDetails
                  setActiveTab={() => this.setActiveTab("reviews")}
                  product={product}
                  geo={
                    !!this.state.radio
                      ? this.state.radio
                      : this.props.match.params.storegeo
                  }
                  goToReviewsTab={() =>
                    this.hasReviewData() ? this.setActiveTab("reviews") : null
                  }
                />
                {/* {this.props.geo === "in" ? (
                  <BulkPricing
                    radio={this.state.radio}
                    currency={currency}
                    product={product}
                    geo={
                      !!this.state.radio
                        ? this.state.radio
                        : this.props.match.params.storegeo
                    }
                    storeid={this.props.match.params.storeid}
                    quantity={this.getSelectedVariantQuantity()}
                    // quantitySea={this.getSelectedVariantQuantitySea()}
                    onUpdate={this.onUpdate}
                    variants={this.state.selectedVariants}
                    onUpdatebulkpricerange={this.onUpdatebulkpricerange}
                    updateFreightData={this.updateFreightData}
                  />
                ) : ( */}
                <GlobalBulkPricing
                  setActiveTab={(tab) => this.setActiveTab(tab)}
                  radio={this.state.radio}
                  currency={currency}
                  product={product}
                  mainRef={this.mainRef}
                  geo={
                    !!this.state.radio
                      ? this.state.radio
                      : this.props.match.params.storegeo
                  }
                  storeid={this.props.match.params.storeid}
                  quantity={this.getSelectedVariantQuantity()}
                  // quantitySea={this.getSelectedVariantQuantitySea()}
                  onUpdate={this.onUpdate}
                  variants={this.state.selectedVariants}
                  onUpdatebulkpricerange={this.onUpdatebulkpricerange}
                  updateFreightData={this.updateFreightData}
                />
                {/* )} */}
                <VariantSelector
                  product={product}
                  currency={currency}
                  variants={product.variants}
                  cr={product.carousal_image}
                  pricing={product.moq_sourcing_table[0]["air"]}
                  setSelectedImage={(index) => this.setSelectedImage(index)}
                  updateSelectedVariants={(k, v) =>
                    this.updateSelectedVariants(k, v)
                  }
                  onUpdate={this.onUpdate}
                  updateFreightData={this.updateFreightData}
                  onUpdatemoqvqlue={this.onUpdatemoqvqlue}
                />
                {/* <p
                  className="f12"
                  style={{ color: "#898989", marginBottom: 20 }}>
                  Available For:
                  {currency === "INR" ? (
                    <span
                      style={{
                        display: "inline-block",
                        background: "#2BC822",
                        color: "#FFFFFF",
                        padding: "4px 8px",
                        borderRadius: 2,
                        marginLeft: 8,
                      }}
                    >
                      India Dropshipping
                    </span>
                  ) : (
                    <Link
                      to={`/in/${this.props.match.params.storeid}/products/${
                        this.props.match.params.vfprodid
                      }`}
                    >
                      <span
                        style={{
                          display: "inline-block",
                          background: "#EDEDED",
                          color: "#707070",
                          padding: "4px 8px",
                          borderRadius: 2,
                          marginLeft: 8,
                        }}
                      >
                        India Dropshipping
                      </span>
                    </Link>
                  )}
                  {currency === "USD" ? (
                    <span
                      style={{
                        display: "inline-block",
                        background: "#2BC822",
                        color: "#FFFFFF",
                        padding: "4px 8px",
                        borderRadius: 2,
                        marginLeft: 12,
                        marginRight: 8,
                      }}
                    >
                      Global Dropshipping
                    </span>
                  ) : (
                    <Link
                      to={`/global/${
                        this.props.match.params.storeid
                      }/products/${this.props.match.params.vfprodid}`}
                    >
                      <span
                        style={{
                          display: "inline-block",
                          background: "#EDEDED",
                          color: "#707070",
                          padding: "4px 8px",
                          borderRadius: 2,
                          marginLeft: 12,
                          marginRight: 8,
                        }}
                      >
                        Global Dropshipping
                      </span>
                    </Link>
                  )}
                </p> */}
                {/* <p
                  className="f12"
                  style={{ color: "#898989", marginBottom: "24px" }}
                >
                  Est. Delivery Time:{" "}
                  <span style={{ color: "black" }}>4-6 Days</span> (Trackable)
                </p> */}
                {/* <div className="customize-data">
                  <div>
                    <span
                      className="f12"
                      style={{ color: "#898989", marginRight: "10px" }}
                    >
                      Customization:
                    </span>
                  </div>
                  <div>
                    <p>
                      <span className="f12" style={{ color: "#21212" }}>
                        Customized Logo
                      </span>
                      <span
                        className="f12"
                        style={{ color: "#898989", fontStyle: "italic" }}
                      >
                        {" "}
                        (MOQ: 500 pieces)
                      </span>
                    </p>
                    <p>
                      <span className="f12" style={{ color: "#21212" }}>
                        Customized Packaging
                      </span>
                      <span
                        className="f12"
                        style={{ color: "#898989", fontStyle: "italic" }}
                      >
                        {" "}
                        (MOQ: 1500 pieces)
                      </span>
                    </p>

                    <p>
                      <span className="f12" style={{ color: "#21212" }}>
                        Customized Graphics
                      </span>
                      <span
                        className="f12"
                        style={{ color: "#898989", fontStyle: "italic" }}
                      >
                        {" "}
                        (MOQ: 1000 pieces)
                      </span>
                    </p>
                    <p>
                      <span className="f12" style={{ color: "#21212" }}>
                        We provide packaging advice over MOQ of 1000 pieces.
                      </span>
                    </p>
                  </div>
                </div> */}
                <div className="bottom-pointer">
                  <div>
                    <span className="bulet-no" />
                    <span className="f12" style={{ color: "#898989" }}>
                      vFulfill.io Ecommerce Solutions
                    </span>
                  </div>
                  <div>
                    <span className="bulet-no" />
                    <span className="f12" style={{ color: "#898989" }}>
                      Powered by VCFL Express
                    </span>
                  </div>
                  <div>
                    <span className="bulet-no" />
                    <span className="f12" style={{ color: "#898989" }}>
                      Real Time Tracking
                    </span>
                  </div>
                </div>
              </div>

              {/* right side floating div */}
              <div
                ref={this.checkoutBoxRef}
                style={{
                  width: 290,
                }}
              >
                <div
                  style={{
                    padding: "20px 16px",
                    position: this.state.fixCheckout ? "fixed" : "relative",
                    top: this.state.checkoutTop,
                    left: this.state.checkoutLeft,
                    animation: this.state.fixCheckout
                      ? "animationdown 1s "
                      : "none",
                  }}
                >
                  <BookInventoryFloatingWidget
                    radio={this.state.radio}
                    storeGeo={
                      this.props.match.params.storegeo === "in"
                        ? "in"
                        : "global"
                    }
                    geo={
                      !!this.state.radio
                        ? this.state.radio
                        : this.props.match.params.storegeo
                    }
                    currency={currency}
                    suggestedPrice={product.suggested_sellingprice}
                    globalSuggested={product.global_suggestedsellingprice}
                    vfprodid={this.props.match.params.vfprodid}
                    storeid={this.props.match.params.storeid}
                    variants={this.state.selectedVariants}
                    gstPercentage={product.hsn_attributes.gst}
                    quantity={this.getSelectedVariantQuantity()}
                    importProducts={(ids) => this.importProducts(ids)}
                    catalogName={product.catalogname}
                    typ={this.state.fieldVal}
                    catalogImage={product.images}
                    selectedImgIndex={this.state.selectedImageIndex}
                    importStoreData={this.state.fixCheckout}
                    freightData={this.state.freightkey}
                    product={product}
                    moqvalue={this.state.moqvalue}
                    loadProductData={() => this.defaultStateSet()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-section-container">
          <div
            className="bottom-section"
            // style={{ minHeight: this.state.checkoutHeight }}
          >
            <ul className="tabs" ref={this.bottomSectionRef}>
              <li
                className={this.state.activeTab === "overview" ? "active" : ""}
              >
                <button
                  className="f12"
                  onClick={() => this.setActiveTab("overview")}
                >
                  Overview
                </button>
              </li>
              {/* <li
                className={this.state.activeTab === "shipping" ? "active" : ""}
              >
                <button
                  className="f12"
                  onClick={() => this.setActiveTab("shipping")}
                >
                  Shipping Details
                </button>
              </li> */}
              <li
                className={this.state.activeTab === "variants" ? "active" : ""}
              >
                <button
                  className="f12"
                  onClick={() => this.setActiveTab("variants")}
                >
                  Variants & Inventory
                </button>
              </li>
              <li className={this.state.activeTab === "media" ? "active" : ""}>
                <button
                  className="f12"
                  onClick={() => this.setActiveTab("media")}
                >
                  Media
                </button>
              </li>

              {/*   <li
                className={
                  this.state.activeTab === "advertising" ? "active" : ""
                }
              >
                <button
                  className="f12"
                  onClick={() => this.setActiveTab("advertising")}
                >
                  Advertising Data
                </button>
              </li>
            
              <li
                className={this.state.activeTab === "reviews" ? "active" : ""}
              >
                <button
                  className="f12"
                  onClick={() => this.setActiveTab("reviews")}
                >
                  Reviews & Ratings
                </button>
              </li> */}

              {/* ) : null} */}
            </ul>
            <div className="tabs-content">
              <div className="tabs-content-container">
                <div
                  style={{
                    display:
                      this.state.activeTab === "overview" ? "block" : "none",
                  }}
                >
                  <OverviewTab
                    radio={this.state.radio}
                    product={product}
                    niches={this.state.niches}
                    geo={
                      !!this.state.radio
                        ? this.state.radio
                        : this.props.match.params.storegeo
                    }
                  />
                </div>
                <div
                  style={{
                    display:
                      this.state.activeTab === "shipping" ? "block" : "none",
                  }}
                >
                  {this.props.match.params.storegeo === "in" ? (
                    <ShippingTab
                      currency={currency}
                      ratecard={product.shipratecard}
                      codCharges={product.cod_charges}
                      storgeo={this.props.match.params.storegeo}
                    />
                  ) : (
                    <ShippingTab
                      currency={currency}
                      ratecard={product.shipratecard}
                      codCharges={product.cod_charges}
                      storgeo={this.props.match.params.storegeo}
                    />
                  )}
                </div>
                <div
                  style={{
                    display:
                      this.state.activeTab === "variants" ? "block" : "none",
                  }}
                >
                  <VariantsTab
                    inventoryRestocking={product.restocking_time}
                    variants={product.variants}
                  />
                </div>
                <div
                  style={{
                    display:
                      this.state.activeTab === "media" ? "block" : "none",
                  }}
                >
                  <MediaTab
                    images={product.images}
                    videos={product.videos}
                    toggleZoomModal={(src, type) =>
                      this.toggleZoomModal(src, type)
                    }
                  />
                </div>
                <div
                  style={{
                    display:
                      this.state.activeTab === "advertising" ? "block" : "none",
                  }}
                >
                  <AdvertisingTab
                    data={product.advertising_data}
                    ad_copy_images={
                      product.ad_copy_images ? product.ad_copy_images : []
                    }
                    ad_copy_videos={
                      product.ad_copy_videos ? product.ad_copy_videos : []
                    }
                    ad_copies={product.ad_copies ? product.ad_copies : []}
                  />
                </div>

                <div
                  style={{
                    display:
                      this.state.activeTab === "reviews" ? "block" : "none",
                  }}
                >
                  <ReviewsTab data={product.rating} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ZoomModal
          {...this.state.zoomModal}
          toggleModal={() => this.toggleZoomModal()}
        />
      </div>
    );
  }
}

export default CatalogProduct;
