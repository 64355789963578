import React, { Component } from "react";
import {
  Row,
  DatePicker,
  Card,
  message,
  Skeleton,
  Checkbox,
  Alert,
  Dropdown,
  Menu,
  Button,
  Modal,
  Icon,
  Pagination,
} from "antd";
import axios from "axios";
import "./index.scss";
import NdrCard from "./NdrCard";

import NDREscalationModal from "./NDREscalationModal";
import NDRReAttemptModal from "./NDRReAttemptModal";
import moment from "moment";
import qs from "qs";
import { connect } from "react-redux";
// import { hasPermission } from "../../../Helpers/Permissions";
import ActionNotification from "../Common/ActionNotification";
import NotFoundCard from "../../../Components/Dashboard/Common/NotFoundCard";
// import Globalsearch from ".././Common/Globalsearch";
import NdrOrderNav from "./Ndrnav";
import ErrorCard from "../../../Components/Dashboard/Common/ErrorCard";
import BucketFilter from "./BucketFilter/BucketFilter";
import AttemptFilter from "./BucketFilter/BucketFilter";
import SingleSelectFilter from "./SingleSelectFilter";
import GlobalTooltip from "../Common/GlobalTooltipIcon";
import { data } from "browserslist";
import { ReactComponent as Arrow } from "../../../assets/images/icons/arrow.svg";
import { ReactComponent as GreenTick } from "../../../assets/images/icons/green-tick.svg";

// const { RangePicker } = DatePicker;
// const { Search } = Input;

class Ndr extends Component {
  state = {
    newData: {},
    errorsdata: {},
    loading: false,
    error: false,
    errorCode: "",
    errorMsg: "",
    total: 0,
    arrow: false,
    paging: {
      page: 1,
      pageSize: 10,
    },
    ndrs: [],
    selectAll: false,
    selectedArr: [],
    actionType: "",
    bucketActionModalVisible: false,
    bucketLoading: false,
    filters: {
      startDate: "",
      endDate: "",
      pagename: [],
      status: this.props.status,
      search: "",
      action_status: "",
      shipment_status: [],
    },
    ndrEscalationModal: {
      visible: false,
      fileList: [],
      shipmentId: "",
      orderid: "",
      uploading: false,
      shipmentId: "",
      awb: "",
    },
    NDRReAttemptModal: {
      visible: false,
      shipmentId: "",
      awb: "",
    },
    ndrcount: [],
    reattemptagain: false,
    date: "",
    remarks: "",
    clear: false,
    fileList: [],

    ndr_bucket: [],
    ndr_count: [],
    payment_type: "",
    reattamptDate: "",
  };

  bucket = {
    0: "Exception",
    1: "Customer Unavailable",
    2: "Rejected by Customer",
    3: "Delivery Rescheduled",
    4: "No Attempt",
    5: "Customer Unreachable",
    6: "Address Issue",
    7: "Payment Issue",
    8: "Out of Delivery Area",
    9: "Order Already Cancelled",
    10: "Self Collect",
    11: "Shipment Seized by Customer",
    12: "Customer Wants Open Delivery",
    13: "Shipment Misrouted by Logistics Partner",
    14: "OTP-Based Cancellation",
  };

  removeRemarkhnadler(value) {
    this.setState({
      // ...this.state.remarks,
      remarks: value,
    });
  }

  newDataHandler(object) {
    this.setState({ newData: object });
  }

  datehandler(obj2) {
    this.setState({ date: obj2 });
  }

  reattempttoggle() {
    this.setState({ reattemptagain: !this.state.reattemptagain });
  }

  toggleNdrEscalationModal(
    shipmentid = "",
    orderid = "",
    reattempt = false,
    awb = "",
    address = { customer_details: {}, shipping_address: {} },
    shopifyId = "",
    vforderid = "",
    shopifyLink = ""
  ) {
    this.setState({
      ndrEscalationModal: {
        ...this.state.ndrEscalationModal,
        visible: !this.state.ndrEscalationModal.visible,
        shipmentId: shipmentid,
        orderid: orderid,
        reattempt,
        awb: awb,
        address,
        shopifyId: shopifyId,
        vforderid: vforderid,
        order_vfid: vforderid,
      },

      fileList: [],
      reattemptagain: false,
      remarks: "",
      shopifyLink: shopifyLink,
    });
  }

  toggleNdrReAttemptModal(
    shipmentid = "",
    awb = "",
    address = { customer_details: {}, shipping_address: {} }
  ) {
    this.setState({
      NDRReAttemptModal: {
        ...this.state.NDRReAttemptModal,
        visible: !this.state.NDRReAttemptModal.visible,
        shipmentId: shipmentid,
        awb: awb,
        address,
      },
    });
  }

  searchhandler() {
    if (window.location.href.includes("open")) {
      this.props.history.push(
        `/in/${this.props.match.params.storeid}/ndr/open?search=${this.state.filters.search}`
      );
    } else if (window.location.href.includes("responded")) {
      this.props.history.push(
        `/in/${this.props.match.params.storeid}/ndr/responded?search=${this.state.filters.search}`
      );
    } else if (window.location.href.includes("expired")) {
      this.props.history.push(
        `/in/${this.props.match.params.storeid}/ndr/expired?search=${this.state.filters.search}`
      );
    } else {
      this.props.history.push(
        `/in/${this.props.match.params.storeid}/ndr/all?search=${this.state.filters.search}`
      );
    }
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search.replace(/\?/g, ""));
    if (params.search) {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: params.search,
            status: "All",
            pagename: ["All"],
          },
        },
        () => this.loadData()
      );
    } else {
      this.loadData();
    }
  }

  loadData() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/shipments/get_ndrs",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            filters: this.state.filters,
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
            ndr_bucket: this.state.ndr_bucket,
            ndr_count: this.state.ndr_count,
            payment_type: this.state.payment_type,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              if (!!res.data.shipments.length) {
                this.setState({
                  ndrs: res.data.shipments.map((d) => {
                    d.checked = false;
                    return d;
                  }),
                  selectAll: false,
                  selectedArr: [],
                  ndrcount: res.data.ndr_counts,
                  total: res.data.total,
                  loading: false,
                  error: false,
                  errorMsg: "",
                  actionType: "",
                  reattamptDate: "",
                });
              } else {
                this.setState({
                  ndrs: res.data.shipments,
                  selectAll: false,
                  selectedArr: [],
                  ndrcount: res.data.ndr_counts,
                  total: res.data.total,
                  loading: false,
                  error: false,
                  actionType: "",

                  errorMsg: "",
                });
              }
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
                loading: false,
              });
            }
          })
          .catch((e) => {
            if (!!e.request) {
              this.setState({
                error: true,
                errorCode: e.request.status,
                errorMsg: e.request.statusText,
                loading: false,
              });
            } else {
              this.setState({
                error: false,
                errorMsg: e.message,
                loading: true,
              });
            }
          });
      }
    );
  }

  validatendrcustomerdetail() {
    const order = this.state.newData;
    const date = this.state.date;
    let errors = {};

    if (order.shipping_address.name === "") {
      errors.customer_details_name = "This is a required field!";
    }
    if (order.shipping_address.address1 === "") {
      errors.shipping_address_address1 = "This is a required field!";
    }
    if (order.shipping_address.zip === "") {
      errors.shipping_address_zip = "This is a required field!";
    }
    if (order.customer_details.email === "") {
      errors.customer_details_email = "This is a required field!";
    }
    if (order.shipping_address.country === "") {
      errors.shipping_address_country = "This is a required field!";
    }
    if (order.shipping_address.city === "") {
      errors.shipping_address_city = "This is a required field!";
    }
    if (order.shipping_address.province === "") {
      errors.shipping_address_province = "This is a required field!";
    }
    if (!moment(date).isValid()) {
      errors.date = "This is a required field!";
    }
    this.setState({ errorsdata: errors });
    return errors;
  }

  onSaveEscalation(file, validateOnly = false) {
    if (!this.state.reattemptagain) {
      const formData = new FormData();
      file.forEach((file) => {
        formData.append("file[]", file);
      });

      formData.append("storeid", this.props.match.params.storeid);
      formData.append("orderid", this.state.ndrEscalationModal.orderid);
      formData.append("shipmentid", this.state.ndrEscalationModal.shipmentId);
      formData.append("remarks", this.state.remarks);
      this.setState(
        {
          ndrEscalationModal: {
            ...this.state.ndrEscalationModal,
            uploading: true,
          },
        },
        () => {
          axios({
            url:
              process.env.REACT_APP_API_URL +
              "/shipments/escalate_and_reattempt_ndr",
            method: "post",
            data: formData,
            withCredentials: true,
          })
            .then((res) => {
              if (res.data.success === 1) {
                ActionNotification({
                  notificationType: "re-attempt-and-escalate",
                  awb: this.state.ndrEscalationModal.awb,
                });

                const reattempt = this.state.ndrEscalationModal.reattempt;
                if (reattempt) {
                  const ndr = this.state.ndrs.find(
                    (n) =>
                      n.shipment_vfid ===
                      this.state.ndrEscalationModal.shipmentId
                  );
                  this.toggleNdrEscalationModal(
                    (reattempt = false),
                    ndr.shipment_vfid,
                    ndr.awb,
                    {
                      customer_details: ndr.customer_details,
                      shipping_address: ndr.shipping_address,
                    }
                  );
                }
                this.setState((state) => ({
                  ndrEscalationModal: {
                    ...this.state.ndrEscalationModal,
                    visible: !state.ndrEscalationModal.visible,
                    uploading: false,
                  },
                }));
                // message.success("Updated Successfully!");
              } else {
                this.setState({
                  ndrEscalationModal: {
                    ...this.state.ndrEscalationModal,
                    uploading: false,
                  },
                });
                message.error(res.data.msg);
              }
            })
            .catch((e) => {
              this.setState({
                ndrEscalationModal: {
                  ...this.state.ndrEscalationModal,
                  uploading: false,
                },
              });
              message.error(e.message);
            });
        }
      );
    } else {
      const errors = this.validatendrcustomerdetail();

      if (Object.keys(errors).length === 0) {
        const formData = new FormData();
        file.forEach((file) => {
          formData.append("file[]", file);
        });

        formData.append("storeid", this.props.match.params.storeid);
        formData.append("orderid", this.state.ndrEscalationModal.orderid);
        formData.append("shipmentid", this.state.ndrEscalationModal.shipmentId);
        formData.append("remarks", this.state.remarks);
        formData.append(
          "reattempt",
          this.state.reattemptagain
            ? JSON.stringify(this.state.newData)
            : JSON.stringify({})
        );
        formData.append(
          "date",
          this.state.reattemptagain
            ? this.state.date.format("YYYY-MM-DD")
            : JSON.stringify({})
        );
        if (!validateOnly && Object.keys(errors).length === 0) {
          this.setState(
            {
              ndrEscalationModal: {
                ...this.state.ndrEscalationModal,
                uploading: true,
              },
            },
            () => {
              axios({
                url:
                  process.env.REACT_APP_API_URL +
                  "/shipments/escalate_and_reattempt_ndr",
                method: "post",
                data: formData,
                withCredentials: true,
              })
                .then((res) => {
                  if (res.data.success === 1) {
                    ActionNotification({
                      notificationType: "re-attempt-and-escalate",
                      awb: this.state.ndrEscalationModal.awb,
                    });
                    const reattempt = this.state.ndrEscalationModal.reattempt;
                    if (reattempt) {
                      const ndr = this.state.ndrs.find(
                        (n) =>
                          n.shipment_vfid ===
                          this.state.ndrEscalationModal.shipmentId
                      );
                      this.toggleNdrEscalationModal(
                        (reattempt = false),
                        ndr.shipment_vfid,
                        ndr.awb,
                        {
                          customer_details: ndr.customer_details,
                          shipping_address: ndr.shipping_address,
                        }
                      );
                    }
                    this.setState((state) => ({
                      ndrEscalationModal: {
                        ...this.state.ndrEscalationModal,
                        visible: !state.ndrEscalationModal.visible,
                        uploading: false,
                      },
                    }));
                    // message.success("Updated Successfully!");
                  } else {
                    this.setState({
                      ndrEscalationModal: {
                        ...this.state.ndrEscalationModal,
                        uploading: false,
                      },
                    });
                    message.error(res.data.msg);
                  }
                })
                .catch((e) => {
                  this.setState({
                    ndrEscalationModal: {
                      ...this.state.ndrEscalationModal,
                      uploading: false,
                    },
                  });
                  message.error(e.message);
                });
            }
          );
        }
      }
    }
  }

  onNDRReattemptSuccess() {
    this.loadData();
  }

  changeFilter(kvpairs, bucketReset = false) {
    if (bucketReset === true) {
      this.setState(
        {
          filters: { ...this.state.filters, ...kvpairs },
          paging: { ...this.state.paging, page: 1 },
          ndr_bucket: [],
          ndr_count: [],
          payment_type: "",
        },
        () => this.loadData()
      );
    } else {
      this.setState(
        {
          filters: { ...this.state.filters, ...kvpairs },
          paging: { ...this.state.paging, page: 1 },
        },
        () => this.loadData()
      );
    }
  }

  changeFilter2(kvpairs) {
    this.setState(
      {
        filters: { ...this.state.filters, ...kvpairs },
        paging: { ...this.state.paging, page: 1 },
      },
      () => {
        this.loadData();
        this.searchhandler();
      }
    );
  }

  goToPage(page) {
    this.setState(
      {
        paging: { ...this.state.paging, page },
      },
      () => {
        this.loadData();
      }
    );
  }

  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        paging: { ...this.state.paging, pageSize: pageSize },
      },
      () => this.loadData()
    );
  }

  componentDidUpdate(pp) {
    if (pp.status !== this.props.status) {
      this.setState(
        {
          ndrs: [],
          total: 0,
          paging: {
            page: 1,
            pageSize: 10,
          },
          filters: {
            startDate: "",
            endDate: "",
            status: this.props.status,
            search: "",
          },
        },
        () => this.loadData()
      );
    }
  }

  changeHandler(kvpairs) {
    this.setState({
      filters: { ...this.state.filters, ...kvpairs },
    });
  }

  bucketHandler(e) {
    this.setState({ ndr_bucket: e }, () => this.loadData());
  }
  attemptHandler(e) {
    this.setState({ ndr_count: e }, () => this.loadData());
  }
  paymentMode(e) {
    this.setState({ payment_type: e }, () => this.loadData());
  }

  // select all handler
  selectAllHandler(e) {
    let checked = e.target.checked;
    if (checked) {
      let selectedArr = [];
      let ndrs = this.state.ndrs.map((d) => {
        selectedArr.push(d.awb);
        d.checked = true;
        return d;
      });
      this.setState({ ndrs: ndrs, selectAll: true, selectedArr: selectedArr });
    } else {
      let ndrs = this.state.ndrs.map((d) => {
        d.checked = false;
        return d;
      });
      this.setState({ ndrs: ndrs, selectAll: false, selectedArr: [] });
    }
  }

  // selectHandler
  selectHandler(obj) {
    if (obj.checked) {
      let selectedArr = [...this.state.selectedArr];
      let ndrs = this.state.ndrs.map((d) => {
        if (d.awb === obj.awb) {
          selectedArr.push(d.awb);
          d.checked = true;
        }
        return d;
      });
      let selectAll = ndrs.every((d) => d.checked);
      this.setState({
        ndrs: ndrs,
        selectedArr: selectedArr,
        selectAll: selectAll,
      });
    } else {
      let selectedArr = [...this.state.selectedArr];
      let ndrs = this.state.ndrs.map((d) => {
        if (d.awb === obj.awb) {
          selectedArr = selectedArr.filter((d) => d !== obj.awb);
          d.checked = false;
        }
        return d;
      });
      let selectAll = ndrs.every((d) => d.checked);
      this.setState({
        ndrs: ndrs,
        selectedArr: selectedArr,
        selectAll: selectAll,
      });
    }
  }

  // bucketActionSubmitHandler
  bucketActionSubmitHandler() {
    this.setState(
      {
        bucketLoading: true,
      },
      () => {
        let obj;

        if (this.state.actionType === "reattampt") {
          obj = {
            storeid: this.props.match.params.storeid,
            action: this.state.actionType,
            awbs: this.state.selectedArr,
            date: this.state.reattamptDate,
          };
        } else {
          obj = {
            storeid: this.props.match.params.storeid,
            action: this.state.actionType,
            awbs: this.state.selectedArr,
          };
        }

        axios({
          url: process.env.REACT_APP_API_URL + "/shipments/bulk_action",
          method: "post",
          data: { ...obj },
          withCredentials: true,
        })
          .then((res) => {
            this.setState({
              bucketLoading: false,
              bucketActionModalVisible: false,
              arrow: false,
            });
            if (res.data.success === 1) {
              message.success(res.data.msg);
              this.loadData();
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              bucketLoading: false,
              arrow: false,
              bucketActionModalVisible: false,
            });

            message.error(e);
          });
      }
    );
  }

  setDate(a, b) {
    this.setState({
      reattamptDate: b,
    });
  }

  bucketUi() {
    return (
      <div className="bucket-filters-wraper">
        <BucketFilter
          value={this.state.ndr_bucket}
          changeHandler={(e) => this.bucketHandler(e)}
          type="bucket"
        />
        <SingleSelectFilter
          value={this.state.payment_type}
          changeHandler={(e) => this.paymentMode(e)}
        />
        <AttemptFilter
          value={this.state.ndr_count}
          changeHandler={(e) => this.attemptHandler(e)}
          type="attempt"
        />
      </div>
    );
  }

  render() {
    // const PageTitles = {
    //   pending: "Open NDR",
    //   responded: "Responded NDR",
    //   expired: "Expired NDR",
    //   All: "All",
    // };
    // const PageSubTitles = {
    //   pending: "Open Non-Delivery Reports that require action",
    //   responded: "Non-Delivery Reports with actions taken",
    //   expired: "Non-Delivery Reports that have expired validity",
    //   "": "All the Non-Delivery Reports are shown here",

    // };
    const { fileList } = this.state;
    const imageprops = {
      multiple: true,
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          // fileList: [.file]
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    const bucketActionStatus = {
      initiate_rto: "RTO",
      reattampt: "Reattempt Delivery",
    };

    const menu = (
      <Menu>
        <Menu.Item
          onClick={() =>
            this.setState({
              actionType: "initiate_rto",
              bucketActionModalVisible: true,
            })
          }
        >
          RTO
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            this.setState({
              actionType: "reattampt",
              bucketActionModalVisible: true,
            })
          }
        >
          Re-Attempt Delivery
        </Menu.Item>
      </Menu>
    );

    const PageWrapper = (children) => {
      return (
        <>
          <div className="ndr-page">
            <NdrOrderNav
              filters={this.state.filters}
              {...this.props}
              changeFilter={(e, bool) => this.changeFilter(e, bool)}
              changeHandler={(e) => this.changeHandler(e)}
              ndrs={this.state.ndrs}
              shipment_status={this.state.filters.shipment_status}
              ndrcount={this.state.ndrcount}
              storeid={this.props.match.params.storeid}
              loading={this.state.loading}
            />
            {this.bucketUi()}

            {this.state.filters.startDate && this.state.filters.endDate ? (
              <h2
                style={{ marginBottom: "24px" }}
                className="content-page-subtitle"
              >
                Orders from{" "}
                {moment(this.state.filters.startDate).format("DD MMM, YYYY")} -{" "}
                {moment(this.state.filters.endDate).format("DD MMM, YYYY")}
              </h2>
            ) : // <h2 className="content-page-subtitle">Your Orders</h2>
            null}

            <Row className="tabs-row">{children}</Row>
            <Row>
              <Pagination
                locale={{ jump_to: "Go to page" }}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                current={this.state.paging.page}
                onChange={(page) => this.goToPage(page)}
                total={this.state.total}
                pageSize={this.state.paging.pageSize}
                showSizeChanger
                showQuickJumper
                pageSizeOptions={["10", "25", "50"]}
                onShowSizeChange={(p, d) => this.onShowSizeChange(p, d)}
              />
            </Row>
          </div>
          <NDREscalationModal
            imageprops={imageprops}
            fileList={fileList}
            {...this.props}
            visible={this.state.ndrEscalationModal.visible}
            toggleNdrEscalationModal={() => this.toggleNdrEscalationModal()}
            confirmNdrEscalationModal={(file) => this.onSaveEscalation(file)}
            address={this.state.ndrEscalationModal.address}
            shipment_vfid={this.state.ndrEscalationModal.shipmentId}
            awb={this.state.ndrEscalationModal.awb}
            shopifyId={this.state.ndrEscalationModal.shopifyId}
            onNDRReattemptSuccess={() => this.onNDRReattemptSuccess()}
            newDataHandler={(o, obj2) => this.newDataHandler(o, obj2)}
            reattempttoggle={() => this.reattempttoggle()}
            reattemptagain={this.state.reattemptagain}
            datehandler={(o) => this.datehandler(o)}
            newData={this.state.newData}
            error={this.state.errorsdata}
            removeRemarkhnadler={(val) => this.removeRemarkhnadler(val)}
            remarks={this.state.remarks}
            clear={this.state.clear}
            uploading={this.state.ndrEscalationModal.uploading}
          />
          <NDRReAttemptModal
            {...this.props}
            address={this.state.NDRReAttemptModal.address}
            visible={this.state.NDRReAttemptModal.visible}
            toggleNdrReAttemptModal={() => this.toggleNdrReAttemptModal()}
            shipment_vfid={this.state.NDRReAttemptModal.shipmentId}
            awb={this.state.NDRReAttemptModal.awb}
            onNDRReattemptSuccess={() => this.onNDRReattemptSuccess()}
          />
          <Modal
            className="bulk-action-modal"
            visible={this.state.bucketActionModalVisible}
          >
            <div className="heading">
              <Icon component={GreenTick} /> <p>Confirm Bulk Action</p>
            </div>
            <div className="content-data">
              <p>
                You are taking following bulk action for{" "}
                {this.state.selectedArr.length} Shipments in NDR:
              </p>
              <h3>{bucketActionStatus[this.state.actionType]}</h3>

              {this.state.actionType === "reattampt" ? (
                <div className="picker">
                  {" "}
                  <p style={{ textAlign: "left" }}>Re-Attempt On</p>
                  <DatePicker
                    // format="DD MMM, YYYY"
                    value={
                      this.state.reattamptDate === "" ||
                      this.state.reattamptDate === null
                        ? ""
                        : moment(this.state.reattamptDate, "YYYY-MM-DD")
                    }
                    defaultValue={
                      this.state.reattamptDate === "" ||
                      this.state.reattamptDate === null
                        ? ""
                        : moment(this.state.reattamptDate, "YYYY-MM-DD")
                    }
                    disabledDate={(current) =>
                      current &&
                      (current <
                        moment()
                          .subtract(0, "days")
                          .endOf("day") ||
                        current >
                          moment()
                            .add(7, "days")
                            .endOf("day"))
                    }
                    onChange={(a, b) => this.setDate(a, b)}
                    style={{ width: "100%" }}
                  />
                </div>
              ) : null}
            </div>

            <div className="custom-footer">
              <Button
                onClick={() =>
                  this.setState({
                    bucketActionModalVisible: false,
                    arrow: false,
                    actionType: "",
                    bucketLoading: false,
                    reattamptDate: "",
                  })
                }
                type="ghost"
              >
                Cancel
              </Button>

              {this.state.actionType === "reattampt" ? (
                <Button
                  className={`${
                    !!this.state.reattamptDate ? `` : `disabled-Btn`
                  }`}
                  disabled={!!this.state.reattamptDate ? false : true}
                  loading={this.state.bucketLoading}
                  onClick={() => this.bucketActionSubmitHandler()}
                  type="primary"
                >
                  Continue
                </Button>
              ) : (
                <Button
                  loading={this.state.bucketLoading}
                  onClick={() => this.bucketActionSubmitHandler()}
                  type="primary"
                >
                  Continue
                </Button>
              )}
            </div>
          </Modal>
        </>
      );
    };

    if (this.state.loading) {
      return PageWrapper(
        <Row style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </Row>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <ErrorCard
          errorMsg={this.state.errorMsg}
          errorCode={this.state.errorCode}
        />
      );
    }

    return PageWrapper(
      this.state.ndrs.length > 0 ? (
        <>
          {this.state.filters.status === "pending" ? (
            <div className="select-all-wraper">
              <div className="select-all">
                <Checkbox
                  checked={this.state.selectAll}
                  onClick={(e) => this.selectAllHandler(e)}
                >
                  {this.state.selectAll ? "Deselect All" : "Select All"}
                </Checkbox>
                <div className="divider">|</div>
                {this.state.selectedArr.length} of {this.state.total} Shipments
                selected
              </div>

              <div className="action">
                <p>
                  {/* Recommended Action{" "} */}
                  {/* <GlobalTooltip
                    tooltipText={
                      "We recommend the best possible action that can be taken on  NDR"
                    }
                  />{" "} */}
                  <Dropdown
                    disabled={!!this.state.selectedArr.length ? false : true}
                    overlayClassName="bucket-drodown-overlay"
                    overlay={menu}
                    className={`clickable ${
                      !!this.state.selectedArr.length ? `` : `disabled`
                    }`}
                    onVisibleChange={(a) =>
                      this.setState({ arrow: !this.state.arrow })
                    }
                  >
                    <Button
                      className={`clickable ${
                        !!this.state.selectedArr.length ? `` : `disabled`
                      }`}
                    >
                      Take Bulk Action
                    </Button>
                  </Dropdown>
                  <Icon
                    className={`white-arrow ${this.state.arrow ? `open` : ``}`}
                    component={Arrow}
                  />
                </p>
              </div>
            </div>
          ) : null}

          {this.state.ndrs.map((ndr) => (
            <NdrCard
              {...this.props}
              key={ndr._id["$oid"]}
              ndr={ndr}
              loadData={() => this.loadData()}
              bucket={this.bucket}
              pendingTab={
                this.state.filters.status === "pending" ? true : false
              }
              selectHandler={(obj) => this.selectHandler(obj)}
              shopifyfulfillment={ndr.shopifyfulfillment}
              store={this.props.store}
              reloadList={() => this.loadData()}
              toggleNdrEscalationModal={(reattempt) =>
                this.toggleNdrEscalationModal(
                  ndr.shipment_vfid,
                  ndr.order_id["$oid"],
                  reattempt,
                  ndr.awb,
                  {
                    customer_details: ndr.customer_details,
                    shipping_address: ndr.shipping_address,
                  },
                  ndr.order_shopify_name,
                  ndr.order_vfid,
                  ndr.order_shopifylink
                )
              }
              toggleNdrReAttemptModal={() =>
                this.toggleNdrReAttemptModal(ndr.shipment_vfid, ndr.awb, {
                  customer_details: ndr.customer_details,
                  shipping_address: ndr.shipping_address,
                })
              }
              filters={this.state.filters}
            />
          ))}
        </>
      ) : (
        <NotFoundCard
          mainHeading={"Oops! No Data Found."}
          secondHeading={<p>Looks like there are no NDR in this page.</p>}
        />
      )
    );
  }
}
const mapStateToProps = (state) => ({
  store: state.auth.auth.store,
});
export default connect(mapStateToProps)(Ndr);
