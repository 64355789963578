import { Button, Modal, Alert } from "antd";
import React, { Component } from "react";
import "./index.scss";
import { ReactComponent as YellowIcon } from "../../.././../../assets/images/icons/yellow-i.svg";

export default class RedirectModal extends Component {
  handleClick() {
    let origin = window.location.origin;
    window.location.href =
      origin + "/in/" + this.props.id + "/onboarding#profile";
  }
  render() {
    return (
      <Modal
        footer={[
          <Button
            onClick={() => {
              this.props.cancel();
            }}
            style={{ width: "120px", height: "40px", fontSize: "12px" }}
          >
            Do It Later
          </Button>,
          <Button
            className="clickable-green"
            onClick={() => {
              this.handleClick();
            }}
            style={{
              width: "151px",
              height: "40px",
              fontSize: "12px",
              fontWeight: 500,
            }}
          >
            Complete My KYC
          </Button>,
        ]}
        width={480}
        className="redirect-kyc"
        title={
          <div className="heading-main">
            <span className="icon-container">
              <YellowIcon />{" "}
            </span>{" "}
            Action Required: Complete your KYC
          </div>
        }
        visible={this.props.visible}
        closable={false}
      >
        <div className="steps-kyc-modal">
          <div className="upperheading">
            Hey, it seems like you haven’t completed your KYC yet.
            <span
              style={{ color: "#6A6A6A", fontWeight: 600, fontStyle: "italic" }}
            >
              {" "}
              Correct KYC information is important for us to make sure that
              money is transferred into the correct account, therefore it is
              mandatory before entertaining any withdrawal requests.
            </span>
          </div>
          <div>
            Don’t worry, we’ve made it quick & easy for you! Just click on the
            button below to get started.
          </div>
        </div>
      </Modal>
    );
  }
}
