import React from "react";

const ManualNote = props => {
  const transaction = props.transaction;

  return (
    <div style={{ padding: "15px 25px" }}>
      <h3>Remarks:</h3>
      <p>{transaction.transaction_external_remark}</p>
    </div>
  );
};

export default ManualNote;
