import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import rootReducer from "./store/reducers/rootReducer";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import axios from "axios";
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://de3a10ba9a0d442ca2f09463a5fb690a@sentry.io/5166894",
});

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
      store.dispatch({
        type: "SIGNOUT_SUCCESS",
        payload: error,
      });
    }
    return Promise.reject(error);
  }
);

// const store = createStore(rootReducer,
//   compose(
//     applyMiddleware(thunk),
//   ),
// );
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));
const store = createStore(rootReducer, enhancer);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
