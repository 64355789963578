import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  DatePicker,
  Alert,
  Select,
  Table,
  Tooltip,
  Icon,
  message,
} from "antd";
import { formatNumber, getCurrencySymbol } from "../../../../Helpers/Pricing";
import moment from "moment";
import axios from "axios";
import { connect } from "react-redux";
import { hasPermission } from "../../../../Helpers/Permissions";
import { SHOPIFY_NAME } from "../../../../constants";
import "./index.scss";
import qs from "qs";

const { RangePicker } = DatePicker;
const { Option } = Select;

const columnData = {
  vforderid: {
    title: "vF Order Id",
    width: "150px",
    dataIndex: "vforderid",
    sortValue: 1,
  },
  transaction_id: {
    title: "Transaction ID",
    width: "150px",
    dataIndex: "transaction_id",
    sortValue: 2,
    render: (_, row) =>
      Object.values(row.vfcost_transactions).map((tdata, i) => (
        <p key={i}>{tdata.transaction_vfid}</p>
      )),
  },
  subtransactionid: {
    title: "Sub Transaction ID",
    width: "150px",
    dataIndex: "subtransactionid",
    sortValue: 3,
    render: (_, row) =>
      Object.values(row.vfcost_transactions).map((tdata, i) => (
        <p key={i}>
          {tdata.transaction_vfid}_{row.vforderid}
        </p>
      )),
  },
  productid: {
    width: "150px",
    title: "vF Product Id",
    dataIndex: "productid",
    sortValue: 4,
    render: (_, row) =>
      row.items.map((item, i) => <p key={i}>{item.vendorsku}</p>),
  },
  items: {
    title: "Details",
    width: "150px",
    className: "order-content",
    dataIndex: "items",
    sortValue: 5,
    render: (items) =>
      items.map((item, i) => (
        <p key={i}>
          <Tooltip
            title={`${item.name} X ${item.quantity}`}
            placement="topLeft"
          >
            {`${item.name} X ${item.quantity}`}
          </Tooltip>
        </p>
      )),
  },
  transaction_product_amount: {
    title: "Product Price",
    width: "150px",
    dataIndex: "transaction_product_amount",
    sortValue: 6,
    render: (_, row) => (
      <p>
        {getCurrencySymbol("INR")} {formatNumber(row.vfcosts.product_total)}
      </p>
    ),
  },
  transaction_shipping_amount: {
    title: "Shipping",
    width: "150px",
    dataIndex: "transaction_shipping_amount",
    sortValue: 7,
    render: (_, row) => (
      <p>
        {getCurrencySymbol("INR")} {formatNumber(row.vfcosts.shipping_total)}
      </p>
    ),
  },
  transaction_cod_charges: {
    title: "COD Costs",
    width: "150px",
    dataIndex: "transaction_cod_charges",
    sortValue: 8,
    render: (_, row) => (
      <p>
        {row.vfcosts.cod_fee_total !== 0
          ? `${getCurrencySymbol("INR")} ${formatNumber(
              row.vfcosts.cod_fee_total
            )}`
          : "NA"}
      </p>
    ),
  },
  shopify_order_name: {
    width: "150px",
    title: "Shopify Order Name",
    dataIndex: "shopify_order_name",
    sortValue: 9,
    render: (_, row) => <p>{row.shopify_ordername}</p>,
  },
  shopify_order_date: {
    width: "150px",
    title: `${SHOPIFY_NAME} Order Date`,
    dataIndex: "shopify_order_date",
    sortValue: 10,
    render: (_, row) => (
      <p>{moment(row.order_filterdate).format("DD MMM, YYYY")}</p>
    ),
  },
  storename: {
    width: "150px",
    title: "Shopify Store Name",
    dataIndex: "storename",
    sortValue: 11,
  },
  shopify_order_payment_type: {
    width: "150px",
    title: "Shopify Order Payment Type",
    dataIndex: "shopify_order_payment_type",
    sortValue: 12,
    render: (_, row) => <p>{row.payment_type}</p>,
  },

  vf_order_date: {
    width: "150px",
    title: "vF Order Date",
    dataIndex: "vf_order_date",
    sortValue: 13,
    render: (_, row) =>
      row.items.map((item, i) => (
        <p key={i}>{moment(item.confirmation_date).format("DD MMM, YYYY")}</p>
      )),
  },
  total_vf_cost: {
    width: "150px",
    title: "Total vF Cost",
    dataIndex: "total_vf_cost",
    sortValue: 14,
    render: (_, row) => (
      <p>
        {getCurrencySymbol("INR")} {formatNumber(row.vfcosts.total)}
      </p>
    ),
  },
};

const defaultCols = [
  "vforderid",
  "transaction_id",
  "vf_order_date",
  "shopify_order_name",
  "transaction_product_amount",
  "transaction_shipping_amount",
  "transaction_cod_charges",
  "total_vf_cost",
];

class VfCostMis extends Component {
  state = {
    currency: "INR",
    stats: {
      totalpp: 0,
      totalfs: 0,
      totalcod: 0,
      total_vf_cost: 0,
    },
    filters: {
      startDate: moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
    paging: {
      page: 1,
      pageSize: 10,
      total: 0,
    },
    loading: false,
    error: false,
    errorMsg: "",
    statsLoading: false,
    orders: [],
    columns: defaultCols.map((c) => columnData[c]),
    columnData: {},
  };

  componentDidMount() {
    const inExclusive = ["transaction_cod_charges"];
    if (this.props.store.store_geo === "global") {
      inExclusive.forEach((k) => delete columnData[k]);
    }
    this.setState(
      {
        columnData,
        columns:
          this.props.store.store_geo === "global"
            ? defaultCols
                .filter((k) => inExclusive.indexOf(k) === -1)
                .map((c) => columnData[c])
            : defaultCols.map((c) => columnData[c]),
      },
      () => {
        this.getOrders();
        this.getStats();
      }
    );
  }

  getOrders() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/reporting/get_orders",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
            filters: this.state.filters,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                orders: res.data.orders,
                loading: false,
                error: false,
                errorMsg: "",
                paging: { ...this.state.paging, total: res.data.total },
              });
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
                loading: false,
              });
            }
          })
          .catch((e) => {
            this.setState({
              error: true,
              errorMsg: e.message,
              loading: false,
            });
          });
      }
    );
  }

  getStats() {
    this.setState(
      {
        statsLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/reporting/vf_cost_mis",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            filters: this.state.filters,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                statsLoading: false,
                stats: {
                  totalpp: res.data.stats.total_product_price,
                  totalfs: res.data.stats.total_forward_shipping,
                  totalcod: res.data.stats.total_cod_fee,
                  total_vf_cost: res.data.stats.total_vf_cost,
                },
              });
            } else {
              this.setState({
                statsLoading: false,
              });
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              statsLoading: false,
            });
            message.error(e.message);
          });
      }
    );
  }

  filterChanged() {
    this.getOrders();
    this.getStats();
  }

  handleChange(value) {
    this.setState({
      columns: value
        .map((c) => this.state.columnData[c])
        .sort((a, b) => a.sortValue - b.sortValue),
    });
  }

  unselectColumn(index) {
    this.setState({
      columns: this.state.columns
        .filter((_, i) => i !== index)
        .sort((a, b) => a.sortValue - b.sortValue),
    });
  }

  goToPage = (page) => {
    this.setState(
      {
        paging: { ...this.state.paging, page: page.current },
      },
      () => this.getOrders()
    );
  };

  render() {
    const { store } = this.props;
    const PageWrapper = (children) => {
      return (
        <div className="reporting">
          <Row>
            <div className="heading">
              <h1 className="content-page-title" style={{ marginBottom: 0 }}>
                vF Costs MIS
              </h1>
              <h3
                className="content-page-subtitle"
                style={{ marginBottom: 30 }}
              >
                Product Costs (Your Spent) In The Selected Period
                <span style={{ float: "right", marginTop: "-4px" }}>
                  Show data from:
                  <RangePicker
                    disabledDate={(current) => {
                      return current > moment();
                    }}
                    value={[
                      this.state.filters.startDate === ""
                        ? null
                        : moment(this.state.filters.startDate),
                      this.state.filters.endDate === ""
                        ? null
                        : moment(this.state.filters.endDate),
                    ]}
                    ranges={{
                      "Last Month": [
                        moment()
                          .subtract(1, "months")
                          .startOf("month"),
                        moment()
                          .subtract(1, "months")
                          .endOf("month"),
                      ],
                      "Last 3 Month": [
                        moment()
                          .subtract(2, "months")
                          .startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    onChange={(_, dateString) =>
                      this.setState(
                        {
                          filters: {
                            startDate: dateString[0],
                            endDate: dateString[1],
                          },
                        },
                        () => this.filterChanged()
                      )
                    }
                  />
                </span>
              </h3>
            </div>
          </Row>
          {children}
        </div>
      );
    };

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <Row>
          <Alert
            message="There was an error"
            description={this.state.errorMsg}
            type="error"
          />
        </Row>
      );
    }

    const order_stats = {
      totalpp: "Total Product Price",
      totalfs: "Total Forword Shipping",
      totalcod: "Total COD Fee",
    };
    if (this.props.store.store_geo === "global") {
      ["totalcod"].forEach((k) => delete order_stats[k]);
    }
    const tooltip = {
      totalpp: "Total Product Price",
      totalfs: "Total Forword Shipping",
      totalcod: "Total COD Fee",
    };

    const pagination = {
      current: this.state.paging.page,
      pageSize: this.state.paging.pageSize,
      total: this.state.paging.total,
    };

    return PageWrapper(
      <React.Fragment>
        <Row gutter={{ lg: 30 }}>
          <Col xs={24} md={24} lg={24} xl={24} style={{ marginBottom: 56 }}>
            <Card className="reporting-card" loading={this.state.statsLoading}>
              <div className="header" style={{ textAlign: "center" }}>
                Total vF Cost
              </div>
              <div className="body" style={{ textAlign: "center" }}>
                {getCurrencySymbol(this.state.currency)}{" "}
                {formatNumber(this.state.stats.total_vf_cost)}
              </div>
            </Card>
          </Col>
        </Row>
        <Row gutter={{ lg: 30 }}>
          {Object.keys(order_stats).map((k) => (
            <Col
              xs={24}
              md={12}
              lg={8}
              xl={8}
              style={{ marginBottom: 56 }}
              key={k}
            >
              <Card
                className="reporting-card"
                loading={this.state.statsLoading}
              >
                <div className="header">
                  {order_stats[k]}
                  <span className="header-tooltip">
                    <Tooltip title={tooltip[k]}>
                      <Icon type="exclamation-circle-o" />
                    </Tooltip>
                  </span>
                </div>
                <div className="body">
                  {getCurrencySymbol(this.state.currency)}{" "}
                  {formatNumber(this.state.stats[k])}
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <Row>
          <div className="heading">
            <h1 className="content-page-title" style={{ marginBottom: "10px" }}>
              vF Cost Analysis Data
              <span style={{ float: "right" }}>
                {hasPermission(store, "export_vf_cost_report") && (
                  <a
                    href={`/reporting/vf_cost_mis_csv?${qs.stringify(
                      {
                        storeid: this.props.match.params.storeid,
                        filters: this.state.filters,
                        columns: this.state.columns.map((c) => c.dataIndex),
                      },
                      { encodeValuesOnly: true }
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      background: "#25BE05",
                      border: "none",
                      color: "white",
                    }}
                    className="ant-btn"
                  >
                    <Icon type="download" /> Download CSV
                  </a>
                )}
              </span>
            </h1>
          </div>
        </Row>
        <Row>
          <div className="selected-column">
            <h3 className="content-page-subtitle">Display Columns : </h3>
            <Select
              mode="multiple"
              placeholder="Select from dropdown"
              value={this.state.columns.map((column) => `${column.dataIndex}`)}
              onChange={(value) => this.handleChange(value)}
              className="display-column-select"
            >
              {Object.values(this.state.columnData).map((c) => (
                <Option value={c.dataIndex} key={c.dataIndex}>
                  {c.title}
                </Option>
              ))}
            </Select>
          </div>
        </Row>
        <Row style={{ marginBottom: "30px" }}>
          {this.state.columns.map((column, i) => (
            <div className="selectedValue" key={i}>
              {column.title}{" "}
              <Icon type="close" onClick={() => this.unselectColumn(i)} />
            </div>
          ))}
          <div />
        </Row>
        <Row>
          <Table
            columns={this.state.columns}
            dataSource={this.state.orders}
            loading={this.state.loading}
            rowKey={(row) => row._id["$oid"]}
            onChange={(page) => this.goToPage(page)}
            scroll={{ x: 175 * this.state.columns.length }}
            pagination={{
              ...pagination,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            tableLayout="auto"
          />
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};
export default connect(mapStateToProps)(VfCostMis);
