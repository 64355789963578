import { Button, Icon, message, Modal, Tooltip } from "antd";
import React, { Component } from "react";
import {
  formatNumber,
  getCurrencySymbol,
  toStoreCurrency,
} from "../../../../../Helpers/Pricing";
import "./index.scss";
import axios from "axios";
import { connect } from "react-redux";
import { hasPermission } from "../../../../../Helpers/Permissions";
import BulkOrderConfirmModal from "../BulkOrderConfirmModal";
import ConfirmPackagingModal from "../ConfirmPackagingModal";
import NoBalanceModal from "../NoBalanceModal";
import { ReactComponent as NewTab } from "../../../../../assets/images/icons/newtab.svg";
import GlobalTooltipIcon from "../../../Common/GlobalTooltipIcon";

class BookInventoryFloatingWidget extends Component {
  state = {
    pricing: {
      total: 0,
      variants: {},
      gstamount: 0,
    },
    modalData: [],
    days: "N/A",
    loading: false,
    showPackagingModal: false,
    packagingModalVisible: false,
    packagingData: [],
    bookingLoading: false,
    bookingConfirmationModal: {
      visible: false,
      id: null,
    },
    confimModal: false,
    bookingId: "",
    selectedImages: [],
    loadingImage: false,
    remarks: "",
    privateLabel: false,
    noBalanceVisible: false,
  };

  freightStatusName = {
    "Via Air": "air",
    "Via Sea": "sea",
    Domestic: "domestic",
    Sample: "retail",
    vf: "vf",
  };

  handleRemarksChange = (e) => {
    this.setState({ remarks: e.target.value });
  };
  handleImageChange = (file) => {
    // const selectedImages = Array.from(event.target.files);
    console.log(file);
    const formData = new FormData();
    formData.append("file", file);

    this.setState({ loadingImage: true }, () => {
      axios({
        url:
          process.env.REACT_APP_API_URL +
          "/sourcing/upload_clientsourcingrequest_packaging_docs",
        method: "POST",
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          if (response.data.success) {
            const uploadedUrl = response.data.file;
            // message.success();
            this.setState({
              selectedImages: [...this.state.selectedImages, uploadedUrl],
            });
          } else {
            // console.log("errrrrrreeeees", response);
            message.error(response.data.msg);
          }
          this.setState({ loadingImage: false });
        })
        .catch((error) => {
          // console.log("errrrrrr", error);
          message.error("Error uploading images:", error);
          this.setState({ loadingImage: false });
        });
    });
  };
  handleCancelimage = (index) => {
    let selected = [...this.state.selectedImages];
    selected.splice(index, 1);
    this.setState({
      selectedImages: selected,
    });
  };
  handleCustomPacking = () => {
    // if (!this.state.remarks) {
    //   message.error("Please enter remarks ");
    //   return;
    // }
    axios({
      url:
        process.env.REACT_APP_API_URL +
        "/sourcing/add_clientsourcingrequest_packaging_suggestions",
      method: "POST",
      data: {
        csrid: this.props.bookingId,
        remarks: this.state.remarks,
        suggestions: this.state.selectedImages,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.msg);
          this.props.cancel();
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  onChangePrivateLabel = (checked) => {
    if (!checked) {
      this.setState({
        privateLabel: checked,
        selectedImages: [],
        loadingImage: false,
        remarks: "",
      });
    } else {
      this.setState({ privateLabel: checked });
    }
  };
  toggleBookingConfirmationModal(id = null) {
    this.setState((state) => ({
      bookingConfirmationModal: {
        visible: !state.bookingConfirmationModal.visible,
        id,
      },
    }));
  }

  fcHandler(geo, freightData) {
    if (geo === "global") {
      return "china_freight";
    }

    if (this.freightStatusName[freightData] === "sample") {
      return "retail";
    }

    if (this.freightStatusName[freightData] === "vf") {
      return "vf_inventory";
    }

    if (this.freightStatusName[freightData] === "air") {
      return "india_air_freight";
    }

    if (this.freightStatusName[freightData] === "sea") {
      return "india_sea_freight";
    }

    return this.freightStatusName[freightData];
  }

  packagingModalHandler() {
    if (!this.state.showPackagingModal) {
      this.props.loadProductData();
    }
  }

  bookInventory(btn = false) {
    // fc china_freight for global stores

    if (btn) {
      this.setState({ confimModal: true });
    } else {
      const totalQty = Object.values(this.props.variants).reduce(
        (acc, v) => acc + v,
        0
      );
      this.setState(
        {
          bookingLoading: true,
        },
        () => {
          axios({
            url:
              process.env.REACT_APP_API_URL +
              "/sourcing/create_sourcing_request",
            method: "POST",
            data: {
              storeid: this.props.storeid,
              product_vfid: this.props.vfprodid,
              mix_skus: 0,
              skus: Object.entries(this.props.variants)
                .filter((d) => d[1] !== 0)
                .reduce((acc, [k, v]) => ((acc[k] = v), acc), {}),
              packaging: 0,
              // fc: "india_express_freight",
              quantity: totalQty,
              currency: this.props.currency,
              pricing: this.state.pricing,
              fc: this.fcHandler(this.props.storeGeo, this.props.freightData),
              remarks: this.state.remarks,
              suggestions: this.state.selectedImages,
            },
            withCredentials: true,
          })
            .then((res) => {
              return res;
            })
            .then((res) => {
              if (res.data.success === 1) {
                message.success(res.data.msg);
                this.setState({
                  bookingLoading: false,
                  confimModal: false,
                  packagingModalVisible: true,
                  bookingId: res.data.id,
                });

                // this.toggleBookingConfirmationModal(res.data.id);

                this.props.onClose();
              } else if (res.data.success === 2) {
                this.setState({
                  bookingLoading: false,
                });
                this.props.onClose();
                this.setState({ noBalanceVisible: true });
              } else {
                this.setState({
                  bookingLoading: false,
                });
                message.error(res.data.msg);
              }
            })
            .catch((err) => {
              this.setState({
                bookingLoading: false,
              });
              message.error(err.message);
            });
        }
      );
    }
  }

  leadTime(time) {
    if (time === "") {
      return "N/A";
    }

    if (time === 0 || time === "0") {
      return "0 day";
    }

    return `${time} days`;
  }
  getPricing() {
    console.log("ddddddwsss", this.props.freightData);

    this.setState(
      {
        loading: true,
      },
      () => {
        if (this.cancelToken) {
          this.cancelToken.cancel();
        }

        this.cancelToken = axios.CancelToken.source();
        axios({
          url: process.env.REACT_APP_API_URL + "/products/calculate_csrpricing",
          method: "post",
          cancelToken: this.cancelToken.token,
          data: {
            vfprodid: this.props.vfprodid,
            variants: this.props.variants,
            packaging: 0,
            storeid: this.props.storeid,
            geo: this.props.currency === "USD" ? "global" : "in",
            freight: this.freightStatusName[this.props.freightData],
          },
          withCredentials: true,
        })
          // .then((res) => {
          //   this.setState({ loading: false });
          //   return res;
          // })
          .then((res) => {
            this.setState({ loading: false });
            if (!!res.data.error_msg) {
              this.props.variantsErrorInfo(res.data.error_msg);
            } else {
              this.props.variantsErrorInfo("");
            }

            let product = this.props.product.variants;
            let variants = this.props.variants;

            let api = res.data.pricing;

            let fill = Object.entries(variants)
              .filter((d) => d[1] !== 0)
              .reduce((acc, [k, v]) => ((acc[k] = v), acc), {});

            variants = fill;

            let dataForModal = [];

            product.forEach((product) => {
              let obj = {
                vfsku: "",
                img: "",
                qty: "",
                price: "",
                totalPrice: "",
                specs: "",
              };

              if (
                api["variants"].hasOwnProperty(product.vfsku) &&
                !!variants[product.vfsku]
              ) {
                obj.qty = variants[product.vfsku];
                obj.specs = product.allspecs;
                obj.vfsku = product.vfsku;
                obj.img = product.images[0];
                obj.price = api["variants"][product.vfsku]["base"];
                obj.totalPrice = api["variants"][product.vfsku]["base"];

                dataForModal.push(obj);
              }
            });

            console.log("eeee", res.data.pricing);

            this.setState({
              pricing: res.data.pricing,
              modalData: dataForModal,
              days: this.leadTime(res.data.lead_time),
            });
          })
          .catch((e) => {});
      }
    );
  }

  freightStatus(status) {
    if (status === "") {
      return "None";
    }
    if (status === "vf") {
      return "VF Inventory";
    }

    if (status === "sea") {
      return "Transit Via Sea";
    }

    if (status === "air") {
      return "Transit Via Air";
    }
  }

  componentDidMount() {
    if (this.props.product !== undefined) {
      let packagingData =
        this.props.product.hasOwnProperty("recommended_packaging") &&
        this.props.product.recommended_packaging.length
          ? true
          : false;

      if (packagingData) {
        this.setState({
          showPackagingModal: true,
          packagingData: this.props.product.recommended_packaging,
        });
      }
    }
  }

  componentDidUpdate(pp) {
    const prevQty = Object.values(pp.variants).reduce((acc, v) => acc + v, 0);
    const newQty = Object.values(this.props.variants).reduce(
      (acc, v) => acc + v,
      0
    );

    if (pp.freightData !== this.props.freightData) {
      this.getPricing();
    }
    if (prevQty !== newQty) {
      this.getPricing();

      let totalQty = Object.entries(this.props.variants).reduce(
        (acc, v) => acc + v[1],
        0
      );

      // if (totalQty < this.props.minimumMoq) {
      //   return this.props.sampleHandler();
      // }

      // if (
      //   (totalQty == this.props.minimumMoq ||
      //     totalQty > this.props.minimumMoq) &&
      //   this.props.freightData === "retail"
      // ) {
      //   return this.props.handleAirChange();
      // }
    }
  }

  processingTimeHandler() {
    // stocking time calculation

    const totalQty = Object.values(this.props.variants).reduce(
      (acc, v) => acc + v,
      0
    );

    let days = "N/A";
    let restockingArr =
      !!this.props.product.restocking_time &&
      !!this.props.product.restocking_time.length
        ? this.props.product.restocking_time
        : [];
    let freightType = this.props.freightData;

    restockingArr.forEach((d, i) => {
      if (freightType === "vf") {
        days = "0 day";
      }

      // air_restocking_time
      if (freightType === "air") {
        if (totalQty <= Number(d.moq_start)) {
          return (days = `${d.air_restocking_time.start}-${d.air_restocking_time.end} days`);
        }

        if (totalQty >= Number(d.moq_start) && totalQty <= Number(d.moq_end)) {
          return (days = `${d.air_restocking_time.start}-${d.air_restocking_time.end} days`);
        }

        if (i === restockingArr.length - 1 && totalQty >= Number(d.moq_end)) {
          return (days = `${d.air_restocking_time.start}-${d.air_restocking_time.end} days`);
        }
      }

      // sea_restocking_time
      if (freightType === "sea") {
        if (totalQty <= Number(d.moq_start)) {
          return (days = `${d.sea_restocking_time.start}-${d.sea_restocking_time.end} days`);
        }

        if (totalQty >= Number(d.moq_start) && totalQty <= Number(d.moq_end)) {
          return (days = `${d.sea_restocking_time.start}-${d.sea_restocking_time.end} days`);
        }

        if (i === restockingArr.length - 1 && totalQty >= Number(d.moq_end)) {
          return (days = `${d.sea_restocking_time.start}-${d.sea_restocking_time.end} days`);
        }
      }
    });

    return days;
  }

  render() {
    // const rangval = Object.entries(this.props.product.moq_sourcing).map(
    //   ([range, data], index) => {
    //     const rangeVals = range.split("-").map((rv) => Number(rv));
    //     return rangeVals[0];
    //   }
    // );
    const imageprops = {
      multiple: true,
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        // this.setState((state) => ({
        //   // fileList: [.file]
        //   fileList: [...state.fileList, file],
        // }));
        this.handleImageChange(file);
        return false;
      },
      fileList: [],
    };
    const totalQty = Object.values(this.props.variants).reduce(
      (acc, v) => acc + v,
      0
    );
    const currency = this.props.currency;
    const store = this.props.store;
    return (
      <div
        className="floating-widget-wraper-new"
        style={{
          background: "white",
        }}
      >
        {!!this.state.confimModal ? (
          <BulkOrderConfirmModal
            confirmOrder={() => this.bookInventory()}
            cancelOrder={() =>
              this.setState({
                confimModal: false,
                selectedImages: [],
                loadingImage: false,
                remarks: "",
                privateLabel: false,
              })
            }
            data={this.state.modalData}
            pricing={this.state.pricing}
            freightType={this.freightStatus(this.props.freightData)}
            visible={this.state.confimModal}
            loading={this.state.bookingLoading}
            currency={getCurrencySymbol(currency)}
            gstPercentage={this.props.gstPercentage}
            stockProcessingTime={this.state.days}
            handleRemarksChange={(e) => this.handleRemarksChange(e)}
            handleImageChange={(e) => this.handleImageChange(e)}
            handleCancelimage={(e) => this.handleCancelimage(e)}
            onChangePrivateLabel={(e) => this.onChangePrivateLabel(e)}
            selectedImages={this.state.selectedImages}
            loadingImage={this.state.loadingImage}
            remarks={this.state.remarks}
            privateLabel={this.state.privateLabel}
            imageprops={imageprops}
          />
        ) : null}

        <ConfirmPackagingModal
          product={this.props.product}
          bookingId={this.state.bookingId}
          cancel={() =>
            this.setState({ packagingModalVisible: false }, () =>
              this.props.loadProductData()
            )
          }
          freightType={this.freightStatus(this.props.freightData)}
          data={this.state.packagingData.filter(
            (d) => d.complimentary_packaging !== 1
          )}
          currency={getCurrencySymbol(currency)}
          visible={this.state.packagingModalVisible}
        />

        <NoBalanceModal
          cancel={() =>
            this.setState({ noBalanceVisible: false }, () =>
              this.props.loadProductData()
            )
          }
          visible={this.state.noBalanceVisible}
        />

        <div className="price-div">
          <p>Total Quantity:</p>
          <span>{this.props.quantity}</span>
        </div>

        <div className="price-div">
          <p>Product Price X Total Qty:</p>
          <span>
            {this.state.loading ? (
              <div className="loader" />
            ) : (
              `${getCurrencySymbol(currency)} ${formatNumber(
                this.state.pricing.base > 0 ? this.state.pricing.base : 0
              )}`
            )}
          </span>
        </div>

        {this.state.pricing.gstamount > 0 && !this.props.importStoreData ? (
          <div className="price-div">
            <p>+ {this.props.gstPercentage}% GST</p>
            <span>
              {getCurrencySymbol(currency)}
              {formatNumber(
                this.state.pricing.gstamount > 0
                  ? this.state.pricing.gstamount
                  : 0
              )}
            </span>
          </div>
        ) : null}

        <div className="price-div">
          <p>Total</p>
          <span>
            {this.state.loading ? (
              <div className="loader" />
            ) : (
              `${getCurrencySymbol(currency)} ${formatNumber(
                this.state.pricing.total
              )}`
            )}
          </span>
        </div>

        <div className="action-btns-drawer">
          <Button type="cancel" onClick={() => this.props.onClose()}>
            Cancel
          </Button>

          {hasPermission(store, "raise_booking_request") ? (
            <Button
              type={
                totalQty >= 0 && this.state.pricing.total === 0
                  ? "cancel"
                  : "primary"
              }
              disabled={
                totalQty >= 0 && this.state.pricing.total === 0 ? true : false
              }
              style={{
                fontSize: "14px",
                letterSpacing: "0.032em",
                display: `${
                  this.props.storeGeo === "global" && this.props.radio === "in"
                    ? "none"
                    : "block"
                }`,
              }}
              onClick={(bool) => this.bookInventory(false)}
              loading={this.state.bookingLoading}
            >
              {this.props.freightData === "vf"
                ? "Review Booking"
                : "Raise Sourcing Request"}
            </Button>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};

export default connect(mapStateToProps)(BookInventoryFloatingWidget);
