import { Button, Modal, Slider, Table } from "antd";
import React, { Component } from "react";
import "./index.scss";

export default class ModalStorage extends Component {
  componentDidMount() {}
  render() {
    let { transactions, id } = this.props;
    console.log(transactions);
    let index = transactions.findIndex((tra) => {
      return tra.transaction_vfid === id;
    });
    let current = transactions[index];
    let currentDataSkuObj = current.transaction_data.skubreakup || {};
    let SKUlist = Object.keys(currentDataSkuObj).map((sku) => {
      let { gst = 0, base = 0, total = 0, quantity = 0, img = "", date = "" } =
        currentDataSkuObj[sku] || {};

      // const dateWiseBreakup = currentDataSkuObj[sku].date_wise_breakup || {};
      // let dateWiseBreakupArr = Object.keys(dateWiseBreakup);
      // let date = dateWiseBreakupArr.find((key) => key.startsWith("2"));
      // const { gst = 0, base = 0, total = 0,quantity=0,img="" } = dateWiseBreakup[date] || {};
      return {
        date,
        gst: gst.toFixed(2),
        base: base.toFixed(2),
        amount: total.toFixed(2),
        skuid: sku,
        quantity: quantity,
        img: img,
      };
    });
    const columns = [
      {
        title: <span className="table-heading">Product Details</span>,
        dataIndex: "skuid",
        key: "skuid",
        render: (key, obj) => {
          return (
            <div className="delail-CONTAINER">
               {obj.img ? <img src={obj.img} alt="" className="sku-img" />:null}
             <span> SKU:{obj.skuid}</span>
            </div>
          );
        },
      },
      {
        title: <span className="table-heading">Quantity</span>,
        dataIndex: "quantity",
        align:"center",
        key: "quantity",
        render:(quantity)=>{
          return<span className="data">{quantity}</span>
        }
      },
      {
        title: <span className="table-heading">Taxable Amount</span>,
        dataIndex: "base",
        align:"center",
        key: "base",
        render:(base)=>{
          return<span  className="data">₹{base}</span>
        }
      },
      {
        title: <span className="table-heading">GST</span>,
        dataIndex: "gst",
        align:"center",
        key: "gst",
        render:(gst)=>{
          return<span  className="data">₹{gst}</span>
        }
      },
      {
        title: <span className="table-heading">Amount</span>,
        dataIndex: "amount",
        align:"center",
        key: "amount",
        render:(amount)=>{
          return<span  className="amount">₹{amount}</span>
        }
      },
    ];
    // console.log("jojojo", SKUlist);
    return (
      <Modal
        visible={this.props.visible}
        footer={null}
        width={900}
        keyboard={true}
        onCancel={()=>this.props.handleCancel()}
      >
        <div className="modal-data-transtion">
          <div className="heading-modal">Storage Charges</div>
        <div className="id-cointainer">
            <span className="heading-vfd">
            VF Product ID : {transactions[index].transaction_data.vfprodid}
            </span>
            <span className="transtionid">Transaction ID : {transactions[index].transaction_vfid}</span>
        </div>
       
<div className="divider top-divider" />

          <Table
          columns={columns}
          dataSource={SKUlist}
          pagination={false}
          />
          <div  className="footer-table">
            <div className="item ">TOTAL</div>
            <div className="item total"> {current.transaction_amount.toFixed(2)}</div>
          </div>
      
          <div className="footer-cointainer">
            <div className="divider"/>
            <Button
              onClick={() => {
                this.props.handleCancel();
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}
