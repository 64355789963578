import React, { PureComponent } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import moment from "moment";
import { Row, Skeleton, Icon, Select, Table } from "antd";
import { ReactComponent as OrangeCircle } from "../../../../../assets/images/icons/blueCircle.svg";
import { ReactComponent as BlueCircle } from "../../../../../assets/images/icons/OrangeCircle.svg";
import NotFoundCard from "../../../Common/NotFoundCard";
import ErrorCard from "../../../Common/ErrorCard";
import "./index.scss";

const Option = Select.Option;

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.85;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      style={{ fontSize: "10px", fontWeight: "500" }}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {(percent * 100).toFixed(0) > 0 ? `${(percent * 100).toFixed(0)}%` : null}
    </text>
  );
};
class CustomPieChart extends PureComponent {
  CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return this.graphTooltipHandler(payload, label);
    }

    return null;
  };

  graphTooltipHandler(data, label) {
    if (true) {
      //   let graphdata = data.find((data) => data.item_shipmentstatus === label);

      return (
        <div className="graph-custom-tooltip">
          <div>
            <div className="text">
              <p>
                {data[0].name}:{" "}
                <span className="light-bold"> {data[0].value} </span>{" "}
              </p>
            </div>
            <div style={{ display: "block" }} className="text">
              <p>
                Percentage:{" "}
                <span className="light-bold">
                  {" "}
                  {data[0].payload.payload.percantage.toFixed(1) + ` %`}{" "}
                </span>{" "}
              </p>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    const {
      data,

      graphName,
      startDate,
      endDate,
      loading,

      error,
      errorCode,
      errorMsg,
    } = this.props;

    const PageWrapper = (children) => {
      return <div>{children}</div>;
    };

    if (loading) {
      return (
        <Row style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </Row>
      );
    }

    if (!loading && error) {
      return (
        <div className="orders-to-vf-graph-wraper pie" id="fade-in">
          <div className="graph-head">
            {" "}
            <div className="graph-head-child">
              <span className="name">{graphName}</span>{" "}
              <span>
                {startDate} {endDate}
              </span>
            </div>
          </div>
          <ErrorCard errorMsg={errorMsg} errorCode={errorCode} />
        </div>
      );
    }

    if (this.props.data.length === 0) {
      return (
        <div className="orders-to-vf-graph-wraper" id="fade-in">
          <div className="graph-head">
            {" "}
            <div className="graph-head-child">
              <span className="name">{graphName}</span>{" "}
              <span>
                {startDate} {endDate}
              </span>
            </div>
          </div>

          <NotFoundCard
            mainHeading={"Oops! No Data Found."}
            secondHeading={
              <p>
                Looks like there are no graph data in{" "}
                <span>current Date Range</span>.
              </p>
            }
            thirdHeading={
              <p>
                Please <span>select another Date Range</span> from Date Picker
                to filter data.
              </p>
            }
          />
        </div>
      );
    }

    let COLORS = !!this.props.data.length
      ? this.props.data.map((d) => d.color)
      : [];

    return PageWrapper(
      <div className="orders-to-vf-graph-wraper pie" id="fade-in">
        <div className="graph-head">
          {" "}
          <div className="graph-head-child">
            <span className="name">{graphName}</span>{" "}
            <span>
              {startDate} {endDate}
            </span>
          </div>
          <div className="abs-div"> </div>
        </div>

        <ResponsiveContainer width={600} height={600}>
          <PieChart>
            <Pie
              onMouseEnter={(d) => this.props.customData(!!d ? d.name : false)}
              onMouseLeave={(d) => this.props.customData(false)}
              data={this.props.data}
              cx="50%"
              cy="50%"
              margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
              labelLine={false}
              //   label={renderCustomizedLabel}
              outerRadius={210}
              fill="#8884d8"
              dataKey="value"
            >
              {!!this.props.data2.length
                ? this.props.data2.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))
                : []}
            </Pie>
            <Tooltip cursor={false} content={this.CustomTooltip} />
          </PieChart>
        </ResponsiveContainer>
        <div className="map-table-wraper pie">
          <div className="row-wraper head">
            <div className="row row1">Reason</div>
            <div className="row row2">Order Count</div>
            <div className="row row2">Total %</div>

            <div className="row row3">
              {/* <div
                style={{ background: `${entry.color}` }}
                className="circle"
              /> */}
            </div>
          </div>
          {!!this.props.data.length
            ? this.props.data.map((entry, index) => (
                <div
                  className={`row-wraper ${
                    entry.activeTooltip ? `activeTooltip` : ""
                  } `}
                >
                  <div className="row row1">{entry.name}</div>
                  <div className="row row2">{entry.value}</div>
                  <div className="row row2">
                    {entry.percantage.toFixed(1) + ` %`}
                  </div>

                  <div className="row row3">
                    <div
                      style={{ background: `${entry.color}` }}
                      className="circle"
                    />
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    );
  }
}

export default CustomPieChart;
