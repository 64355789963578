import React from "react";
import "./index.scss";
import { Modal, Button, Row, Col } from "antd";

import OrderPlaced from "./OrderPlaced";
import OrderPlacedV2 from "./OrderPlacedV2";
import OrderPlacedFs from "./OrderPlacedFs";
import OrderPlacedFF from "./OrderPlacedFF";
import OrderPlacedCODF from "./OrderPlacedCODF";
import Buybackhandling from "./Buybackhandlingfee";
import Rtoshipping from "./RtoShipping";
import Rtohandleingfee from "./RtoHandleingfee";
import CodRemittancenew from "./CODRemittance";
import RTO from "./RTO";
import COD from "./COD";
import CreditNote from "./CreditNote";
import ManualNote from "./ManualNote";

const MyWalletReviewModal = (props) => {
  const { visible, handleCancel, transactions, id } = props;
  const transaction = transactions.find((t) => t._id["$oid"] === id);
  let Table;
  let title = "";

  let width = 1150;
  let showCSV = true;

  if (transaction) {
    if (transaction.transaction_subtype === "order_placed") {
      Table = <OrderPlaced transaction={transaction} />;
      title = "Orders Placed";
    }
    if (
      transaction.transaction_subtype === "order_placed_pp" ||
      transaction.transaction_subtype === "credit_note_pp"
    ) {
      Table = (
        <OrderPlacedV2 storegeo={props.storegeo} transaction={transaction} />
      );
      title =
        transaction.transaction_subtype === "credit_note_pp"
          ? "Product Price Reverse"
          : "Product Price";
    }
    if (
      transaction.transaction_subtype === "order_placed_fs" ||
      transaction.transaction_subtype === "credit_note_fs"
    ) {
      Table = (
        <OrderPlacedFs storegeo={props.storegeo} transaction={transaction} />
      );
      title =
        transaction.transaction_subtype === "order_placed_fs"
          ? "Forward Shipping Fee"
          : "Forward Shipping Reverse";
    }
    if (
      transaction.transaction_subtype === "order_placed_ff" ||
      transaction.transaction_subtype === "credit_note_ff"
    ) {
      Table = (
        <OrderPlacedFF storegeo={props.storegeo} transaction={transaction} />
      );
      title =
        transaction.transaction_subtype === "order_placed_ff"
          ? "Fulfilment Fees"
          : "Fulfilment Fees Reverse";
    }

    if (
      transaction.transaction_subtype === "order_placed_codf" ||
      transaction.transaction_subtype === "codf_reverse"
    ) {
      Table = (
        <OrderPlacedCODF storegeo={props.storegeo} transaction={transaction} />
      );
      title =
        transaction.transaction_subtype === "order_placed_codf"
          ? "COD Fees"
          : "COD Fees Reverse";
    }

    if (transaction.transaction_subtype === "buyback_handling_fee") {
      Table = (
        <Buybackhandling storegeo={props.storegeo} transaction={transaction} />
      );
      title = "Buyback Handling Fee";
    }
    if (transaction.transaction_subtype === "rto_shipping") {
      Table = (
        <Rtoshipping storegeo={props.storegeo} transaction={transaction} />
      );
      title = "RTO Shipping Fee";
    }
    if (transaction.transaction_subtype === "rto_handling_fee") {
      Table = (
        <Rtohandleingfee storegeo={props.storegeo} transaction={transaction} />
      );
      title = "RTO Handling fee";
    }

    if (transaction.transaction_subtype === "rto_remit") {
      Table = <RTO transaction={transaction} />;
      title = "RTO Remittance";
    }
    if (transaction.transaction_subtype === "cod_remit") {
      Table = <COD transaction={transaction} />;
      title = "COD Remittance";
    }

    if (transaction.transaction_subtype === "cod_remit_new") {
      Table = (
        <CodRemittancenew storegeo={props.storegeo} transaction={transaction} />
      );
      title = "COD Remittance";
    }

    if (transaction.transaction_subtype === "orderamount_gst_deductions") {
      Table = (
        <CodRemittancenew storegeo={props.storegeo} transaction={transaction} />
      );
      title = "GST Deductions";
    }

    if (transaction.transaction_subtype === "credit_note") {
      Table = <CreditNote transaction={transaction} />;
      title = "Credit Note";
    }
    if (transaction.transaction_subtype === "admin_note") {
      Table = <ManualNote transaction={transaction} />;
      title = "Manual Note";
      width = 400;
      showCSV = false;
    }
  }

  if (!transaction) {
    return null;
  }

  return (
    <Modal
      className="my-wallet-review-modal"
      visible={visible}
      title={title}
      width={
        transaction.transaction_subtype === "order_placed_pp" ||
        transaction.transaction_subtype === "order_placed_fs" ||
        transaction.transaction_subtype === "order_placed_ff" ||
        transaction.transaction_subtype === "order_placed_codf" ||
        transaction.transaction_subtype === "credit_note_pp" ||
        transaction.transaction_subtype === "credit_note_ff" ||
        transaction.transaction_subtype === "credit_note_fs" ||
        transaction.transaction_subtype === "codf_reverse" ||
        transaction.transaction_subtype === "buyback_handling_fee" ||
        transaction.transaction_subtype === "rto_shipping" ||
        transaction.transaction_subtype === "rto_handling_fee" ||
        transaction.transaction_subtype === "cod_remit_new" ||
        transaction.transaction_subtype === "orderamount_gst_deductions"
          ? props.storegeo === "global"
            ? 645
            : 820
          : width
      }
      footer={
        <Row>
          <Col xs={18} style={{ textAlign: "left" }}>
            {/* {props.id && showCSV && (
              <a
                href={`/wallet/export_transaction_csv?transaction_id=${
                  props.id
                }`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Icon type="download" /> Export as CSV
              </a>
            )} */}
          </Col>
          <Col xs={6}>
            <Button key="back" onClick={() => handleCancel()}>
              Close
            </Button>
          </Col>
        </Row>
      }
      onCancel={() => handleCancel()}
    >
      {Table}
    </Modal>
  );
};

export default MyWalletReviewModal;
