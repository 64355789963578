import React, { Component } from "react";
import { Icon, Card, Row, Col, Button, Skeleton } from "antd";

import { ReactComponent as rightArrow } from "../../../../../assets/images/icons/long-arrow.svg";
import { ReactComponent as check } from "../../../../../assets/images/icons/check.svg";

import "./index.scss";

class ProductNiche extends Component {
  state = {
    defaultNiches: this.props.defaultNiches,
    showMore: this.props.showMore,
  };

  componentDidUpdate(pp) {
    if (
      pp.defaultNiches.length !== this.props.defaultNiches.length ||
      pp.showMore !== this.props.showMore
    ) {
      this.setState({
        defaultNiches: this.props.defaultNiches,
        showMore: this.props.showMore,
      });
    }
  }

  render() {
    if (this.props.loading) {
      return (
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    return (
      <>
        <Card className="product_niche">
          <Row className="niche-container">
            {this.state.defaultNiches.map((n) => (
              <Col md={6} key={n.id} className="border-container">
                <div
                  className={
                    this.props.selectedNiches.find((e) => e === n.id)
                      ? "niche-column-selected"
                      : "niche-column"
                  }
                  onClick={() => this.props.selectNiche(n.id, 1)}
                >
                  <div
                    className={
                      this.props.selectedNiches.find((e) => e === n.id)
                        ? "niche-name-selected"
                        : "niche-name"
                    }
                  >
                    <Col md={8}>
                      <Icon component={this.props.nicheIcons[n.name]} />
                    </Col>
                    <Col md={16}>
                      <p>{n.name}</p>
                    </Col>
                  </div>
                </div>
                {this.props.selectedNiches.find((e) => e === n.id) && (
                  <>
                    <div style={{ position: "relative", zIndex: 99 }}>
                      <Button
                        block
                        onClick={(e) => this.props.showCategories(e, n.id)}
                      >
                        Open Geek Search <Icon component={rightArrow} />
                      </Button>
                    </div>
                    <Icon component={check} className="niche-check" />
                  </>
                )}
              </Col>
            ))}
            {!this.props.showMore && (
              <Col md={6}>
                <div
                  className="niche-column"
                  onClick={() => this.props.onClickShowMore()}
                >
                  <div className="niche-name">
                    <p style={{ color: "#0068FF" }}>... More Niches</p>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Card>
      </>
    );
  }
}

export default ProductNiche;
