import React, { Component } from "react";
import { message, Pagination } from "antd";
import "./index.scss";
import axios from "axios";
import moment from "moment";
class AllNotification extends Component {
  state = {
    loading: false,
    page: 1,
    pageSize: 10,
    error: false,
    notification_data: [],
    total: 0,
    total_unread: 0,
  };

  fetchNotifications() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL + "/Notifications/get_notifications",
          method: "post",
          data: {
            storeid: this.props.match.params.storeid,
            page: this.state.page,
            pageSize: this.state.pageSize,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState((state) => ({
                loading: false,
                notification_data: res.data.notification_data,
                page: state.page,
                total_unread: res.data.total_unread,
                total: res.data.total,
              }));
            }
          })
          .catch((e) => {
            this.setState({
              loading: false,
              error: true,
            });
          });
      }
    );
  }

  closeNotifications(ids) {
    axios({
      url:
        process.env.REACT_APP_API_URL +
        "/Notifications/mark_as_read_notifications",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        notification_id: ids,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState((state) => ({
            total_unread: 0,
            notification_data: state.notification_data.map((n) => {
              const id = n.notification_id["$oid"];

              if (ids.indexOf(id) === -1) {
                return n;
              } else {
                return {
                  ...n,
                  status: 1,
                };
              }
            }),
          }));
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  }

  markAllRead() {
    axios({
      url: process.env.REACT_APP_API_URL + "/Notifications/mark_all_read",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState((state) => ({
            total_unread: 0,
            notification_data: state.notification_data.map((n) => {
              return {
                ...n,
                status: 1,
              };
            }),
          }));
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  }

  goToPage(page) {
    this.setState(
      {
        loading: true,
        page: page,
      },
      () => this.fetchNotifications()
    );
  }

  componentDidMount() {
    this.fetchNotifications();
  }

  snapshotnotifiaction() {
    const list = this.state.notification_data;
    const size = 5;
    const items = list.slice(0, size);
    return items.map((n, i) => {
      const ahrefProps = {
        href: n.action.actiondata,
        className: n.status === 1 ? "unread" : "",
      };

      if (n.action.actiontype === "REDIRECT_NEWTAB") {
        ahrefProps.target = "_blank";
        ahrefProps.rel = "noreferrer noopener";
      }
      return (
        <a
          {...ahrefProps}
          key={i}
          onClick={() => this.closeNotifications([n.notification_id["$oid"]])}
          className="notification-list"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 16px 8px 16px",
            color: "#898989",
            background: n.status === 0 ? "#f5faff" : null,
          }}
        >
          <div className="notification-icon-content">
            <div
              style={{
                display: "flex",
                justifyContent: "start",
              }}
            >
              <div
                style={{
                  marginRight: "16px",
                  width: "64px",
                  height: "64px",
                  background: "#E8E8E8",
                  borderRadius: "50%",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textDecoration: "underline",
                }}
              >
                <img
                  src={n.icon}
                  alt="Notification icon"
                  style={{ width: "100%" }}
                />
              </div>

              <div>
                <p
                  style={{
                    margin: "0px",
                    fontSize: "12px",
                    lineHeight: "16px",
                    padding: "8px 0px",
                    textDecoration: "underline",
                    fontWeight: "500",
                    letterSpacing: "0.032em",
                    color: n.status === 0 ? "#212121" : null,
                  }}
                >
                  {n.title}
                </p>
                <p
                  style={{
                    margin: "0px",
                    fontSize: "12px",
                    letterSpacing: "0.032em",
                    color: n.status === 0 ? "#212121" : null,
                  }}
                >
                  {n.subtitle}
                </p>
              </div>
            </div>
          </div>
          <div className="notification-date">
            <span
              style={{
                fontSize: "12px",
                letterSpacing: "0.032em",
                color: n.status === 0 ? "#212121" : null,
              }}
            >
              {moment(n.date_created).format("MMM DD , YYYY")} |{" "}
              {moment(n.date_created).format("hh:mm:ss")}
            </span>
          </div>
        </a>
      );
    });
  }

  render() {
    return (
      <div className="notifications" id={this.props.id}>
        {this.props.snapshot === true ? null : (
          <div
            className="notification-page-header"
            style={{ paddingBottom: "28px" }}
          >
            <h3
              style={{
                fontSize: "12px",
                color: "#212121",
                margin: "0px",
                textDecoration: "underline",
                letterSpacing: "0.032em",
              }}
            >
              Notifications
            </h3>
          </div>
        )}

        <div
          className="all-notification-container"
          style={{
            padding: "20px 32px",
            background: "#fff",
            boxShadow: "0 3px 10px rgba(0, 0, 0, 0.05)",
            borderRadius: "4px",
          }}
        >
          {this.props.snapshot === true ? (
            <div className="Snapshot_notifications">
              <div className="recent_notifications">
                <p>Recent Notifications</p>
                <div>
                  {this.state.total_unread !== 0 ? (
                    <div
                      className="notification-marks"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #F5F5F5",
                        paddingBottom: "5px",
                      }}
                    >
                      <div className="notifications-mark">
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#212121",
                            margin: "0px",
                          }}
                        >
                          <span
                            style={{
                              paddingRight: "5px",
                              letterSpacing: "0.032em",
                            }}
                          />
                          {this.state.total_unread} Unread Notification(s)
                        </span>
                      </div>

                      <div className="notification-mark">
                        <button
                          onClick={() => this.markAllRead()}
                          style={{
                            border: "none",
                            background: "transparent",
                            padding: "0",
                            fontSize: "12px",
                            cursor: "pointer",
                            color: "#1370FC",
                            letterSpacing: "0.032em",
                            fontWeight: "400",
                          }}
                        >
                          Mark All As Read
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="notification-marks"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #F5F5F5",
                        paddingBottom: "5px",
                      }}
                    >
                      <div className="notifications-mark">
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#212121",
                            margin: "0px",
                            letterSpacing: "0.032em",
                          }}
                        >
                          No Unread Notifications
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>
              {this.state.total_unread !== 0 ? (
                <div
                  className="notification-marks"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #F5F5F5",
                    paddingBottom: "5px",
                  }}
                >
                  <div className="notifications-mark">
                    <span
                      style={{
                        fontSize: "12px",
                        color: "#212121",
                        margin: "0px",
                      }}
                    >
                      <span
                        style={{
                          paddingRight: "5px",
                          letterSpacing: "0.032em",
                        }}
                      />
                      {this.state.total_unread} Unread Notification(s)
                    </span>
                  </div>

                  <div className="notification-mark">
                    <button
                      onClick={() => this.markAllRead()}
                      style={{
                        border: "none",
                        background: "transparent",
                        padding: "0",
                        fontSize: "12px",
                        cursor: "pointer",
                        color: "#1370FC",
                        letterSpacing: "0.032em",
                        fontWeight: "400",
                      }}
                    >
                      Mark All As Read
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  className="notification-marks"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #F5F5F5",
                    paddingBottom: "5px",
                  }}
                >
                  <div className="notifications-mark">
                    <span
                      style={{
                        fontSize: "12px",
                        color: "#212121",
                        margin: "0px",
                        letterSpacing: "0.032em",
                      }}
                    >
                      No Unread Notifications
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}

          {this.props.snapshot === true
            ? this.snapshotnotifiaction()
            : this.state.notification_data.map((n, i) => {
                const ahrefProps = {
                  href: n.action.actiondata,
                  className: n.status === 1 ? "unread" : "",
                };

                if (n.action.actiontype === "REDIRECT_NEWTAB") {
                  ahrefProps.target = "_blank";
                  ahrefProps.rel = "noreferrer noopener";
                }
                return (
                  <a
                    {...ahrefProps}
                    key={i}
                    onClick={() =>
                      this.closeNotifications([n.notification_id["$oid"]])
                    }
                    className="notification-list"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "8px 16px 8px 16px",
                      color: "#898989",
                      background: n.status === 0 ? "#f5faff" : null,
                    }}
                  >
                    <div className="notification-icon-content">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                        }}
                      >
                        <div
                          style={{
                            marginRight: "16px",
                            width: "64px",
                            height: "64px",
                            background: "#E8E8E8",
                            borderRadius: "50%",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textDecoration: "underline",
                          }}
                        >
                          <img
                            src={n.icon}
                            alt="Notification icon"
                            style={{ width: "100%" }}
                          />
                        </div>

                        <div>
                          <p
                            style={{
                              margin: "0px",
                              fontSize: "12px",
                              lineHeight: "16px",
                              padding: "8px 0px",
                              textDecoration: "underline",
                              fontWeight: "500",
                              letterSpacing: "0.032em",
                              color: n.status === 0 ? "#212121" : null,
                            }}
                          >
                            {n.title}
                          </p>
                          <p
                            style={{
                              margin: "0px",
                              fontSize: "12px",
                              letterSpacing: "0.032em",
                              color: n.status === 0 ? "#212121" : null,
                            }}
                          >
                            {n.subtitle}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="notification-date">
                      <span
                        style={{
                          fontSize: "12px",
                          letterSpacing: "0.032em",
                          color: n.status === 0 ? "#212121" : null,
                        }}
                      >
                        {moment(n.date_created).format("MMM DD , YYYY")} |{" "}
                        {moment(n.date_created).format("hh:mm:ss")}
                      </span>
                    </div>
                  </a>
                );
              })}

          {this.props.snapshot === true ? null : (
            <Pagination
              locale={{ jump_to: "Go to page" }}
              style={{ marginTop: "10px", textAlign: "right" }}
              showQuickJumper
              defaultCurrent={this.state.page}
              pageSize={this.state.pageSize}
              total={this.state.total}
              onChange={(page) => this.goToPage(page)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default AllNotification;
