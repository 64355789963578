import React from "react";
import { Card, Icon, Tooltip, Popover, Button, message, Modal } from "antd";
import { ReactComponent as circle } from "../../../../assets/images/icons/circle-filled.svg";
import { ReactComponent as ShipmentStatusIcon } from "../../../../assets/images/icons/shopping-cart.svg";
import { ReactComponent as BlueTick } from "../../../../assets/images/icons/blue-tick.svg";
import { ReactComponent as GreenTick } from "../../../../assets/images/icons/green-tick.svg";
import { ReactComponent as PurpleTick } from "../../../../assets/images/icons/purple-tick.svg";
import moment from "moment";
import VfIdCopyBadge from "../../Common/VfIdCopyBadge";
import { reasons } from "../../Shipments/DisputeModals/DisputeModal";
import OrderProductTitle from "../.../../../Common/GlobalCommon/ProductTitle/OrderTitle";
import { SHOPIFY_NAME } from "../../../../constants";
import "./index.scss";
import axios from "axios";
const { confirm } = Modal;
class DisputeCard extends React.Component {
  state = {
    rvp: false,
    shipment: this.props.shipment,
    activeTab: "disputeInformation",
    customerTab: {
      editing: false,
      saving: false,
    },
    errors: {},
    shipmentsVisible: {},
  };

  // this function used  to show current tabs
  setActiveTab = (activeTab) => {
    this.setState({
      activeTab,
    });
  };

  // this function used show image modal
  showImage(index, files) {
    this.props.toggleShowImageModal(index, files);
  }

  // action status text wrap in tooltip if exceed the limit

  actionStatusText(status, reverseAwb = false) {
    if (!!reverseAwb && status.length >= 20) {
      return (
        <Tooltip title={status}>
          <span>{status.slice(0, 20).trim() + "..."}</span>
        </Tooltip>
      );
    } else {
      return status;
    }
  }

  cancelRequest() {
    confirm({
      title: "Are you sure?",
      content: "Are you sure you want to  cancel escalation request",
      onOk: () => {
        axios({
          url:
            process.env.REACT_APP_API_URL + "/disputes/cancel_store_disputes",
          method: "post",
          data: {
            storeid: this.props.storeid,
            dispute_vfid: this.props.dispute_vfid,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.props.loadData();
              message.success("Update Successfully!");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
          });
      },
    });
  }

  render() {
    const {
      shipmentStatusColors,
      shipmentStatusText,
      disputeStatusColors,
      disputeStatusText,
    } = this.props;

    // riverse pick up name status object

    const rvp = {
      rvp_request: "Reverse Pickup Requested",
      rvp_initiated: "Reverse Pickup Initiated",
      rvp_awb_map: "AWB Mapped",
      rvp_qc_check: "QC Status Updated",
    };

    // Transaction pick up name status object

    const transaction = {
      remittance: "Remittance Amount Reversed",
      refund: "VF Cost Refunded",
      rvp_transaction: "Reverse Pickup Charged",
    };
    const dispute = this.props.dispute;
    const notes = dispute.notes.filter((note) => note.notetype === "note");
    const logs = dispute.notes.filter((note) => note.notetype === "log");
    let lineItems = this.props.dispute.items;
    let mediaAttachments = this.props.dispute.files;

    return (
      <Card id="test" className="dispute-card">
        <div className="upper-section">
          <div
            // style={{
            //   background: disputeStatusColors[dispute.status],
            // }}
            className="vf"
          >
            <VfIdCopyBadge
              text={"VF Escalation ID"}
              hashSign={false}
              textToCopy={dispute.dispute_vfid}
            />
          </div>
          <div className="cancelEscalation_status">
            {dispute.status === "open" && (
              <div className="cancel_esc">
                <Button
                  className="clickable"
                  style={{ height: "24px" }}
                  onClick={() => this.cancelRequest()}
                >
                  Cancel Escalation
                </Button>
              </div>
            )}
            <div className="dispute-title">
              <span
                style={{
                  display: "flex",
                  color: "white",
                  background: disputeStatusColors[dispute.status],
                  borderRadius: "2px",
                  fontWeight: "500",
                }}
              >
                <Icon component={circle} className="circle-ican" />
                <span className="circle-ican-status">
                  {dispute.status === "in_processing"
                    ? "Processing"
                    : disputeStatusText[dispute.status]}
                </span>
              </span>
            </div>
          </div>
        </div>

        <div class="fields">
          <div class="field">
            <p class="field-label"> WayBill Number(AWB): </p>
            <p class="field-value">
              {" "}
              <span className="blue-text">
                <a
                  href={dispute.tracking_url}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {dispute.dispute_awb}
                </a>
              </span>
            </p>
          </div>
          <div class="field">
            <p class="field-label">Escalation Raised On:</p>
            <p class="field-value">
              <span>
                {moment(dispute.created_at).format("DD MMM, YYYY | LT")}
              </span>{" "}
            </p>
          </div>
          <div class="field">
            <p class="field-label">Order Number:</p>
            <p class="field-value">
              <a
                href={`../orders/closedOrders?search=${dispute.order_vfid}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <span>{dispute.order_vfid}</span>
              </a>
            </p>
          </div>
          <div class="field">
            <p class="field-label">{`${SHOPIFY_NAME} Order Id:`}</p>
            <p class="field-value">
              {" "}
              <a
                href={dispute.shopify_url}
                rel="noreferrer noopener"
                target="_blank"
              >
                <span>{dispute.order_shopify_name}</span>{" "}
              </a>
            </p>
          </div>
        </div>
        <div className="fields action-fields">
          {dispute.hasOwnProperty("dispute_rvp_awb") &&
          !!dispute.dispute_rvp_awb ? (
            <div className="field">
              <p className="field-label">Reverse Pickup AWB: </p>
              <p className="field-value">
                {" "}
                <a
                  href={`https://track.vcfl.express/?waybill=${
                    dispute.dispute_rvp_awb
                  }`}
                  target="_blank"
                >
                  {dispute.dispute_rvp_awb}
                </a>{" "}
              </p>
            </div>
          ) : (
            <div className="field">
              <p className="field-label">Reverse Pickup AWB: </p>
              <p className="field-value">N/A</p>
            </div>
          )}
          {dispute.action_taken &&
          dispute.action_taken.hasOwnProperty("rvp") ? (
            <div className="field">
              <p className="field-label">Reverse Pickup Status: </p>
              <p className="field-value">
                <GreenTick />

                {this.actionStatusText(
                  rvp[
                    dispute.action_taken.rvp[
                      dispute.action_taken.rvp.length - 1
                    ][0]
                  ],
                  true
                )}
              </p>
            </div>
          ) : (
            <div className="field">
              <p className="field-label">Reverse Pickup Status: </p>
              <p className="field-value">No Action Taken </p>
            </div>
          )}
          {dispute.action_taken &&
          dispute.action_taken.hasOwnProperty("transaction") ? (
            <div className="field">
              <p className="field-label">Transaction Status: </p>
              <p className="field-value">
                <BlueTick />

                {this.actionStatusText(
                  transaction[
                    dispute.action_taken.transaction[
                      dispute.action_taken.transaction.length - 1
                    ][0]
                  ],
                  true
                )}
              </p>
            </div>
          ) : (
            <div className="field">
              <p className="field-label">Transaction Status:</p>
              <p className="field-value">No Action Taken </p>
            </div>
          )}{" "}
          {dispute.action_taken &&
          dispute.action_taken.hasOwnProperty("replacement") ? (
            <div className="field">
              <p className="field-label">Replacement Status: </p>
              <p className="field-value">
                <PurpleTick />
                Replacement Created
              </p>
            </div>
          ) : (
            <div className="field">
              <p className="field-label">Replacement Status: </p>
              <p className="field-value">No Action Taken </p>
            </div>
          )}
        </div>
        <div className="tabs-row">
          <ul className="row-ul">
            <li
              className={
                this.state.activeTab === "disputeInformation" ? "active" : ""
              }
              onClick={() => this.setActiveTab("disputeInformation")}
            >
              Information
            </li>
            <li
              className={this.state.activeTab === "lineItems" ? "active" : ""}
              onClick={() => this.setActiveTab("lineItems")}
            >
              Shipment Items
            </li>
            <li
              className={
                this.state.activeTab === "mediaAttachments" ? "active" : ""
              }
              onClick={() => this.setActiveTab("mediaAttachments")}
            >
              Media
            </li>

            <li
              className={this.state.activeTab === "notes" ? "active" : ""}
              onClick={() => this.setActiveTab("notes")}
            >
              Notes <span className="tab-count">{notes.length}</span>
            </li>

            <li
              className={this.state.activeTab === "logs" ? "active" : ""}
              onClick={() => this.setActiveTab("logs")}
            >
              Logs <span className="tab-count">{logs.length}</span>
            </li>
          </ul>
        </div>

        <div
          className={`tab-container ${
            this.state.activeTab !== "disputeInformation" ? "hidden" : ""
          }`}
        >
          <div className="dispute-info-tab" style={{ padding: "16px" }}>
            <div className="upper_data">
              <div className="dispute-reason">
                <h6>Owner:</h6>
                <p className="capitalize-text">
                  {" "}
                  {!!dispute && dispute.raised_by
                    ? dispute.raised_by
                    : "Not Available"}
                </p>
              </div>

              <div className="dispute-reason">
                <h6>Reason:</h6>
                <p>
                  {" "}
                  {!!reasons && reasons[dispute.reason]
                    ? reasons[dispute.reason]
                    : "Not Available"}
                </p>
              </div>
            </div>

            <div
              className="dispute-reason"
              style={{ width: "99%", marginTop: "10px" }}
            >
              <h6>Description:</h6>
              <p style={{ height: "auto" }}>
                {/* {dispute &&
                dispute.description &&
                dispute.description.length <= 60 ? (
                  dispute.description
                ) : (
                  <Tooltip title={dispute.description}>
                    {" "}
                    {dispute.description
                      ? `${dispute.description.substring(0, 60)}...`
                      : "Not Available"}
                  </Tooltip>
                )} */}
                {dispute.description}
              </p>
            </div>
          </div>
        </div>

        <div
          className={`tab-container ${
            this.state.activeTab !== "lineItems" ? "hidden" : ""
          }`}
        >
          <div className="line-items-tab">
            <div className="flex header-varaint-status">
              <div className="flex-5">Variant Details</div>
              <div className="flex-6">Order Status</div>
              <div className="flex-7">Quantity</div>
              <div className="flex-8">Action</div>
            </div>
            {lineItems.map((li) => {
              return (
                <div className={`line-item `} key={li.lineitem_id}>
                  <div className="flex-5">
                    <div className="line-item-product-detail">
                      <span
                        className="line-item-img"
                        style={{ background: `url(${li.img})` }}
                      />
                      <span>
                        <p className="blue-text line-item-name">
                          <OrderProductTitle
                            orderProductname={li.name}
                            productShopifyid={li.product_shopifyid}
                            storeShopifyadmin={li.product_link}
                          />
                        </p>
                        <p className="line-item-sku">
                          <b>SKU: </b> #
                          <b className="detailcommon">
                            {" "}
                            {li && li.sku.length ? (
                              li.sku.length > 16 ? (
                                <Tooltip title={li.sku} placement="topLeft">
                                  {li.sku.slice(0, 16) + "..."}
                                </Tooltip>
                              ) : (
                                li.sku
                              )
                            ) : (
                              "Not Available"
                            )}
                          </b>
                        </p>
                        <p className="line-item-sku">
                          <b>Specs: </b>{" "}
                          <b className="detailcommon">
                            {li.allspecs === "" ? (
                              "Default"
                            ) : li && li.allspecs && li.allspecs.length > 16 ? (
                              <Tooltip title={li.allspecs} placement="topLeft">
                                {li.allspecs.slice(0, 16) + "..."}
                              </Tooltip>
                            ) : (
                              li.allspecs
                            )}
                          </b>
                        </p>
                      </span>
                    </div>
                  </div>

                  <div className="flex-6">
                    <span
                      style={{
                        color: shipmentStatusColors[dispute.shipment_status],
                      }}
                      className="shipment-status"
                    >
                      <Tooltip
                        title={shipmentStatusText[dispute.shipment_status]}
                      >
                        <Icon component={ShipmentStatusIcon} />
                      </Tooltip>

                      {/* {shipmentStatusText[dispute.shipment_status]} */}
                    </span>
                  </div>

                  <div className="flex-7">
                    <span>
                      {li.quantity === 1
                        ? `${li.quantity} piece `
                        : `${li.quantity} pieces `}
                    </span>
                  </div>

                  <div className="flex-8">
                    {dispute.shipment_status === "delivered" ||
                    dispute.shipment_status === "rto" ? (
                      <p className="delivered-date">
                        {dispute.shipment_status === "rto" ? (
                          dispute.shipment_closed_on ? (
                            <span className="returned-on">Returned On</span>
                          ) : (
                            <span className="returned">Returned</span>
                          )
                        ) : dispute.shipment_closed_on ? (
                          <span className="delivered-on">Delivered On</span>
                        ) : (
                          <span className="delivered"> Delivered</span>
                        )}{" "}
                        <p className="delivered-on-date">
                          {dispute.shipment_closed_on
                            ? moment(dispute.shipment_closed_on).format(
                                "DD MMM, YYYY"
                              )
                            : ""}
                        </p>
                      </p>
                    ) : (
                      <p className="shipment-status">
                        {shipmentStatusText[dispute.shipment_status]}
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={`tab-container ${
            this.state.activeTab !== "mediaAttachments" ? "hidden" : ""
          }`}
        >
          <div className="media-attachments">
            {mediaAttachments.map((media, i) => {
              let imageTag = true;
              media.split(".")[1] === "mp4"
                ? (imageTag = false)
                : (imageTag = true);
              return (
                <div>
                  <div
                    className="image-div"
                    key={i}
                    onClick={() => this.showImage(i, mediaAttachments)}
                  >
                    {media ? (
                      imageTag ? (
                        <div>
                          <img
                            src={`${
                              process.env.REACT_APP_API_URL
                            }/disputes/get_dispute_media/${media}`}
                            alt="not found"
                          />
                        </div>
                      ) : (
                        <video className="video-data">
                          <source
                            src={`${
                              process.env.REACT_APP_API_URL
                            }/disputes/get_dispute_media/${media}`}
                            type="video/mp4"
                          />
                        </video>
                      )
                    ) : (
                      "image not available"
                    )}
                  </div>
                  <a
                    target="_self"
                    download
                    href={`${
                      process.env.REACT_APP_API_URL
                    }/disputes/get_dispute_media/${media}`}
                    className="download-image-data"
                  >
                    <Tooltip title="download">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.8"
                        height="19.6"
                        viewBox="0 0 16.8 19.6"
                      >
                        <g data-name="Group 2197">
                          <g data-name="Group 2193">
                            <g data-name="Group 2192">
                              <path
                                data-name="Path 52"
                                d="M16.154 13.411a.633.633 0 00-.646.619v3.714a.633.633 0 01-.646.619H1.934a.633.633 0 01-.646-.619V14.03a.647.647 0 00-1.292 0v3.714A1.9 1.9 0 001.934 19.6h12.928a1.9 1.9 0 001.938-1.857v-3.714a.633.633 0 00-.646-.618z"
                                fill="#1370fc"
                              />
                            </g>
                          </g>
                          <g data-name="Group 2195">
                            <g data-name="Group 2194">
                              <path
                                data-name="Path 53"
                                d="M12.316 11.892a.69.69 0 00-.957 0l-2.27 2.265V.688a.689.689 0 00-1.378 0v13.469l-2.269-2.265a.688.688 0 00-.974.972L7.912 16.3a.69.69 0 00.974 0l3.445-3.438a.687.687 0 00-.015-.97z"
                                fill="#1370fc"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </Tooltip>
                  </a>
                </div>
              );
            })}
          </div>
        </div>

        <div
          style={{
            display: this.state.activeTab === "notes" ? "block" : "none",
          }}
          className="notes-logs-data"
        >
          {(this.state.activeTab === "notes" ? notes : logs).length > 0 ? (
            <div className="flex notes-logs-txt">
              <div className="flex-note-log">
                {this.state.activeTab === "notes" ? `Notes` : `Logs`}
              </div>
              <div className="flex-date">Date</div>
              <div className="flex-addedby">Added by</div>
            </div>
          ) : null}

          {(this.state.activeTab === "notes" ? notes : logs).length > 0 ? (
            (this.state.activeTab === "notes" ? notes : logs).map(
              //  flex  flex-2 flex-3 and flex-1 used to fex grid system
              (note, idx) => (
                <div className="flex note-logs" key={idx}>
                  <div className="flex-note-log">
                    {" "}
                    {note && note.note.length <= 60 ? (
                      note.note
                    ) : (
                      <Tooltip title={note.note}>
                        {" "}
                        {note.note ? `${note.note.substring(0, 60)}...` : null}
                      </Tooltip>
                    )}
                  </div>
                  <div className="flex-date">
                    {moment(note.date).format("DD MMM, YYYY | HH:mm")}
                  </div>
                  <div className="flex-addedby">by {note.added_by}</div>
                </div>
              )
            )
          ) : (
            <div className="no-found">
              No {this.state.activeTab === "notes" ? `Notes` : `Logs`} Found!
            </div>
          )}
        </div>

        {/* logs */}
        <div
          style={{
            display: this.state.activeTab === "logs" ? "block" : "none",
          }}
          className="notes-logs-data"
        >
          {(this.state.activeTab === "notes" ? notes : logs).length > 0 ? (
            <div className="flex escalation-status-wraper">
              {/* Progress status for escalation */}

              <div className="progress-line">
                <div
                  className="progress"
                  style={{
                    animation:
                      dispute.status === "open"
                        ? "load-25 1.2s linear forwards"
                        : dispute.status === "in_processing"
                        ? "load-50 1.2s linear forwards"
                        : dispute.status === "close"
                        ? "load-100 1.2s linear forwards"
                        : dispute.status === "cancelled"
                        ? "load-100 1.2s linear forwards"
                        : "",
                  }}
                />

                <div className="status">
                  <div
                    className={`dot ${
                      dispute.status === "open" ||
                      dispute.status === "close" ||
                      dispute.status === "in_processing" ||
                      dispute.status === "cancelled"
                        ? "completed"
                        : ""
                    } ${dispute.status === "open" ? "dot-active" : ""} `}
                  />

                  <div className="time-and-status-wraper">
                    <div
                      className={
                        dispute.status === "open" ||
                        dispute.status === "close" ||
                        dispute.status === "in_processing" ||
                        dispute.status === "cancelled"
                          ? "shipment_step_color"
                          : "original_color"
                      }
                    >
                      Open
                    </div>
                    <div className="date">
                      {" "}
                      {moment(dispute.created_at).format("DD MMM, YYYY")}
                    </div>
                  </div>
                </div>

                <div className="status">
                  <div
                    className={`dot ${
                      dispute.status === "close" ||
                      dispute.status === "in_processing" ||
                      dispute.status === "cancelled"
                        ? "completed"
                        : ""
                    } ${
                      dispute.status === "in_processing" ? "dot-active" : ""
                    }`}
                  />

                  <div className="time-and-status-wraper">
                    <div
                      className={
                        dispute.status === "close" ||
                        dispute.status === "in_processing" ||
                        dispute.status === "cancelled"
                          ? "shipment_step_color"
                          : "original_color"
                      }
                    >
                      Processing
                    </div>

                    {dispute.status === "in_processing" ||
                    dispute.status === "close" ||
                    dispute.status === "cancelled" ? (
                      <div className="date">
                        {" "}
                        {moment(dispute.in_processing_date).format(
                          "DD MMM, YYYY"
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="status" style={{ left: "1px" }}>
                  <div
                    className={`dot ${
                      dispute.status === "close" ||
                      dispute.status === "cancelled"
                        ? "completed"
                        : ""
                    } ${
                      dispute.status === "close" ||
                      dispute.status === "cancelled"
                        ? "dot-active"
                        : ""
                    }`}
                  />

                  <div
                    className="time-and-status-wraper"
                    style={{ marginLeft: "-64px" }}
                  >
                    <div
                      className={
                        dispute.status === "close" ||
                        dispute.status === "cancelled"
                          ? "shipment_step_color"
                          : "original_color"
                      }
                    >
                      {dispute.status === "cancelled" ? "cancelled" : "Closed"}
                    </div>

                    {dispute.status === "close" ||
                    dispute.status === "cancelled" ? (
                      <div className="date">
                        {" "}
                        {moment(dispute.closed_on).format("DD MMM, YYYY")}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {/* <div className="note">
            NOTE: The following escalation status dates are under IST timezone:
          </div> */}

          <div
            className={`logs-wraper ${
              !!logs && logs.length <= 3 ? "single-log" : ""
            }`}
          >
            {(this.state.activeTab === "notes" ? notes : logs).length > 0 ? (
              (this.state.activeTab === "notes" ? notes : logs).map(
                //  flex  flex-2 flex-3 and flex-1 used to fex grid system
                (note, idx) => (
                  <div className="flex note-logs status-logs" key={idx}>
                    <div className="flex-date">
                      <div
                        className={`circle ${
                          logs.length === 1 ? "single-circle" : ""
                        }`}
                      />

                      <div className="date-concat">
                        <span className="font-weight">
                          {moment(note.date).format("DD MMM, YYYY")}
                        </span>

                        <span>
                          <span className="line">|</span>
                          {moment(note.date).format("dddd")}{" "}
                          <span className="line">|</span>
                        </span>

                        <span>{moment(note.date).format("LT")} </span>
                      </div>
                    </div>

                    <div className="flex-note-log">
                      {" "}
                      {note && note.note.length <= 60 ? (
                        note.note
                      ) : (
                        <Tooltip title={note.note}>
                          {" "}
                          {note.note
                            ? `${note.note.substring(0, 60)}...`
                            : null}
                        </Tooltip>
                      )}
                    </div>

                    <div className="flex-addedby">Added by {note.added_by}</div>
                  </div>
                )
              )
            ) : (
              <div className="no-found">
                No {this.state.activeTab === "notes" ? `Notes` : `Logs`} Found!
              </div>
            )}
          </div>
        </div>
      </Card>
    );
  }
}

export default DisputeCard;
