import React, { Component } from "react";
import { Row, Alert, Icon, message } from "antd";
import axios from "axios";
import "./index.scss";
import moment from "moment";
import { connect } from "react-redux";
import CounterCardWraper from "../Common/CounterCardWraper";
import { SHOPIFY_NAME } from "../../../../constants";
import OrdersReportNav from "./OrdersReportNav";
import OrderToVfStatusGraph from "../Common/Graph/Graph";
import StoreVsVfDataGraph from "../Common/Graph/Graph";
import TopPerformingProducts from "./TopPerformingProducts";
import MapChart from "./Map/Map.js";
import CustomTable from "../Common/CustomTable";
import MapChart2 from "./Map/Map.js";

class OrdersReport extends Component {
  state = {
    loading: false,
    ndrs: [],
    error: false,
    errorMsg: "",
    startDate: moment()
      .subtract(30, "days")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),

    ordersCounter: {},
    ordersCounterLoading: false,
    ordersCounterError: false,
    ordersCounterErrorMsg: "",
    ordersCounterErrorCode: "",

    orderToVfStatusData: [],
    orderToVfStatusDataLoading: false,
    orderToVfStatusDataError: false,
    orderToVfStatusDataErrorMsg: "",
    orderToVfStatusDataErrorCode: "",

    storeVsVfData: [],
    storeVsVfDataLoading: false,
    storeVsVfDataError: false,
    storeVsVfDataErrorMsg: "",
    storeVsVfDataErrorCode: "",

    topPerformingProducts: [],
    topPerformingProductsLoading: false,
    topPerformingProductsError: false,
    topPerformingProductsErrorMsg: "",
    topPerformingProductsErrorCode: "",

    mapData: [],
    mapDataLoading: false,
    sortingOrder: "",
    mapDataError: false,
    mapDataErrorMsg: "",
    mapDataErrorCode: "",

    mapData2: [],
    mapDataLoading2: false,
    mapData2error: false,
    mapData2errorMsg: "",
    mapData2errorCode: "",

    rtoData: [],
    rtoDataLoading: false,
    rtoDataError: false,
    rtoDataErrorMsg: "",
    rtoDataErrorCode: "",

    //
    storeProductsData: [],
    storeProductsLoading: false,
    storeProductsError: false,
    storeProductsErrorMsg: "",
    storeProductsErrorCode: "",
    selectedStoreProducts: "",

    rtoDownloadCsvLoading: false,

    pageSize: 10,
    page: 1,
    total: 0,
  };

  componentDidMount() {
    this.storeProductsFetch();
    this.ordersCounter();

    this.storeVsVfData();
    this.topPerformingProducts();
    this.shipmentJourney();
    this.mapData();
    this.mapData2();
    this.orderToVfStatusData();
  }

  componentWillUnmount() {
    if (this.cancelToken1) {
      this.cancelToken1.cancel();
    }
    if (this.cancelToken2) {
      this.cancelToken2.cancel();
    }
    if (this.cancelToken3) {
      this.cancelToken3.cancel();
    }
    if (this.cancelToken4) {
      this.cancelToken4.cancel();
    }
    if (this.cancelToken5) {
      this.cancelToken5.cancel();
    }
    if (this.cancelToken6) {
      this.cancelToken6.cancel();
    }

    if (this.cancelToken7) {
      this.cancelToken7.cancel();
    }
    if (this.cancelToken8) {
      this.cancelToken8.cancel();
    }
  }

  ordersCounter() {
    this.setState(
      {
        ordersCounterLoading: true,
      },
      () => {
        if (this.cancelToken1) {
          this.cancelToken1.cancel();
        }
        this.cancelToken1 = axios.CancelToken.source();
        axios({
          url: process.env.REACT_APP_API_URL + "/bqreporting/counters_data",
          method: "post",
          cancelToken: this.cancelToken1.token,
          data: {
            store_id: this.props.match.params.storeid,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
            product_id: this.state.selectedStoreProducts,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                ordersCounter: res.data.data,
                ordersCounterLoading: false,
                ordersCounterError: false,
                errorMsg: "",
              });
            } else {
              this.setState({
                ordersCounter: {},

                ordersCounterError: true,
                ordersCounterErrorMsg: res.data.msg,
                ordersCounterLoading: false,
              });
            }
          })
          .catch((e) => {
            // this.setState({
            //   error: true,
            //   errorMsg: e.message,
            //   ordersCounterLoading: true,
            // });

            if (!!e.request) {
              this.setState({
                ordersCounterError: true,
                ordersCounterErrorCode: e.request.status,
                ordersCounterErrorMsg: e.request.statusText,
                ordersCounterLoading: false,
              });
            } else {
              this.setState({
                ordersCounterError: false,
                ordersCounterErrorMsg: e.message,
                ordersCounterLoading: true,
              });
            }
          });
      }
    );
  }

  orderToVfStatusData() {
    this.setState(
      {
        orderToVfStatusDataLoading: true,
      },
      () => {
        if (this.cancelToken2) {
          this.cancelToken2.cancel();
        }
        this.cancelToken2 = axios.CancelToken.source();

        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/bqreporting/order_to_vF_status_graph",
          method: "post",
          cancelToken: this.cancelToken2.token,
          data: {
            store_id: this.props.match.params.storeid,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
            product_id: this.state.selectedStoreProducts,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                orderToVfStatusData: res.data.data.type1,
                orderToVfStatusDataLoading: false,
                error: false,
                errorMsg: "",
              });
            } else {
              this.setState({
                orderToVfStatusData: [],
                orderToVfStatusDataError: true,
                orderToVfStatusDataErrorMsg: res.data.msg,
                orderToVfStatusDataLoading: false,
              });
            }
          })
          .catch((e) => {
            if (!!e.request) {
              this.setState({
                orderToVfStatusDataError: true,
                orderToVfStatusDataErrorCode: e.request.status,
                orderToVfStatusDataErrorMsg: e.request.statusText,
                orderToVfStatusDataLoading: false,
              });
            } else {
              this.setState({
                orderToVfStatusDataError: false,
                orderToVfStatusDataErrorMsg: e.message,
                orderToVfStatusDataLoading: true,
              });
            }
          });
      }
    );
  }

  storeVsVfData() {
    this.setState(
      {
        storeVsVfDataLoading: true,
      },
      () => {
        if (this.cancelToken3) {
          this.cancelToken3.cancel();
        }
        this.cancelToken3 = axios.CancelToken.source();

        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/bqreporting/order_comparision_graph",
          method: "post",
          cancelToken: this.cancelToken3.token,
          data: {
            store_id: this.props.match.params.storeid,
            date_type: this.state.sortingOrder,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
            product_id: this.state.selectedStoreProducts,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success == 1) {
              this.setState({
                storeVsVfData: res.data.type2,
                storeVsVfDataLoading: false,
                error: false,
                errorMsg: "",
              });
            } else {
              this.setState({
                storeVsVfData: [],
                storeVsVfDataError: true,
                storeVsVfDataErrorMsg: res.data.msg,
                storeVsVfDataLoading: false,
              });
            }
          })
          .catch((e) => {
            // this.setState({
            //   error: true,
            //   errorMsg: e.message,
            //   storeVsVfDataLoading: true,
            // });

            if (!!e.request) {
              this.setState({
                storeVsVfDataError: true,
                storeVsVfDataErrorCode: e.request.status,
                storeVsVfDataErrorMsg: e.request.statusText,
                storeVsVfDataLoading: false,
              });
            } else {
              this.setState({
                storeVsVfDataError: false,
                storeVsVfDataErrorMsg: e.message,
                storeVsVfDataLoading: true,
              });
            }
          });
      }
    );
  }

  topPerformingProducts() {
    this.setState(
      {
        topPerformingProductsLoading: true,
      },
      () => {
        if (this.cancelToken4) {
          this.cancelToken4.cancel();
        }
        this.cancelToken4 = axios.CancelToken.source();

        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/bqreporting/top_performing_products",
          method: "post",
          cancelToken: this.cancelToken4.token,
          data: {
            store_id: this.props.match.params.storeid,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
            product_id: this.state.selectedStoreProducts,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                topPerformingProducts: res.data.data,
                topPerformingProductsLoading: false,
                topPerformingProductsError: false,
                topPerformingProductsErrorMsg: "",
              });
            } else {
              this.setState({
                topPerformingProducts: [],
                topPerformingProductsError: true,
                errorMsg: res.data.msg,
                topPerformingProductsLoading: false,
              });
            }
          })
          .catch((e) => {
            if (!!e.request) {
              this.setState({
                topPerformingProductsError: true,
                topPerformingProductsErrorCode: e.request.status,
                topPerformingProductsErrorMsg: e.request.statusText,
                topPerformingProductsLoading: false,
              });
            } else {
              this.setState({
                topPerformingProductsError: false,
                topPerformingProductsErrorMsg: e.message,
                topPerformingProductsLoading: true,
              });
            }
          });
      }
    );
  }

  mapData() {
    this.setState(
      {
        mapDataLoading: true,
      },
      () => {
        if (this.cancelToken5) {
          this.cancelToken5.cancel();
        }
        this.cancelToken5 = axios.CancelToken.source();

        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/bqreporting/top_performing_states",
          method: "post",
          cancelToken: this.cancelToken5.token,
          data: {
            store_id: this.props.match.params.storeid,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
            product_id: this.state.selectedStoreProducts,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success == 1) {
              this.setState({
                mapData: res.data.data.type1,
                mapDataLoading: false,
                mapDataError: false,
                errorMsg: "",
              });
            } else {
              this.setState({
                mapData: [],
                mapDataError: true,
                mapDataErrorMsg: res.data.msg,
                mapDataLoading: false,
              });
            }
          })
          .catch((e) => {
            this.setState({
              error: true,
              errorMsg: e.message,
              mapDataLoading: true,
            });

            if (!!e.request) {
              this.setState({
                mapDataError: true,
                mapDataErrorCode: e.request.status,
                mapDataErrorMsg: e.request.statusText,
                mapDataLoading: false,
              });
            } else {
              this.setState({
                mapDataError: false,
                mapDataErrorMsg: e.message,
                mapDataLoading: true,
              });
            }
          });
      }
    );
  }
  mapData2() {
    this.setState(
      {
        mapDataLoading2: true,
      },
      () => {
        if (this.cancelToken6) {
          this.cancelToken6.cancel();
        }
        this.cancelToken6 = axios.CancelToken.source();

        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/bqreporting/delivery_count_from_overall",
          method: "post",
          cancelToken: this.cancelToken6.token,
          data: {
            store_id: this.props.match.params.storeid,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
            product_id: this.state.selectedStoreProducts,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success == 1) {
              this.setState({
                mapData2: res.data.data.type1,
                mapDataLoading2: false,
                mapData2error: false,
                errorMsg: "",
              });
            } else {
              this.setState({
                mapData2: [],
                mapData2error: true,
                mapData2errorMsg: res.data.msg,
                mapDataLoading2: false,
              });
            }
          })
          .catch((e) => {
            if (!!e.request) {
              this.setState({
                mapData2error: true,
                mapData2errorCode: e.request.status,
                mapData2errorMsg: e.request.statusText,
                mapDataLoading2: false,
              });
            } else {
              this.setState({
                mapData2error: false,
                mapData2errorMsg: e.message,
                mapDataLoading2: true,
              });
            }
          });
      }
    );
  }

  changeFilter(e) {
    console.log("hi",e);
    this.setState(
      {
        startDate:e.startDate? moment(e.startDate).format("YYYY-MM-DD"):null,
        endDate:e.endDate? moment(e.endDate).format("YYYY-MM-DD"):null,
      },
      () => {
        this.ordersCounter();
        this.orderToVfStatusData();
        this.storeVsVfData();
        this.topPerformingProducts();
        this.mapData();
        this.mapData2();
        this.shipmentJourney();
      }
    );
  }

  productsSetter(e, apply = false, clear = false) {
    if (apply) {
      this.ordersCounter();
      this.orderToVfStatusData();
      this.storeVsVfData();
      this.topPerformingProducts();
      this.mapData();
      this.mapData2();
      this.shipmentJourney();
    } else if (clear) {
      this.setState(
        {
          selectedStoreProducts: "",
        },
        () => {
          this.ordersCounter();
          this.orderToVfStatusData();
          this.storeVsVfData();
          this.topPerformingProducts();
          this.mapData();
          this.mapData2();
          this.shipmentJourney();
        }
      );
    } else {
      this.setState({
        selectedStoreProducts: e,
      });
    }
  }

  startDateHandler(startDate) {
    if (!!startDate) {
      return moment(startDate).format("DD MMM, YYYY");
    } else {
      return "";
    }
  }

  endDateHandler(endDate) {
    if (!!endDate) {
      return moment(endDate).format("DD MMM, YYYY");
    } else {
      return "";
    }
  }

  mapDataHandler(data) {
    if (!!data && !!data.length) {
      let arr = {};

      data.forEach((item) => {
        let obj = {};

        obj = {
          [item.province_code]: {
            value: item.delivery_percentage,
            total: item.total_order_count,
            count: item.delivered_orders,
          },
        };

        arr = { ...arr, ...obj };
      });

      return arr;
    } else {
      return {};
    }
  }

  mapDataHandler2(data) {
    if (!!data && !!data.length) {
      let arr = {};

      data.forEach((item) => {
        let obj = {};

        obj = {
          [item.province_code]: {
            value: item.delivery_percentage,
            total: item.total_order_count,
            count: item.delivered_orders,
          },
        };

        arr = { ...arr, ...obj };
      });

      return arr;
    } else {
      return {};
    }
  }

  // order comparison dropdown handler function

  dropdownChange(selected) {
    this.setState(
      {
        storeVsVfDataLoading: true,
        sortingOrder: selected,
      },
      () => {
        if (this.cancelToken3) {
          this.cancelToken3.cancel();
        }
        this.cancelToken3 = axios.CancelToken.source();

        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/bqreporting/order_comparision_graph",
          method: "post",
          cancelToken: this.cancelToken3.token,
          data: {
            store_id: this.props.match.params.storeid,
            date_type: selected,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success == 1) {
              this.setState({
                storeVsVfData: res.data.type2,
                storeVsVfDataLoading: false,
                error: false,
                errorMsg: "",
              });
            } else {
              this.setState({
                storeVsVfData: [],
                error: true,
                errorMsg: res.data.msg,
                storeVsVfDataLoading: false,
              });
            }
          })
          .catch((e) => {
            this.setState({
              error: true,
              errorMsg: e.message,
              storeVsVfDataLoading: true,
            });
          });
      }
    );
  }

  // rto journey

  shipmentJourney() {
    this.setState(
      {
        rtoDataLoading: true,
      },
      () => {
        if (this.cancelToken7) {
          this.cancelToken7.cancel();
        }
        this.cancelToken7 = axios.CancelToken.source();
        axios({
          url: process.env.REACT_APP_API_URL + "/bqreporting/shipment_journey",
          method: "post",
          cancelToken: this.cancelToken7.token,
          data: {
            store_id: this.props.match.params.storeid,
            pageSize: this.state.pageSize,
            page: this.state.page,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
            product_id: this.state.selectedStoreProducts,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                rtoData: res.data.data,
                total: res.data.total,
                rtoDataLoading: false,
                rtoDataError: false,
                rtoDataErrorMsg: "",
              });
            } else {
              this.setState({
                rtoData: [],
                rtoDataLoading: true,
                rtoDataError: res.data.msg,
                rtoDataErrorMsg: false,
              });
            }
          })
          .catch((e) => {
            if (!!e.request) {
              this.setState({
                rtoDataLoading: false,
                rtoDataError: true,
                rtoDataErrorMsg: e.request.statusText,
                rtoDataErrorCode: e.request.status,
              });
            } else {
              this.setState({
                rtoDataLoading: true,
                rtoDataError: false,
                rtoDataErrorMsg: e.message,
              });
            }
          });
      }
    );
  }

  storeProductsFetch() {
    this.setState(
      {
        storeProductsLoading: true,
      },
      () => {
        if (this.cancelToken8) {
          this.cancelToken8.cancel();
        }
        this.cancelToken8 = axios.CancelToken.source();
        axios({
          url: process.env.REACT_APP_API_URL + "/bqreporting/get_store_product",
          method: "post",
          cancelToken: this.cancelToken8.token,
          data: {
            store_id: this.props.match.params.storeid,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                storeProductsData: res.data.data,
                storeProductsLoading: false,
                storeProductsError: false,
                storeProductsErrorMsg: "",
              });
            } else {
              this.setState({
                storeProductsData: [],
                storeProductsLoading: true,
                storeProductsError: res.data.msg,
                storeProductsErrorMsg: false,
              });
            }
          })
          .catch((e) => {
            if (!!e.request) {
              this.setState({
                storeProductsLoading: false,
                storeProductsError: true,
                storeProductsErrorMsg: e.request.statusText,
                storeProductsErrorCode: e.request.status,
              });
            } else {
              this.setState({
                storeProductsLoading: true,
                storeProductsError: false,
                storeProductsErrorMsg: e.message,
              });
            }
          });
      }
    );
  }

  rtoDownloadCsv() {
    this.setState(
      {
        rtoDownloadCsvLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/bqreporting/shipment_journey",
          method: "post",
          data: {
            downloadcsv: 1,
            store_id: this.props.match.params.storeid,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
          },
          withCredentials: true,
        })
          .then((res) => {
            this.setState({ rtoDownloadCsvLoading: false });

            if (res.data.success === 1) {
              message.success(res.data.msg);
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({ rtoDownloadCsvLoading: false });

            message.error(e.message);
          });
      }
    );
  }

  goToPage(page) {
    this.setState(
      {
        page: page,
      },
      () => {
        this.shipmentJourney();
      }
    );
  }

  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        pageSize: pageSize,
      },
      () => this.shipmentJourney()
    );
  }

  render() {
    const {
      ordersCounterLoading,
      orderToVfStatusDataLoading,
      storeVsVfDataLoading,
      topPerformingProductsLoading,
      mapDataLoading,
      mapDataLoading2,
      rtoDataLoading,
    } = this.state;

    const PageWrapper = (children) => {
      return (
        <>
          <div className="order-reporting-wraper">
            <OrdersReportNav
              filters={this.state}
              productLoading={
                ordersCounterLoading ||
                orderToVfStatusDataLoading ||
                storeVsVfDataLoading ||
                topPerformingProductsLoading ||
                mapDataLoading ||
                mapDataLoading2 ||
                rtoDataLoading
              }
              {...this.props}
              orderToVfStatusDataLoading={this.state.orderToVfStatusDataLoading}
              changeFilter={(e) => this.changeFilter(e)}
              changeHandler={(e) => this.changeHandler(e)}
              productsSetter={(e, a, c) => this.productsSetter(e, a, c)}
              ndrs={this.state.ndrs}
              ndrcount={this.state.ndrcount}
              storeid={this.props.match.params.storeid}
              loading={false}
            />
            <Row className="tabs-row">{children}</Row>
          </div>
        </>
      );
    };

    return PageWrapper(
      <>
        {this.state.ordersCounterLoading ? null : (
          <h2
            style={{ marginBottom: "12px" }}
            className="content-page-subtitle"
          >
            {`Data shown for your ${SHOPIFY_NAME} Orders between`}{" "}
            {moment(this.state.startDate).format("DD MMM, YYYY")} -{" "}
            {moment(this.state.endDate).format("DD MMM, YYYY")}
          </h2>
        )}
        {this.state.ordersCounterLoading ? null : (
          <div className="alert-wrap-reporting">
            <Alert
              style={{ marginBottom: "40px" }}
              message="Important Information"
              description="Please note the data below updates every 2 hours."
              type="info"
              showIcon
            />
            <span
              className="ex-ic"
              style={{
                width: "24px",
                height: "24px",
                fontSize: "24px",
                lineHeight: "24px",
                display: "inline-block",
                color: "#0068FF",
              }}
            >
              <Icon type="exclamation-circle" theme="filled" />
            </span>
          </div>
        )}

        <CounterCardWraper
          counterType="Orders"
          loading={this.state.ordersCounterLoading}
          data={this.state.ordersCounter}
          error={this.state.ordersCounterError}
          errorMsg={this.state.ordersCounterErrorMsg}
          errorCode={this.state.ordersCounterErrorCode}
        />
        <OrderToVfStatusGraph
          data={this.state.orderToVfStatusData}
          error={this.state.orderToVfStatusDataError}
          errorMsg={this.state.orderToVfStatusDataErrorMsg}
          errorCode={this.state.orderToVfStatusDataErrorCode}
          yAxisKey={"item_shipmentstatus"}
          firstBar={"total_order_count"}
          graphName={"Orders to VF Status"}
          startDate={this.startDateHandler(this.state.startDate)}
          endDate={this.endDateHandler(this.state.endDate)}
          loading={this.state.orderToVfStatusDataLoading}
          firstLegendText={"Number of Orders"}
        />
        <StoreVsVfDataGraph
          error={this.state.storeVsVfDataError}
          errorMsg={this.state.storeVsVfDataErrorMsg}
          errorCode={this.state.storeVsVfDataErrorCode}
          sortingOrder={this.state.sortingOrder}
          dropdownChange={(v) => this.dropdownChange(v)}
          data={JSON.parse(JSON.stringify(this.state.storeVsVfData))}
          originalData={this.state.storeVsVfData}
          yAxisKey={"date"}
          firstBar={"total_shopify_orders_count"}
          secondBar={"total_vf_orders_count"}
          graphName={"Orders on Store vs Orders to VF"}
          startDate={this.startDateHandler(this.state.startDate)}
          endDate={this.endDateHandler(this.state.endDate)}
          loading={this.state.storeVsVfDataLoading}
          firstLegendText={"Orders on Store"}
          secondLegendText={"Orders to VF"}
        />

        <TopPerformingProducts
          data={this.state.topPerformingProducts}
          error={this.state.topPerformingProductsError}
          errorMsg={this.state.topPerformingProductsErrorMsg}
          errorCode={this.state.topPerformingProductsErrorCode}
          loading={this.state.topPerformingProductsLoading}
          startDate={this.startDateHandler(this.state.startDate)}
          endDate={this.endDateHandler(this.state.endDate)}
        />

        <CustomTable
          customColumns={true}
          data={this.state.rtoData}
          downloadCSVLoading={this.state.rtoDownloadCsvLoading}
          downloadCSV={() => this.rtoDownloadCsv()}
          loading={this.state.rtoDataLoading}
          error={this.state.rtoDataError}
          errorMsg={this.state.rtoDataErrorMsg}
          errorCode={this.state.rtoDataErrorCode}
          startDate={this.startDateHandler(this.state.startDate)}
          endDate={this.endDateHandler(this.state.endDate)}
          graphName={"Shipment NDR Journey"}
          currentPage={this.state.page}
          pageSize={this.state.pageSize}
          total={this.state.total}
          goToPage={(e) => this.goToPage(e)}
          onShowSizeChange={(a, b) => this.onShowSizeChange(a, b)}
        />

        <div className="maps-container">
          <MapChart2
            map2={true}
            originalData={this.state.mapData2}
            subHeadingName={
              "Total Orders Delivered in State vs Total Orders Delivered in India"
            }
            startDate={this.startDateHandler(this.state.startDate)}
            endDate={this.endDateHandler(this.state.endDate)}
            data={this.mapDataHandler2(this.state.mapData2)}
            loading={this.state.mapDataLoading2}
            error={this.state.mapData2error}
            errorMsg={this.state.mapData2errorMsg}
            errorCode={this.state.mapData2errorCode}
          />
          <MapChart
            originalData={this.state.mapData}
            subHeadingName={
              "Total Orders Delivered in State vs Total Orders Ordered in that State"
            }
            startDate={this.startDateHandler(this.state.startDate)}
            endDate={this.endDateHandler(this.state.endDate)}
            data={this.mapDataHandler(this.state.mapData)}
            loading={this.state.mapDataLoading}
            error={this.state.mapDataError}
            errorMsg={this.state.mapDataErrorMsg}
            errorCode={this.state.mapDataErrorCode}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  store: state.auth.auth.store,
});
export default connect(mapStateToProps)(OrdersReport);
