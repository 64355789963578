import React from "react";
import { Modal, message } from "antd";
import ConnectToShopify from "../../Settings/Shopify/ConnectToShopify";

import {
  SHOPIFY_CLIENT_ID,
  BASE_URL,
  SHOPIFY_NAME,
} from "../../../../constants";
import axios from "axios";

import "./index.scss";

class AddStoreModal2 extends React.Component {
  state = {
    geo: "in",
  };

  handleSubmit = (shop, storeid, geo) => {
    geo = "in";

    axios({
      url: process.env.REACT_APP_API_URL + "/store/add_store",
      method: "post",
      data: {
        shop,
        storeid,
        geo,
        timezone: new Date().getTimezoneOffset(),
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          window.location =
            `https://${shop}.myshopify.com/admin/oauth/authorize?client_id=${SHOPIFY_CLIENT_ID}&scope=read_product_listings,read_products,write_products,read_orders,write_orders,read_inventory,write_customers,write_inventory,write_price_rules,read_draft_orders,write_draft_orders,read_customers,write_customers,read_checkouts,write_checkouts,read_fulfillments,read_merchant_managed_fulfillment_orders,write_merchant_managed_fulfillment_orders,read_assigned_fulfillment_orders,write_assigned_fulfillment_orders,read_third_party_fulfillment_orders,write_third_party_fulfillment_orders,write_fulfillments&redirect_uri=` +
            BASE_URL +
            `login/shopify_callback&state=${res.data.id}`;
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  render() {
    const props = this.props;
    const { visible, handleCancel } = props;
    return (
      <Modal
        className="switch-store-modal3"
        width={600}
        destroyOnClose={true}
        title={
          <p className="title_txt"> {`Add New Shopify ${SHOPIFY_NAME}`}</p>
        }
        visible={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <ConnectToShopify
          showCancel={true}
          alignButtonsRight={true}
          onCancel={() => handleCancel()}
          handleOk={(val) => this.handleSubmit(val, props.storeid)}
          {...props}
          toggleShopifyFlagFunctionlity={() =>
            this.props.toggleShopifyFlagFunctionlity()
          }
        />
      </Modal>
    );
  }
}

export default AddStoreModal2;
