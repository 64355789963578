import React, { Component } from "react";
import "./index.scss";

// import { Modal } from "antd";

import image from "../../../../assets/images/logos/vfulfillLogoWithBackground.png";

import axios from "axios";

class Preview extends Component {
  state = {
    data: [],
    btnStatus: false,
    vfbdid: "",
    loading: true,
    filters: {
      search: "",
    },
  };

  fetchOrders() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/Notifications/get_broadcast",
          method: "POST",
          data: {
            vfbdid: this.state.vfbdid,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                data: res.data.broadcast,
              });
            } else {
              this.setState({
                loading: false,
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
              error: true,
              errorMsg: err.message,
            });
          });
      }
    );
  }

  componentDidMount() {
    let id = window.location.href.split("=")[
      window.location.href.split("=").length - 1
    ];

    this.setState(
      {
        vfbdid: id,
      },
      () => this.fetchOrders()
    );
  }

  render() {
    return (
      <div
        className={`preview-broadcast1 ${
          this.state.loading ? "" : "showOpacity"
        }`}
      >
        <div className="preview">
          {this.state.data.length ? (
            <div className="imgdiv">
              <img src={this.state.data[0].image} alt="" />
            </div>
          ) : null}

          {this.state.data.length ? (
            <div className="we">
              {" "}
              <h1>{this.state.data[0].title}</h1>{" "}
            </div>
          ) : null}

          {this.state.data.length ? (
            <div className="we">
              {" "}
              <p>{this.state.data[0].subject}</p>{" "}
            </div>
          ) : null}

          {this.state.data.length ? (
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.data[0].description,
              }}
              className="rf"
            />
          ) : null}
        </div>
        <div className="footer">
          <div className="footerimg">
            <img src={image} alt="" />
          </div>

          <h4>
            End-to-End Dropshipping Ecosystem,
            <br />
            made for the Indian eCommerce.
          </h4>

          <p className="follow">Follow Us:</p>

          <div className="social">
            <a
              href="https://www.instagram.com/vfulfillhq/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 36 36"
              >
                <g
                  id="Group_19"
                  data-name="Group 19"
                  transform="translate(-597 -2223)"
                >
                  <circle
                    id="Ellipse_10"
                    data-name="Ellipse 10"
                    cx="18"
                    cy="18"
                    r="18"
                    transform="translate(597 2223)"
                    fill="#fff"
                  />
                  <g
                    id="Group_16"
                    data-name="Group 16"
                    transform="translate(606.498 2232.498)"
                  >
                    <path
                      id="Path_34"
                      data-name="Path 34"
                      d="M121.413,109.468h-6.876a5.074,5.074,0,0,0-5.068,5.068v6.876a5.074,5.074,0,0,0,5.068,5.068h6.876a5.074,5.074,0,0,0,5.068-5.068v-6.876a5.074,5.074,0,0,0-5.068-5.068Zm3.357,11.945a3.357,3.357,0,0,1-3.357,3.357h-6.876a3.357,3.357,0,0,1-3.357-3.357v-6.876a3.357,3.357,0,0,1,3.357-3.357h6.876a3.357,3.357,0,0,1,3.357,3.357Z"
                      transform="translate(-109.468 -109.468)"
                      fill="#3a3a3c"
                    />
                    <path
                      id="Path_35"
                      data-name="Path 35"
                      d="M184.6,180.2a4.4,4.4,0,1,0,4.4,4.4,4.405,4.405,0,0,0-4.4-4.4Zm0,7.089a2.689,2.689,0,1,1,2.689-2.689,2.689,2.689,0,0,1-2.689,2.689Z"
                      transform="translate(-176.096 -176.096)"
                      fill="#3a3a3c"
                    />
                  </g>
                </g>
              </svg>
            </a>

            <a
              style={{ marginLeft: "14px", marginRight: "14px" }}
              href="https://twitter.com/vfulfillhq"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 36 36"
              >
                <g
                  id="Group_21"
                  data-name="Group 21"
                  transform="translate(-342 -2167)"
                >
                  <circle
                    id="Ellipse_11"
                    data-name="Ellipse 11"
                    cx="18"
                    cy="18"
                    r="18"
                    transform="translate(342 2167)"
                    fill="#fff"
                  />
                  <g
                    id="Group_18"
                    data-name="Group 18"
                    transform="translate(350.684 2178.504)"
                  >
                    <path
                      id="Path_39"
                      data-name="Path 39"
                      d="M45.259,35.967a8.25,8.25,0,0,1-2.375.65A4.146,4.146,0,0,0,44.7,34.33a8.278,8.278,0,0,1-2.626,1,4.139,4.139,0,0,0-7.047,3.772,11.739,11.739,0,0,1-8.524-4.321,4.14,4.14,0,0,0,1.28,5.521,4.106,4.106,0,0,1-1.873-.518c0,.018,0,.035,0,.052a4.138,4.138,0,0,0,3.317,4.054,4.151,4.151,0,0,1-1.867.072,4.14,4.14,0,0,0,3.863,2.872A8.348,8.348,0,0,1,25.1,48.55a11.76,11.76,0,0,0,18.1-9.908c0-.179,0-.358-.012-.535a8.386,8.386,0,0,0,2.064-2.14Z"
                      transform="translate(-25.103 -34.028)"
                      fill="#3a3a3c"
                    />
                  </g>
                </g>
              </svg>
            </a>
            <a
              href="https://www.facebook.com/vfulfillhq"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 36 36"
              >
                <g
                  id="Group_20"
                  data-name="Group 20"
                  transform="translate(-469 -2129)"
                >
                  <circle
                    id="Ellipse_9"
                    data-name="Ellipse 9"
                    cx="18"
                    cy="18"
                    r="18"
                    transform="translate(469 2129)"
                    fill="#fff"
                  />
                  <path
                    id="Path_32"
                    data-name="Path 32"
                    d="M47.809,33.039h-3.09V44.358H40.038V33.039H37.812V29.061h2.226V26.486c0-1.841.874-4.723,4.723-4.723l3.467.015v3.861H45.713a.953.953,0,0,0-.993,1.084v2.341h3.5Z"
                    transform="translate(443.549 2114.754)"
                    fill="#3a3a3c"
                  />
                </g>
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/vfulfillhq/"
              style={{ marginLeft: "14px" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 36 36"
              >
                <path
                  id="Union_1"
                  data-name="Union 1"
                  d="M1667.993-785.415a17.92,17.92,0,0,1-5.721-3.857,17.94,17.94,0,0,1-3.857-5.721A17.887,17.887,0,0,1,1657-802a17.887,17.887,0,0,1,1.415-7.006,17.929,17.929,0,0,1,3.857-5.721,17.919,17.919,0,0,1,5.721-3.857A17.889,17.889,0,0,1,1675-820a17.888,17.888,0,0,1,7.006,1.415,17.919,17.919,0,0,1,5.721,3.857,17.929,17.929,0,0,1,3.857,5.721A17.887,17.887,0,0,1,1693-802a17.887,17.887,0,0,1-1.415,7.007,17.94,17.94,0,0,1-3.857,5.721,17.92,17.92,0,0,1-5.721,3.857A17.887,17.887,0,0,1,1675-784,17.888,17.888,0,0,1,1667.993-785.415Zm11.9-13.672v5.455h3.484v-6.152c0-3.021-.652-5.344-4.182-5.344a3.664,3.664,0,0,0-3.3,1.812h-.047v-1.533H1672.5v11.218h3.484v-5.549c0-1.463.279-2.881,2.093-2.881C1679.865-802.062,1679.889-800.388,1679.889-799.087Zm-13.061,5.455h3.486v-11.218h-3.486Zm-.326-14.854a2.033,2.033,0,0,0,2.033,2.033,2.034,2.034,0,0,0,2.031-2.033,2.033,2.033,0,0,0-2.031-2.031A2.033,2.033,0,0,0,1666.5-808.486Z"
                  transform="translate(-1657 820)"
                  fill="#fff"
                />
              </svg>
            </a>
          </div>

          <p className="address">
            VCFL EXPRESS PRIVATE LIMITED 1st Floor, Wing-B, AIHP Signature,
            <br />
            418 & 419, Udyog Vihar Phase 4, Gurugram, 122015, Haryana, India
          </p>
        </div>
      </div>
    );
  }
}

export default Preview;
