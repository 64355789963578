import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Layout, Modal } from "antd";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import "./Dashboard.scss";
// import AddStoreModal from "./Common/AddStoreModal";
import StoreChanelModal from "./Common/StoreChanelModal";
import { ReactComponent as ErrorIcon } from "../../assets/images/icons/500.svg";
import NoData from "./Common/NoData";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import UpdateHeader from "./AppUpdateModal/header.js";
import { storecount } from "../../store/actions/authActions";
import FeedbackWidget from "./FeedbackWidget";
import AddStoreModal from "./Common/AddStoreModalNew";
import WoCommerceModal from "./Common/WoCommerceModal";
import OverDueModal from "./OverDueModal/index.js";
// import AddStoreModal2 from "./Common/AddStoreModal2";
import AddStoreModal2 from "./Common/AddStoreModal2new";
import Intercom from "../../Intercom/index.js";
import analytics from "../../analytics";
const { Content } = Layout;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    Sentry.captureException(error, { extra: info });
    // You can also log the error to an error reporting service
    axios({
      url: process.env.REACT_APP_API_URL + "/user/log_error",
      method: "post",
      data: {
        error,
        info,
        storeid: this.props.auth.store.id,
        location: this.props.location.pathname,
      },
      withCredentials: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <NoData
          image={ErrorIcon}
          heading="Oops! Something went wrong!"
          content="If you continue to see this error, please contact support"
        />
      );
    }

    return this.props.children;
  }
}

class Dashboard extends Component {
  state = {
    sidebarOpen: window.innerWidth > 768 ? true : false,
    switchStoreModalVisible: false,
    addStoreModalVisible: false,
    wallet_balance: 0,
    appStatus: true,
    storeName: undefined,
    url: undefined,
    storeSelected: "",
    flag: {
      flagchanel: "",
    },
    flagVisiable: false,
    woCommerceModalVisible: false,
    ShopifModalVisible: false,
    store_url: "",
    store_admin_url: "",
    walletLoading: false,
    due_from: 0,
    showPaymentDueModal: false,
  };

  // Woocommercelogic

  toggleWoCommerceModal() {
    if (
      (this.state.storeSelected === "shopify_store" &&
        this.state.flag.flagchanel === "") ||
      this.state.flag.flagchanel === "in" ||
      (this.state.storeSelected === "shopify_store" &&
        this.state.flag.flagchanel === "global")
    ) {
      this.toggleShopifModalVisible();
    } else if (
      (this.state.storeSelected === "WoCommerce" &&
        this.state.flag.flagchanel === "") ||
      this.state.flag.flagchanel === "in" ||
      (this.state.storeSelected === "WoCommerce" &&
        this.state.flag.flagchanel === "global")
    ) {
      this.setState({
        woCommerceModalVisible: !this.state.woCommerceModalVisible,
        flagVisiable: false,
      });
    }
  }

  toggleWoCommerceModal2() {
    this.setState({
      woCommerceModalVisible: !this.state.woCommerceModalVisible,
    });
  }

  //addStoremodal2logic
  toggleShopifModalVisible() {
    this.setState({
      ShopifModalVisible: !this.state.ShopifModalVisible,
      flagVisiable: false,
    });
  }

  fetchDashboardRequests = async () => {
    this.setState({ walletLoading: true });

    try {
      const res1 = await axios({
        url: process.env.REACT_APP_API_URL + "/wallet/get_current_balance",
        method: "post",
        data: {
          currency:
            this.props.auth.store &&
            this.props.auth.store.store_geo === "global"
              ? "USD"
              : "INR",
        },
        withCredentials: true,
      });

      const res2 = await axios({
        url: process.env.REACT_APP_API_URL + "/snapshot/",
        method: "post",
        data: {
          storeid: this.props.match.params.storeid,
        },
        withCredentials: true,
      });
      this.setState({
        wallet_balance: res1.data.balance,
        appStatus: res2.data.new_app_status,
        storeName: res2.data.shop_name,
        url: res2.data.shopify_connect_url,
        store_url: res2.data.store_url,
        store_admin_url: res2.data.store_admin_url,
        walletLoading: false,
      });
    } catch (e) {
      this.setState({
        error: true,
        errorMsg: e.message,
        loading: false,
        walletLoading: false,
      });
    }
  };

  toggleIndiaGlobalflag() {
    this.setState({
      flagVisiable: !this.state.flagVisiable,
      addStoreModalVisible: false,
    });
  }

  toggleIndiaGlobalModal(key, value) {
    this.setState({
      flag: {
        ...this.state.flag,
        [key]: value,
      },
    });
    this.toggleWoCommerceModal();
  }

  handleStoreChanel(key, value) {
    this.setState({
      [key]: value,
    });
    this.toggleIndiaGlobalflag();
  }

  setCookie(cookieName, cookieValue, expirationMillis, path) {
    var d = new Date();
    d.setTime(d.getTime() + expirationMillis);
    var expires = "expires=" + d.toUTCString();

    // Include the path in the cookie setting if provided, otherwise use the default path '/'
    var pathString = path ? "path=" + path + ";" : "path=/;";

    document.cookie =
      cookieName + "=" + cookieValue + ";" + expires + ";" + pathString;
  }

  getCookie(cookieName) {
    var name = cookieName + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(";");
    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i];
      while (cookie.charAt(0) == " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) == 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return "";
  }

  componentDidMount() {
    this.props.storecount(this.props.match.params.storeid);
    this.fetchDashboardRequests();
    document.title = this.props.title;

    this.unlisten = this.props.history.listen(() => {
      if (window.innerWidth <= 768) {
        this.setState({ sidebarOpen: false });
      }
    });

    const user = this.props.auth.user;
    var cookieValue = this.getCookie("past_due");

    if (
      user &&
      user.account_status &&
      user.subscription === "past_due" &&
      !cookieValue
    ) {
      setTimeout(() => {
        this.setState(
          { showPaymentDueModal: true, due_from: user.due_from },
          () => {
            this.setCookie(
              "past_due",
              "billing_false",
              24 * 60 * 60 * 1000,
              "/"
            );
          }
        );
      }, 5000);
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentWillUpdate(nextProps) {
    let str =
      !!nextProps && !!nextProps.title
        ? nextProps.title.split("|")[1].trim()
        : null;

    if (!!str) {
      analytics.track(str);
    }

    document.title = nextProps.title;
  }

  toggleSidebar() {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
    });
  }

  toggleSwitchStoreModal() {
    this.setState({
      switchStoreModalVisible: !this.state.switchStoreModalVisible,
    });
  }

  toggleAddStoreModal() {
    this.setState({
      addStoreModalVisible: !this.state.addStoreModalVisible,
      storeSelected: "",
      flag: {
        flagchanel: "",
      },
    });
  }

  toggleGobackAddStoreModal() {
    this.setState({
      addStoreModalVisible: !this.state.addStoreModalVisible,
      flagVisiable: !this.state.flagVisiable,
      storeSelected: "",
      flag: {
        flagchanel: "",
      },
    });
  }

  toggleFlageBackfunctionlity() {
    this.setState({
      flagVisiable: !this.state.flagVisiable,
      woCommerceModalVisible: !this.state.woCommerceModalVisible,
      storeSelected: "",
      flag: {
        flagchanel: "",
      },
    });
  }

  toggleShopifyFlagFunctionlity() {
    this.setState({
      flagVisiable: !this.state.flagVisiable,
      ShopifModalVisible: !this.state.ShopifModalVisible,
      storeSelected: "",
      flag: {
        flagchanel: "",
      },
    });
  }

  unVisibleAddStoreModal() {
    this.setState({
      addStoreModalVisible: false,
    });
  }

  render() {
    const { auth } = this.props;

    // Fetch URL and Pass to Switch Store
    const url_redirect = window.location.href
      .split("/")
      .slice(3)
      .join("/");
    if (!auth.logged_in)
      return (
        <Redirect
          to={{
            pathname: "/switch-store",
            search: `${url_redirect}`,
          }}
        />
      );

    if (!auth.store || !auth.store.id) return <Redirect to={`/switch-store`} />;

    if (!this.props.children) return <Redirect to={`/switch-store`} />;

    if (this.props.auth.user.email_verified !== 1)
      return <Redirect to={`/onboarding`} />;

    if (auth.redirect && auth.redirect.includes("plan")) {
      return window.location.assign("/profile?plan");
    }

    if (this.props.auth.user.show_onboarding === true)
      return window.location.assign(
        process.env.REACT_APP_NEW_APP + `/onboarding-question`
      );

    if (this.props.auth.user.subscription == "") {
      return <Redirect to={`/subscription`} />;
    }

    if (this.props.auth.user && !this.props.auth.user.account_status) {
      if (!window.location.href.includes("profile?plan")) {
        return <Redirect to={`/profile?plan`} />;

        // window.location.assign("/profile?plan");
      }
    }

    return (
      <>
        {this.state.appStatus === false ? (
          <UpdateHeader
            auth={auth}
            geo={this.props.auth.store.store_geo}
            storeName={this.state.storeName}
            url={this.state.url}
            store_url={this.state.store_admin_url}
          />
        ) : null}

        <Layout style={{ height: "calc(100vh)" }}>
          {!this.props.hideSidebar && (
            <Sidebar
              appStatus={this.state.appStatus}
              {...this.props}
              user={auth.user}
              sidebarOpen={this.state.sidebarOpen}
              toggleSwitchStoreModal={() => this.toggleSwitchStoreModal()}
              toggleAddStoreModal={() => this.toggleAddStoreModal()}
            />
          )}
          <Layout
            className={`lay ${!!this.props.fullWidthNavbar ? "lay2" : null}`}
          >
            <Navbar
              walletLoading={this.state.walletLoading}
              appStatus={this.state.appStatus}
              top={
                this.state.appStatus !== undefined &&
                this.state.appStatus === false
                  ? 56
                  : 0
              }
              {...this.props}
              user={auth.user}
              wallet_balance={this.state.wallet_balance}
              toggleSidebar={() => this.toggleSidebar()}
              toggleSwitchStoreModal={() => this.toggleSwitchStoreModal()}
              toggleAddStoreModal={() => this.toggleAddStoreModal()}
              role={this.props.auth.user.user_stores[auth.store.id].store_role}
              store_url={this.state.store_url}
              store_admin_url={this.state.store_admin_url}
            />
            <Content
              className={`dashboard-content ${
                this.props.hideSidebar ? "no-sidebar" : ""
              } ${this.state.appStatus === true ? `upheader` : `downheader`} ${
                this.props.customPadding === true
                  ? "dashboard-content-padding"
                  : ""
              }`}
            >
              <ErrorBoundary {...this.props}>
                {this.props.children}
              </ErrorBoundary>
            </Content>
          </Layout>
          {/* <AddStoreModal
            storeid={auth.store.id}
            visible={this.state.addStoreModalVisible}
            handleCancel={() => this.toggleAddStoreModal()}
            alignButtonsRight={true}
            user={auth.user}
          /> */}
          <StoreChanelModal
            storeid={auth.store.id}
            visible={this.state.addStoreModalVisible}
            handleCancel={() => this.unVisibleAddStoreModal()}
            toggleAddStoreModal={() => this.toggleAddStoreModal()}
            alignButtonsRight={true}
            user={auth.user}
            unVisibleAddStoreModal={() => this.unVisibleAddStoreModal()}
            handleStoreChanel={(key, value) =>
              this.handleStoreChanel(key, value)
            }
            storeSelected={this.state.storeSelected}
            toggleIndiaGlobalModal={(key, value) =>
              this.toggleIndiaGlobalModal(key, value)
            }
            flag={this.state.flag}
            toggleIndiaGlobalflag={() => this.toggleIndiaGlobalflag()}
            flagVisiable={this.state.flagVisiable}
          />

          <AddStoreModal
            visible={this.state.flagVisiable}
            flag={this.state.flag}
            toggleIndiaGlobalModal={(key, value) =>
              this.toggleIndiaGlobalModal(key, value)
            }
            handleCancel={() => this.toggleIndiaGlobalflag()}
            toggleWoCommerceModal={() => this.toggleWoCommerceModal()}
            toggleGobackAddStoreModal={() => this.toggleGobackAddStoreModal()}
          />

          <WoCommerceModal
            visible={this.state.woCommerceModalVisible}
            toggleWoCommerceModal2={() => this.toggleWoCommerceModal2()}
            history={this.props.history}
            toggleFlageBackfunctionlity={() =>
              this.toggleFlageBackfunctionlity()
            }
          />

          <AddStoreModal2
            toggleShopifModalVisible={() => this.toggleShopifModalVisible()}
            visible={this.state.ShopifModalVisible}
            handleCancel={() => this.toggleShopifModalVisible()}
            // handleCancel={() => this.toggleAddStoreModal()}
            storeid={auth.store.id}
            toggleShopifyFlagFunctionlity={() =>
              this.toggleShopifyFlagFunctionlity()
            }
          />

          {/* <FeedbackWidget /> */}
          <Intercom auth={auth} />
          <OverDueModal
            due_from_date={
              this.props.auth &&
              this.props.auth.plan &&
              this.props.auth.plan.current_plan &&
              this.props.auth.plan.current_plan.due_from_date
            }
            due_from={this.state.due_from}
            onCancel={() => this.setState({ showPaymentDueModal: false })}
            visible={this.state.showPaymentDueModal}
          />
        </Layout>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storecount: (storeid) => dispatch(storecount(storeid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
