import React, { Component } from "react";
import { switchStore, signOut } from "../../../../store/actions/authActions";
import { connect } from "react-redux";
import { Skeleton } from "antd";
import qs from "qs";
import "./index.scss";

class SwitchToStore extends Component {
  componentDidMount() {
    console.log("aaaaaaaaaaa1");
    const storeid = this.props.match.params.storeid;

    if (!!this.props.location.search) {
      this.props.storeSwitched(storeid, this.props.location.search);
    } else {
      this.props.storeSwitched(storeid);
    }
  }
  componentDidUpdate() {
    console.log("aaaaaaaaaaa2");
    const setCookie = (cname, cvalue) => {
      const d = new Date();
      d.setTime(d.getTime() + 100000); // (exdays*24*60*60*1000)
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    };
    const getCookie = (cname) => {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    };
    if (this.props.auth.logged_in) {
      const store = this.props.auth.store;
      const params = qs.parse(this.props.location.search.replace(/\?/, ""));
      if (!store) {
        this.props.logOut("You do not have any active stores!");
        return;
      }

      if (params.redirect) {
        // window.location.href = params.redirect;
        this.props.history.push(`/${params.redirect}`);
      }
      //Check if url_redirect exists in Session Storage
      else if (
        // sessionStorage.getItem("url_redirect") !== null &&
        // sessionStorage.getItem("url_redirect") !== undefined
        getCookie("url_redirect") !== null &&
        getCookie("url_redirect") !== undefined
      ) {
        let url_redirect = getCookie("url_redirect")
          .replace(/['"]+/g, "")
          .replace(",", " ")
          .split("/")
          .slice(2)
          .join("/");
        this.props.history.push(
          `/${this.props.auth.store.store_geo}/${this.props.auth.store.id}/${url_redirect}`
        );
      } else if (store.store_role === "owner") {
        this.props.history.push(
          `/${this.props.auth.store.store_geo}/${this.props.auth.store.id}`
        );
      } else if (store.store_permissions.indexOf("all") !== -1) {
        this.props.history.push(
          `/${this.props.auth.store.store_geo}/${this.props.auth.store.id}`
        );
      } else if (
        store.store_permissions.indexOf("access_snapshot_page") !== -1
      ) {
        this.props.history.push(
          `/${this.props.auth.store.store_geo}/${this.props.auth.store.id}`
        );
      } else if (
        store.store_permissions.indexOf("view_discover_products_page") !== -1
      ) {
        this.props.history.push(
          `/${this.props.auth.store.store_geo}/${this.props.auth.store.id}/products/search-products`
        );
      } else if (store.store_permissions.indexOf("view_open_orders") !== -1) {
        this.props.history.push(
          `/${this.props.auth.store.store_geo}/${this.props.auth.store.id}/orders/open`
        );
      } else if (store.store_permissions.indexOf("view_shipments") !== -1) {
        this.props.history.push(
          `/${this.props.auth.store.store_geo}/${this.props.auth.store.id}/tracking`
        );
      } else {
        const page = store.store_permissions.find(
          (p) => p !== "snapshot" && p !== "products" && p !== "shipments"
        );
        this.props.history.push(
          `/${this.props.auth.store.store_geo}/${this.props.auth.store.id}/${page}`
        );
      }
    } else {
      // Parameter passed by Dashboard is validated and stored in Cookie
      if (
        this.props.history.location.search !== null &&
        this.props.history.location.search !== undefined
      ) {
        setCookie(
          "url_redirect",
          `${JSON.stringify(
            this.props.history.location.search.replace(/\?/, "")
          )}`
        ); // Cookie exists for 100 seconds
      }
      // this.props.history.push(`/login`);
      console.log("herere");
      return window.location.assign(
        process.env.REACT_APP_OLD_APP + `/switch-store`
      );
    }
  }
  render() {
    return (
      <div className="loading-container">
        <p>Redirecting to your store...</p>
        <Skeleton active />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    storeSwitched: (storeid, redirect) =>
      dispatch(switchStore(storeid, redirect)),
    logOut: (msg) => dispatch(signOut(msg)),
  };
};
const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SwitchToStore);
