import React, { Component } from "react";
import Intercom from "@intercom/messenger-js-sdk";

class IntercomComponent extends Component {
  componentDidMount() {
    const { user } = this.props.auth;

    Intercom({
      app_id: "sm08dgcp",
      user_id: user.numid, // Replace with the variable you use to capture the user's ID
      name: user.user_fname, // Replace with the variable you use to capture the user's name
      email: user.user_email, // Replace with the variable you use to capture the user's email
      created_at: user.user_created, // Replace with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds)
    });
  }

  render() {
    return null; // Since this component doesn't render any UI, we return null
  }
}

export default IntercomComponent;
