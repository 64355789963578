import React, { Component } from "react";
import {
  Row,
  Skeleton,
  Alert,
  Table,
  Switch,
  message,
  Icon,
  Tooltip,
  Pagination,
} from "antd";
class LmdTable extends Component {
  render() {
    let valueAspreKey = {
      A: "Zone A",
      B: "Zone B",
      C: "Zone C",
      D: "Zone D",
      E: "Zone E",
      minimum_reverse_pickup_charges: "Minimum Reverse Pickup Charges",
      cod_fees: "COD Fees Charges",
    };
    let chargeableAsPerKey = {
      A: "Intra City Shipping Charges",
      B: "Intra State Shipping Charges",
      C: "Shipping to Metros",
      D: "Shipping to Rest of India",
      E:
        " Shipping to Special Zones like, J&K, Kerala, Rural Locations, Remote Areas, etc.",
      minimum_reverse_pickup_charges: "",
      cod_fees: "",
    };
    const columns = [
      {
        title: "Forward Shipping",
        dataIndex: "key",
        width: "30%",
        render: (key, obj) => {
          return <div>{valueAspreKey[key]}</div>;
        },
      },

      {
        title: "Chargeables",
        dataIndex: "key",
        width: "40%",
        render: (key, obj) => {
          return <div>{chargeableAsPerKey[key]}</div>;
        },
      },
      {
        title: " Prices in ₹ ( For Each 500 gm SLAB)",
        dataIndex: "key",
        width: "30%",
        render: (key, obj) => {
          if (key === "minimum_reverse_pickup_charges") {
            return (
              <div>
                {obj["minimum_reverse_pickup_charges"].replace("INR", "₹")}{" "}
              </div>
            );
          }
          if (key === "cod_fees") {
            return (
              <>
                <div>
                  {obj["cod_fees"]["cod_fee"]} or{" "}
                  {obj["cod_fees"]["min"].replace("INR", "₹")}
                </div>
                <div>
                  <span style={{ fontSize: "8px" }}>(whichever is higher)</span>
                </div>
              </>
            );
          }
          return <div>{obj.lmdf500.replace("INR", "₹")}</div>;
        },
      },
    ];
    return (
      <Table
        rowKey={(row, i) => row.key}
        columns={columns}
        dataSource={this.props.data}
        // onChange={(page) => this.goToPage(page)}
        pagination={false}
        width="500px"
        className="lmd-table"
      />
    );
  }
}
export default LmdTable;
