import React, { Component } from "react";
import { Card, Checkbox, Button, Input, message, Radio, Select } from "antd";
import Progressbar from "./Progressbar";
import axios from "axios";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./index.scss";

const { Option } = Select;
var step3_questions = [
  {
    qid: 1,
    question: "How do you drive traffic to your store front?",
    type: "multiselect-singlecolumn",
    mandatory: true,
    fixedunit: "",
    options: [
      {
        key: "social_platforms",
        value:
          "Paid Traffic from Social Platforms like Facebook, Instagram, TikTok, etc.",
      },
      {
        key: "search_engines",
        value:
          "Paid Traffic from Search Engines / Display like Google, Youtube, etc.",
      },
      {
        key: "native_platforms",
        value:
          "Paid Traffic from Native Advertorials Platforms like Taboola, Outbrain, MGID, etc. ",
      },
      {
        key: "influencer_marketing",
        value: "Influencer Marketing",
      },
      {
        key: "content_marketing",
        value: "Content Marketing",
      },
      {
        key: "marketplaces",
        value: "Marketplaces",
      },
    ],
  },
  {
    qid: 2,
    question: "How do you drive traffic to your store front?",
    type: "multiselect-singlecolumn",
    mandatory: true,
    fixedunit: "",
    options: [
      {
        key: "social_platforms",
        value:
          "Paid Traffic from Social Platforms like Facebook, Instagram, TikTok, etc.",
      },
      {
        key: "search_engines",
        value:
          "Paid Traffic from Search Engines / Display like Google, Youtube, etc.",
      },
      {
        key: "native_platforms",
        value:
          "Paid Traffic from Native Advertorials Platforms like Taboola, Outbrain, MGID, etc. ",
      },
      {
        key: "influencer_marketing",
        value: "Influencer Marketing",
      },
      {
        key: "content_marketing",
        value: "Content Marketing",
      },
      {
        key: "marketplaces",
        value: "Marketplaces",
      },
      {
        key: "affiliate_channels",
        value: "Affiliate Channels",
      },
      {
        key: "customer_referrals",
        value: "Customer Referrals",
      },
    ],
  },
  {
    qid: 3,
    question: "How do you currently drive traffic to offers?",
    type: "multiselect-singlecolumn",
    mandatory: true,
    fixedunit: "",
    options: [
      {
        key: "social_platforms",
        value:
          "Paid Traffic from Social Platforms like Facebook, Instagram, TikTok, etc.",
      },
      {
        key: "search_engines",
        value:
          "Paid Traffic from Search Engines / Display like Google, Youtube, etc.",
      },
      {
        key: "native_platforms",
        value:
          "Paid Traffic from Native Advertorials Platforms like Taboola, Outbrain, MGID, etc. ",
      },
      {
        key: "influencer_marketing",
        value: "Influencer Marketing",
      },
      {
        key: "content_marketing",
        value: "Content Marketing",
      },
    ],
  },
  {
    qid: 4,
    question: "How many orders do you process in average in a day?",
    type: "singleselect-dropdown",
    fixedunit: "",
    mandatory: true,
    options: [
      {
        key: "3-10order_day",
        value: "3-10 orders / day",
      },
      { key: "10-30order_day", value: "10-30 orders/day" },
      { key: "30-70order_day", value: "30-70 orders/day" },
      { key: "70-100order_day", value: "70-100 orders/day" },
      { key: "100-250order_day", value: "100-250 orders/day" },
      { key: "250-500order_day", value: "250-500 orders/day" },
      { key: "500plusorder_day", value: "500+ orders/day" },
    ],
  },
  {
    qid: 5,
    question: "How many orders do you process in average in a day?",
    type: "singleselect-dropdown",
    fixedunit: "",
    mandatory: true,
    options: [
      {
        key: "3-10order_day",
        value: "3-10 orders / day",
      },
      { key: "10-30order_day", value: "10-30 orders/day" },
      { key: "30-70order_day", value: "30-70 orders/day" },
      { key: "70-100order_day", value: "70-100 orders/day" },
      { key: "100-250order_day", value: "100-250 orders/day" },
      { key: "250-500order_day", value: "250-500 orders/day" },
      { key: "500-1000order_day", value: "500-1000 orders/day" },
      { key: "1000-2000order_day", value: "1000-2000 orders/day" },
      { key: "2000plusorder_day", value: "2000+ orders/day" },
    ],
  },
  {
    qid: 6,
    question:
      "What's your current average media buying spend in a day overall? Please mention in US Dollars.",
    type: "number",
    placeholder: "Enter Amount",
    fixedunit: "$",
    mandatory: true,
  },
  {
    qid: 7,
    question: "What's your current average order value in US Dollars? ",
    type: "number",
    placeholder: "Enter Amount",
    fixedunit: "$",
    mandatory: true,
  },
  {
    qid: 8,
    question: "What's your current average order value in INR?",
    type: "number",
    placeholder: "Enter Amount",
    fixedunit: "₹",
    mandatory: true,
  },
  {
    qid: 9,
    question:
      "What's your current average delivery rate as compared to total leads / orders you generate?",
    type: "number",
    placeholder: "Enter Number",
    fixedunit: "%",
    mandatory: true,
    max: 100,
  },
  {
    qid: 10,
    question: "Which niche do you generally sell in?",
    type: "multiselect-doublecolumn",
    fixedunit: "",
    mandatory: true,
    options: [
      {
        key: "general",
        value: "General",
      },
      {
        key: "beauty_wellness",
        value: "Beauty, Wellness & Self Care",
      },
      {
        key: "fashion_jewellery",
        value: "Fashion & Jewellery",
      },
      {
        key: "home_kitchen_garden",
        value: "Home, Kitchen & Garden",
      },
      {
        key: "electronics_gadgets_accessories",
        value: "Electronics, Gadgets & Accessories",
      },
      {
        key: "mom_kids_care",
        value: "Mom & Kids Care",
      },
      {
        key: "pets",
        value: "Pets",
      },
      {
        key: "automobiles",
        value: "Automobiles",
      },
      {
        key: "travel_outdoors",
        value: "Travel & Outdoors",
      },
      {
        key: "others",
        value: "Others",
        placeholder: "Press Enter to add multiple niches",
      },
    ],
  },
  {
    qid: 11,
    question: "Which platform do you use to manage your store front & orders?",
    type: "singleselect-doublecolumn",
    fixedunit: "",
    mandatory: true,
    options: [
      {
        key: "shopify",
        value: "Shopify",
      },
      {
        key: "woocommerce",
        value: "WooCommerce",
      },
      {
        key: "wix",
        value: "Wix",
      },
      {
        key: "konnective",
        value: "Konnective",
      },
      {
        key: "clickfunnels",
        value: "ClickFunnels",
      },
      {
        key: "others",
        value: "Others",
        placeholder: "Press Enter to add multiple niches",
      },
    ],
  },
  {
    qid: 12,
    question:
      "Which platform are you comfortable to make & manage your store front & orders from?",
    type: "singleselect-doublecolumn",
    fixedunit: "",
    mandatory: true,
    options: [
      {
        key: "shopify",
        value: "Shopify",
      },
      {
        key: "woocommerce",
        value: "WooCommerce",
      },
      {
        key: "wix",
        value: "Wix",
      },
      {
        key: "konnective",
        value: "Konnective",
      },
      {
        key: "clickfunnels",
        value: "ClickFunnels",
      },
      {
        key: "others",
        value: "Others",
        placeholder: "Press Enter to add multiple niches",
      },
    ],
  },
  {
    qid: 13,
    question: "What is your website / store URL?",
    type: "text",
    placeholder: "Enter website URL",
    fixedunit: "https://",
    mandatory: false,
  },
  {
    qid: 14,
    question: "If you have a website, please share its URL? (Optional)",
    type: "text",
    placeholder: "Enter website URL",
    fixedunit: "https://",
    mandatory: false,
  },
  {
    qid: 15,
    question: "How do you currently drive traffic to offers?",
    type: "multiselect-singlecolumn",
    mandatory: true,
    fixedunit: "",
    options: [
      {
        key: "social_platforms",
        value:
          "Paid Traffic from Social Platforms like Facebook, Instagram, TikTok, etc.",
      },
      {
        key: "search_engines",
        value:
          "Paid Traffic from Search Engines / Display like Google, Youtube, etc.",
      },
      {
        key: "native_platforms",
        value:
          "Paid Traffic from Native Advertorials Platforms like Taboola, Outbrain, MGID, etc. ",
      },
      {
        key: "influencer_marketing",
        value: "Influencer Marketing",
      },
      {
        key: "content_marketing",
        value: "Content Marketing",
      },
      {
        key: "affiliates",
        value: "Affiliates",
      },
    ],
  },
];
var questiomap = {
  dropshipping_prepaid_nonindia: [1, 4, 7, 10, 11, 13],
  dropshipping_cod_nonindia: [1, 4, 9, 7, 10, 11, 13],
  dropshipping_india: [1, 4, 9, 8, 10, 11, 13],
  d2c_prepaid_nonindia: [2, 4, 7, 10, 11, 13],
  d2c_cod_nonindia: [2, 4, 9, 7, 10, 11, 13],
  d2c_india: [2, 5, 9, 8, 10, 11, 13],
  media_buying_prepaid_nonindia: [3, 6, 12, 14],
  media_buying_cod_nonindia: [3, 6, 12, 14],
  media_buying_india: [3, 6, 12, 14],
  agency_prepaid_nonindia: [15, 6, 12, 14],
  agency_cod_nonindia: [15, 6, 12, 14],
  agency_india: [15, 6, 12, 14],
};
var answers = {};
class QuestionScreen2Step3 extends Component {
  state = {
    final_questions: [],
    singleselect: {},
    multiselect: {},
    errorsq: {},
    others: {},
    loading: false,
    commnucation_mode: "",
    skype: !!this.props.user_skype ? this.props.user_skype : "",
    telegram: "",
    fb_messenger: "",
    whatsapp:
      !!this.props.user_contact_ext && !!this.props.user_contact
        ? this.props.user_contact
        : "",
    email: !!this.props.user_email ? this.props.user_email : "",
    phonecall:
      !!this.props.user_contact_ext && !!this.props.user_contact
        ? this.props.user_contact
        : "",
    phonecall_countrycode: "in",

    whatsapp_ext: !!this.props.user_contact_ext
      ? this.props.user_contact_ext
      : "",
    phonecall_ext: !!this.props.user_contact_ext
      ? this.props.user_contact_ext
      : "",
    telegram_ext: !!this.props.user_contact_ext
      ? this.props.user_contact_ext
      : "",
  };

  componentDidMount() {
    var secondscreenanswer = this.props.Step2Selected;
    var fques = [];
    var ss = {};
    var errorsq = {};
    questiomap[secondscreenanswer].forEach(function(x) {
      var arr = step3_questions.filter((y) => {
        return y.qid === x;
      });
      if (arr[0].qid !== undefined) {
        if (
          arr[0].type === "singleselect-doublecolumn" ||
          arr[0].type === "singleselect-singlecolumn"
        ) {
          ss["qid" + arr[0].qid] = "";
        }
        errorsq[arr[0].qid] = 0;
        fques.push(arr[0]);
      }
    });
    this.setState({
      singleselect: { ...ss },
    });
    this.setState({
      errorsq: { ...errorsq },
    });
    this.setState({
      final_questions: [...this.state.final_questions, ...fques],
    });
  }

  componentDidUpdate(pp) {
    if (pp.commnucation_mode !== this.state.commnucation_mode) {
      if (this.state.commnucation_mode === "email") {
        answers["email"] = {
          ["email"]: this.state.email,
        };
      }
      if (this.state.commnucation_mode === "whatsapp") {
        answers["whatsapp"] = {
          ["whatsapp"]: this.state.whatsapp,
        };
        answers["whatsapp_ext"] = {
          ["whatsapp_ext"]: this.state.whatsapp_ext,
        };
      }
      if (this.state.commnucation_mode === "phonecall") {
        answers["phonecall"] = {
          ["phonecall"]: this.state.phonecall,
        };
        answers["phonecall_ext"] = {
          ["phonecall_ext"]: this.state.phonecall_ext,
        };
      }
      if (this.state.commnucation_mode === "telegram") {
        answers["telegram_ext"] = {
          ["telegram_ext"]: this.state.telegram_ext,
        };
      }
    }
  }

  numberValueHandler(dialcode, number) {
    return `${dialcode}` + `${number}`;
  }

  updateField(k, v) {
    answers[k] = {
      [k]: v,
    };
    this.setState(() => ({
      [k]: v,
    }));
  }

  updQAns(key, value, checked = 1, othervalue = 0) {
    var qidkey = "qid" + key;
    var ques = step3_questions.filter((x) => x.qid == key);
    ques = ques[0];
    if (answers[qidkey] === undefined) {
      answers[qidkey] = {
        qid: key,
      };
    }
    if (
      ["multiselect-singlecolumn", "multiselect-doublecolumn"].indexOf(
        ques.type
      ) !== -1
    ) {
      if (answers[qidkey].value === undefined) {
        answers[qidkey].value = [];
      }

      if (checked == 1) {
        if (answers[qidkey].value.indexOf(value) === -1) {
          answers[qidkey].value.push(value);
        }
        if (value == "others") {
          var others = this.state.others;
          others[key] = 1;
          this.setState({ others: { ...others } });
          answers[qidkey].othervalue = othervalue;
        }
      } else {
        var rem = answers[qidkey].value.indexOf(value);
        if (rem > -1) {
          answers[qidkey].value.splice(rem, 1);
        }
        if (value == "others") {
          if (answers[qidkey].othervalue !== undefined) {
            delete answers[qidkey].othervalue;
          }
        }
      }
    } else {
      if (Number(value) > 100 && key === 9) {
        answers[qidkey].value = "100";
        return;
      }
      answers[qidkey].value = value;
      if (qidkey == "qid13" || qidkey == "qid14") {
        answers[qidkey].value = "https://" + value;
      }
      if (value == "others") {
        var others = this.state.others;
        others[key] = 1;
        this.setState({ others: { ...others } });
        answers[qidkey].othervalue = othervalue;
      } else {
        if (answers[qidkey].othervalue !== undefined) {
          delete answers[qidkey].othervalue;
        }
      }
      if (
        ["singleselect-singlecolumn", "singleselect-doublecolumn"].indexOf(
          ques.type
        ) !== -1
      ) {
        var ss = this.state.singleselect;
        ss[qidkey] = value;
        this.setState({ singleselect: { ...ss } });
      }
    }
  }

  saveSignupQuestions() {
    if (this.state.commnucation_mode.length === 0) {
      message.error("Please select preferred mode of communication.");
      return;
    }

    if (
      this.state.commnucation_mode.includes("skype") &&
      this.state.skype.length === 0
    ) {
      message.error("Please enter Skype ID.");
      return;
    }

    if (
      this.state.commnucation_mode.includes("telegram") &&
      this.state.telegram.length === 0
    ) {
      message.error("Please enter Telegram ID.");
      return;
    }

    if (
      this.state.commnucation_mode.includes("fb_messenger") &&
      this.state.fb_messenger.length === 0
    ) {
      message.error("Please enter Facebook ID.");
      return;
    }

    if (
      this.state.commnucation_mode.includes("whatsapp") &&
      this.state.whatsapp.length === 0
    ) {
      message.error("Please enter Whatsapp Number.");
      return;
    }

    if (
      this.state.commnucation_mode.includes("email") &&
      this.state.email.length === 0
    ) {
      message.error("Please enter Email Address.");
      return;
    }
    if (
      this.state.commnucation_mode.includes("phonecall") &&
      this.state.phonecall.length === 0
    ) {
      message.error("Please enter Phone Number.");
      return;
    }

    let emptyqes = [];
    var serror = {};
    this.state.final_questions.forEach((q) => {
      if (q.mandatory === true) {
        if (answers["qid" + q.qid] === undefined) {
          emptyqes.push(q.qid);
          serror[q.qid] = 1;
        }
      }
    });
    console.log(emptyqes);
    console.log(serror);
    if (!!emptyqes.length) {
      this.setState({
        errorsq: { ...serror },
      });
      message.error("All fields are mandatory.");
      return;
    }

    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/user/save_signup_questions",
          method: "post",
          data: {
            questions: {
              screen1: this.props.Step1Selected,
              screen2: this.props.Step2Selected,
              screen3: Object.values(answers),
            },
          },
          withCredentials: true,
        })
          .then((res) => {
            this.setState({
              loading: false,
            });
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              window.location.href = "/switch-store";
              // window.location.href = `${this.props.storeid}/onboarding`;
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
          });
      }
    );
  }

  platefprmNiche = (key, value) => {
    this.setState({
      platformniche: {
        [key]: value,
      },
    });
  };

  otherNiche = (key, value) => {
    this.setState({
      otherNiche: {
        [key]: value,
      },
    });
  };

  telegramChangeHandler(ob) {
    let onlyNumber = ob.value
      .replace(/[^0-9]+/g, "")
      .slice(
        !!Object.entries(ob.extraVal).length ? ob.extraVal.dialCode.length : 0
      );

    let customVal = ob.value.replace(ob.extraVal.dialCode, "");

    answers["telegram"] = {
      ["telegram"]: onlyNumber,
    };
    answers["telegram_ext"] = {
      ["telegram_ext"]: onlyNumber,
    };
    if (customVal.replaceAll(" ", "").length < 10) {
      this.setState((state) => ({
        whatsapp: "",
        phonecall: "",
        skype: "",
        telegram: onlyNumber,
        fb_messenger: "",
        whatsapp_ext: "",
        phonecall_ext: "",
        telegram_ext: ob.extraVal.dialCode,
        phonecall_countrycode: ob.extraVal.dialCode,
      }));
    } else {
      this.setState((state) => ({
        whatsapp: "",
        phonecall: "",
        skype: "",
        telegram: onlyNumber,

        fb_messenger: "",
        whatsapp_ext: "",
        phonecall_ext: "",
        telegram_ext: ob.extraVal.dialCode,
        phonecall_countrycode: ob.extraVal.dialCode,
      }));
    }
  }

  // whats app handler
  whatsappChangeHandler(ob) {
    let onlyNumber = ob.value
      .replace(/[^0-9]+/g, "")
      .slice(
        !!Object.entries(ob.extraVal).length ? ob.extraVal.dialCode.length : 0
      );

    let customVal = ob.value.replace(ob.extraVal.dialCode, "");
    answers["whatsapp"] = {
      ["whatsapp"]: onlyNumber,
    };
    answers["whatsapp_ext"] = {
      ["whatsapp_ext"]: ob.extraVal.dialCode,
    };
    if (customVal.replaceAll(" ", "").length < 10) {
      this.setState((state) => ({
        whatsapp: onlyNumber,
        phonecall: "",
        skype: "",
        telegram: "",
        fb_messenger: "",
        whatsapp_ext: ob.extraVal.dialCode,
        phonecall_ext: "",
        telegram_ext: "",
        phonecall_countrycode: ob.extraVal.dialCode,
      }));
    } else {
      this.setState((state) => ({
        whatsapp: onlyNumber,
        phonecall: "",
        skype: "",
        telegram: "",
        fb_messenger: "",
        whatsapp_ext: ob.extraVal.dialCode,
        phonecall_ext: "",
        telegram_ext: "",
        phonecall_countrycode: ob.extraVal.dialCode,
      }));
    }
  }

  // phone call handler
  phonecallChangeHandler(ob) {
    let onlyNumber = ob.value
      .replace(/[^0-9]+/g, "")
      .slice(
        !!Object.entries(ob.extraVal).length ? ob.extraVal.dialCode.length : 0
      );

    let customVal = ob.value.replace(ob.extraVal.dialCode, "");

    answers["phonecall"] = {
      ["phonecall"]: onlyNumber,
    };
    answers["phonecall_ext"] = {
      ["phonecall_ext"]: ob.extraVal.dialCode,
    };
    if (customVal.replaceAll(" ", "").length < 10) {
      this.setState((state) => ({
        phonecall: onlyNumber,
        whatsapp: "",
        skype: "",
        telegram: "",
        fb_messenger: "",
        whatsapp_ext: "",
        phonecall_ext: ob.extraVal.dialCode,
        telegram_ext: "",
        phonecall_countrycode: ob.extraVal.dialCode,
      }));
    } else {
      this.setState((state) => ({
        phonecall: onlyNumber,
        whatsapp: "",
        skype: "",
        telegram: "",
        fb_messenger: "",
        whatsapp_ext: "",
        phonecall_ext: ob.extraVal.dialCode,
        telegram_ext: "",
        phonecall_countrycode: ob.extraVal.dialCode,
      }));
    }
  }

  render() {
    //url/text/textarea/number/multiselect-singlecolumn/multiselect-doublecolumn/singleselect

    const final_questions = this.state.final_questions;
    return (
      <Card className="questionScreen2">
        <div className="questionScreen2-header-txt">
          <p className="questionScreen2-account-txt">
            Let Us Set Up Your Account
          </p>
          <p className="step1">Step 3 of 3</p>
        </div>
        <Progressbar
          questionScreen_step={this.props.questionScreen2_step2}
          activeStep={this.props.activeStep}
        />

        <div className="step-list3">
          {final_questions.map((step3, i) =>
            step3.type === "multiselect-singlecolumn" ? (
              <div>
                <p
                  className={
                    this.state.errorsq[step3.qid] !== undefined &&
                    this.state.errorsq[step3.qid] === 1
                      ? "header-question-txt error-red"
                      : "header-question-txt"
                  }
                >
                  <span className="step3-number">{i + 1}.</span>
                  {step3.question}
                  {"(You can choose multiple options)"}
                  {step3.mandatory === true ? (
                    <span className="mandatory-validation">*</span>
                  ) : null}
                </p>

                <div className="multiselect-singlecolumn">
                  <ul>
                    {step3.options.map((option1) => (
                      <li className="questionScreenStep3">
                        <Checkbox
                          className="step3-list-checkbox"
                          onChange={(e) =>
                            this.updQAns(
                              step3.qid,
                              option1.key,
                              e.target.checked
                            )
                          }
                        >
                          <span className="option-value"> {option1.value}</span>
                        </Checkbox>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : step3.type === "singleselect-dropdown" ? (
              <div className="dd">
                <p className="header-question-txt">
                  {<span className="step3-number">{i + 1}.</span>}
                  {step3.question}
                  <b style={{ color: "#ED0000" }}>*</b>
                </p>
                <div className={`ant-form-item`}>
                  <Select
                    className="communication-mode"
                    placeholder={step3.question}
                    onChange={(val) => this.updQAns(step3.qid, val)}
                  >
                    {step3.options.map((option1) => (
                      <Option
                        value={option1.key}
                        className="communication-mode-option"
                      >
                        {option1.value}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            ) : step3.type === "singleselect-doublecolumn" ? (
              <div className={`singleselect-doublecolumn qid${step3.qid}`}>
                <p
                  className={
                    this.state.errorsq[step3.qid] !== undefined &&
                    this.state.errorsq[step3.qid] === 1
                      ? "header-question-txt error-red"
                      : "header-question-txt"
                  }
                >
                  {<span className="step3-number">{i + 1}.</span>}
                  {step3.question}
                  {step3.mandatory === true ? (
                    <span className="mandatory-validation">*</span>
                  ) : null}
                </p>
                <div className="singleselect-doublecolumn-data">
                  <ul className="singleselect-doublecolumn-list">
                    {step3.options.map((option1) => (
                      <li
                        className={
                          option1.key === "others"
                            ? "widthquestionScreenStep3 questionScreenStep3"
                            : "questionScreenStep3"
                        }
                      >
                        <Radio
                          className="step3-list-checkbox"
                          checked={
                            this.state.singleselect["qid" + step3.qid] ===
                            option1.key
                              ? 1
                              : 0
                          }
                          onChange={() => this.updQAns(step3.qid, option1.key)}
                        >
                          <span className="option-value-radio-btn">
                            {" "}
                            {option1.value}
                          </span>
                        </Radio>
                      </li>
                    ))}
                    {this.state.others[step3.qid] !== undefined &&
                    this.state.others[step3.qid] === 1 ? (
                      <div className="other-select-niche">
                        <Input
                          className="selectNiche"
                          type="txtarea"
                          placeholder="Press Enter to add multiple plateform front"
                          onChange={(e) =>
                            this.updQAns(step3.qid, "others", 1, e.target.value)
                          }
                        />
                      </div>
                    ) : null}
                  </ul>
                </div>
              </div>
            ) : step3.type === "multiselect-doublecolumn" ? (
              <div className="multiselect-doublecolumn">
                <p
                  className={
                    this.state.errorsq[step3.qid] !== undefined &&
                    this.state.errorsq[step3.qid] === 1
                      ? "header-question-txt error-red"
                      : "header-question-txt"
                  }
                >
                  {<span className="step3-number">{i + 1}.</span>}
                  {step3.question} {"(You can choose multiple options)"}{" "}
                  {step3.mandatory === true ? (
                    <span className="mandatory-validation">*</span>
                  ) : null}
                </p>
                <div className="multiselect-doublecolumn-data">
                  <ul className="multiselect-doublecolumn-list">
                    {step3.options.map((option1) => (
                      <li
                        className={
                          option1.key === "others"
                            ? "questionScreenStep3 widthquestionScreenStep3"
                            : "questionScreenStep3"
                        }
                      >
                        <Checkbox
                          className="step3-list-checkbox"
                          onChange={(e) =>
                            this.updQAns(
                              step3.qid,
                              option1.key,
                              e.target.checked
                            )
                          }
                        >
                          <span className="option-value"> {option1.value}</span>
                        </Checkbox>
                      </li>
                    ))}
                    <div className="other-select-niche">
                      {this.state.others[step3.qid] !== undefined &&
                      this.state.others[step3.qid] === 1 ? (
                        <Input
                          className="selectNiche"
                          type="txt"
                          placeholder="Press Enter to add multiple niches"
                          onChange={(e) =>
                            this.updQAns(step3.qid, "others", 1, e.target.value)
                          }
                        />
                      ) : null}
                    </div>
                  </ul>
                </div>
              </div>
            ) : step3.type === "number" ? (
              <div>
                <p
                  className={
                    this.state.errorsq[step3.qid] !== undefined &&
                    this.state.errorsq[step3.qid] === 1
                      ? "header-question-txt error-red"
                      : "header-question-txt"
                  }
                >
                  {<span className="step3-number">{i + 1}.</span>}
                  {step3.question}
                  {step3.mandatory === true ? (
                    <span className="mandatory-validation">*</span>
                  ) : null}
                </p>
                <div className="input-type-number">
                  <Input
                    addonBefore={step3.fixedunit}
                    className="question-number-input gh-9"
                    type="number"
                    max={step3.max ? step3.max : null}
                    placeholder={step3.placeholder}
                    onChange={(e) => this.updQAns(step3.qid, e.target.value)}
                    onBlur={() => {
                      if (step3.qid === 9) {
                        if (
                          Number(document.querySelector(".gh-9 input").value) >
                          100
                        ) {
                          document.querySelector(".gh-9 input").value = "100";
                        }
                      }
                    }}
                  />
                </div>
              </div>
            ) : step3.type === "text" ? (
              <div>
                <p
                  className={
                    this.state.errorsq[step3.qid] !== undefined &&
                    this.state.errorsq[step3.qid] === 1
                      ? "header-question-txt error-red"
                      : "header-question-txt"
                  }
                >
                  {" "}
                  {<span className="step3-number">{i + 1}.</span>}
                  {step3.question}
                  {step3.mandatory === true ? (
                    <span className="mandatory-validation">*</span>
                  ) : null}
                </p>
                <div className="input-type-text">
                  <Input
                    addonBefore={step3.fixedunit}
                    className="question-text-input"
                    type="text"
                    placeholder={step3.placeholder}
                    onChange={(e) => this.updQAns(step3.qid, e.target.value)}
                  />
                </div>
              </div>
            ) : null
          )}

          <div className="dd">
            <p className="header-question-txt">
              {
                <span className="step3-number">
                  {this.state.final_questions &&
                    this.state.final_questions.length + 1}
                  .
                </span>
              }
              What is the preferred mode of communication for you?{" "}
              <b style={{ color: "#ED0000" }}>*</b>
            </p>

            <div className={`ant-form-item`}>
              <Select
                className="communication-mode"
                placeholder="Please select preferred mode of communication."
                onChange={(val) => this.updateField("commnucation_mode", val)}
              >
                <Option value="phonecall" className="communication-mode-option">
                  Phonecall
                </Option>
                <Option value="email" className="communication-mode-option">
                  Email
                </Option>
                <Option value="skype" className="communication-mode-option">
                  Skype
                </Option>
                <Option value="whatsapp" className="communication-mode-option">
                  Whatsapp
                </Option>
                <Option value="telegram" className="communication-mode-option">
                  Telegram
                </Option>
                <Option
                  value="fb_messenger"
                  className="communication-mode-option"
                >
                  FB Messenger
                </Option>
              </Select>
            </div>
          </div>
          {this.state.commnucation_mode === "skype" && (
            <div className="dd-communication-mode">
              <p className="header-question-txt ">
                <span className="step3-number">
                  {this.state.final_questions &&
                    this.state.final_questions.length + 2}
                  .
                </span>
                Your Skype ID <b style={{ color: "#ED0000" }}>*</b>
              </p>
              <span
                style={{
                  display: "flex",
                }}
              >
                <input
                  type="text"
                  onChange={(e) => this.updateField("skype", e.target.value)}
                  className="input-communication-mode"
                />
              </span>
            </div>
          )}

          {this.state.commnucation_mode === "email" && (
            <div className="dd-communication-mode">
              <p className="header-question-txt ">
                <span className="step3-number">
                  {this.state.final_questions &&
                    this.state.final_questions.length + 2}
                  .
                </span>
                Your Email Address <b style={{ color: "#ED0000" }}>*</b>
              </p>
              <span
                style={{
                  display: "flex",
                }}
              >
                <input
                  className="input-communication-mode"
                  type="text"
                  value={this.state.email}
                  onChange={(e) => this.updateField("email", e.target.value)}
                />
              </span>
            </div>
          )}

          {this.state.commnucation_mode === "fb_messenger" && (
            <div className="dd-communication-mode">
              <p className="header-question-txt ">
                <span className="step3-number">
                  {this.state.final_questions &&
                    this.state.final_questions.length + 2}
                  .
                </span>
                Your Facebook Address<b style={{ color: "#ED0000" }}>*</b>
              </p>
              <span
                style={{
                  display: "flex",
                }}
              >
                <input
                  type="text"
                  onChange={(e) =>
                    this.updateField("fb_messenger", e.target.value)
                  }
                  className="input-communication-mode"
                />
              </span>
            </div>
          )}

          {this.state.commnucation_mode === "telegram" && (
            <div className="dd-communication-mode">
              <p className="header-question-txt ">
                <span className="step3-number">
                  {this.state.final_questions &&
                    this.state.final_questions.length + 2}
                  .
                </span>
                Your Telegram Address<b style={{ color: "#ED0000" }}>*</b>
              </p>
              <span
                style={{
                  display: "flex",
                }}
              >
                <ReactPhoneInput
                  country={this.state.phonecall_countrycode}
                  placeholder="Enter your telegram number"
                  enableSearchField
                  // value={this.state.telegram}
                  // onChange={(val, country) =>
                  //   this.telegramChangeHandler(val, country)
                  // }

                  value={this.numberValueHandler(
                    this.state.telegram_ext,
                    this.state.telegram
                  )}
                  onChange={(val, extra) =>
                    this.telegramChangeHandler({
                      value: val,
                      extraVal: extra,
                    })
                  }
                />
              </span>
            </div>
          )}

          {this.state.commnucation_mode === "whatsapp" && (
            <div className="dd-communication-mode">
              <p className="header-question-txt ">
                <span className="step3-number">
                  {this.state.final_questions &&
                    this.state.final_questions.length + 2}
                  .
                </span>
                Your Whatsapp Number <b style={{ color: "#ED0000" }}>*</b>
              </p>

              <ReactPhoneInput
                country={this.state.phonecall_countrycode}
                placeholder="Enter your whatsapp number"
                enableSearchField
                // value={this.state.whatsapp}
                // onChange={(val, country) =>
                //   this.whatsappChangeHandler(val, country)
                // }

                value={this.numberValueHandler(
                  this.state.whatsapp_ext,
                  this.state.whatsapp
                )}
                onChange={(val, extra) =>
                  this.whatsappChangeHandler({
                    value: val,
                    extraVal: extra,
                  })
                }
              />
            </div>
          )}

          {this.state.commnucation_mode === "phonecall" && (
            <div className="dd-communication-mode">
              <p className="header-question-txt">
                <span className="step3-number">
                  {this.state.final_questions &&
                    this.state.final_questions.length + 2}
                  .
                </span>
                Your Phone Number <b style={{ color: "#ED0000" }}>*</b>
              </p>

              <ReactPhoneInput
                country={this.state.phonecall_countrycode}
                placeholder="Enter your phone number"
                // value={
                //   `${this.state.phonecall_ext}` + `${this.state.phonecall}`
                // }

                value={this.numberValueHandler(
                  this.state.phonecall_ext,
                  this.state.phonecall
                )}
                enableSearchField
                onChange={(val, extra) =>
                  this.phonecallChangeHandler({
                    value: val,
                    extraVal: extra,
                  })
                }

                // onChange={(val, c) => this.phonecallChangeHandler(val, c)}
              />
            </div>
          )}
        </div>
        <Button
          className="continue-btn2"
          onClick={() => this.saveSignupQuestions()}
          loading={this.state.loading}
        >
          Continue
        </Button>
      </Card>
    );
  }
}

export default QuestionScreen2Step3;
