import React from "react";
import { Row, Col, Icon, Button } from "antd";
import "./ImportListProductBasic.scss";
// import { Brush } from "recharts";

class EscalatedMessage extends React.Component {
  render() {
    return (
      <div>
        {" "}
        {this.props.messagedata === "escalated" ? (
          <Row
            style={{
              width: "100%",
              background: "#F5FFF4",
              border: "1px solid #2BC822",
              padding: "10px",
              borderRadius: "3px",
              marginTop: "16px",
            }}
          >
            <Col md={1}>
              <span
                style={{
                  width: "24px",
                  height: "24px",
                  fontSize: "24px",
                  lineHeight: "24px",
                  display: "inline-block",
                  color: "#2BC822",
                }}
              >
                <Icon type="exclamation-circle" theme="filled" />
              </span>
            </Col>
            <Col md={23}>
              <p
                style={{
                  fontSize: "12px",
                  color: "#000000D9",
                }}
              >
                Your request to get this product cataloged has been submitted.
                It takes us between 2-3 working days on average to completely
                catalog a product. You will be sent a notification on your
                vFulfill dashboard once it has been done!
              </p>
            </Col>
          </Row>
        ) : (
          this.props.messageStatus === "doescalate" && (
            <Row
              style={{
                width: "100%",
                background:
                  this.props.messagedata === "escalated"
                    ? "#F5FFF4"
                    : "#F5FAFF",
                border:
                  this.props.messagedata === "escalated"
                    ? "2px solid #2BC822"
                    : " 2px solid #0068FF",
                padding: "10px",
                borderRadius: "3px",
                marginTop: "16px",
              }}
            >
              <Col md={1}>
                <span
                  style={{
                    width: "24px",
                    height: "24px",
                    fontSize: "24px",
                    lineHeight: "24px",
                    display: "inline-block",
                    color:
                      this.props.messagedata === "escalated"
                        ? "#2BC822"
                        : "#0068FF",
                  }}
                >
                  <Icon type="exclamation-circle" theme="filled" />
                </span>
              </Col>
              <Col md={23} style={{ textAlign: "center" }}>
                <p>
                  {" "}
                  <span style={{ fontWeight: "600" }}> Next Steps? </span> Get
                  this product Cataloged by vFulfill team to see more details
                  around this product, including its product quotation. This is
                  a mandatory step before you start selling this product with
                  vFulfill.
                </p>
                <Button
                  className="rejected"
                  style={{
                    backgroundColor: "#0068FF",
                    color: "#fff",
                    textAlign: "center",
                    display: "inline-block",
                    // padding: "8px 12px",
                    borderRadius: "2px",
                    marginTop: "12px",
                    cursor: "pointer",
                    fontSize: "12px",
                  }}
                  onClick={() => this.props.escalated()}
                  loading={this.props.loading}
                  disabled={this.props.loading}
                >
                  Request to Catalog & Quote
                </Button>
              </Col>
            </Row>
          )
        )}
      </div>
    );
  }
}

export default EscalatedMessage;
