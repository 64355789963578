import React from "react";
import { formatBalance, getCurrencySymbol } from "../../../../Helpers/Pricing";

import { Row, Skeleton, Tooltip } from "antd";
import "./index.scss";
import NotFoundCard from "../../Common/NotFoundCard";

const DeductedFromWallet = (props) => {
  const { data, startDate, endDate, loading, currency } = props;

  function amountHandler(amount) {
    if (!!amount) {
      return (
        <div className="delivered-order-head debit">
          {getCurrencySymbol(currency)} {formatBalance(amount)}
        </div>
      );
    } else {
      return <div className="delivered-order-head ">0</div>;
    }
  }

  function totalAmountHandler(data) {
    let totalAmountData = Object.entries(data);
    let totalAmount = 0;

    totalAmountData.forEach((d) => {
      if (d[1] > 0) {
        totalAmount += d[1];
      } else {
        totalAmount += 0;
      }
    });

    if (!!totalAmount) {
      return (
        <div className="delivered-order-head debit">
          {getCurrencySymbol(currency)}
          {formatBalance(totalAmount)}
        </div>
      );
    } else {
      return <div className="delivered-order-head ">0</div>;
    }
  }

  if (loading) {
    return (
      <Row style={{ marginBottom: "30px", marginTop: "60px" }}>
        <Skeleton active />
      </Row>
    );
  }

  if (Object.entries(data).length === 0) {
    return (
      <div id="fade-in" className="deductedFromWallet-summary">
        <div className="topPerformingProductHead">
          {" "}
          <span className="name">Money Deducted From Wallet</span> |{" "}
          <span>All time</span>
        </div>

        <NotFoundCard mainHeading={"Oops! No Data Found."} />
      </div>
    );
  }
  return (
    <div id="fade-in" className="deductedFromWallet">
      <div className="topPerformingProductHead">
        {" "}
        <span className="name">Money Deducted From Wallet</span> |{" "}
        <span>All time</span>
      </div>
      <div className="product-heads">
        <div className="name-head">VF Order Charges</div>

        <div className="delivered-order-head">Amount</div>
      </div>

      <div className="product-row-wraper">
        <div className="product-row">
          <div className="name-head">Product Price</div>
          {amountHandler(data.pp_cost)}
        </div>

        <div className="product-row">
          <div className="name-head">Sourcing Request</div>
          {amountHandler(data.client_sourcing_request)}
        </div>

        <div className="product-row">
          <div className="name-head">Fulfillment Fees</div>
          {amountHandler(data.ff_cost)}
        </div>

        <div className="product-row">
          <div className="name-head">Forward Shipping</div>
          {amountHandler(data.fs_cost)}
        </div>

        <div className="product-row">
          <div className="name-head">COD Charges</div>
          {amountHandler(data.codf_cost)}
        </div>

        <div className="product-row">
          <div className="name-head">RTO Handling Fee</div>
          {amountHandler(data.rto_handling_cost)}
        </div>

        <div className="product-row">
          <div className="name-head">RTO Shipping</div>
          {amountHandler(data.rto_shipping_cost)}
        </div>

        <div className="product-row">
          <div className="name-head">Order GST</div>
          {amountHandler(data.orderamount_gst_deductions)}
        </div>

        <div className="product-row">
          <div className="name-head">Management Fee</div>
          {amountHandler(data.management_fee)}
        </div>

        <div className="product-row">
          <div className="name-head">Gateway Charges</div>
          {amountHandler(data.payment_gatway_charges)}
        </div>
        <div className="product-row">
          <div className="name-head">Inventory RTV</div>
          {amountHandler(data.inventory_rtv)}
        </div>

        <div className="product-row">
          <div className="name-head">Verified Order Cancellation</div>
          {amountHandler(data.verified_order_cancellation)}
        </div>
        <div className="product-row">
          <div className="name-head">Order Reverse Pickup</div>
          {amountHandler(data.order_reverse_pickup)}
        </div>

        <div className="product-row">
          <div className="name-head">Manual Settlement</div>
          {amountHandler(data.manual_transactions)}
        </div>

        {!!data.vf_cost ? (
          <div className="product-row">
            <div className="name-head">VF Cost</div>
            {amountHandler(data.vf_cost)}
          </div>
        ) : null}

        {!!data.rto_remit_debit ? (
          <div className="product-row">
            <div className="name-head">RTO Remit</div>
            {amountHandler(data.rto_remit_debit)}
          </div>
        ) : null}

        {!!data.platform_fee ? (
          <div className="product-row">
            <div className="name-head">Convenience Fee</div>
            {amountHandler(data.platform_fee)}
          </div>
        ) : null}

        {!!data.platform_fee_reverse ? (
          <div className="product-row">
            <div className="name-head">Convenience Fee Reverse</div>
            {amountHandler(data.platform_fee_reverse)}
          </div>
        ) : null}

        {!!data.inward_fees ? (
          <div className="product-row">
            <div className="name-head">Inward Fee</div>
            {amountHandler(data.inward_fees)}
          </div>
        ) : null}
        {!!data.storage_charges ? (
          <div className="product-row">
            <div className="name-head">Storage Charges</div>
            {amountHandler(data.storage_charges)}
          </div>
        ) : null}
        {!!data.order_confirmation_management_fee ? (
          <div className="product-row">
            <div className="name-head">Order Management Fee</div>
            {amountHandler(data.order_confirmation_management_fee)}
          </div>
        ) : null}
        {!!data.wingmen_charges ? (
          <div className="product-row">
            <div className="name-head">Wingmen Charges</div>
            {amountHandler(data.wingmen_charges)}
          </div>
        ) : null}

        <div className="product-row">
          <div className="name-head total">Total</div>
          {amountHandler(data.total)}
        </div>
      </div>
    </div>
  );
};

export default DeductedFromWallet;
