import React, { Component } from 'react';
import LoginPageContainer from '..//LoginPageContainer';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { signUp } from '../../../store/actions/authActions';
import { Form, Icon, Input, Button, Alert, Tabs } from 'antd';
const TabPane = Tabs.TabPane;

class RegisterForm extends Component {
  state = {
    first_name: '',
    last_name: '',
    email: '',
    password: ''
  }

  componentDidMount() {
    document.title = this.props.title;
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.signUp({ first_name: values.first_name, last_name: values.last_name, email: values.email, password: values.password });
      }
    });
  }

  render() {
    const { signupError } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <LoginPageContainer>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Open Your Free vFulfill Account" key="1">
            <Form onSubmit={this.handleSubmit} className="login-form" noValidate>
              <p className="bold register-tag-line">Enter Your Details</p>
              <Form.Item>
                {getFieldDecorator('first_name', {
                  rules: [{ required: true, type: "string", message: 'Please enter your first name!' }],
                })(
                  <Input size="large" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} type="string" placeholder="Your First Name" />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('last_name', {
                  rules: [{ required: false, type: "string", message: 'Please enter your last name!' }],
                })(
                  <Input size="large" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} type="string" placeholder="Your Last Name" />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('email', {
                  rules: [{ required: true, type: "email", message: 'Please enter a valid Email Id!' }],
                })(
                  <Input size="large" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} type="email" placeholder="Your Email Id" />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'Please enter your Password!' }],
                })(
                  <Input size="large" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Your Password" />
                )}
              </Form.Item>
              <Form.Item>
                {signupError && <Alert message={signupError} type="error" />}
                <Button size="large" type="primary" htmlType="submit" className="login-form-button">Let’s Get Ahead</Button>
                Already have an account? <Link to="/login" className="login-form-signup-here">Log In Here</Link>
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </LoginPageContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    signupError: state.auth.signupError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (creds) => dispatch(signUp(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(RegisterForm));
