import React from "react";
import { Modal, Radio, message, Button, Icon } from "antd";
import axios from "axios";
import {
  SHOPIFY_CLIENT_ID,
  BASE_URL,
  SHOPIFY_NAME,
} from "../../../../constants";
import "./index.scss";
import StoreTypeContant from "./StoreTypeContant";
import LocationTypeContant from "./LocationTypeContant";
import ShopifyFormContant from "./ShopifyFormContant";
import WooStoreContent from "./WooStoreContant";

class AddStoreModal extends React.Component {
  state = {
    visbleFrom: "storeTYPE",
    form2Visble: false,
    selectedStoreType: "",
    selectedGeo: "",
    loading: false,
  };

  handleSubmit = (shop) => {
    this.setState({ loading: true }, () => {
      axios({
        url: process.env.REACT_APP_API_URL + "/store/add_store",
        method: "post",
        data: {
          shop,
          storeid: this.props.storeid,
          geo: this.state.selectedGeo,
          timezone: new Date().getTimezoneOffset(),
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            window.location =
              `https://${shop}.myshopify.com/admin/oauth/authorize?client_id=${SHOPIFY_CLIENT_ID}&scope=read_product_listings,read_products,write_products,read_orders,write_orders,read_inventory,write_customers,write_inventory,write_price_rules,read_draft_orders,write_draft_orders,read_customers,write_customers,read_checkouts,write_checkouts,read_fulfillments,read_merchant_managed_fulfillment_orders,write_merchant_managed_fulfillment_orders,read_assigned_fulfillment_orders,write_assigned_fulfillment_orders,read_third_party_fulfillment_orders,write_third_party_fulfillment_orders,write_fulfillments&redirect_uri=` +
              BASE_URL +
              `login/shopify_callback&state=${res.data.id}`;
          } else {
            message.error(res.data.msg);
          }
        })
        .catch((e) => {
          message.error(e.message);
        })
        .finally(this.setState({ loading: false }));
    });
  };
  handleWooCommerceSubmit = (details) => {
    let data = { ...details, geo: this.state.selectedGeo };
    function validURL(str) {
      let pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    }
    if (!validURL(data.shop_url)) {
      message.error("Please provide a valid URL");
      return;
    }
    if (this.props.storeid) {
      data.storeid = this.props.storeid;
    }
    this.setState({ loading: true }, () => {
      axios({
        url: process.env.REACT_APP_API_URL + "/store/add_woocommerce_store",
        method: "post",
        data: {
          // ...details,
          // geo: this.state.selectedGeo,
          ...data,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.setState({
              loading: false,
              // key_id: res.data.id, //ask about that
            });
            // this.props.toggleWoCommerceModal2();
            this.props.handleCancel();
            this.props.history.push(`/switch-store/${res.data.id}`);
            // this.emptywoCommerceDetail();
            message.success("Store successfully connected");
          } else {
            this.setState({
              loading: false,
            });
            message.error(res.data.msg);
          }
        })
        .catch((e) => {
          this.setState({
            loading: false,
          });
          message.error(e.message);
        });
    });
  };

  handleStoreTypeChange = (e) => {
    this.setState({
      selectedStoreType: e.target.value,
      visbleFrom: "globalorIn",
    });
  };
  handleLocationTypeChange = (e) => {
    console.log(e.target.value);
    if (this.state.selectedStoreType === "shopify_store") {
      this.setState({
        selectedGeo: e.target.value,
        visbleFrom: "shopifyStore",
      });
    }
    if (this.state.selectedStoreType === "WoCommerce") {
      this.setState({
        selectedGeo: e.target.value,
        visbleFrom: "wooStore",
      });
    }
  };
  handleGoBacktoStoreTypeClick = () => {
    this.setState({
      visbleFrom: "storeTYPE",
      selectedStoreType: "",
    });
  };
  handleGoBackToLoctionType = () => {
    this.setState({
      visbleFrom: "globalorIn",
      selectedGeo: "",
    });
  };
  componentDidUpdate(pp) {}

  render() {
    const props = this.props;
    const { visible, handleCancel } = props;
    console.log("hj", this.props.history);
    // if(this.state.loading){
    //   return
    // }
    return (
      <Modal
        className="switch-store-modal-first"
        width={600}
        // title={<p className="title_txt">{`Add New ${SHOPIFY_NAME}`}</p>}
        title={null}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
        closable={false}
      >
        <>
          {this.state.visbleFrom === "storeTYPE" ? (
            <StoreTypeContant
              handleCancel={this.props.handleCancel}
              handleStoreTypeChange={(e) => this.handleStoreTypeChange(e)}
              selectedStoreType={this.state.selectedStoreType}
            />
          ) : null}
          {this.state.visbleFrom === "globalorIn" ? (
            <LocationTypeContant
              handleCancel={this.props.handleCancel}
              selectedGeo={this.state.selectedGeo}
              handleLocationTypeChange={(e) => this.handleLocationTypeChange(e)}
              handleGoBacktoStoreTypeClick={() =>
                this.handleGoBacktoStoreTypeClick()
              }
            />
          ) : null}
          {this.state.visbleFrom === "shopifyStore" ? (
            <ShopifyFormContant
              handleSubmit={(shop) => this.handleSubmit(shop)}
              handleGoBackToLoctionType={() => this.handleGoBackToLoctionType()}
              handleCancel={this.props.handleCancel}
              loading={this.state.loading}
            />
          ) : null}
          {this.state.visbleFrom === "wooStore" ? (
            <WooStoreContent
              handleWooCommerceSubmit={(details) =>
                this.handleWooCommerceSubmit(details)
              }
              handleGoBackToLoctionType={() => this.handleGoBackToLoctionType()}
              handleCancel={this.props.handleCancel}
              loading={this.state.loading}
            />
          ) : null}
        </>
      </Modal>
    );
  }
}

export default AddStoreModal;
