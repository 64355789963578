const initState = {
  authError: null,
  auth: {
    logged_in: localStorage.getItem("logged_in")
      ? localStorage.getItem("logged_in")
      : false,
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null,
    store: localStorage.getItem("store")
      ? JSON.parse(localStorage.getItem("store"))
      : null,
    plan: localStorage.getItem("plan")
      ? JSON.parse(localStorage.getItem("plan"))
      : null,
    redirect: "",
  },
  count: {},
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_ERROR":
      return {
        ...state,
        authError: action.err,
      };

    case "LOGIN_SUCCESS":
      localStorage.setItem("logged_in", true);
      localStorage.setItem("user", JSON.stringify(action.payload.data.user));
      localStorage.setItem("store", JSON.stringify(action.payload.data.store));
      localStorage.setItem("plan", JSON.stringify(action.payload.data.plan));
      return {
        ...state,
        auth: {
          logged_in: true,
          user: action.payload.data.user,
          store: action.payload.data.store,
          plan: action.payload.data.plan,
        },
        authError: null,
      };

    case "STORE_SWITCH_SUCCESSFUL":
      localStorage.setItem("logged_in", true);
      localStorage.setItem("user", JSON.stringify(action.payload.data.user));
      localStorage.setItem("store", JSON.stringify(action.payload.data.store));
      localStorage.setItem("plan", JSON.stringify(action.payload.data.plan));
      return {
        ...state,
        auth: {
          logged_in: true,
          user: action.payload.data.user,
          store: action.payload.data.store,
          plan: action.payload.data.plan,
          redirect: action.payload.data.redirect_to,
        },
        authError: null,
      };

    case "SIGNUP_ERROR":
      return {
        ...state,
        signupError: action.err,
      };
    case "REMOVE_REDIRECT":
      return {
        ...state,
        redirect: "",
      };

    case "SIGNUP_SUCCESS":
      localStorage.setItem("logged_in", true);
      localStorage.setItem("user", JSON.stringify(action.payload.data.user));
      localStorage.setItem("store", JSON.stringify(action.payload.data.store));
      localStorage.setItem("plan", JSON.stringify(action.payload.data.plan));
      return {
        ...state,
        auth: {
          logged_in: true,
          user: action.payload.data.user,
          store: action.payload.data.store,
          plan: action.payload.data.plan,
        },
        authError: null,
      };

    case "SIGNOUT_SUCCESS":
      localStorage.clear();

      return window.location.assign(process.env.REACT_APP_NEW_APP + `/login`);
    // return {
    //   ...state,
    //   auth: {
    //     logged_in: false,
    //     domid: "",
    //   },
    //   authError: action.payload.msg ? action.payload.msg : null,
    // };

    case "STORE_STATUS_UPDATED":
      const user = JSON.parse(localStorage.getItem("user"));
      user.user_stores[action.payload.storeid]["store_active"] =
        action.payload.status;
      return {
        ...state,
        auth: {
          ...state.auth,
          user: {
            ...state.auth.user,
            user_stores: {
              ...state.auth.user.user_stores,
              [action.payload.storeid]: {
                ...state.auth.user.user_stores[action.payload.storeid],
                store_active: action.payload.status,
              },
            },
          },
        },
      };

    case "STORE_COUNT_SUCCESSFUL":
      return {
        ...state,
        count: action.payload.data,
      };

    default:
      return state;
  }
};

export default authReducer;
