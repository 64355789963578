import React, { Component } from "react";
import axios from "axios";
import qs from "qs";
import ProductCard from "./ProductCard";
import PackagingCard from "./PackagingCard/index.js";
import CategoriesBar from "../CategoriesBar";
import "./index.scss";
import {
  Dropdown,
  Icon,
  Menu,
  message,
  Pagination,
  Skeleton,
  Divider,
} from "antd";
import Filters from "./Filters";
import ImportProductModal from "../../Products/ImportList/ImportProductModal";
import ActionNotification from "../../Common/ActionNotification";
import ErrorCard from "../../Common/ErrorCard";
import NotFoundCard from "../../Common/NotFoundCard";
import VideoTooltip from "../../Common/VideoTooltip";
import { ReactComponent as Arrow } from "../../../../assets/images/icons/arrow.svg";

const defaultState = {
  importProductModalVisible: false,
  products: [],
  labelproducts: [],
  total: 0,
  loading: true,
  error: false,
  errorMsg: "",
  errorCode: "",
  niches: [],
  categories: [],
  arrow: false,
  mapping: {},
  listing: "",
  filters: {
    search: "",
    category: "",
    media: 0,
    buyback: 0,
    watchlist: 0,
    // adv_data: 0,
    ad_copy_available: 0,
    inventory_available: 0,
    potd: 0,
    india_dropshipping: 0,
    global_dropshipping: 0,
    bestseller: 0,
    trending: 0,
    express_shipping: 0,
    niche: [],
    newproduct: 0,
    inner_circle_product: 0,
    evergreen: 0,
    all: 1,
    packaging: 0,
    selectedlabel: "",
  },

  sort: "date-desc",
  paging: {
    page: 1,
    per_page_count: 20,
  },

  step: 3,
  value: [0, 0],
};

class CatalogListing extends Component {
  state = {
    ...defaultState,
  };

  onRangeFilterChange(value) {
    if (value > 60) {
      return;
    } else {
      this.setState({
        value: value,
      });
    }
  }

  firstInputeOnchnage(e) {
    if (e >= 52) {
      return;
    }
    let arr = [Number(e), this.state.value[1]];
    this.onRangeFilterChange(arr);
  }

  secondInputeOnchnage(e) {
    if (e >= 52) {
      return;
    }
    let arr = [this.state.value[0], Number(e)];
    this.onRangeFilterChange(arr);
  }

  goToPage(page) {
    this.setState(
      {
        paging: { ...this.state.paging, page },
      },
      () => {
        this.loadData();
      }
    );
  }

  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        paging: { ...this.state.paging, per_page_count: pageSize },
      },
      () => this.loadData()
    );
  }
  loadNormalproducts() {
    this.setState({ loading: true, products: [] }, () => {
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.cancelToken = axios.CancelToken.source();
      axios({
        url: process.env.REACT_APP_API_URL + "/products/search",
        method: "post",
        cancelToken: this.cancelToken.token,
        data: {
          storeid: this.props.match.params.storeid,
          page: this.state.paging.page,
          per_page_count: this.state.paging.per_page_count,
          filters: {
            ...this.state.filters,
            restocking_day1:
              this.state.filters.packaging === 1 ? "" : this.state.value[0],
            restocking_day2:
              this.state.filters.packaging === 1 ? "" : this.state.value[1],
          },
          sort: this.state.sort,
          listing: this.state.listing,
        },
        withCredentials: true,
      })
        .then((res) => {
          this.setState({ loading: false });
          return res;
        })
        .then((res) => {
          if (res.data.success === 1) {
            const upd = {
              products: res.data.products,
              total: res.data.total,
              loading: false,
              niches: res.data.niches,
              categories: res.data.categories,
              mapping: res.data.mapping,
              requestCount:
                !!res.data.products.length &&
                res.data.products[0].request_latest_price
                  ? res.data.products[0].request_latest_price.count
                  : null,
            };
            this.setState(upd);
          } else {
            this.setState({
              error: true,
              errorMsg: res.data.msg,
              loading: false,
            });
          }
        })
        .catch((e) => {
          if (!!e.request) {
            this.setState({
              error: true,
              errorCode: e.request.status,
              errorMsg: e.request.statusText,
              loading: false,
            });
          } else {
            this.setState({
              error: false,
              errorMsg: e.message,
              loading: true,
            });
          }
        });
    });
  }
  loadLabelsections() {
    this.setState({ loading: true, products: [] }, () => {
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.cancelToken = axios.CancelToken.source();
      axios({
        url:
          process.env.REACT_APP_API_URL +
          "/products/get_label_related_products",
        method: "post",
        cancelToken: this.cancelToken.token,
        data: {
          storeid: this.props.match.params.storeid,
          page: this.state.paging.page,
          per_page_count: this.state.paging.per_page_count,
          label_id: this.state.filters.selectedlabel,
          // filters: {
          //   ...this.state.filters,
          //   restocking_day1:
          //     this.state.filters.packaging === 1 ? "" : this.state.value[0],
          //   restocking_day2:
          //     this.state.filters.packaging === 1 ? "" : this.state.value[1],
          // },
          // sort: this.state.sort,
          listing: this.state.lifsting,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            const upd = {
              loading: false,
              labelproducts: res.data.products,
              niches: res.data.niches,
              categories: res.data.categories,
              mapping: res.data.mapping,
              total: res.data.products[0].total_products,
            };
            this.setState(upd);
          } else {
            this.setState({
              error: true,
              errorMsg: res.data.msg,
              loading: false,
            });
          }
        })
        .catch((e) => {
          if (!!e.request) {
            this.setState({
              error: true,
              errorCode: e.request.status,
              errorMsg: e.request.statusText,
              loading: false,
            });
          } else {
            this.setState({
              error: false,
              errorMsg: e.message,
              loading: true,
            });
          }
        });
    });
  }

  loadData() {
    if (this.state.filters.all === 1) {
      this.loadLabelsections();
    } else {
      this.loadNormalproducts();
    }
  }
  changeLabel(newlabelid) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          all: 1,
          selectedlabel: newlabelid,
          paging: {
            page: 1,
            per_page_count: 20,
          },
        },
      },
      () => this.loadData()
    );
  }
  componentDidMount() {
    const params = qs.parse(this.props.location.search.replace(/\?/g, ""));

    if (parseInt(params.listing) === 0) {
      this.setState({ listing: 0 });
    } else {
      this.setState({ listing: 1 });
    }

    const upd = { ...defaultState.filters };
    let sort = { ...this.defaultState };
    sort = "date-desc";

    if (!!params.packaging) {
      upd.all = 0;
      sort = "date-desc";
      upd.packaging = 1;
      upd.newproduct = 0;
      if (params.packaging.includes("search")) {
        let search = params.packaging.split("=")[1];
        upd.search = search;
      }
    }

    if (params.search) {
      upd.search = params.search;
    }
    if (params.category) {
      upd.category = params.category;
    }
    if (params.filter) {
      upd[params.filter] = 1;
    }
    if (Object.keys(upd).length > 0) {
      this.setState(
        {
          sort,
          filters: {
            ...this.state.filters,

            ...upd,
            all: 2,
          },
        },
        () => this.loadData()
      );
    } else {
      this.loadData();
    }
  }

  componentDidUpdate(pp) {
    if (
      pp.location.search !== this.props.location.search ||
      pp.location.category !== this.props.location.category
    ) {
      const params = qs.parse(this.props.location.search.replace(/\?/g, ""));

      if (parseInt(params.listing) === 0) {
        this.setState({ listing: 0 });
      } else {
        this.setState({ listing: 1 });
      }

      const upd = { ...defaultState.filters };
      let sort = { ...this.defaultState };
      sort = "date-desc";
      if (!!params.packaging) {
        sort = "date-desc";
        if (params.packaging.includes("search")) {
          let search = params.packaging.split("=")[1];
          upd.search = search;
        }

        upd.all = 0;
        upd.newproduct = 0;
        upd.packaging = 1;
      }

      if (!!params.search) {
        upd.search = params.search;
        upd.all = 1;
        upd.newproduct = 0;
        upd.packaging = 0;
      }
      if (params.category) {
        upd.category = params.category;
        upd.all = 1;
        upd.newproduct = 0;
        upd.packaging = 0;
      }
      if (params.filter) {
        upd[params.filter] = 1;
        upd.all = 1;
        upd.newproduct = 0;
        upd.packaging = 0;
      }
      if (Object.keys(upd).length > 0) {
        this.setState(
          {
            sort,
            filters: {
              ...this.state.filters,

              ...upd,
              all: 2,
            },
          },
          () => this.loadData()
        );
      } else {
        this.loadData();
      }
    }
  }

  onFilterChange(key, value) {
    if (key === "newproduct") {
      window.history.pushState(
        {},
        null,
        `/${this.props.match.params.storegeo}/${this.props.match.params.storeid}/products/search-products`
      );
      this.setState(
        {
          ...defaultState,
          filters: {
            ...defaultState.filters,
            newproduct: 1,
            all: 0,
          },
        },
        () => this.loadData()
      );
    } else if (key === "inner_circle_product") {
      window.history.pushState(
        {},
        null,
        `/${this.props.match.params.storegeo}/${this.props.match.params.storeid}/products/search-products`
      );
      this.setState(
        {
          ...defaultState,
          filters: {
            ...defaultState.filters,
            inner_circle_product: 1,
            newproduct: 0,
            all: 0,
          },
        },
        () => this.loadData()
      );
    } else if (key === "evergreen") {
      window.history.pushState(
        {},
        null,
        `/${this.props.match.params.storegeo}/${this.props.match.params.storeid}/products/search-products`
      );
      this.setState(
        {
          ...defaultState,
          filters: {
            ...defaultState.filters,
            evergreen: 1,
            newproduct: 0,
            all: 0,
          },
        },
        () => this.loadData()
      );
    } else if (key === "watchlist") {
      window.history.pushState(
        {},
        null,
        `/${this.props.match.params.storegeo}/${this.props.match.params.storeid}/products/search-products`
      );
      this.setState(
        {
          ...defaultState,
          filters: {
            ...defaultState.filters,
            watchlist: 1,
            newproduct: 0,
            all: 0,
          },
        },
        () => this.loadData()
      );
    } else if (key === "packaging") {
      window.history.pushState(
        {},
        null,
        `/${this.props.match.params.storegeo}/${this.props.match.params.storeid}/products/search-products?packaging=true`
      );

      this.setState(
        {
          ...defaultState,
          sort: "date-desc",
          filters: {
            ...defaultState.filters,
            packaging: 1,
            newproduct: 0,
            all: 0,
          },
        },
        () => this.loadData()
      );
    } else if (key === "all") {
      window.history.pushState(
        {},
        null,
        `/${this.props.match.params.storegeo}/${this.props.match.params.storeid}/products/search-products`
      );
      this.setState(
        {
          ...defaultState,
          filters: {
            ...defaultState.filters,
            newproduct: 0,
            all: 1,
          },
        },
        () => this.loadData()
      );
    } else {
      let tempItem = [];
      const {
        filters: { niche },
      } = this.state;
      if (key === "niche") {
        const index = tempItem.indexOf(value.name);
        if (value.status && index === -1) {
          tempItem.push(value.name);
        } else if (!value.status && index !== -1) {
          tempItem.splice(index, 1);
        }
      }

      this.setState(
        {
          filters: {
            ...this.state.filters,
            all: 2,
            [key]: key === "niche" ? tempItem : value,
          },
          paging: { page: 1, per_page_count: 20 },
        },
        () => this.loadData()
      );
    }
  }

  onSortChange(sort) {
    this.setState(
      {
        sort,
        paging: { page: 1, per_page_count: 20 },
      },
      () => this.loadData()
    );
  }

  clearAllFilters() {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          search: "",
          category: "",
          media: 0,
          buyback: 0,
          watchlist: 0,
          adv_data: 0,
          india_dropshipping: 0,
          global_dropshipping: 0,
          bestseller: 0,
          trending: 0,
          express_shipping: 0,
          hotselling: 0,
          inner_circle_product: 0,
          evergreen: 0,
          newproduct: 0,
          ad_copy_available: 0,
          inventory_available: 0,
          potd: 0,
          all: 1,
        },
        value: [0, 0],
        paging: { page: 1, per_page_count: 20 },
      },
      () => this.loadData()
    );
  }

  clearAllNiche() {
    this.setState(
      {
        niches: [],
        filters: {
          ...this.state.filters,
          niche: [],
        },
        paging: { page: 1, per_page_count: 20 },
      },
      () => this.loadData()
    );
  }

  toggleImportProductModal = () => {
    this.setState({
      importProductModalVisible: !this.state.importProductModalVisible,
    });
  };

  lessRequestCount() {
    if (this.state.requestCount > 0) {
      this.setState({ requestCount: this.state.requestCount - 1 });
    }
  }

  importProducts = (productIds, name = null) => {
    axios({
      url:
        process.env.REACT_APP_API_URL + "/products/import_products_aliexpress",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
        productids: productIds,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success === 1) {
          !!name
            ? ActionNotification({
                notificationType: "importProduct",
                name: name,
              })
            : ActionNotification({
                notificationType: "ali-express-import",
              });

          // message.success(
          //   "Product import in progress. Please visit import list section to view imported products"
          // );
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  sorterHandler(sort) {
    if (sort === "date-asc") {
      return `Oldest to Newest`;
    }

    if (sort === "date-desc") {
      return `Newest to Oldest`;
    }

    if (sort === "inv") {
      return `Inventory Available`;
    }
  }

  render() {
    const currency = this.props.match.params.storegeo === "in" ? "INR" : "USD";

    return (
      <div className="catalog-listing">
        <CategoriesBar
          categories={this.state.categories}
          niches={this.state.niches}
          geo={this.props.match.params.storegeo}
          storeid={this.props.match.params.storeid}
          onChange={(k, v) => this.onFilterChange(k, v)}
          niche={this.state.filters.niche}
          filters={this.state.filters}
          product={this.state.products}
          clearAllNiche={() => this.clearAllNiche}
          loading={this.state.loading}
        />
        <div className="catalog-listing-content">
          <div className="content-sidebar">
            <div className="div-sidebar">
              <h1 className="f20 product-research-txt">
                {this.state.filters.packaging === 1
                  ? "Discover Packaging"
                  : "Product Research"}{" "}
              </h1>
              <VideoTooltip
                title="Product Research Tool"
                contentHeadings={[
                  "New Products",
                  "Inner Circle Products",
                  "Evergreen Products",
                  "All",
                ]}
                content={[
                  "These are the recently added products which vFulfill feels are novelty & never seen before products.",
                  "Winning products that vFulfill knows are performing good already basis the product performance reports.",
                  "Products which are generic and used to be viral at a point in time & are today available with local sellers to sell.",
                  "All products listed from newest to oldest sequence.",
                ]}
                subtitle="vFulfill product research tool helps you to find products in the niche you would like to do business."
                thumbnail="https://d24fzeiqvvundc.cloudfront.net/catalog/images/3e7c8814a2af76df557cfaa0a8c99c24.png"
                videolink="https://d24fzeiqvvundc.cloudfront.net/images/global/dashboard/tooltips_videos/Product%20Research%20tool.mp4"
              />
            </div>

            <div className="filter-product-data">
              <Filters
                loading={this.state.loading}
                filters={this.state.filters}
                clearAll={() => this.clearAllFilters()}
                clearAllNiche={() => this.clearAllNiche()}
                categories={this.state.categories}
                onChange={(k, v) => this.onFilterChange(k, v)}
                niches={this.state.niches}
                getNicheName={(inche) => this.getNicheName(inche)}
                storegeo={this.props.match.params.storegeo}
                storeid={this.props.match.params.storeid}
                firstInputeOnchnage={(e) => this.firstInputeOnchnage(e)}
                secondInputeOnchnage={(e) => this.secondInputeOnchnage(e)}
                onRangeFilterChange={(e) => this.onRangeFilterChange(e)}
                step={this.state.step}
                loadData={() => this.loadData()}
                value={this.state.value}
              />
            </div>
          </div>
          <div className="content">
            <div
              className="f12 sorter"
              style={{
                marginBottom: 18,
                display: "flex",
                justifyContent: "space-between",
                lineHeight: "32px",
              }}
            >
              {this.state.filters.all === 1 ? (
                <div />
              ) : (
                <span style={{ color: "#6B6B6B" }}>
                  <span style={{ fontWeight: 500 }}>Sort By:</span>
                  <Dropdown
                    onOpenChange={(v) => this.setState({ arrow: v })}
                    arrow={true}
                    overlay={
                      <Menu
                        value={this.state.sort}
                        onClick={(item) => this.onSortChange(item.key)}
                      >
                        <Menu.Item key="date-desc">Newest to Oldest</Menu.Item>
                        <Menu.Item key="date-asc">Oldest to Newest</Menu.Item>
                        <Menu.Item
                          disabled={
                            this.state.filters.packaging === 1 ? true : false
                          }
                          key="inv"
                        >
                          {" "}
                          Inventory Available
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <a
                      href="#"
                      className="ant-dropdown-link sorter-link"
                      onClick={(e) => e.preventDefault()}
                      style={{ marginLeft: 8 }}
                    >
                      {this.sorterHandler(this.state.sort)}
                      {/* <Icon
                      className={`${
                        !this.state.arrow
                          ? `arrow arrow-down`
                          : `arrow arrow-up`
                      }`}
                      component={Arrow}
                    /> */}

                      <Icon type="down" />
                    </a>
                  </Dropdown>
                </span>
              )}

              {this.state.filters.packaging === 1 ? null : (
                <button
                  className="top-import-button"
                  style={{ marginLeft: 16 }}
                  onClick={() => this.toggleImportProductModal()}
                >
                  <Icon
                    type="download"
                    style={{
                      fontSize: "24px",
                      verticalAlign: "middle",
                      paddingRight: "5px",
                      color: "#fff",
                    }}
                  />
                  Add products from AliExpress
                </button>
              )}
            </div>

            {this.state.filters.all === 1 ? (
              <div className="label-page">
                {this.state.error ? (
                  <ErrorCard
                    errorCode={this.state.errorCode}
                    errorMsg={this.state.errorMsg}
                  />
                ) : this.state.loading ? (
                  <Skeleton active />
                ) : this.state.labelproducts.length > 0 ? (
                  this.state.labelproducts.map((labeld) => (
                    <div className="catalog-listing-labelsection">
                      <div className="catalog-listing-labelhead">
                        <div className="catalog-listing-labelname">
                          {labeld.label_name}
                        </div>
                        <div
                          onClick={() =>
                            this.state.labelproducts.length === 1
                              ? this.changeLabel("")
                              : this.changeLabel(labeld.label_id)
                          }
                          className="catalog-listing-labelviewall"
                        >
                          {this.state.labelproducts.length === 1 ? (
                            <p className="viewmore-label-products backward">
                              <Arrow /> Go Back
                            </p>
                          ) : (
                            <p className="viewmore-label-products forward">
                              View More <Arrow />
                            </p>
                          )}
                        </div>
                      </div>
                      <Divider />
                      <div className="product-grid">
                        {labeld.products.map((p) => (
                          <ProductCard
                            key={p._id["$oid"]}
                            product={p}
                            currency={currency}
                            storeid={this.props.match.params.storeid}
                            storegeo={this.props.match.params.storegeo}
                            importProduct={(id, name) =>
                              this.importProducts([id], name)
                            }
                            lessRequestCount={() => this.lessRequestCount()}
                            requestCount={this.state.requestCount}
                          />
                        ))}
                      </div>
                      {this.state.labelproducts.length === 1 ? (
                        <Pagination
                          locale={{ jump_to: "Go to page" }}
                          showTotal={(total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`
                          }
                          showQuickJumper
                          showSizeChanger
                          pageSizeOptions={["10", "20", "40"]}
                          onShowSizeChange={(p, d) =>
                            this.onShowSizeChange(p, d)
                          }
                          current={this.state.paging.page}
                          onChange={(page) => this.goToPage(page)}
                          total={this.state.total}
                          defaultPageSize={this.state.paging.per_page_count}
                        />
                      ) : null}
                    </div>
                  ))
                ) : (
                  <NotFoundCard
                    mainHeading={"Oops! No Data Found."}
                    secondHeading={
                      <p>Looks like there are no products in this page.</p>
                    }
                  />
                )}
              </div>
            ) : null}
            {this.state.filters.all !== 1 ? (
              this.state.error ? (
                <ErrorCard
                  errorCode={this.state.errorCode}
                  errorMsg={this.state.errorMsg}
                />
              ) : this.state.loading ? (
                <Skeleton active />
              ) : this.state.products.length > 0 ? (
                <>
                  <div className="product-grid">
                    {this.state.products.map((p) =>
                      !!this.state.filters.packaging ? (
                        <PackagingCard
                          key={p._id["$oid"]}
                          product={p}
                          currency={currency}
                          storeid={this.props.match.params.storeid}
                          storegeo={this.props.match.params.storegeo}
                          requestCount={this.state.requestCount}
                          lessRequestCount={() => this.lessRequestCount()}
                        />
                      ) : (
                        <ProductCard
                          key={p._id["$oid"]}
                          product={p}
                          currency={currency}
                          storeid={this.props.match.params.storeid}
                          storegeo={this.props.match.params.storegeo}
                          importProduct={(id, name) =>
                            this.importProducts([id], name)
                          }
                          lessRequestCount={() => this.lessRequestCount()}
                          requestCount={this.state.requestCount}
                        />
                      )
                    )}
                  </div>
                  <Pagination
                    locale={{ jump_to: "Go to page" }}
                    showTotal={(total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`
                    }
                    showQuickJumper
                    showSizeChanger
                    pageSizeOptions={["10", "20", "40"]}
                    onShowSizeChange={(p, d) => this.onShowSizeChange(p, d)}
                    current={this.state.paging.page}
                    onChange={(page) => this.goToPage(page)}
                    total={this.state.total}
                    defaultPageSize={this.state.paging.per_page_count}
                  />
                </>
              ) : (
                <NotFoundCard
                  mainHeading={"Oops! No Data Found."}
                  secondHeading={
                    <p>Looks like there are no products in this page.</p>
                  }
                />
              )
            ) : null}

            {/* {this.state.loading ? (
              <Skeleton active />
            ) : this.state.products.length > 0 ? (
              <>
                <div className="product-grid">
                  {this.state.products.map((p) =>
                    !!this.state.filters.packaging ? (
                      <PackagingCard
                        key={p._id["$oid"]}
                        product={p}
                        currency={currency}
                        storeid={this.props.match.params.storeid}
                        storegeo={this.props.match.params.storegeo}
                        requestCount={this.state.requestCount}
                        lessRequestCount={() => this.lessRequestCount()}
                      />
                    ) : (
                      <ProductCard
                        key={p._id["$oid"]}
                        product={p}
                        currency={currency}
                        storeid={this.props.match.params.storeid}
                        storegeo={this.props.match.params.storegeo}
                        importProduct={(id, name) =>
                          this.importProducts([id], name)
                        }
                        lessRequestCount={() => this.lessRequestCount()}
                        requestCount={this.state.requestCount}
                      />
                    )
                  )}
                </div>
                <Pagination
                  locale={{ jump_to: "Go to page" }}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  showQuickJumper
                  showSizeChanger
                  pageSizeOptions={["10", "20", "40"]}
                  onShowSizeChange={(p, d) => this.onShowSizeChange(p, d)}
                  current={this.state.paging.page}
                  onChange={(page) => this.goToPage(page)}
                  total={this.state.total}
                  defaultPageSize={this.state.paging.per_page_count}
                />
              </>
            ) : (
              <NotFoundCard
                mainHeading={"Oops! No Data Found."}
                secondHeading={
                  <p>Looks like there are no products in this page.</p>
                }
              />
            )} */}
          </div>
        </div>
        <ImportProductModal
          visible={this.state.importProductModalVisible}
          onCancel={() => this.toggleImportProductModal()}
          onConfirm={(productIds) => this.importProducts(productIds)}
        />
      </div>
    );
  }
}

export default CatalogListing;
