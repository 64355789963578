import React, { Component } from "react";
import { Button, Modal, Input, message, Tabs } from "antd";
import "./index.scss";
export default class MediaModal extends Component {
  state = {
    files: [],
    showMediaModalUrl: "",
    currentFileIndex: 0,
    // disableLeft: false,
    // disableRight: false,
  };
  componentDidMount() {
    console.log(this.props.mediaFilesArr);
    this.setState({
      files: this.props.mediaFilesArr,
      showMediaModalUrl: this.props.mediaFilesArr[0],
    });
  }
  //   componentDidUpdate() {
  //     if (this.state.currentFileIndex === 0) {
  //       this.setState({
  //         disableLeft: true,
  //       });
  //     } else {
  //       this.setState({
  //         disableLeft: false,
  //       });
  //     }
  //     if (this.state.currentFileIndex === this.state.files.length - 1) {
  //       this.setState({
  //         disableRight: true,
  //       });
  //     } else {
  //       this.setState({
  //         disableRight: false,
  //       });
  //     }
  //   }
  imgPreviewHandler(fileUrl) {
    // Get the file extension.
    const fileExtension = fileUrl.split(".")[fileUrl.split(".").length - 1];

    // Check the file extension against a list of supported file types.
    const supportedFileTypes = ["xlsx", "png", "jpg", "jpeg", "pdf", "mp4"];
    if (supportedFileTypes.includes(fileExtension)) {
      // The file type is supported, so return the HTML for displaying the file.
      switch (fileExtension) {
        case "xlsx":
          return (
            <iframe width="100%" height="500px" title="file" src={fileUrl} />
          );
        case "png":
        case "jpg":
        case "jpeg":
          return <img width="100%" height="500px" alt="img" src={fileUrl} />;
        case "pdf":
          return (
            <embed
              src={fileUrl}
              type="application/pdf"
              width="100%"
              height="500px"
            />
          );
        case "mp4":
          return <video src={fileUrl} controls width="100%" height="500px" />;

        default:
          return null;
      }
    } else {
      // The file type is not supported, so return an empty string.
      return "No Media";
    }
  }

  render() {
    return (
      <Modal
        className="media-modal-new"
        title="Media"
        width={900}
        footer={<Button onClick={() => this.props.onCancel()}>Close</Button>}
        destroyOnClose={true}
        // {...this.props}
        visible={this.props.visible}
        onCancel={() => this.props.onCancel()}
      >
        <div
          className="left"
          style={{ display:this.state.files.length ===1 ?"none":"block"}}
          onClick={() => {
            if (this.state.currentFileIndex < 1) {
              this.setState({
                showMediaModalUrl: this.state.files[
                  this.state.files.length - 1
                ],
                currentFileIndex: this.state.files.length - 1,
              });
              return;
            }
            this.setState({
              showMediaModalUrl: this.state.files[
                // !!(this.state.currentFileIndex - 1)
                //   ? this.state.currentFileIndex - 1
                //   : this.state.files.length - 1
                this.state.currentFileIndex - 1
              ],
              currentFileIndex: this.state.currentFileIndex - 1,
            });
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="m480-320 56-56-64-64h168v-80H472l64-64-56-56-160 160 160 160Zm0 240q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
          </svg>
        </div>
        <div className="img-slider">
          {this.state.showMediaModalUrl
            ? this.imgPreviewHandler(this.state.showMediaModalUrl)
            : null}{" "}
        </div>{" "}
        <div
          className="right"
          style={{ display:this.state.files.length ===1 ?"none":"block"}}
          onClick={() => {
            if (this.state.currentFileIndex === this.state.files.length - 1) {
              this.setState({
                showMediaModalUrl: this.state.files[0],
                currentFileIndex: 0,
              });
              return;
            }
            this.setState({
              showMediaModalUrl: this.state.files[
                // !!(this.state.currentFileIndex === this.state.files.length - 1)
                //   ? 0
                //   : this.state.currentFileIndex + 1
                this.state.currentFileIndex + 1
              ],
              currentFileIndex: this.state.currentFileIndex + 1,
            });
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="m480-320 160-160-160-160-56 56 64 64H320v80h168l-64 64 56 56Zm0 240q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
          </svg>
        </div>
      </Modal>
    );
  }
}
