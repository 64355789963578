import React, { Component } from "react";
import {
  Button,
  Divider,
  Icon,
  Input,
  Form,
  message,
  Select,
  Row,
  Radio,
  Col,
  DatePicker,
  Spin,
} from "antd";

import axios from "axios";
import moment from "moment";
import "./index.scss";
import { Link } from "react-router-dom";

const { TextArea } = Input;
const { Option } = Select;
class vfullfillForm extends Component {
  state = {
    saving: false,
    privateLabel: undefined,

    productLoading: false,
    productData: [],
    selectedProduct: "",
    prodVariants: [],
    selectedVariant: "",
    count: 0,
    varientqtyerror: false,
    externalFormProduct: undefined,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ varientqtyerror: false });
        let keysarr = Object.keys(values);
        let varient = keysarr.filter((key) => key.startsWith("VFPD"));
        let varientqty = {};
        varient.forEach((key) => {
          if (!values[key]) {
            return;
          }
          return (varientqty[key] = values[key]);
        });
        console.log(varientqty);
        let varientARR = Object.keys(varientqty);
        let aleastOnefill = varientARR.length ? true : false;

      
        if (!aleastOnefill) {
          this.setState({ varientqtyerror: true });
          return;
        }

        this.setState(
          {
            saving: true,
          },
          () => {
            axios({
              url:
                process.env.REACT_APP_API_URL +
                "/rfqrequests/create_rfq_requests",
              method: "post",
              data: {
                ...values,

                type: "vfulfill",

                qty: varientqty,
                expected_landing_product_cost: Number(
                  values.expected_landing_product_cost
                ),
              },
              withCredentials: true,
            })
              .then((res) => {
                if (res.data.success === 1) {
                  this.props.activeTabHandler("open");
                  this.setState(
                    {
                      saving: false,
                    },
                    () => this.props.onCancel()
                  );
                } else {
                  message.info(res.data.msg);

                  this.setState({
                    saving: false,
                  });
                }
              })
              .catch((e) => {
                this.setState({
                  saving: false,
                });
              });
          }
        );
      }
    });
  };

  loadProductData(search) {
    this.setState({ productLoading: true }, () => {
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.cancelToken = axios.CancelToken.source();
      axios({
        url:
          process.env.REACT_APP_API_URL +
          "/rfqrequests/get_products_rqf_requests_create",
        method: "post",
        cancelToken: this.cancelToken.token,
        data: {
          filters: { search },
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.setState({
              productData: res.data.data,
              productLoading: false,
              count: res.data.count,
            });
          } else {
            this.setState({
              error: true,
              errorMsg: res.data.msg,
              productLoading: false,
              productData: [],
            });
          }
        })
        .catch((e) => {
          this.setState({
            error: true,
            errorMsg: e.message,
            productData: [],
          });
        });
    });
  }

  componentDidMount() {
    if (Object.keys(this.props.externalFormProduct).length) {
      this.props.form.setFieldsValue({
        vfprodid: this.props.externalFormProduct.vfprodid,
      });

      this.setState({
        productData: [this.props.externalFormProduct],
        prodVariants: this.props.externalFormProduct.variants,
      });
    } else {
      this.loadProductData();
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item className="product-select" label="Select Required Product">
          {getFieldDecorator("vfprodid", {
            rules: [
              {
                required: true,
                message: "Please Select Product",
              },
            ],
          })(
            <Select
              showArrow={false}
              autoClearSearchValue={false}
              value={this.state.selectedProduct}
              placeholder="Enter product name to search for it"
              notFoundContent={
                this.state.productLoading ? (
                  <Spin size="small" />
                ) : (
                  <p>Not found</p>
                )
              }
              dropdownRender={(menu) => (
                <div>
                  {menu}

                  {this.state.productData && this.state.count > 10 ? (
                    <>
                      <Divider style={{ margin: "4px 0" }} />
                      <Link target="_blank" to={`../products/search-products`}>
                        <div
                          style={{ padding: "4px 8px", cursor: "pointer" }}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          <Icon type="plus" /> View All
                        </div>
                      </Link>
                    </>
                  ) : null}
                </div>
              )}
              showSearch
              allowClear
              dropdownClassName="product-overlay-wraper"
              filterOption={false}
              onSearch={(e) => this.loadProductData(e)}
              onSelect={(e, a) =>
                this.setState({
                  selectedProduct: e,
                  prodVariants: a.props.variants,
                })
              }
              style={{ width: "100%" }}
            >
              {this.state.productData.length &&
                this.state.productData.map((d) => (
                  <Option variants={d.variants} key={d.vfprodid}>
                    <div className="img-details-wraper">
                      <div className="img-details">
                        <img src={d.image} alt="" />
                        <div className="text-wraper">
                          <a
                            target="_blank"
                            className="underline-animation"
                            href={d.product_url}
                          >
                            {d.name}
                          </a>

                          <div className="vf">
                            <span>VF ID:</span> {d.vfprodid}
                          </div>
                        </div>
                      </div>

                      <svg
                        className="right_svg"
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                      >
                        <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z" />
                      </svg>
                    </div>
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        {this.state.prodVariants.length ? (
          <div className="varient-qty-container">
            <div className="heading-varient">
              {" "}
              <span className="red-dot">*</span>Select Quantity By Variant
            </div>
            {this.state.prodVariants.map((variant) => {
              console.log(variant);
              return (
                <div className="img-details-wraper-custom">
                  <div className="img-details">
                    <img src={variant.image} alt="" />
                    <div className="text-wraper">
                      <div className="vf">
                        <span>SKU :</span> {variant.vfsku}
                      </div>
                      <div className="allsep">
                        {" "}
                        <span>Specs:</span> {variant.allspecs}{" "}
                      </div>
                    </div>
                    <Form.Item className="varient-item">
                      {getFieldDecorator(
                        variant.vfsku,
                        {}
                      )(
                        <Select
                          className="landing-select"
                          placeholder="Enter required quantity"
                        >
                          <Option value={"1"}> Sample </Option>
                          <Option value={"100-250"}>100 - 250</Option>
                          <Option value={"251-500"}>251 - 500</Option>
                          <Option value={"501-1000"}>501 - 1000</Option>
                          <Option value={"1001-2000"}>1001 - 2000</Option>
                          <Option value={"2001"}> + 2001</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </div>
                </div>
              );
            })}
            {this.state.varientqtyerror ? (
              <div className="qty-error">
                Please Select Atleast One Variant Quantity Range{" "}
              </div>
            ) : null}
          </div>
        ) : null}
        {/* 
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item label="Expected Landing Timeline">
              {getFieldDecorator("expected_landing_date", {
                rules: [
                  {
                    required: true,
                    message: "Please select expected landing timeline",
                  },
                ],
              })(
                <Select
                  className="landing-select"
                  placeholder="Enter expected landing timeline"
                >
                  <Option value={"4-7"}>4-7 days</Option>
                  <Option value={"10-15"}>10-15 days</Option>
                  <Option value={"30-45"}>30-45 days</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row> */}
        <Form.Item label="Expected Product Cost">
          {getFieldDecorator("expected_landing_product_cost")(
            <Input
              placeholder="Enter expected product cost for the required quantity"
              addonBefore="₹"
              type="number"
            />
          )}
        </Form.Item>

        <Form.Item className="private-label-radio-group" label="Private Label">
          {getFieldDecorator("private_label", {
            initialValue: "no",
          })(
            <Radio.Group
              onChange={(e) => this.setState({ privateLabel: e.target.value })}
            >
              <Radio value={"yes"}>Yes</Radio>
              <Radio value={"no"}>No</Radio>
            </Radio.Group>
          )}
        </Form.Item>

        <Form.Item label="Additional Information">
          {getFieldDecorator("private_label_remarks")(
            <TextArea placeholder="Enter some additional information for the request." />
          )}
        </Form.Item>

        <Form.Item className="footer-button">
          <Button
            type="info"
            htmlType="button"
            className="cancelBtn"
            disabled={this.state.saving}
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: 12 }}
            loading={this.state.saving}
          >
            Submit Request
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(vfullfillForm);
