import React, { useState } from "react";

import "./index.scss";
import { Button, Modal, Tooltip, Icon } from "antd";
import { ReactComponent as TooltipIcon } from "../../../../../assets/images/icons/TooltipIcon.svg";
import { ReactComponent as RightTick } from "../../../../../assets/images/icons/rightTick.svg";
import { Link } from "react-router-dom";

const ConfirmPackagingModal = (props) => {
  const { data, visible, loading, currency, product, bookingId } = props;

  return (
    <>
      {/*  Open Raise Bulk Order Request */}
      <Modal
        width={478}
        className={`recomended-packaging-modal ${
          data.length === 0 ? `hide-border` : ``
        } `}
        visible={visible}
        footer={
          <>
            <div className="btns">
              {data.length === 0 ? null : (
                <Link
                  className="green-outline"
                  to="../products/search-products?packaging=true"
                >
                  View Packaging Options
                </Link>
              )}
            </div>

            {props.freightType === "VF Inventory" ? (
              <Link
                className="clickable-dropdown custom-width"
                to={`../requests/sourcing-requests?search=${bookingId}`}
              >
                View Booking Status
              </Link>
            ) : (
              <Link
                className="clickable-dropdown custom-width"
                to={`../requests/sourcing-requests?search=${bookingId}`}
              >
                View Booking Request
              </Link>
            )}

            <Button
              className="p-cancel"
              onClick={() => props.cancel()}
              type="cancel"
            >
              Close Window
            </Button>
          </>
        }
        // onOk={handleOk}
        title={
          <>
            <div>
              {" "}
              <span>
                <Icon component={RightTick} />
              </span>{" "}
              {props.freightType === "VF Inventory" ? (
                <>
                  <h1>Thank You! </h1>
                  <h1>Your Sourcing Request Has Been Confirmed.</h1>
                </>
              ) : (
                <>
                  <h1>Thank You! </h1>
                  <h1>Your Sourcing Request Has Been Recieved.</h1>
                </>
              )}
            </div>{" "}
            {data.length === 0 ? null : (
              <p>
                We recommend adding the extra delight for your customers by
                upgrading to custom packaging:
              </p>
            )}{" "}
          </>
        }
        // onCancel={handleCancel2}
      >
        {!!data.length ? (
          <div className="variants-headings">
            <div className="head-1">Recommended Packaging Type</div>
            <div className="head-2" />
            <div className="head-3">Pricing</div>
          </div>
        ) : null}

        {!!data.length
          ? data.map((d, i) => {
              return (
                <div className="variants-data-row">
                  <div className="head-1">
                    <div className="img">
                      {" "}
                      <img src={d.image} />
                    </div>
                    <div className="details">
                      <div className="sku">
                        <span>{d.catalogname}</span>
                      </div>
                      <div className="specs">
                        <p>PACKAGING ID:</p>
                        {/* <span>{d.vfprodid}</span> */}
                        <a
                          className="underline-animation"
                          href={`../products/search-products?packaging=true?search=${d.vfprodid}&listing=1`}
                          target="_blank"
                        >
                          {d.vfprodid}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="head-2" />
                  <div className="head-3">
                    {currency} {d.ppq}
                  </div>
                </div>
              );
            })
          : null}
      </Modal>
    </>
  );
};

export default ConfirmPackagingModal;
