import React, { Component } from "react";
import { Divider, Checkbox, Select, Icon, Tooltip, Button } from "antd";
import "./index.scss";
import { timezones } from "../../../../Helpers/Timezones";
import { ReactComponent as Tooltipican } from "../../../../assets/images/icons/tooltipican.svg";
import moment from "moment";
const { Option } = Select;

class BasicSettings extends Component {
  state = {
    ...this.props.settings,
    arrow: false,
    timezone: this.props.settings.timezone ? this.props.settings.timezone : [],
  };

  toggleState = (field) => {
    this.setState(
      {
        ...this.state,
        [field]: !this.state[field],
      },
      () => {
        this.updateSetting();

        this.props.onUpdate(this.state);
      }
    );
  };

  changeState = (field, value) => {
    if (field === "customShippingTrackingUrl") {
      let validUrl = false;

      if (
        value &&
        value.match(
          /^(http:\/\/www.|https:\/\/www.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
        )
      ) {
        validUrl = true;
      }

      this.setState(
        {
          ...this.state,
          validCustomShippingTrackingUrl: validUrl,
        },
        () => {
          this.setState(
            {
              ...this.state,
              [field]: value,
            },
            () => this.updateSetting()
          );
        }
      );
    } else {
      this.setState(
        {
          ...this.state,
          [field]: value,
        },
        () => this.props.onUpdate(this.state)
      );
    }
  };

  updateSetting() {
    this.props.onSettingChange();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps });
  }

  render() {
    const { setNewProductsAsPublished } = this.state;

    const { toggleState } = this;

    return (
      <div className="settings-container">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3 className="title">Store Settings</h3>
        </div>

        <Divider />

        <div className="options-container">
          {/* <span className="spn">Set new products as published</span> */}

          <div className="select-wrap" style={{ marginBottom: "24px" }}>
            {" "}
            <Checkbox
              className={`spn ${setNewProductsAsPublished ? `true` : `false`}`}
              onChange={() => toggleState("setNewProductsAsPublished")}
              checked={setNewProductsAsPublished}
            >
              Set new products as published
            </Checkbox>
            <i>
              (Automatically publish products after pushing them to your store)
            </i>
          </div>

          <label className="defaultTime">Select Default Timezone</label>
          <br />
          <Select
            style={{ width: 400 }}
            showSearch
            className="time-zone-dropdown"
            onDropdownVisibleChange={(val) => this.setState({ arrow: val })}
            filterOption
            value={this.state.timezone}
            placeholder="Please set your timezone"
            onChange={(val) => this.changeState("timezone", val)}
            suffixIcon={
              <svg
                style={{
                  marginLeft: "5px",
                  transition: "all 0.5s",
                  transform: `${
                    this.state.arrow ? "rotateX(180deg)" : "rotateX(0deg)"
                  }`,
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="8"
                viewBox="0 0 16 8"
              >
                <g
                  id="Polygon_49"
                  data-name="Polygon 49"
                  transform="translate(16 8) rotate(180)"
                  fill={`${this.state.arrow ? "#898989" : "none"}`}
                >
                  <path d="M8,0l8,8H0Z" stroke="none" />
                  <path
                    d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                    stroke="none"
                    fill="#000"
                  />
                </g>
              </svg>
            }
          >
            {timezones.map(([t, name]) => (
              <Option key={t}>
                {t}: {name}
              </Option>
            ))}
          </Select>

          <span
            className="time-zone-tooltip"
            style={{
              marginLeft: "10px",
            }}
          >
            <Tooltip title=" If timezone not set then timezone will be UTC by default">
              <Icon component={Tooltipican} />
            </Tooltip>
          </span>
        </div>
      </div>
    );
  }
}

export default BasicSettings;
