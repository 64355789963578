import React, { Component } from 'react'
import { Checkbox } from "antd";
import CodOrders from "./CodOrders";
import NonDeliveryReport from "./NonDeliveryReports";
import PrepaidOrders from "./PrepaidOrders";
export default class WingmanSettings
 extends Component {
  
  render() {
    console.log(" nk",this.props.cod)
    return (
        <div className="contact-center-setting">
        <div className="enable-order-container">
          <div
            className="enable-order-check"
            style={{
              padding: "16px 0px",
              borderBottom: "1px solid #CECECE",
            }}
          >
            {" "}
            <Checkbox
              checked={this.props.cod.enabled === 1}
              onChange={(e) =>
                this.props.updateSetting(
                  "cod",
                  "enabled",
                  e.target.checked === true ? 1 : 0
                )
              }
            >
              Enable Order Verification for COD Orders:
            </Checkbox>
            {this.props.cod.enabled === 1 ? (
              <CodOrders
                data={this.props.cod}
                updateSetting={(section, key, value) =>
                  this.props.updateSetting(section, key, value)
                }
              />
            ) : null}
          </div>
        </div>

        <div
          style={{
            padding: "16px 0px",
            borderBottom: "1px solid #CECECE",
          }}
        >
          {" "}
          <Checkbox
            checked={this.props.prepaid.enabled === 1}
            onChange={(e) =>
              this.props.updateSetting(
                "prepaid",
                "enabled",
                e.target.checked === true ? 1 : 0
              )
            }
          >
            Enable Order Verification for Prepaid Orders:
          </Checkbox>
          {this.props.prepaid.enabled === 1 ? (
            <PrepaidOrders
              data={this.props.prepaid}
              updateSetting={(section, key, value) =>
                this.props.updateSetting(section, key, value)
              }
            />
          ) : null}
        </div>

        <div
          style={{
            padding: "16px 0px",
          }}
        >
          {" "}
          <Checkbox
            checked={this.props.ndr.enabled === 1}
            onChange={(e) =>
              this.props.updateSetting(
                "ndr",
                "enabled",
                e.target.checked === true ? 1 : 0
              )
            }
          >
           Take actions on NDR (Non-Delivery reports) from remarks/actions received from WingMan app on NDR
          </Checkbox>
          {this.props.ndr.enabled === 1 ? (
            <NonDeliveryReport
              data={this.props.ndr}
              updateSetting={(section, key, value) =>
                this.props.updateSetting(section, key, value)
              }
            />
          ) : null}
        </div>
      </div>
    )
  }
}
