import React from "react";
import { formatBalance, getCurrencySymbol } from "../../../../Helpers/Pricing";

import { Row, Skeleton, Tooltip } from "antd";
import GlobalTooltipIcon from "../../Common/GlobalTooltipIcon";
import "./index.scss";
import NotFoundCard from "../../Common/NotFoundCard";

const AddedInWallet = (props) => {
  const {
    data,
    remit_reverse,
    startDate,
    endDate,
    loading,
    currency,
    show_remit_reverse,
  } = props;

  // function amountHandler(amount) {
  //   if (!!amount) {
  //     return (
  //       <div className="delivered-order-head credit">
  //         {getCurrencySymbol(currency)} {formatBalance(amount)}
  //       </div>
  //     );
  //   } else {
  //     return <div className="delivered-order-head ">0</div>;
  //   }
  // }

  function amountHandler(amount, color) {
    if (!!amount) {
      if (color === "yellow") {
        return (
          <div className="delivered-order-head yellow">
            {getCurrencySymbol(currency)} {formatBalance(amount)}
          </div>
        );
      } else {
        return (
          <div className="delivered-order-head credit">
            {getCurrencySymbol(currency)} {formatBalance(amount)}
          </div>
        );
      }
    } else {
      return <div className="delivered-order-head ">0</div>;
    }
  }

  function amountHandlerDebit(amount) {
    if (!!amount) {
      return (
        <div className="delivered-order-head debit">
          {getCurrencySymbol(currency)} {formatBalance(amount)}
        </div>
      );
    } else {
      return <div className="delivered-order-head ">0</div>;
    }
  }

  if (loading) {
    return (
      <Row style={{ marginBottom: "30px", marginTop: "60px" }}>
        <Skeleton active />
      </Row>
    );
  }

  if (Object.entries(data).length === 0) {
    return (
      <div id="fade-in" className="deductedFromWallet-summary">
        <div className="topPerformingProductHead">
          {" "}
          <span className="name">{props.title}</span> | <span>All time</span>
        </div>

        <NotFoundCard mainHeading={"Oops! No Data Found."} />
      </div>
    );
  }
  return (
    <div id="fade-in" className="deductedFromWallet-summary">
      <div className="topPerformingProductHead">
        {" "}
        <span className="name">{props.title}</span> | <span>All time</span>
      </div>
      <div className="product-heads">
        <div className="name-head">Source</div>

        <div className="delivered-order-head">Amount</div>
      </div>
      {props.title === "Money Added In Wallet" ? (
        <div className="product-row-wraper">
          <div className="product-row">
            <div className="name-head">Funds Added </div>
            {amountHandler(data.funds_added)}
          </div>

          <div className="product-row">
            <div className="name-head">COD + Prepaid Remittance</div>
            {amountHandler(
              data.processed_cod_remit_amount +
                data.processed_prepaid_remit_amount
            )}
          </div>
          {parseInt(data.processed_buyback_credit_pp) > 0 && (
            <div className="product-row">
              <div className="name-head">Buyback Credit Product Price </div>
              {amountHandler(data.processed_buyback_credit_pp)}
            </div>
          )}

          <div className="product-row">
            <div className="name-head">Remittance Pending</div>
            {amountHandler(data.pending_cod_remit_amount, "yellow")}
          </div>
          <div className="product-row">
            <div className="name-head">Manual Settlement</div>
            {amountHandler(data.manual_transactions)}
          </div>

          {!!data.rto_remit_credit ? (
            <div className="product-row">
              <div className="name-head">RTO Remit</div>
              {amountHandler(data.rto_remit_credit)}
            </div>
          ) : null}

          <div className="product-row">
            <div className="name-head total">Total</div>
            {amountHandler(props.total)}
          </div>
        </div>
      ) : (
        <div className="product-row-wraper">
          <div className="product-row">
            <div className="name-head">Money Added in Wallet </div>
            {amountHandler(data.money_added_in_wallet)}
          </div>

          <div className="product-row">
            <div className="name-head">Money Deducted from Wallet</div>
            {amountHandlerDebit(data.money_deducted)}
          </div>

          {/* {show_remit_reverse && (
            <div className="product-row">
              <div className="name-head">Remittance Reversal</div>
              {amountHandlerDebit(remit_reverse)}
            </div>
          )} */}

          <div className="product-row">
            <div className="name-head">Money Withdrawn from Wallet</div>
            {amountHandlerDebit(data.withdrawn)}
          </div>

          <div className="product-row">
            <div className="name-head">Wingman Charges</div>
            {amountHandlerDebit(data.wingman_charges)}
          </div>

          <div className="product-row">
            <div className="name-head total">Current Wallet Balance</div>
            {amountHandler(data.total)}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddedInWallet;
