import React, { Component } from "react";
import { formatNumber, getCurrencySymbol } from "../../../../Helpers/Pricing";
import axios from "axios";

class ShippingTab extends Component {
  state = {
    cod: null,
    charges: null,
    loading: false,
    changePrice: false,
  };

  setCOD(val) {
    if (/^[0-9.]+$/.test(val) && val.split(".").length <= 2) {
      this.setState({ cod: val });
    } else {
      this.setState({ cod: null });
    }
  }
  changePrice() {
    this.setState({ changePrice: !this.state.changePrice });
  }

  getCodCharges(e) {
    e.preventDefault();
    this.setState(
      {
        loading: true,
      },
      () => {
        if (this.cancelToken) {
          this.cancelToken.cancel();
        }
        this.cancelToken = axios.CancelToken.source();
        axios({
          url: process.env.REACT_APP_API_URL + "/products/calculate_codcharges",
          method: "post",
          cancelToken: this.cancelToken.token,
          data: {
            codamount: this.state.cod === 0 ? 0 : parseInt(this.state.cod, 10),
            storeid: this.props.storeid,
          },
          withCredentials: true,
        })
          .then((res) => {
            this.setState({ loading: false });
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                charges: res.data.cod_charges,
              });
            }
          })
          .catch((e) => {});
      }
    );
  }

  render() {
    return (
      <div>
        <div>
          <p className="f12 mb8" style={{ color: "#898989" }}>
            Last Mile Delivery/ Shipping Fees:
          </p>
          <table className="shipping-zones">
            <thead>
              {this.props.storgeo === "global" ? (
                <th>Country code</th>
              ) : (
                <th>Zone</th>
              )}

              {this.props.storgeo === "global" ? (
                <th> Country</th>
              ) : (
                <th> Description</th>
              )}

              <th>
                Amount{" "}
                {this.props.currency === "INR" ? (
                  <span style={{ fontStyle: "Italic", fontSize: "8px" }}>
                    (excl. of GST)
                  </span>
                ) : null}
              </th>
            </thead>
            <tbody>
              {this.props.ratecard.map((rc, index) => (
                <tr key={index}>
                  <td>{rc[0]}</td>
                  <td>{rc[1]}</td>

                  {this.props.currency === "INR" ? (
                    <td>
                      {getCurrencySymbol("INR")} {formatNumber(rc[2])}
                    </td>
                  ) : (
                    <td>
                      {getCurrencySymbol("USD")} {formatNumber(rc[2])}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {this.props.currency === "INR" ? (
          <div className="mb16" style={{ marginTop: "24px" }}>
            {this.state.charges ? (
              <>
                <p className="f12 mb8" style={{ color: "#898989" }}>
                  Cash On Delivery Charges ({getCurrencySymbol("INR")}):{" "}
                  <span style={{ color: "#212121", fontWeight: "600" }}>
                    {getCurrencySymbol("INR")}{" "}
                    {formatNumber(this.state.charges.base)}
                  </span>{" "}
                  <span style={{ color: "#212121" }}>
                    + {getCurrencySymbol("INR")}{" "}
                    {formatNumber(this.state.charges.gst)} (GST)
                  </span>
                </p>
              </>
            ) : (
              <p className="f12 mb8" style={{ color: "#898989" }}>
                Cash On Delivery Charges({getCurrencySymbol("INR")}):{" "}
                <span style={{ color: "#212121", fontWeight: "600" }}>
                  {getCurrencySymbol("INR")}{" "}
                  {this.props &&
                  this.props.codCharges &&
                  this.props.codCharges.base
                    ? this.props.codCharges.base
                    : null}
                </span>{" "}
                <span style={{ color: "#212121" }}>
                  + {getCurrencySymbol("INR")}{" "}
                  {this.props &&
                  this.props.codCharges &&
                  this.props.codCharges.gst
                    ? this.props.codCharges.gst
                    : null}{" "}
                  (GST)
                </span>
              </p>
            )}
            <p className="f12 mb8">
              (Based on the Suggested Selling Price.{" "}
              <span
                style={{
                  textDecoration: "underline",
                  color: "#1370FC",
                  cursor: "pointer",
                }}
                onClick={() => this.changePrice()}
              >
                Change Price
              </span>
              )
            </p>

            <div style={{ display: "flex", alignItems: "baseline" }}>
              {this.state.changePrice ? (
                <form
                  style={{ width: 392, marginRight: 64 }}
                  onSubmit={(e) => this.getCodCharges(e)}
                >
                  <div className="w100" style={{ display: "flex" }}>
                    <input
                      type="text"
                      placeholder="Enter Selling Price"
                      value={this.state.cod}
                      onChange={(e) => this.setCOD(e.target.value)}
                      style={{
                        background: "#F5F5F5",
                        color: "black",
                        border: "none",
                        flex: 1,
                        padding: "3px 16px",
                        fontSize: 12,
                      }}
                    />
                    <button
                      type="submit"
                      style={{
                        background: "#1370FC",
                        color: "white",
                        borderRadius: 0,
                        border: "none",
                        fontSize: 12,
                      }}
                    >
                      Calculate COD Fees
                      <div
                        className="loader"
                        style={{
                          display: this.state.loading ? "inline-block" : "none",
                          width: 15,
                          height: 15,
                          marginLeft: 10,
                          border: "2px solid rgb(241 241 242 / 30%)",
                          borderTopColor: "white",
                          verticalAlign: "top",
                        }}
                      />
                    </button>
                  </div>
                </form>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default ShippingTab;
