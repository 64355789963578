import React, { Component } from "react";
import { Dropdown, Icon, Menu, Select } from "antd";
import { SHOPIFY_NAME } from "../../../../constants";
import { ReactComponent as Arrow } from "../../../../assets/images/icons/arrow.svg";

import "./index.scss";
const { Option } = Select;
class Sorter extends Component {
  state = {
    arrow: false,
  };
  render() {
    return (
      <div className="data-sorter">
        <div
          style={{
            display: "flex",
            float: "left",
            alignItems: "center",
          }}
        >
          <span>Filter By:</span>

          <Select
            // className=""
            style={{ fontWeight: 500, fontSize: 12 }}
            value={this.props.sortingOrder}
            onChange={null}
            onDropdownVisibleChange={(val) => this.setState({ arrow: val })}
            suffixIcon={
              <svg
                style={{
                  transition: "all 0.5s",
                  transform: `${
                    this.state.arrow ? "rotateX(180deg)" : "rotateX(0deg)"
                  }`,
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="8"
                viewBox="0 0 16 8"
              >
                <g
                  id="Polygon_49"
                  data-name="Polygon 49"
                  transform="translate(16 8) rotate(180)"
                  fill={`${this.state.arrow ? "#898989" : "none"}`}
                >
                  <path d="M8,0l8,8H0Z" stroke="none" />
                  <path
                    d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                    stroke="none"
                    fill="#898989"
                  />
                </g>
              </svg>
            }
          >
            <Option value="">{`${SHOPIFY_NAME} Order Date`}</Option>
            <Option disabled value="weeks">
              VF Order date
            </Option>
          </Select>
        </div>
      </div>
    );
  }
}

export default Sorter;
