import React from "react";
import "./index.scss";

export default function Header(props) {
  return (
    <div className="onboarding-header">
      <h1 className="title">{props.title}</h1>
      {props.subtitle && <p className="subtitle">{props.subtitle}</p>}
    </div>
  );
}
