import React, { Component } from "react";
import { Select, Checkbox, Divider, Button } from "antd";
import { ReactComponent as Arrow } from "../../../../assets/images/icons/arrow.svg";
import "./index.scss";
const { Option } = Select;

class BucketFilter extends Component {
  state = {
    selected: this.props.value,
    open: false,
    searchText: "",
    arrow: false,
  };

  componentDidUpdate(pp) {
    if (pp.value !== this.props.value) {
      this.setState({ selected: this.props.value });
    }
  }

  bucket = [
    { value: 0, label: "Exception" },
    { value: 1, label: "Customer Unavailable" },
    { value: 2, label: "Rejected by Customer" },
    { value: 3, label: "Delivery Rescheduled" },
    { value: 4, label: "No Attempt" },
    { value: 5, label: "Customer Unreachable" },
    { value: 6, label: "Address Issue" },
    { value: 7, label: "Payment Issue" },
    { value: 8, label: "Out of Delivery Area" },
    { value: 9, label: "Order Already Cancelled" },
    { value: 10, label: "Self Collect" },
    { value: 11, label: "Shipment Seized by Customer" },
    { value: 12, label: "Customer Wants Open Delivery" },
    { value: 13, label: "Shipment Misrouted by Logistics Partner" },
    { value: 14, label: "OTP-Based Cancellation" },
  ];

  attemps = [
    // { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "More than 5" },
  ];

  selectAll() {
    if (this.props.type === "attempt") {
      let selected = this.attemps.map((d) => d.value);

      this.setState({ selected: selected }, () =>
        this.props.changeHandler(this.state.selected)
      );
    } else {
      let selected = this.bucket.map((d) => d.value);
      this.setState({ selected: selected }, () =>
        this.props.changeHandler(this.state.selected)
      );
    }
  }

  render() {
    const selectProps = {
      mode: "multiple",
      // open: true,
      style: { width: "312px" },
      value: this.props.value,
      searchValue: this.state.searchText,
      dropdownMatchSelectWidth: true,
      dropdownRender: (menu) => (
        <div>
          {menu}
          <Divider style={{ margin: "4px 0" }} />
          <div
            className="overlay-footer"
            style={{ cursor: "pointer" }}
            onMouseDown={(e) => e.preventDefault()}
          >
            {this.state.selected.length > 0 ? (
              <Button
                type=""
                onClick={() =>
                  this.setState({ selected: [] }, () =>
                    this.props.changeHandler(this.state.selected)
                  )
                }
              >
                Clear All
              </Button>
            ) : (
              <Button onClick={() => this.selectAll()} type="primary">
                Select All
              </Button>
            )}
          </div>
        </div>
      ),
      dropdownClassName: "bucket-filter-overlay",
      maxTagCount: this.props.type === "attempt" ? "5" : "4",
      onSearch: (t) => this.setState({ searchText: t }),
      filterOption: (input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
      onChange: (d) =>
        this.setState({ selected: d }, () =>
          this.props.changeHandler(this.state.selected)
        ),
      onDropdownVisibleChange: (d) => this.setState({ arrow: d }),
    };

    const PageWrapper = (children) => {
      return (
        <div style={{ display: "flex" }}>
          <div
            className={`bucket-filter-wraper ${
              this.props.type === "attempt" ? `custom-padding` : ``
            }`}
          >
            <div className="custom-text">
              {this.props.type === "attempt"
                ? `Delivery Attempts: `
                : `NDR Bucket: `}
              {!!this.state.selected.length ||
              !!this.state.searchText.length ? null : (
                <span>All</span>
              )}
            </div>

            <Select {...selectProps}>
              {(this.props.type === "attempt" ? this.attemps : this.bucket).map(
                (d, i) => {
                  return (
                    <Option key={i} value={d.value}>
                      {d.label}
                    </Option>
                  );
                }
              )}
            </Select>
            <div
              className={`custom-arrow-icon ${this.state.arrow ? `open` : ``}`}
            >
              <Arrow />
            </div>
          </div>
        </div>
      );
    };

    return PageWrapper();
  }
}

export default BucketFilter;
