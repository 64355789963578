import { Component } from "react";
import { connect } from "react-redux";

class StoreGeoChecker extends Component {
  componentDidMount() {
    const urlGeo = this.props.match.params.storegeo;
    const storeGeo = this.props.store.store_geo;
    if (urlGeo !== storeGeo) {
      const urlParts = this.props.location.pathname.split("/");
      urlParts[1] = storeGeo;
      this.props.history.push(urlParts.join("/"));
    }
  }
  render() {
    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};
export default connect(mapStateToProps)(StoreGeoChecker);
