import React from "react";
import { Modal } from "antd";
import Lottie from "react-lottie";
import bulb from "../../../../assets/images/jsonAnimationIcon/bulb.json";
import "./index.scss";

class AppTourEndModal extends React.Component {
  render() {
    const defaultOptions = {
      // loop: true,
      // autoplay: true,
      animationData: bulb,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <Modal
        className="tour-end-modal"
        visible={this.props.visible}
        footer={null}
        onCancel={() => this.props.closeModal()}
      >
        <div className="tour-modal-content">
          <div className="bulb-icon-wraper">
            <div className="bulb-icon">
              <Lottie
                options={defaultOptions}
                // isStopped={this.state.iconStop}
                height={40}
                width={40}
              />
            </div>
          </div>

          <h1>More Information</h1>
          <h2>
            You can always find more information on each page by hovering over
            the icon shown above!
          </h2>

          <div className="btn-wraper">
            <button onClick={() => this.props.closeModal()}>Let's Go!</button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default AppTourEndModal;
