export const hasPermission = (store, perm) => {
  if (
    store.store_role === "owner" ||
    store.store_permissions.indexOf("all") !== -1 ||
    store.store_permissions.indexOf(perm) !== -1
  ) {
    return true;
  }
  return false;
};
